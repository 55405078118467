import * as constants from '../constants/constantsFormularioQuiz'

export const getAvaliacaoByHash = (hash) => {
  return {
    type: constants.AVALIACAO_LOAD,
    api: {
      method: 'get',
      url: constants.URL_AVALIACAO_LOAD(hash),
    },
  }
}

export const loadQuiz = (quiz_id) => {
  return {
    type: constants.QUIZ_LOAD,
    api: {
      method: 'get',
      url: constants.URL_QUIZ_LOAD(quiz_id),
    },
  }
}

export const loadCompanyLogo = (conta_url) => {
  return {
    type: constants.COMPANY_LOGO_LOAD,
    api: {
      method: 'get',
      url: constants.URL_COMPANY_LOGO_LOAD(conta_url),
    },
  }
}

export const postResposta = (avalia_id, resposta) => {
  return {
    type: constants.POST_RESPOSTA_AVALIACAO,
    api: {
      method: 'post',
      url: constants.URL_POST_RESPOSTA_AVALIACAO(avalia_id),
      data: resposta,
    },
  }
}

export const loadNota = (avalia_id) => {
  return {
    type: constants.NOTA_LOAD,
    api: {
      method: 'get',
      url: constants.URL_NOTA_LOAD(avalia_id),
    },
  }
}
