import { connect } from 'react-redux'
import * as actions from './actions/pdtActions'

// Components
import PDTController from './PDTController'

// Actions

const mapStateToProps = ({ pdt }) => {
  return { ...pdt }
}

export default connect(mapStateToProps, { ...actions })(PDTController)
