import { useField } from '@unform/core'
import React, { useEffect, useRef } from 'react'

const CheckboxField = ({
  name,
  label,
  required,
  onChange,
  disabled,
  ...rest
}) => {
  const inputRef = useRef(null)

  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.checked
      },

      setValue: (ref, value) => {
        ref.current.checked = !!value
      },

      clearValue: (ref) => {
        ref.current.checked = false
      },
    })
  }, [fieldName, registerField])

  return (
    <label
      htmlFor={fieldName}
      style={{
        cursor: rest.disabled ? 'not-allowed' : 'pointer',
      }}
    >
      <input
        style={{
          marginRight: 8,
          cursor: rest.disabled ? 'not-allowed' : 'pointer',
        }}
        type="checkbox"
        checked={defaultValue}
        ref={inputRef}
        id={fieldName}
        name={fieldName}
        disabled={disabled}
        required={required}
        onChange={(e) => {
          if (onChange) {
            onChange(e)
          }
        }}
        {...rest}
      />
      {label}
    </label>
  )
}

export default CheckboxField
