// Redux types
import * as constants from '../constants/constantsPerfilComportamentalDetalhes'

export const getColaboradores = () => {
  return {
    type: constants.COLABORADORES_GET,
    api: {
      method: 'get',
      url: constants.URL_COLABORADORES_GET,
    },
  }
}
export const getPessoa = (id) => {
  return {
    type: constants.PESSOA_GET,
    api: {
      method: 'get',
      url: `${constants.URL_PESSOA_GET}/${id}`,
    },
  }
}
export const getDadosVetor = (id, avalia_id) => {
  return {
    type: constants.VETOR_GET,
    api: {
      method: 'get',
      url: `${constants.URL_VETOR_GET}/${id}/${avalia_id}`,
    },
  }
}
