// types
export const PESQ_VALORES_LOAD = 'PESQ_VALORES_LOAD'

export const PESQ_VALORES_GET_COMPORTAMENTOS = 'PESQ_VALORES_GET_COMPORTAMENTOS'

export const PESQ_VALORES_POST_RESPOSTAS = 'PESQ_VALORES_POST_RESPOSTAS'

// urls
export const URL_PESQ_VALORES_LOAD = (hash) =>
  `${process.env.REACT_APP_API_URL}/pesqvalores/load/${hash}`

export const URL_PESQ_VALORES_GET_COMPORTAMENTOS = (hash) =>
  `${process.env.REACT_APP_API_URL}/pesqvalores/comportamentos/${hash}`

export const URL_PESQ_VALORES_POST_RESPOSTAS = (hash) =>
  `${process.env.REACT_APP_API_URL}/pesqvalores/respostas/${hash}`

export const tooltips = [
  'Nunca',
  'Raramente',
  'Às vezes',
  'Muitas vezes',
  'Sempre',
]

// export const tooltips =
// 	[
// 		'Não me identifico',
// 		'Me identifico pouco',
// 		'Nem um nem outro',
// 		'Me identifico',
// 		'Me identifico muito'
// 	]
