import { QuestionCircleOutlined } from '@ant-design/icons'
import { Card, Tooltip } from 'antd'
import moment from 'moment'
import Select, { Option } from '../../../../../../components/select/Select'
//
import { cardHeadBlue } from '../../../../../../utils/inlineStyles'
import {
  EstatisticaBox,
  EstatisticasColumn,
  EstatisticasContainer,
  EstatisticaTitle,
  EstatisticaValue,
} from '../../styles'
import ComparacaoTime from './comparacao-time'

const Time = ({
  columns,
  grupos,
  loadingGrupos,
  grupoSelecionado,
  handleChangeTime,
  statistics,
  nps,
  setShowModalAddColaborador,
  setShowModalAddCandidato,
  loadingMembros,
  membros,
  grupoId,
  setMembros,
  ter,
  removeFromStorage,
}) => {
  function isLoadedValue() {
    const id = localStorage.getItem('id')
    if (id) return true
    return false
  }
  return (
    <Card title="Time" className="card-time" headStyle={cardHeadBlue}>
      <Select
        showSearch
        style={{ width: '100%' }}
        label={<span data-html2canvas-ignore>Selecione</span>}
        placeholder="Selecione"
        onChange={removeFromStorage}
        value={
          isLoadedValue()
            ? grupoSelecionado.grupo_nome
            : grupoSelecionado.grupo_id
        }
        data-html2canvas-ignore
        loading={loadingGrupos}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {grupos.map(({ grupo_nome, grupo_id }) => (
          <Option value={grupo_id} key={grupo_id}>
            {grupo_nome}
          </Option>
        ))}
      </Select>

      {statistics && (
        <EstatisticasContainer>
          <EstatisticasColumn>
            <EstatisticaBox>
              <EstatisticaTitle>Pesquisa mais antiga: </EstatisticaTitle>
              <EstatisticaValue>
                {statistics.antiga
                  ? moment(statistics.antiga).format('DD/MM/YYYY')
                  : ''}
              </EstatisticaValue>
            </EstatisticaBox>
            <EstatisticaBox>
              <EstatisticaTitle>Pesquisa mais recente: </EstatisticaTitle>
              <EstatisticaValue>
                {statistics.recente
                  ? moment(statistics.recente).format('DD/MM/YYYY')
                  : ''}
              </EstatisticaValue>
            </EstatisticaBox>
          </EstatisticasColumn>
          <EstatisticasColumn>
            <EstatisticaBox>
              <EstatisticaTitle>Membros: </EstatisticaTitle>
              <EstatisticaValue>{statistics.membros}</EstatisticaValue>
            </EstatisticaBox>
            <EstatisticaBox>
              <EstatisticaTitle>Respondidas: </EstatisticaTitle>
              <EstatisticaValue>{statistics.respostas}</EstatisticaValue>
            </EstatisticaBox>
          </EstatisticasColumn>
          <EstatisticasColumn>
            <EstatisticaBox>
              <EstatisticaTitle>e-NPS: </EstatisticaTitle>
              <EstatisticaValue>
                {nps === 'N/A' ? (
                  <Tooltip title="Para a proteção do anonimato, é necessário, no mínimo, 4 pesquisas válidas de e-NPS realizadas no time.">
                    {nps} <QuestionCircleOutlined />
                  </Tooltip>
                ) : nps >= 75 ? (
                  <Tooltip color="green" title="Escala (100 a -100): Excelente">
                    {nps} <QuestionCircleOutlined />
                  </Tooltip>
                ) : nps >= 50 ? (
                  <Tooltip color="lime" title="Escala (100 a -100): Muito Bom">
                    {nps} <QuestionCircleOutlined />
                  </Tooltip>
                ) : nps >= 0 ? (
                  <Tooltip color="yellow" title="Escala (100 a -100): Razoável">
                    {nps} <QuestionCircleOutlined />
                  </Tooltip>
                ) : (
                  <Tooltip color="gold" title="Escala (100 a -100): Ruim">
                    {nps} <QuestionCircleOutlined />
                  </Tooltip>
                )}
              </EstatisticaValue>
            </EstatisticaBox>
          </EstatisticasColumn>
        </EstatisticasContainer>
      )}

      <ComparacaoTime
        grupoId={grupoId}
        columns={columns}
        loadingMembros={loadingMembros}
        membros={membros}
        setMembros={setMembros}
        setShowModalAddCandidato={setShowModalAddCandidato}
        setShowModalAddColaborador={setShowModalAddColaborador}
      />
    </Card>
  )
}

export default Time
