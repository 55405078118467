import React, { Component } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import 'moment-timezone'
import XLSX from 'xlsx'

// Components
import { SearchOutlined, FilterFilled } from '@ant-design/icons'
import { message } from 'antd'
import GestaoPesquisasView from './GestaoPesquisasView'
import Button from '../../components/button/Button'
import Input, { Range as RangePicker } from '../../components/input/Input'

// Constants
import { modals } from './constants/constantsGestaoPesquisas'

class GestaoPesquisasController extends Component {
  state = {
    showModals: [false, false, false],
    dateSource: [],
    modalStates: modals.map((modal) => ({ ...modal.initialState })),
    searchText: '',
    selectedRows: [],
    selectedRowKeys: [],
    listPessoas: [[], []],
  }

  componentDidMount() {
    const { getPesquisas, getCandidatos } = this.props

    getCandidatos().then(({ response }) => {
      if (response.status === 200) {
        const candidatos = response.data.candidatos.map(
          ({ pes_id, pes_nome }) => ({
            nome: pes_nome,
            id: pes_id,
          })
        )

        const colaboradores = this.state.listPessoas[1] || []
        this.setState({
          listPessoas: [candidatos, colaboradores],
        })
      }
    })

    getPesquisas()
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  handleChangeModal = (id, { target }) =>
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.value } : elem
      ),
    }))

  handleChangeCheckbox = (id, { target }) => {
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.checked } : elem
      ),
    }))
  }

  openModal = (id) => {
    const { showModals } = this.state
    const newshowModals = showModals
    newshowModals[id] = true
    this.setState({ showModals: newshowModals })
  }

  closeModal = (id) => {
    const { showModals, modalStates } = this.state
    const newshowModals = showModals

    newshowModals[id] = false

    const newModalStates = modalStates

    newModalStates[id] = modals[id].initialState

    this.setState({
      showModals: newshowModals,
      modalStates: newModalStates,
    })
  }

  exportarExcel = (pesquisas) => {
    const aoa = [
      [
        'Status',
        'Pesquisa',
        'Nome',
        'Login',
        'Grupo',
        'Data de envio',
        'Data de resposta',
        'Senha',
        'Criado por',
        'Alterado por',
        'Alterado em',
      ],
      ...pesquisas.map((pesquisa) => [
        pesquisa.status === 0 ? 'Pendente' : '',
        pesquisa.tipo,
        pesquisa.pes_nome,
        pesquisa.pes_login,
        pesquisa.grupos,
        pesquisa.data_solicitacao &&
          moment(pesquisa.data_solicitacao)
            .utc()
            .tz(moment.tz.guess())
            .format('DD/MM/YYYY'),
        pesquisa.data_entrega &&
          moment(pesquisa.data_entrega)
            .utc()
            .tz(moment.tz.guess())
            .format('DD/MM/YYYY'),
        pesquisa.pes_senha,
        pesquisa.created_by,
        pesquisa.updated_by,
        pesquisa.updated_at,
      ]),
    ]

    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(aoa)

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Export Pesquisas')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'pesquisas.xlsx')
  }

  onModalSubmit = (id) => {
    const { modalStates, selectedRows } = this.state
    const {
      sendVetorComportamental,
      getPesquisas,
      sendValoresOrganizacionais,
    } = this.props

    // if (id === 1) {
    if (
      !modalStates[id].colaborador ||
      (!modalStates[id].percepcaoAmbiente &&
        !modalStates[id].valoresOrganizacionais &&
        !modalStates[id].vetorComportamental)
    ) {
      toast.error('Selecione um usuário e gere ao menos uma pesquisa.')
    } else {
      if (modalStates[id].vetorComportamental)
        sendVetorComportamental(
          {
            pessoas: [{ pes_id: modalStates[id].colaborador }],
          },
          { enviarEmails: modalStates[id].enviarEmails }
        ).then(({ response }) => {
          if (response.status === 200) {
            getPesquisas()
            message.success('Enviado com sucesso!')
          }
        })
      if (modalStates[id].valoresOrganizacionais)
        sendValoresOrganizacionais(modalStates[id].colaborador, {
          enviarEmails: modalStates[id].enviarEmails,
        }).then(({ response }) => {
          if (response.status === 200) {
            getPesquisas()
            message.success('Enviado com sucesso!')
          }
        })

      this.closeModal(id)
    }
    // }
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      switch (dataIndex) {
        case 'data_entrega':
          return (
            <div style={{ padding: 8 }}>
              <RangePicker
                format="DD/MM/YYYY"
                name="startAndEnd"
                onChange={(dates) => setSelectedKeys([dates])}
              />
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                Buscar
              </Button>
              <Button
                onClick={() => this.handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Resetar
              </Button>
            </div>
          )
        default:
          return (
            <div style={{ padding: 8 }}>
              <Input
                placeholder="Buscar"
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                style={{ width: 188, display: 'block' }}
              />
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                Buscar
              </Button>
              <Button
                onClick={() => this.handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Resetar
              </Button>
            </div>
          )
      }
    },
    filterIcon: (filtered) => {
      switch (dataIndex) {
        case 'data_entrega':
          return (
            <FilterFilled style={{ color: filtered ? '#30318b' : undefined }} />
          )
        default:
          return (
            <SearchOutlined
              style={{ color: filtered ? '#30318b' : undefined }}
            />
          )
      }
    },
    onFilter: (value, record) => {
      switch (dataIndex) {
        case 'data_entrega':
          return (
            record[dataIndex] &&
            moment(record[dataIndex]) >= value[0] &&
            moment(record[dataIndex] <= value[1])
          )
        default:
          return (
            record[dataIndex] &&
            record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          )
      }
    },
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedRows,
    })
  }

  handleNewLink = (pes_id, avalia_id) => {
    this.props.newLink(pes_id, avalia_id).then(() => this.props.getPesquisas())
  }

  render() {
    const {
      onModalSubmit,
      openModal,
      closeModal,
      handleChangeModal,
      getColumnSearchProps,
      handleChangeCheckbox,
      onSelectChange,
      exportarExcel,
      handleNewLink,
    } = this
    const {
      showModals,
      dataSource,
      modalStates,
      selectedRowKeys,
      listPessoas,
    } = this.state
    const {
      listPesquisas,
      sendVetorComportamental,
      getPesquisas,
      cancelPercepcao,
      cancelVetorComportamental,
      cancelValoresOrganizacionais,
      sendValoresOrganizacionais,
    } = this.props
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    }

    return (
      <GestaoPesquisasView
        openModal={openModal}
        closeModal={closeModal}
        onModalSubmit={onModalSubmit}
        showModals={showModals}
        modalStates={modalStates}
        handleChangeModal={handleChangeModal}
        dataSource={listPesquisas}
        rowSelection={rowSelection}
        getColumnSearchProps={getColumnSearchProps}
        handleChangeCheckbox={handleChangeCheckbox}
        listPessoas={listPessoas}
        sendVetorComportamental={sendVetorComportamental}
        exportarExcel={exportarExcel}
        getPesquisas={getPesquisas}
        cancelPercepcao={cancelPercepcao}
        cancelVetorComportamental={cancelVetorComportamental}
        handleNewLink={handleNewLink}
        cancelValoresOrganizacionais={cancelValoresOrganizacionais}
        sendValoresOrganizacionais={sendValoresOrganizacionais}
      />
    )
  }
}

export default GestaoPesquisasController
