// Error messages
const errorMessages = {
  required: `O campo é obrigatório`,
  length: (minLength) => `Você deve informar no mínimo ${minLength} valor(es)`,
  linkedin: 'Link de perfil do Linkedin inválido.',
  twitter: 'Link de perfil do Twitter inválido.',
  url: 'Url inválida.',
  email: 'E-mail inválido!',
  cpf: 'CPF inválido!',
}

export const validateForm = (formFields, values) => {
  let error = { hasError: false, messages: {} }

  formFields.forEach((field) => {
    if (field.required) {
      if (
        values[field.name] === undefined ||
        values[field.name] === null ||
        values[field.name] === ''
      )
        error = {
          ...error,
          hasError: true,
          messages: { ...error.messages, [field.name]: errorMessages.required },
        }

      if (field.minLength) {
        if (
          !Array.isArray(values[field.name]) ||
          values[field.name].length < field.minLength
        ) {
          error = {
            ...error,
            hasError: true,
            messages: {
              ...error.messages,
              [field.name]: errorMessages.length(field.minLength),
            },
          }
        }
      }
    }

    if (field.linkedin && values[field.name]) {
      let pattern =
        /(ftp|http|https):\/\/?((www|\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/

      if (!values[field.name].match(pattern)) {
        error = {
          ...error,
          hasError: true,
          messages: {
            ...error.messages,
            [field.name]: errorMessages.linkedin,
          },
        }
      }
    }

    if (field.twitter && values[field.name]) {
      let pattern = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/

      if (!values[field.name].match(pattern)) {
        error = {
          ...error,
          hasError: true,
          messages: {
            ...error.messages,
            [field.name]: errorMessages.twitter,
          },
        }
      }
    }

    if (field.url && values[field.name]) {
      let pattern =
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi

      if (!values[field.name].match(pattern)) {
        error = {
          ...error,
          hasError: true,
          messages: {
            ...error.messages,
            [field.name]: errorMessages.url,
          },
        }
      }
    }

    if (field.name === 'email' && values[field.name]) {
      let pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!values.email.match(pattern)) {
        error = {
          ...error,
          hasError: true,
          messages: {
            ...error.messages,
            [field.name]: errorMessages.email,
          },
        }
      }
    }

    if (field.name === 'cpf' && values[field.name]) {
      let pattern =
        /^([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/

      if (!values.cpf.match(pattern)) {
        error = {
          ...error,
          hasError: true,
          messages: {
            ...error.messages,
            [field.name]: errorMessages.cpf,
          },
        }
      }
    }
  })

  return error
}
