import { useState, useEffect } from 'react'
import { debounce } from '../debounce'

/**
 *
 * @param tempo em milissegundos
 *
 */
export const useTimeQuestion = (time: number) => {
  const [tempoAcabou, setTempoAcabou] = useState(false)
  const [tempoMaximo, setTempoMaximo] = useState(time)

  useEffect(() => {
    setTempoAcabou(false)

    debounce(() => {
      if (tempoMaximo !== 0) {
        setTempoMaximo(tempoMaximo - 1000)
      }
    }, 1000)()

    if (tempoMaximo === 0) {
      setTempoAcabou(true)
    }
  }, [tempoMaximo, time])

  return {
    tempoAcabou,
    tempoMaximo,
  }
}
