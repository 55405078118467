export function findElement(name, array) {
  if (!array) return 0
  const myobj = Object.keys(array)
    .filter((key) => key.includes(name))
    .reduce((obj, key) => {
      return Object.assign(obj, {
        Grupo: array[key],
      })
    }, {})
  return myobj
}

export function findGender(name, array) {
  if (!array) 'N/A'
  let retorno = 'N/A'
  array.forEach((gender) => {
    if (gender.genero == name) retorno = String(gender.qtde)
  })

  return retorno
}

export function findGeneration(name, array) {
  if (!array) return 'N/A'
  let retorno = 'N/A'
  array.forEach((gender) => {
    if (gender.geracao == name) retorno = String(gender.qtde)
  })

  return retorno
}

export function findConfianca(name, array) {
  if (!array) return 'N/A'
  let retorno = 'N/A'
  array.forEach((conf) => {
    if (conf.TEXTO == name) retorno = String(conf.VALOR)
  })

  return retorno
}
