import {
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  StarFilled,
} from '@ant-design/icons'
import { Checkbox, Col, message, Row } from 'antd'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
//
import { noPadding } from '../../../../utils/inlineStyles'
// utils
import {
  parseCulturas,
  parseDISC,
  parseVetor,
  styleColors,
} from '../../../../utils/utils'

import useAsyncState from '../../../hooks/useAsyncState'
// hooks
import usePrevious from '../../../hooks/usePrevious'
import '../../Dashboard.css'
// actions
import {
  getCultura,
  getCulturaOrganizacional,
  getCulturaPessoa,
  getGrupos,
  getNps,
  getStatistics,
} from './actions'
import ArquetiposCulturais from './components/arquetipos-culturais'
import ModalGraficoArquetiposCulturais from './components/arquetipos-culturais/modal/grafico'
import ForcasEstrategicas from './components/forcas-estrategicas'
import IndicadoresMudanca from './components/indicadores-mudanca'
import PerfilComportamental from './components/perfil-comportamental'
import Time from './components/time'
import FitCulturalMembroTime from './components/time/fit-cultural-membro-time'
import ModalAdicionarCandidato from './components/time/modal/adicionar-candidato'
import ModalAdicionarColaborador from './components/time/modal/adicionar-colaborador'
import PerfilComportamentalMembroTime from './components/time/perfil-comportamental-membro-time'
// constants
import {
  culturaOrgDefault,
  CULTURAS,
  DISC,
  estatisticasDefault,
  FORCAS,
  GESTAO,
  labelsCulturaGrupo,
  OLHARES,
} from './constants'
import { ColFill, TimeTableRow } from './styles'

const { pink } = styleColors

const VisaoTimes = () => {
  const dispatch = useDispatch()

  const [statistics, setStatistics] = useState(estatisticasDefault)
  const [loadingCultura, setLoadingCultura] = useState(false)
  const [cultura, setCultura] = useState([])
  const [forcas, setForcas] = useState([])
  const [disc, setDisc] = useState([])
  const [olhares, setOlhares] = useState([])
  const [gestao, setGestao] = useState([])
  const [nps, setNPS] = useState([])
  const [culturaOrganizacional, setCulturaOrganizacional] =
    useState(culturaOrgDefault)
  const [loadingCulturaOrg, setLoadingCulturaOrg] = useState(false)
  const [init, setInit] = useState(true)

  const [pessoasEscolhidas, setPessoasEscolhidas] = useAsyncState([])

  const [pessoasExcluidas, setPessoasExcluidas] = useState([])
  const prevPessoasExcluidas = usePrevious(pessoasExcluidas)

  const [pessoasIncluidas, setPessoasIncluidas] = useState([])
  const prevPessoasIncluidas = usePrevious(pessoasIncluidas)

  const [grupoSelecionado, setGrupoSelecionado] = useState({
    grupo_id: undefined,
    grupo_nome: undefined,
  })

  const [grupos, setGrupos] = useState([])
  const [loadingGrupos, setLoadingGrupos] = useState(false)

  const [membros, setMembros] = useState([])
  const [loadingMembros, setLoadingMembros] = useState(false)

  const [showModalAddCandidato, setShowModalAddCandidato] = useState(false)
  const [showModalAddColaborador, setShowModalAddColaborador] = useState(false)

  const loadGrupos = useCallback(() => {
    setLoadingGrupos(true)
    dispatch(getGrupos())
      .then(({ response }) => {
        if (response.status === 200) {
          const { grupos: gruposData } = response.data

          setGrupos(gruposData)

          if (gruposData.length > 0) {
            setGrupoSelecionado(gruposData[0])
          }
        }

        setLoadingGrupos(false)
      })
      .catch((err) => {
        message.error('Erro no carregamento, volte daqui a alguns minutos')
      })
  }, [dispatch])

  const loadMembros = useCallback(
    (grupo_id) => {
      setLoadingMembros(true)
      dispatch(getMembros(grupo_id))
        .then(({ response }) => {
          if (response.status === 200) {
            const { membros: membrosData } = response.data

            setMembros(membrosData)
          } else setMembros([])

          setLoadingMembros(false)
        })
        .catch((err) => {
          message.error(
            'Erro no carregamento dos membros, volte daqui a alguns minutos'
          )
        })
    },
    [dispatch]
  )

  const loadCultura = useCallback(
    (grupo_id, pessoasExcluidas = null, pessoasIncluidas = null) => {
      setLoadingCultura(true)
      dispatch(getCultura(grupo_id, { pessoasExcluidas, pessoasIncluidas }))
        .then(({ response }) => {
          if (response.status === 200) {
            let useCulturaLider = true

            const {
              culturaDesejada,
              culturaInstalada,
              culturaLider,
              culturaDesejadaGrupo,
            } = response.data

            const newPessoasEscolhidas = [...pessoasEscolhidas]

            if (
              (culturaLider && cultura.length === 0) ||
              (culturaLider &&
                pessoasEscolhidas.find(
                  (p) => p.lider && parseInt(p.lider) === 1
                ))
            ) {
              newPessoasEscolhidas.push({
                pes_id: culturaLider.pes_id,
                lider: 1,
                membro: true,
              })
            } else {
              useCulturaLider = false
            }

            const data = parseCulturas({
              culturaDesejada,
              culturaInstalada,
              culturaDesejadaGrupo,
              labelCulturaDesejadaEmpresa: 'Desejado (Empresa)',
              labelCulturaDesejadaGrupo: 'Desejado',
              ...(useCulturaLider ? { culturaLider } : {}),
            })

            const {
              cultura: culturaParsed,
              forcas: forcasParsed,
              disc: discParsed,
              olhares: olharesParsed,
              gestao: gestaoParsed,
            } = data

            setCultura((state) => [
              ...culturaParsed,
              ...state.filter(
                (c) => !labelsCulturaGrupo.find((l) => l === c.Label)
              ),
            ])
            setForcas((state) => [
              ...forcasParsed,
              ...state.filter(
                (f) => !labelsCulturaGrupo.find((l) => l === f.Label)
              ),
            ])
            setDisc((state) => [
              ...discParsed,
              ...state.filter(
                (d) => !labelsCulturaGrupo.find((l) => l === d.Label)
              ),
            ])
            setOlhares((state) => [
              ...olharesParsed,
              ...state.filter(
                (o) => !labelsCulturaGrupo.find((l) => l === o.Label)
              ),
            ])
            setGestao((state) => [
              ...gestaoParsed,
              ...state.filter(
                (g) => !labelsCulturaGrupo.find((l) => l === g.Label)
              ),
            ])
            setPessoasEscolhidas([...newPessoasEscolhidas])
          } else {
            setCultura([])
            setForcas([])
            setDisc([])
            setOlhares([])
            setGestao([])
          }

          setLoadingCultura(false)
        })
        .catch((err) => {
          message.error(
            'Erro no carregamento dos dados da cultura, volte daqui a alguns minutos'
          )
        })
    },
    [cultura.length, dispatch, pessoasEscolhidas, setPessoasEscolhidas]
  )

  const loadNPS = useCallback(
    (grupo_id) => {
      dispatch(getNps(grupo_id))
        .then(({ response }) => {
          if (response.status === 200) {
            const nps = response.data
            setNPS(nps)
          } else {
            const nps = 'N/A'
            setNPS(nps)
          }
        })
        .catch((err) => {
          message.error('Erro no carregamento, volte daqui a alguns minutos')
        })
    },
    [dispatch]
  )

  const loadStatistics = useCallback(
    (grupo_id) => {
      dispatch(getStatistics(grupo_id))
        .then(({ response }) => {
          if (response.status === 200) {
            const { estatisticas } = response.data

            setStatistics(estatisticas[0])
          } else setStatistics(estatisticasDefault)
        })
        .catch((err) => {
          message.error(
            'Erro no carregamento das estatísticas, volte daqui a alguns minutos'
          )
        })
    },
    [dispatch]
  )

  const loadCulturaOrganizacional = useCallback(
    (grupo_id) => {
      setLoadingCulturaOrg(true)
      dispatch(getCulturaOrganizacional(grupo_id))
        .then(({ response }) => {
          if (response.status === 200) {
            const { culturaOrganizacional: culturaOrgData, disc: discData } =
              response.data

            setCulturaOrganizacional({
              culturaOrg: culturaOrgData,
              disc: discData,
            })
          } else setCulturaOrganizacional(culturaOrgDefault)

          setLoadingCulturaOrg(false)
        })
        .catch((err) => {
          message.error(
            'Erro no carregamento da cultura organizacional, volte daqui a alguns minutos'
          )
        })
    },
    [dispatch]
  )

  const loadCulturaPessoa = useCallback(
    (pes_id, pessoasEscolhidas) => {
      dispatch(getCulturaPessoa(pes_id))
        .then(({ response }) => {
          if (response.status === 200) {
            const { cultura: culturaData } = response.data

            const pessoaEscolhida = pessoasEscolhidas.find(
              (pes) => pes.pes_id === pes_id
            )

            const newCulturas = [
              ...cultura,
              ...Object.keys(culturaData)
                .filter((key) => CULTURAS.includes(key))
                .map((key) => ({
                  Tipo: key,
                  Valor: Number(culturaData[key]),
                  Label:
                    pessoaEscolhida.lider &&
                    parseInt(pessoaEscolhida.lider) === 1
                      ? 'Líder'
                      : pessoaEscolhida.pes_nome,
                })),
            ]

            const newForcas = [
              ...forcas,
              ...Object.keys(culturaData)
                .filter((key) => FORCAS.includes(key))
                .map((key) => ({
                  Tipo: key === 'Ambiente interno' ? 'Ambiente Interno' : key,
                  Valor: Number(culturaData[key]),
                  Label:
                    pessoaEscolhida.lider &&
                    parseInt(pessoaEscolhida.lider) === 1
                      ? 'Líder'
                      : pessoaEscolhida.pes_nome,
                })),
            ]

            const newOlhares = [
              ...olhares,
              ...Object.keys(culturaData)
                .filter((key) => OLHARES.includes(key))
                .map((key) => ({
                  Tipo: key,
                  Valor: Number(culturaData[key]),
                  Label:
                    pessoaEscolhida.lider &&
                    parseInt(pessoaEscolhida.lider) === 1
                      ? 'Líder'
                      : pessoaEscolhida.pes_nome,
                })),
            ]

            const newGestao = [
              ...gestao,
              ...Object.keys(culturaData)
                .filter((key) => GESTAO.includes(key))
                .map((key) => ({
                  Tipo: key,
                  Valor: Number(culturaData[key]),
                  Label:
                    pessoaEscolhida.lider &&
                    parseInt(pessoaEscolhida.lider) === 1
                      ? 'Líder'
                      : pessoaEscolhida.pes_nome,
                })),
            ]

            const newDisc = [
              ...disc,
              ...Object.keys(culturaData)
                .filter((key) => DISC.includes(key))
                .map((key) => ({
                  Tipo: parseDISC(key),
                  Valor: Number(culturaData[key]),
                  Label:
                    pessoaEscolhida.lider &&
                    parseInt(pessoaEscolhida.lider) === 1
                      ? 'Líder'
                      : pessoaEscolhida.pes_nome,
                })),
            ]

            setCultura(newCulturas)
            setForcas(newForcas)
            setOlhares(newOlhares)
            setDisc(newDisc)
            setGestao(newGestao)
          }
        })
        .catch((err) => {
          message.error(
            'Erro no carregamento da cultura de pessoa, volte daqui a alguns minutos'
          )
        })
    },
    [cultura, disc, dispatch, forcas, gestao, olhares]
  )

  useEffect(() => {
    loadGrupos()
  }, [loadGrupos])

  useEffect(() => {
    if (grupoSelecionado.grupo_id) {
      loadCulturaOrganizacional(grupoSelecionado.grupo_id)
      loadStatistics(grupoSelecionado.grupo_id)
      loadNPS(grupoSelecionado.grupo_id)
    }
  }, [
    grupoSelecionado?.grupo_id,
    loadCulturaOrganizacional,
    loadStatistics,
    loadMembros,
    loadNPS,
  ])
  function getGrupoSelecionadoFromLocalStorage() {
    const id = localStorage.getItem('id')
    const nome = localStorage.getItem('nome')
    return { id, nome }
  }

  function removeGrupoFromLocalStorage(id) {
    localStorage.removeItem('id')
    localStorage.removeItem('nome')
    handleChangeTime(id)
  }

  useEffect(() => {
    const grupo = getGrupoSelecionadoFromLocalStorage()
    if (grupo.id != null && grupo.id != grupoSelecionado.grupo_id) {
      setGrupoSelecionado({ grupo_id: grupo.id, grupo_nome: grupo.nome })
    }
    if (grupoSelecionado.grupo_id) {
      if (
        !_.isEqual(pessoasExcluidas, prevPessoasExcluidas) ||
        !_.isEqual(pessoasIncluidas, prevPessoasIncluidas) ||
        init
      ) {
        setInit(false)
        loadCultura(
          grupoSelecionado.grupo_id,
          pessoasExcluidas,
          pessoasIncluidas
        )
      }
    }
  }, [
    grupoSelecionado.grupo_id,
    init,
    loadCultura,
    pessoasExcluidas,
    pessoasIncluidas,
    prevPessoasExcluidas,
    prevPessoasIncluidas,
    setInit,
  ])

  const handleChangeTime = useCallback(
    (grupo_id) => {
      const grupo = grupos.find((grupo) => grupo.grupo_id === grupo_id)

      setGrupoSelecionado(grupo)
      setInit(true)
    },
    [grupos, setInit]
  )

  const setPessoaEscolhida = useCallback(
    async (pessoa) => {
      const newPessoasEscolhidas = await setPessoasEscolhidas([
        ...pessoasEscolhidas,
        pessoa,
      ])

      setMembros((state) =>
        membros.findIndex((p) => p.pes_id === pessoa.pes_id) >= 0
          ? [...state]
          : [...state, { ...pessoa, membro: false }]
      )

      loadCulturaPessoa(pessoa.pes_id, newPessoasEscolhidas)
    },
    [loadCulturaPessoa, membros, pessoasEscolhidas, setPessoasEscolhidas]
  )

  const handleIncludePessoaCulturaGrupo = useCallback((pes_id) => {
    setPessoasIncluidas((state) => [
      ...state.filter((p) => p !== pes_id),
      pes_id,
    ])
  }, [])

  const handleExcludePessoaCulturaGrupo = useCallback((pes_id) => {
    setPessoasIncluidas((state) => [...state.filter((p) => p !== pes_id)])
  }, [])

  const handlePessoaIncluida = useCallback(
    (pes_id) => {
      if (!pessoasIncluidas.find((p) => p === pes_id)) {
        handleIncludePessoaCulturaGrupo(pes_id)
      } else {
        handleExcludePessoaCulturaGrupo(pes_id)
      }
    },
    [
      handleExcludePessoaCulturaGrupo,
      handleIncludePessoaCulturaGrupo,
      pessoasIncluidas,
    ]
  )

  const handleRemoveMembro = useCallback((pessoa) => {
    setMembros((state) => state.filter((pes) => pes.pes_id !== pessoa.pes_id))
  }, [])

  const handleRemovePessoa = useCallback(
    (pessoa) => {
      if (membros.find((p) => p.pes_id === pessoa.pes_id)) {
        setPessoasEscolhidas((state) =>
          state.filter((p) => p.pes_id !== pessoa.pes_id)
        )

        setCultura((state) =>
          state.filter((cultura) => {
            return pessoa.lider && parseInt(pessoa.lider) === 1
              ? cultura.Label !== 'Líder'
              : cultura.Label !== pessoa.pes_nome
          })
        )

        setForcas((state) =>
          state.filter((forca) => {
            return pessoa.lider && parseInt(pessoa.lider) === 1
              ? forca.Label !== 'Líder'
              : forca.Label !== pessoa.pes_nome
          })
        )

        setGestao((state) =>
          state.filter((gestao) => {
            return pessoa.lider && parseInt(pessoa.lider) === 1
              ? gestao.Label !== 'Líder'
              : gestao.Label !== pessoa.pes_nome
          })
        )

        setOlhares((state) =>
          state.filter((olhar) => {
            return pessoa.lider && parseInt(pessoa.lider) === 1
              ? olhar.Label !== 'Líder'
              : olhar.Label !== pessoa.pes_nome
          })
        )

        setDisc((state) =>
          state.filter((disc) => {
            return pessoa.lider && parseInt(pessoa.lider) === 1
              ? disc.Label !== 'Líder'
              : disc.Label !== pessoa.pes_nome
          })
        )

        if (pessoasIncluidas.find((p) => p === pessoa.pes_id)) {
          handlePessoaIncluida(pessoa.pes_id)
        }
      }
    },
    [handlePessoaIncluida, membros, pessoasIncluidas, setPessoasEscolhidas]
  )

  const handleAddPessoa = useCallback(
    (pessoa) => {
      if (pessoasEscolhidas.find((p) => p.pes_id === pessoa.pes_id)) {
        handleRemovePessoa(pessoa)
        return
      }

      setPessoaEscolhida(pessoa)
    },
    [handleRemovePessoa, pessoasEscolhidas, setPessoaEscolhida]
  )

  const handleAddMembro = useCallback(
    (pes_id) => {
      const pessoa = membros.find((membro) => membro.pes_id === pes_id)
      handleAddPessoa(pessoa)
    },
    [handleAddPessoa, membros]
  )

  const handleChangeMembroVisibility = useCallback(
    (pes_id) => {
      const isMembroVisible = !pessoasExcluidas.find((p) => p === pes_id)

      if (isMembroVisible) {
        setPessoasExcluidas((state) => [
          ...state.filter((p) => p !== pes_id),
          pes_id,
        ])
      } else {
        setPessoasExcluidas((state) => [...state.filter((p) => p !== pes_id)])
      }
    },
    [pessoasExcluidas]
  )

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'pes_nome',
      align: 'left',
      width: '60%',
      render: (pes_nome, { pes_id, membro = true, lider }) =>
        membro && (!lider || parseInt(lider) === 0) ? (
          <>
            <Checkbox
              onClick={() => handleChangeMembroVisibility(pes_id)}
              checked={!pessoasExcluidas.find((pes) => pes === pes_id)}
            >
              {pes_nome}
            </Checkbox>
            {pessoasEscolhidas.find((pes) => pes.pes_id === pes_id) ? (
              <EyeOutlined onClick={() => handleAddMembro(pes_id)} />
            ) : (
              <EyeInvisibleOutlined onClick={() => handleAddMembro(pes_id)} />
            )}
          </>
        ) : (
          <TimeTableRow>
            {!lider || parseInt(lider) === 0 ? (
              <Checkbox
                onClick={() => handlePessoaIncluida(pes_id)}
                checked={Boolean(
                  pessoasIncluidas.find((pes) => pes === pes_id)
                )}
              >
                <span style={{ color: pink }}>{pes_nome}</span>
              </Checkbox>
            ) : (
              <span style={{ color: pink }}>{pes_nome}</span>
            )}

            {pessoasEscolhidas.find((pes) => pes.pes_id === pes_id) ? (
              <EyeOutlined onClick={() => handleAddMembro(pes_id)} />
            ) : (
              <EyeInvisibleOutlined onClick={() => handleAddMembro(pes_id)} />
            )}
            {(!lider || parseInt(lider) === 0) && (
              <DeleteOutlined
                onClick={() => {
                  handleRemovePessoa({ pes_id, pes_nome })
                  handleRemoveMembro({ pes_id, pes_nome })
                }}
              />
            )}
          </TimeTableRow>
        ),
    },
    {
      title: 'Líder',
      dataIndex: 'lider',
      align: 'center',
      width: '15%',

      render: (lider) =>
        lider &&
        parseInt(lider) !== 0 && (
          <StarFilled
            style={{
              color: '#ef5472',
            }}
          />
        ),
    },
    {
      title: 'Fit Empresa',
      align: 'center',
      dataIndex: ['fitCulturalEmpresa', 'geral'],
      width: '20%',

      render: (fit) =>
        fit
          ? parseFloat(fit) >= 0
            ? `${parseFloat(fit).toFixed(1)}%`
            : '0%'
          : '',
      sorter: (a, b) =>
        parseFloat(a.fitCulturalEmpresa ? a.fitCulturalEmpresa.geral : 0) -
        parseFloat(b.fitCulturalEmpresa ? b.fitCulturalEmpresa.geral : 0),
    },
    {
      title: 'Perfil Comp.',
      align: 'center',
      width: '15%',
      render: (membro) => {
        return <PerfilComportamentalMembroTime membroId={membro.pes_id} />
      },
    },
  ]

  const { culturaOrg } = culturaOrganizacional
  const { olharParaMudancas } = culturaOrg
  const { engajamento, proficiencia, velocidadeAdocao } = olharParaMudancas

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <Col span={24} style={{ marginTop: '8px', padding: '0' }}>
      <Row type="flex" gutter={8}>
        <Col
          lg={12}
          xxl={6}
          xs={24}
          sm={24}
          style={{
            flexGrow: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '8px',
          }}
        >
          <Row>
            <Col
              xs={24}
              md={24}
              sm={24}
              style={{ padding: '0', marginBottom: '8px' }}
            >
              <ArquetiposCulturais
                cultura={cultura}
                loadingCultura={loadingCultura}
                showModal={showModal}
              />
            </Col>
          </Row>

          <ModalGraficoArquetiposCulturais
            cultura={cultura}
            handleCancel={handleCancel}
            handleOk={handleOk}
            isModalVisible={isModalVisible}
          />

          <ColFill xs={24} md={24} sm={24} style={noPadding}>
            <PerfilComportamental disc={disc} loadingCultura={loadingCultura} />
          </ColFill>
        </Col>

        <Col
          lg={12}
          xxl={8}
          xs={24}
          sm={24}
          style={{
            flexGrow: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '8px',
          }}
        >
          <ColFill xs={24} md={24} sm={24} style={{ padding: '0' }}>
            <Time
              grupoId={grupoSelecionado.grupo_id}
              loadingMembros={loadingMembros}
              grupos={grupos}
              loadingGrupos={loadingGrupos}
              nps={nps}
              statistics={statistics}
              columns={columns}
              grupoSelecionado={grupoSelecionado}
              handleChangeTime={handleChangeTime}
              setShowModalAddCandidato={setShowModalAddCandidato}
              setShowModalAddColaborador={setShowModalAddColaborador}
              membros={membros}
              setMembros={setMembros}
              removeFromStorage={removeGrupoFromLocalStorage}
            />
          </ColFill>
        </Col>

        <Col
          lg={24}
          xxl={10}
          xs={24}
          sm={24}
          style={{
            flexGrow: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '8px',
          }}
        >
          <Row>
            <Col sm={24} xs={24} style={{ padding: '0', marginBottom: '8px' }}>
              <ForcasEstrategicas
                forcas={forcas}
                loadingCultura={loadingCultura}
              />
            </Col>
          </Row>

          <ColFill sm={24} xs={24} style={noPadding}>
            <IndicadoresMudanca
              engajamento={engajamento}
              loadingCulturaOrg={loadingCulturaOrg}
              proficiencia={proficiencia}
              velocidadeAdocao={velocidadeAdocao}
            />
          </ColFill>
        </Col>
      </Row>

      <ModalAdicionarCandidato
        handleAddPessoa={handleAddPessoa}
        setShowModalAddCandidato={setShowModalAddCandidato}
        showModalAddCandidato={showModalAddCandidato}
      />

      <ModalAdicionarColaborador
        grupoId={grupoSelecionado.grupo_id}
        handleAddPessoa={handleAddPessoa}
        setShowModalAddColaborador={setShowModalAddColaborador}
        showModalAddColaborador={showModalAddColaborador}
      />
    </Col>
  )
}

export default VisaoTimes
