// Redux types
import * as constants from '../constants/constantsLoginColaborador'

export const postLogin = ({ login, senha }) => {
  return {
    type: constants.LOGIN_POST,
    api: {
      method: 'post',
      url: constants.URL_LOGIN,
      data: {
        login,
        senha,
      },
    },
  }
}
