import React from 'react'

import { Form, Input, InputNumber } from 'antd'

export default function NovaSla({ onFinish, readOnly = false }) {
  const [form] = Form.useForm()

  return (
    <Form layout="vertical" id="nova-sla" onFinish={onFinish} form={form}>
      <Form.Item
        label="Nome"
        name="nome"
        rules={[
          {
            required: true,
            message: 'Por favor, informe um nome para o sla.',
          },
        ]}
      >
        <Input type="text" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label="Quantidade de dias"
        name="dias"
        rules={[
          {
            required: true,
            message: 'Por favor, informe uma quantidade de dias',
          },
        ]}
      >
        <InputNumber
          readOnly={readOnly}
          min={0}
          max={10000}
          withoutMargin
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item
        label="Descrição"
        name="descricao"
        rules={[
          {
            required: true,
            message: 'Por favor, informe uma descrição para o sla.',
          },
        ]}
      >
        <Input type="text" style={{ width: '100%' }} />
      </Form.Item>
    </Form>
  )
}
