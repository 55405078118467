import { connect } from 'react-redux'
import * as actions from './actions/actionsColaboradoresInativos'
import { getTimes, postTime } from '../times/actions/actionsTimes'
import { getCargos, postCargo } from '../cargos/actions/actionsCargos'
import { excluirPerfil, desativarPerfil } from '../perfil/actions/actionsPerfil'
import { listFiliais } from '../ConfigFiliais/actions/actions'
import { sendValoresOrganizacionais } from '../gestaoPesquisasColaboradores/actions/actionsGestaoPesquisas'

// Components
import ColaboradoresInativosController from './ColaboradoresInativosController'

// Actions

const mapStateToProps = ({ colaboradores, times, cargos }) => {
  return {
    listaColaboradoresInativos: colaboradores.listaColaboradoresInativos,
    times: times.list,
    cargos: cargos.list,
  }
}

export default connect(mapStateToProps, {
  ...actions,
  getTimes,
  getCargos,
  postTime,
  postCargo,
  excluirPerfil,
  sendValoresOrganizacionais,
  desativarPerfil,
  listFiliais,
})(ColaboradoresInativosController)
