import React, { useMemo } from 'react'
import { injectIntl } from 'react-intl'
import { Form } from '@ant-design/compatible'
import { CaretRightOutlined } from '@ant-design/icons'
import '@ant-design/compatible/assets/index.css'
import { Row, Col } from 'antd'
import { sorterString } from '../../../../utils/utils'

// Components
import Input, { MaskedInput } from '../../../../components/input/Input'
import Select, { Option } from '../../../../components/select/Select'

// CSS
import './NovoGestor.css'

// Constants

function NovoGestor({
  intl,
  handleChangeModal,
  listaPesos,
  listaTimes,
  listaFiliais,
  cpf,
  nome,
  email,
  sexo,
  cargo,
  peso,
  error,
}) {
  const filiaisSorted = useMemo(() => {
    const t = listaFiliais.sort((a, b) => sorterString(a, b, 'nome'))
    return t
  }, [listaFiliais])

  const childrenRender = (times, level = 0) => {
    return times.map((time) => [
      <Option value={time.grupo_id}>
        {_.times(level, () => (
          <>&emsp;</>
        ))}
        <CaretRightOutlined style={{ opacity: '0.8', fontSize: '12px' }} />{' '}
        {time.grupo_nome}
      </Option>,
      childrenRender(time.children, level + 1),
    ])
  }

  return (
    <div className="novo-gestor">
      <Form layout="vertical">
        <Row gutter={20}>
          <Col md={10}>
            <Form.Item
              label="CPF"
              validateStatus={error.cpf ? 'error' : ''}
              help={error.cpf || ''}
              required
            >
              <MaskedInput
                value={cpf}
                mask="999.999.999-99"
                name="cpf"
                onChange={handleChangeModal}
                withoutMargin
              />
            </Form.Item>
          </Col>
          <Col md={14}>
            <Form.Item
              label="Nome"
              validateStatus={error.nome ? 'error' : ''}
              help={error.nome || ''}
              required
            >
              <Input
                value={nome}
                type="text"
                placeholder="Digite o nome"
                name="nome"
                onChange={handleChangeModal}
                withoutMargin
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col md={10}>
            <Form.Item
              label="Sexo"
              validateStatus={error.sexo ? 'error' : ''}
              help={error.sexo || ''}
            >
              <Select
                value={sexo}
                placeholder="Insira o sexo"
                name="sexo"
                onChange={(value) =>
                  handleChangeModal({ target: { name: 'sexo', value } })
                }
                style={{ width: '100%' }}
                withoutMargin
              >
                <Option value="M">Masculino</Option>
                <Option value="F">Feminino</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={14}>
            <Form.Item
              label="E-mail"
              validateStatus={error.email ? 'error' : ''}
              help={error.email || ''}
              required
            >
              <Input
                value={email}
                type="text"
                placeholder="Digite o e-mail"
                name="email"
                onChange={handleChangeModal}
                withoutMargin
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col md={10}>
            <Form.Item label="Peso de decisão">
              <Select
                value={peso}
                placeholder="Selecione o peso"
                name="peso"
                onChange={(value) =>
                  handleChangeModal({ target: { name: 'peso', value } })
                }
                style={{ width: '100%' }}
                withoutMargin
              >
                {listaPesos.map((peso) => (
                  <Option value={peso.valor}>{peso.descricao}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={14}>
            <Form.Item label="Cargo">
              <Input
                value={cargo}
                type="text"
                placeholder="Digite o cargo"
                name="cargo"
                onChange={handleChangeModal}
                withoutMargin
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col md={24}>
            <Form.Item label="Time">
              <Select
                placeholder="Selecione o time"
                name="grupo"
                onChange={(evt) =>
                  handleChangeModal({ target: { name: 'grupo', value: evt } })
                }
                withoutMargin
              >
                {childrenRender(listaTimes)}
              </Select>
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item label="Filial">
              <Select
                placeholder="Selecione a filial"
                name="filial"
                onChange={(evt) =>
                  handleChangeModal({
                    target: { name: 'filial', value: evt },
                  })
                }
                withoutMargin
              >
                {filiaisSorted.map(({ nome, filial_id }) => (
                  <Option value={filial_id} key={filial_id}>
                    {nome}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default injectIntl(NovoGestor)
