import { connect } from 'react-redux'
import * as actions from './actions/actionsTimes'

// Components
import TimesController from './TimesController'

// Actions

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, { ...actions })(TimesController)
