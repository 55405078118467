import React from 'react'

import { Card, Row, Col, Empty, InputNumber, Button } from 'antd'

import { DonutChart } from 'bizcharts'

import { noPadding } from '../../../../utils/inlineStyles'

import { LimitesContainer } from './styles'

function LimiteCadastro({
  limiteColaboradores,
  limiteCandidatos,
  limiteUtilizadoColaboradores,
  novoLimiteColaboradores,
  limiteUtilizadoCandidatos,
  novoLimiteCandidatos,
  colaboradores,
  candidatos,
  conta_old,
  loading,
  handleNovoLimiteCandidatos,
  handleNovoLimiteColaboradores,
  handleSolicitarNovoLimite,
  solicitado,
}) {
  return (
    <Col span={24} style={noPadding}>
      <Row gutter={16}>
        <Col xs={24} sm={24} lg={conta_old === 0 ? 12 : 24}>
          <Card
            title="Cadastro de Colaboradores"
            loading={loading}
            bodyStyle={{ padding: '4px' }}
          >
            {colaboradores ? (
              <DonutChart
                data={colaboradores}
                renderer="canvas"
                forceFit
                title={{
                  visible: true,
                  text: `Limite contratado de colaboradores cadastrados: ${limiteColaboradores}`,
                }}
                radius={0.8}
                padding="auto"
                angleField="value"
                colorField="type"
                statistic={{
                  visible: true,
                  content: {
                    name: 'Utilizado',
                    value: `${(
                      (limiteUtilizadoColaboradores / limiteColaboradores) *
                      100
                    ).toFixed(1)}%`,
                  },
                }}
                pieStyle={{ stroke: 'white', lineWidth: 5 }}
              />
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
        {conta_old === 0 && (
          <Col xs={24} sm={24} lg={12}>
            <Card
              title="Cadastro de Candidatos"
              loading={loading}
              bodyStyle={{ padding: '4px' }}
            >
              {candidatos ? (
                <DonutChart
                  data={candidatos}
                  renderer="canvas"
                  forceFit
                  title={{
                    visible: true,
                    text: `Limite contratado de candidatos cadastrados: ${limiteCandidatos}`,
                  }}
                  radius={0.8}
                  padding="auto"
                  angleField="value"
                  colorField="type"
                  statistic={{
                    visible: true,
                    content: {
                      name: 'Utilizado',
                      value: `${(
                        (limiteUtilizadoCandidatos / limiteCandidatos) *
                        100
                      ).toFixed(1)}%`,
                    },
                  }}
                  pieStyle={{ stroke: 'white', lineWidth: 5 }}
                />
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
        )}
        <Col sm={24} xs={24} style={{ marginTop: '16px' }}>
          <Card
            title="Solicite um aumento em seus limites de cadastro informando os novos limites abaixo."
            loading={loading}
          >
            <LimitesContainer>
              <div>
                <span>Limite de Colaboradores: </span>
                <InputNumber
                  defaultValue={limiteColaboradores || 0}
                  value={novoLimiteColaboradores}
                  min={limiteColaboradores || 0}
                  max={500000}
                  onChange={(value) => handleNovoLimiteColaboradores(value)}
                  size="large"
                />
              </div>
              <div>
                <span>Limite de Candidatos: </span>
                <InputNumber
                  defaultValue={limiteCandidatos || 0}
                  value={novoLimiteCandidatos}
                  min={limiteCandidatos || 0}
                  max={500000}
                  onChange={(value) => handleNovoLimiteCandidatos(value)}
                  size="large"
                />
              </div>
              <div>
                <Button
                  type="primary"
                  size="large"
                  disabled={loading || solicitado}
                  onClick={() => handleSolicitarNovoLimite()}
                >
                  SOLICITAR
                </Button>
              </div>
            </LimitesContainer>
          </Card>
        </Col>
      </Row>
    </Col>
  )
}

export default LimiteCadastro
