import { Select, Form, Input, Button } from 'antd'
import { toast } from 'react-toastify'
import { postAvaliadores } from '../../Api/api'

const { Option } = Select

const App = (props) => {
  function updateValues() {
    props.update()
    props.closeModal()
    toast.success('Avaliador cadastrado com sucesso')
  }
  const onFinish = async (values) => {
    let response
    if (props?.cultura.cultura_tipo === 2) {
      response = await postAvaliadores(
        props?.cultura.cultura_id,
        props?.cultura.subcultura_id,
        values
      )
      if (response.success) updateValues()
    } else {
      response = await postAvaliadores(props?.cultura.cultura_id, null, values)
      if (response.success) updateValues()
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Nome"
        name="nome"
        rules={[
          {
            required: true,
            message: 'Por favor insira o seu nome',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Por favor insira seu email',
          },
        ]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item name="peso" label="Peso" rules={[{ required: false }]}>
        <Select placeholder="Informe o peso" allowClear>
          <Option value={1}>Baixo</Option>
          <Option value={2}>Médio</Option>
          <Option value={3}>Alto</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn_purple newCultureBtn floatRight"
        >
          Cadastrar
        </Button>
      </Form.Item>
    </Form>
  )
}

export default App
