import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { Modal, Row, Col, Card } from 'antd'

// Layouts
import ContentLayout from '../../layouts/contentLayout/ContentLayout'

// Components
import TableCargos from './components/tableCargos/TableCargos'
import AdicionarNovoCargo from './components/adicionarNovoCargo/AdicionarNovoCargo'
import AtividadeCargo from './components/adicionarAtividade'
import FerramentaCargo from './components/adicionarFerramenta'
import TecnicaCargo from './components/adicionarTecnica'
import RequisitoCargo from './components/adicionarRequisito'
import CadastroCargo from './components/CadastroCargo'

// CSS
import './Cargos.css'

// Constants
import { modals } from './constants/constantsCargos'

function CargosView({
  intl,
  openModal,
  rowSelection,
  showModals,
  closeModal,
  onModalSubmit,
  handleChangeModal,
  modalStates,
  dataSource,
  handleDelete,
  editModal,
  getColumnSearchProps,
  handleChangeCheckbox,
  error,
  cargoToClone,
  handleCloneCargo,
  openModalNovoCargo,
  closeModalNovoCargo,
  openModalEditCargo,
  closeModalEditCargo,
  handleSubmitModalNovoCargo,
  handleSubmitModalEditCargo,
  showModalEditCargo,
  showModalNovoCargo,
  currentCargoData,
  openModalCloneCargo,
  closeModalCloneCargo,
  showModalCloneCargo,
  frequencia,
  ferramenta,
  formacao,
  showModalAtividade,
  showModalFerramenta,
  showModalRequisito,
  showModalTecnica,
  closeModalAtividade,
  closeModalFerramenta,
  closeModalRequisito,
  closeModalTecnica,
  handleSubmitModalNewAtividade,
  handleSubmitModalNewFerramenta,
  handleSubmitModalNewRequisito,
  handleSubmitModalNewTecnica,
  handleSubmitModalNovoCargoClone,
}) {
  return (
    <>
      <Helmet>
        <title>TeamHub | Cargos</title>
      </Helmet>
      <ContentLayout
        className="cargos"
        header={{
          title: 'Cargos',
          subtitle: 'Gerencie os cargos da sua empresa',
          breadcrumb: [{ name: 'Home', path: '/' }, { name: 'Cargos' }],
        }}
      >
        <div className="cargos__body">
          <Row>
            <Col md={24}>
              <Card className="cargos__card" type="inner">
                <TableCargos
                  openModal={openModal}
                  closeModal={closeModal}
                  onModalSubmit={onModalSubmit}
                  showModals={showModals}
                  modalStates={modalStates}
                  handleChangeModal={handleChangeModal}
                  dataSource={dataSource}
                  rowSelection={rowSelection}
                  handleDelete={handleDelete}
                  editModal={editModal}
                  getColumnSearchProps={getColumnSearchProps}
                  cargoToClone={cargoToClone}
                  handleCloneCargo={handleCloneCargo}
                  openModalNovoCargo={openModalNovoCargo}
                  openModalEditCargo={openModalEditCargo}
                  openModalCloneCargo={openModalCloneCargo}
                />
              </Card>
            </Col>
          </Row>
        </div>
        <CadastroCargo
          intl={intl}
          type="edit"
          visible={showModalNovoCargo}
          closeModal={() => closeModalNovoCargo()}
          onSubmit={handleSubmitModalNovoCargo}
          frequencia={frequencia}
          ferramenta={ferramenta}
          formacao={formacao}
        />
        <Modal
          title="NOVO CARGO"
          // visible={}
          cancelText="CANCELAR"
          okText="SALVAR"
          onCancel={() => closeModalNovoCargo()}
          destroyOnClose
          okButtonProps={{
            form: 'novo-cargo-submit',
            key: 'submit',
            htmlType: 'submit',
          }}
          centered
          width={650}
        >
          <AdicionarNovoCargo
            showModalNovoCargo={showModalNovoCargo}
            onFinish={handleSubmitModalNovoCargo}
          />
        </Modal>
        <Modal
          title="EDITAR CARGO"
          visible={showModalEditCargo}
          cancelText="CANCELAR"
          okText="SALVAR"
          onCancel={() => closeModalEditCargo()}
          destroyOnClose
          okButtonProps={{
            form: 'novo-cargo-submit',
            key: 'submit',
            htmlType: 'submit',
          }}
          centered
        >
          <AdicionarNovoCargo
            onFinish={handleSubmitModalEditCargo}
            initialValues={currentCargoData}
          />
        </Modal>
        <Modal
          title="CLONAR CARGO"
          visible={showModalCloneCargo}
          cancelText="CANCELAR"
          okText="SALVAR"
          onCancel={() => closeModalCloneCargo()}
          destroyOnClose
          okButtonProps={{
            form: 'novo-cargo-submit',
            key: 'submit',
            htmlType: 'submit',
          }}
          centered
        >
          <AdicionarNovoCargo
            onFinish={handleSubmitModalNovoCargoClone}
            initialValues={cargoToClone}
          />
        </Modal>
        <Modal
          title="ADICIONAR UMA ATIVIDADE PARA O NOVO CARGO"
          visible={showModalAtividade}
          cancelText="CANCELAR"
          onCancel={() => closeModalAtividade()}
          okText="SALVAR"
          destroyOnClose
          okButtonProps={{
            form: 'cadastro-atividade',
            key: 'submit',
            htmlType: 'submit',
          }}
          centered
          width={650}
        >
          <AtividadeCargo
            onFinish={handleSubmitModalNewAtividade}
            frequencia={frequencia}
          />
        </Modal>
        <Modal
          title="ADICIONAR UMA FERRAMENTA PARA O NOVO CARGO"
          visible={showModalFerramenta}
          cancelText="CANCELAR"
          onCancel={() => closeModalFerramenta()}
          okText="SALVAR"
          destroyOnClose
          okButtonProps={{
            form: 'cadastro-ferramenta',
            key: 'submit',
            htmlType: 'submit',
          }}
          centered
          width={650}
        >
          <FerramentaCargo
            onFinish={handleSubmitModalNewFerramenta}
            ferramenta={ferramenta}
          />
        </Modal>
        <Modal
          title="ADICIONAR UM REQUISITO PARA O NOVO CARGO"
          visible={showModalRequisito}
          cancelText="CANCELAR"
          onCancel={() => closeModalRequisito()}
          okText="SALVAR"
          destroyOnClose
          okButtonProps={{
            form: 'cadastro-requisito',
            key: 'submit',
            htmlType: 'submit',
          }}
          centered
          width={650}
        >
          <RequisitoCargo
            formacao={formacao}
            onFinish={handleSubmitModalNewRequisito}
          />
        </Modal>
        <Modal
          title="ADICIONAR UMA TÉCNICA PARA O NOVO CARGO"
          visible={showModalTecnica}
          cancelText="CANCELAR"
          onCancel={() => closeModalTecnica()}
          okText="SALVAR"
          destroyOnClose
          okButtonProps={{
            form: 'cadastro-tecnica',
            key: 'submit',
            htmlType: 'submit',
          }}
          centered
          width={650}
        >
          <TecnicaCargo onFinish={handleSubmitModalNewTecnica} />
        </Modal>
        {modals.map(
          ({ title, cancelText, okText, id, width, children: Children }) => (
            <Modal
              visible={showModals[id]}
              title={title}
              cancelText={cancelText}
              okText={okText}
              onCancel={() => closeModal(id)}
              onOk={() => onModalSubmit(id)}
              width={width}
              destroyOnClose
              key={id}
            >
              <Children
                handleChangeModal={(event) => handleChangeModal(id, event)}
                handleChangeCheckbox={(event) =>
                  handleChangeCheckbox(id, event)
                }
                dataSource={dataSource}
                modalState={modalStates[id]}
                error={error}
                {...modalStates[id]}
              />
            </Modal>
          )
        )}
      </ContentLayout>
    </>
  )
}

export default injectIntl(CargosView)
