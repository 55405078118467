import React, { useState } from 'react'
import _ from 'lodash'
import { injectIntl } from 'react-intl'

import {
  CaretRightOutlined,
  CalendarOutlined,
  DotChartOutlined,
  ExclamationCircleFilled,
  GiftOutlined,
  HomeOutlined,
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  StarFilled,
  TeamOutlined,
  UserOutlined,
  WomanOutlined,
  ManOutlined,
  UserSwitchOutlined,
  SolutionOutlined,
} from '@ant-design/icons'

import { Icon as LegacyIcon } from '@ant-design/compatible'

import {
  Row,
  Col,
  Avatar,
  Divider,
  Tag,
  Tabs,
  Timeline,
  Statistic,
  Button,
  Popconfirm,
  Modal,
  DatePicker,
  TreeSelect,
  Tooltip,
} from 'antd'

// Components
import Select, { Option } from '../../components/select'
import TabHome from './components/tabHome'
import DesligamentoModal from './components/DesligamentoModal'
import TabPesquisas from './components/tabPesquisas'
import TabProcessoSeletivo from './components/TabProcessoSeletivo/index'
import TabTimesCargos from './components/tabTimesCargos'
import EditarColaborador from './components/editarColaborador/AdicionarNovoColaborador'
import EnviarPesquisa from '../../components/enviarPesquisa/EnviarPesquisa'
import Feedback from '../feedback'
import Pdi from '../Pdi'
import FitCulturalPerfil from '../../components/FitCulturalPerfil'
import TabDetalhes from './components/tabDetalhes'
import { ReactComponent as Svg } from '../../assets/onu_pcd.svg'

// CSS
import './Perfil.css'

// Services
import { isLider } from '../../services/auth'

// Constants
import { styleColors, sorterString } from '../../utils/utils'
import ProcessoSeletivo from '../recrutamentoSelecao/containers/ProcessoSeletivo'

// Layout
import ContentLayout from '../../layouts/contentLayout/ContentLayout'

const { TreeNode } = TreeSelect

const { pink } = styleColors

const { TabPane } = Tabs

const childrenRender = (times, level = 0) => {
  return times.map((time) => [
    <TreeNode value={time.grupo_id} title={time.grupo_nome}>
      {childrenRender(time.children, level + 1)}
    </TreeNode>,
  ])
}

function PerfilView({
  intl,
  handleChange,
  info,
  showModal,
  openModal,
  closeModal,
  onModalSubmit,
  timesDisponiveis,
  cargosDisponiveis,
  ativarPerfil,
  desativarPerfil,
  excluirPerfil,
  vetores,
  chartVetores,
  percepcaoAmbiente,
  vetorComportamental,
  isCandidato,
  efetivaCandidato,
  candidato,
  handleEfetivar,
  handleChangeModal,
  handleChangeCheckbox,
  handleChangeLider,
  cargos,
  sendVetorComportamental,
  cancelVetorComportamental,
  sendPercepcao,
  cancelPercepcao,
  getColumnSearchProps,
  deleteCargoPessoa,
  deleteTimePessoa,
  getVetorComportamental,
  getPercepcaoAmbiente,
  setActiveTab,
  valoresOrganizacionais,
  getValoresOrganizacionais,
  cancelValoresOrganizacionais,
  cultura,
  forcas,
  olhares,
  gestao,
  disc,
  culturaEmpresa,
  forcasEmpresa,
  olharesEmpresa,
  gestaoEmpresa,
  discEmpresa,
  fitCultural,
  culturaOrg,
  culturaOrgEmpresa,
  valoresOrgEmpresa,
  handleSaveCargo,
  handleSaveTime,
  media,
  filiais,
  processosSeletivos,
  processos,
  processoSelected,
  processoTriagemSelected,
  processosTriagem,
  handleCreatePsLog,
  handleDeletePsLog,
  handleChangePsSelected,
  logs,
  observacao,
  handleChangeObservacao,
}) {
  const lider = isLider()

  return (
    <ContentLayout
      header={{
        title: 'Perfil do Colaborador',
        subtitle: 'Informações sobre o colaborador cadastrado',
        breadcrumb: [
          { name: 'Home', path: '/' },
          { name: 'Colaboradores', path: '/colaboradores' },
          { name: 'Perfil' },
        ],
      }}
    >
      <div className="perfil">
        <Row gutter={20}>
          <Col md={{ span: 6 }} xs={{ span: 24 }} sm={{ span: 24 }}>
            <div className="perfil__info">
              <div className="perfil__info__user">
                <Avatar
                  size={150}
                  icon={<UserOutlined />}
                  className={`perfil__info__user__avatar${
                    info.ativo === 0 ? ' desativado' : ''
                  }`}
                />
                <h2
                  className={`perfil__info__user__nome${
                    info.ativo === 0 ? ' desativado' : ''
                  }`}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {info.nome}
                  {info.pcd === 1 && (
                    <Tooltip
                      title={info.pcd_tipo && `Tipo de PCD: ${info.pcd_tipo}`}
                    >
                      <Tag color="blue" style={{ marginLeft: 8 }}>
                        <Svg
                          height="22"
                          width="22"
                          style={{ display: 'flex', alignItems: 'center' }}
                        />
                      </Tag>
                    </Tooltip>
                  )}
                </h2>
                <h3
                  className={`perfil__info__user__cargo${
                    info.ativo === 0 ? ' desativado' : ''
                  }`}
                >
                  {info.ativo === 0 ? (
                    <span className="desativado">Desligado - </span>
                  ) : (
                    ''
                  )}
                  {info.cargos.map(
                    (cargo) =>
                      cargo.status === '1' && (
                        <>
                          <span key={cargo.cargo_id}>{cargo.cargo_nome}</span>
                          <br />
                        </>
                      )
                  )}
                </h3>

                <div className="perfil__info__user__dados">
                  <span>
                    <MailOutlined />
                    <p>{info.email}</p>
                  </span>
                  <span>
                    <PhoneOutlined />
                    <p>{info.telefone}</p>
                  </span>
                  <span>
                    <IdcardOutlined />
                    <p>{info.documento}</p>
                  </span>
                  <span>
                    <GiftOutlined />
                    <p>
                      {info.nascimento} - {info.idadetotal}{' '}
                      {info.idade ? '(anos)' : ''}
                    </p>
                  </span>
                  <span>
                    <CalendarOutlined />
                    <p>
                      {info.admissao} - {info.tempoempresa}{' '}
                      {info.companyTime || info.companyTime === 0
                        ? '(anos)'
                        : ''}{' '}
                    </p>
                  </span>
                  <span>
                    <span>
                      {info.sexo === 'Masculino' ? (
                        <ManOutlined />
                      ) : (
                        <WomanOutlined />
                      )}
                      <p>{info.sexo}</p>
                    </span>

                    {!lider && (
                      <a onClick={() => openModal('editarColaborador')}>
                        EDITAR
                      </a>
                    )}
                  </span>
                </div>
              </div>
              {!isCandidato && (
                <>
                  <Divider dashed />
                  <div className="perfil__info__tags">
                    <h3>Times</h3>

                    {info.times
                      .filter((time) => time.data_saida === null)
                      .map((tag) => (
                        <Tag
                          key={tag.grupo_id}
                          closable={false}
                          className="perfil__info__tag"
                        >
                          {tag.lider === 1 && (
                            <StarFilled className="perfil__info__tag__lider" />
                          )}

                          {tag.grupo_nome}
                        </Tag>
                      ))}
                    {!lider && (
                      <Tag
                        onClick={() => openModal('alocarTime')}
                        className="perfil__info__tag--adicionar"
                        style={{ cursor: 'pointer' }}
                      >
                        <PlusOutlined />
                      </Tag>
                    )}
                  </div>
                </>
              )}
              <Divider dashed />
              {/* <div className="perfil__info__acessos">
							<h3>Útimos Acessos</h3>

							<Timeline>
								<Timeline.Item>Acessado em 08/08/2017 às 18:54</Timeline.Item>
								<Timeline.Item>Acessado em 08/08/2017 às 18:54</Timeline.Item>
								<Timeline.Item>Acessado em 08/08/2017 às 18:54</Timeline.Item>
								<Timeline.Item>Acessado em 08/08/2017 às 18:54</Timeline.Item>
							</Timeline>
						</div>

						<Divider dashed /> */}

              <Row gutter={20}>
                <Col xxl={12}>
                  {!lider && (
                    <>
                      <Button
                        className={`perfil__info__login__btn${
                          info.ativo === 1 && !isCandidato ? '--desativar' : ''
                        }`}
                        type="primary"
                        icon={
                          <LegacyIcon
                            type={
                              info.ativo === 1 && !isCandidato
                                ? 'user-delete'
                                : 'user-add'
                            }
                          />
                        }
                        style={{ marginTop: '10px' }}
                        block
                        onClick={() =>
                          isCandidato
                            ? handleEfetivar(info.id)
                            : info.ativo === 1
                            ? openModal('desligamentoModal')
                            : ativarPerfil({ id: info.id })
                        }
                      >
                        {isCandidato
                          ? 'EFETIVAR'
                          : info.ativo === 1
                          ? 'DESLIGAR'
                          : 'RELIGAR'}
                      </Button>
                      <Modal
                        id="desligamentoModal"
                        title="Desligar Colaborador?"
                        visible={showModal.desligamentoModal}
                        cancelText="CANCELAR"
                        okText="SALVAR"
                        onCancel={() => closeModal('desligamentoModal')}
                        onOk={() => onModalSubmit('desligamentoModal')}
                        destroyOnClose
                        icon={
                          <ExclamationCircleFilled
                            style={{ color: '#ef5472' }}
                          />
                        }
                      >
                        <span>Confirme a data de desligamento: </span>
                        <DatePicker
                          onChange={(date) =>
                            handleChange(
                              {
                                target: {
                                  name: 'datademissao',
                                  value: date,
                                },
                              },
                              'desligamentoModal'
                            )
                          }
                          allowClear
                        />
                      </Modal>
                    </>
                  )}
                </Col>
                <Col xxl={12}>
                  {!lider && (
                    <Popconfirm
                      title="Excluir Colaborador?"
                      onConfirm={excluirPerfil}
                      okText="Sim"
                      cancelText="Não"
                      icon={
                        <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                      }
                    >
                      <Button style={{ marginTop: '10px' }} block>
                        EXCLUIR
                      </Button>
                    </Popconfirm>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={{ span: 18 }} xs={{ span: 24 }} sm={{ span: 24 }}>
            <Row gutter={20}>
              <Col span={24}>
                <div className="perfil__data__card">
                  <div className="perfil__data__title">
                    <span className="">ÚLTIMO PERFIL</span>
                  </div>
                  <Divider
                    type="vertical"
                    className="perfil__data__item__divider"
                  />
                  <div className="perfil__data__item">
                    <Statistic
                      title="Perfil"
                      value={`${
                        (vetores.length > 0 &&
                          vetores[vetores.length - 1].primario) ||
                        ' '
                      }${
                        (vetores.length > 0 &&
                          vetores[vetores.length - 1].secundario) ||
                        ' '
                      }`}
                    />
                  </div>
                  <Divider
                    type="vertical"
                    className="perfil__data__item__divider"
                  />
                  <div className="perfil__data__item">
                    <Statistic
                      title="Dominância"
                      value={
                        (vetores.length > 0 && vetores[vetores.length - 1].D) ||
                        0
                      }
                      suffix="%"
                    />
                  </div>
                  <Divider
                    type="vertical"
                    className="perfil__data__item__divider"
                  />
                  <div className="perfil__data__item">
                    <Statistic
                      title="Influência"
                      value={
                        (vetores.length > 0 && vetores[vetores.length - 1].I) ||
                        0
                      }
                      suffix="%"
                    />
                  </div>
                  <Divider
                    type="vertical"
                    className="perfil__data__item__divider"
                  />
                  <div className="perfil__data__item">
                    <Statistic
                      title="Estabilidade"
                      value={
                        (vetores.length > 0 && vetores[vetores.length - 1].S) ||
                        0
                      }
                      suffix="%"
                    />
                  </div>
                  <Divider
                    type="vertical"
                    className="perfil__data__item__divider"
                  />
                  <div className="perfil__data__item">
                    <Statistic
                      title="Conformidade"
                      value={
                        (vetores.length > 0 && vetores[vetores.length - 1].C) ||
                        0
                      }
                      suffix="%"
                    />
                  </div>
                  <Divider
                    type="vertical"
                    className="perfil__data__item__divider"
                  />
                </div>
              </Col>

              <Col span={24} style={{ marginTop: 20 }}>
                <div className="perfil__data__card">
                  <div className="perfil__data__title">
                    <span>MÉDIA</span>
                  </div>
                  <Divider
                    type="vertical"
                    className="perfil__data__item__divider"
                  />
                  <div className="perfil__data__item">
                    <Statistic
                      title="Perfil"
                      value={`${media.primario}${media.secundario}`}
                    />
                  </div>
                  <Divider
                    type="vertical"
                    className="perfil__data__item__divider"
                  />
                  <div className="perfil__data__item">
                    <Statistic title="Dominância" value={media.D} suffix="%" />
                  </div>
                  <Divider
                    type="vertical"
                    className="perfil__data__item__divider"
                  />
                  <div className="perfil__data__item">
                    <Statistic title="Influência" value={media.I} suffix="%" />
                  </div>
                  <Divider
                    type="vertical"
                    className="perfil__data__item__divider"
                  />
                  <div className="perfil__data__item">
                    <Statistic
                      title="Estabilidade"
                      value={media.S}
                      suffix="%"
                    />
                  </div>
                  <Divider
                    type="vertical"
                    className="perfil__data__item__divider"
                  />
                  <div className="perfil__data__item">
                    <Statistic
                      title="Conformidade"
                      value={media.C}
                      suffix="%"
                    />
                  </div>
                  <Divider
                    type="vertical"
                    className="perfil__data__item__divider"
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="perfil__tabs">
                  <Tabs
                    defaultActiveKey="1"
                    onChange={(activeKey) => setActiveTab(activeKey)}
                  >
                    <TabPane
                      tab={
                        <span>
                          <HomeOutlined />
                          Home
                        </span>
                      }
                      key="1"
                    >
                      <TabHome
                        chartVetores={chartVetores}
                        vetorComportamental={vetorComportamental}
                        getColumnSearchProps={getColumnSearchProps}
                        sendVetorComportamental={sendVetorComportamental}
                        sendPercepcao={sendPercepcao}
                        cancelPercepcao={cancelPercepcao}
                        cancelVetorComportamental={cancelVetorComportamental}
                        getVetorComportamental={getVetorComportamental}
                      />
                    </TabPane>
                    <TabPane tab={<span>Detalhes do Colaborador</span>} key="2">
                      <TabDetalhes info={info} />
                    </TabPane>
                    {!isCandidato && (
                      <TabPane
                        tab={
                          <span>
                            <DotChartOutlined />
                            Pesquisas
                          </span>
                        }
                        key="3"
                      >
                        <TabPesquisas
                          openModal={openModal}
                          percepcaoAmbiente={percepcaoAmbiente}
                          vetorComportamental={vetorComportamental}
                          valoresOrganizacionais={valoresOrganizacionais}
                          getColumnSearchProps={getColumnSearchProps}
                          sendVetorComportamental={sendVetorComportamental}
                          sendPercepcao={sendPercepcao}
                          cancelVetorComportamental={cancelVetorComportamental}
                          cancelPercepcao={cancelPercepcao}
                          id={info.id}
                          getVetorComportamental={getVetorComportamental}
                          getPercepcaoAmbiente={getPercepcaoAmbiente}
                          getValoresOrganizacionais={getValoresOrganizacionais}
                          cancelValoresOrganizacionais={
                            cancelValoresOrganizacionais
                          }
                        />
                      </TabPane>
                    )}
                    <TabPane
                      tab={
                        isCandidato ? (
                          <span>
                            <TeamOutlined />
                            Cargos
                          </span>
                        ) : (
                          <span>
                            <TeamOutlined />
                            Times & Cargos
                          </span>
                        )
                      }
                      key="4"
                    >
                      <TabTimesCargos
                        openModal={openModal}
                        times={info.times}
                        cargos={info.cargos}
                        isCandidato={isCandidato}
                        getColumnSearchProps={getColumnSearchProps}
                        handleChangeLider={handleChangeLider}
                        deleteCargoPessoa={deleteCargoPessoa}
                        deleteTimePessoa={deleteTimePessoa}
                        id={info.id}
                        handleSaveCargo={handleSaveCargo}
                        handleSaveTime={handleSaveTime}
                      />
                    </TabPane>
                    {!isCandidato && (
                      <TabPane
                        tab={
                          <>
                            <UserSwitchOutlined />
                            <span>Feedback</span>
                          </>
                        }
                        key="5"
                        forceRender
                      >
                        <Feedback id={info.id} />
                      </TabPane>
                    )}
                    {!isCandidato && (
                      <TabPane
                        tab={
                          <>
                            <SolutionOutlined />
                            <span>PDI</span>
                          </>
                        }
                        key="6"
                        forceRender
                      >
                        <Pdi pes_id={info.id} />
                      </TabPane>
                    )}
                    <TabPane tab={<span>Processos Seletivos</span>} key="8">
                      <TabProcessoSeletivo
                        processosSeletivos={processosSeletivos}
                        processos={processos}
                        processoSelected={processoSelected}
                        handleCreatePsLog={handleCreatePsLog}
                        handleDeletePsLog={handleDeletePsLog}
                        handleChangePsSelected={handleChangePsSelected}
                        logs={logs}
                        observacao={observacao}
                        handleChangeObservacao={handleChangeObservacao}
                      />
                    </TabPane>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          visible={showModal.alocarTime}
          title="Adicionar novo time a este colaborador"
          cancelText="CANCELAR"
          okText="SALVAR"
          onCancel={() => closeModal('alocarTime')}
          onOk={() => onModalSubmit('alocarTime')}
          destroyOnClose
        >
          <div className="alocar-time">
            <Row>
              <Col md={24}>
                <TreeSelect
                  showSearch
                  style={{ width: '100%', marginBottom: '20px' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Selecione o novo time"
                  filterTreeNode={(search, item) => {
                    return (
                      item.title.toLowerCase().indexOf(search.toLowerCase()) >=
                      0
                    )
                  }}
                  treeNodeFilterProp="value"
                  allowClear
                  treeDefaultExpandAll
                  label="Selecione o novo time a ser adicionado:"
                  onChange={(evt) => {
                    handleChange(
                      {
                        target: {
                          name: 'id',
                          value: evt,
                        },
                      },
                      'alocarTime'
                    )
                  }}
                >
                  {childrenRender(timesDisponiveis)}
                </TreeSelect>
                <span>Data de Entrada </span>
                <DatePicker
                  onChange={(date) =>
                    handleChange(
                      {
                        target: {
                          name: 'grupo_dataentrada',
                          value: date,
                        },
                      },
                      'alocarTime'
                    )
                  }
                  allowClear
                />
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={showModal.alocarCargo}
          title="Adicionar novo cargo a este colaborador"
          cancelText="CANCELAR"
          okText="SALVAR"
          onCancel={() => closeModal('alocarCargo')}
          onOk={() => onModalSubmit('alocarCargo')}
          destroyOnClose
        >
          <div className="alocar-cargo">
            <Row>
              <Col md={24}>
                <Select
                  label="Selecione o novo cargo a ser adicionado:"
                  placeholder="Selecione o novo cargo"
                  showSearch
                  onChange={(evt) =>
                    handleChange(
                      {
                        target: {
                          name: 'id',
                          value: evt,
                        },
                      },
                      'alocarCargo'
                    )
                  }
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(a, b) => {
                    return sorterString(a, b, 'label')
                  }}
                  options={cargosDisponiveis
                    .map((cargo) => {
                      return {
                        value: cargo.cargo_id,
                        label: cargo.cargo_nome,
                      }
                    })
                    .sort((a, b) => (a.label > b.label ? 1 : -1))}
                />
                <span>Data de Entrada </span>
                <DatePicker
                  onChange={(date) =>
                    handleChange(
                      {
                        target: {
                          name: 'cargopes_dataentrada',
                          value: date,
                        },
                      },
                      'alocarCargo'
                    )
                  }
                  allowClear
                />
              </Col>
            </Row>
          </div>
        </Modal>
        <EditarColaborador
          handleChange={(evt) => handleChange(evt, 'editarColaborador')}
          showModal={showModal.editarColaborador}
          onSubmit={() => onModalSubmit('editarColaborador')}
          closeModal={() => closeModal('editarColaborador')}
          cpf={info.documento}
          nome={info.nome}
          filiais={filiais}
          dataNascimento={info.nascimento}
          email={info.email}
          sexo={info.sexo}
          telefone={info.telefone}
          filial_id={info.filial_id}
          dataAdmissao={info.admissao}
          pcdDefault={info.pcd}
          pcd_tipo={info.pcd_tipo}
          cid={info.cid}
        />
        <Modal
          visible={showModal.escolherCargo}
          title="ESCOLHA O CARGO PARA EFETIVAR O CANDIDATO"
          cancelText="CANCELAR"
          okText="SALVAR"
          onCancel={() => closeModal('escolherCargo')}
          onOk={() => onModalSubmit('escolherCargo')}
        >
          <div className="adicionar-candidatos">
            <Row>
              <Col md={24}>
                <Select
                  label="Cargo"
                  placeholder="Selecione o cargo"
                  name="cargo"
                  onChange={(cargo) =>
                    handleChangeModal({
                      target: {
                        name: 'candidato',
                        value: { ...candidato, cargo },
                      },
                    })
                  }
                  style={{ width: '100%' }}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{
                          padding: '8px',
                          cursor: 'pointer',
                        }}
                      >
                        <PlusOutlined /> Novo Cargo
                      </div>
                    </div>
                  )}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(a, b) => {
                    return sorterString(a, b, 'label')
                  }}
                  options={cargos
                    .map((cargo) => {
                      return {
                        value: cargo.cargo_id,
                        label: cargo.cargo_nome,
                      }
                    })
                    .sort((a, b) => (a.label > b.label ? 1 : -1))}
                >
                  {/* {cargos.map(({ cargo_id, cargo_nome }) => (
                  <Option key={cargo_id} value={cargo_id}>
                    {cargo_nome}
                  </Option>
                ))} */}
                </Select>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={showModal.enviarPesquisa}
          title="GERAR PESQUISA"
          cancelText="CANCELAR"
          okText="ENVIAR"
          onCancel={() => closeModal('enviarPesquisa')}
          onOk={() => onModalSubmit('enviarPesquisa')}
          destroyOnClose
        >
          <EnviarPesquisa handleChangeCheckbox={handleChangeCheckbox} />
        </Modal>
      </div>
    </ContentLayout>
  )
}

export default injectIntl(PerfilView)
