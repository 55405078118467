// TYPES
export const LOAD_VETOR_INDIVIDUAL_SHARE = 'LOAD_VETOR_INDIVIDUAL_SHARE'

// URLS
export const URL_VETOR_INDIVIDUAL_SHARE = `${process.env.REACT_APP_API_URL}/share/vetor/pessoa`
export const URL_LOAD_VETOR_INDIVIDUAL_SHARE = (hash) =>
  `${URL_VETOR_INDIVIDUAL_SHARE}/${hash}`

export const CULTURAS = [
  'Inovação',
  'Empreendedora',
  'Colaboração',
  'Clã',
  'Aprendizado',
  'Complacência',
  'Regra',
  'Autoridade',
]
