// Redux types
import * as constants from '../constants/constantsCadastro'

export const postCadastro = ({
  nome,
  email,
  telefone,
  senha,
  razaoSocial,
  urlEmpresa,
}) => {
  return {
    type: constants.CADASTRO_POST,
    api: {
      method: 'post',
      url: constants.URL_CADASTRO,
      data: {
        user_nome: nome,
        conta_url: urlEmpresa,
        conta_email: email,
        conta_razaonome: razaoSocial,
        conta_telefone: telefone,
        conta_senha: senha,
      },
    },
  }
}
