import React from 'react'
import { Form } from '@ant-design/compatible'

import _ from 'lodash'

import { Row, Col } from 'antd'

// Components
import Select, { Option } from '../../../../components/select'

// Constants
import { styleColors, sorterString } from '../../../../utils/utils'

// import { Container } from './styles';

function escolherGestor({
  intl,
  handleChangeModal,
  handleChange,
  error,
  listColaboradores,
}) {
  return (
    <div className="novo-gestor">
      <Row>
        <Col xs={24} sm={24}>
          {listColaboradores && (
            <Form.Item
              validateStatus={error.pes_id ? 'error' : ''}
              help={error.pes_id || ''}
            >
              <Select
                label="Selecione a pessoa:"
                placeholder="Selecione"
                showSearch
                onChange={(option) =>
                  handleChangeModal({
                    target: {
                      name: 'pes_id',
                      value: option,
                    },
                  })
                }
                style={{ width: '100%' }}
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(a, b) => {
                  return sorterString(a, b, 'label')
                }}
                options={listColaboradores
                  .map((colaborador) => {
                    return {
                      value: colaborador.id,
                      label: colaborador.nome,
                    }
                  })
                  .sort((a, b) => (a.label > b.label ? 1 : -1))}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default escolherGestor
