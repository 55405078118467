import axios from 'axios'

import { toast } from 'react-toastify'
import { SWRConfiguration } from 'swr'
import { message } from 'antd'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})
const token = localStorage.getItem('access_token')

api.defaults.headers = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  Pragma: 'no-cache',
  Expires: '0',
}

api.defaults.headers.authorization = `Bearer ${token}`
api.interceptors.request.use((request) => {
  const headers = {
    ...request.headers.common,
    ...(request.method ? { ...request.headers[request.method] } : {}),
    ...request.headers,
  }

  ;['common', 'get', 'post', 'head', 'put', 'patch', 'delete'].forEach(
    (header) => {
      delete headers[header]
    }
  )

  const printable = `${new Date()} | Request: ${request.method?.toUpperCase()} | ${
    request.url
  }
  \n`

  return request
})

api.interceptors.response.use(
  function (response) {
    const printable = `${new Date()} | Status: ${response.status} | URL: ${
      response.request.responseURL
    }\n`

    return response
  },
  function (error) {
    const { status, data } = error.response

    if (status === 500) {
      toast.error('Houve um problema inesperado, tente novamente.')
    } else if (
      status !== 412 &&
      status !== 400 &&
      data &&
      data.message &&
      data.message !== 'Usuário não encontrado'
    ) {
      toast.error(data.message)
    } else if (
      status !== 412 &&
      status !== 400 &&
      (data.errors || data.erros) &&
      data.message !== 'Usuário não encontrado'
    ) {
      const errors = data.errors || data.erros

      errors.forEach((error: { message: string }) => {
        if (error.message) {
          toast.error(error.message)
        } else toast.error('Error')
      })
    }

    return Promise.reject(error)
  }
)

type HttpMethods = 'get' | 'post' | 'patch' | 'put' | 'options'
async function dataFetcher<TResponse = any>(
  url: string,
  method: HttpMethods = 'get'
) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api.request({
      method,
      url,
    })
    return response.data as TResponse
  } catch (error) {
    throw error
  }
}

export const swrConfiguration: SWRConfiguration = {
  fetcher: dataFetcher,
  errorRetryCount: 1,
  revalidateOnFocus: true,
  shouldRetryOnError: true,
}

export default api
