import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  > span {
    &:first-of-type {
      font-weight: 500;
      font-size: 18px;
    }

    &:nth-of-type(2) {
      font-size: 16px;
    }

    & + span {
      margin-top: 8px;
    }
  }

  ul {
    margin: 8px 0px 0px 0px;
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;

    li {
      text-align: left;

      span {
        & + span {
          margin-left: 8px;
        }
      }

      svg {
        color: var(--pink);
      }
    }
  }
`

export const LinkPesquisa = styled.span`
  color: var(--pink);
  text-decoration: none;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
