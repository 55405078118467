import styled, { css } from 'styled-components'

import { Table as TableAntd, Card } from 'antd'

export const Container = styled.div``

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Table = styled(TableAntd)`
  .ant-table-title {
    padding: 16px 0px;
  }
  overflow-x: auto;
`

export const LinkSpan = styled.span`
  color: var(--purple);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const PercentageBox = styled.div`
  span:first-of-type {
    font-weight: 500;
    font-size: 18px;
    margin-right: 8px;
  }
  span:last-of-type {
  }
`

export const FlexCard = styled(Card)`
  height: 100%;
  .ant-card-body {
    ${(props) =>
      props.noPadding &&
      css`
        padding: 0;
      `}
    ${(props) =>
      props.center &&
      css`
        justify-content: center;
      `}

		height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .bizcharts {
      height: 100% !important;
    }

    > .ant-statistic {
      .ant-statistic-title {
        min-height: 45px;
      }

      flex: 1;
      padding: 0px 16px;

      width: 50%;
      min-width: 50%;

      @media (min-width: 768px) {
        width: 33%;
        min-width: 33%;
      }

      @media (min-width: 1500px) {
        width: 16.6%;
        min-width: 16.6%;
      }
    }
  }
`

export const StepBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .value {
    font-size: 24px;
    font-weight: 500;
  }

  .value-inscritos {
    color: var(--purple);
  }

  .value-triados {
    color: var(--orange);
  }

  .value-contratados {
    color: green;
  }

  > div {
    display: flex;
    justify-content: space-between;
    .pct {
      margin-left: 4px;
    }
  }
`

export const VagaContainer = styled.span`
  display: flex;
  justify-content: flex-start;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > span:first-of-type {
      font-weight: 500;
      font-size: 14px;
    }
  }
`

export const ContainerSelecionadas = styled.div`
  margin-left: 14px;
  display: inline-block;
`

export const ContainerTextoLongo = styled.span`
  display: block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ContainerTexto = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
