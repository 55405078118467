import React from 'react'

import AnaliseCulturaGeralView from '../relatoriosGeral/analiseCulturaTimes/AnaliseCulturaGeralView'
import Olhar360GeralView from '../relatoriosGeral/olhar360/Olhar360GeralView'
import PerfilComportamentalGeralView from '../relatoriosGeral/perfilComportamental/PerfilComportamentalView'

import Select, { Option } from '../../components/select/Select'

function RelatoriosTimesView({
  grupoSelecionado,
  grupos,
  handleChangeGrupo,
  culturaOrganizacional,
  cultura,
  forcas,
  disc,
  olhares,
  gestao,
  type,
  vetor,
  perfis,
  showGraficoRadar,
  handleSwitchGraficoRadar,
  geracoes,
  generos,
  destaques,
}) {
  return (
    <>
      <Select
        label={<span data-html2canvas-ignore={true}>Selecione um time:</span>}
        placeholder={'Selecione'}
        onChange={handleChangeGrupo}
        style={{ width: '100%' }}
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={grupoSelecionado ? grupoSelecionado.grupo_id : null}
        data-html2canvas-ignore={true}
      >
        {grupos.map(({ grupo_nome, grupo_id }) => (
          <Option value={grupo_id} key={grupo_id}>
            {grupo_nome}
          </Option>
        ))}
      </Select>

      {type === 'analiseCultura' && (
        <AnaliseCulturaGeralView
          grupoSelecionado={grupoSelecionado}
          culturaOrganizacional={culturaOrganizacional}
          cultura={cultura}
          forcas={forcas}
          disc={disc}
          olhares={olhares}
          gestao={gestao}
          geracoes={geracoes}
          generos={generos}
          destaques={destaques}
        />
      )}

      {type === 'olhar360' && (
        <Olhar360GeralView culturaOrganizacional={culturaOrganizacional} />
      )}

      {type === 'perfilComportamental' && (
        <PerfilComportamentalGeralView
          {...{ ...vetor, perfis, showGraficoRadar, handleSwitchGraficoRadar }}
        />
      )}
    </>
  )
}

export default RelatoriosTimesView
