import axios from 'axios'
import { parseCulturas } from '../../../utils/utils'

import { IRelatorio, IRelatorioParsed } from '../@types'

interface CulturaDataParserOptions {
  date?: string
}

interface CulturaOrgDataParserOptions {
  date?: string
}

interface CulturaOrgIndicadoresParserOptions {
  date?: string
}

interface CustomParserOptions {
  key: string
  value: string
  date?: string
}

interface IndicadoresMudancaParserOptions {
  key: string
  value: string
  date?: string
}

interface SimpleParserOptions {
  labelField: string
  valueField: string
  date?: string
}

const parseCulturaData = (
  data: any[] = [],
  { date }: CulturaDataParserOptions
) => {
  const newData: any[] = []
  for (const c of data) {
    let obj: any = newData.find((o) => o.Tipo === c.Tipo)
    let add = false
    if (!obj) {
      add = true
      obj = {}
    }
    obj.Tipo = c.Tipo
    obj[date || c.Label] = c.Valor ? `${c.Valor}%` : null
    obj.key = c.Tipo
    if (add) newData.push(obj)
  }
  return newData
}

const parseCulturaOrgData = (
  data: any[] = [],
  { date }: CulturaOrgDataParserOptions
) => {
  const newData: any[] = []
  const sums = data.reduce((acc: any, next: any) => {
    return acc[next.TEXTO]
      ? { ...acc, [next.TEXTO]: acc[next.TEXTO] + next.VALOR }
      : { ...acc, [next.TEXTO]: next.VALOR }
  }, {})

  for (const c of data) {
    let obj = newData.find((o) => o.Tipo === c.TEXTO)
    let add = false
    if (!obj) {
      add = true
      obj = {}
    }
    obj.Tipo = c.TEXTO
    if (c.TIPO) {
      obj[date ? `${date}-${c.TIPO}` : c.TIPO] = c.VALOR
        ? `${((parseFloat(c.VALOR) / parseFloat(sums[c.TEXTO])) * 100).toFixed(
            1
          )}%`
        : c.VALOR === 0
        ? '0%'
        : null
    } else {
      obj[date || 'Instalado'] = c.VALOR
        ? `${((parseFloat(c.VALOR) / parseFloat(sums[c.TEXTO])) * 100).toFixed(
            1
          )}%`
        : c.VALOR === 0
        ? '0%'
        : null
    }

    obj.key = c.TEXTO
    if (add) newData.push(obj)
  }

  return newData
}

const parseCulturaOrgIndicadores = (
  data: any[] = [],
  { date }: CulturaOrgIndicadoresParserOptions
) => {
  const newData: any[] = []
  const sum = data.reduce(
    (acc: number, next: any) => acc + parseFloat(next.VALOR),
    0
  )

  for (const c of data) {
    let obj = newData.find((o) => o.Tipo === c.TEXTO)
    let add = false
    if (!obj) {
      add = true
      obj = {}
    }
    obj.Tipo = c.TEXTO
    if (c.TIPO) {
      obj[date ? `${date}-${c.TIPO}` : c.TIPO] = c.VALOR
        ? `${((parseFloat(c.VALOR) / sum) * 100).toFixed(1)}%`
        : c.VALOR === 0
        ? '0%'
        : null
    } else {
      obj[date || 'Instalado'] = c.VALOR
        ? `${((parseFloat(c.VALOR) / sum) * 100).toFixed(1)}%`
        : c.VALOR === 0
        ? '0%'
        : null
    }

    obj.key = c.TEXTO
    if (add) newData.push(obj)
  }

  return newData
}

const customParser = (
  data: any[] = [],
  { key, value, date }: CustomParserOptions
) => {
  const sum = data.reduce(
    (acc: number, next: any) => (acc += parseInt(next[value])),
    0
  )

  return data.map((i: any) => {
    const obj: any = {
      Tipo: i[key],
      key: i[key],
    }

    obj[date || 'Instalado'] = i[value]
      ? `${((parseInt(i[value]) / sum) * 100).toFixed(1)}%`
      : null

    return obj
  })
}

const simpleParser = (
  data: any[] = [],
  { labelField, valueField, date }: SimpleParserOptions
) => {
  return data.map((i: any) => {
    const obj: any = {
      Tipo: i[labelField],
      key: i[labelField],
    }

    obj[date || 'Instalado'] = i[valueField] ? `${i[valueField]}%` : null

    return obj
  })
}

const indicadoresMudancaParser = (
  data: any,
  { key, value, date }: IndicadoresMudancaParserOptions
) => {
  if (!data) return null

  const obj: any = {
    Tipo: data[key],
  }

  if (date) {
    obj[date] = data[value]
  } else {
    obj.Instalado = `${data[value]}%`
  }

  return obj
}

export function parseRelatorio({
  culturaInstalada,
  culturaOrganizacional,
  generos,
  geracoes,
  date,
}: IRelatorio): IRelatorioParsed {
  const { cultura, forcas, disc, olhares, gestao } = parseCulturas({
    culturaInstalada,
    culturaDesejada: undefined,
    culturaConsensuada: undefined,
    culturaLider: undefined,
    custom: undefined,
    culturaDesejadaGrupo: undefined,
    labelCulturaDesejadaEmpresa: 'Desejado',
    labelCulturaDesejadaGrupo: 'Desejado (Time)',
  })

  const culturaParsed = parseCulturaData(cultura, { date })
  const forcasParsed = parseCulturaData(forcas, { date })
  const olharesParsed = parseCulturaData(olhares, { date })
  const gestaoParsed = parseCulturaData(gestao, { date })

  const generosParsed = customParser(generos, {
    key: 'genero',
    value: 'qtde',
    date,
  })
  const geracoesParsed = customParser(geracoes, {
    key: 'geracao',
    value: 'qtde',
    date,
  })

  const {
    valoresEvidencia,
    satisfacaoGeral,
    niveisConfianca,
    olharLideranca,
    olharParaMudancas,
    olharParaSi,
  } = culturaOrganizacional

  const valoresEvidenciaParsed = simpleParser(valoresEvidencia, {
    labelField: 'TEXTO',
    valueField: 'VALOR',
    date,
  })

  const satisfacaoGeralParsed = simpleParser(satisfacaoGeral, {
    labelField: 'TEXTO',
    valueField: 'VALOR',
    date,
  })

  const niveisConfiancaParsed = simpleParser(niveisConfianca, {
    labelField: 'TEXTO',
    valueField: 'VALOR',
    date,
  })

  const {
    expectativaEntregas,
    feedbackContribui,
    frequenciaFeedback,
    perceboLider,
    perfilLiderDireto,
    reconhecimento,
  } = olharLideranca

  const expectativaEntregasParsed = parseCulturaOrgIndicadores(
    expectativaEntregas,
    {
      date,
    }
  )
  const feedbackContribuiParsed = parseCulturaOrgIndicadores(
    feedbackContribui,
    {
      date,
    }
  )
  const frequenciaFeedbackParsed = parseCulturaOrgIndicadores(
    frequenciaFeedback,
    {
      date,
    }
  )
  const perceboLiderParsed = parseCulturaOrgData(perceboLider, { date })
  const perfilLiderDiretoParsed = parseCulturaOrgIndicadores(
    perfilLiderDireto,
    {
      date,
    }
  )
  const reconhecimentoParsed = parseCulturaOrgIndicadores(reconhecimento, {
    date,
  })

  const {
    impedimento,
    pontosFortes,
    principalProblema,
    engajamento,
    proficiencia,
    velocidadeAdocao,
  } = olharParaMudancas

  const impedimentoParsed = parseCulturaOrgData(impedimento, { date })
  const pontosFortesParsed = parseCulturaOrgData(pontosFortes, { date })
  const principalProblemaParsed = parseCulturaOrgData(principalProblema, {
    date,
  })

  const engajamentoParsed = indicadoresMudancaParser(engajamento, {
    key: 'mudanca',
    value: 'percent',
    date,
  })

  const proficienciaParsed = indicadoresMudancaParser(proficiencia, {
    key: 'mudanca',
    value: 'percent',
    date,
  })

  const velocidadeAdocaoParsed = indicadoresMudancaParser(velocidadeAdocao, {
    key: 'mudanca',
    value: 'percent',
    date,
  })

  const { escolhaEmpresa, expectativas6meses, fonteEntusiasmo, prioridades } =
    olharParaSi

  const escolhaEmpresaParsed = parseCulturaOrgData(escolhaEmpresa, { date })
  const expectativas6mesesParsed = parseCulturaOrgData(expectativas6meses, {
    date,
  })
  const fonteEntusiasmoParsed = parseCulturaOrgIndicadores(fonteEntusiasmo, {
    date,
  })
  const prioridadesParsed = parseCulturaOrgData(prioridades, { date })

  const result = {
    culturaOrganizacional: {
      valoresEvidencia: valoresEvidenciaParsed,
      satisfacaoGeral: satisfacaoGeralParsed,
      niveisConfianca: niveisConfiancaParsed,
      olharLideranca: {
        expectativaEntregas: expectativaEntregasParsed,
        feedbackContribui: feedbackContribuiParsed,
        frequenciaFeedback: frequenciaFeedbackParsed,
        perceboLider: perceboLiderParsed,
        perfilLiderDireto: perfilLiderDiretoParsed,
        reconhecimento: reconhecimentoParsed,
      },
      olharParaMudancas: {
        impedimento: impedimentoParsed,
        pontosFortes: pontosFortesParsed,
        principalProblema: principalProblemaParsed,
        engajamento: engajamentoParsed,
        velocidadeAdocao: velocidadeAdocaoParsed,
        proficiencia: proficienciaParsed,
      },
      olharParaSi: {
        escolhaEmpresa: escolhaEmpresaParsed,
        expectativas6meses: expectativas6mesesParsed,
        fonteEntusiasmo: fonteEntusiasmoParsed,
        prioridades: prioridadesParsed,
      },
    },
    culturaInstalada: {
      cultura: culturaParsed,
      forcas: forcasParsed,
      olhares: olharesParsed,
      gestao: gestaoParsed,
    },
    generos: generosParsed,
    geracoes: geracoesParsed,
  }

  return result
}

interface IMergeRelatorios {
  dataSource: IRelatorioParsed
  newData: IRelatorioParsed
}

export function mergeRelatorios({
  dataSource,
  newData,
}: IMergeRelatorios): IRelatorioParsed {
  if (!dataSource) return newData
  if (!newData) return dataSource

  return {
    culturaInstalada: {
      cultura: [
        ...dataSource.culturaInstalada.cultura.map((item) => {
          const mergeItem = newData.culturaInstalada.cultura.find(
            (i) => i.key === item.key
          )

          if (mergeItem) {
            return {
              ...item,
              ...mergeItem,
            }
          }
          return item
        }),
      ],
      forcas: [
        ...dataSource.culturaInstalada.forcas.map((item) => {
          const mergeItem = newData.culturaInstalada.forcas.find(
            (i) => i.key === item.key
          )

          if (mergeItem) {
            return {
              ...item,
              ...mergeItem,
            }
          }
          return item
        }),
      ],
      gestao: [
        ...dataSource.culturaInstalada.gestao.map((item) => {
          const mergeItem = newData.culturaInstalada.gestao.find(
            (i) => i.key === item.key
          )

          if (mergeItem) {
            return {
              ...item,
              ...mergeItem,
            }
          }
          return item
        }),
      ],
      olhares: [
        ...dataSource.culturaInstalada.olhares.map((item) => {
          const mergeItem = newData.culturaInstalada.olhares.find(
            (i) => i.key === item.key
          )

          if (mergeItem) {
            return {
              ...item,
              ...mergeItem,
            }
          }
          return item
        }),
      ],
    },
    culturaOrganizacional: {
      niveisConfianca: [
        ...dataSource.culturaOrganizacional.niveisConfianca.map((item) => {
          const mergeItem = newData.culturaOrganizacional.niveisConfianca.find(
            (i) => i.key === item.key
          )

          if (mergeItem) {
            return {
              ...item,
              ...mergeItem,
            }
          }
          return item
        }),
      ],
      satisfacaoGeral: [
        ...dataSource.culturaOrganizacional.satisfacaoGeral.map((item) => {
          const mergeItem = newData.culturaOrganizacional.satisfacaoGeral.find(
            (i) => i.key === item.key
          )

          if (mergeItem) {
            return {
              ...item,
              ...mergeItem,
            }
          }
          return item
        }),
      ],
      olharLideranca: {
        expectativaEntregas: [
          ...dataSource.culturaOrganizacional.olharLideranca.expectativaEntregas.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharLideranca.expectativaEntregas.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
        feedbackContribui: [
          ...dataSource.culturaOrganizacional.olharLideranca.feedbackContribui.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharLideranca.feedbackContribui.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
        frequenciaFeedback: [
          ...dataSource.culturaOrganizacional.olharLideranca.frequenciaFeedback.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharLideranca.frequenciaFeedback.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
        perceboLider: [
          ...dataSource.culturaOrganizacional.olharLideranca.perceboLider.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharLideranca.perceboLider.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
        perfilLiderDireto: [
          ...dataSource.culturaOrganizacional.olharLideranca.perfilLiderDireto.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharLideranca.perfilLiderDireto.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
        reconhecimento: [
          ...dataSource.culturaOrganizacional.olharLideranca.reconhecimento.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharLideranca.reconhecimento.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
      },
      olharParaMudancas: {
        impedimento: [
          ...dataSource.culturaOrganizacional.olharParaMudancas.impedimento.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharParaMudancas.impedimento.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
        pontosFortes: [
          ...dataSource.culturaOrganizacional.olharParaMudancas.pontosFortes.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharParaMudancas.pontosFortes.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
        principalProblema: [
          ...dataSource.culturaOrganizacional.olharParaMudancas.principalProblema.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharParaMudancas.principalProblema.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
        engajamento: {
          ...dataSource.culturaOrganizacional.olharParaMudancas.engajamento,
          ...newData.culturaOrganizacional.olharParaMudancas.engajamento,
        },
        velocidadeAdocao: {
          ...dataSource.culturaOrganizacional.olharParaMudancas
            .velocidadeAdocao,
          ...newData.culturaOrganizacional.olharParaMudancas.velocidadeAdocao,
        },
        proficiencia: {
          ...dataSource.culturaOrganizacional.olharParaMudancas.proficiencia,
          ...newData.culturaOrganizacional.olharParaMudancas.proficiencia,
        },
      },
      olharParaSi: {
        escolhaEmpresa: [
          ...dataSource.culturaOrganizacional.olharParaSi.escolhaEmpresa.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharParaSi.escolhaEmpresa.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
        expectativas6meses: [
          ...dataSource.culturaOrganizacional.olharParaSi.expectativas6meses.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharParaSi.expectativas6meses.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
        fonteEntusiasmo: [
          ...dataSource.culturaOrganizacional.olharParaSi.fonteEntusiasmo.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharParaSi.fonteEntusiasmo.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
        prioridades: [
          ...dataSource.culturaOrganizacional.olharParaSi.prioridades.map(
            (item) => {
              const mergeItem =
                newData.culturaOrganizacional.olharParaSi.prioridades.find(
                  (i) => i.key === item.key
                )

              if (mergeItem) {
                return {
                  ...item,
                  ...mergeItem,
                }
              }
              return item
            }
          ),
        ],
      },
      valoresEvidencia: [
        ...dataSource.culturaOrganizacional.valoresEvidencia.map((item) => {
          const mergeItem = newData.culturaOrganizacional.valoresEvidencia.find(
            (i) => i.key === item.key
          )

          if (mergeItem) {
            return {
              ...item,
              ...mergeItem,
            }
          }
          return item
        }),
      ],
    },
    generos: [
      ...dataSource.generos.map((item) => {
        const mergeItem = newData.generos.find((i) => i.key === item.key)

        if (mergeItem) {
          return {
            ...item,
            ...mergeItem,
          }
        }
        return item
      }),
    ],
    geracoes: [
      ...dataSource.geracoes.map((item) => {
        const mergeItem = newData.geracoes.find((i) => i.key === item.key)

        if (mergeItem) {
          return {
            ...item,
            ...mergeItem,
          }
        }
        return item
      }),
    ],
  }
}

interface IFilterRelatorios {
  dataSource: IRelatorioParsed
  removeDate: string
}

const removeKey = ({ data, key }: { data: any; key: string }) => {
  delete data[key]
  return data
}

export function filterRelatorios({
  dataSource,
  removeDate,
}: IFilterRelatorios): IRelatorioParsed {
  return {
    culturaInstalada: {
      cultura: [
        ...dataSource.culturaInstalada.cultura.map((item) => {
          delete item[removeDate]

          return item
        }),
      ],
      forcas: [
        ...dataSource.culturaInstalada.forcas.filter((item) => {
          delete item[removeDate]

          return item
        }),
      ],
      gestao: [
        ...dataSource.culturaInstalada.gestao.filter((item) => {
          delete item[removeDate]

          return item
        }),
      ],
      olhares: [
        ...dataSource.culturaInstalada.olhares.filter((item) => {
          delete item[removeDate]

          return item
        }),
      ],
    },
    culturaOrganizacional: {
      niveisConfianca: [
        ...dataSource.culturaOrganizacional.niveisConfianca.filter((item) => {
          delete item[removeDate]

          return item
        }),
      ],
      satisfacaoGeral: [
        ...dataSource.culturaOrganizacional.satisfacaoGeral.filter((item) => {
          delete item[removeDate]

          return item
        }),
      ],
      olharLideranca: {
        expectativaEntregas: [
          ...dataSource.culturaOrganizacional.olharLideranca.expectativaEntregas.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
        feedbackContribui: [
          ...dataSource.culturaOrganizacional.olharLideranca.feedbackContribui.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
        frequenciaFeedback: [
          ...dataSource.culturaOrganizacional.olharLideranca.frequenciaFeedback.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
        perceboLider: [
          ...dataSource.culturaOrganizacional.olharLideranca.perceboLider.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
        perfilLiderDireto: [
          ...dataSource.culturaOrganizacional.olharLideranca.perfilLiderDireto.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
        reconhecimento: [
          ...dataSource.culturaOrganizacional.olharLideranca.reconhecimento.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
      },
      olharParaMudancas: {
        impedimento: [
          ...dataSource.culturaOrganizacional.olharParaMudancas.impedimento.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
        pontosFortes: [
          ...dataSource.culturaOrganizacional.olharParaMudancas.pontosFortes.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
        principalProblema: [
          ...dataSource.culturaOrganizacional.olharParaMudancas.principalProblema.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
        engajamento: removeKey({
          data: dataSource.culturaOrganizacional.olharParaMudancas.engajamento,
          key: removeDate,
        }),
        velocidadeAdocao: removeKey({
          data: dataSource.culturaOrganizacional.olharParaMudancas
            .velocidadeAdocao,
          key: removeDate,
        }),
        proficiencia: removeKey({
          data: dataSource.culturaOrganizacional.olharParaMudancas.proficiencia,
          key: removeDate,
        }),
      },
      olharParaSi: {
        escolhaEmpresa: [
          ...dataSource.culturaOrganizacional.olharParaSi.escolhaEmpresa.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
        expectativas6meses: [
          ...dataSource.culturaOrganizacional.olharParaSi.expectativas6meses.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
        fonteEntusiasmo: [
          ...dataSource.culturaOrganizacional.olharParaSi.fonteEntusiasmo.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
        prioridades: [
          ...dataSource.culturaOrganizacional.olharParaSi.prioridades.filter(
            (item) => {
              delete item[removeDate]

              return item
            }
          ),
        ],
      },
      valoresEvidencia: [
        ...dataSource.culturaOrganizacional.valoresEvidencia.filter((item) => {
          delete item[removeDate]

          return item
        }),
      ],
    },
    generos: [
      ...dataSource.generos.filter((item) => {
        delete item[removeDate]

        return item
      }),
    ],
    geracoes: [
      ...dataSource.geracoes.filter((item) => {
        delete item[removeDate]

        return item
      }),
    ],
  }
}

export async function getGrupos() {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const url = `${process.env.REACT_APP_API_URL}/dashboard/grupos`
  try {
    const response = await axios.get(url, config)
    return response.data
  } catch (error: any) {
    return error.response.data
  }
}

export async function getGrupoById(id: string) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const url = `${process.env.REACT_APP_API_URL}/dashboard/grupo/cultura/${id}`
  try {
    const response = await axios.post(url, null, config)
    return response.data
  } catch (error: any) {
    return error.response.data
  }
}

export async function getGrupoByIdGET(id: string) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const url = `${process.env.REACT_APP_API_URL}/dashboard/grupo/culturaOrganizacional/${id}`
  try {
    const response = await axios.get(url, config)
    return response.data
  } catch (error: any) {
    return error.response.data
  }
}
