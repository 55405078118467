import { toast } from 'react-toastify'

import * as constants from '../constants/constantsProcessoSeletivo'

const initialState = {
  processos: [],
  cargos: [],
  times: [],
  cidades: [],
  ufs: [],
  kpi: undefined,
}

export const processoSeletivo = (state = initialState, action) => {
  switch (action.type) {
    case constants.PROCESSO_SELETIVO_LIST: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error('Erro ao listar processos.')
        return { ...state }
      }

      const { processos } = action.response.data

      return { ...state, processos }
    }

    case constants.PROCESSO_SELETIVO_CARGOS_GET: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error('Erro ao listar cargos.')
        return { ...state }
      }

      const { cargos } = action.response.data

      return {
        ...state,
        cargos: cargos.map((c) => ({
          cargo_id: c.cargo_id,
          cargo_nome: c.cargo_nome,
        })),
      }
    }

    case constants.PROCESSO_SELETIVO_TIMES_GET: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error('Erro ao listar grupos.')
        return { ...state }
      }

      const { grupos } = action.response.data

      return {
        ...state,
        times: [...grupos],
      }
    }

    case constants.PROCESSO_SELETIVO_UFS_GET: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204) toast.error('Erro ao listar UFs.')
        return { ...state }
      }

      const { ufs } = action.response.data
      return { ...state, ufs }
    }

    case constants.PROCESSO_SELETIVO_CIDADES_GET: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error('Erro ao listar Cidades.')
        return { ...state }
      }

      const { cidades } = action.response.data
      return { ...state, cidades }
    }

    case constants.PROCESSO_SELETIVO_KPI: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204) toast.error('Erro ao obter KPI.')
        return { ...state }
      }

      const {
        andamento,
        atrasados,
        cancelados,
        contratados,
        duracaoMedia,
        emDia,
        inscricoes15dias,
        inscritos,
        triados,
        concluidos,
      } = action.response.data

      return {
        ...state,
        kpi: {
          andamento,
          atrasados,
          cancelados,
          concluidos,
          contratados,
          duracaoMedia,
          emDia,
          inscricoes15dias,
          inscritos,
          triados,
        },
      }
    }

    default:
      return { ...state }
  }
}

export default processoSeletivo
