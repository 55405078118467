import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { Input, Form, Typography } from 'antd'

const { Text } = Typography

export default function InputField({
  name,
  label,
  required,
  placeholder,
  showCount,
  maxLength,
  rules,
  ...rest
}) {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: (ref) => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <Form layout="vertical">
      <Form.Item label={label} name={name} rules={rules}>
        <Input
          type="text"
          ref={inputRef}
          defaultValue={defaultValue}
          placeholder={placeholder}
          showCount={showCount}
          maxLength={maxLength}
          required={required}
          {...rest}
        />
      </Form.Item>
    </Form>
  )
}
