import { connect } from 'react-redux'
import * as actionsPerfil from './actions/actionsPerfil'
import {
  postTimePessoa,
  postGrupoLider,
  deleteGrupoLider,
  deleteTimePessoa,
} from '../timesDetalhes/actions/actionsTimesDetalhes'
import { getTimes as getTimesDisponiveis } from '../times/actions/actionsTimes'
import {
  postCargoPessoa,
  deleteCargoPessoa,
} from '../cargosDetalhes/actions/actionsCargosDetalhes'
import { listFiliais } from '../ConfigFiliais/actions/actions'
import { getCargos as getCargosDisponiveis } from '../cargos/actions/actionsCargos'
import { putColaborador } from '../cadastroColaboradores/actions/actionsCadastroColaboradores'
import {
  getCandidatos,
  efetivaCandidato,
} from '../candidatos/actions/actionsCandidatos'

import {
  sendVetorComportamental,
  sendPercepcao,
  sendValoresOrganizacionais,
} from '../gestaoPesquisasColaboradores/actions/actionsGestaoPesquisas'

// Components
import PerfilController from './PerfilController'

// Actions

const mapStateToProps = ({ perfil, times, cargos }) => {
  return {
    ...perfil,
    timesDisponiveis: times.list,
    cargosDisponiveis: cargos.list,
  }
}

export default connect(mapStateToProps, {
  ...actionsPerfil,
  getTimesDisponiveis,
  getCargosDisponiveis,
  postTimePessoa,
  postCargoPessoa,
  putColaborador,
  getCandidatos,
  efetivaCandidato,
  postGrupoLider,
  deleteGrupoLider,
  sendVetorComportamental,
  sendPercepcao,
  deleteCargoPessoa,
  deleteTimePessoa,
  sendValoresOrganizacionais,
  listFiliais,
})(PerfilController)
