import React, { Component } from 'react'

// Components
import { SearchOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { toast } from 'react-toastify'
import CargosView from './CargosView'
import Button from '../../components/button/Button'
import Input from '../../components/input/Input'

// Constants
import { modals, apiToFront } from './constants/constantsCargos'

import { validateForm } from '../../utils/forms'

const formFields = [
  {
    name: 'nome',
    required: true,
  },
]

class CargosController extends Component {
  state = {
    showModals: [false],
    dateSource: [],
    modalStates: modals.map((modal) => ({ ...modal.initialState })),
    searchText: '',
    selectedRows: [],
    selectedRowKeys: [],
    error: {},
    cargoToClone: undefined,
    showModalCloneCargo: false,
    showModalNovoCargo: false,
    showModalEditCargo: false,
    showModalAtividade: false,
    showModalRequisito: false,
    showModalFerramenta: false,
    showModalTecnica: false,
    currentCargoId: undefined,
    currentCargoData: undefined,
  }

  componentDidMount() {
    const { getCargos, getFrequencia, getFerramenta, getNivelFormacao } =
      this.props

    getCargos().then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          dataSource: response.data.cargos
            .filter((cargo) => cargo.cargo_ativo == 1)
            .map((cargo) => apiToFront({ cargo })),
        })
      }
    })

    getFrequencia().then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          frequencia: response.data,
        })
      }
    })

    getFerramenta().then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          ferramenta: response.data,
        })
      }
    })

    getNivelFormacao().then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          formacao: response.data,
        })
      }
    })
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  handleChangeModal = (id, { target }) =>
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.value } : elem
      ),
    }))

  handleChangeCheckbox = (id, { target }) =>
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.checked } : elem
      ),
    }))

  openModal = (id) => {
    const { showModals } = this.state
    const newshowModals = showModals

    newshowModals[id] = true

    this.setState({ showModals: newshowModals })
  }

  closeModal = (id) => {
    const { showModals, modalStates } = this.state
    const newshowModals = showModals

    newshowModals[id] = false

    const newModalStates = modalStates

    newModalStates[id] = modals[id].initialState

    this.setState({
      showModals: newshowModals,
      modalStates: newModalStates,
      error: {},
      cargoToClone: undefined,
    })
  }

  editModal = (idModal, idData) => {
    const { modalStates, dataSource } = this.state
    const { openModal } = this

    const newModalState = dataSource.filter((d) => d.id === idData)

    this.setState(
      (prevState) => ({
        modalStates: prevState.modalStates.map((elem, index) =>
          index === idModal ? { ...newModalState[0] } : elem
        ),
      }),
      () => {
        openModal(idModal)
      }
    )
  }

  onModalSubmit = (id) => {
    const { modalStates, selectedRows } = this.state
    const {
      postCargo,
      getCargos,
      updateCargo,
      sendVetorComportamental,
      sendPercepcao,
      sendValoresOrganizacionais,
      storeAtividade,
      storeFerramenta,
      storeRequisito,
      storeTecnica,
    } = this.props

    if (id === 0) {
      let sucessoEnvioPesquisas = 0
      selectedRows.forEach((row) => {
        if (modalStates[id].vetorComportamental)
          sendVetorComportamental(row.id, {
            enviarEmails: modalStates[id].enviarEmails,
          }).then(({ response }) => {
            if (response.status === 200) {
              sucessoEnvioPesquisas++
              if (sucessoEnvioPesquisas === selectedRows.length)
                message.success('Enviado com sucesso!')
            }
          })
        if (modalStates[id].percepcaoAmbiente)
          sendPercepcao(row.id, {
            enviarEmails: modalStates[id].enviarEmails,
          }).then(({ response }) => {
            if (response.status === 200) {
              sucessoEnvioPesquisas++
              if (sucessoEnvioPesquisas === selectedRows.length)
                message.success('Enviado com sucesso!')
            }
          })

        if (modalStates[id].valoresOrganizacionais)
          sendValoresOrganizacionais(row.id, {
            enviarEmails: modalStates[id].enviarEmails,
          }).then(({ response }) => {
            if (response.status === 200) {
              sucessoEnvioPesquisas++
              if (sucessoEnvioPesquisas === selectedRows.length)
                message.success('Enviado com sucesso!')
            }
          })
      })
      this.closeModal(0)
    } else if (id === 1) {
      const error = validateForm(formFields, modalStates[id])
      this.setState({ error: error.messages })

      if (error.hasError) return

      postCargo(modalStates[id]).then(({ response }) => {
        if (response.status === 200) {
          message.success('Adicionado com sucesso!')
          getCargos().then(({ response }) => {
            if (response.status === 200) {
              this.setState({
                dataSource: response.data.cargos
                  .filter((cargo) => cargo.cargo_ativo == 1)
                  .map((cargo) => apiToFront({ cargo })),
              })
            }
          })
          this.closeModal(1)
        }
      })
    } else if (id === 2) {
      updateCargo(modalStates[id]).then(({ response }) => {
        if (response.status === 200) {
          message.success('Editado com sucesso!')
          getCargos().then(({ response }) => {
            if (response.status === 200) {
              this.setState({
                dataSource: response.data.cargos
                  .filter((cargo) => cargo.cargo_ativo == 1)
                  .map((cargo) => apiToFront({ cargo })),
              })
            }
          })
          this.closeModal(2)
        }
      })
    } else if (id === 3) {
      const error = validateForm(formFields, modalStates[id])
      this.setState({ error: error.messages })

      storeAtividade(modalStates[id]).then(({ response }) => {
        if (response.status === 200) {
          message.success('Inserido com sucesso!')
          this.closeModal(3)
        }
      })
    } else if (id === 4) {
      const error = validateForm(formFields, modalStates[id])
      this.setState({ error: error.messages })

      storeFerramenta(modalStates[id]).then(({ response }) => {
        if (response.status === 200) {
          message.success('Inserido com sucesso!')
          this.closeModal(4)
        }
      })
    } else if (id === 5) {
      const error = validateForm(formFields, modalStates[id])
      this.setState({ error: error.messages })

      storeRequisito(modalStates[id]).then(({ response }) => {
        if (response.status === 200) {
          message.success('Inserido com sucesso!')
          this.closeModal(5)
        }
      })
    } else if (id === 6) {
      const error = validateForm(formFields, modalStates[id])
      this.setState({ error: error.messages })

      storeTecnica(modalStates[id]).then(({ response }) => {
        if (response.status === 200) {
          message.success('Inserido com sucesso!')
          this.closeModal(6)
        }
      })
    }
  }

  handleDelete = (id) => {
    const { deleteCargo, getCargos } = this.props
    deleteCargo(id).then(({ response }) => {
      if (response.status === 200) {
        message.success('Excluído com sucesso!')
        getCargos().then(({ response }) => {
          if (response.status === 200) {
            this.setState({
              dataSource: response.data.cargos
                .filter((cargo) => cargo.cargo_ativo == 1)
                .map((cargo) => apiToFront({ cargo })),
            })
          }
        })
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder="Buscar"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedRows,
    })
  }

  openModalCloneCargo = (cargo) => {
    this.setState({
      showModalCloneCargo: true,
      cargoToClone: cargo,
    })
  }

  closeModalCloneCargo = () => {
    this.setState({
      showModalCloneCargo: false,
      cargoToClone: undefined,
    })
  }

  openModalNovoCargo = () => {
    this.setState({
      showModalNovoCargo: true,
    })
  }

  closeModalNovoCargo = () => {
    this.setState({
      showModalNovoCargo: false,
    })
  }

  handleSubmitModalNovoCargoClone = (data) => {
    const { postCargo, getCargos } = this.props

    postCargo(data).then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          currentCargoId: response.data.cargo.cargo_id,
          currentCargoData: response.data.cargo,
        })

        toast.success('Adicionado com sucesso!')
        getCargos().then(({ response }) => {
          if (response.status === 200) {
            const { currentCargoId } = this.state

            this.setState({
              dataSource: response.data.cargos
                .filter((cargo) => cargo.cargo_ativo == 1)
                .map((cargo) => apiToFront({ cargo })),
            })
          }
        })
        this.closeModalCloneCargo()
      }
    })
  }

  handleSubmitModalNovoCargo = async (data) => {
    const { postCargo, getCargos } = this.props

    if (
      !data.nome ||
      /^\s*$/.test(data.nome) ||
      !data.oquefaz ||
      /^\s*$/.test(data.oquefaz) ||
      !data.nomeFerramenta ||
      /^\s*$/.test(data.nomeFerramenta) ||
      !data.nomeRequisito ||
      /^\s*$/.test(data.nomeRequisito) ||
      !data.nomeTecnica ||
      /^\s*$/.test(data.nomeTecnica) ||
      !data.descricaoTecnica ||
      /^\s*$/.test(data.descricaoTecnica)
    ) {
      toast.error('Verifique e preencha os campos obrigatórios!')
    } else
      postCargo(data).then(({ response }) => {
        if (response.status === 200) {
          this.setState({
            currentCargoId: response.data.cargo.cargo_id,
            currentCargoData: response.data.cargo,
            showModalNovoCargo: false,
          })

          toast.success('Adicionado com sucesso!')

          getCargos().then(({ response }) => {
            if (response.status === 200) {
              const { currentCargoId } = this.state

              if (currentCargoId) {
                const {
                  storeAtividade,
                  storeFerramenta,
                  storeRequisito,
                  storeTecnica,
                } = this.props

                storeAtividade({ data, id: currentCargoId })

                storeFerramenta({ data, id: currentCargoId })

                storeRequisito({ data, id: currentCargoId })

                storeTecnica({ data, id: currentCargoId })
              }

              this.setState({
                dataSource: response.data.cargos
                  .filter((cargo) => cargo.cargo_ativo == 1)
                  .map((cargo) => apiToFront({ cargo })),
              })
            }
          })
          return true
        }
        return false
      })
  }

  handleSubmitModalNewFerramenta = (data) => {
    const { currentCargoId } = this.state

    if (currentCargoId) {
      const { storeFerramenta } = this.props

      storeFerramenta({ data, id: currentCargoId }).then(({ response }) => {
        if (response.status === 200) {
          this.setState({
            showModalRequisito: true,
            currentCargoData: response.data.cargo,
          })

          toast.success('Informação adicionada ao cargo com sucesso!')
          this.closeModalFerramenta()
        }
      })
    } else toast.error('Nenhum cargo selecionado para adicionar atividade.')
  }

  handleSubmitModalNewRequisito = (data) => {
    const { currentCargoId } = this.state

    if (currentCargoId) {
      const { storeRequisito } = this.props

      storeRequisito({ data, id: currentCargoId }).then(({ response }) => {
        if (response.status === 200) {
          this.setState({
            showModalTecnica: true,
            currentCargoData: response.data.cargo,
          })

          toast.success('Informação adicionada ao cargo com sucesso!')
          this.closeModalRequisito()
        }
      })
    } else toast.error('Nenhum cargo selecionado para adicionar atividade.')
  }

  handleSubmitModalNewTecnica = (data) => {
    const { currentCargoId } = this.state

    if (currentCargoId) {
      const { storeTecnica } = this.props

      storeTecnica({ data, id: currentCargoId }).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Informação adicionada ao cargo com sucesso!')
          this.closeModalTecnica()
        }
      })
    } else toast.error('Nenhum cargo selecionado para adicionar atividade.')
  }

  closeModalAtividade = () => {
    this.setState({
      showModalAtividade: false,
      currentCargoData: undefined,
    })
  }

  closeModalFerramenta = () => {
    this.setState({
      showModalFerramenta: false,
      currentCargoData: undefined,
    })
  }

  closeModalRequisito = () => {
    this.setState({
      showModalRequisito: false,
      currentCargoData: undefined,
    })
  }

  closeModalTecnica = () => {
    this.setState({
      showModalTecnica: false,
      currentCargoData: undefined,
    })
  }

  openModalEditCargo = (cargo) => {
    this.setState({
      showModalEditCargo: true,
      currentCargoId: cargo.id,
      currentCargoData: cargo,
    })
  }

  closeModalEditCargo = () => {
    this.setState({
      showModalEditCargo: false,
      currentCargoId: undefined,
      currentCargoData: undefined,
    })
  }

  handleSubmitModalEditCargo = (data) => {
    const { currentCargoId } = this.state

    if (currentCargoId) {
      const { updateCargo, getCargos } = this.props

      updateCargo({ id: currentCargoId, ...data }).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Editado com sucesso!')
          getCargos().then(({ response }) => {
            if (response.status === 200) {
              this.setState({
                dataSource: response.data.cargos
                  .filter((cargo) => cargo.cargo_ativo == 1)
                  .map((cargo) => apiToFront({ cargo })),
              })
            }
          })
          this.closeModalEditCargo()
        }
      })
    } else toast.error('Nenhum cargo selecionado para editar.')
  }

  render() {
    const {
      onModalSubmit,
      openModal,
      closeModal,
      handleChangeModal,
      handleDelete,
      editModal,
      getColumnSearchProps,
      handleChangeCheckbox,
      onSelectChange,
      openModalNovoCargo,
      closeModalNovoCargo,
      openModalEditCargo,
      closeModalEditCargo,
      handleSubmitModalNovoCargo,
      handleSubmitModalNovoCargoClone,
      handleSubmitModalEditCargo,
      openModalCloneCargo,
      closeModalCloneCargo,
      handleSubmitModalNewAtividade,
      handleSubmitModalNewFerramenta,
      handleSubmitModalNewRequisito,
      handleSubmitModalNewTecnica,
      closeModalAtividade,
      closeModalFerramenta,
      closeModalTecnica,
      closeModalRequisito,
    } = this

    const {
      showModals,
      dataSource,
      modalStates,
      selectedRowKeys,
      error,
      cargoToClone,
      showModalEditCargo,
      showModalNovoCargo,
      currentCargoData,
      showModalCloneCargo,
      frequencia,
      ferramenta,
      formacao,
      showModalAtividade,
      showModalFerramenta,
      showModalRequisito,
      showModalTecnica,
    } = this.state

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    }

    return (
      <CargosView
        openModal={openModal}
        closeModal={closeModal}
        onModalSubmit={onModalSubmit}
        showModals={showModals}
        modalStates={modalStates}
        handleChangeModal={handleChangeModal}
        dataSource={dataSource}
        rowSelection={rowSelection}
        handleDelete={handleDelete}
        editModal={editModal}
        getColumnSearchProps={getColumnSearchProps}
        handleChangeCheckbox={handleChangeCheckbox}
        error={error}
        cargoToClone={cargoToClone}
        openModalNovoCargo={openModalNovoCargo}
        closeModalNovoCargo={closeModalNovoCargo}
        openModalEditCargo={openModalEditCargo}
        closeModalEditCargo={closeModalEditCargo}
        handleSubmitModalNovoCargo={handleSubmitModalNovoCargo}
        handleSubmitModalNovoCargoClone={handleSubmitModalNovoCargoClone}
        handleSubmitModalEditCargo={handleSubmitModalEditCargo}
        showModalEditCargo={showModalEditCargo}
        showModalNovoCargo={showModalNovoCargo}
        currentCargoData={currentCargoData}
        openModalCloneCargo={openModalCloneCargo}
        closeModalCloneCargo={closeModalCloneCargo}
        showModalCloneCargo={showModalCloneCargo}
        frequencia={frequencia}
        ferramenta={ferramenta}
        formacao={formacao}
        handleSubmitModalNewAtividade={handleSubmitModalNewAtividade}
        handleSubmitModalNewFerramenta={handleSubmitModalNewFerramenta}
        handleSubmitModalNewRequisito={handleSubmitModalNewRequisito}
        handleSubmitModalNewTecnica={handleSubmitModalNewTecnica}
        showModalAtividade={showModalAtividade}
        showModalFerramenta={showModalFerramenta}
        showModalRequisito={showModalRequisito}
        showModalTecnica={showModalTecnica}
        closeModalAtividade={closeModalAtividade}
        closeModalFerramenta={closeModalFerramenta}
        closeModalTecnica={closeModalTecnica}
        closeModalRequisito={closeModalRequisito}
      />
    )
  }
}

export default CargosController
