import styled from 'styled-components'

export const Container = styled.div`
  > span {
    display: block;
  }

  > span:first-of-type {
    font-weight: 500;
    font-size: 16px;
  }
`
