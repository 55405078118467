import React from 'react'
import { Select as SelectAntd } from 'antd'

// Components

// CSS
import './Select.css'

function Select({
  label,
  children,
  withoutMargin,
  readOnly = false,
  filterSort,
  filterOption = true,
  ...props
}) {
  return (
    <div className={`select ${withoutMargin ? 'remove-margin' : ''}`}>
      {label && <span className="select__label">{label}</span>}
      <SelectAntd disabled={readOnly} {...props}>
        {children}
      </SelectAntd>
    </div>
  )
}

export const { Option } = SelectAntd

export default Select
