import { connect } from 'react-redux'
import * as actions from './actions/actionsGestaoPesquisas'

// Components
import GestaoPesquisasController from './GestaoPesquisasController'

// Actions

const mapStateToProps = (state) => {
  return {
    listPesquisas: state.gestaoPesquisasColaboradores.list,
  }
}

export default connect(mapStateToProps, { ...actions })(
  GestaoPesquisasController
)
