import styled, { css } from 'styled-components'

import { Button, Collapse } from 'antd'

const { Panel } = Collapse

export const CustomPanel = styled(Panel)`
  .ant-collapse-header {
    font-size: 18px;
    color: #fff !important;
    background-color: var(--purple);
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    i {
      font-size: 18px !important;
    }
    i + i {
      margin-left: 12px;
    }

    .ant-collapse-extra {
      margin-right: 12px;
    }
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .ant-collapse-content-box {
    padding: 0px;
  }
`

export const MetaContainer = styled.div`
  .ant-collapse {
    margin: 0px;
  }
`

export const ItemHeader = styled.span`
  align-self: flex-start;
  flex: 1;
`

export const CustomButton = styled(Button)`
  border: none;
  color: #000;
  vertical-align: middle;
`

export const SubTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin-top: 16px;
`

export const Label = styled.span`
  font-size: 16px;
  font-weight: 500;
`

export const Content = styled.div`
  padding: 12px 24px 24px 24px;
  background-color: #f0f0f0;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }
`

export const PDTItem = styled.div``

export const Container = styled.div`
  background-color: #fff;
  padding: 8px 24px 24px 24px;
  margin-top: 8px;
  border-radius: 5px;
  button + button {
    margin-left: 8px;
  }
  @media screen and (max-width: 1000px) {
    button {
      margin-left: 0px !important;
      width: 100%;
    }
  }
  ${PDTItem} + ${PDTItem} {
    margin-top: 8px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 8px;

  * + * {
    margin-left: 8px;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    * {
      margin-left: 0px !important;
      width: 100%;
    }

    * + * {
      margin-top: 8px;
    }
  }
`

export const MetaHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 32px 0px 8px 0px;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
    flex-direction: column;

    * + * {
      margin-top: 8px;
    }

    ${Label} {
      flex: 1;
      text-align: center;
    }
  }
`

export const Footer = styled.div`
  margin-top: 16px;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-divider {
      display: none;
    }
    ${Label} {
      flex: 1;
      text-align: center;
    }
  }
`
