import { LoadingOutlined } from '@ant-design/icons'
import {
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Empty,
  Row,
  Slider,
  Spin,
  Tag,
  Alert,
  Input,
  Button,
} from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  PerfilComportamental,
  PerfilComportamentalTextual,
} from '../../../../components/perfilComportamental'
import Link from '../../../../components/privateLink/PrivateLink'
import Select, { Option } from '../../../../components/select/Select'
import { cardHeadBlue, noPadding } from '../../../../utils/inlineStyles'
// utils
import {
  calcAge,
  parseCulturas,
  parseDISC,
  parseVetor,
} from '../../../../utils/utils'
import {
  getCultura,
  getCulturaEmpresa,
  getVetor,
  getVetorCompleto,
} from '../Colaboradores/actions'
// actions
import { getCandidatos, newLink } from './actions'
// constants
import { CULTURAS, DISC, filtrosDefault, vetorDefault } from './constants'
import {
  Container,
  CustomList,
  FlexCol,
  FullCol,
  HistoricoOverlay,
  HistoricoRow,
  Label,
  Link16,
  LinkPanel,
  LinkRow,
  PerfilComposicao,
  PerfilDetalhes,
  PerfilLog,
  ScrollablePanel,
} from './styles'

const { Panel } = Collapse

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />

const ageFilterDefaults = {
  range: {
    marks: {
      0: '0%',
      100: '100%',
    },
    marksYears: {
      16: '16 anos',
      100: '100 anos',
    },
    formatter: (value) => `${value}%`,
    formatterYears: (value) => `${value} anos`,
  },
}

const selectFiltroArquetiposOptions = [
  <Option key={1} value={0}>
    Baixo
  </Option>,
  <Option key={2} value={50}>
    Médio
  </Option>,
  <Option key={3} value={80}>
    Alto
  </Option>,
]

function Candidatos() {
  const dispatch = useDispatch()

  const location = useLocation()
  const [initialCandidato, setInitialCandidato] = useState()

  useEffect(() => {
    if (location && location.search) {
      const queries = queryString.parse(location.search)

      if (queries.candidato) {
        setInitialCandidato(Number(queries.candidato))
      }
    }
  }, [location])

  const [candidatoNome, setCandidatoNome] = useState('')
  const [candidatos, setCandidatos] = useState([])
  const [candidatosLoading, setCandidatosLoading] = useState(false)
  const [candidatosRaw, setCandidatosRaw] = useState([])
  const [searchCount, setSearchCount] = useState(0)
  const [pessoaEscolhida, setPessoaEscolhida] = useState({
    pes_id: null,
    pes_nome: '',
  })
  const [vetor, setVetor] = useState(vetorDefault)
  const [vetores, setVetores] = useState([])
  const [cultura, setCultura] = useState([])
  const [culturaEmpresa, setCulturaEmpresa] = useState([])
  const [disc, setDisc] = useState([])

  const [currentPage, setCurrentPage] = useState()
  const [filtros, setFiltros] = useState(filtrosDefault)

  const [loadingAvaliacao, setLoadingAvaliacao] = useState(false)
  const [showInfografico, setShowInfografico] = useState(false)
  const [showGraficoRadar, setShowGraficoRadar] = useState(true)
  const [horizontalList, setHorizontalList] = useState(
    window.matchMedia('(max-width: 1599px)').matches
  )

  const loadCandidatos = useCallback(() => {
    setCandidatosLoading(true)
    dispatch(getCandidatos(candidatoNome)).then(({ response }) => {
      if (response.status === 200) {
        const { candidatos: candidatosData } = response.data

        if (candidatosData.length > 0) {
          setPessoaEscolhida(
            initialCandidato
              ? candidatosData.find(
                  (pessoa) => pessoa.pes_id === initialCandidato
                )
              : candidatosData[0]
          )

          setCandidatos(candidatosData)
          setCandidatosRaw(candidatosData)
        }
      } else {
        setCandidatos([])
        setCandidatosRaw([])
        setPessoaEscolhida({ pes_id: null, pes_nome: '' })
      }
      setCandidatosLoading(false)
    })
  }, [initialCandidato, dispatch, searchCount])

  const loadCultura = useCallback(
    (pes_id) => {
      dispatch(getCultura(pes_id)).then(({ response }) => {
        if (response.status === 200) {
          const { cultura } = response.data

          const culturasParsed = Object.keys(cultura)
            .filter((key) => CULTURAS.includes(key))
            .map((key) => ({
              Tipo: key,
              Valor: Number(cultura[key]),
              Label: pessoaEscolhida.pes_nome,
              key,
            }))

          const discParsed = Object.keys(cultura)
            .filter((key) => DISC.includes(key))
            .map((key) => ({
              Tipo: parseDISC(key),
              Valor: Number(cultura[key]),
              Label: pessoaEscolhida.pes_nome,
              key,
            }))

          setCultura(culturasParsed)
          setDisc(discParsed)
        } else {
          setCultura([])
          setDisc([])
        }
      })
    },
    [dispatch, pessoaEscolhida.pes_nome]
  )

  const loadVetor = useCallback(
    (pes_id) => {
      setLoadingAvaliacao(true)
      dispatch(getVetor(pes_id)).then(({ response }) => {
        if (response.status === 200) {
          const { data } = response.data

          delete data.culturaEmpresa
          delete data.cultura

          const v = parseVetor(data)

          setVetor(v)
        } else {
          setVetor(vetorDefault)
        }
        setLoadingAvaliacao(false)
      })
    },
    [dispatch]
  )

  const loadVetorCompleto = useCallback(
    (pes_id) => {
      dispatch(getVetorCompleto(pes_id)).then(({ response }) => {
        if (response.status === 200) {
          const { data } = response.data

          const { vetores: vetoresData } = data

          const newVetores = vetoresData
            .map((vetor) => {
              return [
                {
                  log_data: vetor.log_data,
                  value: Math.round(vetor.D),
                  type: 'D',
                  key: 'D',
                },
                {
                  log_data: vetor.log_data,
                  value: Math.round(vetor.I),
                  type: 'I',
                  key: 'I',
                },
                {
                  log_data: vetor.log_data,
                  value: Math.round(vetor.S),
                  type: 'S',
                  key: 'S',
                },
                {
                  log_data: vetor.log_data,
                  value: Math.round(vetor.C),
                  type: 'C',
                  key: 'C',
                },
              ]
            })
            .flat()

          setVetores([...newVetores])
        } else {
          setVetores([])
        }
      })
    },
    [dispatch]
  )

  function handleSearch() {
    setSearchCount(searchCount + 1)
    // loadCandidatos()
  }

  const loadCulturaEmpresa = useCallback(() => {
    dispatch(getCulturaEmpresa()).then(({ response }) => {
      if (response.status === 200) {
        const { culturaDesejada, culturaInstalada } = response.data

        const { cultura } = parseCulturas({
          culturaDesejada,
          culturaInstalada,
        })

        setCulturaEmpresa(cultura)
      } else {
        setCulturaEmpresa([])
      }
    })
  }, [dispatch])

  useEffect(() => {
    loadCandidatos()
    loadCulturaEmpresa()
  }, [loadCandidatos, loadCulturaEmpresa])

  useEffect(() => {
    if (pessoaEscolhida.pes_id) {
      if (vetor && vetor.pes_id !== pessoaEscolhida.pes_id) {
        loadVetor(pessoaEscolhida.pes_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadVetor, pessoaEscolhida.pes_id, vetor])

  useEffect(() => {
    if (pessoaEscolhida.pes_id !== null) {
      loadCultura(pessoaEscolhida.pes_id)
      loadVetor(pessoaEscolhida.pes_id)
      loadVetorCompleto(pessoaEscolhida.pes_id)
    }
  }, [
    loadCultura,
    loadVetor,
    loadVetorCompleto,
    pessoaEscolhida.pes_id,
    pessoaEscolhida.pes_nome,
  ])

  const handleMediaListaHorizontal = (e) => setHorizontalList(e.matches)

  useEffect(() => {
    window
      .matchMedia('(max-width: 1599px)')
      .addListener(handleMediaListaHorizontal)
  }, [])

  const handleChangeCandidato = useCallback(
    (pes_id) => {
      const pessoa = candidatos.find((c) => c.pes_id === pes_id)
      setPessoaEscolhida(pessoa)
    },
    [candidatos]
  )

  const handleNewLink = useCallback(
    (pes_id, avalia_id) => {
      dispatch(newLink(pes_id, avalia_id)).then(({ response }) => {
        if (response.status === 200) {
          loadVetor(pessoaEscolhida.pes_id)
        }
      })
    },
    [dispatch, loadVetor, pessoaEscolhida.pes_id]
  )

  const handleChangeInfograficoTextual = useCallback(() => {
    setShowInfografico((state) => !state)
  }, [])

  const handleSwitchGraficoRadar = useCallback(() => {
    setShowGraficoRadar((state) => !state)
  }, [])

  const handleClearFilters = useCallback(() => {
    setFiltros(filtrosDefault)
  }, [])

  const handleChangeFilters = useCallback(
    (target) => {
      const { idade, arquetipos, genero, perfil } = target

      let newFilters = { ...filtros }

      if (idade) {
        newFilters = {
          ...newFilters,
          idade,
        }
      }

      if (arquetipos) {
        newFilters = {
          ...newFilters,
          arquetipos: {
            ...newFilters.arquetipos,
            ...arquetipos,
          },
        }
      }

      if (perfil) {
        newFilters = {
          ...newFilters,
          perfil: {
            ...newFilters.perfil,
            ...perfil,
          },
        }
      }

      if (genero) {
        const { tipo, checked } = genero

        let { generos } = filtros

        if (checked) {
          if (!generos.indexOf(tipo) >= 0) generos.push(tipo)
        } else {
          generos = generos.filter((g) => g !== tipo)
        }

        newFilters = {
          ...newFilters,
          generos,
        }
      }

      setFiltros(newFilters)
    },
    [filtros]
  )

  const handleFiltrar = useCallback(() => {
    const { arquetipos, generos, idade, perfil } = filtros

    let newCandidatos = [...candidatosRaw]

    const { min, max } = idade

    newCandidatos = newCandidatos.filter(
      (c) =>
        (moment(c.pes_datanascimento, 'YYYY-MM-DD').isValid() &&
          calcAge(c.pes_datanascimento) >= min &&
          calcAge(c.pes_datanascimento) <= max) ||
        !moment(c.pes_datanascimento, 'YYYY-MM-DD').isValid()
    )

    const {
      inovacao,
      empreendedora,
      colaboracao,
      cla,
      aprendizado,
      complacencia,
      regra,
      autoridade,
    } = arquetipos

    newCandidatos = newCandidatos.filter(
      (c) =>
        (inovacao === 0
          ? true
          : c.fitcultural && parseFloat(c.fitcultural.Inovação) > inovacao) &&
        (empreendedora === 0
          ? true
          : c.fitcultural &&
            parseFloat(c.fitcultural.Empreendedora) > empreendedora) &&
        (colaboracao === 0
          ? true
          : c.fitcultural &&
            parseFloat(c.fitcultural.Colaboração) > colaboracao) &&
        (cla === 0
          ? true
          : c.fitcultural && parseFloat(c.fitcultural.Clã) > cla) &&
        (aprendizado === 0
          ? true
          : c.fitcultural &&
            parseFloat(c.fitcultural.Aprendizado) > aprendizado) &&
        (complacencia === 0
          ? true
          : c.fitcultural &&
            parseFloat(c.fitcultural.Complacência) > complacencia) &&
        (regra === 0
          ? true
          : c.fitcultural && parseFloat(c.fitcultural.Regra) > regra) &&
        (autoridade === 0
          ? true
          : c.fitcultural && parseFloat(c.fitcultural.Autoridade) > autoridade)
    )

    // FILTRO GENEROS
    newCandidatos = newCandidatos.filter(
      (c) => generos.indexOf(c.pes_sexo) >= 0
    )

    // FILTRO PERFIL
    const { d, i, s, c } = perfil

    const { min: minD, max: maxD } = d

    const { min: minI, max: maxI } = i

    const { min: minS, max: maxS } = s

    const { min: minC, max: maxC } = c

    newCandidatos = newCandidatos.filter(
      (c) =>
        c.cultura &&
        c.cultura.D >= minD &&
        c.cultura.D <= maxD &&
        c.cultura.I >= minI &&
        c.cultura.I <= maxI &&
        c.cultura.S >= minS &&
        c.cultura.S <= maxS &&
        c.cultura.C >= minC &&
        c.cultura.C <= maxC
    )

    setCandidatos([...newCandidatos])
  }, [candidatosRaw, filtros])

  useEffect(() => {
    handleFiltrar()
  }, [filtros, handleFiltrar])

  const getDateDifference = useCallback((data) => {
    const difference = moment().diff(moment(data, 'DD/MM/YYYY HH:mm'), 'months')

    const years = Math.floor(difference / 12)

    return difference >= 12
      ? `${years} ${years > 1 ? 'anos' : 'ano'}`
      : `${difference} meses`
  }, [])

  const { principal, hash, avalia_id } = vetor

  const { idade, generos, arquetipos, perfil: perfilFiltro } = filtros

  return (
    <Container span={24}>
      <Row gutter={16} type="flex" style={noPadding}>
        <FlexCol
          xxl={{ span: 4, order: 1 }}
          xl={{ span: 24, order: 1 }}
          lg={{ span: 24, order: 1 }}
          md={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          xs={{ span: 24, order: 1 }}
        >
          <Row className="colaboradores__row-historico">
            <Col
              xs={24}
              sm={24}
              xl={24}
              xxl={24}
              style={{ marginBottom: '16px', ...noPadding }}
            >
              <Label>Pesquisar por Nome</Label>
              <Input onChange={(e) => setCandidatoNome(e.target.value)} />
              <Button onClick={() => handleSearch()}>Pesquisar</Button>
              <Label>Selecione um Candidato: </Label>

              <Select
                data-html2canvas-ignore
                showSearch
                style={{ width: '100%', marginTop: '8px' }}
                placeholder="Selecione"
                optionFilterProp="children"
                onChange={(option) => handleChangeCandidato(option)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={pessoaEscolhida.pes_id}
                loading={candidatosLoading}
              >
                {candidatos.map(({ pes_nome, pes_id }) => (
                  <Option value={pes_id} key={pes_id}>
                    {pes_nome}
                  </Option>
                ))}
              </Select>

              <LinkRow>
                <Link16 onClick={() => handleChangeInfograficoTextual()}>
                  Veja o {showInfografico ? 'textual' : 'infográfico'}
                </Link16>
                <Divider type="vertical" />
                {hash ? (
                  <CopyToClipboard
                    text={`${window.location.host}/share/vetor/pessoa/${hash}`}
                    onCopy={() =>
                      toast.success(
                        'Link do relatório copiado para a área de transferência.'
                      )
                    }
                  >
                    <Link16>Copiar Link</Link16>
                  </CopyToClipboard>
                ) : (
                  <Link16
                    onClick={() =>
                      handleNewLink(pessoaEscolhida.pes_id, avalia_id)
                    }
                  >
                    Gerar Link
                  </Link16>
                )}
              </LinkRow>
            </Col>
            <Col
              xs={24}
              sm={24}
              xl={24}
              xxl={24}
              style={{ marginBottom: '16px', ...noPadding }}
            >
              <Collapse>
                <Panel
                  header="Filtros"
                  extra={
                    <LinkPanel onClick={() => handleClearFilters()}>
                      Limpar Filtros
                    </LinkPanel>
                  }
                >
                  <Collapse>
                    <Panel header="Gênero">
                      <Checkbox
                        checked={generos.indexOf('M') >= 0}
                        onChange={(e) =>
                          handleChangeFilters({
                            genero: { tipo: 'M', checked: e.target.checked },
                          })
                        }
                        defaultChecked
                      >
                        Masculino
                      </Checkbox>
                      <Checkbox
                        checked={generos.indexOf('F') >= 0}
                        onChange={(e) =>
                          handleChangeFilters({
                            genero: {
                              tipo: 'F',
                              checked: e.target.checked,
                            },
                          })
                        }
                        defaultChecked
                      >
                        Feminino
                      </Checkbox>
                    </Panel>
                    <Panel header="Perfil">
                      <Label>Dominância</Label>
                      <Slider
                        range
                        defaultValue={[0, 100]}
                        min={0}
                        marks={ageFilterDefaults.range.marks}
                        max={100}
                        value={[perfilFiltro.d.min, perfilFiltro.d.max]}
                        tipFormatter={ageFilterDefaults.range.formatter}
                        onChange={(values) =>
                          handleChangeFilters({
                            perfil: { d: { min: values[0], max: values[1] } },
                          })
                        }
                      />
                      <Label>Influência</Label>
                      <Slider
                        range
                        defaultValue={[0, 100]}
                        marks={ageFilterDefaults.range.marks}
                        min={0}
                        max={100}
                        value={[perfilFiltro.i.min, perfilFiltro.i.max]}
                        tipFormatter={ageFilterDefaults.range.formatter}
                        onChange={(values) =>
                          handleChangeFilters({
                            perfil: { i: { min: values[0], max: values[1] } },
                          })
                        }
                      />
                      <Label>Estabilidade</Label>
                      <Slider
                        range
                        defaultValue={[0, 100]}
                        marks={ageFilterDefaults.range.marks}
                        min={0}
                        max={100}
                        value={[perfilFiltro.s.min, perfilFiltro.s.max]}
                        tipFormatter={ageFilterDefaults.range.formatter}
                        onChange={(values) =>
                          handleChangeFilters({
                            perfil: { s: { min: values[0], max: values[1] } },
                          })
                        }
                      />
                      <Label>Conformidade</Label>
                      <Slider
                        range
                        defaultValue={[0, 100]}
                        marks={ageFilterDefaults.range.marks}
                        min={0}
                        max={100}
                        value={[perfilFiltro.c.min, perfilFiltro.c.max]}
                        tipFormatter={ageFilterDefaults.range.formatter}
                        onChange={(values) =>
                          handleChangeFilters({
                            perfil: { c: { min: values[0], max: values[1] } },
                          })
                        }
                      />
                    </Panel>
                    <Panel header="Idade">
                      <Slider
                        range
                        defaultValue={[0, 100]}
                        marks={ageFilterDefaults.range.marksYears}
                        min={16}
                        max={100}
                        value={[idade.min, idade.max]}
                        tipFormatter={ageFilterDefaults.range.formatterYears}
                        onChange={(values) =>
                          handleChangeFilters({
                            idade: { min: values[0], max: values[1] },
                          })
                        }
                      />
                    </Panel>
                    <ScrollablePanel
                      header="Arquétipos Culturais"
                      maxheight={400}
                    >
                      <Label>Inovação</Label>
                      <Select
                        defaultValue={0}
                        onChange={(value, option) =>
                          handleChangeFilters({
                            arquetipos: {
                              inovacao: value,
                            },
                          })
                        }
                        value={
                          arquetipos.inovacao < 50
                            ? 0
                            : arquetipos.inovacao < 80
                            ? 50
                            : 80
                        }
                      >
                        {selectFiltroArquetiposOptions.map((option) => option)}
                      </Select>
                      <Label>Empreendedora</Label>
                      <Select
                        defaultValue={0}
                        onChange={(value, option) =>
                          handleChangeFilters({
                            arquetipos: {
                              empreendedora: value,
                            },
                          })
                        }
                        value={
                          arquetipos.empreendedora < 50
                            ? 0
                            : arquetipos.empreendedora < 80
                            ? 50
                            : 80
                        }
                      >
                        {selectFiltroArquetiposOptions.map((option) => option)}
                      </Select>
                      <Label>Colaboração</Label>
                      <Select
                        defaultValue={0}
                        onChange={(value, option) =>
                          handleChangeFilters({
                            arquetipos: {
                              colaboracao: value,
                            },
                          })
                        }
                        value={
                          arquetipos.colaboracao < 50
                            ? 0
                            : arquetipos.colaboracao < 80
                            ? 50
                            : 80
                        }
                      >
                        {selectFiltroArquetiposOptions.map((option) => option)}
                      </Select>
                      <Label>Clã</Label>
                      <Select
                        defaultValue={0}
                        onChange={(value, option) =>
                          handleChangeFilters({
                            arquetipos: {
                              cla: value,
                            },
                          })
                        }
                        value={
                          arquetipos.cla < 50
                            ? 0
                            : arquetipos.cla < 80
                            ? 50
                            : 80
                        }
                      >
                        {selectFiltroArquetiposOptions.map((option) => option)}
                      </Select>
                      <Label>Aprendizado</Label>
                      <Select
                        defaultValue={0}
                        onChange={(value, option) =>
                          handleChangeFilters({
                            arquetipos: {
                              aprendizado: value,
                            },
                          })
                        }
                        value={
                          arquetipos.aprendizado < 50
                            ? 0
                            : arquetipos.aprendizado < 80
                            ? 50
                            : 80
                        }
                      >
                        {selectFiltroArquetiposOptions.map((option) => option)}
                      </Select>
                      <Label>Complacência</Label>
                      <Select
                        defaultValue={0}
                        onChange={(value, option) =>
                          handleChangeFilters({
                            arquetipos: {
                              complacencia: value,
                            },
                          })
                        }
                        value={
                          arquetipos.complacencia < 50
                            ? 0
                            : arquetipos.complacencia < 80
                            ? 50
                            : 80
                        }
                      >
                        {selectFiltroArquetiposOptions.map((option) => option)}
                      </Select>
                      <Label>Regra</Label>
                      <Select
                        defaultValue={0}
                        onChange={(value, option) =>
                          handleChangeFilters({
                            arquetipos: {
                              regra: value,
                            },
                          })
                        }
                        value={
                          arquetipos.regra < 50
                            ? 0
                            : arquetipos.regra < 80
                            ? 50
                            : 80
                        }
                      >
                        {selectFiltroArquetiposOptions.map((option) => option)}
                      </Select>
                      <Label>Autoridade</Label>
                      <Select
                        defaultValue={0}
                        onChange={(value, option) =>
                          handleChangeFilters({
                            arquetipos: {
                              autoridade: value,
                            },
                          })
                        }
                        value={
                          arquetipos.autoridade < 50
                            ? 0
                            : arquetipos.autoridade < 80
                            ? 50
                            : 80
                        }
                      >
                        {selectFiltroArquetiposOptions.map((option) => option)}
                      </Select>
                    </ScrollablePanel>
                  </Collapse>
                </Panel>
              </Collapse>
            </Col>
            <Col xs={24} sm={24} xl={24} xxl={24} style={{ ...noPadding }}>
              <Card
                title="Vetor de Candidatos"
                bodyStyle={noPadding}
                headStyle={cardHeadBlue}
              >
                <CustomList
                  grid={horizontalList ? { gutter: 8 } : false}
                  pagination={
                    horizontalList
                      ? false
                      : {
                          pageSize: 5,
                          position: 'bottom',
                          defaultCurrent: currentPage,
                        }
                  }
                  loading={candidatosLoading}
                  dataSource={candidatos}
                  renderItem={(item) => {
                    return (
                      <HistoricoRow
                        onClick={() => handleChangeCandidato(item.pes_id)}
                      >
                        <HistoricoOverlay selected={item.selected}>
                          <PerfilComposicao>
                            <span>{`${item.primario}${item.secundario}`}</span>
                          </PerfilComposicao>
                          <PerfilDetalhes>
                            <Link
                              key={item.pes_id}
                              to={`/perfilCandidato/${item.pes_id}`}
                            >
                              <span style={{ fontWeight: '500', fontSize: 16 }}>
                                {item.pes_nome}
                              </span>
                            </Link>
                            <PerfilLog>
                              <span>{item.log_data}</span>
                            </PerfilLog>
                            <Row>
                              <Tag color="#ef5472">
                                {getDateDifference(item.log_data)}
                              </Tag>
                            </Row>
                            <Row />
                          </PerfilDetalhes>
                        </HistoricoOverlay>
                      </HistoricoRow>
                    )
                  }}
                />
              </Card>
            </Col>
          </Row>
        </FlexCol>

        {loadingAvaliacao ? (
          <FullCol
            xxl={{ span: 20, order: 2 }}
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
          >
            <Spin
              indicator={antIcon}
              style={{
                flex: '1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          </FullCol>
        ) : showInfografico ? (
          <FullCol
            xxl={{ span: 20, order: 2 }}
            xl={{ span: 24, order: 2 }}
            lg={{ span: 24, order: 2 }}
            md={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            xs={{ span: 24, order: 2 }}
          >
            <Col xs={24} sm={24} xl={24} xxl={24} style={{ ...noPadding }}>
              <Card
                title="Fit Cultural"
                bodyStyle={noPadding}
                headStyle={cardHeadBlue}
              >
                <Alert
                  message="O relatório de fit cultural foi movido para seção de Fit Cultural"
                  type="warning"
                  style={{
                    marginTop: '24px',
                    fontSize: '12px',
                    width: '100%',
                  }}
                  showIcon
                />
              </Card>
            </Col>
          </FullCol>
        ) : principal ? (
          <Col
            xxl={{ span: 20, order: 2 }}
            sm={{ span: 24, order: 2 }}
            xs={{ span: 24, order: 2 }}
          >
            <Row>
              <PerfilComportamentalTextual
                type="candidato"
                cultura={cultura}
                culturaEmpresa={culturaEmpresa}
                {...vetor}
              />
            </Row>
          </Col>
        ) : (
          <Empty />
        )}
      </Row>
    </Container>
  )
}

export default Candidatos
