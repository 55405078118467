import { Button } from 'antd'
import { Steps } from 'antd'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  updateStatusCulturaDesejada,
  sendEmailForAllAvaliators,
  graficos,
} from '../Api/api'

const { Step } = Steps

const App = (props) => {
  useEffect(() => {
    getGraficos()
  })

  const [nextStep, setNextStep] = useState(false)
  const [isLoading, setLoading] = useState(false)

  async function getGraficos() {
    const response = await graficos(props.cultura.cultura_id)
    if (props.cultura.cultura_status != 2) setNextStep(true)
    else if (response.instalado.length != 0) setNextStep(true)
  }

  // Verifica se é possivél mover para a próxima etapa, se possivél é a função
  // responsavél por atualizar os status em todas as páginas da cultura
  async function handleNextStep() {
    setLoading(true)
    let etapaAtual = props.cultura?.conta_cultura_statuses[0]?.id
    if (etapaAtual < 3) {
      etapaAtual += 1
      try {
        await updateStatusCulturaDesejada(etapaAtual, props.cultura?.cultura_id)
        if (etapaAtual === 2)
          await sendEmailForAllAvaliators(props.cultura.cultura_id)
        toast.success('Salvo com sucesso')
        setLoading(false)
        const conta_cultura_status = props.cultura?.conta_cultura_statuses
        conta_cultura_status[0].id = etapaAtual
        props.setCultura({
          ...props.cultura,
          conta_cultura_status,
        })
      } catch (err) {
        toast.error('Erro ao processar dados')
        setLoading(false)
      }
    }
  }
  return (
    <div style={{ margin: '0 10px 0 10px' }}>
      {props.cultura?.conta_cultura_statuses[0]?.id != 3 &&
        nextStep &&
        (isLoading ? (
          <Button
            style={{ float: 'right', margin: '20px 0 20px 0' }}
            type="primary"
            loading
          >
            Processando
          </Button>
        ) : (
          <Button
            onClick={handleNextStep}
            style={{ float: 'right', margin: '20px 0 20px 0' }}
            type="primary"
          >
            Avançar Etapa
          </Button>
        ))}

      <Steps
        direction="vertical"
        current={
          props.cultura?.conta_cultura_statuses[0]?.id === 3
            ? props.cultura?.conta_cultura_statuses[0]?.id
            : props.cultura?.conta_cultura_statuses[0]?.id - 1
        }
      >
        <Step
          title="Criação"
          description="Nesta etapa você ainda poderá editar as métricas de sua pesquisa"
        />
        <Step
          title="Envio das Pesquisas"
          description="Sua pesquisa já foi enviada"
        />
        <Step
          title="Finalização"
          description="O fit cultural foi finalizado com sucesso"
        />
      </Steps>
    </div>
  )
}

export default App
