import { toast } from 'react-toastify'

import * as constants from '../constants'

import { parseCulturas, parseVetor } from '../../../utils/utils'

const initialState = {
  destaques: {
    Inovação: [],
    Empreendedora: [],
    Colaboração: [],
    Clã: [],
    Aprendizado: [],
    Complacência: [],
    Regra: [],
    Autoridade: [],
  },
  cultura: [],
  forcas: [],
  disc: [],
  olhares: [],
  gestao: [],
  culturaOrganizacional: {
    loading: true,
    niveisConfianca: [],
    olharLideranca: {
      perceboLider: [],
      frequenciaFeedback: [],
      feedbackContribui: [],
      reconhecimento: [],
      expectativaEntregas: [],
      perfilLiderDireto: [],
    },
    olharParaMudancas: {
      pontosFortes: [],
      impedimento: [],
      principalProblema: [],
      velocidadeAdocao: null,
      engajamento: null,
      proficiencia: null,
    },
    olharParaSi: {
      escolhaEmpresa: [],
      prioridades: [],
      expectativas6meses: [],
      fonteEntusiasmo: [],
    },
    valoresEvidencia: [],
    satisfacaoGeral: [],
  },
  vetor: {
    intensidade: [
      {
        row: '',
        essencia: '',
        exigido: '',
        entrega: '',
      },
    ],
    rct: [],
    edl: [],
    foc: [],
    ode: {
      maior: {
        nome: '',
        score: '',
      },
      menor: {
        nome: '',
        score: '',
      },
    },
    oct: {
      maior: {
        nome: '',
        score: '',
      },
      menor: {
        nome: '',
        score: '',
      },
    },
    tdd: {
      maior: {
        nome: '',
        score: '',
      },
      menor: {
        nome: '',
        score: '',
      },
    },
    cor: [],
    erc: [],
    edc: [],
    principal: {},
    disc: [],
    perfilComp: [],
  },
  perfis: [],
  grupoSelecionado: undefined,
  grupos: [],
  generos: [],
  geracoes: [],
}

export const relatoriosTimes = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_RELATORIOSTIMES_TIMES: {
      if (action.response.status === 204) {
        toast.error('Nenhum time encontrado.')
        return state
      }
      if (action.response.status !== 200) {
        toast.error('Erro ao buscar times.')
        return state
      }

      const { grupos } = action.response.data

      if (action.response.status === 200) {
        return {
          ...state,
          grupos,
          grupoSelecionado: grupos.length > 0 && grupos[0],
        }
      }

      return state
    }

    case constants.SET_RELATORIOSTIMES_TIME_SELECIONADO: {
      const { grupo_id } = action

      const grupo = state.grupos.filter((g) => g.grupo_id === grupo_id)

      return {
        ...initialState,
        grupos: state.grupos,
        grupoSelecionado: {
          grupo_id: grupo_id,
          grupo_nome: grupo.grupo_nome,
        },
      }
    }

    case constants.GET_RELATORIOSTIMES_CULTURA_ORGANIZACIONAL: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error('Erro ao obter Cultura Organizacional')

        return {
          ...state,
          culturaOrganizacional: {
            ...state.culturaOrganizacional,
            loading: false,
          },
        }
      }

      const { culturaOrganizacional } = action.response.data

      return {
        ...state,
        culturaOrganizacional: {
          ...state.culturaOrganizacional,
          ...culturaOrganizacional,

          olharLideranca: {
            ...state.culturaOrganizacional.olharLideranca,
            ...culturaOrganizacional.olharLideranca,
          },
          olharParaMudancas: {
            ...state.culturaOrganizacional.olharParaMudancas,
            ...culturaOrganizacional.olharParaMudancas,
          },
          olharParaSi: {
            ...state.culturaOrganizacional.olharParaSi,
            ...culturaOrganizacional.olharParaSi,
          },
          loading: false,
        },
      }
    }

    case constants.GET_RELATORIOSTIMES_CULTURA: {
      {
        if (action.response.status === 204) {
          return state
        }
        if (action.response.status !== 200) {
          toast.error('Erro ao obter Culturas')
          return state
        }

        const { culturaDesejada, culturaInstalada } = action.response.data

        const { generos, geracoes } = culturaInstalada

        const { cultura, forcas, disc, olhares, gestao } = parseCulturas({
          culturaDesejada,
          culturaInstalada,
        })

        return {
          ...state,
          cultura,
          forcas,
          disc,
          olhares,
          gestao,
          generos,
          geracoes,
        }
      }
    }

    case constants.GET_RELATORIOSTIMES_VETOR: {
      if (action.response.status !== 200) {
        return state
      }

      const { data } = action.response.data

      const vetor = parseVetor(data)

      return {
        ...state,
        vetor,
      }
    }
    case constants.GET_RELATORIOSTIMES_PERFIS: {
      if (action.response.status !== 200) {
        return state
      }

      const { data } = action.response

      const { perfis } = data

      return { ...state, perfis }
    }

    case constants.GET_RELATORIOSTIMES_TOP_ARQ: {
      const { destaques } = action.response.data
      if (action.response.status !== 200) {
        return state
      }

      if (Object.keys(destaques).length > 0)
        return {
          ...state,
          destaques,
        }

      return state
    }

    default:
      return state
  }
}
