import { Button, Layout, Tooltip, Table, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DeleteOutlined } from '@ant-design/icons'
import { deleteObjetivosEstrategicos, getObjetivos } from '../Api/api'
import VincularObjetivo from './VincularObjetivo'

const { Column } = Table

const App = (props) => {
  const [dataSource, setDataSource] = useState([])
  const [isModalVisible, setModalVisible] = useState(false)
  const navigate = useHistory()

  async function deleteObjEstrategicos(cultura, obj, conta) {
    // eslint-disable-next-line no-restricted-globals
    const answer = confirm('Tem Certeza que deseja apagar o objetivo?')
    if (answer) {
      const response = await deleteObjetivosEstrategicos(cultura, obj, conta)
      if (response.success) {
        toast.success('Vinculo excluído')
        getListOfObjetives()
      }
    }
  }

  function handleCancel() {
    setModalVisible(!isModalVisible)
  }

  function showModal() {
    setModalVisible(true)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function getListOfObjetives() {
    let response
    if (props.cultura.subcultura_id) {
      response = await getObjetivos(
        props.cultura.cultura_id,
        props.cultura.subcultura_id
      )
    } else {
      response = await getObjetivos(props.cultura.cultura_id)
    }

    response.conta_cultura_objetivo.forEach((objetivo) => {
      objetivo.desc = objetivo.obj_conta[0].objetivos_estrategico.descricao
      objetivo.key = objetivo.obj_conta[0].objetivos_estrategico.descricao
      objetivo.descricao = objetivo.obj_conta[0].descricao
      objetivo.obj_cliente = objetivo.obj_conta[0].obj_cliente
    })
    fillTable(response.conta_cultura_objetivo)
  }

  useEffect(() => {
    getListOfObjetives()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function fillTable(data) {
    const newObj = []
    data?.forEach((item) =>
      item.obj_conta.forEach((element) => newObj.push(element))
    )
    newObj.forEach((item, index) => {
      item.key = index
      item.actions = ['Excluir']
      item.desc = item?.objetivos_estrategico?.descricao
      item.subcultura_id = data[index].subcultura_id
      item.cultura_id = data[index].cultura_id
      item.obj_conta_id = data[index].obj_conta_id
    })
    setDataSource(newObj)
  }

  return (
    <div>
      {props.cultura.conta_cultura_statuses && (
        <Layout style={{ padding: 20 }}>
          <h1 className="subTitle">
            Cadastre seus objetivos estratégicos e relacione-os com os Drives
            Estratégicos.
          </h1>
          {props.cultura.conta_cultura_statuses[0]?.id === 1 && (
            <div>
              <div>
                {props.cultura.cultura_tipo === 1 ? (
                  <Button
                    onClick={() =>
                      navigate.push('../../../objetivos-estrategicos')
                    }
                    style={{ margin: 5 }}
                    type="primary"
                    className="btn_purple newCultureBtn floatRight"
                  >
                    Novo Objetivo
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      navigate.push('../../../../objetivos-estrategicos')
                    }
                    style={{ margin: 5 }}
                    type="primary"
                    className="btn_purple newCultureBtn floatRight"
                  >
                    Novo Objetivo
                  </Button>
                )}

                <Button
                  style={{ margin: 5 }}
                  type="primary"
                  onClick={showModal}
                  className="btn_purple newCultureBtn floatRight"
                >
                  Novo vínculo
                </Button>
              </div>
            </div>
          )}
          <Table dataSource={dataSource}>
            <Column title="Drives Estratégicos" key="desc" dataIndex="desc" />
            <Column
              title="Objetivo Cliente"
              key="obj_cliente"
              dataIndex="obj_cliente"
            />
            <Column title="Descrição" key="descricao" dataIndex="descricao" />
            <Column
              title="Ação"
              key="actions"
              dataIndex="actions"
              render={(tags, row) => (
                <>
                  {tags.map((tag, index) => {
                    return (
                      tag === 'Excluir' &&
                      props?.cultura?.conta_cultura_statuses[0]?.id === 1 && (
                        <Tooltip title="Remover" placement="bottom">
                          <DeleteOutlined
                            style={{ margin: 5, color: '#30318b' }}
                            key={index}
                            onClick={() =>
                              deleteObjEstrategicos(
                                row.cultura_id,
                                row.subcultura_id,
                                row.obj_conta_id
                              )
                            }
                          />
                        </Tooltip>
                      )
                    )
                  })}
                </>
              )}
            />
          </Table>
          <Modal
            title="Vincular Objetivo Estratégico"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            width={850}
          >
            <VincularObjetivo
              cultura={props.cultura.cultura_id}
              empresa={props.cultura.emp_id}
              grupo={props.cultura.subcultura_id}
              subcultura={props.cultura?.subcultura_id}
              conta={props.cultura.conta_id}
              cultura_status={props?.cultura?.conta_cultura_statuses[0]?.id}
              closeModal={handleCancel}
              updateList={getListOfObjetives}
            />
          </Modal>
        </Layout>
      )}
    </div>
  )
}

export default App
