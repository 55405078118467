import { Card, Empty, Tooltip } from 'antd'
import { Axis, Chart, Interval, Legend } from 'bizcharts'
//
import { cardHeadBlue } from '../../../../../../utils/inlineStyles'
// utils
import { colors } from '../../../../../../utils/utils'
import '../../../../Dashboard.css'

const PerfilComportamental = ({ disc, loadingCultura }) => {
  return (
    <Card
      title="Perfil Comportamental"
      headStyle={cardHeadBlue}
      bodyStyle={{ padding: '8px' }}
      loading={loadingCultura}
    >
      {disc.length === 0 ? (
        <Empty description={false} height={315} />
      ) : (
        <Chart height={315} data={disc} autoFit>
          <Legend />
          <Axis name="Label" />
          <Axis name="Valor" />
          <Tooltip />
          <Interval
            position="Label*Valor"
            color={['Tipo', colors]}
            adjust={[
              {
                type: 'dodge',
                marginRatio: 1 / 32,
              },
            ]}
          />
        </Chart>
      )}
    </Card>
  )
}

export default PerfilComportamental
