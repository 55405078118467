import React, { useCallback, useEffect, useState } from 'react'

import moment from 'moment'

import { Helmet } from 'react-helmet'

import { Row, Tag, Alert, Card, Space, Select } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

import DatePicker, { DateObject } from 'react-multi-date-picker'

import { getGrupos, getGrupoById, getGrupoByIdGET } from '../utils'

import {
  Col,
  DatePickerButton,
  ContainerSelecionadas,
  FlexCard,
} from './styles'

import {
  findConfianca,
  findElement,
  findGender,
  findGeneration,
} from '../utils/findElement'

// Api
import api from '../../../services/api'

// Utils
import { IRelatorioParsed } from '../@types'

import { mergeRelatorios, parseRelatorio, filterRelatorios } from '../utils'
import { Relatorio } from '../components/Relatorio'

const initialDataSource: IRelatorioParsed = {
  culturaOrganizacional: {
    valoresEvidencia: [],
    satisfacaoGeral: [],
    niveisConfianca: [],
    olharLideranca: {
      expectativaEntregas: [],
      feedbackContribui: [],
      frequenciaFeedback: [],
      perceboLider: [],
      perfilLiderDireto: [],
      reconhecimento: [],
    },
    olharParaMudancas: {
      impedimento: [],
      pontosFortes: [],
      principalProblema: [],
      engajamento: { Tipo: '', key: '' },
      velocidadeAdocao: { Tipo: '', key: '' },
      proficiencia: { Tipo: '', key: '' },
    },
    olharParaSi: {
      escolhaEmpresa: [],
      expectativas6meses: [],
      fonteEntusiasmo: [],
      prioridades: [],
    },
  },
  culturaInstalada: {
    cultura: [],
    forcas: [],
    olhares: [],
    gestao: [],
  },
  generos: [],
  geracoes: [],
}

const { Option } = Select

function RelatorioComparativo() {
  const [selectedDates, setSelectedDates] = useState<DateObject[]>([])

  const [loading, setLoading] = useState(false)

  const [grupos, setGrupos] = useState([])
  const [selectedGroup, setSelectedGroup] = useState()
  const [selectedGroupId, setSelectedGroupId] = useState('')

  const [minDate, setMinDate] = useState<Date>()

  const [dataSource, setDataSource] =
    useState<IRelatorioParsed>(initialDataSource)

  const [dadosComparativo, setDadosComparativo] =
    useState<IRelatorioParsed>(initialDataSource)

  // Load relatorio atual
  const loadRelatorio = useCallback(async () => {
    setLoading(true)
    try {
      const response = await api.get('/dashboard/v2/culturas')

      if (response.status === 200) {
        const { data } = response

        setMinDate(data.minDate)

        const parsedData = parseRelatorio(data)

        setDataSource(parsedData)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    loadRelatorio()
  }, [loadRelatorio])

  const handleChangeSelectedDates = useCallback((selectedDates: any) => {
    setSelectedDates(selectedDates)
  }, [])

  const loadRelatorioByDate = useCallback(
    async (date: Date) => {
      setLoading(true)
      try {
        const response = await api.get('/dashboard/v2/culturas', {
          params: { date: date.toISOString() },
        })

        if (response.status === 200) {
          const { data } = response

          const parsedData = parseRelatorio({
            ...data,
            date: moment(date).format('DD/MM/YYYY'),
          })

          const mergedData = mergeRelatorios({
            dataSource,
            newData: parsedData,
          })

          setDataSource(mergedData)
        } else {
          throw new Error('Dados de histórico não disponíveis.')
        }
      } catch (err) {
        console.error(err)
        setSelectedDates((state) =>
          state.filter(
            (selectedDate) =>
              selectedDate.toDate().toISOString() !== date.toISOString()
          )
        )
      } finally {
        setLoading(false)
      }
    },
    [dataSource]
  )

  useEffect(() => {
    if (dataSource.culturaInstalada.cultura.length > 0) {
      const removedDate = Object.keys(
        dataSource.culturaInstalada.cultura[0]
      ).filter(
        (key) =>
          key !== 'key' &&
          key !== 'Tipo' &&
          key !== 'Instalado' &&
          !selectedDates.find(
            (date) => moment(date.toDate()).format('DD/MM/YYYY') === key
          )
      )

      if (removedDate.length > 0) {
        const filteredDataSouce = filterRelatorios({
          dataSource,
          removeDate: removedDate[0],
        })

        setDataSource(filteredDataSouce)
      }
    }
  }, [dataSource, dataSource.culturaInstalada.cultura, selectedDates])

  useEffect(() => {
    function filterByDate() {
      const newDates = selectedDates.filter(
        (date) =>
          !dataSource.culturaInstalada.cultura.find(
            (item) => item[moment(date.toDate()).format('DD/MM/YYYY')]
          )
      )

      if (newDates.length > 0) {
        newDates.forEach((date) => {
          loadRelatorioByDate(date.toDate())
        })
      }
    }
    filterByDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates])

  useEffect(() => {
    getGruposFromApi()
  }, [])

  useEffect(() => {
    getaGroup()
  }, [selectedGroupId])

  async function getGruposFromApi() {
    const response = await getGrupos()
    const { grupos } = response
    setGrupos(grupos)
  }

  async function getaGroup() {
    const newDataSource = dataSource

    if (selectedGroupId) {
      const response = await getGrupoById(selectedGroupId)

      const responseGet = await getGrupoByIdGET(selectedGroupId)
      newDataSource.culturaInstalada.cultura.forEach((cultura) => {
        const newValue: { Grupo?: string } = findElement(
          cultura.Tipo,
          response.culturaInstalada?.cultura
        )
        cultura.Grupo = `${newValue?.Grupo}%` || ''
      })

      newDataSource.culturaInstalada.forcas.forEach((cultura) => {
        const newValue: { Grupo?: string } = findElement(
          cultura.Tipo,
          response.culturaInstalada?.forcas
        )
        cultura.Grupo = `${newValue.Grupo}%` || ''
      })

      newDataSource.culturaInstalada.olhares.forEach((cultura) => {
        const newValue: { Grupo?: string } = findElement(
          cultura.Tipo,
          response.culturaInstalada?.olhares
        )
        cultura.Grupo = `${newValue.Grupo}%` || ''
      })

      newDataSource.culturaInstalada.gestao.forEach((cultura) => {
        const newValue: { Grupo?: string } = findElement(
          cultura.Tipo,
          response.culturaInstalada?.gestao
        )
        cultura.Grupo = `${newValue.Grupo}%` || ''
      })

      newDataSource.generos.forEach((genero) => {
        const newValue = findGender(
          genero.Tipo,
          response.culturaInstalada?.generos
        )
        genero.Grupo = `${newValue}%`
      })

      newDataSource.geracoes.forEach((geracao) => {
        const newValue = findGeneration(
          geracao.Tipo,
          response.culturaInstalada?.geracoes
        )
        geracao.Grupo = `${newValue}%`
      })

      newDataSource.culturaOrganizacional.niveisConfianca.forEach(
        (confianca) => {
          const newValue = findConfianca(
            confianca.Tipo,
            responseGet.culturaOrganizacional?.niveisConfianca
          )
          confianca.Grupo = `${newValue}%`
        }
      )

      newDataSource.culturaOrganizacional.satisfacaoGeral.forEach(
        (satisfacao) => {
          const newValue = findConfianca(
            satisfacao.Tipo,
            responseGet.culturaOrganizacional?.niveisConfianca
          )
          satisfacao.Grupo = `${newValue}%`
        }
      )
      newDataSource.culturaOrganizacional?.olharLideranca.perfilLiderDireto.forEach(
        (lideranca) => {
          const newValue = findConfianca(
            lideranca.Tipo,
            responseGet.culturaOrganizacional?.olharLideranca?.perfilLiderDireto
          )
          lideranca.Grupo = `${newValue}%`
        }
      )

      newDataSource.culturaOrganizacional?.olharLideranca.expectativaEntregas.forEach(
        (entregas) => {
          const newValue = findConfianca(
            entregas.Tipo,
            responseGet.culturaOrganizacional?.olharLideranca
              ?.expectativaEntregas
          )
          entregas.Grupo = `${newValue}%`
        }
      )

      newDataSource.culturaOrganizacional?.olharLideranca.reconhecimento.forEach(
        (reconhecimento) => {
          const newValue = findConfianca(
            reconhecimento.Tipo,
            responseGet.culturaOrganizacional?.olharLideranca?.reconhecimento
          )
          reconhecimento.Grupo = `${newValue}%`
        }
      )

      newDataSource.culturaOrganizacional?.olharLideranca.feedbackContribui.forEach(
        (reconhecimento) => {
          const newValue = findConfianca(
            reconhecimento.Tipo,
            responseGet.culturaOrganizacional?.olharLideranca?.feedbackContribui
          )
          reconhecimento.Grupo = `${newValue}%`
        }
      )
      newDataSource.culturaOrganizacional?.olharLideranca.frequenciaFeedback.forEach(
        (reconhecimento) => {
          const newValue = findConfianca(
            reconhecimento.Tipo,
            responseGet.culturaOrganizacional?.olharLideranca
              ?.frequenciaFeedback
          )
          reconhecimento.Grupo = `${newValue}%`
        }
      )

      newDataSource.culturaOrganizacional?.olharParaSi.fonteEntusiasmo.forEach(
        (reconhecimento) => {
          const newValue = findConfianca(
            reconhecimento.Tipo,
            responseGet.culturaOrganizacional?.olharParaSi.fonteEntusiasmo
          )
          reconhecimento.Grupo = `${newValue}%`
        }
      )
    }

    const mergedData = mergeRelatorios({
      dataSource,
      newData: newDataSource,
    })

    setDataSource(mergedData)
  }

  return (
    <>
      <Helmet>
        <title>TeamHub | Relatório Comparativo</title>
      </Helmet>

      <Col span={24}>
        <Row gutter={16}>
          <Col span={24} style={{ marginBottom: '16px' }}>
            <Card>
              <div>
                <Alert
                  message="O relatório gerado apresenta os indicadores atuais (Instalado). Selecione mais datas para comparar valores de outros momentos da sua empresa."
                  type="info"
                  style={{ marginBottom: '16px' }}
                />
              </div>
              <FlexCard
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                <Space size="large">
                  <div>
                    <span>Selecione um time para comparar:</span>
                    <Select
                      placeholder="Selecione"
                      onChange={(id, valores: any) => {
                        setSelectedGroup(id)
                        setSelectedGroupId(valores.key)
                      }}
                      style={{ width: '100%', marginBottom: '16px' }}
                      showSearch
                      filterOption={(input, option) =>
                        option?.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={selectedGroup}
                      data-html2canvas-ignore
                    >
                      {grupos.map(({ grupo_nome, grupo_id }) => (
                        <Option value={grupo_nome} key={grupo_id}>
                          {grupo_nome}
                        </Option>
                      ))}
                    </Select>
                    <span>Datas selecionadas:</span>

                    <DatePicker
                      value={selectedDates}
                      onChange={(dates) =>
                        Array.isArray(dates)
                          ? handleChangeSelectedDates(dates)
                          : handleChangeSelectedDates([dates])
                      }
                      multiple
                      sort
                      animation
                      type="custom"
                      numberOfMonths={1}
                      currentDate={new DateObject()}
                      minDate={minDate}
                      maxDate={new DateObject()}
                      weekDays={[['do', '2˚', '3˚', '4˚', '5˚', '6˚', 'sá']]}
                      months={[
                        [
                          'Janeiro',
                          'Fevereiro',
                          'Março',
                          'Abril',
                          'Maio',
                          'Junho',
                          'Julho',
                          'Agosto',
                          'Setembro',
                          'Outubro',
                          'Novembro',
                          'Dezembro',
                        ],
                      ]}
                      showOtherDays
                      render={(stringDate: string, openCalendar: any) => {
                        return (
                          <DatePickerButton
                            type="primary"
                            onClick={openCalendar}
                          >
                            <PlusOutlined />
                          </DatePickerButton>
                        )
                      }}
                    />

                    <ContainerSelecionadas>
                      {selectedDates.map((currentDate, index) => (
                        <Tag
                          key={index}
                          closable
                          onClose={(e) => {
                            e.preventDefault()
                            handleChangeSelectedDates(
                              selectedDates.filter(
                                (date) => date !== currentDate
                              )
                            )
                          }}
                        >
                          {moment(currentDate.toDate()).format('DD/MM/YYYY')}
                        </Tag>
                      ))}
                    </ContainerSelecionadas>
                  </div>
                </Space>
              </FlexCard>
            </Card>
          </Col>
        </Row>

        <Relatorio
          selectedDates={selectedDates}
          loading={loading}
          dataSource={dataSource}
          selectedGroup={selectedGroup}
          selectedGroupId={selectedGroupId}
          setLoading={setLoading}
          updateGroup={getaGroup}
        />
      </Col>
    </>
  )
}

export default RelatorioComparativo
