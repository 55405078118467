import React from 'react'

import { Form, Select, Input } from 'antd'

export default function NovaEtapa({
  onFinish,
  usuarios,
  initialValues = null,
}) {
  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      id="nova-etapa-requisicao-ps"
      onFinish={onFinish}
      form={form}
      initialValues={initialValues}
    >
      <Form.Item
        label="Nome da etapa"
        name="nome"
        rules={[
          {
            required: true,
            message: 'Por favor, informe um título para a etapa.',
          },
        ]}
      >
        <Input type="text" placeholder="Título/descrição da etapa" />
      </Form.Item>
      <Form.Item
        label="Responsável (Aprovador)"
        name="user_aprovador_id"
        rules={[
          {
            required: true,
            message:
              'Por favor, informe um usuário para se tornar aprovador desta etapa.',
          },
        ]}
      >
        <Select
          options={usuarios}
          showSearch
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </Form.Item>
    </Form>
  )
}
