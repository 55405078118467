export const tinyDefaultConfig = {
  height: 240,
  menubar: false,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
  ],
  toolbar: `undo redo | formatselect | bold italic backcolor |
    alignleft aligncenter alignright alignjustify |
    bullist numlist outdent indent | removeformat | help`,
}

export const tinyApiKey = 'izkbdj020bs1na2oycyihhr1licqhgrqyxxhob0fui2uvgyw'
