import { Card, Empty, Tooltip } from 'antd'
import { Axis, Chart, Coordinate, Interval, Legend } from 'bizcharts'
import { cardHeadBlue } from '../../../../../../utils/inlineStyles'
// utils
import { colors, forcasRange } from '../../../../../../utils/utils'

const ForcasEstrategicas = ({ loadingCultura, forcas }) => {
  return (
    <Card
      title="Forças estratégicas"
      bodyStyle={{ padding: '8px' }}
      headStyle={cardHeadBlue}
      loading={loadingCultura}
    >
      {forcas.length === 0 ? (
        <Empty description={false} height={340} />
      ) : (
        <Chart
          height={340}
          data={forcas.sort(function (a, b) {
            if (a.Tipo < b.Tipo) {
              return -1
            }
            if (a.Tipo > b.Tipo) {
              return 1
            }
            return 0
          })}
          autoFit
          padding={[0, 50, 0, 120]}
          scale={forcasRange}
        >
          <Legend />
          <Coordinate transpose scale={[1, -1]} />
          <Axis
            name="Tipo"
            label={{
              offset: 12,
            }}
          />
          <Axis name="Valor" position="right" />
          <Tooltip />
          <Interval
            position="Tipo*Valor"
            color={['Label', colors]}
            adjust={[
              {
                type: 'dodge',
                marginRatio: 1 / 32,
              },
            ]}
            label={[
              'Valor',
              (valor) => {
                return `${valor.toFixed(2)}%`
              },
            ]}
          />
        </Chart>
      )}
    </Card>
  )
}

export default ForcasEstrategicas
