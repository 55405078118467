import { Col, Row } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'

const Dashboard = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>TeamHub | Dashboard</title>
      </Helmet>
      <Row gutter={16}>
        <Col span={24}>{children}</Col>
      </Row>
    </>
  )
}

export default Dashboard
