import { connect } from 'react-redux'

// Components
import PesquisasController from './PesquisasController'

// Actions
import {
  getPesquisasDisponiveis,
  getAvaliacoesDisponiveis,
} from './actions/actionsPesquisas'

const mapStateToProps = ({ pesquisas }) => {
  return {
    ...pesquisas,
  }
}

export default connect(mapStateToProps, {
  getPesquisasDisponiveis,
  getAvaliacoesDisponiveis,
})(PesquisasController)
