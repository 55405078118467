import styled, { css } from 'styled-components'

import { darken, lighten } from 'polished'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;
  min-height: 0;
  flex: 1;
`

export const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 16px 16px;
  background-color: #fff;
  box-shadow: 0 5px 5px -5px;

  & h1 {
    font-size: 24px;
    font-weight: 500;
  }

  & h2 {
    font-size: 18px;
    color: #718096;
  }

  .ps-kanban__button__finalizar {
    color: green;
    border-color: green;
    :hover {
      color: ${lighten(0.1, 'green')};
      border-color: ${lighten(0.1, 'green')};
    }
  }

  .ps-kanban__processo-details__container {
    display: flex;
    flex-direction: row;
    margin-left: 64px;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 500;
    }
    .anticon {
      margin-right: 8px;
    }
  }

  .select-filter {
    display: flex;
    margin-left: 16px;

    .ant-select {
      width: 100%;
    }
  }

  @media screen and (max-width: 1500px) {
    flex-direction: column;
    align-items: center;

    .ps-kanban__button__container {
      margin: 0;
    }

    .ps-kanban__processo-details__container {
      margin: 0;
    }

    > *:not(:first-child) {
      margin-top: 16px;
    }

    .select-filter {
      display: flex;
      width: 100%;
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .ps-kanban__processo-details__container {
      flex-direction: column;
      .ant-divider {
        display: none;
      }

      > *:not(:first-child) {
        margin-top: 16px;
      }
    }

    .ps-kanban__button__container {
      flex-direction: column;
      align-items: center;
      width: 100%;

      > .ant-btn + .ant-btn {
        margin: 0;
        margin-top: 16px;
      }

      > .ant-btn {
        width: 85%;
      }

      .select-filter {
        width: 85%;
        margin-left: 0;
        margin-top: 16px;
      }
    }
  }
`
