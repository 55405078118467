import React from 'react'

import { Typography, Row, Divider, Alert, Col, Button } from 'antd'

import { PlayCircleFilled } from '@ant-design/icons'

import { CompanyLogo, Container, Placeholder } from '../styles'

const { Title } = Typography

function ApresentacaoQuiz({
  preview,
  companyLogo,
  informacoesQuiz,
  handleIniciar,
}) {
  const { titulo, objetivo, instrucoes, tempo_limite } = informacoesQuiz

  return (
    <Container>
      <div>
        <CompanyLogo src={companyLogo} large />
      </div>

      {titulo ? (
        <Title level={2}>{titulo}</Title>
      ) : (
        <Placeholder large>O título do teste será exibido aqui</Placeholder>
      )}

      <Row>
        {objetivo ? (
          <p>{objetivo}</p>
        ) : (
          <Placeholder>A descricação do teste será exibida aqui</Placeholder>
        )}
      </Row>

      <Row>
        {instrucoes ? (
          <p>{instrucoes}</p>
        ) : (
          <Placeholder>As instrucões do teste serão exibidas aqui</Placeholder>
        )}
      </Row>

      <Divider />

      <Row
        justify="center"
        align="middle"
        gutter={20}
        style={{ width: '100%' }}
      >
        <Col xs={24} md={6}>
          {tempo_limite ? (
            <Alert message={`Tempo limite total: ${tempo_limite} minutos`} />
          ) : (
            <Alert
              message={
                <Placeholder> O tempo limite aparecerá aqui</Placeholder>
              }
            />
          )}
        </Col>
        <Col xs={24} md={6}>
          <Button
            color="#f1f1f1"
            type="primary"
            onClick={handleIniciar}
            size="large"
            icon={<PlayCircleFilled />}
          >
            Iniciar Teste
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default ApresentacaoQuiz
