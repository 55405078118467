import { useState } from 'react'
import { Button, DatePicker, Form, Input, Spin } from 'antd'
import { toast } from 'react-toastify'
import moment from 'moment'
import 'moment/locale/pt-br'
import { updateCulturaDesejada } from '../Api/api'

const App = (props) => {
  // eslint-disable-next-line prefer-const
  let { data_inicio, data_final, limite_dentro, limite_maximo, descricao } =
    props.settings

  data_inicio = moment(data_inicio, 'YYYY-MM-DD').format('DD/MM/YYYY')
  data_final = moment(data_final, 'YYYY-MM-DD').format('DD/MM/YYYY')

  const [settingsState, setInitial] = useState({
    data_inicio: moment(data_inicio, 'DD/MM/YYYY'),
    data_final: moment(data_final, 'DD/MM/YYYY'),
    limite_dentro,
    limite_maximo,
    descricao,
  })

  const [isLoading, setLoading] = useState(false)

  function validadeDate(data) {
    if (moment(data.data_inicio).isBefore(data.data_final)) return true
    return false
  }

  const onFinish = async (values) => {
    setLoading(true)
    const { data_inicio, data_final, limite_dentro, limite_maximo, descricao } =
      values
    const dataToSave = {}

    function handleCreateObjectToSave() {
      if (
        data_inicio !== settingsState.data_inicio ||
        data_final !== settingsState.data_final
      ) {
        dataToSave.data_inicio = moment(data_inicio, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        )
        dataToSave.data_final = moment(data_final, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        )
      }

      if (limite_dentro !== settingsState.limite_dentro) {
        dataToSave.limite_dentro = limite_dentro
      }

      if (limite_maximo !== settingsState.limite_maximo) {
        dataToSave.limite_maximo = limite_maximo
      }

      dataToSave.descricao = descricao
    }

    handleCreateObjectToSave()
    if (dataToSave.data_inicio) {
      if (!validadeDate(dataToSave)) {
        toast.error('Verifique as datas, Data inicial maior que a final')
        setLoading(false)
        return
      }
    }

    try {
      await updateCulturaDesejada(props?.settings?.cultura_id, dataToSave)
      toast.success('Salvo com sucesso')
      setLoading(false)
      props.changeState({
        ...props.cultura,
        data_inicio,
        data_final,
        limite_dentro,
        limite_maximo,
        descricao,
      })
    } catch (err) {
      toast.error('Erro ao processar dados')
      setLoading(false)
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Form
      name="basic"
      wrapperCol={{
        span: 8,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={settingsState}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label="Data Inicial"
        name="data_inicio"
        rules={[
          {
            required: true,
            message: 'Por favor, informe a data inicial',
          },
        ]}
      >
        <DatePicker
          format="DD/MM/YYYY"
          disabled={props?.settings?.conta_cultura_statuses[0]?.id != 1}
        />
      </Form.Item>

      <Form.Item
        label="Data Final"
        name="data_final"
        rules={[
          {
            required: true,
            message: 'Por favor, informe a data final',
          },
        ]}
      >
        <DatePicker
          format="DD/MM/YYYY"
          disabled={props?.settings?.conta_cultura_statuses[0].id != 1}
        />
      </Form.Item>

      <Form.Item
        label="Limite de dentro (em %)"
        name="limite_dentro"
        rules={[
          {
            required: true,
            message: 'Por favor, informe o limite de dentro',
          },
        ]}
      >
        <Input
          type="number"
          disabled={props?.settings?.conta_cultura_statuses[0].id == 3}
          style={{ maxWidth: 80 }}
        />
      </Form.Item>

      <Form.Item
        label="Limite de Fora (em %)"
        name="limite_maximo"
        rules={[
          {
            required: true,
            message: 'Por favor, informe o limite de fora',
          },
        ]}
      >
        <Input
          type="number"
          disabled={props?.settings?.conta_cultura_statuses[0].id == 3}
          style={{ maxWidth: 80 }}
        />
      </Form.Item>

      <Form.Item label="Descrição" name="descricao">
        <Input />
      </Form.Item>

      {isLoading ? (
        <Spin />
      ) : (
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      )}
    </Form>
  )
}
export default App
