import React, { Component } from 'react'

// Components
import OnboardingView from './OnboardingView'

// Services
import { getContaURL } from '../../services/auth'

// Utils
import history from '../../utils/history'

class OnboardingController extends Component {
  state = {
    currentStep: -1,
  }

  componentDidMount = () => {
    const { onboarding } = this.props

    if (onboarding) {
      if (onboarding.obj_enable) this.setState({ currentStep: 0 })
      else if (onboarding.val_enable) this.setState({ currentStep: 1 })
      else this.setState({ currentStep: 2 })
    } else this.setState({ currentStep: 0 })
  }

  nextStep = () => {
    const { postOnboarding } = this.props
    const { currentStep } = this.state
    const contaURL = getContaURL()

    postOnboarding({ currentStep })

    return currentStep < 2
      ? this.setState({ currentStep: currentStep + 1 })
      : history.push(`/${contaURL}/dashboard`)
  }

  prevStep = () => {
    const { postOnboarding } = this.props
    const { currentStep } = this.state
    const contaURL = getContaURL()

    postOnboarding({ currentStep })

    return currentStep > 0
      ? this.setState({ currentStep: currentStep - 1 })
      : history.push(`/${contaURL}/dashboard`)
  }

  render() {
    const { nextStep, prevStep } = this
    const { currentStep } = this.state

    return (
      <OnboardingView
        nextStep={nextStep}
        prevStep={prevStep}
        currentStep={currentStep}
      />
    )
  }
}

export default OnboardingController
