import React from 'react'

import moment from 'moment'

import 'moment-timezone'

import { Col, Row, Input, Button, Popconfirm } from 'antd'

import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { LogsContainer, Log, Container } from './styles'

import Select, { Option } from '../../../../components/select/Select'

function TabProcessoSeletivo({
  processoSelected,
  processos,
  logs,
  handleCreatePsLog,
  handleDeletePsLog,
  handleChangePsSelected,
  observacao,
  handleChangeObservacao,
}) {
  return (
    <Col span={24}>
      <Row style={{ marginBottom: '0px' }}>
        <Col xs={24} sm={24}>
          <Select
            placeholder="Selecione"
            onChange={(option) => handleChangePsSelected(option)}
            style={{ width: '100%', marginTop: '0xpx', marginBottom: '0px' }}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            value={processoSelected || null}
            data-html2canvas-ignore
          >
            {processos.map(({ processo_id, cargo, criadoem }) => (
              <Option value={processo_id} key={processo_id}>
                {cargo.cargo_nome} -{' '}
                {moment(criadoem)
                  .utc()
                  .tz(moment.tz.guess())
                  .format('DD/MM/YYYY')}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} style={{ padding: '0px' }}>
          <Container>
            {logs.length > 0 && (
              <LogsContainer>
                {logs.map((log) => {
                  if (parseInt(log.observacao) === 1) {
                    return (
                      <Log>
                        <div className="log-header">
                          <span className="log-username">
                            {log.responsavel.pes_nome}{' '}
                          </span>
                          <span className="log-date">
                            {moment(log.createdAt)
                              .utc()
                              .tz(moment.tz.guess())
                              .format('DD/MM/YY HH:mm:ss')}
                          </span>
                          <Popconfirm
                            title="Tem certeza que deseja excluir essa observação?"
                            onConfirm={() => handleDeletePsLog(log.log_id)}
                            okText="Sim"
                            cancelText="Não"
                            icon={
                              <ExclamationCircleFilled
                                style={{ color: '#ef5472' }}
                              />
                            }
                          >
                            <DeleteOutlined style={{ color: 'red' }} />
                          </Popconfirm>
                        </div>
                        <span className="log-obs">{log.text}</span>
                      </Log>
                    )
                  }
                  return (
                    <Log>
                      <div className="log-header">
                        <span className="log-username">
                          {log.responsavel.pes_nome}{' '}
                        </span>
                        <span>{log.text}</span>
                      </div>
                      <span className="log-date">
                        {moment(log.createdAt)
                          .utc()
                          .tz(moment.tz.guess())
                          .format('DD/MM/YY HH:mm:ss')}
                      </span>
                    </Log>
                  )
                })}
              </LogsContainer>
            )}
            <Input.TextArea
              placeholder="Nova observação"
              value={observacao}
              onChange={(e) => handleChangeObservacao(e.target.value)}
            />
            <div className="btn-row">
              <Button type="primary" onClick={handleCreatePsLog}>
                Registrar Observação
              </Button>
            </div>
          </Container>
        </Col>
      </Row>
    </Col>
  )
}

export default TabProcessoSeletivo
