import { FullscreenOutlined } from '@ant-design/icons'
import { Card, Row, Tooltip } from 'antd'
import { Axis, Chart, Coordinate, Legend, Line, Point } from 'bizcharts'
import Empty from '../../../../../../components/empty'
//
import { cardHeadBlue } from '../../../../../../utils/inlineStyles'
// utils
import {
  arqRange,
  ArquetiposCulturaisDetalhes,
  colors,
} from '../../../../../../utils/utils'
import '../../../../Dashboard.css'

const ArquetiposCulturais = ({ cultura, loadingCultura, showModal }) => {
  return (
    <Card
      title="Arquétipos Culturais"
      bodyStyle={{ padding: '8px' }}
      headStyle={cardHeadBlue}
      extra={<ArquetiposCulturaisDetalhes />}
      loading={loadingCultura}
    >
      {cultura.length === 0 ? (
        <Empty description={false} height={340} />
      ) : (
        <Chart height={340} data={cultura} scale={arqRange} autoFit>
          <Legend />
          <Coordinate type="polar" radius={0.8} />
          <Axis
            name="Tipo"
            line={null}
            tickLine={null}
            grid={{
              lineStyle: {
                lineDash: null,
              },
              hideFirstLine: false,
            }}
          />
          <Tooltip />
          <Axis
            name="Valor"
            line={null}
            tickLine={null}
            grid={{
              type: 'polygon',
              lineStyle: {
                lineDash: null,
              },
              alternateColor: 'rgba(0, 0, 0, 0.04)',
            }}
          />
          <Line position="Tipo*Valor" color={['Label', colors]} size={2} />
          <Point
            position="Tipo*Valor"
            color={['Label', colors]}
            shape="circle"
            size={4}
            style={{
              stroke: '#fff',
              lineWidth: 1,
              fillOpacity: 1,
            }}
          />
        </Chart>
      )}

      <Row type="flex" justify="end">
        <Tooltip title="Ampliar gráfico">
          <a onClick={showModal}>
            <FullscreenOutlined />
          </a>
        </Tooltip>
      </Row>
    </Card>
  )
}

export default ArquetiposCulturais
