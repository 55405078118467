import { connect } from 'react-redux'
import * as actions from './actions/actionsCandidatos'

// Components
import CandidatosController from './CandidatosController'

// Actions

const mapStateToProps = ({}) => {
  return {}
}

export default connect(mapStateToProps, { ...actions })(CandidatosController)
