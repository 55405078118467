import React, { useCallback, useEffect, useState, useMemo } from 'react'

import {
  Col,
  Row,
  Card,
  Input,
  Button,
  Divider,
  Alert,
  Typography,
  Tooltip,
  Modal,
} from 'antd'

import {
  PlusOutlined,
  UploadOutlined,
  YoutubeOutlined,
  GlobalOutlined,
  InstagramOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'

import { UploadFile } from 'antd/lib/upload/interface'

import { toast } from 'react-toastify'
import { getContaURL } from '../../../../services/auth'

import {
  Upload,
  LinkPaginaTrabalheConosco as LinkPaginaTrabalheConoscoStyled,
  BotoesSalvar,
} from './styles'

import api from '../../../../services/api'

import TinyEditor from '../../../../components/TinyEditor'

const { Text, Link } = Typography

interface ICustomParam {
  param_id: number
  value: string
  url?: string
}

interface IParam {
  id: number
  param: string
  value: string
  customParam?: ICustomParam
}

interface IHandleChangeParam {
  param: string
  value: string
}

interface IParamsValuesIndexable {
  [key: string]: string
}

interface IAssetsGroup {
  [key: string]: boolean
}

interface IHandleUpload {
  name: string
  file: any
  onSuccess: any
  onError: any
  onProgress: any
}

interface IGalleryImageResponse {
  id: number
  emp_id: number
  conta_id: number
  value: string
  url: string
}

const contaURL = getContaURL()

const ConfigTrabalheConosco: React.FC = () => {
  const [params, setParams] = useState<IParam[]>([])

  const [updateTermo, setUpdateTermo] = useState('')

  const [updateStatus, setUpdateStatus] = useState(1)

  const [idTermo, setIdTermo] = useState(0)

  const [savingAssets, setSavingAssets] = useState<IAssetsGroup>({})

  const [loadingParams, setLoadingParams] = useState(false)

  const [savingParams, setSavingParams] = useState(false)

  const [galleryImages, setGalleryImages] = useState<UploadFile[]>([])

  const [errorUpload, setErrorUpload] = useState(false)

  const [modalTermoVisible, setModalTermoVisible] = useState(false)

  const [initialValue, setInitialValue] = useState(undefined)
  const [initialValueTeam, setInitialValueTeam] = useState(undefined)
  const [initialValueTermo, setInitialValueTermo] = useState(undefined)

  const loadTermo = useCallback(async () => {
    setLoadingParams(true)

    try {
      const response = await api.get('/termo-e-condicoes/conta')

      if (response.status === 200) {
        const { termo, ativo, termo_id } = response.data.termo
        setInitialValueTermo(termo)
        setUpdateTermo(termo)
        setUpdateStatus(ativo)
        setIdTermo(termo_id)
      }
    } finally {
      setLoadingParams(false)
    }
  }, [])

  const loadParams = useCallback(async () => {
    setLoadingParams(true)

    try {
      const response = await api.get('/trabalheConosco/params')

      if (response.status === 200) {
        setInitialValue(response.data[6].customParam.value)
        setInitialValueTeam(response.data[9].customParam.value)
        setParams(response.data)
      }
    } finally {
      setLoadingParams(false)
    }
  }, [])

  const loadGalleryImages = useCallback(async () => {
    const response = await api.get<IGalleryImageResponse[]>(
      '/trabalheConosco/gallery'
    )

    if (response.status === 200) {
      setGalleryImages(
        response.data.map((image) => ({
          uid: image.id.toString(),
          name: image.value,
          status: 'done',
          size: 0,
          type: 'img',
          url: image.url,
          thumbUrl: image.url,
        }))
      )
    }
  }, [])

  useEffect(() => {
    loadParams()
    loadGalleryImages()
    loadTermo()
  }, [loadGalleryImages, loadParams, loadTermo])

  const handleSaveParams = useCallback(async () => {
    setSavingParams(true)

    const updatedParams = params
      .filter((param) => param.customParam)
      .map((param) => ({ ...param.customParam }))

    try {
      const response = await api.put('/trabalheConosco/params', {
        params: updatedParams,
      })

      if (response.status === 200) {
        toast.success('Parâmetros salvos com sucesso.')
        loadParams()
      }
    } finally {
      setSavingParams(false)
      setModalTermoVisible(false)
    }
  }, [loadParams, params])

  const handleSaveTermo = useCallback(async () => {
    setSavingParams(true)
    setModalTermoVisible(true)

    if (!updateTermo) {
      toast.error('O campo conteúdo deve ser preenchido!')
    } else {
      try {
        const response = await api.put('/termo-e-condicoes/update', {
          termo: updateTermo,
          ativo: updateStatus,
          termo_id: idTermo,
        })

        if (response.status === 200) {
          toast.success('Termo alterado com sucesso!')
          loadParams()
          loadTermo()
        }
      } finally {
        setSavingParams(false)
        setTimeout(() => setModalTermoVisible(false), 6000)
      }
    }
  }, [loadParams, loadTermo, updateTermo, updateStatus, idTermo])

  const handleSaveStatus = useCallback(async () => {
    try {
      const response = await api.put('/termo-e-condicoes/update-status', {
        ativo: updateStatus === 1 ? 0 : 1,
        termo_id: idTermo,
      })

      if (response.status === 200) {
        toast.success('Termo alterado com sucesso!')
        loadParams()
        loadTermo()
      }
    } finally {
      setSavingParams(false)
    }
  }, [loadParams, loadTermo, updateStatus, idTermo])

  const handleChangeParam = useCallback(
    ({ param, value }: IHandleChangeParam) => {
      setParams((params) =>
        params.map((p) =>
          p.param === param
            ? p.customParam
              ? {
                  ...p,
                  customParam: {
                    ...p.customParam,
                    value,
                  },
                }
              : {
                  ...p,
                  customParam: {
                    param_id: p.id,
                    value,
                  },
                }
            : p
        )
      )
    },
    []
  )

  const handleChangeText = useCallback(
    ({ param, value }: IHandleChangeParam) => {
      setUpdateTermo(value)
    },
    []
  )
  /* const handleStatusTermo = () => {
    setUpdateStatus(0);
    handleSaveTermo();
  }; */

  const parseParamsValues = useCallback((param: IParam | undefined) => {
    return param
      ? param.customParam
        ? param.customParam.value
        : param.value
      : ''
  }, [])

  const paramsValues = useMemo((): IParamsValuesIndexable => {
    const paramsParsed = params.reduce((acc, next) => {
      return {
        ...acc,
        [next.param]: parseParamsValues(next),
      }
    }, {})

    return paramsParsed
  }, [params, parseParamsValues])

  const handleUploadAsset = useCallback(
    async ({ name, file, onSuccess, onError, onProgress }: IHandleUpload) => {
      const formData = new FormData()

      formData.append('asset', file)

      setSavingAssets((state) => ({ ...state, [name]: true }))
      try {
        const response = await api.patch(
          `/trabalheConosco/assets/${name}`,
          formData,
          {
            onUploadProgress: ({ total, loaded }) => {
              onProgress(
                { percent: Math.round((loaded / total) * 100).toFixed(2) },
                file
              )
            },
          }
        )

        onSuccess(file, response)
      } catch (err) {
        onError(err)
      } finally {
        setSavingAssets((state) => ({ ...state, [name]: false }))
      }
    },
    []
  )

  const handleDeleteAsset = useCallback(
    async (name: string) => {
      setSavingAssets((state) => ({ ...state, [name]: true }))

      try {
        await api.delete(`/trabalheConosco/assets/${name}`)

        loadParams()
      } finally {
        setSavingAssets((state) => ({ ...state, [name]: false }))
      }
    },
    [loadParams]
  )

  const logoPreviewFileList = useMemo((): UploadFile[] | undefined => {
    if (!params || params.length === 0) return undefined

    const logoParam = params.find((param) => param.param === 'logo')

    if (!logoParam || !logoParam.customParam) return undefined

    return [
      {
        uid: '-1',
        name: 'logo',
        status: 'done',
        url: logoParam.customParam.url,
        size: 0,
        type: 'jpeg',
        thumbUrl: logoParam.customParam.url,
      },
    ]
  }, [params])

  const backgroundImgPreviewFileList = useMemo((): UploadFile[] | undefined => {
    if (!params || params.length === 0) return undefined

    const backgroundParam = params.find(
      (param) => param.param === 'background_img'
    )

    if (!backgroundParam || !backgroundParam.customParam) return undefined

    return [
      {
        uid: '-1',
        name: 'background_img',
        status: 'done',
        url: backgroundParam.customParam.url,
        size: 0,
        type: 'jpeg',
        thumbUrl: backgroundParam.customParam.url,
      },
    ]
  }, [params])

  const uploadButton = useMemo(
    () => (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    ),
    []
  )

  const beforeUpload = (file: any) => {
    const imagemMaior = file.size > 200100
    if (imagemMaior) {
      toast.error(
        'Por favor, certifique-se que a imagem seja menor que 200 KB.'
      )
      setErrorUpload(false)
    } else {
      setErrorUpload(true)
    }
    return false
  }

  const handleUploadGalleryImage = useCallback(
    async ({
      file,
      onSuccess,
      onError,
      onProgress,
    }: Omit<IHandleUpload, 'name'>) => {
      const formData = new FormData()

      formData.append('image', file)

      try {
        const response = await api.post(`/trabalheConosco/gallery`, formData, {
          onUploadProgress: ({ total, loaded }) => {
            onProgress(
              { percent: Math.round((loaded / total) * 100).toFixed(2) },
              file
            )
          },
        })

        loadGalleryImages()

        onSuccess(file, response)
      } catch (err) {
        onError(err)
      }
    },
    [loadGalleryImages]
  )

  const handleDeleteGalleryImage = useCallback(
    async (id: number) => {
      await api.delete(`/trabalheConosco/gallery/${id}`)

      loadGalleryImages()
    },
    [loadGalleryImages]
  )

  const envioPesqVetor = useMemo(() => {
    if (
      !paramsValues.envio_pesq_vetor ||
      paramsValues.envio_pesq_vetor === '0'
    ) {
      return false
    }
    return Boolean(paramsValues.envio_pesq_vetor)
  }, [paramsValues.envio_pesq_vetor])

  const envioPesqValores = useMemo(() => {
    if (
      !paramsValues.envio_pesq_valores ||
      paramsValues.envio_pesq_valores === '0'
    ) {
      return false
    }
    return Boolean(paramsValues.envio_pesq_valores)
  }, [paramsValues.envio_pesq_valores])

  const LinkPaginaTrabalheConosco = () => (
    <>
      <span>
        Edite e configure as informações que aparecerão na página de Trabalhe
        Conosco da empresa {getContaURL()}. Link da página:{' '}
      </span>
      <LinkPaginaTrabalheConoscoStyled
        onClick={() =>
          window.open(`https://recruta.io/${getContaURL()}`, '_blank')
        }
      >
        https://recruta.io/{getContaURL()}
      </LinkPaginaTrabalheConoscoStyled>
    </>
  )

  return (
    <Col span={24}>
      {/* <Row gutter={20} style={{ marginBottom: 20 }}>
        <Col xs={24} sm={24}>
          <Card
            title="Parâmetros"
            loading={params.length === 0 && loadingParams}
            extra={
              <Button
                type="primary"
                disabled={savingParams}
                loading={savingParams}
                onClick={() => handleSaveParams()}
              >
                Salvar
              </Button>
            }
          >
            <Row gutter={20}>
              <Col xs={24} sm={24} md={10}>
                <span style={{ fontWeight: 500 }}>
                  URL para redirecionar ao finalizar cadastro
                </span>
                <Input type="text" value={paramsValues.url_redirect} />
              </Col>
              <Col xs={24} sm={24} md={14}>
                <ParamContainer>
                  <Checkbox
                    checked={envioPesqVetor}
                    onChange={() =>
                      handleChangeParam({
                        param: "envio_pesq_vetor",
                        value: envioPesqVetor ? "0" : "1",
                      })
                    }
                  >
                    <strong>Solicitar Perfil Comportamental</strong>
                  </Checkbox>
                </ParamContainer>

                <ParamContainer>
                  <Checkbox
                    checked={envioPesqValores}
                    onChange={() =>
                      handleChangeParam({
                        param: "envio_pesq_valores",
                        value: envioPesqValores ? "0" : "1",
                      })
                    }
                  >
                    <strong>
                      Solicitar pesquisa de Valores Organizacionais
                    </strong>
                  </Checkbox>
                </ParamContainer>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row> */}

      <Row gutter={20}>
        <Col xs={24} sm={24}>
          <Card
            title="Configure e visualize a página de Trabalhe Conosco"
            loading={params.length === 0 && loadingParams}
          >
            <Row gutter={20}>
              <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                <Alert
                  message={<LinkPaginaTrabalheConosco />}
                  type="warning"
                  showIcon
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={24} md={24}>
                <Card
                  title="Selecione as imagens principais da página"
                  type="inner"
                >
                  <Row>
                    <Col md={12}>
                      <Row justify="center">
                        <Col>
                          <Upload
                            listType="picture"
                            maxCount={1}
                            multiple={false}
                            customRequest={({
                              file,
                              onSuccess,
                              onError,
                              onProgress,
                            }) => {
                              handleUploadAsset({
                                name: 'logo',
                                file,
                                onSuccess,
                                onError,
                                onProgress,
                              })
                            }}
                            defaultFileList={logoPreviewFileList}
                            onRemove={() => handleDeleteAsset('logo')}
                          >
                            <Button
                              style={{
                                marginRight: '8px',
                              }}
                              icon={<UploadOutlined />}
                            >
                              Selecionar logo
                            </Button>
                          </Upload>
                          <Text type="secondary">
                            Tamanho recomendado: 120x30px{' '}
                            <Tooltip title="A logo será mostrada na Home, nas Páginas de Login e Cadastro e na Dashboard.">
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </Text>
                          {/* Texto invisível (https://invisibletext.com/) */}
                          <Text type="secondary">ㅤ</Text>{' '}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12}>
                      <Row justify="center">
                        <Col>
                          <Upload
                            listType="picture"
                            maxCount={1}
                            multiple={false}
                            customRequest={({
                              file,
                              onSuccess,
                              onError,
                              onProgress,
                            }) => {
                              handleUploadAsset({
                                name: 'background_img',
                                file,
                                onSuccess,
                                onError,
                                onProgress,
                              })
                            }}
                            defaultFileList={backgroundImgPreviewFileList}
                            onRemove={() => handleDeleteAsset('background_img')}
                          >
                            <Button
                              style={{
                                marginRight: '8px',
                              }}
                              icon={<UploadOutlined />}
                            >
                              Selecionar logo
                            </Button>
                          </Upload>
                          <Row>
                            <Text type="secondary">
                              Tamanho recomendado: 1280x720px{' '}
                              <Tooltip title="O banner será mostrado na Home e nas Páginas de Login e Cadastro. ">
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </Text>
                          </Row>
                          <Row>
                            <Text type="secondary">
                              Tamanho do arquivo recomendado: até 200 KB
                            </Text>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* <ColorPickerContainer>
                      <Popover
                        placement="right"
                        content={
                          <SketchPicker
                            color={paramsValues.primaryColor}
                            onChange={(handler) =>
                              handleChangeParam({
                                param: "primaryColor",
                                value: handler.hex,
                              })
                            }
                          />
                        }
                        overlayInnerStyle={{
                          backgroundColor: "transparent",
                          padding: 0,
                          boxShadow: "none",
                        }}
                        overlayStyle={{
                          padding: 0,
                        }}
                      >
                        <span>Cor Primária</span>
                        <Input
                          type="text"
                          readOnly
                          size="middle"
                          value={paramsValues.primaryColor}
                        />
                      </Popover>
                    </ColorPickerContainer>

                    <ColorPickerContainer>
                      <Popover
                        placement="right"
                        content={
                          <SketchPicker
                            color={paramsValues.secondaryColor}
                            onChange={(handler) =>
                              handleChangeParam({
                                param: "secondaryColor",
                                value: handler.hex,
                              })
                            }
                          />
                        }
                        overlayInnerStyle={{
                          backgroundColor: "transparent",
                          padding: 0,
                          boxShadow: "none",
                        }}
                        overlayStyle={{
                          padding: 0,
                        }}
                      >
                        <span>Cor Secundária</span>
                        <Input
                          type="text"
                          readOnly
                          size="middle"
                          value={paramsValues.secondaryColor}
                        />
                      </Popover>
                    </ColorPickerContainer> */}
                </Card>
              </Col>
              <Divider />
              <Col xs={24} sm={24} xxl={12} style={{ marginBottom: 20 }}>
                <Card
                  title="Editar descrição da empresa - Quem somos"
                  type="inner"
                >
                  <Row gutter={20}>
                    <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: 500 }}>Título</span>
                      <Input
                        type="text"
                        onChange={(event) =>
                          handleChangeParam({
                            param: 'about_us_title',
                            value: event.target.value,
                          })
                        }
                        value={paramsValues.about_us_title}
                      />
                    </Col>
                    <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: 500 }}>Conteúdo</span>
                      <TinyEditor
                        onEditorChange={(content: string) =>
                          handleChangeParam({
                            param: 'about_us_text',
                            value: content,
                          })
                        }
                        value={paramsValues.about_us_text}
                        initialValue={initialValue}
                        outputFormat="html"
                      />
                    </Col>
                    <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: 500 }}>
                        Adicionar URL de um vídeo (Youtube)
                      </span>
                      <Input
                        type="text"
                        onChange={(event) =>
                          handleChangeParam({
                            param: 'about_us_video',
                            value: event.target.value,
                          })
                        }
                        prefix={<YoutubeOutlined />}
                        value={paramsValues.about_us_video}
                      />
                    </Col>
                    <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: 500 }}>
                        Adicionar imagens sobre a empresa
                      </span>
                      <Row>
                        <Text type="secondary">
                          Tamanho recomendado: 1280x720px
                        </Text>
                      </Row>
                      <Upload
                        listType="picture-card"
                        fileList={galleryImages}
                        customRequest={({
                          file,
                          onSuccess,
                          onError,
                          onProgress,
                        }) => {
                          handleUploadGalleryImage({
                            file,
                            onSuccess,
                            onError,
                            onProgress,
                          })
                        }}
                        onRemove={(file) =>
                          handleDeleteGalleryImage(Number(file.uid))
                        }
                      >
                        {galleryImages.length >= 5 ? null : uploadButton}
                      </Upload>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      style={{
                        marginBottom: 20,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <BotoesSalvar
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          type="primary"
                          disabled={savingParams}
                          loading={savingParams}
                          onClick={() => handleSaveParams()}
                        >
                          SALVAR
                        </Button>
                      </BotoesSalvar>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} sm={24} xxl={12}>
                <Card
                  title="Editar descrição de vagas - Entre para o time"
                  type="inner"
                >
                  <Row gutter={20}>
                    <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: 500 }}>Título</span>
                      <Input
                        type="text"
                        onChange={(event) =>
                          handleChangeParam({
                            param: 'enter_team_title',
                            value: event.target.value,
                          })
                        }
                        value={paramsValues.enter_team_title}
                      />
                    </Col>
                    <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: 500 }}>Conteúdo</span>
                      <TinyEditor
                        onEditorChange={(content: string) =>
                          handleChangeParam({
                            param: 'enter_team_text',
                            value: content,
                          })
                        }
                        outputFormat="html"
                        value={paramsValues.enter_team_text}
                        initialValue={initialValueTeam}
                      />
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      style={{
                        marginBottom: 20,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        type="primary"
                        disabled={savingParams}
                        loading={savingParams}
                        onClick={() => handleSaveParams()}
                      >
                        SALVAR
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Divider />
              <Col xs={24} sm={24} xxl={12} style={{ marginBottom: 20 }}>
                <Card title="Termos e condições" type="inner">
                  <Row gutter={20}>
                    <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: 500 }}>Título</span>
                      <Input type="text" disabled value="TERMOS E CONDIÇÕES" />
                    </Col>
                    <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: 500 }}>Conteúdo</span>
                      <TinyEditor
                        onEditorChange={(content: string) =>
                          handleChangeText({
                            param: 'termo',
                            value: content,
                          })
                        }
                        outputFormat="html"
                        value={updateTermo}
                        initialValue={initialValueTermo}
                      />
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      style={{
                        marginRight: 10,
                        marginBottom: 20,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        type="primary"
                        disabled={!idTermo}
                        loading={savingParams}
                        style={{
                          marginRight: 10,
                          width: 81,
                          backgroundColor:
                            updateStatus === 1 && !!idTermo
                              ? 'green'
                              : !idTermo
                              ? 'grey'
                              : 'red',
                          border: 'none',
                        }}
                        onClick={handleSaveStatus}
                      >
                        {updateStatus === 1 ? 'ATIVO' : 'INATIVO'}
                      </Button>
                      <Button
                        type="primary"
                        disabled={Boolean(!updateStatus)}
                        loading={savingParams}
                        onClick={() => handleSaveTermo()}
                      >
                        SALVAR
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} sm={24} xxl={12}>
                <Card title="Site e Redes Sociais" type="inner">
                  <Row gutter={20}>
                    <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: 500 }}>Site</span>
                      <Input
                        type="text"
                        onChange={(event) =>
                          handleChangeParam({
                            param: 'site',
                            value: event.target.value,
                          })
                        }
                        prefix={<GlobalOutlined />}
                        value={paramsValues.site}
                      />
                    </Col>
                    <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: 500 }}>Instagram</span>
                      <Input
                        type="text"
                        onChange={(event) =>
                          handleChangeParam({
                            param: 'instagram',
                            value: event.target.value,
                          })
                        }
                        prefix={<InstagramOutlined />}
                        value={paramsValues.instagram}
                      />
                    </Col>
                    <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: 500 }}>Facebook</span>
                      <Input
                        type="text"
                        onChange={(event) =>
                          handleChangeParam({
                            param: 'facebook',
                            value: event.target.value,
                          })
                        }
                        prefix={<FacebookOutlined />}
                        value={paramsValues.facebook}
                      />
                    </Col>
                    <Col xs={24} sm={24} style={{ marginBottom: 20 }}>
                      <span style={{ fontWeight: 500 }}>Linkedin</span>
                      <Input
                        type="text"
                        onChange={(event) =>
                          handleChangeParam({
                            param: 'linkedin',
                            value: event.target.value,
                          })
                        }
                        prefix={<LinkedinOutlined />}
                        value={paramsValues.linkedin}
                      />
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      style={{
                        marginBottom: 20,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        type="primary"
                        disabled={savingParams}
                        loading={savingParams}
                        onClick={() => handleSaveParams()}
                      >
                        SALVAR
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Atualização!"
        visible={modalTermoVisible}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <p>Olá, estamos atualizando as condições do novo termo!</p>
      </Modal>
    </Col>
  )
}

export default ConfigTrabalheConosco
