import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Button,
  Popconfirm,
  Modal,
  Divider,
  Card,
  Tooltip,
  Space,
} from 'antd'

import {
  DeleteOutlined,
  UserAddOutlined,
  EditOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import Table from '../../../../components/editableTable/EditableTable'

import ModalCadastro from './components/ModalCadastro/index'

import {
  listRequisitos,
  storeRequisitos,
  updateRequisito,
  deleteRequisitos,
  getFrequencia,
} from './actions/actions'

function Requisitos({ id }) {
  const dispatch = useDispatch()

  const [showModalNewRequisito, setShowModalNewRequisito] = useState(false)

  const [newRequisito, setNewRequisito] = useState(null)

  const [atividades, setRequisitos] = useState([])

  const [frequencia_nome, setFrequenciaNome] = useState([])

  const [frequencia, setFrequencias] = useState([])

  const [handleRequisito, sethandleUpdateRequisito] = useState(false)

  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const [atividadeUpdated, setRequisitoUpdated] = useState({
    nome: undefined,
    nivel_id: undefined,
  })

  const loadRequisitos = useCallback(() => {
    dispatch(listRequisitos(id)).then(({ response }) => {
      if (response.status === 200) {
        setRequisitos(response.data)
      }
    })
  }, [dispatch, id])

  const listFrequencias = useCallback(() => {
    dispatch(getFrequencia()).then(({ response }) => {
      if (response.status === 200) {
        const frequencia = response.data
        setFrequencias(frequencia)
        const frequencia_nome = response.data
        setFrequenciaNome(
          frequencia_nome.map((m) => ({ value: m.id, label: m.nome }))
        )
      }
    })
  }, [dispatch])

  useEffect(() => {
    listFrequencias()
    loadRequisitos()
  }, [loadRequisitos, listFrequencias])

  const handleDeleteRequisito = useCallback(
    (formacoes_id) => {
      dispatch(deleteRequisitos({ formacoes_id, id })).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Requisito excluída com sucesso.')
          loadRequisitos()
        }
      })
    },
    [dispatch, id, loadRequisitos]
  )

  const handleChangeNewRequisito = (requisito) => {
    setNewRequisito(requisito)
  }

  const handleCancelNewRequisito = useCallback(() => {
    setShowModalNewRequisito(false)
  }, [])

  const handleNewRequisito = useCallback(
    (data) => {
      dispatch(storeRequisitos({ data, id })).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Requisito criada com sucesso.')
          setShowModalNewRequisito(false)
          loadRequisitos()
        }
      })
    },
    [dispatch, loadRequisitos, id]
  )

  // UPDATE REQUISITO
  const handleUpdateModal = ({ id, nome, nivel_id }) => {
    setRequisitoUpdated({ id, nome, nivel_id })
    setShowUpdateModal(true)
  }

  const handleChangeUpdateRequisito = (nome, nivel_id) => {
    setRequisitoUpdated((prevState) => ({
      ...prevState,
      nome,
      nivel_id,
    }))
  }

  const handleSubmitUpdateRequisito = (data) => {
    dispatch(
      updateRequisito({
        id,
        formacoes_id: atividadeUpdated.id,
        ...data,
      })
    ).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Atualizado com sucesso.')
        loadRequisitos()
        handleCloseUpdateRequisito()
      } else toast.error('Erro ao atualizar requisito.')
    })
  }

  const handleCloseUpdateRequisito = () => {
    setRequisitoUpdated({
      nome: undefined,
      nivel_id: undefined,
    })
    setShowUpdateModal(false)
  }

  //

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
    },
    {
      title: 'Nível',
      dataIndex: 'nivel_id',
      render: (text, record) => {
        const frequencia_nome = frequencia.filter(
          (frequencia) => frequencia.id === text
        )
        return (
          <span>
            {frequencia_nome[0] !== undefined ? frequencia_nome[0].nome : ''}
          </span>
        )
      },
    },
    {
      title: 'Opções',
      key: 'opcoes',
      editable: true,
      className: 'column-opcoes',
      render: (text, record) => (
        <Space split={<Divider type="vertical" />}>
          <a>
            <Popconfirm
              title="Tem certeza que deseja remover do cargo?"
              onConfirm={() => handleDeleteRequisito(record.id)}
              okText="Sim"
              cancelText="Não"
            >
              <Tooltip title="Excluir" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </a>
          <a
            href="javascript:;"
            onClick={() => handleUpdateModal(record)}
            aria-label="Editar"
          >
            <Tooltip title="Editar" placement="bottom">
              <EditOutlined />
            </Tooltip>
          </a>
        </Space>
      ),
    },
  ]

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Card title="Requisitos do cargo" type="inner">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '12px',
              }}
            >
              <Button
                type="primary"
                className="btn_purple"
                onClick={() => setShowModalNewRequisito(true)}
                icon={<UserAddOutlined />}
              >
                NOVO
              </Button>
            </div>
            <Table
              bordered
              pagination={false}
              size="small"
              dataSource={atividades.map((atividades, frequencia_nome) => {
                return {
                  ...atividades,
                  ...frequencia_nome,
                  key: atividades.pes_id,
                }
              })}
              columns={columns}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="NOVA INFORMAÇÃO"
        visible={showModalNewRequisito}
        cancelText="CANCELAR"
        onCancel={() => handleCancelNewRequisito()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'cadastro',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <ModalCadastro
          handleChangeRequisito={handleChangeNewRequisito}
          onFinish={handleNewRequisito}
          {...handleRequisito}
          frequencia={frequencia}
        />
      </Modal>
      <Modal
        title="EDITAR INFORMAÇÃO"
        visible={showUpdateModal}
        cancelText="CANCELAR"
        onCancel={() => handleCloseUpdateRequisito()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'cadastro',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <ModalCadastro
          handleChangeRequisito={handleChangeUpdateRequisito}
          onFinish={handleSubmitUpdateRequisito}
          {...atividadeUpdated}
          frequencia={frequencia}
        />
      </Modal>
    </Col>
  )
}
export default Requisitos
