import { toast } from 'react-toastify'
import { message } from 'antd'
// Redux types
import * as constants from '../constants/constantsGestaoPesquisas'
import moment from 'moment'

const initialState = {
  list: [],
}

export const gestaoPesquisasCandidatos = (state = initialState, action) => {
  switch (action.type) {
    case constants.SEND_AVALIACOES:
      if (action.response.status !== 200)
        message.error('Erro ao gerar pesquisa de perfil comportamental.')

      return state

    case constants.PESQUISAS_GET:
      const { pesquisas } = action.response.data

      if (action.response.status !== 200) return state
      let pesquisasParsed = pesquisas
        .map((pesquisa) => {
          return { ...pesquisa, status: pesquisa.data_entrega ? 1 : 0 }
        })
        .sort(
          (a, b) =>
            moment(b.data_solicitacao).unix() -
            moment(a.data_solicitacao).unix()
        )
      const pesquisaList = [[], []]
      pesquisaList[0] = pesquisasParsed.filter(
        (pesquisa) => pesquisa.colaborador === '0'
      )
      pesquisaList[1] = pesquisasParsed.filter(
        (pesquisa) => pesquisa.colaborador === '1'
      )

      return {
        ...state,
        list: pesquisaList,
      }

    case constants.PESQUISA_COMPORTAMENTAL_KPI: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204) toast.error('Erro ao obter KPI.')
        return { ...state }
      }

      const { enviadas, entregues, pct } = action.response.data

      return {
        ...state,
        kpicomportamental: {
          enviadas,
          entregues,
          pct,
        },
      }
    }

    case constants.PESQUISA_PERCEPCAO_KPI: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204) toast.error('Erro ao obter KPI')
        return { ...state }
      }

      const { enviadas, entregues, pct } = action.response.data

      return {
        ...state,
        kpipercepcao: {
          enviadas,
          entregues,
          pct,
        },
      }
    }

    case constants.PESQUISA_VALORES_KPI: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204) toast.error('Erro ao obter KPI')
        return { ...state }
      }

      const { enviadas, entregues, pct } = action.response.data

      return {
        ...state,
        kpipercepcao: {
          enviadas,
          entregues,
          pct,
        },
      }
    }

    default:
      return state
  }
}
