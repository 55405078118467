import React from 'react'

import { Form, Input, Select } from 'antd'

export default function NovaMotivo({ onFinish }) {
  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      id="nova-motivo-suspensao"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        label="Nome"
        name="nome"
        rules={[
          {
            required: true,
            message: 'Por favor, informe um nome para o motivo da suspensão.',
          },
        ]}
      >
        <Input type="text" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label="Descrição"
        name="descricao"
        rules={[
          {
            required: true,
            message:
              'Por favor, informe uma descrição para o motivo da suspensão.',
          },
        ]}
      >
        <Input type="text" style={{ width: '100%' }} />
      </Form.Item>
    </Form>
  )
}
