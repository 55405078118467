import { connect } from 'react-redux'

// Components
import CargosDetalhesController from './CargosDetalhesController'

// Actions
import * as actions from './actions/actionsCargosDetalhes'

import { getCulturaEmpresa } from '../dashboard/containers/Colaboradores/actions'

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, { ...actions, getCulturaEmpresa })(
  CargosDetalhesController
)
