import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Button,
  Popconfirm,
  Card,
  Modal,
  Divider,
  Tooltip,
} from 'antd'

import {
  ExclamationCircleFilled,
  EditOutlined,
  DeleteOutlined,
  UserAddOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import {
  listMotivos,
  storeMotivo,
  updateMotivo,
  deleteMotivo,
  load,
} from './actions/actions'

import { LinkSpan, TableHeader, Table } from './styles'

import NovoMotivo from './components/novoMotivo'

function MotivoCancelamento() {
  const dispatch = useDispatch()

  const [showNewModal, setShowNewModal] = useState(false)

  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const [newMotivo, setNewMotivo] = useState(null)

  const [motivoUpdated, setMotivoUpdated] = useState({ motivo: undefined })

  const motivos = useSelector((state) => state.psMotivoCancelamento.motivos)

  const loading = useSelector((state) => state.psMotivoCancelamento.loading)

  useEffect(() => {
    dispatch(load())
    dispatch(listMotivos())
  }, [dispatch])

  const handleDeleteMotivo = (cancel_id) => {
    dispatch(deleteMotivo({ cancel_id })).then(({ response }) => {
      if (response.status === 200) {
        dispatch(listMotivos())
        toast.success('Motivo excluído com sucesso.')
      } else toast.error('Erro ao excluir motivo.')
    })
  }

  // NEW MOTIVO

  const handleSubmitNewMotivo = () => {
    if (newMotivo) {
      dispatch(storeMotivo({ motivo: newMotivo })).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Motivo gravado com sucesso.')
          setNewMotivo(null)
          setShowNewModal(false)
          dispatch(listMotivos())
        } else
          toast.error(
            'Erro ao gravar novo motivo de cancelamento do processo seletivo.'
          )
      })
    }
  }

  const handleChangeNewMotivo = (motivo) => {
    setNewMotivo(motivo)
  }

  // UPDATE MOTIVO
  const handleUpdateModal = ({ cancel_id, motivo }) => {
    setMotivoUpdated({ cancel_id, motivo })
    setShowUpdateModal(true)
  }

  const handleChangeUpdateMotivo = (motivo) => {
    setMotivoUpdated((prevState) => ({ ...prevState, motivo }))
  }

  const handleSubmitUpdateMotivo = () => {
    dispatch(
      updateMotivo({
        cancel_id: motivoUpdated.cancel_id,
        motivo: motivoUpdated.motivo,
      })
    ).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Atualizado com sucesso.')
        dispatch(listMotivos())
        handleCloseUpdateMotivo()
      } else toast.error('Erro ao atualizar motivo.')
    })
  }

  const handleCloseUpdateMotivo = () => {
    setMotivoUpdated({ motivo: undefined })
    setShowUpdateModal(false)
  }

  //

  const tableMotivosColumns = [
    {
      title: 'Motivo',
      dataIndex: 'motivo',
      width: '85%',
    },
    {
      title: 'Ações',
      key: 'acoes',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        return (
          <span
            style={{
              display: 'inline',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LinkSpan onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Editar">
                <EditOutlined />
              </Tooltip>
            </LinkSpan>
            <Divider type="vertical" />
            <Popconfirm
              title="Tem certeza que deseja excluir esse motivo?"
              okText="Sim"
              cancelText="Não"
              icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
              onConfirm={() => handleDeleteMotivo(record.cancel_id)}
            >
              <LinkSpan>
                <Tooltip title="Excluir">
                  <DeleteOutlined />
                </Tooltip>
              </LinkSpan>
            </Popconfirm>
          </span>
        )
      },
    },
  ]

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Card title="Motivos de Cancelamento do Processo Seletivo">
            <Table
              loading={loading}
              title={() => (
                <TableHeader>
                  <Button
                    type="primary"
                    size="middle"
                    onClick={() => setShowNewModal(true)}
                  >
                    <UserAddOutlined /> NOVO MOTIVO
                  </Button>
                </TableHeader>
              )}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total} registros`,
              }}
              dataSource={motivos}
              columns={tableMotivosColumns}
              rowKey={(record) => record.cancel_id}
            />
          </Card>
        </Col>
      </Row>

      {/* NEW MOTIVO MODAL */}
      <Modal
        title="ADICIONAR NOVO MOTIVO"
        visible={showNewModal}
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => setShowNewModal(false)}
        destroyOnClose
        key="1"
        okButtonProps={{
          form: 'motivo-cancelamento-submit',
          key: 'submit',
          htmlType: 'submit',
        }}
      >
        <NovoMotivo
          handleChangeMotivo={handleChangeNewMotivo}
          motivo={newMotivo}
          onFinish={handleSubmitNewMotivo}
        />
      </Modal>

      {/* UPDATE MOTIVO MODAL */}
      <Modal
        title="ATUALIZAR MOTIVO"
        visible={showUpdateModal}
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => handleCloseUpdateMotivo()}
        destroyOnClose
        key="2"
        okButtonProps={{
          form: 'motivo-cancelamento-submit',
          key: 'submit',
          htmlType: 'submit',
        }}
      >
        <NovoMotivo
          handleChangeMotivo={handleChangeUpdateMotivo}
          onFinish={handleSubmitUpdateMotivo}
          {...motivoUpdated}
        />
      </Modal>
    </Col>
  )
}

export default MotivoCancelamento
