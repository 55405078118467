import React from 'react'

import { Form, Input } from 'antd'

// import { Container } from './styles';

function NovoMotivo({ handleChangeMotivo, motivo, onFinish }) {
  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      initialValues={{ motivo }}
      id="motivo-cancelamento-submit"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        name="motivo"
        rules={[
          {
            required: true,
            message: 'Por favor, preencha o motivo.',
          },
        ]}
      >
        <Input
          onChange={(e) => handleChangeMotivo(e.target.value)}
          placeholder="Descrição do motivo"
          size="middle"
          value={motivo}
        />
      </Form.Item>
    </Form>
  )
}

export default NovoMotivo
