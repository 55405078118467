import React from 'react'
import { injectIntl } from 'react-intl'
import { DotChartOutlined } from '@ant-design/icons'
import { Button, Tabs } from 'antd'

// Layouts
import ContentLayout from '../../layouts/contentLayout/ContentLayout'

// Components
import Textual from './components/textual/Textual'
import Infografico from './components/infografico/Infografico'
import InfograficoPDF from './components/infograficoPDF/Infografico'
import TextualPDF from './components/textualPDF/Textual'

// CSS
import './PerfilComportamentalDetalhes.css'

// Constants

const { TabPane } = Tabs

function PerfilComportamentalDetalhesView({
  printDocument,
  loadingPDF,
  principal,
  ...props
}) {
  const { nome } = props

  return (
    <>
      <ContentLayout
        className="perfil-comportamental-detalhes"
        header={{
          title: `Perfil Comportamental - ${nome}`,
          subtitle: 'Apollo III - 21/01/2019',
          breadcrumb: [{ name: 'Home', path: '/' }, { name: 'Pesquisa' }],
          tabs: (
            <>
              <Tabs
                defaultActiveKey="1"
                className="perfil-comportamental-detalhes__tabs"
                tabBarExtraContent={
                  <div style={{ padding: '0 30px' }}>
                    <Button
                      className="btn_purple"
                      onClick={printDocument}
                      loading={loadingPDF}
                    >
                      EXPORTAR PDF
                    </Button>
                  </div>
                }
              >
                <TabPane
                  tab={
                    <span>
                      <DotChartOutlined />
                      Infográfico
                    </span>
                  }
                  key="1"
                >
                  <ContentLayout>
                    <Infografico {...props} />
                  </ContentLayout>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <DotChartOutlined />
                      Textual
                    </span>
                  }
                  key="2"
                >
                  <ContentLayout>
                    <Textual principal={props[principal]} />
                  </ContentLayout>
                </TabPane>
              </Tabs>
            </>
          ),
        }}
      />
      <div
        id="divToPrint1"
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          zIndex: '-1',
        }}
      >
        <InfograficoPDF {...props} nome={nome} />
      </div>
      <div
        id="divToPrint2"
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          zIndex: '-1',
        }}
      >
        <TextualPDF principal={props[principal]} />
      </div>
    </>
  )
}

export default injectIntl(PerfilComportamentalDetalhesView)
