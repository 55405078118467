import { Button, Select, Skeleton } from 'antd'
import { useState, useEffect } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'

import Charts from '../Components/Charts'
import Pdf from '../Pdf/pessoas'
import MenuBar from '../Components/Menu'
import TimesTable from './Tables/TimesTable'
import SpinLoading from '../../../utils/SpinLoading'
import NewTable from './Tables/FitsTable'

import AutoCompleteAdd from '../Components/AutoCompleteAdd'
import {
  getCulturas,
  getCulturasFromEmpresas,
  getAllTimes,
  getFitByPeoples,
  getTimeFromCultura,
  getFitsByGroup,
  getCulturasFromTimes,
} from '../Api/api'
import ReportLogo from '../../../assets/business-report.png'

const App = () => {
  const [isDownloading, setDownloading] = useState(false)
  const [typeDownload, setTypeDownload] = useState(null)
  const [typeSelected, setTypeSelected] = useState(null)
  const [culturas, setCulturas] = useState(null)
  const [isSearched, setSearched] = useState(false)
  const [AllTimes, setAllTimes] = useState(null)
  const [selectedCultura, setSelectedCultura] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [timeFromCultura, setTimeFromCultura] = useState(null)
  const [graficoOne, setGraficoOne] = useState(null)
  const [instalado, setInstalado] = useState(null)
  const [selectedCulturaData, setSelectedCulturaData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [graficoFive, setGraficoFive] = useState(null)

  const { Option } = Select

  async function getCulturasFromApi() {
    const AllTimes = await getAllTimes()
    if (AllTimes.success) {
      setAllTimes(AllTimes.grupos)
    }
    let RESPONSE
    if (typeSelected === '1') RESPONSE = await getCulturasFromEmpresas()
    else RESPONSE = await getCulturasFromTimes()

    if (RESPONSE) setCulturas(filterCulturasWithFitId(RESPONSE.culture))
  }

  useEffect(() => {
    getCulturasFromApi()
  }, [typeSelected])

  function closeDownload() {
    setDownloading(false)
  }

  function handlePdf() {
    setTypeDownload(1)
    setDownloading(true)
  }

  // Generate fitArquetipos
  function generateFitArquetipos(arquetipo) {
    arquetipo.forEach((element) => {
      element.fitArquetipos = element.fitArquetipos?.geral
    })

    return arquetipo
  }

  async function getTimeFromCulturaFromAPI() {
    if (selectedCultura != null) {
      const RESPONSE = await getTimeFromCultura(
        selectedCulturaData.cultura_id,
        selectedCulturaData.fit_id
      )
      if (RESPONSE) {
        const FilteredArray = []
        AllTimes.forEach((time) => {
          if (time.children.length > 0) {
            AllTimes.push(...time.children)
          }
        })
        RESPONSE.forEach((item) => {
          AllTimes.forEach((time) => {
            if (item === time.grupo_id) {
              FilteredArray.push(time)
            }
          })
        })
        setTimeFromCultura(FilteredArray)
      }
    }
  }

  function removeDecimals(value) {
    value = value.replace('%', '')
    value = Number(value).toFixed(2)
    return `${value}%`
  }

  function fixFitValores(values) {
    if (!values) return
    values.forEach((element) => {
      if (element.fitValores) {
        element.fitValores = removeDecimals(element.fitValores)
      }
    })
    return values
  }

  async function getRelatorios() {
    setGraficoFive(null)
    setLoading(true)
    setSearched(false)

    const response = await getFitsByGroup(
      selectedTime,
      selectedCultura,
      selectedCulturaData.fit_id
    )

    if (response) {
      let dataGenerated = generateFitArquetipos(response.grafico_5)
      dataGenerated = fixFitValores(dataGenerated)
      setGraficoFive(dataGenerated)
    }

    let RESPONSE
    if (typeSelected === '2') {
      RESPONSE = await getFitByPeoples(
        selectedCultura,
        selectedTime,
        selectedCulturaData.fit_id
      )
    } else
      RESPONSE = await getFitByPeoples(
        selectedCultura,
        null,
        selectedCulturaData.fit_id
      )

    if (
      !RESPONSE.grafico_2 ||
      Object.entries(RESPONSE.grafico_2).length === 0
    ) {
      toast.error('Não é possível gerar relatório para essa cultura')
    } else {
      setGraficoOne(RESPONSE.grafico_2)
      setInstalado(RESPONSE.grafico_1)
      setTimeout(() => {
        setSearched(true)
      }, 1000)
    }
    setLoading(false)
    setSearched(true)
  }

  function filterCulturasWithFitId(array) {
    if (!array) return
    const newArray = array.filter((cultura) => cultura?.fit_id != undefined)
    return newArray
  }

  function updateCultura(id) {
    culturas.forEach((cultura) => {
      if (cultura.cultura_id == id) {
        setSelectedCulturaData({ ...cultura })
      }
    })
  }

  useEffect(() => {
    getTimeFromCulturaFromAPI()
  }, [selectedCultura])

  return (
    <div>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: 5,
          padding: 20,
          border: '0.5px solid black',
        }}
      >
        <MenuBar selectedKeys={['2']} />

        <div style={{ margin: 25 }}>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div style={{ minWidth: 290 }}>
              <span>Tipo</span>
              <Select
                onChange={(value) => setTypeSelected(value)}
                style={{ minWidth: 200, marginLeft: 20 }}
              >
                <Select.Option value="1">Empresa</Select.Option>
                <Select.Option value="2">Time</Select.Option>
              </Select>
            </div>
            <div style={{ minWidth: 420 }}>
              <span>Cultura Desejada</span>
              <Select
                onChange={(valor) => {
                  setTimeFromCultura(null)
                  getCulturasFromApi()
                  setSelectedCultura(valor)
                  updateCultura(valor)
                }}
                style={{ minWidth: 220, marginLeft: 20 }}
              >
                {culturas?.map((cultura) => (
                  <Option key={cultura.cultura_id} value={cultura.cultura_id}>
                    {cultura.conta_culturas[0].descricao}
                  </Option>
                ))}
              </Select>
            </div>

            {typeSelected === '2' && (
              <div style={{ minWidth: 290 }}>
                <span>Time</span>
                <Select
                  onChange={(value) => setSelectedTime(value)}
                  style={{ minWidth: 200, marginLeft: 20 }}
                >
                  {timeFromCultura?.map((time) => (
                    <Option key={time.grupo_nome} value={time.grupo_id}>
                      {time.grupo_nome}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <div style={{ flex: 'row', width: 250 }} />

            <div style={{ flex: 'row', width: 250, display: 'none' }}>
              <span>Pessoas:</span>
              <AutoCompleteAdd key="2" />
            </div>
            <div style={{ marginTop: 20 }}>
              <Button>Limpar Filtros</Button>
              <Button onClick={getRelatorios} type="primary">
                {loading ? <SpinLoading /> : 'Gerar Relatório'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: 5,
          padding: 20,
          border: '0.5px solid black',
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {isSearched ? (
            <div
              style={{
                borderWidth: 1,
                borderColor: 'black',
                borderStyle: 'solid',
                borderRadius: 5,
                padding: 20,
                backgroundColor: '#F1F1F1',
                maxWidth: '20%',
              }}
            >
              <h4>Resultado da Pesquisa</h4>
              <h4>
                Cultura desejada:{' '}
                <span>{selectedCulturaData.conta_culturas[0].descricao}</span>
              </h4>
              <h4>
                Período{' '}
                <span>
                  {moment(
                    selectedCulturaData.conta_culturas[0].data_inicio
                  ).format('DD-MM-YYYY')}{' '}
                  à{' '}
                  {moment(
                    selectedCulturaData.conta_culturas[0].data_final
                  ).format('DD-MM-YYYY')}
                </span>
              </h4>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              {loading || isSearched ? (
                <Skeleton active />
              ) : (
                <>
                  <h1>O relatório será exibido aqui quando for gerado.</h1>
                  <img style={{ maxWidth: 200 }} src={ReportLogo} />
                </>
              )}
            </div>
          )}

          {isSearched ? (
            <div>
              <Button onClick={handlePdf}>Exportar documento</Button>
            </div>
          ) : null}
        </div>
        {!isDownloading ? (
          isSearched ? (
            <Relatorios
              grafico_3={graficoOne}
              instalado={instalado}
              type={typeSelected}
              graficoFive={graficoFive}
            />
          ) : null
        ) : (
          <Download
            grafico_3={graficoOne}
            instalado={instalado}
            type={typeSelected}
            downloads={typeDownload}
            close={closeDownload}
            graficoFive={graficoFive}
          />
        )}
      </div>
    </div>
  )
}

const Relatorios = (props) => (
  <div>
    <Charts data={props.instalado} />
    {props.graficoFive ? <NewTable data={props.graficoFive} /> : null}
    <TimesTable dataSource={props.grafico_3} type={props.type} />
  </div>
)
const Download = (props) => (
  <Pdf
    grafico_3={props.grafico_3}
    instalado={props.instalado}
    type={props.type}
    downloads={props.downloads}
    close={props.close}
    graficoFive={props.graficoFive}
  />
)

export default App
