import styled from 'styled-components'

export const EditorLegend = styled.div`
  margin-top: 16px;

  > ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    > li {
      line-height: 2em;
      display: inline-block;
    }
  }

  @media screen and (min-width: 700px) {
    > ul {
      flex-direction: row;
      justify-content: center;
      align-items: center;

      > li {
        margin: 0;

        & + li {
          margin-left: 16px;
        }
      }
    }
  }
`
