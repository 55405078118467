import { Select, Input, Button } from 'antd'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  getGruposDisponiveis,
  getSubCulturas,
  vincularTimeSubcultura,
} from '../Api/api'

const { Option } = Select

const App = (props) => {
  const [times, setTimes] = useState('')
  const [isLoading, setLoading] = useState(true)
  const [descricao, setDescricao] = useState('')
  const [grupoId, setGrupoId] = useState('')
  const [subCulturaId, setSubculturaId] = useState('')
  const [sub_cultura, setSubCultura] = useState([])

  useEffect(() => {
    async function getTimes() {
      const response = await getGruposDisponiveis()
      const subCulturas = await getSubCulturas(props.cultura.cultura_id)
      setSubCultura(subCulturas.response?.rows)
      setTimes(response.grupos)
      setLoading(false)
    }

    getTimes()
  }, [])

  const handleChange = (value) => {
    setGrupoId(value)
  }

  const handleChangeSubCultura = (value) => {
    setSubculturaId(value)
  }

  const saveNewSubCulture = async () => {
    const newObj = {
      emp_id: props.cultura.emp_id,
      conta_id: props.cultura.conta_id,
      cultura_id: props.cultura.cultura_id,
      descricao,
      grupo_id: grupoId,
      subcultura_id: subCulturaId,
    }
    const response = await vincularTimeSubcultura(newObj)
    if (response.success) {
      toast.success('Cadastrado com sucesso')
      props.updateSubCultura()
      props.closeModal()
    } else toast.error(response.message)
  }

  return (
    <div style={{ minWidth: 200 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <p>Times Disponíveis</p>
          <Select style={{ minWidth: 200 }} onChange={handleChange}>
            {!isLoading &&
              times?.map((time, index) => (
                <Option value={time.grupo_id} key={index}>
                  {time.grupo_nome}
                </Option>
              ))}
          </Select>
        </div>
        x
        <div>
          <p>SubCulturas Disponíveis</p>
          <Select style={{ minWidth: 200 }} onChange={handleChangeSubCultura}>
            {!isLoading &&
              sub_cultura?.map((subCultura, index) => (
                <Option value={subCultura.subcultura_id} key={index}>
                  {subCultura.descricao}
                </Option>
              ))}
          </Select>
        </div>
      </div>
      <p>Descrição</p>
      <Input
        showCount
        rows={4}
        placeholder="Descrição"
        maxLength={255}
        onChange={(res) => setDescricao(res.currentTarget.value)}
        value={descricao}
      />
      <Button
        onClick={saveNewSubCulture}
        type="primary"
        className="btn_purple newCultureBtn"
      >
        Cadastrar SubCultura
      </Button>
    </div>
  )
}

export default App
