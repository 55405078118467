import * as constants from '../constants/olhar360GeralConstants'

export const getCulturaOrganizacional = () => {
  return {
    type: constants.GET_OLHAR360_GERAL_CULTURA_ORGANIZACIONAL,
    api: {
      method: 'get',
      url: constants.URL_OLHAR360_GERAL_CULTURA_ORGANIZACIONAL,
    },
  }
}
