import styled from 'styled-components'

import { Table as TableAntd } from 'antd'

export const Table = styled(TableAntd)`
  .ant-table-title {
    padding: 0px 0px 16px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;

    > button {
      margin-left: auto;
    }
  }
`

export const Action = styled.span`
  color: var(--purple);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
