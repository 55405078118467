import React from 'react'
import {
  Chart,
  Axis,
  Tooltip,
  Coordinate,
  Interval,
  Annotation,
  Legend,
} from 'bizcharts'

const { Text } = Annotation

function Donut({
  data,
  scale,
  handleChange,
  nameProp,
  score,
  nomeCenter,
  scoreCenter,
  nome,
  color,
}) {
  return (
    <Chart
      padding={0}
      height={300}
      data={data}
      autoFit
      scale={scale}
      onPlotClick={(evt) => {
        evt.data &&
          handleChange({
            target: {
              name: nameProp,
              value: {
                nome: evt.data._origin.nome,
                score: evt.data._origin.score,
              },
            },
          })
      }}
    >
      <Tooltip showTitle={false} />
      <Coordinate type={'theta'} radius={0.75} innerRadius={0.6} />
      <Axis name={score} visible={false} />
      <Legend visible={false} />
      <Text
        position={['50%', '50%']}
        content={`${scoreCenter}%`}
        style={{ textAlign: 'center', color: '#262626', fontSize: '28' }}
      />
      <Interval
        adjust="stack"
        position={score}
        color={color}
        tooltip={[
          `${nome}*${score}`,
          (nome, score) => {
            return {
              name: nome,
              value: score + '%',
            }
          },
        ]}
        style={{
          lineWidth: 1,
          stroke: '#fff',
        }}
        select={[
          true,
          {
            animate: true,
          },
        ]}
      />
    </Chart>
  )
}

export default Donut
