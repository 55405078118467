import { FullscreenOutlined } from '@ant-design/icons'
import DataSet from '@antv/data-set'
import { Card, Col, Modal, Row, Tooltip } from 'antd'
import {
  Axis,
  Chart,
  Coordinate,
  GaugeChart,
  Interval,
  Legend,
  Line,
  Point,
} from 'bizcharts'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { Link } from 'react-router-dom'
import Empty from '../../../../components/empty'
import EmptyFit from '../../../../components/empty/fit'
import { getContaNome } from '../../../../services/auth'
import { cardHeadBlue, noPadding } from '../../../../utils/inlineStyles'

import {
  arqRange,
  ArquetiposCulturaisDetalhes,
  colors,
  forcasRange,
  parseCulturas,
  parseVetor,
  styleColors,
} from '../../../../utils/utils'
import EstatisticasGerais from '../../components/EstatisticasGerais'
// actions
import {
  getCulturaOrganizacional,
  getCulturas,
  getEstatisticas,
  getNps,
  getTopArq,
  getVetorGeral,
} from './actions'
import {
  culturaOrgDefault,
  estatisticasDefault,
  vetorDefault,
} from './constants'
import { ChartTitle, ColFill, IndicadoresContainer, Span } from './styles'
import { getCulturasFromEmpresas } from '../../../../components/FitCultural/Api/api'

const { purple, pink } = styleColors

const VisaoGeral = () => {
  const dispatch = useDispatch()

  const [estatisticas, setEstatisticas] = useState(estatisticasDefault)

  const [cultura, setCultura] = useState([])

  const [nps, setNPS] = useState([])

  const [forcas, setForcas] = useState([])

  const [disc, setDisc] = useState([])

  const [olhares, setOlhares] = useState([])

  const [gestao, setGestao] = useState([])

  const HIDDEN = true

  const [culturaOrganizacional, setCulturaOrganizacional] =
    useState(culturaOrgDefault)

  const [destaques, setDestaques] = useState()

  const [vetor, setVetor] = useState(vetorDefault)

  const loadEstatisticas = useCallback(() => {
    dispatch(getEstatisticas()).then(({ response }) => {
      if (response.status === 200) {
        const { estatisticas: estatisticasData } = response.data
        setEstatisticas(estatisticasData)
      }
    })
  }, [dispatch])

  const loadNPS = useCallback(() => {
    dispatch(getNps()).then(({ response }) => {
      if (response.status === 200) {
        const nps = response.data
        setNPS(nps)
      } else {
        const nps = 'N/A'
        setNPS(nps)
      }
    })
  }, [dispatch])

  function removeZeros(arr) {
    return arr.filter((item) => item.Valor !== 0)
  }

  const loadCulturas = useCallback(() => {
    dispatch(getCulturas()).then(({ response }) => {
      if (response.status === 200) {
        const { culturaDesejada, culturaInstalada } = response.data

        const {
          cultura: culturaData,
          forcas: forcasData,
          disc: discData,
          olhares: olharesData,
          gestao: gestaoData,
        } = parseCulturas({
          culturaDesejada,
          culturaInstalada,
        })

        setCultura(culturaData)

        setForcas(removeZeros(forcasData))
        setDisc(discData)

        setOlhares(olharesData)

        setGestao(gestaoData)
      }
    })
  }, [dispatch])

  const loadCulturaOrganizacional = useCallback(() => {
    dispatch(getCulturaOrganizacional()).then(({ response }) => {
      if (response.status === 200) {
        const { culturaOrganizacional: culturaOrg, disc: discData } =
          response.data

        setCulturaOrganizacional({
          culturaOrg,
          disc: discData,
        })
      }
    })
  }, [dispatch])

  const loadTopArq = useCallback(() => {
    dispatch(getTopArq()).then(({ response }) => {
      if (response.status === 200) {
        const { destaques: destaquesData } = response.data

        setDestaques(destaquesData)
      }
    })
  }, [dispatch])

  const loadVetorGeral = useCallback(() => {
    dispatch(getVetorGeral()).then(({ response }) => {
      if (response.status === 200) {
        const { data } = response.data

        const vetorData = parseVetor(data)

        setVetor(vetorData)
      } else setVetor(vetorDefault)
    })
  }, [dispatch])

  useEffect(() => {
    loadEstatisticas()
    loadNPS()
    loadCulturas()
    loadCulturaOrganizacional()
    loadTopArq()
    loadVetorGeral()
    culturasFromEmpresas()
  }, [
    loadCulturaOrganizacional,
    loadCulturas,
    loadEstatisticas,
    loadNPS,
    loadTopArq,
    loadVetorGeral,
  ])

  const conta = getContaNome() || ''

  const [showFit, setShow] = useState(false)

  async function culturasFromEmpresas() {
    const culturas = await getCulturasFromEmpresas()
    if (culturas.count == 0) setShow(true)
  }

  const { culturaOrg } = culturaOrganizacional

  const { olharParaMudancas } = culturaOrg

  const { engajamento, proficiencia, velocidadeAdocao } = olharParaMudancas

  const ds = new DataSet()
  const dvGestao = ds
    .createView()
    .source(gestao)
    .transform({
      type: 'percent',
      field: 'Valor',
      dimension: 'Tipo',
      groupBy: ['Label'],
      as: 'percent',
    })
  const dvOlhares = ds
    .createView()
    .source(olhares)
    .transform({
      type: 'percent',
      field: 'Valor',
      dimension: 'Tipo',
      groupBy: ['Label'],
      as: 'percent',
    })

  const cols = {
    percent: {
      min: 0,

      formatter(val) {
        return `${(val * 100).toFixed(2)}%`
      },
    },
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Col span={24} style={{ marginTop: '8px', padding: '0' }}>
        <Row type="flex" gutter={8}>
          <Col
            lg={12}
            xxl={6}
            xs={24}
            sm={24}
            style={{
              flexGrow: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '8px',
            }}
          >
            <Row>
              <Col
                xs={24}
                md={24}
                sm={24}
                style={{ padding: '0', marginBottom: '8px' }}
              >
                <Card
                  title="Arquétipos Culturais"
                  bodyStyle={{ padding: '8px' }}
                  headStyle={cardHeadBlue}
                  extra={<ArquetiposCulturaisDetalhes />}
                  scale={arqRange}
                >
                  <Row>
                    {showFit ? (
                      <Col xs={24} style={{ height: '340px' }}>
                        {cultura.length === 0 ? (
                          <Empty description={false} height={340} />
                        ) : (
                          <Chart
                            height={318}
                            data={cultura}
                            scale={arqRange}
                            autoFit
                          >
                            <Legend />
                            <Coordinate type="polar" radius={0.8} />
                            <Axis
                              name="Tipo"
                              line={null}
                              tickLine={null}
                              grid={{
                                lineStyle: {
                                  lineDash: null,
                                },
                                hideFirstLine: false,
                              }}
                            />
                            <Tooltip />
                            <Axis
                              name="Valor"
                              line={null}
                              tickLine={null}
                              grid={{
                                type: 'polygon',
                                lineStyle: {
                                  lineDash: null,
                                },
                                alternateColor: 'rgba(0, 0, 0, 0.04)',
                              }}
                            />
                            <Line
                              position="Tipo*Valor"
                              color={['Label', colors]}
                              size={2}
                            />
                            <Point
                              position="Tipo*Valor"
                              color={['Label', colors]}
                              shape="circle"
                              size={4}
                              style={{
                                stroke: '#fff',
                                lineWidth: 1,
                                fillOpacity: 1,
                              }}
                            />
                          </Chart>
                        )}

                        <Row justify="end">
                          <Tooltip title="Ampliar gráfico">
                            <a onClick={showModal}>
                              <FullscreenOutlined />
                            </a>
                          </Tooltip>
                        </Row>
                      </Col>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '340px',
                          width: '100%',
                        }}
                      >
                        <EmptyFit
                          description={
                            <Link to="../cultura-desejada/relatorios/empresas">
                              Verifique o fit na aba de fit cultural
                            </Link>
                          }
                          height={340}
                        />
                      </div>
                    )}
                  </Row>
                </Card>
              </Col>
            </Row>
            <Modal
              title="VISUALIZAR GRÁFICO DE ARQUÉTIPOS CULTURAIS"
              centered
              width={900}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
              render="svg"
            >
              <TransformWrapper>
                <TransformComponent>
                  <Col
                    style={{
                      flexGrow: '1 1 auto',
                      display: 'flex',
                    }}
                    justify="center"
                    render="svg"
                  >
                    <Chart
                      renderer="svg"
                      height={500}
                      width={844}
                      data={cultura}
                      scale={arqRange}
                      autoFit
                      render="svg"
                    >
                      <Legend />
                      <Coordinate type="polar" radius={0.8} />
                      <Axis
                        name="Tipo"
                        line={null}
                        tickLine={null}
                        grid={{
                          lineStyle: {
                            lineDash: null,
                          },
                          hideFirstLine: false,
                        }}
                        render="svg"
                      />
                      <Tooltip />
                      <Axis
                        name="Valor"
                        line={null}
                        tickLine={null}
                        grid={{
                          type: 'polygon',
                          lineStyle: {
                            lineDash: null,
                          },
                          alternateColor: 'rgba(0, 0, 0, 0.04)',
                        }}
                        render="svg"
                      />
                      <Line
                        position="Tipo*Valor"
                        color={['Label', colors]}
                        size={2}
                        render="svg"
                      />
                      <Point
                        position="Tipo*Valor"
                        color={['Label', colors]}
                        shape="circle"
                        size={4}
                        style={{
                          stroke: '#fff',
                          lineWidth: 1,
                          fillOpacity: 1,
                        }}
                        render="svg"
                      />
                    </Chart>
                  </Col>
                </TransformComponent>
              </TransformWrapper>
            </Modal>

            <Row>
              {
                // SUBSTITUIÇÃO AQUI
              }
              <ColFill xs={24} md={24} sm={24} style={{ padding: '0px' }}>
                <Card
                  title="Estatísticas Gerais"
                  headStyle={cardHeadBlue}
                  style={{ padding: '0px' }}
                >
                  <EstatisticasGerais
                    estatisticas={estatisticas}
                    nps={nps}
                    style={{ padding: '0px' }}
                  />
                </Card>
              </ColFill>
            </Row>
          </Col>

          <Col
            lg={12}
            xxl={8}
            xs={24}
            sm={24}
            style={{
              flexGrow: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '8px',
            }}
          >
            <Row>
              <Col
                xs={24}
                md={24}
                sm={24}
                style={{ padding: '0', marginBottom: '8px' }}
              >
                <Card
                  title="Gestão"
                  bodyStyle={{ padding: '8px' }}
                  headStyle={cardHeadBlue}
                >
                  <Row>
                    <Col xs={24} md={12} lg={12} style={{ height: '340px' }}>
                      <Row
                        type="flex"
                        justify="center"
                        style={{ marginBottom: '10px' }}
                      >
                        <ChartTitle>Modelo de Gestão</ChartTitle>
                      </Row>
                      {gestao.length === 0 ? (
                        <Empty description={false} height={340 - 25 - 10} />
                      ) : (
                        <Chart
                          height={340 - 25 - 10}
                          data={dvGestao}
                          scale={cols}
                          autoFit
                        >
                          <Legend />
                          <Axis name="Label" />
                          <Axis name="percent" />
                          <Tooltip />
                          <Interval
                            adjust="stack"
                            position="Label*percent"
                            color={['Tipo', colors]}
                          />
                        </Chart>
                      )}
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Row
                        type="flex"
                        justify="center"
                        style={{ marginBottom: '10px' }}
                      >
                        <ChartTitle>Olhar para o Ambiente</ChartTitle>
                      </Row>
                      {olhares.length === 0 ? (
                        <Empty description={false} height={340 - 25 - 10} />
                      ) : (
                        <Chart
                          height={340 - 25 - 10}
                          data={dvOlhares}
                          scale={cols}
                          autoFit
                        >
                          <Legend />
                          <Axis name="Label" />
                          <Axis name="percent" />
                          <Tooltip />
                          <Interval
                            adjust="stack"
                            position="Label*percent"
                            color={['Tipo', colors]}
                          />
                        </Chart>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            {
              // SUBSTITUIÇÃO AQUI
            }
            {!HIDDEN ? (
              <ColFill xs={24} md={24} sm={24} style={noPadding}>
                <Card
                  title="Perfil Comportamental"
                  bodyStyle={{ padding: '8px' }}
                  headStyle={cardHeadBlue}
                >
                  {disc.length === 0 ? (
                    <Empty description={false} height={315} />
                  ) : (
                    /*
											<Chart
												height={315}
												data={disc}
												scale={discRange}
												padding='auto'
												autoFit
											>
												<Legend />
												<Coordinate type='polar' radius={0.8} />
												<Axis
													name='Tipo'
													line={null}
													tickLine={null}
													grid={{
														lineStyle: {
															lineDash: null,
														},
														hideFirstLine: false,
													}}
												/>
												<Tooltip />
												<Axis
													name='Valor'
													line={null}
													tickLine={null}
													grid={{
														type: 'polygon',
														lineStyle: {
															lineDash: null,
														},
													}}
												/>
												<Line
													position='Tipo*Valor'
													size={2}
													color={['Label', colors]}
												/>
												<Point
													position='Tipo*Valor'
													shape='circle'
													size={4}
													style={{
														stroke: '#fff',
														lineWidth: 1,
														fillOpacity: 1,
													}}
													color={['Label', colors]}
												/>
											</Chart> */
                    <Chart height={315} data={disc} autoFit>
                      <Legend />
                      <Axis name="Label" />
                      <Axis name="Valor" />
                      <Tooltip />
                      <Interval
                        position="Label*Valor"
                        color={['Tipo', colors]}
                        adjust={[
                          {
                            type: 'dodge',
                            marginRatio: 1 / 32,
                          },
                        ]}
                      />
                    </Chart>
                  )}
                </Card>
              </ColFill>
            ) : (
              <ColFill sm={24} xs={24} style={noPadding}>
                <Card title="Indicadores de Mudança" headStyle={cardHeadBlue}>
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col lg={8} xs={24} sm={24}>
                          {velocidadeAdocao ? (
                            <IndicadoresContainer>
                              <span>Velocidade de adoção à mudança</span>
                              <GaugeChart
                                forceFit
                                min={0}
                                max={100}
                                value={parseFloat(
                                  parseFloat(velocidadeAdocao.percent).toFixed(
                                    1
                                  )
                                )}
                                height={219}
                                range={[0, 25, 50, 75, 100]}
                                color={colors}
                                statistic={{
                                  visible: true,
                                  text: `${parseFloat(
                                    velocidadeAdocao.percent
                                  ).toFixed(1)}%`,
                                  size: 35,
                                  position: ['50%', '100%'],
                                }}
                                axis={{
                                  offset: -15,
                                  tickLine: {
                                    visible: true,
                                    length: 10,
                                  },
                                  label: {
                                    visible: false,
                                  },
                                }}
                              />
                            </IndicadoresContainer>
                          ) : (
                            <Empty />
                          )}
                        </Col>
                        <Col lg={8} xs={24} sm={24}>
                          {engajamento ? (
                            <IndicadoresContainer>
                              <span>Engajamento no processo de mudança</span>
                              <GaugeChart
                                forceFit
                                min={0}
                                max={100}
                                value={parseFloat(
                                  parseFloat(engajamento.percent).toFixed(1)
                                )}
                                statistic={{
                                  visible: true,
                                  text: `${parseFloat(
                                    engajamento.percent
                                  ).toFixed(1)}%`,
                                  size: 35,
                                  position: ['50%', '100%'],
                                }}
                                height={219}
                                range={[0, 25, 50, 75, 100]}
                                color={colors}
                                axis={{
                                  offset: -15,
                                  tickLine: {
                                    visible: true,
                                    length: 10,
                                  },
                                  label: {
                                    visible: false,
                                  },
                                }}
                              />
                            </IndicadoresContainer>
                          ) : (
                            <Empty />
                          )}
                        </Col>
                        <Col lg={8} xs={24} sm={24}>
                          {proficiencia ? (
                            <IndicadoresContainer>
                              <span>Proficiência</span>
                              <GaugeChart
                                forceFit
                                min={0}
                                max={100}
                                value={parseFloat(
                                  parseFloat(proficiencia.percent).toFixed(1)
                                )}
                                statistic={{
                                  visible: true,
                                  text: `${parseFloat(
                                    proficiencia.percent
                                  ).toFixed(1)}%`,
                                  size: 35,
                                  position: ['50%', '100%'],
                                }}
                                height={219}
                                range={[0, 25, 50, 75, 100]}
                                color={colors}
                                axis={{
                                  offset: -15,
                                  tickLine: {
                                    visible: true,
                                    length: 10,
                                  },
                                  label: {
                                    visible: false,
                                  },
                                }}
                              />
                            </IndicadoresContainer>
                          ) : (
                            <Empty />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </ColFill>
            )}
          </Col>

          <Col
            lg={24}
            xxl={10}
            xs={24}
            sm={24}
            style={{
              flexGrow: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '8px',
            }}
          >
            <Row>
              <Col
                sm={24}
                xs={24}
                style={{ padding: '0', marginBottom: '8px' }}
              >
                <Card
                  title="Forças estratégicas"
                  bodyStyle={{ padding: '8px' }}
                  headStyle={cardHeadBlue}
                >
                  {forcas.length === 0 ? (
                    <Empty description={false} height={340} />
                  ) : (
                    <Chart
                      height={340}
                      data={forcas.sort(function (a, b) {
                        if (a.Tipo < b.Tipo) {
                          return -1
                        }
                        if (a.Tipo > b.Tipo) {
                          return 1
                        }
                        return 0
                      })}
                      autoFit
                      padding={[0, 50, 0, 120]}
                      scale={forcasRange}
                    >
                      <Legend />
                      <Coordinate transpose scale={[1, -1]} />
                      <Axis
                        name="Tipo"
                        label={{
                          offset: 12,
                        }}
                      />
                      <Axis name="Valor" position="right" />
                      <Tooltip />
                      <Interval
                        position="Tipo*Valor"
                        color={['Label', [colors[1], colors[0]]]}
                        adjust={[
                          {
                            type: 'dodge',
                            marginRatio: 1 / 32,
                          },
                        ]}
                        label={[
                          'Valor',
                          (valor) => {
                            return `${valor.toFixed(1)}%`
                          },
                        ]}
                      />
                    </Chart>
                  )}
                </Card>
              </Col>
            </Row>

            {/*
						<ColFill sm={24} xs={24} style={noPadding}>
							<Card
								title='Destaques'
								headStyle={cardHeadBlue}
								bodyStyle={{
									display: 'flex',
									justifyContent: 'space-between',
									flexDirection: 'column',
								}}
							>
								{satisfacaoMax || valoresEvidenciaMax || niveisConfiancaMax ? (
									<>
										<DestaquesRow type='flex'>
											{niveisConfiancaMax && (
												<DestaquesContainer>
													<Tag>Níveis de Confiança</Tag>
													<LabelText>{niveisConfiancaMax.TEXTO}</LabelText>
													<Progress
														width={140}
														type='dashboard'
														percent={niveisConfiancaMax.VALOR}
														gapDegree={30}
														strokeColor={purple}
													/>
												</DestaquesContainer>
											)}

											{niveisConfiancaMax && satisfacaoMax && (
												<Divider type='vertical' id='divider1' />
											)}

											{satisfacaoMax && (
												<DestaquesContainer>
													<Tag>Satisfação Geral</Tag>
													<LabelText>{satisfacaoMax.TEXTO}</LabelText>
													<Progress
														width={140}
														type='dashboard'
														percent={satisfacaoMax.VALOR}
														gapDegree={30}
														strokeColor={purple}
													/>
												</DestaquesContainer>
											)}

											{((!niveisConfiancaMax &&
												satisfacaoMax &&
												valoresEvidenciaMax) ||
												(!satisfacaoMax &&
													niveisConfiancaMax &&
													valoresEvidenciaMax) ||
												(satisfacaoMax &&
													niveisConfiancaMax &&
													valoresEvidenciaMax)) && (
												<Divider type='vertical' id='divider1' />
											)}

											{valoresEvidenciaMax && (
												<DestaquesContainer>
													<Tag>Valores em Evidência</Tag>
													<LabelText>{valoresEvidenciaMax.TEXTO}</LabelText>
													<Progress
														width={140}
														type='dashboard'
														percent={valoresEvidenciaMax.VALOR}
														gapDegree={30}
														strokeColor={purple}
													/>
												</DestaquesContainer>
											)}
										</DestaquesRow>
									</>
								) : (
									<Empty />
								)}
							</Card>
						</ColFill>
								*/}
            {!HIDDEN && (
              <ColFill sm={24} xs={24} style={noPadding}>
                <Card title="Indicadores de Mudança" headStyle={cardHeadBlue}>
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col lg={8} xs={24} sm={24}>
                          {velocidadeAdocao ? (
                            <IndicadoresContainer>
                              <span>Velocidade de adoção à mudança</span>
                              <GaugeChart
                                forceFit
                                min={0}
                                max={100}
                                value={parseFloat(
                                  parseFloat(velocidadeAdocao.percent).toFixed(
                                    1
                                  )
                                )}
                                height={219}
                                range={[0, 25, 50, 75, 100]}
                                color={colors}
                                statistic={{
                                  visible: true,
                                  text: `${parseFloat(
                                    velocidadeAdocao.percent
                                  ).toFixed(1)}%`,
                                  size: 35,
                                  position: ['50%', '100%'],
                                }}
                                axis={{
                                  offset: -15,
                                  tickLine: {
                                    visible: true,
                                    length: 10,
                                  },
                                  label: {
                                    visible: false,
                                  },
                                }}
                              />
                            </IndicadoresContainer>
                          ) : (
                            <Empty />
                          )}
                        </Col>
                        <Col lg={8} xs={24} sm={24}>
                          {engajamento ? (
                            <IndicadoresContainer>
                              <span>Engajamento no processo de mudança</span>
                              <GaugeChart
                                forceFit
                                min={0}
                                max={100}
                                value={parseFloat(
                                  parseFloat(engajamento.percent).toFixed(1)
                                )}
                                statistic={{
                                  visible: true,
                                  text: `${parseFloat(
                                    engajamento.percent
                                  ).toFixed(1)}%`,
                                  size: 35,
                                  position: ['50%', '100%'],
                                }}
                                height={219}
                                range={[0, 25, 50, 75, 100]}
                                color={colors}
                                axis={{
                                  offset: -15,
                                  tickLine: {
                                    visible: true,
                                    length: 10,
                                  },
                                  label: {
                                    visible: false,
                                  },
                                }}
                              />
                            </IndicadoresContainer>
                          ) : (
                            <Empty />
                          )}
                        </Col>
                        <Col lg={8} xs={24} sm={24}>
                          {proficiencia ? (
                            <IndicadoresContainer>
                              <span>Proficiência</span>
                              <GaugeChart
                                forceFit
                                min={0}
                                max={100}
                                value={parseFloat(
                                  parseFloat(proficiencia.percent).toFixed(1)
                                )}
                                statistic={{
                                  visible: true,
                                  text: `${parseFloat(
                                    proficiencia.percent
                                  ).toFixed(1)}%`,
                                  size: 35,
                                  position: ['50%', '100%'],
                                }}
                                height={219}
                                range={[0, 25, 50, 75, 100]}
                                color={colors}
                                axis={{
                                  offset: -15,
                                  tickLine: {
                                    visible: true,
                                    length: 10,
                                  },
                                  label: {
                                    visible: false,
                                  },
                                }}
                              />
                            </IndicadoresContainer>
                          ) : (
                            <Empty />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </ColFill>
            )}
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default VisaoGeral
