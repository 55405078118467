import React, { Component } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import xlsxParser from 'xlsx-parse-json'
import { SearchOutlined } from '@ant-design/icons'
import { Input, Button } from 'antd'
import XLSX from 'xlsx'
import { format, parse, isValid } from 'date-fns'
import { enGB } from 'date-fns/locale'

// Components
import ColaboradoresInativosView from './ColaboradoresInativosView'

// CSS

import { validateForm } from '../../utils/forms'

import { isLider } from '../../services/auth'

const formFields = [
  {
    name: 'nome',
    required: true,
  },
  {
    name: 'cpf',
    required: true,
  },
  {
    name: 'email',
    required: true,
  },
]

moment.prototype.toMySqlDateTime = function () {
  return this.format('YYYY-MM-DD HH:mm:ss')
}

class ColaboradoresInativosController extends Component {
  state = {
    showModals: [false, false, false, false, false, false, false],
    currentPessoa: undefined,
    tableCount: 1,
    selectedRows: [],
    selectedRowKeys: [],
    filais: [],
    error: {},
  }

  openModal = (modalId) => {
    this.setState((prevState) => ({
      showModal: { ...prevState.showModal, [modalId]: true },
    }))
  }

  closeModal = (modalId) => {
    this.setState((prevState) => ({
      showModal: { ...prevState.showModal, [modalId]: false },
    }))
  }

  componentDidMount = () => {
    const { getColaboradores, getTimes, getCargos, listFiliais } = this.props

    getColaboradores()

    listFiliais().then(({ response }) => {
      if (response.status === 200) {
        this.setState({ filiais: response.data })
      }
    })

    if (!isLider()) {
      const promisses = [getTimes(), getCargos()]

      Promise.all(promisses)
    }
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  handleChangeModal = (id, { target }) =>
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.value } : elem
      ),
    }))

  handleChangeCheckbox = (id, { target }) => {
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.checked } : elem
      ),
    }))
  }

  openModal = (id) => {
    const { showModals } = this.state
    const newshowModals = showModals

    newshowModals[id] = true

    this.setState({ showModals: newshowModals })
  }

  closeModal = (id, { reset = true } = {}) => {
    const { showModals, modalStates } = this.state
    const newshowModals = showModals

    newshowModals[id] = false

    const newModalStates = modalStates

    if (reset) {
      newModalStates[id] = modals[id].initialState
    }

    this.setState({
      showModals: newshowModals,
      modalStates: newModalStates,
      error: {},
    })
  }

  okButtonProps = (id) => {
    if (id === 1) {
      const { fileList } = this.state.modalStates[1]

      return {
        disabled: fileList.length < 1 || fileList[0].status === 'error',
      }
    }
  }

  onModalSubmit = (id, { sendToAll = false } = {}) => {
    const { modalStates, selectedRows } = this.state
    const { getCargos, postCargo } = this.props

    if (id === 4) {
      postCargo(modalStates[id]).then(({ response }) => {
        this.handleChange(
          {
            target: {
              name: 'datademissao',
              value: undefined,
            },
          },
          'desligamentoModal'
        )
        if (response.status === 200) {
          toast.success('Adicionado com sucesso!')
          getCargos()
          this.closeModal(id)
        }
      })
    }
  }

  onTableDelete = (newData, deleted) => {
    const { deleteColaborador, getColaboradores } = this.props

    deleteColaborador({ id: deleted.key }).then(({ response }) => {
      if (response.status === 200) {
        getColaboradores()
        toast.success('Colaborador desligado com sucesso')
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder="Buscar"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  excluirPerfil = (id) => {
    const { excluirPerfil, getColaboradores } = this.props

    excluirPerfil({ id }).then(({ response }) => {
      if (response.status === 200) {
        getColaboradores()
        toast.success('Colaborador excluido com sucesso')
      }
    })
  }

  setCurrentPessoa = (pes_id = undefined) => {
    this.setState({ currentPessoa: pes_id })
  }

  closeModalDesligamento = () => {
    this.setCurrentPessoa()
    this.closeModal(6, { reset: false })
  }

  submitModalDesligamento = (data) => {
    this.props
      .desativarPerfil({
        id: this.state.currentPessoa,
        datademissao: data.datademissao,
      })
      .then(({ response }) => {
        if (response.status === 200 || response.status === 204) {
          this.closeModalDesligamento()
          this.props.getColaboradores()
        }
      })
  }

  render() {
    const {
      onTableDelete,
      onModalSubmit,
      openModal,
      closeModal,
      handleChangeModal,
      onFileUpload,
      onRemoveFile,
      okButtonProps,
      handleChangeCheckbox,
      getColumnSearchProps,
      excluirPerfil,
      exportarExcel,
      setCurrentPessoa,
      closeModalDesligamento,
      submitModalDesligamento,
    } = this
    const { showModals, tableCount, modalStates, error, filiais } = this.state
    const { times, cargos, listaColaboradoresInativos, onboarding } = this.props

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys,
          selectedRows,
        })
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }

    return (
      <ColaboradoresInativosView
        openModal={openModal}
        closeModal={closeModal}
        onModalSubmit={onModalSubmit}
        showModals={showModals}
        modalStates={modalStates}
        handleChangeModal={handleChangeModal}
        onTableDelete={onTableDelete}
        listaColaboradoresInativos={listaColaboradoresInativos}
        tableCount={tableCount}
        rowSelection={rowSelection}
        onFileUpload={onFileUpload}
        onRemoveFile={onRemoveFile}
        okButtonProps={okButtonProps}
        getColumnSearchProps={getColumnSearchProps}
        handleChangeCheckbox={handleChangeCheckbox}
        times={times}
        cargos={cargos}
        filiais={filiais}
        excluirPerfil={excluirPerfil}
        error={error}
        exportarExcel={exportarExcel}
        onboarding={onboarding}
        setCurrentPessoa={setCurrentPessoa}
        closeModalDesligamento={closeModalDesligamento}
        submitModalDesligamento={submitModalDesligamento}
      />
    )
  }
}

export default ColaboradoresInativosController
