import { connect } from 'react-redux'

// Components
import DashboardLayoutController from './DashboardLayoutController'

// Actions
import { getUserInfo } from '../../containers/login/actions/actionsLogin'

const mapStateToProps = ({ login: { user } }) => {
  return { user }
}

export default connect(mapStateToProps, { getUserInfo })(
  DashboardLayoutController
)
