import styled from 'styled-components'

import { Space } from 'antd'

export const Header = styled.div`
  padding: 24px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`

export const FormSubheader = styled.h3`
  width: 33%;
  font-weight: normal;
`

export const FormColumn = styled(Space)`
  width: 66%;

  & .ant-form-item {
    margin-bottom: 6px;
  }
`

export const Instruction = styled.p`
  font-size: 16px;

  .highlight {
    font-weight: bold;
  }
`

export const QuestionContainer = styled.div``
