import * as constants from '../constants'

export const listRequisicoes = () => {
  return {
    type: constants.PS_REQUISICOES_LIST_REQUISICOES,
    api: {
      method: 'get',
      url: constants.URL_PS_REQUISICOES_LIST_REQUISICOES,
    },
  }
}

export const storeRequisicao = ({ ufid, cidadeid, homeoffice, ...data }) => {
  return {
    type: constants.PS_REQUISICOES_STORE_REQUISICAO,
    api: {
      method: 'post',
      url: constants.URL_PS_REQUISICOES_STORE_REQUISICAO,
      data: {
        ...data,
        requisicao: 1,
        ...(homeoffice ? { homeoffice } : { cidadeid, ufid }),
      },
    },
  }
}

export const updateRequisicao = ({
  processo_id,
  ufid,
  cidadeid,
  homeoffice,
  ...data
}) => {
  return {
    type: constants.PS_REQUISICOES_UPDATE_REQUISICAO,
    api: {
      method: 'put',
      url: constants.URL_PS_REQUISICOES_UPDATE_REQUISICAO(processo_id),
      data: {
        ...data,
        ...(homeoffice
          ? { homeoffice }
          : { cidadeid, ufid, homeoffice: false }),
      },
    },
  }
}

export const cancelRequisicao = (processo_id) => {
  return {
    type: constants.PS_REQUISICOES_CANCEL_REQUISICAO,
    api: {
      method: 'delete',
      url: constants.URL_PS_REQUISICOES_CANCEL_REQUISICAO(processo_id),
    },
  }
}

export const reenviarRequisicao = ({ processo_id, ...data }) => {
  return {
    type: constants.PS_REQUISICOES_REENVIAR_REQUISICAO,
    api: {
      method: 'post',
      url: constants.URL_PS_REQUISICOES_REENVIAR_REQUISICAO(processo_id),
      data: {
        ...data,
      },
    },
  }
}

export const aprovarEtapaRequisicao = ({
  processo_id,
  etapa_id,
  observacao,
}) => {
  return {
    type: constants.PS_REQUISICOES_APROVAR_ETAPA_REQUISICAO,
    api: {
      method: 'post',
      url: constants.URL_PS_REQUISICOES_APROVAR_ETAPA_REQUISICAO({
        processo_id,
        etapa_id,
      }),
      data: {
        observacao,
      },
    },
  }
}

export const reprovarEtapaRequisicao = ({
  processo_id,
  etapa_id,
  observacao,
}) => {
  return {
    type: constants.PS_REQUISICOES_REPROVAR_ETAPA_REQUISICAO,
    api: {
      method: 'post',
      url: constants.URL_PS_REQUISICOES_REPROVAR_ETAPA_REQUISICAO({
        processo_id,
        etapa_id,
      }),
      data: {
        observacao,
      },
    },
  }
}

export const checkRequisitor = () => {
  return {
    type: constants.PS_REQUISICOES_CHECK_REQUISITOR,
    api: {
      method: 'get',
      url: constants.URL_PS_REQUISICOES_CHECK_REQUISITOR,
    },
  }
}

export const getTimes = () => {
  return {
    type: constants.PS_REQUISICOES_TIMES_GET,
    api: {
      method: 'get',
      url: constants.URL_PS_REQUISICOES_TIMES_GET,
    },
  }
}

export const getEtapas = (grupo_id) => {
  return {
    type: constants.PS_REQUISICOES_ETAPAS_GET,
    api: {
      method: 'get',
      url: constants.URL_PS_REQUISICOES_ETAPAS_GET(grupo_id),
    },
  }
}
