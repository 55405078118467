import * as constants from '../constants'

export const getGrupos = () => {
  return {
    type: constants.GET_RELATORIOSTIMES_TIMES,
    api: {
      method: 'get',
      url: constants.URL_RELATORIOSTIMES_TIMES,
    },
  }
}

export const setGrupoSelecionado = (id) => {
  return {
    type: constants.SET_RELATORIOSTIMES_TIME_SELECIONADO,
    grupo_id: id,
  }
}

export const getCulturaOrganizacional = (id) => {
  return {
    type: constants.GET_RELATORIOSTIMES_CULTURA_ORGANIZACIONAL,
    api: {
      method: 'get',
      url: constants.URL_RELATORIOSTIMES_CULTURA_ORGANIZACIONAL(id),
    },
  }
}

export const getCulturas = (id) => {
  return {
    type: constants.GET_RELATORIOSTIMES_CULTURA,
    api: {
      method: 'post',
      url: constants.URL_RELATORIOSTIMES_CULTURA(id),
    },
  }
}

export const getVetor = (id) => {
  return {
    type: constants.GET_RELATORIOSTIMES_VETOR,
    api: {
      method: 'get',
      url: constants.URL_RELATORIOSTIMES_VETOR(id),
    },
  }
}

export const getPerfis = (id) => {
  return {
    type: constants.GET_RELATORIOSTIMES_PERFIS,
    api: {
      method: 'get',
      url: constants.URL_RELATORIOSTIMES_PERFIS(id),
    },
  }
}

export const getTopArq = () => {
  return {
    type: constants.GET_RELATORIOSTIMES_TOP_ARQ,
    api: {
      method: 'get',
      url: constants.URL_RELATORIOSTIMES_TOP_ARQ,
    },
  }
}
