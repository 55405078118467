import styled from 'styled-components'

import { Button } from 'antd'

import { shade, lighten } from 'polished'

export const FeedbackContainer = styled.div`
  margin: 0px 16px 16px 16px;
  .ant-collapse {
    margin-top: 8px;
  }

  > p {
    padding: 10px;
    background-color: ${shade(0.05, '#fff')};
    border: 1px solid ${lighten(0.6, '#000')};
  }
`

export const Label = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #000;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
    flex-direction: column;

    > * + * {
      margin-top: 8px;
    }

    ${Label} {
      flex: 1;
      text-align: center;
    }
  }
`

export const FeedbackButton = styled(Button)`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
