import React, { useEffect, useState, useCallback, useMemo } from 'react'
import {
  ExclamationCircleFilled,
  LinkOutlined,
  RetweetOutlined,
  PoweroffOutlined,
  DashboardOutlined,
  CopyOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  ProfileOutlined,
  FormOutlined,
  SolutionOutlined,
} from '@ant-design/icons'

import {
  Badge,
  message,
  Divider,
  Popconfirm,
  Tooltip,
  Row,
  Space,
  Tag,
  Button,
  Col,
} from 'antd'

import moment from 'moment'

import { sorterString, sorterDate } from '../../../../../utils/utils'
import { ReactComponent as Svg } from '../../../../../assets/onu_pcd.svg'

import Link from '../../../../../components/privateLink/PrivateLink'
import {
  Container,
  Table,
  TableHeader,
  LinkSpan,
  PercentageBox,
  FlexCard,
  StepBox,
  VagaContainer,
  ContainerSelecionadas,
  ContainerTextoLongo,
  ContainerTexto,
} from '../styles'

// Utils

function TabProcessosAtivos({
  solicitadoresOptions,
  solicitadorFilterState,
  huntersFilterOptions,
  hunterFilterState,
  prioridadesOptions,
  tiposOptions,
  handleSuspenderProcesso,
  handleRetomarProcesso,
  handleCloneProcesso,
  handleTableChange,
  setToExport,
  toExport,
  exportarExcel,
  setShowModalNovoProcesso,
  loading,
  processos,
  clearTableFilters,
  handleModalUpdateProcesso,
  setShowModalReqHistory,
  setReqHistory,
}) {
  const tableProcessosColumns = useMemo(() => {
    return [
      {
        title: 'Número',
        dataIndex: 'numero',
        sorter: (a, b) => sorterString(a, b, 'numero'),
        render: (text, record) => {
          if (text && text !== null) {
            return <span>{text.padStart(5, '0')}</span>
          }
        },
      },
      {
        title: 'Vaga',
        dataIndex: ['cargo', 'cargo_nome'],
        width: 200,
        render: (text, record) => (
          <>
            {' '}
            <VagaContainer>
              <div>
                <Link
                  style={{ display: 'flex' }}
                  to={`/ps/${record.processo_id}`}
                >
                  {text}
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {record.pcd === 1 && (
                      <Tooltip
                        title={
                          record.pcd_tipo
                            ? `Tipo de PCD: ${record.pcd_tipo}`
                            : 'Tipo de PCD: Todos'
                        }
                      >
                        <Tag color="blue" style={{ marginLeft: 8 }}>
                          <Svg
                            height="22"
                            width="22"
                            style={{ display: 'flex', alignItems: 'center' }}
                          />
                        </Tag>
                      </Tooltip>
                    )}
                  </span>
                </Link>
              </div>
            </VagaContainer>
          </>
        ),
      },
      {
        title: 'Local',
        render: (text, record) => (
          <ContainerTexto>
            {record.homeoffice
              ? 'Home Office'
              : record.cidade && record.cidade.CIDADE
              ? record.cidade.uf && record.cidade.uf.UF
                ? `${record.cidade.CIDADE.trim()} / ${record.cidade.uf.UF.trim()}`
                : record.cidade.CIDADE.trim()
              : ''}
          </ContainerTexto>
        ),
      },
      /*        {
       title: (
          <>
            <span>Vaga</span>
            <br />
            <span>(Local)</span>{" "}
          </>
        ),
        dataIndex: "cargo_nome",
        width: "15%",
        render: (text, record) => (
          <VagaContainer>
            <div>
              <span>{text}</span>
              <span>{record.homeoffice ? "(HomeOffice)" : record.cidade}</span>
              {record.numero && <span>n° {record.numero}</span>}
            </div>
          </VagaContainer>
        ),
        sorter: (a, b) => sorterString(a, b, "cargo_nome"),
        ...getColumnSearchProps("cidade"),
        onFilter: (value, record) => {
          return (
            (record.cargo_nome &&
              record.cargo_nome
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())) ||
            (record.cidade &&
              record.cidade
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())) ||
            "homeoffice"
              .toLowerCase()
              .includes(value.toLowerCase().replace(" ", ""))
          );
        },
      }, */
      {
        title: 'Sigiloso',
        dataIndex: 'sigiloso',
        align: 'center',
        sorter: (a, b) => sorterString(a, b, 'sigiloso'),
        render: (text, record) => (
          <span>{record.sigiloso ? 'Sim' : 'Não'}</span>
        ),
      },
      {
        title: 'Quantidade',
        align: 'center',
        dataIndex: 'qtde',
        sorter: (a, b) => a.qtde - b.qtde,
      },
      {
        title: 'Filial',
        dataIndex: ['filial', 'nome'],
        width: 150,
        align: 'center',
      },
      {
        title: 'Time',
        dataIndex: ['grupo', 'grupo_nome'],
        width: 150,
        render: (text, record) => <ContainerTexto>{text}</ContainerTexto>,
      },
      {
        title: 'Solicitado por',
        width: 50,
        dataIndex: ['creator', 'pes_nome'],
        filters: solicitadoresOptions,
        filteredValue: solicitadorFilterState || [],
        onFilter: (value, record) => {
          return record.creator && record.creator.pes_id === value
        },
        sorter: (a, b) => sorterString(a, b, ['creator', 'pes_nome']),
        render: (text, record) => (
          <ContainerTextoLongo>{text}</ContainerTextoLongo>
        ),
      },
      {
        title: 'Recrutador(a)',
        width: 50,
        dataIndex: ['responsible', 'pes_nome'],
        filters: huntersFilterOptions,
        filteredValue: hunterFilterState || [],
        onFilter: (value, record) => {
          return record.responsible && record.responsible.pes_id === value
        },
        sorter: (a, b) => sorterString(a, b, ['responsible', 'pes_nome']),
        render: (text, record) => (
          <ContainerTextoLongo>{text}</ContainerTextoLongo>
        ),
      },
      {
        title: 'Prioridade',
        dataIndex: 'prioridade',
        align: 'center',
        sorter: (a, b) => sorterString(a, b, 'prioridade'),
        render: (text, record) => {
          const prioridade = prioridadesOptions.find((p) => p.value === text)

          return <>{prioridade && <span> {prioridade.label} </span>}</>
        },
      },
      {
        title: 'Inscritos',
        dataIndex: 'inscritos',
        align: 'center',
        sorter: (a, b) => a.inscritos - b.inscritos,
      },
      {
        title: 'Contrato',
        dataIndex: ['contrato', 'nome'],
        align: 'center',
      },
      {
        title: 'Triados',
        dataIndex: 'triados',
        sorter: (a, b) => a.triados - b.triados,
        render: (text, record) => {
          return (
            <PercentageBox>
              <span>{text}</span>
              <span>({record.pct_triados || 0}%)</span>
            </PercentageBox>
          )
        },
      },
      {
        title: 'Tipo',
        dataIndex: 'tipo',
        render: (text, record) => {
          const tipo = tiposOptions.find((p) => p.value === record.tipo)
          return <>{tipo && <span>{tipo.label}</span>}</>
        },
      },
      {
        title: 'Contratados',
        dataIndex: 'contratados',
        sorter: (a, b) => a.contratados - b.contratados,
        render: (text, record) => {
          return (
            <>
              <PercentageBox>
                <span>{text}</span>
                <span>({record.pct_contratados || 0}%)</span>
              </PercentageBox>
            </>
          )
        },
      },
      {
        title: 'Motivo',
        dataIndex: ['motivo', 'nome'],
      },
      {
        title: 'Início',
        dataIndex: 'criadoem',
        align: 'center',
        render: (text, record) =>
          text
            ? moment(text)
                .utc()
                .tz(moment.tz.guess())
                .format('DD/MM/YYYY (HH:mm)')
            : '',
        sorter: (a, b) => sorterDate(a, b, 'criadoem', 'DD/MM/YYYY'),
      },
      {
        title: (
          <>
            <span>Duração</span>
            <br />
            <span>(Etapa)</span>
          </>
        ),
        dataIndex: 'duracao',
        align: 'center',
        render: (text, record) => {
          const lastEtapa =
            record.etapas && record.etapas.length > 0
              ? record.etapas.reduce(
                  (acc, next) =>
                    next.etapaCandidato && next.etapaCandidato.length > 0
                      ? next
                      : acc,
                  null
                )
              : null
          return (
            <>
              <span>{text} dias</span>
              {lastEtapa && (
                <>
                  <br />
                  <span>({lastEtapa.etapa})</span>
                </>
              )}
            </>
          )
        },
        sorter: (a, b) => a.duracao - b.duracao,
      },
      {
        title: 'Status',
        dataIndex: 'prazo',
        align: 'center',
        width: 150,
        render: (text, record) => {
          return record.suspenso ? (
            <>
              <p>(Suspenso)</p> <p>{record.prazo}</p>
            </>
          ) : (
            <ContainerTexto>{text}</ContainerTexto>
          )
        },
      },
      {
        title: 'Ações',
        align: 'center',
        key: 'acoes',
        width: 100,
        fixed: 'right',
        render: (text, record) => {
          return (
            <>
              {!record.finalizadoem && (
                <Row
                  style={{
                    width: '150px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title="Editar">
                    <LinkSpan onClick={() => handleModalUpdateProcesso(record)}>
                      <FormOutlined />
                    </LinkSpan>
                  </Tooltip>
                  {!record.suspenso ? (
                    <>
                      <Divider type="vertical" />
                      <Popconfirm
                        title="Tem certeza que deseja suspender o Processo Seletivo?"
                        onConfirm={() =>
                          handleSuspenderProcesso(record.processo_id)
                        }
                        okText="Sim"
                        cancelText="Não"
                      >
                        <Tooltip title="Suspender">
                          <LinkSpan>
                            <PauseCircleOutlined />
                          </LinkSpan>{' '}
                        </Tooltip>
                      </Popconfirm>
                    </>
                  ) : (
                    <>
                      <Divider type="vertical" />
                      <Popconfirm
                        title="Tem certeza que deseja retomar o Processo Seletivo?"
                        onConfirm={() =>
                          handleRetomarProcesso(record.processo_id)
                        }
                        okText="Sim"
                        cancelText="Não"
                      >
                        <Tooltip title="Retomar">
                          <LinkSpan>
                            <PlayCircleOutlined />
                          </LinkSpan>
                        </Tooltip>
                      </Popconfirm>
                    </>
                  )}
                  {record.requisicao &&
                    record.history &&
                    record.history.length > 0 && (
                      <>
                        <Divider type="vertical" />
                        <Tooltip title="Historico">
                          <LinkSpan
                            onClick={() => {
                              setShowModalReqHistory(true)
                              setReqHistory(record.history)
                            }}
                          >
                            {' '}
                            <SolutionOutlined />
                          </LinkSpan>
                        </Tooltip>
                      </>
                    )}

                  {/* <Divider type='vertical' />
                    <Popconfirm
                      title='Tem certeza que deseja cancelar esse Processo Seletivo?'
                      okText='Sim'
                      cancelText='Não'
                      icon={
                        <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                      }
                      onConfirm={() => handleDeleteProcesso(record)}
                    >
                      <LinkSpan>Excluir</LinkSpan>
                    </Popconfirm>
                    */}
                  {!record.suspenso && (
                    <>
                      <Divider type="vertical" />
                      <Tooltip title="Painel">
                        <LinkSpan>
                          {' '}
                          <Link to={`/ps/${record.processo_id}`}>
                            <ProfileOutlined />
                          </Link>
                        </LinkSpan>
                      </Tooltip>
                      <Divider type="vertical" />
                      <Tooltip title="Clonar vaga" placement="top">
                        <LinkSpan>
                          <CopyOutlined
                            onClick={() => handleCloneProcesso(record)}
                          />
                        </LinkSpan>
                      </Tooltip>
                    </>
                  )}
                </Row>
              )}
            </>
          )
        },
      },
    ]
  }, [
    solicitadoresOptions,
    solicitadorFilterState,
    huntersFilterOptions,
    hunterFilterState,
    prioridadesOptions,
    tiposOptions,
    handleSuspenderProcesso,
    handleRetomarProcesso,
    handleCloneProcesso,
    handleModalUpdateProcesso,
    setShowModalReqHistory,
    setReqHistory,
  ])

  return (
    <div>
      <div>
        <Table
          title={() => (
            <TableHeader>
              <Space xs={24} md={12}>
                {solicitadorFilterState.length !== 0 ||
                hunterFilterState.length !== 0 ? (
                  <>
                    <span>Filtros selecionados:</span>
                    <ContainerSelecionadas>
                      {hunterFilterState.map((filtro, index) => {
                        const hunter = huntersFilterOptions.find(
                          (hunter) => hunter.value === filtro
                        )

                        if (!hunter) return null

                        return <Tag key={index}>Hunter: {hunter.text}</Tag>
                      })}
                      {solicitadorFilterState.map((filtro, index) => {
                        const solicitador = solicitadoresOptions.find(
                          (solicitador) => solicitador.value === filtro
                        )

                        if (!solicitador) return null

                        return (
                          <Tag key={index}>
                            Solicitado Por: {solicitador.text}
                          </Tag>
                        )
                      })}
                    </ContainerSelecionadas>
                    <Button
                      onClick={() => clearTableFilters()}
                      style={{ marginRight: '10px' }}
                    >
                      LIMPAR FILTROS
                    </Button>
                  </>
                ) : (
                  <Row />
                )}
              </Space>
              <Space
                xs={24}
                md={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  onClick={() => {
                    toExport.length == 0
                      ? exportarExcel(processos)
                      : exportarExcel(toExport)
                  }}
                >
                  EXPORTAR PARA EXCEL
                </Button>
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => {
                    setShowModalNovoProcesso(true)
                  }}
                >
                  NOVO PROCESSO SELETIVO
                </Button>
              </Space>
            </TableHeader>
          )}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} registros`,
          }}
          loading={loading}
          dataSource={processos}
          columns={tableProcessosColumns}
          rowKey={(record) => record.processo_id}
          onChange={(pagination, filters, sorter, extra) => {
            handleTableChange(pagination, filters, sorter)
            setToExport(extra.currentDataSource)
          }}
          size="middle"
        />
      </div>
    </div>
  )
}

export default TabProcessosAtivos
