import React, { useState, useEffect, useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { SearchOutlined } from '@ant-design/icons'

import { Input, Button } from 'antd'

// Constants
import { acaoModals, pdtModals, metaModals } from './constants/constantsPDT'

import { validateForm } from '../../utils/forms'

// Components
import PDTView from './PDTView'

const modals = [...acaoModals, ...pdtModals, ...metaModals]

const acoesFormFields = [
  {
    name: 'descricao',
    required: true,
  },
  {
    name: 'sabotadores',
    required: true,
  },
  {
    name: 'opcoes',
    required: true,
  },
  {
    name: 'startAndEnd',
    required: true,
  },
]

const pdtFormFields = [
  {
    name: 'objetivo',
    required: true,
  },
]

const metaFormFields = [{ name: 'descricao', required: true }]

// import { Container } from './styles';

export default function PDTController(
  {
    id,
    pdts,
    listPDTs,
    storePDT,
    completePDT,
    updatePDT,
    deletePDT,
    storeMeta,
    updateMeta,
    deleteMeta,
    getAcao,
    storeAcao,
    completeAcao,
    updateAcao,
    deleteAcao,
    changePDTData,
    getPessoaPDT,
  },
  searchInput
) {
  const activeTab = useSelector((state) => state.timesDetalhes.activeTab)

  const [searchText, setSearchText] = useState('')

  const [showModals, setShowModals] = useState([false, false, false, false])
  const [modalStates, setModalStates] = useState(
    modals.map((modal) => ({ ...modal.initialState }))
  )

  const [error, setError] = useState({})

  useEffect(() => {
    if (activeTab === '2') listPDTs(id)
  }, [activeTab, id, listPDTs])

  // Handlers de controle geral do CRUD

  const handleGetPessoa = (pes_id) => {
    return getPessoaPDT(pes_id)
  }

  const handleCreatePDT = (pdt) => {
    return storePDT(id, pdt)
  }

  const handleCompletePDT = (pdt_id) => {
    return completePDT(pdt_id)
  }

  const handleUpdatePDT = (pdt_id, pdt) => {
    return updatePDT(pdt_id, pdt)
  }

  const handleDeletePDT = (pdt_id) => {
    return deletePDT(pdt_id)
  }

  const handleCreateMeta = (pdt_id, descricao) => {
    return storeMeta(pdt_id, { descricao })
  }

  const handleUpdateMeta = (pdt_id, meta) => {
    return updateMeta(pdt_id, meta)
  }

  const handleDeleteMeta = (pdt_id, meta_id) => {
    return deleteMeta(pdt_id, meta_id)
  }

  const handleCreateAcao = (pdt_id, meta_id, acao) => {
    return storeAcao(pdt_id, meta_id, acao)
  }

  const handleUpdateAcao = (pdt_id, acao) => {
    return updateAcao(pdt_id, acao)
  }

  const handleCompleteAcao = (pdt_id, meta_id, acao_id) => {
    return completeAcao(pdt_id, meta_id, acao_id)
  }

  const handleDeleteAcao = (pdt_id, meta_id, acao_id) => {
    return deleteAcao(pdt_id, meta_id, acao_id)
  }

  // Handlers de controle da interface e dos estados

  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    setSearchText(selectedKeys[0])
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder="Buscar"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  const findMetaIndex = (meta_id, metas) =>
    metas.findIndex((m) => m.meta_id === meta_id)

  const findPDTIndex = (pdt_id) => pdts.findIndex((f) => f.pdt_id === pdt_id)

  const handleChangePDTData = (pdt_id, data) => {
    const pdtIndex = findPDTIndex(pdt_id)
    if (pdtIndex !== -1) {
      const pdt = pdts[pdtIndex]
      const newPDT = { ...pdt, ...data }
      changePDTData(pdt_id, { pdt: newPDT })
    }
  }

  const handleChangeMetaDescricao = (pdt_id, meta_id, descricao) => {
    const pdtIndex = findPDTIndex(pdt_id)
    if (pdtIndex !== -1) {
      const pdt = pdts[pdtIndex]
      const { metas } = pdt
      const metasUpdated = metas
      const metaIndex = findMetaIndex(meta_id, metas)
      if (metaIndex !== -1) {
        metasUpdated[metaIndex].descricao = descricao
        changePDTData(pdt_id, { metas: metasUpdated })
      }
    }
  }

  // MODALS

  const handleChangeModal = (id, { target }) => {
    let dataValues = null
    if (target.name === 'startAndEnd' && (id === 0 || id === 1)) {
      dataValues = {
        start: target.value[0],
        end: target.value[1],
        startAndEnd: true,
      }
    }

    setModalStates(
      modalStates.map((elem, index) =>
        index === id
          ? dataValues
            ? { ...elem, ...dataValues }
            : { ...elem, [target.name]: target.value }
          : elem
      )
    )
  }

  const openModal = useCallback(
    (id, data = null) => {
      if (id !== -1) {
        const newshowModals = showModals

        newshowModals[id] = true

        setShowModals([...newshowModals])

        const newModalStates = modalStates
        newModalStates[id].open = false

        if (data) {
          newModalStates[id] = { ...newModalStates[id], ...data }
        }

        setModalStates([...newModalStates])
      }
    },
    [modalStates, showModals]
  )

  const closeModal = (id) => {
    const newshowModals = showModals

    newshowModals[id] = false

    const newModalStates = modalStates

    newModalStates[id] = modals[id].initialState

    setShowModals(newshowModals)
    setModalStates(newModalStates)
    setError({})
  }

  const editModal = (idModal, pdt, meta, acao_id) => {
    const { acoes } = meta
    const newModalState = acoes.filter((a) => a.acao_id === acao_id)
    setModalStates(
      modalStates.map((elem, index) =>
        index === idModal
          ? { ...newModalState[0], pdt, meta, open: true }
          : elem
      )
    )
  }

  useEffect(() => {
    const modalId = modalStates.findIndex((m) => m.open)
    if (modalId !== -1) openModal(modalId)
  }, [modalStates, openModal, showModals])

  const onModalSubmit = (id) => {
    const modalState = modalStates[id]

    if (id === 0) {
      const { pdt, meta } = modalState
      const { pdt_id } = pdt
      const { meta_id } = meta

      const error = validateForm(acoesFormFields, modalState)
      setError(error.messages)

      if (error.hasError) return

      handleCreateAcao(pdt_id, meta_id, modalState).then(({ response }) => {
        if (response.status === 200) {
          closeModal(0)
        }
      })
    } else if (id === 1) {
      const { pdt, meta } = modalState
      const { pdt_id } = pdt
      const { meta_id } = meta

      const error = validateForm(acoesFormFields, modalState)
      setError(error.messages)

      if (error.hasError) return

      handleUpdateAcao(pdt_id, { meta_id, ...modalState }).then(
        ({ response }) => {
          if (response.status === 200) {
            closeModal(1)
          }
        }
      )
    } else if (id === 2) {
      const error = validateForm(pdtFormFields, modalState)

      const { objetivo } = modalState

      setError(error.messages)

      if (error.hasError) return

      handleCreatePDT({ objetivo }).then(({ response }) => {
        if (response.status === 200) {
          closeModal(2)
        }
      })
    } else if (id === 3) {
      const error = validateForm(metaFormFields, modalState)

      const { descricao, pdt } = modalState

      const { pdt_id } = pdt

      setError(error.messages)

      if (error.hasError) return

      handleCreateMeta(pdt_id, descricao).then(({ response }) => {
        if (response.status === 200) {
          closeModal(3)
        }
      })
    }
  }

  return (
    <PDTView
      pdts={pdts}
      getColumnSearchProps={getColumnSearchProps}
      handleCompletePDT={handleCompletePDT}
      handleUpdatePDT={handleUpdatePDT}
      handleDeletePDT={handleDeletePDT}
      handleCreateMeta={handleCreateMeta}
      handleUpdateMeta={handleUpdateMeta}
      handleDeleteMeta={handleDeleteMeta}
      handleCompleteAcao={handleCompleteAcao}
      handleDeleteAcao={handleDeleteAcao}
      handleChangeMetaDescricao={handleChangeMetaDescricao}
      openModal={openModal}
      closeModal={closeModal}
      onModalSubmit={onModalSubmit}
      showModals={showModals}
      modalStates={modalStates}
      handleChangeModal={handleChangeModal}
      editModal={editModal}
      error={error}
      handleCreatePDT={handleCreatePDT}
      handleGetPessoa={handleGetPessoa}
      pes_id={id}
      handleChangePDTData={handleChangePDTData}
    />
  )
}
