import React, { useEffect, useState } from 'react'

import { Col, Row, Slider, Form, InputNumber, Button, Skeleton } from 'antd'

import {
  Chart,
  Line,
  Point,
  Axis,
  Tooltip,
  Coordinate,
  Interval,
  Legend,
} from 'bizcharts'

import { toast } from 'react-toastify'
import { arqRange, colors, forcasRange } from '../../../utils/utils'
import { ArquetipoBox } from './styles'
import { postConsensuar } from '../Api/api'

const ARQUETIPOS = [
  {
    arquetipo_id: 1,
    nome: 'Empreendedora',
  },
  {
    arquetipo_id: 2,
    nome: 'Inovação',
  },
  {
    arquetipo_id: 3,
    nome: 'Colaboração',
  },
  {
    arquetipo_id: 4,
    nome: 'Clã',
  },
  {
    arquetipo_id: 5,
    nome: 'Aprendizado',
  },
  {
    arquetipo_id: 6,
    nome: 'Complacência',
  },
  {
    arquetipo_id: 7,
    nome: 'Autoridade',
  },
  {
    arquetipo_id: 8,
    nome: 'Regra',
  },
]

function CulturaConsensuada(props) {
  const [indicadores, setIndicadores] = useState(props.indices)
  const [indicatorArray, setArray] = useState([])
  const [forcasGraph, setForcas] = useState(props.forcas)
  const [isLoading, setIsLoading] = useState(false)
  const [copyLoading, setCopyLoading] = useState(false)
  const [valueTotal, setValueTotal] = useState()
  const [showCopy, setShowCopy] = useState(true)

  // Atualizar valor da porcentagem total sempre que o dado for alterado
  useEffect(() => {
    let valueTotal = 0
    indicadores.Empreendedora ? (valueTotal += indicadores.Empreendedora) : null
    indicadores.Inovação ? (valueTotal += indicadores.Inovação) : null
    indicadores.Colaboração ? (valueTotal += indicadores.Colaboração) : null
    indicadores.Clã ? (valueTotal += indicadores.Clã) : null
    indicadores.Aprendizado ? (valueTotal += indicadores.Aprendizado) : null
    indicadores.Complacência ? (valueTotal += indicadores.Complacência) : null
    indicadores.Autoridade ? (valueTotal += indicadores.Autoridade) : null
    indicadores.Regra ? (valueTotal += indicadores.Regra) : null

    if (Number.isNaN(valueTotal)) setValueTotal(0)
    else setValueTotal(valueTotal.toFixed(1))
  }, [indicadores])

  const tipFormatterFitCultural = (value) => {
    if (value >= 16.7) {
      return <span>Muito Alto</span>
    }
    if (value >= 13) {
      return <span>Alto</span>
    }
    if (value >= 9.7) {
      return <span>Médio</span>
    }
    if (value >= 6) {
      return <span>Baixo</span>
    }
    return <span>Muito Baixo</span>
  }

  const attributes = [
    'Regra',
    'Complacência',
    'Autoridade',
    'Aprendizado',
    'Inovação',
    'Clã',
    'Colaboração',
    'Empreendedora',
  ]

  function updateForca() {
    setIsLoading(true)
    const newForca = forcasGraph
    newForca[11].Valor = forcas.operacao
    newForca[8].Valor = forcas.mercado
    newForca[5].Valor = forcas.cliente
    newForca[2].Valor = forcas.interno
    setForcas([...newForca])
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
  }
  const forcas = {
    mercado: parseFloat(
      (
        (indicadores.Autoridade +
          indicadores.Inovação +
          indicadores.Empreendedora +
          indicadores.Colaboração) /
        2
      ).toFixed(1)
    ),
    cliente: parseFloat(
      (
        (indicadores.Empreendedora +
          indicadores.Colaboração +
          indicadores.Clã +
          indicadores.Aprendizado) /
        2
      ).toFixed(1)
    ),
    interno: parseFloat(
      (
        (indicadores.Clã +
          indicadores.Aprendizado +
          indicadores.Complacência +
          indicadores.Regra) /
        2
      ).toFixed(1)
    ),
    operacao: parseFloat(
      (
        (indicadores.Complacência +
          indicadores.Regra +
          indicadores.Autoridade +
          indicadores.Inovação) /
        2
      ).toFixed(1)
    ),
  }

  function getElementValue(name) {
    const valor = Object.keys(indicadores)
      .filter((key) => key.includes(name))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: indicadores[key],
        })
      }, {})
    return Object.values(valor)[0]
  }

  function resetArray() {
    setArray([])
  }

  if (indicatorArray.length === 0) {
    const ArrayDesejado = props.cultura.filter(
      (array) => array.Label === 'Desejado'
    )
    ArrayDesejado.forEach((array) => indicatorArray.push(array))

    attributes.forEach((attribute) => {
      indicatorArray.push({
        Tipo: attribute,
        Valor: getElementValue(attribute),
        Label: 'Ajustada',
      })
    })
    if (indicatorArray[15]?.Label === 'Ajustada') {
      const newArray = arrangeVector()
      setArray([...newArray])
    }
  }

  function BarGraph(values) {
    return (
      <Col xs={24} sm={24} lg={12} style={{ marginTop: '16px' }}>
        <Chart height={400} data={values} scale={forcasRange} autoFit>
          <Legend />
          <Axis name="Tipo" />
          <Axis name="Valor" />
          <Tooltip
            crosshairs={{
              type: 'y',
            }}
          />
          <Interval
            adjust={[
              {
                type: 'dodge',
                marginRatio: 1 / 32,
              },
            ]}
            position="Tipo*Valor"
            color={['Label', colors]}
          />
        </Chart>
      </Col>
    )
  }

  // Converte o arquetipo de nome para Id.
  function searchArchName(name, id) {
    const myobj = Object.keys(indicadores)
      .filter((key) => key.includes(name))
      .map((key) => ({
        valor: Number(indicadores[key]),
        arquetipo_id: id,
      }))

    return myobj[0]
  }

  // Essa função é necessária para fazer a conversão do objeto do arquétipo para atender a estrutura
  // desejada pela API.
  function convertArqNameToID() {
    const arquetipoWithId = []
    ARQUETIPOS.forEach((arquetipo) => {
      arquetipoWithId.push(
        searchArchName(arquetipo.nome, arquetipo.arquetipo_id)
      )
    })
    if (valueTotal == 100) postValues(arquetipoWithId)
    else
      toast.error('Você precisa setar exatamente 100% na sua cultura ajustada')
    props.update()
  }

  async function postValues(data) {
    const dataFinal = { arquetipos: data }
    const response = await postConsensuar(
      dataFinal,
      props.cultura_id,
      props.subcultura
    )
    if (response.success) toast.success('Salvo com sucesso')
    else toast.error(response.message)
  }

  function getArchsValues(arquetipo) {
    switch (arquetipo.Tipo) {
      case 'Inovação':
        return arquetipo.Label === 'Desejado'
          ? (indicadores.Inovação = arquetipo.Valor)
          : (arquetipo.Valor = searchValueFromArchName(arquetipo.Tipo))
      case 'Empreendedora':
        return arquetipo.Label === 'Desejado'
          ? (indicadores.Empreendedora = arquetipo.Valor)
          : (arquetipo.Valor = searchValueFromArchName(arquetipo.Tipo))
      case 'Colaboração':
        return arquetipo.Label === 'Desejado'
          ? (indicadores.Colaboração = arquetipo.Valor)
          : (arquetipo.Valor = searchValueFromArchName(arquetipo.Tipo))
      case 'Clã':
        return arquetipo.Label === 'Desejado'
          ? (indicadores.Clã = arquetipo.Valor)
          : (arquetipo.Valor = searchValueFromArchName(arquetipo.Tipo))
      case 'Aprendizado':
        return arquetipo.Label === 'Desejado'
          ? (indicadores.Aprendizado = arquetipo.Valor)
          : (arquetipo.Valor = searchValueFromArchName(arquetipo.Tipo))
      case 'Complacência':
        return arquetipo.Label === 'Desejado'
          ? (indicadores.Complacência = arquetipo.Valor)
          : (arquetipo.Valor = searchValueFromArchName(arquetipo.Tipo))
      case 'Regra':
        return arquetipo.Label === 'Desejado'
          ? (indicadores.Regra = arquetipo.Valor)
          : (arquetipo.Valor = searchValueFromArchName(arquetipo.Tipo))
      case 'Autoridade':
        return arquetipo.Label === 'Desejado'
          ? (indicadores.Autoridade = arquetipo.Valor)
          : (arquetipo.Valor = searchValueFromArchName(arquetipo.Tipo))
      default:
        return null
    }
  }

  // Essa função procura um valor e retorna o mesmo a partir do nome do arquetipo

  function searchValueFromArchName(name) {
    let returnValue = 0
    indicatorArray.forEach((arquetipo) => {
      if (arquetipo.Tipo === name && arquetipo.Label === 'Desejado') {
        returnValue = arquetipo.Valor
      }
    })
    return returnValue
  }

  function getASecond() {
    setTimeout(() => {
      setCopyLoading(false)
    }, 1000)
  }

  // Essa função copia os valores de cultura desejada para ajustada.
  function copyValues() {
    setCopyLoading(true)
    indicatorArray.forEach((arquetipo) => getArchsValues(arquetipo))
    setIndicadores({ ...indicadores })
    setArray([...indicatorArray])
    getASecond()
  }

  // Essa função reordena o vetor para que os arquétipos
  // fiquem nos mesmos lugares no gráfico ao ajustar a cultura.
  function arrangeVector() {
    const newVetor = []
    newVetor[0] = indicatorArray[15]
    newVetor[1] = indicatorArray[12]
    newVetor[2] = indicatorArray[14]
    newVetor[3] = indicatorArray[13]
    newVetor[4] = indicatorArray[11]
    newVetor[5] = indicatorArray[9]
    newVetor[6] = indicatorArray[10]
    newVetor[7] = indicatorArray[8]
    newVetor[8] = indicatorArray[0]
    newVetor[9] = indicatorArray[1]
    newVetor[10] = indicatorArray[2]
    newVetor[11] = indicatorArray[3]
    newVetor[12] = indicatorArray[4]
    newVetor[13] = indicatorArray[5]
    newVetor[14] = indicatorArray[6]
    newVetor[15] = indicatorArray[7]

    return newVetor
  }

  return (
    <Col span={24}>
      <h1>Porcentagem Atual: {valueTotal} %</h1>
      <Form layout="vertical">
        <Row>
          <Col xs={11} sm={11} md={11}>
            <Row>
              <Col xs={24} sm={24}>
                <Form.Item label="Regra">
                  <ArquetipoBox>
                    <Slider
                      value={indicadores.Regra}
                      min={0}
                      step={0.5}
                      max={100}
                      name="regra"
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Regra: e })
                        updateForca()
                      }}
                      tipFormatter={tipFormatterFitCultural}
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={indicadores.Regra}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Regra: e })
                        updateForca()
                      }}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item label="Autoridade">
                  <ArquetipoBox>
                    <Slider
                      value={indicadores.Autoridade}
                      defaultValue={indicadores.Autoridade}
                      min={0}
                      step={0.5}
                      max={100}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Autoridade: e })
                        updateForca()
                      }}
                      tipFormatter={tipFormatterFitCultural}
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={indicadores.Autoridade}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Autoridade: e })
                        updateForca()
                      }}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item label="Inovação">
                  <ArquetipoBox>
                    <Slider
                      value={indicadores.Inovação}
                      defaultValue={indicadores.Inovação}
                      step={0.5}
                      min={0}
                      max={100}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Inovação: e })
                        updateForca()
                      }}
                      tipFormatter={tipFormatterFitCultural}
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={indicadores.Inovação}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Inovação: e })
                        updateForca()
                      }}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item label="Empreendedora">
                  <ArquetipoBox>
                    <Slider
                      value={indicadores.Empreendedora}
                      defaultValue={indicadores.Empreendedora}
                      min={0}
                      step={0.5}
                      max={100}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Empreendedora: e })
                        updateForca()
                      }}
                      tipFormatter={tipFormatterFitCultural}
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={indicadores.Empreendedora}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Empreendedora: e })
                      }}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={2} sm={2} md={2} />
          <Col xs={11} sm={11} md={11}>
            <Row>
              <Col xs={24} sm={24}>
                <Form.Item label="Complacência">
                  <ArquetipoBox>
                    <Slider
                      value={indicadores.Complacência}
                      defaultValue={indicadores.Complacência}
                      min={0}
                      step={0.5}
                      max={100}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Complacência: e })
                        updateForca()
                      }}
                      tipFormatter={tipFormatterFitCultural}
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={indicadores.Complacência}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Complacência: e })
                        updateForca()
                      }}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item label="Aprendizado">
                  <ArquetipoBox>
                    <Slider
                      value={indicadores.Aprendizado}
                      defaultValue={indicadores.Aprendizado}
                      min={0}
                      step={0.5}
                      max={100}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Aprendizado: e })
                        updateForca()
                      }}
                      tipFormatter={tipFormatterFitCultural}
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={indicadores.Aprendizado}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Aprendizado: e })
                        updateForca()
                      }}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item label="Clã">
                  <ArquetipoBox>
                    <Slider
                      value={indicadores.Clã}
                      defaultValue={indicadores.Clã}
                      min={0}
                      step={0.5}
                      max={100}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Clã: e })
                        updateForca()
                      }}
                      tipFormatter={tipFormatterFitCultural}
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={indicadores.Clã}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Clã: e })
                      }}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item label="Colaboração">
                  <ArquetipoBox>
                    <Slider
                      defaultValue={indicadores.Colaboração}
                      value={indicadores.Colaboração}
                      min={0}
                      step={0.5}
                      max={100}
                      onChange={(e) => {
                        resetArray()
                        setIndicadores({ ...indicadores, Colaboração: e })
                        updateForca()
                      }}
                      tipFormatter={tipFormatterFitCultural}
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={indicadores.Colaboração}
                      onChange={(e) => {
                        setIsLoading(true)
                        resetArray()
                        setIndicadores({ ...indicadores, Colaboração: e })
                        updateForca()
                      }}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} lg={12} style={{ marginTop: '16px' }}>
            {copyLoading ? (
              <Skeleton active />
            ) : (
              <Chart
                height={400}
                data={indicatorArray}
                scale={arqRange}
                autoFit
              >
                <Coordinate type="polar" radius={0.8} />
                <Axis
                  name="Tipo"
                  line={null}
                  tickLine={null}
                  grid={{
                    lineStyle: {
                      lineDash: null,
                    },
                    hideFirstLine: false,
                  }}
                />
                <Tooltip />
                <Axis
                  name="Valor"
                  line={null}
                  tickLine={null}
                  grid={{
                    type: 'polygon',
                    lineStyle: {
                      lineDash: null,
                    },
                    alternateColor: 'rgba(0, 0, 0, 0.04)',
                  }}
                />
                <Line
                  position="Tipo*Valor"
                  color={['Label', colors]}
                  size={2}
                />
                <Point
                  position="Tipo*Valor"
                  color={['Label', colors]}
                  shape="circle"
                  size={4}
                  style={{
                    stroke: '#fff',
                    lineWidth: 1,
                    fillOpacity: 1,
                  }}
                  state
                />
              </Chart>
            )}
          </Col>
          {/* {!isLoading && !copyLoading ? (
            BarGraph(forcasGraph)
          ) : (
            <Skeleton style={{ maxWidth: 350, marginLeft: 40 }} active />
          )} */}
        </Row>
      </Form>
      <Button style={{ marginRight: 10 }} onClick={convertArqNameToID}>
        Salvar
      </Button>
      {showCopy && <Button onClick={copyValues}>Copiar Valores</Button>}
    </Col>
  )
}

export default CulturaConsensuada
