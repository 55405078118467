import React from 'react'

import moment from 'moment'
import DataSet from '@antv/data-set'

import { ArquetiposCulturaisDetalhes as ArquetiposCulturaisDetalhesStyled } from './styles'

export function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key]
    let prefixedKey = prefix ? `${prefix}.${key}` : key
    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }
    return messages
  }, {})
}

export function sorterString(a, b, prop) {
  let nameA
  let nameB

  if (Array.isArray(prop)) {
    nameA = prop.reduce((acc, next) => (acc ? acc[next] : null), a)
    nameB = prop.reduce((acc, next) => (acc ? acc[next] : null), b)
  } else {
    nameA = a[prop] && `${a[prop]}`.toUpperCase()
    nameB = b[prop] && `${b[prop]}`.toUpperCase()
  }

  if (!nameB) {
    return -1
  }
  if (!nameA) {
    return 1
  }
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

export function sorterNumber(a, b, prop) {
  let valueA
  let valueB

  if (Array.isArray(prop)) {
    valueA = prop.reduce(
      (acc, next) => (acc && acc[next] ? acc[next] : null),
      a
    )
    valueB = prop.reduce(
      (acc, next) => (acc && acc[next] ? acc[next] : null),
      b
    )
  } else {
    valueA = a[prop] && a[prop].toUpperCase()
    valueB = b[prop] && b[prop].toUpperCase()
  }

  valueA = parseFloat(valueA)
  valueB = parseFloat(valueB)

  if (valueA && valueB) {
    return valueA - valueB
  } else if (valueA) {
    return 1
  } else if (valueB) {
    return -1
  } else return 0
}

export function sorterDate(a, b, prop, format = '') {
  const nameA = a[prop]
  const nameB = b[prop]
  if (!nameA) {
    return -1
  }
  if (!nameB) {
    return 1
  }
  if (format) {
    return moment(nameA, format).unix() - moment(nameB, format).unix()
  }
  return moment(nameA).unix() - moment(nameB).unix()
}

export function parseDISC(disc) {
  switch (disc) {
    case 'D':
      return 'Dominância'
    case 'I':
      return 'Influência'
    case 'S':
      return 'Estabilidade'
    case 'C':
      return 'Conformidade'
    default:
      break
  }
}
export const arqRange = {
  Valor: {
    min: 0,
    tickInterval: 10,
    formatter: (val) => `${parseFloat(val).toFixed(1)}%`,
  },
}
export const valoresEvidenciaRange = ({ min = 0 }) => {
  return {
    VALOR: {
      min: parseFloat(min) > 10 ? parseFloat(min) - 10 : parseFloat(min),
      tickInterval: 20,
      formatter: (val) => `${parseFloat(val).toFixed(1)}%`,
    },
  }
}
export const discRange = {
  Valor: {
    min: 0,
    tickInterval: 20,
  },
  value: {
    min: 0,
    tickInterval: 20,
  },
}
export const forcasRange = {
  Valor: {
    min: 0,
    formatter: (val) => `${parseFloat(val).toFixed(1)}%`,
  },
}

export function sortCultura(cultura) {
  const sortCulturaArray = [
    'Regra',
    'Complacência',
    'Aprendizado',
    'Clã',
    'Colaboração',
    'Empreendedora',
    'Inovação',
    'Autoridade',
  ]

  return cultura.sort(
    (a, b) =>
      sortCulturaArray.indexOf(a.Tipo) - sortCulturaArray.indexOf(b.Tipo)
  )
}

export function parseCulturas({
  culturaDesejada,
  culturaInstalada,
  culturaConsensuada,
  culturaLider,
  custom,
  culturaDesejadaGrupo,
  labelCulturaDesejadaEmpresa = 'Desejado',
  labelCulturaDesejadaGrupo = 'Desejado (Time)',
}) {
  let cultura = [
    ...[
      ...(culturaDesejada && culturaDesejada.cultura
        ? [
            ...Object.keys(culturaDesejada.cultura).map((cultura) => ({
              Tipo: cultura,
              Valor: Number(culturaDesejada.cultura[cultura]),
              Label: labelCulturaDesejadaEmpresa,
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaInstalada && culturaInstalada.cultura
        ? [
            ...Object.keys(culturaInstalada.cultura).map((cultura) => ({
              Tipo: cultura,
              Valor: Number(culturaInstalada.cultura[cultura]),
              Label: 'Instalado',
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaConsensuada && culturaConsensuada.cultura
        ? [
            ...Object.keys(culturaConsensuada.cultura).map((cultura) => ({
              Tipo: cultura,
              Valor: Number(culturaConsensuada.cultura[cultura]),
              Label: 'Ajustada',
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaDesejadaGrupo && culturaDesejadaGrupo.cultura
        ? [
            ...Object.keys(culturaDesejadaGrupo.cultura).map((cultura) => ({
              Tipo: cultura,
              Valor: Number(culturaDesejadaGrupo.cultura[cultura]),
              Label: labelCulturaDesejadaGrupo,
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaLider && culturaLider.cultura
        ? [
            ...Object.keys(culturaLider.cultura).map((cultura) => ({
              Tipo: cultura,
              Valor: Number(culturaLider.cultura[cultura]),
              Label: 'Líder',
            })),
          ]
        : []),
    ],
  ]

  cultura = sortCultura(cultura)

  const forcas = [
    ...[
      ...(culturaDesejada && culturaDesejada.forcas
        ? [
            ...Object.keys(culturaDesejada.forcas).map((forca) => ({
              Tipo: forca,
              Valor: Number(culturaDesejada.forcas[forca]),
              Label: !custom
                ? labelCulturaDesejadaEmpresa
                : custom === 'objetivos_estrategicos'
                ? 'Gestores'
                : labelCulturaDesejadaEmpresa,
            })),
          ]
        : []),
    ],
    ...[
      ...(!culturaInstalada &&
      culturaDesejada &&
      culturaDesejada.forcasPrioridades
        ? [
            ...Object.keys(culturaDesejada.forcasPrioridades).map((forca) => ({
              Tipo: forca,
              Valor: Number(culturaDesejada.forcasPrioridades[forca]),
              Label: 'Prioridades',
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaInstalada && culturaInstalada.forcas
        ? [
            ...Object.keys(culturaInstalada.forcas).map((forca) => ({
              Tipo: forca,
              Valor: Number(culturaInstalada.forcas[forca]),
              Label: 'Instalado',
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaConsensuada && culturaConsensuada.forcas
        ? [
            ...Object.keys(culturaConsensuada.forcas).map((forca) => ({
              Tipo: forca,
              Valor: Number(culturaConsensuada.forcas[forca]),
              Label: 'Ajustada',
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaDesejadaGrupo && culturaDesejadaGrupo.forcas
        ? [
            ...Object.keys(culturaDesejadaGrupo.forcas).map((forca) => ({
              Tipo: forca,
              Valor: Number(culturaDesejadaGrupo.forcas[forca]),
              Label: labelCulturaDesejadaGrupo,
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaLider && culturaLider.forcas
        ? [
            ...Object.keys(culturaLider.forcas).map((forca) => ({
              Tipo: forca,
              Valor: Number(culturaLider.forcas[forca]),
              Label: 'Líder',
            })),
          ]
        : []),
    ],
  ]

  const disc = [
    ...[
      ...(culturaDesejada && culturaDesejada.disc
        ? [
            ...Object.keys(culturaDesejada.disc).map((disc) => ({
              Tipo: parseDISC(disc),
              Valor: Number(culturaDesejada.disc[disc]),
              Label: labelCulturaDesejadaEmpresa,
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaInstalada && culturaInstalada.disc
        ? [
            ...Object.keys(culturaInstalada.disc).map((disc) => ({
              Tipo: parseDISC(disc),
              Valor: Number(culturaInstalada.disc[disc]),
              Label: 'Instalado',
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaConsensuada && culturaConsensuada.disc
        ? [
            ...Object.keys(culturaConsensuada.disc).map((disc) => ({
              Tipo: parseDISC(disc),
              Valor: Number(culturaConsensuada.disc[disc]),
              Label: 'Ajustada',
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaDesejadaGrupo && culturaDesejadaGrupo.disc
        ? [
            ...Object.keys(culturaDesejadaGrupo.disc).map((disc) => ({
              Tipo: parseDISC(disc),
              Valor: Number(culturaDesejadaGrupo.disc[disc]),
              Label: labelCulturaDesejadaGrupo,
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaLider && culturaLider.disc
        ? [
            ...Object.keys(culturaLider.disc).map((disc) => ({
              Tipo: parseDISC(disc),
              Valor: Number(culturaLider.disc[disc]),
              Label: 'Líder',
            })),
          ]
        : []),
    ],
  ]

  const olhares = [
    ...[
      ...(culturaDesejada && culturaDesejada.olhares
        ? [
            ...Object.keys(culturaDesejada.olhares).map((olhar) => ({
              Tipo: olhar,
              Valor: Number(culturaDesejada.olhares[olhar]),
              Label: labelCulturaDesejadaEmpresa,
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaInstalada && culturaInstalada.olhares
        ? [
            ...Object.keys(culturaInstalada.olhares).map((olhar) => ({
              Tipo: olhar,
              Valor: Number(culturaInstalada.olhares[olhar]),
              Label: 'Instalado',
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaConsensuada && culturaConsensuada.olhares
        ? [
            ...Object.keys(culturaConsensuada.olhares).map((olhar) => ({
              Tipo: olhar,
              Valor: Number(culturaConsensuada.olhares[olhar]),
              Label: 'Ajustada',
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaDesejadaGrupo && culturaDesejadaGrupo.olhares
        ? [
            ...Object.keys(culturaDesejadaGrupo.olhares).map((olhar) => ({
              Tipo: olhar,
              Valor: Number(culturaDesejadaGrupo.olhares[olhar]),
              Label: labelCulturaDesejadaEmpresa,
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaLider && culturaLider.olhares
        ? [
            ...Object.keys(culturaLider.olhares).map((olhar) => ({
              Tipo: olhar,
              Valor: Number(culturaLider.olhares[olhar]),
              Label: 'Líder',
            })),
          ]
        : []),
    ],
  ]

  const gestao = [
    ...[
      ...(culturaDesejada && culturaDesejada.gestao
        ? [
            ...Object.keys(culturaDesejada.gestao).map((gestao) => ({
              Tipo: gestao,
              Valor: Number(culturaDesejada.gestao[gestao]),
              Label: labelCulturaDesejadaEmpresa,
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaInstalada && culturaInstalada.gestao
        ? [
            ...Object.keys(culturaInstalada.gestao).map((gestao) => ({
              Tipo: gestao,
              Valor: Number(culturaInstalada.gestao[gestao]),
              Label: 'Instalado',
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaConsensuada && culturaConsensuada.gestao
        ? [
            ...Object.keys(culturaConsensuada.gestao).map((gestao) => ({
              Tipo: gestao,
              Valor: Number(culturaConsensuada.gestao[gestao]),
              Label: 'Ajustada',
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaDesejadaGrupo && culturaDesejadaGrupo.gestao
        ? [
            ...Object.keys(culturaDesejadaGrupo.gestao).map((gestao) => ({
              Tipo: gestao,
              Valor: Number(culturaDesejadaGrupo.gestao[gestao]),
              Label: labelCulturaDesejadaGrupo,
            })),
          ]
        : []),
    ],
    ...[
      ...(culturaLider && culturaLider.gestao
        ? [
            ...Object.keys(culturaLider.gestao).map((gestao) => ({
              Tipo: gestao,
              Valor: Number(culturaLider.gestao[gestao]),
              Label: 'Líder',
            })),
          ]
        : []),
    ],
  ]

  return { cultura, forcas, disc, olhares, gestao }
}

export function parseVetor(data) {
  const {
    essencia,
    exigido,
    entrega,
    principal,
    hash,
    pes_nome,
    pes_id,
    avalia_id,
    emp_id,
    conta_id,
    fitCulturalEmpresa,
    ...rest
  } = data

  const disc = [essencia, exigido, entrega]
    .map((categ, index) => {
      let label
      switch (index) {
        case 0: {
          label = 'Essência'
          break
        }
        case 1: {
          label = 'Exigido'
          break
        }
        case 2: {
          label = 'Entrega'
          break
        }
        default:
          break
      }
      return [
        {
          Tipo: parseDISC('D'),
          Valor: Number(categ.intensidade.D),
          Label: label,
        },
        {
          Tipo: parseDISC('I'),
          Valor: Number(categ.intensidade.I),
          Label: label,
        },
        {
          Tipo: parseDISC('S'),
          Valor: Number(categ.intensidade.S),
          Label: label,
        },
        {
          Tipo: parseDISC('C'),
          Valor: Number(categ.intensidade.C),
          Label: label,
        },
      ]
    })
    .flat()

  const intensidade = [
    {
      row: 'Dominância',
      essencia: Number(essencia.intensidade.D),
      exigido: Number(exigido.intensidade.D),
      entrega: Number(entrega.intensidade.D),
    },
    {
      row: 'Influência',
      essencia: Number(essencia.intensidade.I),
      exigido: Number(exigido.intensidade.I),
      entrega: Number(entrega.intensidade.I),
    },
    {
      row: 'Estabilidade',
      essencia: Number(essencia.intensidade.S),
      exigido: Number(exigido.intensidade.S),
      entrega: Number(entrega.intensidade.S),
    },
    {
      row: 'Conformidade',
      essencia: Number(essencia.intensidade.C),
      exigido: Number(exigido.intensidade.C),
      entrega: Number(entrega.intensidade.C),
    },
  ]

  const perfilComp = [
    {
      item: 'Conformidade',
      score: Math.round(data[principal].CPOS),
      type: 'Positivo',
    },
    {
      item: 'Conformidade',
      score: Math.round(data[principal].CNEG),
      type: 'Negativo',
    },
    {
      item: 'Estabilidade',
      score: Math.round(data[principal].SPOS),
      type: 'Positivo',
    },
    {
      item: 'Estabilidade',
      score: Math.round(data[principal].SNEG),
      type: 'Negativo',
    },
    {
      item: 'Influência',
      score: Math.round(data[principal].IPOS),
      type: 'Positivo',
    },
    {
      item: 'Influência',
      score: Math.round(data[principal].INEG),
      type: 'Negativo',
    },
    {
      item: 'Dominância',
      score: Math.round(data[principal].DPOS),
      type: 'Positivo',
    },
    {
      item: 'Dominância',
      score: Math.round(data[principal].DNEG),
      type: 'Negativo',
    },
  ]

  const rct = [
    {
      nome: 'Monocrônico',
      score: Math.round(data[principal].rct.rctMonocronico),
    },
    {
      nome: 'Policrônico',
      score: Math.round(data[principal].rct.rctPolicronico),
    },
  ]

  const edl = [
    {
      nome: 'Autocrático',
      score: Math.round(data[principal].edl.edlAutocratico),
    },
    {
      nome: 'Democrático',
      score: Math.round(data[principal].edl.edlDemocratico),
    },
  ]

  const foc = [
    {
      nome: 'Processos',
      score: Math.round(data[principal].foc.focProcessos),
    },
    {
      nome: 'Resultados',
      score: Math.round(data[principal].foc.focResultados),
    },
  ]

  const ode =
    data[principal].ode.nome === 'Extroversão'
      ? {
          maior: {
            nome: 'Extroversão',
            score: Math.round(data[principal].ode.odeExtroversao),
          },
          menor: {
            nome: 'Introversão',
            score: Math.round(data[principal].ode.odeIntroversao),
          },
        }
      : {
          maior: {
            nome: 'Introversão',
            score: Math.round(data[principal].ode.odeIntroversao),
          },
          menor: {
            nome: 'Extroversão',
            score: Math.round(data[principal].ode.odeExtroversao),
          },
        }

  const oct =
    data[principal].oct.nome === 'Tarefas'
      ? {
          maior: {
            nome: 'Tarefas',
            score: Math.round(data[principal].oct.octTarefas),
          },
          menor: {
            nome: 'Relacionamentos',
            score: Math.round(data[principal].oct.octRelacionamentos),
          },
        }
      : {
          maior: {
            nome: 'Relacionamentos',
            score: Math.round(data[principal].oct.octRelacionamentos),
          },
          menor: {
            nome: 'Tarefas',
            score: Math.round(data[principal].oct.octTarefas),
          },
        }

  const tdd =
    data[principal].tdd.nome === 'Racional'
      ? {
          maior: {
            nome: 'Racional',
            score: Math.round(data[principal].tdd.tddRaciocinio),
          },
          menor: {
            nome: 'Emocional',
            score: Math.round(data[principal].tdd.tddSentimentos),
          },
        }
      : {
          maior: {
            nome: 'Emocional',
            score: Math.round(data[principal].tdd.tddSentimentos),
          },
          menor: {
            nome: 'Racional',
            score: Math.round(data[principal].tdd.tddRaciocinio),
          },
        }

  const cor = [
    {
      nome: 'Cliente',
      selected: data[principal].cor.nome === 'Cliente',
    },
    {
      nome: 'Ação',
      selected: data[principal].cor.nome === 'Ação',
    },
    {
      nome: 'Pessoa',
      selected: data[principal].cor.nome === 'Pessoa',
    },
    {
      nome: 'Qualidade',
      selected: data[principal].cor.nome === 'Qualidade',
    },
  ]

  const erc = [
    {
      nome: 'Cede',
      selected: data[principal].erc.nome === 'Cede',
    },
    {
      nome: 'Negocia',
      selected: data[principal].erc.nome === 'Negocia',
    },
    {
      nome: 'Impõe',
      selected: data[principal].erc.nome === 'Impõe',
    },
    {
      nome: 'Evita',
      selected: data[principal].erc.nome === 'Evita',
    },
  ]

  const edc = [
    {
      nome: 'Emocional',
      selected: data[principal].edc.nome === 'Emocional',
    },
    {
      nome: 'Racional',
      selected: data[principal].edc.nome === 'Racional',
    },
    {
      nome: 'Objetiva',
      selected: data[principal].edc.nome === 'Objetiva',
    },
    {
      nome: 'Instrutiva',
      selected: data[principal].edc.nome === 'Instrutiva',
    },
  ]

  return {
    ...rest,
    emp_id,
    conta_id,
    pes_nome,
    pes_id,
    hash,
    avalia_id,
    perfilComp,
    intensidade,
    rct,
    edl,
    foc,
    ode,
    oct,
    tdd,
    cor,
    erc,
    edc,
    principal: data[principal],
    disc,
    fitCulturalEmpresa,
  }
}

export function parseStackedChartData(data) {
  /**
   * Retorna um array formato para utilizar como DataView dos Gráficos de StackedHistogram, na escala de porcentagem
   * Objeto contendo : { TEXTO, TIPO, VALOR }
   */
  const ds = new DataSet()
  const dvPercent = ds.createView().source(data).transform({
    type: 'percent',
    field: 'VALOR',
    dimension: 'TIPO',
    groupBy: 'TEXTO',
    as: 'percent',
  })

  data = dvPercent.rows

  const newData = []
  data.forEach((item) => {
    let addToNewData = false
    let obj = newData.find((i) => i.TEXTO === item.TEXTO)
    if (!obj) {
      obj = {}
      addToNewData = true
    }
    obj.TEXTO = item.TEXTO
    obj[item.TIPO] = item.percent
    if (addToNewData) newData.push(obj)
  })

  return newData
}

export function parsePieChartData(data) {
  const newData = []
  data.forEach((item) => {
    let addToNewData = false
    let obj = newData.find((i) => i.TEXTO === item.TEXTO)
    if (!obj) {
      obj = {}
      addToNewData = true
    }
    obj.TEXTO = item.TEXTO
    if (!obj.data) obj.data = []
    obj.data.push({ TEXTO: item.TIPO, VALOR: item.VALOR })
    if (addToNewData) newData.push(obj)
  })
  return newData
}

export function sortChartData(data, filterNull = false) {
  // Ordena e remove valores nulos do array
  if (filterNull) data = data.filter((item) => parseFloat(item.VALOR) > 0)
  return data.sort((a, b) =>
    parseFloat(a.VALOR) > parseFloat(b.VALOR) ? 1 : -1
  )
}

export function calcAge(date) {
  return moment().diff(date, 'years')
}

export const colors = [
  '#30318b',
  '#ef5472',
  '#ffb17f',
  '#5999a5',
  '#f6d9d5',
  '#b8dadc',
  '#a99f9e',
  '#ff595b',
  '#5a78a3',
  '#6a6989',
  '#f37374',
  '#406087',
]

export const styleColors = {
  purple: '#30318b',
  pink: '#ef5472',
}

export const ArquetiposCulturaisDetalhes = () => (
  <ArquetiposCulturaisDetalhesStyled
    onClick={() =>
      window.open(
        'https://blog.teamhub.com.br/arquetipos-culturais-teamhub/',
        '_blank'
      )
    }
  >
    Saiba Mais
  </ArquetiposCulturaisDetalhesStyled>
)

export const getForcasEstrategicasTicks = (forcas) => {
  const maxForca = forcas.reduce(
    (prev, f) => (parseFloat(f.Valor) > prev.Valor ? f : prev),
    { Valor: 0 }
  )
  const ticks = []
  const tickInterval = 5
  let count = parseFloat(maxForca.Valor) + tickInterval * 2
  let value = 0

  do {
    ticks.push(value)
    value += tickInterval
    count -= tickInterval
  } while (count > 0)

  return ticks
}

export const getScrollbarWidth = () => {
  let width = 0

  let scrollbox = document.createElement('div')

  scrollbox.style.overflow = 'scroll'

  document.body.appendChild(scrollbox)

  width = scrollbox.offsetWidth - scrollbox.clientWidth

  document.body.removeChild(scrollbox)

  return width
}
