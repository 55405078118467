import React from 'react'

import { ExclamationCircleFilled } from '@ant-design/icons'

import {
  Table,
  Badge,
  message,
  Divider,
  Popconfirm,
  Empty,
  Col,
  Row,
  Progress,
} from 'antd'

import {
  Chart,
  Line,
  Point,
  Axis,
  Tooltip,
  Coordinate,
  Legend,
  ProgressChart,
} from 'bizcharts'
import moment from 'moment'

// Utils
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  arqRange,
  valoresEvidenciaRange,
  sorterDate,
  colors,
  discRange,
} from '../../utils/utils'
import Link from '../privateLink/PrivateLink'
import { getContaURL } from '../../services/auth'

import {
  FlexTablesContainer,
  Title,
  TitleRow,
  ProgressWrapper,
  ProgressContainer,
  FitCulturalRow,
  FitsContainer,
} from './styles'

const fitCulturalTableColumns = [
  {
    title: 'Arquétipo',
    dataIndex: 'Tipo',
  },
  {
    title: 'Fit',
    dataIndex: 'Valor',
    sorter: (a, b) => a.Valor - b.Valor,
    sortOrder: 'descend',
    render: (text, record) => <span>{text}%</span>,
  },
]

const fitCulturalTablesSource = {
  dataSource: [],
  geral: 0,
}

function FitCulturalPerfil({
  cultura: culturaPessoa,
  fitCultural,
  culturaOrg,
  valoresOrgEmpresa: valoresOrgEmpresaData,
  culturaEmpresa,
  forcasEmpresa,
  olharesEmpresa,
  gestaoEmpresa,
  discEmpresa,
}) {
  const cultura = [
    ...culturaPessoa,
    ...culturaEmpresa.filter((c) => c.Label === 'Desejado'),
  ]

  const { valoresEvidencia: valoresEvidenciaPessoa } = culturaOrg

  const valoresOrgEmpresa =
    valoresOrgEmpresaData.length > 0
      ? valoresOrgEmpresaData.map((v) => v.valor)
      : []
  const valoresFitEmpresa =
    valoresOrgEmpresaData.length > 0
      ? valoresOrgEmpresaData.map((v) => ({
          TEXTO: v.valor,
          LABEL: 'Mínimo',
          VALOR: parseFloat(v.fitcultural),
        }))
      : []
  const valoresEvidencia = [
    ...(valoresEvidenciaPessoa.length > 0
      ? valoresEvidenciaPessoa.map((v) => ({
          VALOR: parseFloat(v.VALOR),
          TEXTO: v.TEXTO,
          LABEL: 'Vivenciado',
        }))
      : []),
    ...valoresFitEmpresa,
  ]

  const valoresEvidenciaMinValue = valoresEvidencia.reduce(
    (acc, next) =>
      parseFloat(next.VALOR) < acc ? parseFloat(next.VALOR) : acc,
    101
  )

  const ValoresProgressCharts = () =>
    valoresOrgEmpresa.map((v) => {
      const valorPessoa = valoresEvidencia.find(
        (valor) => valor.TEXTO === v && valor.LABEL === 'Vivenciado'
      )
      const valorEmpresa = valoresEvidencia.find(
        (valor) => valor.TEXTO === v && valor.LABEL === 'Mínimo'
      )

      const value = valorPessoa ? parseFloat(valorPessoa.VALOR) / 100 : 0

      const empresaValue = valorEmpresa
        ? parseFloat(valorEmpresa.VALOR) / 100
        : 0

      return (
        <ProgressWrapper>
          <span>
            {v} -{`${(value * 100).toFixed(1)}%`} /{' '}
            {`${(empresaValue * 100).toFixed(1)}%`}
          </span>
          <ProgressChart
            renderer="canvas"
            forceFit
            height={50}
            percent={value}
            tooltip
            color={colors[0]}
            marker={
              valorEmpresa
                ? [
                    {
                      value: empresaValue,
                      style: {
                        stroke: 'red',
                      },
                    },
                  ]
                : []
            }
          />
        </ProgressWrapper>
      )
    })

  /*
  const columnsHome = [
    {
      title: 'Status',
      dataIndex: 'respondido',
      align: 'center',
      sorter: (a, b) => a.respondido - b.respondido,
      sortDirections: ['descend', 'ascend'],
      render: (text) => {
        return <Badge status={text === 1 ? 'success' : 'error'} />;
      },
    },
    {
      title: 'Enviado Em',
      dataIndex: 'data_solicitacao',
      align: 'center',
      sorter: (a, b) => sorterDate(a, b, 'enviadoEm', 'DD/MM/YYYY (HH:mm)'),
      sortDirections: ['descend', 'ascend'],
      render: (text) =>
        text
          ? moment(text, 'DD/MM/YYYY (HH:mm)').format('DD/MM/YYYY (HH:mm)')
          : '',
      ...getColumnSearchProps('enviadoEm', 'data envio'),
    },
    {
      title: 'Respondido Em',
      dataIndex: 'data_entrega',
      align: 'center',
      sorter: (a, b) => sorterDate(a, b, 'respondidoEm', 'DD/MM/YYYY (HH:mm)'),
      sortDirections: ['descend', 'ascend'],
      render: (text) =>
        text
          ? moment(text, 'DD/MM/YYYY (HH:mm)').format('DD/MM/YYYY (HH:mm)')
          : '',
      ...getColumnSearchProps('respondidoEm', 'data resposta'),
    },
    {
      title: 'Ações',
      key: 'acoes',
      align: 'center',
      render: (text, record) => {
        return record.respondido === 1 ? (
          <Link to={`/dashboard?candidato=${record.pes_id}`}>
            Ver na Dashboard
          </Link>
        ) : (
            <span>
              <CopyToClipboard
                text={`${window.location.host}/${getContaURL()}/pesquisas/${record.tipo === 'Perfil Comportamental'
                  ? 'perfil-comportamental'
                  : 'percepcao-ambiente'
                  }/${record.hashacesso}`}
                onCopy={() =>
                  message.success(
                    'Link da pesquisa copiado para a área de transferência.',
                  )
                }
              >
                <a href='javascript:;'>Link da Pesquisa</a>
              </CopyToClipboard>
              <Divider type='vertical' />
              <Popconfirm
                title='Reenviar pesquisa?'
                onConfirm={() => {
                  sendVetorComportamental({
                    pessoas: [{ pes_id: record.pes_id }],
                    enviarEmails: true,
                  }).then(({ response }) => {
                    if (response.status === 200) {
                      message.success('Enviado com sucesso!');
                    }
                  });
                }}
                okText='Sim'
                cancelText='Não'
                icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
              >
                <a href='javascript:;'>Reenviar</a>
              </Popconfirm>
              <Divider type='vertical' />
              <Popconfirm
                title='Cancelar pesquisa?'
                onConfirm={() => {
                  const canceledId = record.pes_id;
                  cancelVetorComportamental(record.pes_id, record.avalia_id).then(
                    ({ response }) => {
                      if (response.status === 200) {
                        message.success('Cancelado com sucesso!');
                      }
                      getVetorComportamental({ id: canceledId });
                    },
                  );
                }}
                okText='Sim'
                cancelText='Não'
                icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
              >
                <a href='javascript:;'>Cancelar</a>
              </Popconfirm>
            </span>
          );
      },
    },
  ];
  */

  if (
    culturaEmpresa &&
    culturaEmpresa.length != 0 &&
    Object.keys(fitCultural).length != 0
  ) {
    fitCultural.fitArquetipos.Aprendizado =
      (fitCultural.arquetipos.Aprendizado /
        culturaEmpresa.find((element) => element.Tipo === 'Aprendizado')
          .Valor) *
      100
    fitCultural.fitArquetipos.Clã =
      (fitCultural.arquetipos.Clã /
        culturaEmpresa.find((element) => element.Tipo === 'Clã').Valor) *
      100
    fitCultural.fitArquetipos.Inovação =
      (fitCultural.arquetipos.Inovação /
        culturaEmpresa.find((element) => element.Tipo === 'Inovação').Valor) *
      100
    fitCultural.fitArquetipos.Empreendedora =
      (fitCultural.arquetipos.Empreendedora /
        culturaEmpresa.find((element) => element.Tipo === 'Empreendedora')
          .Valor) *
      100
    fitCultural.fitArquetipos.Autoridade =
      (fitCultural.arquetipos.Autoridade /
        culturaEmpresa.find((element) => element.Tipo === 'Autoridade').Valor) *
      100
    fitCultural.fitArquetipos.Colaboração =
      (fitCultural.arquetipos.Colaboração /
        culturaEmpresa.find((element) => element.Tipo === 'Colaboração')
          .Valor) *
      100
    fitCultural.fitArquetipos.Regra =
      (fitCultural.arquetipos.Regra /
        culturaEmpresa.find((element) => element.Tipo === 'Regra').Valor) *
      100
    fitCultural.fitArquetipos.Complacência =
      (fitCultural.arquetipos.Complacência /
        culturaEmpresa.find((element) => element.Tipo === 'Complacência')
          .Valor) *
      100

    fitCultural.fitArquetipos.Aprendizado = verificarSeMaiorQueCem(
      fitCultural.fitArquetipos.Aprendizado
    )
    fitCultural.fitArquetipos.Clã = verificarSeMaiorQueCem(
      fitCultural.fitArquetipos.Clã
    )
    fitCultural.fitArquetipos.Inovação = verificarSeMaiorQueCem(
      fitCultural.fitArquetipos.Inovação
    )
    fitCultural.fitArquetipos.Empreendedora = verificarSeMaiorQueCem(
      fitCultural.fitArquetipos.Empreendedora
    )
    fitCultural.fitArquetipos.Autoridade = verificarSeMaiorQueCem(
      fitCultural.fitArquetipos.Autoridade
    )
    fitCultural.fitArquetipos.Colaboração = verificarSeMaiorQueCem(
      fitCultural.fitArquetipos.Colaboração
    )
    fitCultural.fitArquetipos.Regra = verificarSeMaiorQueCem(
      fitCultural.fitArquetipos.Regra
    )
    fitCultural.fitArquetipos.Complacência = verificarSeMaiorQueCem(
      fitCultural.fitArquetipos.Complacência
    )
  }

  function verificarSeMaiorQueCem(valor) {
    if (valor > 100) return 100
    return valor.toFixed(2)
  }

  return (
    <Col span={24} style={{ padding: '20px' }}>
      <Row gutter={20}>
        <Col xs={24} sm={24}>
          <FitsContainer>
            <TitleRow>
              <Title>Fits Culturais</Title>
            </TitleRow>

            <div>
              <div>
                <span>Fit Cultural Geral</span>
                {fitCultural.geral ? (
                  <Progress
                    type="circle"
                    percent={parseFloat(fitCultural.geral).toFixed(1)}
                    strokeColor={colors[1]}
                    status="normal"
                    width={120}
                  />
                ) : (
                  <Empty />
                )}
              </div>
              <div>
                <span>Fit Arquétipos Culturais</span>
                {fitCultural.fitArquetipos ? (
                  <Progress
                    type="circle"
                    percent={parseFloat(
                      fitCultural.fitArquetipos.geral
                    ).toFixed(1)}
                    strokeColor={colors[0]}
                    status="normal"
                    width={120}
                  />
                ) : (
                  <Empty />
                )}
              </div>
              <div>
                <span>Fit Valores Organizacionais</span>
                {fitCultural.fitValores ? (
                  <Progress
                    type="circle"
                    percent={parseFloat(fitCultural.fitValores.geral).toFixed(
                      1
                    )}
                    strokeColor={colors[0]}
                    status="normal"
                    width={120}
                  />
                ) : (
                  <Empty />
                )}
              </div>
            </div>
          </FitsContainer>
        </Col>
      </Row>
      <Row>
        <Divider />
      </Row>
      <Row gutter={20}>
        <Col xs={24} sm={24} xxl={12}>
          <Title>Arquétipos Culturais</Title>
          {cultura.length === 0 ? (
            <Empty description={false} height={300} />
          ) : (
            <>
              <Chart height={300} data={cultura} scale={arqRange} autoFit>
                <Legend />
                <Coordinate type="polar" radius={0.8} />
                <Axis
                  name="Tipo"
                  line={null}
                  tickLine={null}
                  grid={{
                    lineStyle: {
                      lineDash: null,
                    },
                    hideFirstLine: false,
                  }}
                />
                <Tooltip />
                <Axis
                  name="Valor"
                  line={null}
                  tickLine={null}
                  grid={{
                    type: 'polygon',
                    lineStyle: {
                      lineDash: null,
                    },
                    alternateColor: 'rgba(0, 0, 0, 0.04)',
                  }}
                />
                <Line
                  position="Tipo*Valor"
                  color={['Label', colors]}
                  size={2}
                />
                <Point
                  position="Tipo*Valor"
                  color={['Label', colors]}
                  shape="circle"
                  size={4}
                  style={{
                    stroke: '#fff',
                    lineWidth: 1,
                    fillOpacity: 1,
                  }}
                />
              </Chart>
            </>
          )}
        </Col>
        <Col xs={24} sm={24} xxl={12}>
          <FitCulturalTables fitCultural={fitCultural} />
        </Col>
      </Row>
      <Row>
        <Divider />
      </Row>
      <Row gutter={20}>
        <Col xs={24} sm={24}>
          <TitleRow>
            <Title>Valores em Evidência</Title>
          </TitleRow>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={24} sm={24} xxl={12}>
          {valoresEvidencia.length > 0 ? (
            <Chart
              height={300}
              data={valoresEvidencia}
              autoFit
              scale={valoresEvidenciaRange({ min: valoresEvidenciaMinValue })}
            >
              <Legend />
              <Coordinate type="polar" radius={0.8} />
              <Axis
                name="TEXTO"
                line={null}
                tickLine={null}
                grid={{
                  lineStyle: {
                    lineDash: null,
                  },
                  hideFirstLine: false,
                }}
              />
              <Tooltip />
              <Axis
                name="VALOR"
                line={null}
                tickLine={null}
                grid={{
                  type: 'polygon',
                  lineStyle: {
                    lineDash: null,
                  },
                  alternateColor: 'rgba(0, 0, 0, 0.04)',
                }}
              />
              <Line position="TEXTO*VALOR" color={['LABEL', colors]} size={2} />
              <Point
                position="TEXTO*VALOR"
                color={['LABEL', colors]}
                shape="circle"
                size={4}
                style={{
                  stroke: '#fff',
                  lineWidth: 1,
                  fillOpacity: 1,
                }}
              />
            </Chart>
          ) : (
            <Empty description={false} height={300} />
          )}
        </Col>
        <Col xs={24} sm={24} xxl={12}>
          {valoresEvidencia.length > 0 ? (
            <ProgressContainer>
              <ValoresProgressCharts />
            </ProgressContainer>
          ) : (
            <Empty description={false} height={300} />
          )}
        </Col>
      </Row>
    </Col>
    /*
    <div className='perfil__tabs__home'>
      <div className='perfil__tabs__home__chart'>

      </div>
      <div className='perfil__tabs__home__table'>
        <Table
          bordered
          columns={columnsHome}
          dataSource={vetorComportamental}
        />
      </div>
    </div>
    */
  )
}

export default FitCulturalPerfil

export const FitCulturalTables = ({ fitCultural, title = true }) => {
  fitCulturalTablesSource.dataSource = fitCultural.fitArquetipos
    ? Object.keys(fitCultural.fitArquetipos)
        .map((item) => {
          return {
            Tipo: item,
            Valor: parseFloat(fitCultural.fitArquetipos[item]),
          }
        })
        .filter((item) => item)
    : []

  fitCulturalTablesSource.dataSource.pop() // retirando pessoa id
  fitCulturalTablesSource.geral = fitCulturalTablesSource.dataSource.shift()

  return (
    <>
      {title && (
        <Row style={{ marginBottom: '16px' }}>
          <Title>Fit Arquétipos Culturais</Title>
        </Row>
      )}
      {fitCultural.fitArquetipos ? (
        <FitCulturalRow>
          <FlexTablesContainer>
            <Table
              size="small"
              columns={fitCulturalTableColumns}
              dataSource={fitCulturalTablesSource.dataSource}
              pagination={false}
              rowClassName="table-fitcultural-muitoAlto-row"
            />
          </FlexTablesContainer>
        </FitCulturalRow>
      ) : (
        <Empty description={false} height={300} />
      )}
    </>
  )
}
