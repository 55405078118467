import React, { useState, useEffect, useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { Modal, Button } from 'antd'

import { toast } from 'react-toastify'

// components
import AdicionarNovoColaborador from '../cadastroColaboradores/components/adicionarNovoColaborador/AdicionarNovoColaborador'

import Select, { Option } from '../../components/select/Select'

// actions
import { getColaboradores, postVinculo } from './actions/actions'

import { postColaborador } from '../cadastroColaboradores/actions/actionsCadastroColaboradores'

// constants
import { initialState } from './constants/constants'

// styles
import { ButtonRow, NewColaboradorRow } from './styles'

// utils
import { validateForm } from '../../utils/forms'
import { logout } from '../../services/auth'

function VincularPessoaUsuario() {
  const history = useHistory()

  const dispatch = useDispatch()

  const [colaboradores, setColaboradores] = useState([])

  const [loadingColaboradores, setLoadingColaboradores] = useState(false)

  const [colaboradorSelected, setColaboradorSelected] = useState(null)

  const [showModal, setShowModal] = useState(true)

  const [showModalColaborador, setShowModalColaborador] = useState(false)

  const [novoColaboradorState, setNovoColaboradorState] = useState(initialState)

  const [novoColaboradorError, setNovoColaboradorError] = useState({})

  const novoColaboradorFormFields = [
    {
      name: 'nome',
      required: true,
    },
    {
      name: 'email',
      required: true,
    },
  ]

  const loadColaboradores = useCallback(() => {
    setLoadingColaboradores(true)
    dispatch(getColaboradores()).then(({ response }) => {
      if (response.status === 200) {
        setColaboradores(
          response.data.pessoas
            .filter(({ pes_ativo }) => parseInt(pes_ativo) === 1)
            .map(({ pes_id, pes_nome }) => ({
              pes_nome,
              pes_id,
            }))
        )
      }
      setLoadingColaboradores(false)
    })
  }, [dispatch])

  useEffect(() => {
    loadColaboradores()
  }, [loadColaboradores])

  const handleChangeModal = ({ target }) => {
    setNovoColaboradorState((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }))
  }

  const handlePostNovoColaborador = () => {
    const error = validateForm(novoColaboradorFormFields, novoColaboradorState)
    setNovoColaboradorError({ error: error.messages })

    if (error.hasError) return

    dispatch(postColaborador(novoColaboradorState)).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Novo colaborador cadastrado com sucesso.')
        loadColaboradores()
        handleCloseNovoColaborador()
      } else toast.error('Ocorreu um erro ao cadastrar colaborador')
    })
  }

  const handleCloseNovoColaborador = () => {
    setNovoColaboradorState(initialState)
    setNovoColaboradorError({})
    setShowModalColaborador(false)
  }

  const handleSubmit = () => {
    if (colaboradorSelected) {
      dispatch(postVinculo({ pes_id: colaboradorSelected })).then(
        ({ response }) => {
          if (response.status === 200) {
            toast.success(
              'Usuário vinculado com sucesso. Por favor, faça login novamente para continuar.'
            )
            logout()
            history.push('/')
          } else toast.error('Erro ao vincular usuário ao colaborador.')
        }
      )
    } else {
      toast.error('Por favor, selecione um colaborador.')
    }
  }

  return (
    <>
      <Modal
        title="Por favor, vincule seu usuário a um colaborador"
        key="1"
        visible={showModal}
        closable={false}
        footer={
          <ButtonRow>
            <Button type="primary" onClick={() => handleSubmit()}>
              VINCULAR
            </Button>
          </ButtonRow>
        }
        destroyOnClose
      >
        {colaboradores && (
          <Select
            label="Selecione um colaborador"
            placeholder="Selecione"
            onChange={(option) => setColaboradorSelected(option)}
            style={{ width: '100%' }}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            value={colaboradorSelected}
            loading={loadingColaboradores}
          >
            {colaboradores.map(({ pes_nome, pes_id }) => (
              <Option value={pes_id} key={pes_id}>
                {pes_nome}
              </Option>
            ))}
          </Select>
        )}
        <NewColaboradorRow>
          <span>ou </span>
          <Button type="primary" onClick={() => setShowModalColaborador(true)}>
            Adicione um novo colaborador
          </Button>
        </NewColaboradorRow>
      </Modal>

      <Modal
        visible={showModalColaborador}
        title="Adicionar novo colaborador"
        key="2"
        onCancel={() => handleCloseNovoColaborador()}
        cancelText="CANCELAR"
        okText="SALVAR"
        onOk={() => handlePostNovoColaborador()}
      >
        <AdicionarNovoColaborador
          handleChangeModal={handleChangeModal}
          wholeForm={false}
          error={novoColaboradorError}
          {...novoColaboradorState}
        />
      </Modal>
    </>
  )
}

export default VincularPessoaUsuario
