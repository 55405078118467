import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Button,
  Popconfirm,
  Modal,
  Divider,
  Card,
  Tooltip,
  Switch,
} from 'antd'

import {
  ExclamationCircleFilled,
  DeleteOutlined,
  Loading3QuartersOutlined,
  UserAddOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import Table from '../../../../components/editableTable/EditableTable'

import PsMotivo from './components/NovaMotivo/index'

import {
  listMotivos,
  storeMotivos,
  updateMotivos,
  deleteMotivos,
  loadMotivos,
} from './actions/actions'

import { Action, TableHeader } from './styles'

function Motivo() {
  const dispatch = useDispatch()

  const [showModalNewMotivo, setShowModalNewMotivo] = useState(false)

  const [Motivos, setMotivos] = useState([])

  const [showInativos, setShowInativos] = useState(false)

  const [loadingMotivos, setloadingMotivos] = useState(false)

  const [handleMotivo, sethandleUpdateMotivo] = useState(false)

  const loadMotivos = useCallback(() => {
    setloadingMotivos(true)
    dispatch(listMotivos()).then(({ response }) => {
      setloadingMotivos(false)
      if (response.status === 200) {
        setMotivos(response.data)
      }
    })
  }, [dispatch])

  useEffect(() => {
    loadMotivos()
  }, [loadMotivos])

  const onFinishNewMotivo = () => {
    dispatch(storeMotivos(Filial)).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Motivo cadastrado com sucesso.')
        loadMotivos()
      } else toast.error('Erro ao cadastrar Motivo.')
    })
  }

  const handleListMotivos = useCallback(() => {
    dispatch(listMotivos()).then(({ response }) => {
      if (response.status === 200) {
        const { motivos } = response.data
        setMotivos(motivos.map((m) => ({ value: m.motivo_id, label: m.nome })))
      }
    })
  }, [dispatch])

  const handleDeleteMotivo = useCallback(
    (motivo_id) => {
      dispatch(deleteMotivos({ motivo_id })).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Motivo excluída com sucesso.')
          loadMotivos()
        }
      })
    },
    [dispatch, loadMotivos]
  )

  const handleUpdateMotivo = useCallback(
    (updatedMotivo, data) => {
      dispatch(
        updateMotivos({ motivo_id: updatedMotivo.motivo_id, ...updatedMotivo })
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Motivo atualizada com sucesso.')
          loadMotivos()
        }
      })
    },
    [dispatch, loadMotivos]
  )

  const getColumns = useCallback(() => {
    return [
      {
        title: 'Nome',
        dataIndex: 'nome',
        width: '20%',
        sorter: (a, b) => sorterString(a, b, 'nome'),
        editable: true,
      },
      {
        title: 'Descricao',
        dataIndex: 'descricao',
        width: '25%',
        sorter: (a, b) => sorterString(a, b, 'descricao'),
        editable: true,
      },
      {
        title: 'Criado Por',
        dataIndex: ['creator', 'colaborador', 'pes_nome'],
        width: '15%',
        sorter: (a, b) =>
          sorterString(a, b, ['creator', 'colaborador', 'pes_nome']),
      },
      {
        title: 'Atualizado Por',
        dataIndex: ['updater', 'colaborador', 'pes_nome'],
        width: '15%',
        sorter: (a, b) =>
          sorterString(a, b, ['updater', 'colaborador', 'pes_nome']),
      },
      {
        title: 'Data de Atualização',
        dataIndex: 'updated_at',
        width: '10.5%',
        sorter: (a, b) => sorterDate(a, b, 'updated_at'),
        render: (text) =>
          text
            ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
            : '',
      },
      {
        title: 'Ações',
        key: 'extra_actions',
        width: '10%',
        render: (text, record) => {
          return (
            <>
              <Divider type="vertical" />
              <Tooltip title="Excluir">
                {' '}
                <Popconfirm
                  title="Tem certeza que deseja excluir esse motivo?"
                  onConfirm={() => handleDeleteMotivo(record.motivo_id)}
                  okText="Excluir"
                  cancelText="Cancelar"
                  icon={
                    <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                  }
                >
                  <Action>
                    <DeleteOutlined />
                  </Action>
                </Popconfirm>
              </Tooltip>
            </>
          )
        },
      },
    ]
  }, [handleDeleteMotivo])

  const handleCancelNewMotivo = useCallback(() => {
    setShowModalNewMotivo(false)
  }, [])

  const handleNewMotivo = useCallback(
    (data) => {
      dispatch(storeMotivos(data)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Motivo criada com sucesso.')
          setShowModalNewMotivo(false)
          loadMotivos()
        }
      })
    },
    [dispatch, loadMotivos]
  )

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Card title="Cadastre e gerencie motivos">
            <Table
              dataSource={Motivos.filter((motivo) => {
                if (showInativos) {
                  return motivo
                }
                return parseInt(motivo.ativo) === 1
              }).map((motivo) => ({ ...motivo, key: motivo.motivo_id }))}
              isEditable
              key={(record) => record.motivo_id}
              loading={loadingMotivos}
              columns={getColumns()}
              onSave={handleUpdateMotivo}
              header={() => (
                <TableHeader>
                  <div>
                    <Switch
                      checked={showInativos}
                      onChange={() => setShowInativos(!showInativos)}
                    />
                    <span>Exibir Motivos inativos</span>
                  </div>
                  <Button
                    type="primary"
                    onClick={() => setShowModalNewMotivo(true)}
                  >
                    <UserAddOutlined /> NOVO MOTIVO
                  </Button>
                </TableHeader>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="NOVO MOTIVO"
        visible={showModalNewMotivo}
        cancelText="CANCELAR"
        onCancel={() => handleCancelNewMotivo()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'nova-motivo',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <PsMotivo
          handleChangeMotivo={handleUpdateMotivo}
          onFinish={handleNewMotivo}
          {...handleMotivo}
        />
      </Modal>
    </Col>
  )
}
export default Motivo
