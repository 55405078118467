import React from 'react'
import { Layout, Row, Col } from 'antd'

// Components
import Topbar from '../../components/topbar/Topbar'

// CSS
import './GeneralLayout.css'

const { Content } = Layout

const GeneralLayout = ({ component: Component, ...props }) => (
  <Layout className="general-layout">
    <Topbar />
    <Row>
      <Col xs={{ offset: 1, span: 22 }} md={{ offset: 1, span: 22 }}>
        <Content className="general-layout__content">
          <Component {...props} />
        </Content>
      </Col>
    </Row>
  </Layout>
)

export default GeneralLayout
