import React from 'react'
import { injectIntl } from 'react-intl'

// Components
import { Row, Form } from 'antd'
import Select, { Option } from '../../../components/select/Select'

// Constants

const { useForm } = Form

function AddPessoa({ listPessoas, type, onFinish, loading = false }) {
  const [form] = useForm()

  return (
    <div className="enviar-pesquisa">
      <Form layout="vertical" id="add-pessoa" onFinish={onFinish} form={form}>
        <Form.Item
          label={`Selecione o ${type}:`}
          name="pes_id"
          rules={[
            {
              required: true,
              message: 'Por favor, selecione uma pessoa para adicionar.',
            },
          ]}
        >
          <Select
            placeholder="Selecione"
            style={{ width: '100%' }}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            loading={loading}
          >
            {listPessoas.map(({ pes_nome, pes_id }) => (
              <Option value={pes_id} key={pes_id}>
                {pes_nome}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  )
}

export default injectIntl(AddPessoa)
