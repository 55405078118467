import React from 'react'
import Link from '../../components/privateLink/PrivateLink'
import logo from '../../assets/logo_lg.png'
import { Breadcrumb } from 'antd'

// CSS
import './PageHeader.css'

const { Item } = Breadcrumb

function PageHeader({
  breadcrumb,
  title,
  subtitle,
  renderRight,
  tabs,
  newFitCultura,
}) {
  return (
    <header>
      {!newFitCultura ? (
        <div className={`page-header ${tabs && 'page-header--with-tabs'}`}>
          <div
            className={`page-header__left ${
              renderRight && 'page-header__left--render-right'
            }`}
          >
            <div className="page-header__title">{title}</div>
            {subtitle && <p className="page-header__subtitle">{subtitle}</p>}
          </div>
          <div className="page-header__right">
            <div
              className={`page-header__breadcrumb ${
                renderRight && 'page-header__breadcrumb--render-right'
              }`}
            >
              {breadcrumb && (
                <Breadcrumb>
                  {breadcrumb.map((route, index, arr) => (
                    <Item key={index}>
                      {index === arr.length - 1 ? (
                        route.name
                      ) : route.path !== '#' ? (
                        <Link to={route.path}>{route.name}</Link>
                      ) : (
                        route.name
                      )}
                    </Item>
                  ))}
                </Breadcrumb>
              )}
            </div>
            {renderRight}
          </div>
        </div>
      ) : (
        <div className={`page-header ${tabs && 'page-header--with-tabs'}`}>
          <div
            className={`page-header__left ${
              renderRight && 'page-header__left--render-right'
            }`}
          >
            <img src={logo} alt="Logotipo" style={{ width: '' }} />
          </div>
          <div className="page-header__right">
            <div
              className={`page-header__breadcrumb ${
                renderRight && 'page-header__breadcrumb--render-right'
              }`}
            >
              {breadcrumb && (
                <Breadcrumb>
                  {breadcrumb.map((route, index, arr) => (
                    <Item key={index}>
                      {index === arr.length - 1 ? (
                        route.name
                      ) : (
                        <Link to={route.path}>{route.name}</Link>
                      )}
                    </Item>
                  ))}
                </Breadcrumb>
              )}
            </div>
            {renderRight}
          </div>
        </div>
      )}
      <div className="page-header__tabs">{tabs}</div>
    </header>
  )
}

export default PageHeader
