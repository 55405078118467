import * as constants from '../constants/constants'

export const loadSlas = () => {
  return {
    type: constants.PS_SLA_LOAD,
  }
}

export const listSlas = () => {
  return {
    type: constants.PS_SLA_LIST,
    api: {
      method: 'get',
      url: constants.URL_PS_SLA,
    },
  }
}

export const storeSlas = ({ nome, descricao, dias }) => {
  return {
    type: constants.PS_SLA_STORE,
    api: {
      method: 'post',
      url: constants.URL_PS_SLA,
      data: { nome, descricao, dias },
    },
  }
}

export const updateSlas = ({ sla_id, nome, descricao, dias }) => {
  return {
    type: constants.PS_SLA_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_PS_SLA_ID(sla_id),
      data: { nome, descricao, dias },
    },
  }
}

export const deleteSlas = ({ sla_id }) => {
  return {
    type: constants.PS_SLA_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_PS_SLA_ID(sla_id),
    },
  }
}
