import moment from 'moment'
import 'moment-timezone'
import { message } from 'antd'
import { toast } from 'react-toastify'

import {
  CULTURAS,
  FORCAS,
  GESTAO,
  OLHARES,
  DISC,
} from '../../dashboard/constants/constantsDashboard'

import { parseDISC, parseCulturas, sortCultura } from '../../../utils/utils'

// Redux types
import * as constants from '../constants/constantsPerfil'

const initialState = {
  info: {
    nome: 'Não informado',
    cargo: 'Não informado',
    email: 'Não informado',
    telefone: 'Não informado',
    documento: 'Não informado',
    nascimento: 'Não informado',
    idade: 'Não informado',
    idadetotal: 'Não informado',
    admissao: 'Não informado',
    companyTime: 'Não informado',
    tempoempresa: 'Não informado',
    sexo: 'Não informado',
    url: 'https://app.teamhub.com.br/apollo/sistema',
    id: '',
    times: [],
    cargos: [],
    filial_id: undefined,
    video: 'Não informado',
    pcd: 0,
    pcd_tipo: undefined,
    cid: undefined,
    escolaridade: undefined,
    cep: undefined,
    logradouro: undefined,
    bairro: undefined,
    numero: undefined,
    complemento: undefined,
    estado: undefined,
    cidade: undefined,
    profissao: undefined,
    prtsalarial: undefined,
    linkedin: undefined,
    twitter: undefined,
    site: undefined,
    indicacao: undefined,
    curriculo_url: undefined,
    laudo_url: undefined,
  },
  processos: [],
  vetorComportamental: [],
  percepcaoAmbiente: [],
  vetores: [],
  media: {
    primario: '',
    secundario: '',
    D: '00.00',
    I: '00.00',
    S: '00.00',
    C: '00.00',
    fitCultural: { geral: '00.00' },
  },
  chartVetores: [],
  valoresOrganizacionais: [],
  cultura: [],
  forcas: [],
  olhares: [],
  gestao: [],
  disc: [],
  culturaEmpresa: [],
  forcasEmpresa: [],
  olharesEmpresa: [],
  gestaoEmpresa: [],
  discEmpresa: [],
  culturaOrg: {
    niveisConfianca: [],
    olharLideranca: {
      perceboLider: [],
      frequenciaFeedback: [],
      feedbackContribui: [],
      reconhecimento: [],
      expectativaEntregas: [],
    },
    olharParaMudancas: {
      pontosFortes: [],
      impedimento: [],
      principalProblema: [],
      engajamento: undefined,
      velocidadeAdocao: undefined,
      proficiencia: undefined,
    },
    olharParaSi: {
      escolhaEmpresa: [],
      prioridades: [],
      expectativas6meses: [],
      fonteEntusiasmo: [],
    },
    valoresEvidencia: [],
    satisfacaoGeral: [],
  },
  culturaOrgEmpresa: {
    niveisConfianca: [],
    olharLideranca: {
      perceboLider: [],
      frequenciaFeedback: [],
      feedbackContribui: [],
      reconhecimento: [],
      expectativaEntregas: [],
    },
    olharParaMudancas: {
      pontosFortes: [],
      impedimento: [],
      principalProblema: [],
      engajamento: undefined,
      velocidadeAdocao: undefined,
      proficiencia: undefined,
    },
    olharParaSi: {
      escolhaEmpresa: [],
      prioridades: [],
      expectativas6meses: [],
      fonteEntusiasmo: [],
    },
    valoresEvidencia: [],
    satisfacaoGeral: [],
  },
  processosTriagem: [],
  fitCultural: {
    geral: '00.00',
    arquetipos: {},
    fitArquetipos: {},
    fitValores: { valores: [], geral: '' },
  },
  valoresOrgEmpresa: [],
  processosSeletivos: [],
  processos: [],
  logs: [],
}

export const perfil = (state = initialState, action) => {
  try {
    switch (action.type) {
      case constants.PERFIL_INFO_GET: {
        const {
          pes_nome,
          pes_email,
          pes_cel,
          pes_cpf,
          pes_datanascimento,
          idade,
          dataadmissao,
          companyTime,
          pes_sexo,
          url,
          pes_ativo,
          pes_id,
          filial_id,
          video,
          pcd,
          pcd_tipo,
          cid,
          escolaridade,
          cep,
          logradouro,
          bairro,
          numero,
          complemento,
          estado,
          cidade,
          profissao,
          prtsalarial,
          linkedin,
          twitter,
          site,
          indicacao,
          curriculo_url,
          laudo_url,
        } = action.response.data.pessoa

        if (action.response.status !== 200) return { ...state }

        return {
          ...state,
          info: {
            ...state.info,
            nome: pes_nome || 'Não informado',
            email: pes_email || 'Não informado',
            telefone: pes_cel || 'Não informado',
            documento: pes_cpf || 'Não informado',
            nascimento:
              (pes_datanascimento &&
                moment(pes_datanascimento).format('DD/MM/YYYY')) ||
              'Não informado',
            idade,
            idadetotal: idade || idade === 0 ? idade : 'Não informado',
            companyTime,
            tempoempresa:
              companyTime || companyTime === 0 ? companyTime : 'Não informado',
            admissao:
              (dataadmissao && moment(dataadmissao).format('DD/MM/YYYY')) ||
              'Não informado',
            sexo:
              (pes_sexo && (pes_sexo === 'F' ? 'Feminino' : 'Masculino')) ||
              'Não informado',
            url: url || '',
            ativo: pes_ativo || 0,
            id: pes_id || '',
            filial_id,
            video: video || 'Não informado',
            pcd,
            pcd_tipo,
            cid,
            escolaridade,
            cep,
            logradouro,
            bairro,
            numero,
            complemento,
            estado,
            cidade,
            profissao,
            prtsalarial,
            linkedin,
            twitter,
            site,
            indicacao,
            curriculo_url,
            laudo_url,
          },
        }
      }

      case constants.PERFIL_TIMES_GET: {
        const { grupos } = action.response.data

        if (action.response.status !== 200) return { ...state }

        return {
          ...state,
          info: {
            ...state.info,
            times: grupos.map((grupo) => {
              const { data_entrada, data_saida } = grupo
              return {
                ...grupo,
                data_entrada: data_entrada
                  ? moment(data_entrada)
                      .utc()
                      .tz(moment.tz.guess())
                      .format('DD/MM/YYYY')
                  : data_entrada,
                data_saida: data_saida
                  ? moment(data_saida)
                      .utc()
                      .tz(moment.tz.guess())
                      .format('DD/MM/YYYY')
                  : data_saida,
              }
            }),
          },
        }
      }

      case constants.PERFIL_CARGOS_GET: {
        const { cargos } = action.response.data

        if (action.response.status !== 200) return { ...state }

        return {
          ...state,
          info: {
            ...state.info,
            cargos: cargos.map((cargo) => {
              const { cargopes_dataentrada, cargopes_datasaida } = cargo
              return {
                ...cargo,
                cargopes_dataentrada: cargopes_dataentrada
                  ? moment(cargopes_dataentrada)
                      .utc()
                      .tz(moment.tz.guess())
                      .format('DD/MM/YYYY')
                  : cargopes_dataentrada,
                cargopes_datasaida: cargopes_datasaida
                  ? moment(cargopes_datasaida)
                      .utc()
                      .tz(moment.tz.guess())
                      .format('DD/MM/YYYY')
                  : cargopes_datasaida,
              }
            }),
          },
        }
      }

      case constants.ATIVAR_PERFIL_DELETE: {
        if (action.response.status !== 200) return { ...state }

        message.success('Religado com sucesso!')
        return { ...state, info: { ...state.info, ativo: 1 } }
      }

      case constants.DESATIVAR_PERFIL_DELETE: {
        if (action.response.status !== 200) return { ...state }

        message.success('Desligado com sucesso!')
        return { ...state, info: { ...state.info, ativo: 0 } }
      }

      case constants.VETORES_GET: {
        // Garantir que o estado seja atualizado com uma nova referência
        const { vetores, media } = action.response.data.data

        if (action.response.status !== 200) return { ...state }

        // Criar uma nova referência para chartVetores
        const chartVetores = vetores.flatMap((elem) => [
          {
            log_data: elem['log_data'],
            value: parseFloat(elem['D']),
            type: 'D',
          },
          {
            log_data: elem['log_data'],
            value: parseFloat(elem['I']),
            type: 'I',
          },
          {
            log_data: elem['log_data'],
            value: parseFloat(elem['S']),
            type: 'S',
          },
          {
            log_data: elem['log_data'],
            value: parseFloat(elem['C']),
            type: 'C',
          },
        ])

        // Retornar o novo estado com referências atualizadas
        return {
          ...state,
          vetores: [...vetores], // Garantir que a referência de vetores seja nova
          media: { ...state.media, ...media }, // Atualizar media com nova referência
          chartVetores, // Atualizar chartVetores com nova referência
        }
      }

      case constants.VETOR_COMPORTAMENTAL_GET: {
        const { avaliacoes } = action.response.data

        if (action.response.status !== 200) return { ...state }

        return {
          ...state,
          vetorComportamental: avaliacoes.map((avaliacao) => {
            const { data_entrega, data_solicitacao } = avaliacao
            return {
              ...avaliacao,
              respondido: data_entrega ? 1 : 0,
              data_entrega: data_entrega
                ? moment(data_entrega)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('DD/MM/YYYY (HH:mm)')
                : data_entrega,
              data_solicitacao: data_solicitacao
                ? moment(data_solicitacao)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('DD/MM/YYYY (HH:mm)')
                : data_solicitacao,
              ativo: data_entrega ? '1' : '0',
              tipo: 'Perfil Comportamental',
            }
          }),
        }
      }

      case constants.PERCEPCAO_AMBIENTE_GET: {
        const { avaliacoes } = action.response.data

        if (action.response.status !== 200) return { ...state }

        return {
          ...state,
          percepcaoAmbiente: avaliacoes.map((avaliacao) => {
            const { data_entrega, data_solicitacao } = avaliacao
            return {
              ...avaliacao,
              respondido: data_entrega ? 1 : 0,
              data_entrega: data_entrega
                ? moment(data_entrega)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('DD/MM/YYYY (HH:mm)')
                : data_entrega,
              data_solicitacao: data_solicitacao
                ? moment(data_solicitacao)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('DD/MM/YYYY (HH:mm)')
                : data_solicitacao,
              tipo: 'Pesquisa de Percepção',
            }
          }),
        }
      }

      case constants.PERFIL_GET_PESQ_VALORES_ORGANIZACIONAIS: {
        const { avaliacoes } = action.response.data

        if (action.response.status !== 200)
          return { ...state, valoresOrganizacionais: [] }

        return {
          ...state,
          valoresOrganizacionais: avaliacoes.map((avaliacao) => {
            const { entrega, solicitacao } = avaliacao
            return {
              ...avaliacao,
              respondido: entrega ? 1 : 0,
              data_entrega: entrega
                ? moment(entrega)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('DD/MM/YYYY (HH:mm)')
                : entrega,
              data_solicitacao: solicitacao
                ? moment(solicitacao)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('DD/MM/YYYY (HH:mm)')
                : solicitacao,
              tipo: 'Valores Organizacionais',
            }
          }),
        }
      }

      case constants.SET_ACTIVE_TAB: {
        return { ...state, activeTab: action.value }
      }

      case constants.PERFIL_COLABORADOR_CULTURA_GET: {
        if (action.response.status !== 200) {
          if (action.response.status !== 204)
            toast.error('Erro ao obter cultura do colaborador.')
          return {
            ...state,
            cultura: initialState.cultura,
            forcas: initialState.forcas,
            disc: initialState.disc,
            gestao: initialState.gestao,
            olhares: initialState.olhares,
          }
        }

        const { cultura } = action.response.data

        const { nome } = state.info

        const newCulturas = [
          ...Object.keys(cultura)
            .filter((key) => CULTURAS.includes(key))
            .map((key) => ({
              Tipo: key,
              Valor: Number(cultura[key]),
              Label: nome,
            })),
        ]

        const forcas = [
          ...Object.keys(cultura)
            .filter((key) => FORCAS.includes(key))
            .map((key) => ({
              Tipo: key === 'Ambiente interno' ? 'Ambiente Interno' : key,
              Valor: Number(cultura[key]),
              Label: nome,
            })),
        ]

        const olhares = [
          ...Object.keys(cultura)
            .filter((key) => OLHARES.includes(key))
            .map((key) => ({
              Tipo: key,
              Valor: Number(cultura[key]),
              Label: nome,
            })),
        ]

        const gestao = [
          ...Object.keys(cultura)
            .filter((key) => GESTAO.includes(key))
            .map((key) => ({
              Tipo: key,
              Valor: Number(cultura[key]),
              Label: nome,
            })),
        ]

        const disc = [
          ...Object.keys(cultura)
            .filter((key) => DISC.includes(key))
            .map((key) => ({
              Tipo: parseDISC(key),
              Valor: Number(cultura[key]),
              Label: nome,
            })),
        ]

        return {
          ...state,
          cultura: sortCultura(newCulturas),
          forcas,
          gestao,
          olhares,
          disc,
        }
      }

      case constants.PERFIL_COLABORADOR_CULTURAORG_GET: {
        if (action.response.status !== 200) {
          if (action.response.status !== 204)
            toast.error('Erro ao obter cultura organizacional do colaborador.')
          return { ...state, culturaOrg: initialState.culturaOrg }
        }

        const { culturaOrganizacional } = action.response.data

        return {
          ...state,
          culturaOrg: {
            ...state.culturaOrg,
            ...culturaOrganizacional,

            olharLideranca: {
              ...state.culturaOrg.olharLideranca,
              ...culturaOrganizacional.olharLideranca,
            },
            olharParaMudancas: {
              ...state.culturaOrg.olharParaMudancas,
              ...culturaOrganizacional.olharParaMudancas,
            },
            olharParaSi: {
              ...state.culturaOrg.olharParaSi,
              ...culturaOrganizacional.olharParaSi,
            },
          },
        }
      }
      case constants.PERFIL_COLABORADOR_FITCULTURAL_GET: {
        if (action.response.status !== 200) {
          if (action.response.status !== 204) {
            toast.error('Erro ao obter fit cultural do colaborador.')
          }

          return { ...state, fitCultural: initialState.fitCultural }
        }

        const { fitCultural } = action.response.data

        return {
          ...state,
          fitCultural,
        }
      }
      case constants.PERFIL_COLABORADOR_CULTURAORG_EMPRESA_GET: {
        if (action.response.status !== 200) {
          if (action.response.status !== 204)
            toast.error('Erro ao obter cultura organizacional da empresa.')
          return {
            ...state,
            culturaOrgEmpresa: initialState.culturaOrgEmpresa,
          }
        }

        const { culturaOrganizacional } = action.response.data

        return {
          ...state,
          culturaOrgEmpresa: {
            ...state.culturaOrgEmpresa,
            ...culturaOrganizacional,

            olharLideranca: {
              ...state.culturaOrgEmpresa.olharLideranca,
              ...culturaOrganizacional.olharLideranca,
            },
            olharParaMudancas: {
              ...state.culturaOrgEmpresa.olharParaMudancas,
              ...culturaOrganizacional.olharParaMudancas,
            },
            olharParaSi: {
              ...state.culturaOrgEmpresa.olharParaSi,
              ...culturaOrganizacional.olharParaSi,
            },
          },
        }
      }
      case constants.PERFIL_COLABORADOR_VALORES_ORG_GET: {
        if (action.response.status !== 200) {
          if (action.response.status !== 204)
            toast.error('Erro ao obter valores organizacionais da empresa.')
          return {
            ...state,
            valoresOrgEmpresa: initialState.valoresOrgEmpresa,
          }
        }

        const { valores } = action.response.data

        return { ...state, valoresOrgEmpresa: valores || [] }
      }

      case constants.PERFIL_COLABORADOR_CULTURA_EMPRESA_GET: {
        if (action.response.status !== 200) {
          if (action.response.status !== 204)
            toast.error('Erro ao buscar cultura da empresa.')
          return { ...state, processosTriagem: [] }
        }

        const { culturaDesejada, culturaInstalada } = action.response.data

        const { cultura, forcas, disc, olhares, gestao } = parseCulturas({
          culturaDesejada,
          culturaInstalada,
        })

        return {
          ...state,
          culturaEmpresa: cultura,
          forcasEmpresa: forcas,
          discEmpresa: disc,
          olharesEmpresa: olhares,
          gestaoEmpresa: gestao,
        }
      }

      case constants.PERFIL_PROCESSO_SELETIVO: {
        if (action.response.status !== 200) {
          return { ...state, processosSeletivos: [] }
        }
        return { ...state, processosSeletivos: action.response.data }
      }

      case constants.PERFIL_COLABORADOR_PROCESSOS_LIST: {
        if (action.response.status !== 200) {
          if (action.response.status !== 204)
            toast.error('Erro ao listar Processos Seletivos do colaborador.')
          return { ...state, processos: initialState.processos }
        }

        const { processos } = action.response.data

        return { ...state, processos }
      }

      case constants.PERFIL_COLABORADOR_PROCESSO_LOG_LIST: {
        if (action.response.status !== 200) {
          if (action.response.status !== 204)
            toast.error(
              'Erro ao listar logs do Processo Seletivo do colaborador.'
            )
          return { ...state, logs: initialState.logs }
        }

        const { logs } = action.response.data

        return { ...state, logs }
      }

      case constants.PERFIL_COLABORADOR_PROCESSOS_TRIAGEM_LIST: {
        if (action.response.status !== 200) {
          if (action.response.status !== 204)
            toast.error('Erro ao listar Processos Seletivos da empresa.')
          return { ...state, processosTriagem: [] }
        }

        const { processos } = action.response.data

        return { ...state, processosTriagem: processos }
      }
      case constants.PERFIL_COLABORADOR_CULTURA_GET: {
        if (action.response.status !== 200) {
          if (action.response.status !== 204)
            toast.error('Erro ao buscar cultura da empresa.')
          return { ...state, processosTriagem: [] }
        }

        const { culturaDesejada, culturaInstalada } = action.response.data

        const { cultura, forcas, disc, olhares, gestao } = parseCulturas({
          culturaDesejada,
          culturaInstalada,
        })

        return {
          ...state,
          culturaEmpresa: cultura,
          forcasEmpresa: forcas,
          discEmpresa: disc,
          olharesEmpresa: olhares,
          gestaoEmpresa: gestao,
        }
      }

      default:
        return state
    }
  } catch {
    return state
  }
}
