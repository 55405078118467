import React, { Component } from 'react'

// Components

// CSS
import './LegendCard.css'

class LegendCard extends Component {
  render() {
    const { title, description, children, bodyStyle } = this.props
    return (
      <fieldset className="legend-card" style={bodyStyle}>
        <legend className="legend-card__header">
          <span>
            <b>{title}</b>
          </span>
          <span>{description}</span>
        </legend>
        {children}
      </fieldset>
    )
  }
}

export default LegendCard
