import React from 'react'
import { injectIntl } from 'react-intl'
import { UserOutlined } from '@ant-design/icons'
import { Modal, Row, Col } from 'antd'

import history from '../../utils/history'

// Assets
import logo from '../../assets/logo_lg.png'

// Components
import Input from '../../components/input/Input'
import Button from '../../components/button/Button'

// CSS
import './EsqueciMinhaSenha.css'

function EsqueciMinhaSenhaView({ intl, handleChange, onSubmit }) {
  return (
    <Modal
      visible
      centered
      mask={false}
      footer={null}
      closable={false}
      wrapClassName="login"
    >
      <div className="login__header">
        <img src={logo} />
      </div>
      <form className="login__form" onSubmit={onSubmit}>
        <div className="cadastro__header">
          <h2>Esqueceu sua senha?</h2>
          <p>Insira seu e-mail registrado para redefinir sua senha</p>
        </div>
        <Row>
          <Col md={24}>
            <Input
              type="email"
              placeholder={intl.formatMessage({ id: 'common.email' })}
              name="email"
              onChange={handleChange}
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Button
              type="primary"
              className="btn_orange"
              htmlType="submit"
              block
            >
              Enviar e-mail
            </Button>
          </Col>
          <Col md={24}>
            <Button
              type="link"
              className="login__form__link-btn"
              onClick={() => history.push('/')}
              block
            >
              Voltar para o login
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default injectIntl(EsqueciMinhaSenhaView)
