import styled, { css } from 'styled-components'
import { Statistic as StatisticItem, Card, Col } from 'antd'

export const Statistic = styled(StatisticItem)``

export const ContainerKPI = styled(Card)`
  width: 100%;

  .ant-card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ant-statistic-content-suffix {
    color: #ef5c79;
    font-size: 14px;
  }

  .ant-statistic {
    text-align: center;
  }

  .ant-steps-item-custom.ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon {
    color: rgba(0, 0, 0, 0.7);
  }
  .ant-steps-item
    .ant-steps-item-wait
    .ant-steps-item-custom
    .ant-steps-item-icon
    > .ant-steps-icon {
    color: red;
  }
`

export const EtapaKPI = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .value {
    font-size: 24px;
    font-weight: 500;
  }

  .ant-statistic-content-suffix {
    color: #ef5c79;
    font-size: 14px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    .pct {
      margin-left: 4px;
    }
  }
`

export const ChartContainer = styled(Card)`
  min-height: 100%;

  .ant-card-body {
    padding: 12px;
  }
`

export const DashboardCol = styled(Col)`
  margin-bottom: 36px;
`

export const LegendaContainer = styled.div`
  width: 100%;
  padding: 12px;

  .legenda-item + .legenda-item {
    border-top: 1px solid #f0f0f0;
  }

  .legenda-item {
    display: flex;
    justify-content: space-between;
    padding: 4px 0px;
    font-size: 16px;
  }

  .legenda-item-titulo {
    display: flex;
    align-items: center;
  }

  .legenda-item-titulo span {
    font-size: 14px;
  }

  .legenda-item-label {
    width: 12px;
    height: 12px;
    margin: 6px;
  }

  .legenda-item-valor {
    font-size: 16px;
    font-weight: 500;
  }
`

export const ControllersContainer = styled.div`
  width: '100%';
  display: flex;
  justify-content: end;

  margin-bottom: 36px;
`

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
