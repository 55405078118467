import { Button, Table } from 'antd'
import { CSVLink } from 'react-csv'

const columns = [
  {
    title: 'Pessoa',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: any, b: any) => a.name.charCodeAt(0) - b.name.charCodeAt(0),
    sortDirections: ['ascend'],
  },
  {
    title: 'Fit Geral',
    dataIndex: 'fitGeral',
    key: 'fitGeral',
    sorter: (a: any, b: any) => {
      const itemA = a.fitGeral.replace('%', '')
      const itemB = b.fitGeral.replace('%', '')
      return Number(itemA) - Number(itemB)
    },
  },
  {
    title: 'Fit Arquetipos',
    dataIndex: 'fitArquetipos',
    key: 'fitArquetipos',
    sorter: (a: any, b: any) => {
      const itemA = a.fitArquetipos.replace('%', '')
      const itemB = b.fitArquetipos.replace('%', '')
      return Number(itemA) - Number(itemB)
    },
  },
  {
    title: 'Fit Valores',
    dataIndex: 'fitValores',
    key: 'fitValores',
    sorter: (a: any, b: any) => {
      const itemA = a.fitValores.replace('%', '')
      const itemB = b.fitValores.replace('%', '')
      return Number(itemA) - Number(itemB)
    },
  },
]

type Props = {
  isPrintable?: boolean
  data: [{}]
}

const App = (props: Props) => {
  return (
    <div style={{ marginTop: 50 }}>
      <Button type="primary" style={{ marginBottom: 20 }}>
        {!props.isPrintable && (
          <CSVLink
            filename="Reports.csv"
            data={props.data}
            className="btn btn-primary"
            onClick={() => {
              console.log('The file is downloading')
            }}
          >
            Exportar para CSV
          </CSVLink>
        )}
      </Button>
      {props.isPrintable ? (
        <Table
          pagination={false}
          dataSource={props.data}
          columns={columns as unknown as any}
        />
      ) : (
        <Table
          dataSource={props.data}
          columns={columns as unknown as any}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
          }}
        />
      )}
    </div>
  )
}

export default App
