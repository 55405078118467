import { Select, Button } from 'antd'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getObjetivosEstrategicos, postObjetivosEstrategicos } from '../Api/api'

const { Option } = Select

const App = (props) => {
  const [filiais, setFiliais] = useState([])
  const [obj_conta_id, setObj] = useState('')
  useEffect(() => {
    getFromApi()
  }, [])

  async function VincularObjetivo() {
    let data
    if (props.subcultura)
      data = {
        emp_id: props.empresa,
        conta_id: props.conta,
        obj_conta_id,
        subcultura_id: props.subcultura,
      }
    else
      data = {
        emp_id: props.empresa,
        conta_id: props.conta,
        obj_conta_id,
      }
    const response = await postObjetivosEstrategicos(
      props.cultura,
      props.grupo,
      data
    )
    if (response.success) {
      props.updateList()
      props.closeModal()
      toast.success('Objetivo vinculado')
    } else {
      props.closeModal()
      toast.error(response.message)
    }
  }

  async function getFromApi() {
    try {
      const response = await getObjetivosEstrategicos()
      if (response.success) {
        setFiliais(response.objetivosEstrategicos)
      }
    } catch {
      console.log('erro ao obter objetivos estrategicos')
    }
  }

  const handleChange = (value) => {
    setObj(value)
  }

  return (
    <div style={{ minHeight: 80 }}>
      <Select style={{ minWidth: 400 }} onChange={handleChange}>
        {filiais.map((filial, id) => (
          <Option value={filial.obj_conta_id} key={id}>
            {filial.obj_cliente}
          </Option>
        ))}
      </Select>
      <Button
        onClick={VincularObjetivo}
        type="primary"
        className="btn_purple newCultureBtn floatRight"
      >
        Vincular Objetivo
      </Button>
    </div>
  )
}

export default App
