import * as constants from '../constants'

export const getCandidatos = (nome) => {
  return {
    type: constants.GET_DASHBOARD_CANDIDATO_COMPLETO,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_CANDIDATO_COMPLETO + '?nome=' + nome,
    },
  }
}

export const newLink = (pes_id, avalia_id) => {
  return {
    type: constants.POST_DASHBOARD_NEW_LINK,
    api: {
      method: 'post',
      url: constants.URL_DASHBOARD_NEW_LINK(pes_id, avalia_id),
    },
  }
}
