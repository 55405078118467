import _ from 'lodash'
import moment from 'moment'

// Redux types
import * as constants from '../constants/constantsPesquisas'

const initialState = {
  pesquisasDisponiveis: [],
  perfilComportamental: { caracteristicas: [[], [], []] },
  percepcaoAmbiente: { questoes: [[]] },
}

export const pesquisas = (state = initialState, action) => {
  try {
    switch (action.type) {
      case constants.PESQUISAS_DISPONIVEIS_GET:
        const { pesquisas } = action.response.data

        if (action.response.status !== 200) return { ...state }

        return {
          ...state,
          pesquisasDisponiveis: _.concat(
            state.pesquisasDisponiveis,
            ...pesquisas.map((item) => ({
              id: item.avalia_id,
              data: moment(item.data_solicitacao).format('DD/MM/YYYY'),
              tipo: constants.PESQUISAS[item.idpesquisa],
            }))
          ).filter((item) => item.tipo),
        }

      case constants.AVALIACOES_DISPONIVEIS_GET:
        const { avaliacao } = action.response.data

        if (action.response.status !== 200) return { ...state }

        return {
          ...state,
          pesquisasDisponiveis: _.concat(state.pesquisasDisponiveis, {
            id: avaliacao.avalia_id,
            data: moment(avaliacao.data_solicitacao).format('DD/MM/YYYY'),
            tipo: constants.PERFIL_COMPORTAMENTAL,
          }),
        }

      case constants.CARACTERISTICAS_PERFIL_COMPORTAMENTAL_GET:
        const { caracteristicas } = action.response.data

        if (action.response.status !== 200) return { ...state }

        return {
          ...state,
          perfilComportamental: {
            ...state.perfilComportamental,
            caracteristicas: caracteristicas.map((c) => _.chunk(c, 4)),
          },
        }

      case constants.QUESTOES_PESQUISA_GET:
        const { questoes, questoesGrade } = action.response.data

        if (action.response.status !== 200) return { ...state }

        return {
          ...state,
          percepcaoAmbiente: {
            ...state.percepcaoAmbiente,
            questoes: _.values(
              _.groupBy([...questoes, ...questoesGrade], 'pagina')
            ),
          },
        }

      default:
        return state
    }
  } catch {
    return state
  }
}
