import styled from 'styled-components'

import { Form as FormAntd } from 'antd'

export const Form = styled(FormAntd)`
  .select {
    margin-bottom: 0px;
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
`

export const FaixaSalarialGroup = styled.div`
  margin-bottom: 16px;

  > .ant-input-group {
    max-width: 100%;
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .divider {
      pointer-events: none;
      width: 30px;
    }

    .ant-form-item {
      margin: 0;
      flex: 1;
    }
  }
`
