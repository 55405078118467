import React from 'react'

import moment from 'moment'

import 'moment-timezone'

import { useHistory } from 'react-router-dom'

import { LinkOutlined } from '@ant-design/icons'

import { Container, LinkPesquisa } from './styles'

function PesquisasPendentes({ pesquisas, conta_url }) {
  const history = useHistory()

  const n = pesquisas.length

  const handleLinkClick = (pesquisa) => {
    const { tipo, hashacesso } = pesquisa

    if (tipo) {
      const actions = {
        'perfil comportamental': () => {
          history.push(
            `/${conta_url}/pesquisas/perfil-comportamental/${hashacesso}`
          )
        },
        percepção: () => {
          history.push(
            `/${conta_url}/pesquisas/percepcao-ambiente/${hashacesso}`
          )
        },
        valores: () => {
          history.push(
            `/${conta_url}/pesquisas/valores-organizacionais/${hashacesso}`
          )
        },
      }

      if (actions[tipo.toLowerCase()]) {
        actions[tipo.toLowerCase()]()
      }
    }
  }

  return (
    <Container>
      <span>
        {'Estamos quase terminando, mas você ainda possui '}
        {n} pesquisa
        {n > 1 ? 's' : ''}
        {n > 1 ? 's' : ''} pendente{n > 1 ? 's' : ''}!
      </span>
      <span>Responda as pesquisas abaixo para completar este processo: </span>
      <ul>
        {pesquisas.map((pesquisa) => (
          <li key={pesquisa.avalia_id}>
            <LinkPesquisa onClick={() => handleLinkClick(pesquisa)}>
              {pesquisa.tipo} -{' '}
              {moment(pesquisa.data_solicitacao)
                .utc()
                .tz(moment.tz.guess())
                .format('DD/MM/YYYY (HH:mm)')}
            </LinkPesquisa>
            <LinkOutlined />
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default PesquisasPendentes
