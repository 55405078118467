import * as constants from '../constants/constants'

export const loadMotivoSuspensao = () => {
  return {
    type: constants.PS_MOTIVO_SUSPENSAO_LOAD,
  }
}

export const listMotivoSuspensao = () => {
  return {
    type: constants.PS_MOTIVO_SUSPENSAO_LIST,
    api: {
      method: 'get',
      url: constants.URL_PS_MOTIVO_SUSPENSAO,
    },
  }
}

export const storeMotivoSuspensao = (data) => {
  return {
    type: constants.PS_MOTIVO_SUSPENSAO_STORE,
    api: {
      method: 'post',
      url: constants.URL_PS_MOTIVO_SUSPENSAO,
      data: { ...data },
    },
  }
}

export const updateMotivoSuspensao = ({ suspensao_id, ...data }) => {
  return {
    type: constants.PS_MOTIVO_SUSPENSAO_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_PS_MOTIVO_SUSPENSAO_ID(suspensao_id),
      data: { ...data },
    },
  }
}

export const deleteMotivoSuspensao = ({ suspensao_id }) => {
  return {
    type: constants.PS_MOTIVO_SUSPENSAO_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_PS_MOTIVO_SUSPENSAO_ID(suspensao_id),
    },
  }
}
