// Redux types
import * as constants from '../constants/constantsOnboarding'

export const postOnboarding = ({ currentStep }) => {
  let data = {}
  switch (currentStep) {
    case 0:
      data = { obj_enable: 0 }
      break

    case 1:
      data = { val_enable: 0 }
      break

    case 2:
      data = { colab_enable: 0 }
      break

    default:
  }

  return {
    type: constants.ONBOARDING_POST,
    api: {
      method: 'post',
      url: constants.URL_ONBOARDING_POST,
      data,
    },
  }
}
