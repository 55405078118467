import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import {
  Row,
  Col,
  Card,
  Popconfirm,
  Modal,
  Slider,
  Switch,
  Tooltip,
} from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

// Components
import Button from '../../components/button/Button'
import Input from '../../components/input/Input'
import Table from '../../components/editableTable/EditableTable'

// CSS
import './ValoresOrganizacionais.css'

function ValoresOrganizacionaisView({
  intl,
  handleChange,
  handleDisable,
  handleDelete,
  closeModal,
  openModal,
  onModalSubmit,
  cards,
  showModal,
  nomeValorOrganizacional,
  comportamentos,
  descricao,
  fitcultural,
  error,
  novoComportamento,
  handleChangeNovoComportamento,
  handleAddNovoComportamento,
  handleDeleteNovoComportamento,
  onSaveComportamento,
  usarponderacao,
}) {
  let comportamentoCols = [
    {
      title: 'Comportamento',
      dataIndex: 'comportamento',
      editable: true,
    },
  ]

  if (usarponderacao) {
    comportamentoCols = [
      ...comportamentoCols,
      {
        title: 'Ponderação',
        dataIndex: 'ponderacao',
        editable: true,
        inputType: 'number',
        render: (text) => `${text} %`,
      },
    ]
  }

  return (
    <>
      <Helmet>
        <title>TeamHub | Valores Organizacionais</title>
      </Helmet>
      <div className="valores-organizacionais">
        <Card
          type="inner"
          title={
            <span>
              <b>
                Selecione os valores organizacionais a serem praticados na sua
                empresa. Você pode editar, excluir ou adicionar um novo valor
                organizacional. Recomendamos que sejam criados no máximo 6
                valores organizacionais.
              </b>
            </span>
          }
        >
          <div className="valores-organizacionais__buttons">
            <Button
              type="primary"
              className="btn_purple"
              onClick={() => openModal()}
            >
              ADICIONAR NOVO VALOR
            </Button>
          </div>
          <Row gutter={30}>
            {cards.map(
              (card, index) =>
                !card.excluded && (
                  <Col md={8}>
                    <Card
                      type="inner"
                      className={`valores-organizacionais__cards ${
                        card.disabled ? 'disabled' : ''
                      }`}
                      actions={[
                        // <Button
                        // 	type="link"
                        // 	className="valores-organizacionais__cards__actions"
                        // 	onClick={() => handleDisable(index)}
                        // >
                        // 	{card.disabled ? 'Ativar' : 'Desativar'}
                        // </Button>,
                        <Button
                          type="link"
                          className="valores-organizacionais__cards__actions"
                          onClick={() => openModal({ card, index })}
                          disabled={
                            !card.valor_id || card.disabled || card.default
                          }
                        >
                          <Tooltip title="Editar" placement="bottom">
                            <EditOutlined />
                          </Tooltip>
                        </Button>,
                        <Button
                          type="link"
                          className="valores-organizacionais__cards__actions"
                          disabled={card.disabled || card.default}
                        >
                          <Popconfirm
                            title={`Ao excluir um Valor Organizacional, o mesmo não será exibido no formulário de Pesquisa de Valores Organizacionais e nem nos Relatórios.
													Deseja realmente excluir?`}
                            onConfirm={() => handleDelete(index)}
                            okText="Sim"
                            cancelText="Não"
                            overlayStyle={{ maxWidth: '350px' }}
                          >
                            <a href="javascript:;" aria-label="excluir">
                              <Tooltip title="Excluir" placement="bottom">
                                <DeleteOutlined />
                              </Tooltip>
                            </a>
                          </Popconfirm>
                        </Button>,
                      ]}
                    >
                      <h3 className="valores-organizacionais__cards__title">
                        {card.nome}
                      </h3>
                      <p>Fit cultural mínimo -{card.fitcultural}%</p>
                      <p>Conceito:{card.descricao}</p>
                      <div style={{ overflowY: 'auto', maxHeight: '150px' }}>
                        {card.comportamentos.map((comportamento, index) => (
                          <p>
                            {index + 1} -{comportamento.comportamento}
                          </p>
                        ))}
                      </div>
                    </Card>
                  </Col>
                )
            )}
          </Row>
        </Card>
        <Modal
          title="Adicionar valor organizacional"
          visible={showModal}
          centered
          onOk={onModalSubmit}
          onCancel={closeModal}
          okText="Salvar"
          className="valoresorg__modal"
        >
          <Form layout="vertical">
            <Form.Item
              label="Defina um valor organizacional:"
              validateStatus={error.nomeValorOrganizacional ? 'error' : ''}
              help={error.nomeValorOrganizacional || ''}
            >
              <Input
                type="text"
                placeholder="Ex: Autonomia para inovar"
                name="nomeValorOrganizacional"
                value={nomeValorOrganizacional}
                onChange={handleChange}
                withoutMargin
              />
            </Form.Item>
            <Form.Item
              label="Defina o conceito deste valor:"
              validateStatus={error.descricao ? 'error' : ''}
              help={error.descricao || ''}
            >
              <Input
                type="text"
                placeholder='Ex: "Divirto-me no trabalho, mantendo uma postura positiva e alegre independente das circunstâncias."'
                name="descricao"
                value={descricao}
                onChange={handleChange}
                withoutMargin
              />
            </Form.Item>
            <Form.Item
              label="Defina o percentual mínimo para Fit Cultural deste valor"
              validateStatus={error.fitcultural ? 'error' : ''}
              help={error.fitcultural || ''}
            >
              <Slider
                defaultValue={80}
                name="fitcultural"
                onChange={(value) =>
                  handleChange({
                    target: {
                      name: 'fitcultural',
                      value,
                    },
                  })
                }
                value={fitcultural}
                tooltipVisible
                getTooltipPopupContainer={() =>
                  document.querySelector('.ant-slider-step')
                }
              />
            </Form.Item>
            <hr />
            <p>Defina comportamentos que demonstram esse valor:</p>

            <Form.Item
              label="Comportamento:"
              validateStatus={error.comportamento1 ? 'error' : ''}
              help={error.comportamento1 || ''}
            >
              <Input
                type="text"
                placeholder="Insira um novo comportamento"
                name="novoComportamento"
                value={novoComportamento}
                onChange={(evt) => {
                  handleChangeNovoComportamento(evt.target.value)
                }}
                withoutMargin
              />
              <div className="valoresorg-comportamento__options_containter">
                <Button
                  type="primary"
                  onClick={() => handleAddNovoComportamento()}
                >
                  Adicionar
                </Button>
                <div className="valoresorg-comportamento__ponderacao__container">
                  <Switch
                    onChange={(value) =>
                      handleChange({
                        target: {
                          name: 'usarponderacao',
                          value,
                        },
                      })
                    }
                    checked={usarponderacao}
                  />
                  <span>Utilizar ponderação para cálculo de Fit Cultural</span>
                </div>
              </div>

              <Table
                dataSource={comportamentos.map((c, index) => ({
                  ...c,
                  key: index,
                }))}
                columns={comportamentoCols}
                isEditable
                onSave={onSaveComportamento}
                key={(record) => record.comportamento_id}
                isDeleble
                deleteNode={(handleDelete, key) => (
                  <Popconfirm
                    title="Ao excluir um Comportamento, o mesmo não será exibido no formulário de Pesquisa de Valores Organizacionais e nem nos Relatórios.
								Deseja realmente excluir?"
                    overlayStyle={{ maxWidth: '350px' }}
                    onConfirm={() =>
                      handleDeleteNovoComportamento(
                        comportamentos.find((c, index) => index === key)
                          .comportamento
                      )
                    }
                    okText="Sim"
                    cancelText="Não"
                  >
                    <a>
                      <Tooltip title="Excluir" placement="bottom">
                        <DeleteOutlined />
                      </Tooltip>
                    </a>
                  </Popconfirm>
                )}
                usarponderacao={usarponderacao}
                editPondera={() =>
                  handleChange({
                    target: {
                      name: 'isEdit',
                      value: true,
                    },
                  })
                }
                savePondera={() =>
                  handleChange({
                    target: {
                      name: 'isEdit',
                      value: false,
                    },
                  })
                }
              />
              {/*
						<List
							dataSource={comportamentos}
							header={<div>Comportamentos</div>}
							size='small'
							itemLayout='vertical'
							className='valores-org__custom-list'
							renderItem={(item) => (
								<List.Item>
									<div
										style={{ display: 'flex', justifyContent: 'space-between' }}
									>
										<span>{item.comportamento}</span>{' '}
										<Popconfirm
											title='Ao excluir um Comportamento, o mesmo não será exibido no formulário de Pesquisa de Valores Organizacionais e nem nos Relatórios.
											Deseja realmente excluir?'
											overlayStyle={{ maxWidth: '350px' }}
											onConfirm={() =>
												handleDeleteNovoComportamento(item.comportamento)
											}
											okText='Sim'
											cancelText='Não'
										>
											<DeleteOutlined />
										</Popconfirm>
									</div>
								</List.Item>
							)}
						/>
						*/}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default injectIntl(ValoresOrganizacionaisView)
