import React, { useEffect, useState, useCallback, useMemo } from 'react'

import { toast } from 'react-toastify'

import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'

import 'moment-timezone'

import {
  Card,
  Form,
  Modal,
  Table,
  Button,
  Tooltip,
  Row,
  Popconfirm,
  Divider,
} from 'antd'

import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  ProfileOutlined,
} from '@ant-design/icons'

import { Status } from './styles'

import Link from '../../../../components/privateLink/PrivateLink'

import { getQuizes } from './actions/actionsQuiz'

import {
  updateQuiz,
  updateStatusQuiz,
} from './PainelQuiz/actions/actionsPainelQuiz'

import { sorterDate, sorterString } from '../../../../utils/utils'
import { loadQuiz } from './FormularioQuiz/actions/actionsFormularioQuiz'
import quiz from './reducers/reducersQuiz'

import FormSuspenderQuiz from './components/FormSuspenderQuiz'

const { Meta } = Card

function Quiz(_, SearchInput) {
  const dispatch = useDispatch()

  const quizes = useSelector((state) => state.quiz.quizes)

  const [loading, setLoading] = useState([])

  const [showModalSuspender, setShowModalSuspender] = useState(false)

  const [quizSelecionado, setQuizSelecionado] = useState()

  const loadQuizes = useCallback(() => {
    setLoading(true)
    dispatch(getQuizes()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  useEffect(() => {
    loadQuizes()
  }, [loadQuizes])

  const handleOpenModalSuspender = useCallback(
    (quiz_id) => {
      const quizSelecionado = quizes.find((quiz) => quiz.quiz_id === quiz_id)

      setQuizSelecionado(quizSelecionado)

      setShowModalSuspender(true)
    },
    [quizes]
  )

  const handleCloseModalSuspender = () => {
    setQuizSelecionado(undefined)

    setShowModalSuspender(false)
  }

  const handleSuspenderQuiz = useCallback(
    (quiz_id, values) => {
      dispatch(updateStatusQuiz(quiz_id)).then(({ response }) => {
        if (response.status === 200) {
          dispatch(updateQuiz(quiz_id, values)).then(({ response }) => {
            if (response.status === 200) {
              toast.success('Quiz suspenso com sucesso.')
              loadQuizes()

              setShowModalSuspender(false)
            }
          })
        }
      })
    },
    [dispatch, loadQuizes]
  )

  const handleAtivarQuiz = useCallback(
    (quiz_id) => {
      dispatch(updateStatusQuiz(quiz_id)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Quiz ativado com sucesso.')
          loadQuizes()
        }
      })
    },
    [dispatch, loadQuizes]
  )

  const tableQuizColumns = useMemo(() => {
    return [
      {
        title: 'Status',
        key: 'ativo',
        dataIndex: 'ativo',
        width: '5%',
        align: 'center',
        filters: [
          {
            text: 'Ativo',
            value: 1,
          },
          {
            text: 'Suspenso',
            value: 0,
          },
        ],
        onFilter: (value, record) => {
          return record.ativo === value
        },
        render: (text, record) =>
          text === 1 ? (
            <Tooltip title="Ativo">
              <Status ativo />
            </Tooltip>
          ) : (
            <Tooltip title="Suspenso">
              <Status />
            </Tooltip>
          ),
      },
      {
        title: 'Título',
        key: 'titulo',
        dataIndex: 'titulo',
        align: 'left',
        sorter: (a, b) => sorterString(a, b, 'titulo'),
        render: (text, record) => (
          <>
            <div>
              <Link to={`/teste-painel/${record.quiz_id}`}>{text}</Link>
            </div>
          </>
        ),
      },
      {
        title: 'Criado em',
        key: 'created_at',
        dataIndex: 'created_at',
        align: 'center',
        render: (text, record) =>
          text
            ? moment(text)
                .utc()
                .tz(moment.tz.guess())
                .format('DD/MM/YYYY (HH:mm)')
            : '',
        sorter: (a, b) => sorterDate(a, b, 'created_at', 'DD/MM/YYYY'),
      },
      {
        title: 'Criado por',
        dataIndex: ['createpes', 'user_nome'],
        align: 'center',
        sorter: (a, b) => sorterString(a, b, 'created_by'),
      },
      {
        title: 'Atualizado em',
        key: 'updated_at',
        dataIndex: 'updated_at',
        align: 'center',
        render: (text, record) =>
          text
            ? moment(text)
                .utc()
                .tz(moment.tz.guess())
                .format('DD/MM/YYYY (HH:mm)')
            : '',
        sorter: (a, b) => sorterDate(a, b, 'created_at', 'DD/MM/YYYY'),
      },
      {
        title: 'Atualizado por',
        dataIndex: ['updatepes', 'user_nome'],
        align: 'center',
        sorter: (a, b) => sorterString(a, b, 'updated_by'),
      },
      {
        title: 'Ações',
        key: 'acoes',
        width: 200,
        fixed: 'right',
        align: 'center',
        render: (text, record) => {
          return (
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {record.ativo ? (
                <a href="javascript:;" aria-label="suspender">
                  <Tooltip title="Suspender Teste">
                    <PauseCircleOutlined
                      onClick={() => handleOpenModalSuspender(record.quiz_id)}
                    />
                  </Tooltip>
                </a>
              ) : (
                <a href="javascript:;" aria-label="reativar">
                  <Popconfirm
                    title="Tem certeza que deseja reativar esse Teste?"
                    onConfirm={() => handleAtivarQuiz(record.quiz_id)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <Tooltip title="Re-ativar">
                      <PlayCircleOutlined />
                    </Tooltip>
                  </Popconfirm>
                </a>
              )}
              <Divider type="vertical" />

              <Tooltip title="Ver Teste">
                <Link to={`/teste-painel/${record.quiz_id}`}>
                  <ProfileOutlined />
                </Link>
              </Tooltip>
            </Row>
          )
        },
      },
    ]
  }, [handleAtivarQuiz, handleOpenModalSuspender])

  return (
    <>
      <Card style={{ marginBottom: '16px' }}>
        <Meta
          title="Módulo de Testes"
          description="Crie testes avaliativos e utilize-os em seus Processos Seletivos. Os testes podem ser enviados pela plataforma ou compartilhos por código, seus resultados são corrigidos e relacionado aos candidatos automaticamente."
        />
      </Card>

      <Card>
        <Table
          title={() => (
            <Row style={{ justifyContent: 'end' }}>
              <Button type="primary" href="">
                <Link to="/recrutamento-selecao/criar-novo-teste">
                  NOVO TESTE
                </Link>
              </Button>
            </Row>
          )}
          loading={loading}
          dataSource={quizes}
          columns={tableQuizColumns}
          rowKey={(record) => record.quiz_id}
        />
      </Card>

      {/* MODAL SUSPENDER QUIZ */}
      <Modal
        title="SUSPENDER TESTE"
        visible={showModalSuspender}
        cancelText="CANCELAR"
        okText="CONFIRMAR SUSPENSÃO"
        onCancel={() => handleCloseModalSuspender()}
        destroyOnClose
        okButtonProps={{
          form: 'suspender-teste',
          key: 'submit',
          htmlType: 'submit',
        }}
        width="60%"
        centered
      >
        <FormSuspenderQuiz
          initialValues={quizSelecionado}
          handleSuspenderQuiz={handleSuspenderQuiz}
        />
      </Modal>
    </>
  )
}

export default Quiz
