import { connect } from 'react-redux'
import * as actions from './actions/actionsCadastro'

// Components
import CadastroController from './CadastroController'

// Actions

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, { ...actions })(CadastroController)
