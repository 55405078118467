import React from 'react'

import { Empty } from 'antd'

function empty({ height = 'auto', ...props }) {
  return (
    <div
      style={{
        height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Empty {...props} />
    </div>
  )
}

export default empty
