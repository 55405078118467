import React from 'react'

import { Form, Input, Select } from 'antd'

import { IFeedbackOption } from '../../../feedback/@types'

const { TextArea } = Input

interface CreatePdiProps {
  onFinish: (data: any) => void
  feedbacksOptions: IFeedbackOption[]
}

export default function CreatePdi({
  onFinish,
  feedbacksOptions,
}: CreatePdiProps) {
  const [form] = Form.useForm()

  return (
    <Form layout="vertical" form={form} onFinish={onFinish} id="new-pdi">
      <Form.Item
        name="objetivo"
        label="Objetivo"
        rules={[{ required: true, message: 'Informe o objetivo do seu PDI' }]}
      >
        <TextArea
          rows={8}
          placeholder="Descreva o objetivo do seu Plano de Desenvolvimento Individual"
        />
      </Form.Item>

      <Form.Item name="feedback_id" label="One-on-One">
        <Select options={feedbacksOptions} allowClear />
      </Form.Item>
    </Form>
  )
}
