import styled from 'styled-components'

import { Button } from 'antd'

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  > button {
    & + button {
      margin-left: 8px;
    }
  }
`

export const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  span + svg {
    margin-left: 8px;
  }
`

export const LinkSpan = styled.span`
  color: var(--purple);
  :hover {
    cursor: pointer;
    border-bottom: 1px solid var(--purple);
  }
`
