// types
export const AVALIACAO_LOAD = 'AVALIACAO_LOAD'
export const QUIZ_LOAD = 'QUIZ_LOAD'
export const COMPANY_LOGO_LOAD = 'COMPANY_LOGO_LOAD'
export const POST_RESPOSTA_AVALIACAO = 'POST_RESPOSTA_AVALIACAO'
export const NOTA_LOAD = 'NOTA_LOAD'

// urls
export const URL_AVALIACAO_LOAD = (hash) =>
  `${process.env.REACT_APP_API_URL}/quiz_avaliacao/check/${hash}`
export const URL_QUIZ_LOAD = (quiz_id) =>
  `${process.env.REACT_APP_API_URL}/listaquiz/${quiz_id}`
export const URL_COMPANY_LOGO_LOAD = (conta_url) =>
  `${process.env.REACT_APP_API_URL}/trabalheConosco/params/conta/${conta_url}`
export const URL_POST_RESPOSTA_AVALIACAO = (avalia_id) =>
  `${process.env.REACT_APP_API_URL}/quiz_resposta/${avalia_id}`
export const URL_NOTA_LOAD = (avalia_id) =>
  `${process.env.REACT_APP_API_URL}/quiz_nota/${avalia_id}`
