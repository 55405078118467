import React, { useState, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { toast } from 'react-toastify'

import _ from 'lodash'

// COMPONENTS
import { Row, Col, Slider } from 'antd'

import { FrownOutlined, SmileOutlined } from '@ant-design/icons'

import Button from '../../../../components/button/Button'

import LegendCard from '../../../../components/legendCard/LegendCard'

import { ReactComponent as Svg } from '../../../../assets/success.svg'

import PesquisasPendentes from '../../../../components/PesquisasPendentes'

// STYLES
import { Container, ValoresOrg, Modal, TutorialContainer } from './styles'

import { getComportamentos, postRespostas, load } from './actions'

// SERVICES
import { getEmpresa } from '../../../../services/auth'

// CONSTANTS
import { tooltips } from './constants'

function ValoresOrganizacionais({ match, history }) {
  const dispatch = useDispatch()

  const [pesquisasPendentes, setPesquisasPendentes] = useState([])

  const [contaUrl, setContaUrl] = useState()

  useEffect(() => {
    const { hash } = match.params
    dispatch(load(hash)).then(({ response }) => {
      if (response.status !== 200) {
        history.push('/')
        return
      }
      const { pesquisasPendentes, conta_url } = response.data

      setPesquisasPendentes(pesquisasPendentes || [])

      setContaUrl(conta_url)

      dispatch(getComportamentos(hash))
    })
  }, [dispatch, history, match])

  const comportamentos = useSelector(
    (state) => state.pesqValores.comportamentos
  )

  const pessoa = useSelector((state) => state.pesqValores.pessoa)

  // const avaliacao = useSelector((state) => state.pesqValores.avaliacao);

  const [respostas, setRespostas] = useState([])

  const [showModal, setShowModal] = useState(false)

  const [showTutorial, setShowTutorial] = useState(true)

  const [showTooltip, setShowTooltip] = useState(true)

  useEffect(() => {
    setRespostas(
      comportamentos.map((c) => ({
        comportamento_id: c.comportamento_id,
        valor: 0,
      }))
    )
  }, [comportamentos])

  const handleChange = (valor, comportamento_id) => {
    const newRespostas = [...respostas]

    newRespostas[
      _.findIndex(newRespostas, ['comportamento_id', comportamento_id])
    ].valor = valor

    setRespostas([...newRespostas])
  }

  const invertValues = (valor) => {
    switch (valor) {
      case 1:
        return 5
      case 1.25:
        return 3.75
      case 3.75:
        return 1.25
      case 5:
        return 0
      case 2.5:
        return 2.5
      default:
        return 5
    }
  }

  const handleSubmit = () => {
    const { hash } = match.params

    // Verifica se a ponderação é negativa e inverte os valores, por ex: 0 -> 5
    respostas.forEach((resposta) =>
      comportamentos.forEach((ponderacao) => {
        if (resposta.comportamento_id === ponderacao.comportamento_id) {
          if (ponderacao.ponderacao < 0) {
            resposta.valor = invertValues(resposta.valor)
          }
        }
      })
    )

    // Faz o envio das respostas
    dispatch(postRespostas({ hash, respostas })).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Respostas gravadas com sucesso.')
        setShowTooltip(false)
        setShowModal(true)
      } else {
        toast.error('Erro ao gravar respostas.')
      }
    })
  }

  const handleInit = () => setShowTutorial(false)
  return (
    <ValoresOrg>
      <div className="valores-org__header">
        <div className="valores-org__header__title">
          <span>
            <b>
              {pessoa.pes_nome
                ? `Olá, ${pessoa.pes_nome.split(' ')[0]}!`
                : 'Olá!'}
            </b>
          </span>
        </div>
        <span className="valores-org__header__subtitle">
          Queremos saber um pouquinho mais sobre você.
        </span>
      </div>

      <Row>
        {showTutorial ? (
          <Col xs={{ offset: 1, span: 22 }} md={{ offset: 2, span: 20 }}>
            <TutorialContainer>
              <p>
                A pesquisa a seguir tem como objetivo saber um pouco mais de
                seus comportamentos no dia-a-dia.
              </p>
              <span className="valores-org__tutorial__hint__label">
                4 dicas para responder
              </span>
              <div className="valores-org__tutorial__hints">
                <span>
                  1) Não existem respostas certas ou erradas, apenas o que tem
                  mais ou menos a ver com você.
                </span>
                <span>2) Separe um tempo exclusivo para respondê-la.</span>
                <span>
                  3) Responda à pesquisa em um ambiente tranquilo e sem
                  interferências.
                </span>
                <span>4) Seja sincero!</span>
              </div>
              <Button
                color="orange"
                type="primary"
                onClick={() => handleInit()}
              >
                CLIQUE AQUI PARA COMEÇAR
              </Button>
            </TutorialContainer>
          </Col>
        ) : (
          <Col xs={{ offset: 1, span: 22 }} md={{ offset: 2, span: 20 }}>
            <Container>
              {comportamentos.length > 0 && (
                // <LegendCard title="Marque o quanto você se identifica com as afirmativas abaixo.">
                <LegendCard title="Marque o quanto você pratica os comportamentos abaixo:">
                  {comportamentos.map((comportamento, index) => (
                    <Row key={index} className="valores-org__content__grade">
                      <Col md={12}>
                        <p>{comportamento.comportamento}</p>
                      </Col>
                      <Col
                        md={12}
                        className="valores-org__content__grade__slider"
                      >
                        <FrownOutlined />
                        <Slider
                          step={1.25}
                          marks={{
                            1: '',
                            2: '',
                            3: '',
                            4: '',
                            5: '',
                          }}
                          min={0}
                          max={5}
                          defaultValue={0}
                          onChange={(value) => {
                            handleChange(value, comportamento.comportamento_id)
                          }}
                          value={
                            (respostas.length > 0 &&
                              respostas[
                                _.findIndex(respostas, [
                                  'comportamento_id',
                                  comportamento.comportamento_id,
                                ])
                              ].valor) ||
                            0
                          }
                          tooltipVisible={showTooltip}
                          tipFormatter={(value) =>
                            value == 0
                              ? tooltips[0]
                              : value === 5
                              ? tooltips[Math.floor(value) - 1]
                              : tooltips[Math.floor(value)]
                          }
                        />
                        <SmileOutlined />
                      </Col>
                    </Row>
                  ))}
                </LegendCard>
              )}
              <div className="valores-org__footer">
                <Button color="orange" type="primary" onClick={handleSubmit}>
                  FINALIZAR
                </Button>
              </div>
            </Container>
          </Col>
        )}
      </Row>
      <Modal
        visible={showModal}
        closable={false}
        footer={null}
        width={860}
        centered
      >
        <Svg className="valores-org__modal__image" />
        <h1>Sua Resposta foi recebida com sucesso!</h1>
        {pesquisasPendentes.length > 0 && (
          <PesquisasPendentes
            pesquisas={pesquisasPendentes}
            conta_url={contaUrl}
          />
        )}

        {pesquisasPendentes.length === 0 && (
          <Button
            color="orange"
            type="primary"
            onClick={() => history.push(`/${contaUrl}`)}
          >
            VOLTAR PARA A HOME
          </Button>
        )}
      </Modal>
    </ValoresOrg>
  )
}

export default ValoresOrganizacionais
