import { Form } from 'antd'
import '@ant-design/compatible/assets/index.css'
import { CaretRightOutlined, PlusOutlined } from '@ant-design/icons'
import { Checkbox, Col, Divider, Row, Select } from 'antd'
import _ from 'lodash'
import { useState } from 'react'
import InputMask from 'react-input-mask'
import { injectIntl } from 'react-intl'
import { Input as InputAntd } from 'antd'
// Components
import Input, { Date, MaskedInput } from '../../../../components/input/Input'
import { Option } from '../../../../components/select/Select'
import { sorterString } from '../../../../utils/utils'
// CSS
import './AdicionarNovoColaborador.css'
import MaskInput from './input/input-mask'

// Constants

const childrenRender = (times, level = 0) => {
  return times.map((time, index) => [
    <Option
      value={time.grupo_id}
      key={`${time.grupo_nome}-${index}-level:${level}`}
    >
      {_.times(level, () => (
        <>&emsp;</>
      ))}
      <CaretRightOutlined style={{ opacity: '0.8', fontSize: '12px' }} />{' '}
      {time.grupo_nome}
    </Option>,
    childrenRender(time.children, level + 1),
  ])
}

function AdicionarNovoColaborador({
  intl,
  handleChangeModal,
  handleChangeCheckbox,
  times,
  cargos,
  filiais,
  openModal,
  error,
  wholeForm = true,
}) {
  const [pessoaComDeficiencia, setPessoaComDeficiencia] = useState(false)

  return (
    <div className="adicionar-colaboradores">
      <Form layout="vertical">
        <Row gutter={20}>
          <Col xs={24} md={24} lg={16}>
            <Form.Item
              label="Nome"
              validateStatus={error.nome ? 'error' : ''}
              help={error.nome || ''}
              required
            >
              <Input
                type="text"
                placeholder="Digite o nome"
                name="nome"
                onChange={handleChangeModal}
                withoutMargin
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={8}>
            <Form.Item
              label="Sexo"
              validateStatus={error.sexo ? 'error' : ''}
              help={error.sexo || ''}
            >
              <Select
                placeholder="Insira o sexo"
                name="sexo"
                defaultValue="M"
                onChange={(value) =>
                  handleChangeModal({
                    target: { name: 'sexo', value },
                  })
                }
                style={{ width: '100%' }}
                withoutMargin
              >
                <Option value="M">Masculino</Option>
                <Option value="F">Feminino</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col xs={24} sm={24} lg={12}>
            <MaskInput
              label="CPF"
              validateStatus={error.cpf ? 'error' : ''}
              help={error.cpf || ''}
              mask="999.999.999-99"
              name="cpf"
              onChange={handleChangeModal}
            />
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              label="Data de Nascimento"
              validateStatus={error.dataNascimento ? 'error' : ''}
              help={error.dataNascimento || ''}
            >
              <Date
                placeholder="Digite o nascimento"
                name="dataNascimento"
                onChange={(date) =>
                  handleChangeModal({
                    target: {
                      name: 'dataNascimento',
                      value: date,
                    },
                  })
                }
                withoutMargin
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col xs={24} sm={24} lg={16}>
            <Form.Item
              label="E-mail"
              validateStatus={error.email ? 'error' : ''}
              help={error.email || ''}
              required
            >
              <Input
                type="text"
                placeholder="Digite o e-mail"
                name="email"
                onChange={handleChangeModal}
                withoutMargin
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} lg={8}>
            <MaskInput
              label={intl.formatMessage({
                id: 'common.telefone',
              })}
              validateStatus={error.telefone ? 'error' : ''}
              help={error.telefone || ''}
              mask="(99) 99999-9999"
              name="telefone"
              onChange={handleChangeModal}
            />
          </Col>
        </Row>

        <Row gutter={20}>
          <Col xs={24} sm={24} lg={8}>
            <Form.Item label="Filial">
              <Select
                placeholder="Selecione a filial"
                name="filial_id"
                showSearch
                options={filiais
                  .filter((filial) => filial.ativo === 1)
                  .map((filial) => {
                    return {
                      label: filial.nome,
                      value: filial.filial_id,
                    }
                  })}
                onChange={(option) =>
                  handleChangeModal({
                    target: { name: 'filial_id', value: option },
                  })
                }
                style={{ width: '100%' }}
                withoutMargin
                filterOption={(input, option) =>
                  option.props.label
                    .toString()
                    .toLowerCase()
                    .includes(input.toString().toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          {wholeForm && (
            <>
              <Col sm={24} xs={24} lg={8}>
                <Form.Item label="Time">
                  <Select
                    placeholder="Selecione o time"
                    name="grupo"
                    onChange={(value) =>
                      handleChangeModal({
                        target: { name: 'grupo', value },
                      })
                    }
                    style={{ width: '100%' }}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{
                            padding: '8px',
                            cursor: 'pointer',
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => openModal(3)}
                        >
                          <PlusOutlined /> Novo Time
                        </div>
                      </div>
                    )}
                    withoutMargin
                  >
                    {childrenRender(times)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={8}>
                <Form.Item label="Cargo">
                  <Select
                    placeholder="Selecione o cargo"
                    name="cargo"
                    onChange={(value) =>
                      handleChangeModal({
                        target: { name: 'cargo', value },
                      })
                    }
                    style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.label
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toString().toLowerCase()) >= 0
                    }
                    filterSort={(a, b) => {
                      return sorterString(a, b, 'label')
                        .toString()
                        .toLowerCase()
                    }}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{
                            padding: '8px',
                            cursor: 'pointer',
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => openModal(4)}
                        >
                          <PlusOutlined /> Novo Cargo
                        </div>
                      </div>
                    )}
                    withoutMargin
                    options={cargos
                      .map((cargo) => {
                        return {
                          value: cargo.cargo_id,
                          label: cargo.cargo_nome,
                        }
                      })
                      .sort((a, b) =>
                        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
                      )}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} lg={8}>
                <Form.Item
                  label="Data de Admissão"
                  validateStatus={error.dataAdmissao ? 'error' : ''}
                  help={error.dataAdmissao || ''}
                >
                  <Date
                    placeholder="Digite a data de admissão"
                    name="dataAdmissao"
                    onChange={(date) =>
                      handleChangeModal({
                        target: {
                          name: 'dataAdmissao',
                          value: date,
                        },
                      })
                    }
                    withoutMargin
                  />
                </Form.Item>
              </Col>
              <Col
                md={14}
                xs={14}
                sm={14}
                lg={14}
                style={{
                  marginTop: 20,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Form.Item>
                  <Checkbox
                    name="pcd"
                    onChange={({ target }) => {
                      if (target.checked === true) {
                        handleChangeModal({
                          target: {
                            name: 'pcd',
                            value: 1,
                          },
                        })
                        handleChangeModal({
                          target: {
                            name: 'pcd_tipo',
                            value: 'Física',
                          },
                        })
                      } else {
                        handleChangeModal({
                          target: {
                            name: 'pcd',
                            value: 0,
                          },
                        })
                        handleChangeModal({
                          target: {
                            name: 'pcd_tipo',
                            value: undefined,
                          },
                        })
                        handleChangeModal({
                          target: {
                            name: 'cid',
                            value: undefined,
                          },
                        })
                      }
                      setPessoaComDeficiencia(target.checked)
                    }}
                    style={{ marginTop: 5 }}
                  >
                    É pessoa com deficiência (PCD)?
                  </Checkbox>
                </Form.Item>
              </Col>

              {pessoaComDeficiencia && (
                <>
                  <Col xs={12} md={12} lg={12}>
                    <Form.Item label="Tipo de PCD">
                      <Select
                        placeholder="Selecione o tipo"
                        name="pcd_tipo"
                        onChange={(value) => {
                          if (pessoaComDeficiencia === true)
                            handleChangeModal({
                              target: { name: 'pcd_tipo', value },
                            })
                          else
                            handleChangeModal({
                              target: {
                                name: 'pcd_tipo' && 'cid',
                                value: undefined,
                              },
                            })
                        }}
                        style={{ width: '100%' }}
                        defaultValue="Física"
                      >
                        <Option value="Física">Física</Option>
                        <Option value="Auditiva">Auditiva</Option>
                        <Option value="Visual">Visual</Option>
                        <Option value="Mental">Mental</Option>
                        <Option value="Múltipla">Múltipla</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <Form.Item label="CID">
                      <Input
                        type="text"
                        placeholder="Digite o CID"
                        name="cid"
                        onChange={handleChangeModal}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </>
          )}
        </Row>

        {wholeForm && (
          <>
            <Row>
              <Checkbox
                name="vetorComportamental"
                onChange={handleChangeCheckbox}
              >
                Gerar pesquisa de Perfil Comportamental
              </Checkbox>
            </Row>
            <Row>
              <Checkbox
                name="percepcaoAmbiente"
                onChange={handleChangeCheckbox}
              >
                Gerar pesquisa de Percepção do Ambiente
              </Checkbox>
            </Row>
            <Row>
              <Checkbox
                name="valoresOrganizacionais"
                onChange={handleChangeCheckbox}
              >
                Gerar pesquisa de Valores Organizacionais
              </Checkbox>
            </Row>
            <Divider />
            <Row>
              <Checkbox name="enviarEmails" onChange={handleChangeCheckbox}>
                Enviar e-mails
              </Checkbox>
            </Row>
          </>
        )}
      </Form>
    </div>
  )
}

export default injectIntl(AdicionarNovoColaborador)
