import {
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Button,
  Divider,
  Empty,
  Popconfirm,
  TableColumnProps,
  Tooltip,
} from 'antd'
import moment from 'moment'
import React, { useMemo } from 'react'
// Utils
import { sorterDate, sorterString } from '../../../../utils/utils'
// Types
import {
  IAcao,
  IDeleteAcaoFn,
  IFinishAcaoFn,
  IPdi,
  IUpdateAcao,
  IUpdateAcaoFn,
} from '../../@types'
// Components
import { ButtonsContainer, Table, TableContainer } from './styles'

interface IExtraColumnProps extends TableColumnProps<any> {
  editable?: boolean
  inputType?: string
}

interface IProps {
  pdi?: IPdi
  isPdiFinished: boolean
  getColumnSearchProps(dataIndex: string): Record<string, unknown>
  handleDeleteAcao(data: IDeleteAcaoFn): Promise<void>
  handleFinishAcao(data: IFinishAcaoFn): Promise<void>
  toggleModalNewAcao(): void
  handleUpdateAcao(data: IUpdateAcaoFn): void
  data: IAcao[]
  loading?: boolean
}

const AcoesTable: React.FC<IProps> = ({
  data,
  pdi,
  getColumnSearchProps,
  handleDeleteAcao,
  handleFinishAcao,
  toggleModalNewAcao,
  handleUpdateAcao,
  loading = false,
  isPdiFinished,
}) => {
  const columns: IExtraColumnProps[] = useMemo(() => {
    if (!pdi) return []

    const acoesColumns: IExtraColumnProps[] = [
      {
        title: 'Ação',
        dataIndex: 'descricao',
        editable: true,
        align: 'center',
        sorter: (a: any, b: any) => sorterString(a, b, 'descricao'),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('descricao'),
      },
      {
        title: 'Início',
        dataIndex: 'start',
        editable: true,
        align: 'center',
        inputType: 'date',
        sorter: (a: any, b: any) =>
          sorterDate(a, b, 'inicio', 'DD/MM/YYYY (HH:mm)'),
        sortDirections: ['descend', 'ascend'],
        render: (text: string) =>
          text ? moment(text).format('DD/MM/YYYY') : '',
        ...getColumnSearchProps('start'),
      },
      {
        title: 'Fim',
        dataIndex: 'end',
        editable: true,
        inputType: 'date',
        align: 'center',
        sorter: (a: any, b: any) =>
          sorterDate(a, b, 'fim', 'DD/MM/YYYY (HH:mm)'),
        sortDirections: ['descend', 'ascend'],
        render: (text: string) =>
          text ? moment(text).format('DD/MM/YYYY') : '',
        ...getColumnSearchProps('end'),
      },
      {
        title: 'Sabotadores',
        editable: true,
        align: 'center',
        dataIndex: 'sabotadores',
        sorter: (a: any, b: any) => sorterString(a, b, 'sabotadores'),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('sabotadores'),
      },
      {
        title: 'Opções',
        editable: true,
        align: 'center',
        dataIndex: 'opcoes',
        sorter: (a: any, b: any) => sorterString(a, b, 'opcoes'),
        sortDirections: ['descend', 'ascend'],
        ...getColumnSearchProps('opcoes'),
      },
    ]

    if (!isPdiFinished) {
      acoesColumns.push({
        title: 'Ações',
        key: 'extra_actions',
        className: 'column-acoes',
        width: '25%',
        render: (_: any, record: any) => {
          return (
            <span>
              {!record.completed_at && (
                <>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="Tem certeza que deseja concluir essa ação?"
                    onConfirm={() =>
                      handleFinishAcao({
                        pdi_id: pdi.pdi_id,
                        acao_id: record.acao_id,
                      })
                    }
                    okText="Sim"
                    cancelText="Não"
                    icon={
                      <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                    }
                  >
                    <Tooltip title="Concluir">
                      <a>
                        <CheckOutlined />
                      </a>
                    </Tooltip>
                  </Popconfirm>
                  <Divider type="vertical" />
                </>
              )}

              <Popconfirm
                title="Tem certeza que deseja excluir essa ação?"
                onConfirm={() =>
                  handleDeleteAcao({
                    pdi_id: pdi.pdi_id,
                    acao_id: record.acao_id,
                  })
                }
                okText="Sim"
                cancelText="Não"
                icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
              >
                <Tooltip title="Excluir">
                  <a>
                    <DeleteOutlined />
                  </a>
                </Tooltip>
              </Popconfirm>
            </span>
          )
        },
      })
    }

    return acoesColumns
  }, [
    getColumnSearchProps,
    handleDeleteAcao,
    handleFinishAcao,
    isPdiFinished,
    pdi,
  ])

  if (!pdi) {
    return <Empty />
  }

  return (
    <TableContainer>
      <Table
        loading={loading}
        header={() =>
          !isPdiFinished && (
            <ButtonsContainer>
              <Button
                type="primary"
                onClick={() => toggleModalNewAcao()}
                icon={<PlusOutlined />}
              >
                NOVA AÇÃO
              </Button>
            </ButtonsContainer>
          )
        }
        dataSource={data.map((acao) => ({
          ...acao,
          start: moment(acao.start) || undefined,
          end: moment(acao.end) || undefined,
          key: acao.acao_id,
        }))}
        columns={columns}
        isEditable={!isPdiFinished}
        disableEditing={{ prop: 'isFinished', falsy: true }}
        onSave={(data: IUpdateAcao) => {
          handleUpdateAcao({
            pdi_id: pdi.pdi_id,
            ...data,
          })
        }}
      />
    </TableContainer>
  )
}

export default AcoesTable
