import React from 'react'
import { injectIntl } from 'react-intl'
import { Row, Col, Card, Alert, Divider, Statistic, Tabs, Button } from 'antd'
import { UserOutlined, AimOutlined, SaveOutlined } from '@ant-design/icons'

import Atividades from './containers/Atividades'
import Requisitos from './containers/Requisitos'
import Ferramentas from './containers/Ferramenta'
import Tecnicas from './containers/Tecnicas'
// Layouts
import ContentLayout from '../../layouts/contentLayout/ContentLayout'

// Components
import TableCargosDetalhes from './components/tableCargosDetalhes/TableCargosDetalhes'

// CSS
import './CargosDetalhes.css'
import CulturaDesejadaForm from '../../components/CulturaDesejadaForm'

const { TabPane } = Tabs

function CargosDetalhesView({
  intl,
  getColumnSearchProps,
  cargo,
  dataSource,
  onTableDelete,
  handleSaveCulturaDesejadaCargo,
  culturaDesejadaCargo,
  culturaDesejadaEmpresa,
  culturaInstaladaCargo,
  match,
}) {
  const { id, nome, cbo, descricao, piso, qtde } = cargo

  return (
    <ContentLayout
      className="cargos-detalhes"
      header={{
        title: nome,
        subtitle: descricao,
        breadcrumb: [
          { name: 'Home', path: '/' },
          { name: 'Cargos', path: '/cargos' },
          { name: nome },
        ],
        renderRight: (
          <div className="cargos-detalhes-statistic">
            <div className="cargos-detalhes-statistic__item">
              <Statistic
                title="Piso salarial"
                value={piso || ''}
                groupSeparator="."
              />
            </div>
            <Divider
              className="cargos-detalhes-statistic__divider"
              type="vertical"
            />
            <div className="cargos-detalhes-statistic__item">
              <Statistic title="CBO" value={cbo || ''} groupSeparator="" />
            </div>
            <Divider
              className="cargos-detalhes-statistic__divider"
              type="vertical"
            />
            <div className="cargos-detalhes-statistic__item">
              <Statistic
                title="Membros"
                value={(dataSource && dataSource.length) || 0}
              />
            </div>
          </div>
        ),
      }}
    >
      <Tabs defaultActiveKey="1">
        <TabPane
          key="1"
          tab={
            <>
              <UserOutlined />
              <span>Membros</span>
            </>
          }
        >
          <div className="cargos-detalhes-body">
            <Row>
              <Col xs={24}>
                <Card
                  type="inner"
                  title={<span>Colaboradores com este cargo</span>}
                >
                  <TableCargosDetalhes
                    dataSource={dataSource}
                    getColumnSearchProps={getColumnSearchProps}
                    onTableDelete={onTableDelete}
                  />
                  <div className="cargos-detalhes-body__footer">
                    <Alert
                      message="Para alterar o Cargo do colaborador, clique sobre o colaborador e faça a alteração na tela de colaboradores :)"
                      type="info"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </TabPane>
        {/* <TabPane
          key="2"
          tab={
            <>
              <AimOutlined />
              <span>Fit Cultural</span>
            </>
          }
        >
          <div className="cargos-detalhes-body">
            <Row>
              <Col xs={24}>
                <Card
                  type="inner"
                  title={<span>Cultura desejada</span>}
                  extra={
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                      form="cultura-desejada-form"
                    >
                      SALVAR
                    </Button>
                  }
                >
                  <CulturaDesejadaForm
                    onFinish={handleSaveCulturaDesejadaCargo}
                    initialValues={culturaDesejadaCargo}
                    label="Cargo"
                    culturaDesejadaEmpresa={culturaDesejadaEmpresa}
                    culturaInstalada={culturaInstaladaCargo}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </TabPane> */}
        <TabPane tab="Atividades" key="2">
          <Atividades id={id} />
        </TabPane>
        <TabPane tab="Requisitos de Formação" key="3">
          <Requisitos id={id} />
        </TabPane>
        <TabPane tab="Ferramentas" key="4">
          <Ferramentas id={id} />
        </TabPane>
        <TabPane tab="Técnicas" key="5">
          <Tecnicas id={id} />
        </TabPane>
      </Tabs>
    </ContentLayout>
  )
}

export default injectIntl(CargosDetalhesView)
