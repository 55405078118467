export const PS_TIPOCONTRATO_LIST = 'PS_TIPOCONTRATO_LIST'
export const PS_TIPOCONTRATO_STORE = 'PS_TIPOCONTRATO_STORE'
export const PS_TIPOCONTRATO_UPDATE = 'PS_TIPOCONTRATO_UPDATE'
export const PS_TIPOCONTRATO_DELETE = 'PS_TIPOCONTRATO_DELETE'
export const PS_TIPOCONTRATO_LOAD = 'PS_TIPOCONTRATO_LOAD'

// urls
export const URL_PS_TIPOCONTRATO = `${process.env.REACT_APP_API_URL}/psContrato`
export const URL_PS_TIPOCONTRATO_ID = (id) =>
  `${process.env.REACT_APP_API_URL}/psContrato/${id}`
