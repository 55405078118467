// TYPES
export const ANALISE_GERAL_TIMES_GET_CULTURA = 'ANALISE_GERAL_TIMES_GET_CULTURA'
export const ANALISE_GERAL_TIMES_GET_CULTURA_ORGANIZACIONAL =
  'ANALISE_GERAL_TIMES_GET_CULTURA_ORGANIZACIONAL'
export const ANALISE_GERAL_TIMES_GET_TIMES = 'ANALISE_GERAL_TIMES_GET_TIMES'
export const ANALISE_GERAL_TIMES_LOADING = 'ANALISE_GERAL_TIMES_LOADING'
export const GET_DASHBOARD_NPS = 'GET_DASHBOARD_NPS'

// URLS
export const URL_ANALISE_GERAL_TIMES_GET_CULTURA = (id) =>
  `${process.env.REACT_APP_API_URL}/dashboard/grupo/cultura/${id}`

export const URL_ANALISE_GERAL_TIMES_GET_CULTURA_ORGANIZACIONAL = (id) =>
  `${process.env.REACT_APP_API_URL}/dashboard/grupo/culturaOrganizacional/${id}`

export const URL_ANALISE_GERAL_TIMES_GET_TIMES = `${process.env.REACT_APP_API_URL}/dashboard/grupos`
