// URLs
export const URL_LOGIN = `${process.env.REACT_APP_API_URL}/login`
export const URL_CONFIRMAR_CONTA = `${process.env.REACT_APP_API_URL}/confirmar_cadastro`
export const URL_INFO_GET = `${process.env.REACT_APP_API_URL}/auth/check`
export const URL_POST_LOG_ACESSO = `${process.env.REACT_APP_API_URL}/logacesso`

// Redux types
export const LOGIN_POST = 'LOGIN_POST'
export const SALVAR_ONBOARDING = 'SALVAR_ONBOARDING'
export const CONFIRMAR_CONTA_POST = 'CONFIRMAR_CONTA_POST'
export const INFO_GET = 'INFO_GET'
export const POST_LOG_ACESSO = 'POST_LOG_ACESSO'
