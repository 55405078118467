import React from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import 'moment-timezone'
import {
  Modal,
  Row,
  Col,
  Card,
  Alert,
  Popconfirm,
  Divider,
  Table,
  Progress,
} from 'antd'
import {
  UserAddOutlined,
  TwitterCircleFilled,
  LinkedinFilled,
  LinkOutlined,
} from '@ant-design/icons'

import { sorterString, sorterDate, styleColors } from '../../utils/utils'
import { logout } from '../../services/auth'
import Link from '../../components/privateLink/PrivateLink'

// Components
import Button from '../../components/button/Button'
import Confirm from '../../components/Confirm'

// CSS
import './Candidatos.css'
import { CandidatoCell } from './styles'

// Constants
import { modals, apiToFront } from './constants/constantsCandidatos'

// Utils

function CandidatosView({
  intl,
  showModals,
  handleChangeModal,
  onTableSave,
  onTableDelete,
  dataSource,
  tableCount,
  rowSelection,
  openModal,
  closeModal,
  onModalSubmit,
  onRemoveFile,
  modalStates,
  handleEfetivar,
  getColumnSearchProps,
  handleChangeCheckbox,
  cargos,
  error,
  novoCandidatoCurrentStep,
  handleNextStepAddCandidato,
  handlePrevStepAddCandidato,
  handleCheckCEP,
  handleSelectCurriculo,
  fileList,
  handleSelectCargos,
  handleRemoveCurriculo,
  fetchingCpf,
  loadingCandidatos,
  processos,
}) {
  const columns = [
    {
      title: 'Candidato',
      dataIndex: 'pes_nome',
      editable: true,
      width: '20%',
      align: 'center',
      ...getColumnSearchProps('pes_nome'),
      render: (text, record) => (
        <CandidatoCell>
          <Link to={`/perfilCandidato/${record.key}`}>
            <span className="candidatoNome">{text}</span>
          </Link>
          <span className="candidatoProfissao">{record.profissao}</span>
          <span className="candidatoCidade">{record.ufcidade}</span>
        </CandidatoCell>
      ),
    },
    {
      title: 'Idade',
      dataIndex: 'idade',
      editable: true,
      width: '5%',
      align: 'center',
      sorter: (a, b) => sorterString(a, b, 'nome'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('idade'),
    },
    {
      title: 'Cadastro',
      dataIndex: 'cadastro',
      editable: true,
      width: '10%',
      align: 'center',
      sorter: (a, b) => sorterDate(a, b, 'inicio', 'DD/MM/YYYY (HH:mm)'),
      sortDirections: ['descend', 'ascend'],
      render: (text) =>
        text
          ? moment(text)
              .utc()
              .tz(moment.tz.guess())
              .format('DD/MM/YYYY (HH:mm)')
          : '',
      ...getColumnSearchProps('cadastro'),
    },
    {
      title: 'Cidade',
      dataIndex: 'cidade',
      editable: true,
      width: '5%',
      align: 'center',
      sorter: (a, b) => sorterString(a, b, 'cidade'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('cidade'),
      render: (text, record) => {
        return (
          <>
            {record.uf ? (
              <span>
                {record.cidade}-{record.uf}
              </span>
            ) : null}
          </>
        )
      },
    },
    {
      title: 'Pretensão Salarial',
      dataIndex: 'prtsalario',
      editable: true,
      width: '5%',
      align: 'center',
      ...getColumnSearchProps('prtsalario'),
      render: (text, record) => (text ? `R$ ${text}` : ''),
    },
    {
      title: 'Currículo',
      dataIndex: 'curriculo_url',
      editable: true,
      width: '5%',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            {text && (
              <LinkOutlined onClick={() => window.open(text, '_blank')} />
            )}
          </>
        )
      },
    },
    {
      title: 'PCD',
      filters: [
        {
          text: 'Sim',
          value: 1,
        },
        {
          text: 'Não',
          value: 0,
        },
      ],
      onFilter: (value, record) => {
        return record.pcd === value
      },
      render: (text, record) => {
        return (
          <>
            {record && record.pcd === 1 ? <span>Sim</span> : <span>Não</span>}
          </>
        )
      },
    },
    {
      title: 'Função Pretendida',
      dataIndex: 'cargos',
      editable: true,
      width: '20%',
      align: 'center',
      className: 'column-cargo',
      sorter: (a, b) => sorterString(a, b, 'cargos'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('cargos'),
    },
    {
      title: 'Indicação',
      dataIndex: 'indicacao',
      editable: true,
      width: '10%',
      align: 'center',
      ...getColumnSearchProps('indicacao'),
    },
    {
      title: 'Escolaridade',
      filters: [
        {
          text: 'Ensino Fundamental Incompleto',
          value: 'ens_fund_inc',
        },
        {
          text: 'Ensino Fundamental Completo',
          value: 'ens_fund_com',
        },
        {
          text: 'Ensino Médio Incompleto',
          value: 'ens_medio_inc',
        },
        {
          text: 'Ensino Médio Completo',
          value: 'ens_medio_com',
        },
        {
          text: 'Ensino Superior Incompleto',
          value: 'ens_sup_inc',
        },
        {
          text: 'Ensino Superior Completo',
          value: 'ens_sup_com',
        },
      ],
      onFilter: (value, record) => {
        return record.escolaridade && record.escolaridade.indexOf(value) === 0
      },
      render: (text, record) => {
        return (
          <>
            {text && text.escolaridade === 'ens_fund_inc' ? (
              <span>Ensino Fundamental Incompleto</span>
            ) : text.escolaridade === 'ens_fund_com' ? (
              <span>Ensino Fundamental Completo</span>
            ) : text.escolaridade === 'ens_medio_inc' ? (
              <span>Ensino Médio Incompleto</span>
            ) : text.escolaridade === 'ens_medio_com' ? (
              <span>Ensino Médio Completo</span>
            ) : text.escolaridade === 'ens_sup_inc' ? (
              <span>Ensino Superior Incompleto</span>
            ) : text.escolaridade === 'ens_sup_com' ? (
              <span>Ensino Superior Completo</span>
            ) : null}
          </>
        )
      },
    },
    {
      title: 'Social',
      dataIndex: 'site',
      editable: true,
      width: '10%',
      className: 'column-social',
      render: (text, record) => (
        <div>
          {record.twitter && (
            <TwitterCircleFilled
              onClick={() =>
                window.open(
                  record.twitter.startsWith('http')
                    ? record.twitter
                    : `https://twitter.com/${record.twitter}`,
                  '_blank'
                )
              }
            />
          )}
          {record.linkedin && (
            <LinkedinFilled
              onClick={() =>
                window.open(
                  record.linkedin.startsWith('http')
                    ? record.linkedin
                    : `https://linkedin.com/in/${record.linkedin}`,
                  '_blank'
                )
              }
            />
          )}
          {record.site && (
            <LinkOutlined onClick={() => window.open(record.site, '_blank')} />
          )}
        </div>
      ),
    },
    {
      title: 'Perfil',
      dataIndex: 'perfil',
      editable: true,
      width: '5%',
      align: 'center',
      ...getColumnSearchProps('perfil'),
    },
    {
      title: 'Fit Cultural',
      dataIndex: ['fitcultural'],
      editable: true,
      width: '10%',
      align: 'center',
      ...getColumnSearchProps('fitcultural'),
      render: (text, record) => (
        <>
          {text && (
            <Progress
              type="circle"
              width={72}
              strokeColor={{
                '0%': styleColors.pink,
                '100%': styleColors.pink,
              }}
              percent={parseFloat(text)}
            />
          )}
        </>
      ),
    },
    /*
		{
			title: 'Ações',
			key: 'acoes',
			className: 'column-acoes',
			width: '25%',
			render: (text, record) =>
				dataSource.length >= 1 ? (
					<span>
						<Popconfirm
							title='Tem certeza que deseja efetivar?'
							onConfirm={() => handleEfetivar(record.key)}
							okText='Sim'
							cancelText='Não'
						>
							<a>Efetivar</a>
						</Popconfirm>
						<Divider type='vertical' />
						<Popconfirm
							title='Tem certeza que deseja excluir?'
							onConfirm={() => onTableDelete(record.key)}
							okText='Sim'
							cancelText='Não'
						>
							<a>Excluir</a>
						</Popconfirm>
					</span>
				) : null,
		},
		*/
  ]

  return (
    <div className="candidatos">
      <Row>
        <Col md={24}>
          <Card type="inner">
            <div className="candidatos__footer">
              <Alert
                message="Selecione um dos candidatos para enviar o teste de Perfil comportamental."
                type="info"
              />
            </div>
            <div className="candidatos__table">
              <Table
                loading={loadingCandidatos}
                title={() => (
                  <div className="candidatos__table__buttons">
                    <Button
                      className="btn_purple btn_gerar_pesquisa"
                      onClick={() => openModal(0)}
                    >
                      GERAR PESQUISA
                    </Button>
                    <Button
                      className="btn_purple"
                      type="primary"
                      onClick={() => openModal(3)}
                    >
                      TRIAR CANDIDATOS
                    </Button>
                    <Button
                      type="primary"
                      className="btn_purple"
                      onClick={() => openModal(1)}
                      icon={<UserAddOutlined />}
                    >
                      NOVO
                    </Button>
                  </div>
                )}
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} de ${total} registros`,
                }}
                dataSource={dataSource}
                count={tableCount}
                columns={columns}
                rowSelection={rowSelection}
                className="candidatos__table"
                isDeleble
                bordered
                locale={{ emptyText: 'Nenhum registro' }}
              />
            </div>
          </Card>
        </Col>
      </Row>
      {modals.map(
        ({
          title,
          cancelText,
          okText: okTextRaw,
          id,
          isCandidato = false,
          width,
          children: Children,
          steps = null,
        }) => {
          const okText =
            id === 1 ? okTextRaw(novoCandidatoCurrentStep) : okTextRaw

          const onOk = () => {
            if (id === 1) {
              if (novoCandidatoCurrentStep === 0) {
                return handleNextStepAddCandidato()
              }
              return onModalSubmit(id)
            }

            return onModalSubmit(id)
          }

          return (
            <Modal
              visible={showModals[id]}
              title={title}
              cancelText={cancelText}
              okText={okText}
              onCancel={() => closeModal(id)}
              onOk={onOk}
              width={width}
              destroyOnClose
              key={id}
              {...(id === 1
                ? {
                    footer: (
                      <div className="candidatos__addmodal__footer">
                        <Button
                          type="primary"
                          danger
                          onClick={() => closeModal(id)}
                          className="candidatos__button__cancel"
                        >
                          CANCELAR
                        </Button>
                        {novoCandidatoCurrentStep > 0 && (
                          <Button onClick={handlePrevStepAddCandidato}>
                            VOLTAR
                          </Button>
                        )}
                        {novoCandidatoCurrentStep + 1 < steps ? (
                          <Button
                            type="primary"
                            onClick={handleNextStepAddCandidato}
                          >
                            PRÓXIMO
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() => onModalSubmit(id)}
                          >
                            SALVAR
                          </Button>
                        )}
                      </div>
                    ),
                  }
                : null)}
            >
              <Children
                isCandidato={isCandidato}
                handleChangeModal={(event) => handleChangeModal(id, event)}
                onRemoveFile={onRemoveFile}
                handleChangeCheckbox={(event) =>
                  handleChangeCheckbox(id, event)
                }
                cargos={cargos}
                {...modalStates[id]}
                error={error}
                {...(id === 1
                  ? {
                      currentStep: novoCandidatoCurrentStep,
                      handleCheckCEP,
                      handleSelectCurriculo,
                      fileList,
                      handleSelectCargos,
                      handleRemoveCurriculo,
                      fetchingCpf,
                    }
                  : {})}
                {...(id === 3 ? { processos } : {})}
                id={id}
              />
            </Modal>
          )
        }
      )}
    </div>
  )
}

export default injectIntl(CandidatosView)
