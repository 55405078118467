import React from 'react'

import { Form, Input, Col } from 'antd'

const { TextArea } = Input

function FormSuspenderQuiz({ initialValues, handleSuspenderQuiz }) {
  const [formSuspenderTeste] = Form.useForm()

  return (
    <Form
      layout="vertical"
      id="suspender-teste"
      form={formSuspenderTeste}
      initialValues={{ ...initialValues }}
      onFinish={(values) =>
        handleSuspenderQuiz(initialValues.quiz_id, {
          titulo: initialValues.titulo,
          instrucoes: initialValues.instrucoes,
          objetivo: initialValues.objetivo,
          msg_encerramento: initialValues.msg_encerramento,
          tempo_limite: initialValues.tempo_limite,
          nota_min: initialValues.nota_min,
          ativo: 0,
          motivo_suspensao: values.motivo_suspensao,
        })
      }
    >
      <Col span={24}>
        <Form.Item
          label="Motivo de suspensão"
          name="motivo_suspensao"
          rules={[
            {
              required: true,
              message: 'Por favor, insira um motivo válido para a suspensão',
              pattern: new RegExp(/^(?!\s+$).*/),
            },
          ]}
        >
          <TextArea
            placeholder="Insira o motivo para a suspensão desse teste"
            maxLength={250}
            showCount
          />
        </Form.Item>
      </Col>
    </Form>
  )
}

export default FormSuspenderQuiz
