import * as constants from '../constants/vetorIndividualShareConstants'

export const load = (hash) => {
  return {
    type: constants.LOAD_VETOR_INDIVIDUAL_SHARE,
    api: {
      method: 'get',
      url: constants.URL_LOAD_VETOR_INDIVIDUAL_SHARE(hash),
    },
  }
}
