import React from 'react'
import moment from 'moment'

// URLs
export const URL_COLABORADORES = `${process.env.REACT_APP_API_URL}/pessoas`
export const URL_COLABORADORES_GET = `${URL_COLABORADORES}/list?inativos=true`
export const URL_COLABORADOR_POST = `${URL_COLABORADORES}/store`
export const URL_COLABORADORES_POST = `${URL_COLABORADORES}/storePessoas`
export const URL_COLABORADOR_PUT = `${URL_COLABORADORES}/update`
export const URL_COLABORADOR_DELETE = `${URL_COLABORADORES}/delete`
export const URL_COLABORADORES_SEND_AVALIACOES = `${process.env.REACT_APP_API_URL}/avaliacoes/send`
export const URL_SEND_PERCEPCAO = `${process.env.REACT_APP_API_URL}/pesquisa`

// Redux types
export const COLABORADORES_GET = 'COLABORADORES_GET'
export const COLABORADOR_POST = 'COLABORADOR_POST'
export const COLABORADORES_POST = 'COLABORADORES_POST'
export const COLABORADOR_DELETE = 'COLABORADOR_DELETE'
export const COLABORADOR_PUT = 'COLABORADOR_PUT'
export const COLABORADORES_SEND_AVALIACOES = 'COLABORADORES_SEND_AVALIACOES'
export const SEND_PERCEPCAO = 'SEND_PERCEPCAO'

// Data processing
export const apiToFront = ({ pessoa }) => ({
  cpf: pessoa.pes_cpf || '',
  nome: pessoa.pes_nome || '',
  email: pessoa.pes_email || '',
  cargo: pessoa.cargos || '',
  key: pessoa.pes_id,
  times: pessoa.times || '',
  nome_filial: pessoa.nome_filial || '',
  ultimofeedback: pessoa.ultimofeedback
    ? moment(pessoa.ultimofeedback).format('DD/MM/YYYY')
    : '',
  pes_datanascimento: pessoa.pes_datanascimento
    ? moment(pessoa.pes_datanascimento).format('DD/MM/YYYY')
    : '',
  dataadmissao: pessoa.dataadmissao
    ? moment(pessoa.dataadmissao).format('DD/MM/YYYY')
    : '',
  datademissao: pessoa.datademissao
    ? moment(pessoa.datademissao).format('DD/MM/YYYY (HH:mm)')
    : '',
  pct_metas: pessoa.pct_metas ? `${pessoa.pct_metas}%` : '',
})
