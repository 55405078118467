import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'

// Middlewares
import { logger, authenticatedRequests } from './middlewares'

// Reducers
import { login } from '../containers/login/reducers/reducersLogin'
import { cadastro } from '../containers/cadastro/reducers/reducersCadastro'
// import { configuracoes } from '../containers/configuracoes/reducers/reducersConfiguracoes';
import { configuracoes } from '../containers/configuracoes/reducers'
import { objetivosEstrategicos } from '../containers/objetivosEstrategicos/reducers/reducersObjetivosEstrategicos'
import { colaboradores } from '../containers/cadastroColaboradores/reducers/reducersCadastroColaboradores'
import { times } from '../containers/times/reducers/reducersTimes'
import { cargos } from '../containers/cargos/reducers/reducersCargos'
import { cargosDetalhes } from '../containers/cargosDetalhes/reducers/reducersCargosDetalhes'
import { timesDetalhes } from '../containers/timesDetalhes/reducers/reducersTimesDetalhes'
import { perfil } from '../containers/perfil/reducers/reducersPerfil'
import { perfilCandidato } from '../containers/perfilCandidato/reducers/reducersPerfilCandidato'
import { pesquisas } from '../containers/pesquisas/reducers/reducersPesquisas'
import { gestaoPesquisasColaboradores } from '../containers/gestaoPesquisasColaboradores/reducers/reducersGestaoPesquisas'
import { gestaoPesquisasCandidatos } from '../containers/gestaoPesquisasCandidatos/reducers/reducersGestaoPesquisas'
// import { dashboard } from '../containers/dashboard/reducers/reducersDashboard';
//import { feedback } from '../containers/feedback/reducers/reducersFeedback';
import { pdt } from '../containers/pdt/reducers/reducersPdt'
import { olhar360Geral } from '../containers/relatoriosGeral/olhar360/reducers/olhar360GeralReducers'
import { analiseCulturaGeral } from '../containers/relatoriosGeral/analiseCultura/reducers/analiseCulturaGeralReducers'
import { perfilComportamentalGeral } from '../containers/relatoriosGeral/perfilComportamental/reducers/perfilComportamentalReducers'
import { relatoriosTimes } from '../containers/relatoriosTimes/reducers'
import { vetorIndividualShare } from '../containers/VetorIndividualShare/reducers/vetorIndividualShareReducers'
import analiseGeral from '../containers/analiseGeral/reducers/analiseGeralReducers'
import analiseGeralTimes from '../containers/analiseGeralTimes/reducers/analiseGeralTimesReducers'
import pesqValores from '../containers/pesquisas/containers/valoresOrganizacionais/reducers'
import psMotivoCancelamento from '../containers/recrutamentoSelecao/containers/MotivoCancelamento/reducers/reducers'
import { processoSeletivo } from '../containers/recrutamentoSelecao/containers/ProcessoSeletivo/reducers/reducersProcessoSeletivo'
import { quiz } from '../containers/recrutamentoSelecao/containers/Quiz/reducers/reducersQuiz'
import formularioQuiz from '../containers/recrutamentoSelecao/containers/Quiz/FormularioQuiz/reducers/reducersFormularioQuiz'
import psKanban from '../containers/recrutamentoSelecao/containers/PsKanban/reducers/psKanbanReducers'
import psPainel from '../containers/recrutamentoSelecao/containers/PsPainel/reducers/psPainelReducers'
import quizPainel from '../containers/recrutamentoSelecao/containers/Quiz/PainelQuiz/reducers/quizPainelReducers'
import { usuarios } from '../containers/Usuarios/reducers'

import { DESTROY_REDUX } from '../constants/global'

const appReducer = combineReducers({
  login,
  cadastro,
  configuracoes,
  objetivosEstrategicos,
  colaboradores,
  times,
  cargos,
  cargosDetalhes,
  timesDetalhes,
  perfil,
  perfilCandidato,
  pesquisas,
  gestaoPesquisasColaboradores,
  gestaoPesquisasCandidatos,
  // dashboard,
  //feedback,
  pdt,
  olhar360Geral,
  analiseCulturaGeral,
  perfilComportamentalGeral,
  relatoriosTimes,
  vetorIndividualShare,
  analiseGeral,
  analiseGeralTimes,
  pesqValores,
  psMotivoCancelamento,
  processoSeletivo,
  psKanban,
  psPainel,
  usuarios,
  quiz,
  quizPainel,
  formularioQuiz,
})

const rootReducer = (state, action) => {
  if (action.type === DESTROY_REDUX) {
    state = undefined
  }
  return appReducer(state, action)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(reduxThunk, authenticatedRequests, logger))
)
