import React from 'react'

import { Select as SelectAntd, SelectProps } from 'antd'

// CSS
import './Select.css'

interface ISelect extends SelectProps<string> {
  label?: string
  withoutMargin?: boolean
  readOnly?: boolean
}

const Select: React.FC<ISelect> = ({
  children,
  label,
  withoutMargin = false,
  readOnly = false,
  ...props
}) => {
  return (
    <div className={`select ${withoutMargin ? 'remove-margin' : ''}`}>
      {label && <span className="select__label">{label}</span>}
      <SelectAntd disabled={readOnly} {...props}>
        {children}
      </SelectAntd>
    </div>
  )
}

export const { Option } = SelectAntd

export default Select
