// URLs
export const URL_OBJETIVOS_ESTRATEGICOS_DASHBOARD = `${process.env.REACT_APP_API_URL}/dashboard`
export const URL_OBJETIVOS_ESTRATEGICOS = `${process.env.REACT_APP_API_URL}/objetivosEstrategicos`
export const URL_GESTORES = `${URL_OBJETIVOS_ESTRATEGICOS}/gestor`
export const URL_GESTORES_GET = `${URL_GESTORES}`
export const URL_GESTOR_POST = (id = null) =>
  id ? `${URL_GESTORES}/${id}` : `${URL_GESTORES}`
export const URL_GESTOR_DELETE = `${URL_GESTORES}`
export const URL_GESTOR_UPDATE = (pes_id) => `${URL_GESTORES}/${pes_id}`
export const URL_PESOS_GET = `${URL_OBJETIVOS_ESTRATEGICOS}/pesos`
export const URL_ENVIAR_EMAILS_POST = `${URL_OBJETIVOS_ESTRATEGICOS}/send`
export const URL_CULTURA_DESEJADA_GET = `${URL_OBJETIVOS_ESTRATEGICOS}/culturaDesejada`
// export const URL_OBJETIVOS_ESTRATEGICOS_CULTURAS = `${URL_OBJETIVOS_ESTRATEGICOS_DASHBOARD}/culturas`;
export const URL_OBJETIVOS_ESTRATEGICOS_LIST_OBJETIVO = `${URL_OBJETIVOS_ESTRATEGICOS}`
export const URL_OBJETIVOS_ESTRATEGICOS_STORE_OBJETIVO = `${URL_OBJETIVOS_ESTRATEGICOS}`
export const URL_OBJETIVOS_ESTRATEGICOS_UPDATE_OBJETIVO = (id) =>
  `${URL_OBJETIVOS_ESTRATEGICOS}/${id}`
export const URL_OBJETIVOS_ESTRATEGICOS_DELETE_OBJETIVO = (id) =>
  `${URL_OBJETIVOS_ESTRATEGICOS}/${id}`
export const URL_OBJETIVOS_ESTRATEGICOS_LIST_OBJETIVOS_TEAMHUB = `${process.env.REACT_APP_API_URL}/objetivosTeamhub`
export const URL_OBJETIVOS_ESTRATEGICOS_GET_COLABORADORES = `${process.env.REACT_APP_API_URL}/pessoas/list`
export const URL_OBJETIVOS_ESTRATEGICOS_GET_COLABORADORES_PESSOAS = `${process.env.REACT_APP_API_URL}/pessoas/listPesquisa`
const URL_CULTURA_CONSENSUADA = `${URL_OBJETIVOS_ESTRATEGICOS}/culturaConsensuada`
export const URL_OBJETIVOS_ESTRATEGICOS_POST_CULTURA_CONSENSUADA = `${URL_CULTURA_CONSENSUADA}`
export const URL_OBJETIVOS_ESTRATEGICOS_DELETE_CULTURA_CONSENSUADA = `${URL_CULTURA_CONSENSUADA}`

// Times
export const URL_TIMES_PESSOAS = `${process.env.REACT_APP_API_URL}/objetivosEstrategicos`
export const URL_TIMES_PESSOAS_GET = `${URL_TIMES_PESSOAS}/listatimes`

export const URL_TIMES = `${process.env.REACT_APP_API_URL}/grupo`
export const URL_TIMES_GET = `${URL_TIMES}/list`

// Filiais
export const URL_PS_FILIAL = `${process.env.REACT_APP_API_URL}/filiais`

// Redux Types - Filial
export const PS_FILIAL_LIST = 'PS_FILIAL_LIST'

// Redux Types - Times
export const TIMES_GET = 'TIMES_GET'
export const TIMES_PESSOAS_GET = 'TIMES_PESSOAS_GET'

// Redux types
export const GESTORES_GET = 'GESTORES_GET'
export const GESTOR_POST = 'GESTOR_POST'
export const GESTOR_DELETE = 'GESTOR_DELETE'
export const GESTOR_UPDATE = 'GESTOR_UPDATE'
export const PESOS_GET = 'PESOS_GET'
export const ENVIAR_EMAILS_POST = 'ENVIAR_EMAILS_POST'
export const CULTURA_DESEJADA_GET = 'CULTURA_DESEJADA_GET'
export const GET_OBJETIVOS_ESTRATEGICOS_CULTURAS =
  'GET_OBJETIVOS_ESTRATEGICOS_CULTURAS'
export const OBJETIVOS_ESTRATEGICOS_LIST_OBJETIVO =
  'OBJETIVOS_ESTRATEGICOS_LIST_OBJETIVO'
export const OBJETIVOS_ESTRATEGICOS_STORE_OBJETIVO =
  'OBJETIVOS_ESTRATEGICOS_STORE_OBJETIVO '
export const OBJETIVOS_ESTRATEGICOS_UPDATE_OBJETIVO =
  'OBJETIVOS_ESTRATEGICOS_UPDATE_OBJETIVO'
export const OBJETIVOS_ESTRATEGICOS_DELETE_OBJETIVO =
  'OBJETIVOS_ESTRATEGICOS_DELETE_OBJETIVO'
export const OBJETIVOS_ESTRATEGICOS_LIST_OBJETIVOS_TEAMHUB =
  'OBJETIVOS_ESTRATEGICOS_LIST_OBJETIVOS_TEAMHUB'
export const OBJETIVOS_ESTRATEGICOS_GET_COLABORADORES =
  'OBJETIVOS_ESTRATEGICOS_GET_COLABORADORES'
export const OBJETIVOS_ESTRATEGICOS_GET_COLABORADORES_PESSOAS =
  'OBJETIVOS_ESTRATEGICOS_GET_COLABORADORES_PESSOAS'
export const OBJETIVOS_ESTRATEGICOS_POST_CULTURA_CONSENSUADA =
  'OBJETIVOS_ESTRATEGICOS_POST_CULTURA_CONSENSUADA'
// Charts columns

export const columnsCulturaDesejada = {
  Valor: {
    tickInterval: 2,
  },
}
