// Components
import EnviarPesquisa from '../../../components/enviarPesquisa/EnviarPesquisa'
import { URL_BASE } from '../../perfil/constants/constantsPerfil'

// URLs
export const URL_SEND_AVALIACOES = `${process.env.REACT_APP_API_URL}/avaliacoes/send`
export const URL_SEND_PERCEPCAO = `${process.env.REACT_APP_API_URL}/pesquisa`
export const URL_COLABORADORES = `${process.env.REACT_APP_API_URL}/pessoas`
export const URL_CANDIDATOS = `${process.env.REACT_APP_API_URL}/candidato`
export const URL_PESQUISAS_GET = `${process.env.REACT_APP_API_URL}/pesquisas`
export const URL_COLABORADORES_GET = `${URL_COLABORADORES}/list`
export const URL_GESTAOPESQUISAS_CANDIDATOS_GET = `${URL_CANDIDATOS}/list`
export const URL_VETOR_COMPORTAMENTAL_DELETE = `${process.env.REACT_APP_API_URL}/avaliacoes`
export const URL_PERCEPCAO_AMBIENTE_DELETE = `${URL_BASE}/pesquisa`
export const URL_POST_GESTAO_PESQUISAS_VETOR_SHARE = (pes_id, avalia_id) =>
  `${process.env.REACT_APP_API_URL}/share/vetor/pessoa/${pes_id}/${avalia_id}`
export const URL_SEND_PESQ_VALORES_ORGANIZACIONAIS = (pes_id) =>
  `${process.env.REACT_APP_API_URL}/pesqvalores/${pes_id}`
export const URL_DELETE_PESQ_VALORES_ORGANIZACIONAIS = (avalia_id) =>
  `${process.env.REACT_APP_API_URL}/pesqvalores/${avalia_id}`
export const URL_SENDLOTE_PESQ_VALORES_ORGANIZACIONAIS = `${process.env.REACT_APP_API_URL}/pesqvalores/sendlote`
export const URL_PESQUISA_COMPORTAMENTAL_KPI = `${process.env.REACT_APP_API_URL}/pesquisa/kpi/comportamental`
export const URL_PESQUISA_PERCEPCAO_KPI = `${process.env.REACT_APP_API_URL}/pesquisa/kpi/percepcao`
export const URL_PESQUISA_VALORES_KPI = `${process.env.REACT_APP_API_URL}/pesquisa/kpi/valores`
// Redux types
export const SEND_AVALIACOES = 'SEND_AVALIACOES'
export const SEND_PERCEPCAO = 'SEND_PERCEPCAO'
export const COLABORADORES_GET = 'COLABORADORES_GET'
export const GESTAOPESQUISAS_CANDIDATOS_GET = 'CANDIDATOS_GET'
export const PESQUISAS_GET = 'PESQUISAS_GET'
export const VETOR_COMPORTAMENTAL_DELETE = 'VETOR_COMPORTAMENTAL_DELETE'
export const PERCEPCAO_AMBIENTE_DELETE = 'PERCEPCAO_AMBIENTE_DELETE'
export const POST_GESTAO_PESQUISAS_VETOR_SHARE =
  'POST_GESTAO_PESQUISAS_VETOR_SHARE'
export const SEND_PESQ_VALORES_ORGANIZACIONAIS =
  'SEND_PESQ_VALORES_ORGANIZACIONAIS'
export const DELETE_PESQ_VALORES_ORGANIZACIONAIS =
  'DELETE_PESQ_VALORES_ORGANIZACIONAIS'
export const SENDLOTE_PESQ_VALORES_ORGANIZACIONAIS =
  'SENDLOTE_PESQ_VALORES_ORGANIZACIONAIS'
export const PESQUISA_COMPORTAMENTAL_KPI = 'PESQUISA_COMPORTAMENTAL_KPI'
export const PESQUISA_PERCEPCAO_KPI = 'PESQUISA_PERCEPCAO_KPI'
export const PESQUISA_VALORES_KPI = 'PESQUISA_VALORES_KPI'

// Modals
export const modals = [
  {
    id: 0,
    title: 'GERAR PESQUISA PARA OS CANDIDATOS',
    cancelText: 'CANCELAR',
    okText: 'ENVIAR',
    isCandidato: true,
    children: EnviarPesquisa,
    initialState: {
      vetorComportamental: false,
      enviarEmails: false,
      //percepcaoAmbiente: false,
      valoresOrganizacionais: false,
      colaborador: '',
    },
  },
  {
    id: 1,
    title: 'GERAR PESQUISA PARA OS COLABORADORES',
    cancelText: 'CANCELAR',
    okText: 'ENVIAR',
    children: EnviarPesquisa,
    initialState: {
      vetorComportamental: false,
      percepcaoAmbiente: false,
      enviarEmails: false,
      valoresOrganizacionais: false,
      colaborador: '',
    },
  },
]
