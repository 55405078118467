import { map } from 'lodash'

export const parseCulturaData = (data) => {
  const newData = []
  for (const c of data) {
    let obj = newData.find((o) => o.Tipo === c.Tipo)
    let add = false
    if (!obj) {
      add = true
      obj = {}
    }
    obj.Tipo = c.Tipo
    obj[c.Label] = `${c.Valor}%`
    obj.key = c.Tipo
    if (add) newData.push(obj)
  }
  return newData
}

export const parseCulturaOrgData = (data) => {
  const newData = []
  const sums = data.reduce(
    (acc, next) =>
      acc[next.TEXTO]
        ? { ...acc, [next.TEXTO]: acc[next.TEXTO] + next.VALOR }
        : { ...acc, [next.TEXTO]: next.VALOR },
    {}
  )

  for (const c of data) {
    let obj = newData.find((o) => o.Tipo === c.TEXTO)
    let add = false
    if (!obj) {
      add = true
      obj = {}
    }
    obj.Tipo = c.TEXTO
    obj[c.TIPO] = `${(
      (parseInt(c.VALOR) / parseInt(sums[c.TEXTO])) *
      100
    ).toFixed(1)}%`
    obj.key = c.TEXTO
    if (add) newData.push(obj)
  }
  return newData
}

export const parsePercent = (data) => {
  const sum = data.reduce((acc, next) => (acc += parseInt(next.VALOR)), 0)
  const result = data.map((i) => ({
    Tipo: i.TEXTO,
    Valor: `${((parseInt(i.VALOR) / sum) * 100).toFixed(1)}%`,
  }))
  return result
}

export const parsePercent1 = (data) => {
  const sum = data.reduce((acc, next) => (acc += Number(next.VALOR)), 0)
  const soma = Number(sum).toFixed(1)
  const result = data.map((i) => ({
    Tipo: i.TEXTO,
    Valor: `${((Number(i.VALOR) / soma) * 100).toFixed(1)}%`,
  }))
  return result
}

export const customParser = (data, { key, value }) => {
  const sum = data.reduce((acc, next) => (acc += parseInt(next[value])), 0)
  return data.map((i) => ({
    Tipo: i[key],
    Valor: `${((parseInt(i[value]) / sum) * 100).toFixed(1)}%`,
  }))
}
