import { dataSource } from '../../../../times/constants/constantsTimes'
import processoSeletivo from '../reducers/reducersProcessoSeletivo'
import data from '../../../../recrutamentoSelecao/components/ProcessoSeletivoForm'

// types
export const PROCESSO_SELETIVO_LIST = 'PROCESSO_SELETIVO_LIST'
export const PROCESSO_SELETIVO_STORE = 'PROCESSO_SELETIVO_STORE'
export const PROCESSO_SELETIVO_UPDATE = 'PROCESSO_SELETIVO_UPDATE'
export const PROCESSO_SELETIVO_DELETE = 'PROCESSO_SELETIVO_DELETE'
export const PROCESSO_SELETIVO_CARGOS_GET = 'PROCESSO_SELETIVO_CARGOS_GET'

export const CARGO_ATIVIDADE_LIST = 'CARGO_ATIVIDADE_LIST'
export const CARGO_ATIVIDADE = 'CARGO_ATIVIDADE'

export const PROCESSO_SELETIVO_TIMES_GET = 'PROCESSO_SELETIVO_TIMES_GET'
export const PROCESSO_SELETIVO_UFS_GET = 'PROCESSO_SELETIVO_UFS_GET'
export const PROCESSO_SELETIVO_CIDADES_GET = 'PROCESSO_SELETIVO_CIDADES_GET'
export const PROCESSO_SELETIVO_SUSPENSAO = 'PROCESSO_SELETIVO_SUSPENSAO'
export const PROCESSO_SELETIVO_LIST_USUARIOS = 'PROCESSO_SELETIVO_LIST_USUARIOS'
export const PROCESSO_SELETIVO_LIST_QUIZES = 'PROCESSO_SELETIVO_LIST_QUIZES'

// urls
const URL_PROCESSO_SELETIVO = (processo_id) =>
  `${process.env.REACT_APP_API_URL}/ps/${processo_id}`
export const URL_PROCESSO_SELETIVO_LIST = `${process.env.REACT_APP_API_URL}/ps`
export const URL_PROCESSO_SELETIVO_STORE = `${process.env.REACT_APP_API_URL}/ps`
export const URL_PROCESSO_SELETIVO_UPDATE = (id) =>
  `${process.env.REACT_APP_API_URL}/ps/${id}`
export const URL_PROCESSO_SELETIVO_DELETE = (id) =>
  `${process.env.REACT_APP_API_URL}/ps/${id}`
export const URL_PROCESSO_SELETIVO_TIMES_GET = `${process.env.REACT_APP_API_URL}/dashboard/grupos`
export const URL_PROCESSO_SELETIVO_CARGOS_GET = `${process.env.REACT_APP_API_URL}/cargo/list`
export const URL_PROCESSO_SELETIVO_UFS_GET = `${process.env.REACT_APP_API_URL}/uf`
export const URL_PROCESSO_SELETIVO_CIDADES_GET = (UFID) =>
  `${process.env.REACT_APP_API_URL}/cidade/${UFID}`

export const URL_CARGO_ATIVIDADE_LIST = `${process.env.REACT_APP_API_URL}/cargo/listAtividades`
export const URL_CARGO_ATIVIDADE = (cargo) =>
  `${process.env.REACT_APP_API_URL}/cargo/listatividadecargo/${cargo}`

export const URL_PROCESSO_SELETIVO_SUSPENSAO = (processo_id) =>
  `${URL_PROCESSO_SELETIVO(processo_id)}/suspensao`
export const URL_BASE = process.env.REACT_APP_API_URL
export const URL_PROCESSO_SELETIVO_LIST_USUARIOS = `${URL_BASE}/usuario?tipo=1`

// Listar quizes
export const URL_PROCESSO_SELETIVO_LIST_QUIZES = `${URL_BASE}/quiz`

export const novoProcessoInitialState = {
  qtde: 1,
  salario: 0,
  jornada: undefined,
  beneficios: undefined,
  requisitos: undefined,
  ufid: undefined,
  cidadeid: undefined,
  concluirem: undefined,
  time: undefined,
  cargo: undefined,
  homeoffice: false,
  responsavel: undefined,
  tipo: undefined,
  prioridade: undefined,
  numero: undefined,
  sigiloso: false,
  descricao: undefined,
  pcd: 0,
  pcd_tipo: undefined,
}
