import { connect } from 'react-redux'

// Components
import PercepcaoAmbienteController from './PercepcaoAmbienteController'

// Actions
import {
  getQuestoesPesquisas,
  postRespostaPesquisas,
  loadPercepcao,
} from '../../actions/actionsPesquisas'

const mapStateToProps = ({ pesquisas }) => {
  return {
    ...pesquisas.percepcaoAmbiente,
  }
}

export default connect(mapStateToProps, {
  getQuestoesPesquisas,
  postRespostaPesquisas,
  loadPercepcao,
})(PercepcaoAmbienteController)
