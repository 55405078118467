import React from 'react'
import { injectIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons'
import { Row, Col, Divider } from 'antd'

// Components
import Select, { Option } from '../../../../components/select/Select'

// CSS

// Constants

function EfetivarCandidato({ intl, handleChangeModal, cargosEfetivar }) {
  return (
    <div className="adicionar-candidatos">
      <Row>
        <Col md={24}>
          <Select
            label="Cargo"
            placeholder="Selecione o cargo"
            name="cargo"
            onChange={(cargo) =>
              handleChangeModal({ target: { name: 'cargo', value: cargo } })
            }
            style={{ width: '100%' }}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <div style={{ padding: '8px', cursor: 'pointer' }}>
                  <PlusOutlined /> Novo Cargo
                </div>
              </div>
            )}
          >
            {cargosEfetivar.map(({ cargo_id, cargo_nome }) => (
              <Option key={cargo_id} value={cargo_id}>
                {cargo_nome}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  )
}

export default injectIntl(EfetivarCandidato)
