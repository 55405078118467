import styled from 'styled-components'

export const ArquetiposCulturaisDetalhes = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props && props.color ? props.color : '#fff')};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
