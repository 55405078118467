import React from 'react'

import { Card, Col, Divider, Row, Space } from 'antd'

import moment from 'moment'

import 'moment-timezone'

import { Statistic, StatContainer, InfoContainer, Subtitle } from './styles'

function InformacoesBasicas({
  cargo,
  cidade,
  concluirem,
  contratados,
  contrato,
  criadopor,
  duracao,
  filial,
  grupo,
  homeoffice,
  inicio,
  inscritos,
  jornada,
  motivo,
  quantidade,
  prazo,
  prioridade,
  recrutadopor,
  salario,
  salario_faixa,
  sigilo,
  triados,
}) {
  function handleTipoSigilo(sigilo) {
    switch (sigilo) {
      case 0:
        return 'Não'
      case 1:
        return 'Sim'
      default:
        return '-'
    }
  }

  function handleTipoDivulgacao(divulgacao) {
    switch (divulgacao) {
      case 'E': {
        return 'Externa'
      }
      case 'I': {
        return 'Interna'
      }
      case 'H': {
        return 'Híbrida'
      }
      default:
        return '-'
    }
  }

  function handleTipoPrioridade(prioridade) {
    switch (prioridade) {
      case 1: {
        return 'Baixa'
      }
      case 2: {
        return 'Média'
      }
      case 3: {
        return 'Alta'
      }
      default:
        return '-'
    }
  }

  function handleHomeOffice(homeoffice) {
    switch (homeoffice) {
      case 1: {
        return 'Sim'
      }
      case 0: {
        return 'Não'
      }
      default:
        return '-'
    }
  }

  return (
    <Col span={24}>
      <Row gutter={16}>
        <Col sm={24} md={24} lg={6}>
          <Card>
            <Space direction="vertical" style={{ width: '100%' }}>
              <StatContainer direction="horizontal" size="large">
                <Statistic
                  title="Início"
                  value={moment(inicio)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('DD/MM/YYYY (H:mm)')}
                />
                <Statistic
                  title="Prazo"
                  value={
                    moment(concluirem)
                      .utc()
                      .tz(moment.tz.guess())
                      .format('DD/MM/YYYY (H:mm)') || '-'
                  }
                />
              </StatContainer>

              <Divider />

              <StatContainer direction="horizontal" size="large">
                <Statistic title="Tempo até prazo" value={prazo || '-'} />
                <Statistic title="Duração" value={duracao} suffix="dia(s)" />
              </StatContainer>

              <Divider />

              <StatContainer direction="horizontal" size="large">
                <Statistic
                  title="Quantidade"
                  value={quantidade || '-'}
                  suffix="vaga(s)"
                />
                <Statistic title="Inscritos" value={inscritos || '-'} />
                <Statistic title="Triados" value={triados || '-'} />
                <Statistic title="Contratados" value={contratados || '-'} />
              </StatContainer>
            </Space>
          </Card>
        </Col>
        <Col sm={24} md={24} lg={18}>
          <Card>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <InfoContainer direction="horizontal" size="large">
                <Statistic title="Cargo" value={cargo || '-'} />
                <Statistic
                  title="Sigiloso"
                  value={handleTipoSigilo(sigilo || '-')}
                />
                <Statistic
                  title="Prioridade"
                  value={handleTipoPrioridade(prioridade || '-')}
                />
              </InfoContainer>
              <InfoContainer direction="horizontal" size="large">
                <Statistic
                  title="Tipo de Contrato"
                  value={contrato ? contrato.nome : '-'}
                />
                <Statistic title="Jornada" value={jornada || '-'} />
                <Statistic
                  title="Salário"
                  value={salario || salario_faixa || '-'}
                />
                <Statistic title="Motivo" value={motivo ? motivo.nome : '-'} />
              </InfoContainer>

              <Divider />
              <Subtitle>Responsáveis</Subtitle>
              <InfoContainer direction="horizontal" size="large">
                <Statistic title="Recrutado por" value={recrutadopor || '-'} />
                <Statistic title="Criado por" value={criadopor || '-'} />
              </InfoContainer>
              <Subtitle>Localidade</Subtitle>
              <InfoContainer direction="horizontal" size="large">
                <Statistic
                  title="Cidade"
                  value={cidade ? cidade.CIDADE : '-'}
                />
                <Statistic
                  title="Homeoffice"
                  value={handleHomeOffice(homeoffice)}
                />
                <Statistic title="Filial" value={filial ? filial.nome : '-'} />
                <Statistic
                  title="Time"
                  value={grupo ? grupo.grupo_nome : '-'}
                />
              </InfoContainer>
            </Space>
          </Card>
        </Col>
      </Row>
    </Col>
  )
}

export default InformacoesBasicas
