import { connect } from 'react-redux'
import * as actions from './actions/actionsPerfilComportamentalDetalhes'
import { getCandidatos } from '../candidatos/actions/actionsCandidatos'

// Components
import PerfilComportamentalDetalhesController from './PerfilComportamentalDetalhesController'

// Actions

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, { ...actions })(
  PerfilComportamentalDetalhesController
)
