// URLs
export const URL_COLABORADORES = `${process.env.REACT_APP_API_URL}/pessoas`
export const URL_COLABORADORES_GET = `${URL_COLABORADORES}/list`
export const URL_VETOR_GET = `${process.env.REACT_APP_API_URL}/vetor`
export const URL_PESSOA_GET = `${process.env.REACT_APP_API_URL}/pessoas/check/`
// Redux types
export const COLABORADORES_GET = 'COLABORADORES_GET'
export const VETOR_GET = 'VETOR_GET'
export const PESSOA_GET = 'PESSOA_GET'

// Data processing
export const apiToFront = ({ pesquisa }) => ({
  status: pesquisa.status,
  nome: pesquisa.nome,
  colaborador: pesquisa.colaborador,
  login: pesquisa.login,
  grupo: pesquisa.grupo,
  dataenvio: pesquisa.dataenvio,
  dataresposta: pesquisa.dataresposta,
  senha: pesquisa.senha,
  id: pesquisa.id,
})
