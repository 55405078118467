import moment from 'moment'

// types
export const VINCULAR_USUARIO_COLABORADORES_GET =
  'VINCULAR_USUARIO_COLABORADORES_GET'

export const VINCULAR_USUARIO_COLABORADORES_POST =
  'VINCULAR_USUARIO_COLABORADORES_POST'

// urls
export const URL_VINCULAR_USUARIO_COLABORADORES_GET = `${process.env.REACT_APP_API_URL}/pessoas/list`

export const URL_VINCULAR_USUARIO_COLABORADORES_POST = (pes_id) =>
  `${process.env.REACT_APP_API_URL}/usuario/vincular/${pes_id}`

export const initialState = {
  cpf: undefined,
  nome: undefined,
  dataNascimento: moment(),
  email: undefined,
  sexo: 'M',
  telefone: undefined,
}
