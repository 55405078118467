import React from 'react'

import moment from 'moment'

import 'moment-timezone'

import { Input, Button } from 'antd'

import { Label, Container, FeedbackItem, ButtonContainer } from './styles'

// Types
import { IFeedbackUpdateFn, IFeedbackSaveUpdateFn } from '../../@types'

const { TextArea } = Input

interface IProps {
  pes_id: number
  data: any
  handleUpdateFeedbackData(data: IFeedbackUpdateFn): Promise<void>
  handleSaveUpdateFeedback(data: IFeedbackSaveUpdateFn): Promise<void>
  saving: boolean
  // handleGetPessoa(): Promise<void>;
  children?: React.ReactNode
}

const FeedbackListItem: React.FC<IProps> = ({
  pes_id,
  data,
  handleUpdateFeedbackData,
  handleSaveUpdateFeedback,
  saving = false,
  children,
}) => {
  const {
    feedback_id,
    created_at,
    situacao,
    comportamento,
    impacto,
    sugestao,
    updated_at,
    created_by,
    updated_by,
    completed_by,
  } = data

  return (
    <>
      <Container>
        <FeedbackItem>
          <Label>S</Label> - Situação
          <TextArea
            maxLength={1000}
            placeholder="Detalhar a situação com fatos e dados que sejam recentes."
            value={situacao}
            onChange={(e) =>
              handleUpdateFeedbackData({
                feedback_id,
                situacao: e.target.value,
              })
            }
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </FeedbackItem>
        <FeedbackItem>
          <Label>C</Label> - Comportamento
          <TextArea
            maxLength={1000}
            placeholder="Detalhar o comportamento frente a situação."
            value={comportamento}
            onChange={(e) =>
              handleUpdateFeedbackData({
                feedback_id,
                comportamento: e.target.value,
              })
            }
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </FeedbackItem>
        <FeedbackItem>
          <Label>I</Label> - Impacto
          <TextArea
            maxLength={1000}
            placeholder="Qual ou quais impactos tal comportamento ocasionou no time, na empresa, etc?"
            value={impacto}
            onChange={(e) =>
              handleUpdateFeedbackData({
                feedback_id,
                impacto: e.target.value,
              })
            }
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </FeedbackItem>
        <FeedbackItem>
          <Label>Sugestão de desenvolvimento individual</Label>
          <TextArea
            maxLength={1000}
            placeholder="Em casos de feedback de desenvolvimento (resultado, relacionamento...), ajuste de rotas e/ou comportamento faz se necessário a realização de PDI que está disponível logo abaixo."
            value={sugestao}
            onChange={(e) =>
              handleUpdateFeedbackData({
                feedback_id,
                sugestao: e.target.value,
              })
            }
            autoSize={{ minRows: 5, maxRows: 7 }}
          />
        </FeedbackItem>

        <ButtonContainer>
          <Button
            type="primary"
            onClick={() => {
              handleSaveUpdateFeedback({ feedback_id })
            }}
            loading={saving}
            disabled={saving}
          >
            Salvar Feedback
          </Button>
        </ButtonContainer>
      </Container>
      {children}
    </>
  )
}

export default FeedbackListItem
