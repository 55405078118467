import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import {
  Checkbox,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Tooltip,
} from 'antd'
import * as _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'

// Components

// CSS
import './EditableTable.css'

const EditableContext = React.createContext()

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />
    }
    if (this.props.inputType === 'select') {
      return <Select options={this.props.options} style={{ width: '100%' }} />
    }
    if (this.props.inputType === 'date') {
      return <DatePicker />
    }
    if (this.props.inputType === 'checkbox') {
      return (
        <Checkbox
          defaultChecked={Boolean(this.props.record[this.props.dataIndex])}
        />
      )
    }
    return <Input placeholder={this.props.placeholder} />
  }

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `${title} é obrigatório!`,
                },
              ],
              initialValue:
                inputType !== 'date'
                  ? record[dataIndex]
                  : moment(record[dataIndex], 'DD/MM/YYYY'),
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    )
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = { editingKey: '', columns: [] }
  }

  buildColumns = () => {
    return [
      ...this.props.columns.filter((c) => c.key !== 'extra_actions'),
      this.props.isEditable || this.props.isDeleble
        ? {
            title: 'Ações',
            dataIndex: 'acoes',
            align: 'center',
            render: (text, record) => {
              const { editingKey } = this.state
              const editable = this.isEditing(record)

              const extraActions = this.props.columns.find(
                (c) => c.key === 'extra_actions'
              )

              const { disableEditing } = this.props

              let confirmEditable = true

              if (disableEditing && disableEditing.prop) {
                const { prop, falsy } = disableEditing

                if (record[prop]) {
                  if (falsy) {
                    confirmEditable = !record[prop]
                  } else {
                    confirmEditable = !!record[prop]
                  }
                }
              }

              return editable ? (
                <span
                  style={{
                    display: 'inline',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <EditableContext.Consumer>
                    {(form) => (
                      <a
                        href="javascript:;"
                        onClick={() => {
                          return this.save(form, record.key)
                        }}
                        style={{ marginRight: 8 }}
                      >
                        Salvar
                      </a>
                    )}
                  </EditableContext.Consumer>
                  <Popconfirm
                    title="Tem certeza que deseja cancelar?"
                    onConfirm={() => {
                      return this.cancel(record.key)
                    }}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <a>Cancelar</a>
                  </Popconfirm>
                </span>
              ) : (
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {confirmEditable && (
                    <EditableContext.Consumer>
                      {(form) =>
                        this.state.editingKey !== '' &&
                        this.state.editingKey === record.key ? (
                          <span
                            className="editable-table action"
                            onClick={() => {
                              this.save(form, record.key)
                            }}
                          >
                            <Tooltip title="Salvar">
                              <SaveOutlined />
                            </Tooltip>
                          </span>
                        ) : (
                          <span
                            className="editable-table action"
                            onClick={() => {
                              if (this.state.editingKey === '')
                                this.edit(record.key)
                              else this.cancel()
                            }}
                          >
                            <Tooltip title="Editar">
                              <EditOutlined />
                            </Tooltip>
                          </span>
                        )
                      }
                    </EditableContext.Consumer>
                  )}
                  {this.props.isEditable && this.props.isDeleble && (
                    <Divider type="vertical" />
                  )}
                  {this.props.isDeleble && (
                    <span>
                      {this.props.deleteNode ? (
                        this.props.deleteNode(
                          () => this.handleDelete(record.key),
                          record.key
                        )
                      ) : (
                        <>
                          <Popconfirm
                            title="Tem certeza que deseja excluir?"
                            onConfirm={() => this.handleDelete(record.key)}
                            okText="Sim"
                            cancelText="Não"
                          >
                            <span className="editable-table action">
                              <Tooltip title="Excluir" placement="bottom">
                                <DeleteOutlined />
                              </Tooltip>
                            </span>
                          </Popconfirm>
                        </>
                      )}
                    </span>
                  )}
                  {extraActions && <>{extraActions.render(text, record)}</>}
                </span>
              )
            },
          }
        : this.props.isNavigable
        ? {
            title: 'Ação',
            dataIndex: 'conta_cultura_tipo',
            align: 'center',
            render: (text, record) => {
              const { disableEditing } = this.props

              let confirmEditable = true

              if (disableEditing && disableEditing.prop) {
                const { prop, falsy } = disableEditing

                if (record[prop]) {
                  if (falsy) {
                    confirmEditable = !record[prop]
                  } else {
                    confirmEditable = !!record[prop]
                  }
                }
              }

              return (
                <span
                  key={record.cultura_id}
                  style={{
                    display: 'inline',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Link to={`./dashboard/${record.cultura_id}/`}>Acessar</Link>
                </span>
              )
            },
          }
        : null,
    ].filter((elem) => elem)
  }

  componentDidMount() {
    this.setState({
      columns: this.buildColumns(),
    })
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState({ columns: this.buildColumns() })
    }
  }

  isEditing = (record) => record.key === this.state.editingKey

  cancel = () => {
    this.setState({ editingKey: '' })
  }

  flattenDeep = (data) => {
    return data.reduce((acc, next) => {
      return next.children &&
        Array.isArray(next.children) &&
        next.children.length > 0
        ? acc.concat(next, this.flattenDeep(next.children))
        : acc.concat(next)
    }, [])
  }

  save(form, key) {
    const { dataSource, onSave } = this.props

    form.validateFields((error, row) => {
      if (error) {
        return
      }
      const newData = this.flattenDeep([...dataSource])
      const index = newData.findIndex((item) => key === item.key)
      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row,
        })
        this.setState({ editingKey: '' })

        onSave(
          {
            ...item,
            ...row,
          },
          newData
        )
      } else {
        newData.push(row)
        this.setState({ editingKey: '' })

        onSave(row, newData)
      }
    })
  }

  edit(key) {
    this.setState({ editingKey: key })
  }

  handleAdd = () => {
    const { dataSource, newDataModel, onSave } = this.props

    this.edit(dataSource.length.toString())

    onSave(newDataModel, [
      ...dataSource,
      { ...newDataModel, key: dataSource.length.toString() },
    ])
  }

  handleDelete = (key) => {
    const { dataSource, onDelete } = this.props

    onDelete(
      dataSource.filter((item) => item.key !== key),
      dataSource.filter((item) => item.key === key)[0]
    )
  }

  render() {
    const { form, dataSource, header, rowSelection, loading } = this.props

    const components = {
      body: {
        cell: EditableCell,
      },
    }

    const columns = this.state.columns.map((col) => {
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: (record) => {
          return {
            record,
            inputType: col.inputType
              ? col.inputType
              : col.dataIndex === 'age'
              ? 'number'
              : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            placeholder: col.placeholder,
            editing: col.editableConditionKey
              ? record[col.editableConditionKey] ===
                  col.editableConditionValue && this.isEditing(record)
              : this.isEditing(record),
            options: col.options,
          }
        },
      }
    })

    return (
      <EditableContext.Provider value={form}>
        <div className="editable-table">
          {header && header({ handleAdd: this.handleAdd })}
          <Table
            components={components}
            bordered
            dataSource={dataSource}
            columns={columns}
            rowClassName="editable-row"
            pagination={{
              onChange: this.cancel,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} de ${total} registros`,
            }}
            locale={{ emptyText: 'Nenhum registro' }}
            rowSelection={rowSelection}
            loading={loading}
          />
        </div>
      </EditableContext.Provider>
    )
  }
}

const EditableFormTable = Form.create({
  mapPropsToFields: (props) => {
    return {
      usarponderacao: Form.createFormField({ value: props.usarponderacao }),
    }
  },
})(EditableTable)

export default EditableFormTable
