import React from 'react'

// Components

// CSS
import './Container.css'

function Container({ children, className = '' }) {
  return <div className={`container ${className}`}>{children}</div>
}

export default Container
