import React from 'react'

import { Form, DatePicker } from 'antd'

function DesligamentoModal({ onFinish }) {
  const [form] = Form.useForm()

  return (
    <Form
      id="desligamento-modal"
      layout="vertical"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        label="Selecione a data: "
        name="datademissao"
        rules={[
          {
            required: true,
            message: 'Por favor, selecione uma data.',
          },
        ]}
      >
        <DatePicker allowClear />
      </Form.Item>
    </Form>
  )
}

export default DesligamentoModal
