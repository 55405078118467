import { Layout } from 'antd'
import React from 'react'
import Dashboard from '../Dashboard'

const App = () => {
  return (
    <Layout>
      <h1>Informações</h1>
    </Layout>
  )
}

export default App
