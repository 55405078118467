import React, { Component } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { message, Input, Button } from 'antd'
import history from '../../utils/history'

// Components
import PerfilView from './PerfilView'

// CSS

// Constants

// Services
import { getContaURL, isLider } from '../../services/auth'

class PerfilController extends Component {
  state = {
    showModal: {
      alocarTime: false,
      alocarCargo: false,
      editarColaborador: false,
      escolherCargo: false,
      enviarPesquisa: false,
      desligamentoModal: false,
      triarCandidato: false,
      confirmTriagem: false,
    },
    isCandidato: false,
    cargoId: '',
    cargoNome: '',
    candidato: {},
    cargos: [],
    filiais: [],
    vetorComportamental: false,
    percepcaoAmbiente: false,
    valoresOrganizacionais: false,
    enviarEmails: false,
    activeTab: '1',
    desligamentoModal: {
      datademissao: undefined,
    },
    processoSelected: null,
    etapacand_id: null,
    observacao: null,
    processoTriagemSelected: null,
    confirmTriagemMessage: undefined,
  }

  componentDidUpdate = (_, prevState) => {
    if (
      prevState.processoSelected !== this.state.processoSelected &&
      this.state.processoSelected &&
      this.state.etapacand_id
    ) {
      const { listLogs } = this.props
      listLogs({
        etapacand_id: this.state.etapacand_id,
        processo_id: this.state.processoSelected,
      })
    }
  }

  componentDidMount = () => {
    const {
      match,
      getInfoPerfil,
      getVetores,
      getVetorComportamental,
      getPercepcaoAmbiente,
      getTimes,
      getCargos,
      getTimesDisponiveis,
      getCargosDisponiveis,
      getCandidatos,
      getValoresOrganizacionais,
      getCultura,
      getCulturaOrg,
      getFitCultural,
      getCulturaOrgEmpresa,
      getCulturaEmpresa,
      listValoresOrg,
      listFiliais,
      listProcessos,
      listProcessosTriagem,
    } = this.props

    listProcessosTriagem()

    listProcessos(match.params.id).then(({ response }) => {
      if (response.status === 200 && response.data.processos.length > 0) {
        const { etapacand_id } =
          response.data.processos[0].etapas[0].etapaCandidato[0]
        const { processo_id } = response.data.processos[0]
        this.setState({
          processoSelected: processo_id,
          etapacand_id,
        })
      }
    })

    getInfoPerfil({ id: match.params.id }).then(({ response }) => {
      const { data } = response
      const { pessoa } = data
      if (parseInt(pessoa.ativorelatorios) === 0) {
        history.push(`/${getContaURL()}/perfilCandidato/${match.params.id}`)
      }
      getVetores({ id: match.params.id })
      getVetorComportamental({ id: match.params.id })
      if (!isLider()) getPercepcaoAmbiente({ id: match.params.id })
      getTimes({ id: match.params.id })
      getCargos({ id: match.params.id })
      if (!isLider()) getTimesDisponiveis()
      if (!isLider()) getCargosDisponiveis()
      getValoresOrganizacionais({ id: match.params.id })
      getCultura(match.params.id)
      getCulturaOrg(match.params.id)
      getFitCultural(match.params.id)
    })

    getCulturaEmpresa()
    getCulturaOrgEmpresa()

    listValoresOrg()

    listFiliais().then(({ response }) => {
      if (response.status === 200) {
        this.setState({ filiais: response.data })
      }
    })

    if (!isLider())
      getCandidatos().then(({ response }) => {
        if (response.status === 200) {
          const candidato = response.data.candidatos.filter(
            (candidato) => candidato.pes_id == match.params.id
          )
          this.setState({
            isCandidato: candidato.length > 0,
            cargoId: candidato.length > 0 && candidato[0].cargos_id,
            cargoNome: candidato.length > 0 && candidato[0].cargos,
          })
        }
      })
  }

  handleChange = ({ target }, subState) => {
    if (subState) {
      this.setState((prevState) => ({
        [subState]: {
          ...prevState[subState],
          [target.name]: target.value,
        },
      }))
    } else this.setState({ [target.name]: target.value })
  }

  openModal = (modalId) => {
    this.setState((prevState) => ({
      showModal: { ...prevState.showModal, [modalId]: true },
    }))
  }

  closeModal = (modalId) => {
    this.setState((prevState) => ({
      showModal: { ...prevState.showModal, [modalId]: false },
    }))
  }

  handleEfetivar = (id) => {
    const { openModal } = this
    const { efetivaCandidato, info, getInfoPerfil } = this.props
    const { cargoId, cargoNome } = this.state

    const candidato = {
      cpf: info.documento,
      id: info.id,
      cargo: cargoId.includes(',')
        ? cargoId.split(',').map((cargo_id, index) => {
            return {
              cargo_id,
              cargo_nome: cargoNome.split(',')[index],
            }
          })
        : cargoId,
    }

    if (Array.isArray(candidato.cargo)) {
      this.setState({ candidato, cargos: candidato.cargo }, () =>
        openModal('escolherCargo')
      )
    } else {
      efetivaCandidato(candidato).then(({ response }) => {
        if (response.status === 200) {
          message.success('Efetivado com sucesso!')
          getInfoPerfil({ id: info.id })
          this.setState({ isCandidato: false })
        }
      })
    }
  }

  handleChangeModal = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value })
  }

  handleChangeCheckbox = (evt) => {
    this.setState({ [evt.target.name]: evt.target.checked })
  }

  handleChangeLider = (idTime, lider) => {
    const { match, getTimes, postGrupoLider, deleteGrupoLider } = this.props

    const idPessoa = match.params.id

    if (!lider) {
      postGrupoLider(idTime, idPessoa).then(({ response }) => {
        if (response.status === 200) {
          getTimes({ id: match.params.id })
        }
      })
    } else {
      deleteGrupoLider(idTime, idPessoa).then(({ response }) => {
        if (response.status === 200) {
          getTimes({ id: match.params.id })
        }
      })
    }
  }

  onModalSubmit = (modalId, confirm = false) => {
    const {
      postTimePessoa,
      postCargoPessoa,
      getTimes,
      getCargos,
      putColaborador,
      getInfoPerfil,
      info,
      efetivaCandidato,
      desativarPerfil,
    } = this.props
    const {
      alocarTime,
      alocarCargo,
      editarColaborador,
      candidato,
      desligamentoModal,
    } = this.state

    switch (modalId) {
      case 'alocarTime': {
        return postTimePessoa({
          grupo_id: alocarTime.id,
          pes_id: info.id,
          grupo_dataentrada: alocarTime.grupo_dataentrada,
        }).then(({ response }) => {
          if (response.status === 200) {
            message.success('Time adicionado com sucesso!')
            getTimes({ id: info.id })
            this.closeModal('alocarTime')
          }
        })
      }

      case 'alocarCargo': {
        return postCargoPessoa({
          cargo_id: alocarCargo.id,
          pes_id: info.id,
          cargopes_dataentrada: alocarCargo.cargopes_dataentrada,
        }).then(({ response }) => {
          if (response.status === 200) {
            message.success('Cargo adicionado com sucesso!')
            getCargos({ id: info.id })
            this.closeModal('alocarCargo')
          }
        })
      }

      case 'editarColaborador': {
        return putColaborador({
          id: info.id,
          cpf: editarColaborador.cpf,
          nome: editarColaborador.nome,
          dataNascimento: editarColaborador.pes_datanascimento,
          email: editarColaborador.email,
          sexo: editarColaborador.pes_sexo,
          telefone: editarColaborador.telefone,
          filial_id: editarColaborador.filial_id,
          dataAdmissao: editarColaborador.dataadmissao,
          pcd: editarColaborador.pcd,
          pcd_tipo: editarColaborador.pcd_tipo,
          cid: editarColaborador.cid,
        }).then(({ response }) => {
          if (response.status === 200) {
            message.success('Editado com sucesso!')
            getInfoPerfil({ id: info.id })
            this.closeModal('editarColaborador')
          }
        })
      }

      case 'escolherCargo': {
        return efetivaCandidato(candidato).then(({ response }) => {
          if (response.status === 200) {
            message.success('Efetivado com sucesso!')
            getInfoPerfil({ id: info.id })
            this.setState({ isCandidato: false })
            this.closeModal('escolherCargo')
          }
        })
      }

      case 'enviarPesquisa':
        {
          const {
            vetorComportamental,
            percepcaoAmbiente,
            valoresOrganizacionais,
            enviarEmails,
          } = this.state

          const {
            match,
            sendVetorComportamental,
            sendPercepcao,
            getVetorComportamental,
            getPercepcaoAmbiente,
            sendValoresOrganizacionais,
            getValoresOrganizacionais,
          } = this.props

          const promises = []

          if (vetorComportamental)
            promises.push(
              sendVetorComportamental(
                { pessoas: [{ pes_id: info.id }] },
                { enviarEmails }
              )
            )

          if (percepcaoAmbiente)
            promises.push(sendPercepcao(info.id, { enviarEmails }))

          if (valoresOrganizacionais)
            promises.push(sendValoresOrganizacionais(info.id, { enviarEmails }))

          Promise.all(promises).then((payload) => {
            if (
              payload.length > 0 &&
              payload.filter(({ response }) => response.status !== 200)
                .length === 0
            ) {
              message.success('Pesquisa enviada com sucesso!')
              getVetorComportamental({ id: match.params.id })
              getPercepcaoAmbiente({ id: match.params.id })
              getValoresOrganizacionais({ id: match.params.id })
            }

            this.closeModal('enviarPesquisa')
          })
        }
        break
      case 'desligamentoModal':
        const { match } = this.props
        desativarPerfil({
          id: match.params.id,
          datademissao: desligamentoModal.datademissao,
        }).then(({ response }) => {
          if (response.status === 200 || response.status === 204) {
            this.closeModal('desligamentoModal')
            this.handleChange(
              {
                target: {
                  name: 'datademissao',
                  value: undefined,
                },
              },
              'desligamentoModal'
            )
          }
        })
        break

      case 'triarCandidato': {
        const { processoTriagemSelected } = this.state
        const { triarCandidatos, validateTriagem, match } = this.props
        if (processoTriagemSelected) {
          validateTriagem({ pes_id: match.params.id }).then(({ response }) => {
            if (response.status === 204 || confirm) {
              triarCandidatos({
                processo_id: processoTriagemSelected,
                candidatos: [match.params.id],
                confirm: true,
              }).then(({ response }) => {
                if (response.status === 200) {
                  toast.success('Candidato triado com sucesso.')
                  this.closeModal('confirmTriagem')
                  this.closeModal('triarCandidato')
                  this.setState({
                    processoTriagemSelected: null,
                    confirmTriagemMessage: undefined,
                  })
                } else toast.error('Erro ao triar candidato.')
              })
            } else if (response.status === 412) {
              const { message } = response.data
              this.setState({
                confirmTriagemMessage: message,
              })
              this.closeModal('triarCandidato')
              this.openModal('confirmTriagem')
            }
          })
        }
        break
      }

      default:
        break
    }
  }

  getColumnSearchProps = (dataIndex, label = '') => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Buscar por ${label || dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  deleteCargoPessoa = (idCargo, idPessoa) => {
    const { match, deleteCargoPessoa, getCargos } = this.props
    deleteCargoPessoa(idCargo, idPessoa).then(({ response }) => {
      if (response.status === 200) {
        getCargos({ id: match.params.id })
      }
    })
  }

  deleteTimePessoa = (idTime, idPessoa) => {
    const { match, deleteTimePessoa, getTimes } = this.props
    deleteTimePessoa(idTime, idPessoa).then(({ response }) => {
      if (response.status === 200) {
        getTimes({ id: match.params.id })
      }
    })
  }

  excluirPerfil = () => {
    const { excluirPerfil, info } = this.props
    const contaURL = getContaURL()

    excluirPerfil({ id: info.id }).then(({ response }) => {
      if (response.status === 200) {
        history.push(`/${contaURL}/colaboradores`)
        toast.success('Colaborador excluido com sucesso')
      }
    })
  }

  handleSaveCargo = (data) => {
    const { cargopes_id } = data
    const { updateCargoPessoa, getCargos, match } = this.props

    updateCargoPessoa(cargopes_id, data).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Dados atualizados com sucesso.')
        getCargos({ id: match.params.id })
      }
    })
  }

  handleSaveTime = (data) => {
    const { match, updateGrupoPessoa, getTimes } = this.props
    const idPessoa = match.params.id

    updateGrupoPessoa(idPessoa, data).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Dados atualizados com sucesso.')
        getTimes({ id: match.params.id })
      }
    })
  }

  handleDataDesl = (data_saida) => {
    const { handleSaveDataDesl } = this.props

    updateDataDesl(id, data_saida).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Dados atualizados com sucesso.')
      }
    })
  }

  handleChangeObservacao = (value) => {
    this.setState({ observacao: value })
  }

  handleCreatePsLog = () => {
    const { storeLog, listLogs } = this.props
    const { observacao, etapacand_id, processoSelected } = this.state
    if (observacao && etapacand_id && processoSelected) {
      storeLog({
        etapacand_id,
        processo_id: processoSelected,
        observacao,
      }).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Observação registrada com sucesso.')
          this.setState({ observacao: null })
          listLogs({ processo_id: processoSelected, etapacand_id })
        } else toast.error('Erro ao registrar observação.')
      })
    }
  }

  handleDeletePsLog = (log_id) => {
    const { deleteLog, listLogs } = this.props
    const { etapacand_id, processoSelected } = this.state

    if (etapacand_id && processoSelected && log_id) {
      deleteLog({ etapacand_id, processo_id: processoSelected, log_id }).then(
        ({ response }) => {
          if (response.status === 200) {
            toast.success('Observação excluída com sucesso.')
            listLogs({ processo_id: processoSelected, etapacand_id })
          } else if (response.status === 400 && response.data.message) {
            toast.error(response.data.message)
          } else toast.error('Erro ao excluir observação.')
        }
      )
    }
  }

  handleChangePsTriagem = ({ target }) => {
    this.setState({ processoTriagemSelected: target.value })
  }

  handleChangePsSelected = (processo_id) => {
    const { processos } = this.props
    const processo = processos.find((p) => p.processo_id === processo_id)
    if (processo) {
      this.setState({
        processoSelected: processo_id,
        etapacand_id: processo.etapas[0].etapaCandidato[0].etapacand_id,
      })
    }
  }

  render() {
    const {
      vetorComportamental,
      percepcaoAmbiente,
      valoresOrganizacionais,
      filiais,
      ...state
    } = this.state
    return (
      <PerfilView
        {...this.props}
        {...state}
        handleChange={this.handleChange}
        openModal={this.openModal}
        closeModal={this.closeModal}
        onModalSubmit={this.onModalSubmit}
        handleEfetivar={this.handleEfetivar}
        handleChangeLider={this.handleChangeLider}
        handleChangeModal={this.handleChangeModal}
        handleChangeCheckbox={this.handleChangeCheckbox}
        getColumnSearchProps={this.getColumnSearchProps}
        deleteCargoPessoa={this.deleteCargoPessoa}
        deleteTimePessoa={this.deleteTimePessoa}
        excluirPerfil={this.excluirPerfil}
        handleSaveCargo={this.handleSaveCargo}
        handleSaveTime={this.handleSaveTime}
        filiais={filiais}
        handleCreatePsLog={this.handleCreatePsLog}
        handleUpdatePsLog={this.handleUpdatePsLog}
        handleDeletePsLog={this.handleDeletePsLog}
        handleChangePsSelected={this.handleChangePsSelected}
        handleChangeObservacao={this.handleChangeObservacao}
        handleTriar={this.handleTriar}
        handleChangePsTriagem={this.handleChangePsTriagem}
      />
    )
  }
}

export default PerfilController
