import React from 'react'
import { Row, Col, DatePicker, Input, Form } from 'antd'
import 'moment-timezone'
import { ICreateAcao } from '../../@types'

const { TextArea } = Input

const { RangePicker } = DatePicker

interface IProps {
  onFinish(data: ICreateAcao): Promise<void>
}

const AdicionarNovaAcao: React.FC<IProps> = ({ onFinish }) => {
  const [form] = Form.useForm()

  return (
    <Form layout="vertical" form={form} onFinish={onFinish} id="pdi-new-acao">
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item name="descricao" label="Descricao">
            <Input type="text" placeholder="Descreva a ação" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item name="sabotadores" label="Sabotadores">
            <TextArea placeholder="Descreva os sabotadores" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item name="opcoes" label="Opcoes">
            <TextArea placeholder="Informe as opções" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item name="startAndEnd" label="Prazo">
            <RangePicker format="DD/MM/YYYY" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default AdicionarNovaAcao
