import axios from 'axios'

export const getFullAddress = async (cep) => {
  try {
    const { data } = await axios.get(
      `https://brasilapi.com.br/api/cep/v2/${cep}`
    )
    return data
  } catch (error) {
    return error.response
  }
}
