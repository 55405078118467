// URLs
export const URL_BASE = process.env.REACT_APP_API_URL
export const URL_PESQUISAS_DISPONIVEIS_GET = `${URL_BASE}/avaliacoes/check/`

// Redux types
export const PESQUISAS_DISPONIVEIS_GET = 'PESQUISAS_DISPONIVEIS_GET'

export const stepTitles = [
  null,
  null,
  null,
  'Qual seu grau de satisfação em relação à:',
  'Marque a melhor opção para as questões abaixo',
  'Marque a melhor opção para as questões abaixo',
]
