import React from 'react'
import InputMask from 'react-input-mask'
import { Input } from 'antd'
import FormItem from 'antd/lib/form/FormItem'

const MaskInput = (props) => {
  const { disabled, mask, label, required, name } = props
  return (
    <FormItem label={label} required={required} {...props}>
      <InputMask
        mask={mask}
        disabled={disabled}
        autoComplete="off"
        {...props.input}
      >
        {(inputProps) => <Input name={name} {...inputProps} />}
      </InputMask>
    </FormItem>
  )
}

export default MaskInput
