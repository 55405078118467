import React from 'react'

import { Col, Row, Slider, Form, InputNumber } from 'antd'

import {
  Chart,
  Interval,
  Line,
  Point,
  Axis,
  Tooltip,
  Coordinate,
  Legend,
  useRootChart,
} from 'bizcharts'

import { arqRange, colors, forcasRange } from '../../../../utils/utils'
import { Container, ArquetipoBox } from './styles'

import Select, { Option } from '../../../../components/select/Select'

function culturaConsensuada({
  intl,
  handleChangeModal,
  cultura,
  forcasEstrategicas,
  regra,
  autoridade,
  inovacao,
  empreendedora,
  complacencia,
  aprendizado,
  cla,
  colaboracao,
  id,
  error,
  tooltipVisible,
  regra_limite,
  autoridade_limite,
  inovacao_limite,
  empreendedora_limite,
  complacencia_limite,
  aprendizado_limite,
  cla_limite,
  colaboracao_limite,
}) {
  const limites = [
    { value: 'min', text: 'Mínimo' },
    { value: 'max', text: 'Máximo' },
  ]
  const LimiteSelect = ({ name, value }) => {
    return (
      <Select
        placeholder="Selecione"
        onChange={(option) =>
          handleChangeModal({
            target: { name, value: option },
          })
        }
        defaultValue="min"
        value={value}
        style={{ width: '100%' }}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {limites.map(({ value, text }) => (
          <Option value={value} key={value}>
            {text}
          </Option>
        ))}
      </Select>
    )
  }

  const tipFormatterFitCultural = (value) => {
    if (value >= 16.7) {
      return <span>Muito Alto</span>
    }
    if (value >= 13) {
      return <span>Alto</span>
    }
    if (value >= 9.7) {
      return <span>Médio</span>
    }
    if (value >= 6) {
      return <span>Baixo</span>
    }
    return <span>Muito Baixo</span>
  }

  return (
    <Col span={24}>
      <Form layout="vertical">
        <Row>
          <Col xs={11} sm={11} md={11}>
            <Row>
              <Col xs={24} sm={24}>
                <Form.Item
                  label="Regra"
                  validateStatus={error.regra ? 'error' : ''}
                  help={error.regra || ''}
                >
                  <ArquetipoBox>
                    <Slider
                      value={regra}
                      min={0}
                      step={0.5}
                      max={100}
                      name="regra"
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'regra', value: e },
                        })
                      }
                      tipFormatter={tipFormatterFitCultural}
                      tooltipVisible
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={regra}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'regra', value: e },
                        })
                      }
                    />
                    <LimiteSelect name="regra_limite" value={regra_limite} />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item
                  label="Autoridade"
                  validateStatus={error.autoridade ? 'error' : ''}
                  help={error.autoridade || ''}
                >
                  <ArquetipoBox>
                    <Slider
                      value={autoridade}
                      defaultValue={autoridade}
                      min={0}
                      step={0.5}
                      max={100}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'autoridade', value: e },
                        })
                      }
                      tipFormatter={tipFormatterFitCultural}
                      tooltipVisible
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={autoridade}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'autoridade', value: e },
                        })
                      }
                    />
                    <LimiteSelect
                      name="autoridade_limite"
                      value={autoridade_limite}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item
                  label="Inovação"
                  validateStatus={error.inovacao ? 'error' : ''}
                  help={error.inovacao || ''}
                >
                  <ArquetipoBox>
                    <Slider
                      value={inovacao}
                      defaultValue={inovacao}
                      step={0.5}
                      min={0}
                      max={100}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'inovacao', value: e },
                        })
                      }
                      tipFormatter={tipFormatterFitCultural}
                      tooltipVisible
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={inovacao}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'inovacao', value: e },
                        })
                      }
                    />
                    <LimiteSelect
                      name="inovacao_limite"
                      value={inovacao_limite}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item
                  label="Empreendedora"
                  validateStatus={error.empreendedora ? 'error' : ''}
                  help={error.empreendedora || ''}
                >
                  <ArquetipoBox>
                    <Slider
                      value={empreendedora}
                      defaultValue={empreendedora}
                      min={0}
                      step={0.5}
                      max={100}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'empreendedora', value: e },
                        })
                      }
                      tipFormatter={tipFormatterFitCultural}
                      tooltipVisible
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={empreendedora}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'empreendedora', value: e },
                        })
                      }
                    />
                    <LimiteSelect
                      name="empreendedora_limite"
                      value={empreendedora_limite}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={2} sm={2} md={2} />
          <Col xs={11} sm={11} md={11}>
            <Row>
              <Col xs={24} sm={24}>
                <Form.Item
                  label="Complacência"
                  validateStatus={error.complacencia ? 'error' : ''}
                  help={error.complacencia || ''}
                >
                  <ArquetipoBox>
                    <Slider
                      value={complacencia}
                      defaultValue={complacencia}
                      min={0}
                      step={0.5}
                      max={100}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'complacencia', value: e },
                        })
                      }
                      tipFormatter={tipFormatterFitCultural}
                      tooltipVisible
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={complacencia}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'complacencia', value: e },
                        })
                      }
                    />
                    <LimiteSelect
                      name="complacencia_limite"
                      value={complacencia_limite}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item
                  label="Aprendizado"
                  validateStatus={error.aprendizado ? 'error' : ''}
                  help={error.aprendizado || ''}
                >
                  <ArquetipoBox>
                    <Slider
                      value={aprendizado}
                      defaultValue={aprendizado}
                      min={0}
                      step={0.5}
                      max={100}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'aprendizado', value: e },
                        })
                      }
                      tipFormatter={tipFormatterFitCultural}
                      tooltipVisible
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={aprendizado}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'aprendizado', value: e },
                        })
                      }
                    />
                    <LimiteSelect
                      name="aprendizado_limite"
                      value={aprendizado_limite}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item
                  label="Clã"
                  validateStatus={error.cla ? 'error' : ''}
                  help={error.cla || ''}
                >
                  <ArquetipoBox>
                    <Slider
                      value={cla}
                      defaultValue={cla}
                      min={0}
                      step={0.5}
                      max={100}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'cla', value: e },
                        })
                      }
                      tipFormatter={tipFormatterFitCultural}
                      tooltipVisible
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={cla}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'cla', value: e },
                        })
                      }
                    />
                    <LimiteSelect name="cla_limite" value={cla_limite} />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item
                  label="Colaboração"
                  validateStatus={error.colaboracao ? 'error' : ''}
                  help={error.colaboracao || ''}
                >
                  <ArquetipoBox>
                    <Slider
                      defaultValue={colaboracao}
                      value={colaboracao}
                      min={0}
                      step={0.5}
                      max={100}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'colaboracao', value: e },
                        })
                      }
                      tipFormatter={tipFormatterFitCultural}
                      tooltipVisible
                      tooltipPlacement="bottom"
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      step={0.5}
                      value={colaboracao}
                      onChange={(e) =>
                        handleChangeModal({
                          target: { name: 'colaboracao', value: e },
                        })
                      }
                    />
                    <LimiteSelect
                      name="colaboracao_limite"
                      value={colaboracao_limite}
                    />
                  </ArquetipoBox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} lg={12} style={{ marginTop: '16px' }}>
            <Chart height={400} data={cultura} scale={arqRange} autoFit>
              <Coordinate type="polar" radius={0.8} />
              <Axis
                name="Tipo"
                line={null}
                tickLine={null}
                grid={{
                  lineStyle: {
                    lineDash: null,
                  },
                  hideFirstLine: false,
                }}
              />
              <Tooltip />
              <Axis
                name="Valor"
                line={null}
                tickLine={null}
                grid={{
                  type: 'polygon',
                  lineStyle: {
                    lineDash: null,
                  },
                  alternateColor: 'rgba(0, 0, 0, 0.04)',
                }}
              />
              <Line position="Tipo*Valor" color={['Label', colors]} size={2} />
              <Point
                position="Tipo*Valor"
                color={['Label', colors]}
                shape="circle"
                size={4}
                style={{
                  stroke: '#fff',
                  lineWidth: 1,
                  fillOpacity: 1,
                }}
                state
              />
            </Chart>
          </Col>
          <Col xs={24} sm={24} lg={12} style={{ marginTop: '16px' }}>
            <Chart
              height={400}
              data={forcasEstrategicas}
              scale={forcasRange}
              autoFit
            >
              <Legend />
              <Axis name="Tipo" />
              <Axis name="Valor" />
              <Tooltip
                crosshairs={{
                  type: 'y',
                }}
              />
              <Interval
                adjust={[
                  {
                    type: 'dodge',
                    marginRatio: 1 / 32,
                  },
                ]}
                position="Tipo*Valor"
                color={['Label', colors]}
              />
            </Chart>
          </Col>
        </Row>
      </Form>
    </Col>
  )
}

export default culturaConsensuada
