import { Button, Table } from 'antd'
import { CSVLink } from 'react-csv'

type Props = {
  data: any
}

const App = (props: Props) => {
  return (
    <div style={{ marginTop: 50 }}>
      <Button type="primary" style={{ marginBottom: 20 }}>
        <CSVLink
          filename="Reports.csv"
          data={props.data}
          className="btn btn-primary"
          onClick={() => {
            console.log('The file is downloading')
          }}
        >
          Exportar para CSV
        </CSVLink>
      </Button>
    </div>
  )
}

export default App
