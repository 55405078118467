// redux types
export const GET_DASHBOARD_CANDIDATO_COMPLETO =
  'GET_DASHBOARD_CANDIDATO_COMPLETO'
export const POST_DASHBOARD_NEW_LINK = 'POST_DASHBOARD_NEW_LINK'

// urls
export const URL_DASHBOARD = `${process.env.REACT_APP_API_URL}/dashboard`
export const URL_DASHBOARD_CANDIDATO_COMPLETO = `${process.env.REACT_APP_API_URL}/vetor/candidatos`
export const URL_DASHBOARD_NEW_LINK = (pes_id, avalia_id) =>
  `${process.env.REACT_APP_API_URL}/share/vetor/pessoa/${pes_id}/${avalia_id}`

// constants
export const vetorDefault = {
  pes_nome: undefined,
  avalia_id: undefined,
  hash: undefined,
  cultura: [],
  perfilComp: [],
  intensidade: [
    {
      row: '',
      essencia: '',
      exigido: '',
      entrega: '',
    },
  ],
  rct: [],
  edl: [],
  foc: [],
  ode: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  oct: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  tdd: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  cor: [],
  erc: [],
  edc: [],
  principal: {},
  vetores: [],
  fitCulturalEmpresa: {},
}

// Utils
export const FORCAS = ['Mercado', 'Cliente', 'Operação', 'Ambiente interno']
export const OLHARES = ['Olhar externo', 'Olhar interno']
export const GESTAO = ['Controle', 'Flexibilidade']
export const DISC = ['D', 'I', 'S', 'C']
export const CULTURAS = [
  'Inovação',
  'Empreendedora',
  'Colaboração',
  'Clã',
  'Aprendizado',
  'Complacência',
  'Regra',
  'Autoridade',
]

export const filtrosDefault = {
  generos: ['M', 'F'],
  perfil: {
    d: {
      min: 0,
      max: 100,
    },
    i: {
      min: 0,
      max: 100,
    },
    s: {
      min: 0,
      max: 100,
    },
    c: {
      min: 0,
      max: 100,
    },
  },
  idade: {
    min: 0,
    max: 100,
  },
  arquetipos: {
    inovacao: 0,
    empreendedora: 0,
    colaboracao: 0,
    aprendizado: 0,
    cla: 0,
    regra: 0,
    complacencia: 0,
    autoridade: 0,
  },
}
