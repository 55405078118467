import React from 'react'
import { injectIntl } from 'react-intl'
import { Row, Col, Steps, Card, Checkbox, Popover, Modal } from 'antd'

// Components
import Container from '../../../../components/container/Container'
import Button from '../../../../components/button/Button'
import Introducao from './components/Introducao'
import PesquisasPendentes from '../../../../components/PesquisasPendentes'

// Services
import { getEmpresa } from '../../../../services/auth'

// CSS
import './PerfilComportamental.css'

// Constants
import { STEPS } from './constants/constantsPesquisas'

// Assets
import { ReactComponent as Svg } from '../../../../assets/success.svg'

const { Step } = Steps

function PerfilComportamentalView({
  intl,
  history,
  name,
  currentStep,
  checkboxesSelecionados,
  showModal,
  nextStep,
  prevStep,
  onSubmit,
  handleChangeCheckbox,
  caracteristicas,
  showHints,
  handleHintHover,
  showIntro,
  handleInit,
  pesquisasPendentes = [],
  conta_url,
}) {
  const contaURL = getEmpresa()

  return (
    <div className="perfil-comportamental">
      <div className="perfil-comportamental__header">
        <div className="perfil-comportamental__header__title">
          <span>
            <b>Pesquisa de Perfil Comportamental</b>
          </span>
        </div>
        {!showIntro && (
          <span className="perfil-comportamental__header__subtitle">
            Selecione as opções disponíveis nos cards abaixo.
          </span>
        )}
      </div>

      {showIntro && <Introducao handleNext={handleInit} />}
      {!showIntro && (
        <Row>
          <Col xs={{ offset: 1, span: 22 }} md={{ offset: 2, span: 20 }}>
            <Container className="perfil-comportamental__content">
              <Col xs={24} sm={24} md={0}>
                <Steps
                  current={currentStep}
                  className="perfil-comportamental__content__steps"
                  direction="vertical"
                >
                  <Step
                    key={1}
                    title="Passo 1"
                    description="Quem acho que sou"
                  />
                  <Step
                    key={2}
                    title="Passo 2"
                    description="O que exigem de mim"
                  />
                  <Step
                    key={3}
                    title="Passo 3"
                    description="O que consigo entregar"
                  />
                </Steps>
              </Col>
              <Col xs={0} sm={0} md={24}>
                <Steps
                  current={currentStep}
                  className="perfil-comportamental__content__steps"
                  direction="horizontal"
                >
                  <Step
                    key={1}
                    title="Passo 1"
                    description="Quem acho que sou"
                  />
                  <Step
                    key={2}
                    title="Passo 2"
                    description="O que exigem de mim"
                  />
                  <Step
                    key={3}
                    title="Passo 3"
                    description="O que consigo entregar"
                  />
                </Steps>
              </Col>
              <hr />
              <div className="perfil-comportamental__content__card">
                <Card
                  type="inner"
                  title={
                    <span>
                      <center>
                        <b style={{ whiteSpace: 'normal' }}>
                          {STEPS[currentStep].replace('{{NAME}}', name)}
                        </b>
                      </center>
                    </span>
                  }
                >
                  <Row>
                    <Col span={24}>
                      {caracteristicas[currentStep].map((row) => (
                        <Row className="perfil-comportamental__content__row">
                          {row.map((item) => {
                            return (
                              <Col
                                md={{ span: 6 }}
                                xs={{ span: 24 }}
                                className="perfil-comportamental__content__card__checkbox"
                                key={`${currentStep}-${item.id}`}
                              >
                                <Popover
                                  content={
                                    <div className="perfil-comportamental__content__card__popover">
                                      <p>{item.glossario}</p>
                                    </div>
                                  }
                                  title={item.nome}
                                  trigger="hover"
                                  placement="left"
                                  mouseEnterDelay={2}
                                >
                                  <Checkbox
                                    checked={
                                      checkboxesSelecionados[currentStep][
                                        item.id
                                      ]
                                    }
                                    onChange={(evt) =>
                                      handleChangeCheckbox(
                                        evt,
                                        currentStep,
                                        item.id
                                      )
                                    }
                                  >
                                    {item.nome}
                                  </Checkbox>
                                </Popover>
                              </Col>
                            )
                          })}
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Card>
              </div>
              <div className="perfil-comportamental__footer">
                <div>
                  {currentStep > 0 && (
                    <Button color="orange" type="link" onClick={prevStep}>
                      VOLTAR
                    </Button>
                  )}
                </div>

                <div>
                  {currentStep < 2 && (
                    <Button color="orange" type="primary" onClick={nextStep}>
                      PRÓXIMO PASSO
                    </Button>
                  )}
                  {currentStep > 1 && (
                    <Button color="orange" type="primary" onClick={onSubmit}>
                      FINALIZAR
                    </Button>
                  )}
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      )}

      <Modal
        visible={showModal}
        closable={false}
        footer={null}
        width={860}
        className="perfil-comportamental__modal"
      >
        <Svg className="perfil-comportamental__modal__image" />
        <h1>Sua Resposta foi recebida com sucesso!</h1>
        {pesquisasPendentes.length > 0 && (
          <PesquisasPendentes
            pesquisas={pesquisasPendentes}
            conta_url={conta_url}
          />
        )}

        {pesquisasPendentes.length === 0 && (
          <Button
            color="orange"
            type="primary"
            onClick={() =>
              (window.location.href = `https://app.gestaodecultura.com`)
            }
          >
            FINALIZAR
          </Button>
        )}
      </Modal>
    </div>
  )
}

export default injectIntl(PerfilComportamentalView)
