import styled, { css } from 'styled-components'

import { darken, lighten } from 'polished'

import { Item } from './components/CandidatoItem/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;
  min-height: 0;
  flex: 1;
  /* background: rgb(48, 49, 139); */

  /*
	background: linear-gradient(
		135deg,
		rgba(48, 49, 139, 1) 35%,
		rgba(239, 84, 114, 1) 100%
	);
	*/
`

export const FilterIndicator = styled.div`
  margin: auto;
  padding: 0 8px 0 8px;
  display: flex;

  & .anticon {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.6);
  }
`

export const EtapaHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;

  > span {
    font-size: 20px;
    font-weight: 500;
    vertical-align: middle;
  }

  > input {
    font-size: 22px;
    font-weight: 500;
    vertical-align: middle;
    background-color: transparent;
    padding: 4px 8px;
  }

  > div {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    > span {
      font-size: 20px;
      vertical-align: middle;
    }

    > * + * {
      margin-left: 12px;
    }

    .anticon-delete {
      &:hover {
        color: red;
        transition: color 0.2s linear;
        cursor: pointer;
      }
    }

    .anticon-edit {
      &:hover {
        color: var(--purple);
        transition: color 0.2s linear;
        cursor: pointer;
      }
    }

    & .anticon {
      color: rgba(0, 0, 0, 0.75);
    }
  }
`

export const EtapaWrapper = styled.div`
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 100%;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--orange);
    border-color: var(--orange);
  }
`

export const EtapaContainer = styled.div`
  display: flex;
  min-height: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 140px;
  min-width: 330px;
  height: 500px;

  ${(props) =>
    props.loading &&
    css`
      align-items: center;
      justify-content: center;
    `}

  ::-webkit-scrollbar {
    background-color: #1e1f20;
    color: #969490;
  }

  ::-webkit-scrollbar-corner {
    background-color: #161718;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #404243;
  }

  padding: 10px;

  ${Item} + ${Item} {
    margin-top: 12px;
  }

  border-radius: 7px;

  background-color: rgba(0, 0, 0, 0.05);

  overflow-y: auto;
`

export const KanbanContainer = styled.div`
  min-height: 0;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  ${EtapaWrapper} + ${EtapaWrapper} {
    margin-left: 24px;
  }

  padding: 16px;

  > .ant-btn {
    margin: 46px 0px 0px 24px;
  }
`

export const Header = styled.header`
  display: flex;
  width: 98%;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: #fff;
  border-top: 1px solid #ebedf0;
  border-bottom: 1px solid #ebedf0;
  > span {
    font-size: 24px;
    font-weight: 500;
  }

  /* background-color: ${darken(0.1, 'rgba(48, 49, 139, 1)')}; */

  .ps-kanban__button__container {
    display: flex;
    flex-wrap: nowrap;

    .ant-btn + .ant-btn {
      margin-left: 16px;
    }

    .ps-kanban__button__finalizar {
      color: green;
      border-color: green;
      :hover {
        color: ${lighten(0.1, 'green')};
        border-color: ${lighten(0.1, 'green')};
      }
    }

    .ps-kanban__button__enviar-pesquisas {
      color: var(--purple);
      border-color: var(--purple);
      :hover {
        color: ${lighten(0.2, 'rgba(48, 49, 139, 1)')};
        border-color: ${lighten(0.2, 'rgba(48, 49, 139, 1)')};
      }
    }

    .ps-kanban__button__triar-candidatos {
    }
  }

  .ps-kanban__processo-details__container {
    display: flex;
    flex-direction: row;
    margin-left: 64px;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 500;
    }
    .anticon {
      margin-right: 8px;
    }
  }

  .select-filter {
    display: flex;

    .ant-select {
      width: 100%;
    }
  }

  @media screen and (max-width: 1500px) {
    flex-direction: column;
    align-items: center;

    .ps-kanban__button__container {
      margin: 0;
    }

    .ps-kanban__processo-details__container {
      margin: 0;
    }

    > *:not(:first-child) {
      margin-top: 16px;
    }

    .select-filter {
      display: flex;
      width: 100%;
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .ps-kanban__processo-details__container {
      flex-direction: column;
      .ant-divider {
        display: none;
      }

      > *:not(:first-child) {
        margin-top: 16px;
      }
    }

    .ps-kanban__button__container {
      flex-direction: column;
      align-items: center;
      width: 100%;

      > .ant-btn + .ant-btn {
        margin: 0;
        margin-top: 16px;
      }

      > .ant-btn {
        width: 85%;
      }

      .select-filter {
        width: 85%;
        margin-left: 0;
        margin-top: 16px;
      }
    }
  }
`

export const TableHeader = styled.div`
  display: flex;
  vertical-align: flex;

  > span {
    font-size: 16px;
  }

  > * + * {
    margin-left: 8px;
  }
`

export const EtapaEditInputIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    font-size: 20px;

    & + span {
      margin-left: 8px;
    }
  }

  > .anticon-check-circle {
    &:hover {
      color: green;
      transition: color 0.2s linear;
    }
  }

  > .anticon-close-circle {
    &:hover {
      color: red;
      transition: color 0.2s linear;
    }
  }
`
