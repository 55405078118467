import styled from 'styled-components'

import { Table as TableAntd } from 'antd'

export const LinkSpan = styled.span`
  color: var(--purple);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const Table = styled(TableAntd)`
  .ant-table-title {
    padding: 16px 0px;
  }
`
