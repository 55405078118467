import styled from 'styled-components'

import { Button } from 'antd'

export const Label = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #000;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;

    > * + * {
      margin-top: 8px;
    }

    ${Label} {
      flex: 1;
      text-align: center;
    }
  }
`

export const PdiButton = styled(Button)`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
