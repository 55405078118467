import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCulturaOrganizacional } from './actions/olhar360GeralActions'

import Olhar360GeralView from './Olhar360GeralView'

function Olhar360Geral() {
  const culturaOrganizacional = useSelector(
    (state) => state.olhar360Geral.culturaOrganizacional
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCulturaOrganizacional())
  }, [dispatch])

  return <Olhar360GeralView culturaOrganizacional={culturaOrganizacional} />
}

export default Olhar360Geral
