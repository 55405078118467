import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import {
  Modal,
  Row,
  Col,
  Card,
  Alert,
  Popconfirm,
  Divider,
  Tabs,
  Tooltip,
  Select,
} from 'antd'

import moment from 'moment'

import { DeleteOutlined, PoweroffOutlined } from '@ant-design/icons'

import Link from '../../components/privateLink/PrivateLink'
import { logout, isLider, getContaURL } from '../../services/auth'
import history from '../../utils/history'

import DesligamentoModal from '../perfil/components/DesligamentoModal'

// Components
import Button from '../../components/button/Button'
import Table from '../../components/editableTable/EditableTable'

// CSS
import './CadastroColaboradores.css'

// Constants
import { modals } from './constants/constantsCadastroColaboradores'

// Utils
import { sorterString, sorterDate } from '../../utils/utils'
// GetCargos
import { getCargosFromApi } from '../../components/FitCultural/Api/api'

const { TabPane } = Tabs

const { Option } = Select

function CadastroColaboradoresView({
  showModals,
  handleChangeModal,
  onTableSave,
  onTableDelete,
  listaColaboradores,
  rowSelection,
  openModal,
  closeModal,
  closeModalDesligamento,
  submitModalDesligamento,
  onModalSubmit,
  exportarExcel,
  onFileUpload,
  onRemoveFile,
  modalStates,
  okButtonProps,
  handleChangeCheckbox,
  getColumnSearchProps,
  times,
  pcd_tipo,
  cargos,
  excluirPerfil,
  error,
  setCurrentPessoa,
  onboarding = false,
  filiais,
}) {
  const lider = isLider()
  const [processos, setProcessos] = useState([])
  const [showTriar, setTriar] = useState(false)
  const [selectedCargo, setSelectedCargo] = useState(null)

  async function getAllCargos() {
    const response = await getCargosFromApi()
    setProcessos(response.processos)
  }

  window.onbeforeunload = function () {
    localStorage.removeItem('triar')
    return ''
  }
  // Table columns
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'nome'),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) =>
        onboarding ? text : <Link to={`/perfil/${record.key}`}>{text}</Link>,
      ...getColumnSearchProps('nome'),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      editable: true,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'cargo'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('cargo'),
    },
    {
      title: 'Times',
      dataIndex: 'times',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'times'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('times'),
    },
    {
      title: 'Filial',
      dataIndex: 'nome_filial',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'nome_filial'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('nome_filial'),
    },
    {
      title: 'Último Feedback',
      dataIndex: 'ultimofeedback',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'ultimofeedback'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('ultimofeedback'),
    },
    {
      title: '% PDI',
      dataIndex: 'pct_metas',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'pct_metas'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('pct_metas'),
    },
    {
      title: 'Data de Nascimento',
      dataIndex: 'pes_datanascimento',
      editable: true,
      sorter: (a, b) => sorterDate(a, b, 'pes_datanascimento'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('pes_datanascimento'),
    },
    {
      title: 'Data de Admissão',
      dataIndex: 'dataadmissao',
      editable: true,
      sorter: (a, b) => sorterDate(a, b, 'dataadmissao'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dataadmissao'),
    },
  ]

  return (
    <>
      <Helmet>
        <title>TeamHub | Colaboradores</title>
      </Helmet>
      <div className="cadastro-colaboradores">
        <Row>
          <Col md={24}>
            <Tabs
              defaultActiveKey="1"
              onTabClick={(tab) => {
                tab === '2' &&
                  history.push(`/${getContaURL()}/colaboradores/inativos`)
              }}
            >
              <TabPane tab="Ativos" key="1">
                <Card
                  type="inner"
                  title={
                    !lider ? (
                      <b>Adicione seus colaboradores e envie pesquisas.</b>
                    ) : (
                      <b>Visualize seus colaboradores.</b>
                    )
                  }
                >
                  {!lider && (
                    <div className="cadastro-colaboradores__footer">
                      <Alert
                        message={`Selecione colaboradores para enviar pesquisas.
                        Se precisar enviar pesquisas para todos, basta clicar no botão Gerar Pesquisa sem selecionar colaboradores.`}
                        type="info"
                      />
                    </div>
                  )}
                  <div className="cadastro-colaboradores__table">
                    <Table
                      header={() => (
                        <div className="cadastro-colaboradores__table__buttons">
                          <div className="cadastro-colaboradores__table__buttons__container">
                            {!lider && (
                              <Button
                                className="btn_purple"
                                onClick={() => openModal(0)}
                              >
                                GERAR PESQUISA
                              </Button>
                            )}
                            <Button
                              className="gestao-pesquisas__table__button btn_purple"
                              onClick={() => exportarExcel(listaColaboradores)}
                            >
                              EXPORTAR PARA EXCEL
                            </Button>
                          </div>

                          <div className="cadastro-colaboradores__table__buttons__container">
                            {localStorage.getItem('triar') && (
                              <Button
                                type="primary"
                                className="btn_purple"
                                onClick={() => setTriar(true)}
                                style={{ marginRight: 15 }}
                              >
                                Triar
                              </Button>
                            )}
                            {!lider && (
                              <Button
                                type="primary"
                                className="btn_purple"
                                onClick={() => openModal(1)}
                              >
                                IMPORTAR COLABORADORES
                              </Button>
                            )}
                            {!lider && (
                              <Button
                                type="primary"
                                className="btn_purple"
                                onClick={() => openModal(2)}
                              >
                                NOVO COLABORADOR
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                      dataSource={listaColaboradores}
                      columns={columns}
                      onSave={onTableSave}
                      onDelete={onTableDelete}
                      rowSelection={!lider ? rowSelection : false}
                      isDeleble={onboarding ? false : !lider}
                      deleteNode={(_, key) =>
                        !lider && (
                          <Col
                            className="cadastro-colaboradores__action__buttons"
                            style={{
                              marginRight: '12px',
                            }}
                          >
                            <a
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: '8px',
                              }}
                              onClick={() => {
                                setCurrentPessoa(key)
                                openModal(6)
                              }}
                            >
                              <Divider type="vertical" />
                              <Tooltip title="Desligar" placement="bottom">
                                <PoweroffOutlined />
                              </Tooltip>
                            </a>

                            <Divider type="vertical" />
                            <Popconfirm
                              title="Tem certeza que deseja excluir?"
                              onConfirm={() => excluirPerfil(key)}
                              okText="Sim"
                              cancelText="Não"
                            >
                              <a>
                                <Tooltip title="Excluir" placement="bottom">
                                  <DeleteOutlined />
                                </Tooltip>
                              </a>
                            </Popconfirm>
                          </Col>
                        )
                      }
                    />
                  </div>
                </Card>
              </TabPane>
              <TabPane tab="Inativos" key="2" />
            </Tabs>
          </Col>
        </Row>
        {modals.map(
          ({ title, cancelText, okText, id, width, children: Children }) => (
            <Modal
              visible={showModals[id]}
              title={title}
              cancelText={cancelText}
              okText={okText}
              onCancel={() => closeModal(id)}
              onOk={() => onModalSubmit(id)}
              okButtonProps={okButtonProps(id)}
              width={width}
              destroyOnClose
              key={id}
            >
              <Children
                times={times}
                pcd_tipo={pcd_tipo}
                filiais={filiais}
                cargos={cargos}
                handleChangeModal={(event) => handleChangeModal(id, event)}
                handleChangeCheckbox={(event) =>
                  handleChangeCheckbox(id, event)
                }
                onFileUpload={onFileUpload}
                onRemoveFile={onRemoveFile}
                openModal={openModal}
                error={error}
                {...modalStates[id]}
              />
            </Modal>
          )
        )}

        {/* NEW DESLIGAMENTO MODAL */}
        <Modal
          id={6}
          title="Desligar Colaborador?"
          visible={showModals[6]}
          okButtonProps={{ htmlType: 'submit', form: 'desligamento-modal' }}
          onCancel={() => closeModalDesligamento()}
          destroyOnClose
          okText="Sim"
          cancelText="Não"
          centered
        >
          <DesligamentoModal onFinish={submitModalDesligamento} />
        </Modal>

        {/* TRIAR MODAL */}
        <Modal
          id={6}
          title="Triar Colaborador"
          destroyOnClose
          centered
          visible={showTriar}
          onCancel={() => setTriar(false)}
          okText="Triar Colaborador"
          onOk={() => {
            onModalSubmit({ id: 7, selectedCargo })
            setTriar(false)
          }}
        >
          <div>
            <Select
              placeholder="Selecione"
              style={{ width: '100%' }}
              showSearch
              value={selectedCargo}
              onChange={(value) => setSelectedCargo(value)}
            >
              {processos.map(({ cargo, processo_id, inicio, grupo }) => (
                <Option value={processo_id} key={processo_id}>
                  {cargo && cargo.cargo_nome} {grupo && `(${grupo.grupo_nome})`}{' '}
                  - Início em{' '}
                  {moment(inicio)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('DD/MM/YYYY')}
                </Option>
              ))}
            </Select>
            <br />
          </div>
        </Modal>
      </div>
    </>
  )
}

export default injectIntl(CadastroColaboradoresView)
