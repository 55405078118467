import { toast } from 'react-toastify'

import * as constants from '../constants/psPainelConstants'

const initialState = {
  processo: {
    cargo: undefined,
  },
  quiz: undefined,
  motivosCancelamento: [],
}

export const psPainel = (state = initialState, action) => {
  switch (action.type) {
    case constants.PS_PAINEL_PROCESSO_DETAILS_GET: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error('Erro ao obter detalhes do Processo Seletivo.')
        return { ...state }
      }
      const { details } = action.response.data
      return { ...state, processo: details }
    }

    case constants.PS_PAINEL_PROCESSO_QUIZ_GET: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error('Erro ao obter quizes relacionados.')
        return { ...state }
      }

      let quizes = []

      action.response.data.forEach((quiz) => {
        quizes = [...quizes, quiz.quiz[0]]
      })

      return { ...state, quiz: quizes }
    }

    case constants.PS_PAINEL_CLEAR: {
      return { ...initialState }
    }

    case constants.PS_KANBAN_PROCESSO_LIST_MOTIVOSCANCELAMENTO: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error(
            'Erro ao listar motivos de cancelamento do Processo Seletivo.'
          )
        return { ...state }
      }

      const { motivosCancelamento } = action.response.data

      return { ...state, motivosCancelamento }
    }

    default:
      return state
  }
}

export default psPainel
