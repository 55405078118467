import React from 'react'
import { injectIntl } from 'react-intl'

// Components
import { Row, Checkbox, Divider, Col, Select, Tooltip } from 'antd'

// CSS
import './EnviarPesquisa.css'

// Constants

function EnviarPesquisa({
  intl,
  handleChangeCheckbox,
  listPessoas,
  handleChangeModal,
  showEnviarFIlhos,
  isCandidato = false,
}) {
  return (
    <div className="enviar-pesquisa">
      <Row>
        <Col xs={24} sm={24}>
          {listPessoas && (
            <Select
              label="Selecione a pessoa: "
              placeholder="Selecione"
              onChange={(option) =>
                handleChangeModal({
                  target: { name: 'colaborador', value: option },
                })
              }
              style={{ width: '100%' }}
              showSearch
              filterOption={(input, option) =>
                option.props.label.toLowerCase().includes(input.toLowerCase())
              }
              options={listPessoas.map(({ nome, id }) => ({
                value: id,
                label: nome,
              }))}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Checkbox name="vetorComportamental" onChange={handleChangeCheckbox}>
          <Tooltip
            title="Pesquisa para o cálculo do perfil pessoal DISC"
            placement="right"
            mouseEnterDelay={0.5}
          >
            Gerar pesquisa de Perfil Comportamental
          </Tooltip>
        </Checkbox>
      </Row>
      {!isCandidato && (
        <Row>
          <Checkbox name="percepcaoAmbiente" onChange={handleChangeCheckbox}>
            <Tooltip
              title="Pesquisa confidencial onde será calculado a Percepção do Ambiente por times com no mínimo 4 integrantes"
              placement="right"
              mouseEnterDelay={0.5}
            >
              Gerar pesquisa de Percepção do Ambiente
            </Tooltip>
          </Checkbox>
        </Row>
      )}
      <Row>
        <Checkbox name="valoresOrganizacionais" onChange={handleChangeCheckbox}>
          <Tooltip
            title="Pesquisa onde o usuário selecionará o quanto ele se identifica com as afirmativas sobre seus comportamentos no dia-a-dia"
            placement="right"
            mouseEnterDelay={0.5}
          >
            Gerar pesquisa de Valores Organizacionais
          </Tooltip>
        </Checkbox>
      </Row>
      {showEnviarFIlhos && (
        <Row>
          <Checkbox name="enviarGruposFilho" onChange={handleChangeCheckbox}>
            Gerar pesquisa para os grupos filhos do grupo escolhido
          </Checkbox>
        </Row>
      )}
      <Divider />
      <Row>
        <Checkbox name="enviarEmails" onChange={handleChangeCheckbox}>
          Enviar e-mails
        </Checkbox>
      </Row>
    </div>
  )
}

export default injectIntl(EnviarPesquisa)
