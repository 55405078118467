import styled, { css } from 'styled-components'

import { darken, ligthen } from 'polished'

import { Space as antdSpace, Statistic as antdStatistic } from 'antd'

export const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background-color: #fff;
  margin-bottom: 20px;

  & .ant-typography {
    margin: 0;
  }
`

export const AcoesContainer = styled(antdSpace)`
  width: 100%;

  & .ant-btn {
    width: 100%;
  }
`

export const InfoContainer = styled.div`
  width: 100%;
  color: #718096;
  margin: 6px 0;

  & span {
    margin-left: 4px;
  }
`

export const Statistic = styled(antdStatistic)`
  .ant-statistic-content-value {
    font-size: 16px;
    font-weight: 500;
  }

  .ant-statistic-content-suffix {
    font-size: 16px;
  }
`
export const QuestoesContainer = styled.div`
  & article ~ article {
    border-top: 1px solid #ebedf0;
  }
`

export const Questao = styled.article`
  margin-bottom: 12px;
  padding-top: 12px;

  & p {
    color: rgba(0, 0, 0, 0.45);
    margin: 0;
  }
`

export const Alternativa = styled.div`
  padding: 6px;

  & .ant-tag {
    margin: 0 8px;
  }
`
