import { toast } from 'react-toastify'

import * as constants from '../constants/constantsPainelQuiz'

const initialState = {
  titulo: undefined,
}

export const quizPainel = (state = initialState, action) => {
  switch (action.type) {
    case constants.QUIZ_GET: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error('Erro ao obter informações do Teste.')
        return { ...state }
      }

      const { quizz: quiz } = action.response.data

      const {
        quiz_id,
        titulo,
        instrucoes,
        objetivo,
        msg_encerramento,
        tempo_limite,
        nota_min,
        exibir_resultado,
        motivo_suspensao,
        ativo: status,
        created_at: criado_em,
        updated_at: atualizado_em,
        created_by: criado_por,
        updated_by: atualizado_por,
        quizQuestao: questoes,
      } = quiz[0]

      return {
        ...state,
        quiz_id,
        titulo,
        instrucoes,
        objetivo,
        msg_encerramento,
        motivo_suspensao,
        tempo_limite,
        nota_min,
        exibir_resultado,
        status,
        criado_em,
        atualizado_em,
        criado_por,
        atualizado_por,
        questoes,
      }
    }

    default:
      return { ...state }
  }
}

export default quizPainel

/* const {
  quiz_id,
  titulo,
  instrucoes,
  objetivo,
  msg_encerramento,
  tempo_limite,
  nota_min,
  ativo: status,
  created_at: criado_em,
  updated_at: atualizado_em,
  quizQuestao: questoes,
} = quiz;

setQuiz({
  quiz_id,
  titulo,
  instrucoes,
  objetivo,
  msg_encerramento,
  tempo_limite,
  nota_min,
  status,
  criado_em,
  atualizado_em,
  questoes,
}); */
