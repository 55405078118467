import { connect } from 'react-redux'

// Components
import TimesDetalhesController from './TimesDetalhesController'
import { sendValoresOrganizacionais } from '../gestaoPesquisasColaboradores/actions/actionsGestaoPesquisas'
import {
  sendPercepcao,
  sendVetorComportamental,
} from '../cadastroColaboradores/actions/actionsCadastroColaboradores'

// Actions
import * as actions from './actions/actionsTimesDetalhes'

import { getCulturaEmpresa } from '../dashboard/containers/Colaboradores/actions'

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, {
  ...actions,
  sendValoresOrganizacionais,
  getCulturaEmpresa,
  sendPercepcao,
  sendVetorComportamental,
})(TimesDetalhesController)
