import * as constants from '../constants'

export const getTemplatesManuais = () => {
  return {
    type: constants.CONFIG_PS_LIST_MANUAL_TEMPLATES,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_PS_LIST_MANUAL_TEMPLATES,
    },
  }
}

export const getEmailsManuaisParams = () => {
  return {
    type: constants.CONFIG_PS_LIST_MANUAL_PARAMS,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_PS_LIST_MANUAL_PARAMS,
    },
  }
}

export const storeNewManualEmailTemplate = (
  descricao,
  assunto,
  cco,
  textoemail
) => {
  return {
    type: constants.CONFIG_PS_STORE_MANUAL_TEMPLATE,
    api: {
      method: 'post',
      url: constants.URL_CONFIG_PS_STORE_MANUAL_TEMPLATE,
      data: {
        descricao,
        assunto,
        cco,
        textoemail,
      },
    },
  }
}

export const updateManualTemplate = (
  id,
  { assunto, descricao, textoemail, cco }
) => {
  return {
    type: constants.CONFIG_PS_UPDATE_MANUAL_TEMPLATE,
    api: {
      method: 'put',
      url: constants.URL_CONFIG_PS_UPDATE_MANUAL_TEMPLATE(id),
      data: {
        assunto,
        descricao,
        textoemail,
        cco,
      },
    },
  }
}

export const deleteManualEmailTemplate = (id) => {
  return {
    type: constants.CONFIG_PS_DELETE_MANUAL_TEMPLATE,
    api: {
      method: 'delete',
      url: constants.URL_CONFIG_PS_DELETE_MANUAL_TEMPLATE(id),
    },
  }
}

// Anexos de e-mails automaticos
export const getAnexosTemplatesAutomaticos = () => {
  return {
    type: constants.CONFIG_PS_LIST_ANEXO_AUTOMATICO_TEMPLATES,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_PS_LIST_ANEXO_AUTOMATICO_TEMPLATES,
    },
  }
}

// Anexos de e-mails manuais
export const getAnexosTemplatesManuais = () => {
  return {
    type: constants.CONFIG_PS_LIST_ANEXO_MANUAL_TEMPLATES,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_PS_LIST_ANEXO_MANUAL_TEMPLATES,
    },
  }
}

export const storeAnexoManualEmailTemplate = (anexo, id) => {
  return {
    type: constants.CONFIG_PS_STORE_ANEXO_MANUAL_TEMPLATE,
    api: {
      method: 'post',
      url: constants.URL_CONFIG_PS_STORE_ANEXO_MANUAL_TEMPLATE(id),
      data: anexo,
    },
  }
}

export const deleteAnexoManualEmailTemplate = (id) => {
  return {
    type: constants.CONFIG_PS_DELETE_ANEXO_MANUAL_TEMPLATE,
    api: {
      method: 'patch',
      url: constants.URL_CONFIG_PS_DELETE_ANEXO_MANUAL_TEMPLATE(id),
    },
  }
}
