import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Tabs, Card } from 'antd'
import { useLocation } from 'react-router'
import queryString from 'query-string'

// Utils
import history from '../../utils/history'
import { getContaURL } from '../../services/auth'

import PsContrato from './containers/PsContrato/index'

import { isLider } from '../../services/auth'

import { getModulosConta } from '../../services/auth'

const { TabPane } = Tabs

function RecrutamentoSelecao() {
  const lider = isLider()

  const location = useLocation()

  const accountModules = getModulosConta()

  const [activeKey, setActiveKey] = useState(lider ? '4' : '6')

  useEffect(() => {
    if (location.search) {
      const queries = queryString.parse(location.search)

      if (queries.tab) {
        setActiveKey(queries.tab)
      }
    }
  }, [location])

  const hasJobsModule = useMemo(() => {
    const jobsModule = accountModules.find(
      (accountModule) => accountModule.module.name === 'trabalhe_conosco'
    )

    if (!jobsModule || !jobsModule.enabled) return false

    return true
  }, [accountModules])

  const hasQuizModule = useMemo(() => {
    const quizModule = accountModules.find(
      (accountModule) => accountModule.module.name === 'quiz'
    )

    if (!quizModule || !quizModule.enabled) return false

    return true
  }, [accountModules])

  return !lider ? (
    <>
      <Helmet>
        <title>TeamHub | Recrutamento e Seleção</title>
      </Helmet>
      <Tabs
        activeKey={activeKey}
        defaultActiveKey="6"
        onTabClick={(key) => {
          setActiveKey(key)
          key === '1' &&
            history.push(`/${getContaURL()}/recrutamento-selecao/dashboard`)
          key === '2' &&
            history.push(
              `/${getContaURL()}/recrutamento-selecao/processos-seletivos`
            )
          key === '3' &&
            history.push(`/${getContaURL()}/recrutamento-selecao/candidatos`)
          key === '4' &&
            history.push(`/${getContaURL()}/recrutamento-selecao/requisicoes`)
          key === '5' &&
            history.push(`/${getContaURL()}/recrutamento-selecao/testes`)
          key === '6' &&
            history.push(`/${getContaURL()}/recrutamento-selecao/configuracoes`)
          key === '7' &&
            history.push(
              `/${getContaURL()}/recrutamento-selecao/trabalhe-conosco`
            )
        }}
      >
        <TabPane tab={<span>Dashboard</span>} key="1" />
        <TabPane tab={<span>Processo Seletivo</span>} key="2" />
        <TabPane tab={<span>Candidatos</span>} key="3" />
        <TabPane tab={<span>Requisições</span>} key="4" />
        {/* <TabPane tab={<span>Cadastro de Requisitores</span>} key="4">
          <Requisitores />
        </TabPane> */}
        {hasQuizModule && <TabPane tab={<span>Testes</span>} key="5" />}
        <TabPane tab={<span>Configurações</span>} key="6">
          <Card>
            <Tabs
              defaultActiveKey="3"
              onTabClick={(key) => {
                key === '1' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/configuracoes/motivos`
                  )
                key === '2' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/configuracoes/motivos-de-cancelamento`
                  )
                key === '3' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/configuracoes/tipos-de-contratos`
                  )
                key === '4' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/configuracoes/motivos-de-suspensao`
                  )
                key === '5' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/configuracoes/cadastro-de-slas`
                  )
                key === '6' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/configuracoes/workflow`
                  )
              }}
            >
              <TabPane tab="Cadastro de Motivos" key="1" />
              <TabPane tab="Motivos de Cancelamento" key="2" />
              <TabPane tab="Tipos de Contrato" key="3">
                <PsContrato />
              </TabPane>
              <TabPane tab="Motivos de Suspensão" key="4" />
              <TabPane tab="Cadastro de SLAs" key="5" />
              <TabPane tab="Workflow" key="6" />
            </Tabs>
          </Card>
        </TabPane>
        {hasJobsModule && (
          <TabPane tab={<span>Trabalhe Conosco</span>} key="7" />
        )}
      </Tabs>
    </>
  ) : (
    <>
      <Tabs defaultActiveKey="4" activeKey={activeKey}>
        <TabPane tab={<span>Requisições</span>} key="4" />
      </Tabs>
    </>
  )
}

export default RecrutamentoSelecao
