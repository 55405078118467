import * as constants from '../constants/constantsPDT'
import { toast } from 'react-toastify'

const initialState = {
  pdts: [],
}

export const pdt = (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_PDTS_GRUPO: {
      const { data, status } = action.response

      if (status !== 200) {
        toast.error(data.message || 'Erro ao listar pdts.')
        return { ...state, pdts: [] }
      }

      const { pdts } = data

      return {
        ...state,
        pdts,
      }
    }
    case constants.GET_PDT: {
      const { data, status } = action.response
      if (status !== 200) return { ...state }

      const { pdt } = data
      const { pdt_id } = pdt
      const { pdts } = state

      if (pdts && pdts.length > 0) {
        for (let [i, pdtData] of pdts.entries()) {
          if (pdtData.pdt_id === pdt_id) {
            pdts[i] = pdt
          }
          break
        }
      }

      return {
        ...state,
        pdts,
      }
    }
    case constants.STORE_PDT: {
      const { data, status } = action.response
      if (status !== 200) {
        toast.error(data.message)
        return { ...state }
      }

      toast.success(data.message)

      const { pdt } = data

      const { pdts } = state

      return { ...state, pdts: [pdt, ...pdts] }
    }

    case constants.UPDATE_PDT: {
      const { data, status } = action.response
      if (status !== 200) {
        toast.error(data.message || 'Erro ao atualizar PDT.')
      } else toast.success(data.message)
      return { ...state }
    }

    case constants.COMPLETE_PDT: {
      const { data, status } = action.response
      if (status !== 200) {
        toast.error(data.message)
        return { ...state }
      }

      toast.success(data.message)

      const { pdt } = data

      const { pdt_id } = action

      const { pdts } = state

      let newPDTs = pdts

      const pdtIndex = newPDTs.findIndex((f) => f.pdt_id === pdt_id)

      newPDTs[pdtIndex] = pdt

      return { ...state, pdts: [...newPDTs] }
    }

    case constants.DELETE_PDT: {
      const { data, status } = action.response
      if (status !== 200) {
        toast.error(data.message)
      }

      toast.success(data.message)

      const { pdt_id } = action

      const { pdts } = state

      let newPDTs = pdts.filter((f) => f.pdt_id !== pdt_id)

      return { ...state, pdts: [...newPDTs] }
    }

    case constants.CHANGE_PDTS_DATA: {
      const { data } = action

      const { pdt_id, pdt, metas } = data

      const { pdts } = state

      const pdtIndex = pdts.findIndex((pdt) => pdt.pdt_id === pdt_id)

      if (pdtIndex === -1) return { ...state }

      const newPDTs = [...pdts]
      newPDTs[pdtIndex] = null

      let pdtUpdated = pdts.find((pdt) => pdt.pdt_id === pdt_id)

      if (pdt) {
        pdtUpdated = pdt
      }

      if (metas) {
        pdtUpdated.metas = metas
      }

      newPDTs[pdtIndex] = pdtUpdated

      return { ...state, pdts: [...newPDTs] }
    }

    case constants.STORE_META_PDT: {
      const { status, data } = action.response

      if (status !== 200) {
        toast.error(data.message)
        return { ...state }
      }

      toast.success(data.message)

      const { pdt_id } = action

      const { pdts } = state

      let newPDTs = [...pdts]

      const pdtIndex = newPDTs.findIndex((f) => f.pdt_id === pdt_id)

      const { meta } = data

      const metas = newPDTs[pdtIndex].metas || []

      newPDTs[pdtIndex].metas = [...metas, { ...meta, acoes: [] }]

      return { ...state, pdts: [...newPDTs] }
    }
    case constants.UPDATE_META_PDT: {
      const { status, data } = action.response
      if (status !== 200) {
        toast.error(data.message)
      } else toast.success(data.message)
      return { ...state }
    }
    case constants.DELETE_META_PDT: {
      const { status, data } = action.response
      if (status !== 200) {
        toast.error(data.message)
        return { ...state }
      } else toast.success(data.message)

      const { pdt_id, meta_id } = action

      const { pdts } = state

      let newPDTs = [...pdts]

      const pdtIndex = newPDTs.findIndex((f) => f.pdt_id === pdt_id)

      const metas = newPDTs[pdtIndex].metas

      newPDTs[pdtIndex].metas = metas.filter((m) => m.meta_id !== meta_id)

      return { ...state, pdts: [...newPDTs] }
    }
    case constants.STORE_ACAO_PDT: {
      const { status, data } = action.response

      if (status !== 200) {
        toast.error(data.message)
        return { ...state }
      }

      toast.success(data.message)

      const { acao } = data

      const { pdt_id, meta_id } = action

      const { pdts } = state

      let newPDTs = [...pdts]

      const pdtIndex = newPDTs.findIndex((f) => f.pdt_id === pdt_id)

      const metaIndex = newPDTs[pdtIndex].metas.findIndex(
        (m) => m.meta_id === meta_id
      )

      const acoes = newPDTs[pdtIndex].metas[metaIndex].acoes

      newPDTs[pdtIndex].metas[metaIndex].acoes = [...acoes, acao]

      return { ...state, pdts: [...newPDTs] }
    }
    case constants.COMPLETE_ACAO_PDT: {
      const { status, data } = action.response

      if (status !== 200) {
        toast.error(data.message)
        return { ...state }
      }

      toast.success(data.message)

      const { acao } = data

      const { pdt_id, meta_id, acao_id } = action

      const { pdts } = state

      let newPDTs = [...pdts]

      const pdtIndex = newPDTs.findIndex((f) => f.pdt_id === pdt_id)

      const metaIndex = newPDTs[pdtIndex].metas.findIndex(
        (m) => m.meta_id === meta_id
      )

      const acaoIndex = newPDTs[pdtIndex].metas[metaIndex].acoes.findIndex(
        (a) => a.acao_id === acao_id
      )

      newPDTs[pdtIndex].metas[metaIndex].acoes[acaoIndex] = {
        ...acao,
      }

      return { ...state, pdts: [...newPDTs] }
    }
    case constants.UPDATE_ACAO_PDT: {
      const { status, data } = action.response
      if (status !== 200) {
        toast.error(data.message)
        return { ...state }
      }

      toast.success(data.message)

      const { acao } = data

      const { pdt_id, meta_id, acao_id } = action

      const { pdts } = state

      let newPDTs = [...pdts]

      const pdtIndex = newPDTs.findIndex((f) => f.pdt_id === pdt_id)

      const metaIndex = newPDTs[pdtIndex].metas.findIndex(
        (m) => m.meta_id === meta_id
      )

      const acaoIndex = newPDTs[pdtIndex].metas[metaIndex].acoes.findIndex(
        (a) => a.acao_id === acao_id
      )

      newPDTs[pdtIndex].metas[metaIndex].acoes[acaoIndex] = {
        ...acao,
      }

      return { ...state, pdts: [...newPDTs] }
    }
    case constants.DELETE_ACAO_PDT: {
      const { status, data } = action.response
      if (status !== 200) {
        toast.error(data.message)
        return { ...state }
      }

      toast.success(data.message)

      const { pdt_id, meta_id, acao_id } = action

      const { pdts } = state

      let newPDTs = [...pdts]

      const pdtIndex = newPDTs.findIndex((f) => f.pdt_id === pdt_id)

      const metaIndex = newPDTs[pdtIndex].metas.findIndex(
        (m) => m.meta_id === meta_id
      )

      const acoes = newPDTs[pdtIndex].metas[metaIndex].acoes

      newPDTs[pdtIndex].metas[metaIndex].acoes = acoes.filter(
        (a) => a.acao_id !== acao_id
      )

      return { ...state, pdts: [...newPDTs] }
    }
    default:
      return state
  }
}
