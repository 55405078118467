import React, { useEffect, useState, useCallback, useMemo } from 'react'

import moment from 'moment'

import 'moment-timezone'

import XLSX from 'xlsx'

import { toast } from 'react-toastify'

import { useSelector, useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Popconfirm,
  Card,
  Modal,
  Divider,
  Statistic,
  Steps,
  Tooltip,
  Tabs,
} from 'antd'

import { LineChart } from 'bizcharts'

import {
  FilterOutlined,
  CheckOutlined,
  UserOutlined,
  FormOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  SolutionOutlined,
  CopyOutlined,
  ProfileOutlined,
} from '@ant-design/icons'

import { sorterString, sorterDate, styleColors } from '../../../../utils/utils'

import ProcessoSeletivoForm from '../../components/ProcessoSeletivoForm'

import EditProcessoSeletivoForm from '../../components/EditProcessoSeletivoForm'

import TabProcessosAtivos from './components/tabProcessosAtivos'

import TabProcessosFinalizados from './components/tabProcessosFinalizados'

import TabProcessosCancelados from './components/tabProcessosCancelados'

import TabProcessosSuspensos from './components/tabProcessosSuspensos'

import RequisicaoHistory from '../Requisicoes/components/RequisicaoHistory'

import { novoProcessoInitialState } from './constants/constantsProcessoSeletivo'

import AdicionarNovoCargo from '../../../cargos/components/adicionarNovoCargo/AdicionarNovoCargo'

import Link from '../../../../components/privateLink/PrivateLink'

import {
  getProcessos,
  storeProcesso,
  updateProcesso,
  deleteProcesso,
  getCargos,
  getTimes,
  getUFs,
  getCidades,
  getKpi,
  suspenderProcesso,
  retomarProcesso,
  getUsuarios,
  getCargoAtividadesList,
  getQuizes,
} from './actions/actionsProcessoSeletivo'
import { postCargo } from '../../../cargos/actions/actionsCargos'
import { listMotivos } from '../PsMotivo/actions/actions'
import { listTipoContrato } from '../PsContrato/actions/actions'
import { listFiliais } from '../../../ConfigFiliais/actions/actions'
import { listSlas } from '../PsSla/actions/actions'

import {
  LinkSpan,
  PercentageBox,
  FlexCard,
  StepBox,
  VagaContainer,
  ContainerTextoLongo,
  ContainerTexto,
} from './styles'
import { parseSalarioFaixa } from '../../utils'

import { getModulosConta } from '../../../../services/auth'

const { Step } = Steps

const { purple, pink } = styleColors

const { TabPane } = Tabs

function ProcessoSeletivo(_, searchInput) {
  const [hunterFilterState, setHunterFilterState] = useState([])

  const [disableSaveButton, setDisableSaveButton] = useState(true)

  const [solicitadorFilterState, setSolicitadorFilterState] = useState([])

  const dispatch = useDispatch()

  const processos = useSelector((state) =>
    state.processoSeletivo.processos.filter((ps) => {
      return (
        ps.status !== 'finalizado' &&
        ps.status !== 'cancelado' &&
        ps.status !== 'suspenso' &&
        !ps.finalizadoem !== false
      )
    })
  )

  const processosSuspensos = useSelector((state) =>
    state.processoSeletivo.processos.filter((ps) => {
      return ps.status === 'suspenso' && ps.finalizadoem === null
    })
  )

  const processosFinalizados = useSelector((state) =>
    state.processoSeletivo.processos.filter((ps) => {
      return (
        ps.status === 'finalizado' ||
        (!ps.finalizadoem !== true && ps.prazo !== 'CANCELADO')
      )
    })
  )

  const processosCancelados = useSelector((state) =>
    state.processoSeletivo.processos.filter((ps) => {
      return ps.status === 'cancelado' || ps.prazo === 'CANCELADO'
    })
  )

  const [toExport, setToExport] = useState([])

  const [loading, setLoading] = useState(false)

  const cargos = useSelector((state) => state.processoSeletivo.cargos)

  const [loadingCargos, setLoadingCargos] = useState(false)

  const times = useSelector((state) => state.processoSeletivo.times)

  const [usuarios, setUsuarios] = useState([])

  const [loadingTimes, setLoadingTimes] = useState(false)

  const ufs = useSelector((state) => state.processoSeletivo.ufs)

  const [loadingUfs, setLoadingUfs] = useState(false)

  const cidades = useSelector((state) => state.processoSeletivo.cidades)

  const [loadingCidades, setLoadingCidades] = useState(false)

  const [showModalNovoProcesso, setShowModalNovoProcesso] = useState(false)

  const [showModalNewCargo, setShowModalNewCargo] = useState(false)

  const [showModalUpdateProcesso, setShowModalUpdateProcesso] = useState(false)

  const [showModalReqHistory, setShowModalReqHistory] = useState(false)

  const [currentProcessoId, setCurrentProcessoId] = useState()

  const [cargoId, setCargoId] = useState(0)

  const [mostrarAtividades, setMostrarAtividades] = useState([])

  const [processo, setProcesso] = useState([])

  const [processoToClone, setProcessoToClone] = useState()

  const kpi = useSelector((state) => state.processoSeletivo.kpi)

  const [loadingKpi, setLoadingKpi] = useState(false)

  const [reqHistory, setReqHistory] = useState([])

  const [motivos, setMotivos] = useState([])
  const [filiais, setfiliais] = useState([])
  const [contratos, setContratos] = useState([])
  const [slas, setSlas] = useState([])

  const [quizes, setQuizes] = useState([])

  const loadProcessos = useCallback(() => {
    setLoading(true)
    dispatch(getProcessos()).then(() => setLoading(false))
  }, [dispatch])

  const loadTipos = useCallback(() => {
    setLoading(true)
    dispatch(getProcessos()).then(() => setLoading(false))
  }, [dispatch])

  const loadUsuarios = useCallback(() => {
    dispatch(getUsuarios()).then(({ response }) => {
      if (response.status === 200) {
        setUsuarios(
          response.data.filter(
            (usuario) => usuario.user_ativo === 1 && usuario.pes_id
          )
        )
      }
    })
  }, [dispatch])

  const loadQuizes = useCallback(() => {
    dispatch(getQuizes()).then(({ response }) => {
      if (response.status === 200) {
        // eslint-disable-next-line prettier/prettier
        setQuizes(response.data.quizz)
      }
    })
  }, [dispatch])

  const handleGetAtividadesList = useCallback(() => {
    dispatch(getCargoAtividadesList()).then(({ response }) => {
      if (response.status === 200) {
        // eslint-disable-next-line prettier/prettier
        console.log('response data', response.data)
        setMostrarAtividades(response.data.atividades)
      }
    })
  }, [dispatch])

  const loadMotivos = useCallback(() => {
    dispatch(listMotivos()).then(({ response }) => {
      if (response.status === 200) {
        setMotivos(response.data.filter((motivo) => motivo.ativo === 1))
      }
    })
  }, [dispatch])

  const loadSlas = useCallback(() => {
    dispatch(listSlas()).then(({ response }) => {
      if (response.status === 200) {
        setSlas(response.data.filter((sla) => sla.ativo === 1))
      }
    })
  }, [dispatch])

  const loadContratos = useCallback(() => {
    dispatch(listTipoContrato()).then(({ response }) => {
      if (response.status === 200) {
        setContratos(response.data.filter((contrato) => contrato.ativo === 1))
      }
    })
  }, [dispatch])

  const loadFiliais = useCallback(() => {
    dispatch(listFiliais()).then(({ response }) => {
      if (response.status === 200) {
        setfiliais(response.data.filter((filial) => filial.ativo === 1))
      }
    })
  }, [dispatch])

  const loadFiltros = useCallback(() => {
    const hunterFilters = localStorage.getItem('@TeamHubGC:ps_hunters')
    const solicitadorFilters = localStorage.getItem(
      '@TeamHubGC:ps_solicitadores'
    )

    if (
      hunterFilters &&
      hunterFilters !== 'undefined' &&
      hunterFilters !== 'null'
    )
      setHunterFilterState(JSON.parse(hunterFilters))
    else setHunterFilterState([])

    if (
      solicitadorFilters &&
      solicitadorFilters !== 'undefined' &&
      solicitadorFilters !== 'null'
    )
      setSolicitadorFilterState(JSON.parse(solicitadorFilters))
    else setSolicitadorFilterState([])
  }, [])

  useEffect(() => {
    setLoadingTimes(true)
    setLoadingCargos(true)
    setLoadingUfs(true)
    loadProcessos()
    loadFiliais()
    loadUsuarios()
    loadTipos()
    loadContratos()
    loadMotivos()
    loadFiltros()
    loadSlas()
    loadQuizes()
    dispatch(getCargos()).then(() => setLoadingCargos(false))
    dispatch(getTimes()).then(() => setLoadingTimes(false))
    dispatch(getUFs()).then(() => setLoadingUfs(false))
    dispatch(getKpi()).then(() => setLoadingKpi(false))
  }, [
    dispatch,
    loadContratos,
    loadFiliais,
    loadProcessos,
    loadUsuarios,
    loadMotivos,
    handleGetAtividadesList,
    loadSlas,
    loadFiltros,
    loadTipos,
    loadQuizes,
  ])

  const exportarExcel = (toExport) => {
    const aoa = [
      [
        'Nro Requisição',
        'Prioridade',
        'Filial',
        'Cidade /UF ',
        'HomeOffice',
        'Cargo',
        'Time',
        'Quantidade',
        'Solicitado por',
        'Hunter',
        'Motivo',
        'Tipo de Recrutamento',
        'Tipo de contrato',
        'Sigiloso',
        'Status',
        // "Etapa",
        'Inscritos',
        'Triados',
        'Contratados',
        'Início',
        'Duração',
        'Observação',
        'Candidatos Contratados',
      ],
      ...toExport.map((processo) => {
        const candidatosEtapas = processo.etapas.map((etapa) =>
          etapa.etapaCandidato.filter(
            (candidato) =>
              candidato.contratado && parseInt(candidato.contratado) === 1
          )
        )
        return [
          processo.numero,
          processo.prioridade === 1
            ? 'Baixa'
            : processo.prioridade === 2
            ? 'Média'
            : processo.prioridade === 3
            ? 'Alta'
            : '',
          processo.filial ? processo.filial.nome : '',
          processo.cidade && processo.cidade.CIDADE
            ? processo.cidade.uf && processo.cidade.uf.UF
              ? `${processo.cidade.CIDADE.trim()} / ${processo.cidade.uf.UF.trim()}`
              : processo.cidade.CIDADE.trim()
            : '',
          processo.homeoffice === 1 ? 'Não' : 'Sim',
          processo.cargo ? processo.cargo.cargo_nome : '',
          processo.grupo ? processo.grupo.grupo_nome : '',
          processo.qtde,
          processo.creator ? processo.creator.pes_nome : '',
          processo.responsible ? processo.responsible.pes_nome : '',
          processo.motivo ? processo.motivo.nome : '',
          processo.tipo === 'I'
            ? 'Interno'
            : processo.tipo === 'E'
            ? 'Externo'
            : processo.tipo === 'H'
            ? 'Híbrido'
            : '',
          processo.contrato ? processo.contrato.nome : '',
          processo.sigoloso ? 'Sim' : 'Não',
          processo.prazo,
          // processo.etapa,
          processo.inscritos,
          processo.triados,
          processo.contratados,
          processo.criadoem &&
            moment(processo.criadoem)
              .utc()
              .tz(moment.tz.guess())
              .format('DD/MM/YYYYY, H:mm'),
          processo.duracao,
          processo.observacao,
          candidatosEtapas
            .reduce((acc, next) => [...acc, ...next], [])
            .reduce(
              (acc, next) =>
                acc
                  ? `${acc}, ${next.candidato.pes_nome}`
                  : next.candidato.pes_nome,
              ''
            ),
        ]
      }),
    ]

    const ws = XLSX.utils.aoa_to_sheet(aoa)

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Export Processos')
    XLSX.writeFile(wb, 'processos.xlsx')
  }

  const huntersOptions = useMemo(() => {
    return usuarios
      .filter((usuario) => usuario.colaborador)
      .filter((usuario) => usuario.usertipo_id === 1 && usuario.pes_id)
      .map((usuario) => ({
        label: usuario.colaborador.pes_nome,
        value: usuario.pes_id,
      }))
  }, [usuarios])
  const huntersFilterOptions = useMemo(() => {
    return huntersOptions.map((hunter) => ({
      text: hunter.label,
      value: hunter.value,
    }))
  }, [huntersOptions])

  const solicitadoresOptions = useMemo(() => {
    const solicitadoresSet = [
      ...new Set(
        processos
          .filter((processo) => processo.creator)
          .map((processo) => JSON.stringify(processo.creator))
      ),
    ]

    return solicitadoresSet
      .filter((solicitador) => solicitador)
      .map((solicitador) => JSON.parse(solicitador))
      .map((solicitador) => ({
        text: solicitador.pes_nome,
        value: solicitador.pes_id,
      }))
  }, [processos])

  const handleTableChange = (pagination, filters, sorter) => {
    if (!filters['responsible.pes_nome']) {
      setHunterFilterState([])
    } else {
      setHunterFilterState([...filters['responsible.pes_nome']])
      localStorage.setItem(
        '@TeamHubGC:ps_hunters',
        JSON.stringify(filters['responsible.pes_nome'])
      )
    }

    if (!filters['creator.pes_nome']) {
      setSolicitadorFilterState([])
    } else {
      setSolicitadorFilterState([...filters['creator.pes_nome']])
      localStorage.setItem(
        '@TeamHubGC:ps_solicitadores',
        JSON.stringify(filters['creator.pes_nome'])
      )
    }
  }

  const clearTableFilters = () => {
    setHunterFilterState([])
    setSolicitadorFilterState([])
    setToExport(processos)

    localStorage.setItem('@TeamHubGC:ps_hunters', JSON.stringify([]))
    localStorage.setItem('@TeamHubGC:ps_solicitadores', JSON.stringify([]))
  }

  const loadCidades = useCallback(
    (UFID) => {
      setLoadingCidades(true)
      dispatch(getCidades(UFID)).then(() => setLoadingCidades(false))
    },
    [dispatch]
  )

  useEffect(() => {
    if (processo.ufid) {
      loadCidades(processo.ufid)
    }
  }, [loadCidades, processo.ufid])

  const handleModalUpdateProcesso = (processo) => {
    console.log(' processo ', processo)
    setProcesso(processo)
    setCurrentProcessoId(processo.processo_id)
    setShowModalUpdateProcesso(true)
  }

  const closeModalNovoProcesso = () => {
    setProcesso(novoProcessoInitialState)
    setCurrentProcessoId(undefined)
    setProcessoToClone(undefined)
    setShowModalNovoProcesso(false)
    setDisableSaveButton(true)
  }

  const closeModalUpdateProcesso = () => {
    setProcesso(novoProcessoInitialState)
    setCurrentProcessoId(undefined)
    setProcessoToClone(undefined)
    setShowModalUpdateProcesso(false)
  }

  const handleChangeProcesso = useCallback((_, allValues) => {
    setProcesso(allValues)
  }, [])

  const handleDeleteProcesso = ({ processo_id }) => {
    dispatch(deleteProcesso(processo_id)).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Processo cancelado com sucesso.')
        loadProcessos()
      } else toast.error('Erro ao cancelar Processo Seletivo.')
    })
  }

  const onFinishNewProcesso = useCallback(
    async (data) => {
      setDisableSaveButton(true)
      const { salario_faixa_min, salario_faixa_max, descricao } = data

      const descricaoNovo = descricao.level
        ? descricao.level.content
        : descricao

      const salario_faixa = await parseSalarioFaixa(
        salario_faixa_min,
        salario_faixa_max
      )

      dispatch(
        storeProcesso({ ...data, salario_faixa, descricao: descricaoNovo })
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Processo cadastrado com sucesso.')
          loadProcessos()
          closeModalNovoProcesso()
        } else toast.error('Erro ao criar Processo Seletivo.')
      })
    },
    [dispatch, loadProcessos]
  )

  /* const onFinishNewProcesso = async (data) => {
    const { salario_faixa_min, salario_faixa_max, descricao } = data;

    const descricaoNovo = descricao.level ? descricao.level.content : descricao;

    const salario_faixa = await parseSalarioFaixa(
      salario_faixa_min,
      salario_faixa_max
    );

    dispatch(
      storeProcesso({ ...data, salario_faixa, descricao: descricaoNovo })
    ).then(({ response }) => {
      if (response.status === 200) {
        toast.success("Processo cadastrado com sucesso.");
        loadProcessos();
        closeModalNovoProcesso();
      } else toast.error("Erro ao criar Processo Seletivo.");
    });
  }; */

  const onFinishUpdateProcesso = async () => {
    const { salario_faixa_min, salario_faixa_max } = processo

    let descricao_new = ''

    /* É criado uma nova key no objeto processo, a key (descricao_v) é removida é deixada apenas a original (descricao) */
    if (processo.descricao_v) {
      if (processo.descricao_v.level) {
        descricao_new = processo.descricao_v.level.content
      } else {
        descricao_new = processo.descricao_v
      }
      processo.descricao = descricao_new
      delete processo.descricao_v
    } else if (processo.descricao) {
      if (processo.descricao.level) {
        descricao_new = processo.descricao.level.content
      } else {
        descricao_new = processo.descricao
      }
    }

    const salario_faixa = await parseSalarioFaixa(
      salario_faixa_min,
      salario_faixa_max
    )

    dispatch(
      updateProcesso({
        ...processo,
        processo_id: currentProcessoId,
        salario_faixa,
        descricao: descricao_new,
      })
    ).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Processo atualizado com sucesso.')
        loadProcessos()
        closeModalUpdateProcesso()
      } else toast.error('Erro ao atualizar Processo Seletivo.')
    })
  }

  const handleSuspenderProcesso = useCallback(
    (processo_id) => {
      dispatch(suspenderProcesso(processo_id)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Processo Seletivo suspenso com sucesso.')
          loadProcessos()
        }
      })
    },
    [dispatch, loadProcessos]
  )

  const handleRetomarProcesso = useCallback(
    (processo_id) => {
      dispatch(retomarProcesso(processo_id)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Processo Seletivo retomado com sucesso.')
          loadProcessos()
        }
      })
    },
    [dispatch, loadProcessos]
  )

  const handleSubmitNewCargo = useCallback(
    (data) => {
      dispatch(postCargo(data)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Cargo cadastrado com sucesso.')
          dispatch(getCargos()).then(() => setLoadingCargos(false))
          setShowModalNewCargo(false)
        }
      })
    },
    [dispatch]
  )

  const contratoOptions = useMemo(() => {
    return contratos.map((contrato) => {
      return {
        label: contrato.nome,
        value: contrato.contrato_id,
      }
    })
  }, [contratos])

  const motivosOptions = useMemo(() => {
    return motivos.map((motivo) => {
      return {
        label: motivo.nome,
        value: motivo.motivo_id,
      }
    })
  }, [motivos])

  const filiaisOptions = useMemo(() => {
    return filiais.map((filial) => {
      return {
        label: filial.nome,
        value: filial.filial_id,
      }
    })
  }, [filiais])

  const slasOptions = useMemo(() => {
    return slas.map((sla) => {
      return {
        label: sla.nome,
        value: sla.sla_id,
      }
    })
  }, [slas])

  const prioridadesOptions = useMemo(() => {
    return [
      { label: 'Baixa', value: 1 },
      { label: 'Média', value: 2 },
      { label: 'Alta', value: 3 },
    ]
  }, [])

  const tiposOptions = useMemo(() => {
    return [
      { label: 'Externo', value: 'E' },
      { label: 'Interno', value: 'I' },
      { label: 'Hibrido', value: 'H' },
    ]
  }, [])

  const userOptions = useMemo(() => {
    return usuarios
      .filter((usuario) => usuario.colaborador)
      .map((usuario) => {
        return {
          label: usuario.colaborador.pes_nome,
          value: usuario.user_id,
        }
      })
  }, [usuarios])

  const handleCloneProcesso = useCallback((processo) => {
    const { processo_id, ...data } = processo
    setProcessoToClone({ ...data })
    setShowModalNovoProcesso(true)
  }, [])

  const tableProcessosColumns = useMemo(() => {
    return [
      {
        title: 'Número',
        dataIndex: 'numero',
        sorter: (a, b) => sorterString(a, b, 'numero'),
        render: (text, record) => {
          if (text && text !== null) {
            return <span>{text.padStart(5, '0')}</span>
          }
        },
      },
      {
        title: 'Vaga',
        dataIndex: ['cargo', 'cargo_nome'],
        width: 200,
        render: (text, record) => (
          <>
            {' '}
            <VagaContainer>
              <div>
                {!record.finalizadoem ? (
                  <Link to={`/ps/${record.processo_id}`}>{text}</Link>
                ) : (
                  <span>{text}</span>
                )}
              </div>
            </VagaContainer>
          </>
        ),
      },
      {
        title: 'Local',
        render: (text, record) => (
          <ContainerTexto>
            {record.homeoffice
              ? 'Home Office'
              : record.cidade && record.cidade.CIDADE
              ? record.cidade.uf && record.cidade.uf.UF
                ? `${record.cidade.CIDADE.trim()} / ${record.cidade.uf.UF.trim()}`
                : record.cidade.CIDADE.trim()
              : ''}
          </ContainerTexto>
        ),
      },
      /*        {
       title: (
          <>
            <span>Vaga</span>
            <br />
            <span>(Local)</span>{" "}
          </>
        ),
        dataIndex: "cargo_nome",
        width: "15%",
        render: (text, record) => (
          <VagaContainer>
            <div>
              <span>{text}</span>
              <span>{record.homeoffice ? "(HomeOffice)" : record.cidade}</span>
              {record.numero && <span>n° {record.numero}</span>}
            </div>
          </VagaContainer>
        ),
        sorter: (a, b) => sorterString(a, b, "cargo_nome"),
        ...getColumnSearchProps("cidade"),
        onFilter: (value, record) => {
          return (
            (record.cargo_nome &&
              record.cargo_nome
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())) ||
            (record.cidade &&
              record.cidade
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())) ||
            "homeoffice"
              .toLowerCase()
              .includes(value.toLowerCase().replace(" ", ""))
          );
        },
      }, */
      {
        title: 'Sigiloso',
        dataIndex: 'sigiloso',
        align: 'center',
        sorter: (a, b) => sorterString(a, b, 'sigiloso'),
        render: (text, record) => (
          <span>{record.sigiloso ? 'Sim' : 'Não'}</span>
        ),
      },
      {
        title: 'Quantidade',
        align: 'center',
        dataIndex: 'qtde',
        sorter: (a, b) => a.qtde - b.qtde,
      },
      {
        title: 'Filial',
        dataIndex: ['filial', 'nome'],
        width: 150,
        align: 'center',
      },
      {
        title: 'Time',
        dataIndex: ['grupo', 'grupo_nome'],
        width: 150,
        render: (text, record) => <ContainerTexto>{text}</ContainerTexto>,
      },
      {
        title: 'Solicitado por',
        width: 50,
        dataIndex: ['creator', 'pes_nome'],
        filters: solicitadoresOptions,
        filteredValue: solicitadorFilterState || [],
        onFilter: (value, record) => {
          return record.creator && record.creator.pes_id === value
        },
        sorter: (a, b) => sorterString(a, b, ['creator', 'pes_nome']),
        render: (text, record) => (
          <ContainerTextoLongo>{text}</ContainerTextoLongo>
        ),
      },
      {
        title: 'Recrutador(a)',
        width: 50,
        dataIndex: ['responsible', 'pes_nome'],
        filters: huntersFilterOptions,
        filteredValue: hunterFilterState || [],
        onFilter: (value, record) => {
          return record.responsible && record.responsible.pes_id === value
        },
        sorter: (a, b) => sorterString(a, b, ['responsible', 'pes_nome']),
        render: (text, record) => (
          <ContainerTextoLongo>{text}</ContainerTextoLongo>
        ),
      },
      {
        title: 'Prioridade',
        dataIndex: 'prioridade',
        align: 'center',
        sorter: (a, b) => sorterString(a, b, 'prioridade'),
        render: (text, record) => {
          const prioridade = prioridadesOptions.find((p) => p.value === text)

          return <>{prioridade && <span> {prioridade.label} </span>}</>
        },
      },
      {
        title: 'Inscritos',
        dataIndex: 'inscritos',
        align: 'center',
        sorter: (a, b) => a.inscritos - b.inscritos,
      },
      {
        title: 'Contrato',
        dataIndex: ['contrato', 'nome'],
        align: 'center',
      },
      {
        title: 'Triados',
        dataIndex: 'triados',
        sorter: (a, b) => a.triados - b.triados,
        render: (text, record) => {
          return (
            <PercentageBox>
              <span>{text}</span>
              <span>({record.pct_triados || 0}%)</span>
            </PercentageBox>
          )
        },
      },
      {
        title: 'Tipo',
        dataIndex: 'tipo',
        render: (text, record) => {
          const tipo = tiposOptions.find((p) => p.value === record.tipo)
          return <>{tipo && <span>{tipo.label}</span>}</>
        },
      },
      {
        title: 'Contratados',
        dataIndex: 'contratados',
        sorter: (a, b) => a.contratados - b.contratados,
        render: (text, record) => {
          return (
            <>
              <PercentageBox>
                <span>{text}</span>
                <span>({record.pct_contratados || 0}%)</span>
              </PercentageBox>
            </>
          )
        },
      },
      {
        title: 'Motivo',
        dataIndex: ['motivo', 'nome'],
      },
      {
        title: 'Início',
        dataIndex: 'criadoem',
        align: 'center',
        render: (text, record) =>
          text
            ? moment(text)
                .utc()
                .tz(moment.tz.guess())
                .format('DD/MM/YYYY (HH:mm)')
            : '',
        sorter: (a, b) => sorterDate(a, b, 'criadoem', 'DD/MM/YYYY'),
      },
      {
        title: (
          <>
            <span>Duração</span>
            <br />
            <span>(Etapa)</span>
          </>
        ),
        dataIndex: 'duracao',
        align: 'center',
        render: (text, record) => {
          const lastEtapa =
            record.etapas && record.etapas.length > 0
              ? record.etapas.reduce(
                  (acc, next) =>
                    next.etapaCandidato && next.etapaCandidato.length > 0
                      ? next
                      : acc,
                  null
                )
              : null
          return (
            <>
              <span>{text} dias</span>
              {lastEtapa && (
                <>
                  <br />
                  <span>({lastEtapa.etapa})</span>
                </>
              )}
            </>
          )
        },
        sorter: (a, b) => a.duracao - b.duracao,
      },
      {
        title: 'Status',
        dataIndex: 'prazo',
        align: 'center',
        width: 150,
        render: (text, record) => {
          return record.suspenso ? (
            <>
              <p>(Suspenso)</p> <p>{record.prazo}</p>
            </>
          ) : (
            <ContainerTexto>{text}</ContainerTexto>
          )
        },
      },
      {
        title: 'Ações',
        align: 'center',
        key: 'acoes',
        width: 200,
        fixed: 'right',
        render: (text, record) => {
          return (
            <>
              {!record.finalizadoem && (
                <Row
                  style={{
                    width: '150px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title="Editar">
                    <LinkSpan
                      onClick={() => {
                        handleModalUpdateProcesso(record)
                        console.log('record ', record)
                      }}
                    >
                      <FormOutlined />
                    </LinkSpan>
                  </Tooltip>
                  {!record.suspenso ? (
                    <>
                      <Divider type="vertical" />
                      <Popconfirm
                        title="Tem certeza que deseja suspender o Processo Seletivo?"
                        onConfirm={() =>
                          handleSuspenderProcesso(record.processo_id)
                        }
                        okText="Sim"
                        cancelText="Não"
                      >
                        <Tooltip title="Suspender">
                          <LinkSpan>
                            <PauseCircleOutlined />
                          </LinkSpan>{' '}
                        </Tooltip>
                      </Popconfirm>
                    </>
                  ) : (
                    <>
                      <Divider type="vertical" />
                      <Popconfirm
                        title="Tem certeza que deseja retomar o Processo Seletivo?"
                        onConfirm={() =>
                          handleRetomarProcesso(record.processo_id)
                        }
                        okText="Sim"
                        cancelText="Não"
                      >
                        <Tooltip title="Retomar">
                          <LinkSpan>
                            <PlayCircleOutlined />
                          </LinkSpan>
                        </Tooltip>
                      </Popconfirm>
                    </>
                  )}
                  {record.requisicao &&
                    record.history &&
                    record.history.length > 0 && (
                      <>
                        <Divider type="vertical" />
                        <Tooltip title="Historico">
                          <LinkSpan
                            onClick={() => {
                              setShowModalReqHistory(true)
                              setReqHistory(record.history)
                            }}
                          >
                            {' '}
                            <SolutionOutlined />
                          </LinkSpan>
                        </Tooltip>
                      </>
                    )}

                  {/* <Divider type='vertical' />
                    <Popconfirm
                      title='Tem certeza que deseja cancelar esse Processo Seletivo?'
                      okText='Sim'
                      cancelText='Não'
                      icon={
                        <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                      }
                      onConfirm={() => handleDeleteProcesso(record)}
                    >
                      <LinkSpan>Excluir</LinkSpan>
                    </Popconfirm>
                    */}
                  {!record.suspenso && (
                    <>
                      <Divider type="vertical" />
                      <Tooltip title="Painel">
                        <LinkSpan>
                          {' '}
                          <Link to={`/ps-kanban/${record.processo_id}`}>
                            <ProfileOutlined />
                          </Link>
                        </LinkSpan>
                      </Tooltip>
                      <Divider type="vertical" />
                      <Tooltip title="Clonar vaga" placement="top">
                        <LinkSpan>
                          <CopyOutlined
                            onClick={() => handleCloneProcesso(record)}
                          />
                        </LinkSpan>
                      </Tooltip>
                    </>
                  )}
                </Row>
              )}
            </>
          )
        },
      },
    ]
  }, [
    solicitadoresOptions,
    solicitadorFilterState,
    huntersFilterOptions,
    hunterFilterState,
    prioridadesOptions,
    tiposOptions,
    handleSuspenderProcesso,
    handleRetomarProcesso,
    handleCloneProcesso,
  ])

  return (
    <Col span={24}>
      {kpi && (
        <Row gutter={16}>
          <Col xs={24} sm={24} xxl={10} style={{ marginBottom: '16px' }}>
            <FlexCard>
              <Statistic
                title="Em andamento"
                value={kpi.andamento}
                valueStyle={{ color: purple }}
              />

              <Statistic
                title="Atrasados"
                value={kpi.atrasados || 0}
                suffix={
                  kpi.atrasados > 0 ? (
                    <span>
                      (
                      {(
                        (parseInt(kpi.atrasados) / parseInt(kpi.andamento)) *
                        100
                      ).toFixed(1)}
                      %)
                    </span>
                  ) : null
                }
                valueStyle={{ color: 'orange' }}
              />

              <Statistic
                title="Em dia"
                value={kpi.emDia || 0}
                suffix={
                  kpi.emDia > 0 ? (
                    <span>
                      (
                      {(
                        (parseInt(kpi.emDia) / parseInt(kpi.andamento)) *
                        100
                      ).toFixed(1)}
                      %)
                    </span>
                  ) : null
                }
                valueStyle={{ color: 'blue' }}
              />

              <Statistic
                title="Concluídos"
                value={kpi.concluidos}
                valueStyle={{ color: 'green' }}
              />

              <Statistic
                title="Cancelados"
                value={kpi.cancelados}
                valueStyle={{ color: 'red' }}
              />

              <Statistic
                title="Duração Média"
                value={`${kpi.duracaoMedia} dias`}
                valueStyle={{ color: pink }}
              />
            </FlexCard>
          </Col>
          <Col xs={24} sm={24} xxl={7} style={{ marginBottom: '16px' }}>
            <FlexCard noPadding center>
              <LineChart
                data={kpi.inscricoes15dias}
                title={{
                  visible: true,
                  text: 'Inscrições últimos 15 dias',
                  style: {
                    fontFamily: 'inherit',
                    fontSize: 16,
                  },
                }}
                xField="DATA"
                yField="qtde"
                height={150}
                forceFit
                xAxis={{ visible: false }}
              />
            </FlexCard>
          </Col>
          <Col xs={24} sm={24} xxl={7} style={{ marginBottom: '16px' }}>
            <FlexCard>
              <Steps>
                <Step
                  status="finish"
                  title={
                    <StepBox>
                      <span>Inscritos</span>
                      <span className="value value-inscritos">
                        {kpi.inscritos}
                      </span>
                    </StepBox>
                  }
                  icon={<UserOutlined />}
                />
                <Step
                  status="finish"
                  title={
                    <StepBox>
                      <span>Triados</span>
                      <div>
                        <span className="value  value-triados">
                          {kpi.triados}
                        </span>
                        <span className="pct">
                          (
                          {(
                            (parseInt(kpi.triados) / parseInt(kpi.inscritos)) *
                            100
                          ).toFixed(1)}
                          %)
                        </span>
                      </div>
                    </StepBox>
                  }
                  icon={<FilterOutlined />}
                />
                <Step
                  status="finish"
                  title={
                    <StepBox>
                      <span>Contratados</span>
                      <div>
                        <span className="value  value-contratados">
                          {kpi.contratados}
                        </span>{' '}
                        <span className="pct">
                          (
                          {(
                            (parseInt(kpi.contratados) /
                              parseInt(kpi.inscritos)) *
                            100
                          ).toFixed(1)}
                          %)
                        </span>
                      </div>
                    </StepBox>
                  }
                  icon={<CheckOutlined />}
                />
              </Steps>
            </FlexCard>
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={24} sm={24}>
          <Card title="Processos Seletivos">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Ativos" key="1">
                <TabProcessosAtivos
                  setShowModalNovoProcesso={setShowModalNovoProcesso}
                  solicitadoresOptions={solicitadoresOptions}
                  solicitadorFilterState={solicitadorFilterState}
                  huntersFilterOptions={huntersFilterOptions}
                  hunterFilterState={hunterFilterState}
                  prioridadesOptions={prioridadesOptions}
                  tiposOptions={tiposOptions}
                  handleModalUpdateProcesso={handleModalUpdateProcesso}
                  handleSuspenderProcesso={handleSuspenderProcesso}
                  handleRetomarProcesso={handleRetomarProcesso}
                  handleCloneProcesso={handleCloneProcesso}
                  handleTableChange={handleTableChange}
                  clearTableFilters={clearTableFilters}
                  exportarExcel={exportarExcel}
                  setToExport={setToExport}
                  toExport={toExport}
                  loading={loading}
                  processos={processos}
                  setShowModalReqHistory={setShowModalReqHistory}
                  setReqHistory={setReqHistory}
                />
              </TabPane>
              <TabPane tab="Suspensos" key="2">
                <TabProcessosSuspensos
                  setShowModalNovoProcesso={setShowModalNovoProcesso}
                  solicitadoresOptions={solicitadoresOptions}
                  solicitadorFilterState={solicitadorFilterState}
                  huntersFilterOptions={huntersFilterOptions}
                  hunterFilterState={hunterFilterState}
                  prioridadesOptions={prioridadesOptions}
                  tiposOptions={tiposOptions}
                  handleModalUpdateProcesso={handleModalUpdateProcesso}
                  handleSuspenderProcesso={handleSuspenderProcesso}
                  handleRetomarProcesso={handleRetomarProcesso}
                  handleCloneProcesso={handleCloneProcesso}
                  handleTableChange={handleTableChange}
                  clearTableFilters={clearTableFilters}
                  exportarExcel={exportarExcel}
                  setToExport={setToExport}
                  toExport={toExport}
                  loading={loading}
                  processosSuspensos={processosSuspensos}
                  setShowModalReqHistory={setShowModalReqHistory}
                  setReqHistory={setReqHistory}
                />
              </TabPane>
              <TabPane tab="Finalizados" key="3">
                <TabProcessosFinalizados
                  setShowModalNovoProcesso={setShowModalNovoProcesso}
                  solicitadoresOptions={solicitadoresOptions}
                  solicitadorFilterState={solicitadorFilterState}
                  huntersFilterOptions={huntersFilterOptions}
                  hunterFilterState={hunterFilterState}
                  prioridadesOptions={prioridadesOptions}
                  tiposOptions={tiposOptions}
                  handleSuspenderProcesso={handleSuspenderProcesso}
                  handleRetomarProcesso={handleRetomarProcesso}
                  handleCloneProcesso={handleCloneProcesso}
                  handleTableChange={handleTableChange}
                  clearTableFilters={clearTableFilters}
                  exportarExcel={exportarExcel}
                  setToExport={setToExport}
                  toExport={toExport}
                  loading={loading}
                  processosFinalizados={processosFinalizados}
                />
              </TabPane>
              <TabPane tab="Cancelados" key="4">
                <TabProcessosCancelados
                  setShowModalNovoProcesso={setShowModalNovoProcesso}
                  solicitadoresOptions={solicitadoresOptions}
                  solicitadorFilterState={solicitadorFilterState}
                  huntersFilterOptions={huntersFilterOptions}
                  hunterFilterState={hunterFilterState}
                  prioridadesOptions={prioridadesOptions}
                  tiposOptions={tiposOptions}
                  handleSuspenderProcesso={handleSuspenderProcesso}
                  handleRetomarProcesso={handleRetomarProcesso}
                  handleCloneProcesso={handleCloneProcesso}
                  handleTableChange={handleTableChange}
                  clearTableFilters={clearTableFilters}
                  exportarExcel={exportarExcel}
                  setToExport={setToExport}
                  toExport={toExport}
                  loading={loading}
                  processosCancelados={processosCancelados}
                />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>

      {/* NEW PROCESSO MODAL */}
      <Modal
        title="NOVO PROCESSO SELETIVOS"
        visible={showModalNovoProcesso}
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => closeModalNovoProcesso()}
        maskClosable={false}
        key="1"
        width="60%"
        destroyOnClose
        okButtonProps={{
          form: 'req-processo-seletivo',
          key: 'submit',
          htmlType: 'submit',
          disabled: disableSaveButton,
        }}
        centered
      >
        <ProcessoSeletivoForm
          getModulosConta={getModulosConta}
          onFinish={onFinishNewProcesso}
          setDisableSaveButton={setDisableSaveButton}
          times={times}
          loadingTimes={loadingTimes}
          cargos={cargos}
          loadingCargos={loadingCargos}
          ufs={ufs}
          loadingUfs={loadingUfs}
          cidades={cidades}
          loadingCidades={loadingCidades}
          usuarios={userOptions}
          motivos={motivosOptions}
          contratos={contratoOptions}
          filiais={filiaisOptions}
          slas={slasOptions}
          loadCidades={loadCidades}
          huntersOptions={huntersOptions}
          setCargoId={setCargoId}
          mostrarAtividades={mostrarAtividades}
          quizes={quizes}
          type="create"
        />
      </Modal>

      {/* UPDATE PROCESSO MODAL */}
      <Modal
        title="EDITAR PROCESSO SELETIVO"
        visible={showModalUpdateProcesso}
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => closeModalUpdateProcesso()}
        destroyOnClose
        key="2"
        okButtonProps={{
          form: 'req-editar-processo-seletivo',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
        width="60%"
      >
        {console.log('mostrar atividades  ', mostrarAtividades)}
        <EditProcessoSeletivoForm
          onValuesChange={handleChangeProcesso}
          onFinish={onFinishUpdateProcesso}
          times={times}
          loadingTimes={loadingTimes}
          cargos={cargos}
          loadingCargos={loadingCargos}
          ufs={ufs}
          loadingUfs={loadingUfs}
          cidades={cidades}
          loadingCidades={loadingCidades}
          usuarios={userOptions}
          motivos={motivosOptions}
          contratos={contratoOptions}
          filiais={filiaisOptions}
          initialValues={{ ...processo }}
          loadCidades={loadCidades}
          slas={slasOptions}
          huntersOptions={huntersOptions}
          setCargoId={setCargoId}
          mostrarAtividades={mostrarAtividades}
          quizes={quizes}
          type="edit"
        />
      </Modal>
      <Modal
        title="HISTÓRICO DA REQUISIÇÃO"
        visible={showModalReqHistory}
        cancelText="FECHAR"
        onCancel={() => {
          setShowModalReqHistory(false)
          setReqHistory([])
        }}
        okButtonProps={{ style: { display: 'none' } }}
        destroyOnClose
        centered
      >
        <RequisicaoHistory history={reqHistory} />
      </Modal>
      <Modal
        title="ADICIONAR NOVO CARGO"
        visible={showModalNewCargo}
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => setShowModalNewCargo()}
        destroyOnClose
        okButtonProps={{
          form: 'novo-cargo-submit',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <AdicionarNovoCargo onFinish={handleSubmitNewCargo} />
      </Modal>
    </Col>
  )
}

export default ProcessoSeletivo
