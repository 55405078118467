import React, { useEffect, useState, useCallback } from 'react'

import { Helmet } from 'react-helmet'

import { useSelector, useDispatch } from 'react-redux'

import { Select, Row, Col, Card, Tooltip } from 'antd'

import {
  getCultura,
  getCulturaOrganizacional,
  load,
  getNps,
} from './actions/analiseGeralActions'

// import { Container } from './styles';

import AnaliseGeralView from '../../components/AnaliseGeral'

function AnaliseGeral() {
  const dispatch = useDispatch()

  const [nps, setNPS] = useState([])

  const cultura = useSelector((state) => state.analiseGeral.cultura)

  const culturaOrganizacional = useSelector(
    (state) => state.analiseGeral.culturaOrganizacional
  )

  const loadingCulturaOrg = useSelector(
    (state) => state.analiseGeral.loadingCulturaOrganizacional
  )

  const loadingCultura = useSelector(
    (state) => state.analiseGeral.loadingCultura
  )

  const loadNPS = useCallback(() => {
    dispatch(getNps()).then(({ response }) => {
      if (response.status === 200) {
        const nps = response.data
        setNPS(nps)
      } else {
        const nps = 'Não há dados'
        setNPS(nps)
      }
    })
  }, [dispatch])

  useEffect(() => {
    loadNPS()
  }, [dispatch, loadNPS])

  useEffect(() => {
    dispatch(load())
    dispatch(getCultura())
    dispatch(getCulturaOrganizacional())
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>TeamHub | Análise Geral</title>
      </Helmet>
      <Row gutter={8} type>
        <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: '8px' }}>
          <Card title="Detalhes Adicionais">
            <Row gutter={16}>
              <span style={{ marginRight: '5px' }}>e-NPS: </span>
              {nps === 'Não há dados' ? (
                'Não há dados. É necessário que, ao menos, 4 pesquisas válidas de e-NPS tenham sido realizadas. '
              ) : nps >= 75 ? (
                <Tooltip color="green" title="Escala (100 a -100): Excelente">
                  {nps} (excelente).
                </Tooltip>
              ) : nps >= 50 ? (
                <Tooltip color="lime" title="Escala (100 a -100): Muito Bom">
                  {nps} (muito bom).
                </Tooltip>
              ) : nps >= 0 ? (
                <Tooltip color="yellow" title="Escala (100 a -100): Razoável">
                  {nps} (razoável).
                </Tooltip>
              ) : (
                <Tooltip color="gold" title="Escala (100 a -100): Ruim">
                  {nps} (ruim).
                </Tooltip>
              )}
              <span style={{ marginLeft: '5px' }}>
                Legenda: Excelente: entre 75 e 100; Muito bom: entre 50 e 74;
                Razoável: entre 0 e 49; Ruim: entre -100 e -1.{' '}
              </span>
            </Row>
          </Card>
        </Col>
      </Row>
      <AnaliseGeralView
        cultura={cultura}
        culturaOrganizacional={culturaOrganizacional}
        loadingCultura={loadingCultura}
        loadingCulturaOrg={loadingCulturaOrg}
      />
    </>
  )
}

export default AnaliseGeral
