import * as constants from '../constants'

export const getTemplates = () => {
  return {
    type: constants.CONFIG_PS_LIST_TEMPLATES,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_PS_LIST_TEMPLATES,
    },
  }
}

export const updateTemplate = (
  id,
  { textoemail, ativo, assunto, cco, anexo }
) => {
  return {
    type: constants.CONFIG_PS_UPDATE_TEMPLATE,
    api: {
      method: 'put',
      url: constants.URL_CONFIG_PS_UPDATE_TEMPLATE(id),
      data: {
        textoemail,
        ativo,
        assunto,
        cco,
      },
    },
  }
}

// Anexos de e-mails automaticos
export const getAnexosTemplatesAutomaticos = () => {
  return {
    type: constants.CONFIG_PS_LIST_ANEXO_AUTOMATICO_TEMPLATES,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_PS_LIST_ANEXO_AUTOMATICO_TEMPLATES,
    },
  }
}

export const storeAnexoAutomaticoEmailTemplate = (anexo, id) => {
  return {
    type: constants.CONFIG_PS_STORE_ANEXO_AUTOMATICO_TEMPLATE,
    api: {
      method: 'post',
      url: constants.URL_CONFIG_PS_STORE_ANEXO_AUTOMATICO_TEMPLATE(id),
      data: anexo,
    },
  }
}

export const deleteAnexoAutomaticoEmailTemplate = (id) => {
  return {
    type: constants.CONFIG_PS_DELETE_ANEXO_AUTOMATICO_TEMPLATE,
    api: {
      method: 'patch',
      url: constants.URL_CONFIG_PS_DELETE_ANEXO_AUTOMATICO_TEMPLATE(id),
    },
  }
}
