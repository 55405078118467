import styled from 'styled-components'

import { Collapse as AntdCollapse } from 'antd'

export const Collapse = styled(AntdCollapse)`
  background-color: #fff;

  .ant-collapse-item {
    & + .ant-collapse-item {
      margin-top: 8px;
    }
    border: 0px;
    overflow: hidden;
    background-color: #fcfcfc;
  }
`

export const StepTitle = styled.div`
  > span {
    & + span {
      margin-left: 8px;
    }
  }

  .anticon-delete {
    &:hover {
      color: red;
      cursor: pointer;
    }
  }

  .anticon-edit {
    &:hover {
      color: var(--purple);
      cursor: pointer;
    }
  }
`
