import * as constants from '../constants'

export const getColaboradores = () => {
  return {
    type: constants.GET_DASHBOARD_COLABORADORES,
    api: {
      method: 'get',
      url: constants.URL_GET_DASHBOARD_COLABORADORES,
    },
  }
}

export const getVetorCompleto = (id) => {
  return {
    type: constants.GET_DASHBOARD_VETOR_COMPLETO,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_VETOR_COMPLETO + id,
    },
    pes_id: id,
  }
}

export const getVetor = (id, avalia_id = null) => {
  return {
    type: constants.GET_DASHBOARD_VETOR,
    api: {
      method: 'get',
      url: !avalia_id
        ? constants.URL_DASHBOARD_VETOR + id
        : `${constants.URL_DASHBOARD_VETOR}${id}/${avalia_id}`,
    },
    pes_id: id,
    avalia_id,
  }
}

export const getCultura = (id) => {
  return {
    type: constants.GET_DASHBOARD_CULTURA_PESSOA_INDIVIDUAL,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_CULTURA_PESSOA + id,
    },
  }
}

export const newLink = (pes_id, avalia_id) => {
  return {
    type: constants.POST_DASHBOARD_NEW_LINK,
    api: {
      method: 'post',
      url: constants.URL_DASHBOARD_NEW_LINK(pes_id, avalia_id),
    },
  }
}

export const getHistoricoVetor = (id) => {
  return {
    type: constants.GET_DASHBOARD_HISTORICO_VETOR_INDIVIDUAL,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_HISTORICO_VETOR_INDIVIDUAL(id),
    },
  }
}

export const getCulturaEmpresa = () => {
  return {
    type: constants.GET_DASHBOARD_CULTURAS,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_CULTURAS,
    },
  }
}
