import { styleColors } from './utils'

const { purple } = styleColors

export const noPadding = {
  padding: '0px',
}

export const cardHeadBlue = {
  color: '#fff',
  backgroundColor: purple,
}
