import React, { useState, useEffect, useMemo } from 'react'

import { Form, Input, Select, Checkbox } from 'antd'
import { sorterString } from '../../../../utils/utils'

export default function NovoUsuario({
  onFinish,
  userTypes,
  colaboradores,
  initialValues,
  handleChangePessoa,
  filiais,
  type = 'create',
  timesOptions = [],
  loadingTimes = false,
}) {
  const [form] = Form.useForm()

  const filiaisSorted = useMemo(() => {
    return filiais.sort((a, b) => sorterString(a, b, 'label'))
  }, [filiais])

  const [userType, setUserType] = useState(1)

  const [hasFilial, setHasFilial] = useState(!!initialValues.filial_id)

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        grupos: initialValues.grupos
          ? initialValues.grupos.map((grupo) => grupo.grupo_id)
          : [],
      })
    }
  }, [form, initialValues])

  useEffect(() => {
    setUserType(form.getFieldValue('usertipo_id'))
  }, [form])

  return (
    <Form
      layout="vertical"
      id="novo-usuario"
      onFinish={onFinish}
      initialValues={
        initialValues
          ? {
              ...initialValues,
              grupos: initialValues.grupos
                ? initialValues.grupos.map((grupo) => grupo.grupo_id)
                : [],
            }
          : undefined
      }
      form={form}
    >
      <Form.Item
        label="Colaborador"
        name="pes_id"
        rules={[
          {
            required: true,
            message:
              'Por favor, vincule este usuário a um colaborador existente.',
          },
        ]}
      >
        <Select
          options={colaboradores}
          showSearch
          onChange={(option) => {
            handleChangePessoa(option)
          }}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </Form.Item>
      <Form.Item
        label="E-mail"
        name="user_email"
        rules={[
          {
            required: true,
            message: 'Por favor, informe o e-email do usuário.',
          },
        ]}
      >
        <Input type="text" style={{ width: '100%' }} />
      </Form.Item>
      {type === 'create' && (
        <Form.Item
          label="Senha"
          name="user_senha"
          rules={[
            {
              required: true,
              message:
                'Por favor, informe a senha do usuário. (Ele poderá alterá-la depois)',
            },
          ]}
        >
          <Input type="password" style={{ width: '100%' }} />
        </Form.Item>
      )}
      <Form.Item
        label="Tipo do usuário"
        name="usertipo_id"
        rules={[
          {
            required: true,
            message: 'Por favor, informe o tipo do usuário.',
          },
        ]}
      >
        <Select options={userTypes} onChange={(value) => setUserType(value)} />
      </Form.Item>

      {userType === 3 && (
        <>
          <Form.Item label="Times do usuário" name="grupos">
            <Select
              mode="multiple"
              allowClear
              placeholder="Selecione os times"
              options={timesOptions}
              loading={loadingTimes}
              filterOption={(input, option) =>
                option.props.label.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            />
          </Form.Item>
          <Form.Item name="requisitor" valuePropName="checked">
            <Checkbox>
              Este usuário pode fazer requisições de processos seletivos
            </Checkbox>
          </Form.Item>
        </>
      )}

      <Form.Item label="Filial" name="filial_id">
        <Select
          placeholder="Selecione a filial"
          showSearch
          options={filiaisSorted}
          suffixIcon={null}
          onChange={(value) => setHasFilial(value)}
          filterOption={(input, option) =>
            option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </Form.Item>
      {userType === 1 && (
        <Form.Item name="ps_filial_filtro" valuePropName="checked">
          <Checkbox disabled={!hasFilial}>
            Exibir os Processos Seletivos somente da filial do usuario
          </Checkbox>
        </Form.Item>
      )}
    </Form>
  )
}
