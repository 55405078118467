export default {
  'en-US': {
    common: {
      name: 'Name',
      name_placeholder: 'Enter your name',
      sobrenome: 'Last name',
      sobrenome_placeholder: 'Enter your last name',
      email: 'E-mail',
      email_placeholder: 'Enter your email',
      telefone: 'Phone',
      senha: 'Password',
      senha_placeholder: 'Enter a password',
      confirmacao_senha: 'Confirm your password',
      confirmacao_senha_placeholder: 'Confirm the Password',
    },
    step1: {
      title: "It's time to create your TeamHub!",
      subtitle:
        'Fill in the information below to create your Free Plan account.',
      submit: 'CREATE ACCOUNT ON THE FREE PLAN',
    },
  },
  'pt-BR': {
    common: {
      name: 'Nome',
      name_placeholder: 'Digite seu nome',
      sobrenome: 'Sobrenome',
      sobrenome_placeholder: 'Digite seu sobrenome',
      email: 'E-mail',
      email_placeholder: 'Digite seu e-mail',
      telefone: 'Telefone',
      senha: 'Senha',
      senha_placeholder: 'Digite uma senha',
      confirmacao_senha: 'Confirme sua senha',
      confirmacao_senha_placeholder: 'Confirme a senha',
    },
    step1: {
      title: 'É hora de criar o seu TeamHub!',
      subtitle: 'Preencha os dados abaixo para criar sua conta no Plano Free.',
      submit: 'CRIAR CONTA NO PLANO FREE',
    },
  },
}
