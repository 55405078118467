// types
export const PS_KANBAN_CLEAR = 'PS_KANBAN_CLEAR'

export const PS_KANBAN_CANDIDATOS_LIST = 'PS_KANBAN_CANDIDATOS_LIST'

export const PS_KANBAN_PROCESSO_DETAILS_GET = 'PS_KANBAN_PROCESSO_DETAILS_GET'

export const PS_KANBAN_PROCESSO_ETAPAS_LIST = 'PS_KANBAN_PROCESSO_ETAPAS_GET'

export const PS_KANBAN_PROCESSO_ETAPAS_STORE = 'PS_KANBAN_PROCESSO_ETAPAS_STORE'

export const PS_KANBAN_PROCESSO_ETAPAS_DELETE =
  'PS_KANBAN_PROCESSO_ETAPAS_DELETE'

export const PS_KANBAN_PROCESSO_UPDATE_ETAPA_CANDIDATO =
  'PS_KANBAN_PROCESSO_UPDATE_ETAPA_CANDIDATO'

export const PS_KANBAN_PROCESSO_RATE_CANDIDATO =
  'PS_KANBAN_PROCESSO_RATE_CANDIDATO'

export const PS_KANBAN_PROCESSO_DELETE_CANDIDATO =
  'PS_KANBAN_PROCESSO_DELETE_CANDIDATO'

export const PS_KANBAN_PROCESSO_CONTRATAR_CANDIDATO =
  'PS_KANBAN_PROCESSO_CONTRATAR_CANDIDATO'

export const PS_KANBAN_PROCESSO_VALIDATE_CONTRATACAO = `PS_KANBAN_PROCESSO_VALIDATE_CONTRATACAO`

export const PS_KANBAN_PROCESSO_LIST_MOTIVOSCANCELAMENTO =
  'PS_KANBAN_PROCESSO_LIST_MOTIVOSCANCELAMENTO'

export const PS_KANBAN_PROCESSO_CANCEL_PROCESSO =
  'PS_KANBAN_PROCESSO_CANCEL_PROCESSO'

export const PS_KANBAN_PROCESSO_FINALIZAR_PROCESSO =
  'PS_KANBAN_PROCESSO_FINALIZAR_PROCESSO'

export const PS_KANBAN_PROCESSO_VALIDATE_FINALIZAR_PROCESSO =
  'PS_KANBAN_PROCESSO_VALIDATE_FINALIZAR_PROCESSO'

export const PS_KANBAN_CHECK_HAS_HUNTER = 'PS_KANBAN_CHECK_HAS_HUNTER'

export const PS_KANBAN_UPDATE_HUNTER = 'PS_KANBAN_UPDATE_HUNTER'

export const PS_KANBAN_GET_CANDIDATOS_DISPONIVEIS =
  'PS_KANBAN_GET_CANDIDATOS_DISPONIVEIS'

export const PS_KANBAN_GET_CANDIDATOS_DISPONIVEIS_PROCESSO =
  'PS_KANBAN_GET_CANDIDATOS_DISPONIVEIS_PROCESSO'

export const PS_KANBAN_GET_COLABORADORES_DISPONIVEIS_PROCESSO =
  'PS_KANBAN_GET_COLABORADORES_DISPONIVEIS_PROCESSO'

export const PS_KANBAN_PROCESSO_ETAPAS_UPDATE =
  'PS_KANBAN_PROCESSO_ETAPAS_UPDATE'

export const PS_KANBAN_CHECK = 'PS_KANBAN_CHECK'

export const PS_KANBAN_PROCESSO_ETAPA_CANDIDATO_ENVIAR_EMAIL_MANUAL =
  'PS_KANBAN_PROCESSO_ETAPA_CANDIDATO_ENVIAR_EMAIL_MANUAL'

export const CONFIG_PS_LIST_MANUAL_TEMPLATES = 'CONFIG_PS_LIST_MANUAL_TEMPLATES'

export const CONFIG_PS_LIST_MANUAL_PARAMS = 'CONFIG_PS_LIST_MANUAL_PARAMS'

// URLS
const URL_BASE = `${process.env.REACT_APP_API_URL}`

const URL_PS_KANBAN_PROCESSO = (processo_id) =>
  `${process.env.REACT_APP_API_URL}/ps/${processo_id}`

export const URL_PS_KANBAN_CHECK = (processo_id) =>
  URL_PS_KANBAN_PROCESSO(processo_id)

export const URL_PS_KANBAN_CANDIDATOS_LIST = (processo_id) =>
  `${URL_PS_KANBAN_PROCESSO(processo_id)}/candidatos`

export const URL_PS_KANBAN_PROCESSO_DETAILS_GET = (processo_id) =>
  `${URL_PS_KANBAN_PROCESSO(processo_id)}/details`

// ETAPAS
const URL_PS_KANBAN_PROCESSO_ETAPAS = (processo_id) =>
  `${URL_PS_KANBAN_PROCESSO(processo_id)}/etapas`

export const URL_PS_KANBAN_PROCESSO_ETAPAS_LIST = (processo_id) =>
  URL_PS_KANBAN_PROCESSO_ETAPAS(processo_id)

export const URL_PS_KANBAN_PROCESSO_ETAPAS_STORE = (processo_id) =>
  URL_PS_KANBAN_PROCESSO_ETAPAS(processo_id)

export const URL_PS_KANBAN_PROCESSO_ETAPAS_DELETE = (processo_id, etapa_id) =>
  `${URL_PS_KANBAN_PROCESSO_ETAPAS(processo_id)}/${etapa_id}`

export const URL_PS_KANBAN_PROCESSO_ETAPAS_UPDATE = (processo_id, etapa_id) =>
  `${URL_PS_KANBAN_PROCESSO_ETAPAS(processo_id)}/${etapa_id}`

// ETAPA CANDIDATO
const URL_PS_KANBAN_PROCESSO_ETAPA_CANDIDATO = (processo_id, etapacand_id) =>
  `${URL_PS_KANBAN_PROCESSO(processo_id)}/etapacand/${etapacand_id}`

// ENVIAR EMAIL MANUAL
export const URL_PS_KANBAN_PROCESSO_ETAPA_CANDIDATO_ENVIAR_EMAIL_MANUAL = (
  processo_id,
  etapacand_id,
  id_email,
  etapa_id
) =>
  `${URL_PS_KANBAN_PROCESSO(
    processo_id
  )}/etapacand/${etapacand_id}/${id_email}/email/${etapa_id}`

export const URL_PS_KANBAN_PROCESSO_UPDATE_ETAPA_CANDIDATO = (
  processo_id,
  etapacand_id
) => URL_PS_KANBAN_PROCESSO_ETAPA_CANDIDATO(processo_id, etapacand_id)

export const URL_PS_KANBAN_PROCESSO_RATE_CANDIDATO = (
  processo_id,
  etapacand_id
) => `${URL_PS_KANBAN_PROCESSO_ETAPA_CANDIDATO(processo_id, etapacand_id)}/rate`

export const URL_PS_KANBAN_PROCESSO_DELETE_CANDIDATO = (
  processo_id,
  etapacand_id
) => `${URL_PS_KANBAN_PROCESSO_ETAPA_CANDIDATO(processo_id, etapacand_id)}`

export const URL_PS_KANBAN_PROCESSO_CONTRATAR_CANDIDATO = (
  processo_id,
  etapacand_id
) =>
  `${URL_PS_KANBAN_PROCESSO_ETAPA_CANDIDATO(
    processo_id,
    etapacand_id
  )}/contratar`

export const URL_PS_KANBAN_PROCESSO_VALIDATE_CONTRATACAO = (
  processo_id,
  etapacand_id
) =>
  `${URL_PS_KANBAN_PROCESSO_ETAPA_CANDIDATO(
    processo_id,
    etapacand_id
  )}/validateContratacao`

export const URL_PS_KANBAN_PROCESSO_LIST_MOTIVOSCANCELAMENTO = `${process.env.REACT_APP_API_URL}/ps/cancel`

export const URL_PS_KANBAN_PROCESSO_CANCEL_PROCESSO = (processo_id) =>
  `${URL_PS_KANBAN_PROCESSO(processo_id)}`

export const URL_PS_KANBAN_PROCESSO_FINALIZAR_PROCESSO = (processo_id) =>
  `${URL_PS_KANBAN_PROCESSO(processo_id)}/finalizar`

export const URL_PS_KANBAN_PROCESSO_VALIDATE_FINALIZAR_PROCESSO = (
  processo_id
) => `${URL_PS_KANBAN_PROCESSO(processo_id)}/validateFinalizacao`

export const URL_PS_KANBAN_GET_CANDIDATOS_DISPONIVEIS = (processo_id) =>
  `${URL_PS_KANBAN_PROCESSO(processo_id)}/candidatos/disponiveis`

export const URL_PS_KANBAN_GET_CANDIDATOS_DISPONIVEIS_PROCESSO = (
  processo_id
) => `${process.env.REACT_APP_API_URL}/candidatos/disponiveis/${processo_id}`

export const URL_PS_KANBAN_GET_COLABORADORES_DISPONIVEIS_PROCESSO = (
  processo_id
) => `${process.env.REACT_APP_API_URL}/colaboradores/disponiveis/${processo_id}`

const URL_PS_EMAILS_MANUAL = `${process.env.REACT_APP_API_URL}/psManualEmails`

export const URL_CONFIG_PS_LIST_MANUAL_TEMPLATES = URL_PS_EMAILS_MANUAL

export const URL_CONFIG_PS_LIST_MANUAL_PARAMS = `${URL_PS_EMAILS_MANUAL}/params`

// PS HUNTER
export const URL_PS_KANBAN_CHECK_HAS_HUNTER = (processo_id) =>
  `${URL_PS_KANBAN_PROCESSO(processo_id)}/hunter`

export const URL_PS_KANBAN_UPDATE_HUNTER = (processo_id) =>
  `${URL_PS_KANBAN_PROCESSO(processo_id)}/hunter`
export const candidatoUpdateInitialState = {
  observacao: undefined,
}

//Fit Minimo
export const CONFIG_PESQUISAS_GET_FIT_MINIMO = 'CONFIG_PESQUISAS_GET_FIT_MINIMO'
export const URL_CONFIG_PESQUISAS_GET_FIT_MINIMO = `${process.env.REACT_APP_API_URL}/config/pesquisas/pesos/fitminimo`
