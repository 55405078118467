import React from 'react'
import { injectIntl } from 'react-intl'
import { Modal, Row, Col } from 'antd'

// Components
import Input, { Password, MaskedInput } from '../../components/input/Input'
import Button from '../../components/button/Button'

// CSS
import './Cadastro.css'

function CadastroView({ intl, handleChange, onSubmit }) {
  return (
    <Modal
      visible
      centered
      mask={false}
      footer={null}
      closable={false}
      wrapClassName="cadastro"
    >
      <div className="cadastro__header">
        <h2>{intl.formatMessage({ id: 'step1.title' })}</h2>
        <p>{intl.formatMessage({ id: 'step1.subtitle' })}</p>
      </div>
      <form className="cadastro__form" onSubmit={onSubmit}>
        <Row>
          <Col md={24}>
            <Input
              label={intl.formatMessage({ id: 'common.name' })}
              type="text"
              placeholder={intl.formatMessage({
                id: 'common.name_placeholder',
              })}
              name="nome"
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row gutter={20}>
          <Col lg={16} md={24}>
            <Input
              label={intl.formatMessage({ id: 'common.email' })}
              type="email"
              placeholder={intl.formatMessage({
                id: 'common.email_placeholder',
              })}
              name="email"
              onChange={handleChange}
            />
          </Col>
          <Col lg={8} md={24}>
            <MaskedInput
              label={intl.formatMessage({
                id: 'common.telefone',
              })}
              mask="(99) 99999-9999"
              name="telefone"
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Password
              label={intl.formatMessage({
                id: 'common.senha',
              })}
              placeholder={intl.formatMessage({
                id: 'common.senha_placeholder',
              })}
              name="senha"
              onChange={handleChange}
              withoutMargin
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={24}>
            <Input
              label="Nome da empresa"
              type="text"
              placeholder="Digite o nome da empresa"
              name="razaoSocial"
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Input
              label="Defina o endereço do seu TeamHub"
              type="text"
              addonBefore="app.teamhub.com.br/"
              placeholder="razaosocial"
              name="urlEmpresa"
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Button
              type="primary"
              className="btn_orange"
              htmlType="submit"
              block
            >
              CRIAR CONTA NO PLANO FREE
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default injectIntl(CadastroView)
