import React from 'react'

import { Row, Col, Form, Input } from 'antd'

// Types
import { IFeedbackCreate } from '../../@types'

// Components
const { TextArea } = Input

interface IProps {
  onFinish(data: IFeedbackCreate): Promise<void>
}

const AdicionarFeedback: React.FC<IProps> = ({ onFinish }) => {
  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      form={form}
      id="feedback-novo-feedback"
      onFinish={onFinish}
    >
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item name="situacao" label="Situação">
            <TextArea
              placeholder="Detalhar a situação com fatos e dados que sejam recentes."
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item label="Comportamento" name="comportamento">
            <TextArea
              placeholder="Detalhar o comportamento frente a situação."
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item label="Impacto" name="impacto">
            <TextArea
              placeholder="Qual ou quais impactos tal comportamento ocasionou no time, na empresa, etc?"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item name="sugestao" label="Sugestão">
            <TextArea
              placeholder="Em casos de feedback de desenvolvimento (resultado, relacionamento...), ajuste de rotas e/ou comportamento faz se necessário a realização de PDI que está disponível logo abaixo."
              autoSize={{ minRows: 5, maxRows: 7 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default AdicionarFeedback
