import styled from 'styled-components'
import { Col } from 'antd'
export const Container = styled(Col)`
  padding: 0px !important;

  .textual__col {
    margin-bottom: 16px !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
`
