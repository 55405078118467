import React from 'react'

import { Empty, Button, Modal } from 'antd'

import PDTListItem from './components/PDTListItem'

import { PDTContainer, Label, Header } from './styles'

import { pdtModals as modals } from './constants/constantsPDT'

export default function PDTView({
  pes_id,
  pdts,
  getColumnSearchProps,
  handleCompletePDT,
  handleDeletePDT,
  handleCreatePDI,
  handleUpdatePDI,
  handleDeletePDI,
  handleCreateMeta,
  handleUpdateMeta,
  handleDeleteMeta,
  handleCreateAcao,
  handleUpdateAcao,
  handleCompleteAcao,
  handleDeleteAcao,
  handleChangeMetaDescricao,
  handleChangePDTData,
  handleUpdatePDT,
  openModal,
  closeModal,
  onModalSubmit,
  showModals,
  modalStates,
  handleChangeModal,
  editModal,
  error,
  handleGetPessoa,
}) {
  return (
    <PDTContainer>
      <Header>
        <Label>PDTs</Label>
        <Button type="primary" onClick={() => openModal(2)}>
          NOVO PDT
        </Button>
      </Header>
      {pdts && pdts.length > 0 ? (
        <>
          {pdts.map((pdt, i) => {
            return (
              <PDTListItem
                active={i === 0}
                data={pdt}
                getColumnSearchProps={getColumnSearchProps}
                key={pdt.pdt_id}
                handleDeletePDT={handleDeletePDT}
                handleCompletePDT={handleCompletePDT}
                handleUpdatePDI={handleUpdatePDI}
                handleDeletePDI={handleDeletePDI}
                handleCreateMeta={handleCreateMeta}
                handleUpdateMeta={handleUpdateMeta}
                handleDeleteMeta={handleDeleteMeta}
                handleCreateAcao={handleCreateAcao}
                handleUpdateAcao={handleUpdateAcao}
                handleCompleteAcao={handleCompleteAcao}
                handleDeleteAcao={handleDeleteAcao}
                handleChangeMetaDescricao={handleChangeMetaDescricao}
                handleChangePDTData={handleChangePDTData}
                handleUpdatePDT={handleUpdatePDT}
                openModal={openModal}
                closeModal={closeModal}
                onModalSubmit={onModalSubmit}
                showModals={showModals}
                modalStates={modalStates}
                handleChangeModal={handleChangeModal}
                editModal={editModal}
                error={error}
                handleCreatePDI={handleCreatePDI}
                handleGetPessoa={handleGetPessoa}
                pes_id={pes_id}
              />
            )
          })}
        </>
      ) : (
        <Empty />
      )}
      {modals.map((m) => {
        const { title, cancelText, okText, id, width, children: Children } = m
        return (
          <Modal
            visible={showModals[id]}
            title={title}
            cancelText={cancelText}
            okText={okText}
            onCancel={() => closeModal(id)}
            onOk={() => onModalSubmit(id)}
            width={width}
            destroyOnClose
            key={id}
          >
            <Children
              handleChangeModal={(event) => handleChangeModal(id, event)}
              // handleChangeCheckbox={(event) => handleChangeCheckbox(id, event)}
              modalState={modalStates[id]}
              error={error}
              {...modalStates[id]}
            />
          </Modal>
        )
      })}
    </PDTContainer>
  )
}
