import { Menu } from 'antd'
import { useHistory } from 'react-router-dom'

type Props = {
  selectedKeys: string[]
}

const App = (props: Props) => {
  const navigate = useHistory()

  function handleEmpresas() {
    navigate.push('./empresas')
  }
  function handleGrupos() {
    navigate.push('./grupos')
  }
  function handlePessoas() {
    navigate.push('./pessoas')
  }
  function handleRecrutamento() {
    navigate.push('./recrutamento')
  }
  return (
    <Menu mode="horizontal" selectedKeys={props.selectedKeys}>
      <Menu.Item onClick={handleEmpresas} key="0">
        Empresas
      </Menu.Item>
      <Menu.Item onClick={handleGrupos} key="1">
        Times
      </Menu.Item>
      <Menu.Item onClick={handlePessoas} key="2">
        Pessoas
      </Menu.Item>
      <Menu.Item onClick={handleRecrutamento} key="3">
        Recrutamento e Seleção
      </Menu.Item>
    </Menu>
  )
}

export default App
