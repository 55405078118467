import React from 'react'
import moment from 'moment'

// Components
import EnviarPesquisa from '../../../components/enviarPesquisa/EnviarPesquisa'
import AdicionarNovoCandidato from '../components/adicionarNovoCandidato/AdicionarNovoCandidato'
import EfetivarCandidato from '../components/efetivarCandidato/EfetivarCandidato'
import TriarCandidatos from '../components/TriarCandidatos'
import Confirm from '../../../components/Confirm'

// URLs
export const URL_CANDIDATOS = `${process.env.REACT_APP_API_URL}/candidato`
export const URL_CANDIDATOS_GET = `${URL_CANDIDATOS}/list`
export const URL_CANDIDATO_POST = `${URL_CANDIDATOS}/store`
export const URL_CANDIDATO_EFETIVA = `${URL_CANDIDATOS}/efetiva`
export const URL_CANDIDATOS_POST = `${URL_CANDIDATOS}/storePessoas`
export const URL_CANDIDATO_PUT = `${URL_CANDIDATOS}/update`
export const URL_CANDIDATO_DELETE = `${URL_CANDIDATOS}/delete`
export const URL_CANDIDATOS_SEND_AVALIACOES = `${process.env.REACT_APP_API_URL}/avaliacoes/send`
export const URL_CANDIDATOS_SEND_VALORES = `${process.env.REACT_APP_API_URL}/pesqvalores/sendlote`
export const URL_CARGOS_GET = `${process.env.REACT_APP_API_URL}/cargo/list`
export const URL_SEND_PERCEPCAO = `${process.env.REACT_APP_API_URL}/pesquisa`
export const URL_CANDIDATOS_TRIAR = (processo_id) =>
  `${process.env.REACT_APP_API_URL}/ps/${processo_id}/triar`
export const URL_CANDIDATOS_LIST_PROCESSOS = `${process.env.REACT_APP_API_URL}/ps/pendentes`

// Redux types
export const CANDIDATOS_GET = 'CANDIDATOS_GET'
export const CANDIDATO_POST = 'CANDIDATO_POST'
export const CANDIDATO_EFETIVA = 'CANDIDATO_EFETIVA'
export const CANDIDATOS_POST = 'CANDIDATOS_POST'
export const CANDIDATO_DELETE = 'CANDIDATO_DELETE'
export const CANDIDATO_PUT = 'CANDIDATO_PUT'
export const CANDIDATOS_SEND_AVALIACOES = 'CANDIDATOS_SEND_AVALIACOES'
export const CANDIDATOS_SEND_VALORES = 'CANDIDATOS_SEND_VALORES'
export const CARGOS_GET = 'CARGOS_GET'
export const SEND_PERCEPCAO = 'SEND_PERCEPCAO'
export const CANDIDATOS_TRIAR = 'CANDIDATOS_TRIAR'
export const CANDIDATOS_LIST_PROCESSOS = 'CANDIDATOS_LIST_PROCESSOS'

// Modals
export const modals = [
  {
    id: 0,
    title: 'GERAR PESQUISA PARA OS CANDIDATOS',
    cancelText: 'CANCELAR',
    okText: 'ENVIAR',
    children: EnviarPesquisa,
    isCandidato: true,
    initialState: {
      vetorComportamental: false,
      enviarEmails: false,
      valoresOrganizacionais: false,
      //percepcaoAmbiente: false,
    },
  },
  {
    id: 1,
    title: 'ADICIONAR NOVO CANDIDATO',
    isCandidato: true,
    cancelText: 'CANCELAR',
    okText: (step = 0) => {
      switch (step) {
        case 0:
          return 'PRÓXIMO'
        case 1:
        default:
          return 'SALVAR'
      }
    },
    children: AdicionarNovoCandidato,
    steps: 3,
    initialState: {
      cpf: undefined,
      nome: undefined,
      dataNascimento: moment(),
      email: undefined,
      sexo: 'M',
      telefone: undefined,
      vetorComportamental: false,
      valoresOrganizacionais: false,
      enviarEmails: false,
      profissao: undefined,
      prtsalario: undefined,
      indicacao: undefined,
      linkedin: undefined,
      twitter: undefined,
      site: undefined,
      cep: undefined,
      logradouro: undefined,
      nro: undefined,
      complemento: undefined,
      bairro: undefined,
      ufid: undefined,
      cidadeid: undefined,
      cargosEscolhidos: [],
      curriculo: undefined,
      pcd: 0,
      pcd_tipo: undefined,
      cid: undefined,
    },
  },
  {
    id: 2,
    title: 'ESCOLHA O CARGO PARA EFETIVAR O CANDIDATO',
    isCandidato: true,
    cancelText: 'CANCELAR',
    okText: 'SALVAR',
    children: EfetivarCandidato,
    initialState: {
      cargo: '',
    },
  },
  {
    id: 3,
    title: 'ESCOLHA UM PROCESSO SELETIVO PARA TRIAR OS CANDIDATOS',
    cancelText: 'CANCELAR',
    okText: 'ESCOLHER',
    children: TriarCandidatos,
    initialState: {
      processoSelected: undefined,
    },
  },
  {
    id: 4,
    title: 'CONFIRMAR TRIAGEM CANDIDATOS',
    cancelText: 'CANCELAR',
    okText: 'TRIAR',
    children: Confirm,
    initialState: {
      message: undefined,
      list: [],
    },
  },
]

// Data processing
export const apiToFront = ({ candidato }) => ({
  pes_nome: candidato.pes_nome || '',
  idade: candidato.idade,
  cadastro: candidato.cadastro,
  prtsalario: candidato.prtsalario || '',
  profissao: candidato.profissao || '',
  cidade: candidato.cidade || '',
  uf: candidato.uf || '',
  curriculo: candidato.curriculo || '',
  curriculo_url: candidato.curriculo_url || '',
  cargos: candidato.cargos || [],
  indicacao: candidato.indicacao || '',
  linkedin: candidato.linkedin || '',
  twitter: candidato.twitter || '',
  site: candidato.site || '',
  pcd: candidato.pcd || 0,
  escolaridade: candidato.escolaridade || '',
  perfil: candidato.perfil || '',
  key: candidato.pes_id,
  fitcultural: candidato.fitcultural,
})
