// Redux types
import * as constants from '../constants/constantsTimesDetalhes'

export const getColaboradores = () => {
  return {
    type: constants.PESSOAS_GET,
    api: {
      method: 'get',
      url: constants.URL_PESSOAS_GET,
    },
  }
}

export const getGrupoLider = (id) => {
  return {
    type: constants.GRUPO_LIDER_GET,
    api: {
      method: 'get',
      url: constants.URL_GRUPO_LIDER_GET + id,
    },
  }
}

export const postGrupoLider = (idTime, idPessoa) => {
  return {
    type: constants.GRUPO_LIDER_POST,
    api: {
      method: 'post',
      url: constants.URL_GRUPO_LIDER_POST + idTime + '/' + idPessoa,
    },
  }
}

export const deleteGrupoLider = (idTime, idPessoa) => {
  return {
    type: constants.GRUPO_LIDER_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_GRUPO_LIDER_DELETE + idTime + '/' + idPessoa,
    },
  }
}

export const getTimes = () => {
  return {
    type: constants.TIMES_GET,
    api: {
      method: 'get',
      url: constants.URL_TIMES_GET,
    },
  }
}

export const getTimeInfo = (timeId) => {
  return {
    type: constants.TIME_INFO_GET,
    api: {
      method: 'get',
      url: `${constants.URL_TIME_INFO_GET}/${timeId}`,
    },
  }
}

export const getTimePessoas = (id) => {
  return {
    type: constants.TIME_PESSOAS_GET,
    api: {
      method: 'get',
      url: constants.URL_TIME_PESSOAS_GET + id,
    },
  }
}

export const postTimePessoa = ({
  grupo_id,
  pes_id,
  grupo_dataentrada = null,
}) => {
  return {
    type: constants.TIME_PESSOA_POST,
    api: {
      method: 'post',
      url: constants.URL_TIME_PESSOA_POST + grupo_id + '/' + pes_id,
      data: {
        grupo_dataentrada,
      },
    },
  }
}

export const deleteTimePessoa = (idTime, idPessoa) => {
  return {
    type: constants.TIME_PESSOA_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_TIME_PESSOA_DELETE + idTime + '/' + idPessoa,
    },
  }
}

// export const sendValoresOrganizacionais = (
// 	id,
// 	{ enviarGruposFilho, enviarEmails },
// ) => {
// 	return {
// 		type: constants.TIMES_SEND_VALORES,
// 		api: {
// 			method: 'post',
// 			url: constants.URL_TIMES_SEND_VALORES(id),
// 			data: { enviarGruposFilho, enviarEmails },
// 		},
// 	};
// };

// export const sendVetorComportamental = (id, { enviarEmails }) => {
// 	return {
// 		type: constants.TIMES_SEND_AVALIACOES,
// 		api: {
// 			method: 'post',
// 			url: constants.URL_TIMES_SEND_AVALIACOES + id,
// 			data: {
// 				enviarEmails,
// 			},
// 		},
// 	};
// };

// export const sendPercepcao = (id, idpesquisa, { enviarEmails }) => {
// 	return {
// 		type: constants.TIMES_SEND_PESQUISAS,
// 		api: {
// 			method: 'post',
// 			url: constants.URL_TIMES_SEND_PESQUISAS + id + '/' + idpesquisa,
// 			data: {
// 				enviarEmails,
// 			},
// 		},
// 	};
// };

export const setActiveTab = (id) => {
  return {
    type: constants.SET_ACTIVE_TAB_TIMES,
    value: id,
  }
}

export const getCulturaDesejadaGrupo = (grupo_id) => {
  return {
    type: constants.GRUPO_CULTURA_DESEJADA_GRUPO,
    api: {
      method: 'get',
      url: constants.URL_GRUPO_CULTURA_DESEJADA_GRUPO(grupo_id),
    },
  }
}

export const updateCulturaDesejadaGrupo = ({ grupo_id, ...data }) => {
  return {
    type: constants.GRUPO_CULTURA_DESEJADA_GRUPO,
    api: {
      method: 'put',
      url: constants.URL_GRUPO_CULTURA_DESEJADA_GRUPO(grupo_id),
      data: {
        ...data,
      },
    },
  }
}

export const getCulturaInstaladaGrupo = (grupo_id) => {
  return {
    type: constants.GRUPO_CULTURA_INSTALADA_GRUPO,
    api: {
      method: 'get',
      url: constants.URL_GRUPO_CULTURA_INSTALADA_GRUPO(grupo_id),
    },
  }
}
