import React from 'react'
import { Helmet } from 'react-helmet'
import { Row } from 'antd'
import {
  OlharParaSi,
  OlharParaLideranca,
  OlharParaMudancas,
} from '../../../components/percepcao'

import { CustomTabs as Tabs } from './styles'

import { noPadding } from '../../../utils/inlineStyles'

const { TabPane } = Tabs

function Olhar360GeralView({ culturaOrganizacional }) {
  const { olharParaSi, olharParaMudancas, olharLideranca, loading } =
    culturaOrganizacional
  return (
    <>
      <Helmet>
        <title>TeamHub | Olhar 360</title>
      </Helmet>
      <Row gutter={16} style={{ ...noPadding, margin: '0' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Olhar para Si" key="1">
            <OlharParaSi data={olharParaSi} loading={loading} />
          </TabPane>
          <TabPane tab="Olhar para Liderança" key="2">
            <OlharParaLideranca data={olharLideranca} loading={loading} />
          </TabPane>
          <TabPane tab="Olhar para Mudanças" key="3">
            <OlharParaMudancas data={olharParaMudancas} loading={loading} />
          </TabPane>
        </Tabs>
      </Row>
    </>
  )
}

export default Olhar360GeralView
