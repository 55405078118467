import styled, { css } from 'styled-components'

import { Table as TableAntd, Card, Button, Col as ColAntd } from 'antd'

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

interface FlexCardProps {
  noPadding?: boolean
  center?: boolean
}

export const FlexCard = styled(Card)<FlexCardProps>`
  height: 100%;
  .ant-card-body {
    ${(props) =>
      props.noPadding &&
      css`
        padding: 0;
      `}
    ${(props) =>
      props.center &&
      css`
        justify-content: center;
      `}

		height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    flex-wrap: wrap;
    align-items: center;

    .bizcharts {
      height: 100% !important;
    }

    > .ant-statistic {
      .ant-statistic-title {
        min-height: 45px;
      }

      flex: 1;
      padding: 0px 16px;

      width: 50%;
      min-width: 50%;

      @media (min-width: 768px) {
        width: 33%;
        min-width: 33%;
      }

      @media (min-width: 1500px) {
        width: 16.6%;
        min-width: 16.6%;
      }
    }
  }
`

export const ContainerSelecionadas = styled.div`
  margin-left: 14px;
  display: inline-block;
`

export const DatePickerButton = styled(Button)`
  background-color: #fff;
  position: relative;
  display: inline-block;
  min-width: 0;
  padding: 0 7px;
  color: rgba(0, 0, 0, 0.85);
  height: 100%;
  line-height: 20px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  margin-right: 8px;
`

export const Col = styled(ColAntd)``
