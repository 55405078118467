import _ from 'lodash'
// Redux types
import * as constants from '../constants/constantsPesquisas'

export const getPesquisasDisponiveis = () => {
  return {
    type: constants.PESQUISAS_DISPONIVEIS_GET,
    api: {
      method: 'get',
      url: constants.URL_PESQUISAS_DISPONIVEIS_GET,
      data: {},
    },
  }
}

export const getAvaliacoesDisponiveis = () => {
  return {
    type: constants.AVALIACOES_DISPONIVEIS_GET,
    api: {
      method: 'get',
      url: constants.URL_AVALIACOES_DISPONIVEIS_GET,
      data: {},
    },
  }
}

// Perfil Comportamental
export const loadVetor = (hash) => {
  return {
    type: constants.PESQUISAS_VETOR_LOAD,
    api: {
      method: 'get',
      url: constants.URL_PESQUISAS_VETOR_LOAD(hash),
    },
  }
}

export const getCaracteristicasPerfilComportamental = () => {
  return {
    type: constants.CARACTERISTICAS_PERFIL_COMPORTAMENTAL_GET,
    api: {
      method: 'get',
      url: constants.URL_CARACTERISTICAS_PERFIL_COMPORTAMENTAL_GET,
      data: {},
    },
  }
}

export const getInfoPerfilComportamental = (hash) => {
  return {
    type: constants.PERFIL_COMPORTAMENTAL_INFO_GET,
    api: {
      method: 'get',
      url: `${constants.URL_PERFIL_COMPORTAMENTAL_INFO_GET}/${hash}`,
      data: {},
    },
  }
}

export const postRespostaPerfilComportamental = ({
  id,
  checkboxesSelecionados,
}) => {
  return {
    type: constants.RESPOSTA_PERFIL_COMPORTAMENTAL_POST,
    api: {
      method: 'post',
      url: `${constants.URL_RESPOSTA_PERFIL_COMPORTAMENTAL_POST}/${id}`,
      data: {
        // avalia_id: id,
        formularios: {
          essencia: checkboxesSelecionados['0'],
          exigido: checkboxesSelecionados['1'],
          entrega: checkboxesSelecionados['2'],
        },
      },
    },
  }
}

// Percepcão do ambiente
export const loadPercepcao = (hash) => {
  return {
    type: constants.PESQUISAS_PERCEPCAO_LOAD,
    api: {
      method: 'get',
      url: constants.URL_PESQUISAS_PERCEPCAO_LOAD(hash),
    },
  }
}

export const getQuestoesPesquisas = (tipo) => {
  return {
    type: constants.QUESTOES_PESQUISA_GET,
    api: {
      method: 'get',
      url: `${constants.URL_QUESTOES_PESQUISA_GET}/${tipo}`,
      data: {},
    },
  }
}

export const postRespostaPesquisas = ({ id, tipoPesquisa, listaRespostas }) => {
  let respostas = []

  listaRespostas.questoes.forEach((questao) => {
    let resposta

    if (questao.alternativas) {
      resposta = _.map(questao.alternativas, (alt, index) =>
        alt.key
          ? {
              idquestao: questao.id,
              idalternativa: alt.key,
              valor: index + 1,
            }
          : alt
          ? {
              idquestao: questao.id,
              idalternativa: index,
              valor: 1,
            }
          : false
      ).filter((elem) => elem)
    } else {
      resposta = [
        {
          idquestao: questao.id,
          idalternativa: questao.valor,
          valor: questao.valor,
        },
      ]
    }

    respostas = [...respostas, ...resposta]
  })

  return {
    type: constants.RESPOSTA_PESQUISA_POST,
    api: {
      method: 'post',
      url: `${constants.URL_RESPOSTA_PESQUISA_POST}/${id}`,
      data: {
        // avalia_id: id,
        idpesquisa: tipoPesquisa,
        respostas,
        // respostas: [
        // 	{ idquestao: 2, idalternativa: 1, valor: 1 },
        // 	{ idquestao: 2, idalternativa: 2, valor: 2 },
        // 	{ idquestao: 2, idalternativa: 3, valor: 3 },
        // 	{ idquestao: 2, idalternativa: 4, valor: 4 },
        // 	{ idquestao: 2, idalternativa: 5, valor: 5 },
        // ],
      },
    },
  }
}
