import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { Modal, Row, Col, Card, Tabs } from 'antd'

// Services
import { getContaURL } from '../../services/auth'

import history from '../../utils/history'

// Layouts
import ContentLayout from '../../layouts/contentLayout/ContentLayout'

// Components
import TableGestaoPesquisas from './components/tableGestaoPesquisas/TableGestaoPesquisas'

// CSS
import './GestaoPesquisas.css'

// Constants
import { modals } from './constants/constantsGestaoPesquisas'

const { TabPane } = Tabs
function GestaoPesquisasView({
  openModal,
  rowSelection,
  showModals,
  closeModal,
  onModalSubmit,
  handleChangeModal,
  modalStates,
  dataSource,
  handleDelete,
  getColumnSearchProps,
  exportarExcel,
  handleChangeCheckbox,
  listPessoas,
  sendVetorComportamental,
  cancelVetorComportamental,
  cancelPercepcao,
  getPesquisas,
  handleNewLink,
  cancelValoresOrganizacionais,
  sendValoresOrganizacionais,
}) {
  return (
    <>
      <Helmet>
        <title>TeamHub | Gestão de Pesquisas</title>
      </Helmet>
      <ContentLayout
        className="cargos"
        header={{
          title: 'Gestão de Pesquisas',
          subtitle: 'Gerencie aqui todas as pesquisas enviadas :)',
          breadcrumb: [
            { name: 'Home', path: '/' },
            { name: 'Gestão de Pesquisas' },
          ],
        }}
      >
        <div className="gestao-pesquisas__body">
          <Row>
            <Col md={24}>
              <Card className="gestao-pesquisas__card" type="inner">
                <Tabs
                  defaultActiveKey="2"
                  style={{
                    borderRadius: '2px',
                    borderTop: 'none',
                  }}
                  tabBarStyle={{ backgroundColor: '#fff', marginBottom: 0 }}
                  onTabClick={(tab) => {
                    tab === '1' &&
                      history.push(
                        `/${getContaURL()}/pesquisas/gestao-pesquisas/colaboradores`
                      )
                    tab === '2' &&
                      history.push(
                        `/${getContaURL()}/pesquisas/gestao-pesquisas/candidatos`
                      )
                  }}
                >
                  <TabPane tab={<span>Colaboradores</span>} key="1" />
                  <TabPane tab={<span>Candidatos</span>} key="2">
                    <TableGestaoPesquisas
                      openModal={openModal}
                      closeModal={closeModal}
                      onModalSubmit={onModalSubmit}
                      showModals={showModals}
                      modalStates={modalStates}
                      handleChangeModal={handleChangeModal}
                      modalId={0}
                      dataSource={dataSource[0]}
                      rowSelection={rowSelection}
                      handleDelete={handleDelete}
                      getColumnSearchProps={getColumnSearchProps}
                      exportarExcel={exportarExcel}
                      sendVetorComportamental={sendVetorComportamental}
                      cancelVetorComportamental={cancelVetorComportamental}
                      cancelPercepcao={cancelPercepcao}
                      getPesquisas={getPesquisas}
                      type="candidato"
                      handleNewLink={handleNewLink}
                      cancelValoresOrganizacionais={
                        cancelValoresOrganizacionais
                      }
                      sendValoresOrganizacionais={sendValoresOrganizacionais}
                    />
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </div>
        {modals.map(
          ({
            title,
            cancelText,
            okText,
            id,
            width,
            isCandidato,
            children: Children,
          }) => {
            return (
              <Modal
                visible={showModals[id]}
                title={title}
                cancelText={cancelText}
                okText={okText}
                onCancel={() => closeModal(id)}
                onOk={() => onModalSubmit(id)}
                width={width}
                destroyOnClose
                key={id}
              >
                <Children
                  isCandidato={isCandidato}
                  handleChangeModal={(event) => handleChangeModal(id, event)}
                  handleChangeCheckbox={(event) =>
                    handleChangeCheckbox(id, event)
                  }
                  listPessoas={listPessoas[id]}
                  dataSource={dataSource}
                  modalState={modalStates[id]}
                  {...modalStates[id]}
                />
              </Modal>
            )
          }
        )}
      </ContentLayout>
    </>
  )
}

export default injectIntl(GestaoPesquisasView)
