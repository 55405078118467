import { useEffect, useState } from 'react'

import { Layout, Menu, Card } from 'antd'

import { useParams } from 'react-router-dom'
import moment from 'moment'
import Settings from './Forms/Settings'
import Dashboard from './Dashboard'
import General from './Dashboard/informations'
import Etapas from './Etapas'
import SubCulturas from './SubCulturas'
import { getCulturaDesejada } from './Api/api'
import Avaliadores from './SubCulturas/subCulturaAvaliadores'
import Objetivos from './SubCulturas/objetivosEstrategicos'
import Times from './SubCulturas/Times'

import './styles.css'

import 'moment/locale/pt-br'

const { Content } = Layout

function Home(props) {
  const [screen, setScreen] = useState({
    title: 'Consensuar',
    key: '1',
  })

  const [cultura, setCultura] = useState({})
  const params = useParams()

  useEffect(() => {
    async function fetchData() {
      const value = await getCulturaDesejada(params.id)
      if (value) {
        setCultura(value.data.conta_cultura)
        if (value.data.conta_cultura.cultura_tipo === 1) {
          setScreen({ title: 'Consensuar', key: '1' })
        } else setScreen({ title: 'SubCulturas', key: '4' })
      }
    }
    fetchData()
  }, [params.id])
  const ReturnScreen = () => {
    if (screen.key === '0') return <General />
    if (screen.key === '1')
      return <Dashboard cultura={cultura} changeScreen={setScreen} />
    if (screen.key === '2')
      return (
        <Etapas
          cultura={cultura}
          setCultura={setCultura}
          changeScreen={setScreen}
        />
      )
    if (screen.key === '3')
      return (
        <Settings
          changeState={setCultura}
          cultura={cultura}
          settings={cultura}
        />
      )
    if (screen.key === '4')
      return (
        <SubCulturas
          cultura={cultura}
          setCultura={setCultura}
          changeScreen={setScreen}
        />
      )
    if (screen.key === '5')
      return <Avaliadores cultura={cultura} setCultura={setCultura} />
    if (screen.key === '6')
      return <Objetivos cultura={cultura} setCultura={setCultura} />
    if (screen.key === '7')
      return <Times cultura={cultura} setCultura={setCultura} />
  }

  const handleChangeScreen = (screen) => {
    setScreen({ title: screen.title, key: screen.key })
  }

  return (
    <div className="container">
      <Card>
        <h3>
          Data Inicial: {moment(cultura?.data_inicio).format('DD/MM/YYYY')} -
          Data Final: {moment(cultura?.data_final).format('DD/MM/YYYY')}
        </h3>
        <h3>
          Limite Dentro: {cultura?.limite_dentro}% - Limite Máximo:{' '}
          {cultura?.limite_maximo}%
        </h3>
        {cultura?.conta_cultura_statuses && (
          <h3>
            Status:{' '}
            {cultura?.conta_cultura_statuses[0]?.id === 1
              ? 'Criação'
              : cultura?.conta_cultura_statuses[0]?.id === 2
              ? 'Pesquisa'
              : 'Ativa'}
          </h3>
        )}
      </Card>
      <Layout>
        <Layout
          style={{
            padding: '0 24px 24px',
            background: '#fff',
          }}
        >
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Menu mode="horizontal" selectedKeys={screen.key}>
              {cultura?.cultura_tipo === 1 ? (
                <>
                  <Menu.Item
                    onClick={() =>
                      handleChangeScreen({ title: 'Consensuar', key: '1' })
                    }
                    key="1"
                  >
                    Consensuar
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      handleChangeScreen({ title: 'Avaliadores', key: '5' })
                    }
                    key="5"
                  >
                    Avaliadores
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      handleChangeScreen({
                        title: 'Objetivos Estratégicos',
                        key: '6',
                      })
                    }
                    key="6"
                  >
                    Objetivos Estratégicos
                  </Menu.Item>
                </>
              ) : (
                <>
                  <Menu.Item
                    onClick={() =>
                      handleChangeScreen({ title: 'Times', key: '7' })
                    }
                    key="7"
                  >
                    Times
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      handleChangeScreen({ title: 'SubCulturas', key: '4' })
                    }
                    key="4"
                  >
                    Sub Culturas
                  </Menu.Item>
                </>
              )}

              <Menu.Item
                onClick={() =>
                  handleChangeScreen({ title: 'Etapas', key: '2' })
                }
                key="2"
              >
                Etapas
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  handleChangeScreen({ title: 'Configurações', key: '3' })
                }
                key="3"
              >
                Configurações
              </Menu.Item>
            </Menu>
            <div style={{ margin: '10px 0 0 0' }}>
              <ReturnScreen />
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}

export default Home
