import React, { useState, useEffect } from 'react'

import moment from 'moment'

import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'

import {
  Input,
  DatePicker,
  Popconfirm,
  Button,
  Modal,
  Collapse,
  Empty,
} from 'antd'

import { Content, Line, Label, Box, CustomPanel } from './styles'

import { acaoModals as modals } from '../../constants/constantsPDT'

import AcoesTable from '../AcoesTable'

export default function MetaListItem({
  pdt,
  data: meta,
  getColumnSearchProps,
  handleChangeMetaDescricao,
  handleDeleteAcao,
  handleCompleteAcao,
  handleDeleteMeta,
  handleUpdateMeta,
  openModal,
  closeModal,
  onModalSubmit,
  showModals,
  modalStates,
  handleChangeModal,
  editModal,
  error,
  isPDTCompleted,
  ...props
}) {
  const { pdt_id } = pdt
  const [saving, setSaving] = useState(false)

  const { meta_id, descricao, acoes } = meta

  const acoesPendentes = acoes
    ? acoes.reduce((acc, next) => {
        if (next.completed_at === null) acc++
        return acc
      }, 0)
    : null

  const status = acoes
    ? acoesPendentes > 0
      ? 'Em andamento'
      : 'Concluído'
    : 'Cadastre ações'

  return (
    <>
      <Collapse
        expandIconPosition="right"
        style={{ margin: '0', marginTop: '8px' }}
      >
        <CustomPanel
          {...props}
          header={`${descricao} - ${status}`}
          key={meta_id}
          extra={
            <>
              {!isPDTCompleted && (
                <Popconfirm
                  title="Apagar Meta?"
                  onConfirm={(event) => {
                    event.stopPropagation()
                    handleDeleteMeta(pdt_id, meta_id)
                  }}
                  onClick={(event) => event.stopPropagation()}
                  okText="Sim"
                  cancelText="Não"
                  icon={
                    <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                  }
                >
                  <DeleteOutlined />
                </Popconfirm>
              )}
            </>
          }
        >
          <Content>
            <Line>
              <Box style={{ minWidth: !isPDTCompleted ? '85%' : '100%' }}>
                <Label>Descrição da Meta</Label>
                <Input
                  maxLength={1000}
                  value={descricao}
                  readOnly={isPDTCompleted}
                  onChange={(e) =>
                    handleChangeMetaDescricao(pdt_id, meta_id, e.target.value)
                  }
                />
              </Box>
              {!isPDTCompleted && (
                <Box style={{ minWidth: '14%' }}>
                  <Button
                    style={{
                      borderColor: getComputedStyle(
                        document.body
                      ).getPropertyValue('--orange'),
                      backgroundColor: getComputedStyle(
                        document.body
                      ).getPropertyValue('--orange'),
                    }}
                    type="primary"
                    onClick={() => {
                      setSaving(true)
                      handleUpdateMeta(pdt_id, meta).then(() =>
                        setSaving(false)
                      )
                    }}
                    loading={saving}
                  >
                    Salvar Meta
                  </Button>
                </Box>
              )}
            </Line>
            <AcoesTable
              getColumnSearchProps={getColumnSearchProps}
              data={acoes}
              bordered
              handleDeleteAcao={handleDeleteAcao}
              handleCompleteAcao={handleCompleteAcao}
              openModal={openModal}
              closeModal={closeModal}
              onModalSubmit={onModalSubmit}
              showModals={showModals}
              modalStates={modalStates}
              handleChangeModal={handleChangeModal}
              editModal={editModal}
              pdt={pdt}
              meta={meta}
              isPDTCompleted={isPDTCompleted}
            />
          </Content>
        </CustomPanel>
      </Collapse>

      {modals.map((m) => {
        const { title, cancelText, okText, id, width, children: Children } = m
        return (
          <Modal
            visible={showModals[id]}
            title={title}
            cancelText={cancelText}
            okText={okText}
            onCancel={() => closeModal(id)}
            onOk={() => onModalSubmit(id)}
            width={width}
            destroyOnClose
            key={id}
          >
            <Children
              handleChangeModal={(event) => handleChangeModal(id, event)}
              // handleChangeCheckbox={(event) => handleChangeCheckbox(id, event)}
              dataSource={acoes}
              modalState={modalStates[id]}
              pdt={pdt}
              meta={meta}
              error={error}
              {...modalStates[id]}
            />
          </Modal>
        )
      })}
    </>
  )
}
