import React from 'react'
import { injectIntl } from 'react-intl'
import { Row, Col, Checkbox, Card, Upload, Divider } from 'antd'

import { FileExcelOutlined, UploadOutlined } from '@ant-design/icons'

// Components
import Button from '../../../../components/button/Button'

// CSS
import './AdicionarColaboradores.css'

// Constants

// Assets
import ModeloPlanilhaTeamhub from '../../assets/ModeloPlanilhaTeamhub2.xlsx'

function AdicionarColaboradoresView({
  intl,
  handleChangeModal,
  handleChangeCheckbox,
  onFileUpload,
  onRemoveFile,
  fileList,
}) {
  return (
    <div className="adicionar-colaboradores">
      <Row gutter={30}>
        <Col md={12}>
          <Card className="adicionar-colaboradores__card">
            <Row>
              <p>
                1- Baixe a planilha com o modelo de importação de colaboradores
              </p>
            </Row>
            <Row className="adicionar-colaboradores__card__row-btn">
              <Button
                className="btn_purple"
                icon={<FileExcelOutlined />}
                onClick={() => window.open(ModeloPlanilhaTeamhub)}
              >
                BAIXAR MODELO
              </Button>
            </Row>
          </Card>
        </Col>
        <Col md={12}>
          <Card className="adicionar-colaboradores__card">
            <Row>
              <p>
                2- Importe a planilha preenchida para importar seus
                colaboradores.
              </p>
            </Row>
            <Row className="adicionar-colaboradores__card__row-btn">
              <Upload
                name="lista-colaboradores"
                beforeUpload={onFileUpload}
                onRemove={onRemoveFile}
                accept=".xlsx"
                fileList={fileList}
              >
                <Button className="btn_purple" icon={<UploadOutlined />}>
                  IMPORTAR PLANILHA
                </Button>
              </Upload>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Checkbox name="vetorComportamental" onChange={handleChangeCheckbox}>
          Gerar pesquisa de Perfil Comportamental
        </Checkbox>
      </Row>
      <Row>
        <Checkbox name="percepcaoAmbiente" onChange={handleChangeCheckbox}>
          Gerar pesquisa de Percepção do Ambiente
        </Checkbox>
      </Row>
      <Row>
        <Checkbox name="valoresOrganizacionais" onChange={handleChangeCheckbox}>
          Gerar pesquisa de Valores Organizacionais
        </Checkbox>
      </Row>
      <Divider />
      <Row>
        <Checkbox name="enviarEmails" onChange={handleChangeCheckbox}>
          Enviar e-mails
        </Checkbox>
      </Row>
    </div>
  )
}

export default injectIntl(AdicionarColaboradoresView)
