import * as constants from '../constants/constants'

export const getColaboradores = () => {
  return {
    type: constants.VINCULAR_USUARIO_COLABORADORES_GET,
    api: {
      method: 'get',
      url: constants.URL_VINCULAR_USUARIO_COLABORADORES_GET,
    },
  }
}

export const postVinculo = ({ pes_id }) => {
  return {
    type: constants.VINCULAR_USUARIO_COLABORADORES_POST,
    api: {
      method: 'post',
      url: constants.URL_VINCULAR_USUARIO_COLABORADORES_POST(pes_id),
    },
  }
}
