import React from 'react'
import { Input as InputAntd, DatePicker } from 'antd'
import MaskedInputAntd from 'antd-mask-input'
import InputMask from 'react-input-mask'
import locale from 'antd/es/date-picker/locale/pt_BR'
import moment from 'moment'

// CSS
import './Input.css'

// Components
const { RangePicker } = DatePicker

const { Password: AntdPassword, TextArea: AntdTextArea } = InputAntd

export const Password = (props) => (
  <Input InputType={AntdPassword} type="password" {...props} />
)

export const TextArea = (props) => <Input InputType={AntdTextArea} {...props} />

export const Date = (props) => (
  <Input
    InputType={DatePicker}
    format="DD/MM/YYYY"
    locale={locale}
    defaultValue={moment()}
    {...props}
  />
)

export const Range = (props) => (
  <Input
    InputType={RangePicker}
    format="DD/MM/YYYY"
    locale={locale}
    defaultValue={[moment(), null]}
    {...props}
  />
)

export const MaskedInput = ({ label, withoutMargin, ...props }) => (
  <div className={`input ${withoutMargin ? 'remove-margin' : ''}`}>
    {label && <span className="input__label">{label}</span>}
    <InputMask {...props} />
  </div>
)

const Input = ({ InputType = InputAntd, label, withoutMargin, ...props }) => (
  <div className={`input ${withoutMargin ? 'remove-margin' : ''}`}>
    {label && <span className="input__label">{label}</span>}
    <InputType {...props} />
  </div>
)

export default Input
