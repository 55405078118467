import React from 'react'
import { injectIntl } from 'react-intl'
import { Row, Col, Divider } from 'antd'

// Components
import Input, { Date, TextArea } from '../../../../components/input/Input'
import Select, { Option } from '../../../../components/select/Select'

// CSS

// Constants

function AdicionarNovoMembro({ intl, handleChangeModal, colaboradores }) {
  return (
    <div className="adicionar-time">
      <Row>
        <Col md={24}>
          <Select
            label="Selecione o novo membro a ser selecionado"
            placeholder="Selecione o novo membro"
            showSearch
            onChange={(evt) =>
              handleChangeModal({ target: { name: 'pes_id', value: evt } })
            }
            style={{ width: '100%' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {colaboradores.map((pessoa) => (
              <Option value={pessoa.id}>{pessoa.nome}</Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  )
}

export default injectIntl(AdicionarNovoMembro)
