import * as constants from '../constants/psKanbanConstants'

export const checkProcesso = (processo_id) => {
  return {
    type: constants.PS_KANBAN_CHECK,
    api: {
      method: 'get',
      url: constants.URL_PS_KANBAN_CHECK(processo_id),
    },
  }
}

export const listCandidatos = (processo_id) => {
  return {
    type: constants.PS_KANBAN_CANDIDATOS_LIST,
    api: {
      method: 'get',
      url: constants.URL_PS_KANBAN_CANDIDATOS_LIST(processo_id),
    },
  }
}

export const getDetails = (processo_id) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_DETAILS_GET,
    api: {
      method: 'get',
      url: constants.URL_PS_KANBAN_PROCESSO_DETAILS_GET(processo_id),
    },
  }
}

export const listEtapas = (processo_id) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_ETAPAS_LIST,
    api: {
      method: 'get',
      url: constants.URL_PS_KANBAN_PROCESSO_ETAPAS_LIST(processo_id),
    },
  }
}

export const storeEtapa = ({ processo_id, etapa }) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_ETAPAS_STORE,
    api: {
      method: 'post',
      url: constants.URL_PS_KANBAN_PROCESSO_ETAPAS_STORE(processo_id),
      data: {
        etapa,
      },
    },
  }
}

export const deleteEtapa = ({ processo_id, etapa_id }) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_ETAPAS_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_PS_KANBAN_PROCESSO_ETAPAS_DELETE(
        processo_id,
        etapa_id
      ),
    },
  }
}

export const updateEtapa = ({ processo_id, etapa_id, data }) => {
  return {
    type: constants.URL_PS_KANBAN_PROCESSO_ETAPAS_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_PS_KANBAN_PROCESSO_ETAPAS_UPDATE(
        processo_id,
        etapa_id
      ),
      data,
    },
  }
}

export const updateEtapaCandidato = ({
  processo_id,
  etapacand_id,
  new_etapa_id = null,
  index = null,
  observacao = null,
}) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_UPDATE_ETAPA_CANDIDATO,
    api: {
      method: 'put',
      url: constants.URL_PS_KANBAN_PROCESSO_UPDATE_ETAPA_CANDIDATO(
        processo_id,
        etapacand_id
      ),
      data: {
        new_etapa_id,
        index,
        observacao,
      },
    },
  }
}

export const rateCandidato = ({ etapacand_id, processo_id, rate }) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_RATE_CANDIDATO,
    api: {
      method: 'put',
      url: constants.URL_PS_KANBAN_PROCESSO_RATE_CANDIDATO(
        processo_id,
        etapacand_id
      ),
      data: {
        rate,
      },
    },
  }
}

export const deleteCandidato = ({
  etapacand_id,
  processo_id,
  motivoExclusao,
}) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_DELETE_CANDIDATO,
    api: {
      method: 'delete',
      url: constants.URL_PS_KANBAN_PROCESSO_DELETE_CANDIDATO(
        processo_id,
        etapacand_id
      ),
      data: {
        motivoExclusao,
      },
    },
  }
}

export const contratarCandidato = ({ etapacand_id, processo_id, ...data }) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_CONTRATAR_CANDIDATO,
    api: {
      method: 'post',
      url: constants.URL_PS_KANBAN_PROCESSO_CONTRATAR_CANDIDATO(
        processo_id,
        etapacand_id
      ),
      data: {
        ...data,
      },
    },
  }
}

export const validateContratacao = ({ etapacand_id, processo_id }) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_VALIDATE_CONTRATACAO,
    api: {
      method: 'get',
      url: constants.URL_PS_KANBAN_PROCESSO_VALIDATE_CONTRATACAO(
        processo_id,
        etapacand_id
      ),
    },
  }
}

export const listMotivosCancelamento = () => {
  return {
    type: constants.PS_KANBAN_PROCESSO_LIST_MOTIVOSCANCELAMENTO,
    api: {
      method: 'get',
      url: constants.URL_PS_KANBAN_PROCESSO_LIST_MOTIVOSCANCELAMENTO,
    },
  }
}

export const cancelProcesso = ({ processo_id, cancel_id }) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_CANCEL_PROCESSO,
    api: {
      method: 'delete',
      url: constants.URL_PS_KANBAN_PROCESSO_CANCEL_PROCESSO(processo_id),
      data: {
        cancel_id,
      },
    },
  }
}

export const finalizarProcesso = (processo_id) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_FINALIZAR_PROCESSO,
    api: {
      method: 'put',
      url: constants.URL_PS_KANBAN_PROCESSO_FINALIZAR_PROCESSO(processo_id),
    },
  }
}

export const clearKanban = () => {
  return {
    type: constants.PS_KANBAN_CLEAR,
  }
}

export const validateFinalizarProcesso = (processo_id) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_VALIDATE_FINALIZAR_PROCESSO,
    api: {
      method: 'get',
      url: constants.URL_PS_KANBAN_PROCESSO_VALIDATE_FINALIZAR_PROCESSO(
        processo_id
      ),
    },
  }
}

export const getHunter = (processo_id) => {
  return {
    type: constants.PS_KANBAN_CHECK_HAS_HUNTER,
    api: {
      method: 'get',
      url: constants.URL_PS_KANBAN_CHECK_HAS_HUNTER(processo_id),
    },
  }
}

export const updateHunter = (processo_id, pes_id) => {
  return {
    type: constants.PS_KANBAN_UPDATE_HUNTER,
    api: {
      method: 'put',
      url: constants.URL_PS_KANBAN_UPDATE_HUNTER(processo_id),
      data: {
        hunter: pes_id,
      },
    },
  }
}

export const getCandidatosDisponiveis = (cargo_id) => {
  return {
    type: constants.PS_KANBAN_GET_CANDIDATOS_DISPONIVEIS,
    api: {
      method: 'get',
      url: constants.URL_PS_KANBAN_GET_CANDIDATOS_DISPONIVEIS(cargo_id),
    },
  }
}

export const getCandidatosDisponiveisProcesso = (processo_id) => {
  return {
    type: constants.PS_KANBAN_GET_CANDIDATOS_DISPONIVEIS_PROCESSO,
    api: {
      method: 'get',
      url: constants.URL_PS_KANBAN_GET_CANDIDATOS_DISPONIVEIS_PROCESSO(
        processo_id
      ),
    },
  }
}

export const getColaboradoresDisponiveisProcesso = (processo_id) => {
  return {
    type: constants.PS_KANBAN_GET_COLABORADORES_DISPONIVEIS_PROCESSO,
    api: {
      method: 'get',
      url: constants.URL_PS_KANBAN_GET_COLABORADORES_DISPONIVEIS_PROCESSO(
        processo_id
      ),
    },
  }
}

export const getFitMinimo = () => {
  return {
    type: constants.CONFIG_PESQUISAS_GET_FIT_MINIMO,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_PESQUISAS_GET_FIT_MINIMO,
    },
  }
}

// Enviar email manual
export const sendEmailManual = ({
  processo_id,
  etapacand_id,
  id_email,
  etapa_id,
  textoemail,
}) => {
  return {
    type: constants.PS_KANBAN_PROCESSO_ETAPA_CANDIDATO_ENVIAR_EMAIL_MANUAL,
    api: {
      method: 'post',
      url: constants.URL_PS_KANBAN_PROCESSO_ETAPA_CANDIDATO_ENVIAR_EMAIL_MANUAL(
        processo_id,
        etapacand_id,
        id_email,
        etapa_id
      ),
      data: {
        textoemail,
      },
    },
  }
}

export const getTemplatesManuais = () => {
  return {
    type: constants.CONFIG_PS_LIST_MANUAL_TEMPLATES,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_PS_LIST_MANUAL_TEMPLATES,
    },
  }
}

export const getEmailsManuaisParams = () => {
  return {
    type: constants.CONFIG_PS_LIST_MANUAL_PARAMS,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_PS_LIST_MANUAL_PARAMS,
    },
  }
}
