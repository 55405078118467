import * as constants from '../constants/psPainelConstants'

export const checkProcesso = (processo_id) => {
  return {
    type: constants.PS_PAINEL_CHECK,
    api: {
      method: 'get',
      url: constants.URL_PS_PAINEL_CHECK(processo_id),
    },
  }
}

export const getDetails = (processo_id) => {
  return {
    type: constants.PS_PAINEL_PROCESSO_DETAILS_GET,
    api: {
      method: 'get',
      url: constants.URL_PS_PAINEL_PROCESSO_DETAILS_GET(processo_id),
    },
  }
}

export const getProcessoQuiz = (processo_id) => {
  return {
    type: constants.PS_PAINEL_PROCESSO_QUIZ_GET,
    api: {
      method: 'get',
      url: constants.URL_PS_PAINEL_PROCESSO_QUIZ_GET(processo_id),
    },
  }
}

export const listMotivosCancelamento = () => {
  return {
    type: constants.PS_KANBAN_PROCESSO_LIST_MOTIVOSCANCELAMENTO,
    api: {
      method: 'get',
      url: constants.URL_PS_KANBAN_PROCESSO_LIST_MOTIVOSCANCELAMENTO,
    },
  }
}

export const clearPainel = () => {
  return {
    type: constants.PS_PAINEL_CLEAR,
  }
}

export const getAllQuizzesCandidates = (processo_id) => {
  return {
    type: constants.PS_LIST_ALL_QUIZZES_CANDIDATES,
    api: {
      method: 'get',
      url: constants.URL_PS_LIST_ALL_QUIZZES_CANDIDATES(processo_id),
    },
  }
}
