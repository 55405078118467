// types
export const PS_MOTIVO_CANCELAMENTO_LIST = 'PS_MOTIVO_CANCELAMENTO_LIST'
export const PS_MOTIVO_CANCELAMENTO_STORE = 'PS_MOTIVO_CANCELAMENTO_STORE'
export const PS_MOTIVO_CANCELAMENTO_UPDATE = 'PS_MOTIVO_CANCELAMENTO_UPDATE'
export const PS_MOTIVO_CANCELAMENTO_DELETE = 'PS_MOTIVO_CANCELAMENTO_DELETE'
export const PS_MOTIVO_CANCELAMENTO_LOAD = 'PS_MOTIVO_CANCELAMENTO_LOAD'

// urls
export const URL_PS_MOTIVO_CANCELAMENTO = `${process.env.REACT_APP_API_URL}/ps/cancel`
export const URL_PS_MOTIVO_CANCELAMENTO_ID = (id) =>
  `${process.env.REACT_APP_API_URL}/ps/cancel/${id}`
