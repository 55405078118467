import React, { useState } from 'react'

import { Form, Input, Select } from 'antd'

import { Col, Steps } from 'antd'
import { Option } from '../../../../components/select/Select'

const { Step } = Steps
export default function RequisitoCargo({
  handleChangeRequisito,
  onFinish,
  formacao,
  nivel_id,
  nomeRequisito,
  form,
}) {
  const [valorInvalido, setValorInvalido] = useState(false)

  /* Não remover rule */
  const validationRequisito = (rule, value, callback) => {
    if (!value.match(/^(?!\s+$).*/)) {
      setValorInvalido(true)
      return callback('Por favor, insira um nome válido!')
    }
    setValorInvalido(false)
    return callback()
  }

  const [maximoValor, setMaximoValor] = useState(0)

  const handleVerificarMaximoValor = (e) => {
    setMaximoValor(e.target.value.length)
  }

  return (
    <Form
      layout="vertical"
      id="cadastro-requisito"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        label="Descrição"
        name="nomeRequisito"
        style={{
          marginBottom: 3,
        }}
        rules={[
          {
            required: true,
            message: 'Por favor, preencha este campo.',
          },
          {
            validator: validationRequisito,
            message: 'Insira um nome válido!',
          },
        ]}
      >
        <Input
          type="text"
          placeholder="Digite o que o cargo faz"
          onChange={(e) => handleVerificarMaximoValor(e)}
          maxLength={255}
          key="form_requisito-nome"
        />
      </Form.Item>
      <p
        style={{
          color: maximoValor >= 240 ? 'red' : '',
          marginTop: valorInvalido ? -9 : -6,
        }}
      >
        {maximoValor <= 9
          ? maximoValor.toString().padStart(2, '0')
          : maximoValor}
        /255
      </p>
      <Form.Item label="Selecione o nível" name="nivel_id">
        <Select
          placeholder="Escolha o nível"
          style={{ width: '100%' }}
          key="form_requisito-nivel"
        >
          {formacao.map(({ nome, id }) => (
            <Option value={id} key={id}>
              {nome}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}
