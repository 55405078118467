import * as constants from '../constants'

export const getColaboradores = () => {
  return {
    type: constants.RELATORIOS_LIST_COLABORADORES_FITCULTURAL,
    api: {
      method: 'get',
      url: constants.URL_RELATORIOS_LIST_COLABORADORES_FITCULTURAL,
    },
  }
}

export const getCandidatos = () => {
  return {
    type: constants.RELATORIOS_LIST_CANDIDATOS_FITCULTURAL,
    api: {
      method: 'get',
      url: constants.URL_RELATORIOS_LIST_CANDIDATOS_FITCULTURAL,
    },
  }
}
