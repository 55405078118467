import { Card, Col, Row } from 'antd'
import { GaugeChart } from 'bizcharts'
import Empty from '../../../../../../components/empty'
import { cardHeadBlue } from '../../../../../../utils/inlineStyles'
// utils
import { colors } from '../../../../../../utils/utils'
import '../../../../Dashboard.css'
import { IndicadoresContainer } from '../../styles'

const IndicadoresMudanca = ({
  loadingCulturaOrg,
  velocidadeAdocao,
  proficiencia,
  engajamento,
}) => {
  return (
    <Card
      title="Indicadores de Mudança"
      headStyle={cardHeadBlue}
      loading={loadingCulturaOrg}
    >
      <Row>
        <Col span={24}>
          <Row>
            <Col lg={8} xs={24} sm={24}>
              {velocidadeAdocao ? (
                <IndicadoresContainer>
                  <span>Velocidade de adoção à mudança</span>
                  <GaugeChart
                    forceFit
                    min={0}
                    max={100}
                    value={parseFloat(
                      parseFloat(velocidadeAdocao.percent).toFixed(1)
                    )}
                    height={219}
                    range={[0, 25, 50, 75, 100]}
                    color={colors}
                    statistic={{
                      visible: true,
                      text: `${parseFloat(velocidadeAdocao.percent).toFixed(
                        1
                      )}%`,
                      size: 35,
                      position: ['50%', '100%'],
                    }}
                    axis={{
                      offset: -15,
                      tickLine: {
                        visible: true,
                        length: 10,
                      },
                      label: {
                        visible: false,
                      },
                    }}
                  />
                </IndicadoresContainer>
              ) : (
                <Empty />
              )}
            </Col>
            <Col lg={8} xs={24} sm={24}>
              {engajamento ? (
                <IndicadoresContainer>
                  <span>Engajamento no processo de mudança</span>
                  <GaugeChart
                    forceFit
                    min={0}
                    max={100}
                    value={parseFloat(
                      parseFloat(engajamento.percent).toFixed(1)
                    )}
                    statistic={{
                      visible: true,
                      text: `${parseFloat(engajamento.percent).toFixed(1)}%`,
                      size: 35,
                      position: ['50%', '100%'],
                    }}
                    height={219}
                    range={[0, 25, 50, 75, 100]}
                    color={colors}
                    axis={{
                      offset: -15,
                      tickLine: {
                        visible: true,
                        length: 10,
                      },
                      label: {
                        visible: false,
                      },
                    }}
                  />
                </IndicadoresContainer>
              ) : (
                <Empty />
              )}
            </Col>
            <Col lg={8} xs={24} sm={24}>
              {proficiencia ? (
                <IndicadoresContainer>
                  <span>Proficiência</span>
                  <GaugeChart
                    color={colors}
                    forceFit
                    min={0}
                    max={100}
                    value={parseFloat(
                      parseFloat(proficiencia.percent).toFixed(1)
                    )}
                    statistic={{
                      visible: true,
                      text: `${parseFloat(proficiencia.percent).toFixed(1)}%`,
                      size: 35,
                      position: ['50%', '100%'],
                    }}
                    height={219}
                    range={[0, 25, 50, 75, 100]}
                    axis={{
                      offset: -15,
                      tickLine: {
                        visible: true,
                        length: 10,
                      },
                      label: {
                        visible: false,
                      },
                    }}
                  />
                </IndicadoresContainer>
              ) : (
                <Empty />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default IndicadoresMudanca
