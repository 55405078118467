import jwt from 'jsonwebtoken'
import { DESTROY_REDUX } from '../constants/global'
import api from './api'
import { store } from './rootReducer'
// Authentication methods
export const isAuthenticated = ({ empresa } = { empresa: getEmpresa() }) => {
  const token = localStorage.getItem('access_token')

  if (
    token === null ||
    !validaToken(token) ||
    jwt.decode(token)['auth']['conta_url'] !== empresa
  ) {
    logout()
    return false
  }

  api.defaults.headers.authorization = `Bearer ${token}`

  sessionStorage.setItem('info', JSON.stringify(jwt.decode(token)))

  return true
}

export const getToken = () => {
  const token = localStorage.getItem('access_token')
  return token
}

export const getEmpresa = () =>
  JSON.parse(sessionStorage.getItem('info')) &&
  JSON.parse(sessionStorage.getItem('info'))['auth']['conta_url']

export const login = (token, rememberMe) => {
  store.dispatch({ type: DESTROY_REDUX })
  localStorage.setItem('access_token', token)
  api.defaults.headers.authorization = `Bearer ${token}`
}

export const logout = () => {
  localStorage.removeItem('access_token')
  sessionStorage.removeItem('access_token')
  sessionStorage.removeItem('info')
  store.dispatch({ type: DESTROY_REDUX })
}

const validaToken = (token) => true

export const getContaURL = () => {
  const info = JSON.parse(sessionStorage.getItem('info'))

  if (!info) return null

  const { auth } = info

  if (auth) {
    return auth.conta_url
  }

  return null
}

export const getContaNome = () =>
  JSON.parse(sessionStorage.getItem('info')) &&
  JSON.parse(sessionStorage.getItem('info'))['auth']['conta_nome']

export const getModulosConta = () =>
  JSON.parse(sessionStorage.getItem('info')) &&
  JSON.parse(sessionStorage.getItem('info'))['auth']['modules']

export const getUserName = () =>
  JSON.parse(sessionStorage.getItem('info')) &&
  JSON.parse(sessionStorage.getItem('info'))['auth']['user_nome']

export const isLider = () => {
  const infos = JSON.parse(sessionStorage.getItem('info'))

  if (infos) {
    return infos.auth.usertipo_id === 3
  }
}

export const isAdmin = () => {
  const infos = JSON.parse(sessionStorage.getItem('info'))

  if (infos) {
    return infos.auth.usertipo_id === 1
  }
}

export const hasVinculoPessoa = () => {
  const info = JSON.parse(sessionStorage.getItem('info'))

  if (info && info.auth && parseInt(info.auth.vinculado) === 1) {
    return true
  }

  return false
}

export const getUserId = () => {
  const info = JSON.parse(sessionStorage.getItem('info'))

  if (!info) {
    return null
  }

  const { auth } = info

  if (!auth) {
    return null
  }

  return parseInt(auth.user_id)
}

export const getUserPesId = () => {
  const info = JSON.parse(sessionStorage.getItem('info'))

  if (!info) {
    return null
  }

  const { auth } = info

  if (!auth) {
    return null
  }

  return parseInt(auth.user_pes_id)
}
