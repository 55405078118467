import styled from 'styled-components'

export const PDTContainer = styled.div`
  margin: 0px 16px 16px 16px;
  .ant-collapse {
    margin-top: 8px;
  }
`

export const Label = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #000;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
    flex-direction: column;

    * + * {
      margin-top: 8px;
    }

    ${Label} {
      flex: 1;
      text-align: center;
    }
  }
`
