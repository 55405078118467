import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Button,
  Popconfirm,
  Modal,
  Divider,
  Card,
  Tooltip,
  Switch,
  Space,
} from 'antd'

import {
  ExclamationCircleFilled,
  DeleteOutlined,
  Loading3QuartersOutlined,
  UserAddOutlined,
  EditOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import Table from '../../../../components/editableTable/EditableTable'

import ModalCadastro from './components/ModalCadastro/index'

import {
  listAtividades,
  storeAtividades,
  updateAtividade,
  deleteAtividades,
  loadAtividades,
  getFrequencia,
} from './actions/actions'

import { Action, TableHeader } from './styles'

function Atividades({ id }) {
  const dispatch = useDispatch()

  const [showModalNewAtividade, setShowModalNewAtividade] = useState(false)

  const [showModalUpdateAtividade, setShowModalUpdateAtividade] =
    useState(false)

  const [newAtividade, setNewAtividade] = useState(null)

  const [atividades, setAtividades] = useState([])

  const [frequencia_nome, setFrequenciaNome] = useState([])

  const [frequencia, setFrequencias] = useState([])

  const [showInativos, setShowInativos] = useState(false)

  const [loadingAtividades, setloadingAtividades] = useState(false)

  const [handleAtividade, sethandleUpdateAtividade] = useState(false)

  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const [atividadeUpdated, setAtividadeUpdated] = useState({
    oquefaz: undefined,
    comofaz: undefined,
    paraquefaz: undefined,
    frequencia_id: undefined,
  })

  const loadAtividades = useCallback(() => {
    setloadingAtividades(true)
    dispatch(listAtividades(id)).then(({ response }) => {
      setloadingAtividades(false)
      if (response.status === 200) {
        setAtividades(response.data)
      }
    })
  }, [dispatch, id])

  const listFrequencias = useCallback(() => {
    dispatch(getFrequencia()).then(({ response }) => {
      if (response.status === 200) {
        const frequencia = response.data
        setFrequencias(frequencia)
        const frequencia_nome = response.data
        setFrequenciaNome(
          frequencia_nome.map((m) => ({ value: m.id, label: m.nome }))
        )
      }
    })
  }, [dispatch])

  useEffect(() => {
    listFrequencias()
    loadAtividades()
  }, [loadAtividades, listFrequencias])

  const onFinishNewAtividade = () => {
    dispatch(storeAtividades()).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Atividade cadastrado com sucesso.')
        loadAtividades()
      } else toast.error('Erro ao cadastrar Atividade.')
    })
  }

  const handleListAtividades = useCallback(() => {
    dispatch(listAtividades()).then(({ response }) => {
      if (response.status === 200) {
        const { atividades } = response.data
        setAtividades(
          atividades.map((m) => ({ value: m.atividade_id, label: m.nome }))
        )
      }
    })
  }, [dispatch])

  const handleDeleteAtividade = useCallback(
    (atividade_id) => {
      dispatch(deleteAtividades({ atividade_id, id })).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Atividade excluída com sucesso.')
          loadAtividades()
        }
      })
    },
    [dispatch, id, loadAtividades]
  )

  const handleUpdateAtividade = useCallback(
    (updatedAtividade, data) => {
      dispatch(
        updateAtividade({
          atividade_id: updatedAtividade.atividade_id,
          ...updatedAtividade,
        })
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Atividade atualizada com sucesso.')
          loadAtividades()
        }
      })
    },
    [dispatch, loadAtividades]
  )

  const handleChangeNewAtividade = (atividade) => {
    setNewAtividade(atividade)
  }

  const handleCancelNewAtividade = useCallback(() => {
    setShowModalNewAtividade(false)
  }, [])

  const handleNewAtividade = useCallback(
    (data) => {
      dispatch(storeAtividades({ data, id })).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Atividade criada com sucesso.')
          setShowModalNewAtividade(false)
          loadAtividades()
        }
      })
    },
    [dispatch, loadAtividades, id]
  )

  // UPDATE ATIVIDADE
  const handleUpdateModal = ({
    id,
    oquefaz,
    paraquefaz,
    comofaz,
    frequencia_id,
  }) => {
    setAtividadeUpdated({ id, oquefaz, paraquefaz, comofaz, frequencia_id })
    setShowUpdateModal(true)
  }

  const handleChangeUpdateAtividade = (
    oquefaz,
    paraquefaz,
    comofaz,
    frequencia_id
  ) => {
    setAtividadeUpdated((prevState) => ({
      ...prevState,
      oquefaz,
      paraquefaz,
      comofaz,
      frequencia_id,
    }))
  }

  const handleSubmitUpdateAtividade = (data) => {
    dispatch(
      updateAtividade({
        id,
        atividade_id: atividadeUpdated.id,
        ...data,
      })
    ).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Atualizado com sucesso.')
        loadAtividades()
        handleCloseUpdateAtividade()
      } else toast.error('Erro ao atualizar atividade.')
    })
  }

  const handleCloseUpdateAtividade = () => {
    setAtividadeUpdated({
      oquefaz: undefined,
      paraquefaz: undefined,
      comofaz: undefined,
      frequencia_id: undefined,
    })
    setShowUpdateModal(false)
  }

  //

  const columns = [
    {
      title: 'O que faz',
      dataIndex: 'oquefaz',
    },
    {
      title: 'Para que faz',
      dataIndex: 'paraquefaz',
    },
    {
      title: 'Como faz',
      dataIndex: 'comofaz',
    },
    {
      title: 'Frequência',
      dataIndex: 'frequencia_id',
      render: (text, record) => {
        const frequencia_nome = frequencia.filter(
          (frequencia) => frequencia.id === text
        )
        return (
          <span>
            {frequencia_nome[0] !== undefined ? frequencia_nome[0].nome : ''}
          </span>
        )
      },
    },
    {
      title: 'Opções',
      key: 'opcoes',
      editable: true,
      className: 'column-opcoes',
      render: (text, record) => (
        <Space split={<Divider type="vertical" />}>
          <a>
            <Popconfirm
              title="Tem certeza que deseja remover do cargo?"
              onConfirm={() => handleDeleteAtividade(record.id)}
              okText="Sim"
              cancelText="Não"
            >
              <Tooltip title="Excluir" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </a>
          <a
            href="javascript:;"
            onClick={() => handleUpdateModal(record)}
            aria-label="editar"
          >
            <Tooltip title="Editar" placement="bottom">
              <EditOutlined />
            </Tooltip>
          </a>
        </Space>
      ),
    },
  ]

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Card title="Atividades do cargo" type="inner">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '12px',
              }}
            >
              <Button
                type="primary"
                className="btn_purple"
                onClick={() => setShowModalNewAtividade(true)}
                icon={<UserAddOutlined />}
              >
                NOVO
              </Button>
            </div>
            <Table
              bordered
              pagination={false}
              size="small"
              dataSource={atividades.map((atividades, frequencia_nome) => {
                return {
                  ...atividades,
                  ...frequencia_nome,
                  key: atividades.pes_id,
                }
              })}
              columns={columns}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="NOVA INFORMAÇÃO"
        visible={showModalNewAtividade}
        cancelText="CANCELAR"
        onCancel={() => handleCancelNewAtividade()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'cadastro',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <ModalCadastro
          handleChangeAtividade={handleChangeNewAtividade}
          onFinish={handleNewAtividade}
          {...handleAtividade}
          frequencia={frequencia}
        />
      </Modal>
      <Modal
        title="EDITAR INFORMAÇÃO"
        visible={showUpdateModal}
        cancelText="CANCELAR"
        onCancel={() => handleCloseUpdateAtividade()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'cadastro',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <ModalCadastro
          handleChangeAtividade={handleChangeUpdateAtividade}
          onFinish={handleSubmitUpdateAtividade}
          {...atividadeUpdated}
          frequencia={frequencia}
        />
      </Modal>
    </Col>
  )
}
export default Atividades
