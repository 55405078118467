import {
  ContactsOutlined,
  DashboardOutlined,
  FilePdfOutlined,
  ProfileOutlined,
  RadarChartOutlined,
  SettingOutlined,
  TeamOutlined,
  FundProjectionScreenOutlined,
} from '@ant-design/icons'
import { Navigation } from '../../@types/navigation-items'

export const NavigationMenuGestor = (isAdmin: boolean): Navigation[] => {
  return [
    navigationMenuDefault(isAdmin),
    {
      title: 'Estratégia Cultural',
      icon: <RadarChartOutlined />,
      children: [
        {
          title: 'Cultura Desejada',
          href: '/cultura-desejada/dashboard',
        },
        {
          title: 'Valores Organizacionais',
          href: '/valores-organizacionais',
        },
        {
          title: 'Objetivos estrategicos',
          href: '/objetivos-estrategicos',
        },
      ],
    },
    {
      title: 'Cadastros',
      icon: <TeamOutlined />,
      children: [
        {
          title: 'Colaboradores',
          href: '/colaboradores',
        },
        {
          title: 'Avaliadores',
          href: '/avaliadores',
        },
        {
          title: 'Times',
          href: '/times',
        },
        {
          title: 'Cargos',
          href: '/cargos',
        },
        {
          title: 'Filial',
          href: '/filiais',
        },
      ],
    },
    {
      title: 'Fit Cultural',
      href: '/cultura-desejada/relatorios/empresas',
      icon: <FundProjectionScreenOutlined />,
    },
    {
      title: 'Pesquisas',
      href: '/pesquisas/gestao-pesquisas',
      icon: <ProfileOutlined />,
    },
    {
      title: 'Recrutamento e Seleção',
      href: '/recrutamento-selecao',
      icon: <ContactsOutlined />,
    },
    {
      title: 'Relatórios',
      icon: <FilePdfOutlined />,
      children: [
        {
          title: 'Geral (Empresas)',
          children: [
            {
              title: 'Análise Geral',
              href: '/relatorios/geral/analiseGeral',
            },
            {
              title: 'Análise Cultural',
              href: '/relatorios/geral/analiseCultural',
            },
            {
              title: 'Olhar 360',
              href: '/relatorios/geral/olhar360',
            },
            {
              title: 'Perfil Comportamental',
              href: '/relatorios/geral/perfilComportamental',
            },
            {
              title: 'Pessoas - Fit Cultural',
              href: '/relatorios/geral/pessoasFitCultural',
            },
            {
              title: 'Times - Fit Cultural',
              href: '/relatorios/geral/timesFitCultural',
            },
            {
              title: 'Comparativa (BETA)',
              href: '/relatorios/geral/comparativo',
            },
          ],
        },
        {
          title: 'Times',
          children: [
            {
              title: 'Análise Geral',
              href: '/relatorios/times/analiseGeral',
            },
            {
              title: 'Análise Cultural',
              href: '/relatorios/times/analiseCultural',
            },
            {
              title: 'Olhar 360',
              href: '/relatorios/times/olhar360',
            },
            {
              title: 'Perfil Comportamental',
              href: '/relatorios/times/perfilComportamental',
            },
          ],
        },
        {
          title: 'Lideres',
          children: [
            {
              title: 'Análise Geral',
              href: '/relatorios/lideres/analiseGeral',
            },
            {
              title: 'Análise Cultural',
              href: '/relatorios/lideres/analiseCultural',
            },
            {
              title: 'Olhar 360',
              href: '/relatorios/lideres/olhar360',
            },
            {
              title: 'Perfil Comportamental',
              href: '/relatorios/lideres/perfilComportamental',
            },
          ],
        },
      ],
    },
    {
      title: 'Configurações',
      href: '/config',
      icon: <SettingOutlined />,
    },
  ]
}
export const NavigationMenuLider = (isAdmin: boolean): Navigation[] => [
  navigationMenuDefault(isAdmin),
  {
    title: 'Cadastros',
    icon: <TeamOutlined />,
    children: [
      {
        title: 'Colaboradores',
        href: '/colaboradores',
      },
      {
        title: 'Times',
        href: '/times',
      },
    ],
  },
  {
    title: 'Recrutamento e Seleção',
    href: '/recrutamento-selecao',
    icon: <ContactsOutlined />,
  },
  {
    title: 'Relatórios',
    icon: <FilePdfOutlined />,
    children: [
      {
        title: 'Times',
        children: [
          {
            title: 'Análise Geral',
            href: '/relatorios/times/analiseGeral',
          },
          {
            title: 'Análise Cultural',
            href: '/relatorios/times/analiseCultural',
          },
          {
            title: 'Olhar 360',
            href: '/relatorios/times/olhar360',
          },
          {
            title: 'Perfil Comportamental',
            href: '/relatorios/times/perfilComportamental',
          },
        ],
      },
    ],
  },
]

export const navigationMenuDefault = (isAdmin: boolean): Navigation => {
  if (isAdmin) {
    return {
      title: 'Dashboard',
      icon: <DashboardOutlined />,
      children: [
        {
          title: 'Visao Geral',
          href: '/dashboard/visao-geral',
        },
        {
          title: 'Visao Times',
          href: '/dashboard/visao-times',
        },
        {
          title: 'Candidatos',
          href: '/dashboard/candidatos',
        },
        {
          title: 'Colaboradores',
          href: '/dashboard/colaboradores',
        },
      ],
    }
  }

  return {
    title: 'Dashboard',
    icon: <DashboardOutlined />,
    children: [
      {
        title: 'Visao Times',
        href: '/dashboard/visao-times',
      },
      {
        title: 'Colaboradores',
        href: '/dashboard/colaboradores',
      },
      {
        title: 'Candidatos',
        href: '/dashboard/candidatos',
      },
    ],
  }
}
