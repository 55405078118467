// URLs
export const URL_BASE = process.env.REACT_APP_API_URL
export const URL_PERFIL_INFO_GET = `${URL_BASE}/pessoas/check`
export const URL_VETORES_GET = `${URL_BASE}/vetorCompleto`
export const URL_VETOR_COMPORTAMENTAL_GET = `${URL_BASE}/avaliacoes/list`
export const URL_VETOR_COMPORTAMENTAL_DELETE = `${URL_BASE}/avaliacoes`
export const URL_PERCEPCAO_AMBIENTE_GET = `${URL_BASE}/pesquisa`
export const URL_PERCEPCAO_AMBIENTE_DELETE = `${URL_BASE}/pesquisa`
export const URL_ATIVAR_PERFIL_DELETE = `${URL_BASE}/pessoas/turnOn`
export const URL_DESATIVAR_PERFIL_DELETE = `${URL_BASE}/pessoas/delete`
export const URL_EXCLUIR_PERFIL_DELETE = `${URL_BASE}/pessoas/permaDelete`
export const URL_PERFIL_TIMES_GET = `${URL_BASE}/pessoas/listGruposPessoa`
export const URL_PERFIL_CARGOS_GET = `${URL_BASE}/pessoas/listCargosPessoa`
export const URL_PERFIL_PESQ_VALORES_ORGANIZACIONAIS = (pes_id) =>
  `${URL_BASE}/pesqvalores/${pes_id}`

export const URL_PERFIL_PROCESSO_SELETIVO = (pes_id) =>
  `${URL_BASE}/pessoas/${pes_id}/processosSeletivos`

export const URL_PERFIL_COLABORADOR_PROCESSOS_LIST = (pes_id) =>
  `${URL_BASE}/candidato/${pes_id}/listProcessos`

const URL_PERFIL_COLABORADOR_PROCESSO_LOG = ({ etapacand_id, processo_id }) =>
  `${URL_BASE}/ps/${processo_id}/etapacand/${etapacand_id}/logs`

export const URL_PERFIL_COLABORADOR_PROCESSO_LOG_LIST = ({
  etapacand_id,
  processo_id,
}) => URL_PERFIL_COLABORADOR_PROCESSO_LOG({ etapacand_id, processo_id })

export const URL_PERFIL_COLABORADOR_PROCESSO_LOG_STORE = ({
  etapacand_id,
  processo_id,
}) => URL_PERFIL_COLABORADOR_PROCESSO_LOG({ etapacand_id, processo_id })

export const URL_PERFIL_COLABORADOR_PROCESSO_LOG_DELETE = ({
  etapacand_id,
  processo_id,
  log_id,
}) =>
  `${URL_PERFIL_COLABORADOR_PROCESSO_LOG({
    etapacand_id,
    processo_id,
  })}/${log_id}`

export const URL_DELETE_PERFIL_PESQ_VALORES_ORGANIZACIONAIS = (pes_id) =>
  `${URL_BASE}/pesqvalores/${pes_id}`

export const URL_PERFIL_COLABORADOR_CULTURA_GET = (pes_id) =>
  `${URL_BASE}/dashboard/pessoa/cultura/${pes_id}`

export const URL_PERFIL_COLABORADOR_CULTURAORG_GET = (pes_id) =>
  `${URL_BASE}/dashboard/pessoa/culturaOrganizacional/${pes_id}`

export const URL_PERFIL_COLABORADOR_FITCULTURAL_GET = (pes_id) =>
  `${URL_BASE}/fitcultural/empresa/${pes_id}`

export const URL_GRUPO_PESSOA = `${process.env.REACT_APP_API_URL}/grupoPessoa`
export const URL_GRUPO_PESSOA_PUT = `${URL_GRUPO_PESSOA}/update`
export const URL_PERFIL_COLABORADOR_PROCESSOS_TRIAGEM_LIST = `${URL_BASE}/ps/pendentes`

export const URL_PERFIL_COLABORADOR_CULTURAORG_EMPRESA_GET = `${URL_BASE}/dashboard/culturaOrganizacional`

export const URL_PERFIL_COLABORADOR_CULTURA_EMPRESA_GET = `${URL_BASE}/dashboard/culturas`

export const URL_PERFIL_COLABORADOR_VALORES_ORG_GET = `${URL_BASE}/valoresOrganizacionais`

export const URL_PERFIL_COLABORADOR_UPDATE_CARGO = (cargopes_id) =>
  `${URL_BASE}/cargoPessoa/${cargopes_id}`

// Redux types
export const PERFIL_INFO_GET = 'PERFIL_INFO_GET'
export const VETORES_GET = 'VETORES_GET'
export const VETOR_COMPORTAMENTAL_GET = 'VETOR_COMPORTAMENTAL_GET'
export const VETOR_COMPORTAMENTAL_DELETE = 'VETOR_COMPORTAMENTAL_DELETE'

export const PERCEPCAO_AMBIENTE_GET = 'PERCEPCAO_AMBIENTE_GET'
export const PERCEPCAO_AMBIENTE_DELETE = 'PERCEPCAO_AMBIENTE_DELETE'
export const ATIVAR_PERFIL_DELETE = 'ATIVAR_PERFIL_DELETE'
export const DESATIVAR_PERFIL_DELETE = 'DESATIVAR_PERFIL_DELETE'
export const EXCLUIR_PERFIL_DELETE = 'EXCLUIR_PERFIL_DELETE'
export const PERFIL_TIMES_GET = 'PERFIL_TIMES_GET'
export const PERFIL_CARGOS_GET = 'PERFIL_CARGOS_GET'
export const PERFIL_GET_PESQ_VALORES_ORGANIZACIONAIS =
  'PERFIL_GET_PESQ_VALORES_ORGANIZACIONAIS'
export const PERFIL_DELETE_PESQ_VALORES_ORGANIZACIONAIS =
  'PERFIL_DELETE_PESQ_VALORES_ORGANIZACIONAIS'

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'

export const PERFIL_COLABORADOR_CULTURA_GET = 'PERFIL_COLABORADOR_CULTURA_GET'

export const PERFIL_COLABORADOR_CULTURAORG_GET =
  'PERFIL_COLABORADOR_CULTURAORG_GET'

export const PERFIL_COLABORADOR_FITCULTURAL_GET =
  'PERFIL_COLABORADOR_FITCULTURAL_GET'

export const PERFIL_COLABORADOR_CULTURAORG_EMPRESA_GET =
  'PERFIL_COLABORADOR_CULTURAORG_EMPRESA_GET'

export const PERFIL_COLABORADOR_CULTURA_EMPRESA_GET =
  'PERFIL_COLABORADOR_CULTURA_EMPRESA_GET'

export const PERFIL_COLABORADOR_VALORES_ORG_GET =
  'PERFIL_COLABORADOR_VALORES_ORG_GET'

export const PERFIL_COLABORADOR_UPDATE_CARGO = 'PERFIL_COLABORADOR_UPDATE_CARGO'

export const PERFIL_COLABORADOR_UPDATE_TIME = 'PERFIL_COLABORADOR_UPDATE_TIME'

export const PERFIL_PROCESSO_SELETIVO = 'PERFIL_PROCESSO_SELETIVO'

export const PERFIL_COLABORADOR_PROCESSOS_TRIAGEM_LIST =
  'PERFIL_COLABORADOR_PROCESSOS_TRIAGEM_LIST'

export const PERFIL_COLABORADOR_PROCESSOS_LIST =
  'PERFIL_COLABORADOR_PROCESSOS_LIST'

export const PERFIL_COLABORADOR_PROCESSO_LOG_LIST =
  'PERFIL_COLABORADOR_PROCESSO_LOG_LIST'

export const PERFIL_COLABORADOR_PROCESSO_LOG_DELETE =
  'PERFIL_COLABORADOR_PROCESSO_LOG_DELETE'

export const PERFIL_COLABORADOR_PROCESSO_LOG_STORE =
  'PERFIL_COLABORADOR_PROCESSO_LOG_STORE'

export const GRUPO_PESSOA_PUT = 'GRUPO_PESSOA_PUT'
