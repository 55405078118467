import * as constants from '../constants'

export const setPesos = ({ pesoArquetipos, pesoValores }) => {
  return {
    type: constants.CONFIG_PESQUISAS_SET_PESOS,
    api: {
      method: 'put',
      url: constants.URL_CONFIG_PESQUISAS_SET_PESOS,
      data: {
        pesoArquetipos,
        pesoValores,
      },
    },
  }
}

export const getPesos = () => {
  return {
    type: constants.CONFIG_PESQUISAS_GET_PESOS,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_PESQUISAS_GET_PESOS,
    },
  }
}

export const setFitMinimoValue = ({ fitMinimo }) => {
  return {
    type: constants.CONFIG_PESQUISAS_SET_FIT_MINIMO,
    api: {
      method: 'put',
      url: constants.URL_CONFIG_PESQUISAS_SET_FIT_MINIMO,
      data: {
        fitMinimo,
      },
    },
  }
}

export const getFitMinimo = () => {
  return {
    type: constants.CONFIG_PESQUISAS_GET_FIT_MINIMO,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_PESQUISAS_GET_FIT_MINIMO,
    },
  }
}
