import { useState, useEffect } from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Checkbox, Col, Modal, Row, Spin } from 'antd'
import { injectIntl } from 'react-intl'
// Assets
import Button from '../../components/button/Button'
import { ReactComponent as LogoOne } from '../../assets/logoOneBlack.svg'
import { ReactComponent as LogoTwo } from '../../assets/logoTwo.svg'
import { ReactComponent as LogoThree } from '../../assets/logoThree.svg'
import Input, { Password } from '../../components/input/Input'
import Select, { Option } from '../../components/select/Select'
// CSS
import './Login.css'

function LoginView({
  intl,
  handleChange,
  handleChangeCheckbox,
  onSubmit,
  showModal,
  closeModal,
  history,
  contas,
  contaSelecionada,
  handleChangeSelect,
  onSelectConta,
  isLoading,
}) {
  const [contador, setContador] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      if (contador < 2) setContador(contador + 1)
      else setContador(0)
    }, 1000)
  }, [contador])

  return (
    <Modal
      visible
      centered
      mask={false}
      footer={null}
      closable={false}
      wrapClassName="login"
    >
      <div className="login__header">
        {contador == 0 ? (
          <LogoOne />
        ) : contador == 1 ? (
          <LogoTwo />
        ) : (
          <LogoThree />
        )}
      </div>
      <form className="login__form" onSubmit={(values) => onSubmit(values)}>
        <Row>
          <Col lg={24} sm={24} xs={24} md={24}>
            <Input
              type="email"
              placeholder={intl.formatMessage({ id: 'common.email' })}
              name="email"
              onChange={handleChange}
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={24} sm={24} xs={24} md={24}>
            <Password
              placeholder={intl.formatMessage({
                id: 'common.senha',
              })}
              name="senha"
              onChange={handleChange}
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Col>
        </Row>
        <div className="login__form__footer">
          <Checkbox name="rememberMe" onChange={handleChangeCheckbox}>
            Lembre-se de mim
          </Checkbox>
          <Button
            type="link"
            className="login__form__footer__link-btn"
            onClick={() => history.push('/esqueci-minha-senha')}
          >
            Esqueci minha senha
          </Button>
        </div>
        <Row>
          <Col lg={24} sm={24} xs={24} md={24}>
            {isLoading ? (
              <Spin tip="">
                <Button
                  type="primary"
                  className="btn_orange"
                  htmlType="submit"
                  block
                >
                  Entrar
                </Button>
              </Spin>
            ) : (
              <Button
                type="primary"
                className="btn_orange"
                htmlType="submit"
                block
              >
                Entrar
              </Button>
            )}
          </Col>
          {/*
					<Col md={24}>
						<Button
							type='link'
							className='login__form__link-btn'
							onClick={() => history.push('/cadastro')}
							block
						>
							Cadastre-se
						</Button>
					</Col>
					*/}
        </Row>
      </form>
      <Modal
        title="Selecione sua conta"
        visible={showModal}
        centered
        onOk={onSelectConta}
        onCancel={closeModal}
      >
        <Row>
          <Col lg={24} sm={24} xs={24} md={24}>
            <Select
              label="Selecione a conta que você deseja entrar"
              onChange={handleChangeSelect}
              value={contaSelecionada}
            >
              {contas.map((conta, index) => (
                <Option value={index}>{conta.auth.conta_nome}</Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Modal>
    </Modal>
  )
}

export default injectIntl(LoginView)
