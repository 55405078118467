import * as constants from '../constants'
import { getContaURL } from '../../../services/auth'

export const listUsers = () => {
  return {
    type: constants.CONFIG_USUARIOS_LIST_USUARIOS,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_USUARIOS_LIST_USUARIOS,
    },
  }
}

export const storeUser = (data) => {
  return {
    type: constants.CONFIG_USUARIOS_STORE_USUARIO,
    api: {
      method: 'post',
      url: constants.URL_CONFIG_USUARIOS_STORE_USUARIO,
      data: {
        ...data,
      },
    },
  }
}

export const updateUser = ({ user_id, ...data }) => {
  return {
    type: constants.CONFIG_USUARIOS_UPDATE_USUARIO,
    api: {
      method: 'put',
      url: constants.URL_CONFIG_USUARIOS_UPDATE_USUARIO(user_id),
      data: {
        ...data,
      },
    },
  }
}

export const disableUser = (user_id) => {
  return {
    type: constants.CONFIG_USUARIOS_DISABLE_USUARIO,
    api: {
      method: 'put',
      url: constants.URL_CONFIG_USUARIOS_DISABLE_USUARIO(user_id),
    },
  }
}

export const enableUser = (user_id) => {
  return {
    type: constants.CONFIG_USUARIOS_ENABLE_USUARIO,
    api: {
      method: 'put',
      url: constants.URL_CONFIG_USUARIOS_ENABLE_USUARIO(user_id),
    },
  }
}

export const deleteUser = (user_id) => {
  return {
    type: constants.CONFIG_USUARIOS_DELETE_USUARIO,
    api: {
      method: 'delete',
      url: constants.URL_CONFIG_USUARIOS_DELETE_USUARIO(user_id),
    },
  }
}

export const listTipos = () => {
  return {
    type: constants.CONFIG_USUARIOS_LIST_TIPOS,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_USUARIOS_LIST_TIPOS,
    },
  }
}

export const listColaboradores = () => {
  return {
    type: constants.CONFIG_USUARIOS_LIST_COLABORADORES,
    api: {
      method: 'get',
      url: constants.URL_CONFIG_USUARIOS_LIST_COLABORADORES,
    },
  }
}

export const resetPassword = (user_email) => {
  return {
    type: constants.CONFIG_USUARIOS_RESET_PASSWORD,
    api: {
      method: 'post',
      url: constants.URL_CONFIG_USUARIOS_RESET_PASSWORD(),
      data: {
        email: user_email,
        conta_url: getContaURL(),
      },
    },
  }
}

export const listUsuariosVinculados = () => {
  return {
    type: constants.LIST_USUARIOS_VINCULADOS,
    api: {
      method: 'get',
      url: constants.URL_LIST_USUARIOS_VINCULADOS,
    },
  }
}

export const listTimes = () => {
  return {
    type: constants.USUARIOS_LIST_TIMES,
    api: {
      method: 'get',
      url: constants.URL_USUARIOS_LIST_TIMES,
    },
  }
}
