import React, { useState, useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { Col, Steps, Row, Space, Button } from 'antd'

import Questao from './Questao'

import {
  ContainerQuestoes,
  ContainerIndicadorQuestao,
  IndicadorQuestao,
} from '../styles'

const { Step } = Steps

function Questoes({
  preview,
  questoesQuiz,
  tempo_limite,
  handleEncerrar,
  handleTimeout,
}) {
  const dispatch = useDispatch()

  const [questaoAtual, setQuestaoAtual] = useState(0)

  const tempoLimiteQuestao = tempo_limite
    ? (tempo_limite * 60000) / questoesQuiz.length
    : 180000

  const handleQuestaoEscolha = useCallback(
    (questao_id, resposta) => {
      dispatch({
        type: 'POST_RESPOSTA_QUESTAO',
        data: { questao_id, alternativa_id: resposta },
      })
    },
    [dispatch]
  )

  const handleQuestaoTextual = useCallback(
    (questao_id, resposta) => {
      dispatch({
        type: 'POST_RESPOSTA_QUESTAO',
        data: { questao_id, valor: resposta },
      })
    },
    [dispatch]
  )

  const handleProximaQuestao = useCallback(
    (timeout, tipo, questao_id, resposta) => {
      const ultimaQuestao = questaoAtual == questoesQuiz.length - 1

      if (timeout) {
        ultimaQuestao ? handleTimeout() : setQuestaoAtual(questaoAtual + 1)
      } else if (ultimaQuestao) {
        tipo === 'E'
          ? handleQuestaoEscolha(questao_id, resposta)
          : handleQuestaoTextual(questao_id, resposta)

        handleEncerrar()
      } else {
        tipo === 'E'
          ? handleQuestaoEscolha(questao_id, resposta)
          : handleQuestaoTextual(questao_id, resposta)

        setQuestaoAtual(questaoAtual + 1)
      }
    },
    [
      setQuestaoAtual,
      questaoAtual,
      questoesQuiz,
      handleEncerrar,
      handleTimeout,
      handleQuestaoEscolha,
      handleQuestaoTextual,
    ]
  )

  const handleQuestaoAnterior = useCallback(
    (timeout) => {
      const ultimaQuestao = questaoAtual == questoesQuiz.length - 1
      setQuestaoAtual(questaoAtual - 1)
    },
    [setQuestaoAtual, questaoAtual, questoesQuiz]
  )

  const handleProximaQuestaoPreview = useCallback(
    (timeout) => {
      const ultimaQuestao = questaoAtual == questoesQuiz.length - 1

      timeout
        ? ultimaQuestao
          ? handleTimeout()
          : setQuestaoAtual(questaoAtual + 1)
        : ultimaQuestao
        ? handleEncerrar()
        : setQuestaoAtual(questaoAtual + 1)
    },
    [setQuestaoAtual, questaoAtual, questoesQuiz, handleEncerrar, handleTimeout]
  )

  const handleQuestao = useCallback(
    (questaoAtual) => {
      const questao = questoesQuiz[questaoAtual]

      return (
        <Questao
          preview={preview}
          key={questaoAtual}
          tipo={questao.tipo}
          questao_id={questao.questao_id}
          questao={questao.questao}
          alternativas={
            questao.alternativas
              ? questao.alternativas
              : questao.quizQuestaoAlternativa
          }
          handleProximaQuestao={
            preview ? handleProximaQuestaoPreview : handleProximaQuestao
          }
          tempoLimiteQuestao={tempoLimiteQuestao}
        />
      )
    },
    [
      preview,
      questoesQuiz,
      handleProximaQuestao,
      handleProximaQuestaoPreview,
      tempoLimiteQuestao,
    ]
  )

  return (
    <>
      <Row gutter={16} justify="center" style={{ marginTop: '20px' }}>
        <Space size={16}>
          {questaoAtual !== 0 && (
            <Button
              size="large"
              type="primary"
              className="formulario__textual__confirmar"
              onClick={() => handleQuestaoAnterior()}
              ghost
            >
              VOLTAR
            </Button>
          )}
          <Button
            size="large"
            type="primary"
            className="formulario__textual__confirmar"
            onClick={() => handleProximaQuestao()}
            ghost
          >
            PRÓXIMO
          </Button>
        </Space>
      </Row>
      <ContainerQuestoes>
        <ContainerIndicadorQuestao>
          <Col xs={20} md={6}>
            <IndicadorQuestao current={questaoAtual}>
              {questoesQuiz.map((item, index) => (
                <Step key={index} />
              ))}
            </IndicadorQuestao>
          </Col>
        </ContainerIndicadorQuestao>
        {handleQuestao(questaoAtual)}
      </ContainerQuestoes>
    </>
  )
}
export default Questoes
