import React from 'react'

// Containers
import ObjetivosEstrategicos from '../../objetivosEstrategicos/ObjetivosEstrategicos'
import ValoresOrganizacionais from '../../valoresOrganizacionais/ValoresOrganizacionais'
import CadastroColaboradores from '../../cadastroColaboradores/CadastroColaboradores'

// URLs
export const URL_ONBOARDING_POST = `${process.env.REACT_APP_API_URL}/conta/setOnboarding`

// Redux types
export const ONBOARDING_POST = 'ONBOARDING_POST'

// Steps
export const steps = [
  {
    title: 'Passo 1',
    description: 'Cultura Desejada da organização.',
    content: <ObjetivosEstrategicos onboarding />,
  },
  {
    title: 'Passo 2',
    description: 'Valores organizacionais.',
    content: <ValoresOrganizacionais />,
  },
  {
    title: 'Passo 3',
    description: 'Cadastro de colaboradores.',
    content: <CadastroColaboradores onboarding />,
  },
]
