import styled, { css } from 'styled-components'

import { Input as AntdInput } from 'antd'

interface AvailableArquetiposValueProps {
  invalid?: boolean
  available?: boolean
}

export const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-form-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;

    .ant-form-item-label {
      padding: 0;
      label {
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }
`

export const AvailableArquetiposValue = styled.span<AvailableArquetiposValueProps>`
  background-color: inherit;
  font-weight: 500;
  margin-left: auto;
  font-size: 16px;

  ${(props) =>
    props.available &&
    css`
      color: var(--purple);
    `}

  ${(props) =>
    props.invalid &&
    css`
      color: #ff4d4f;
    `}
`

export const InputGroup = styled(AntdInput.Group)`
  display: flex !important;
  align-items: center;

  div:first-of-type {
    flex: 1 !important;
  }

  div:last-of-type {
    margin-left: 4px;
  }
`
