import { Button, Table } from 'antd'
import { CSVLink } from 'react-csv'

type Props = {
  dataSource: any[]
  type: string
}

function getBackgroundColor(record: string, valorReal: string) {
  if (record === 'Desejado Time' || record === 'Desejado Empresa')
    return 'purple'
  if (Number(valorReal[1]) > 7) return '#d9534f'
  if (Number(valorReal[1]) > 3) return '#ffcc5c'
  if (Number(valorReal[1]) != 1) return '#88d8b0'
}

const App = (props: Props) => {
  if (props.type === '1') {
    props.dataSource[0].name = 'Desejado Empresa'
  }
  const columns = [
    {
      title: '#',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Aprendizado',
      dataIndex: 'Aprendizado',
      key: 'Aprendizado',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Autoridade',
      dataIndex: 'Autoridade',
      key: 'Autoridade',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Clã',
      dataIndex: 'Clã',
      key: 'Clã',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Colaboração',
      dataIndex: 'Colaboração',
      key: 'Colaboração',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Complacência',
      dataIndex: 'Complacência',
      key: 'Colaboração',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Empreendedora',
      dataIndex: 'Empreendedora',
      key: 'Empreendedora',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Inovação',
      dataIndex: 'Inovação',
      key: 'Inovação',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Regras',
      dataIndex: 'Regras',
      key: 'Regras',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
  ]
  return (
    <div style={{ marginTop: 50 }}>
      <Table
        dataSource={props.dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  )
}

export default App
