import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { Modal, Row, Col, Card, Tabs } from 'antd'

// Services
import { getContaURL } from '../../services/auth'

import history from '../../utils/history'

// Layouts
import ContentLayout from '../../layouts/contentLayout/ContentLayout'

// Components
import ItemKPIPesquisa from './components/ItemKPIPesquisa/ItemKPIPesquisa'
import TableGestaoPesquisas from './components/tableGestaoPesquisas/TableGestaoPesquisas'

// CSS
import './GestaoPesquisas.css'

// Constants
import { modals } from './constants/constantsGestaoPesquisas'

const { TabPane } = Tabs
function GestaoPesquisasView({
  openModal,
  rowSelection,
  showModals,
  closeModal,
  onModalSubmit,
  handleChangeModal,
  modalStates,
  dataSource,
  handleDelete,
  getColumnSearchProps,
  exportarExcel,
  handleChangeCheckbox,
  listPessoas,
  sendVetorComportamental,
  cancelVetorComportamental,
  sendPercepcao,
  cancelPercepcao,
  getPesquisas,
  handleNewLink,
  cancelValoresOrganizacionais,
  sendValoresOrganizacionais,
  kpiComportamental,
  handleKpiComportamentalByDate,
  kpiPercepcao,
  handleKpiPercepcaoByDate,
  kpiValores,
  handleKpiValoresByDate,
}) {
  return (
    <>
      <Helmet>
        <title>TeamHub | Gestão de Pesquisas</title>
      </Helmet>
      <ContentLayout
        className="cargos"
        header={{
          title: 'Gestão de Pesquisas',
          subtitle: 'Gerencie aqui todas as pesquisas enviadas :)',
          breadcrumb: [
            { name: 'Home', path: '/' },
            { name: 'Gestão de Pesquisas' },
          ],
        }}
      >
        <div className="gestao-pesquisas__body">
          <Row>
            <Col md={24}>
              <Card className="gestao-pesquisas__card" type="inner">
                <Tabs
                  defaultActiveKey="1"
                  style={{
                    borderRadius: '2px',
                    borderTop: 'none',
                  }}
                  tabBarStyle={{ backgroundColor: '#fff', marginBottom: 0 }}
                  onTabClick={(tab) => {
                    tab === '1' &&
                      history.push(
                        `/${getContaURL()}/pesquisas/gestao-pesquisas/colaboradores`
                      )
                    tab === '2' &&
                      history.push(
                        `/${getContaURL()}/pesquisas/gestao-pesquisas/candidatos`
                      )
                  }}
                >
                  <TabPane tab={<span>Colaboradores</span>} key="1">
                    <Row gutter={16} style={{ paddingTop: '24px' }}>
                      {kpiComportamental && (
                        <ItemKPIPesquisa
                          titulo={kpiComportamental.titulo}
                          enviadas={kpiComportamental.enviadas}
                          entregues={kpiComportamental.entregues}
                          percentual={kpiComportamental.percentual}
                          handleByDate={handleKpiComportamentalByDate}
                        />
                      )}

                      {kpiPercepcao && (
                        <ItemKPIPesquisa
                          titulo={kpiPercepcao.titulo}
                          enviadas={kpiPercepcao.enviadas}
                          entregues={kpiPercepcao.entregues}
                          percentual={kpiPercepcao.percentual}
                          handleByDate={handleKpiPercepcaoByDate}
                        />
                      )}

                      {kpiValores && (
                        <ItemKPIPesquisa
                          titulo={kpiValores.titulo}
                          enviadas={kpiValores.enviadas}
                          entregues={kpiValores.entregues}
                          percentual={kpiValores.percentual}
                          handleByDate={handleKpiValoresByDate}
                        />
                      )}
                    </Row>
                    <Row>
                      <Col xs={24} sm={24}>
                        <TableGestaoPesquisas
                          openModal={openModal}
                          closeModal={closeModal}
                          onModalSubmit={onModalSubmit}
                          showModals={showModals}
                          modalStates={modalStates}
                          handleChangeModal={handleChangeModal}
                          modalId={1}
                          dataSource={dataSource[1]}
                          rowSelection={rowSelection}
                          handleDelete={handleDelete}
                          getColumnSearchProps={getColumnSearchProps}
                          exportarExcel={exportarExcel}
                          sendVetorComportamental={sendVetorComportamental}
                          sendPercepcao={sendPercepcao}
                          cancelVetorComportamental={cancelVetorComportamental}
                          cancelPercepcao={cancelPercepcao}
                          getPesquisas={getPesquisas}
                          type="colaborador"
                          handleNewLink={handleNewLink}
                          cancelValoresOrganizacionais={
                            cancelValoresOrganizacionais
                          }
                          sendValoresOrganizacionais={
                            sendValoresOrganizacionais
                          }
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab={<span>Candidatos</span>} key="2" />
                </Tabs>
              </Card>
            </Col>
          </Row>
        </div>
        {modals.map(
          ({
            title,
            cancelText,
            okText,
            id,
            width,
            isCandidato,
            children: Children,
          }) => {
            return (
              <Modal
                visible={showModals[id]}
                title={title}
                cancelText={cancelText}
                okText={okText}
                onCancel={() => closeModal(id)}
                onOk={() => onModalSubmit(id)}
                width={width}
                destroyOnClose
                key={id}
              >
                <Children
                  isCandidato={isCandidato}
                  handleChangeModal={(event) => handleChangeModal(id, event)}
                  handleChangeCheckbox={(event) =>
                    handleChangeCheckbox(id, event)
                  }
                  listPessoas={listPessoas[id]}
                  dataSource={dataSource}
                  modalState={modalStates[id]}
                  {...modalStates[id]}
                />
              </Modal>
            )
          }
        )}
      </ContentLayout>
    </>
  )
}

export default injectIntl(GestaoPesquisasView)
