// TYPES
export const ANALISE_GERAL_GET_CULTURA = 'ANALISE_GERAL_GET_CULTURA'
export const ANALISE_GERAL_GET_CULTURA_ORGANIZACIONAL =
  'ANALISE_GERAL_GET_CULTURA_ORGANIZACIONAL'
export const ANALISE_GERAL_LOADING = 'ANALISE_GERAL_LOADING'
export const GET_DASHBOARD_NPS = 'GET_DASHBOARD_NPS'
// URLS
export const URL_ANALISE_GERAL_GET_CULTURA = `${process.env.REACT_APP_API_URL}/dashboard/culturas`

export const URL_ANALISE_GERAL_GET_CULTURA_ORGANIZACIONAL = `${process.env.REACT_APP_API_URL}/dashboard/culturaOrganizacional`

export const URL_DASHBOARD_NPS = `/relatorios/nps`
