import * as constants from '../constants/constants'

export const loadMotivos = () => {
  return {
    type: constants.PS_MOTIVO_LOAD,
  }
}

export const listMotivos = () => {
  return {
    type: constants.PS_MOTIVO_LIST,
    api: {
      method: 'get',
      url: constants.URL_PS_MOTIVO,
    },
  }
}

export const storeMotivos = ({ nome, descricao }) => {
  return {
    type: constants.PS_MOTIVO_STORE,
    api: {
      method: 'post',
      url: constants.URL_PS_MOTIVO,
      data: { nome, descricao },
    },
  }
}

export const updateMotivos = ({ motivo_id, nome, descricao }) => {
  return {
    type: constants.PS_MOTIVO_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_PS_MOTIVO_ID(motivo_id),
      data: { nome, descricao },
    },
  }
}

export const deleteMotivos = ({ motivo_id }) => {
  return {
    type: constants.PS_MOTIVO_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_PS_MOTIVO_ID(motivo_id),
    },
  }
}
