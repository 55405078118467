// redux types
export const CONFIG_PS_LIST_TEMPLATES = 'CONFIG_PS_LIST_TEMPLATES'
export const CONFIG_PS_UPDATE_TEMPLATE = 'CONFIG_PS_UPDATE_TEMPLATE'

export const CONFIG_PS_STORE_ANEXO_AUTOMATICO_TEMPLATE =
  'CONFIG_PS_STORE_ANEXO_AUTOMATICO_TEMPLATE'
export const CONFIG_PS_LIST_ANEXO_AUTOMATICO_TEMPLATES =
  'CONFIG_PS_LIST_ANEXO_AUTOMATICO_TEMPLATES'
export const CONFIG_PS_DELETE_ANEXO_AUTOMATICO_TEMPLATE =
  'CONFIG_PS_DELETE_ANEXO_AUTOMATICO_TEMPLATE'

export const CONFIG_PS_STORE_MANUAL_TEMPLATE = 'CONFIG_PS_STORE_MANUAL_TEMPLATE'
export const CONFIG_PS_LIST_MANUAL_TEMPLATES = 'CONFIG_PS_LIST_MANUAL_TEMPLATES'
export const CONFIG_PS_LIST_MANUAL_PARAMS = 'CONFIG_PS_LIST_MANUAL_PARAMS'
export const CONFIG_PS_UPDATE_MANUAL_TEMPLATE =
  'CONFIG_PS_UPDATE_MANUAL_TEMPLATE'
export const CONFIG_PS_DELETE_MANUAL_TEMPLATE =
  'CONFIG_PS_DELETE_MANUAL_TEMPLATE'

export const CONFIG_PS_STORE_ANEXO_MANUAL_TEMPLATE =
  'CONFIG_PS_STORE_ANEXO_MANUAL_TEMPLATE'
export const CONFIG_PS_LIST_ANEXO_MANUAL_TEMPLATES =
  'CONFIG_PS_LIST_ANEXO_MANUAL_TEMPLATES'
export const CONFIG_PS_DELETE_ANEXO_MANUAL_TEMPLATE =
  'CONFIG_PS_DELETE_ANEXO_MANUAL_TEMPLATE'

// urls
const URL_PS_EMAILS = `${process.env.REACT_APP_API_URL}/psEmails`
export const URL_CONFIG_PS_LIST_TEMPLATES = URL_PS_EMAILS
export const URL_CONFIG_PS_UPDATE_TEMPLATE = (id) => `${URL_PS_EMAILS}/${id}`

// urls email automatico anexos
const URL_PS_ANEXO_EMAILS_AUTOMATICO = `${process.env.REACT_APP_API_URL}/psEmails`
export const URL_CONFIG_PS_STORE_ANEXO_AUTOMATICO_TEMPLATE = (id) =>
  `${URL_PS_ANEXO_EMAILS_AUTOMATICO}/anexo/${id}`
export const URL_CONFIG_PS_LIST_ANEXO_AUTOMATICO_TEMPLATES = `${URL_PS_ANEXO_EMAILS_AUTOMATICO}/anexo`
export const URL_CONFIG_PS_DELETE_ANEXO_AUTOMATICO_TEMPLATE = (id) =>
  `${URL_PS_ANEXO_EMAILS_AUTOMATICO}/anexo/${id}`

// urls email manual
const URL_PS_EMAILS_MANUAL = `${process.env.REACT_APP_API_URL}/psManualEmails`
export const URL_CONFIG_PS_STORE_MANUAL_TEMPLATE = URL_PS_EMAILS_MANUAL
export const URL_CONFIG_PS_LIST_MANUAL_TEMPLATES = URL_PS_EMAILS_MANUAL
export const URL_CONFIG_PS_LIST_MANUAL_PARAMS = `${URL_PS_EMAILS_MANUAL}/params`
export const URL_CONFIG_PS_UPDATE_MANUAL_TEMPLATE = (id) =>
  `${URL_PS_EMAILS_MANUAL}/${id}`
export const URL_CONFIG_PS_DELETE_MANUAL_TEMPLATE = (id) =>
  `${URL_PS_EMAILS_MANUAL}/${id}`

// urls anexos email manual
const URL_PS_ANEXO_EMAILS_MANUAL = `${process.env.REACT_APP_API_URL}/psManualEmails`
export const URL_CONFIG_PS_STORE_ANEXO_MANUAL_TEMPLATE = (id) =>
  `${URL_PS_ANEXO_EMAILS_MANUAL}/anexo/${id}`
export const URL_CONFIG_PS_LIST_ANEXO_MANUAL_TEMPLATES = `${URL_PS_ANEXO_EMAILS_MANUAL}/anexo`
export const URL_CONFIG_PS_DELETE_ANEXO_MANUAL_TEMPLATE = (id) =>
  `${URL_PS_ANEXO_EMAILS_MANUAL}/anexo/${id}`
