import { Button, Col, Row, Table } from 'antd'
import { useEffect } from 'react'
import { useFetch } from '../../../../../../../hooks/use-fetch'
import '../../../../../Dashboard.css'

const ComparacaoTime = ({
  columns,
  membros,
  setMembros,
  loadingMembros,
  setShowModalAddCandidato,
  setShowModalAddColaborador,
  grupoId,
}) => {
  const { response, isLoading } = useFetch(
    grupoId ? `/v2/dashboard/grupo/membros/${grupoId}` : undefined
  )
  useEffect(() => {
    if (!isLoading && response) {
      setMembros(response)
    }
  }, [isLoading, response, setMembros])

  return (
    <Table
      columns={columns}
      key={(record) => record.pes_id}
      dataSource={[...membros]}
      pagination={false}
      loading={loadingMembros}
      scroll={{ y: 400 }}
      size="middle"
      footer={() => (
        <div>
          Adicionar uma pessoa para comparação:
          <Row
            type="flex"
            justify="space-between"
            className="row-button-list"
            gutter={16}
          >
            <Col span={12}>
              <Button
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
                onClick={() => setShowModalAddColaborador(true)}
              >
                Colaborador
              </Button>
            </Col>
            <Col span={12}>
              <Button
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
                onClick={() => setShowModalAddCandidato(true)}
              >
                Candidato
              </Button>
            </Col>
          </Row>
        </div>
      )}
    />
  )
}

export default ComparacaoTime
