import React, { useEffect, useState, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { Card, Row, Col, Empty, Progress, Divider, Statistic, Tabs } from 'antd'
import { Link } from 'react-router-dom'

import {
  Chart,
  Line,
  Interval,
  Point,
  Axis,
  Tooltip,
  Coordinate,
  Legend,
  registerShape,
  Interaction,
  Label,
} from 'bizcharts'

import DataSet from '@antv/data-set'

import { useSelector, useDispatch } from 'react-redux'

import {
  arqRange,
  discRange,
  forcasRange,
  colors,
  ArquetiposCulturaisDetalhes,
  getForcasEstrategicasTicks,
} from '../../../utils/utils'

// actions
import { getNps } from './actions/analiseCulturaGeralActions'

import { getContaNome } from '../../../services/auth'
import { noPadding } from '../../../utils/inlineStyles'

import {
  ChartTitle,
  ColFill,
  FlexCard,
  Box,
  ConfiancaBox,
  ConfiancaLabel,
  SatisfacaoBox,
  NPSLegendaLabel,
  NPSLabel,
  NPSChartPercent,
} from './styles'
import { getCulturasFromEmpresas } from '../../../components/FitCultural/Api/api'

const { TabPane } = Tabs

const sliceNumber = 0.01

registerShape('interval', 'sliceShape', {
  draw(cfg, container) {
    const { points } = cfg
    let path = []
    path.push(['M', points[0].x, points[0].y])
    path.push(['L', points[1].x, points[1].y - sliceNumber])
    path.push(['L', points[2].x, points[2].y - sliceNumber])
    path.push(['L', points[3].x, points[3].y])
    path.push('Z')
    path = this.parsePath(path)
    return container.addShape('path', {
      attrs: {
        fill: cfg.color,
        path,
      },
    })
  },
})

const percentScale = {
  percent: {
    formatter: (val) => `${(parseFloat(val) * 100).toFixed(1)}%`,
  },
}

const AnaliseCulturaGeralView = ({
  grupoSelecionado = {},
  culturaOrganizacional,
  cultura,
  forcas,
  olhares,
  gestao,
  disc,
  generos,
  geracoes,
  destaques = null,
}) => {
  const dispatch = useDispatch()

  const conta = getContaNome() || ''

  const [nps, setNPS] = useState([])

  const { niveisConfianca, satisfacaoGeral, valoresEvidencia } =
    culturaOrganizacional

  const ds = new DataSet()
  const dvGestao = ds
    .createView()
    .source(gestao)
    .transform({
      type: 'percent',
      field: 'Valor',
      dimension: 'Tipo',
      groupBy: ['Label'],
      as: 'percent',
    })
  const dvOlhares = ds
    .createView()
    .source(olhares)
    .transform({
      type: 'percent',
      field: 'Valor',
      dimension: 'Tipo',
      groupBy: ['Label'],
      as: 'percent',
    })

  const dvGeneros = ds.createView().source(generos).transform({
    type: 'percent',
    field: 'qtde',
    dimension: 'genero',
    as: 'percent',
  })

  const dvGeracoes = ds.createView().source(geracoes).transform({
    type: 'percent',
    field: 'qtde',
    dimension: 'geracao',
    as: 'percent',
  })

  const loadNPS = useCallback(
    (grupo_id) => {
      if (grupoSelecionado.grupo_id) {
        dispatch(getNps(grupo_id)).then(({ response }) => {
          if (response.status === 200) {
            const nps = response.data
            setNPS(nps)
          } else {
            const nps = 'Não há dados'
            setNPS(nps)
          }
        })
      }
    },
    [dispatch, grupoSelecionado]
  )

  useEffect(() => {
    if (grupoSelecionado.grupo_id) {
      loadNPS(grupoSelecionado.grupo_id)
    }

    culturasFromEmpresas()
  }, [dispatch, grupoSelecionado, loadNPS])

  const cols = {
    percent: {
      min: 0,

      formatter(val) {
        return `${(val * 100).toFixed(2)}%`
      },
    },
  }

  const [showFit, setShow] = useState(false)

  async function culturasFromEmpresas() {
    const culturas = await getCulturasFromEmpresas()
    if (culturas.count == 0) setShow(true)
  }

  return (
    <>
      <Helmet>
        <title>TeamHub | Análise Cultural</title>
      </Helmet>
      <Col span={24} style={{ marginTop: '8px', padding: '0' }}>
        <Row type="flex" gutter={8}>
          <Col
            lg={12}
            xxl={7}
            xs={24}
            sm={24}
            style={{
              flexGrow: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '8px',
            }}
          >
            <Row>
              <Col
                xs={24}
                md={24}
                sm={24}
                style={{ padding: '0', marginBottom: '8px' }}
              >
                <Card
                  title="Arquétipos Culturais"
                  bodyStyle={{ padding: '16px' }}
                  extra={<ArquetiposCulturaisDetalhes />}
                >
                  {!cultura || cultura.length === 0 || !showFit ? (
                    <Empty
                      description={
                        <Link to="../../cultura-desejada/relatorios/empresas">
                          Verifique o fit na aba de fit cultural
                        </Link>
                      }
                    />
                  ) : (
                    <Chart height={400} data={cultura} scale={arqRange} autoFit>
                      <Legend />
                      <Coordinate type="polar" radius={0.8} />
                      <Axis
                        name="Tipo"
                        line={null}
                        tickLine={null}
                        grid={{
                          lineStyle: {
                            lineDash: null,
                          },
                          hideFirstLine: false,
                        }}
                      />
                      <Tooltip />
                      <Axis
                        name="Valor"
                        line={null}
                        tickLine={null}
                        grid={{
                          type: 'polygon',
                          lineStyle: {
                            lineDash: null,
                          },
                          alternateColor: 'rgba(0, 0, 0, 0.04)',
                        }}
                      />
                      <Line
                        position="Tipo*Valor"
                        color={['Label', colors]}
                        size={2}
                      />
                      <Point
                        position="Tipo*Valor"
                        color={['Label', colors]}
                        shape="circle"
                        size={4}
                        style={{
                          stroke: '#fff',
                          lineWidth: 1,
                          fillOpacity: 1,
                        }}
                      />
                    </Chart>
                  )}
                </Card>
              </Col>
            </Row>

            <ColFill xs={24} md={24} sm={24} style={noPadding}>
              <FlexCard
                title="Níveis de Confiança"
                bodyStyle={{ padding: '8px' }}
              >
                {!niveisConfianca || niveisConfianca.length === 0 ? (
                  <Empty description={false} />
                ) : (
                  niveisConfianca.map((item) => (
                    <ConfiancaBox key={item.TEXTO}>
                      <ConfiancaLabel>{item.TEXTO}</ConfiancaLabel>
                      <Progress
                        type="dashboard"
                        percent={parseFloat(item.VALOR)}
                        gapDegree={30}
                        width={90}
                        strokeColor={getComputedStyle(
                          document.body
                        ).getPropertyValue('--orange')}
                      />
                    </ConfiancaBox>
                  ))
                )}
              </FlexCard>
            </ColFill>
          </Col>

          <Col
            lg={12}
            xxl={6}
            xs={24}
            sm={24}
            style={{
              flexGrow: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '8px',
            }}
          >
            <Row>
              <Col
                xs={24}
                md={24}
                sm={24}
                style={{ padding: '0', marginBottom: '8px' }}
              >
                <Card title="Gestão" bodyStyle={{ padding: '16px' }}>
                  <Row>
                    <Col xs={24} md={12} lg={12}>
                      <Row
                        type="flex"
                        justify="center"
                        style={{ marginBottom: '8px' }}
                      >
                        <ChartTitle>Modelo de Gestão</ChartTitle>
                      </Row>
                      {!gestao || gestao.length === 0 ? (
                        <Empty description={false} />
                      ) : (
                        <Chart
                          height={400 - 25 - 8}
                          data={dvGestao}
                          scale={cols}
                          autoFit
                        >
                          <Legend />
                          <Axis name="Label" />
                          <Axis name="percent" />
                          <Tooltip />
                          <Interval
                            adjust="stack"
                            position="Label*percent"
                            color={['Tipo', colors]}
                          />
                        </Chart>
                      )}
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Row
                        type="flex"
                        justify="center"
                        style={{ marginBottom: '8px' }}
                      >
                        <ChartTitle>Olhar para o Ambiente</ChartTitle>
                      </Row>
                      {!olhares || olhares.length === 0 ? (
                        <Empty description={false} />
                      ) : (
                        <Chart
                          height={400 - 25 - 8}
                          data={dvOlhares}
                          scale={cols}
                          autoFit
                        >
                          <Legend />
                          <Axis name="Label" />
                          <Axis name="percent" />
                          <Tooltip />
                          <Interval
                            adjust="stack"
                            position="Label*percent"
                            color={['Tipo', colors]}
                          />
                        </Chart>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <ColFill xs={24} md={24} sm={24} style={noPadding}>
              <FlexCard title="Valores em Evidência">
                {!valoresEvidencia || valoresEvidencia.length === 0 ? (
                  <Empty description={false} />
                ) : (
                  valoresEvidencia.map((item) => (
                    <Box key={item.TEXTO}>
                      <Statistic
                        title={item.TEXTO}
                        value={item.VALOR}
                        suffix="%"
                      />
                    </Box>
                  ))
                )}
              </FlexCard>
            </ColFill>
          </Col>

          <Col
            lg={24}
            xxl={7}
            xs={24}
            sm={24}
            style={{
              flexGrow: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '8px',
            }}
          >
            <Row>
              <Col
                sm={24}
                xs={24}
                style={{ padding: '0', marginBottom: '8px' }}
              >
                <Card
                  title="Forças estratégicas"
                  bodyStyle={{ padding: '16px' }}
                >
                  {!forcas || forcas.length === 0 ? (
                    <Empty description={false} />
                  ) : (
                    <Chart
                      height={400}
                      data={forcas.sort(function (a, b) {
                        if (a.Tipo < b.Tipo) {
                          return -1
                        }
                        if (a.Tipo > b.Tipo) {
                          return 1
                        }
                        return 0
                      })}
                      padding={[0, 50, 0, 70]}
                      autoFit
                      scale={forcasRange}
                    >
                      <Legend />
                      <Coordinate transpose scale={[1, -1]} />
                      <Axis
                        name="Tipo"
                        label={{
                          offset: 12,
                          formatter(text, item, index) {
                            const arr = text.split(' ')
                            let string = ''
                            for (const w of arr) {
                              string =
                                string === '' ? `${w}` : `${string}\n${w}`
                            }
                            return string
                          },
                        }}
                      />
                      <Axis name="Valor" position="right" />
                      <Tooltip />
                      <Interval
                        position="Tipo*Valor"
                        color={['Label', colors]}
                        adjust={[
                          {
                            type: 'dodge',
                            marginRatio: 1 / 32,
                          },
                        ]}
                        label={[
                          'Valor',
                          (valor) => {
                            return `${valor.toFixed(2)}%`
                          },
                        ]}
                      />
                    </Chart>
                  )}
                </Card>
              </Col>
            </Row>

            <ColFill sm={24} xs={24} style={noPadding}>
              <FlexCard title="Satisfação Geral">
                {!satisfacaoGeral || satisfacaoGeral.length === 0 ? (
                  <Empty description={false} />
                ) : (
                  satisfacaoGeral.map((item) => (
                    <SatisfacaoBox key={item.TEXTO}>
                      <Statistic
                        title={item.TEXTO}
                        value={item.VALOR}
                        suffix="%"
                      />
                    </SatisfacaoBox>
                  ))
                )}
              </FlexCard>
            </ColFill>
          </Col>

          <Col
            lg={24}
            xxl={4}
            xs={24}
            sm={24}
            style={{
              flexGrow: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '8px',
            }}
          >
            <Row>
              <Col
                xs={24}
                sm={12}
                md={24}
                style={{ ...noPadding, marginBottom: '8px' }}
              >
                <Card title="Gerações" bodyStyle={{ padding: '16px' }}>
                  <Chart
                    data={dvGeracoes}
                    height={330}
                    autoFit
                    scale={percentScale}
                  >
                    <Coordinate type="theta" radius={0.8} innerRadius={0.75} />
                    <Axis visible={false} />
                    <Tooltip showTitle={false} />
                    <Interval
                      adjust="stack"
                      position="percent"
                      color={['geracao', colors]}
                      shape="sliceShape"
                    />
                    <Interaction type="element-single-selected" />
                  </Chart>
                </Card>
              </Col>
            </Row>
            <ColFill sm={12} xs={24} md={24} style={noPadding}>
              <FlexCard title="Gêneros" bodyStyle={{ padding: '16px' }}>
                <Chart
                  data={dvGeneros}
                  height={330}
                  autoFit
                  scale={percentScale}
                >
                  <Coordinate type="theta" radius={0.8} innerRadius={0.75} />
                  <Axis visible={false} />
                  <Tooltip showTitle={false} />
                  <Interval
                    adjust="stack"
                    position="percent"
                    color={['genero', colors]}
                    shape="sliceShape"
                  />
                  <Interaction type="element-single-selected" />
                </Chart>
              </FlexCard>
            </ColFill>
          </Col>
          {destaques && (
            <Col xs={24} sm={24} md={18}>
              <Card title="Arquétipos Culturais - Ranking de Times">
                <Tabs
                  defaultActiveKey="0"
                  style={{
                    borderRadius: '2px',
                    border: 'none',
                  }}
                  tabBarStyle={{
                    backgroundColor: '#fff',
                    marginBottom: '16px',
                  }}
                >
                  {Object.keys(destaques).map((destaque, index) => (
                    <TabPane tab={destaque} key={`${index}`}>
                      {destaques[destaque].length === 0 ? (
                        <Empty description={false} />
                      ) : (
                        <Chart
                          height={400}
                          data={destaques[destaque].sort(function (a, b) {
                            if (a.grupo_nome < b.grupo_nome) {
                              return -1
                            }
                            if (a.grupo_nome > b.grupo_nome) {
                              return 1
                            }
                            return 0
                          })}
                          autoFit
                        >
                          <Axis name="grupo_nome" />
                          <Axis name="valor" />
                          <Tooltip
                            crosshairs={{
                              type: 'y',
                            }}
                          />
                          <Interval
                            position="grupo_nome*valor"
                            color={['grupo_nome', colors]}
                          />
                        </Chart>
                      )}
                    </TabPane>
                  ))}
                </Tabs>
              </Card>
            </Col>
          )}

          <ColFill xs={24} md={6} sm={24} lg={6}>
            <FlexCard
              title="e-NPS do Time"
              bodyStyle={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NPSChartPercent xs={24} md={24} sm={24}>
                <NPSLabel style={{ marginTop: '8px' }}>
                  Resultado e-NPS: {nps} (
                  {nps === 'N/A'
                    ? 'não há dados gerais'
                    : nps >= 75
                    ? 'excelente'
                    : nps >= 50
                    ? 'muito bom'
                    : nps >= 0
                    ? 'razoável'
                    : 'ruim'}
                  )
                </NPSLabel>
              </NPSChartPercent>
              <NPSChartPercent xs={24} md={24} sm={24}>
                {nps !== 'N/A' ? (
                  <Progress
                    type="dashboard"
                    percent={parseFloat((nps + 100) / 2)}
                    gapDegree={30}
                    width={100}
                    strokeColor={getComputedStyle(
                      document.body
                    ).getPropertyValue('--orange')}
                  />
                ) : (
                  <Progress
                    type="dashboard"
                    percent={100}
                    status="exception"
                    gapDegree={30}
                    width={100}
                    strokeColor={getComputedStyle(
                      document.body
                    ).getPropertyValue('--orange')}
                  />
                )}
              </NPSChartPercent>
              <Row style={{ justify: 'top', marginBottom: '8px' }}>
                <h3 style={{ margin: '0px' }}>Legenda: </h3>
                <Divider style={{ marginBottom: '16px', marginTop: '0px' }} />
                <NPSLegendaLabel>
                  Excelente: entre 75 e 100 (87,5% a 100%);
                </NPSLegendaLabel>
                <NPSLegendaLabel>
                  Muito bom: entre 50 e 74 (75% a 87%);
                </NPSLegendaLabel>
                <NPSLegendaLabel>
                  Razoável: entre 0 e 49 (50% a 74,5%);
                </NPSLegendaLabel>
                <NPSLegendaLabel>
                  Ruim: entre -100 e -1 (0% a 49,5%).
                </NPSLegendaLabel>
              </Row>
            </FlexCard>
          </ColFill>
        </Row>
      </Col>
    </>
  )
}

export default AnaliseCulturaGeralView
