// Redux types
import * as constants from '../constants/constantsCargos'
import { message } from 'antd'

const initialState = {
  list: [],
}

export const cargos = (state = initialState, action) => {
  switch (action.type) {
    case constants.CARGOS_GET:
      const { cargos } = action.response.data

      if (action.response.status !== 200) return { ...state }

      return { ...state, list: cargos }

    case constants.CARGO_POST:
      if (action.response.status !== 200) message.error('Erro ao cadastrar.')

      return state

    case constants.CARGO_DELETE:
      if (action.response.status !== 200) message.error('Erro ao deletar.')

      return state

    case constants.CARGOS_PUT:
      if (action.response.status !== 200) message.error('Erro ao editar.')

      return state

    case constants.ATIVIDADE_POST:
      if (action.response.status !== 200)
        message.error('Erro ao adicionar informação.')

      return state

    case constants.FERRAMENTA_POST:
      if (action.response.status !== 200)
        message.error('Erro ao adicionar informação.')

      return state

    case constants.REQUISITO_POST:
      if (action.response.status !== 200)
        message.error('Erro ao adicionar informação.')

      return state

    case constants.TECNICA_POST:
      if (action.response.status !== 200)
        message.error('Erro ao adicionar informação.')

      return state

    case constants.CARGOS_SEND_AVALIACOES:
      if (action.response.status !== 200)
        message.error('Erro ao gerar pesquisa de perfil comportamental.')

      return state

    default:
      return state
  }
}
