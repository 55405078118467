import React from 'react'
import { injectIntl } from 'react-intl'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Row, Col } from 'antd'

// Components
import { TextArea } from '../../../../components/input/Input'

// Constants

function AdicionarPDT({ intl, handleChangeModal, objetivo, error }) {
  return (
    <div>
      <Form>
        <Row gutter={20}>
          <Col md={24}>
            <Form.Item
              label="Objetivo"
              validateStatus={error.objetivo ? 'error' : ''}
              help={error.objetivo || ''}
            >
              <TextArea
                type="text"
                placeholder="Qual o objetivo deste PDT? O que deve ser alcançado?"
                name="objetivo"
                autoSize={{ minRows: '3', maxRows: '5' }}
                value={objetivo || ''}
                onChange={handleChangeModal}
                withoutMargin
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default injectIntl(AdicionarPDT)
