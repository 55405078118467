import * as constants from '../constants'
import { toast } from 'react-toastify'

const initialState = {
  limiteCadastro: {
    conta_old: 0,
    limiteColaboradores: null,
    limiteUtilizadoColaboradores: null,
    limiteCandidatos: null,
    limiteUtilizadoCandidatos: null,
    colaboradores: null,
    candidatos: null,
    loading: true,
    solicitado: false,
  },
  pesquisas: {
    pesos: {
      arquetipos: 50,
      valores: 50,
      createdBy: undefined,
      createdAt: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    },
  },
  fit: {
    fitminimo: 0,
  },
}

export const configuracoes = (state = initialState, action) => {
  switch (action.type) {
    case constants.CONFIG_GET_LIMITES_CONTA: {
      if (action.response.status !== 200) {
        toast.error('Erro ao obter dados de limites da conta.')
        return state
      }

      const {
        conta_old,
        colaboradores,
        candidatos,
        limitecadastro,
        limitecandidato,
      } = action.response.data

      const newColaboradores = Object.keys(colaboradores).map((c) => ({
        type: `${c.charAt(0).toUpperCase()}${c.slice(1).toLowerCase()}`,
        value: colaboradores[c],
      }))

      const newCandidatos =
        parseInt(conta_old) === 0
          ? Object.keys(candidatos).map((c) => ({
              type: `${c.charAt(0).toUpperCase()}${c.slice(1).toLowerCase()}`,
              value: candidatos[c],
            }))
          : []

      return {
        ...state,
        limiteCadastro: {
          conta_old: parseInt(conta_old),
          loading: false,
          limiteColaboradores: limitecadastro,
          limiteCandidatos: limitecandidato,
          colaboradores: newColaboradores.filter(
            (c) =>
              ['ativos', 'desligados', 'disponiveis'].indexOf(
                c.type.toLowerCase()
              ) >= 0
          ),
          candidatos: newCandidatos.filter(
            (c) => ['ativos', 'disponiveis'].indexOf(c.type.toLowerCase()) >= 0
          ),
          limiteUtilizadoColaboradores: newColaboradores
            .filter(
              (c) => ['ativos', 'desligados'].indexOf(c.type.toLowerCase()) >= 0
            )
            .reduce((acc, next) => parseInt(next.value) + acc, 0),
          limiteUtilizadoCandidatos:
            parseInt(conta_old) === 0
              ? newCandidatos
                  .filter((c) => ['ativos'].indexOf(c.type.toLowerCase()) >= 0)
                  .reduce((acc, next) => parseInt(next.value) + acc, 0)
              : null,
        },
      }
    }
    case constants.CONFIG_SOLICITAR_NOVOS_LIMITES: {
      const { message } = action.response.data
      if (action.response.status !== 200) {
        toast.error(message)
        return {
          ...state,
          limiteCadastro: { ...state.limiteCadastro, solicitado: false },
        }
      }

      toast.success(message)
      return {
        ...state,
        limiteCadastro: { ...state.limiteCadastro, solicitado: true },
      }
    }
    case constants.BLOCK_SOLICITAR_NOVOS_LIMITES: {
      return {
        ...state,
        limiteCadastro: { ...state.limiteCadastro, solicitado: false },
      }
    }
    case constants.CONFIG_PESQUISAS_GET_PESOS: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204) {
          const { message } = action.response.data
          toast.error(message)
        }
        return { ...state }
      }

      const { params } = action.response.data

      const {
        valor: pesoArquetipos,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
      } = params.find((p) => p.param_id === 114) || {}

      const { valor: pesoValores } =
        params.find((p) => p.param_id === 115) || {}

      return {
        ...state,
        pesquisas: {
          ...state.pesquisas,
          pesos: {
            arquetipos: parseFloat(pesoArquetipos),
            valores: parseFloat(pesoValores),
            createdAt,
            createdBy,
            updatedAt,
            updatedBy,
          },
        },
      }
    }

    case constants.CONFIG_PESQUISAS_GET_FIT_MINIMO: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204) {
          const { message } = action.response.data
          toast.error(message)
        }
        return { ...state }
      }

      const { fit } = action.response.data

      return {
        ...state,
        fit: {
          fitminimo: parseFloat(fit[0].fitminimo),
        },
      }
    }

    default:
      return state
  }
}
