import React from 'react'
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Row, Col, Card, Divider } from 'antd'

function CardStatistic({ nome, maiorScore, menorScore, maiorNome, menorNome }) {
  return (
    <Card className="infografico__card infografico__card--statistic">
      <Row type="flex" justify="space-between">
        <span className="infografico__statistic__title">{nome}</span>
        <InfoCircleOutlined />
      </Row>
      <Row
        type="flex"
        justify="space-between"
        className="infografico__statistic__score-container"
      >
        <Col span={12}>
          <Row
            type="flex"
            justify="center"
            className="infografico__statistic__score-content"
            style={{ borderRight: '1px solid #e6e6e6' }}
          >
            <Col>
              <span className="infografico__statistic__score">
                {maiorScore}%
              </span>
            </Col>
            <Col>
              <ArrowUpOutlined className="infografico__statistic__icon infografico__statistic__icon--left" />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row
            type="flex"
            justify="center"
            className="infografico__statistic__score-content"
          >
            <Col>
              <ArrowDownOutlined className="infografico__statistic__icon infografico__statistic__icon--right" />
            </Col>
            <Col>
              <span className="infografico__statistic__score">
                {menorScore}%
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <span>{`${maiorNome}`}</span>
        <span>{`${menorNome}`}</span>
      </Row>
    </Card>
  )
}

export default CardStatistic
