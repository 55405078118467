import * as constants from '../constants/olhar360GeralConstants'
import { toast } from 'react-toastify'

const initialState = {
  culturaOrganizacional: {
    loading: true,
    niveisConfianca: [],
    olharLideranca: {
      perceboLider: [],
      frequenciaFeedback: [],
      feedbackContribui: [],
      reconhecimento: [],
      expectativaEntregas: [],
      perfilLiderDireto: [],
    },
    olharParaMudancas: {
      pontosFortes: [],
      impedimento: [],
      principalProblema: [],
      proficiencia: null,
      velocidadeAdocao: null,
      engajamento: null,
    },
    olharParaSi: {
      escolhaEmpresa: [],
      prioridades: [],
      expectativas6meses: [],
      fonteEntusiasmo: [],
    },
    valoresEvidencia: [],
    satisfacaoGeral: [],
  },
  disc: {},
}

export const olhar360Geral = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_OLHAR360_GERAL_CULTURA_ORGANIZACIONAL: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error('Erro ao obter Cultura Organizacional')
        return {
          ...state,
          culturaOrganizacional: {
            ...state.culturaOrganizacional,
            loading: false,
          },
        }
      }

      const { culturaOrganizacional, disc } = action.response.data
      return {
        ...state,
        culturaOrganizacional: {
          ...initialState.culturaOrganizacional,
          ...culturaOrganizacional,
          olharLideranca: {
            ...initialState.culturaOrganizacional.olharLideranca,
            ...culturaOrganizacional.olharLideranca,
          },
          olharParaMudancas: {
            ...initialState.culturaOrganizacional.olharParaMudancas,
            ...culturaOrganizacional.olharParaMudancas,
          },
          olharParaSi: {
            ...initialState.culturaOrganizacional.olharParaSi,
            ...culturaOrganizacional.olharParaSi,
          },
          loading: false,
        },
        disc,
      }
    }
    default:
      return state
  }
}
