import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import { useDispatch, useSelector } from 'react-redux'

import { toast } from 'react-toastify'

import { Col, Tabs } from 'antd'

import LimiteCadastro from './components/LimiteCadastro'

import { noPadding } from '../../utils/inlineStyles'
import { getContaURL } from '../../services/auth'
import history from '../../utils/history'

import {
  getLimitesConta,
  solicitarNovoLimite,
  blockSolicitacaoLimite,
  setPesos,
  getPesos,
  setFitMinimoValue,
  getFitMinimo,
} from './actions'

const { TabPane } = Tabs

function Configuracoes() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLimitesConta())
    dispatch(getPesos())
    dispatch(getFitMinimo())
  }, [dispatch])

  // limites
  const limiteCadastro = useSelector(
    (state) => state.configuracoes.limiteCadastro
  )

  const [novoLimiteColaboradores, setNovoLimiteColaboradores] = useState(0)

  const [novoLimiteCandidatos, setNovoLimiteCandidatos] = useState(0)

  useEffect(() => {
    const { limiteColaboradores, limiteCandidatos } = limiteCadastro
    setNovoLimiteColaboradores(limiteColaboradores)
    setNovoLimiteCandidatos(limiteCandidatos)
  }, [limiteCadastro])

  const handleNovoLimiteColaboradores = (value) =>
    setNovoLimiteColaboradores(parseInt(value))

  const handleNovoLimiteCandidatos = (value) =>
    setNovoLimiteCandidatos(parseInt(value))

  const handleSolicitarNovoLimite = () => {
    dispatch(blockSolicitacaoLimite())
    dispatch(
      solicitarNovoLimite({ novoLimiteColaboradores, novoLimiteCandidatos })
    )
  }

  return (
    <>
      <Helmet>
        <title>TeamHub | Configurações</title>
      </Helmet>
      <Col span={24} style={noPadding}>
        <Tabs
          defaultActiveKey="1"
          tabBarStyle={{
            marginBottom: '8px',
            paddingRight: '8px',
            paddingLeft: '8px',
          }}
          onTabClick={(tab) => {
            tab === '2' && history.push(`/${getContaURL()}/config/pesquisas`)
            tab === '3' && history.push(`/${getContaURL()}/config/usuarios`)
            tab === '4' &&
              history.push(`/${getContaURL()}/config/processos-seletivos`)
          }}
        >
          <TabPane tab={<span>Limites de Cadastro</span>} key="1">
            <LimiteCadastro
              {...{
                ...limiteCadastro,
                novoLimiteColaboradores,
                novoLimiteCandidatos,
                handleNovoLimiteCandidatos,
                handleNovoLimiteColaboradores,
                handleSolicitarNovoLimite,
              }}
            />
          </TabPane>
          <TabPane tab={<span>Pesquisas</span>} key="2" />
          <TabPane tab={<span>Usuários</span>} key="3" />
          <TabPane tab={<span>Processo Seletivo</span>} key="4" />
        </Tabs>
      </Col>
    </>
  )
}

export default Configuracoes
