import { Button, Select } from 'antd'
import { useState, useEffect } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import Charts from '../Components/Charts'
import ChartsTwo from '../Components/ChartsTwo'
import TimesTable from './Tables/TimesTable'
import Pdf from '../Pdf/indexPessoa'
import TableTwo from './Tables/percentageTable'
import MenuBar from '../Components/Menu'
import AutoCompleteRemove from '../Components/AutoCompleteRemove'
import AutoCompleteAdd from '../Components/AutoCompleteAdd'
import ReportLogo from '../../../assets/business-report.png'
import SpinLoading from '../../../utils/SpinLoading'
import NewTable from './Tables/FitsTable'

import {
  getCulturas,
  getProcessosSeletivos,
  getTimeFromCultura,
  getRecrutamentoInstalado,
  getCulturasFromEmpresas,
} from '../Api/api'

const App = () => {
  const [isDownloading, setDownloading] = useState(false)
  const [typeDownload, setTypeDownload] = useState(null)
  const [advancedFilter, setAdvancedFilter] = useState(false)
  const [isSearched, setSearched] = useState(false)
  const [relatorios, setRelatorios] = useState(null)
  const [culturas, setCulturas] = useState([])
  const [selectedCultura, setSelectedCultura] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [ps, setPS] = useState(null)
  const [AllTimes, setAllTimes] = useState(null)
  const [novoGrafico, setNovoGrafico] = useState(null)
  const [graficoOne, setGraficoOne] = useState(null)
  const [instalado, setInstalado] = useState(null)
  const [graficoFour, setGraficoFour] = useState(null)
  const [selectedCulturaData, setSelectedCulturaData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [graficoFive, setGraficoFive] = useState(null)
  const [page, setPage] = useState(1)

  const { Option } = Select

  function handleBack() {
    if (page > 1) {
      const newPage = page - 1
      setPage(newPage)
      handleSearch(newPage)
    } else {
      toast.error('Você já está na primeira página!')
    }
  }

  function handleNextPage() {
    const newPage = page + 1
    setPage(newPage)
    handleSearch(newPage)
  }

  function handleAdvancedFilter() {
    setAdvancedFilter(!advancedFilter)
  }

  function closeDownload() {
    setDownloading(false)
  }

  function handlePng() {
    setTypeDownload(2)
    setDownloading(true)
  }

  function handlePdf() {
    setTypeDownload(1)
    setDownloading(true)
  }

  function updateCultura(id) {
    culturas.forEach((cultura) => {
      if (cultura.cultura_id == id) {
        setSelectedCulturaData({ ...cultura })
      }
    })
  }

  function generateGrafico4(arquetipo) {
    const newElement = {
      name: arquetipo.arquetipo,
      'Abaixo Máximo': arquetipo.abaixo[1],
      'Abaixo Mínimo': arquetipo.abaixo[0],
      'Acima Máximo': arquetipo.acima[1],
      'Acima Mínimo': arquetipo.acima[0],
      'Dentro Mínimo': arquetipo.dentro[0],
      'Dentro Máximo': arquetipo.dentro[1],
      Desejado: arquetipo.valor,
      'Muito Abaixo': arquetipo.muito_abaixo,
      'Muito Acima': arquetipo.muito_acima,
    }
    return newElement
  }

  async function handleSearch(currentPage) {
    if (currentPage === null) setPage(null)
    setGraficoFive(null)
    setLoading(true)
    const RESPONSE = await getRecrutamentoInstalado(
      selectedCultura,
      selectedTime,
      currentPage
    )
    if (RESPONSE.grafico_5) setGraficoFive(RESPONSE.grafico_5)
    if (RESPONSE.instalado) {
      RESPONSE.grafico_1 = {
        instalado: RESPONSE.instalado,
        desejado: RESPONSE.desejado,
      }
      setInstalado(RESPONSE)
    } else {
      toast.error('Não há dados para o filtro selecionado')
      setLoading(false)
      return
    }
    if (
      !RESPONSE.grafico_2 ||
      Object.entries(RESPONSE.grafico_2).length === 0
    ) {
      toast.error('Não é possível gerar relatório para essa cultura')
    } else {
      const newArray = []
      Object.entries(RESPONSE.grafico_2).forEach((elemento) => {
        elemento[1].name = elemento[0]
        newArray.push(elemento[1])
      })
      newArray.forEach((element) => {
        const desejado = element.Desejado.replace('%', '')
        const maximo = element['Dentro Máximo'].replace('%', '')
        const minimo = element['Dentro Mínimo'].replace('%', '')
        const acimaMax = element['Acima Máximo'].replace('%', '')
        const acimaMin = element['Acima Mínimo'].replace('%', '')
        const abaixoMax = element['Abaixo Máximo'].replace('%', '')
        const abaixoMin = element['Abaixo Mínimo'].replace('%', '')

        element.Acima = Number(acimaMax) + Number(acimaMin)
        element.Abaixo = Number(abaixoMax) + Number(abaixoMin)
        element.Desejado = Number(desejado) + Number(maximo) + Number(minimo)

        element.Acima = `${element.Acima.toFixed(2)}%`
        element.Abaixo = `${element.Abaixo.toFixed(2)}%`
        element.Desejado = `${element.Desejado.toFixed(2)}%`
      })
      setNovoGrafico(newArray)
      const graficoNovo = []
      RESPONSE.grafico_1.desejado.forEach((element) => {
        graficoNovo.push(generateGrafico4(element))
      })
      setGraficoFour(graficoNovo)
      const arrayOne = []

      Object.entries(RESPONSE.grafico_2).forEach((valor) => {
        Object.entries(valor[1]).forEach((categorias) => {
          const newObject = {
            arquetipo: valor[0],
            categoria: categorias[0],
            valor: parseFloat(categorias[1]),
          }
          arrayOne.push(newObject)
        })
      })
      setGraficoOne(arrayOne)
      setRelatorios(RESPONSE.grafico_3)
      setSearched(true)
    }
    setLoading(false)
  }

  function generateArqPercentage(desejado, pessoal) {
    if (desejado > pessoal) return ((pessoal / desejado) * 100).toFixed(1)
    return ((desejado / pessoal) * 100).toFixed(1)
  }

  // Essa função recebe todas as porcentagem individuais e retorna a média
  function generateArqAverage(lista) {
    let sum = 0
    lista.forEach((element) => {
      sum += parseFloat(element)
    })
    return (sum / 8).toFixed(1)
  }

  // Essa função recebe o valor desejada da empresa e o arquétipo pessoal do funcionário e retorna a média individual
  function handleArqPercentage(desejado, pessoal) {
    const list = []
    let i = 0
    Object.entries(pessoal).forEach((pessoa) => {
      if (i < 8) {
        const arquetipo = pessoa[0]
        list.push(generateArqPercentage(desejado[arquetipo][0], pessoa[1][0]))
        i++
      }
    })
    const result = generateArqAverage(list)
    return result
  }

  // Essa função é responsável por gerar o fitArquétipo dentro do grafico 5
  function generateFitArquetipo(dados) {
    if (dados == null) return
    const desejado = dados[0]
    const lista = []
    for (let i = 1; i < dados.length; i++) {
      const pessoal = dados[i]
      const name = pessoal.name
      const fitArquetipos = handleArqPercentage(desejado, pessoal)
      const newElement = {
        name,
        fitArquetipos,
      }
      lista.push(newElement)
    }
    const fits = graficoFive
    const another = lista
    fits.forEach((fit) => {
      fit.fitArquetipos = `${
        another.find((element) => fit.name === element.name).fitArquetipos
      }%`
      if (fit.fitValores) {
        fit.fitValores = fit.fitValores.replace('%', '')
        if (fit?.fitValores > 100) fit.fitValores = 100
        fit.fitValores = `${fit.fitValores}%`
      }
    })

    setGraficoFive(fits)
    setLoading(false)
    setSearched(true)
  }

  useEffect(() => {
    generateFitArquetipo(relatorios)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatorios])

  async function getCulturasFromApi() {
    const RESPONSE = await getCulturasFromEmpresas()
    setCulturas(filterCulturasWithFitId(RESPONSE.culture))
  }

  useEffect(() => {
    getCulturasFromApi()
  }, [])

  useEffect(() => {
    getTimeFromCulturaFromAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCultura])

  async function getTimeFromCulturaFromAPI() {
    if (selectedCultura != null) {
      const RESPONSE = await getProcessosSeletivos()
      RESPONSE.unshift({
        grupo_id: null,
        id: 0,
        nome: 'Candidatos sem processo',
      })
      setPS(RESPONSE)
      const AllTimes = await getTimeFromCultura(
        selectedCulturaData.cultura_id,
        selectedCulturaData.fit_id
      )
      if (AllTimes.success) {
        setAllTimes(AllTimes.grupos)
      }
    }
  }

  function filterCulturasWithFitId(array) {
    const newArray = array.filter((cultura) => cultura?.fit_id != undefined)
    return newArray
  }

  return (
    <div>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: 5,
          padding: 20,
          border: '0.5px solid black',
        }}
      >
        <MenuBar selectedKeys={['3']} />

        <div style={{ margin: 25 }}>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                minWidth: 220,
              }}
            >
              <span>Cultura Desejada</span>
              <Select
                style={{ maxWidth: 220 }}
                onChange={(value) => {
                  setSelectedCultura(value)
                  updateCultura(value)
                }}
              >
                {culturas?.map((cultura) => (
                  <Option key={cultura.cultura_id} value={cultura.cultura_id}>
                    {cultura.conta_culturas[0].descricao}
                  </Option>
                ))}
              </Select>
            </div>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                minWidth: 200,
                marginLeft: 30,
              }}
            >
              <span>Processo Seletivo</span>
              <Select
                onChange={(value) => setSelectedTime(ps[value].id)}
                style={{ maxWidth: 220 }}
              >
                {ps?.map((processoSeletivo, index) => (
                  <Option key={index} value={index}>
                    {processoSeletivo.nome}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <div>
              <Button
                style={{ display: 'none' }}
                onClick={handleAdvancedFilter}
                type="primary"
              >
                Filtros Avançados
              </Button>
              {advancedFilter && (
                <div
                  style={{
                    border: 'gray 1px solid',
                    padding: 20,
                    marginTop: 10,
                    borderRadius: 5,
                    backgroundColor: '#f2f2f2',
                  }}
                >
                  {' '}
                  <div style={{ flex: 'row', width: 250 }}>
                    <span>Remover do Comparativo:</span>
                    <AutoCompleteRemove key="1" />
                  </div>
                  <div style={{ flex: 'row', marginTop: 20, width: 250 }}>
                    <span>Adicionar ao Comparativo:</span>
                    <AutoCompleteAdd key="2" />
                  </div>{' '}
                </div>
              )}
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <Button style={{ marginRight: 5 }}>Limpar Filtros</Button>
                  {loading ? (
                    <Button onClick={() => handleSearch(page)} type="primary">
                      <SpinLoading />
                    </Button>
                  ) : (
                    <>
                      <Button
                        style={{ marginRight: 5 }}
                        onClick={() => handleSearch(page)}
                        type="primary"
                      >
                        Gerar Relátorio Recente
                      </Button>
                      <Button onClick={() => handleSearch(null)} type="primary">
                        Gerar Relátorio Completo
                      </Button>
                    </>
                  )}
                </div>
                <span>
                  *Relatório completo pode durar minutos para ser gerado
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: 5,
          padding: 20,
          border: '0.5px solid black',
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {isSearched ? (
            <div
              style={{
                borderWidth: 1,
                borderColor: 'black',
                borderStyle: 'solid',
                borderRadius: 5,
                padding: 20,
                backgroundColor: '#F1F1F1',
                flex: 1,
                maxWidth: '20%',
              }}
            >
              <h4>
                Cultura desejada: <span>{selectedCulturaData.descricao}</span>
              </h4>
              <h4>
                Período{' '}
                <span>
                  {moment(selectedCulturaData.data_inicio, 'YYYY-MM-DD').format(
                    'DD/MM/YYYY'
                  )}{' '}
                  à{' '}
                  {moment(selectedCulturaData.data_final, 'YYYY-MM-DD').format(
                    'DD/MM/YYYY'
                  )}
                </span>
              </h4>
            </div>
          ) : null}
          {isSearched && page ? (
            <div>
              {loading ? (
                <Button type="primary">
                  <SpinLoading />
                </Button>
              ) : (
                <>
                  <Button
                    style={{ marginRight: 5 }}
                    type="primary"
                    onClick={handleBack}
                  >
                    Ver Anterior
                  </Button>
                  <Button
                    style={{ marginRight: 5 }}
                    type="primary"
                    onClick={handleNextPage}
                  >
                    Ver Próximo
                  </Button>
                </>
              )}
              <Button onClick={handlePdf}>Exportar como PDF</Button>
              <Button onClick={handlePng}>Exportar como PNG</Button>
            </div>
          ) : null}
        </div>
        {isDownloading ? (
          <Download
            instalado={instalado}
            grafico_2={novoGrafico}
            grafico_1={graficoOne}
            downloads={typeDownload}
            close={closeDownload}
            grafico_3={relatorios}
            graficoFour={graficoFour}
          />
        ) : isSearched ? (
          <Relatorios
            grafico_2={novoGrafico}
            grafico_1={graficoOne}
            instalado={instalado}
            grafico_3={relatorios}
            graficoFour={graficoFour}
            graficoFive={graficoFive}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <h1>O relatório será exibido aqui quando for gerado.</h1>
            <img style={{ maxWidth: 200 }} src={ReportLogo} />
          </div>
        )}
      </div>
    </div>
  )
}

const Relatorios = (props) => (
  <div>
    {props.instalado.desejado.length != 0 && <Charts data={props.instalado} />}
    {props.grafico_1 && <ChartsTwo data={props.grafico_1} />}
    <TableTwo dataSource={props.grafico_2} />
    {props.graficoFive ? <NewTable data={props.graficoFive} /> : null}
    <TimesTable dataSource={props.grafico_3} />
  </div>
)

const Download = (props) => (
  <Pdf
    downloads={props.downloads}
    instalado={props.instalado}
    grafico_2={props.grafico_2}
    grafico_1={props.grafico_1}
    close={props.close}
    grafico_3={props.grafico_3}
    graficoFour={props.graficoFour}
  />
)
export default App
