// types
export const PS_MOTIVO_LIST = 'PS_MOTIVO_LIST'
export const PS_MOTIVO_STORE = 'PS_MOTIVO_STORE'
export const PS_MOTIVO_UPDATE = 'PS_MOTIVO_UPDATE'
export const PS_MOTIVO_DELETE = 'PS_MOTIVO_DELETE'
export const PS_MOTIVO_LOAD = 'PS_MOTIVO_LOAD'

// urls
export const URL_PS_MOTIVO = `${process.env.REACT_APP_API_URL}/psMotivo`
export const URL_PS_MOTIVO_ID = (id) =>
  `${process.env.REACT_APP_API_URL}/psMotivo/${id}`
