import React, { useState } from 'react'

import { Scope } from '@unform/core'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import CheckboxField from '../form/checkbox-field'
import InputField from '../form/input-field'
import { AlternativaItem } from '../Questoes/styles'

const QUANTIDADE_ALTERNATIVAS = 2

const Alternativas = ({ questaoIndex, formRef }) => {
  const [quantidade, setQuantidade] = useState(QUANTIDADE_ALTERNATIVAS)

  return (
    <>
      <Col>
        {Array.from(new Array(quantidade)).map((_, alternativaIndex) => (
          <Scope
            path={`alternativas[${alternativaIndex}]`}
            key={`alternativas-item-${alternativaIndex}`}
          >
            <AlternativaItem>
              <InputField
                maxLength={149}
                name="alternativa"
                placeholder="Alternativa..."
              />

              <CheckboxField
                name="resposta_certa"
                label="Resposta certa"
                // onChange={handleChanged(target)}
                onChange={({ target }) => {
                  if (target.checked) {
                    const dados = formRef.current?.getData()

                    dados.questoes.forEach((questao) => {
                      questao.alternativas?.forEach((_, index) => {
                        formRef.current?.setFieldValue(
                          `questoes[${questaoIndex}].alternativas[${index}].resposta_certa`,
                          false
                        )
                      })
                    })

                    formRef.current?.setFieldValue(
                      `questoes[${questaoIndex}].alternativas[${alternativaIndex}].resposta_certa`,
                      true
                    )
                  }
                }}
              />
            </AlternativaItem>
          </Scope>
        ))}
      </Col>

      <Row>
        {quantidade < 5 && (
          <Button
            type="secondary"
            icon={<PlusOutlined />}
            onClick={() => setQuantidade((quantidade) => quantidade + 1)}
            size="middle"
          >
            Adicionar alternativa
          </Button>
        )}

        {quantidade > 2 && (
          <Button
            type="secondary"
            icon={<DeleteOutlined />}
            style={{ marginLeft: 8 }}
            onClick={() => setQuantidade((quantidade) => quantidade - 1)}
            size="middle"
          >
            Remover alternativa
          </Button>
        )}
      </Row>
    </>
  )
}

export default Alternativas
