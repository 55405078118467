// Redux types
import * as constants from '../constants/constantsLogin'

export const postLogin = ({ email, senha, contaURL }) => {
  return {
    type: constants.LOGIN_POST,
    api: {
      method: 'post',
      url: constants.URL_LOGIN,
      data: {
        email,
        senha,
        conta: contaURL,
      },
    },
  }
}

export const saveOnboarding = (onboarding) => {
  return {
    type: constants.SALVAR_ONBOARDING,
    onboarding,
  }
}

export const postConfirmarConta = (hash) => {
  return {
    type: constants.CONFIRMAR_CONTA_POST,
    api: {
      method: 'post',
      url: `${constants.URL_CONFIRMAR_CONTA}/${hash}`,
    },
  }
}

export const getUserInfo = () => {
  return {
    type: constants.INFO_GET,
    api: {
      method: 'get',
      url: constants.URL_INFO_GET,
    },
  }
}

export const postLogAcesso = () => {
  return {
    type: constants.POST_LOG_ACESSO,
    api: {
      method: 'post',
      url: constants.URL_POST_LOG_ACESSO,
    },
  }
}
