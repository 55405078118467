// Redux types
import * as constants from '../constants/constantsCargos'

export const getCargos = () => {
  return {
    type: constants.CARGOS_GET,
    api: {
      method: 'get',
      url: constants.URL_CARGOS_GET,
    },
  }
}

export const postCargo = ({
  nome,
  cbo,
  descricao,
  superior,
  lotacao,
  piso,
  proposito,
  comofaz,
  oquefaz,
  paraquefaz,
  frequencia_id,
  nomeFerramenta,
  nomeRequisito,
  nomeTecnica,
  tipo_id,
  nivel_id,
  descricaoTecnica,
}) => {
  return {
    type: constants.CARGO_POST,
    api: {
      method: 'post',
      url: constants.URL_CARGO_POST,
      data: {
        cargo_nome: nome,
        cargo_cbo: cbo,
        cargo_descricao: descricao,
        cargo_superior: superior,
        cargo_piso: piso,
        proposito: proposito,
        lotacao: lotacao,
        comofaz: comofaz,
        oquefaz: oquefaz,
        paraquefaz: paraquefaz,
        frequencia_id: frequencia_id,
        nome: nomeFerramenta,
        nome: nomeRequisito,
        nomeTecnica,
        tipo_id,
        nivel_id,
        descricaoTecnica,
      },
    },
  }
}

export const deleteCargo = (id) => {
  return {
    type: constants.CARGO_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_CARGO_DELETE + id,
    },
  }
}

export const updateCargo = ({
  nome,
  cbo,
  proposito,
  lotacao,
  descricao,
  superior,
  piso,
  id,
}) => {
  return {
    type: constants.CARGOS_PUT,
    api: {
      method: 'put',
      url: constants.URL_CARGOS_PUT + id,
      data: {
        cargo_nome: nome,
        cargo_cbo: cbo,
        cargo_descricao: descricao,
        cargo_superior: superior,
        cargo_piso: piso,
        proposito: proposito,
        lotacao: lotacao,
      },
    },
  }
}

export const sendVetorComportamental = (id, { enviarEmails }) => {
  return {
    type: constants.CARGOS_SEND_AVALIACOES,
    api: {
      method: 'post',
      url: constants.URL_CARGOS_SEND_AVALIACOES + id,
      data: { enviarEmails },
    },
  }
}

export const sendPercepcao = (id, { enviarEmails }) => {
  return {
    type: constants.SEND_PERCEPCAO,
    api: {
      method: 'post',
      url: constants.URL_SEND_PERCEPCAO + '/' + id + '/2',
      data: { enviarEmails },
    },
  }
}

export const sendValoresOrganizacionais = (id, { enviarEmails }) => {
  return {
    type: constants.CARGOS_SEND_VALORES,
    api: {
      method: 'post',
      url: constants.URL_CARGOS_SEND_VALORES(id),
      data: { enviarEmails },
    },
  }
}

export const getFrequencia = () => {
  return {
    type: constants.FREQUENCIA_CARGO,
    api: {
      method: 'get',
      url: constants.URL_FREQUENCIA_CARGO,
    },
  }
}

export const getFerramenta = () => {
  return {
    type: constants.FERRAMENTA_CARGO,
    api: {
      method: 'get',
      url: constants.URL_FERRAMENTA_CARGO,
    },
  }
}

export const getNivelFormacao = () => {
  return {
    type: constants.NIVEL_FORMACAO,
    api: {
      method: 'get',
      url: constants.URL_NIVEL_FORMACAO_CARGO,
    },
  }
}

export const storeAtividade = ({ id, data }) => {
  return {
    type: constants.ATIVIDADE_POST,
    api: {
      method: 'post',
      url: constants.URL_ATIVIDADE_POST(id),
      data: {
        oquefaz: data.oquefaz,
        paraquefaz: data.paraquefaz,
        comofaz: data.comofaz,
        frequencia_id: data.frequencia_id,
      },
    },
  }
}

export const storeFerramenta = ({ id, data, nome, tipo_id }) => {
  return {
    type: constants.FERRAMENTA_POST,
    api: {
      method: 'post',
      url: constants.URL_FERRAMENTA_POST(id),
      data: { nome: data.nomeFerramenta, tipo_id: data.tipo_id },
    },
  }
}

export const storeRequisito = ({ id, data, nome, nivel_id }) => {
  return {
    type: constants.REQUISITO_POST,
    api: {
      method: 'post',
      url: constants.URL_REQUISITO_POST(id),
      data: { nome: data.nomeRequisito, nivel_id: data.nivel_id },
    },
  }
}

export const storeTecnica = ({ id, data, nome, descricao }) => {
  return {
    type: constants.TECNICA_POST,
    api: {
      method: 'post',
      url: constants.URL_TECNICA_POST(id),
      data: { nome: data.nomeTecnica, descricao: data.descricaoTecnica },
    },
  }
}
