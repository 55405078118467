// REDUX TYPES
export const CONFIG_USUARIOS_LIST_USUARIOS = 'CONFIG_USUARIOS_LIST_USUARIOS'
export const CONFIG_USUARIOS_STORE_USUARIO = 'CONFIG_USUARIOS_STORE_USUARIO'
export const CONFIG_USUARIOS_UPDATE_USUARIO = 'CONFIG_USUARIOS_UPDATE_USUARIO'
export const CONFIG_USUARIOS_DELETE_USUARIO = 'CONFIG_USUARIOS_DELETE_USUARIO'
export const CONFIG_USUARIOS_DISABLE_USUARIO = 'CONFIG_USUARIOS_DISABLE_USUARIO'
export const CONFIG_USUARIOS_ENABLE_USUARIO = 'CONFIG_USUARIOS_ENABLE_USUARIO'
export const CONFIG_USUARIOS_LIST_TIPOS = 'CONFIG_USUARIOS_LIST_TIPOS'
export const CONFIG_USUARIOS_LIST_COLABORADORES =
  'CONFIG_USUARIOS_LIST_COLABORADORES'
export const CONFIG_USUARIOS_RESET_PASSWORD = 'CONFIG_USUARIOS_RESET_PASSWORD'
export const LIST_USUARIOS_VINCULADOS = 'LIST_USUARIOS_VINCULADOS'
export const USUARIOS_LIST_TIMES = 'USUARIOS_LIST_TIMES'

// URLS
const URL_BASE = process.env.REACT_APP_API_URL
const URL_USUARIO = `${URL_BASE}/usuario`
const URL_COLABORADORES = `${URL_BASE}/pessoas`
export const URL_CONFIG_USUARIOS_LIST_USUARIOS = URL_USUARIO
export const URL_CONFIG_USUARIOS_STORE_USUARIO = URL_USUARIO
export const URL_CONFIG_USUARIOS_UPDATE_USUARIO = (user_id) =>
  `${URL_USUARIO}/${user_id}`
export const URL_CONFIG_USUARIOS_DELETE_USUARIO = (user_id) =>
  `${URL_USUARIO}/${user_id}/delete`
export const URL_CONFIG_USUARIOS_DISABLE_USUARIO = (user_id) =>
  `${URL_USUARIO}/${user_id}/disable`
export const URL_CONFIG_USUARIOS_ENABLE_USUARIO = (user_id) =>
  `${URL_USUARIO}/${user_id}/enable`
export const URL_CONFIG_USUARIOS_CHANGE_PASSWORD = `${URL_USUARIO}/password`
export const URL_CONFIG_USUARIOS_LIST_TIPOS = `${URL_USUARIO}/tipos`
export const URL_CONFIG_USUARIOS_LIST_COLABORADORES = `${URL_COLABORADORES}/list/1`
export const URL_CONFIG_USUARIOS_RESET_PASSWORD = () =>
  `${URL_USUARIO}/recovery/`
export const URL_LIST_USUARIOS_VINCULADOS = `${URL_BASE}/usuario?pessoaRequired=true`
export const URL_USUARIOS_LIST_TIMES = `${URL_BASE}/dashboard/grupos`
