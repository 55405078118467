// types
export const REQUISITO_LIST = 'REQUISITO_LIST'
export const REQUISITO_POST = 'REQUISITO_POST'
export const REQUISITO_UPDATE = 'REQUISITO_UPDATE'
export const REQUISITO_DELETE = 'REQUISITO_DELETE'
export const REQUISITO_LOAD = 'REQUISITO_LOAD'

export const FREQUENCIA_CARGO = 'FREQUENCIA_CARGO'

// urls
export const URL_REQUISITO = `${process.env.REACT_APP_API_URL}/cargo`
export const URL_REQUISITO_ID = (id, formacoes_id) =>
  `${URL_REQUISITO}/${id}/formacoes/${formacoes_id}`
export const URL_REQUISITO_POST = (id) => `${URL_REQUISITO}/${id}/formacoes`
export const URL_REQUISITO_LIST = (id) => `${URL_REQUISITO}/${id}/formacoes`

export const URL_FREQUENCIA_CARGO = `/cargo/formacoes/niveis`
