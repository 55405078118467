import React, { useState, useCallback, useMemo } from 'react'

import { Input, InputNumber, Col, Row, Divider, Checkbox, Select } from 'antd'

import { Form } from './styles'

import { sorterString } from '../../../../../../utils/utils'

const { Option } = Select

function RequisicaoProcessoForm({
  onFinish,
  initialValues,
  cargos = [],
  loadingCargos,
  times = [],
  loadingTimes,
  ufs = [],
  loadingUfs,
  cidades = [],
  loadingCidades,
  loadCidades,
  readOnly = false,
  onValuesChange,
  motivos = [],
  contratos = [],
}) {
  const [form] = Form.useForm()

  const [homeoffice, setHomeoffice] = useState(
    initialValues ? initialValues.homeoffice : false
  )

  const [sigiloso, setSigiloso] = useState(
    initialValues ? initialValues.sigiloso : false
  )

  const [publicada, setPublicada] = useState(
    initialValues ? initialValues.publicada : false
  )

  const [pcd, setPCD] = useState(initialValues ? initialValues.pcd : false)

  const handleToggleSigiloso = useCallback(() => {
    if (sigiloso) {
      setSigiloso(false)
    } else {
      setSigiloso(true)
      setPublicada(false)
      form.setFieldValue({
        publicada: false,
      })
    }
  }, [form, sigiloso])

  const cargosSorted = useMemo(() => {
    return cargos.sort((a, b) => sorterString(a, b, 'cargo_nome'))
  }, [cargos])

  const timesSorted = useMemo(() => {
    return times.sort((a, b) => sorterString(a, b, 'grupo_nome'))
  }, [times])

  const contratosSorted = useMemo(() => {
    return contratos.sort((a, b) => sorterString(a, b, 'label'))
  }, [contratos])

  const motivosSorted = useMemo(() => {
    return motivos.sort((a, b) => sorterString(a, b, 'label'))
  }, [motivos])

  const ufsSorted = useMemo(() => {
    return ufs.sort((a, b) => sorterString(a, b, 'UF'))
  }, [ufs])

  const cidadesSorted = useMemo(() => {
    return cidades.sort((a, b) => sorterString(a, b, 'CIDADE'))
  }, [cidades])

  return (
    <Form
      layout="vertical"
      initialValues={
        initialValues
          ? {
              ...initialValues,

              tipo: null,
              prioridade: null,
              sla: null,
              recrutador: null,
              filial: null,
              salario_faixa_min: null,
              salario_faixa_max: null,
              jornada: null,
              publicada: null,
              video: null,
              concluirem: null,
              fim_publicacao: null,
              descricao: null,
              numero: initialValues.numero
                ? initialValues.numero.padStart(5, '0')
                : '00000',
            }
          : {}
      }
      id="req-processo-seletivo"
      onFinish={onFinish}
      form={form}
      onValuesChange={onValuesChange}
    >
      <Col span={24}>
        <Row gutter={20}>
          <Col sm={24} lg={12}>
            <Form.Item
              label="Selecione o cargo para esta vaga"
              name="cargo_id"
              rules={[
                {
                  required: true,
                  message: 'Por favor, selecione o cargo desta vaga.',
                },
              ]}
            >
              {cargos && (
                <Select
                  readOnly={readOnly}
                  placeholder="Selecionar cargo"
                  style={{ width: '100%' }}
                  showSeach
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase() >= 0)
                  }
                  loading={loadingCargos}
                >
                  {cargosSorted.map(({ cargo_nome, cargo_id }) => (
                    <Option value={cargo_id} key={cargo_id}>
                      {cargo_nome}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item
              label="Quantidade de Vagas"
              name="qtde"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe a quantidade de vagas.',
                },
              ]}
            >
              <InputNumber
                readOnly={readOnly}
                min={0}
                max={10000}
                withoutMargin
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={20}>
          <Col xs={6} sm={8}>
            <Form.Item
              style={{ marginBottom: 0 }}
              name="sigiloso"
              valuePropName="checked"
            >
              <Checkbox
                disabled={readOnly}
                onChange={() => handleToggleSigiloso()}
              >
                Sigiloso
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={6} sm={8}>
            <Form.Item name="pcd" valuePropName="checked">
              <Checkbox onChange={() => setPCD((state) => !state)}>
                Vaga para PCD
              </Checkbox>
            </Form.Item>
          </Col>
          {!!pcd && (
            <Col xs={8} sm={8}>
              <Form.Item label="Tipo de PCD (opcional)" name="pcd_tipo">
                <Select
                  placeholder="Selecione o tipo"
                  showSearch
                  withoutMargin
                  options={[
                    { label: 'Todos', value: null },
                    { label: 'Física', value: 'Física' },
                    { label: 'Auditiva', value: 'Auditiva' },
                    { label: 'Visual', value: 'Visual' },
                    { label: 'Mental', value: 'Mental' },
                    { label: 'Múltipla', value: 'Múltipla' },
                  ]}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Divider />
        <Row gutter={20}>
          <Col sm={24} md={12} lg={8}>
            <Form.Item
              label="Time"
              name="grupo_id"
              rules={[
                {
                  required: true,
                  message: 'Por favor, selecione um time para essa vaga.',
                },
              ]}
            >
              {times && (
                <Select
                  readOnly={readOnly || initialValues}
                  placeholder="Selecionar Time"
                  style={{ width: '100%' }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={loadingTimes}
                >
                  {timesSorted.map(({ grupo_nome, grupo_id }) => (
                    <Option value={grupo_id} key={grupo_id}>
                      {grupo_nome}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={24} md={12} lg={8}>
            <Form.Item label="Tipo de contrato" name="contrato_id">
              <Select
                placeholder="Selecione o tipo de contrato"
                showSearch
                options={contratosSorted}
                filterOption={(input, option) =>
                  options.props.label
                    .toLowerCase()
                    .indeOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </Col>

          <Col sm={24} md={12} lg={8}>
            <Form.Item label="Motivo da Requisição" name="motivo_id">
              <Select
                placeholder="Selecione o motivo"
                showSearch
                options={motivosSorted}
                filterOption={(input, option) =>
                  option.props.label
                    .toLowerCase()
                    .indexOf(input.toLowerCase() >= 0)
                }
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item name="homeoffice" valuePropName="checked">
              <Checkbox onChange={() => setHomeoffice((state) => !state)}>
                Trabalho Remoto (Home Office)
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col sm={24} md={12} lg={8}>
            <Form.Item label="UF" name="ufid">
              {ufs && (
                <Select
                  readOnly={readOnly}
                  placeholder="Selecione"
                  style={{ width: '100%' }}
                  showSearch
                  disabled={homeoffice}
                  filterOption={(input, option) =>
                    options.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={loadingUfs}
                  onChange={(value) => loadCidades(value)}
                >
                  {ufsSorted.map(({ UFID, UF }) => (
                    <Option value={UFID} key={UFID}>
                      {UF}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={24} md={12} lg={8}>
            <Form.Item label="Cidade" name="cidadeid">
              {cidades && (
                <Select
                  readOnly={readOnly}
                  placeholder="Selecione"
                  style={{ width: '100%' }}
                  disabled={homeoffice}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={loadingCidades}
                >
                  {cidadesSorted.map(({ CIDADEID, CIDADE }) => (
                    <Option value={CIDADEID} key={CIDADEID}>
                      {CIDADE}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={24} md={12} lg={8}>
            <Form.Item label="Salário" name="salario" key="salario_k">
              <InputNumber
                readOnly={readOnly}
                min={0}
                max={10000000}
                decimalSeparator=","
                parser={(value) => value.replace(/R\$\s?|(,*)/g, '')}
                formatter={(value) => `R$ ${value}`}
                withoutMargin
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Observação" name="observacao">
              <Input.TextArea readOnly={readOnly} style={{ height: '100px' }} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Form>
  )
}

export default RequisicaoProcessoForm
