// types
export const FERRAMENTA_LIST = 'FERRAMENTA_LIST'
export const FERRAMENTA_POST = 'FERRAMENTA_POST'
export const FERRAMENTA_UPDATE = 'FERRAMENTA_UPDATE'
export const FERRAMENTA_DELETE = 'FERRAMENTA_DELETE'
export const FERRAMENTA_LOAD = 'FERRAMENTA_LOAD'

export const FREQUENCIA_CARGO = 'FREQUENCIA_CARGO'

// urls
export const URL_FERRAMENTA = `${process.env.REACT_APP_API_URL}/cargo`
export const URL_FERRAMENTA_ID = (id, ferramenta_id) =>
  `${URL_FERRAMENTA}/${id}/ferramentas/${ferramenta_id}`
export const URL_FERRAMENTA_POST = (id) => `${URL_FERRAMENTA}/${id}/ferramentas`
export const URL_FERRAMENTA_LIST = (id) => `${URL_FERRAMENTA}/${id}/ferramentas`

export const URL_FREQUENCIA_CARGO = `/cargo/ferramentas/tipos`
