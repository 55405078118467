import React, { useState, useEffect } from 'react'

import { Col, Divider } from 'antd'

import { FormContainer, FormSubheader, FormColumn } from '../Questoes/styles'

import InputNumberField from '../form/input-number'
import CheckboxField from '../form/checkbox-field'

function Metricas({ formRef }) {
  const [tempoLimite, setTempoLimite] = useState(0)

  const [allowExibirResultado, setAllowExibirResultado] = useState(null)

  const [notaCorteExiste, setNotaCorteExiste] = useState(false)

  useEffect(() => {
    if (formRef && formRef.getFieldValue('nota_corte')) {
      setNotaCorteExiste(true)
    }
  }, [formRef])

  const handleChange = (value) => {
    setAllowExibirResultado(value)
  }

  const handleChangeTempoLimite = (tempoLimite) => {
    const prevValues = JSON.parse(
      localStorage.getItem('@TeamHubGB:teste_preview')
    )

    const update = { ...prevValues, tempo_limite: tempoLimite }

    localStorage.setItem('@TeamHubGB:teste_preview', JSON.stringify(update))
  }

  return (
    <>
      <FormContainer>
        <FormSubheader>Tempo</FormSubheader>
        <FormColumn direction="vertical">
          <Col>
            <InputNumberField
              name="tempo_limite"
              label="Tempo Limite Total (em minutos)"
              min={1}
              max={300}
              rules={[
                {
                  required: true,
                  message: 'Por favor insira o tempo limite!',
                },
              ]}
              onChange={(value) => handleChangeTempoLimite(value)}
            />
          </Col>
        </FormColumn>
      </FormContainer>
      <Divider />
      <FormContainer>
        <FormSubheader>Avaliação</FormSubheader>
        <FormColumn direction="vertical">
          <InputNumberField
            name="nota_min"
            min={1}
            max={100}
            label="Nota de Corte (Porcentagem)"
            onChange={handleChange}
          />
        </FormColumn>
      </FormContainer>
      <Divider />
      <FormContainer>
        <FormSubheader>Exibir Resultado</FormSubheader>
        <FormColumn direction="vertical">
          <CheckboxField
            name="exibir_resultado"
            disabled={!allowExibirResultado}
            label="Deseja exibir o resultado?"
          />
        </FormColumn>
      </FormContainer>
    </>
  )
}

export default Metricas
