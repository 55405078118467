import React from 'react'
import { injectIntl } from 'react-intl'
import { Steps } from 'antd'
import history from '../../utils/history'

// Components
import Container from '../../components/container/Container'
import Button from '../../components/button/Button'

// CSS
import './Onboarding.css'

// Constants
import { steps } from './constants/constantsOnboarding'

function OnboardingView({ intl, nextStep, prevStep, currentStep }) {
  const { Step } = Steps

  return (
    <Container className="onboarding">
      <Steps current={currentStep}>
        {steps.map((step) => (
          <Step
            key={step.title}
            title={step.title}
            description={step.description}
          />
        ))}
      </Steps>
      <hr />
      {currentStep >= 0 && (
        <>
          <div className="onboarding__content">
            {steps[currentStep].content}
          </div>
          <div className="onboarding__footer">
            <div>
              {currentStep > 0 && (
                <Button color="orange" type="link" onClick={prevStep}>
                  VOLTAR
                </Button>
              )}
            </div>
            <div className="email-gestor__buttons__container">
              <Button type="primary" className="btn_purple" onClick={nextStep}>
                PRÓXIMO PASSO
              </Button>
            </div>
          </div>
        </>
      )}
    </Container>
  )
}

export default injectIntl(OnboardingView)
