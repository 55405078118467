import React from 'react'
import { injectIntl } from 'react-intl'
import {
  ExclamationCircleFilled,
  SolutionOutlined,
  EditOutlined,
  ZoomInOutlined,
  DeleteOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import { Divider, Table, Popconfirm, Tooltip } from 'antd'
import Link from '../../../../components/privateLink/PrivateLink'

// Components
import Button from '../../../../components/button/Button'

// CSS

// Constants
import { modals } from '../../constants/constantsCargos'

// Utils
import { sorterString } from '../../../../utils/utils'

function TableCargos({
  openModal,
  dataSource,
  rowSelection,
  handleDelete,
  getColumnSearchProps,
  openModalNovoCargo,
  openModalEditCargo,
  openModalCloneCargo,
}) {
  // Table columns
  const columns = [
    {
      title: 'CBO',
      dataIndex: 'cbo',
      width: '15%',
      sorter: (a, b) => a.cbo - b.cbo,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('cbo'),
    },
    {
      title: 'Cargo',
      dataIndex: 'nome',
      width: '40%',
      sorter: (a, b) => sorterString(a, b, 'nome'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('nome'),
      render: (text, record) => <Link to={`/cargos/${record.id}`}>{text}</Link>,
    },
    {
      title: 'Piso Salarial',
      dataIndex: 'piso',
      width: '25%',
      sorter: (a, b) => a.piso - b.piso,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('piso'),
    },
    {
      title: 'Ações',
      key: 'acoes',
      className: 'column-acoes',
      width: '20%',
      render: (text, record) =>
        dataSource.length >= 1 ? (
          <span>
            <a
              href="javascript:;"
              aria-label="editar"
              onClick={() => openModalEditCargo(record)}
            >
              <Tooltip title="Editar">
                <EditOutlined />
              </Tooltip>
            </a>
            <Divider type="vertical" />

            <Link to={`/cargos/${record.id}`}>
              <Tooltip title="Detalhes">
                <ZoomInOutlined />
              </Tooltip>
            </Link>
            <Divider type="vertical" />
            <Popconfirm
              title="Tem certeza que deseja excluir esse cargo?"
              onConfirm={() => handleDelete(record.id)}
              okText="Sim"
              cancelText="Não"
              icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
            >
              <a href="javascript:;" aria-label="excluir">
                <Tooltip title="Excluir" placement="top">
                  <DeleteOutlined />
                </Tooltip>
              </a>
            </Popconfirm>
            <Divider type="vertical" />
            <a>
              <Tooltip title="Clonar cargo" placement="top">
                <CopyOutlined onClick={() => openModalCloneCargo(record)} />
              </Tooltip>
            </a>
          </span>
        ) : null,
    },
  ]

  return (
    <div className="cargos__table">
      <Table
        title={() => (
          <div className="cargos__table__buttons">
            <Button
              className="cargos__table__button btn_purple"
              onClick={() => openModal(0)}
            >
              GERAR PESQUISA
            </Button>
            <Button
              type="primary"
              className="cargos__table__button btn_purple"
              onClick={openModalNovoCargo}
              icon={<SolutionOutlined />}
            >
              NOVO
            </Button>
          </div>
        )}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} registros`,
        }}
        dataSource={dataSource}
        columns={columns}
        rowSelection={rowSelection}
        isDeleble
        bordered
        rowKey={(record) => record.id}
        locale={{ emptyText: 'Nenhum registro' }}
      />
    </div>
  )
}

export default injectIntl(TableCargos)
