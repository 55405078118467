import * as constants from '../constants/analiseGeralConstants'

export const getCultura = () => {
  return {
    type: constants.ANALISE_GERAL_GET_CULTURA,
    api: {
      method: 'get',
      url: constants.URL_ANALISE_GERAL_GET_CULTURA,
    },
  }
}

export const getCulturaOrganizacional = () => {
  return {
    type: constants.ANALISE_GERAL_GET_CULTURA_ORGANIZACIONAL,
    api: {
      method: 'get',
      url: constants.URL_ANALISE_GERAL_GET_CULTURA_ORGANIZACIONAL,
    },
  }
}

export const load = () => {
  return {
    type: constants.ANALISE_GERAL_LOADING,
  }
}

export const getNps = () => {
  return {
    type: constants.GET_DASHBOARD_NPS,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_NPS,
    },
  }
}
