// Redux types
import * as constants from '../constants/constantsTimes'

export const getTimes = () => {
  return {
    type: constants.TIMES_GET,
    api: {
      method: 'get',
      url: constants.URL_TIMES_GET,
    },
  }
}

export const deleteTime = (id) => {
  return {
    type: constants.TIMES_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_TIMES_DELETE + '/' + id,
    },
  }
}

export const postTime = ({ nome, pai, descricao, filial }) => {
  return {
    type: constants.TIME_POST,
    api: {
      method: 'post',
      url: constants.URL_TIME_POST,
      data: {
        grupo_nome: nome,
        grupo_pai: pai || 0,
        grupo_descricao: descricao,
        grupo_vetordesejado: 0,
        filial_id: filial,
      },
    },
  }
}

export const sendVetorComportamental = (id, { enviarEmails }) => {
  return {
    type: constants.TIMES_SEND_AVALIACOES,
    api: {
      method: 'post',
      url: constants.URL_TIMES_SEND_AVALIACOES + id,
      data: {
        enviarEmails,
      },
    },
  }
}

export const sendPercepcao = (id, { enviarGruposFilho, enviarEmails }) => {
  return {
    type: constants.SEND_PERCEPCAO,
    api: {
      method: 'post',
      url: constants.URL_SEND_PERCEPCAO + '/' + id + '/2',
      data: { enviarGruposFilho, enviarEmails },
    },
  }
}

export const sendValoresOrganizacionais = (
  id,
  { enviarGruposFilho, enviarEmails }
) => {
  return {
    type: constants.TIMES_SEND_VALORES,
    api: {
      method: 'post',
      url: constants.URL_TIMES_SEND_VALORES(id),
      data: { enviarGruposFilho, enviarEmails },
    },
  }
}

export const updateTime = (grupo_id, data) => {
  return {
    type: constants.TIMES_PUT,
    api: {
      method: 'put',
      url: `${constants.URL_TIMES_PUT}/${grupo_id}`,
      data: {
        ...data,
      },
    },
  }
}

// Filiais
export const getFiliais = () => {
  return {
    type: constants.PS_FILIAL_LIST,
    api: {
      method: 'get',
      url: constants.URL_PS_FILIAL,
    },
  }
}
