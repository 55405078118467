import React, { useMemo } from 'react'
import { YoutubeOutlined } from '@ant-design/icons'
import { Divider, Upload, Tabs, Space, Row, Card, Col } from 'antd'
import ReactPlayer from 'react-player'
import { UploadFile } from 'antd/lib/upload/interface'

// Components
import { ReactComponent as Svg } from '../../../assets/onu_pcd.svg'

const { TabPane } = Tabs

function TabDetalhes({ info }) {
  const CurriculumFileList = useMemo(() => {
    if (!info.curriculo_url) {
      return []
    }

    return [
      {
        uid: '1',
        name: `CURRICULO - ${info.nome}`,
        status: 'done',
        size: 0,
        type: 'pdf',
        url: info.curriculo_url,
      },
    ]
  }, [info])

  const PCDLaudoFileList = useMemo(() => {
    if (!info.laudo_url) {
      return []
    }

    return [
      {
        uid: '1',
        name: `PCD-LAUDO - ${info.nome}`,
        status: 'done',
        size: 0,
        type: 'pdf',
        url: info.laudo_url,
      },
    ]
  }, [info])

  return (
    <div style={{ padding: '0px 30px 30px' }}>
      <Card title="Informações Básicas">
        <Space
          style={{ width: '100%' }}
          direction="vertical"
          split={
            <Divider
              type="horizontal"
              style={{
                margin: '0px 0px 8px ',
              }}
            />
          }
        >
          {!!info.nome && (
            <Row>
              <h4>Nome: {info.nome}</h4>
            </Row>
          )}
          {!!info.email && (
            <Row>
              <h4>Email: {info.email}</h4>
            </Row>
          )}
          {!!info.escolaridade && (
            <Row>
              <h4>
                Escolaridade:{' '}
                {(info.escolaridade === 'ens_fund_inc' &&
                  'Ensino Fundamental Incompleto') ||
                  (info.escolaridade === 'ens_fund_com' &&
                    'Ensino Fundamental Completo') ||
                  (info.escolaridade === 'ens_medio_inc' &&
                    'Ensino Médio Incompleto') ||
                  (info.escolaridade === 'ens_medio_com' &&
                    'Ensino Médio Completo') ||
                  (info.escolaridade === 'ens_sup_inc' &&
                    'Ensino Superior Incompleto') ||
                  (info.escolaridade === 'ens_sup_com' &&
                    'Ensino Superior Completo')}
              </h4>
            </Row>
          )}
          {!!info.cpf && (
            <Row>
              <h4>CPF: {info.cpf}</h4>
            </Row>
          )}
          {!!info.sexo && (
            <Row>
              {' '}
              <h4>Gênero: {info.sexo}</h4>
            </Row>
          )}
          {!!info.nascimento && (
            <Row>
              <h4>Data de Nascimento: {info.nascimento}</h4>
            </Row>
          )}
          {!!info.celular && (
            <Row>
              <h4>Celular: {info.celular}</h4>
            </Row>
          )}
        </Space>
      </Card>
      <Card title="Endereço">
        <Space
          style={{ width: '100%' }}
          direction="vertical"
          split={
            <Divider
              type="horizontal"
              style={{
                margin: '0px 0px 8px ',
              }}
            />
          }
        >
          {!!info.cep && (
            <Row>
              <h4>CEP: {info.cep}</h4>
            </Row>
          )}
          {!!info.logradouro && (
            <Row>
              <h4>Logradouro: {info.logradouro}</h4>
            </Row>
          )}
          {!!info.bairro && (
            <Row>
              <h4>Bairro: {info.bairro}</h4>
            </Row>
          )}
          {!!info.numero && (
            <Row>
              <h4>Número: {info.numero}</h4>
            </Row>
          )}
          {!!info.complemento && (
            <Row>
              <h4>Complemento: {info.complemento}</h4>
            </Row>
          )}
          {!!info.estado && (
            <Row>
              <h4>Estado: {info.estado}</h4>
            </Row>
          )}
          {!!info.cidade && (
            <Row>
              <h4>Cidade: {info.cidade}</h4>
            </Row>
          )}
          {!info.cep &&
            !info.logradouro &&
            !info.bairro &&
            !info.numero &&
            !info.complemento &&
            !info.estado &&
            !info.cidade &&
            'Nenhum dado informado.'}
        </Space>
      </Card>
      <Card title="Informações Profissionais">
        <Space
          style={{ width: '100%' }}
          direction="vertical"
          split={
            <Divider
              type="horizontal"
              style={{
                margin: '0px 0px 8px ',
              }}
            />
          }
        >
          {!!info.profissao && (
            <Row>
              <h4>Profissão: {info.profissao}</h4>
            </Row>
          )}
          {!!info.prtsalarial && (
            <Row>
              <h4>Pretenção Salarial: {info.prtsalarial}</h4>
            </Row>
          )}
          {!!info.linkedin && (
            <Row>
              <h4>Linkedin: {info.linkedin}</h4>
            </Row>
          )}
          {!!info.twitter && (
            <Row>
              <h4>Twitter: {info.twitter}</h4>
            </Row>
          )}
          {!!info.site && (
            <Row>
              <h4>Site/Portfólio: {info.site}</h4>
            </Row>
          )}
          {!!info.indicacao && (
            <Row>
              <h4>Indicação: {info.indicacao}</h4>
            </Row>
          )}
          {info.pcd === 1 && (
            <div>
              <Row>
                <h4 style={{ display: 'flex', alignItems: 'center' }}>
                  <Svg width="19" height="19" style={{ marginRight: 5 }} />{' '}
                  Pessoa com Deficiência (PCD):
                </h4>
                <h4>
                  {info.nome} apresenta deficiência{' '}
                  {info.pcd_tipo ? `${info.pcd_tipo}. ` : '. '}
                  {info.cid &&
                    `CID (Classificação Internacional de Doenças) informado: ${info.cid}.`}
                </h4>
              </Row>
              <h4>Laudo:</h4>

              {!!info.laudo_url && (
                <Upload
                  fileList={PCDLaudoFileList}
                  showUploadList={{
                    showDownloadIcon: true,
                    downloadIcon: 'Ver arquivo',
                    showRemoveIcon: false,
                  }}
                />
              )}
            </div>
          )}
          {!!info.curriculo_url && (
            <div>
              <h4>Currículo:</h4>
              <Upload
                fileList={CurriculumFileList}
                showUploadList={{
                  showDownloadIcon: true,
                  downloadIcon: 'Ver arquivo',
                  showRemoveIcon: false,
                }}
              />
            </div>
          )}

          <Row>
            <h4 style={{ display: 'flex', alignItems: 'center' }}>
              <YoutubeOutlined style={{ marginRight: 5 }} /> Vídeo de
              Apresentação:
            </h4>
            {info.video !== 'Não informado' ? (
              <ReactPlayer url={info.video} muted controls width="100%" />
            ) : (
              '\u00A0 Vídeo não informado.'
            )}
          </Row>
        </Space>
      </Card>
      <div className="perfil__tabs__pesquisas__table" />
    </div>
  )
}

export default TabDetalhes
