import styled from 'styled-components'

import { Space, Col, Row } from 'antd'

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`

export const FormSubheader = styled.h3`
  width: 33%;
  font-weight: normal;
`

export const FormColumn = styled(Space)`
  width: 66%;

  & .ant-legacy-form-item {
    margin-bottom: 6px;
  }
`

export const Instruction = styled.p`
  font-size: 16px;

  .highlight {
    font-weight: bold;
  }
`

export const Area = styled.textarea`
  width: 100%;
`

export const QuestionContainer = styled(Col)`
  width: 100%;
  padding: 24px;

  .ant-form-item-label {
    font-weight: 600;
    height: 32px;
  }

  .ant-alert {
    margin-top: 12px;
  }

  margin-bottom: 0.5rem;

  background-color: #fff;

  &:hover {
    border: 1px solid #edf2f7;
    background-color: #f7fafc;
  }

  &:hover .ant-form-item-no-colon {
    color: #30318b;
    height: 32px;
  }
`

export const ContainerAlternativas = styled(Col)`
  margin: 12px 0;

  & .section__title {
    font-weight: bold;
    color: #718096;
    margin-bottom: 12px;
  }
`

export const AlternativaContainer = styled(Space)`
  & .ant-form-item {
    margin: 0;
  }
`

export const AlternativaItem = styled.div`
  margin: 12px 0;

  & .ant-input {
    margin: 4px 0;
  }
`
