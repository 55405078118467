import styled from 'styled-components'

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    button + span {
      margin-left: 16px;
    }
  }
`

export const Action = styled.span`
  color: var(--purple);
  display: inline-block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
