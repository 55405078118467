import { message } from 'antd'

// Redux types
import * as constants from '../constants/constantsCadastro'

export const cadastro = (state = 0, action) => {
  switch (action.type) {
    case constants.CADASTRO_POST:
      if (action.response.status !== 200) message.error('Erro ao cadastrar.')

      return state

    default:
      return state
  }
}
