import React, { useEffect, useCallback, useState, useMemo } from 'react'

import {
  Empty,
  Modal,
  Input,
  Button,
  Table,
  Tooltip,
  Divider,
  Popconfirm,
} from 'antd'

import { ColumnsType } from 'antd/lib/table'

import {
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  MailOutlined,
  PlusOutlined,
  ProfileOutlined,
  SearchOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment-timezone'

// Services
import api from '../../services/api'

// Components
import FeedbackListItem from './components/FeedbackListItem'

// Styles
import { FeedbackContainer, FeedbackButton, Header, Label } from './styles'

// Types
import {
  IFeedback,
  IFeedbackDeleteFn,
  IFeedbackSaveUpdateFn,
  IFeedbackUpdateFn,
  IFeedbackCreate,
} from './@types'

import AdicionarFeedback from './components/AdicionarFeedback'

interface MatchParams {
  id: string
}

interface IProps {
  id: string
  match: {
    params: MatchParams
  }
}

const Feedback: React.FC<IProps> = ({ id, match }, searchInput) => {
  const pes_id = parseInt(match ? match.params.id : id)

  const [feedbacks, setFeedbacks] = useState<IFeedback[]>([])

  const [loadingFeedbacks, setLoadingFeedbacks] = useState(false)

  const [currentFeedback, setCurrentFeedback] = useState<number>()

  const [savingFeedback, setSavingFeedback] = useState<boolean>(false)

  const [creatingFeedback, setCreatingFeedback] = useState(false)

  const [showModalFeedbackDetails, setShowModalFeedbackDetails] =
    useState(false)

  const [showModalNewFeedback, setShowModalNewFeedback] = useState(false)

  const loadFeedbacks = useCallback(async () => {
    setLoadingFeedbacks(true)

    try {
      const response = await api.get(`/pessoas/${pes_id}/feedbacks`)

      if (response.data) {
        setFeedbacks(response.data)
      }
    } finally {
      setLoadingFeedbacks(false)
    }
  }, [pes_id])

  useEffect(() => {
    if (pes_id) {
      loadFeedbacks()
    }
  }, [loadFeedbacks, pes_id])

  const handleCreateFeedback = useCallback(
    async ({ comportamento, impacto, situacao, sugestao }: IFeedbackCreate) => {
      setCreatingFeedback(true)
      try {
        const response = await api.post(`/feedbacks`, {
          comportamento,
          impacto,
          situacao,
          sugestao,
          pes_id,
        })

        if (response.status === 200) {
          loadFeedbacks()
          toast.success('Feedback criado com sucesso.')
          setShowModalNewFeedback(false)
        }
      } finally {
        setCreatingFeedback(false)
      }
    },
    [loadFeedbacks, pes_id]
  )

  const handleUpdateFeedbackData = useCallback(
    async ({
      feedback_id,
      comportamento,
      impacto,
      situacao,
      sugestao,
    }: IFeedbackUpdateFn) => {
      setFeedbacks((state) =>
        state.map((f) =>
          f.feedback_id !== feedback_id
            ? f
            : {
                ...f,
                ...(comportamento ? { comportamento } : {}),
                ...(impacto ? { impacto } : {}),
                ...(situacao ? { situacao } : {}),
                ...(sugestao ? { sugestao } : {}),
              }
        )
      )
    },
    []
  )

  const handleSaveUpdateFeedback = useCallback(
    async ({ feedback_id }: IFeedbackSaveUpdateFn) => {
      setSavingFeedback(true)

      const feedback = feedbacks.find((f) => f.feedback_id === feedback_id)

      if (!feedback) {
        setSavingFeedback(false)
      }

      try {
        const response = await api.put(`/feedbacks/${feedback_id}`, {
          ...feedback,
        })

        if (response.status === 200) {
          loadFeedbacks()
          toast.success('Feedback atualizado com sucesso.')
        }
      } finally {
        setSavingFeedback(false)
      }
    },
    [feedbacks, loadFeedbacks]
  )

  const handleDeleteFeedback = useCallback(
    async ({ feedback_id }: IFeedbackDeleteFn) => {
      const response = await api.delete(`/feedbacks/${feedback_id}`)

      if (response.status === 204) {
        loadFeedbacks()
        toast.success('Feedback excluído com sucesso.')
      }
    },
    [loadFeedbacks]
  )

  const handleSendEmailFeedback = useCallback(async (feedback_id: number) => {
    const response = await api.post(`/feedbacks/${feedback_id}/sendEmail`)

    if (response.status === 204) {
      toast.success('E-mail com os detalhes do feedback enviado com sucesso.')
    }
  }, [])

  const toggleModalFeedbackDetails = useCallback(
    (feedback_id: number | null = null) => {
      if (feedback_id) {
        setCurrentFeedback(feedback_id)
        setShowModalFeedbackDetails(true)
      } else {
        setCurrentFeedback(undefined)
        setShowModalFeedbackDetails(false)
      }
    },
    []
  )

  const columns: ColumnsType<IFeedback> = useMemo(
    () => [
      {
        title: 'Data',
        width: '15%',
        dataIndex: 'created_at',
        render: (text: string) =>
          text && moment(text).isValid()
            ? moment(text)
                .utc()
                .tz(moment.tz.guess())
                .format('DD/MM/YYYY (HH:mm)')
            : '',
        align: 'center',
      },
      {
        title: 'Criado por',
        width: '20%',
        dataIndex: ['creator', 'colaborador', 'pes_nome'],
        align: 'center',
        render: (text: string, record: IFeedback) => {
          return (
            <>
              {record.creator && (
                <>
                  <span>{record.creator.colaborador.pes_nome}</span>
                </>
              )}
              {record.creator && record.created_at && <br />}
              {record.created_at && (
                <span>
                  {moment(record.created_at)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('(DD/MM/YYYY [às] HH:mm)')}
                </span>
              )}
            </>
          )
        },
      },
      {
        title: 'Atualizado por',
        width: '20%',
        dataIndex: ['updater', 'colaborador', 'pes_nome'],
        align: 'center',
        render: (text: string, record: IFeedback) => {
          return (
            <>
              {record.updater && (
                <>
                  <span>{record.updater.colaborador.pes_nome}</span>
                </>
              )}
              {record.updater && record.updated_at && <br />}
              {record.updated_at && (
                <span>
                  {moment(record.updated_at)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('(DD/MM/YYYY [às] HH:mm)')}
                </span>
              )}
            </>
          )
        },
      },
      {
        title: 'Comportamento',
        dataIndex: ['comportamento'],
        align: 'center',
      },
      {
        title: 'Ações',
        key: 'actions',
        align: 'center',
        width: '15%',
        render(text: string, record: IFeedback) {
          return (
            <>
              <>
                <Tooltip title="Ver detalhes" placement="top">
                  <a>
                    <ProfileOutlined
                      onClick={() =>
                        toggleModalFeedbackDetails(record.feedback_id)
                      }
                    />
                  </a>
                </Tooltip>
                <Divider type="vertical" />
              </>

              <Popconfirm
                title="Apagar Feedback?"
                onConfirm={() => {
                  handleDeleteFeedback({ feedback_id: record.feedback_id })
                }}
                okText="Sim"
                cancelText="Não"
                icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
              >
                <Tooltip title="Excluir" placement="top">
                  <a>
                    <DeleteOutlined />
                  </a>
                </Tooltip>
              </Popconfirm>

              <Divider type="vertical" />

              <Popconfirm
                title="Enviar e-email do Feedback?"
                onConfirm={() => {
                  handleSendEmailFeedback(record.feedback_id)
                }}
                okText="Sim"
                cancelText="Não"
              >
                <Tooltip title="Enviar e-mail" placement="top">
                  <a>
                    <MailOutlined />
                  </a>
                </Tooltip>
              </Popconfirm>
            </>
          )
        },
      },
    ],
    [handleDeleteFeedback, handleSendEmailFeedback, toggleModalFeedbackDetails]
  )

  const currentFeedbackData = useMemo(() => {
    if (currentFeedback) {
      return feedbacks.find(
        (feedback) => feedback.feedback_id === currentFeedback
      )
    }
    return undefined
  }, [currentFeedback, feedbacks])

  return (
    <FeedbackContainer>
      <p>
        O Feedback é um processo de fornecimento de informações que têm o
        intuito de contribuir para a melhoria do desempenho e da performance de
        um profissional ou para reforço da cultura e comportamentos desejáveis e
        ajuste de rotas e pontos cegos. Para casos de ajuste de rotas e/ou
        comportamento faz se necessário a realização de um PDI, que está
        disponível logo abaixo.
      </p>
      <Table
        title={() => (
          <Header>
            <Label>Feedbacks</Label>
            <FeedbackButton
              type="primary"
              onClick={() => setShowModalNewFeedback(true)}
              icon={<PlusOutlined />}
            >
              NOVO FEEDBACK
            </FeedbackButton>
          </Header>
        )}
        dataSource={feedbacks}
        columns={columns}
        loading={loadingFeedbacks}
      />

      <Modal
        destroyOnClose
        visible={showModalFeedbackDetails}
        onCancel={() => toggleModalFeedbackDetails()}
        cancelText="Fechar"
        width="50%"
        centered
        okButtonProps={{
          hidden: true,
        }}
        title="Registro do Feedback"
      >
        {currentFeedbackData && (
          <FeedbackListItem
            key={currentFeedbackData.feedback_id}
            data={currentFeedbackData}
            handleUpdateFeedbackData={handleUpdateFeedbackData}
            handleSaveUpdateFeedback={handleSaveUpdateFeedback}
            saving={savingFeedback}
            pes_id={pes_id}
          />
        )}
      </Modal>

      <Modal
        destroyOnClose
        visible={showModalNewFeedback}
        onCancel={() => setShowModalNewFeedback(false)}
        okText="Salvar"
        cancelText="Cancelar"
        okButtonProps={{
          form: 'feedback-novo-feedback',
          htmlType: 'submit',
          loading: creatingFeedback,
        }}
        closable={!creatingFeedback}
      >
        <AdicionarFeedback onFinish={handleCreateFeedback} />
      </Modal>
    </FeedbackContainer>
  )
}

export default Feedback
