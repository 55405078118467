import {
  MenuUnfoldOutlined,
  RollbackOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Avatar, Dropdown, Layout, Menu } from 'antd'
import React from 'react'
import { injectIntl } from 'react-intl'
// Assets
import logo from '../../assets/logo.png'
import { ReactComponent as LogoOne } from '../../assets/logoOne.svg'
import { ReactComponent as MiniLogo } from '../../assets/miniLogo.svg'

import logoSmall from '../../assets/logo_sm.png'
import Link from '../../components/privateLink/PrivateLink'
// Services
import { getContaURL, isAdmin, isLider } from '../../services/auth'
import './DashboardLayout.css'
import {
  navigationMenuDefault,
  NavigationMenuGestor,
  NavigationMenuLider,
} from './Navigation'

// Constants

const { Header, Content, Sider } = Layout

function DashboardLayoutView({
  intl,
  handleSider,
  history,
  sider = true,
  component: Component,
  collapsed,
  logout,
  user,
  className = '',
  fixed = false,
  ...props
}) {
  const contaURL = getContaURL()
  const lider = isLider()
  const admin = isAdmin()

  const NavigationMenu = React.useCallback(() => {
    if (lider) {
      return NavigationMenuLider(admin)
    }
    if (admin) {
      return NavigationMenuGestor(admin)
    }

    return [navigationMenuDefault(admin)]
  }, [lider, admin])

  return (
    <Layout
      className={`dashboard-layout ${className} ${fixed ? 'fixed-layout' : ''}`}
    >
      {sider && (
        <Sider
          breakpoint="lg"
          width={250}
          className="dashboard-layout__side-menu"
          collapsed={collapsed}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          <div
            style={{ padding: !collapsed ? 30 : 10 }}
            className="dashboard-layout__side-menu__logo"
          >
            {!collapsed ? <LogoOne /> : <MiniLogo />}
          </div>

          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[history.location.pathname]}
            defaultSelectedKeys={[`/${contaURL}/cultura-desejada`]}
          >
            {NavigationMenu().map((x, key) => (
              <React.Fragment key={`menu-item-${contaURL}-${x.title}-${key}`}>
                {x.children ? (
                  <Menu.SubMenu
                    key={key}
                    title={
                      <span>
                        {x.icon && x.icon}
                        <span>{x.title}</span>
                      </span>
                    }
                  >
                    {x.children.map((child, key) => (
                      <React.Fragment
                        key={`child-${contaURL}-${x.title}-${key}`}
                      >
                        {child.children ? (
                          <Menu.SubMenu
                            title={
                              <span>
                                {child.icon && child.icon}
                                <span>{child.title}</span>
                              </span>
                            }
                          >
                            {child.children.map((subChild, index) => (
                              <Menu.Item key={Math.random()}>
                                <Link to={subChild.href ?? ''}>
                                  {subChild.icon && subChild.icon}
                                  <span className="nav-text">
                                    {subChild.title}
                                  </span>
                                </Link>
                              </Menu.Item>
                            ))}
                          </Menu.SubMenu>
                        ) : (
                          <Menu.Item key={Math.random()}>
                            <Link to={child.href ?? ''}>
                              {child.icon && child.icon}
                              <span className="nav-text">{child.title}</span>
                            </Link>
                          </Menu.Item>
                        )}
                      </React.Fragment>
                    ))}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item key={Math.random()}>
                    <Link to={x.href ?? ''}>
                      {x.icon && x.icon}
                      <span className="nav-text">{x.title}</span>
                    </Link>
                  </Menu.Item>
                )}
              </React.Fragment>
            ))}
          </Menu>
        </Sider>
      )}

      <Layout style={{ marginLeft: !collapsed ? '250px' : '80px' }}>
        <Header className="dashboard-layout__header">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              verticalAlign: 'baseline',
            }}
          >
            {sider && (
              <MenuUnfoldOutlined
                className="dashboard-layout__header__btn"
                onClick={handleSider}
              />
            )}
            {/* If necessary, 'window.location.href' will be used to verify if the link is a link inside the platform and only render if it is true. */}
            {[history.location.pathname][0] !==
              [`/${contaURL}/dashboard`][0] && (
              <RollbackOutlined
                className="dashboard-layout__header__btn"
                onClick={() => window.history.back()}
              />
            )}
          </div>

          <div className="dashboard-layout__header__profile">
            <Dropdown
              className="dashboard-layout__header__dropdown"
              overlay={
                <Menu>
                  <Menu.Item
                    key={Math.random()}
                    onClick={() => history.push('/nova-senha')}
                  >
                    Mudar Senha
                  </Menu.Item>
                  <Menu.Item key={Math.random()} onClick={logout}>
                    Logout
                  </Menu.Item>
                </Menu>
              }
            >
              <a className="ant-dropdown-link">
                <Avatar
                  className="dashboard-layout__header__profile__avatar"
                  icon={<UserOutlined />}
                />
                <span>{user.name}</span>
              </a>
            </Dropdown>
          </div>
        </Header>

        <Content className={`dashboard-layout__content ${className}`}>
          <div className={`dashboard-layout__content__inner ${className}`}>
            <Component {...props} history={history} />
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default injectIntl(DashboardLayoutView)
