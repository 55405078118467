import React, { useState } from 'react'

import { Button, Col, Row, Divider, Alert, Modal, Typography } from 'antd'

import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'

import { Scope } from '@unform/core'
import { QuestionContainer, ContainerAlternativas } from './styles'

import Alternativas from '../Alternativa/Alternativa'
import Questao from '../../../FormularioQuiz/components/Questao'

import TextAreaField from '../form/textarea-field'
import SelectField from '../form/select-field'

const tipoOptions = [
  { id: 1, label: 'Textual', value: 1 },
  { id: 2, label: 'Alternativas', value: 2 },
]

const { Text } = Typography

function Questoes({
  formRef,
  questaoIndex,
  removerItem,
  handleAddNovaQuestao,
  previewData,
}) {
  const [tipoQuestao, settipoQuestao] = useState({ id: null, label: null })

  const [allowPreview, setAllowPreview] = useState(false)
  const [showPreviewPergunta, setShowPreviewPergunta] = useState(false)

  const handleChange = (value) => {
    settipoQuestao(value)
  }

  return (
    <>
      <Scope path={`questoes[${questaoIndex}]`}>
        <QuestionContainer>
          <Row gutter={12} align="top">
            <Col span={20}>
              <Row>
                <Text className="ant-form-item-no-colon ant-form-item-label">
                  Questão 0{questaoIndex + 1}
                </Text>
                {allowPreview && (
                  <Button
                    type="dashed"
                    icon={<EyeOutlined />}
                    onClick={() => {
                      setShowPreviewPergunta(true)
                    }}
                    size="small"
                    style={{ marginLeft: '6px' }}
                  >
                    VER PREVIEW
                  </Button>
                )}
              </Row>
              <TextAreaField
                name="questao"
                placeholder="Insira aqui o enunciado da questão"
                required
                maxLength={512}
                rows={3}
                onChange={() => setAllowPreview(true)}
              />
            </Col>
            <Col span={4}>
              <SelectField
                name="tipo"
                label="Tipo de Questão"
                options={tipoOptions}
                onChange={handleChange}
              />
            </Col>
          </Row>

          {tipoQuestao && +tipoQuestao.id === 1 && (
            <>
              <Alert
                questaoIndex={questaoIndex}
                description="
                  Perguntas do tipo Textual não tem suas respostas corrigidas.
                  Suas respostas são disponibilizadas integralmente na
                  visualização dos resultados em (Processo Seletivo -> Testes) ou no Perfil do Candidiato."
              />
            </>
          )}

          {tipoQuestao && +tipoQuestao.id === 2 && (
            <>
              <ContainerAlternativas>
                <span className="section__title">Alternativas</span>
                <Alternativas questaoIndex={questaoIndex} formRef={formRef} />
              </ContainerAlternativas>
            </>
          )}

          {!tipoQuestao.id && (
            <>
              <Alert description="Selecione o tipo da questao." />
            </>
          )}
        </QuestionContainer>
      </Scope>

      <Row>
        <Button
          type="secondary"
          icon={<PlusOutlined />}
          onClick={() =>
            handleAddNovaQuestao(formRef.current.getData().questoes)
          }
        >
          Adicionar questão
        </Button>
        <Button
          type="secondary"
          style={{ marginLeft: 8 }}
          icon={<DeleteOutlined />}
          onClick={removerItem}
        >
          Remover questão
        </Button>
      </Row>

      <Divider />

      {showPreviewPergunta && (
        <Modal
          visible={showPreviewPergunta}
          width="80%"
          footer={null}
          onCancel={() => setShowPreviewPergunta(false)}
        >
          <Questao
            preview
            questao={previewData.questoes[questaoIndex].questao}
            alternativas={previewData.questoes[questaoIndex].alternativas}
            tempoLimiteQuestao={18000}
            handleProximaQuestao={() => {
              return 0
            }}
          />
        </Modal>
      )}
    </>
  )
}
export default Questoes
