import React, { useState, useEffect, useCallback } from 'react'
import { injectIntl } from 'react-intl'
import {
  UploadOutlined,
  TwitterOutlined,
  LinkedinOutlined,
} from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import {
  Row,
  Col,
  Divider,
  Checkbox,
  InputNumber,
  Upload,
  Button,
  Input as InputAntd,
} from 'antd'

import { getFullAddress } from '../../../../services/cep'

// Components
import Input, { Date, MaskedInput } from '../../../../components/input/Input'
import Select, { Option } from '../../../../components/select/Select'

// CSS
import './AdicionarNovoCandidato.css'

// Constants

function AdicionarNovoCandidato({
  intl,
  handleChangeModal,
  cargos,
  error,
  handleChangeCheckbox,
  currentStep,
  cargosEscolhidos,
  cep,
  logradouro,
  nro,
  complemento,
  bairro,
  ufid,
  cidadeid,
  handleSelectCurriculo,
  fileList,
  handleSelectCargos,
  cpf,
  nome,
  dataNascimento,
  email,
  sexo,
  telefone,
  vetorComportamental,
  valoresOrganizacionais,
  enviarEmails,
  profissao,
  prtsalario,
  indicacao,
  linkedin,
  twitter,
  site,
  handleRemoveCurriculo,
}) {
  const [pessoaComDeficiencia, setPessoaComDeficiencia] = useState(false)
  const [cepValue, setCepValue] = useState(cep)
  const [errCep, setErrCep] = useState(false)
  const [errCepMessage, setErrCepMessage] = useState(false)
  const [loadingCep, setLoadingCep] = useState(false)
  const [rua, setRua] = useState(logradouro)
  const [bairroValue, setBairro] = useState(bairro)
  const [cidade, setCidade] = useState(cidadeid)
  const [estado, setEstado] = useState(ufid)
  const [numero, setNumero] = useState(nro)
  const [complementoValue, setComplemento] = useState(complemento)

  const handleGetCep = useCallback(async () => {
    const regex = /[0-9]/

    if (cepValue != null && cepValue != undefined) {
      let cepGet = cepValue.replace('-', '')
      cepGet = cepGet.replace('_', '')
      if (cepGet.length == 8 && regex.test(cepGet)) {
        setLoadingCep(true)
        const data = await getFullAddress(cep)
        if (data?.city && data?.state) {
          setRua(data.street || '')
          setBairro(data.neighborhood || '')
          setCidade(data.city || '')
          setEstado(data.state || '')
          setNumero('')
          setComplemento('')
        } else {
          setErrCep(true)
          setErrCepMessage('Cep inválido')
        }
        setLoadingCep(false)
      }
    }
  }, [cep, cepValue])

  useEffect(() => {
    handleGetCep()
  }, [cepValue, handleGetCep])

  return (
    <div className="adicionar-candidatos">
      <Form layout="vertical">
        {currentStep === 0 && (
          <>
            <Row gutter={20}>
              <Col md={14} xs={14} sm={14}>
                <Form.Item
                  label="Nome"
                  validateStatus={error.nome ? 'error' : ''}
                  help={error.nome || ''}
                  required
                >
                  <Input
                    value={nome}
                    type="text"
                    placeholder="Digite o nome"
                    name="nome"
                    onChange={handleChangeModal}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
              <Col md={10} xs={10} sm={10}>
                <Form.Item
                  label="CPF"
                  validateStatus={error.cpf ? 'error' : ''}
                  help={error.cpf || ''}
                >
                  <MaskedInput
                    value={cpf}
                    mask="999.999.999-99"
                    name="cpf"
                    onChange={handleChangeModal}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={24} xs={24} sm={24}>
                <Form.Item
                  label="E-mail"
                  validateStatus={error.email ? 'error' : ''}
                  help={error.email || ''}
                  required
                >
                  <Input
                    value={email}
                    type="text"
                    placeholder="Digite o e-mail"
                    name="email"
                    onChange={handleChangeModal}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
              <Col md={14} xs={14} sm={14}>
                <Form.Item
                  label="Data de Nascimento"
                  validateStatus={error.dataNascimento ? 'error' : ''}
                  help={error.dataNascimento || ''}
                >
                  <Date
                    value={dataNascimento}
                    placeholder="Digite o nascimento"
                    name="dataNascimento"
                    onChange={(date) =>
                      handleChangeModal({
                        target: { name: 'dataNascimento', value: date },
                      })
                    }
                    withoutMargin
                  />
                </Form.Item>
              </Col>
              <Col md={10} xs={10} sm={10}>
                <Form.Item
                  label="Sexo"
                  validateStatus={error.sexo ? 'error' : ''}
                  help={error.sexo || ''}
                >
                  <Select
                    placeholder="Insira o sexo"
                    name="sexo"
                    onChange={(value) =>
                      handleChangeModal({ target: { name: 'sexo', value } })
                    }
                    style={{ width: '100%' }}
                    defaultValue="M"
                    withoutMargin
                    value={sexo}
                  >
                    <Option value="M">Masculino</Option>
                    <Option value="F">Feminino</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              {/* <Col md={14}>
								<Form.Item label={'Cargo'}>
									<Select
										placeholder={'Selecione o cargo'}
										name='cargo'
										onChange={(cargo) =>
											handleChangeModal({
												target: { name: 'cargo', value: cargo },
											})
										}
										style={{ width: '100%' }}
										dropdownRender={(menu) => (
											<div>
												{menu}
												<Divider style={{ margin: '4px 0' }} />
												<div style={{ padding: '8px', cursor: 'pointer' }}>
													<PlusOutlined /> Novo Cargo
												</div>
											</div>
										)}
										withoutMargin
									>
										{cargos.map(({ cargo_id, cargo_nome }) => (
											<Option key={cargo_id} value={cargo_id}>
												{cargo_nome}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							*/}
              <Col md={8}>
                <Form.Item
                  label={intl.formatMessage({
                    id: 'common.telefone',
                  })}
                  validateStatus={error.telefone ? 'error' : ''}
                  help={error.telefone || ''}
                >
                  <MaskedInput
                    value={telefone}
                    mask="(99) 99999-9999"
                    name="telefone"
                    onChange={handleChangeModal}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
              <Col
                md={14}
                xs={14}
                sm={14}
                lg={14}
                style={{
                  marginTop: 20,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Form.Item>
                  <Checkbox
                    name="pcd"
                    onChange={({ target }) => {
                      if (target.checked === true) {
                        handleChangeModal({
                          target: {
                            name: 'pcd',
                            value: 1,
                          },
                        })
                      } else {
                        handleChangeModal({
                          target: {
                            name: 'pcd',
                            value: 0,
                          },
                        })
                        handleChangeModal({
                          target: {
                            name: 'pcd_tipo',
                            value: undefined,
                          },
                        })
                        handleChangeModal({
                          target: {
                            name: 'cid',
                            value: undefined,
                          },
                        })
                      }
                      setPessoaComDeficiencia(target.checked)
                    }}
                    style={{ marginTop: 5 }}
                  >
                    É pessoa com deficiência (PCD)?
                  </Checkbox>
                </Form.Item>
              </Col>

              {pessoaComDeficiencia && (
                <>
                  <Col xs={12} md={12} lg={12}>
                    <Form.Item label="Tipo de PCD">
                      <Select
                        placeholder="Selecione o tipo"
                        name="pcd_tipo"
                        onChange={(value) => {
                          if (pessoaComDeficiencia === true)
                            handleChangeModal({
                              target: { name: 'pcd_tipo', value },
                            })
                          else
                            handleChangeModal({
                              target: {
                                name: 'pcd_tipo' && 'cid',
                                value: undefined,
                              },
                            })
                        }}
                        style={{ width: '100%' }}
                        defaultValue="Física"
                      >
                        <Option value="Física">Física</Option>
                        <Option value="Auditiva">Auditiva</Option>
                        <Option value="Visual">Visual</Option>
                        <Option value="Mental">Mental</Option>
                        <Option value="Múltipla">Múltipla</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <Form.Item label="CID">
                      <Input
                        type="text"
                        placeholder="Digite o CID"
                        name="cid"
                        onChange={handleChangeModal}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>

            <Row>
              <Checkbox
                name="vetorComportamental"
                onChange={handleChangeCheckbox}
                checked={vetorComportamental}
              >
                Gerar pesquisa de Perfil Comportamental
              </Checkbox>
            </Row>
            <Row>
              <Checkbox
                name="valoresOrganizacionais"
                onChange={handleChangeCheckbox}
                checked={valoresOrganizacionais}
              >
                Gerar pesquisa de Valores Organizacionais
              </Checkbox>
            </Row>
            <Divider />
            <Row>
              <Checkbox
                name="enviarEmails"
                onChange={handleChangeCheckbox}
                checked={enviarEmails}
              >
                Enviar e-mails
              </Checkbox>
            </Row>
          </>
        )}
        {currentStep === 1 && (
          <>
            <Row gutter={20}>
              <Col md={24} xs={24} sm={24}>
                <Form.Item
                  label="CEP"
                  validateStatus={error.cep || errCep ? errCepMessage : ''}
                  help={error.cep || ''}
                >
                  <MaskedInput
                    value={cep}
                    mask="99999-999"
                    name="cep"
                    onChange={(value) => {
                      setCepValue(value.target.value)
                      return handleChangeModal(value)
                    }}
                    placeholder="Informe o CEP"
                    withoutMargin
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={18} xs={18} sm={18}>
                <Form.Item
                  label="Rua / Avenida / Logradouro"
                  validateStatus={error.cep ? 'error' : ''}
                  help={error.cep || ''}
                >
                  <Input
                    type="text"
                    name="logradouro"
                    onChange={() => true}
                    value={rua}
                    withoutMargin
                  />
                </Form.Item>
              </Col>

              <Col md={6} xs={6} sm={6}>
                <Form.Item
                  label="Número"
                  validateStatus={error.nro ? 'error' : ''}
                  help={error.nro || ''}
                >
                  <InputNumber
                    value={numero}
                    defaultValue={null}
                    min={0}
                    max={10000}
                    onChange={(value) =>
                      handleChangeModal({
                        target: { name: 'nro', value },
                      })
                    }
                    withoutMargin
                    style={{ width: '100%' }}
                    name="nro"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={24} xs={24} sm={24}>
                <Form.Item
                  label="Complemento"
                  validateStatus={error.complemento ? 'error' : ''}
                  help={error.complemento || ''}
                >
                  <Input
                    type="text"
                    name="complemento"
                    onChange={handleChangeModal}
                    value={complementoValue}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={12} xs={12} sm={12}>
                <Form.Item
                  label="Bairro"
                  validateStatus={error.bairro ? 'error' : ''}
                  help={error.bairro || ''}
                >
                  <Input
                    type="text"
                    name="bairro"
                    onChange={() => true}
                    value={bairroValue}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
              <Col md={4} xs={4} sm={4}>
                <Form.Item
                  label="UF"
                  validateStatus={error.ufid ? 'error' : ''}
                  help={error.ufid || ''}
                >
                  <Input
                    type="text"
                    name="ufid"
                    onChange={() => true}
                    value={estado}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={8} sm={8}>
                <Form.Item
                  label="Cidade"
                  validateStatus={error.cidadeid ? 'error' : ''}
                  help={error.cidadeid || ''}
                >
                  <Input
                    type="text"
                    name="cidadeid"
                    onChange={() => true}
                    value={cidade}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {currentStep === 2 && (
          <>
            <Row gutter={20}>
              <Col md={14} xs={14} sm={14}>
                <Form.Item
                  label="Profissão"
                  validateStatus={error.profissao ? 'error' : ''}
                  help={error.profissao || ''}
                >
                  <Input
                    value={profissao}
                    type="text"
                    placeholder="Informe sua profissão"
                    name="profissao"
                    onChange={handleChangeModal}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
              <Col md={10} xs={10} sm={10}>
                <Form.Item
                  label="Pretensão Salarial"
                  validateStatus={error.prtsalario ? 'error' : ''}
                  help={error.prtsalario || ''}
                >
                  <InputNumber
                    value={prtsalario}
                    defaultValue={0}
                    min={0}
                    max={10000000}
                    decimalSeparator=","
                    parser={(value) => value.replace(/\R\$\s?|(,*)/g, '')}
                    formatter={(value) => `R$ ${value}`}
                    onChange={(value) =>
                      handleChangeModal({
                        target: { name: 'prtsalario', value },
                      })
                    }
                    withoutMargin
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={12} xs={12} sm={12}>
                <Form.Item
                  label="Linkedin"
                  validateStatus={error.linkedin ? 'error' : ''}
                  help={error.linkedin || ''}
                >
                  <Input
                    value={linkedin}
                    type="text"
                    placeholder="Caso possua, informe o link"
                    suffix={<LinkedinOutlined />}
                    name="linkedin"
                    onChange={handleChangeModal}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={12} sm={12}>
                <Form.Item
                  label="Twitter"
                  validateStatus={error.twitter ? 'error' : ''}
                  help={error.twitter || ''}
                >
                  <Input
                    value={twitter}
                    type="text"
                    suffix={<TwitterOutlined />}
                    placeholder="Caso possua, informe o link"
                    name="twitter"
                    onChange={handleChangeModal}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={24} xs={24} sm={24}>
                <Form.Item
                  label="Site"
                  validateStatus={error.site ? 'error' : ''}
                  help={error.site || ''}
                >
                  <Input
                    value={site}
                    type="text"
                    placeholder="Caso possua um site próprio, blog ou portifólio informe o link"
                    name="site"
                    onChange={handleChangeModal}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={24} xs={24} sm={24}>
                <Form.Item
                  label="Indicação"
                  validateStatus={error.indicacao ? 'error' : ''}
                  help={error.indicacao || ''}
                >
                  <Input
                    value={indicacao}
                    type="text"
                    placeholder="Caso tenha sido indicado, informe o nome de quem o indicou"
                    name="indicacao"
                    onChange={handleChangeModal}
                    withoutMargin
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={24} xs={24} sm={24}>
                <Form.Item
                  validateStatus={error.curriculo ? 'error' : ''}
                  help={error.curriculo || ''}
                >
                  <Upload
                    accept=".pdf,application/pdf"
                    multiple={false}
                    customRequest={({ onSuccess }) => {
                      setTimeout(() => {
                        onSuccess('ok')
                      }, 0)
                    }}
                    onChange={(info) => handleSelectCurriculo(info)}
                    fileList={fileList}
                    onRemove={handleRemoveCurriculo}
                  >
                    <Button icon={<UploadOutlined />} type="primary">
                      Enviar currículo
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={24} xs={24} sm={24}>
                <Form.Item
                  label="Cargo pretendido (Selecione até 3 cargos)"
                  validateStatus={error.cargosEscolhidos ? 'error' : ''}
                  help={error.cargosEscolhidos || ''}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Selecione o cargo"
                    name="cargosEscolhidos"
                    onChange={(cargos) => handleSelectCargos(cargos)}
                    style={{ width: '100%' }}
                    dropdownRender={(menu) => <div>{menu}</div>}
                    withoutMargin
                    value={cargosEscolhidos}
                  >
                    {cargos.map(({ cargo_id, cargo_nome }) => (
                      <Option key={cargo_id} value={cargo_id}>
                        {cargo_nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </div>
  )
}

export default injectIntl(AdicionarNovoCandidato)
