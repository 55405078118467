import React, { useState, useMemo, useEffect } from 'react'

import {
  Col,
  Card,
  Divider,
  Row,
  Empty,
  Progress,
  Table,
  Modal,
  Alert,
  Tooltip as TooltipAnt,
} from 'antd'

import {
  Chart,
  Axis,
  Coordinate,
  Tooltip,
  Line,
  Point,
  Interval,
  Legend,
} from 'bizcharts'

import DataSet from '@antv/data-set'

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import {
  FullscreenOutlined,
  ZoomInOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  DeleteOutlined,
  StarFilled,
} from '@ant-design/icons'
import { ResponsiveRadar, ResponsiveLine, ResponsiveBar } from '../charts'

import {
  FlexCard,
  FlexTitle,
  Label,
  RowChars,
  RateSpan,
  RateContainer,
  ProgressTitle,
  ProgressContainer,
  ProgressValues,
  ProgressLabels,
  Divider16,
  CardFill,
  ColFill,
  FullCol,
  Divider32,
  Divider64,
  FlexRow,
  FitCulturalContainer,
  Link16,
  FitCulturalCard,
  FlexTablesContainer,
  Container,
  HistoricoRow,
  // eslint-disable-next-line import/named
} from './styles'

import { discRange, colors, arqRange } from '../../utils/utils'

import { noPadding, cardHeadBlue } from '../../utils/inlineStyles'

import Textual from '../../containers/perfilComportamentalDetalhes/components/textual/Textual'

import { FitCulturalTables } from '../FitCulturalPerfil'

const columns = [
  {
    title: 'Perfil',
    dataIndex: 'row',
  },
  {
    title: 'Essência',
    dataIndex: 'essencia',
    render: (text, record) =>
      text === 0 ? '0 %' : text ? `${parseFloat(text).toFixed(1)} %` : '',
  },
  {
    title: 'Exigido',
    dataIndex: 'exigido',
    render: (text, record) =>
      text === 0 ? '0%' : text ? `${parseFloat(text).toFixed(1)} %` : '',
  },
  {
    title: 'Entrega',
    dataIndex: 'entrega',
    render: (text, record) =>
      text === 0 ? '0 %' : text ? `${parseFloat(text).toFixed(1)} %` : '',
  },
]

const fitCulturalTypes = ['colaborador', 'candidato']

export function PerfilComportamental({
  cultura,
  culturaEmpresa,
  disc,
  discInstalado,
  intensidade,
  rct,
  edl,
  foc,
  ode,
  oct,
  tdd,
  cor,
  erc,
  edc,
  vetores = null,
  principal,
  fitCulturalEmpresa = {},
  type = 'geral',
  showHistoricoDisc = null,
  handleSwitchGraficoRadar = null,
  handleSwitchHistoricoDisc = null,
  showGraficoRadar = null,
  perfilComp,
  perfisQtde,
  pes_nome = null,
  minutosPesquisa,
}) {
  const purple = getComputedStyle(document.body).getPropertyValue('--purple')

  const pink = getComputedStyle(document.body).getPropertyValue('--orange')

  const perfil = {
    D: {},
    I: {},
    S: {},
    C: {},
  }

  if (culturaEmpresa != [] && Object.keys(fitCulturalEmpresa).length != 0) {
    fitCulturalEmpresa.fitArquetipos.Aprendizado =
      (fitCulturalEmpresa.arquetipos.Aprendizado /
        culturaEmpresa.find((element) => element.Tipo === 'Aprendizado')
          .Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Clã =
      (fitCulturalEmpresa.arquetipos.Clã /
        culturaEmpresa.find((element) => element.Tipo === 'Clã').Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Inovação =
      (fitCulturalEmpresa.arquetipos.Inovação /
        culturaEmpresa.find((element) => element.Tipo === 'Inovação').Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Empreendedora =
      (fitCulturalEmpresa.arquetipos.Empreendedora /
        culturaEmpresa.find((element) => element.Tipo === 'Empreendedora')
          .Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Autoridade =
      (fitCulturalEmpresa.arquetipos.Autoridade /
        culturaEmpresa.find((element) => element.Tipo === 'Autoridade').Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Colaboração =
      (fitCulturalEmpresa.arquetipos.Colaboração /
        culturaEmpresa.find((element) => element.Tipo === 'Colaboração')
          .Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Regra =
      (fitCulturalEmpresa.arquetipos.Regra /
        culturaEmpresa.find((element) => element.Tipo === 'Regra').Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Complacência =
      (fitCulturalEmpresa.arquetipos.Complacência /
        culturaEmpresa.find((element) => element.Tipo === 'Complacência')
          .Valor) *
      100

    fitCulturalEmpresa.fitArquetipos.Aprendizado = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Aprendizado
    )
    fitCulturalEmpresa.fitArquetipos.Clã = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Clã
    )
    fitCulturalEmpresa.fitArquetipos.Inovação = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Inovação
    )
    fitCulturalEmpresa.fitArquetipos.Empreendedora = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Empreendedora
    )
    fitCulturalEmpresa.fitArquetipos.Autoridade = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Autoridade
    )
    fitCulturalEmpresa.fitArquetipos.Colaboração = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Colaboração
    )
    fitCulturalEmpresa.fitArquetipos.Regra = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Regra
    )
    fitCulturalEmpresa.fitArquetipos.Complacência = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Complacência
    )
  }

  function verificarSeMaiorQueCem(valor) {
    if (valor > 100) return 100
    return valor.toFixed(2)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const culturaDesejadaEmpresa = culturaEmpresa
    ? culturaEmpresa.filter((c) => c.Label === 'Desejado')
    : []

  const fitCulturalEmpresaData = cultura
    ? [...cultura, ...culturaDesejadaEmpresa]
    : null

  for (const p of perfilComp) {
    switch (p.item) {
      case 'Dominância':
        perfil.D[p.type] = p.score
        break
      case 'Influência':
        perfil.I[p.type] = p.score
        break
      case 'Estabilidade':
        perfil.S[p.type] = p.score
        break
      case 'Conformidade':
        perfil.C[p.type] = p.score
        break
      default:
        break
    }
  }
  const { vetor: vetorComposicao, formulario } = principal

  // DATA NIVO
  // const discRadar = [];
  const discGrouped = []

  for (const d of disc) {
    // DATA NIVO
    /*
		let addToDiscRadar = false;
		let objDiscRadar = discRadar.find((o) => o.Tipo === d.Tipo);
		if (!objDiscRadar) {
			objDiscRadar = {};
			addToDiscRadar = true;
		}
		objDiscRadar.Tipo = d.Tipo;
		objDiscRadar[d.Label] = d.Valor;
		if (addToDiscRadar) discRadar.push(objDiscRadar);
		*/

    // DATA BIZCHARTS
    let addToDiscGrouped = false
    let objDiscGrouped = discGrouped.find((o) => o.row === d.Label)
    if (!objDiscGrouped) {
      objDiscGrouped = {}
      addToDiscGrouped = true
    }
    objDiscGrouped.row = d.Label
    objDiscGrouped[d.Tipo] = d.Valor
    if (addToDiscGrouped) discGrouped.push(objDiscGrouped)
  }

  const ds = new DataSet()

  const dvDisc = ds.createView().source(discGrouped)
  dvDisc.transform({
    type: 'fold',
    fields: ['Dominância', 'Influência', 'Estabilidade', 'Conformidade'],
    key: 'key',
    value: 'value',
  })

  const messageObservacao = useMemo(() => {
    if (formulario === '1') {
      return 'Esse relatório é baseado na essência do candidato!'
    }
    return 'Esse relatório é baseado na entrega do colaborador!'
  }, [formulario])

  const dvPerfis = perfisQtde
    ? ds
        .createView()
        .source(perfisQtde)
        .transform({
          type: 'sort',
          callback(a, b) {
            return a.qtde - b.qtde > 0
          },
        })
    : null

  /*
	// PARSE DATA CHART NIVO
	const vetoresParsedNivo = [];

	if (vetores) {
		for (const v of vetores) {
			let obj = vetoresParsedNivo.find((o) => o.id === v.type);

			if (!obj) {
				obj = {
					id: v.type,
					data: [],
				};

				obj.data.push({ x: v.log_data, y: v.value });

				vetoresParsedNivo.push(obj);
			} else {
				obj.data.push({ x: v.log_data, y: v.value });
			}
		}
	}
	*/

  const IndicadoresCard = ({ bodyStyle = {} }) => {
    return (
      <Row style={{ maxWidth: window.innerWidth <= 1600 ? '100%' : null }}>
        <Col xs={24} sm={24} style={noPadding}>
          <ColFill xs={24} sm={24} style={{ ...noPadding }}>
            <Card
              title={
                <FlexTitle>
                  <span>
                    Tipo:{' '}
                    {parseInt(formulario)
                      ? parseInt(formulario) === 3
                        ? 'Entrega'
                        : parseInt(formulario) === 2
                        ? 'Exigido'
                        : 'Essência'
                      : ''}
                  </span>
                  <Divider type="vertical" />
                  <span>Perfil: {vetorComposicao}</span>
                </FlexTitle>
              }
              headStyle={cardHeadBlue}
            >
              <RateContainer>
                <Label>Obtenção de Energia</Label>

                <RateSpan>{ode.maior.nome}</RateSpan>
                <Progress
                  percent={parseFloat(ode.maior.score)}
                  strokeColor={purple}
                  type="line"
                  status="normal"
                />

                <RateSpan>{ode.menor.nome}</RateSpan>
                <Progress
                  percent={parseFloat(ode.menor.score)}
                  strokeColor={purple}
                  type="line"
                  status="normal"
                />
              </RateContainer>
              <Divider64 />
              <RateContainer>
                <Label>Orientação</Label>
                <RateSpan>{oct.maior.nome}</RateSpan>
                <Progress
                  percent={parseFloat(oct.maior.score)}
                  strokeColor={purple}
                  type="line"
                  status="normal"
                />

                <RateSpan>{oct.menor.nome}</RateSpan>
                <Progress
                  percent={parseFloat(oct.menor.score)}
                  strokeColor={purple}
                  type="line"
                  status="normal"
                />
              </RateContainer>
              <Divider64 />
              <RateContainer>
                <Label>Tomada de Decisão</Label>

                <RateSpan>{tdd.maior.nome}</RateSpan>
                <Progress
                  percent={parseFloat(tdd.maior.score)}
                  strokeColor={purple}
                  type="line"
                  status="normal"
                />

                <RateSpan>{tdd.menor.nome}</RateSpan>
                <Progress
                  percent={parseFloat(tdd.menor.score)}
                  strokeColor={purple}
                  type="line"
                  status="normal"
                />
              </RateContainer>
            </Card>
          </ColFill>
        </Col>
      </Row>
    )
  }

  const obsDuracaoPesquisa = useMemo(() => {
    if (!minutosPesquisa || !minutosPesquisa.tempo) return null

    const [hour, minute, second] = minutosPesquisa.tempo.split(':')

    const hourParsed = Number(hour)
    const minuteParsed = Number(minute)
    const secondParsed = Number(second)

    if (hourParsed > 0 || minuteParsed < 4 || minuteParsed > 11) {
      let text = `As respostas para este relatório foram realizadas em: `
      const details = `. Para melhor confiabilidade nas respostas deste relatório, consideramos o intervalo de 4 a 11 minutos.`

      if (hourParsed > 0) {
        text = `${text} ${hourParsed} hora${hourParsed > 0 ? 's' : ''} e `
      }

      if (minuteParsed > 0 || hourParsed) {
        text = `${text}${minuteParsed} minuto${
          minuteParsed === 0 || minuteParsed > 1 ? 's' : ''
        } e `
      }

      text = `${text}${secondParsed} segundo${
        secondParsed === 0 || secondParsed > 1 ? 's' : ''
      }`

      text = `${text}${details}`

      return text
    }
    return null
  }, [minutosPesquisa])

  const hasObservations = useMemo(() => {
    return !!obsDuracaoPesquisa
  }, [obsDuracaoPesquisa])

  return (
    <>
      <FullCol sm={24} xs={24} className="flex-row-xxl-col">
        <Card
          title={
            pes_nome
              ? `Perfil Comportamental - ${pes_nome}`
              : 'Perfil Comportamental'
          }
          id="bizcharts-container"
          headStyle={cardHeadBlue}
        >
          <Chart height={5} data={discInstalado} autoFit>
            <Legend />
            <Axis name="Label" />
            <Axis name="Valor" />
            <Tooltip />
            <Interval
              position="Label*Valor"
              color={['Tipo', colors]}
              adjust={[
                {
                  type: 'dodge',
                  marginRatio: 1 / 32,
                },
              ]}
            />
          </Chart>
          <FlexRow>
            {(!showHistoricoDisc || !type === 'colaborador') && (
              <Link16 onClick={handleSwitchGraficoRadar}>
                Veja o{' '}
                {showGraficoRadar ? 'gráfico de barras' : 'gráfico de Radar'}
              </Link16>
            )}

            {type === 'colaborador' && handleSwitchHistoricoDisc && (
              <Link16
                style={{ marginLeft: 'auto' }}
                onClick={handleSwitchHistoricoDisc}
              >
                Veja o {showHistoricoDisc ? 'Disc Atual' : 'Histórico do DISC'}
              </Link16>
            )}
          </FlexRow>
          <Row style={{ marginLeft: -20 }}>
            {type === 'colaborador' && showHistoricoDisc ? (
              vetores.length === 0 ? (
                <Empty description={false} />
              ) : (
                /*	<ResponsiveLine
											data={vetoresParsedNivo}
											pointLabel='y'
											height={400}
											topLegend='Evolução do Perfil Comportamental'
											leftLegend='Valores'
										/>
										*/
                <Chart
                  data={vetores}
                  height={400}
                  scale={{ value: { ticks: [0, 20, 40, 60, 80, 100] } }}
                  autoFit
                >
                  <Axis name="log_data" />
                  <Axis name="value" />
                  <Legend />
                  <Tooltip />
                  <Line position="log_data*value" color={['type', colors]} />
                </Chart>
              )
            ) : showGraficoRadar ? (
              disc.length === 0 ? (
                <Empty description={false} />
              ) : (
                <Chart height={400} data={disc} scale={discRange} autoFit>
                  <Legend />
                  <Coordinate type="polar" radius={0.8} />
                  <Axis
                    name="Tipo"
                    line={null}
                    tickLine={null}
                    grid={{
                      lineStyle: {
                        lineDash: null,
                      },
                      hideFirstLine: false,
                    }}
                  />
                  <Tooltip />
                  <Axis
                    name="Valor"
                    line={null}
                    tickLine={null}
                    grid={{
                      type: 'polygon',
                      lineStyle: {
                        lineDash: null,
                      },
                    }}
                  />
                  <Line
                    position="Tipo*Valor"
                    size={2}
                    color={['Label', colors]}
                  />
                  <Point
                    position="Tipo*Valor"
                    shape="circle"
                    size={4}
                    style={{
                      stroke: '#fff',
                      lineWidth: 1,
                      fillOpacity: 1,
                    }}
                    color={['Label', colors]}
                  />
                </Chart>

                /* <ResponsiveRadar
											height={400}
											data={discRadar}
											indexBy={'Tipo'}
											keys={['Essência', 'Exigido', 'Entrega']}
										/> */
              )
            ) : discGrouped.length > 0 ? (
              /*	<ResponsiveBar
									height={400}
									data={discRadar}
									indexBy={'Tipo'}
									keys={['Essência', 'Exigido', 'Entrega']}
									groupMode='grouped'
									bottomLegend='Perfis'
									leftLegend='Valores'
								/>
								*/
              <Chart pure height={400} data={dvDisc} autoFit renderer="svg">
                <Axis name="key" />
                <Axis name="value" />
                <Legend />
                <Tooltip />
                <Interval
                  position="key*value"
                  color={['row', colors]}
                  adjust={[
                    {
                      type: 'dodge',
                      marginRatio: 1 / 32,
                    },
                  ]}
                />
              </Chart>
            ) : (
              <Empty />
            )}
            <Row justify="end">
              <TooltipAnt title="Ampliar gráfico">
                <a onClick={showModal}>
                  <FullscreenOutlined />
                </a>
              </TooltipAnt>
            </Row>
          </Row>

          <Modal
            title="VISUALIZAR GRÁFICO DE PERFIL COMPORTAMENTAL"
            centered
            width={900}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <TransformWrapper>
              <TransformComponent>
                <Col
                  style={{
                    flexGrow: '1 1 auto',
                    display: 'flex',
                  }}
                  justify="center"
                  height={500}
                  width={875}
                >
                  {type === 'colaborador' && showHistoricoDisc ? (
                    vetores.length === 0 ? (
                      <Empty description={false} />
                    ) : (
                      <Chart
                        renderer="svg"
                        data={vetores}
                        scale={{
                          value: { ticks: [0, 20, 40, 60, 80, 100] },
                        }}
                        autoFit
                        height={500}
                        width={836}
                      >
                        <Axis name="log_data" />
                        <Axis name="value" />
                        <Legend />
                        <Tooltip />
                        <Line
                          position="log_data*value"
                          color={['type', colors]}
                        />
                      </Chart>
                    )
                  ) : showGraficoRadar ? (
                    disc.length === 0 ? (
                      <Empty description={false} />
                    ) : (
                      <Chart
                        renderer="svg"
                        height={500}
                        width={836}
                        data={disc}
                        scale={discRange}
                        autoFit
                      >
                        <Legend />
                        <Coordinate type="polar" radius={0.8} />
                        <Axis
                          name="Tipo"
                          line={null}
                          tickLine={null}
                          grid={{
                            lineStyle: {
                              lineDash: null,
                            },
                            hideFirstLine: false,
                          }}
                        />
                        <Tooltip />
                        <Axis
                          name="Valor"
                          line={null}
                          tickLine={null}
                          grid={{
                            type: 'polygon',
                            lineStyle: {
                              lineDash: null,
                            },
                          }}
                        />
                        <Line
                          position="Tipo*Valor"
                          size={2}
                          color={['Label', colors]}
                        />
                        <Point
                          position="Tipo*Valor"
                          shape="circle"
                          size={4}
                          style={{
                            stroke: '#fff',
                            lineWidth: 1,
                            fillOpacity: 1,
                          }}
                          color={['Label', colors]}
                        />
                      </Chart>
                    )
                  ) : discGrouped.length > 0 ? (
                    <Chart
                      pure
                      height={500}
                      width={836}
                      data={dvDisc}
                      autoFit
                      renderer="svg"
                    >
                      <Axis name="key" />
                      <Axis name="value" />
                      <Legend />
                      <Tooltip />
                      <Interval
                        position="key*value"
                        color={['row', colors]}
                        adjust={[
                          {
                            type: 'dodge',
                            marginRatio: 1 / 32,
                          },
                        ]}
                      />
                    </Chart>
                  ) : (
                    <Empty />
                  )}
                </Col>
              </TransformComponent>
            </TransformWrapper>
          </Modal>

          <Row>
            <Col sm={24} xs={24}>
              <Divider />
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24}>
              {cor.length === 0 ? (
                <Empty description={false} />
              ) : (
                <>
                  <Row>
                    <Col xs={24} sm={24}>
                      <Label>Competências Organizacionais</Label>
                    </Col>

                    <Row gutter={24} type="flex" style={{ width: '100%' }}>
                      {cor.map(({ nome, selected }) => (
                        <Col sm={6} xs={12} key={nome}>
                          <Card
                            className={`visao-individual__card--caracteristicas p8 ${
                              selected && 'visao-individual__card--selected'
                            }`}
                          >
                            {nome}
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Row>

                  <RowChars>
                    <Col xs={24} sm={24}>
                      <Label>Resolução de Conflitos</Label>
                    </Col>

                    <Row gutter={24} type="flex" style={{ width: '100%' }}>
                      {erc.map(({ nome, selected }) => (
                        <Col sm={6} xs={12} key={nome}>
                          <Card
                            className={`visao-individual__card--caracteristicas p8 ${
                              selected && 'visao-individual__card--selected'
                            }`}
                          >
                            {nome}
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </RowChars>

                  <RowChars>
                    <Col xs={24} sm={24}>
                      <Label>Estilos de Comunicação</Label>
                    </Col>

                    <Row gutter={24} type="flex" style={{ width: '100%' }}>
                      {edc.map(({ nome, selected }) => (
                        <Col sm={6} xs={12} key={nome}>
                          <Card
                            className={`visao-individual__card--caracteristicas p8 ${
                              selected && 'visao-individual__card--selected'
                            }`}
                          >
                            {nome}
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </RowChars>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={24} xs={24}>
              <Divider />
            </Col>
          </Row>
          <Row>
            <Col sm={24} xs={24}>
              {rct.length > 0 ? (
                <ProgressContainer>
                  <ProgressTitle>Relação com o Tempo</ProgressTitle>
                  <ProgressValues>
                    <span>{`${rct[0].score}%`}</span>
                    <span>{`${rct[1].score}%`}</span>
                  </ProgressValues>
                  <Progress
                    success={{ percent: parseFloat(rct[0].score) }}
                    percent={100}
                    showInfo={false}
                    status="normal"
                  />
                  <ProgressLabels>
                    <span>{`${rct[0].nome}`}</span>
                    <span>{`${rct[1].nome}`}</span>
                  </ProgressLabels>
                </ProgressContainer>
              ) : (
                <Empty />
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={24} xs={24}>
              <Divider16 />
            </Col>
          </Row>

          <Row>
            <Col sm={24} xs={24}>
              {foc.length > 0 ? (
                <ProgressContainer>
                  <ProgressTitle>Foco</ProgressTitle>
                  <ProgressValues>
                    <span>{`${foc[0].score}%`}</span>
                    <span>{`${foc[1].score}%`}</span>
                  </ProgressValues>
                  <Progress
                    success={{ percent: parseFloat(foc[0].score) }}
                    percent={100}
                    showInfo={false}
                    status="normal"
                  />
                  <ProgressLabels>
                    <span>{`${foc[0].nome}`}</span>
                    <span>{`${foc[1].nome}`}</span>
                  </ProgressLabels>
                </ProgressContainer>
              ) : (
                <Empty />
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={24} xs={24}>
              <Divider16 />
            </Col>
          </Row>

          <Row>
            <Col sm={24} xs={24}>
              {edl.length > 0 ? (
                <ProgressContainer>
                  <ProgressTitle>Estilo de Liderança</ProgressTitle>
                  <ProgressValues>
                    <span>{`${edl[0].score}%`}</span>
                    <span>{`${edl[1].score}%`}</span>
                  </ProgressValues>
                  <Progress
                    success={{ percent: parseFloat(edl[0].score) }}
                    percent={100}
                    showInfo={false}
                    status="normal"
                  />
                  <ProgressLabels>
                    <span>{`${edl[0].nome}`}</span>
                    <span>{`${edl[1].nome}`}</span>
                  </ProgressLabels>
                </ProgressContainer>
              ) : (
                <Empty />
              )}
            </Col>
          </Row>
        </Card>
        <IndicadoresCard />
        <Col xs={24} sm={24} style={noPadding}>
          {type !== 'geral' && fitCulturalTypes.indexOf(type) >= 0 && (
            <ColFill xs={24} sm={24} style={{ ...noPadding }}>
              <Card
                style={{ height: 'auto' }}
                title="Essência x Exigido x Entrega"
                headStyle={cardHeadBlue}
              >
                <Row>
                  <Col sm={24} xs={24} lg={24} md={24}>
                    <Table
                      size="small"
                      columns={columns}
                      dataSource={intensidade}
                      bordered
                      pagination={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} xs={24}>
                    <Divider32 />
                  </Col>
                </Row>
                <Row>
                  <RateContainer>
                    <Label>Dominância</Label>

                    <RateSpan>Positivo</RateSpan>
                    <Progress
                      percent={parseFloat(perfil.D.Positivo)}
                      strokeColor={pink}
                      type="line"
                      status="normal"
                    />

                    <RateSpan>Negativo</RateSpan>
                    <Progress
                      percent={parseFloat(perfil.D.Negativo)}
                      strokeColor={pink}
                      type="line"
                      status="normal"
                    />
                  </RateContainer>
                  <Divider32 />
                  <RateContainer>
                    <Label>Influência</Label>

                    <RateSpan>Positivo</RateSpan>
                    <Progress
                      percent={parseFloat(perfil.I.Positivo)}
                      strokeColor={pink}
                      type="line"
                      status="normal"
                    />

                    <RateSpan>Negativo</RateSpan>
                    <Progress
                      percent={parseFloat(perfil.I.Negativo)}
                      strokeColor={pink}
                      type="line"
                      status="normal"
                    />
                  </RateContainer>
                  <Divider32 />
                  <RateContainer>
                    <Label>Estabilidade</Label>

                    <RateSpan>Positivo</RateSpan>
                    <Progress
                      percent={parseFloat(perfil.S.Positivo)}
                      strokeColor={pink}
                      type="line"
                      status="normal"
                    />

                    <RateSpan>Negativo</RateSpan>
                    <Progress
                      percent={parseFloat(perfil.S.Negativo)}
                      strokeColor={pink}
                      type="line"
                      status="normal"
                    />
                  </RateContainer>
                  <Divider32 />
                  <RateContainer>
                    <Label>Conformidade</Label>

                    <RateSpan>Positivo</RateSpan>
                    <Progress
                      percent={parseFloat(perfil.C.Positivo)}
                      strokeColor={pink}
                      type="line"
                      status="normal"
                    />

                    <RateSpan>Negativo</RateSpan>
                    <Progress
                      percent={parseFloat(perfil.C.Negativo)}
                      strokeColor={pink}
                      type="line"
                      status="normal"
                    />
                  </RateContainer>
                </Row>
              </Card>
            </ColFill>
          )}
          {type === 'geral' && (
            <ColFill xs={24} sm={24} style={{ ...noPadding }}>
              <CardFill
                title="Perfis x Qtde"
                headStyle={cardHeadBlue}
                flexlayout="column"
              >
                <Chart height={400} data={dvPerfis} autoFit>
                  <Coordinate transpose />
                  <Axis
                    name="perfil"
                    label={{
                      offset: 12,
                    }}
                  />
                  <Axis name="qtde" />
                  <Tooltip />
                  <Interval
                    position="perfil*qtde"
                    color={['perfil', colors]}
                    label="qtde"
                  />
                </Chart>
              </CardFill>
            </ColFill>
          )}
        </Col>
      </FullCol>
    </>
  )
}

export function PerfilComportamentalTextual({
  type,
  principal,
  cultura = [],
  culturaEmpresa = [],
  fitCulturalEmpresa,
}) {
  const culturaDesejadaEmpresa = culturaEmpresa
    ? culturaEmpresa.filter((c) => c.Label === 'Desejado')
    : []

  const fitCulturalEmpresaData = [...cultura, ...culturaDesejadaEmpresa]

  return (
    <FullCol
      xxl={{ span: 24, order: 2 }}
      sm={{ span: 24, order: 2 }}
      xs={{ span: 24, order: 2 }}
    >
      <Row>
        <Col span={24} style={noPadding}>
          <Textual principal={principal} gutter={16} />
        </Col>
      </Row>
    </FullCol>
  )
}
