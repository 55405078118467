import * as constants from '../constants'

export const getEstatisticas = () => {
  return {
    type: constants.GET_DASHBOARD_ESTATISTICAS,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_ESTATISTICAS,
    },
  }
}

export const getCulturas = () => {
  return {
    type: constants.GET_DASHBOARD_CULTURAS,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_CULTURAS,
    },
  }
}

export const getNps = () => {
  return {
    type: constants.GET_DASHBOARD_NPS,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_NPS,
    },
  }
}

export const getTopArq = () => {
  return {
    type: constants.GET_DASHBOARD_TOP_ARQ,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_TOP_ARQ,
    },
  }
}

export const getCulturaOrganizacional = () => {
  return {
    type: constants.GET_DASHBOARD_CULTURA_ORGANIZACIONAL,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_CULTURA_ORGANIZACIONAL,
    },
  }
}

export const getVetorGeral = () => {
  return {
    type: constants.GET_DASHBOARD_VETOR_GERAL,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_VETOR_GERAL,
    },
  }
}

export const getCandidatosCompleto = () => {
  return {
    type: constants.GET_DASHBOARD_CANDIDATO_COMPLETO,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_CANDIDATO_COMPLETO,
    },
  }
}

export const getCandidatos = () => {
  return {
    type: constants.GET_DASHBOARD_CANDIDATOS,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_CANDIDATOS,
    },
  }
}
