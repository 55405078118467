import { connect } from 'react-redux'

// Components
import EmailGestorController from './EmailGestorController'

// Actions
import {
  getQuestionarioGestor,
  getObjetivosQuestoes,
  getCulturaQuestoes,
  putQuestionarioGestor,
} from './actions/actionsEmailGestor'

const mapStateToProps = ({}) => {
  return {}
}

export default connect(mapStateToProps, {
  getQuestionarioGestor,
  putQuestionarioGestor,
  getCulturaQuestoes,
  getObjetivosQuestoes,
})(EmailGestorController)
