import * as constants from '../constants'

export const getGrupos = () => {
  return {
    type: constants.GET_DASHBOARD_GRUPOS,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_GRUPOS,
    },
  }
}

export const getMembros = (id) => {
  return {
    type: constants.GET_DASHBOARD_GRUPO_MEMBROS,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_GRUPO_MEMBROS + id,
    },
  }
}

export const getMembrosV2 = (id) => {
  return {
    type: constants.GET_DASHBOARD_GRUPO_MEMBROS,
    api: {
      method: 'get',
      url: `/v2/dashboard/grupo/membros/` + id,
    },
  }
}

export const getNps = (id) => {
  return {
    type: constants.GET_DASHBOARD_NPS,
    api: {
      method: 'get',
      url: `/relatorios/grupo/${id}/nps`,
    },
  }
}

export const getColaboradores = (id) => {
  return {
    type: constants.GET_DASHBOARD_GRUPO_COLABORADORES,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_GRUPO_COLABORADORES + id,
    },
  }
}

export const getCandidatos = () => {
  return {
    type: constants.GET_DASHBOARD_CANDIDATOS,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_CANDIDATOS,
    },
  }
}

export const getCultura = (id, { pessoasExcluidas, pessoasIncluidas } = {}) => {
  return {
    type: constants.GET_DASHBOARD_GRUPO_CULTURA,
    api: {
      method: 'post',
      url: constants.URL_DASHBOARD_GRUPO_CULTURA + id,
      data: {
        pessoasExcluidas: pessoasExcluidas || [],
        pessoasIncluidas: pessoasIncluidas || [],
      },
    },
  }
}

export const getStatistics = (id) => {
  return {
    type: constants.GET_DASHBOARD_GRUPO_STATISTICS,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_GRUPO_STATISTICS + id,
    },
  }
}

export const getCulturaOrganizacional = (id) => {
  return {
    type: constants.GET_DASHBOARD_CULTURA_ORGANIZACIONAL_GRUPO,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_CULTURA_ORGANIZACIONAL_GRUPO + id,
    },
  }
}

export const getVetor = (id) => {
  return {
    type: constants.GET_DASHBOARD_VETOR_GRUPO,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_VETOR_GRUPO + id,
    },
  }
}

export const getCulturaPessoa = (id) => {
  return {
    type: constants.GET_DASHBOARD_CULTURA_PESSOA,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_CULTURA_PESSOA + id,
    },
    pes_id: id,
  }
}
