import React from 'react'

// Components
import AddPessoa from '../components/AddPessoa'

// URLs

// Geral
export const URL_DASHBOARD = `${process.env.REACT_APP_API_URL}/dashboard`
export const URL_DASHBOARD_ESTATISTICAS = `${URL_DASHBOARD}/statistics`
export const URL_DASHBOARD_CULTURAS = `${URL_DASHBOARD}/culturas`
export const URL_DASHBOARD_CULTURA_PESSOA = `${URL_DASHBOARD}/pessoa/cultura/`
export const URL_DASHBOARD_TOP_ARQ = `${URL_DASHBOARD}/topArquetipos`
export const URL_DASHBOARD_CULTURA_ORGANIZACIONAL = `${URL_DASHBOARD}/culturaOrganizacional`
export const URL_DASHBOARD_VETOR_GERAL = `${URL_DASHBOARD}/vetor`

// Times
export const URL_DASHBOARD_GRUPOS = `${URL_DASHBOARD}/grupos`
export const URL_DASHBOARD_CANDIDATOS = `${URL_DASHBOARD}/candidatos`
export const URL_DASHBOARD_GRUPO = `${URL_DASHBOARD}/grupo`
export const URL_DASHBOARD_GRUPO_STATISTICS = `${URL_DASHBOARD_GRUPO}/statistics/`
export const URL_DASHBOARD_GRUPO_CULTURA = `${URL_DASHBOARD_GRUPO}/cultura/`
export const URL_DASHBOARD_GRUPO_MEMBROS = `${URL_DASHBOARD_GRUPO}/membros/`
export const URL_DASHBOARD_GRUPO_COLABORADORES = `${URL_DASHBOARD_GRUPO}/colaboradores/`
export const URL_DASHBOARD_CULTURA_ORGANIZACIONAL_GRUPO = `${URL_DASHBOARD}/grupo/culturaOrganizacional/`
export const URL_DASHBOARD_VETOR_GRUPO = `${URL_DASHBOARD}/grupo/vetor/`

// Individual
export const URL_DASHBOARD_VETOR_COMPLETO = `${process.env.REACT_APP_API_URL}/vetorCompleto/`
export const URL_DASHBOARD_VETOR = `${process.env.REACT_APP_API_URL}/vetor/`
export const URL_COLABORADORES = `${process.env.REACT_APP_API_URL}/pessoas`
export const URL_GET_DASHBOARD_COLABORADORES = `${URL_COLABORADORES}/list/1`
export const URL_DASHBOARD_HISTORICO_VETOR_INDIVIDUAL = (id) =>
  `${process.env.REACT_APP_API_URL}/vetor/historico/${id}`
export const URL_DASHBOARD_FIT_CULTURAL_EMPRESA_INDIVIDUAL = (id) =>
  `${process.env.REACT_APP_API_URL}/fitcultural/empresa/${id}`
export const URL_DASHBOARD_NEW_LINK = (pes_id, avalia_id) =>
  `${process.env.REACT_APP_API_URL}/share/vetor/pessoa/${pes_id}/${avalia_id}`

// CANDIDATO
export const URL_DASHBOARD_CANDIDATO_COMPLETO = `${process.env.REACT_APP_API_URL}/vetor/candidatos`

// Redux types

// Geral
export const GET_DASHBOARD_ESTATISTICAS = 'GET_DASHBOARD_ESTATISTICAS'
export const GET_DASHBOARD_CULTURAS = 'GET_DASHBOARD_CULTURAS'
export const GET_DASHBOARD_TOP_ARQ = 'GET_DASHBOARD_TOP_ARQ'
export const GET_DASHBOARD_CULTURA_ORGANIZACIONAL =
  'GET_DASHBOARD_CULTURA_ORGANIZACIONAL'
export const GET_DASHBOARD_VETOR_GERAL = 'GET_DASHBOARD_VETOR_GERAL'

// Times
export const GET_DASHBOARD_GRUPOS = 'GET_DASHBOARD_GRUPOS'
export const GET_DASHBOARD_CANDIDATOS = 'GET_DASHBOARD_CANDIDATOS'
export const GET_DASHBOARD_GRUPO_MEMBROS = 'GET_DASHBOARD_GRUPO_MEMBROS'
export const GET_DASHBOARD_GRUPO_COLABORADORES =
  'GET_DASHBOARD_GRUPO_COLABORADORES'
export const GET_DASHBOARD_GRUPO_CULTURA = 'GET_DASHBOARD_GRUPO_CULTURA'
export const GET_DASHBOARD_GRUPO_STATISTICS = 'GET_DASHBOARD_GRUPO_STATISTICS'
export const SET_PESSOA_ESCOLHIDA = 'SET_PESSOA_ESCOLHIDA'
export const REMOVE_PESSOA = 'REMOVE_PESSOA'
export const REMOVE_MEMBRO = 'REMOVE_MEMBRO'
export const SET_GRUPO_SELECIONADO = 'SET_GRUPO_SELECIONADO'
export const GET_DASHBOARD_CULTURA_PESSOA = 'GET_DASHBOARD_CULTURA_PESSOA'
export const GET_DASHBOARD_CULTURA_ORGANIZACIONAL_GRUPO =
  'GET_DASHBOARD_CULTURA_ORGANIZACIONAL_GRUPO'
export const GET_DASHBOARD_VETOR_GRUPO = 'GET_DASHBOARD_VETOR_GRUPO'
export const CHANGE_MEMBRO_VISIBILITY = 'CHANGE_MEMBRO_VISIBILITY'
export const INCLUDE_PESSOA_CULTURA_GRUPO = 'INCLUDE_PESSOA_CULTURA_GRUPO'
export const EXCLUDE_PESSOA_CULTURA_GRUPO = 'EXCLUDE_PESSOA_CULTURA_GRUPO'

// Individual
export const GET_DASHBOARD_VETOR_COMPLETO = 'GET_DASHBOARD_VETOR_COMPLETO'
export const GET_DASHBOARD_VETOR = 'GET_DASHBOARD_VETOR'
export const GET_DASHBOARD_COLABORADORES = 'GET_DASHBOARD_COLABORADORES'
export const SET_PESSOA_ESCOLHIDA_INDIVIDUAL = 'SET_PESSOA_ESCOLHIDA_INDIVIDUAL'
export const GET_DASHBOARD_CULTURA_PESSOA_INDIVIDUAL =
  'GET_DASHBOARD_CULTURA_PESSOA_INDIVIDUAL'
export const RESET_STATE_INDIVIDUAL = 'RESET_STATE_INDIVIDUAL'
export const RESET_WHOLE_STATE = 'RESET_WHOLE_STATE'
export const GET_DASHBOARD_HISTORICO_VETOR_INDIVIDUAL =
  'GET_DASHBOARD_HISTORICO_VETOR_INDIVIDUAL'
export const GET_DASHBOARD_FIT_CULTURAL_EMPRESA_INDIVIDUAL =
  'GET_DASHBOARD_FIT_CULTURAL_EMPRESA_INDIVIDUAL'
export const SET_DASHBOARD_SHOW_COLABORADORES_DESLIGADOS_INDIVIDUAL =
  'SET_DASHBOARD_SHOW_COLABORADORES_DESLIGADOS_INDIVIDUAL'
export const POST_DASHBOARD_NEW_LINK = 'POST_DASHBOARD_NEW_LINK'

// Candidato
export const GET_DASHBOARD_VETOR_COMPLETO_CANDIDATO =
  'GET_DASHBOARD_VETOR_COMPLETO_CANDIDATO'
export const GET_DASHBOARD_VETOR_CANDIDATO = 'GET_DASHBOARD_VETOR_CANDIDATO'
export const SET_PESSOA_ESCOLHIDA_CANDIDATO = 'SET_PESSOA_ESCOLHIDA_CANDIDATO'
export const GET_DASHBOARD_CULTURA_PESSOA_CANDIDATO =
  'GET_DASHBOARD_CULTURA_PESSOA_CANDIDATO'
export const RESET_STATE_CANDIDATO = 'RESET_STATE_CANDIDATO'
// export const RESET_WHOLE_STATE = 'RESET_WHOLE_STATE';
export const GET_DASHBOARD_AVALIACOES_CANDIDATOS =
  'GET_DASHBOARD_AVALIACOES_CANDIDATOS'
export const GET_DASHBOARD_FIT_CULTURAL_EMPRESA_CANDIDATO =
  'GET_DASHBOARD_FIT_CULTURAL_EMPRESA_CANDIDATO'
export const GET_DASHBOARD_CANDIDATO_COMPLETO =
  'GET_DASHBOARD_CANDIDATO_COMPLETO'
export const CHANGE_CANDIDATO_FILTROS = 'CHANGE_CANDIDATO_FILTROS'
export const CLEAR_CANDIDATO_FILTROS = 'CLEAR_CANDIDATO_FILTROS'
export const FILTRAR_CANDIDATOS = 'FILTRAR_CANDIDATOS'

// Utils
export const FORCAS = ['Mercado', 'Cliente', 'Operação', 'Ambiente interno']
export const OLHARES = ['Olhar externo', 'Olhar interno']
export const GESTAO = ['Controle', 'Flexibilidade']
export const DISC = ['D', 'I', 'S', 'C']
export const CULTURAS = [
  'Inovação',
  'Empreendedora',
  'Colaboração',
  'Clã',
  'Aprendizado',
  'Complacência',
  'Regra',
  'Autoridade',
]

// Modals
export const modals = [
  {
    id: 0,
    title: 'Adicionar Pessoa',
    cancelText: 'CANCELAR',
    okText: 'SALVAR',
    children: AddPessoa,
    type: 'colaborador',
    initialState: {
      pes_id: undefined,
    },
  },
  {
    id: 1,
    title: 'Adicionar Pessoa',
    cancelText: 'CANCELAR',
    okText: 'SALVAR',
    children: AddPessoa,
    type: 'candidato',
    initialState: {
      pes_id: undefined,
    },
  },
]

export const defaultVetorState = {
  vetor: {
    intensidade: [
      {
        row: '',
        essencia: '',
        exigido: '',
        entrega: '',
      },
    ],
    rct: [],
    edl: [],
    foc: [],
    ode: {
      maior: {
        nome: '',
        score: '',
      },
      menor: {
        nome: '',
        score: '',
      },
    },
    oct: {
      maior: {
        nome: '',
        score: '',
      },
      menor: {
        nome: '',
        score: '',
      },
    },
    tdd: {
      maior: {
        nome: '',
        score: '',
      },
      menor: {
        nome: '',
        score: '',
      },
    },
    cor: [],
    erc: [],
    edc: [],
    principal: {},
  },
}

export const culturaOrgDefaultState = {
  culturaOrg: {
    niveisConfianca: [],
    olharLideranca: {
      perceboLider: [],
      frequenciaFeedback: [],
      feedbackContribui: [],
      reconhecimento: [],
      expectativaEntregas: [],
    },
    olharParaMudancas: {
      pontosFortes: [],
      impedimento: [],
      principalProblema: [],
      engajamento: undefined,
      velocidadeAdocao: undefined,
      proficiencia: undefined,
    },
    olharParaSi: {
      escolhaEmpresa: [],
      prioridades: [],
      expectativas6meses: [],
      fonteEntusiasmo: [],
    },
    valoresEvidencia: [],
    satisfacaoGeral: [],
  },
}
