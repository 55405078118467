import { connect } from 'react-redux'
import * as actions from './actions/actionsObjetivosEstrategicos'

// Components
import ObjetivosEstrategicosController from './ObjetivosEstrategicosController'

// Actions

const mapStateToProps = ({ objetivosEstrategicos }) => {
  return {
    listaGestores: objetivosEstrategicos.listaGestores,
    listaPesos: objetivosEstrategicos.listaPesos,
    listaTimes: objetivosEstrategicos.listaTimes,
    listaFiliais: objetivosEstrategicos.listaFiliais,
    listaTimesPessoas: objetivosEstrategicos.listaTimesPessoas,
    forcasEstrategicas: objetivosEstrategicos.forcasEstrategicas,
    culturaDesejada: objetivosEstrategicos.culturaDesejada,
    gestao: objetivosEstrategicos.gestao,
    olhares: objetivosEstrategicos.olhares,
    objetivosEstrategicos: objetivosEstrategicos.objetivosEstrategicos,
    listaObjetivosTeamhub: objetivosEstrategicos.listaObjetivosTeamhub,
    listColaboradores: objetivosEstrategicos.listColaboradores,
    culturaConsensuada: objetivosEstrategicos.culturaConsensuada,
    limites: objetivosEstrategicos.limites,
  }
}

export default connect(mapStateToProps, { ...actions })(
  ObjetivosEstrategicosController
)
