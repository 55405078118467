import * as constants from '../constants/perfilComportamentalConstants'

export const getVetor = () => {
  return {
    type: constants.GET_PERFILCOMPORTAMENTAL_GERAL_VETOR,
    api: {
      method: 'get',
      url: constants.URL_PERFILCOMPORTAMENTAL_GERAL_VETOR,
    },
  }
}

export const getPerfis = () => {
  return {
    type: constants.GET_PERFILCOMPORTAMENTAL_GERAL_PERFIS,
    api: {
      method: 'get',
      url: constants.URL_PERFILCOMPORTAMENTAL_GERAL_PERFIS,
    },
  }
}
