import React, { useState, useCallback, useEffect, useMemo } from 'react'

import { useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Card,
  Tabs,
  Modal,
  Popconfirm,
  Tooltip,
  Badge,
  Divider,
  Table,
} from 'antd'

import {
  ExclamationCircleFilled,
  EditOutlined,
  PoweroffOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import moment from 'moment'

import { sorterString, sorterDate } from '../../utils/utils'
import { getContaURL } from '../../services/auth'
import history from '../../utils/history'

import {
  getTemplates,
  updateTemplate,
  getAnexosTemplatesAutomaticos,
  storeAnexoAutomaticoEmailTemplate,
  deleteAnexoAutomaticoEmailTemplate,
} from './actions'

import { listColaboradores } from '../Usuarios/actions'

// components
import EditarTemplateEmailAutomatico from './components/EditarTemplateEmailAutomatico'

const { TabPane } = Tabs

function ConfigProcessoSeletivo() {
  const dispatch = useDispatch()

  const [colaboradores, setColaboradores] = useState([])

  const [templates, setTemplates] = useState([])

  /* Parâmetros para emails */
  const [parametros, setParametros] = useState([])

  /* Anexos automáticos */
  const [anexosTemplatesAutomaticos, setAnexosTemplatesAutomaticos] = useState(
    []
  )

  /* Udpate Email Automatico */
  const [currentEmailAutomaticoId, setCurrentEmailAutomaticoId] = useState()
  const [showModalUpdateEmailAutomatico, setShowModalUpdateEmailAutomatico] =
    useState(false)

  /* Status do e-mail automático que será editado */
  const [statusCurrentEmailAutomatico, setStatusCurrentEmailAutomatico] =
    useState()

  /* . */
  const loadColaboradores = useCallback(() => {
    dispatch(listColaboradores()).then(({ response }) => {
      if (response.status === 200) {
        const { pessoas } = response.data
        setColaboradores(pessoas)
      }
    })
  }, [dispatch])

  /* Armazenar templates dos e-mails automáticos */
  const loadTemplates = useCallback(() => {
    dispatch(getTemplates()).then(({ response }) => {
      if (response.status === 200) {
        setTemplates(response.data)
      }
    })
  }, [dispatch])

  /* Armazenar anexos dos templates de e-mails automáticos */
  const loadAnexosTemplatesAutomaticos = useCallback(() => {
    dispatch(getAnexosTemplatesAutomaticos()).then(({ response }) => {
      if (response.status === 200) {
        setAnexosTemplatesAutomaticos(response.data)
      }
    })
  }, [dispatch])

  useEffect(() => {
    loadColaboradores()
    loadTemplates()
    loadAnexosTemplatesAutomaticos()
  }, [loadColaboradores, loadTemplates, loadAnexosTemplatesAutomaticos])

  const handleCloseUpdateEmailAutomaticoTemplate = useCallback(() => {
    setShowModalUpdateEmailAutomatico(false)
  }, [])

  const usuariosOptions = useMemo(() => {
    return colaboradores.map((colaborador) => {
      return {
        label: colaborador.pes_nome,
        value: colaborador.pes_id,
      }
    })
  }, [colaboradores])

  /* Para o modal de edição de e-mails automáticos - o arquivo já é salvo diretamente */
  const handleUploadAnexosEmailsAutomaticos = useCallback(
    (e) => {
      const newFiles = e.target.files
      const anexo = new FormData()

      for (const file of newFiles) {
        if (file.type !== 'application/pdf') {
          toast.error(
            'Arquivo inválido. Por favor, selecione apenas arquivos .pdf'
          )
        } else {
          anexo.append('anexo', file)
        }
      }

      dispatch(
        storeAnexoAutomaticoEmailTemplate(anexo, currentEmailAutomaticoId)
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Anexo adicionado com sucesso.')
          loadAnexosTemplatesAutomaticos()
        }
      })
    },
    [currentEmailAutomaticoId, dispatch, loadAnexosTemplatesAutomaticos]
  )

  /* Remover anexo e-mail automático */
  const handleDeleteAnexoAutomaticoEmailTemplate = useCallback(
    (id) => {
      dispatch(deleteAnexoAutomaticoEmailTemplate(id)).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Anexo excluído com sucesso.')
          loadAnexosTemplatesAutomaticos()
        }
      })
    },
    [dispatch, loadAnexosTemplatesAutomaticos]
  )

  /* Editar Email Automático */
  const handleInitUpdateEmailAutomaticoTemplate = useCallback(
    (id) => {
      setCurrentEmailAutomaticoId(id)
      const statusCurrentEmailAutomatico = templates.find(
        (template) => template.id === id
      )
      setStatusCurrentEmailAutomatico(statusCurrentEmailAutomatico.ativo)
      setShowModalUpdateEmailAutomatico(true)
    },
    [templates]
  )

  /* Obtendo informações de um Template de Email Automático Específico */
  const currentEmailAutomaticoTemplate = useMemo(() => {
    return templates.find((email) => email.id === currentEmailAutomaticoId)
  }, [currentEmailAutomaticoId, templates])

  /* Habilitar ou desabilitar e-mail automático */
  const handleAtivoEmailAutomatico = useCallback(
    (id) => {
      const newTemplate = templates.find((t) => t.id === id)

      const ativo = parseInt(newTemplate.ativo)

      if (ativo) {
        newTemplate.ativo = 0
      } else newTemplate.ativo = 1

      dispatch(
        updateTemplate(id, {
          textoemail: newTemplate.textoemail,
          ativo: newTemplate.ativo,
          assunto: newTemplate.assunto,
          cco: newTemplate.cco,
        })
      ).then(({ response }) => {
        if (response.status === 200) {
          loadTemplates()
        }
      })
    },
    [dispatch, templates, loadTemplates]
  )

  /* Salvando edição do template automatico */
  const handleUpdateEmailTemplateAutomatico = useCallback(
    (data) => {
      if (data.textoemail.level === undefined) {
        dispatch(
          updateTemplate(currentEmailAutomaticoId, {
            textoemail: data.textoemail,
            ativo: statusCurrentEmailAutomatico,
            cco: data.cco,
            assunto: data.assunto,
          })
        ).then(({ response }) => {
          if (response.status === 200) {
            toast.success('Template atualizado com sucesso!')
            handleCloseUpdateEmailAutomaticoTemplate()
            loadTemplates()
          }
        })
      } else {
        dispatch(
          updateTemplate(currentEmailAutomaticoId, {
            textoemail: data.textoemail.level.content,
            ativo: statusCurrentEmailAutomatico,
            cco: data.cco,
            assunto: data.assunto,
          })
        ).then(({ response }) => {
          if (response.status === 200) {
            toast.success('Template atualizado com sucesso!')
            handleCloseUpdateEmailAutomaticoTemplate()
            loadTemplates()
          }
        })
      }
    },
    [
      currentEmailAutomaticoId,
      statusCurrentEmailAutomatico,
      dispatch,
      handleCloseUpdateEmailAutomaticoTemplate,
      loadTemplates,
    ]
  )

  // Colunas para apresentação dos e-mails automáticos
  const columnsTemplatesAutomaticos = useMemo(
    () => [
      {
        title: 'Status',
        key: 'status',
        align: 'center',
        render: (text, record) => {
          return (
            <>
              {record.ativo ? (
                <Tooltip title="Ativo">
                  <Badge status="success" />
                </Tooltip>
              ) : (
                <Tooltip title="Inativo">
                  <Badge status="default" />
                </Tooltip>
              )}
            </>
          )
        },
      },
      {
        title: 'Assunto',
        dataIndex: 'assunto',
        width: '10%',
        sorter: (a, b) => sorterString(a, b, 'assunto'),
      },
      {
        title: 'Descrição',
        dataIndex: 'descricao',
        width: '15%',
        sorter: (a, b) => sorterString(a, b, 'descricao'),
        editable: true,
        align: 'center',
      },
      {
        title: 'Quantidade anexos',
        dataIndex: 'id',
        width: '10%',
        sorter: (a, b) => sorterString(a, b, 'id'),
        render: (text, record) => {
          const anexos = anexosTemplatesAutomaticos.filter(
            (item) => item.idemail === parseInt(text)
          )
          return <span>{anexos && anexos.length}</span>
        },
        editable: true,
        align: 'center',
      },
      {
        title: 'Criado em',

        width: '15%',
        sorter: (a, b) => sorterDate(a, b, 'createdAt'),
        render: (text, record) => {
          return moment(record.createdAt)
            .utc()
            .tz(moment.tz.guess())
            .format('DD/MM/YYYY (HH:mm)')
        },
        align: 'center',
      },
      {
        title: 'Alterado por',
        dataIndex: 'updatedBy',
        width: '15%',
        sorter: (a, b) => sorterString(a, b, 'updatedBy'),
        render: (text, record) => {
          const usuario = usuariosOptions.find(
            (usuario) => usuario.value === parseInt(text)
          )
          return <span>{usuario && usuario.label}</span>
        },
        align: 'center',
      },
      {
        title: 'Alterado em',
        dataIndex: 'updatedAt',
        width: '20%',
        sorter: (a, b) => sorterDate(a, b, 'updatedAt'),
        render: (text, record) => {
          return moment(record.updatedAt)
            .utc()
            .tz(moment.tz.guess())
            .format('DD/MM/YYYY (HH:mm)')
        },
        align: 'center',
      },
      {
        title: 'Ações',
        key: 'actions',
        render: (text, record) => {
          return (
            <>
              {parseInt(record.ativo) === 1 ? (
                <>
                  <Tooltip title="Editar">
                    <a>
                      <EditOutlined
                        onClick={() =>
                          handleInitUpdateEmailAutomaticoTemplate(record.id)
                        }
                      />
                    </a>
                  </Tooltip>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="Tem certeza que deseja desativar o template?"
                    onConfirm={() => handleAtivoEmailAutomatico(record.id)}
                    okText="Desativar"
                    cancelText="Cancelar"
                    icon={
                      <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                    }
                  >
                    <Tooltip title="Desativar template">
                      <a>
                        <PoweroffOutlined />
                      </a>
                    </Tooltip>
                  </Popconfirm>
                </>
              ) : (
                <Popconfirm
                  title="Tem certeza que deseja ativar o e-mail?"
                  onConfirm={() => handleAtivoEmailAutomatico(record.id)}
                  okText="Ativar"
                  cancelText="Cancelar"
                >
                  <Tooltip title="Ativar template">
                    <a>
                      <PoweroffOutlined />
                    </a>
                  </Tooltip>
                </Popconfirm>
              )}
            </>
          )
        },
      },
    ],
    [
      usuariosOptions,
      anexosTemplatesAutomaticos,
      handleInitUpdateEmailAutomaticoTemplate,
      handleAtivoEmailAutomatico,
    ]
  )

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Col span={24}>
            <Tabs
              defaultActiveKey="4"
              tabBarStyle={{
                marginBottom: '8px',
                paddingRight: '8px',
                paddingLeft: '8px',
              }}
              onTabClick={(tab) => {
                tab === '1' && history.push(`/${getContaURL()}/config/planos`)
                tab === '2' &&
                  history.push(`/${getContaURL()}/config/pesquisas`)
                tab === '3' && history.push(`/${getContaURL()}/config/usuarios`)
              }}
            >
              <TabPane tab={<span>Limites de Cadastro</span>} key="1" />
              <TabPane tab={<span>Pesquisas</span>} key="2" />
              <TabPane tab={<span>Usuários</span>} key="3" />
              <TabPane tab={<span>Processo Seletivo</span>} key="4">
                <Card ex>
                  <Col span={24}>
                    <Tabs
                      defaultActiveKey="1"
                      tabBarStyle={{
                        marginBottom: '8px',
                        paddingRight: '8px',
                        paddingLeft: '8px',
                      }}
                      onTabClick={(tab) => {
                        tab === '2' &&
                          history.push(
                            `/${getContaURL()}/config/processos-seletivos/email-manual`
                          )
                      }}
                    >
                      <TabPane
                        tab={<span>Envio de e-mails automático</span>}
                        key="1"
                      >
                        <Row gutter={12}>
                          {/* {templates.map((template) => (
                      <EditorCol key={template.id} xs={24} sm={24} xxl={12}>
                        <div className="editor-switch">
                          <Switch
                            checked={parseInt(template.ativo)}
                            onChange={() => handleToggleTemplate(template.id)}
                          />
                          <span>{template.descricao}</span>
                        </div>
                        <div className="editor-subject">
                          <span>Assunto do e-mail</span>
                          <Input
                            type="text"
                            onChange={(e) =>
                              handleChangeTemplateSubject({
                                id: template.id,
                                subject: e.target.value,
                              })
                            }
                            value={template.assunto}
                            disabled={!parseInt(template.ativo)}
                          />
                        </div>

                        <div className="editor-subject">
                          <span>CCO</span>
                          <Input
                            type="text"
                            onChange={(e) =>
                              handleChangeTemplateCco({
                                id: template.id,
                                cco: e.target.value,
                              })
                            }
                            value={template.cco}
                            placeholder="Para mais de um email, separe com ponto e vírgula. Ex: joao@email.com; maria@email.com"
                            disabled={!parseInt(template.ativo)}
                          />
                        </div>
                        <TinyEditor
                          value={template.textoemail}
                          onEditorChange={(content) =>
                            handleChangeContentState({
                              id: template.id,
                              content,
                            })
                          }
                          disabled={!parseInt(template.ativo)}
                        />
                        {template.params && template.params.length > 0 && (
                          <div className="editor-legend">
                            <ul>
                              {template.params.map((param) => (
                                <li key={param.id}>
                                  <strong>{`{${param.param}}`}</strong> -{" "}
                                  {param.legend}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </EditorCol>
                    ))} */}
                          <Table
                            style={{
                              width: '99%',
                            }}
                            dataSource={templates
                              .filter((anexo) => {
                                /* if (showInativos) {
                            return user;
                          } */
                                return anexo
                              })
                              .map((anexo) => ({ ...anexo, key: anexo.id }))}
                            key={(record) => record.id}
                            columns={columnsTemplatesAutomaticos}
                          />
                        </Row>
                        {/* <Row style={{ marginTop: 16 }}>
                    <Button
                      type="primary"
                      style={{ marginLeft: "auto" }}
                      onClick={() => handleSaveTemplates()}
                    >
                      Salvar Templates
                    </Button>
                  </Row> */}
                      </TabPane>

                      <TabPane
                        tab={<span>Envio de e-mail manual</span>}
                        key="2"
                      />
                    </Tabs>
                  </Col>
                </Card>
              </TabPane>
            </Tabs>
          </Col>
        </Col>
      </Row>

      <Modal
        title="EDITAR E-MAIL AUTOMÁTICO"
        visible={showModalUpdateEmailAutomatico}
        cancelText="CANCELAR"
        onCancel={() => handleCloseUpdateEmailAutomaticoTemplate()}
        okText="SALVAR"
        destroyOnClose
        width="75%"
        okButtonProps={{
          form: 'editar-email-automatico',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <EditarTemplateEmailAutomatico
          type="update"
          onFinish={handleUpdateEmailTemplateAutomatico}
          files={anexosTemplatesAutomaticos}
          initialValues={{
            ...currentEmailAutomaticoTemplate,
          }}
          handleRemoveFile={handleDeleteAnexoAutomaticoEmailTemplate}
          updateFile={handleUploadAnexosEmailsAutomaticos}
          currentId={currentEmailAutomaticoId}
          parametros={parametros}
        />
      </Modal>
    </Col>
  )
}

export default ConfigProcessoSeletivo
