import React, { useState, useCallback, useEffect } from 'react'

import { Alert, Col, Progress, Typography, Input, Button } from 'antd'

import { set } from 'lodash'

import { useDispatch } from 'react-redux'

import {
  ContainerIndicadorProgresso,
  ContainerQuestaoMain,
  ContainerAlternativas,
  Alternativa,
  Placeholder,
} from '../styles'

import { useTimeQuestion } from '../utils/hooks/useTimeQuestion'

const { Title } = Typography

const { TextArea } = Input

function Questao({
  preview,
  tipo,
  questao_id,
  questao,
  alternativas,
  handleProximaQuestao,
  tempoLimiteQuestao,
}) {
  const dispatch = useDispatch()

  const { tempoAcabou, tempoMaximo } = useTimeQuestion(tempoLimiteQuestao)

  const [respostaTextual, setRespostaTextual] = useState(null)

  const handleFimQuestao = useCallback(() => {
    if (tempoAcabou) handleProximaQuestao(true)
  }, [tempoAcabou, handleProximaQuestao])

  useEffect(() => {
    handleFimQuestao()
  }, [handleFimQuestao])

  const handleEnviarResposta = (questao_id, value) => {
    dispatch({ type: 'ABRACADABRA', data: { questao_id, value } })
  }

  return (
    <>
      <ContainerIndicadorProgresso>
        <Col xs={20} md={8}>
          <Progress
            percent={(tempoMaximo * 100) / tempoLimiteQuestao}
            showInfo={false}
          />
        </Col>
      </ContainerIndicadorProgresso>
      {preview && (
        <Placeholder
          style={{
            textAlign: 'center',
            display: 'inline-block',
            width: '100%',
          }}
        >
          Em todas as perguntas, uma barra de progresso irá indicar o tempo
          restante para responder.
        </Placeholder>
      )}

      <ContainerQuestaoMain>
        {questao ? (
          <Title level={3}>{questao}</Title>
        ) : (
          <Placeholder large>
            O enunciado da questão será exibido aqui
          </Placeholder>
        )}
        {preview ? (
          !tipo ? (
            <Alert
              type="info"
              message="Selecione o tipo de questão para visualizar o campo de resposta."
            />
          ) : tipo === 2 && !!alternativas ? (
            <ContainerAlternativas>
              {alternativas.map((item, index) => (
                <Alternativa key={index}>
                  {item.alternativa
                    ? item.alternativa
                    : 'A alternativa será inserida aqui'}
                </Alternativa>
              ))}
            </ContainerAlternativas>
          ) : (
            <ContainerAlternativas sm={22} md={20}>
              <TextArea
                rows={4}
                placeholder="O candidato utilizará esse campo para responder."
              />
              <div className="formulario__container__confirmar">
                <Button
                  size="large"
                  type="default"
                  className="formulario__textual__confirmar"
                  disabled
                >
                  Confirmar
                </Button>
              </div>
            </ContainerAlternativas>
          )
        ) : tipo === 'E' ? (
          <ContainerAlternativas>
            {alternativas.map((item, index) => (
              <Alternativa
                key={index}
                onClick={() =>
                  handleProximaQuestao(
                    false,
                    tipo,
                    questao_id,
                    item.alternativa_id
                  )
                }
              >
                {item.alternativa}
              </Alternativa>
            ))}
          </ContainerAlternativas>
        ) : (
          <ContainerAlternativas sm={22} md={20}>
            <TextArea
              rows={4}
              onChange={(e) => setRespostaTextual(e.target.value)}
            />
            <div className="formulario__container__confirmar">
              <Button
                size="large"
                type="default"
                className="formulario__textual__confirmar"
                onClick={() =>
                  handleProximaQuestao(false, tipo, questao_id, respostaTextual)
                }
              >
                Confirmar
              </Button>
            </div>
          </ContainerAlternativas>
        )}
      </ContainerQuestaoMain>
    </>
  )
}

export default Questao
