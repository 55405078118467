import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getCulturaOrganizacional,
  getCulturas,
  getTopArq,
} from './actions/analiseCulturaGeralActions'

import AnaliseCulturaGeralView from './AnaliseCulturaGeralView'

function AnaliseCulturaGeral() {
  const culturaOrganizacional = useSelector(
    (state) => state.analiseCulturaGeral.culturaOrganizacional
  )

  const cultura = useSelector((state) => state.analiseCulturaGeral.cultura)

  const forcas = useSelector((state) => state.analiseCulturaGeral.forcas)

  const disc = useSelector((state) => state.analiseCulturaGeral.disc)

  const olhares = useSelector((state) => state.analiseCulturaGeral.olhares)

  const gestao = useSelector((state) => state.analiseCulturaGeral.gestao)

  const generos = useSelector((state) => state.analiseCulturaGeral.generos)

  const geracoes = useSelector((state) => state.analiseCulturaGeral.geracoes)

  const destaques = useSelector((state) => state.analiseCulturaGeral.destaques)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCulturaOrganizacional())
    dispatch(getCulturas())
    dispatch(getTopArq())
  }, [dispatch])

  return (
    <AnaliseCulturaGeralView
      culturaOrganizacional={culturaOrganizacional}
      cultura={cultura}
      forcas={forcas}
      disc={disc}
      olhares={olhares}
      gestao={gestao}
      geracoes={geracoes}
      generos={generos}
      destaques={destaques}
    />
  )
}

export default AnaliseCulturaGeral
