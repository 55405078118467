import React from 'react'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import { FrownOutlined, SmileOutlined } from '@ant-design/icons'
import { Row, Col, Steps, Checkbox, Radio, Slider, Modal, Alert } from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// Components
import Container from '../../../../components/container/Container'
import Button from '../../../../components/button/Button'
import LegendCard from '../../../../components/legendCard/LegendCard'
import PesquisasPendentes from '../../../../components/PesquisasPendentes'
import Intro from './components/Intro'

// Services
import { getEmpresa } from '../../../../services/auth'

// CSS
import './PercepcaoAmbiente.css'
import { DragItem } from './styles'

// Constants
import { stepTitles } from './constants/constantsPesquisas'

// Assets
import { ReactComponent as Svg } from '../../../../assets/success.svg'

const { Step } = Steps

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: 5 * 2,
  margin: `0 0 5px 0`,

  background: isDragging ? '#dadada' : 'white',
  fontWeight: isDragging ? 'bold' : 'normal',

  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#f9f9f9' : 'white',
  padding: 5,
  width: '100%',
  border: '1px dashed #dadada',
})

function PercepcaoAmbienteView({
  intl,
  history,
  questoes,
  listaRespostas,
  currentStep,
  nextStep,
  prevStep,
  onDragEnd,
  onChangeTreeOrder,
  onChangeCheckbox,
  onChangeRadio,
  onChangeSlider,
  showModal,
  onSubmit,
  pesquisasPendentes = [],
  conta_url,
  showIntro,
  handleInit,
  countChecked,
}) {
  return (
    <div className="percepcao-ambiente">
      <div className="percepcao-ambiente__header">
        <div className="percepcao-ambiente__header__title">
          <span>
            <b>Pesquisa de Percepção do Ambiente</b>
          </span>
        </div>
        <span className="percepcao-ambiente__header__subtitle">
          Selecione as opções disponíveis nos cards abaixo.
        </span>
      </div>

      {showIntro && <Intro handleInit={handleInit} />}

      {!showIntro && (
        <Row>
          <Col xs={{ offset: 1, span: 22 }} md={{ offset: 2, span: 20 }}>
            <Container className="percepcao-ambiente__content">
              <Col xs={0} sm={0} lg={24}>
                <Steps
                  current={currentStep}
                  className="percepcao-ambiente__content__steps"
                  direction="horizontal"
                >
                  {questoes.map((step, index) => (
                    <Step key={index} title={`Passo ${index + 1}`} />
                  ))}
                </Steps>
              </Col>
              <Col xs={24} sm={24} lg={0}>
                <Steps
                  current={currentStep}
                  className="percepcao-ambiente__content__steps"
                  direction="horizontal"
                  size="small"
                >
                  {questoes.map((step, index) => (
                    <Step key={index} />
                  ))}
                </Steps>
              </Col>
              <hr />

              {questoes[currentStep].map((questao) =>
                questao.tipo === 'escala' ? (
                  <LegendCard title={questao.descricao}>
                    <Alert
                      type="warning"
                      message="Segure e arraste as opções abaixo para classificá-las. 1ª indica maior valor e 5ª indica menor valor."
                      closable
                    />
                    {listaRespostas.questoes.map(
                      (resposta, index) =>
                        resposta.id === questao.idquestao && (
                          <DragDropContext
                            onDragEnd={(result) =>
                              onDragEnd(result, resposta.id)
                            }
                            onDragStart={() => {
                              if (window.navigator.vibrate) {
                                window.navigator.vibrate(100)
                              }
                            }}
                          >
                            <Droppable droppableId={`droppable${resposta.id}`}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  style={getListStyle(snapshot.isDraggingOver)}
                                >
                                  {resposta.alternativas.map(
                                    (alternativa, index) => (
                                      <Draggable
                                        key={alternativa.key}
                                        draggableId={alternativa.key}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <DragItem>
                                              <span>{`${index + 1}ª `}</span>
                                              <p>{alternativa.title}</p>
                                            </DragItem>
                                          </div>
                                        )}
                                      </Draggable>
                                    )
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        )
                    )}
                  </LegendCard>
                ) : questao.tipo === 'caixa_selecao' ? (
                  <LegendCard
                    title={questao.descricao}
                    description="(Escolha até duas opções)"
                  >
                    <Row>
                      {questao.alternativas.map((alt) => (
                        <Col>
                          <Checkbox
                            className="percepcao-ambiente__content__option"
                            onChange={(evt) =>
                              onChangeCheckbox(
                                evt,
                                questao.idquestao,
                                alt.idalternativa
                              )
                            }
                            checked={
                              (listaRespostas.questoes.length > 0 &&
                                listaRespostas.questoes[
                                  _.findIndex(listaRespostas.questoes, [
                                    'id',
                                    questao.idquestao,
                                  ])
                                ].alternativas[alt.idalternativa]) ||
                              false
                            }
                            disabled={
                              (!(countChecked <= 2) &&
                                !(
                                  listaRespostas.questoes.length > 0 &&
                                  listaRespostas.questoes[
                                    _.findIndex(listaRespostas.questoes, [
                                      'id',
                                      questao.idquestao,
                                    ])
                                  ].alternativas[alt.idalternativa]
                                )) ||
                              false
                            }
                          >
                            {alt.texto}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </LegendCard>
                ) : (
                  questao.tipo === 'multipla_escolha' && (
                    <LegendCard
                      title={questao.descricao}
                      description="(Escolha uma opção)"
                    >
                      <Row>
                        <Radio.Group
                          onChange={(evt) =>
                            onChangeRadio(evt, questao.idquestao)
                          }
                          value={
                            (listaRespostas.questoes.length > 0 &&
                              listaRespostas.questoes[
                                _.findIndex(listaRespostas.questoes, [
                                  'id',
                                  questao.idquestao,
                                ])
                              ].valor) ||
                            1
                          }
                        >
                          {questao.alternativas.map((alt) => (
                            <Col>
                              <Radio
                                className="percepcao-ambiente__content__option"
                                value={alt.idalternativa}
                              >
                                {alt.texto}
                              </Radio>
                            </Col>
                          ))}
                        </Radio.Group>
                      </Row>
                    </LegendCard>
                  )
                )
              )}

              {questoes[currentStep].filter((elem) => elem.tipo === 'grade')
                .length > 0 && (
                <LegendCard title={stepTitles[currentStep]}>
                  {questoes[currentStep]
                    .filter((elem) => elem.tipo === 'grade')
                    .map((questao) => (
                      <Row className="percepcao-ambiente__content__grade">
                        <Col md={12} xs={24}>
                          <p>{questao.descricao}</p>
                        </Col>
                        <Col
                          md={12}
                          xs={24}
                          className="percepcao-ambiente__content__grade__slider"
                        >
                          <FrownOutlined />
                          <Slider
                            step={1}
                            marks={{
                              1: '',
                              2: '',
                              3: '',
                              4: '',
                              5: '',
                            }}
                            min={1}
                            max={5}
                            tipFormatter={(value) =>
                              questao.alternativas[value - 1].texto
                            }
                            onChange={(evt) =>
                              onChangeSlider(evt, questao.idquestao)
                            }
                            value={
                              (listaRespostas.questoes.length > 0 &&
                                listaRespostas.questoes[
                                  _.findIndex(listaRespostas.questoes, [
                                    'id',
                                    questao.idquestao,
                                  ])
                                ].valor) ||
                              1
                            }
                          />
                          <SmileOutlined />
                        </Col>
                      </Row>
                    ))}
                </LegendCard>
              )}

              <div className="percepcao-ambiente__footer">
                <div>
                  {currentStep > 0 && (
                    <Button color="orange" type="link" onClick={prevStep}>
                      VOLTAR
                    </Button>
                  )}
                </div>

                <div>
                  {currentStep + 1 < questoes.length && (
                    <Button color="orange" type="primary" onClick={nextStep}>
                      PRÓXIMO PASSO
                    </Button>
                  )}
                  {currentStep + 1 === questoes.length && (
                    <Button color="orange" type="primary" onClick={onSubmit}>
                      FINALIZAR
                    </Button>
                  )}
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      )}

      <Modal
        visible={showModal}
        closable={false}
        footer={null}
        width={860}
        className="percepcao-ambiente__modal"
      >
        <Svg className="percepcao-ambiente__modal__image" />
        <h1>Sua Resposta foi recebida com sucesso!</h1>

        {pesquisasPendentes.length > 0 && (
          <PesquisasPendentes
            pesquisas={pesquisasPendentes}
            conta_url={conta_url}
          />
        )}

        {pesquisasPendentes.length === 0 && (
          <Button
            color="orange"
            type="primary"
            onClick={() => history.push(`/${conta_url}`)}
          >
            VOLTAR PARA A HOME
          </Button>
        )}
      </Modal>
    </div>
  )
}

function shuffle(array) {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export default injectIntl(PercepcaoAmbienteView)
