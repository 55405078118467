import React from 'react'
import { Chart, Axis, Tooltip, Coordinate, Interval, Guide } from 'bizcharts'

function Donut({
  data,
  scale,
  handleChange,
  nameProp,
  score,
  nomeCenter,
  scoreCenter,
  nome,
  color,
}) {
  const { Html } = Guide
  return (
    <Chart
      padding={0}
      height={300}
      data={data}
      autoFit
      scale={scale}
      onPlotClick={(evt) => {
        evt.data &&
          handleChange({
            target: {
              name: nameProp,
              value: {
                nome: evt.data._origin.nome,
                score: evt.data._origin.score,
              },
            },
          })
      }}
    >
      <Tooltip showTitle={false} />
      <Coordinate type={'theta'} radius={0.75} innerRadius={0.6} />
      <Axis name={score} />
      <Guide>
        <Html
          position={['50%', '50%']}
          html={`<div class="infografico__donut-center__desc">${nomeCenter}<br><span class="infografico__donut-center__total">${scoreCenter}%</span></div>`}
          alignX="middle"
          alignY="middle"
        />
      </Guide>
      <Interval
        adjust="stack"
        position={score}
        color={color}
        tooltip={[
          `${nome}*${score}`,
          (nome, score) => {
            return {
              name: nome,
              value: score + '%',
            }
          },
        ]}
        style={{
          lineWidth: 1,
          stroke: '#fff',
        }}
        select={[
          true,
          {
            animate: true,
          },
        ]}
      />
    </Chart>
  )
}

export default Donut
