import * as Yup from 'yup'

export const schema = Yup.object().shape({
  titulo: Yup.string().matches(
    /^(?!\s+$).*/,
    'Insira um texto válido no campo de título.'
  ),
  objetivo: Yup.string().matches(
    /^(?!\s+$).*/,
    'Insira um texto válido no campo de objetivo.'
  ),
  instrucoes: Yup.string().matches(
    /^(?!\s+$).*/,
    'Insira um texto válido no campo de instruções.'
  ),
  msg_encerramento: Yup.string().matches(
    /^(?!\s+$).*/,
    'Insira um texto válido na mensagem de encerramento.'
  ),
  questoes: Yup.array().of(
    Yup.object().shape({
      questao: Yup.string().matches(
        /^(?!\s+$).*/,
        'Insira um texto válido no campo de enunciado de questão.'
      ),
      alternativas: Yup.array()
        .of(
          Yup.object().shape({
            alternativa: Yup.string().matches(
              /^(?!\s+$).*/,
              'Insira um texto válido no campo de alternativa.'
            ),
            resposta_certa: Yup.boolean(),
          })
        )
        .compact((v) => !v.checked),
    })
  ),
  alternativa: Yup.string().matches(/^(?!\s+$).*/),
})
