import * as constants from '../constants/constantsPDT'

export const listPDTs = (grupo_id) => {
  return {
    type: constants.LIST_PDTS_GRUPO,
    api: {
      method: 'get',
      url: constants.URL_LIST_PDTS_GRUPO(grupo_id),
    },
  }
}

export const getPessoaPDT = (grupo_id) => {
  return {
    type: constants.CHECK_GRUPO_PDT,
    api: {
      method: 'get',
      url: constants.URL_CHECK_GRUPO_PDT(grupo_id),
    },
  }
}

export const storePDT = (pdt_id, { objetivo }) => {
  return {
    type: constants.STORE_PDT,
    api: {
      method: 'post',
      url: constants.URL_STORE_PDT(pdt_id),
      data: {
        objetivo: objetivo,
      },
    },
    pdt_id,
  }
}

export const completePDT = (pdt_id) => {
  return {
    type: constants.COMPLETE_PDT,
    api: {
      method: 'put',
      url: constants.URL_COMPLETE_PDT(pdt_id),
    },
    pdt_id: pdt_id,
  }
}

export const updatePDT = (pdt_id, pdi) => {
  return {
    type: constants.UPDATE_PDT,
    api: {
      method: 'put',
      url: constants.URL_UPDATE_PDT(pdt_id),
      data: {
        ...pdi,
      },
    },
    pdt_id,
  }
}

export const deletePDT = (pdt_id) => {
  return {
    type: constants.DELETE_PDT,
    api: {
      method: 'delete',
      url: constants.URL_DELETE_PDT(pdt_id),
    },
    pdt_id,
  }
}

export const storeMeta = (pdt_id, meta) => {
  return {
    type: constants.STORE_META_PDT,
    api: {
      method: 'post',
      url: constants.URL_STORE_META_PDT(pdt_id),
      data: {
        ...meta,
      },
    },
    pdt_id,
  }
}

export const updateMeta = (pdt_id, { pdi_id, meta_id, descricao }) => {
  return {
    type: constants.UPDATE_META_PDT,
    api: {
      method: 'put',
      url: constants.URL_UPDATE_META_PDT(pdt_id, meta_id),
      data: {
        descricao,
      },
    },
    pdt_id,
    meta_id,
  }
}

export const deleteMeta = (pdt_id, meta_id) => {
  return {
    type: constants.DELETE_META_PDT,
    api: {
      method: 'delete',
      url: constants.URL_DELETE_META_PDT(pdt_id, meta_id),
    },
    pdt_id,
    meta_id,
  }
}

export const getAcao = (pdt_id, meta_id) => {
  return {
    type: constants.GET_ACAO_PDT,
    api: {
      method: 'get',
      url: constants.URL_GET_ACAO_PDT(pdt_id, meta_id),
    },
    pdt_id,
    meta_id,
  }
}

export const storeAcao = (pdt_id, meta_id, acao) => {
  return {
    type: constants.STORE_ACAO_PDT,
    api: {
      method: 'post',
      url: constants.URL_STORE_ACAO_PDT(pdt_id, meta_id),
      data: {
        ...acao,
      },
    },
    pdt_id,
    meta_id,
  }
}

export const completeAcao = (pdt_id, meta_id, acao_id) => {
  return {
    type: constants.COMPLETE_ACAO_PDT,
    api: {
      method: 'put',
      url: constants.URL_COMPLETE_ACAO_PDT(pdt_id, meta_id, acao_id),
    },
    pdt_id,
    meta_id,
    acao_id,
  }
}

export const updateAcao = (
  pdt_id,
  { meta_id, acao_id, descricao, start, end, sabotadores, opcoes }
) => {
  return {
    type: constants.UPDATE_ACAO_PDT,
    api: {
      method: 'put',
      url: constants.URL_UPDATE_ACAO_PDT(pdt_id, meta_id, acao_id),
      data: {
        descricao,
        start,
        end,
        sabotadores,
        opcoes,
      },
    },
    pdt_id,
    meta_id,
    acao_id,
  }
}

export const deleteAcao = (pdt_id, meta_id, acao_id) => {
  return {
    type: constants.DELETE_ACAO_PDT,
    api: {
      method: 'delete',
      url: constants.URL_DELETE_ACAO_PDT(pdt_id, meta_id, acao_id),
    },
    pdt_id,
    meta_id,
    acao_id,
  }
}

export const changePDTData = (pdt_id, data) => {
  return {
    type: constants.CHANGE_PDTS_DATA,
    data: {
      pdt_id,
      ...data,
    },
    pdt_id,
  }
}
