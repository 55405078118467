import React from 'react'

import { Form } from 'antd'

import Select, { Option } from '../../../../../../components/select/Select'

function CancelarProcesso({
  handleChangeMotivoCancelamento,
  motivosCancelamento,
  cancel_id,
  onFinish,
}) {
  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      initialValues={{ cancel_id }}
      id="cancelar-processo-submit"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        label="Selecione o motivo:"
        name="cancel_id"
        rules={[
          {
            required: true,
            message:
              'Por favor, selecione um motivo para o cancelamento do Processo Seletivo.',
          },
        ]}
      >
        <Select
          placeholder="Selecione"
          onChange={(option) => handleChangeMotivoCancelamento(option)}
          style={{ width: '100%' }}
          value={cancel_id}
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {motivosCancelamento.map(({ motivo, cancel_id }) => (
            <Option value={cancel_id} key={cancel_id}>
              {motivo}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}

export default CancelarProcesso
