import React from 'react'

import PerfilComportamentalGeralView from '../../../relatoriosGeral/perfilComportamental/PerfilComportamentalView'
import { Vetor } from '../../@types'

interface PerfilComportamentalProps {
  vetor: Vetor
  perfis: any[]
  showGraficoRadar: boolean
  handleSwitchGraficoRadar: () => Promise<void>
  discInstalado?: any
}

export default function PerfilComportamental({
  vetor,
  perfis,
  showGraficoRadar,
  handleSwitchGraficoRadar,
  discInstalado,
}: PerfilComportamentalProps): JSX.Element {
  return (
    <PerfilComportamentalGeralView
      {...{
        ...vetor,
        perfis,
        showGraficoRadar,
        handleSwitchGraficoRadar,
        discInstalado,
      }}
    />
  )
}
