import React from 'react'
import { Layout } from 'antd'

// Assets
import logo from '../../assets/logo.png'
import { ReactComponent as LogoOne } from '../../assets/logoOne.svg'

// Components

// CSS
import './Topbar.css'

function Topbar({ leftContent, rightContent }) {
  const { Header } = Layout

  return (
    <Header
      style={{ display: 'flex', justifyContent: 'center', padding: 15 }}
      className="topbar"
    >
      <LogoOne />
    </Header>
  )
}

export default Topbar
