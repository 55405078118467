import { message } from 'antd'
import _ from 'lodash'
import queryString from 'query-string'
import { Component } from 'react'
import { Redirect } from 'react-router-dom'
// Services
import { isAuthenticated, isLider, login } from '../../services/auth'
// Components
import LoginView from './LoginView'

class LoginController extends Component {
  state = {
    authenticated: false,
    email: '',
    senha: '',
    rememberMe: false,
    showModal: false,
    contas: [],
    contaSelecionada: 0,
    contaURL: undefined,
    isLoading: false,
    entryPoint: 'dashboard',
  }

  componentDidMount = () => {
    const { match, postConfirmarConta } = this.props

    if (isAuthenticated()) {
      this.setState({ authenticated: true })
    } else if (match.params.hash) {
      postConfirmarConta(match.params.hash).then(({ response }) => {
        if (response.status === 200) {
          message.success(response.data.message)
        }
      })
    } else if (match.params.empresa) {
      this.setState({ contaURL: match.params.empresa })
    }
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  handleChangeCheckbox = ({ target }) =>
    this.setState({ [target.name]: target.checked })

  handleChangeSelect = (value) => this.setState({ contaSelecionada: value })

  onSubmit = (event) => {
    const { openModal } = this
    const { postLogin } = this.props
    const { rememberMe, ...state } = this.state
    this.setState({ isLoading: true })

    event.preventDefault()
    postLogin(state)
      .then(({ response }) => {
        if (!response) {
          message.error(
            'Estamos temporariamente indisponiveis, volte daqui a alguns minutos'
          )
        } else if (response.status === 200) {
          this.setState({ contas: response.data.contas })

          if (response.data.contas.length === 1) {
            message.success('Login efetuado com sucesso')
            this.onSelectConta()
          } else openModal()
        } else if (response.status === 500) {
          message.error(
            'Estamos temporariamente indisponiveis, volte daqui a alguns minutos'
          )
        } else if (response.data.erros) {
          for (let i = 0; response.data.erros.length > i; i++) {
            message.error(response.data.erros[i].msg)
          }
        } else {
          message.error(response.data.message)
        }
        this.setState({ isLoading: false })
      })
      .catch((err) => {
        message.error(
          'Estamos temporariamente indisponiveis, volte daqui a alguns minutos'
        )
        this.setState({ isLoading: false })
      })
  }

  onSelectConta = () => {
    const { contas, contaSelecionada, rememberMe } = this.state
    const { history, saveOnboarding, postLogAcesso } = this.props

    login(contas[contaSelecionada].auth.token, rememberMe)
    saveOnboarding(contas[contaSelecionada].onboarding)
    postLogAcesso()

    if (this.props.location && this.props.location.search) {
      const queries = queryString.parse(this.props.location.search)

      if (queries.entryPoint) {
        this.setState({ entryPoint: queries.entryPoint, authenticated: true })
        return
      }
    }

    if (
      _.filter(contas[contaSelecionada].onboarding, (step) => step === 1)
        .length === 0
    ) {
      const url = isLider() ? 'visao-de-times' : 'visao-geral'
      history.push(
        `/${contas[contaSelecionada].auth.conta_url}/dashboard/${url}`
      )
    } else
      history.push(`/${contas[contaSelecionada].auth.conta_url}/onboarding`)
  }

  openModal = () => this.setState({ showModal: true })

  closeModal = () => this.setState({ showModal: false })

  render() {
    const {
      handleChange,
      handleChangeCheckbox,
      handleChangeSelect,
      onSubmit,
      onSelectConta,
      closeModal,
    } = this
    const { history } = this.props
    const {
      showModal,
      contas,
      contaSelecionada,
      authenticated,
      entryPoint,
      contaURL,
      isLoading,
    } = this.state

    return (
      <>
        {authenticated && (
          <Redirect
            to={{
              pathname,
              search: this.props.location.search,
              state: {
                from: this.props.location,
              },
            }}
          />
        )}
        <LoginView
          handleChange={handleChange}
          handleChangeCheckbox={handleChangeCheckbox}
          handleChangeSelect={handleChangeSelect}
          onSubmit={onSubmit}
          history={history}
          showModal={showModal}
          closeModal={closeModal}
          contas={contas}
          contaSelecionada={contaSelecionada}
          onSelectConta={onSelectConta}
          isLoading={isLoading}
        />
      </>
    )
  }
}

export default LoginController
