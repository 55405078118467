import styled from 'styled-components'

export const Label = styled.span`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
  display: block;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-top: 8px;

  * + * {
    margin-left: 8px;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    * {
      margin-left: 0px !important;
      width: 100%;
    }

    * + * {
      margin-top: 8px;
    }
  }
`

export const Container = styled.div`
  background-color: #fff;
  padding: 8px 24px 24px 24px;
  margin-top: 8px;
  border-radius: 5px;

  button + button {
    margin-left: 8px;
  }

  > .pdi-item {
    .ant-select {
      width: 100%;
    }

    & + .pdi-item {
      margin-top: 16px;
    }
  }

  @media screen and (max-width: 1000px) {
    button {
      margin-left: 0px !important;
      width: 100%;
    }
  }
`
