import styled, { css } from 'styled-components'

import { lighten } from 'polished'

import { Statistic as Stat, Space as Container } from 'antd'

export const ButtonContainer = styled.div`
  .ant-space,
  .ant-space-item,
  .ant-btn {
    width: 100%;
  }

  .ps__button__finalizar {
    color: green;
    border-color: green;
    :hover {
      color: ${lighten(0.1, 'green')};
      border-color: ${lighten(0.1, 'green')};
    }
  }
`

export const Statistic = styled(Stat)`
  .ant-statistic-content-value {
    font-size: 16px;
    font-weight: 500;
  }

  .ant-statistic-content-suffix {
    font-size: 16px;
  }
`

export const StatContainer = styled(Container)`
  width: 100%;
  justify-content: space-between;

  .ant-statistic-content-value {
    font-size: 16px;
    font-weight: 500;
  }

  .ant-statistic-content-suffix {
    font-size: 16px;
  }
`

export const InfoContainer = styled(Container)`
  width: 100%;
  justify-content: space-between;

  .ant-statistic-content-value {
    font-size: 16px;
    font-weight: normal;
  }

  .ant-statistic-content-suffix {
    font-size: 16px;
  }
`

export const Subtitle = styled.span`
  font-size: 14px;
  color: var(--orange);
  font-weight: 500;
`
