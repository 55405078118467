import * as constants from '../constants/analiseCulturaGeralConstants'
import { toast } from 'react-toastify'
import { parseCulturas } from '../../../../utils/utils'

const initialState = {
  cultura: [],
  forcas: [],
  disc: [],
  olhares: [],
  gestao: [],
  culturaOrganizacional: {
    niveisConfianca: [],
    olharLideranca: {
      perceboLider: [],
      frequenciaFeedback: [],
      feedbackContribui: [],
      reconhecimento: [],
      expectativaEntregas: [],
    },
    olharParaMudancas: {
      pontosFortes: [],
      impedimento: [],
      principalProblema: [],
    },
    olharParaSi: {
      escolhaEmpresa: [],
      prioridades: [],
      expectativas6meses: [],
      fonteEntusiasmo: [],
    },
    valoresEvidencia: [],
    satisfacaoGeral: [],
  },
  destaques: {
    Inovação: [],
    Empreendedora: [],
    Colaboração: [],
    Clã: [],
    Aprendizado: [],
    Complacência: [],
    Regra: [],
    Autoridade: [],
  },
  generos: [],
  geracoes: [],
}

export const analiseCulturaGeral = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ANALISECULTURA_GERAL_CULTURA_ORGANIZACIONAL: {
      if (action.response.status === 204) {
        return state
      }
      if (action.response.status !== 200) {
        toast.error('Erro ao obter Cultura Organizacional')
        return state
      }

      const { culturaOrganizacional } = action.response.data
      return {
        ...state,
        culturaOrganizacional,
      }
    }
    case constants.GET_ANALISECULTURA_GERAL_CULTURAS: {
      if (action.response.status === 204) {
        return state
      }
      if (action.response.status !== 200) {
        toast.error('Erro ao obter Culturas')
        return state
      }

      const { culturaDesejada, culturaInstalada } = action.response.data

      const { geracoes, generos } = culturaInstalada

      const { cultura, forcas, disc, olhares, gestao } = parseCulturas({
        culturaDesejada,
        culturaInstalada,
      })

      return {
        ...state,
        cultura,
        forcas,
        disc,
        olhares,
        gestao,
        geracoes,
        generos,
      }
    }
    default:
      return state

    case constants.GET_ANALISECULTURA_GERAL_TOP_ARQ: {
      const { destaques } = action.response.data
      if (action.response.status !== 200) {
        return state
      }

      if (Object.keys(destaques).length > 0)
        return {
          ...state,
          destaques,
        }

      return state
    }
  }
}
