import styled from 'styled-components'

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const NewColaboradorRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > span + .ant-btn {
    margin-left: 16px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;

    > span + .ant-btn {
      margin: 0;
      margin-top: 20px;
    }

    .ant-btn {
      width: 100%;
    }
  }
`
