import styled from 'styled-components'
import { Col, Row } from 'antd'
export const ChartTitle = styled.span`
  color: #000;
  font-size: 16px;
`

export const ColFill = styled(Col)`
  padding: 0;
  flex: 1;
  .ant-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    .ant-card-body {
      flex: 1;
      .ant-row {
        .ant-col + .ant-col {
          margin-bottom: 8px;
        }
      }
    }
  }
`

export const Span = styled.span`
  font-size: 15px !important;
`

export const Tag = styled.span`
  background-color: var(--orange);
  padding: 2px 6px 2px 6px;
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Label = styled.span`
  color: #000;
  font-size: 18px;
  margin-bottom: 24px;
`

export const DestaquesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex: 1;

  ${Tag} {
    align-self: flex-end;
  }

  @media (max-width: 880px) {
    ${Tag} {
      align-self: center;
    }
  }
`

export const DestaquesRow = styled(Row)`
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100%;

  .ant-divider,
  .ant-divider-vertical {
    height: 242px;
    align-self: stretch;
    flex: 1;
    max-width: 1px;
  }

  @media (max-width: 800px) {
    .ant-divider,
    .ant-divider-vertical {
      display: none;
    }
    ${DestaquesContainer} {
      min-width: 100%;
    }
  }
`

export const IndicadoresContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 18px;
    max-width: 90%;
    align-self: flex-start;
    min-height: 56px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`
