import styled from 'styled-components'

export const CandidatoCell = styled.div`
  display: flex;
  flex-direction: column;

  a:first-of-type {
    margin-bottom: 8px;
  }

  .candidatoNome {
    font-size: 18px;
  }

  .candidatoProfissao {
    font-weight: 500;
  }
`
