import React, { useEffect, useState, useCallback, useMemo } from 'react'

import moment from 'moment'

import 'moment-timezone'

import XLSX from 'xlsx'

import { toast } from 'react-toastify'

import { useSelector, useDispatch } from 'react-redux'

import { Col, Row, Card, Modal, Steps, Tabs } from 'antd'

// Utils
import history from '../../../../utils/history'
import { getContaURL } from '../../../../services/auth'

import ProcessoSeletivoForm from '../../components/ProcessoSeletivoForm'

import EditProcessoSeletivoForm from '../../components/EditProcessoSeletivoForm'

import TabProcessosAtivos from './components/tabProcessosAtivos'

import RequisicaoHistory from '../../../recrutamentoSelecao/containers/Requisicoes/components/RequisicaoHistory'

import { novoProcessoInitialState } from './constants/constantsProcessoSeletivo'

import AdicionarNovoCargo from '../../../cargos/components/adicionarNovoCargo/AdicionarNovoCargo'

import {
  getProcessos,
  storeProcesso,
  updateProcesso,
  deleteProcesso,
  getCargos,
  getTimes,
  getUFs,
  getCidades,
  suspenderProcesso,
  retomarProcesso,
  getUsuarios,
  getCargoAtividadesList,
  getQuizes,
  getCargoAtividades,
} from './actions/actionsProcessoSeletivo'
import { postCargo } from '../../../cargos/actions/actionsCargos'
import { listMotivos } from '../../../recrutamentoSelecao/containers/PsMotivo/actions/actions'
import { listTipoContrato } from '../../../recrutamentoSelecao/containers/PsContrato/actions/actions'
import { listFiliais } from '../../../ConfigFiliais/actions/actions'
import { listSlas } from '../../../recrutamentoSelecao/containers/PsSla/actions/actions'

import { parseSalarioFaixa } from '../../utils'

import { getModulosConta } from '../../../../services/auth'

const { TabPane } = Tabs

function ProcessoSeletivo(_, searchInput) {
  const [hunterFilterState, setHunterFilterState] = useState([])

  const [disableSaveButton, setDisableSaveButton] = useState(true)

  const [solicitadorFilterState, setSolicitadorFilterState] = useState([])

  const dispatch = useDispatch()

  const processos = useSelector((state) =>
    state.processoSeletivo.processos.filter((ps) => {
      return (
        ps.status !== 'finalizado' &&
        ps.status !== 'cancelado' &&
        ps.status !== 'suspenso' &&
        !ps.finalizadoem !== false
      )
    })
  )

  const [toExport, setToExport] = useState([])

  const [loading, setLoading] = useState(false)

  const cargos = useSelector((state) => state.processoSeletivo.cargos)

  const [loadingCargos, setLoadingCargos] = useState(false)

  const times = useSelector((state) => state.processoSeletivo.times)

  const [usuarios, setUsuarios] = useState([])

  const [loadingTimes, setLoadingTimes] = useState(false)

  const ufs = useSelector((state) => state.processoSeletivo.ufs)

  const [loadingUfs, setLoadingUfs] = useState(false)

  const cidades = useSelector((state) => state.processoSeletivo.cidades)

  const [loadingCidades, setLoadingCidades] = useState(false)

  const [showModalNovoProcesso, setShowModalNovoProcesso] = useState(false)

  const [showModalNewCargo, setShowModalNewCargo] = useState(false)

  const [showModalUpdateProcesso, setShowModalUpdateProcesso] = useState(false)

  const [showModalReqHistory, setShowModalReqHistory] = useState(false)

  const [currentProcessoId, setCurrentProcessoId] = useState()

  const [cargoId, setCargoId] = useState(0)

  const [cargo, setCargo] = useState(0)

  const [mostrarAtividades, setMostrarAtividades] = useState([])

  const [processo, setProcesso] = useState([])

  const [processoToClone, setProcessoToClone] = useState()

  const [reqHistory, setReqHistory] = useState([])

  const [motivos, setMotivos] = useState([])
  const [filiais, setfiliais] = useState([])
  const [contratos, setContratos] = useState([])
  const [slas, setSlas] = useState([])

  const [quizes, setQuizes] = useState([])

  const loadProcessos = useCallback(() => {
    setLoading(true)
    dispatch(getProcessos()).then(() => setLoading(false))
  }, [dispatch])

  const loadTipos = useCallback(() => {
    setLoading(true)
    dispatch(getProcessos()).then(() => setLoading(false))
  }, [dispatch])

  const loadUsuarios = useCallback(() => {
    dispatch(getUsuarios()).then(({ response }) => {
      if (response.status === 200) {
        setUsuarios(
          response.data.filter(
            (usuario) => usuario.user_ativo === 1 && usuario.pes_id
          )
        )
      }
    })
  }, [dispatch])

  const loadQuizes = useCallback(() => {
    dispatch(getQuizes()).then(({ response }) => {
      if (response.status === 200) {
        // eslint-disable-next-line prettier/prettier
        setQuizes(response.data.quizz)
      }
    })
  }, [dispatch])

  const handleGetAtividadesList = useCallback(() => {
    dispatch(getCargoAtividadesList()).then(({ response }) => {
      if (response.status === 200) {
        // eslint-disable-next-line prettier/prettier
        setMostrarAtividades(response.data.atividades)
      }
    })
  }, [dispatch])

  const loadMotivos = useCallback(() => {
    dispatch(listMotivos()).then(({ response }) => {
      if (response.status === 200) {
        setMotivos(response.data.filter((motivo) => motivo.ativo === 1))
      }
    })
  }, [dispatch])

  const loadSlas = useCallback(() => {
    dispatch(listSlas()).then(({ response }) => {
      if (response.status === 200) {
        setSlas(response.data.filter((sla) => sla.ativo === 1))
      }
    })
  }, [dispatch])

  const loadContratos = useCallback(() => {
    dispatch(listTipoContrato()).then(({ response }) => {
      if (response.status === 200) {
        setContratos(response.data.filter((contrato) => contrato.ativo === 1))
      }
    })
  }, [dispatch])

  const loadFiliais = useCallback(() => {
    dispatch(listFiliais()).then(({ response }) => {
      if (response.status === 200) {
        setfiliais(response.data.filter((filial) => filial.ativo === 1))
      }
    })
  }, [dispatch])

  const loadFiltros = useCallback(() => {
    const hunterFilters = localStorage.getItem('@TeamHubGC:ps_hunters')
    const solicitadorFilters = localStorage.getItem(
      '@TeamHubGC:ps_solicitadores'
    )

    if (
      hunterFilters &&
      hunterFilters !== 'undefined' &&
      hunterFilters !== 'null'
    )
      setHunterFilterState(JSON.parse(hunterFilters))
    else setHunterFilterState([])

    if (
      solicitadorFilters &&
      solicitadorFilters !== 'undefined' &&
      solicitadorFilters !== 'null'
    )
      setSolicitadorFilterState(JSON.parse(solicitadorFilters))
    else setSolicitadorFilterState([])
  }, [])

  useEffect(() => {
    setLoadingTimes(true)
    setLoadingCargos(true)
    setLoadingUfs(true)
    loadProcessos()
    loadFiliais()
    loadUsuarios()
    loadTipos()
    loadContratos()
    loadMotivos()
    handleGetAtividadesList()
    loadFiltros()
    loadSlas()
    loadQuizes()
    dispatch(getCargos()).then(() => setLoadingCargos(false))
    dispatch(getTimes()).then(() => setLoadingTimes(false))
    dispatch(getUFs()).then(() => setLoadingUfs(false))
  }, [
    dispatch,
    loadContratos,
    loadFiliais,
    loadProcessos,
    loadUsuarios,
    loadMotivos,
    handleGetAtividadesList,
    loadSlas,
    loadFiltros,
    loadTipos,
    loadQuizes,
  ])

  const exportarExcel = (toExport) => {
    const aoa = [
      [
        'Nro Requisição',
        'Prioridade',
        'Filial',
        'Cidade /UF ',
        'HomeOffice',
        'Cargo',
        'Time',
        'Quantidade',
        'Solicitado por',
        'Hunter',
        'Motivo',
        'Tipo de Recrutamento',
        'Tipo de contrato',
        'Sigiloso',
        'Status',
        // "Etapa",
        'Inscritos',
        'Triados',
        'Contratados',
        'Início',
        'Duração',
        'Observação',
        'Candidatos Contratados',
      ],
      ...toExport.map((processo) => {
        const candidatosEtapas = processo.etapas.map((etapa) =>
          etapa.etapaCandidato.filter(
            (candidato) =>
              candidato.contratado && parseInt(candidato.contratado) === 1
          )
        )
        return [
          processo.numero,
          processo.prioridade === 1
            ? 'Baixa'
            : processo.prioridade === 2
            ? 'Média'
            : processo.prioridade === 3
            ? 'Alta'
            : '',
          processo.filial ? processo.filial.nome : '',
          processo.cidade && processo.cidade.CIDADE
            ? processo.cidade.uf && processo.cidade.uf.UF
              ? `${processo.cidade.CIDADE.trim()} / ${processo.cidade.uf.UF.trim()}`
              : processo.cidade.CIDADE.trim()
            : '',
          processo.homeoffice === 1 ? 'Não' : 'Sim',
          processo.cargo ? processo.cargo.cargo_nome : '',
          processo.grupo ? processo.grupo.grupo_nome : '',
          processo.qtde,
          processo.creator ? processo.creator.pes_nome : '',
          processo.responsible ? processo.responsible.pes_nome : '',
          processo.motivo ? processo.motivo.nome : '',
          processo.tipo === 'I'
            ? 'Interno'
            : processo.tipo === 'E'
            ? 'Externo'
            : processo.tipo === 'H'
            ? 'Híbrido'
            : '',
          processo.contrato ? processo.contrato.nome : '',
          processo.sigoloso ? 'Sim' : 'Não',
          processo.prazo,
          // processo.etapa,
          processo.inscritos,
          processo.triados,
          processo.contratados,
          processo.criadoem &&
            moment(processo.criadoem)
              .utc()
              .tz(moment.tz.guess())
              .format('DD/MM/YYYYY, H:mm'),
          processo.duracao,
          processo.observacao,
          candidatosEtapas
            .reduce((acc, next) => [...acc, ...next], [])
            .reduce(
              (acc, next) =>
                acc
                  ? `${acc}, ${next.candidato.pes_nome}`
                  : next.candidato.pes_nome,
              ''
            ),
        ]
      }),
    ]

    const ws = XLSX.utils.aoa_to_sheet(aoa)

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Export Processos')
    XLSX.writeFile(wb, 'processos.xlsx')
  }

  const huntersOptions = useMemo(() => {
    return usuarios
      .filter((usuario) => usuario.colaborador)
      .filter((usuario) => usuario.usertipo_id === 1 && usuario.pes_id)
      .map((usuario) => ({
        label: usuario.colaborador.pes_nome,
        value: usuario.pes_id,
      }))
  }, [usuarios])
  const huntersFilterOptions = useMemo(() => {
    return huntersOptions.map((hunter) => ({
      text: hunter.label,
      value: hunter.value,
    }))
  }, [huntersOptions])

  const solicitadoresOptions = useMemo(() => {
    const solicitadoresSet = [
      ...new Set(
        processos
          .filter((processo) => processo.creator)
          .map((processo) => JSON.stringify(processo.creator))
      ),
    ]

    return solicitadoresSet
      .filter((solicitador) => solicitador)
      .map((solicitador) => JSON.parse(solicitador))
      .map((solicitador) => ({
        text: solicitador.pes_nome,
        value: solicitador.pes_id,
      }))
  }, [processos])

  const handleTableChange = (pagination, filters, sorter) => {
    if (!filters['responsible.pes_nome']) {
      setHunterFilterState([])
    } else {
      setHunterFilterState([...filters['responsible.pes_nome']])
      localStorage.setItem(
        '@TeamHubGC:ps_hunters',
        JSON.stringify(filters['responsible.pes_nome'])
      )
    }

    if (!filters['creator.pes_nome']) {
      setSolicitadorFilterState([])
    } else {
      setSolicitadorFilterState([...filters['creator.pes_nome']])
      localStorage.setItem(
        '@TeamHubGC:ps_solicitadores',
        JSON.stringify(filters['creator.pes_nome'])
      )
    }
  }

  const clearTableFilters = () => {
    setHunterFilterState([])
    setSolicitadorFilterState([])
    setToExport(processos)

    localStorage.setItem('@TeamHubGC:ps_hunters', JSON.stringify([]))
    localStorage.setItem('@TeamHubGC:ps_solicitadores', JSON.stringify([]))
  }

  const loadCidades = useCallback(
    (UFID) => {
      setLoadingCidades(true)
      dispatch(getCidades(UFID)).then(() => setLoadingCidades(false))
    },
    [dispatch]
  )

  useEffect(() => {
    if (processo.ufid) {
      loadCidades(processo.ufid)
    }
  }, [loadCidades, processo.ufid])

  const handleModalUpdateProcesso = (processo) => {
    setProcesso(processo)
    setCurrentProcessoId(processo.processo_id)
    setShowModalUpdateProcesso(true)
    setCargo(processo.cargo.cargo_id)
  }

  const closeModalNovoProcesso = () => {
    setProcesso(novoProcessoInitialState)
    setCurrentProcessoId(undefined)
    setProcessoToClone(undefined)
    setShowModalNovoProcesso(false)
    setDisableSaveButton(true)
  }

  const closeModalUpdateProcesso = () => {
    setProcesso(novoProcessoInitialState)
    setCurrentProcessoId(undefined)
    setProcessoToClone(undefined)
    setShowModalUpdateProcesso(false)
  }

  const handleChangeProcesso = useCallback((_, allValues) => {
    setProcesso(allValues)
  }, [])

  const handleDeleteProcesso = ({ processo_id }) => {
    dispatch(deleteProcesso(processo_id)).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Processo cancelado com sucesso.')
        loadProcessos()
      } else toast.error('Erro ao cancelar Processo Seletivo.')
    })
  }

  const onFinishNewProcesso = useCallback(
    async (data) => {
      setDisableSaveButton(true)
      const { salario_faixa_min, salario_faixa_max, descricao } = data

      const descricaoNovo = descricao.level
        ? descricao.level.content
        : descricao

      const salario_faixa = await parseSalarioFaixa(
        salario_faixa_min,
        salario_faixa_max
      )

      dispatch(
        storeProcesso({ ...data, salario_faixa, descricao: descricaoNovo })
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Processo cadastrado com sucesso.')
          loadProcessos()
          closeModalNovoProcesso()
        } else toast.error('Erro ao criar Processo Seletivo.')
      })
    },
    [dispatch, loadProcessos]
  )

  /* const onFinishNewProcesso = async (data) => {
    const { salario_faixa_min, salario_faixa_max, descricao } = data;

    const descricaoNovo = descricao.level ? descricao.level.content : descricao;

    const salario_faixa = await parseSalarioFaixa(
      salario_faixa_min,
      salario_faixa_max
    );

    dispatch(
      storeProcesso({ ...data, salario_faixa, descricao: descricaoNovo })
    ).then(({ response }) => {
      if (response.status === 200) {
        toast.success("Processo cadastrado com sucesso.");
        loadProcessos();
        closeModalNovoProcesso();
      } else toast.error("Erro ao criar Processo Seletivo.");
    });
  }; */

  const onFinishUpdateProcesso = async () => {
    const { salario_faixa_min, salario_faixa_max } = processo

    let descricao_new = ''

    /* É criado uma nova key no objeto processo, a key (descricao_v) é removida é deixada apenas a original (descricao) */
    if (processo.descricao_v) {
      if (processo.descricao_v.level) {
        descricao_new = processo.descricao_v.level.content
      } else {
        descricao_new = processo.descricao_v
      }
      processo.descricao = descricao_new
      delete processo.descricao_v
    } else if (processo.descricao) {
      if (processo.descricao.level) {
        descricao_new = processo.descricao.level.content
      } else {
        descricao_new = processo.descricao
      }
    }

    const salario_faixa = await parseSalarioFaixa(
      salario_faixa_min,
      salario_faixa_max
    )

    dispatch(
      updateProcesso({
        ...processo,
        processo_id: currentProcessoId,
        salario_faixa,
        descricao: descricao_new,
      })
    ).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Processo atualizado com sucesso.')
        loadProcessos()
        closeModalUpdateProcesso()
      } else toast.error('Erro ao atualizar Processo Seletivo.')
    })
  }

  const handleSuspenderProcesso = useCallback(
    (processo_id) => {
      dispatch(suspenderProcesso(processo_id)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Processo Seletivo suspenso com sucesso.')
          loadProcessos()
        }
      })
    },
    [dispatch, loadProcessos]
  )

  const handleRetomarProcesso = useCallback(
    (processo_id) => {
      dispatch(retomarProcesso(processo_id)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Processo Seletivo retomado com sucesso.')
          loadProcessos()
        }
      })
    },
    [dispatch, loadProcessos]
  )

  const handleSubmitNewCargo = useCallback(
    (data) => {
      dispatch(postCargo(data)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Cargo cadastrado com sucesso.')
          dispatch(getCargos()).then(() => setLoadingCargos(false))
          setShowModalNewCargo(false)
        }
      })
    },
    [dispatch]
  )

  const contratoOptions = useMemo(() => {
    return contratos.map((contrato) => {
      return {
        label: contrato.nome,
        value: contrato.contrato_id,
      }
    })
  }, [contratos])

  const motivosOptions = useMemo(() => {
    return motivos.map((motivo) => {
      return {
        label: motivo.nome,
        value: motivo.motivo_id,
      }
    })
  }, [motivos])

  const filiaisOptions = useMemo(() => {
    return filiais.map((filial) => {
      return {
        label: filial.nome,
        value: filial.filial_id,
      }
    })
  }, [filiais])

  const slasOptions = useMemo(() => {
    return slas.map((sla) => {
      return {
        label: sla.nome,
        value: sla.sla_id,
      }
    })
  }, [slas])

  const prioridadesOptions = useMemo(() => {
    return [
      { label: 'Baixa', value: 1 },
      { label: 'Média', value: 2 },
      { label: 'Alta', value: 3 },
    ]
  }, [])

  const tiposOptions = useMemo(() => {
    return [
      { label: 'Externo', value: 'E' },
      { label: 'Interno', value: 'I' },
      { label: 'Hibrido', value: 'H' },
    ]
  }, [])

  const userOptions = useMemo(() => {
    return usuarios
      .filter((usuario) => usuario.colaborador)
      .map((usuario) => {
        return {
          label: usuario.colaborador.pes_nome,
          value: usuario.user_id,
        }
      })
  }, [usuarios])

  const handleCloneProcesso = useCallback((processo) => {
    const { processo_id, ...data } = processo
    setProcessoToClone({ ...data })
    setShowModalNovoProcesso(true)
  }, [])

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Card title="Processos Seletivos">
            <Tabs
              defaultActiveKey="1"
              onTabClick={(key) => {
                key === '1' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/processos-seletivos/ativos`
                  )
                key === '2' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/processos-seletivos/suspensos`
                  )
                key === '3' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/processos-seletivos/finalizados`
                  )
                key === '4' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/processos-seletivos/cancelados`
                  )
              }}
            >
              <TabPane tab="Ativos" key="1">
                <TabProcessosAtivos
                  setShowModalNovoProcesso={setShowModalNovoProcesso}
                  solicitadoresOptions={solicitadoresOptions}
                  solicitadorFilterState={solicitadorFilterState}
                  huntersFilterOptions={huntersFilterOptions}
                  hunterFilterState={hunterFilterState}
                  prioridadesOptions={prioridadesOptions}
                  tiposOptions={tiposOptions}
                  handleModalUpdateProcesso={handleModalUpdateProcesso}
                  handleSuspenderProcesso={handleSuspenderProcesso}
                  handleRetomarProcesso={handleRetomarProcesso}
                  handleCloneProcesso={handleCloneProcesso}
                  handleTableChange={handleTableChange}
                  clearTableFilters={clearTableFilters}
                  exportarExcel={exportarExcel}
                  setToExport={setToExport}
                  toExport={toExport}
                  loading={loading}
                  processos={processos}
                  setShowModalReqHistory={setShowModalReqHistory}
                  setReqHistory={setReqHistory}
                />
              </TabPane>
              <TabPane tab="Suspensos" key="2" />
              <TabPane tab="Finalizados" key="3" />
              <TabPane tab="Cancelados" key="4" />
            </Tabs>
          </Card>
        </Col>
      </Row>

      {/* NEW PROCESSO MODAL */}
      <Modal
        title="NOVO PROCESSO SELETIVOS"
        visible={showModalNovoProcesso}
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => closeModalNovoProcesso()}
        maskClosable={false}
        key="1"
        width="60%"
        destroyOnClose
        okButtonProps={{
          form: 'req-processo-seletivo',
          key: 'submit',
          htmlType: 'submit',
          disabled: disableSaveButton,
        }}
        centered
      >
        <ProcessoSeletivoForm
          getModulosConta={getModulosConta}
          onFinish={onFinishNewProcesso}
          setDisableSaveButton={setDisableSaveButton}
          times={times}
          loadingTimes={loadingTimes}
          cargos={cargos}
          loadingCargos={loadingCargos}
          ufs={ufs}
          loadingUfs={loadingUfs}
          cidades={cidades}
          loadingCidades={loadingCidades}
          usuarios={userOptions}
          motivos={motivosOptions}
          contratos={contratoOptions}
          filiais={filiaisOptions}
          slas={slasOptions}
          loadCidades={loadCidades}
          huntersOptions={huntersOptions}
          setCargoId={setCargoId}
          mostrarAtividades={mostrarAtividades}
          quizes={quizes}
          type="create"
        />
      </Modal>

      {/* UPDATE PROCESSO MODAL */}
      <Modal
        title="EDITAR PROCESSO SELETIVO"
        visible={showModalUpdateProcesso}
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => closeModalUpdateProcesso()}
        destroyOnClose
        key="2"
        okButtonProps={{
          form: 'req-editar-processo-seletivo',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
        width="60%"
      >
        <EditProcessoSeletivoForm
          onValuesChange={handleChangeProcesso}
          onFinish={onFinishUpdateProcesso}
          times={times}
          loadingTimes={loadingTimes}
          cargos={cargos}
          loadingCargos={loadingCargos}
          ufs={ufs}
          loadingUfs={loadingUfs}
          cidades={cidades}
          loadingCidades={loadingCidades}
          usuarios={userOptions}
          motivos={motivosOptions}
          contratos={contratoOptions}
          filiais={filiaisOptions}
          initialValues={{ ...processo }}
          loadCidades={loadCidades}
          slas={slasOptions}
          huntersOptions={huntersOptions}
          setCargoId={setCargoId}
          cargo={cargo}
          mostrarAtividades={mostrarAtividades}
          quizes={quizes}
          type="edit"
          descricao={processo.descricao}
        />
      </Modal>
      <Modal
        title="HISTÓRICO DA REQUISIÇÃO"
        visible={showModalReqHistory}
        cancelText="FECHAR"
        onCancel={() => {
          setShowModalReqHistory(false)
          setReqHistory([])
        }}
        okButtonProps={{ style: { display: 'none' } }}
        destroyOnClose
        centered
      >
        <RequisicaoHistory history={reqHistory} />
      </Modal>
      <Modal
        title="ADICIONAR NOVO CARGO"
        visible={showModalNewCargo}
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => setShowModalNewCargo()}
        destroyOnClose
        okButtonProps={{
          form: 'novo-cargo-submit',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <AdicionarNovoCargo onFinish={handleSubmitNewCargo} />
      </Modal>
    </Col>
  )
}

export default ProcessoSeletivo
