import { Button, Table } from 'antd'
import { CSVLink } from 'react-csv'

const columns = [
  {
    title: 'Arquétipo',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Muito Acima',
    dataIndex: 'Muito Acima',
    key: 'Muito Acima',
  },
  {
    title: 'Acima',
    dataIndex: 'Acima',
    key: 'Acima',
  },
  {
    title: 'Dentro',
    dataIndex: 'Desejado',
    key: 'Desejado',
  },

  {
    title: 'Abaixo',
    dataIndex: 'Abaixo',
    key: 'Abaixo',
  },
  {
    title: 'Muito Abaixo',
    dataIndex: 'Muito Abaixo',
    key: 'Muito Abaixo',
  },
]

type Props = {
  isPrintable?: boolean
  dataSource: any
}

const App = (props: Props) => {
  return (
    <div style={{ marginTop: 50 }}>
      <Button type="primary" style={{ marginBottom: 20 }}>
        {!props.isPrintable && (
          <CSVLink
            filename="Reports.csv"
            data={props.dataSource}
            className="btn btn-primary"
            onClick={() => {
              console.log('The file is downloading')
            }}
          >
            Exportar para CSV
          </CSVLink>
        )}
      </Button>
      <Table
        dataSource={props.dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  )
}

export default App
