import { toast } from 'react-toastify'

import * as constants from '../constants/constantsQuiz'

const initialState = {
  quizes: [],
}

export const quiz = (state = initialState, action) => {
  switch (action.type) {
    case constants.QUIZ_LIST: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error('Erro ao listar testes.')
        return { ...state }
      }

      const { quizz: quizes } = action.response.data

      return { ...state, quizes }
    }

    default:
      return { ...state }
  }
}

export default quiz
