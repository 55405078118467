import React, { useMemo } from 'react'

import {
  Checkbox,
  Rate,
  Popconfirm,
  Progress,
  Tag,
  Tooltip,
  Space,
  List,
} from 'antd'

import { toast } from 'react-toastify'

import {
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  FormOutlined,
  UserOutlined,
  MailOutlined,
  YoutubeOutlined,
  ExclamationCircleTwoTone,
} from '@ant-design/icons'

import { Item, Avatar, Popover } from './styles'

import Link from '../../../../../../components/privateLink/PrivateLink'

import { ReactComponent as Svg } from '../../../../../../assets/onu_pcd.svg'

import { styleColors } from '../../../../../../utils/utils'

const { pink, purple } = styleColors

const CandidatoItem = React.forwardRef(
  (
    {
      pes_id,
      etapacand_id,
      etapaSelectedId,
      nome,
      rate,
      profissao,
      idade,
      prtsalario,
      video,
      draggableProps,
      dragHandleProps,
      isDragging = false,
      scrollWidth = 0,
      handleChangeCandidatoRate,
      checked,
      handleOpenNovaObservacao,
      handleOpenVideo,
      handleOpenEnviarEmail,
      observacao,
      handleCheckCandidato,
      openModalMotivoExclusao,
      handleContratarCandidato,
      contratado = 0,
      fitCultural,
      ativorelatorios = 0,
      handleSendEmail,
      processo,
      pcd,
      pcd_tipo,
    },
    ref
  ) => {
    const isCandidato = !parseInt(ativorelatorios)

    const obsCandidatura = useMemo(() => {
      let text = `Há inconsistências nesta candidatura: `

      if (
        pcd !== processo.pcd ||
        (processo.pcd_tipo !== null && processo.pcd_tipo !== pcd_tipo)
      ) {
        if (pcd !== processo.pcd) {
          text = `${text} O candidato ${
            processo.pcd === 0 || processo.pcd > 1
              ? 'é PCD, porém a vaga não é para candidatos PCD.'
              : 'não é PCD, e vaga é para candidatos PCD.'
          }`
        }

        if (pcd === processo.pcd && pcd_tipo !== processo.pcd_tipo) {
          text = `${text} O candidato apresenta o tipo de PCD ${pcd_tipo}, e o tipo de PCD solicitado na vaga é ${processo.pcd_tipo}.`
        }

        return text
      }

      return null
    }, [pcd, pcd_tipo, processo.pcd, processo.pcd_tipo])

    return (
      <Popover
        {...(parseInt(contratado) === 1 ? { visible: false } : {})}
        title="Ações"
        placement="right"
        content={
          processo.status !== 'suspenso' &&
          processo.status !== 'finalizado' &&
          processo.status !== 'cancelado' ? (
            <ul>
              <li>
                <Link to={`/perfilCandidato/${pes_id}`} target="_blank">
                  <UserOutlined />
                  <span>Ver Perfil</span>
                </Link>
              </li>
              {video && (
                <li>
                  <span onClick={() => handleOpenVideo(video)}>
                    <YoutubeOutlined />
                    Ver Vídeo
                  </span>
                </li>
              )}
              <li>
                <span onClick={() => handleOpenNovaObservacao(etapacand_id)}>
                  <FormOutlined />
                  Registrar Observação
                </span>
              </li>
              <li>
                <span
                  onClick={() =>
                    handleOpenEnviarEmail(etapacand_id, etapaSelectedId)
                  }
                >
                  <MailOutlined />
                  Enviar e-mail
                </span>
              </li>
              <li className="success">
                <Popconfirm
                  title={`Você realmente deseja contratar ${nome}?`}
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={() => handleContratarCandidato(etapacand_id)}
                  icon={<CheckOutlined style={{ color: 'green' }} />}
                >
                  <CheckOutlined />
                  <span>Contratar</span>
                </Popconfirm>
              </li>
              <li className="danger">
                <Popconfirm
                  title={`Tem certeza que deseja remover ${nome} do processo?`}
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={() => openModalMotivoExclusao(etapacand_id)}
                  icon={
                    <ExclamationCircleFilled
                      style={{ color: isCandidato ? pink : purple }}
                    />
                  }
                >
                  <DeleteOutlined />
                  <span>Remover</span>
                </Popconfirm>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <Link to={`/perfilCandidato/${pes_id}`} target="_blank">
                  <UserOutlined />
                  <span>Ver Perfil</span>
                </Link>
              </li>
              {video && (
                <li>
                  <span onClick={() => handleOpenVideo(video)}>
                    <YoutubeOutlined />
                    Ver Vídeo
                  </span>
                </li>
              )}
            </ul>
          )
        }
      >
        <Item
          ref={ref}
          {...draggableProps}
          {...dragHandleProps}
          isDragging={isDragging}
          scrollWidth={scrollWidth}
          checked={parseInt(contratado) === 1 ? false : checked}
          contratado={parseInt(contratado)}
          candidato={isCandidato}
        >
          <div className="psKanban__candidato__row">
            {!fitCultural ? (
              <Avatar>
                <span>{nome.charAt(0)}</span>
              </Avatar>
            ) : (
              <Progress
                type="circle"
                percent={parseFloat(fitCultural.geral).toFixed(1)}
                strokeColor={
                  parseInt(contratado) === 0
                    ? isCandidato
                      ? pink
                      : purple
                    : 'green'
                }
                status="normal"
                width={64}
                style={{
                  marginRight: 16,
                }}
              />
            )}
            <div className="details">
              <span
                className="name"
                style={{ display: 'flex', alignItems: 'center' }}
                // eslint-disable-next-line react/no-unknown-property
                gutter={16}
              >
                <Space>
                  {obsCandidatura && (
                    <Tooltip title={obsCandidatura}>
                      <ExclamationCircleTwoTone twoToneColor="orange" />
                    </Tooltip>
                  )}
                  {nome}
                </Space>
              </span>
              <span className="profissao">{profissao}</span>
              <span className="idade">
                {!!idade && `${idade} anos`}
                {!!pcd && !!idade && ' - '}
                {pcd === 1 && <span>PCD{pcd_tipo && ` ${pcd_tipo}`}</span>}
              </span>
              {processo.status !== 'suspenso' &&
                processo.status !== 'finalizado' &&
                processo.status !== 'cancelado' && (
                  <Rate
                    allowHalf
                    allowClear={parseInt(contratado) === 0}
                    defaultValue={parseFloat(rate)}
                    onChange={(rate) =>
                      parseInt(contratado) === 1
                        ? toast.error(
                            'O candidato já foi contratado, alterações da classificação não serão salvas.'
                          )
                        : handleChangeCandidatoRate({ rate, etapacand_id })
                    }
                    {...(parseInt(contratado) === 1
                      ? { value: parseFloat(rate) }
                      : {})}
                  />
                )}
            </div>

            {processo.status !== 'suspenso' &&
              processo.status !== 'finalizado' &&
              processo.status !== 'cancelado' && (
                <div className="checkbox">
                  {parseInt(contratado) === 0 ? (
                    <Checkbox
                      checked={checked}
                      onChange={() => {
                        handleCheckCandidato(etapacand_id)
                      }}
                    />
                  ) : (
                    <CheckOutlined style={{ color: 'green' }} />
                  )}
                </div>
              )}

            <span className="prtSalario">
              {prtsalario ? `R$ ${prtsalario}` : ''}
            </span>
          </div>
        </Item>
      </Popover>
    )
  }
)
export default CandidatoItem
