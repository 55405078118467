import styled from 'styled-components'

export const Container = styled.div`
  > span {
    margin-left: 8px;
    font-weight: 500;
  }
`

export const ContainersRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  > ${Container} + ${Container} {
    margin-left: 36px;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 36px;

  > * + * {
    margin-left: 48px;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`
