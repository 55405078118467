import React, { useState, useMemo, useEffect } from 'react'

import {
  Col,
  Card,
  Divider,
  Row,
  Empty,
  Progress,
  Table,
  Modal,
  Alert,
  Tooltip as TooltipAnt,
} from 'antd'

import {
  Chart,
  Axis,
  Coordinate,
  Tooltip,
  Line,
  Point,
  Interval,
  Legend,
} from 'bizcharts'

import DataSet from '@antv/data-set'

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import {
  FullscreenOutlined,
  ZoomInOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  DeleteOutlined,
  StarFilled,
} from '@ant-design/icons'
import { ResponsiveRadar, ResponsiveLine, ResponsiveBar } from '../charts'

import {
  FlexCard,
  FlexTitle,
  Label,
  RowChars,
  RateSpan,
  RateContainer,
  ProgressTitle,
  ProgressContainer,
  ProgressValues,
  ProgressLabels,
  Divider16,
  CardFill,
  ColFill,
  FullCol,
  Divider32,
  Divider64,
  FlexRow,
  FitCulturalContainer,
  Link16,
  FitCulturalCard,
  FlexTablesContainer,
  Container,
  HistoricoRow,
  // eslint-disable-next-line import/named
} from './styles'

import { discRange, colors, arqRange } from '../../utils/utils'

import { noPadding, cardHeadBlue } from '../../utils/inlineStyles'

import Textual from '../../containers/perfilComportamentalDetalhes/components/textual/Textual'

import { FitCulturalTables } from '../FitCulturalPerfil'

const columns = [
  {
    title: 'Perfil',
    dataIndex: 'row',
  },
  {
    title: 'Essência',
    dataIndex: 'essencia',
    render: (text, record) =>
      text === 0 ? '0 %' : text ? `${parseFloat(text).toFixed(1)} %` : '',
  },
  {
    title: 'Exigido',
    dataIndex: 'exigido',
    render: (text, record) =>
      text === 0 ? '0%' : text ? `${parseFloat(text).toFixed(1)} %` : '',
  },
  {
    title: 'Entrega',
    dataIndex: 'entrega',
    render: (text, record) =>
      text === 0 ? '0 %' : text ? `${parseFloat(text).toFixed(1)} %` : '',
  },
]

const fitCulturalTypes = ['colaborador', 'candidato']

export function PerfilComportamental({
  cultura,
  culturaEmpresa,
  disc,
  discInstalado,
  intensidade,
  rct,
  edl,
  foc,
  ode,
  oct,
  tdd,
  cor,
  erc,
  edc,
  vetores = null,
  principal,
  fitCulturalEmpresa = {},
  type = 'geral',
  showHistoricoDisc = null,
  handleSwitchGraficoRadar = null,
  handleSwitchHistoricoDisc = null,
  showGraficoRadar = null,
  perfilComp,
  perfisQtde,
  pes_nome = null,
  minutosPesquisa,
}) {
  const purple = getComputedStyle(document.body).getPropertyValue('--purple')

  const pink = getComputedStyle(document.body).getPropertyValue('--orange')

  const perfil = {
    D: {},
    I: {},
    S: {},
    C: {},
  }

  if (culturaEmpresa != [] && Object.keys(fitCulturalEmpresa).length != 0) {
    fitCulturalEmpresa.fitArquetipos.Aprendizado =
      (fitCulturalEmpresa.arquetipos.Aprendizado /
        culturaEmpresa.find((element) => element.Tipo === 'Aprendizado')
          .Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Clã =
      (fitCulturalEmpresa.arquetipos.Clã /
        culturaEmpresa.find((element) => element.Tipo === 'Clã').Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Inovação =
      (fitCulturalEmpresa.arquetipos.Inovação /
        culturaEmpresa.find((element) => element.Tipo === 'Inovação').Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Empreendedora =
      (fitCulturalEmpresa.arquetipos.Empreendedora /
        culturaEmpresa.find((element) => element.Tipo === 'Empreendedora')
          .Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Autoridade =
      (fitCulturalEmpresa.arquetipos.Autoridade /
        culturaEmpresa.find((element) => element.Tipo === 'Autoridade').Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Colaboração =
      (fitCulturalEmpresa.arquetipos.Colaboração /
        culturaEmpresa.find((element) => element.Tipo === 'Colaboração')
          .Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Regra =
      (fitCulturalEmpresa.arquetipos.Regra /
        culturaEmpresa.find((element) => element.Tipo === 'Regra').Valor) *
      100
    fitCulturalEmpresa.fitArquetipos.Complacência =
      (fitCulturalEmpresa.arquetipos.Complacência /
        culturaEmpresa.find((element) => element.Tipo === 'Complacência')
          .Valor) *
      100

    fitCulturalEmpresa.fitArquetipos.Aprendizado = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Aprendizado
    )
    fitCulturalEmpresa.fitArquetipos.Clã = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Clã
    )
    fitCulturalEmpresa.fitArquetipos.Inovação = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Inovação
    )
    fitCulturalEmpresa.fitArquetipos.Empreendedora = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Empreendedora
    )
    fitCulturalEmpresa.fitArquetipos.Autoridade = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Autoridade
    )
    fitCulturalEmpresa.fitArquetipos.Colaboração = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Colaboração
    )
    fitCulturalEmpresa.fitArquetipos.Regra = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Regra
    )
    fitCulturalEmpresa.fitArquetipos.Complacência = verificarSeMaiorQueCem(
      fitCulturalEmpresa.fitArquetipos.Complacência
    )
  }

  function verificarSeMaiorQueCem(valor) {
    if (valor > 100) return 100
    return valor.toFixed(2)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const culturaDesejadaEmpresa = culturaEmpresa
    ? culturaEmpresa.filter((c) => c.Label === 'Desejado')
    : []

  const fitCulturalEmpresaData = cultura
    ? [...cultura, ...culturaDesejadaEmpresa]
    : null

  for (const p of perfilComp) {
    switch (p.item) {
      case 'Dominância':
        perfil.D[p.type] = p.score
        break
      case 'Influência':
        perfil.I[p.type] = p.score
        break
      case 'Estabilidade':
        perfil.S[p.type] = p.score
        break
      case 'Conformidade':
        perfil.C[p.type] = p.score
        break
      default:
        break
    }
  }
  const { vetor: vetorComposicao, formulario } = principal

  // DATA NIVO
  // const discRadar = [];
  const discGrouped = []

  for (const d of disc) {
    // DATA NIVO
    /*
		let addToDiscRadar = false;
		let objDiscRadar = discRadar.find((o) => o.Tipo === d.Tipo);
		if (!objDiscRadar) {
			objDiscRadar = {};
			addToDiscRadar = true;
		}
		objDiscRadar.Tipo = d.Tipo;
		objDiscRadar[d.Label] = d.Valor;
		if (addToDiscRadar) discRadar.push(objDiscRadar);
		*/

    // DATA BIZCHARTS
    let addToDiscGrouped = false
    let objDiscGrouped = discGrouped.find((o) => o.row === d.Label)
    if (!objDiscGrouped) {
      objDiscGrouped = {}
      addToDiscGrouped = true
    }
    objDiscGrouped.row = d.Label
    objDiscGrouped[d.Tipo] = d.Valor
    if (addToDiscGrouped) discGrouped.push(objDiscGrouped)
  }

  const ds = new DataSet()

  const dvDisc = ds.createView().source(discGrouped)
  dvDisc.transform({
    type: 'fold',
    fields: ['Dominância', 'Influência', 'Estabilidade', 'Conformidade'],
    key: 'key',
    value: 'value',
  })

  const messageObservacao = useMemo(() => {
    if (formulario === '1') {
      return 'Esse relatório é baseado na essência do candidato!'
    }
    return 'Esse relatório é baseado na entrega do colaborador!'
  }, [formulario])

  const dvPerfis = perfisQtde
    ? ds
        .createView()
        .source(perfisQtde)
        .transform({
          type: 'sort',
          callback(a, b) {
            return a.qtde - b.qtde > 0
          },
        })
    : null

  /*
	// PARSE DATA CHART NIVO
	const vetoresParsedNivo = [];

	if (vetores) {
		for (const v of vetores) {
			let obj = vetoresParsedNivo.find((o) => o.id === v.type);

			if (!obj) {
				obj = {
					id: v.type,
					data: [],
				};

				obj.data.push({ x: v.log_data, y: v.value });

				vetoresParsedNivo.push(obj);
			} else {
				obj.data.push({ x: v.log_data, y: v.value });
			}
		}
	}
	*/

  const IndicadoresCard = ({ bodyStyle = {} }) => {
    return (
      <Card
        title={
          <FlexTitle>
            <span>
              Tipo:{' '}
              {parseInt(formulario)
                ? parseInt(formulario) === 3
                  ? 'Entrega'
                  : parseInt(formulario) === 2
                  ? 'Exigido'
                  : 'Essência'
                : ''}
            </span>
            <Divider type="vertical" />
            <span>Perfil: {vetorComposicao}</span>
          </FlexTitle>
        }
        headStyle={cardHeadBlue}
      >
        <FlexCard
          flexdirection="column"
          bordered={false}
          bodyStyle={{ paddingTop: '16px' }}
        >
          <RateContainer>
            <Label>Obtenção de Energia</Label>

            <RateSpan>{ode.maior.nome}</RateSpan>
            <Progress
              percent={parseFloat(ode.maior.score)}
              strokeColor={purple}
              type="line"
              status="normal"
            />

            <RateSpan>{ode.menor.nome}</RateSpan>
            <Progress
              percent={parseFloat(ode.menor.score)}
              strokeColor={purple}
              type="line"
              status="normal"
            />
          </RateContainer>
          <Divider64 />
          <RateContainer>
            <Label>Orientação</Label>
            <RateSpan>{oct.maior.nome}</RateSpan>
            <Progress
              percent={parseFloat(oct.maior.score)}
              strokeColor={purple}
              type="line"
              status="normal"
            />

            <RateSpan>{oct.menor.nome}</RateSpan>
            <Progress
              percent={parseFloat(oct.menor.score)}
              strokeColor={purple}
              type="line"
              status="normal"
            />
          </RateContainer>
          <Divider64 />
          <RateContainer>
            <Label>Tomada de Decisão</Label>

            <RateSpan>{tdd.maior.nome}</RateSpan>
            <Progress
              percent={parseFloat(tdd.maior.score)}
              strokeColor={purple}
              type="line"
              status="normal"
            />

            <RateSpan>{tdd.menor.nome}</RateSpan>
            <Progress
              percent={parseFloat(tdd.menor.score)}
              strokeColor={purple}
              type="line"
              status="normal"
            />
          </RateContainer>
        </FlexCard>
      </Card>
    )
  }

  const obsDuracaoPesquisa = useMemo(() => {
    if (!minutosPesquisa || !minutosPesquisa.tempo) return null

    const [hour, minute, second] = minutosPesquisa.tempo.split(':')

    const hourParsed = Number(hour)
    const minuteParsed = Number(minute)
    const secondParsed = Number(second)

    if (hourParsed > 0 || minuteParsed < 4 || minuteParsed > 11) {
      let text = `As respostas para este relatório foram realizadas em: `
      const details = `. Para melhor confiabilidade nas respostas deste relatório, consideramos o intervalo de 4 a 11 minutos.`

      if (hourParsed > 0) {
        text = `${text} ${hourParsed} hora${hourParsed > 0 ? 's' : ''} e `
      }

      if (minuteParsed > 0 || hourParsed) {
        text = `${text}${minuteParsed} minuto${
          minuteParsed === 0 || minuteParsed > 1 ? 's' : ''
        } e `
      }

      text = `${text}${secondParsed} segundo${
        secondParsed === 0 || secondParsed > 1 ? 's' : ''
      }`

      text = `${text}${details}`

      return text
    }
    return null
  }, [minutosPesquisa])

  const hasObservations = useMemo(() => {
    return !!obsDuracaoPesquisa
  }, [obsDuracaoPesquisa])

  return (
    <>
      <FullCol sm={24} xs={24} className="flex-row-xxl-col">
        <FitCulturalCard>
          {cultura?.length === 0 || culturaDesejadaEmpresa.length === 0 ? (
            <Empty />
          ) : (
            <>
              <p>
                Fit Cultural é o resultado do que se é esperado do colaborador
                ou candidato em relação à Cultura Desejada definida pela alta
                direção da empresa.
              </p>
              <FullCol
                style={{ marginLeft: -20 }}
                sm={24}
                xs={24}
                className="flex-row-xxl-col"
              >
                <Chart
                  height={400}
                  data={fitCulturalEmpresaData}
                  autoFit
                  scale={arqRange}
                >
                  <Legend />
                  <Coordinate type="polar" radius={0.8} />
                  <Axis
                    name="Tipo"
                    line={null}
                    tickLine={null}
                    grid={{
                      lineStyle: {
                        lineDash: null,
                      },
                      hideFirstLine: false,
                    }}
                  />
                  <Tooltip />
                  <Axis
                    name="Valor"
                    line={null}
                    tickLine={null}
                    grid={{
                      type: 'polygon',
                      lineStyle: {
                        lineDash: null,
                      },
                    }}
                  />
                  <Line position="Tipo*Valor" size={2} color="Label" />
                  <Point
                    position="Tipo*Valor"
                    shape="circle"
                    size={4}
                    style={{
                      stroke: '#fff',
                      lineWidth: 1,
                      fillOpacity: 1,
                    }}
                    color="Label"
                  />
                </Chart>

                <ColFill xxl={16} sm={24} xs={24}>
                  <Label>Arquétipos Culturais</Label>
                  <p>
                    Neste gráfico, podemos ver a comparação da Cultura Desejada
                    da Empresa e do Potencial Cultural do indivíduo.
                  </p>
                  <p>
                    Caso um time não possua uma Cultura Desejada definida, será
                    a mesma da empresa.
                  </p>

                  <Label>Fit Arquétipos Culturais</Label>
                  <Row>
                    <Col xs={24} sm={24} style={noPadding}>
                      <FitCulturalTables
                        fitCultural={fitCulturalEmpresa}
                        title={false}
                      />
                    </Col>
                  </Row>
                </ColFill>
              </FullCol>
            </>
          )}
          {/* <Divider /> */}
          <FullCol sm={24} xs={24} className="flex-row-xxl-col">
            {/* TODO VALORES ORG */}
            <ColFill xxl={6} sm={24} xs={24} />
            <ColFill xxl={18} sm={24} xs={24} />
          </FullCol>
        </FitCulturalCard>
        <ColFill xs={24} sm={24} style={{ ...noPadding, marginTop: '16px' }}>
          <CardFill
            title="Fit Cultural"
            headStyle={cardHeadBlue}
            flexlayout="column"
          >
            <FitCulturalContainer
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '70%',
              }}
            >
              <div style={{ marginBottom: 11 }}>
                <span>Fit Geral</span>
                {fitCulturalEmpresa.geral ? (
                  <>
                    <Progress
                      type="dashboard"
                      percent={parseFloat(fitCulturalEmpresa.geral).toFixed(1)}
                      width={100}
                      strokeColor={pink}
                      status="normal"
                    />
                  </>
                ) : (
                  <Empty />
                )}
              </div>
              <div>
                <span>Fit Arquétipos Culturais</span>
                {fitCulturalEmpresa.fitArquetipos ? (
                  <>
                    <Progress
                      type="dashboard"
                      percent={parseFloat(
                        fitCulturalEmpresa.fitArquetipos.geral
                      ).toFixed(1)}
                      width={100}
                      strokeColor={pink}
                      status="normal"
                    />
                    <TooltipAnt
                      title={
                        <>
                          <p>
                            Aprendizado:{' '}
                            {`${
                              parseFloat(
                                fitCulturalEmpresa.fitArquetipos.Aprendizado
                              ).toFixed(1) || 0
                            }%`}
                          </p>
                          <p>
                            Autoridade:{' '}
                            {`${
                              parseFloat(
                                fitCulturalEmpresa.fitArquetipos.Autoridade
                              ).toFixed(1) || 0
                            }%`}
                          </p>
                          <p>
                            Clã:{' '}
                            {`${
                              parseFloat(
                                fitCulturalEmpresa.fitArquetipos.Clã
                              ).toFixed(1) || 0
                            }%`}
                          </p>
                          <p>
                            Colaboração:{' '}
                            {`${
                              parseFloat(
                                fitCulturalEmpresa.fitArquetipos.Colaboração
                              ).toFixed(1) || 0
                            }%`}
                          </p>
                          <p>
                            Complacência:{' '}
                            {`${
                              parseFloat(
                                fitCulturalEmpresa.fitArquetipos.Complacência
                              ).toFixed(1) || 0
                            }%`}
                          </p>
                          <p>
                            Empreendedora:{' '}
                            {`${
                              parseFloat(
                                fitCulturalEmpresa.fitArquetipos.Empreendedora
                              ).toFixed(1) || 0
                            }%`}
                          </p>
                          <p>
                            Inovação:{' '}
                            {`${
                              parseFloat(
                                fitCulturalEmpresa.fitArquetipos.Inovação
                              ).toFixed(1) || 0
                            }%`}
                          </p>
                          <p>
                            Regra:{' '}
                            {`${
                              parseFloat(
                                fitCulturalEmpresa.fitArquetipos.Regra
                              ).toFixed(1) || 0
                            }%`}
                          </p>
                          <hr />
                          <p>
                            Geral:{' '}
                            {`${
                              parseFloat(
                                fitCulturalEmpresa.fitArquetipos.geral
                              ).toFixed(1) || 0
                            }%`}
                          </p>
                        </>
                      }
                    >
                      <span className="fitcultural__details">Ver detalhes</span>
                    </TooltipAnt>
                  </>
                ) : (
                  <Empty />
                )}
              </div>
              <div>
                <span>Fit Valores Organizacionais</span>
                {fitCulturalEmpresa.fitValores ? (
                  <>
                    <Progress
                      type="dashboard"
                      percent={parseFloat(
                        fitCulturalEmpresa.fitValores.geral
                      ).toFixed(1)}
                      width={100}
                      strokeColor={pink}
                      status="normal"
                    />
                    <TooltipAnt
                      title={
                        <>
                          {fitCulturalEmpresa.fitValores.valores.map(
                            (valor) => (
                              <p>
                                {valor.TEXTO}:{' '}
                                {`${parseFloat(valor.VALOR).toFixed(1) || 0}%`}
                              </p>
                            )
                          )}
                          <hr />
                          <p>
                            Geral:{' '}
                            {`${
                              parseFloat(
                                fitCulturalEmpresa.fitValores.geral
                              ).toFixed(1) || 0
                            }%`}
                          </p>
                        </>
                      }
                    >
                      <span className="fitcultural__details">Ver detalhes</span>
                    </TooltipAnt>
                  </>
                ) : (
                  <Empty />
                )}
              </div>
            </FitCulturalContainer>
          </CardFill>
          <Card
            title="Observações do Relatório"
            id="bizcharts-container"
            headStyle={cardHeadBlue}
          >
            <Row>
              <Col sm={24} xs={24}>
                <Alert
                  message={messageObservacao}
                  type="warning"
                  style={{
                    marginTop: '24px',
                    fontSize: '12px',
                    width: '100%',
                  }}
                  showIcon
                />
                {obsDuracaoPesquisa && (
                  <>
                    <Alert
                      message={obsDuracaoPesquisa}
                      type="warning"
                      style={{
                        marginTop: '24px',
                        fontSize: '12px',
                        width: '100%',
                      }}
                      showIcon
                    />
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </ColFill>
      </FullCol>
    </>
  )
}

export function PerfilComportamentalTextual({
  type,
  principal,
  cultura = [],
  culturaEmpresa = [],
  fitCulturalEmpresa,
}) {
  const culturaDesejadaEmpresa = culturaEmpresa
    ? culturaEmpresa.filter((c) => c.Label === 'Desejado')
    : []

  const fitCulturalEmpresaData = [...cultura, ...culturaDesejadaEmpresa]

  return (
    <FullCol
      xxl={{ span: 24, order: 2 }}
      sm={{ span: 24, order: 2 }}
      xs={{ span: 24, order: 2 }}
    >
      <Row>
        <Col span={24} style={noPadding}>
          <Textual principal={principal} gutter={16} />
        </Col>
      </Row>
    </FullCol>
  )
}
