import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import {
  Row,
  Col,
  Card,
  Modal,
  Tooltip,
  Button as ButtonAntd,
  Form,
  DatePicker,
  Input,
  Select,
  InputNumber,
} from 'antd'

import 'moment/locale/pt-br'

import { toast } from 'react-toastify'
import moment from 'moment'

// CSS
import './ObjetivosEstrategicos.css'
import { useHistory } from 'react-router-dom'
import { sorterString, sorterDate } from '../../utils/utils'

// Constants

// Utils

// Components
import Button from '../../components/button/Button'
import Table from '../../components/editableTable/EditableTable'

// CSS
import './ObjetivosEstrategicos.css'
import { Footer } from './styles'

const { Option } = Select

const { TextArea } = Input

function ObjetivosEstrategicosView({
  onboarding = false,
  showModals,
  openModal,
  closeModal,
  onModalSubmit,
  dataListaCulturaDesejada,
  dataTipoCultura,
}) {
  const [listaCulturaDesejada, setListaculturaDesejada] = useState(
    dataListaCulturaDesejada
  )
  const [listaCulturaDesejadaTipoGet, setListaculturaDesejadaTipo] =
    useState(dataTipoCultura)
  const [description, setDescription] = useState(null)
  const [dataFinal, setDataFinal] = useState('')
  const [dataInicial, setDataInicial] = useState('')
  const [limiteMaximo, setLimiteMaximo] = useState('')
  const [limiteDentro, setLimiteDentro] = useState('')
  const [tipoCultura, setTipoCultura] = useState(1)
  const [statusCultura, setStatusCultura] = useState(1)
  const [culturaId, setCulturaId] = useState(1)

  useEffect(() => {
    setListaculturaDesejada(
      dataListaCulturaDesejada.map((item, index) => {
        return { ...item, id: index + 1, key: item.cultura_id }
      })
    )
  }, [dataListaCulturaDesejada])

  useEffect(() => {
    setListaculturaDesejadaTipo(dataTipoCultura)
  }, [dataTipoCultura])

  const [form] = Form.useForm()

  const history = useHistory()

  function handleAccess(id) {
    history.push(`dashboard/${id}/`)
  }

  const columns = [
    {
      title: 'Descrição',
      placeholder: 'Descrição',
      dataIndex: 'descricao',
      editable: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Tipo',
      placeholder: '#',
      dataIndex: 'cultura_tipo',
      render: (text) =>
        text == 1 ? <span>Cultura Única</span> : <span>SubCultura</span>,
    },
    {
      title: 'Data de Inicio',
      dataIndex: 'data_inicio',
      width: '10.5%',
      sorter: (a, b) => sorterDate(a, b, 'data_inicio'),
      render: (text) =>
        text
          ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
          : '',
    },
    {
      title: 'Data Final',
      dataIndex: 'data_final',
      width: '10%',
      sorter: (a, b) => sorterDate(a, b, 'data_final'),
      render: (text) =>
        text
          ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
          : '',
    },
    {
      title: 'Limite Dentro',
      dataIndex: 'limite_dentro',
    },
    {
      title: 'Limite Máximo',
      dataIndex: 'limite_maximo',
    },

    !onboarding && {
      title: 'Ações',
      align: 'center',
      key: 'extra_actions',
      render: (text, record) => null,
    },
  ]
  return (
    <>
      <Helmet>
        <title>TeamHub | Cultura Desejada</title>
      </Helmet>
      <div className="objetivos-estrategicos">
        <Row>
          <Col md={24}>
            <Card
              type="inner"
              className="objetivos-estrategicos__gestores objetivos-estrategicos__card"
            >
              <div className="objetivos-estrategicos__gestores__table">
                <Table
                  header={(record) => (
                    <>
                      <div className="objetivos-estrategicos__gestores__table__buttons">
                        <Tooltip title="Cadastrar uma nova cultura desejada">
                          <Button
                            type="primary"
                            className="btn_purple objetivos-estrategicos__gestores__table__buttons__desktop"
                            onClick={() => openModal('novaCulturaDesejada')}
                          >
                            Nova cultura desejada
                          </Button>
                        </Tooltip>
                      </div>
                    </>
                  )}
                  dataSource={listaCulturaDesejada}
                  columns={columns}
                  onDelete={(_, record) => null}
                  onSave={(data) => null}
                  isNavigable
                  key={(record) => {
                    return record.key
                  }}
                  editPondera={(item) => {
                    setDescription(item.descricao)
                    setDataFinal(item.data_final)
                    setDataInicial(item.data_inicio)
                    setLimiteMaximo(item.limite_maximo)
                    setLimiteDentro(item.limite_dentro)
                    setTipoCultura(item.cultura_tipo)
                    setStatusCultura(item.cultura_status)
                    setCulturaId(item.cultura_id)
                    openModal('updateCulturaDesejada')
                  }}
                  isEditModal
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Modal
          style={{ width: '300px' }}
          visible={showModals.novaCulturaDesejada}
          title="Adicionar nova cultura desejada"
          className="valoresorg__modal"
          centered
          onCancel={() => closeModal('novaCulturaDesejada')}
          footer={
            <Footer>
              <ButtonAntd onClick={() => closeModal('novaCulturaDesejada')}>
                CANCELAR
              </ButtonAntd>
              <ButtonAntd
                type="primary"
                onClick={() => {
                  if (limiteDentro >= limiteMaximo) {
                    toast.error(
                      'Limite dentro precisa ser menor que o limite máximo'
                    )
                    return
                  }
                  if (
                    !dataFinal ||
                    !description ||
                    !dataInicial ||
                    !limiteDentro ||
                    !limiteMaximo
                  ) {
                    toast.error('Preencha todos os campos')
                    return
                  }
                  if (dataFinal < dataInicial) {
                    toast.error(
                      'Data final não pode ser menor que data inicial'
                    )
                    return
                  }

                  onModalSubmit(handleAccess, 'novaCulturaDesejada', 0, {
                    descricao: description,
                    data_final: dataFinal,
                    data_inicio: dataInicial,
                    limite_maximo: limiteMaximo,
                    limite_dentro: limiteDentro,
                    cultura_tipo: tipoCultura,
                    cultura_status: 1,
                  })
                }}
              >
                SALVAR
              </ButtonAntd>
            </Footer>
          }
          destroyOnClose
        >
          <Form
            form={form}
            id="nova-cultura-submit"
            // onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              label="Faça a descrição da nova cultura desejada"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira a descrição.',
                },
              ]}
            >
              <Input
                showCount
                rows={4}
                placeholder="Descrição"
                maxLength={255}
                onChange={(res) => setDescription(res.currentTarget.value)}
                value={description}
              />
            </Form.Item>
            <Col>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Item
                  label="Data de inicio"
                  name="initialDate"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, insira a data inicial.',
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: '300px' }}
                    format="DD/MM/YYYY"
                    onChange={setDataInicial}
                    value={dataInicial}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '300px' }}
                  label="Data final"
                  name="finalDate"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, insira a data final.',
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: '300px' }}
                    format="DD/MM/YYYY"
                    onChange={setDataFinal}
                    value={dataFinal}
                  />
                </Form.Item>
              </div>
            </Col>

            <Form.Item
              label="Tipo de cultura"
              name="tipoCultura"
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira o tipo de cultura.',
                },
              ]}
            >
              <div>
                <Select
                  defaultValue={1}
                  onChange={setTipoCultura}
                  value={tipoCultura}
                >
                  {listaCulturaDesejadaTipoGet.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.descricao}
                      </Option>
                    )
                  })}
                </Select>
              </div>
            </Form.Item>
            <Col>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Item
                  label="Limite Dentro"
                  name="limiteDentro"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, insira o valor do limite de dentro.',
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '300px' }}
                    onChange={setLimiteDentro}
                    value={limiteDentro}
                  />
                </Form.Item>
                <Form.Item
                  label="Limite Máximo"
                  name="limiteMaximo"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, insira o limite máximo.',
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '300px' }}
                    onChange={setLimiteMaximo}
                    value={limiteMaximo}
                  />
                </Form.Item>
              </div>
            </Col>
          </Form>
        </Modal>
        <Modal
          style={{ width: '300px' }}
          visible={showModals.updateCulturaDesejada}
          title="EDITAR CULTURA DESEJADA"
          onCancel={() => closeModal('updateCulturaDesejada')}
          footer={
            <Footer>
              <ButtonAntd onClick={() => closeModal('updateCulturaDesejada')}>
                CANCELAR
              </ButtonAntd>
              <ButtonAntd
                type="primary"
                onClick={() =>
                  onModalSubmit('updateCulturaDesejada', culturaId, {
                    descricao: description,
                    data_final: dataFinal,
                    data_inicio: dataInicial,
                    limite_maximo: limiteMaximo,
                    limite_dentro: limiteDentro,
                    cultura_tipo: tipoCultura,
                    cultura_status: statusCultura,
                  })
                }
              >
                SALVAR
              </ButtonAntd>
            </Footer>
          }
          destroyOnClose
        >
          <Form
            form={form}
            id="editar-cultura-submit"
            layout="vertical"
            initialValues={{
              descricao: description,
              data_final: moment(dataFinal),
              data_inicio: moment(dataInicial),
              limite_maximo: limiteMaximo,
              limite_dentro: limiteDentro,
              cultura_tipo: tipoCultura,
              cultura_status: statusCultura,
            }}
          >
            <Col md={14} xs={14} sm={14}>
              <Form.Item
                label="Descrição"
                name="descricao"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira a descrição.',
                  },
                ]}
              >
                <TextArea
                  showCount
                  rows={4}
                  placeholder="Descrição"
                  maxLength={255}
                  onChange={(res) => setDescription(res.currentTarget.value)}
                />
              </Form.Item>
            </Col>
            <Col md={14} xs={14} sm={14}>
              <Form.Item
                label="Data de inicio"
                name="data_inicio"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira a data inicial.',
                  },
                ]}
              >
                <DatePicker onChange={setDataInicial} value={dataInicial} />
              </Form.Item>
            </Col>
            <Col md={14} xs={14} sm={14}>
              <Form.Item
                label="Data final"
                name="data_final"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira a data final.',
                  },
                ]}
              >
                <DatePicker onChange={setDataFinal} value={dataFinal} />
              </Form.Item>
            </Col>
            <Col md={14} xs={14} sm={14}>
              <Form.Item
                label="Tipo de cultura"
                name="cultura_tipo"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira o tipo de cultura.',
                  },
                ]}
              >
                <>
                  <Select
                    style={{ width: '300px' }}
                    defaultValue={1}
                    onChange={setTipoCultura}
                    value={tipoCultura}
                  >
                    {listaCulturaDesejadaTipoGet.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.descricao}
                        </Option>
                      )
                    })}
                  </Select>
                </>
              </Form.Item>
            </Col>
            <Col md={14} xs={14} sm={14}>
              <Form.Item
                label="Limite Dentro"
                name="limite_dentro"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira o valor do limite de dentro.',
                  },
                ]}
              >
                <InputNumber onChange={setLimiteDentro} value={limiteDentro} />
              </Form.Item>
            </Col>
            <Col md={14} xs={14} sm={14}>
              <Form.Item
                label="Limite Máximo"
                name="limite_maximo"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira o limite máximo.',
                  },
                ]}
              >
                <InputNumber onChange={setLimiteMaximo} value={limiteMaximo} />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default injectIntl(ObjetivosEstrategicosView)
