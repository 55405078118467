import React, { useCallback, useMemo, useState, useEffect } from 'react'

import moment from 'moment'

import 'moment-timezone'

import { Row, Card, Divider, Tooltip, Table, Tag } from 'antd'

import { useSelector, useDispatch } from 'react-redux'

import {
  UserOutlined,
  PlusSquareOutlined,
  RedoOutlined,
} from '@ant-design/icons'

import { LinkSpan } from '../../../ProcessoSeletivo/styles'
import { sorterString } from '../../../../../../utils/utils'

import DetalhesTestesCandidatos from '../../components/detalhesTestesCandidato'

function Testes({ objectQuizzes }) {
  const dispatch = useDispatch()

  const quizesProcessoSeletivo = objectQuizzes.quizzes

  const pontuacaoCandidatos = objectQuizzes.candidates

  const [showModalDetalhe, setShowModalDetalhe] = useState(false)

  const [candidatoSelecionado, setCandidatoSelecionado] = useState([])

  const [dataCandidatoSelecionado, setDataCandidatoSelecionado] = useState([])

  const handleOpenModalDetalhes = useCallback(
    (pes_id) => {
      setCandidatoSelecionado(pes_id)

      const data = pontuacaoCandidatos.find(
        (candidato) => candidato.pes_id === pes_id
      )

      setDataCandidatoSelecionado(data)

      setShowModalDetalhe(true)
    },
    [setCandidatoSelecionado, pontuacaoCandidatos]
  )

  const handleCloseModalDetalhes = (pes_id) => {
    setCandidatoSelecionado(1)

    setShowModalDetalhe(false)
  }

  const handleTesteColumns = useCallback((quizes) => {
    let columns = []

    quizes.forEach((quiz, index) => {
      columns = [
        ...columns,
        {
          title: quiz.titulo,
          dataIndex: ['pontuacao', index, 'nota'],
          align: 'center',
          sorter: (a, b) => a.nota - b.nota,
          render: (text, record) => {
            const pontuacaoAvaliacao = record.pontuacao.find(
              (avaliacao) => avaliacao.quiz_id === quiz.quiz_id
            )

            return (
              <>
                {pontuacaoAvaliacao.nota !== null ? (
                  <span>
                    {pontuacaoAvaliacao.nota} /
                    {pontuacaoAvaliacao.questao_quantidade}
                  </span>
                ) : (
                  <span style={{ color: '#718096' }}>Não respondido</span>
                )}

                {pontuacaoAvaliacao.status === 1 && (
                  <Tag style={{ marginLeft: '20px' }} color="success">
                    Aprovado
                  </Tag>
                )}
              </>
            )
          },
        },
      ]
    })

    return columns
  }, [])

  const tableTestesColumns = useMemo(() => {
    return [
      {
        title: 'Candidato',
        dataIndex: 'pes_nome',
        sorter: (a, b) => sorterString(a, b, 'pes_nome'),
        render: (text, record) => {
          return (
            <>
              <LinkSpan to={`/perfil/${record.pes_id}`}>{text}</LinkSpan>
            </>
          )
        },
      },
      ...handleTesteColumns(quizesProcessoSeletivo),
      {
        title: 'Ações',
        align: 'center',
        key: 'acoes',
        width: 100,
        fixed: 'right',
        render: (text, record) => {
          const notas = record.pontuacao.map((teste) => teste.nota)

          return (
            <>
              <Row
                style={{
                  width: '150px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Ver candidato">
                  <LinkSpan to={`/perfil/${record.pes_id}`}>
                    <UserOutlined />
                  </LinkSpan>
                </Tooltip>

                {!notas.every((nota) => nota === null) && (
                  <>
                    <Divider type="vertical" />
                    <Tooltip title="Ver detalhes">
                      <LinkSpan>
                        <PlusSquareOutlined
                          onClick={() => handleOpenModalDetalhes(record.pes_id)}
                        />
                      </LinkSpan>
                    </Tooltip>
                  </>
                )}

                {/* {notas.some((nota) => nota === null) && (
                  <>
                    <Divider type="vertical" />
                    <Tooltip title="Reenviar teste">
                      <LinkSpan>
                        <RedoOutlined color="#30318b" />
                      </LinkSpan>
                    </Tooltip>
                  </>
                )} */}
              </Row>
            </>
          )
        },
      },
    ]
  }, [handleTesteColumns, quizesProcessoSeletivo, handleOpenModalDetalhes])

  return (
    <>
      {objectQuizzes && (
        <>
          <Card>
            <div>
              <Table
                dataSource={pontuacaoCandidatos}
                columns={tableTestesColumns}
              />
            </div>
          </Card>
          {/* MODAL DETALHE CANDIDATO */}
          <DetalhesTestesCandidatos
            showModalDetalhe={showModalDetalhe}
            handleCloseModalDetalhes={handleCloseModalDetalhes}
            dataCandidato={dataCandidatoSelecionado}
            testesProcessoSeletivo={quizesProcessoSeletivo}
          />
        </>
      )}
    </>
  )
}

export default Testes
