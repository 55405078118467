import styled from 'styled-components'

import EditableTable from '../../../../components/editableTable/EditableTable'

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  button:last-of-type {
    margin-left: auto;
  }

  margin: 16px 0;
`

export const TableContainer = styled.div`
  .ant-table-content {
    background-color: #fff;
  }
`

export const Table = styled(EditableTable)<any>`
  .ant-table {
    background: none !important;
  }

  .ant-table-title {
    padding: 16px 0px;
  }
`

export const LinkSpan = styled.span`
  color: var(--purple);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
