import React, { useEffect, useState, useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getCulturaOrganizacional } from '../analiseCulturaLider/actions/analiseCulturaGeralActions'

import { getVetor, getPerfis } from './actions/perfilComportamentalActions'

import PerfilComportamentalView from './PerfilComportamentalView'

import { culturaOrgDefault } from './constants/perfilComportamentalConstants'

const PerfilComportamentalController = () => {
  const dispatch = useDispatch()

  const vetor = useSelector((state) => state.perfilComportamentalGeral.vetor)

  const perfis = useSelector((state) => state.perfilComportamentalGeral.perfis)

  const [culturaOrganizacional, setCulturaOrganizacional] =
    useState(culturaOrgDefault)

  const [discInstalado, setDiscInstalado] = useState([])

  const [showGraficoRadar, setShowGraficoRadar] = useState(true)

  useEffect(() => {
    dispatch(getVetor())
    dispatch(getPerfis())
    loadCulturaOrganizacional()
  }, [dispatch])

  useEffect(() => {
    function generateValues() {
      if (culturaOrganizacional.disc.geral) {
        const object = [
          {
            Tipo: 'Dominância',
            Valor: Number(culturaOrganizacional.disc.geral.entrega.D),
            Label: 'Instalado',
          },
          {
            Tipo: 'Influência',
            Valor: Number(culturaOrganizacional.disc.geral.entrega.I),
            Label: 'Instalado',
          },
          {
            Tipo: 'Estabilidade',
            Valor: Number(culturaOrganizacional.disc.geral.entrega.S),
            Label: 'Instalado',
          },
          {
            Tipo: 'Conformidade',
            Valor: Number(culturaOrganizacional.disc.geral.entrega.C),
            Label: 'Instalado',
          },
        ]

        setDiscInstalado([...object])
      }
    }

    generateValues()
  }, [culturaOrganizacional])

  const loadCulturaOrganizacional = useCallback(() => {
    dispatch(getCulturaOrganizacional()).then(({ response }) => {
      if (response.status === 200) {
        const { culturaOrganizacional: culturaOrg, disc: discData } =
          response.data

        setCulturaOrganizacional({
          culturaOrg,
          disc: discData,
        })
      }
    })
  }, [dispatch])

  const handleSwitchGraficoRadar = () => setShowGraficoRadar(!showGraficoRadar)

  return (
    <PerfilComportamentalView
      {...{
        ...vetor,
        perfis,
        showGraficoRadar,
        handleSwitchGraficoRadar,
        discInstalado,
      }}
    />
  )
}

export default PerfilComportamentalController
