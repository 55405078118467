/* eslint-disable no-await-in-loop */
import React, { Component } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import xlsxParser from 'xlsx-parse-json'
import { SearchOutlined } from '@ant-design/icons'
import { Input, Button } from 'antd'
import XLSX from 'xlsx'
import { format, parse, isValid } from 'date-fns'
import { enGB } from 'date-fns/locale'

// Components
import CadastroColaboradoresView from './CadastroColaboradoresView'

// CSS

// Constants
import { modals } from './constants/constantsCadastroColaboradores'

import { validateForm } from '../../utils/forms'

import { isLider } from '../../services/auth'

import { triarColaborador } from '../../components/FitCultural/Api/api'

import {
  SHEET_CPF_UNDEFINED,
  SHEET_EMAIL_UNDEFINED,
  SHEET_NAME_UNDEFINED,
  SHEET_NASCIMENTO_UNDEFINED,
  SHEET_SEXO_UNDEFINED,
  SHEET_NASCIMENTO_INVALID_FORMAT,
} from './constants/constantsCadastroColaboradores'

const formFields = [
  {
    name: 'nome',
    required: true,
  },
  {
    name: 'cpf',
    required: true,
  },
  {
    name: 'email',
    required: true,
  },
]

moment.prototype.toMySqlDateTime = function () {
  return this.format('YYYY-MM-DD HH:mm:ss')
}

class CadastroColaboradoresController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModals: [false, false, false, false, false, false, false],
      currentPessoa: undefined,
      tableCount: 1,
      modalStates: modals.map((modal) => ({ ...modal.initialState })),
      selectedRows: [],
      selectedRowKeys: [],
      filais: [],
      error: {},
    }
  }

  openModal = (modalId) => {
    this.setState((prevState) => ({
      showModal: { ...prevState.showModal, [modalId]: true },
    }))
  }

  closeModal = (modalId) => {
    this.setState((prevState) => ({
      showModal: { ...prevState.showModal, [modalId]: false },
    }))
  }

  componentDidMount = () => {
    const { getColaboradores, getTimes, getCargos, listFiliais } = this.props

    getColaboradores()

    listFiliais().then(({ response }) => {
      if (response.status === 200) {
        this.setState({ filiais: response.data })
      }
    })

    if (!isLider()) {
      const promisses = [getTimes(), getCargos()]

      Promise.all(promisses)
    }
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  handleChangeModal = (id, { target }) => {
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.value } : elem
      ),
    }))
  }

  handleChangeCheckbox = (id, { target }) => {
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.checked } : elem
      ),
    }))
  }

  openModal = (id) => {
    const { showModals } = this.state
    const newshowModals = showModals

    newshowModals[id] = true

    this.setState({ showModals: newshowModals })
  }

  closeModal = (id, { reset = true } = {}) => {
    const { showModals, modalStates } = this.state
    const newshowModals = showModals

    newshowModals[id] = false

    const newModalStates = modalStates

    if (reset) {
      newModalStates[id] = modals[id].initialState
    }

    this.setState({
      showModals: newshowModals,
      modalStates: newModalStates,
      error: {},
    })
  }

  okButtonProps = (id) => {
    if (id === 1) {
      const { fileList } = this.state.modalStates[1]

      return {
        disabled: fileList.length < 1 || fileList[0].status === 'error',
      }
    }
  }

  onModalSubmit = async (id, { sendToAll = false } = {}) => {
    const { modalStates, selectedRows } = this.state
    const {
      postColaboradores,
      postColaborador,
      getColaboradores,
      sendVetorComportamental,
      sendPercepcao,
      getTimes,
      getCargos,
      postTime,
      postCargo,
      sendValoresOrganizacionais,
    } = this.props

    if (id === 0) {
      let sucessoEnvioPesquisas = 0
      if (selectedRows.length > 0 || sendToAll) {
        let colaboradoresToSend = [...selectedRows]
        if (sendToAll) {
          const { listaColaboradores } = this.props
          colaboradoresToSend = [...listaColaboradores]
        }
        for (const row of colaboradoresToSend) {
          if (modalStates[id].vetorComportamental) {
            const values = {
              pessoas: [{ pes_id: row.key }],
              enviarEmails: modalStates[id].enviarEmails,
            }
            const sendVetor = await sendVetorComportamental(values)
            if (sendVetor) {
              const { response } = sendVetor
              if (response.status === 200) {
                sucessoEnvioPesquisas++
                if (sucessoEnvioPesquisas === colaboradoresToSend.length) {
                  toast.success('Enviado com sucesso!')
                }
              }
            }
          }

          if (modalStates[id].percepcaoAmbiente) {
            const sendValores = await sendPercepcao(row.key, {
              enviarEmails: modalStates[id].enviarEmails,
            })
            if (sendValores) {
              const { response } = sendValores
              if (response.status === 200) {
                sucessoEnvioPesquisas++
                if (sucessoEnvioPesquisas === colaboradoresToSend.length) {
                  toast.success('Enviado com sucesso!')
                }
              }
            }
          }
          if (modalStates[id].valoresOrganizacionais) {
            const sendValoresOrganizacional = await sendValoresOrganizacionais(
              row.key,
              {
                enviarEmails: modalStates[id].enviarEmails,
              }
            )
            if (sendValoresOrganizacional) {
              const { response } = sendValoresOrganizacional
              if (response.status === 200) {
                sucessoEnvioPesquisas++
                if (sucessoEnvioPesquisas === colaboradoresToSend.length) {
                  toast.success('Enviado com sucesso!')
                }
              }
            }
          }
        }

        this.closeModal(0)
      } else {
        this.closeModal(0, { reset: false })
        this.openModal(5)
      }
    } else if (id === 1) {
      postColaboradores({
        listaColaboradores: modalStates[id].listaColaboradores,
        vetorComportamental: modalStates[id].vetorComportamental,
        percepcaoAmbiente: modalStates[id].percepcaoAmbiente,
        enviarEmails: modalStates[id].enviarEmails,
        valoresOrganizacionais: modalStates[id].valoresOrganizacionais,
      }).then(({ response }) => {
        if (response.status === 200) {
          getColaboradores()
          toast.success('Colaboradores importados com sucesso')

          this.closeModal(1)
        }
      })
    } else if (id === 2) {
      const error = validateForm(formFields, modalStates[id])
      this.setState({ error: error.messages })

      if (error.hasError) return

      postColaborador(modalStates[id]).then(({ response }) => {
        if (response.status === 200) {
          getColaboradores()
          toast.success('Colaborador adicionado com sucesso')

          this.closeModal(id)
        }
      })
    } else if (id === 3) {
      postTime(modalStates[id]).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Adicionado com sucesso!')
          getTimes()
          this.closeModal(id)
        }
      })
    } else if (id === 4) {
      postCargo(modalStates[id]).then(({ response }) => {
        this.handleChange(
          {
            target: {
              name: 'datademissao',
              value: undefined,
            },
          },
          'desligamentoModal'
        )
        if (response.status === 200) {
          toast.success('Adicionado com sucesso!')
          getCargos()
          this.closeModal(id)
        }
      })
    } else if (id === 5) {
      this.closeModal(id)
      this.onModalSubmit(0, { sendToAll: true })
    } else if (id.id === 7) {
      const listOfColaboradores = []
      selectedRows.forEach((row) => {
        listOfColaboradores.push(row.key)
      })
      if (listOfColaboradores.length === 0) {
        toast.error('Selecione ao menos um colaborador')
        return
      }
      const data = { candidatos: listOfColaboradores, confirm: true }
      const response = await triarColaborador(id.selectedCargo, data)
      if (response.success) toast.success(response.message)
      else toast.error('Erro ao triar candidato')
    }
  }

  onTableDelete = (_, deleted) => {
    const { deleteColaborador, getColaboradores } = this.props

    deleteColaborador({ id: deleted.key }).then(({ response }) => {
      if (response.status === 200) {
        getColaboradores()
        toast.success('Colaborador desligado com sucesso')
      }
    })
  }

  onFileUpload = (file) => {
    const reader = new FileReader()
    const fileName = file.name

    const excelTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/xls',
    ]

    if (
      !excelTypes.includes(file.type) &&
      fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2) !== 'xlsx'
    ) {
      toast.error('O arquivo deve estar no formato .xlsx (Planilha Excel).')
      return false
    }

    reader.onload = () => {
      xlsxParser.onFileSelection(file).then((data) => {
        // Pega o objeto da planilha processado
        const WorksheetData = data[Object.keys(data)[0]]

        // Converte todas as keys de todos os objetos do array para Upper Case
        const Worksheet = WorksheetData.map((row, index) =>
          Object.keys(row).reduce((parsedWorksheet, key) => {
            if (key.toUpperCase() === 'NASCIMENTO') {
              const parsedDate = parse(row[key], 'P', new Date(), {
                locale: enGB,
              })

              if (isValid(parsedDate)) {
                const dataFormatada = format(new Date(parsedDate), 'M-d-yyyy')

                if (dataFormatada) {
                  parsedWorksheet[key.toUpperCase()] = dataFormatada
                } else {
                  parsedWorksheet[key.toUpperCase()] = { invalid: true }
                }
              } else {
                toast.info(
                  () => {
                    return (
                      <>
                        <p style={{ fontSize: 18 }}>
                          ➡ Erro na linha: <b>{index + 2}</b>
                        </p>
                        <p>
                          O colaborador <b>{row.NOME}</b> possui data de
                          nascimento em formato inválido -{' '}
                          <b>{row.NASCIMENTO}</b>.
                        </p>
                        <p>
                          É necessário utilizar seguinte padrão de data:{' '}
                          <b>dd/mm/yy</b>
                        </p>
                      </>
                    )
                  },
                  { autoClose: 4350 }
                )
              }
            } else parsedWorksheet[key.toUpperCase()] = row[key]
            return parsedWorksheet
          }, {})
        )

        if (!Worksheet || Worksheet.length === 0) {
          toast.error('Arquivo inválido ou vazio.')
          this.handleChangeModal(1, {
            target: { name: 'fileList', value: [] },
          })
        } else {
          const worksheetHeader = Worksheet[0]
          const headerProps = ['NOME', 'CPF', 'EMAIL', 'NASCIMENTO', 'SEXO']
          if (
            !headerProps.every((prop) => worksheetHeader.hasOwnProperty(prop))
          ) {
            toast.error(
              'Uma ou mais colunas do arquivo escolhido não estão de acordo com o modelo para importação.'
            )
          }

          const validationErrors = []
          for (const elem of Worksheet) {
            if (
              !elem.NOME &&
              !validationErrors.includes(SHEET_NAME_UNDEFINED)
            ) {
              validationErrors.push(SHEET_NAME_UNDEFINED)
            }
            if (!elem.CPF && !validationErrors.includes(SHEET_CPF_UNDEFINED)) {
              validationErrors.push(SHEET_CPF_UNDEFINED)
            }
            if (
              !elem.EMAIL &&
              !validationErrors.includes(SHEET_EMAIL_UNDEFINED)
            ) {
              validationErrors.push(SHEET_EMAIL_UNDEFINED)
            }
            if (
              !elem.NASCIMENTO &&
              !validationErrors.includes(SHEET_NASCIMENTO_UNDEFINED)
            ) {
              validationErrors.push(SHEET_NASCIMENTO_UNDEFINED)
            }
            if (
              elem.NASCIMENTO &&
              elem.NASCIMENTO.invalid &&
              !validationErrors.includes(SHEET_NASCIMENTO_INVALID_FORMAT)
            ) {
              validationErrors.push(SHEET_NASCIMENTO_INVALID_FORMAT)
            }
            if (
              !elem.SEXO &&
              !validationErrors.includes(SHEET_SEXO_UNDEFINED)
            ) {
              validationErrors.push(SHEET_SEXO_UNDEFINED)
            }
          }

          if (validationErrors.length === 0) {
            this.handleChangeModal(1, {
              target: { name: 'fileList', value: [file] },
            })
            this.handleChangeModal(1, {
              target: {
                name: 'listaColaboradores',
                value: Worksheet,
              },
            })
          } else {
            let errorString =
              (validationErrors.includes(SHEET_NASCIMENTO_INVALID_FORMAT) &&
                validationErrors.length > 1) ||
              (!validationErrors.includes(SHEET_NASCIMENTO_INVALID_FORMAT) &&
                validationErrors.length > 0)
                ? validationErrors.reduce(
                    (string, error) =>
                      (string += `${
                        error !== SHEET_NASCIMENTO_INVALID_FORMAT ? error : ''
                      }, `),
                    'Os seguintes campos não foram preenchidos e são obrigatórios : '
                  )
                : ''
            if (validationErrors.includes(SHEET_NASCIMENTO_INVALID_FORMAT)) {
              errorString = `${SHEET_NASCIMENTO_INVALID_FORMAT}\n\n${errorString}`
            }

            errorString = errorString.trim()

            errorString =
              errorString[errorString.length - 1] === '.'
                ? errorString
                : `${errorString.substring(0, errorString.length - 2)}.`

            this.handleChangeModal(1, {
              target: {
                name: 'fileList',
                value: [
                  {
                    ...file,
                    name: file.name,
                    status: 'error',
                    response: errorString,
                  },
                ],
              },
            })
          }
        }
      })
    }

    reader.readAsText(file)

    return false
  }

  onRemoveFile = () =>
    this.handleChangeModal(1, {
      target: { name: 'fileList', value: [] },
    })

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder="Buscar"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  excluirPerfil = (id) => {
    const { excluirPerfil, getColaboradores } = this.props

    excluirPerfil({ id }).then(({ response }) => {
      if (response.status === 200) {
        getColaboradores()
        toast.success('Colaborador excluido com sucesso')
      }
    })
  }

  exportarExcel = (colaboradores) => {
    const aoa = [
      [
        'Nome',
        'Email',
        'Cargo',
        'Times',
        'Filial',
        'Ultimo Feedback',
        'Data de Nascimento',
        'Data de Admissão',
      ],
      ...colaboradores.map((colaborador) => [
        colaborador.nome,
        colaborador.email,
        colaborador.cargo,
        colaborador.times,
        colaborador.nome_filial,
        colaborador.ultimofeedback &&
        moment(colaborador.ultimofeedback).isValid()
          ? moment(colaborador.ultimofeedback)
              .utc()
              .tz(moment.tz.guess())
              .format('DD/MM/YYYY')
          : null,
        colaborador.pes_datanascimento,
        colaborador.dataadmissao,
      ]),
    ]

    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(aoa)

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Export colaboradores')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'colaboradores.xlsx')
  }

  setCurrentPessoa = (pes_id = undefined) => {
    this.setState({ currentPessoa: pes_id })
  }

  closeModalDesligamento = () => {
    this.setCurrentPessoa()
    this.closeModal(6, { reset: false })
  }

  submitModalDesligamento = (data) => {
    this.props
      .desativarPerfil({
        id: this.state.currentPessoa,
        datademissao: data.datademissao,
      })
      .then(({ response }) => {
        if (response.status === 200 || response.status === 204) {
          this.closeModalDesligamento()
          this.props.getColaboradores()
        }
      })
  }

  render() {
    const {
      onTableDelete,
      onModalSubmit,
      openModal,
      closeModal,
      handleChangeModal,
      onFileUpload,
      onRemoveFile,
      okButtonProps,
      handleChangeCheckbox,
      getColumnSearchProps,
      excluirPerfil,
      exportarExcel,
      setCurrentPessoa,
      closeModalDesligamento,
      submitModalDesligamento,
    } = this
    const { showModals, tableCount, modalStates, error, filiais } = this.state
    const { listaColaboradores, times, cargos, onboarding } = this.props

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys,
          selectedRows,
        })
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }
    return (
      <CadastroColaboradoresView
        openModal={openModal}
        closeModal={closeModal}
        onModalSubmit={onModalSubmit}
        showModals={showModals}
        modalStates={modalStates}
        handleChangeModal={handleChangeModal}
        onTableDelete={onTableDelete}
        listaColaboradores={listaColaboradores}
        tableCount={tableCount}
        rowSelection={rowSelection}
        onFileUpload={onFileUpload}
        onRemoveFile={onRemoveFile}
        okButtonProps={okButtonProps}
        getColumnSearchProps={getColumnSearchProps}
        handleChangeCheckbox={handleChangeCheckbox}
        times={times}
        cargos={cargos}
        filiais={filiais}
        excluirPerfil={excluirPerfil}
        error={error}
        exportarExcel={exportarExcel}
        onboarding={onboarding}
        setCurrentPessoa={setCurrentPessoa}
        closeModalDesligamento={closeModalDesligamento}
        submitModalDesligamento={submitModalDesligamento}
      />
    )
  }
}

export default CadastroColaboradoresController
