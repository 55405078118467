import { YoutubeOutlined } from '@ant-design/icons'
import {
  Alert,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Tabs,
} from 'antd'
import { useCallback, useMemo, useState } from 'react'
import TinyEditor from '../../../../components/TinyEditor'
import { sorterString } from '../../../../utils/utils'
import { FaixaSalarialGroup, Form } from './styles'

const { Option } = Select

const { TabPane } = Tabs

function ProcessoSeletivoForm({
  getModulosConta,
  onFinish,
  cargos = [],
  loadingCargos,
  times = [],
  loadingTimes,
  ufs = [],
  loadingUfs,
  cidades = [],
  loadingCidades,
  loadCidades,
  readOnly = false,
  motivos = [],
  contratos = [],
  filiais = [],
  huntersOptions = [],
  slas = [],
  requisicao = false,
  setCargoId,
  mostrarAtividades,
  quizes = [],
  setDisableSaveButton,
}) {
  const accountModules = getModulosConta()

  const [form] = Form.useForm()

  const [homeoffice, setHomeoffice] = useState(false)

  const [publicada, setPublicada] = useState(false)

  const [enderecoCompleto, setEnderecoCompleto] = useState(false)
  const [sigiloso, setSigiloso] = useState(false)
  const [cep, setCep] = useState(false)
  const [logradouro, setLogradouro] = useState(false)
  const [bairro, setBairro] = useState(false)
  const [numero, setNumero] = useState(false)
  const [complemento, setComplemento] = useState(false)
  const [estado, setEstado] = useState(false)
  const [cidade, setCidade] = useState(false)

  const hasJobsModule = useMemo(() => {
    const jobsModule = accountModules.find(
      (accountModule) => accountModule.module.name === 'trabalhe_conosco'
    )

    if (!jobsModule || !jobsModule.enabled) return false

    return true
  }, [accountModules])

  const hasQuizModule = useMemo(() => {
    const quizModule = accountModules.find(
      (accountModule) => accountModule.module.name === 'quiz'
    )

    if (!quizModule || !quizModule.enabled) return false

    return true
  }, [accountModules])

  const [pcd, setPCD] = useState(false)

  const handleToggleSigiloso = useCallback(() => {
    if (sigiloso) {
      setSigiloso(false)
    } else {
      setSigiloso(true)
      setPublicada(false)
      form.setFieldsValue({
        publicada: false,
      })
    }
  }, [form, sigiloso])

  const handleToggleEndereco = useCallback(() => {
    if (enderecoCompleto) {
      setEnderecoCompleto(false)
      setCep(true)
      setLogradouro(true)
      setBairro(true)
      setNumero(true)
      setComplemento(true)
      setEstado(true)
      setCidade(true)
    } else {
      setEnderecoCompleto(true)
      setCep(false)
      setLogradouro(false)
      setBairro(false)
      setNumero(false)
      setComplemento(false)
      setEstado(false)
      setCidade(false)
      form.setFieldsValue({
        check_endereco_completo: true,
        check_cep: false,
        check_logradouro: false,
        check_bairro: false,
        check_numero: false,
        check_complemento: false,
        check_estado: false,
        check_cidade: false,
      })
    }
  }, [form, enderecoCompleto])

  const cargosSorted = useMemo(() => {
    return cargos.sort((a, b) => sorterString(a, b, 'cargo_nome'))
  }, [cargos])

  const timesSorted = useMemo(() => {
    return times.sort((a, b) => sorterString(a, b, 'grupo_nome'))
  }, [times])
  const huntersSorted = useMemo(() => {
    return huntersOptions.sort((a, b) => sorterString(a, b, 'label'))
  }, [huntersOptions])

  const filiaisSorted = useMemo(() => {
    return filiais.sort((a, b) => sorterString(a, b, 'label'))
  }, [filiais])

  const slaSorted = useMemo(() => {
    return slas.sort((a, b) => sorterString(a, b, 'label'))
  }, [slas])

  const contratosSorted = useMemo(() => {
    return contratos.sort((a, b) => sorterString(a, b, 'label'))
  }, [contratos])

  const motivosSorted = useMemo(() => {
    return motivos.sort((a, b) => sorterString(a, b, 'label'))
  }, [motivos])

  const ufsSorted = useMemo(() => {
    return ufs.sort((a, b) => sorterString(a, b, 'UF'))
  }, [ufs])

  const cidadesSorted = useMemo(() => {
    return cidades.sort((a, b) => sorterString(a, b, 'CIDADE'))
  }, [cidades])

  const quizesSorted = useMemo(() => {
    return quizes.sort((a, b) => sorterString(a, b, 'titulo'))
  }, [quizes])

  const [resultados, setResultados] = useState()
  const [cargo, setCargo] = useState()
  const [mostrar, setMostrar] = useState(false)
  const options = []
  for (let i = 0; i < 9; i++) {
    const value = `${i.toString(36)}${i}`
    options.push({
      value,
      disabled: i === 10,
    })
  }

  const handleFilterCargoId = useCallback(
    (id) => {
      let resultados = ''
      let cargoId = 0

      // eslint-disable-next-line prettier/prettier
      const CargoAtividade = mostrarAtividades.filter(
        (cargo) => cargo.cargo_id === id
      )
      CargoAtividade.forEach((indice) => {
        resultados += '<h3>Propósito: </h3>'
        resultados +=
          indice.proposito && indice.proposito
            ? `<p>&#8226 ${indice.proposito} </p>`
            : ''
        cargoId = indice.cargo_id
        setCargo(cargoId)
        resultados += ' <h3>Atividades</h3>'
        indice.cargoAtividades.forEach((value) => {
          resultados += `<p>&#8226 ${value.oquefaz}  </p>`
        })
        resultados += '<h3>Ferramentas / Sistemas</h3>'
        indice.cargoFerramenta.forEach((value) => {
          resultados += `<p>&#8226 ${value.nome} </p>`
        })
        resultados += '<h3>Técnicas</h3>'
        indice.cargoFormacao.forEach((value) => {
          resultados += `<p>&#8226 ${value.nome} </p>`
        })
        resultados += '<h3>Requisito de Formação</h3>'
        indice.cargoTecnicas.forEach((value) => {
          resultados += `<p>&#8226 ${value.nome} </p>`
        })
        setResultados(resultados)
        form.setFieldsValue({
          descricao: resultados,
        })
      })
    },
    [form, mostrarAtividades]
  )

  return (
    <Form
      layout="vertical"
      id="req-processo-seletivo"
      onFinish={onFinish}
      onFinishFailed={(item) => {
        for (let i = 0; item.errorFields.length > i; i++) {
          message.error(item.errorFields[i].errors[0])
        }
      }}
      // eslint-disable-next-line react/jsx-boolean-value
      scrollToFirstError={true}
      form={form}
    >
      <Col span={24}>
        <Tabs
          defaultActiveKey="1"
          tabBarStyle={{
            marginBottom: '8px',
            paddingRight: '8px',
            paddingLeft: '8px',
          }}
          onChange={() => setDisableSaveButton(false)}
        >
          <TabPane tab={<span>Informações Básicas</span>} key="1">
            <Row gutter={20}>
              <Col xs={24} sm={14} lg={8}>
                <Form.Item
                  label="Selecione um cargo para esta vaga "
                  name="cargo_id"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, selecione um cargo para esta vaga.',
                    },
                  ]}
                >
                  {cargos && (
                    <Select
                      allowClear
                      readOnly={readOnly}
                      placeholder="Selecione"
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      loading={loadingCargos}
                      onChange={(id) => handleFilterCargoId(id)}
                    >
                      {cargosSorted.map(({ cargo_nome, cargo_id }) => (
                        <Option value={cargo_id} key={cargo_id}>
                          {cargo_nome}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={10} lg={8}>
                <Form.Item
                  label="Quantidade de Vagas"
                  name="qtde"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, informe a quantidade de vagas.',
                    },
                  ]}
                >
                  <InputNumber
                    readOnly={readOnly}
                    disabled={readOnly}
                    min={0}
                    max={10000}
                    withoutMargin
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label="Tipo de Divulgação" name="tipo">
                  <Select
                    allowClear
                    placeholder="Selecione o tipo de processo"
                    showSearch
                    withoutMargin
                    options={[
                      { label: 'Externo', value: 'E' },
                      { label: 'Interno', value: 'I' },
                      { label: 'Hibrido', value: 'H' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row gutter={20}>
              <Col xs={6} sm={8}>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  name="sigiloso"
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={readOnly}
                    onChange={() => handleToggleSigiloso()}
                  >
                    Sigiloso
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={6} sm={8}>
                <Form.Item name="pcd" valuePropName="checked">
                  <Checkbox
                    onChange={() => setPCD((state) => !state)}
                    disabled={readOnly}
                  >
                    Vaga para PCD
                  </Checkbox>
                </Form.Item>
              </Col>
              {!!pcd && (
                <Col xs={8} sm={8}>
                  <Form.Item label="Tipo de PCD (opcional)" name="pcd_tipo">
                    <Select
                      allowClear
                      placeholder="Selecione o tipo"
                      showSearch
                      withoutMargin
                      disabled={readOnly}
                      options={[
                        { label: 'Todos', value: null },
                        { label: 'Física', value: 'Física' },
                        { label: 'Auditiva', value: 'Auditiva' },
                        { label: 'Visual', value: 'Visual' },
                        { label: 'Mental', value: 'Mental' },
                        { label: 'Múltipla', value: 'Múltipla' },
                      ]}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Divider />
            <Row gutter={20}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Prioridade" name="prioridade">
                  <Select
                    allowClear
                    placeholder="Selecione o tipo de prioridade"
                    showSearch
                    withoutMargin
                    options={[
                      { label: 'Baixa', value: 1 },
                      { label: 'Média', value: 2 },
                      { label: 'Alta', value: 3 },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="SLA" name="sla_id">
                  <Select
                    allowClear
                    placeholder="Selecione o SLA"
                    showSearch
                    options={slaSorted}
                    filterOption={(input, option) =>
                      option.props.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item name="numero" label="Número">
                  <Input readOnly={readOnly} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={6}>
                <Form.Item
                  label="Preencher vaga até"
                  name="concluirem"
                  rules={[
                    {
                      required: true,
                      message:
                        'Por favor, selecione o prazo de conclusão deste processo',
                    },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} disabled={readOnly} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Time" name="grupo_id">
                  {times && (
                    <Select
                      allowClear
                      readOnly={readOnly}
                      placeholder="Selecione"
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      loading={loadingTimes}
                    >
                      {timesSorted.map(({ grupo_nome, grupo_id }) => (
                        <Option value={grupo_id} key={grupo_id}>
                          {grupo_nome}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Tipo de contrato" name="contrato_id">
                  <Select
                    allowClear
                    placeholder="Selecione o tipo de contrato"
                    showSearch
                    options={contratosSorted}
                    filterOption={(input, option) =>
                      option.props.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Recrutador(a)" name="responsavel">
                  <Select
                    allowClear
                    placeholder="Selecione o hunter"
                    showSearch
                    options={huntersSorted}
                    filterOption={(input, option) =>
                      option.props.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Filial" name="filial_id">
                  <Select
                    allowClear
                    placeholder="Selecione a filial"
                    showSearch
                    options={filiaisSorted}
                    filterOption={(input, option) =>
                      option.props.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Motivo da Requisição" name="motivo_id">
                  <Select
                    allowClear
                    placeholder="Selecione o motivo"
                    showSearch
                    options={motivosSorted}
                    filterOption={(input, option) =>
                      option.props.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item name="homeoffice" valuePropName="checked">
                  <Checkbox onChange={() => setHomeoffice((state) => !state)}>
                    Trabalho Remoto (Home Office)
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={8} sm={8} lg={6}>
                <Form.Item label="UF" name="ufid">
                  {ufs && (
                    <Select
                      allowClear
                      readOnly={readOnly}
                      placeholder="Selecione"
                      style={{ width: '100%' }}
                      showSearch
                      disabled={homeoffice}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      loading={loadingUfs}
                      onChange={(value) => loadCidades(value)}
                    >
                      {ufsSorted.map(({ UFID, UF }) => (
                        <Option value={UFID} key={UFID}>
                          {UF}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={16} sm={16} lg={6}>
                <Form.Item label="Cidade" name="cidadeid">
                  {cidades && (
                    <Select
                      allowClear
                      readOnly={readOnly}
                      placeholder="Selecione"
                      style={{ width: '100%' }}
                      disabled={homeoffice}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      loading={loadingCidades}
                    >
                      {cidadesSorted.map(({ CIDADEID, CIDADE }) => (
                        <Option value={CIDADEID} key={CIDADEID}>
                          {CIDADE}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} lg={6}>
                <Form.Item label="Salário" name="salario" key="salario_k">
                  <InputNumber
                    readOnly={readOnly}
                    disabled={readOnly}
                    min={0}
                    max={10000000}
                    decimalSeparator=","
                    parser={(value) => value.replace(/R\$\s?|(,*)/g, '')}
                    formatter={(value) => `R$ ${value}`}
                    withoutMargin
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={16} lg={6}>
                <Form.Item label="Jornada" name="jornada" key="jornada_k">
                  <Input
                    readOnly={readOnly}
                    placeholder="Ex: 40 horas semanais"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
          </TabPane>
          <TabPane tab={<span>Divulgação no Trabalhe Conosco</span>} key="2">
            {!hasJobsModule && (
              <Alert
                type="warning"
                message="Para divulgar a vaga é preciso adquirir o módulo Trabalhe Conosco"
                description="O módulo Trabalhe Conosco permitir criar páginas públicas para suas vagas, possibilitando a candidatura e catalogação automática dos candidatos."
              />
            )}
            {!requisicao && ( // Esconde publicação caso seja uma requisição
              <Row gutter={20}>
                <Col xs={24} sm={24}>
                  <Form.Item valuePropName="checked" name="publicada">
                    <Checkbox
                      disabled={sigiloso || readOnly || !hasJobsModule}
                      onChange={() => setPublicada((state) => !state)}
                    >
                      Publicar vaga
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={20}>
              <Col xs={24} sm={24} md={24} xl={8}>
                <div className="ant-col ant-form-item-label">
                  <span>Faixa Salarial</span>
                </div>

                <FaixaSalarialGroup>
                  <Input.Group compact>
                    <Form.Item name="salario_faixa_min" dis>
                      <InputNumber
                        readOnly={readOnly}
                        min={0}
                        max={10000000}
                        decimalSeparator=","
                        parser={(value) => value.replace(/R\$\s?|(,*)/g, '')}
                        formatter={(value) => `R$ ${value}`}
                        withoutMargin
                        style={{ width: '100%' }}
                        placeholder="Min"
                      />
                    </Form.Item>
                    <Input className="divider" placeholder="~" disabled />
                    <Form.Item name="salario_faixa_max">
                      <InputNumber
                        readOnly={readOnly}
                        min={0}
                        max={10000000}
                        decimalSeparator=","
                        parser={(value) => value.replace(/R\$\s?|(,*)/g, '')}
                        formatter={(value) => `R$ ${value}`}
                        withoutMargin
                        style={{ width: '100%' }}
                        placeholder="Max"
                      />
                    </Form.Item>
                  </Input.Group>
                </FaixaSalarialGroup>
              </Col>
              <Col xs={24} sm={24} md={12} xl={8}>
                <Form.Item name="video" label="Vídeo de apresentação">
                  <Input
                    readOnly={readOnly}
                    placeholder="URL do vídeo no youtube"
                    prefix={<YoutubeOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} xl={8}>
                <Form.Item name="fim_publicacao" label="Fim da publicação">
                  <DatePicker style={{ width: '100%' }} disabled={readOnly} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={24} style={{ marginBottom: 16 }}>
                <Form.Item
                  label="Descrição"
                  name="descricao"
                  rules={[{ required: true }]}
                >
                  <TinyEditor
                    value={resultados}
                    onChange={setResultados}
                    // key={Math.floor(Math.random() * 100)}
                    key={cargo}
                    initialValue={resultados}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Col xs={24} sm={24} lg={24}>
              <Form.Item label="Observação" name="observacao">
                <Input.TextArea
                  readOnly={readOnly}
                  style={{ height: '100px' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={24}>
              <Row>
                <span style={{ fontWeight: 'bold' }}>
                  Informações Obrigatórias
                </span>
              </Row>
              <Row>
                <span style={{ marginTop: 24 }}>
                  Selecione informações de preenchimento obrigatório para o
                  candidato concorrer a vaga
                </span>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: 12,
                    marginTop: 15,
                  }}
                >
                  Informações básicas
                </span>
              </Row>
              <Row>
                <Form.Item name="check_nome_completo" valuePropName="checked">
                  <Checkbox disabled={readOnly}>Nome Completo</Checkbox>
                </Form.Item>
                <Form.Item name="check_email" valuePropName="checked">
                  <Checkbox>E-mail</Checkbox>
                </Form.Item>
                <Form.Item name="check_cpf" valuePropName="checked">
                  <Checkbox>CPF</Checkbox>
                </Form.Item>
                <Form.Item name="check_genero" valuePropName="checked">
                  <Checkbox>Gênero</Checkbox>
                </Form.Item>
                <Form.Item name="check_data_nascimento" valuePropName="checked">
                  <Checkbox>Data de Nascimento</Checkbox>
                </Form.Item>
                <Form.Item name="check_celular" valuePropName="checked">
                  <Checkbox>Celular</Checkbox>
                </Form.Item>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: 12,
                  }}
                >
                  Endereço
                </span>
              </Row>
              <Row>
                <Form.Item
                  name="check_endereco_completo"
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={readOnly}
                    onChange={() => handleToggleEndereco()}
                  >
                    Endereço Completo
                  </Checkbox>
                </Form.Item>
                <Form.Item name="check_cep" valuePropName="checked">
                  <Checkbox
                    disabled={enderecoCompleto || readOnly}
                    onChange={() => setCep((state) => !state)}
                  >
                    CEP
                  </Checkbox>
                </Form.Item>
                <Form.Item name="check_logradouro" valuePropName="checked">
                  <Checkbox disabled={enderecoCompleto || readOnly}>
                    Logradouro
                  </Checkbox>
                </Form.Item>
                <Form.Item name="check_bairro" valuePropName="checked">
                  <Checkbox disabled={enderecoCompleto || readOnly}>
                    Bairro
                  </Checkbox>
                </Form.Item>
                <Form.Item name="check_numero" valuePropName="checked">
                  <Checkbox disabled={enderecoCompleto || readOnly}>
                    Número
                  </Checkbox>
                </Form.Item>
                <Form.Item name="check_complemento" valuePropName="checked">
                  <Checkbox disabled={enderecoCompleto || readOnly}>
                    Complemento
                  </Checkbox>
                </Form.Item>
                <Form.Item name="check_estado" valuePropName="checked">
                  <Checkbox disabled={enderecoCompleto || readOnly}>
                    Estado
                  </Checkbox>
                </Form.Item>
                <Form.Item name="check_cidade" valuePropName="checked">
                  <Checkbox disabled={enderecoCompleto || readOnly}>
                    Cidade
                  </Checkbox>
                </Form.Item>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: 12,
                  }}
                >
                  Informações Profissionais
                </span>
              </Row>
              <Row>
                <Form.Item name="check_profissao" valuePropName="checked">
                  <Checkbox>Profissão</Checkbox>
                </Form.Item>
                <Form.Item
                  name="check_pretensao_salarial"
                  valuePropName="checked"
                >
                  <Checkbox>Pretensão salarial</Checkbox>
                </Form.Item>
                <Form.Item name="check_linkedin" valuePropName="checked">
                  <Checkbox>Linkedin</Checkbox>
                </Form.Item>
                <Form.Item name="check_twitter" valuePropName="checked">
                  <Checkbox>Twitter</Checkbox>
                </Form.Item>
                <Form.Item name="check_site_portfolio" valuePropName="checked">
                  <Checkbox>Link Site/Portfólio</Checkbox>
                </Form.Item>
                <Form.Item name="check_indicacao" valuePropName="checked">
                  <Checkbox>Indicação</Checkbox>
                </Form.Item>
                <Form.Item
                  name="check_video_apresentacao"
                  valuePropName="checked"
                >
                  <Checkbox>Link para vídeo de apresentação</Checkbox>
                </Form.Item>
                <Form.Item name="check_curriculo" valuePropName="checked">
                  <Checkbox>Currículo</Checkbox>
                </Form.Item>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: 'bold',
                    marginTop: 20,
                  }}
                >
                  Avaliações
                </span>
              </Row>
              {/* <Row>
                <span style={{ marginTop: 15 }}>
                  Selecione testes para preenchimento obrigatório dos
                  candidatos.
                </span>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: 12,
                    marginTop: 15,
                  }}
                >
                  Testes da plataforma
                </span>
              </Row>
              <Row>
                <Form.Item
                  name="check_teste_perfil_comportamental"
                  valuePropName="checked"
                >
                  <Checkbox>Perfil comportamental</Checkbox>
                </Form.Item>
                <Form.Item
                  name="check_teste_percepcao_ambiente"
                  valuePropName="checked"
                >
                  <Checkbox>Percepção do Ambiente</Checkbox>
                </Form.Item>
                <Form.Item
                  name="check_teste_valores_organizacionais"
                  valuePropName="checked"
                >
                  <Checkbox>Valores Organizacionais</Checkbox>
                </Form.Item>
              </Row> */}

              <Row>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: 12,
                  }}
                >
                  Testes criados
                </span>
              </Row>
              <Row>
                <Form.Item label="Selecione os testes obrigatórios" name="quiz">
                  <Select
                    allowClear
                    readOnly={readOnly}
                    disabled={!hasQuizModule}
                    mode="multiple"
                    style={{ width: '100%' }}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {quizesSorted.map(({ quiz_id, titulo }) => (
                      <Option value={quiz_id} key={quiz_id}>
                        {titulo}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>
              {!hasQuizModule && (
                <Alert
                  style={{ marginBottom: '12px' }}
                  type="warning"
                  message="Para adicionar testes criados é preciso adquirir o módulo Quiz"
                  description="O módulo Quiz permitir criar testes com questões e configurações personalizadas, facilitando a triagem dos candidatos pela plataforma."
                />
              )}
            </Col>
          </TabPane>
        </Tabs>
      </Col>
    </Form>
  )
}

export default ProcessoSeletivoForm
