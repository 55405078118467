import React from 'react'
import { Button as ButtonAntd } from 'antd'

// Components

// CSS
import './Button.css'

function Button({ children, color, className = '', ...props }) {
  return (
    <ButtonAntd className={`button ${color || ''} ${className}`} {...props}>
      {children}
    </ButtonAntd>
  )
}

export default Button
