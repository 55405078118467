import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { InputNumber as Input, Form } from 'antd'

export default function InputNumberField({
  name,
  label,
  placeholder,
  onChange,
  require,
  min,
  max,
  step,
  rules,
  ...rest
}) {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: (ref) => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <Form layout="vertical">
      <Form.Item label={label} name={name} rules={rules}>
        <Input
          type="number"
          ref={inputRef}
          defaultValue={defaultValue}
          placeholder={placeholder}
          required={require}
          min={min}
          max={max}
          step={step}
          onChange={(e) => {
            if (onChange) {
              onChange(e)
            }
          }}
          {...rest}
        />
      </Form.Item>
    </Form>
  )
}
