import React from 'react'
import { toast } from 'react-toastify'
import {
  ExclamationCircleFilled,
  LinkOutlined,
  PoweroffOutlined,
  SendOutlined,
  DashboardOutlined,
  RetweetOutlined,
} from '@ant-design/icons'
import {
  Table,
  Divider,
  Button,
  Badge,
  Popconfirm,
  message,
  Tooltip,
} from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import moment from 'moment'
import 'moment-timezone'

// Utils
import { sorterDate } from '../../../utils/utils'
import { getContaURL, isLider } from '../../../services/auth'
import Link from '../../../components/privateLink/PrivateLink'

function TabPesquisas({
  openModal,
  percepcaoAmbiente,
  vetorComportamental,
  getColumnSearchProps,
  getVetorComportamental,
  getPercepcaoAmbiente,
  sendVetorComportamental,
  sendPercepcao,
  cancelPercepcao,
  cancelVetorComportamental,
  cancelValoresOrganizacionais,
  id,
  valoresOrganizacionais,
  getValoresOrganizacionais,
}) {
  const lider = isLider()
  const columnsPesquisas = [
    {
      title: 'Status',
      dataIndex: 'respondido',
      align: 'center',
      sorter: (a, b) => a.respondido - b.respondido,
      sortDirections: ['descend', 'ascend'],
      render: (text) => {
        return <Badge status={text === 1 ? 'success' : 'error'} />
      },
    },
    {
      title: 'Enviado Em',
      dataIndex: 'data_solicitacao',
      align: 'center',
      sorter: (a, b) =>
        sorterDate(a, b, 'data_solicitacao', 'DD/MM/YYYY (HH:mm)'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => text || '',
      ...getColumnSearchProps('data_solicitacao', 'data envio'),
    },
    {
      title: 'Respondido Em',
      dataIndex: 'data_entrega',
      align: 'center',
      sorter: (a, b) => sorterDate(a, b, 'data_entrega', 'DD/MM/YYYY (HH:mm)'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => text || '',
      ...getColumnSearchProps('data_entrega', 'data resposta'),
    },
    {
      title: 'Ações',
      key: 'acoes',
      align: 'center',
      render: (text, record) => {
        return record.respondido === 1 || record.data_entrega ? (
          record.tipo === 'Perfil Comportamental' ? (
            <Link
              key={record.pes_id}
              to={`/dashboard?colaborador=${record.pes_id}&avaliacao=${record.avalia_id}`}
            >
              <Tooltip placement="bottom" title="Ver na Dashboard">
                <DashboardOutlined />
              </Tooltip>
            </Link>
          ) : (
            ''
          )
        ) : !lider ? (
          <span key={record.pes_id}>
            <CopyToClipboard
              text={`${window.location.host}/${getContaURL()}/pesquisas/${
                record.tipo === 'Perfil Comportamental'
                  ? 'perfil-comportamental'
                  : record.tipo === 'Valores Organizacionais'
                  ? 'valores-organizacionais'
                  : 'percepcao-ambiente'
              }/${record.hashacesso}`}
              onCopy={() =>
                toast.success(
                  'Link da pesquisa copiado para a área de transferência.'
                )
              }
            >
              <a href="javascript:;" aria-label="link">
                <Tooltip placement="bottom" title="Link da Pesquisa">
                  <LinkOutlined />
                </Tooltip>
              </a>
            </CopyToClipboard>
            <Divider type="vertical" />
            <Popconfirm
              title="Reenviar pesquisa?"
              onConfirm={() => {
                if (record.tipo === 'Perfil Comportamental')
                  sendVetorComportamental({
                    pessoas: [{ pes_id: id }],
                    enviarEmails: true,
                  }).then(({ response }) => {
                    if (response.status === 200) {
                      toast.success('Enviado com sucesso!')
                    }
                  })
                else
                  sendPercepcao(id, { enviarEmails: true }).then(
                    ({ response }) => {
                      if (response.status === 200) {
                        toast.success('Enviado com sucesso!')
                      }
                    }
                  )
              }}
              okText="Sim"
              cancelText="Não"
              icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
            >
              <a href="javascript:;" aria-label="reenviar">
                <Tooltip placement="bottom" title="Reenviar">
                  <RetweetOutlined />
                </Tooltip>
              </a>
            </Popconfirm>
            <Divider type="vertical" />
            <Popconfirm
              title="Cancelar pesquisa?"
              onConfirm={() => {
                const canceledId = record.pes_id || id

                if (record.tipo === 'Perfil Comportamental')
                  cancelVetorComportamental(canceledId, record.avalia_id).then(
                    ({ response }) => {
                      if (response.status === 200) {
                        toast.success('Cancelado com sucesso!')
                      }
                      getVetorComportamental({ id: canceledId })
                    }
                  )
                else if (record.tipo === 'Pesquisa de Percepção')
                  cancelPercepcao(canceledId, record.avalia_id).then(
                    ({ response }) => {
                      if (response.status === 200) {
                        toast.success('Cancelado com sucesso!')
                      }
                      getPercepcaoAmbiente({ id: canceledId })
                    }
                  )
                else if (record.tipo === 'Valores Organizacionais')
                  cancelValoresOrganizacionais(
                    canceledId,
                    record.avalia_id
                  ).then(({ response }) => {
                    if (response.status === 200) {
                      toast.success('Cancelado com sucesso!')
                    }
                    getValoresOrganizacionais({ id: canceledId })
                  })
              }}
              okText="Sim"
              cancelText="Não"
              icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
            >
              <a href="javascript:;" aria-label="cancelar">
                <Tooltip placement="bottom" title="Cancelar">
                  <PoweroffOutlined />
                </Tooltip>
              </a>
            </Popconfirm>
          </span>
        ) : null
      },
    },
  ]

  return (
    <div className="perfil__tabs__pesquisas">
      <div className="perfil__tabs__pesquisas__table">
        {!lider && (
          <>
            <div className="perfil__tabs__pesquisas__table__title">
              <h3>Percepção do ambiente</h3>

              {!lider && (
                <Button
                  type="primary"
                  onClick={() => openModal('enviarPesquisa')}
                  icon={<SendOutlined />}
                >
                  GERAR PESQUISA
                </Button>
              )}
            </div>
            <Table
              bordered
              columns={columnsPesquisas}
              dataSource={percepcaoAmbiente}
            />

            <Divider />
          </>
        )}

        <div className="perfil__tabs__pesquisas__table__title">
          <h3>Perfil Comportamental</h3>
        </div>
        <Table
          bordered
          columns={columnsPesquisas}
          dataSource={vetorComportamental}
        />

        <Divider />
        <div className="perfil__tabs__pesquisas__table__title">
          <h3>Valores Organizacionais</h3>
        </div>
        <Table
          bordered
          columns={columnsPesquisas}
          dataSource={valoresOrganizacionais}
        />
      </div>
    </div>
  )
}

export default TabPesquisas
