// Redux types
import * as constants from '../constants/constantsTimesDetalhes'
import { message } from 'antd'

export const timesDetalhes = (state = {}, action) => {
  switch (action.type) {
    case constants.TIME_PESSOA_DELETE: {
      const { data, status } = action.response

      if (status !== 200) {
        message.error('Erro ao remover, tente novamente.')
        return { ...state }
      }

      message.success(data.message)

      return {
        ...state,
      }
    }
    case constants.SET_ACTIVE_TAB_TIMES: {
      return { ...state, activeTab: action.value }
    }
    default:
      return state
  }
}
