import React from 'react'
import { injectIntl } from 'react-intl'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Row, Col } from 'antd'

// Components
import Input, {
  MaskedInput,
  TextArea,
} from '../../../../components/input/Input'
import Select, { Option } from '../../../../components/select/Select'

// Constants

function NovoObjetivo({
  intl,
  handleChangeModal,
  listaObjetivosTeamhub,
  objetivo,
  descricao,
  obj_id,
  obj_conta_id = null,
  error,
}) {
  return (
    <Form layout="vertical">
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item
            label="Drives Estratégicos"
            validateStatus={error.obj_id ? 'error' : ''}
            help={error.obj_id || ''}
          >
            <Select
              value={obj_id}
              placeholder="Selecione o drive estratégico"
              name="obj_id"
              onChange={(value) =>
                handleChangeModal({
                  target: { name: 'obj_id', value },
                })
              }
              style={{ width: '100%' }}
              withoutMargin
            >
              {listaObjetivosTeamhub.map((obj) => (
                <Option value={obj.obj_id}>{obj.descricao}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item
            label="Objetivo Estratégico"
            validateStatus={error.objetivo ? 'error' : ''}
            help={error.objetivo || ''}
          >
            <Input
              value={objetivo}
              type="text"
              placeholder="Qual Objetivo Estratégico da sua empresa se relaciona com o escolhido acima?"
              name="objetivo"
              onChange={handleChangeModal}
              withoutMargin
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item
            label="Descrição"
            validateStatus={error.descricao ? 'error' : ''}
            help={error.descricao || ''}
          >
            <TextArea
              value={descricao}
              type="text"
              placeholder="Preencha aqui uma descrição detalhada do seu Objetivo Estratégico."
              name="descricao"
              onChange={handleChangeModal}
              autoSize={{ minRows: '3', maxRows: '5' }}
              withoutMargin
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default injectIntl(NovoObjetivo)
