// types
export const QUIZ_LIST = 'QUIZ_LIST'
export const QUIZ_POST = 'QUIZ_POST'
export const QUIZ_UPDATE_STATUS = 'QUIZ_UPDATE_STATUS'

// urls
export const URL_QUIZ = (processo_id) =>
  `${process.env.REACT_APP_API_URL}/quiz/${processo_id}`
export const URL_QUIZ_LIST = `${process.env.REACT_APP_API_URL}/quiz`
export const URL_QUIZ_POST = `${process.env.REACT_APP_API_URL}/quiz`
export const URL_QUIZ_UPDATE_STATUS = (processo_id) =>
  `${process.env.REACT_APP_API_URL}/quizativo/${processo_id}`

export const novoQuizInitialState = {
  id: 0,
}
