/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { Button, Table, Modal, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { getSubCulturas, deleteSubCultura } from '../Api/api'
import AddNewCulture from '../Forms/AddSubCultura'
import EditCulture from '../Forms/EditCulture'

const { Column } = Table

const App = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEditModalVisible, setEditModal] = useState(false)
  const [actualColumn, setColumn] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [dataSource, setDataSource] = useState([])
  const [allData, setData] = useState('')

  async function getListOfSubcultures() {
    const response = await getSubCulturas(props.cultura.cultura_id)
    fillTable(response.response.rows)
    setData(response.response.rows)
  }

  useEffect(() => {
    getListOfSubcultures()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cultura.cultura_id])

  async function deletarSubCultura(id, subcultura_id) {
    // eslint-disable-next-line no-restricted-globals
    const answer = confirm('Tem Certeza que deseja apagar o grupo?')
    if (answer) {
      const response = await deleteSubCultura(id, subcultura_id)
      if (response.success) getListOfSubcultures()
    }
  }
  function fillTable(data) {
    const newObj = []
    data.forEach((item) => {
      newObj.push({
        subcultura_id: item.subcultura_id,
        conta: item.conta_cultura,
      })
    })
    newObj.forEach((item, index) => {
      item.key = index
      item.actions = {
        label: ['Abrir', 'Editar', 'Excluir'],
        id: item.subcultura_id,
        emp_id: item.conta.emp_id,
        cultura_id: data[index].cultura_id,
        conta_id: item.conta.conta_id,
      }
      item.desc = data[index].descricao
      item.cultura_id = data[index].cultura_id
    })
    setDataSource(newObj)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setEditModal(false)
  }

  return (
    <div>
      {props.cultura.cultura_status === 1 && (
        <div style={{ float: 'right' }}>
          <Button
            type="primary"
            onClick={showModal}
            className="btn_purple newCultureBtn"
          >
            Nova cultura desejada
          </Button>
        </div>
      )}
      <Table dataSource={dataSource}>
        <Column title="Descrição" key="desc" dataIndex="desc" />
        <Column
          title="Ação"
          key="actions"
          dataIndex="actions"
          render={(tags, row) => (
            <>
              {tags.label.map((tag, index) => {
                return tag === 'Abrir' ? (
                  <Link
                    className="ActionCultureBtn"
                    key={index}
                    to={{
                      pathname: 'subCultura/',
                      state: {
                        allData,
                        subcultura_id: tags.id,
                        cultura_id: tags.cultura_id,
                        emp_id: tags.emp_id,
                        conta_id: tags.conta_id,
                        conta_cultura_statuses:
                          props.cultura.conta_cultura_statuses,
                        cultura_tipo: props.cultura.cultura_tipo,
                      },
                      key: index,
                    }}
                  >
                    {tag}
                  </Link>
                ) : tag === 'Editar' && props.cultura.cultura_status === 1 ? (
                  <Tooltip title="Editar" placement="bottom">
                    <EditOutlined
                      style={{ margin: 5, color: '#30318b' }}
                      key={index}
                      onClick={() => {
                        setColumn(row)
                        setEditModal(true)
                      }}
                    />
                  </Tooltip>
                ) : tag === 'Excluir' && props.cultura.cultura_status === 1 ? (
                  <Tooltip title="Excluir" placement="bottom">
                    <DeleteOutlined
                      style={{ margin: 5, color: '#30318b' }}
                      key={index}
                      onClick={() =>
                        deletarSubCultura(props.cultura.cultura_id, tags.id)
                      }
                    />
                  </Tooltip>
                ) : null
              })}
            </>
          )}
        />
      </Table>
      <Modal
        title="Adicionar nova cultura"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <AddNewCulture
          cultura={props.cultura}
          updateSubCultura={getListOfSubcultures}
          closeModal={handleCancel}
        />
      </Modal>
      <Modal
        title="Editar cultura"
        visible={isEditModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <EditCulture
          closeModal={handleCancel}
          update={getListOfSubcultures}
          column={actualColumn}
        />
      </Modal>
    </div>
  )
}

export default App
