import React from 'react'

import { Statistic, Divider } from 'antd'
import { EstatisticasRow, EstatisticasCol, EstatisticasLine } from './styles'

export default function EstatisticasGerais({ estatisticas, nps }) {
  return (
    <EstatisticasRow
      gutter={16}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <EstatisticasLine>
        {nps !== 'N/A' ? (
          <>
            <EstatisticasCol span={24}>
              <Statistic title="e-NPS" value={nps} />
            </EstatisticasCol>
            <Divider style={{ margin: '2px' }} />
          </>
        ) : null}
      </EstatisticasLine>
      <EstatisticasLine>
        <EstatisticasCol span={12}>
          <Statistic title="Colaboradores" value={estatisticas.colaboradores} />
        </EstatisticasCol>
        <EstatisticasCol span={12}>
          <Statistic title="Times" value={estatisticas.times} />
        </EstatisticasCol>
      </EstatisticasLine>
      <Divider style={{ margin: '2px' }} />
      <EstatisticasLine>
        <EstatisticasCol span={12}>
          <Statistic
            title="Pesquisas enviadas"
            value={estatisticas.pesquisas}
          />
        </EstatisticasCol>
        <EstatisticasCol span={12}>
          <Statistic
            title="Pesquisas não respondidas"
            value={estatisticas.naorespondidas}
          />
        </EstatisticasCol>
      </EstatisticasLine>
    </EstatisticasRow>
  )
}
