import React from 'react'
import { injectIntl } from 'react-intl'
import { Modal, Row, Col, Card, Statistic, Tabs, Button } from 'antd'
import {
  UserOutlined,
  AimOutlined,
  FormOutlined,
  SaveOutlined,
} from '@ant-design/icons'

// Layouts
import ContentLayout from '../../layouts/contentLayout/ContentLayout'

// Components
import TableTimesDetalhes from './components/tableTimesDetalhes/TableTimesDetalhes'
import PDT from '../pdt/PDT'

// Constants
import { modals } from './constants/constantsTimesDetalhes'

// Services
import { isLider } from '../../services/auth'

// CSS
import './TimesDetalhes.css'
import CulturaDesejadaForm from '../../components/CulturaDesejadaForm'

const { TabPane } = Tabs

function TimesDetalhesView({
  intl,
  handleChangeLider,
  time,
  id,
  onModalSubmit,
  handleChangeCheckbox,
  handleChangeModal,
  openModal,
  closeModal,
  showModals,
  modalStates,
  colaboradores,
  dataSource,
  updatePessoa,
  getColumnSearchProps,
  onTableDelete,
  setActiveTab,
  handleSaveCulturaDesejadaGrupo,
  culturaDesejadaGrupo,
  culturaDesejadaEmpresa,
  culturaInstaladaEmpresa,
  rowSelection,
}) {
  const lider = isLider()
  const { nome, descricao, qtde } = time

  return (
    <ContentLayout
      className="times-detalhes"
      header={{
        title: nome,
        subtitle: descricao,
        breadcrumb: [
          { name: 'Home', path: '/' },
          { name: 'Times', path: '/times' },
          { name: nome },
        ],
        renderRight: (
          <div className="times-detalhes-statistic">
            <div className="times-detalhes-statistic__item">
              <Statistic title="Membros" value={qtde || 0} />
            </div>
          </div>
        ),
      }}
    >
      <div className="times-detalhes-body">
        <Row>
          <Col md={24}>
            <Tabs
              defaultActiveKey="1"
              onChange={(activeKey) => setActiveTab(activeKey)}
            >
              <TabPane
                tab={
                  <>
                    <UserOutlined /> <span>Membros do Time</span>
                  </>
                }
                key="1"
                forceRender
              >
                <Card
                  type="inner"
                  className="times-detalhes-body__card"
                  bordered={false}
                >
                  <TableTimesDetalhes
                    dataSource={dataSource}
                    handleChangeLider={handleChangeLider}
                    openModal={openModal}
                    updatePessoa={updatePessoa}
                    getColumnSearchProps={getColumnSearchProps}
                    onTableDelete={onTableDelete}
                    rowSelection={rowSelection}
                  />
                </Card>
              </TabPane>
              <TabPane
                tab={
                  <>
                    <FormOutlined />
                    <span>PDT</span>
                  </>
                }
                key="2"
              >
                <PDT id={id} />
              </TabPane>
              <TabPane
                tab={
                  <>
                    <AimOutlined />
                    <span>Fit Cultural</span>
                  </>
                }
                key="3"
              >
                <Card
                  type="inner"
                  title={<span>Cultura desejada</span>}
                  extra={
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                      form="cultura-desejada-form"
                    >
                      SALVAR
                    </Button>
                  }
                >
                  <CulturaDesejadaForm
                    label="Time"
                    onFinish={handleSaveCulturaDesejadaGrupo}
                    initialValues={culturaDesejadaGrupo}
                    culturaDesejadaEmpresa={culturaDesejadaEmpresa}
                    culturaInstalada={culturaInstaladaEmpresa}
                  />
                </Card>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
      {modals.map(
        ({ title, cancelText, okText, id, width, children: Children }) => (
          <Modal
            visible={showModals[id]}
            title={title}
            cancelText={cancelText}
            okText={okText}
            onCancel={() => closeModal(id)}
            onOk={() => onModalSubmit(id)}
            width={width}
            destroyOnClose
            key={id}
          >
            <Children
              handleChangeModal={(event) => handleChangeModal(id, event)}
              handleChangeCheckbox={(event) => handleChangeCheckbox(id, event)}
              colaboradores={colaboradores}
              {...modalStates[id]}
            />
          </Modal>
        )
      )}
    </ContentLayout>
  )
}

export default injectIntl(TimesDetalhesView)
