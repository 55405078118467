import * as constants from '../constants/constants'

export const loadTecnicas = () => {
  return {
    type: constants.TECNICA_LOAD,
  }
}

export const listTecnicas = (id) => {
  return {
    type: constants.TECNICA_LIST,
    api: {
      method: 'get',
      url: constants.URL_TECNICA_LIST(id),
    },
  }
}

export const getFrequencia = () => {
  return {
    type: constants.FREQUENCIA_CARGO,
    api: {
      method: 'get',
      url: constants.URL_FREQUENCIA_CARGO,
    },
  }
}

export const storeTecnicas = ({ id, data, nome, descricao }) => {
  return {
    type: constants.TECNICA_POST,
    api: {
      method: 'post',
      url: constants.URL_TECNICA_POST(id),
      data: { nome: data.nome, descricao: data.descricao },
    },
  }
}

export const updateTecnica = ({ id, tecnica_id, nome, descricao }) => {
  return {
    type: constants.TECNICA_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_TECNICA_ID(id, tecnica_id),
      data: { nome, descricao },
    },
  }
}

export const deleteTecnicas = ({ id, tecnica_id }) => {
  return {
    type: constants.TECNICA_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_TECNICA_ID(id, tecnica_id),
    },
  }
}
