import React from 'react'

import styled, { css } from 'styled-components'

import { Popover as PopoverAntd } from 'antd'

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 64px;
  background: #dedede;
  border-radius: 50%;
  margin-right: 16px;
  span {
    font-size: 32px;
    font-weight: 500;
  }
`

export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 120px;
  min-height: 120px;
  width: 310px;

  padding: 6px 12px 6px 12px;

  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;

  /* border */
  border-radius: 7px;

  border-left-width: 5px;
  border-left-style: solid;
  ${(props) =>
    props.contratado === 1
      ? css`
          border-color: green;
        `
      : props.candidato
      ? css`
          border-color: var(--orange);
        `
      : css`
          border-color: var(--purple);
        `}
  border-top-width: 0px;
  border-top-style: solid;
  border-bottom-width: 0px;
  border-bottom-style: solid;
  border-right-width: 0px;
  border-right-style: solid;
  transition: border-width 0.1s linear;

  ${(props) =>
    props.isDragging &&
    css`
      border-left-width: 3px;
      border-top-width: 3px;
      border-top-style: solid;
      border-bottom-width: 3px;
      border-bottom-style: solid;
      border-right-width: 3px;
      border-right-style: solid;
    `}

  .psKanban__candidato__row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    position: relative;
    min-height: 100%;
  }

  .details {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .name {
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 16px;
    }

    > span:not(:first-of-type) {
      margin-top: 4px;
    }

    .profissao {
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .prtSalario {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .checkbox {
    position: absolute;
    top: 0;
    right: 0;
    ${(props) =>
      !props.checked &&
      props.contratado === 0 &&
      css`
        visibility: hidden;
      `}
  }

  :hover {
    box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px;
    .checkbox {
      visibility: visible;
      transition: visibility 0s;
    }
  }

  .ant-rate {
    ${(props) =>
      props.contratado === 1
        ? css`
            color: green;
          `
        : props.candidato
        ? css`
            color: var(--orange);
          `
        : css`
            color: var(--purple);
          `}

    font-size: 16px;
    margin-top: 4px;
  }
`

const MyPopover = ({ className, ...props }) => (
  <PopoverAntd {...props} overlayClassName={className} />
)

export const Popover = styled(MyPopover)`
  .ant-popover-inner-content {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      a {
        color: inherit;
      }

      li:hover {
        span {
          cursor: pointer;
          color: var(--purple);
        }
      }

      .danger:hover {
        span {
          color: red;
        }
      }

      .success:hover {
        span {
          color: green;
        }
      }

      > li + li {
        margin-top: 4px;
      }

      > li {
        .anticon {
          margin-right: 6px;
        }
      }
    }
  }
`
