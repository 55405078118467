import React, { Component } from 'react'

// Components
import { SearchOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { toast } from 'react-toastify'
import CargosDetalhesView from './CargosDetalhesView'
import Button from '../../components/button/Button'
import Input from '../../components/input/Input'

// Constants
import {
  apiToFrontCargo,
  apiToFrontCargoPessoa,
} from './constants/constantsCargosDetalhes'
import { parseCulturas } from '../../utils/utils'

class CargosDetalhesController extends Component {
  state = {
    dateSource: [],
    searchText: '',
    cargo: {},
    culturaDesejadaCargo: undefined,
    culturaDesejadaEmpresa: [],
    culturaInstaladaCargo: [],
  }

  loadCulturaDesejadaCargo = () => {
    const { id } = this.props.match.params

    const { getCulturaDesejadaCargo } = this.props

    getCulturaDesejadaCargo(id).then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          culturaDesejadaCargo: response.data,
        })
      }
    })
  }

  loadCulturaInstaladaCargo = () => {
    const { id } = this.props.match.params

    const { getCulturaInstaladaCargo } = this.props

    getCulturaInstaladaCargo(id).then(({ response }) => {
      if (response.status === 200) {
        const { cultura } = parseCulturas({
          culturaInstalada: response.data,
        })

        this.setState({
          culturaInstaladaCargo: cultura,
        })
      }
    })
  }

  loadCulturaEmpresa = () => {
    const { getCulturaEmpresa } = this.props

    getCulturaEmpresa().then(({ response }) => {
      if (response.status === 200) {
        const { culturaDesejada } = response.data

        const { cultura } = parseCulturas({
          culturaDesejada,
        })

        this.setState({ culturaDesejadaEmpresa: cultura })
      }
    })
  }

  componentDidMount() {
    const { getCargos, getCargoPessoas, postCargoPessoa } = this.props
    const { id } = this.props.match.params

    getCargos().then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          cargo: response.data.cargos
            .filter((cargo) => cargo.cargo_id == id)
            .map((cargo) => apiToFrontCargo({ cargo }))[0],
        })
      }
    })

    this.loadCulturaEmpresa()
    this.loadCulturaDesejadaCargo()
    this.loadCulturaInstaladaCargo()

    getCargoPessoas(id).then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          dataSource: response.data.pessoas
            .filter((pessoa) => pessoa.status == 1)
            .map((pessoa) => apiToFrontCargoPessoa({ pessoa })),
        })
      }
    })
  }

  onTableDelete = (idPessoa) => {
    const { deleteCargoPessoa } = this.props
    const { dataSource, cargo } = this.state
    const newData = dataSource.filter((data) => data.id !== idPessoa)

    deleteCargoPessoa(cargo.id, idPessoa).then(({ response }) => {
      if (response.status === 200) {
        this.setState({ dataSource: newData })
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder="Buscar"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  handleSaveCulturaDesejadaCargo = (data) => {
    const { id } = this.props.match.params
    const { updateCulturaDesejadaCargo } = this.props

    updateCulturaDesejadaCargo({ cargo_id: id, ...data }).then(
      ({ response }) => {
        if (response.status === 200) {
          toast.success('Cultura desejada do cargo ajustada com sucesso.')
          this.loadCulturaDesejadaCargo()
        }
      }
    )
  }

  render() {
    const {
      getColumnSearchProps,
      onTableDelete,
      handleSaveCulturaDesejadaCargo,
    } = this
    const {
      dataSource,
      cargo,
      culturaDesejadaCargo,
      culturaDesejadaEmpresa,
      culturaInstaladaCargo,
    } = this.state

    return (
      <CargosDetalhesView
        dataSource={dataSource}
        getColumnSearchProps={getColumnSearchProps}
        cargo={cargo}
        onTableDelete={onTableDelete}
        culturaDesejadaCargo={culturaDesejadaCargo}
        handleSaveCulturaDesejadaCargo={handleSaveCulturaDesejadaCargo}
        culturaDesejadaEmpresa={culturaDesejadaEmpresa}
        culturaInstaladaCargo={culturaInstaladaCargo}
      />
    )
  }
}

export default CargosDetalhesController
