import { Redirect, Route, Router, Switch } from 'react-router-dom'
import AnaliseGeral from './containers/analiseGeral'
import AnaliseGeralTimes from './containers/analiseGeralTimes'
import Cadastro from './containers/cadastro/Cadastro'
import CadastroColaboradores from './containers/cadastroColaboradores/CadastroColaboradores'
import Cargos from './containers/cargos/Cargos'
import CargosDetalhes from './containers/cargosDetalhes/CargosDetalhes'
import ColaboradoresInativos from './containers/colaboradoresInativos/ColaboradoresInativos'
import CadastroFiliais from './containers/ConfigFiliais/index'
import ConfiguracoesPlanos from './containers/configuracoes'
import ConfiguracoesPesquisas from './containers/configuracoesPesquisas'
import ConfiguracoesPsAuto from './containers/configuracoesPsAuto'
import ConfiguracoesPsManual from './containers/configuracoesPsManual'
import ConfiguracoesUsuarios from './containers/configuracoesUsuarios'
import Candidatos from './containers/dashboard/containers/Candidatos'
import Colaboradores from './containers/dashboard/containers/Colaboradores'
import VisaoGeral from './containers/dashboard/containers/VisaoGeral'
import VisaoTimes from './containers/dashboard/containers/VisaoTimes'
import Dashboard from './containers/dashboard/Dashboard'
import EmailGestor from './containers/emailGestor/EmailGestor'
import EmailAvaliador from './containers/emailGestor/EmailAvaliador'
import EsqueciMinhaSenha from './containers/esqueciMinhaSenha/EsqueciMinhaSenha'
import GestaoPesquisasCandidatos from './containers/gestaoPesquisasCandidatos/GestaoPesquisas'
import GestaoPesquisasColaboradores from './containers/gestaoPesquisasColaboradores/GestaoPesquisas'
// Containers
import Login from './containers/login/Login'
import LoginColaborador from './containers/loginColaborador/LoginColaborador'
import NovaSenha from './containers/novaSenha/NovaSenha'
import ObjetivosEstrategicos from './containers/objetivosEstrategicos/ObjetivosEstrategicos'
import ObjetivosEstrategicosAvaliadores from './containers/objetivosEstrategicosAvaliadores/ObjetivosEstrategicos'
import ObjetivosEstrategicosDashboard from './containers/objetivosEstrategicosDashboard/ObjetivosEstrategicos'
import Onboarding from './containers/onboarding/Onboarding'
import Perfil from './containers/perfil/Perfil'
import PerfilCandidato from './containers/perfilCandidato/PerfilCandidato'
import PerfilComportamentalDetalhes from './containers/perfilComportamentalDetalhes/PerfilComportamentalDetalhes'
import PercepcaoAmbiente from './containers/pesquisas/containers/percepcaoAmbiente/PercepcaoAmbiente'
import PerfilComportamental from './containers/pesquisas/containers/perfilComportamental/PerfilComportamental'
import PesqValoresOrganizacionais from './containers/pesquisas/containers/valoresOrganizacionais'
import Pesquisas from './containers/pesquisas/Pesquisas'
import RecrutamentoSelecao from './containers/recrutamentoSelecao'
import PsPainel from './containers/recrutamentoSelecao/containers/PsPainel'
import FormularioQuiz from './containers/recrutamentoSelecao/containers/Quiz/FormularioQuiz'
import NovoQuiz from './containers/recrutamentoSelecao/containers/Quiz/NovoQuiz'
import PreviewFinal from './containers/recrutamentoSelecao/containers/Quiz/NovoQuiz/components/PreviewFinal'
import PainelQuiz from './containers/recrutamentoSelecao/containers/Quiz/PainelQuiz'
import RecrutamentoSelecaoCadastroSla from './containers/recrutamentoSelecaoCadastroSla'
import RecrutamentoSelecaoCandidatos from './containers/recrutamentoSelecaoCandidatos'
import RecrutamentoSelecaoDashboard from './containers/recrutamentoSelecaoDashboard'
import RecrutamentoSelecaoJobs from './containers/recrutamentoSelecaoJobs'
import RecrutamentoSelecaoMotivos from './containers/recrutamentoSelecaoMotivos'
import RecrutamentoSelecaoMotivosCancel from './containers/recrutamentoSelecaoMotivosCancel'
import RecrutamentoSelecaoMotivosSuspensao from './containers/recrutamentoSelecaoMotivosSuspensao'
import RecrutamentoSelecaoPsAtivos from './containers/recrutamentoSelecaoPsAtivos'
import RecrutamentoSelecaoPsCancelados from './containers/recrutamentoSelecaoPsCancelados'
import RecrutamentoSelecaoPsFinalizados from './containers/recrutamentoSelecaoPsFinalizados'
import RecrutamentoSelecaoPsSuspensos from './containers/recrutamentoSelecaoPsSuspensos'
import RecrutamentoSelecaoRequisicoesAndamento from './containers/recrutamentoSelecaoRequisicoesAndamento'
import RecrutamentoSelecaoRequisicoesAprovadas from './containers/recrutamentoSelecaoRequisicoesAprovadas'
import RecrutamentoSelecaoRequisicoesCanceladas from './containers/recrutamentoSelecaoRequisicoesCanceladas'
import RecrutamentoSelecaoTestes from './containers/recrutamentoSelecaoTestes'
import RecrutamentoSelecaoTiposContratos from './containers/recrutamentoSelecaoTiposContratos'
import RecrutamentoSelecaoWorkflow from './containers/recrutamentoSelecaoWorkflow'
import RelatorioLideres from './containers/relatorioLideres'
import AnaliseCulturaGeral from './containers/relatoriosGeral/analiseCultura/AnaliseCulturaGeral'
import ListPessoasFitCultural from './containers/relatoriosGeral/listPessoasFitCultural'
import ListTimesFitCultural from './containers/relatoriosGeral/ListTimesFitCultural'
import Olhar360Geral from './containers/relatoriosGeral/olhar360/Olhar360Geral'
import PerfilComportamentalGeral from './containers/relatoriosGeral/perfilComportamental/PerfilComportamentalGeral'
import RelatorioComparativo from './containers/relatorioComparativo/RelatorioComparativo'
import RelatoriosTimes from './containers/relatoriosTimes/RelatoriosTimes'
import Times from './containers/times/Times'
import TimesDetalhes from './containers/timesDetalhes/TimesDetalhes'
import ValoresOrganizacionais from './containers/valoresOrganizacionais/ValoresOrganizacionais'
import VetorIndividualShare from './containers/VetorIndividualShare/VetorIndividualShare'
import VetorIndividualShareFit from './containers/VetorIndividualShare/VetorIndividualShareFit'
import VetorIndividualShareFitCompleto from './containers/VetorIndividualShare/VetorIndividualCompleto'

import VincularPessoaUsuario from './containers/vincularPessoaUsuario'
import NotFoundPage from './containers/NotFoundPage'

// Novo Fit Cultural
import FitCultural from './components/FitCultural/index'
import SubCultura from './components/FitCultural/SubCulturas/subCulturaAvaliadores'
import NewObjetivosEstrategicos from './components/FitCultural/SubCulturas/objetivosEstrategicos'
import SubCulturaIndex from './components/FitCultural/SubCulturas/subCulturaDetail'
import Relatorios from './components/FitCultural/Relatorios/Empresas'
import RelatorioGrupos from './components/FitCultural/Relatorios/Grupos'
import RelatorioPessoas from './components/FitCultural/Relatorios/Pessoas'
import RelatorioRecrutamento from './components/FitCultural/Relatorios/Recrutamento'
// Layouts
import ContentLayout from './layouts/contentLayout/ContentLayout'
import DashboardLayout from './layouts/dashboardLayout/DashboardLayout'
import GeneralLayout from './layouts/generalLayout/GeneralLayout'
import {
  getUserName,
  hasVinculoPessoa,
  isAuthenticated,
  isLider,
} from './services/auth'
// Utils
import history from './utils/history'

const PrivateRoute = ({ render: Component, lider = false, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated({ empresa: props.match.params.empresa }) ? (
          (isLider() && lider) || !isLider() ? (
            <>
              {!hasVinculoPessoa() && (
                <VincularPessoaUsuario {...props} {...rest} />
              )}

              <Component {...props} />
            </>
          ) : (
            <>
              <Redirect
                to={{
                  pathname: `/${props.match.params.empresa}/colaboradores`,

                  state: { from: props.location },
                }}
              />
            </>
          )
        ) : (
          <Redirect
            to={{
              pathname: `/${props.match.params.empresa}`,
              state: { from: props.location },
              search: props.location.search,
            }}
          />
        )
      }}
    />
  )
}

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        {/* Login e cadastro */}

        <Route exact path={['/', '/confirmar-conta/:hash']} component={Login} />

        <Route path="/esqueci-minha-senha" component={EsqueciMinhaSenha} />

        <Route path="/nova-senha" component={NovaSenha} />

        <Route path="/cadastro" component={Cadastro} />

        <Route path="/colaborador" component={LoginColaborador} />

        <Route exact path="/:empresa/" component={Login} />

        {/* Onboarding e public routes */}

        <Route
          exact
          path="/:empresa/gestor/:hash"
          render={(props) => (
            <GeneralLayout component={EmailGestor} {...props} />
          )}
        />
        <Route
          exact
          path="/:empresa/avaliador/:hash"
          render={(props) => (
            <GeneralLayout component={EmailAvaliador} {...props} />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/gestor/editar/:id"
          render={(props) => (
            <GeneralLayout component={EmailGestor} {...props} />
          )}
        />

        <PrivateRoute
          path="/:empresa/onboarding"
          render={(props) => (
            <GeneralLayout component={Onboarding} {...props} />
          )}
        />

        {/* Pesquisas */}

        <PrivateRoute
          exact
          path="/:empresa/pesquisas"
          render={(props) => (
            <DashboardLayout component={Pesquisas} sider={false} {...props} />
          )}
        />

        <Route
          path="/:empresa/pesquisas/perfil-comportamental/:id"
          render={(props) => <PerfilComportamental {...props} />}
          lider
        />

        <Route
          path="/:empresa/pesquisas/percepcao-ambiente/:id"
          render={(props) => <PercepcaoAmbiente {...props} />}
        />

        <Route
          path="/:empresa/pesquisas/valores-organizacionais/:hash"
          render={(props) => <PesqValoresOrganizacionais {...props} />}
        />

        <PrivateRoute
          exact
          path="/:empresa/pesquisas/gestao-pesquisas"
          render={(props) => (
            <DashboardLayout
              component={GestaoPesquisasColaboradores}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/pesquisas/gestao-pesquisas/colaboradores"
          render={(props) => (
            <DashboardLayout
              component={GestaoPesquisasColaboradores}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/pesquisas/gestao-pesquisas/candidatos"
          render={(props) => (
            <DashboardLayout component={GestaoPesquisasCandidatos} {...props} />
          )}
        />

        <PrivateRoute
          path="/:empresa/pesquisas/perfil-comportamental-detalhes/:id/:avaliaId?"
          render={(props) => (
            <DashboardLayout
              component={PerfilComportamentalDetalhes}
              {...props}
            />
          )}
          lider
        />

        {/* Configurações */}

        <PrivateRoute
          exact
          path="/:empresa/config"
          render={(props) => (
            <DashboardLayout
              component={(props) => (
                <ContentLayout
                  header={{
                    title: 'Configurações',

                    subtitle: 'Configurações da conta',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Configurações' },
                    ],
                  }}
                >
                  <ConfiguracoesPlanos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/config/planos"
          render={(props) => (
            <DashboardLayout
              component={(props) => (
                <ContentLayout
                  header={{
                    title: 'Configurações',

                    subtitle: 'Configurações da conta',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Configurações' },
                    ],
                  }}
                >
                  <ConfiguracoesPlanos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/config/pesquisas"
          render={(props) => (
            <DashboardLayout
              component={(props) => (
                <ContentLayout
                  header={{
                    title: 'Configurações',

                    subtitle: 'Configurações da conta',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Configurações' },
                    ],
                  }}
                >
                  <ConfiguracoesPesquisas />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/config/usuarios"
          render={(props) => (
            <DashboardLayout
              component={(props) => (
                <ContentLayout
                  header={{
                    title: 'Configurações',

                    subtitle: 'Configurações da conta',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Configurações' },
                    ],
                  }}
                >
                  <ConfiguracoesUsuarios />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/config/processos-seletivos"
          render={(props) => (
            <DashboardLayout
              component={(props) => (
                <ContentLayout
                  header={{
                    title: 'Configurações',

                    subtitle: 'Configurações da conta',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Configurações' },
                    ],
                  }}
                >
                  <ConfiguracoesPsAuto />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/config/processos-seletivos/email-automatico"
          render={(props) => (
            <DashboardLayout
              component={(props) => (
                <ContentLayout
                  header={{
                    title: 'Configurações',

                    subtitle: 'Configurações da conta',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Configurações' },
                    ],
                  }}
                >
                  <ConfiguracoesPsAuto />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/config/processos-seletivos/email-manual"
          render={(props) => (
            <DashboardLayout
              component={(props) => (
                <ContentLayout
                  header={{
                    title: 'Configurações',

                    subtitle: 'Configurações da conta',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Configurações' },
                    ],
                  }}
                >
                  <ConfiguracoesPsManual />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        {/* Dashboard */}
        <PrivateRoute
          lider
          path="/:empresa/dashboard/visao-times"
          render={(props) => (
            <DashboardLayout
              component={(props) => {
                return (
                  <ContentLayout
                    header={{
                      title: `Olá ${
                        isAuthenticated({ empresa: props.match.params.empresa })
                          ? getUserName()
                          : ''
                      }! Que bom que você voltou :)`,
                      subtitle:
                        'Utilize a dashboard para obter insights sobre a cultura dos colaboradores e times da sua empresa.',
                      breadcrumb: [
                        { name: 'Home', path: '/' },
                        { name: 'Dashboard - Visão de times' },
                      ],
                    }}
                    style={{ fontWeight: 500 }}
                  >
                    <Dashboard>
                      <VisaoTimes {...props} />
                    </Dashboard>
                  </ContentLayout>
                )
              }}
              {...props}
            />
          )}
        />

        <PrivateRoute
          lider
          path="/:empresa/dashboard/visao-geral"
          render={(props) => (
            <DashboardLayout
              component={(props) => {
                return (
                  <ContentLayout
                    header={{
                      title: `Olá ${
                        isAuthenticated({ empresa: props.match.params.empresa })
                          ? getUserName()
                          : ''
                      }! Que bom que você voltou :)`,
                      subtitle:
                        'Utilize a dashboard para obter insights sobre a cultura dos colaboradores e times da sua empresa.',
                      breadcrumb: [
                        { name: 'Home', path: '/' },
                        { name: 'Dashboard - Visão Geral' },
                      ],
                    }}
                    style={{ fontWeight: 500 }}
                  >
                    <Dashboard>
                      <VisaoGeral {...props} />
                    </Dashboard>
                  </ContentLayout>
                )
              }}
              {...props}
            />
          )}
        />

        <PrivateRoute
          lider
          path="/:empresa/dashboard"
          exact
          render={(props) => (
            <DashboardLayout
              component={(props) => {
                return (
                  <ContentLayout
                    header={{
                      title: `Olá ${
                        isAuthenticated({ empresa: props.match.params.empresa })
                          ? getUserName()
                          : ''
                      }! Que bom que você voltou :)`,
                      subtitle:
                        'Utilize a dashboard para obter insights sobre a cultura dos colaboradores e times da sua empresa.',
                      breadcrumb: [
                        { name: 'Home', path: '/' },
                        { name: 'Dashboard - Visão Geral' },
                      ],
                    }}
                    style={{ fontWeight: 500 }}
                  >
                    <Dashboard>
                      <VisaoGeral {...props} />
                    </Dashboard>
                  </ContentLayout>
                )
              }}
              {...props}
            />
          )}
        />

        <PrivateRoute
          lider
          path="/:empresa/dashboard/colaboradores"
          render={(props) => (
            <DashboardLayout
              component={(props) => {
                return (
                  <ContentLayout
                    header={{
                      title: `Olá ${
                        isAuthenticated({ empresa: props.match.params.empresa })
                          ? getUserName()
                          : ''
                      }! Que bom que você voltou :)`,

                      subtitle:
                        'Utilize a dashboard para obter insights sobre a cultura dos colaboradores e times da sua empresa.',

                      breadcrumb: [
                        { name: 'Home', path: '/' },

                        { name: 'colaboradores' },
                      ],
                    }}
                    style={{ fontWeight: 500 }}
                  >
                    <Dashboard>
                      <Colaboradores />
                    </Dashboard>
                  </ContentLayout>
                )
              }}
              {...props}
            />
          )}
        />

        <PrivateRoute
          lider
          path="/:empresa/dashboard/candidatos"
          render={(props) => (
            <DashboardLayout
              component={(props) => {
                return (
                  <ContentLayout
                    header={{
                      title: `Olá ${
                        isAuthenticated({ empresa: props.match.params.empresa })
                          ? getUserName()
                          : ''
                      }! Que bom que você voltou :)`,

                      subtitle:
                        'Utilize a dashboard para obter insights sobre a cultura dos colaboradores e times da sua empresa.',

                      breadcrumb: [
                        { name: 'Home', path: '/' },

                        { name: 'candidatos' },
                      ],
                    }}
                    style={{ fontWeight: 500 }}
                  >
                    <Dashboard>
                      <Candidatos />
                    </Dashboard>
                  </ContentLayout>
                )
              }}
              {...props}
            />
          )}
        />

        {/* Dashboard */}

        <PrivateRoute
          exact
          path="/:empresa/cultura-desejada"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Cultura Desejada',

                    subtitle: 'Cultura desejada da organização',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Cultura Desejada' },
                    ],
                  }}
                >
                  <ObjetivosEstrategicos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/:empresa/cultura-desejada/dashboard"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Cultura Desejada',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Cultura Desejada' },
                    ],
                  }}
                >
                  <ObjetivosEstrategicosDashboard />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/:empresa/cultura-desejada/relatorios/empresas"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Fit Cultural',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Fit Cultural' },
                      { name: 'Relatórios' },
                      { name: 'Empresas' },
                    ],
                  }}
                >
                  <Relatorios />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/:empresa/cultura-desejada/relatorios/grupos"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Fit Cultural',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Fit Cultural' },
                      { name: 'Relatórios' },
                      { name: 'Grupos' },
                    ],
                  }}
                >
                  <RelatorioGrupos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/:empresa/cultura-desejada/relatorios/pessoas"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Fit Cultural',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Fit Cultural' },
                      { name: 'Relatórios' },
                      { name: 'Pessoas' },
                    ],
                  }}
                >
                  <RelatorioPessoas />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/:empresa/cultura-desejada/relatorios/recrutamento"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Fit Cultural',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Fit Cultural' },
                      { name: 'Relatórios' },
                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RelatorioRecrutamento />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/:empresa/cultura-desejada/dashboard/:id/"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Cultura Desejada',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      {
                        name: 'Cultura Desejada',
                        path: '/cultura-desejada/dashboard',
                      },
                      {
                        name: 'Detalhes da Cultura',
                        path: '/cultura-desejada/dashboard',
                      },
                    ],
                  }}
                >
                  <FitCultural />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/:empresa/cultura-desejada/dashboard/:id/subCultura/"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Cultura Desejada',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      {
                        name: 'Cultura Desejada',
                        path: '/cultura-desejada/dashboard',
                      },
                      {
                        name: 'Cultura Única',
                        path: '#',
                      },
                      {
                        name: 'SubCultura',
                        path: '#',
                      },
                    ],
                  }}
                >
                  <SubCulturaIndex />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/:empresa/avaliadores"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Avaliadores',

                    subtitle: 'Avaliadores da organização',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Avaliadores' },
                    ],
                  }}
                >
                  <ObjetivosEstrategicosAvaliadores />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />
        <PrivateRoute
          path="/:empresa/cultura-desejada/dashboard/:id/:nameCultura"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Cultura Única',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      {
                        name: 'Cultura Desejada',
                        path: '/cultura-desejada/dashboard',
                      },
                      {
                        name: 'Cultura Única',
                        path: '/cultura-desejada/dashboard',
                      },
                    ],
                  }}
                >
                  <FitCultural />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/:empresa/cultura-desejada/avaliadores"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Avaliadores',

                    subtitle: 'Avaliadores da organização',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Avaliadores' },
                    ],
                  }}
                >
                  <ObjetivosEstrategicosAvaliadores />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/cultura-desejada/avaliadores"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Avaliadores',

                    subtitle: 'Avaliadores da organização',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Avaliadores' },
                    ],
                  }}
                >
                  <ObjetivosEstrategicosAvaliadores />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/cultura-desejada/avaliadores"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Avaliadores',

                    subtitle: 'Avaliadores da organização',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Avaliadores' },
                    ],
                  }}
                >
                  <ObjetivosEstrategicosAvaliadores />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/cultura-desejada/avaliadores"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Avaliadores',

                    subtitle: 'Avaliadores da organização',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Avaliadores' },
                    ],
                  }}
                >
                  <ObjetivosEstrategicosAvaliadores />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/objetivos-estrategicos"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Objetivos estrategicos',

                    subtitle: 'Objetivos estrategicos da organização',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Objetivos estrategicos' },
                    ],
                  }}
                >
                  <ObjetivosEstrategicos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          path="/:empresa/valores-organizacionais"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Valores organizacionais',

                    breadcrumb: [
                      { name: 'Home', path: '#' },

                      { name: 'Valores organizacionais' },
                    ],
                  }}
                >
                  <ValoresOrganizacionais />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/colaboradores"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Colaboradores',

                    subtitle:
                      'Gerencie aqui todos os colaboradores da sua empresa :)',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Colaboradores' },
                    ],
                  }}
                >
                  <CadastroColaboradores />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/colaboradores/inativos"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Colaboradores',

                    subtitle:
                      'Gerencie aqui todos os colaboradores da sua empresa :)',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Colaboradores' },
                    ],
                  }}
                >
                  <ColaboradoresInativos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          path="/:empresa/filiais"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Filiais',

                    subtitle: 'Cadastre e gerencie suas filiais :)',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Filiais' },
                    ],
                  }}
                >
                  <CadastroFiliais />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/times"
          render={(props) => <DashboardLayout component={Times} {...props} />}
          lider
        />

        <PrivateRoute
          path="/:empresa/times/:id"
          render={(props) => (
            <DashboardLayout component={TimesDetalhes} {...props} />
          )}
          lider
        />

        <PrivateRoute
          exact
          path="/:empresa/cargos"
          render={(props) => <DashboardLayout component={Cargos} {...props} />}
        />

        <PrivateRoute
          path="/:empresa/cargos/:id"
          render={(props) => (
            <DashboardLayout component={CargosDetalhes} {...props} />
          )}
        />

        <PrivateRoute
          path="/:empresa/perfil/:id"
          render={(props) => <DashboardLayout component={Perfil} {...props} />}
          lider
        />

        <PrivateRoute
          path="/:empresa/perfilcandidato/:id"
          render={(props) => (
            <DashboardLayout component={PerfilCandidato} {...props} />
          )}
          lider
        />

        <PrivateRoute
          exact
          path="/:empresa/relatorios/geral/olhar360"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Olhar 360',

                    subtitle: 'Visão Geral',

                    breadcrumb: [
                      { name: 'Home' },

                      { name: 'Relatórios' },

                      { name: 'Geral' },

                      { name: 'Olhar 360' },
                    ],
                  }}
                >
                  <Olhar360Geral />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/relatorios/geral/analiseCultural"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Análise Cultural',

                    subtitle: 'Visão Geral',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Geral' },

                      { name: 'Análise Cultural' },
                    ],
                  }}
                >
                  <AnaliseCulturaGeral />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/relatorios/geral/perfilComportamental"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Perfil Comportamental',

                    subtitle: 'Visão Geral',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Geral' },

                      { name: 'Perfil Comportamental' },
                    ],
                  }}
                >
                  <PerfilComportamentalGeral />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/relatorios/geral/pessoasFitCultural"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Pessoas - Fit Cultural',

                    subtitle: 'Visão Geral',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Geral' },

                      { name: 'Pessoas - Fit Cultural' },
                    ],
                  }}
                >
                  <ListPessoasFitCultural />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/relatorios/geral/timesFitCultural"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Times - Fit Cultural',

                    subtitle: 'Visão Geral',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Geral' },

                      { name: 'Times - Fit Cultural' },
                    ],
                  }}
                >
                  <ListTimesFitCultural />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          lider
          exact
          path="/:empresa/relatorios/times/analiseCultural"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Análise Cultural',

                    subtitle: 'Visão de Times',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Times' },

                      { name: 'Análise Cultural' },
                    ],
                  }}
                >
                  <RelatoriosTimes type="analiseCultura" />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          lider
          exact
          path="/:empresa/relatorios/times/olhar360"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Olhar 360',

                    subtitle: 'Visão de Times',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Times' },

                      { name: 'Olhar 360' },
                    ],
                  }}
                >
                  <RelatoriosTimes type="olhar360" />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          lider
          exact
          path="/:empresa/relatorios/times/perfilComportamental"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Perfil Comportamental',

                    subtitle: 'Visão de Times',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Times' },

                      { name: 'Perfil Comportamental' },
                    ],
                  }}
                >
                  <RelatoriosTimes type="perfilComportamental" />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/relatorios/geral/analiseGeral"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Análise Geral',

                    subtitle: 'Visão Geral',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Geral' },

                      { name: 'Análise Geral' },
                    ],
                  }}
                >
                  <AnaliseGeral />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          lider
          exact
          path="/:empresa/relatorios/times/analiseGeral"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Análise Geral',

                    subtitle: 'Visão de Times',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Times' },

                      { name: 'Análise Geral' },
                    ],
                  }}
                >
                  <AnaliseGeralTimes />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          lider
          exact
          path="/:empresa/relatorios/geral/comparativo"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Análise Comparativa',
                    subtitle:
                      'Verifique o progresso da cultura da sua empresa.',
                    breadcrumb: [
                      { name: 'Home', path: '/' },
                      { name: 'Relatórios' },
                      { name: 'Geral' },
                      { name: 'Análise Comparativa' },
                    ],
                  }}
                >
                  <RelatorioComparativo />
                </ContentLayout>
              )}
              {...props}
            />
            // <Redirect
            //   to={{
            //     pathname: `/${props.match.params.empresa}`,

            //     search: props.location.search,
            //   }}
            // />

            // <Redirect

            //   to={{

            //     pathname: `/${props.match.params.empresa}`,

            //     search: props.location.search,

            //   }}

            // />
          )}
        />

        <PrivateRoute
          lider
          exact
          path="/:empresa/relatorios/times/comparativo"
          render={(props) => (
            <Redirect
              to={{
                pathname: `/${props.match.params.empresa}`,

                search: props.location.search,
              }}
            />
          )}
        />

        {/* LINKS COMPARTILHAVEIS */}

        <Route
          exact
          path="/share/vetor/pessoa/fit/:hash"
          render={(props) => (
            <GeneralLayout
              component={() => <VetorIndividualShare {...props} />}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/share/vetor/pessoa/:hash"
          render={(props) => (
            <GeneralLayout
              component={() => <VetorIndividualShareFit {...props} />}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/share/vetor/pessoa/relatorio/:hash"
          render={(props) => (
            <GeneralLayout
              component={() => <VetorIndividualShareFitCompleto {...props} />}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoDashboard />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/dashboard"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoDashboard />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/processos-seletivos"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoPsAtivos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/processos-seletivos/ativos"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoPsAtivos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/processos-seletivos/suspensos"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoPsSuspensos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/processos-seletivos/finalizados"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoPsFinalizados />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/processos-seletivos/cancelados"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoPsCancelados />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/candidatos"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoCandidatos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/requisicoes"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoRequisicoesAprovadas />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/requisicoes/aprovadas"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoRequisicoesAprovadas />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/requisicoes/em-andamento"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoRequisicoesAndamento />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/requisicoes/canceladas"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoRequisicoesCanceladas />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/requisicoes/aprovadas"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecao />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/requisicoes/em-andamento"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecao />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/requisicoes/canceladas"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecao />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/testes"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoTestes />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/configuracoes"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoMotivos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/configuracoes/motivos"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoMotivos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/configuracoes/motivos-de-cancelamento"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoMotivosCancel />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/configuracoes/tipos-de-contratos"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoTiposContratos />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/configuracoes/motivos-de-suspensao"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoMotivosSuspensao />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/configuracoes/cadastro-de-slas"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoCadastroSla />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/configuracoes/workflow"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoWorkflow />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/trabalhe-conosco"
          lider
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Módulo de Recrutamento e Seleção',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Recrutamento e Seleção' },
                    ],
                  }}
                >
                  <RecrutamentoSelecaoJobs />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/ps/:processo_id"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Painel do Processo Seletivo',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      {
                        name: 'Recrutamento e Seleção',

                        path: '/recrutamento-selecao',
                      },

                      { name: 'Painel' },
                    ],
                  }}
                  fixed
                >
                  <PsPainel {...props} />
                </ContentLayout>
              )}
              fixed
              {...props}
            />
          )}
        />

        {/* Quiz */}

        <PrivateRoute
          exact
          path="/:empresa/recrutamento-selecao/criar-novo-teste"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Criação de Teste',

                    subtitle:
                      'Crie novo teste para utilização nos seus Processos Seletivos',
                  }}
                  fixed
                >
                  <NovoQuiz {...props} />
                </ContentLayout>
              )}
              fixed
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/teste/preview-novo-teste"
          render={(props) => <PreviewFinal {...props} />}
        />

        <Route
          exact
          path="/:empresa/teste/:hash"
          render={(props) => <FormularioQuiz {...props} />}
        />

        <PrivateRoute
          exact
          path="/:empresa/teste-painel/:quiz_id"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Recrutamento e Seleção',

                    subtitle: 'Painel do Teste',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      {
                        name: 'Recrutamento e Seleção',

                        path: '/recrutamento-selecao',
                      },

                      { name: 'Painel' },
                    ],
                  }}
                  fixed
                >
                  <PainelQuiz {...props} />
                </ContentLayout>
              )}
              fixed
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/relatorios/lideres/olhar360"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Olhar 360',

                    subtitle: 'Líderes',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Líderes' },

                      { name: 'Olhar 360' },
                    ],
                  }}
                >
                  <RelatorioLideres type="olhar360" />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          lider
          exact
          path="/:empresa/relatorios/lideres/analiseCultural"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Análise Cultural',

                    subtitle: 'Líderes',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Líderes' },

                      { name: 'Análise Cultural' },
                    ],
                  }}
                >
                  <RelatorioLideres type="analiseCultura" />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/relatorios/lideres/perfilComportamental"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Perfil Comportamental',

                    subtitle: 'Líderes',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Líderes' },

                      { name: 'Perfil Comportamental' },
                    ],
                  }}
                >
                  <RelatorioLideres type="perfilComportamental" />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/relatorios/lideres/analiseGeral"
          render={(props) => (
            <DashboardLayout
              component={() => (
                <ContentLayout
                  header={{
                    title: 'Análise Geral',

                    subtitle: 'Líderes',

                    breadcrumb: [
                      { name: 'Home', path: '/' },

                      { name: 'Relatórios' },

                      { name: 'Líderes' },

                      { name: 'Análise Geral' },
                    ],
                  }}
                >
                  <RelatorioLideres type="analiseGeral" />
                </ContentLayout>
              )}
              {...props}
            />
          )}
        />

        <PrivateRoute
          exact
          path="/:empresa/relatorios/lideres/comparativo"
          render={(props) => (
            <Redirect
              to={{
                pathname: `/${props.match.params.empresa}`,

                search: props.location.search,
              }}
            />
          )}
        />
      </Switch>
    </Router>
  )
}

export default Routes
