// types
export const PS_MOTIVO_SUSPENSAO_LIST = 'PS_MOTIVO_SUSPENSAO_LIST'
export const PS_MOTIVO_SUSPENSAO_STORE = 'PS_MOTIVO_SUSPENSAO_STORE'
export const PS_MOTIVO_SUSPENSAO_UPDATE = 'PS_MOTIVO_SUSPENSAO_UPDATE'
export const PS_MOTIVO_SUSPENSAO_DELETE = 'PS_MOTIVO_SUSPENSAO_DELETE'
export const PS_MOTIVO_SUSPENSAO_LOAD = 'PS_MOTIVO_SUSPENSAO_LOAD'

// urls
export const URL_PS_MOTIVO_SUSPENSAO = `${process.env.REACT_APP_API_URL}/psMotivoSuspensao`
export const URL_PS_MOTIVO_SUSPENSAO_ID = (id) =>
  `${process.env.REACT_APP_API_URL}/psMotivoSuspensao/${id}`
