import React from 'react'
import { injectIntl } from 'react-intl'
import { LockOutlined } from '@ant-design/icons'
import { Modal, Row, Col } from 'antd'

import history from '../../utils/history'

// Assets
import logo from '../../assets/logo_lg.png'

// Components
import { Password } from '../../components/input/Input'
import Button from '../../components/button/Button'

// CSS
import './NovaSenha.css'

function NovaSenhaView({ intl, handleChange, onSubmit }) {
  return (
    <Modal
      visible
      centered
      mask={false}
      footer={null}
      closable={false}
      wrapClassName="login"
    >
      <div className="login__header">
        <img src={logo} />
      </div>
      <form className="login__form" onSubmit={onSubmit}>
        <div className="cadastro__header">
          <h2>Digite sua nova senha</h2>
        </div>
        <Row>
          <Col md={24}>
            <Password
              placeholder="Insira sua antiga senha"
              name="senhaOld"
              onChange={handleChange}
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Col>
          <Col md={24}>
            <Password
              placeholder="Insir a nova senha"
              name="senhaNew"
              onChange={handleChange}
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Button
              type="primary"
              className="btn_orange"
              htmlType="submit"
              block
            >
              Salvar
            </Button>
          </Col>
          <Col md={24}>
            <Button
              type="link"
              className="login__form__link-btn"
              onClick={() => history.push('/')}
              block
            >
              Voltar para a tela inicial
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default injectIntl(NovaSenhaView)
