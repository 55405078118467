import React from 'react'

import { Chart, Interval, Legend, Axis, Coordinate } from 'bizcharts'

import { findDOMNode } from 'react-dom'
import { colors } from '../../../../../utils/utils'

import { Statistic, LegendaContainer } from '../styles'

function StackedBarChart({ extraTitle, extraValue, data, yAxis, xAxis }) {
  return (
    <>
      {extraTitle ? (
        <Statistic
          title={extraTitle}
          value={extraValue}
          style={{ margin: '8px' }}
        />
      ) : (
        <Statistic
          title="default"
          value="default"
          style={{ display: 'none' }}
        />
      )}

      <Chart
        height={100}
        data={data}
        scale={{
          percent: {
            min: 0,
            formatter(val) {
              return `${(val * 100).toFixed(2)}%`
            },
          },
        }}
        autoFit
      >
        <Coordinate transpose />
        <Axis name={yAxis} />
        {/* <Axis name="contrato" /> */}
        <Axis name={xAxis} />
        {/* <Axis name="qtde" /> */}
        <Interval adjust="stack" color={[yAxis, colors]} position="percent" />
      </Chart>
      <LegendaContainer>
        {data.rows.map((item, index) => (
          <div className="legenda-item">
            <div className="legenda-item-titulo">
              <div
                style={{ backgroundColor: colors[index] }}
                className="legenda-item-label"
              />
              <span>{item[Object.keys(item)[0]]}</span>
            </div>

            <span
              style={{ color: colors[index] }}
              className="legenda-item-valor"
            >
              {item.qtde} ({(item.percent * 100).toFixed(0)}%)
            </span>
          </div>
        ))}
      </LegendaContainer>
    </>
  )
}

export default StackedBarChart
