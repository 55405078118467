// Redux types
import * as constants from '../constants/constantsCargosDetalhes'

export const getCargos = () => {
  return {
    type: constants.CARGOS_GET,
    api: {
      method: 'get',
      url: constants.URL_CARGOS_GET,
    },
  }
}

export const getCargoPessoas = (id) => {
  return {
    type: constants.CARGO_PESSOAS_GET,
    api: {
      method: 'get',
      url: constants.URL_CARGO_PESSOAS_GET + id,
    },
  }
}

export const postCargoPessoa = ({
  cargo_id,
  pes_id,
  cargopes_dataentrada = null,
}) => {
  return {
    type: constants.CARGO_PESSOA_POST,
    api: {
      method: 'post',
      url: constants.URL_CARGO_PESSOA_POST + cargo_id + '/' + pes_id,
      data: {
        cargopes_dataentrada,
      },
    },
  }
}
export const postCargoCandidato = (idCargo, idPessoa) => {
  return {
    type: constants.CARGO_CANDIDATO_POST,
    api: {
      method: 'post',
      url: constants.URL_CARGO_CANDIDATO_POST + idCargo + '/' + idPessoa,
    },
  }
}
export const deleteCargoPessoa = (idCargo, idPessoa) => {
  return {
    type: constants.CARGO_PESSOA_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_CARGO_PESSOA_DELETE + idCargo + '/' + idPessoa,
    },
  }
}
export const deleteCargoCandidato = (idCargo, idPessoa) => {
  return {
    type: constants.CARGO_CANDIDATO_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_CARGO_CANDIDATO_DELETE + idCargo + '/' + idPessoa,
    },
  }
}

export const getCulturaDesejadaCargo = (cargo_id) => {
  return {
    type: constants.CARGO_CULTURA_DESEJADA_CARGO,
    api: {
      method: 'get',
      url: constants.URL_CARGO_CULTURA_DESEJADA_CARGO(cargo_id),
    },
  }
}

export const updateCulturaDesejadaCargo = ({ cargo_id, ...data }) => {
  return {
    type: constants.CARGO_CULTURA_DESEJADA_CARGO,
    api: {
      method: 'put',
      url: constants.URL_CARGO_CULTURA_DESEJADA_CARGO(cargo_id),
      data: {
        ...data,
      },
    },
  }
}

export const getCulturaInstaladaCargo = (cargo_id) => {
  return {
    type: constants.CARGO_CULTURA_INSTALADA_CARGO,
    api: {
      method: 'get',
      url: constants.URL_CARGO_CULTURA_INSTALADA_CARGO(cargo_id),
    },
  }
}
