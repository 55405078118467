// Redux types
import * as constants from '../constants/constantsCadastroColaboradores'

const initialState = {
  listaColaboradores: [],
}

export const colaboradores = (state = initialState, action) => {
  switch (action.type) {
    case constants.COLABORADORES_GET:
      const { data, status } = action.response

      if (status !== 200) return { ...state }

      return {
        ...state,
        listaColaboradores: data.pessoas
          .filter((pessoa) => pessoa.pes_ativo === 1)
          .map((pessoa) => constants.apiToFront({ pessoa })),
        listaColaboradoresInativos: data.pessoas
          .filter((pessoa) => pessoa.pes_ativo === 0)
          .map((pessoa) => constants.apiToFront({ pessoa })),
      }

    default:
      return state
  }
}
