import React, { useState, useEffect } from 'react'

import {
  CheckCircleOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'

import {
  Input,
  Button,
  Popconfirm,
  Collapse,
  Empty,
  Modal,
  Divider,
} from 'antd'

import moment from 'moment'
import 'moment-timezone'

import {
  Content,
  SubTitle,
  Label,
  Container,
  PDTItem,
  ButtonContainer,
  CustomPanel,
  MetaContainer,
  MetaHeader,
  Footer,
} from './styles'

import { metaModals as modals } from '../../constants/constantsPDT'

import MetaListItem from '../MetaListItem'

const { TextArea } = Input
export default function PDTListItem({
  pes_id,
  data: pdt,
  getColumnSearchProps,
  handleCompletePDT,
  handleCreatePDT,
  handleUpdatePDT,
  handleDeletePDT,
  handleCreateMeta,
  handleUpdateMeta,
  handleDeleteMeta,
  handleCreateAcao,
  handleUpdateAcao,
  handleCompleteAcao,
  handleDeleteAcao,
  handleChangeMetaDescricao,
  handleChangePDTData,
  openModal,
  closeModal,
  onModalSubmit,
  showModals,
  modalStates,
  handleChangeModal,
  editModal,
  error,
  active,
  handleGetPessoa,
  ...props
}) {
  const [savingPDT, setSavingPDT] = useState(false)
  const [createdByName, setCreatedByName] = useState(null)
  const [updatedByName, setUpdatedByName] = useState(null)
  const [completedByName, setCompletedByName] = useState(null)

  const {
    pdt_id,
    status,
    created_at,
    objetivo,
    completed_at,
    created_by,
    updated_by,
    completed_by,
  } = pdt

  useEffect(() => {
    if (created_by)
      handleGetPessoa(created_by).then(({ response }) => {
        if (response.status === 200) {
          const { data } = response
          const { pessoa } = data
          const { pes_nome } = pessoa
          setCreatedByName(pes_nome)
        }
      })
  }, [created_by, handleGetPessoa])

  useEffect(() => {
    if (updated_by)
      handleGetPessoa(updated_by).then(({ response }) => {
        if (response.status === 200) {
          const { data } = response
          const { pessoa } = data
          const { pes_nome } = pessoa
          setUpdatedByName(pes_nome)
        }
      })
  }, [updated_by, handleGetPessoa])

  useEffect(() => {
    if (completed_by)
      handleGetPessoa(completed_by).then(({ response }) => {
        if (response.status === 200) {
          const { data } = response
          const { pessoa } = data
          const { pes_nome } = pessoa
          setCompletedByName(pes_nome)
        }
      })
  }, [completed_by, handleGetPessoa])

  const isPDTCompleted = !!completed_at

  const pdtStatus = status || (isPDTCompleted ? 'Concluído' : 'Em andamento')

  const { metas } = pdt || {}

  return (
    <>
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={active ? [pdt_id] : null}
      >
        <CustomPanel
          {...props}
          disabled={false}
          header={`${moment(created_at)
            .utc()
            .tz(moment.tz.guess())
            .format('DD/MM/YYYY - HH:mm')} - ${pdtStatus}`}
          key={pdt_id}
          extra={
            <>
              {!isPDTCompleted && (
                <Popconfirm
                  title="Concluir PDT?"
                  onConfirm={(event) => {
                    event.stopPropagation()
                    handleCompletePDT(pdt_id)
                  }}
                  onClick={(event) => event.stopPropagation()}
                  okText="Sim"
                  cancelText="Não"
                  icon={<CheckCircleOutlined style={{ color: '#008000' }} />}
                >
                  <CheckCircleOutlined />
                </Popconfirm>
              )}
              <Divider type="vertical" />
              <Popconfirm
                title="Apagar PDT?"
                onConfirm={(event) => {
                  event.stopPropagation()
                  handleDeletePDT(pdt_id)
                }}
                onClick={(event) => event.stopPropagation()}
                okText="Sim"
                cancelText="Não"
                icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
              >
                <DeleteOutlined />
              </Popconfirm>
            </>
          }
        >
          <Content>
            <SubTitle>PDT - Plano de Desenvolvimento de Times</SubTitle>

            <Container>
              <Label>Objetivo</Label>
              <TextArea
                maxLength={1000}
                placeholder="Qual o objetivo deste PDT? O que deve ser alcançado?"
                value={objetivo}
                readOnly={isPDTCompleted}
                onChange={(e) =>
                  handleChangePDTData(pdt_id, {
                    ...pdt,
                    objetivo: e.target.value,
                  })
                }
              />
              {!isPDTCompleted && (
                <ButtonContainer>
                  <Button
                    type="primary"
                    onClick={() => {
                      setSavingPDT(true)
                      handleUpdatePDT(pdt_id, pdt).then(() =>
                        setSavingPDT(false)
                      )
                    }}
                    loading={savingPDT}
                  >
                    Salvar PDT
                  </Button>
                </ButtonContainer>
              )}

              <MetaHeader>
                <Label>Metas</Label>
                {!isPDTCompleted && (
                  <Button
                    type="primary"
                    style={{
                      borderColor: getComputedStyle(
                        document.body
                      ).getPropertyValue('--orange'),
                      backgroundColor: getComputedStyle(
                        document.body
                      ).getPropertyValue('--orange'),
                    }}
                    onClick={() => openModal(3, { pdt })}
                  >
                    Adicionar Meta
                  </Button>
                )}
              </MetaHeader>
              {metas && metas.length > 0 ? (
                <MetaContainer>
                  <>
                    {metas.map((meta, i) => (
                      <MetaListItem
                        data={meta}
                        getColumnSearchProps={getColumnSearchProps}
                        key={meta.meta_id}
                        handleChangeMetaDescricao={handleChangeMetaDescricao}
                        handleDeleteAcao={handleDeleteAcao}
                        handleCompleteAcao={handleCompleteAcao}
                        handleDeleteMeta={handleDeleteMeta}
                        handleUpdateMeta={handleUpdateMeta}
                        openModal={openModal}
                        closeModal={closeModal}
                        onModalSubmit={onModalSubmit}
                        showModals={showModals}
                        modalStates={modalStates}
                        handleChangeModal={handleChangeModal}
                        editModal={editModal}
                        error={error}
                        pdt={pdt}
                        isPDTCompleted={isPDTCompleted}
                      />
                    ))}
                  </>
                </MetaContainer>
              ) : (
                <Empty />
              )}
            </Container>

            {(created_by || updated_by || completed_by) && (
              <Footer>
                {created_by && (
                  <>
                    <Label>Criado por:{createdByName}</Label>
                  </>
                )}
                {created_by && updated_by && (
                  <Divider
                    type="vertical"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
                  />
                )}
                {updated_by && (
                  <>
                    <Label>Atualizado por:{updatedByName}</Label>
                  </>
                )}
                {((updated_by && completed_by) ||
                  (created_by && completed_by)) && (
                  <Divider
                    type="vertical"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
                  />
                )}
                {completed_by && (
                  <Label>Completado por:{completedByName}</Label>
                )}
              </Footer>
            )}
          </Content>
        </CustomPanel>
      </Collapse>
      {modals.map((m) => {
        const { title, cancelText, okText, id, width, children: Children } = m
        return (
          <Modal
            visible={showModals[id]}
            title={title}
            cancelText={cancelText}
            okText={okText}
            onCancel={() => closeModal(id)}
            onOk={() => onModalSubmit(id)}
            width={width}
            destroyOnClose
            key={id}
          >
            <Children
              handleChangeModal={(event) => handleChangeModal(id, event)}
              // handleChangeCheckbox={(event) => handleChangeCheckbox(id, event)}
              modalState={modalStates[id]}
              pdt={pdt}
              error={error}
              {...modalStates[id]}
            />
          </Modal>
        )
      })}
    </>
  )
}
