import React from 'react'
import { injectIntl } from 'react-intl'
import { StarFilled, UserAddOutlined } from '@ant-design/icons'
import moment from 'moment'

import { Divider, Table, Popconfirm } from 'antd'
import { sorterString, sorterDate } from '../../../../utils/utils'
import Link from '../../../../components/privateLink/PrivateLink'

// Components
import Button from '../../../../components/button/Button'

// Services
import { isLider } from '../../../../services/auth'

// Utils

function TableCargosDetalhes({
  dataSource,
  handleChangeLider,
  openModal,
  getColumnSearchProps,
  onTableDelete,
  rowSelection,
}) {
  const lider = isLider()
  // Table columns
  const columns = [
    {
      title: 'Líder',
      dataIndex: 'lider',
      width: '8%',
      className: 'data-center',
      render: (text, record) => (
        <StarFilled
          onClick={() => handleChangeLider(record.id)}
          style={{ color: text ? '#ef5472' : 'rgba(0,0,0,.18)' }}
        />
      ),
    },
    {
      title: 'Colaborador',
      dataIndex: 'nome',
      width: '25%',
      sorter: (a, b) => sorterString(a, b, 'nome'),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <Link to={`/perfil/${record.id}`}>{text}</Link>,
      ...getColumnSearchProps('nome'),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      width: '25%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Vetor',
      dataIndex: 'vetorComportamental',
      className: 'data-center',
      width: '6%',
    },
    {
      title: 'Data de Resposta',
      dataIndex: 'log_data',
      width: '10%',
      sorter: (a, b) => sorterDate(a, b, 'log_data'),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>{text ? moment(text).format('DD/MM/YYYY') : ''}</span>
      ),
    },
    {
      title: 'D',
      dataIndex: 'd',
      className: 'data-center',
      width: '6%',
    },
    {
      title: 'I',
      dataIndex: 'i',
      className: 'data-center',
      width: '6%',
    },
    {
      title: 'S',
      dataIndex: 's',
      className: 'data-center',
      width: '6%',
    },
    {
      title: 'C',
      dataIndex: 'c',
      className: 'data-center',
      width: '6%',
    },
    ...(!lider
      ? [
          {
            title: 'Opções',
            key: 'opcoes',
            editable: true,
            className: 'column-opcoes',
            width: '10%',
            render: (text, record) =>
              !lider && (
                <span>
                  <Popconfirm
                    title="Tem certeza que deseja remover do grupo?"
                    onConfirm={() => onTableDelete(record.id)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <a>Remover</a>
                  </Popconfirm>
                </span>
              ),
          },
        ]
      : []),
  ]

  return (
    <div className="times-detalhes__table">
      <Table
        title={() =>
          !lider && (
            <div className="times__table__buttons">
              <Button
                className="times__table__button btn_purple"
                onClick={() => openModal(0)}
              >
                GERAR PESQUISA
              </Button>
              <Button
                type="primary"
                className="times__table__button btn_purple"
                onClick={() => openModal(1)}
                icon={<UserAddOutlined />}
              >
                NOVO MEMBRO
              </Button>
            </div>
          )
        }
        rowSelection={!lider ? rowSelection : false}
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        bordered
        locale={{ emptyText: 'Nenhum registro' }}
      />
    </div>
  )
}

export default injectIntl(TableCargosDetalhes)
