// import * as constants from '../constants';

const initialState = {}

export const usuarios = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
