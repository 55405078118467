import React, { Component } from 'react'
import _ from 'lodash'
import { message } from 'antd'

import history from '../../utils/history'

// Components
import NovaSenhaView from './NovaSenhaView'

import { getEmpresa } from '../../services/auth'

// CSS

class NovaSenhaController extends Component {
  state = {
    senha: '',
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  onSubmit = (event) => {
    const { senhaNew, senhaOld } = this.state
    const { postNovaSenha } = this.props

    const contaURL = getEmpresa()

    event.preventDefault()

    const token = localStorage.getItem('access_token')

    postNovaSenha({
      user_senha: senhaNew,
      user_senha_old: senhaOld,
      authorization: `${token}`,
    })
      .then(({ response }) => {
        if (response.status === 200) {
          history.push(`/${contaURL}/dashboard/visao-geral`)
          message.success(response.data.message)
        } else {
          message.warning(response.data.message)
        }
      })
      .catch((response) => {
        message.error(response.data.message)
      })
  }

  render() {
    const { handleChange, onSubmit } = this

    return <NovaSenhaView handleChange={handleChange} onSubmit={onSubmit} />
  }
}

export default NovaSenhaController
