import React from 'react'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import moment from 'moment'
import {
  Row,
  Col,
  Card,
  Modal,
  Popconfirm,
  Tabs,
  Tooltip,
  Divider,
  Button as ButtonAntd,
} from 'antd'

import {
  TeamOutlined,
  AimOutlined,
  LinkOutlined,
  FormOutlined,
  DeleteOutlined,
  MailOutlined,
  DashboardOutlined,
} from '@ant-design/icons'

import { FiSend } from 'react-icons/fi'

import DataSet from '@antv/data-set'

// Components
import Button from '../../components/button/Button'
import Table from '../../components/editableTable/EditableTable'

// Modals
import NovoGestor from './components/novoGestor/NovoGestor'
import EscolherGestor from './components/escolherGestor'

// CSS
import './ObjetivosEstrategicos.css'
import { IconButton, Footer } from './styles'

// Constants

// Utils
import { sorterString } from '../../utils/utils'

// Services
import { getContaURL } from '../../services/auth'

import history from '../../utils/history'

const { Meta } = Card

const { TabPane } = Tabs

const cols = {
  percent: {
    min: 0,

    formatter(val) {
      return `${(val * 100).toFixed(2)}%`
    },
  },
}

function ObjetivosEstrategicosView({
  intl,
  onDeleteGestor,
  dataSource,
  onboarding = false,
  getColumnSearchProps,
  modalStates,
  showModals,
  openModal,
  closeModal,
  handleChangeModal,
  resetModal,
  onModalSubmit,
  listaPesos,
  listaTimes,
  listaFiliais,
  listaTimesPessoas,
  enviarEmailGestores,
  error,
  gestao,
  olhares,
  onDeleteObjetivo,
  editModalObjetivos,
  getGestores,
  listColaboradores,
  onUpdateGestor,
}) {
  const ds = new DataSet()
  const dvGestao = ds
    .createView()
    .source(gestao)
    .transform({
      type: 'percent',
      field: 'Valor',
      dimension: 'Tipo',
      groupBy: ['Label'],
      as: 'percent',
    })
  const dvOlhares = ds
    .createView()
    .source(olhares)
    .transform({
      type: 'percent',
      field: 'Valor',
      dimension: 'Tipo',
      groupBy: ['Label'],
      as: 'percent',
    })

  const contaURL = getContaURL()
  // Table columns
  const columns = [
    {
      title: 'Nome',
      placeholder: 'Nome da pessoa',
      dataIndex: 'pes_nome',
      editable: false,
      sorter: (a, b) => sorterString(a, b, 'pes_nome'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('pes_nome'),
    },
    {
      title: 'E-mail',
      placeholder: 'E-mail da pessoa',
      dataIndex: 'pes_email',
      editable: false,
      ...getColumnSearchProps('pes_email'),
    },
    {
      title: 'Cargos',
      placeholder: 'Cargos da pessoa',
      dataIndex: 'cargos',
      editable: false,
      sorter: (a, b) => sorterString(a, b, 'cargo_nome'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('cargos'),
    },
    {
      title: 'Peso',
      placeholder: 'Peso',
      dataIndex: 'peso',
      inputType: 'select',
      options: listaPesos.map((peso) => ({
        label: peso.descricao,
        value: peso.valor,
      })),
      editable: true,
      sorter: (a, b) => Number(a.peso) - Number(b.peso),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('peso'),
      render: (text, record) => {
        return _.find(listaPesos, ['valor', text]).descricao
      },
    },
    {
      title: 'Filial',
      dataIndex: 'filial_id',
      className: 'column-filial',
      editable: true,
      inputType: 'select',
      width: '15%',
      sorter: (a, b) => sorterString(a, b, 'filial_nome'),
      sortDirections: ['descend', 'ascend'],
      options: [
        { value: 0, label: 'Nenhum' },
        ...listaFiliais.map((filial) => ({
          value: filial.filial_id,
          label: filial.nome,
        })),
      ],
      render: (text, record) => {
        const findFilial = listaFiliais.find(
          (filial) => filial.filial_id === parseInt(record.filial_id)
        )
        return <span>{findFilial && findFilial.nome}</span>
      },
    },
    {
      title: 'Times',
      dataIndex: 'nome',
      className: 'column-filial',
      inputType: 'select',
      width: '15%',
      sorter: (a, b) => sorterString(a, b, 'nome'),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        const findTimes = listaTimesPessoas.find(
          (time) => time.pes_id === parseInt(record.pes_id)
        )
        return <span>{findTimes && findTimes.times}</span>
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => (
        <span>
          {!text ? 'Não Enviado' : text === 1 ? 'Enviado' : 'Respondido'}
        </span>
      ),
    },

    {
      title: 'Criado Por',
      dataIndex: ['creator', 'colaborador', 'pes_nome'],
      width: '10%',
      sorter: (a, b) =>
        sorterString(a, b, ['creator', 'colaborador', 'pes_nome']),
    },
    {
      title: 'Data de Criação',
      dataIndex: 'created_at',
      width: '10.5%',
      sorter: (a, b) => sorterDate(a, b, 'created_at'),
      render: (text) =>
        text
          ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
          : '',
    },
    {
      title: 'Atualizado Por',
      dataIndex: ['updater', 'colaborador', 'pes_nome'],
      width: '10%',
      sorter: (a, b) =>
        sorterString(a, b, ['updater', 'colaborador', 'pes_nome']),
    },
    {
      title: 'Data de Atualização',
      dataIndex: 'updated_at',
      width: '10%',
      sorter: (a, b) => sorterDate(a, b, 'updated_at'),
      render: (text) =>
        text
          ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
          : '',
    },

    !onboarding && {
      title: 'Ações',
      align: 'center',
      key: 'extra_actions',
      render: (text, record) => (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Divider type="vertical" />
          {record.hash && (
            <>
              <CopyToClipboard
                text={`${window.location.host}/${getContaURL()}/gestor/${
                  record.hash
                }`}
                onCopy={() =>
                  toast.success(
                    'Link da pesquisa copiado para a área de transferência.'
                  )
                }
              >
                <Tooltip title="Link">
                  <a href="javascript:;" aria-label="link">
                    <LinkOutlined />
                  </a>
                </Tooltip>
              </CopyToClipboard>
              <Divider type="vertical" />
            </>
          )}
          <span>
            <a
              onClick={() =>
                enviarEmailGestores({ id: record.pes_id }).then(() =>
                  getGestores()
                )
              }
            >
              {record.hash ? (
                <Tooltip title="Reenviar e-mail">
                  <MailOutlined />
                </Tooltip>
              ) : (
                <Tooltip title="Enviar e-mail">
                  <MailOutlined />
                </Tooltip>
              )}
            </a>
          </span>
        </span>
      ),
    },
  ]

  const objetivosColumns = [
    {
      title: 'Drives Estratégicos',
      placeholder: 'Drives Estratégicos',
      dataIndex: 'obj_TH',
      sorter: (a, b) => sorterString(a, b, 'obj_TH'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('obj_TH'),
    },
    {
      title: 'Objetivo Cliente',
      placeholder: 'Objetivo Cliente',
      dataIndex: 'obj_cliente',
      sorter: (a, b) => sorterString(a, b, 'obj_cliente'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('obj_cliente'),
    },
    {
      title: 'Descrição',
      placeholder: 'Descrição',
      dataIndex: 'descricao',
      sorter: (a, b) => sorterString(a, b, 'descricao'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('descricao'),
    },

    !onboarding && {
      title: 'Ações',
      align: 'center',
      dataIndex: 'acoes',
      render: (text, record) => {
        return (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <a onClick={() => editModalObjetivos(record)}>
              <Tooltip title="Editar">
                <FormOutlined />
              </Tooltip>
            </a>
            <Divider type="vertical" />
            <span>
              <Popconfirm
                title="Tem certeza que deseja excluir?"
                onConfirm={() => onDeleteObjetivo(record.obj_conta_id)}
                okText="Sim"
                cancelText="Não"
              >
                <a>
                  <Tooltip title="Excluir" placement="bottom">
                    <DeleteOutlined />
                  </Tooltip>
                </a>
              </Popconfirm>
            </span>
          </span>
        )
      },
    },
  ]

  return (
    <>
      <Helmet>
        <title>TeamHub | Avaliadores</title>
      </Helmet>
      <div className="objetivos-estrategicos">
        <Row>
          <Col md={24}>
            <Card>
              <Meta
                title="Adicione pessoas para responderem a pesquisa de Cultura Desejada."
                description="Selecione gestores existentes e torne-os avaliadores ou registre um gestor para torná-lo avaliador."
              />
            </Card>
            <Card
              type="inner"
              className="objetivos-estrategicos__gestores objetivos-estrategicos__card"
            >
              <div className="objetivos-estrategicos__gestores__table">
                <Table
                  header={(record) => (
                    <div className="objetivos-estrategicos__gestores__table__buttons">
                      <Tooltip title="Tornar um administrador existente um avaliador de Cultura Desejada.">
                        <Button
                          type="primary"
                          className="btn_purple objetivos-estrategicos__gestores__table__buttons__desktop"
                          onClick={() => openModal('escolherGestor')}
                        >
                          ADICIONAR AVALIADOR
                        </Button>
                      </Tooltip>
                      <Tooltip title="Cadastre um avaliador de Cultura Desejada.">
                        <Button
                          type="secondary"
                          className="btn_purple objetivos-estrategicos__gestores__table__buttons__desktop"
                          onClick={() => openModal('novoGestor')}
                        >
                          CADASTRAR AVALIADOR
                        </Button>
                      </Tooltip>
                      <Popconfirm
                        title="Você tem certeza que deseja enviar email para todos os avaliadores?"
                        onConfirm={() => {
                          enviarEmailGestores()
                            .then((response) => {
                              if (response.status == 400) {
                                toast.error(response.message)
                              }
                              if (response.status == 500) {
                                toast.error('Erro no servidor')
                              }
                              toast.success(response.message)
                            })
                            .catch((err) => {
                              toast.error(err)
                            })
                        }}
                        okText="Sim"
                        cancelText="Não"
                      >
                        <Button
                          className="btn_purple"
                          style={{ marginLeft: 'auto' }}
                        >
                          ENVIAR PESQUISA PARA TODOS
                        </Button>
                      </Popconfirm>
                    </div>
                  )}
                  dataSource={dataSource.listaGestores.map((avaliador) => {
                    return {
                      ...avaliador,
                      key: avaliador.pes_id,
                    }
                  })}
                  columns={columns}
                  onDelete={(_, record) =>
                    onDeleteGestor({ id: record.pes_id })
                  }
                  onSave={(data) => {
                    onUpdateGestor(data)
                  }}
                  isEditable
                  isDeleble
                  key={(record) => record.key}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Modal
          visible={showModals.novoGestor}
          title="CADASTRAR NOVO AVALIADOR"
          onCancel={() => closeModal('novoGestor')}
          footer={
            <Footer>
              <ButtonAntd onClick={() => closeModal('novoGestor')}>
                CANCELAR
              </ButtonAntd>
              <ButtonAntd
                type="primary"
                onClick={() => onModalSubmit('novoGestor')}
              >
                SALVAR
              </ButtonAntd>
              <IconButton
                type="primary"
                onClick={() =>
                  onModalSubmit('novoGestor', { enviarEmails: true })
                }
              >
                ENVIAR <FiSend />
              </IconButton>
            </Footer>
          }
          destroyOnClose
        >
          <NovoGestor
            listaPesos={listaPesos}
            listaTimes={listaTimes}
            listaFiliais={listaFiliais}
            handleChangeModal={(event) =>
              handleChangeModal('novoGestor', event)
            }
            resetModal={resetModal}
            error={error}
            {...modalStates.novoGestor}
          />
        </Modal>
        <Modal
          visible={showModals.escolherGestor}
          title="ESCOLHER AVALIADOR"
          onCancel={() => closeModal('escolherGestor')}
          footer={
            <Footer>
              <ButtonAntd onClick={() => closeModal('escolherGestor')}>
                CANCELAR
              </ButtonAntd>
              <ButtonAntd
                type="primary"
                onClick={() => onModalSubmit('escolherGestor')}
              >
                SALVAR
              </ButtonAntd>
              <IconButton
                type="primary"
                onClick={() =>
                  onModalSubmit('escolherGestor', { enviarEmails: true })
                }
              >
                ENVIAR <FiSend />
              </IconButton>
            </Footer>
          }
          destroyOnClose
        >
          <EscolherGestor
            handleChangeModal={(event) =>
              handleChangeModal('escolherGestor', event)
            }
            listColaboradores={listColaboradores}
            resetModal={resetModal}
            error={error}
            {...modalStates.escolherGestor}
          />
        </Modal>
      </div>
    </>
  )
}

export default injectIntl(ObjetivosEstrategicosView)
