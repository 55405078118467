// Redux types
import * as constants from '../constants/constantsTimes'
import { message } from 'antd'

const initialState = {
  list: [],
}

export const times = (state = initialState, action) => {
  switch (action.type) {
    case constants.TIMES_GET:
      const { grupos } = action.response.data

      if (action.response.status !== 200) return { ...state }

      return { ...state, list: grupos }

    case constants.TIME_POST:
      if (action.response.status !== 200) message.error('Erro ao cadastrar.')

      return state

    case constants.TIMES_DELETE:
      if (action.response.status !== 200) message.error('Erro ao deletar time.')

      return state

    case constants.TIMES_SEND_AVALIACOES:
      if (action.response.status !== 200)
        message.error('Erro ao gerar pesquisa de perfil comportamental.')

      return state

    default:
      return state
  }
}
