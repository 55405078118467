import { toast } from 'react-toastify'

import * as constants from '../constants/analiseGeralConstants'

import { parseCulturas } from '../../../utils/utils'

const initialState = {
  cultura: {
    forcas: [],
    cultura: [],
    disc: [],
    olhares: [],
    gestao: [],
    generos: [],
    geracoes: [],
  },
  culturaOrganizacional: {
    niveisConfianca: [],
    olharLideranca: {
      perceboLider: [],
      frequenciaFeedback: [],
      feedbackContribui: [],
      reconhecimento: [],
      expectativaEntregas: [],
      perfilLiderDireto: [],
    },
    olharParaMudancas: {
      pontosFortes: [],
      impedimento: [],
      principalProblema: [],
      engajamento: undefined,
      velocidadeAdocao: undefined,
      proficiencia: undefined,
    },
    olharParaSi: {
      escolhaEmpresa: [],
      prioridades: [],
      expectativas6meses: [],
      fonteEntusiasmo: [],
    },
    valoresEvidencia: [],
    satisfacaoGeral: [],
  },
  loadingCultura: false,
  loadingCulturaOrganizacional: false,
}

const analiseGeral = (state = initialState, action) => {
  switch (action.type) {
    case constants.ANALISE_GERAL_GET_CULTURA: {
      if (action.response.status === 204) {
        return state
      }
      if (action.response.status !== 200) {
        toast.error('Erro ao obter Cultura.')
      }

      const { culturaDesejada, culturaInstalada } = action.response.data

      const { generos, geracoes } = culturaInstalada

      const { cultura, forcas, disc, olhares, gestao } = parseCulturas({
        culturaDesejada,
        culturaInstalada,
      })

      return {
        ...state,
        cultura: {
          cultura,
          forcas,
          disc,
          olhares,
          gestao,
          generos,
          geracoes,
        },
        loadingCultura: false,
      }
    }
    case constants.ANALISE_GERAL_GET_CULTURA_ORGANIZACIONAL: {
      if (action.response.status === 204) {
        return state
      }
      if (action.response.status !== 200) {
        toast.error('Erro ao obter Cultura Organizacional.')
      }

      const { culturaOrganizacional: culturaOrg, disc } = action.response.data

      return {
        ...state,
        culturaOrganizacional: {
          ...state.culturaOrganizacional,
          ...culturaOrg,

          olharLideranca: {
            ...state.culturaOrganizacional.olharLideranca,
            ...culturaOrg.olharLideranca,
          },
          olharParaMudancas: {
            ...state.culturaOrganizacional.olharParaMudancas,
            ...culturaOrg.olharParaMudancas,
          },
          olharParaSi: {
            ...state.culturaOrganizacional.olharParaSi,
            ...culturaOrg.olharParaSi,
          },
        },
        disc,
        loadingCulturaOrganizacional: false,
      }
    }
    case constants.ANALISE_GERAL_LOADING: {
      return {
        ...state,
        loadingCultura: true,
        loadingCulturaOrganizacional: true,
      }
    }
    default:
      return state
  }
}

export default analiseGeral
