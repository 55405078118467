import React, { useEffect, useState, useCallback } from 'react'

import { Helmet } from 'react-helmet'

import {
  Col,
  Row,
  Steps,
  Space,
  DatePicker,
  Spin,
  Empty,
  Card,
  Alert,
} from 'antd'

import { FilterOutlined, UserOutlined, CheckOutlined } from '@ant-design/icons'

import { LineChart } from 'bizcharts'

import DataSet from '@antv/data-set'

import {
  Statistic,
  ContainerKPI,
  EtapaKPI,
  ChartContainer,
  DashboardCol,
  ControllersContainer,
  LoadingContainer,
} from './styles'

import BarChart from './components/BarChart'

import StackedBarChart from './components/StackedBarChart'

import api from '../../../../services/api'

function DashboardProcessoSeletivo() {
  const [kpi, setKpi] = useState([])

  const [loadingKpi, setLoadingKpi] = useState(false)

  const [selectedDates, setSelectedDates] = useState([])

  const [beginDate, setBeginDate] = useState([])
  const [startDate, setStartDate] = useState([])

  const [porCargo, setPorCargo] = useState([])
  const [porCargoHomeOffice, setPorCargoHomeOffice] = useState([])

  const [porTime, setPorTime] = useState([])
  const [porTimeHomeOffice, setPorTimeHomeOffice] = useState([])

  const [porLocal, setPorLocal] = useState([])
  const [porFilial, setPorFilial] = useState([])

  const [totalHomeOffice, setTotalHomeOffice] = useState([])

  const [criadosPorPessoa, setCriadosPorPessoa] = useState([])
  const [finalizadosPorPessoa, setFinalizadosPorPessoa] = useState([])
  const [andamentoPorPessoa, setAndamentoPorPessoa] = useState([])
  const [abertosPorHunter, setAbertosPorHunter] = useState([])

  const [porTipoContrato, setPorTipoContrato] = useState([])
  const [porMotivoContratacao, setPorMotivoContratacao] = useState([])
  const [porDivulgacao, setPorDivulgacao] = useState([])
  const [porMotivoCancelamento, setPorMotivoCancelamento] = useState([])

  const loadKpis = useCallback(async () => {
    setLoadingKpi(true)

    try {
      const response = await api.get('/ps/kpi')

      if (response.status === 200) {
        const { data } = response

        setKpi(data)

        setPorCargo(data.porCargo)
        setPorCargoHomeOffice(data.homeOfficePorCargo)
        setPorTime(data.porGrupo)
        setPorTimeHomeOffice(data.homeOfficePorGrupo)
        setPorLocal(data.porLocal)
        setPorFilial(data.porFilial)
        setTotalHomeOffice(data.homeOffice)

        setAndamentoPorPessoa(data.andamentoPorPessoa)
        setFinalizadosPorPessoa(data.finalizadosPorPessoa)
        setCriadosPorPessoa(data.finalizadosPorPessoa)

        setAbertosPorHunter(data.abertosPorHunter)

        setPorTipoContrato(data.porTipoContrato)
        setPorMotivoContratacao(data.porMotivoContratacao)
        setPorDivulgacao(data.porDivulgacao)
        setPorMotivoCancelamento(data.porMotivoCancelamento)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoadingKpi(false)
    }
  }, [])

  useEffect(() => {
    loadKpis()
  }, [loadKpis])

  const handleChangeSelectedDates = useCallback((selectedDates) => {
    setSelectedDates(selectedDates)
  }, [])

  const loadKpiByDate = useCallback(async (dates) => {
    setLoadingKpi(true)

    try {
      const response = await api.get('/ps/kpi', {
        params: {
          beginDate: dates[0],
          endDate: dates[1],
        },
      })

      if (response.status === 200) {
        const { data } = response

        setPorCargo(data.porCargo)
        setPorCargoHomeOffice(data.homeOfficePorCargo)
        setPorTime(data.porGrupo)
        setPorTimeHomeOffice(data.homeOfficePorGrupo)
        setPorLocal(data.porLocal)
        setPorFilial(data.porFilial)
        setTotalHomeOffice(data.homeOffice)

        setAndamentoPorPessoa(data.andamentoPorPessoa)
        setFinalizadosPorPessoa(data.finalizadosPorPessoa)
        setCriadosPorPessoa(data.finalizadosPorPessoa)

        setAbertosPorHunter(data.abertosPorHunter)

        setPorTipoContrato(data.porTipoContrato)
        setPorMotivoContratacao(data.porMotivoContratacao)
        setPorDivulgacao(data.porDivulgacao)
        setPorMotivoCancelamento(data.porMotivoCancelamento)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoadingKpi(false)
    }
  }, [])

  useEffect(() => {
    setSelectedDates([])
  }, [setSelectedDates])
  const ds = new DataSet()

  const dvPorCargo = ds
    .createView()
    .source(porCargo)
    .transform({ type: 'rename', map: { qtde: 'Quantidade' } })
  const dvPorCargoHomeOffice = ds
    .createView()
    .source(porCargoHomeOffice)
    .transform({ type: 'rename', map: { qtde: 'Quantidade' } })
  const dvPorTime = ds
    .createView()
    .source(porTime)
    .transform({ type: 'rename', map: { qtde: 'Quantidade' } })
  const dvPorTimeHomeOffice = ds
    .createView()
    .source(porTimeHomeOffice)
    .transform({ type: 'rename', map: { qtde: 'Quantidade' } })
  const dvPorLocal = ds
    .createView()
    .source(porLocal)
    .transform({ type: 'rename', map: { qtde: 'Quantidade' } })
  const dvPorFilial = ds
    .createView()
    .source(porFilial)
    .transform({ type: 'rename', map: { qtde: 'Quantidade' } })

  const dvAndamentoPorPessoa = ds
    .createView()
    .source(andamentoPorPessoa)
    .transform({ type: 'rename', map: { qtde: 'Quantidade' } })
  const dvFinalizadosPorPessoa = ds
    .createView()
    .source(finalizadosPorPessoa)
    .transform({ type: 'rename', map: { qtde: 'Quantidade' } })
  const dvCriadosPorPessoa = ds
    .createView()
    .source(criadosPorPessoa)
    .transform({ type: 'rename', map: { qtde: 'Quantidade' } })
  const dvAbertosPorHunter = ds
    .createView()
    .source(abertosPorHunter)
    .transform({ type: 'rename', map: { qtde: 'Quantidade' } })

  const dvPorTipoContrato = ds.createView().source(porTipoContrato).transform({
    type: 'percent',
    field: 'qtde',
    dimension: 'contrato',
    as: 'percent',
  })
  const dvPorMotivoContratacao = ds
    .createView()
    .source(porMotivoContratacao)
    .transform({
      type: 'percent',
      field: 'qtde',
      dimension: 'motivo',
      as: 'percent',
    })
  const dvPorDivulgacao = ds.createView().source(porDivulgacao).transform({
    type: 'percent',
    field: 'qtde',
    dimension: 'divulgacao',
    as: 'percent',
  })
  const dvPorMotivoCancelamento = ds
    .createView()
    .source(porMotivoCancelamento)
    .transform({
      type: 'percent',
      field: 'qtde',
      dimension: 'motivo',
      as: 'percent',
    })

  const { Step } = Steps
  const { RangePicker } = DatePicker

  return (
    <>
      <Helmet>
        <title>TeamHub | Dashboard de Processos Seletivos</title>
      </Helmet>

      <Col span={24}>
        {loadingKpi ? (
          <LoadingContainer style={{ height: '300px' }}>
            <Spin size="large" />
          </LoadingContainer>
        ) : (
          <Row gutter={16} style={{ marginBottom: '36px' }}>
            <Col lg={12} sm={24} xs={24} style={{ padding: 0 }}>
              <Space
                size="middle"
                direction="vertical"
                style={{ width: '100%' }}
              >
                <Col span={24}>
                  <ContainerKPI>
                    <Statistic
                      title="Em andamento"
                      value={kpi.andamento}
                      precision={0}
                    />
                    <Statistic
                      title="Atrasados"
                      value={kpi.atrasados}
                      precision={0}
                      suffix={`(${(
                        (parseInt(kpi.atrasados) / parseInt(kpi.andamento)) *
                        100
                      ).toFixed(1)}%)`}
                    />
                    <Statistic
                      title="Em dia"
                      value={kpi.emDia}
                      precision={0}
                      suffix={`(${(
                        (parseInt(kpi.emDia) / parseInt(kpi.andamento)) *
                        100
                      ).toFixed(1)}%)`}
                    />
                    <Statistic
                      title="Concluídos"
                      value={kpi.concluidos}
                      precision={0}
                    />
                    <Statistic
                      title="Cancelados"
                      value={kpi.cancelados}
                      precision={0}
                    />
                    <Statistic
                      title="Suspensos"
                      value={kpi.suspensos}
                      precision={0}
                    />
                    <Statistic
                      title="Duração Média"
                      value={kpi.duracaoMedia}
                      precision={0}
                    />
                  </ContainerKPI>
                </Col>
                <Col span={24}>
                  <ContainerKPI>
                    <Steps>
                      <Step
                        title={
                          <EtapaKPI>
                            <span>Inscritos</span>
                            <span className="value value-inscritos">
                              {kpi.inscritos}
                            </span>
                          </EtapaKPI>
                        }
                        icon={<UserOutlined />}
                      />
                      <Step
                        title={
                          <EtapaKPI>
                            <span>Triados</span>
                            <span className="value value-triados">
                              {kpi.triados}
                            </span>
                          </EtapaKPI>
                        }
                        icon={<FilterOutlined />}
                        className="ant-steps-item-active"
                      />
                      <Step
                        title={
                          <EtapaKPI>
                            <span>Contratados</span>
                            <span className="value value-contratados">
                              {kpi.contratados}
                            </span>
                          </EtapaKPI>
                        }
                        icon={<CheckOutlined />}
                      />
                    </Steps>
                  </ContainerKPI>
                </Col>
              </Space>
            </Col>
            <Col lg={12} sm={24} xs={24}>
              <ChartContainer title="Inscrições últimos 15 dias">
                <LineChart
                  data={kpi.inscricoes15dias}
                  title={{
                    visible: true,
                    style: {
                      fontFamily: 'inherit',
                      fontSize: 16,
                    },
                  }}
                  xField="DATA"
                  yField="qtde"
                  height={150}
                  forceFit
                  xAxis={{ visible: false }}
                />
              </ChartContainer>
            </Col>
          </Row>
        )}

        {loadingKpi ? (
          <LoadingContainer style={{ height: '600px' }}>
            <Spin size="large" />
          </LoadingContainer>
        ) : (
          <>
            <Row gutter={16} style={{ marginBottom: '36px' }}>
              <Col lg={24} sm={24} xs={24}>
                <Card>
                  <span>Selecione um intervalo de tempo para analisar:</span>
                  <RangePicker
                    style={{ marginLeft: '8px' }}
                    format="YYYY-MM-DD"
                    value={selectedDates}
                    onChange={(dates, dateStrings) => {
                      handleChangeSelectedDates(dates)
                      loadKpiByDate(dateStrings)
                    }}
                  />
                </Card>
              </Col>
            </Row>

            <Row gutter={20}>
              {porCargo.length === 0 ? (
                <>
                  <DashboardCol lg={8} md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos por cargo">
                      <Empty description="Não há dados aserem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol lg={8} md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos por cargo">
                    <BarChart
                      data={dvPorCargo}
                      position="cargo*Quantidade"
                      color="#FFB585"
                      label="Quantidade"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}
              {porCargoHomeOffice.length === 0 ? (
                <>
                  <DashboardCol lg={8} md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos por cargo em homeoffice">
                      <Empty description="Não há dados a serem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol lg={8} md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos por cargo em homeoffice">
                    <BarChart
                      extraTitle="Total de processos para homeoffice"
                      extraValue={totalHomeOffice}
                      data={dvPorCargoHomeOffice}
                      position="cargo*Quantidade"
                      color="#FFB585"
                      label="Quantidade"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}

              {porTime.length === 0 ? (
                <>
                  <DashboardCol lg={8} md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos por time">
                      <Empty description="Não há dados a serem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol lg={8} md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos por time">
                    <BarChart
                      data={dvPorTime}
                      position="grupo*Quantidade"
                      color="#EF5C79"
                      label="Quantidade"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}
              {porTimeHomeOffice.length === 0 ? (
                <>
                  <DashboardCol lg={8} md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos por time em homeoffice">
                      <Empty description="Não há dados a serem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol lg={8} md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos por time em homeoffice">
                    <BarChart
                      extraTitle="Total de processos para homeoffice"
                      extraValue={totalHomeOffice}
                      data={dvPorTimeHomeOffice}
                      position="times*Quantidade"
                      color="#EF5C79"
                      label="Quantidade"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}

              {porLocal.length === 0 ? (
                <>
                  <DashboardCol lg={8} md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos por local">
                      <Empty description="Não há dados a serem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol lg={8} md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos por local">
                    <BarChart
                      data={dvPorLocal}
                      position="local*Quantidade"
                      color="#3A3B90"
                      label="Quantidade"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}
              {porFilial.length === 0 ? (
                <>
                  <DashboardCol lg={8} md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos por filial">
                      <Empty description="Não há dados a serem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol lg={8} md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos por filial">
                    <BarChart
                      data={dvPorFilial}
                      position="filial*Quantidade"
                      color="#3A3B90"
                      label="Quantidade"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}
            </Row>

            <Row gutter={20}>
              {porTipoContrato.length === 0 ? (
                <>
                  <DashboardCol md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos por tipo de contrato">
                      <Empty description="Não há dados a serem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos por tipo de contrato">
                    <StackedBarChart
                      data={dvPorTipoContrato}
                      yAxis="contrato"
                      xAxis="qtde"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}

              {porMotivoContratacao.length === 0 ? (
                <>
                  <DashboardCol md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos por motivo de contratação">
                      <Empty description="Não há dados a serem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos por motivo de contratação">
                    <StackedBarChart
                      data={dvPorMotivoContratacao}
                      yAxis="motivo"
                      xAxis="qtde"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}

              {porDivulgacao.length === 0 ? (
                <>
                  <DashboardCol md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos por tipo de divulgação">
                      <Empty description="Não há dados a serem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos por tipo de divulgação">
                    <StackedBarChart
                      data={dvPorDivulgacao}
                      yAxis="divulgacao"
                      xAxis="qtde"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}

              {porMotivoCancelamento.length === 0 ? (
                <>
                  <DashboardCol md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos por motivo de cancelamento">
                      <Empty description="Não há dados a serem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos por motivo de cancelamento">
                    <StackedBarChart
                      data={dvPorMotivoCancelamento}
                      yAxis="motivo"
                      xAxis="qtde"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}
            </Row>

            <Row gutter={20}>
              {criadosPorPessoa.length === 0 ? (
                <>
                  <DashboardCol lg={8} md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos criados por pessoa">
                      <Empty description="Não há dados a serem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol lg={8} md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos criados por pessoa">
                    <BarChart
                      data={dvCriadosPorPessoa}
                      position="nome*Quantidade"
                      color="#619EA9"
                      label="Quantidade"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}
              {finalizadosPorPessoa.length === 0 ? (
                <>
                  <DashboardCol lg={8} md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos finalizados por pessoa">
                      <Empty description="Não há dados a serem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol lg={8} md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos finalizados por pessoa">
                    <BarChart
                      data={dvFinalizadosPorPessoa}
                      position="nome*Quantidade"
                      color="#619EA9"
                      label="Quantidade"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}
              {andamentoPorPessoa.length === 0 ? (
                <>
                  <DashboardCol lg={8} md={12} sm={24} xs={24}>
                    <ChartContainer title="Processos em andamento por pessoa">
                      <Empty description="Não há dados a serem apresentados para o período selecionado." />
                    </ChartContainer>
                  </DashboardCol>
                </>
              ) : (
                <DashboardCol lg={8} md={12} sm={24} xs={24}>
                  <ChartContainer title="Processos em andamento por pessoa">
                    <BarChart
                      data={dvAndamentoPorPessoa}
                      position="nome*Quantidade"
                      color="#619EA9"
                      label="Quantidade"
                    />
                  </ChartContainer>
                </DashboardCol>
              )}
            </Row>

            {abertosPorHunter.length === 0 ? (
              <>
                <DashboardCol span={24}>
                  <ChartContainer title="Processos por hunter">
                    <Empty description="Não há dados a serem apresentados para o período selecionado." />
                  </ChartContainer>
                </DashboardCol>
              </>
            ) : (
              <DashboardCol span={24}>
                <ChartContainer title="Processos por hunter">
                  <BarChart
                    data={dvAbertosPorHunter}
                    position="nome*Quantidade"
                    color="rgb(246, 218, 215)"
                    label="Quantidade"
                  />
                </ChartContainer>
              </DashboardCol>
            )}
          </>
        )}
      </Col>
    </>
  )
}

export default DashboardProcessoSeletivo
