import { connect } from 'react-redux'
import * as actions from './actions/actionsCargos'

// Components
import CargosController from './CargosController'

// Actions

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, { ...actions })(CargosController)
