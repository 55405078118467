import React, { useMemo, useState } from 'react'

import moment from 'moment'

import 'moment-timezone'

import { toast } from 'react-toastify'

import { Modal, Table, Tag } from 'antd'

import {
  UserOutlined,
  PlusSquareOutlined,
  RedoOutlined,
} from '@ant-design/icons'

function DetalhesTestesCandidato({
  showModalDetalhe,
  handleCloseModalDetalhes,
  dataCandidato,
  testesProcessoSeletivo,
}) {
  const [testesSelecionados, setTestesSelecionados] = useState([])

  const tableDetalhesCandidatoColumns = useMemo(() => {
    return [
      {
        title: 'Teste',
        dataIndex: 'quiz_id',
        render: (text, record) => {
          const teste = testesProcessoSeletivo.find(
            (quiz) => quiz.quiz_id === text
          )

          return <span>{teste.titulo}</span>
        },
      },
      {
        title: 'Pontuação',
        dataIndex: 'nota',
        align: 'center',
        sorter: (a, b) => a.nota - b.nota,
        render: (text, record) => {
          return (
            <>
              {text === null ? (
                <span style={{ color: '#718096' }}>Não respondido</span>
              ) : (
                <span>
                  {text}/{record.questao_quantidade}
                </span>
              )}
            </>
          )
        },
      },
      {
        title: 'Resultado',
        dataIndex: 'status',
        align: 'center',
        render: (text, record) => {
          return (
            <>
              {text === 1 && (
                <Tag style={{ marginLeft: '20px' }} color="success">
                  Aprovado
                </Tag>
              )}

              {text === 0 && (
                <Tag style={{ marginLeft: '20px' }} color="error">
                  Reprovado
                </Tag>
              )}

              {text === null && (
                <span style={{ color: '#718096' }}>Não respondido</span>
              )}
            </>
          )
        },
      },
    ]
  }, [testesProcessoSeletivo])

  const rowSelection = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: (selectedRowKeys, selectedRows) => {},
  }

  return (
    <Modal
      title="TESTES CANDIDATO"
      visible={showModalDetalhe}
      cancelText="FECHAR"
      okText="OK"
      onCancel={() => handleCloseModalDetalhes()}
      onOk={() => handleCloseModalDetalhes()}
      width="70%"
      centered
    >
      <Table
        dataSource={dataCandidato.pontuacao}
        columns={tableDetalhesCandidatoColumns}
      />
    </Modal>
  )
}

export default DetalhesTestesCandidato
