import * as constants from '../constants/analiseGeralTimesConstants'

export const getCulturaTime = (id) => {
  return {
    type: constants.ANALISE_GERAL_TIMES_GET_CULTURA,
    api: {
      method: 'post',
      url: constants.URL_ANALISE_GERAL_TIMES_GET_CULTURA(id),
    },
  }
}

export const getCulturaOrganizacionalTime = (id) => {
  return {
    type: constants.ANALISE_GERAL_TIMES_GET_CULTURA_ORGANIZACIONAL,
    api: {
      method: 'get',
      url: constants.URL_ANALISE_GERAL_TIMES_GET_CULTURA_ORGANIZACIONAL(id),
    },
  }
}

export const getTimes = () => {
  return {
    type: constants.ANALISE_GERAL_TIMES_GET_TIMES,
    api: {
      method: 'get',
      url: constants.URL_ANALISE_GERAL_TIMES_GET_TIMES,
    },
  }
}

export const load = () => {
  return {
    type: constants.ANALISE_GERAL_TIMES_LOADING,
  }
}

export const getNps = (id) => {
  return {
    type: constants.GET_DASHBOARD_NPS,
    api: {
      method: 'get',
      url: `/relatorios/grupo/${id}/nps`,
    },
  }
}
