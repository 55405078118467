import React from 'react'

// Components
import EnviarPesquisa from '../../../components/enviarPesquisa/EnviarPesquisa'
import AdicionarNovoTime from '../components/adicionarNovoTime/AdicionarNovoTime'

// URLs
export const URL_TIMES = `${process.env.REACT_APP_API_URL}/grupo`
export const URL_TIMES_GET = `${URL_TIMES}/list/`
export const URL_TIME_POST = `${URL_TIMES}/store`
export const URL_TIMES_PUT = `${URL_TIMES}/update`
export const URL_TIMES_DELETE = `${URL_TIMES}/delete`
export const URL_TIMES_SEND_AVALIACOES = `${process.env.REACT_APP_API_URL}/avaliacoes/send/grupo/`
export const URL_SEND_PERCEPCAO = `${process.env.REACT_APP_API_URL}/pesquisa/grupo`
export const URL_TIMES_SEND_VALORES = (id) =>
  `${process.env.REACT_APP_API_URL}/pesqvalores/sendlote/grupo/${id}`
export const URL_PS_FILIAL = `${process.env.REACT_APP_API_URL}/filiais`

// Redux types
export const PS_FILIAL_LIST = 'PS_FILIAL_LIST'
export const TIMES_GET = 'TIMES_GET'
export const TIME_POST = 'TIME_POST'
export const TIMES_PUT = 'TIMES_PUT'
export const TIMES_DELETE = 'TIMES_DELETE'
export const TIMES_SEND_AVALIACOES = 'TIMES_SEND_AVALIACOES'
export const SEND_PERCEPCAO = 'SEND_PERCEPCAO'
export const TIMES_SEND_VALORES = `TIMES_SEND_VALORES`

// Modals
export const modals = [
  {
    id: 0,
    title: 'GERAR PESQUISA PARA OS TIMES SELECIONADOS',
    cancelText: 'CANCELAR',
    okText: 'ENVIAR',
    children: EnviarPesquisa,
    initialState: {
      vetorComportamental: false,
      percepcaoAmbiente: false,
      valoresOrganizacionais: false,
      enviarGruposFilho: false,
      enviarEmails: false,
    },
  },
  {
    id: 1,
    title: 'Adicionar Novo Time',
    cancelText: 'CANCELAR',
    okText: 'SALVAR',
    children: AdicionarNovoTime,
    initialState: {
      nome: '',
      pai: '',
      descricao: '',
      filial: '',
    },
  },
]

export const dataSource = [
  {
    nome: 'time 1',
    qtde: 10,
    key: 1,
    children: [
      {
        key: 11,
        nome: 'submtime 1',
        qtde: 5,
      },
      {
        key: 12,
        nome: 'submtime 12',
        qtde: 6,
      },
    ],
  },
  {
    nome: 'time 2',
    qtde: 10,
    key: 2,
    children: [
      {
        key: 21,
        nome: 'submtime 21',
        qtde: 3,
      },
    ],
  },
  {
    nome: 'time 3',
    qtde: 10,
    key: 3,
  },
  {
    nome: 'time 4',
    qtde: 10,
    key: 4,
  },
]

export const dataFilial = [
  {
    filial_id: 1,
    nome: 'filial nome',
  },
]

// Data processing
export const apiToFront = ({ grupo }) => ({
  nome: grupo.grupo_nome || '',
  pai: grupo.grupo_pai,
  descricao: grupo.grupo_descricao,
  qtde: grupo.qtde,
  id: grupo.grupo_id,
  filial: grupo.filial_nome,
})
