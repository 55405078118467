import { Card, Col, Row, Select, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
// import { Container } from './styles';
import AnaliseGeralView from '../../components/AnaliseGeral'
import {
  getCulturaOrganizacionalTime,
  getCulturaTime,
  getTimes,
  load,
} from './actions/analiseGeralTimesActions'

const { Option } = Select

function AnaliseGeral() {
  const dispatch = useDispatch()

  const [nps, setNPS] = useState([])

  const [count, setCount] = useState(0)

  const cultura = useSelector((state) => state.analiseGeralTimes.cultura)

  const culturaOrganizacional = useSelector(
    (state) => state.analiseGeralTimes.culturaOrganizacional
  )
  let grupos
  if (nps != 'N/A' && nps != []) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    grupos = useSelector((state) => state.analiseGeralTimes.grupos)
  }

  const [selectedGroup, setSelectedGroup] = useState(null)

  const loadingCulturaOrg = useSelector(
    (state) => state.analiseGeralTimes.loadingCulturaOrg
  )

  const loadingCultura = useSelector(
    (state) => state.analiseGeralTimes.loadingCultura
  )

  // const loadNPS = useCallback(
  //   (grupo_id) => {
  //     dispatch(getNps(grupo_id)).then(({ response }) => {
  //       if (response.status === 200) {
  //         const nps = response.data;
  //         setNPS(nps);
  //       } else {
  //         const nps = "N/A";
  //         setNPS(nps);
  //       }
  //     });
  //   },
  //   [dispatch]
  // );

  useEffect(() => {
    if (count < 3) {
      dispatch(getTimes())
      if (grupos.length > 0) {
        setSelectedGroup(grupos[0].grupo_id)
      }
      if (selectedGroup) {
        // loadNPS(selectedGroup);
        dispatch(load())
        dispatch(getCulturaTime(selectedGroup))
        dispatch(getCulturaOrganizacionalTime(selectedGroup))
      }
    }
    setCount(count + 1)
  }, [selectedGroup])

  useEffect(() => {
    let countSelect = 0
    while (countSelect < 3) {
      dispatch(getTimes())
      if (selectedGroup) {
        // loadNPS(selectedGroup);
        dispatch(load())
        dispatch(getCulturaTime(selectedGroup))
        dispatch(getCulturaOrganizacionalTime(selectedGroup))
      }
      countSelect++
    }
  }, [selectedGroup])

  return (
    <>
      <Helmet>
        <title>TeamHub | Análise Geral Times</title>
      </Helmet>
      <Select
        label={<span data-html2canvas-ignore>Selecione um time:</span>}
        placeholder="Selecione"
        onChange={(id) => setSelectedGroup(id)}
        style={{ width: '100%', marginBottom: '16px' }}
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={selectedGroup}
        data-html2canvas-ignore
      >
        {grupos.map(({ grupo_nome, grupo_id }) => (
          <Option value={grupo_id} key={grupo_id}>
            {grupo_nome}
          </Option>
        ))}
      </Select>
      <Row gutter={8} type>
        <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: '8px' }}>
          <Card title="Detalhes Adicionais">
            <Row gutter={16}>
              <span style={{ marginRight: '5px' }}>e-NPS: </span>
              {nps === 'N/A' ? (
                'Não há dados para esse time. É necessário que, ao menos, 4 pesquisas válidas de e-NPS tenham sido realizadas. '
              ) : nps >= 75 ? (
                <Tooltip color="green" title="Escala (100 a -100): Excelente">
                  {nps} (excelente).
                </Tooltip>
              ) : nps >= 50 ? (
                <Tooltip color="lime" title="Escala (100 a -100): Muito Bom">
                  {nps} (muito bom).
                </Tooltip>
              ) : nps >= 0 ? (
                <Tooltip color="yellow" title="Escala (100 a -100): Razoável">
                  {nps} (razoável).
                </Tooltip>
              ) : (
                <Tooltip color="gold" title="Escala (100 a -100): Ruim">
                  {nps} (ruim).
                </Tooltip>
              )}
              <span style={{ marginLeft: '5px' }}>
                Legenda: Excelente: entre 75 e 100; Muito bom: entre 50 e 74;
                Razoável: entre 0 e 49; Ruim: entre -100 e -1.{' '}
              </span>
            </Row>
          </Card>
        </Col>
      </Row>
      <AnaliseGeralView
        cultura={cultura}
        culturaOrganizacional={culturaOrganizacional}
        loadingCultura={loadingCultura}
        loadingCulturaOrg={loadingCulturaOrg}
      />
    </>
  )
}

export default AnaliseGeral
