import * as constants from '../constants'

export const load = (hash) => {
  return {
    type: constants.PESQ_VALORES_LOAD,
    api: {
      method: 'get',
      url: constants.URL_PESQ_VALORES_LOAD(hash),
    },
  }
}

export const getComportamentos = (hash) => {
  return {
    type: constants.PESQ_VALORES_GET_COMPORTAMENTOS,
    api: {
      method: 'get',
      url: constants.URL_PESQ_VALORES_GET_COMPORTAMENTOS(hash),
    },
  }
}

export const postRespostas = ({ hash, respostas }) => {
  return {
    type: constants.PESQ_VALORES_POST_RESPOSTAS,
    api: {
      method: 'post',
      url: constants.URL_PESQ_VALORES_POST_RESPOSTAS(hash),
      data: { respostas },
    },
  }
}
