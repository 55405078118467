import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  getGrupos,
  getCulturaOrganizacional,
  getCulturas,
  setGrupoSelecionado,
  getPerfis,
  getVetor,
  getTopArq,
} from './actions'

import RelatoriosTimesView from './RelatoriosTimesView'

function RelatoriosTimesController({ type }) {
  const dispatch = useDispatch()

  const grupoSelecionado = useSelector(
    (state) => state.relatoriosTimes.grupoSelecionado
  )

  const grupos = useSelector((state) => state.relatoriosTimes.grupos)

  const culturaOrganizacional = useSelector(
    (state) => state.relatoriosTimes.culturaOrganizacional
  )

  const cultura = useSelector((state) => state.relatoriosTimes.cultura)

  const forcas = useSelector((state) => state.relatoriosTimes.forcas)

  const disc = useSelector((state) => state.relatoriosTimes.disc)

  const olhares = useSelector((state) => state.relatoriosTimes.olhares)

  const gestao = useSelector((state) => state.relatoriosTimes.gestao)

  const vetor = useSelector((state) => state.relatoriosTimes.vetor)

  const perfis = useSelector((state) => state.relatoriosTimes.perfis)

  const generos = useSelector((state) => state.relatoriosTimes.generos)

  const geracoes = useSelector((state) => state.relatoriosTimes.geracoes)

  const destaques = useSelector((state) => state.relatoriosTimes.destaques)

  const [showGraficoRadar, setShowGraficoRadar] = useState(true)

  useEffect(() => {
    dispatch(getGrupos())
  }, [dispatch])

  useEffect(() => {
    if (grupoSelecionado) {
      const { grupo_id } = grupoSelecionado
      dispatch(getCulturaOrganizacional(grupo_id))
      dispatch(getCulturas(grupo_id))
      dispatch(getVetor(grupo_id))
      dispatch(getPerfis(grupo_id))
      dispatch(getTopArq())
    }
  }, [dispatch, grupoSelecionado])

  const handleChangeGrupo = (id) => {
    dispatch(setGrupoSelecionado(id))
  }

  const handleSwitchGraficoRadar = () => setShowGraficoRadar(!showGraficoRadar)

  return (
    <RelatoriosTimesView
      {...{
        grupoSelecionado,
        grupos,
        handleChangeGrupo,
        culturaOrganizacional,
        cultura,
        forcas,
        disc,
        olhares,
        gestao,
        type,
        vetor,
        perfis,
        showGraficoRadar,
        handleSwitchGraficoRadar,
        geracoes,
        generos,
        destaques,
      }}
    />
  )
}

export default RelatoriosTimesController
