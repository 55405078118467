import React from 'react'

import { SmileOutlined } from '@ant-design/icons'

import { Row, Col, Card, Tabs } from 'antd'

// CSS
import './Textual.css'

function Textual({ principal, gutter = 24 }) {
  return (
    <>
      <Row gutter={gutter} type="flex" justify="space-between">
        <Col className="textual__col" xs={24} md={12} xl={6}>
          <Card className="textual__card">
            <div className="textual__card__header">
              <div className="textual__card__icon">
                <SmileOutlined />
              </div>
              <div className="textual__card__title">
                <h1>Perfil Comportamental</h1>
                <p>({principal && principal.vetor})</p>
              </div>
            </div>
            <div className="textual__card__body">
              <p>{principal && principal.descricao}</p>
            </div>
          </Card>
        </Col>
        <Col className="textual__col" xs={24} md={12} xl={6}>
          <Card className="textual__card">
            <div className="textual__card__header">
              <div className="textual__card__icon">
                <SmileOutlined />
              </div>
              <div className="textual__card__title">
                <h1>Obtenção de Energia</h1>
                <p>({principal.ode && principal.ode.nome})</p>
              </div>
            </div>
            <div className="textual__card__body">
              <p>{principal.ode && principal.ode.descricao}</p>
            </div>
          </Card>
        </Col>
        <Col className="textual__col" xs={24} md={12} xl={6}>
          <Card className="textual__card">
            <div className="textual__card__header">
              <div className="textual__card__icon">
                <SmileOutlined />
              </div>
              <div className="textual__card__title">
                <h1>Orientação</h1>
                <p>({principal.oct && principal.oct.nome})</p>
              </div>
            </div>
            <div className="textual__card__body">
              <p>{principal.oct && principal.oct.descricao}</p>
            </div>
          </Card>
        </Col>
        <Col className="textual__col" xs={24} md={12} xl={6}>
          <Card className="textual__card">
            <div className="textual__card__header">
              <div className="textual__card__icon">
                <SmileOutlined />
              </div>
              <div className="textual__card__title">
                <h1>Relação com o tempo</h1>
                <p>({principal.rct && principal.rct.nome})</p>
              </div>
            </div>
            <div className="textual__card__body">
              <p>{principal.rct && principal.rct.descricao}</p>
            </div>
          </Card>
        </Col>
      </Row>
      <Row
        className="textual__row"
        gutter={gutter}
        type="flex"
        justify="space-between"
      >
        <Col className="textual__col" xs={24} md={8}>
          <Card className="textual__card">
            <div className="textual__card__header">
              <div className="textual__card__icon">
                <SmileOutlined />
              </div>
              <div className="textual__card__title">
                <h1>Estilo de Liderança</h1>
                <p>({principal.edl && principal.edl.nome})</p>
              </div>
            </div>
            <div className="textual__card__body">
              <p>{principal.edl && principal.edl.descricao}</p>
            </div>
          </Card>
        </Col>
        <Col className="textual__col" xs={24} md={8}>
          <Card className="textual__card">
            <div className="textual__card__header">
              <div className="textual__card__icon">
                <SmileOutlined />
              </div>
              <div className="textual__card__title">
                <h1>Foco</h1>
                <p>({principal.foc && principal.foc.nome})</p>
              </div>
            </div>
            <div className="textual__card__body">
              <p>{principal.foc && principal.foc.descricao}</p>
            </div>
          </Card>
        </Col>
        <Col className="textual__col" xs={24} md={8}>
          <Card className="textual__card">
            <div className="textual__card__header">
              <div className="textual__card__icon">
                <SmileOutlined />
              </div>
              <div className="textual__card__title">
                <h1>Tomada de Decisão</h1>
                <p>({principal.tdd && principal.tdd.nome})</p>
              </div>
            </div>
            <div className="textual__card__body">
              <p>{principal.tdd && principal.tdd.descricao}</p>
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={gutter} type="flex" justify="space-between">
        <Col className="textual__col" xs={24} md={8}>
          <Card className="textual__card">
            <div className="textual__card__header">
              <div className="textual__card__icon">
                <SmileOutlined />
              </div>
              <div className="textual__card__title">
                <h1>Estilo de Comunicação</h1>
                <p>({principal.edc && principal.edc.nome})</p>
              </div>
            </div>
            <div className="textual__card__body">
              <p>{principal.edc && principal.edc.descricao}</p>
            </div>
          </Card>
        </Col>
        <Col className="textual__col" xs={24} md={8}>
          <Card className="textual__card">
            <div className="textual__card__header">
              <div className="textual__card__icon">
                <SmileOutlined />
              </div>
              <div className="textual__card__title">
                <h1>Resolução de Conflitos</h1>
                <p>({principal.erc && principal.erc.nome})</p>
              </div>
            </div>
            <div className="textual__card__body">
              <p>{principal.erc && principal.erc.descricao}</p>
            </div>
          </Card>
        </Col>
        <Col className="textual__col" xs={24} md={8}>
          <Card className="textual__card">
            <div className="textual__card__header">
              <div className="textual__card__icon">
                <SmileOutlined />
              </div>
              <div className="textual__card__title">
                <h1>Competências Organizacionais</h1>
                <p>({principal.cor && principal.cor.nome})</p>
              </div>
            </div>
            <div className="textual__card__body">
              <p>{principal.cor && principal.cor.descricao}</p>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Textual
