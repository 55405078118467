import React from 'react'

// Components
import PageHeader from '../../components/pageHeader/PageHeader'

// CSS
import './ContentLayout.css'

const ContentLayout = ({
  children,
  className,
  header,
  fixed = false,
  newFitCultura,
}) => (
  <div
    className={`content-layout ${className || ''} ${
      fixed ? 'fixed-content-layout' : ''
    }`}
  >
    {header && (
      <div className="content-layout__header">
        <PageHeader
          title={header.title}
          subtitle={header.subtitle}
          breadcrumb={header.breadcrumb}
          renderRight={header.renderRight}
          tabs={header.tabs}
          newFitCultura={newFitCultura}
        />
      </div>
    )}
    <div
      className={`content-layout__body ${className || ''} ${
        fixed ? 'fixed-content-layout__body' : ''
      }`}
    >
      {children}
    </div>
  </div>
)

export default ContentLayout
