import styled, { css } from 'styled-components'

import { Button, Collapse } from 'antd'

const { Panel } = Collapse

export const CustomPanel = styled(Panel)`
  .ant-collapse-header {
    font-size: 18px;
    color: #fff !important;
    background-color: var(--orange);
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    i {
      font-size: 18px !important;
    }
    i + i {
      margin-left: 12px;
    }

    .ant-collapse-extra {
      margin-right: 12px;
    }
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .ant-collapse-content-box {
    padding: 0px;
  }
`

export const ItemHeader = styled.span`
  align-self: flex-start;
  flex: 1;
`

export const CustomButton = styled(Button)`
  border: none;
`

export const Label = styled.span`
  font-size: 16px;
  font-weight: 500;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const CustomHr = styled.hr`
  border-color: rgba(0, 0, 0, 0.65);
`

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 8px;

  @media screen and (max-width: 1200px) {
    ${Box} {
      width: 100%;
    }
  }

  button {
    margin-top: 8px;
  }

  table {
    background-color: #fff;
    margin-top: 8px;
  }

  .editable-table {
    flex: 1;
  }
`

export const Content = styled.div`
  padding: 12px 24px 24px 24px;
  background-color: #f0f0f0;

  ${CustomHr} + ${Line} {
    margin-top: 0px;
  }
`
