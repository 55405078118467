import React from 'react'
import { injectIntl } from 'react-intl'
import { Modal, Steps } from 'antd'

// Components
import Button from '../../../../components/button/Button'

// Services
import { getContaURL } from '../../../../services/auth'

// CSS
import './ModalPesquisaComportamental.css'

// Constants

const { Step } = Steps

function ModalPesquisaComportamental({
  intl,
  id,
  showModal,
  history,
  closeModal,
}) {
  const contaURL = getContaURL()

  return (
    <Modal
      visible={showModal}
      title="Pesquisa Comportamental"
      cancelText="CANCELAR"
      okText="COMEÇAR"
      onCancel={closeModal}
      onOk={() => history.push(`/${contaURL}/pesquisas/perfil-comportamental`)}
      width={860}
      footer={
        <div className="modal-pesquisa-comportamental__footer">
          <Button color="orange" type="link" onClick={closeModal}>
            CANCELAR
          </Button>
          <Button
            color="orange"
            type="primary"
            onClick={() =>
              history.push(`/${contaURL}/pesquisas/perfil-comportamental/${id}`)
            }
          >
            COMEÇAR
          </Button>
        </div>
      }
      destroyOnClose
    >
      <div className="modal-pesquisa-comportamental">
        <p>Olá Audrey Teste 2,</p>
        <p>
          O ponto de partida para nossa jornada pessoal e ou profissional é
          assumir a responsabilidade por nosso próprio desenvolvimento e a base
          para tudo isso é o <b>autoconhecimento</b>. Conhecer a si mesmo é
          conseguir
          <b>identificar</b> seus
          <b>pontos fortes</b> e também as<b>limitações</b>. E aqui é que nós
          entramos. Nosso propósito é ajudá-lo (a) nesse processo de
          <b>conhecimento de si</b> e para isso, preparamos 3 perguntas chaves
          que nos guiarão na construção do seu mapa de perfil comportamentais.
          Esses perfis são
          <b>condutores</b> <b>impulsionam</b> seus <b>comportamentos</b> no
          dia-a-dia e <b>explicam muito de suas atitudes</b>, sejam elas boas ou
          de impactos negativos.
        </p>
        <div className="modal-pesquisa-comportamental__card">
          <h3>São apenas três formulários, onde você responderá:</h3>
          <Steps className="modal-pesquisa-comportamental__card__steps">
            <Step
              title="Passo 1"
              description="Quem acho que sou"
              status="process"
            />
            <Step
              title="Passo 2"
              description="O que exigem de mim"
              status="process"
            />
            <Step
              title="Passo 3"
              description="O que consigo entregar"
              status="process"
            />
          </Steps>
        </div>
        <h3>Dicas importantes:</h3>
        <p>
          - Seja o mais sincero possível, assim seu resultado será mais
          assertivo.
        </p>
        <p>
          - Aponte o mouse sobre a palavra para abrir o dicionário, caso
          precise.
        </p>
        <p>
          - Não temos acesso às suas marcações, somente ao relatório final.
          Sendo assim, fique tranquilo para ser bem transparente.
        </p>
        <p>
          - Não pergunte a ninguém o que eles acham, responda com seu coração.
        </p>
        <p>
          - Não interrompa o teste, busque respondê-lo num momento de
          tranquilidade e concentração, caso contrário, poderá afetar o
          resultado final.
        </p>
      </div>
    </Modal>
  )
}

export default injectIntl(ModalPesquisaComportamental)
