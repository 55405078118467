// types
export const ATIVIDADE_LIST = 'ATIVIDADE_LIST'
export const ATIVIDADE_POST = 'ATIVIDADE_POST'
export const ATIVIDADE_UPDATE = 'ATIVIDADE_UPDATE'
export const ATIVIDADE_DELETE = 'ATIVIDADE_DELETE'
export const ATIVIDADE_LOAD = 'ATIVIDADE_LOAD'

export const FREQUENCIA_CARGO = 'FREQUENCIA_CARGO'

// urls
export const URL_ATIVIDADE = `${process.env.REACT_APP_API_URL}/cargo`
export const URL_ATIVIDADE_ID = (id, atividade_id) =>
  `${URL_ATIVIDADE}/${id}/atividades/${atividade_id}`
export const URL_ATIVIDADE_POST = (id) => `${URL_ATIVIDADE}/${id}/atividades`
export const URL_ATIVIDADE_LIST = (id) => `${URL_ATIVIDADE}/${id}/atividades`

export const URL_FREQUENCIA_CARGO = `/cargo/atividades/frequencias`
