import React, { useState, useEffect, useCallback } from 'react'

import { Form, Input, Row } from 'antd'

import TinyEditor from '../../../../../../components/TinyEditor'

import Select, { Option } from '../../../../../../components/select/Select'

import { EditorLegend } from './styles'

function EnviarEmail({
  onFinish,
  templatesManuais,
  setIdEmailManualEnviar,
  parametros,
}) {
  const [form] = Form.useForm()

  const [selectedEmail, setSelectedEmail] = useState(
    templatesManuais[0].assunto
  )

  const [textoEmail, setTextoEmail] = useState(templatesManuais[0].textoemail)
  const [newKey, setNewKey] = useState(templatesManuais[0].id)

  const handleChangeSelectedEmail = (value) => {
    form.resetFields()
    const newTextoEmail = templatesManuais.find(
      (template) => template.assunto === value
    )
    setTextoEmail(newTextoEmail.textoemail)
    setSelectedEmail(value)
    setNewKey(newTextoEmail.id)
    form.setFieldsValue({
      assunto: value,
      textoemail: newTextoEmail.textoemail,
    })
  }

  const setEmailManualId = useCallback(() => {
    setIdEmailManualEnviar(newKey)
  }, [newKey, setIdEmailManualEnviar])

  function renderParams() {
    const render = []
    parametros &&
      parametros.length > 0 &&
      render.push(
        <EditorLegend>
          <ul>
            {parametros.map((param) => (
              <li key={param.id}>
                <strong>{`{${param.param}}`}</strong> - {param.legend}
              </li>
            ))}
          </ul>
        </EditorLegend>
      )

    return render
  }

  useEffect(() => {
    setEmailManualId()
  }, [setEmailManualId])

  return (
    <Form
      layout="vertical"
      id="enviar-email-manual"
      onFinish={onFinish}
      form={form}
      initialValues={{
        assunto: selectedEmail,
        textoemail: textoEmail,
      }}
      preserve={false}
    >
      <Form.Item label="Selecione um template de e-mail:" name="assunto">
        <Select
          value={selectedEmail}
          onChange={(value) => handleChangeSelectedEmail(value)}
        >
          {templatesManuais.map((item) => (
            <Option key={item.id} value={item.assunto}>
              {item.assunto}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="textoemail"
        placeholder="Digite aqui o e-mail!"
        rules={[
          {
            required: true,
            message: 'Por favor, preencha o corpo do e-mail.',
          },
        ]}
      >
        <TinyEditor
          initialValues={textoEmail}
          value={textoEmail}
          key={newKey}
        />
      </Form.Item>
      <Form.Item>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {renderParams()}
        </Row>
      </Form.Item>
    </Form>
  )
}

export default EnviarEmail
