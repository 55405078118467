// redux types
export const PS_REQUISICOES_LIST_REQUISICOES = 'PS_REQUISICOES_LIST_REQUISICOES'
export const PS_REQUISICOES_STORE_REQUISICAO = 'PS_REQUISICOES_STORE_REQUISICAO'
export const PS_REQUISICOES_UPDATE_REQUISICAO =
  'PS_REQUISICOES_UPDATE_REQUISICAO'
export const PS_REQUISICOES_CANCEL_REQUISICAO =
  'PS_REQUISICOES_CANCEL_REQUISICAO'
export const PS_REQUISICOES_REENVIAR_REQUISICAO =
  'PS_REQUISICOES_REENVIAR_REQUISICAO'
export const PS_REQUISICOES_APROVAR_ETAPA_REQUISICAO =
  'PS_REQUISICOES_APROVAR_ETAPA_REQUISICAO'
export const PS_REQUISICOES_REPROVAR_ETAPA_REQUISICAO =
  'PS_REQUISICOES_REPROVAR_ETAPA_REQUISICAO'
export const PS_REQUISICOES_CHECK_REQUISITOR = 'PS_REQUISICOES_CHECK_REQUISITOR'
export const PS_REQUISICOES_TIMES_GET = 'PS_REQUISICOES_TIMES_GET'
export const PS_REQUISICOES_ETAPAS_GET = 'PS_REQUISICOES_ETAPAS_GET'

// urls
const URL_BASE = process.env.REACT_APP_API_URL
const URL_PS = `${URL_BASE}/ps`
const URL_REQUISICOES = `${URL_PS}/requisicoes`

export const URL_PS_REQUISICOES_LIST_REQUISICOES = `${URL_REQUISICOES}`

export const URL_PS_REQUISICOES_STORE_REQUISICAO = `${URL_REQUISICOES}`

export const URL_PS_REQUISICOES_UPDATE_REQUISICAO = (processo_id) =>
  `${URL_REQUISICOES}/${processo_id}`

export const URL_PS_REQUISICOES_REENVIAR_REQUISICAO = (processo_id) =>
  `${URL_REQUISICOES}/${processo_id}/reenviar`

export const URL_PS_REQUISICOES_CANCEL_REQUISICAO = (processo_id) =>
  `${URL_REQUISICOES}/${processo_id}`

export const URL_PS_REQUISICOES_APROVAR_ETAPA_REQUISICAO = ({
  processo_id,
  etapa_id,
}) => `${URL_REQUISICOES}/${processo_id}/etapas/${etapa_id}/aprovar`

export const URL_PS_REQUISICOES_REPROVAR_ETAPA_REQUISICAO = ({
  processo_id,
  etapa_id,
}) => `${URL_REQUISICOES}/${processo_id}/etapas/${etapa_id}/reprovar`

export const URL_PS_REQUISICOES_CHECK_REQUISITOR = `${URL_BASE}/usuario/checkRequisitor`

export const URL_PS_REQUISICOES_TIMES_GET = `${URL_BASE}/dashboard/grupos?requisicao=true`

export const URL_PS_REQUISICOES_ETAPAS_GET = (grupo_id) =>
  `${URL_REQUISICOES}/etapas/${grupo_id}`
