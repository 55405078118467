import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import Select from 'react-select'

import { Form } from 'antd'

export default function SelectField({ name, label, onChange, ...rest }) {
  const selectRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return []
          }
          return ref.state.value.map((option) => option.id)
        }
        if (!ref.state.value) {
          return ''
        }
        return ref.state.value.id
      },
    })
  }, [fieldName, registerField, rest.isMulti])
  return (
    <>
      {label && (
        <Form.Item
          style={{ height: '32px', marginBottom: '0px' }}
          htmlFor={fieldName}
        >
          {label}
        </Form.Item>
      )}
      <Select
        defaultValue={
          defaultValue &&
          rest.options &&
          rest.options.find((option) => option.id === defaultValue)
        }
        ref={selectRef}
        onChange={(e) => {
          if (onChange) {
            onChange(e)
          }
        }}
        classNamePrefix="react-select"
        placeholder="Selecione tipo"
        {...rest}
      />
    </>
  )
}
