import React, { useEffect, useState } from 'react'

import { Row, Card } from 'antd'

import { Table, Container, Col } from './styles'

import { noPadding } from '../../utils/inlineStyles'

import {
  parseCulturaData,
  parseCulturaOrgData,
  parsePercent,
  customParser,
  parsePercent1,
} from '../../utils/analiseGeral'
import { getCulturasFromEmpresas } from '../FitCultural/Api/api'

function AnaliseGeral({
  cultura: culturaData,
  culturaOrganizacional: culturaOrgData,
  loadingCultura,
  loadingCulturaOrg,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const {
    cultura: culturaRaw,
    forcas: forcasRaw,
    gestao: gestaoRaw,
    olhares: olharesRaw,
    disc: discRaw,
    generos: generosRaw,
    geracoes: geracoesRaw,
  } = culturaData

  const {
    valoresEvidencia,
    satisfacaoGeral,
    niveisConfianca,
    olharLideranca,
    olharParaMudancas,
    olharParaSi,
  } = culturaOrgData

  const {
    expectativaEntregas,
    feedbackContribui,
    frequenciaFeedback,
    perceboLider,
    perfilLiderDireto,
    reconhecimento,
  } = olharLideranca

  const {
    engajamento,
    impedimento,
    pontosFortes,
    principalProblema,
    proficiencia,
    velocidadeAdocao,
  } = olharParaMudancas

  const { escolhaEmpresa, expectativas6meses, fonteEntusiasmo, prioridades } =
    olharParaSi

  function removerDesejado(elemento) {
    let removeDesejado = true
    elemento.forEach((element) => {
      if (elemento.Desejado && element.Desejado != '0%') {
        removeDesejado = false
      }
    })

    if (removeDesejado) {
      elemento.forEach((element) => {
        delete element.Desejado
      })
    }

    return elemento
  }

  const cultura = removerDesejado(parseCulturaData(culturaRaw))
  const forcas = removerDesejado(parseCulturaData(forcasRaw))
  const gestao = removerDesejado(parseCulturaData(gestaoRaw))
  const olhares = removerDesejado(parseCulturaData(olharesRaw))
  const generos = customParser(generosRaw, { key: 'genero', value: 'qtde' })
  const geracoes = customParser(geracoesRaw, {
    key: 'geracao',
    value: 'qtde',
  })

  useEffect(() => {
    culturasFromEmpresas()
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
  }, [cultura])

  const [showFit, setShow] = useState(false)

  async function culturasFromEmpresas() {
    const culturas = await getCulturasFromEmpresas()
    if (culturas.count == 0) setShow(true)
  }

  return (
    <Container span={24} style={noPadding}>
      <Row gutter={8} type>
        <Col xs={24} sm={24} md={14} lg={10} style={{ marginBottom: '8px' }}>
          <Card title="Informações Gerais">
            <Row>
              {showFit && (
                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    title={() => 'Arquétipos Culturais'}
                    bordered
                    loading={isLoading ? loadingCultura : isLoading}
                    pagination={false}
                    size="small"
                    dataSource={cultura}
                    columns={[
                      {
                        title: 'Arquétipo',
                        dataIndex: 'Tipo',
                      },
                      {
                        title: 'Desejado',
                        dataIndex: 'Desejado',
                      },
                      {
                        title: 'Instalado',
                        dataIndex: 'Instalado',
                      },
                    ]}
                  />
                </Col>
              )}
              <Col xs={24} sm={24} lg={showFit ? 12 : 24}>
                <Table
                  title={() => 'Valores em Evidência'}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  showHeader={false}
                  dataSource={valoresEvidencia}
                  columns={[
                    {
                      title: 'Valor',
                      dataIndex: 'TEXTO',
                    },
                    {
                      title: '%',
                      dataIndex: 'VALOR',
                      render: (value, record) => `${value}%`,
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} lg={24}>
                <Row gutter={8}>
                  <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                    <Table
                      title={() => 'Forças Estratégicas'}
                      bordered
                      loading={isLoading ? loadingCultura : isLoading}
                      pagination={false}
                      size="small"
                      dataSource={forcas}
                      columns={[
                        {
                          title: 'Força',
                          dataIndex: 'Tipo',
                        },
                        {
                          title: 'Desejado',
                          dataIndex: 'Desejado',
                          hidden: true,
                        },
                        {
                          title: 'Instalado',
                          dataIndex: 'Instalado',
                        },
                      ].filter((column) => !column.hidden)}
                    />
                  </Col>
                  <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                    <Table
                      title={() => 'Gestão'}
                      bordered
                      loading={isLoading ? loadingCultura : isLoading}
                      pagination={false}
                      size="small"
                      dataSource={gestao}
                      columns={[
                        {
                          title: 'Gestão',
                          dataIndex: 'Tipo',
                        },
                        {
                          title: 'Desejado',
                          dataIndex: 'Desejado',
                          hidden: true,
                        },
                        {
                          title: 'Instalado',
                          dataIndex: 'Instalado',
                        },
                      ].filter((column) => !column.hidden)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} lg={24} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Satisfação Geral'}
                  bordered
                  loading={isLoading ? loadingCultura : isLoading}
                  pagination={false}
                  size="small"
                  showHeader={false}
                  dataSource={satisfacaoGeral}
                  columns={[
                    {
                      title: 'Satisfação',
                      dataIndex: 'TEXTO',
                    },
                    {
                      title: '%',
                      dataIndex: 'VALOR',
                      render: (value, record) => `${value}%`,
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Olhares para o Ambiente'}
                  bordered
                  loading={isLoading ? loadingCultura : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={olhares}
                  columns={[
                    {
                      title: 'Olhares',
                      dataIndex: 'Tipo',
                    },
                    {
                      title: 'Desejado',
                      dataIndex: 'Desejado',
                      hidden: true,
                    },
                    {
                      title: 'Instalado',
                      dataIndex: 'Instalado',
                    },
                  ].filter((column) => !column.hidden)}
                />
              </Col>
              <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Níveis de Confiança'}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  showHeader={false}
                  dataSource={niveisConfianca}
                  columns={[
                    {
                      title: 'Confiança',
                      dataIndex: 'TEXTO',
                    },
                    {
                      title: '%',
                      dataIndex: 'VALOR',
                      render: (value, record) => `${value}%`,
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Gêneros'}
                  bordered
                  loading={isLoading ? loadingCultura : isLoading}
                  pagination={false}
                  size="small"
                  showHeader={false}
                  dataSource={generos}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      title: '%',
                      dataIndex: 'Valor',
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Gerações'}
                  bordered
                  loading={isLoading ? loadingCultura : isLoading}
                  pagination={false}
                  size="small"
                  showHeader={false}
                  dataSource={geracoes}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      title: '%',
                      dataIndex: 'Valor',
                    },
                  ]}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={10} lg={7} style={{ marginBottom: '8px' }}>
          <Card title="Olhar para Liderança">
            <Row gutter={16}>
              <Col xs={24} sm={24} lg={24} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Perfil do Líder direto'}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  showHeader={false}
                  dataSource={parsePercent1(perfilLiderDireto)}
                  columns={[
                    {
                      title: 'Perfil',
                      dataIndex: 'Tipo',
                    },
                    {
                      title: '%',
                      dataIndex: 'Valor',
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} lg={24} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Tenho clareza sobre as minhas entregas'}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={parsePercent(expectativaEntregas)}
                  showHeader={false}
                  columns={[
                    {
                      title: 'Perfil Líder direto',
                      dataIndex: 'Tipo',
                    },
                    {
                      title: '%',
                      dataIndex: 'Valor',
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Percebo que meu Líder'}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={parseCulturaOrgData(perceboLider)}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      title: 'Sempre',
                      dataIndex: 'Sempre',
                    },
                    {
                      title: 'Frequentemente',
                      dataIndex: 'Frequentemente',
                    },
                    {
                      title: 'Raramente',
                      dataIndex: 'Raramente',
                    },
                    {
                      title: 'Às vezes',
                      dataIndex: 'Às vezes',
                    },
                    {
                      title: 'Nunca',
                      dataIndex: 'Nunca',
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Sou reconhecido e elogiado'}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  showHeader={false}
                  size="small"
                  dataSource={parsePercent(reconhecimento)}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      dataIndex: 'Valor',
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Feedback contribui para'}
                  bordered
                  showHeader={false}
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={parsePercent(feedbackContribui)}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      dataIndex: 'Valor',
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Frequência do Feedback'}
                  showHeader={false}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={parsePercent(frequenciaFeedback)}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      dataIndex: 'Valor',
                    },
                  ]}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} lg={7} style={{ marginBottom: '8px' }}>
          <Card title="Olhar para Si">
            <Row gutter={16}>
              <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Tenho como prioridade neste momento'}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={parseCulturaOrgData(prioridades)}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      title: 'Extremamente',
                      dataIndex: 'Extremamente',
                    },
                    {
                      title: 'Muito',
                      dataIndex: 'Muito',
                    },
                    {
                      title: 'Mais ou menos',
                      dataIndex: 'Mais ou menos',
                    },
                    {
                      title: 'Não muito',
                      dataIndex: 'Não muito',
                    },
                    {
                      title: 'De forma alguma',
                      dataIndex: 'De forma alguma',
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Porque escolhi esta empresa para trabalhar?'}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={parseCulturaOrgData(escolhaEmpresa)}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      title: 'Extremamente',
                      dataIndex: 'Extremamente',
                    },
                    {
                      title: 'Muito',
                      dataIndex: 'Muito',
                    },
                    {
                      title: 'Mais ou menos',
                      dataIndex: 'Mais ou menos',
                    },
                    {
                      title: 'Não muito',
                      dataIndex: 'Não muito',
                    },
                    {
                      title: 'De forma alguma',
                      dataIndex: 'De forma alguma',
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                <Table
                  title={() => 'Para os próximos 6 meses, espero'}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={parseCulturaOrgData(expectativas6meses)}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      title: 'Extremamente',
                      dataIndex: 'Extremamente',
                    },
                    {
                      title: 'Muito',
                      dataIndex: 'Muito',
                    },
                    {
                      title: 'Mais ou menos',
                      dataIndex: 'Mais ou menos',
                    },
                    {
                      title: 'Não muito',
                      dataIndex: 'Não muito',
                    },
                    {
                      title: 'De forma alguma',
                      dataIndex: 'De forma alguma',
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                <Table
                  title={() =>
                    'Qual tem sido sua maior fonte de entusiasmo neste momento?'
                  }
                  showHeader={false}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={parsePercent1(fonteEntusiasmo)}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      dataIndex: 'Valor',
                    },
                  ]}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24}>
          <Card title="Olhar para Mudança">
            <Row gutter={8}>
              <Col
                xs={24}
                sm={24}
                lg={14}
                xxl={7}
                style={{ marginBottom: '16px' }}
              >
                <Table
                  title={() => 'Principal problema percebido'}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={parseCulturaOrgData(principalProblema)}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      title: 'Sempre',
                      dataIndex: 'Sempre',
                    },
                    {
                      title: 'Frequentemente',
                      dataIndex: 'Frequentemente',
                    },
                    {
                      title: 'Raramente',
                      dataIndex: 'Raramente',
                    },
                    {
                      title: 'Às vezes',
                      dataIndex: 'Às vezes',
                    },
                    {
                      title: 'Nunca',
                      dataIndex: 'Nunca',
                    },
                  ]}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                lg={10}
                xxl={7}
                style={{ marginBottom: '16px' }}
              >
                <Table
                  title={() => 'O que impede as mudanças'}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={parseCulturaOrgData(impedimento)}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      title: 'Sempre',
                      dataIndex: 'Sempre',
                    },
                    {
                      title: 'Frequentemente',
                      dataIndex: 'Frequentemente',
                    },
                    {
                      title: 'Às vezes',
                      dataIndex: 'Às vezes',
                    },
                    {
                      title: 'Nunca',
                      dataIndex: 'Nunca',
                    },
                  ]}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                lg={14}
                xxl={7}
                style={{ marginBottom: '16px' }}
              >
                <Table
                  title={() => 'Pontos fortes que auxiliam as mudanças'}
                  bordered
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={parseCulturaOrgData(pontosFortes)}
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      title: 'Muito forte',
                      dataIndex: 'Muito forte',
                    },
                    {
                      title: 'Forte',
                      dataIndex: 'Forte',
                    },
                    {
                      title: 'Fraco',
                      dataIndex: 'Fraco',
                    },
                    {
                      title: 'Muito fraco',
                      dataIndex: 'Muito fraco',
                    },
                  ]}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                lg={10}
                xxl={3}
                style={{ marginBottom: '16px' }}
              >
                <Table
                  title={() => 'Indicadores de mudança'}
                  bordered
                  showHeader={false}
                  loading={isLoading ? loadingCulturaOrg : isLoading}
                  pagination={false}
                  size="small"
                  dataSource={
                    engajamento && proficiencia && velocidadeAdocao
                      ? [
                          {
                            Tipo: engajamento.mudanca,
                            Valor: `${engajamento.percent}%`,
                          },
                          {
                            Tipo: proficiencia.mudanca,
                            Valor: `${proficiencia.percent}%`,
                          },
                          {
                            Tipo: velocidadeAdocao.mudanca,
                            Valor: `${velocidadeAdocao.percent}%`,
                          },
                        ]
                      : []
                  }
                  columns={[
                    {
                      dataIndex: 'Tipo',
                    },
                    {
                      title: '%',
                      dataIndex: 'Valor',
                    },
                  ]}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default AnaliseGeral
