import React from 'react'
import { injectIntl } from 'react-intl'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Row, Col } from 'antd'

// Components
import Input from '../../../../components/input/Input'

// Constants

function AdicionarMeta({ intl, handleChangeModal, descricao, error }) {
  return (
    <div>
      <Form>
        <Row gutter={20}>
          <Col md={24}>
            <Form.Item
              label="Descrição da Meta"
              validateStatus={error.descricao ? 'error' : ''}
              help={error.descricao || ''}
            >
              <Input
                type="text"
                placeholder="Informe a descrição da meta que deseja adicionar"
                name="descricao"
                value={descricao || ''}
                onChange={handleChangeModal}
                withoutMargin
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default injectIntl(AdicionarMeta)
