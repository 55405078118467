// TYPES
export const GET_ANALISECULTURA_GERAL_CULTURAS =
  'GET_ANALISECULTURA_GERAL_CULTURAS'

export const GET_ANALISECULTURA_GERAL_CULTURA_ORGANIZACIONAL =
  'GET_ANALISECULTURA_GERAL_CULTURA_ORGANIZACIONAL'

export const GET_ANALISECULTURA_GERAL_TOP_ARQ =
  'GET_ANALISECULTURA_GERAL_TOP_ARQ'

export const GET_DASHBOARD_NPS = 'GET_DASHBOARD_NPS'

// URLS
export const URL_DASHBOARD = `${process.env.REACT_APP_API_URL}/dashboard`
export const URL_ANALISECULTURA_GERAL_CULTURA_ORGANIZACIONAL = `${URL_DASHBOARD}/culturaOrganizacional`
export const URL_ANALISECULTURA_GERAL_CULTURAS = `${URL_DASHBOARD}/culturas`
export const URL_ANALISECULTURA_GERAL_TOP_ARQ = `${URL_DASHBOARD}/topArquetipos`
export const URL_DASHBOARD_NPS = `relatorios/nps`
