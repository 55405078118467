import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import { useDispatch, useSelector } from 'react-redux'

import { toast } from 'react-toastify'

import { Col, Tabs } from 'antd'

import Pesquisas from './components/Pesquisas'

import { noPadding } from '../../utils/inlineStyles'
import { getContaURL } from '../../services/auth'
import history from '../../utils/history'

import { setPesos, getPesos, setFitMinimoValue, getFitMinimo } from './actions'

const { TabPane } = Tabs

function Configuracoes() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPesos())
    dispatch(getFitMinimo())
  }, [dispatch])

  // pesquisas

  const { pesos } = useSelector((state) => state.configuracoes.pesquisas)

  const fit = useSelector((state) => state.configuracoes.fit)

  const [pesoValores, setPesoValores] = useState(0)
  const [pesoArquetipos, setPesoArquetipos] = useState(0)
  const [fitMinimo, setFitMinimo] = useState(0)

  useEffect(() => {
    const { arquetipos, valores } = pesos
    const { fitminimo } = fit
    setPesoValores(valores)
    setPesoArquetipos(arquetipos)
    setFitMinimo(fitminimo)
  }, [pesos, fit])

  const handleChangePesos = ({ name, value }) => {
    const actions = {
      arquetipos: () => {
        setPesoValores(pesoValores + (pesoArquetipos - value))
        setPesoArquetipos(value)
      },
      valores: () => {
        setPesoArquetipos(pesoArquetipos + (pesoValores - value))
        setPesoValores(value)
      },
      fitminimo: () => {
        setFitMinimo(value)
      },
    }
    if (actions.hasOwnProperty(name)) {
      actions[name]()
    }
  }

  const handleSubmitPesos = () => {
    if (pesoValores + pesoArquetipos !== 100) {
      toast.error('Pesos inválidos. A soma dos pesos deve totalizar 100%.')
      return
    }

    if (fitMinimo < 0 || fitMinimo > 100) {
      toast.error('Fit mínimo inválido. O fit mínimo deve estar entre 0 e 100')
      return
    }

    dispatch(setPesos({ pesoArquetipos, pesoValores })).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Pesos salvos com sucesso.')
        dispatch(getPesos())
      } else toast.error('Ocorreu um erro ao salvar pesos.')
    })

    dispatch(setFitMinimoValue({ fitMinimo })).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Fit mínimo salvo com sucesso!')
        dispatch(getFitMinimo())
      } else toast.error('Ocorreu um erro ao salvar o fit minimo.')
    })
  }

  return (
    <>
      <Helmet>
        <title>TeamHub | Configurações</title>
      </Helmet>
      <Col span={24} style={noPadding}>
        <Tabs
          defaultActiveKey="2"
          tabBarStyle={{
            marginBottom: '8px',
            paddingRight: '8px',
            paddingLeft: '8px',
          }}
          onTabClick={(tab) => {
            tab === '1' && history.push(`/${getContaURL()}/config/planos`)
            tab === '3' && history.push(`/${getContaURL()}/config/usuarios`)
            tab === '4' &&
              history.push(`/${getContaURL()}/config/processos-seletivos`)
          }}
        >
          <TabPane tab={<span>Limites de Cadastro</span>} key="1" />
          <TabPane tab={<span>Pesquisas</span>} key="2">
            <Pesquisas
              {...{
                ...pesos,
                pesoArquetipos,
                pesoValores,
                fitMinimo,
                handleChangePesos,
                handleSubmitPesos,
              }}
            />
          </TabPane>
          <TabPane tab={<span>Usuários</span>} key="3" />
          <TabPane tab={<span>Processo Seletivo</span>} key="4" />
        </Tabs>
      </Col>
    </>
  )
}

export default Configuracoes
