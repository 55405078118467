import React, { useState } from 'react'

import { Form, Input, Select } from 'antd'

import { Col, Steps } from 'antd'
import { Option } from '../../../../components/select/Select'

const { Step } = Steps
export default function FerramentaCargo({
  onFinish,
  ferramenta,
  nomeFerramenta,
  tipo_id,
  form,
}) {
  const [valorInvalido, setValorInvalido] = useState(false)

  /* Não remover rule */
  const validationFerramenta = (rule, value, callback) => {
    if (!value.match(/^(?!\s+$).*/)) {
      setValorInvalido(true)
      return callback('Por favor, insira um nome válido!')
    }
    setValorInvalido(false)
    return callback()
  }

  const [maximoValor, setMaximoValor] = useState(0)

  const handleVerificarMaximoValor = (e) => {
    setMaximoValor(e.target.value.length)
  }

  return (
    <Form
      layout="vertical"
      id="cadastro-ferramenta"
      onFinish={onFinish}
      form={form}
      initialValues={{ nomeFerramenta, tipo_id }}
    >
      <Form.Item
        label="Nome"
        name="nomeFerramenta"
        style={{
          marginBottom: 3,
        }}
        rules={[
          {
            required: true,
            message: 'Por favor, preencha este campo.',
          },
          {
            validator: validationFerramenta,
            message: 'Insira um nome válido!',
          },
        ]}
      >
        <Input
          type="text"
          placeholder="Digite o nome"
          onChange={(e) => handleVerificarMaximoValor(e)}
          maxLength={255}
          key="form_ferramenta-nome"
        />
      </Form.Item>
      <p
        style={{
          color: maximoValor >= 240 ? 'red' : '',
          marginTop: valorInvalido ? -9 : -6,
        }}
      >
        {maximoValor <= 9
          ? maximoValor.toString().padStart(2, '0')
          : maximoValor}
        /255
      </p>
      <Form.Item label="Selecione o tipo" name="tipo_id">
        <Select
          value={tipo_id}
          placeholder="Escolha a frequência"
          style={{ width: '100%' }}
          key="form_ferramenta-tipo"
        >
          {ferramenta.map(({ nome, id }) => (
            <Option value={id} key={id}>
              {nome}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}
