import { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  Col,
  Row,
  Button,
  Popconfirm,
  Modal,
  Divider,
  Card,
  Switch,
} from 'antd'

import {
  ExclamationCircleFilled,
  DeleteOutlined,
  UserAddOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import moment from 'moment'
import Table from '../../components/editableTable/EditableTable'
import NovaFilial from './components/NovaFilial/index'

import { TableHeader, Action } from './styles'
import {
  listFiliais,
  storeFilial,
  updateFilial,
  deleteFilial,
} from './actions/actions'

import { sorterDate } from '../../utils/utils'

function Filial() {
  const dispatch = useDispatch()

  const [showModalNewFilial, setShowModalNewFilial] = useState(false)

  const [filiais, setFiliais] = useState([])

  const [showInativos, setShowInativos] = useState(false)

  const [loadingFiliais, setloadingFiliais] = useState(false)

  const [handleFilial, sethandleUpdateFilial] = useState(false)

  const loadFiliais = useCallback(() => {
    setloadingFiliais(true)
    dispatch(listFiliais()).then(({ response }) => {
      setloadingFiliais(false)
      if (response.status === 200) {
        setFiliais(response.data)
      }
    })
  }, [dispatch])

  useEffect(() => {
    loadFiliais()
  }, [loadFiliais])

  const handleListFiliais = useCallback(() => {
    dispatch(listFiliais()).then(({ response }) => {
      if (response.status === 200) {
        const { filiais } = response.data
        setFiliais(filiais.map((f) => ({ value: f.filial_id, label: f.nome })))
      }
    })
  }, [dispatch])

  const handleDeleteFilial = useCallback(
    (filial_id) => {
      dispatch(deleteFilial({ filial_id })).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Filial excluída com sucesso.')
          loadFiliais()
        }
      })
    },
    [dispatch, loadFiliais]
  )

  const handleUpdateFilial = useCallback(
    (updatedFilial) => {
      dispatch(
        updateFilial({ filial_id: updatedFilial.filial_id, ...updatedFilial })
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Filial atualizada com sucesso.')
          loadFiliais()
        }
      })
    },
    [dispatch, loadFiliais]
  )

  const getColumns = useCallback(() => {
    return [
      {
        title: 'Nome',
        dataIndex: 'nome',
        width: '20%',
        sorter: (a, b) => sorterString(a, b, 'nome'),
        editable: true,
      },
      {
        title: 'Descricao',
        dataIndex: 'descricao',
        width: '25%',
        sorter: (a, b) => sorterString(a, b, 'descricao'),
        editable: true,
      },
      {
        title: 'Criado Por',
        dataIndex: ['creator', 'colaborador', 'pes_nome'],
        width: '15%',
        sorter: (a, b) =>
          sorterString(a, b, ['creator', 'colaborador', 'pes_nome']),
      },
      {
        title: 'Atualizado Por',
        dataIndex: ['updater', 'colaborador', 'pes_nome'],
        width: '15%',
        sorter: (a, b) =>
          sorterString(a, b, ['updater', 'colaborador', 'pes_nome']),
      },
      {
        title: 'Data de Atualização',
        dataIndex: 'updated_at',
        width: '10.5%',
        sorter: (a, b) => sorterDate(a, b, 'updated_at'),
        render: (text) =>
          text
            ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
            : '',
      },
      {
        title: 'Data de Criação',
        dataIndex: 'created_at',
        width: '9%',
        sorter: (a, b) => sorterDate(a, b, 'created_at'),
        render: (text) =>
          text
            ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
            : '',
      },
      {
        title: 'Ações',
        key: 'extra_actions',
        width: '10%',
        render: (_, record) => {
          return (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title="Tem certeza que deseja excluir o usuário?"
                onConfirm={() => handleDeleteFilial(record.filial_id)}
                okText="Excluir"
                cancelText="Cancelar"
                icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
              >
                <Action>
                  <DeleteOutlined />
                </Action>
              </Popconfirm>
            </>
          )
        },
      },
    ]
  }, [handleDeleteFilial])

  const handleCancelNewFilial = useCallback(() => {
    setShowModalNewFilial(false)
  }, [])

  const handleNewFilial = useCallback(
    (data) => {
      dispatch(storeFilial(data)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Filial criada com sucesso.')
          setShowModalNewFilial(false)
          loadFiliais()
        }
      })
    },
    [dispatch, loadFiliais]
  )

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Card title="Cadastre e gerencie filiais">
            <Table
              dataSource={filiais
                .filter((filial) => {
                  if (showInativos) {
                    return filial
                  }
                  return parseInt(filial.ativo) === 1
                })
                .map((filial) => ({ ...filial, key: filial.filial_id }))}
              isEditable
              key={(record) => record.filial_id}
              loading={loadingFiliais}
              columns={getColumns()}
              onSave={handleUpdateFilial}
              header={() => (
                <TableHeader>
                  <div>
                    <Switch
                      checked={showInativos}
                      onChange={() => setShowInativos(!showInativos)}
                    />
                    <span>Exibir filiais inativos</span>
                  </div>
                  <Button
                    type="primary"
                    onClick={() => setShowModalNewFilial(true)}
                  >
                    <UserAddOutlined /> NOVA FILIAL
                  </Button>
                </TableHeader>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="NOVA FILIAL"
        visible={showModalNewFilial}
        cancelText="CANCELAR"
        onCancel={() => handleCancelNewFilial()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'nova-filial',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <NovaFilial
          handleChangeFilial={handleUpdateFilial}
          onFinish={handleNewFilial}
          {...handleFilial}
        />
      </Modal>
    </Col>
  )
}
export default Filial
