import React, { useEffect, useState, useCallback, useRef } from 'react'

import { Form as Unform } from '@unform/web'

import * as Yup from 'yup'

import { Button, Card, Col, Space, Tooltip, Modal } from 'antd'

import { SaveOutlined, EyeOutlined } from '@ant-design/icons'

import { useHistory } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { toast } from 'react-toastify'

import { getContaURL } from '../../../../../services/auth'

import Link from '../../../../../components/privateLink/PrivateLink'

import {
  FormContainer,
  FormSubheader,
  FormColumn,
  Instruction,
  Header,
} from './styles'

import Questoes from './components/Questoes/Questoes'
import Metricas from './components/Metricas'

import InputField from './components/form/input-field'
import TextAreaField from './components/form/textarea-field'

import { postQuiz } from '../actions/actionsQuiz'

import { schema } from './novoQuiz.schema'

import ApresentacaoQuiz from '../FormularioQuiz/components/Apresentacao'

function CriarTeste() {
  const history = useHistory()

  const formRef = useRef(null)

  const contaUrl = getContaURL()

  const dispatch = useDispatch()

  const [questoes, setQuestoes] = useState([])

  const [dataFormPreview, setDataFormPreview] = useState([])

  const [showPreviewButton, setShowPreviewButton] = useState(false)

  const [showModalApresentacao, setShowModalApresentacao] = useState(false)

  const handleQuestion = useCallback(
    (questaoIndex) => {
      // Atualiza o estado para o atual formRem
      questoes[questoes.length - 1] = formRef.current.getData().questoes

      const reducedArr = questoes[questoes.length - 1].filter(
        (item, itemIndex) => {
          return itemIndex !== questaoIndex
        }
      )

      formRef.current.setData({
        ...formRef.current.getData(),
        questoes: reducedArr,
      })

      setQuestoes(reducedArr)
    },
    [questoes]
  )

  async function handleNewQuiz(data) {
    if (!data.titulo || !data.objetivo || !data.tempo_limite) {
      toast.error('Verifique os campos obrigatórios.')
    } else {
      try {
        // Valida preenchimento de campos apenas com a tecla de espaço (" ")
        await schema.validate(data, { abortEarly: false })

        const questoesPossuemAlternativasPreenchidas = data.questoes
          .map((questao) => {
            if (questao.questao) {
              if (questao.tipo === 1) return true
              return questao.alternativas
                ?.map((alternativa, index) => alternativa.resposta_certa)
                .some(Boolean)
            }
            return false
          })
          .every(Boolean)

        if (questoesPossuemAlternativasPreenchidas) postNewQuiz(data)
        else
          toast.error(
            'Certifique que todas as questões possuem uma alternativa marcada como a resposta certa e um enunciado.'
          )
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.errors.forEach((erro) => {
            toast.error(erro)
          })
        }
      }
    }
  }

  const postNewQuiz = useCallback(
    (data) => {
      dispatch(postQuiz(data)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Quiz cadastrado com sucesso.')
          history.push(`/${contaUrl}/recrutamento-selecao`)
        }
      })
    },
    [history, contaUrl, dispatch]
  )

  const handleAddNovaQuestao = useCallback(
    (value) => {
      setQuestoes([...questoes, value])
    },
    [questoes]
  )

  const handleChangeQuiz = () => {
    // Salva o conteúdo de preenchimento do form no local e localStorage
    // A informação salva é utilizada nos previews parciais e no preview completo
    if (showPreviewButton == false) setShowPreviewButton(true)

    const currentFormData = formRef && formRef.current?.getData()

    localStorage.setItem(
      '@TeamHubGB:teste_preview',
      JSON.stringify(currentFormData)
    )

    setDataFormPreview(currentFormData)
  }

  useEffect(() => {
    if (questoes.length === 0) {
      setQuestoes([...questoes, formRef.current.getData().questoes])
    }

    localStorage.setItem(
      '@TeamHubGB:teste_preview',
      JSON.stringify({
        titulo: null,
        exibir_resultado: false,
        instrucoes: null,
        msg_encerramento: null,
        nota_min: null,
        objetivo: null,
        questoes: [
          {
            questao: null,
            tipo: null,
            alternativas: [
              { alternativa: null, resposta_certa: false },
              { alternativa: null, resposta_certa: false },
            ],
          },
        ],
        tempo_limite: null,
      })
    )
    setShowPreviewButton(false)
  }, [setShowPreviewButton, questoes])

  return (
    <>
      <Header>
        <div>
          <Link to="/recrutamento-selecao">Voltar</Link>
        </div>
        <div />
      </Header>

      <Col span={22} style={{ margin: 'auto' }}>
        <Unform
          ref={formRef}
          onSubmit={handleNewQuiz}
          layout="vertical"
          onKeyUp={handleChangeQuiz}
        >
          <Space direction="vertical" size={40} style={{ width: '100%' }}>
            <Card
              title="Informações Básicas"
              extra={
                <>
                  <Tooltip
                    title="Simule como o candidato verá o teste que está criando."
                    placement="bottom"
                  >
                    <Button
                      type="text"
                      icon={<EyeOutlined />}
                      onClick={() => setShowModalApresentacao(true)}
                    >
                      VER PREVIEW
                    </Button>
                  </Tooltip>
                </>
              }
            >
              <FormContainer>
                <FormSubheader>Apresentação</FormSubheader>
                <FormColumn direction="vertical" size={12}>
                  <p style={{ marginBottom: 0 }}>
                    <span style={{ color: 'red' }}>*</span> Título do teste:
                  </p>
                  <InputField
                    name="titulo"
                    placeholder="Exemplo: Teste de Lógica Básica"
                    required
                    maxLength={149}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Por favor insira um título!',
                      },
                    ]}
                  />

                  <p style={{ marginBottom: 0 }}>
                    <span style={{ color: 'red' }}>*</span> Objetivo:
                  </p>
                  <TextAreaField
                    name="objetivo"
                    placeholder="Exemplo: Este teste testará suas habilidades de lógica básica."
                    required
                    maxLength={249}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Por favor insira um objetivo!',
                      },
                    ]}
                  />

                  <p style={{ marginBottom: 0 }}>Instruções:</p>
                  <TextAreaField
                    name="instrucoes"
                    placeholder="O candidato será informado sobre a duração do teste. Se julgar necessário mais instruções, utilize este campo para passá-las."
                    maxLength={249}
                  />

                  <p style={{ marginBottom: 0 }}>Mensagem de encerramento:</p>
                  <TextAreaField name="msg_encerramento" maxLength={249} />
                </FormColumn>
              </FormContainer>
            </Card>
            <Card title="Questões">
              {questoes.map((_, questaoIndex) => {
                return (
                  <Questoes
                    questaoIndex={questaoIndex}
                    formRef={formRef}
                    removerItem={() => handleQuestion(questaoIndex)}
                    key={questaoIndex}
                    handleAddNovaQuestao={handleAddNovaQuestao}
                    previewData={dataFormPreview}
                  />
                )
              })}
            </Card>
            <Card title="Métricas">
              <Metricas formRef={formRef.current} />
            </Card>
            <Card>
              <Instruction>
                Assim que esse teste for salvo você poderá enviá-lo para
                candidatos de qualquer processo seletivo por meio da nossa
                plataforma ou com link compartilhável. Ainda será possível
                corrigir o gabarito deste teste e suas informações básicas,{' '}
                <span className="highlight">
                  mas não será possível editar o corpo ou as alternativas de uma
                  questão
                </span>
                , portanto, confira o seu teste antes de salvá-lo.
              </Instruction>

              <Space>
                <Link target="_blank" to="/teste/preview-novo-teste">
                  <Button
                    type="dashed"
                    icon={<EyeOutlined />}
                    htmlType="button"
                  >
                    PREVIEW TESTE COMPLETO
                  </Button>
                </Link>
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  htmlType="submit"
                >
                  SALVAR TESTE
                </Button>
              </Space>
            </Card>
          </Space>
        </Unform>
      </Col>

      {/* MODAL PREVIEW APRESENTAÇÃO */}
      <Modal
        visible={showModalApresentacao}
        footer={null}
        width="80%"
        onCancel={() => setShowModalApresentacao(false)}
      >
        <ApresentacaoQuiz
          preview
          informacoesQuiz={dataFormPreview}
          tempo_limite={dataFormPreview.data_limite}
        />
      </Modal>
    </>
  )
}

export default CriarTeste
