import React, { useCallback, useMemo, useState } from 'react'
import { Col, Row, Form, Switch, Divider, Select, Slider } from 'antd'
import { Chart, Coordinate, Axis, Tooltip, Line, Point, Area } from 'bizcharts'

import { toast } from 'react-toastify'
import { arqRange, colors, sortCultura } from '../../utils/utils'

import { AvailableArquetiposValue, FormHeader, InputGroup } from './styles'
import { noPadding } from '../../utils/inlineStyles'

type CulturaDesejada = {
  mercado: number
  cliente: number
  operacao: number
  interno: number
  olhar_externo: number
  olhar_interno: number
  controle: number
  flexibilidade: number
  INOVACAO: number
  EMPREENDEDORA: number
  COLABORACAO: number
  CLA: number
  APRENDIZADO: number
  COMPLACENCIA: number
  REGRA: number
  AUTORIDADE: number
  REGRA_limite: 'min' | 'max'
  COMPLACENCIA_limite: 'min' | 'max'
  APRENDIZADO_limite: 'min' | 'max'
  CLA_limite: 'min' | 'max'
  COLABORACAO_limite: 'min' | 'max'
  EMPREENDEDORA_limite: 'min' | 'max'
  INOVACAO_limite: 'min' | 'max'
  AUTORIDADE_limite: 'min' | 'max'
  ativo: boolean
  [key: string]: number | boolean | 'string' | 'min' | 'max'
}

type ChartData = {
  Tipo: string
  Valor: string
  Label: string
}

interface CulturaDesejadaFormProps {
  onFinish(data: CulturaDesejada): void
  initialValues?: CulturaDesejada
  culturaDesejadaEmpresa?: ChartData[]
  culturaInstalada?: ChartData[]
  label: string
  toggable?: boolean
}

type ArquetiposLabels = {
  [key: string]: string
}

const arquetipos = Array.from(
  new Set([
    'INOVACAO',
    'EMPREENDEDORA',
    'COLABORACAO',
    'CLA',
    'APRENDIZADO',
    'COMPLACENCIA',
    'REGRA',
    'AUTORIDADE',
  ])
)

const arquetiposLabels: ArquetiposLabels = {
  INOVACAO: 'Inovação',
  EMPREENDEDORA: 'Empreendedora',
  COLABORACAO: 'Colaboração',
  CLA: 'Clã',
  APRENDIZADO: 'Aprendizado',
  COMPLACENCIA: 'Complacência',
  REGRA: 'Regra',
  AUTORIDADE: 'Autoridade',
}

const culturaDesejadaInitialValues: CulturaDesejada = {
  APRENDIZADO: 0,
  AUTORIDADE: 0,
  CLA: 0,
  COLABORACAO: 0,
  COMPLACENCIA: 0,
  EMPREENDEDORA: 0,
  INOVACAO: 0,
  REGRA: 0,
  cliente: 0,
  controle: 0,
  flexibilidade: 0,
  interno: 0,
  mercado: 0,
  olhar_externo: 0,
  olhar_interno: 0,
  operacao: 0,
  REGRA_limite: 'min',
  COMPLACENCIA_limite: 'min',
  APRENDIZADO_limite: 'min',
  CLA_limite: 'min',
  COLABORACAO_limite: 'min',
  EMPREENDEDORA_limite: 'min',
  INOVACAO_limite: 'min',
  AUTORIDADE_limite: 'min',
  ativo: true,
}

const limitesOptions = [
  { label: 'Min', value: 'min' },
  { label: 'Max', value: 'max' },
]

export default function CulturaDesejadaForm({
  label,
  onFinish,
  culturaDesejadaEmpresa = [],
  culturaInstalada = [],
  initialValues = culturaDesejadaInitialValues,
  toggable = true,
}: CulturaDesejadaFormProps): JSX.Element {
  const [form] = Form.useForm()

  const [culturaDesejada, setCulturaDesejada] = useState(initialValues)

  const handleSubmitForm = useCallback(
    (data: CulturaDesejada) => {
      const {
        APRENDIZADO,
        AUTORIDADE,
        CLA,
        COLABORACAO,
        COMPLACENCIA,
        EMPREENDEDORA,
        INOVACAO,
        REGRA,
      } = data

      const arquetiposSum =
        APRENDIZADO +
        AUTORIDADE +
        CLA +
        COLABORACAO +
        COMPLACENCIA +
        EMPREENDEDORA +
        INOVACAO +
        REGRA

      if (arquetiposSum > 100 || arquetiposSum < 100) {
        toast.error('A soma dos arquétipos deve representar 100%.')
        return
      }

      const forcas = {
        mercado: parseFloat(
          ((AUTORIDADE + INOVACAO + EMPREENDEDORA + COLABORACAO) / 2).toFixed(1)
        ),
        cliente: parseFloat(
          ((EMPREENDEDORA + COLABORACAO + CLA + APRENDIZADO) / 2).toFixed(1)
        ),
        interno: parseFloat(
          ((CLA + APRENDIZADO + COMPLACENCIA + REGRA) / 2).toFixed(1)
        ),
        operacao: parseFloat(
          ((COMPLACENCIA + REGRA + AUTORIDADE + INOVACAO) / 2).toFixed(1)
        ),
      }

      const olhares = {
        controle: parseFloat(
          (
            (forcas.operacao / (forcas.operacao + forcas.cliente)) *
            100
          ).toFixed(1)
        ),
        flexibilidade: parseFloat(
          ((forcas.cliente / (forcas.operacao + forcas.cliente)) * 100).toFixed(
            1
          )
        ),
        olhar_externo: parseFloat(
          ((forcas.interno / (forcas.interno + forcas.mercado)) * 100).toFixed(
            1
          )
        ),
        olhar_interno: parseFloat(
          ((forcas.mercado / (forcas.interno + forcas.mercado)) * 100).toFixed(
            1
          )
        ),
      }

      const cultura: CulturaDesejada = {
        ...data,
        ...forcas,
        ...olhares,
      }

      onFinish(cultura)
    },
    [onFinish]
  )

  const onValuesChange = useCallback((values: CulturaDesejada) => {
    setCulturaDesejada(values)
  }, [])

  const availableArquetiposValue = useMemo((): number => {
    return Object.keys(culturaDesejada)
      .filter((item) => arquetipos.includes(item))
      .reduce((acc, arquetipo) => {
        if (!culturaDesejada[arquetipo]) {
          return acc
        }
        return acc - (culturaDesejada[arquetipo] as number)
      }, 100)
  }, [culturaDesejada])

  const arquetipoRules = useMemo(
    () => [
      {
        validator() {
          if (availableArquetiposValue < 0) {
            return Promise.reject(
              new Error('A soma dos arquétipos deve ser menor que 100.')
            )
          }

          const fields = form.getFieldsValue()

          form.setFields(
            Object.keys(fields).map((field) => ({
              name: field,
              value: fields[field],
              errors: [],
            }))
          )

          return Promise.resolve()
        },
      },
    ],
    [availableArquetiposValue, form]
  )

  const chartData = useMemo<ChartData[]>(() => {
    const culturaDesejadaChartData = Object.keys(culturaDesejada)
      .filter((item) => arquetipos.includes(item))
      .map((arquetipo) => ({
        Tipo: arquetiposLabels[arquetipo],
        Label: `Desejado (${label})`,
        Valor: culturaDesejada[arquetipo],
      }))

    return [
      ...sortCultura(culturaDesejadaChartData),
      ...sortCultura(culturaDesejadaEmpresa),
      ...sortCultura(culturaInstalada),
    ]
  }, [culturaDesejada, culturaDesejadaEmpresa, culturaInstalada, label])

  return (
    <Col span={24}>
      <Row gutter={[24, 24]}>
        <Col
          xs={24}
          sm={24}
          lg={10}
          style={{
            ...noPadding,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Chart height={350} data={chartData} scale={arqRange} autoFit>
            <Coordinate type="polar" radius={0.8} />
            <Axis
              name="Tipo"
              line={null}
              tickLine={null}
              grid={{
                line: {
                  style: {
                    lineDash: null,
                    hideFirstLine: false,
                  },
                },
              }}
            />
            <Tooltip />
            <Axis
              name="Valor"
              line={null}
              tickLine={null}
              grid={{
                line: {
                  style: {
                    lineDash: null,
                  },
                  type: 'circle',
                },
                alternateColor: 'rgba(0, 0, 0, 0.04)',
              }}
            />
            <Area position="Tipo*Valor" color={['Label', colors]} />
            <Line position="Tipo*Valor" color={['Label', colors]} size={2} />
            <Point
              type="point"
              position="Tipo*Valor"
              color={['Label', colors]}
              shape="circle"
              size={4}
              style={{
                stroke: '#fff',
                lineWidth: 1,
                fillOpacity: 1,
              }}
            />
          </Chart>
        </Col>
        <Col xs={24} sm={24} lg={14}>
          <Form
            layout="vertical"
            form={form}
            scrollToFirstError
            onValuesChange={onValuesChange}
            initialValues={initialValues}
            id="cultura-desejada-form"
            onFinish={handleSubmitForm}
          >
            <Col span={24}>
              <FormHeader>
                {toggable && (
                  <div>
                    <Form.Item
                      name="ativo"
                      label="Ativo"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </div>
                )}
                {!(availableArquetiposValue === 0) && (
                  <AvailableArquetiposValue
                    invalid={availableArquetiposValue < 0}
                    available={availableArquetiposValue > 0}
                  >
                    {availableArquetiposValue > 0
                      ? `Disponível: ${availableArquetiposValue}%`
                      : `Inválido: ${Math.abs(
                          -100 + availableArquetiposValue
                        )}%`}
                  </AvailableArquetiposValue>
                )}
              </FormHeader>

              <Divider />

              <Row gutter={48}>
                <Col xs={24} md={12}>
                  {arquetipos.slice(0, 4).map((arquetipo) => (
                    <InputGroup compact>
                      <Form.Item
                        name={arquetipo}
                        label={arquetiposLabels[arquetipo]}
                        rules={arquetipoRules}
                        key={arquetipo}
                      >
                        <Slider
                          step={0.5}
                          tooltipPlacement="left"
                          tipFormatter={(value) => `${value}%`}
                          marks={{
                            0: '0%',
                            50: '50%',
                            100: '100%',
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name={`${arquetipo}_limite`}
                        key={`${arquetipo}_limite`}
                      >
                        <Select options={limitesOptions} />
                      </Form.Item>
                    </InputGroup>
                  ))}
                </Col>
                <Col xs={24} md={12}>
                  {arquetipos.slice(4).map((arquetipo) => (
                    <>
                      <InputGroup compact>
                        <Form.Item
                          name={arquetipo}
                          label={arquetiposLabels[arquetipo]}
                          rules={arquetipoRules}
                          key={arquetipo}
                        >
                          <Slider
                            step={0.5}
                            tooltipPlacement="left"
                            tipFormatter={(value) => `${value}%`}
                            marks={{
                              0: '0%',
                              50: '50%',
                              100: '100%',
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name={`${arquetipo}_limite`}
                          key={`${arquetipo}_limite`}
                        >
                          <Select options={limitesOptions} />
                        </Form.Item>
                      </InputGroup>
                    </>
                  ))}
                </Col>
              </Row>
            </Col>
          </Form>
        </Col>
      </Row>
    </Col>
  )
}
