import styled from 'styled-components'

import { Upload as AntdUpload } from 'antd'

export const ParamContainer = styled.div`
  display: flex;
  flex-direction: row;

  label + span {
    margin-left: 8px;
  }

  & + div {
    margin-top: 16px;
  }

  strong {
    font-weight: 500;
  }
`

export const Upload = styled(AntdUpload)`
  .ant-upload-list {
    display: flex;
    flex-direction: row;
  }

  .ant-upload-list-item {
    width: 200px;
    margin-right: 8px;
  }

  .ant-upload-list-picture-card-container {
    > .ant-upload-list-item {
      width: 104px;
      height: 104px;
    }
  }
`

export const EstilizacaoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center !important;

  > div {
    & + div {
      margin: 0;
      margin-top: 24px;
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    > div {
      & + div {
        margin: 0;
        margin-left: 24px;
      }
    }
  }
`

export const EstilizacaoImagemLogo = styled.div`
  display: flex;
  justify-content: flex-end !important;
`

export const BotoesSalvar = styled.div`
  margin-bottom: '20px';
  justify-content: flex-end !important;
`

export const ColorPickerContainer = styled.div`
  display: flex;
  flex-direction: row;

  > span {
    display: flex;
    flex-direction: row;
    align-items: center;

    > span + input {
      margin-left: 16px;
    }

    > input {
      width: 125px;
    }
  }
`

export const CamposContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  align-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0 64px;

  @media screen and (min-width: 660px) {
    max-height: 670px;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 1000px) {
    max-height: 400px;
  }

  @media screen and (min-width: 1650px) {
    max-height: 400px;
  }
`

export const LinkTrabalheConoscoHeader = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props && props.color ? props.color : '#fff')};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const LinkPaginaTrabalheConosco = styled.span`
  color: var(--purple);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  font-size: 14px;
`

export const CamposItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  justify-content: space-between;
  padding: 12px 16px;

  > span {
    font-weight: 500;
    align-self: flex-start;
    margin-bottom: 8px;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;

      > button + span {
        margin-left: 8px;
      }
    }
  }

  @media screen and (min-width: 660px) {
    width: 45%;
  }

  @media screen and (min-width: 1000px) {
    width: 30%;
  }

  @media screen and (min-width: 1400px) {
    width: 25%;
  }

  @media screen and (min-width: 1500px) {
    width: 20%;
  }

  @media screen and (min-width: 1660px) {
    width: 15%;
  }
`
