import React, { useState } from 'react'

import { Form, Input, Select } from 'antd'

import { Col, Steps } from 'antd'

const { Step } = Steps
export default function TecnicaCargo({
  handleChangeTecnica,
  onFinish,
  nomeTecnica,
  descricaoTecnica,
  form,
}) {
  const [valorInvalido, setValorInvalido] = useState(false)

  /* Não remover rule */
  const validationTecnica = (rule, value, callback) => {
    if (!value.match(/^(?!\s+$).*/)) {
      setValorInvalido(true)
      return callback('Por favor, insira um nome válido!')
    }
    setValorInvalido(false)
    return callback()
  }

  const [maximoValor, setMaximoValor] = useState(0)

  const handleVerificarMaximoValor = (e) => {
    setMaximoValor(e.target.value.length)
  }

  return (
    <Form
      layout="vertical"
      id="cadastro-tecnica"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        label="Nome"
        name="nomeTecnica"
        style={{
          marginBottom: 3,
        }}
        rules={[
          {
            required: true,
            message: 'Por favor, preencha este campo.',
          },
          {
            validator: validationTecnica,
            message: 'Insira um nome válido!',
          },
        ]}
      >
        <Input
          type="text"
          placeholder="Digite o nome"
          onChange={(e) => handleVerificarMaximoValor(e)}
          maxLength={150}
          key="form_tecnica-nome"
        />
      </Form.Item>
      <p
        style={{
          color: maximoValor >= 140 ? 'red' : '',
          marginTop: valorInvalido ? -9 : -6,
        }}
      >
        {maximoValor <= 9
          ? maximoValor.toString().padStart(2, '0')
          : maximoValor}
        /150
      </p>
      <Form.Item
        label="Descrição"
        name="descricaoTecnica"
        rules={[
          {
            required: true,
            message: 'Por favor, preencha este campo.',
          },
          {
            validator: validationTecnica,
            message: 'Insira uma descrição válida!',
          },
        ]}
      >
        <Input
          type="text"
          placeholder="Digite a descrição"
          key="form_tecnica-descricao"
        />
      </Form.Item>
    </Form>
  )
}
