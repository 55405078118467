import React, { Component } from 'react'

// Components
import { SearchOutlined } from '@ant-design/icons'
import { Input, Button, message } from 'antd'
import { toast } from 'react-toastify'
import TimesDetalhesView from './TimesDetalhesView'

// Constants
import {
  apiToFrontGrupo,
  apiToFrontGrupoPessoa,
  modals,
} from './constants/constantsTimesDetalhes'
import { parseCulturas } from '../../utils/utils'

class TimesDetalhesController extends Component {
  constructor(props) {
    super(props)
    this.handleChangeLider = this.handleChangeLider.bind(this)
  }

  state = {
    dateSource: [],
    searchText: '',
    time: {},
    showModals: [false, false],
    modalStates: modals.map((modal) => ({ ...modal.initialState })),
    colaboradores: [],
    culturaDesejadaGrupo: undefined,
    culturaDesejadaEmpresa: [],
    culturaInstaladaEmpresa: [],
    selectedRows: [],
    selectedRowKeys: [],
  }

  loadCulturaDesejadaGrupo = () => {
    const { id } = this.props.match.params

    const { getCulturaDesejadaGrupo } = this.props

    getCulturaDesejadaGrupo(id).then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          culturaDesejadaGrupo: response.data,
        })
      }
    })
  }

  loadCulturaEmpresa = () => {
    const { getCulturaEmpresa } = this.props

    getCulturaEmpresa().then(({ response }) => {
      if (response.status === 200) {
        const { culturaDesejada } = response.data

        const { cultura } = parseCulturas({
          culturaDesejada,
        })

        this.setState({ culturaDesejadaEmpresa: cultura })
      }
    })
  }

  loadCulturaInstaladaGrupo = () => {
    const { id } = this.props.match.params

    const { getCulturaInstaladaGrupo } = this.props

    getCulturaInstaladaGrupo(id).then(({ response }) => {
      if (response.status === 200) {
        const { cultura } = parseCulturas({
          culturaInstalada: response.data,
        })

        this.setState({ culturaInstaladaEmpresa: cultura })
      }
    })
  }

  componentDidMount() {
    const { getTimeInfo, getTimePessoas, getColaboradores, getGrupoLider } =
      this.props
    const { id } = this.props.match.params

    getGrupoLider(id).then(({ response }) => {
      // eslint-disable-next-line no-empty
      if (response.status === 200) {
      }
    })

    getTimeInfo(id).then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          time: apiToFrontGrupo({ grupo: response.data.grupo }),
        })
      }
    })

    this.loadCulturaEmpresa()
    this.loadCulturaDesejadaGrupo()
    this.loadCulturaInstaladaGrupo()

    getColaboradores().then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          colaboradores: response.data.pessoas
            .filter((pessoa) => pessoa.pes_ativo === 1)
            .map((pessoa) => {
              return { id: pessoa.pes_id, nome: pessoa.pes_nome }
            }),
        })
      }
    })

    getTimePessoas(id).then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          dataSource: response.data.pessoas.map((pessoa) =>
            apiToFrontGrupoPessoa({ pessoa })
          ),
        })
      }
    })
  }

  handleChangeModal = (id, { target }) =>
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.value } : elem
      ),
    }))

  handleChangeCheckbox = (id, { target }) =>
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.checked } : elem
      ),
    }))

  openModal = (id) => {
    const { showModals } = this.state
    const newshowModals = showModals

    newshowModals[id] = true

    this.setState({ showModals: newshowModals })
  }

  closeModal = (id) => {
    const { showModals, modalStates } = this.state
    const newshowModals = showModals

    newshowModals[id] = false

    const newModalStates = modalStates

    newModalStates[id] = modals[id].initialState

    this.setState({
      showModals: newshowModals,
      modalStates: newModalStates,
    })
  }

  onModalSubmit = (id, { sendToAll = false } = {}) => {
    const { modalStates, time, dataSource, selectedRows } = this.state
    const {
      postTimePessoa,
      getTimePessoas,
      sendVetorComportamental,
      sendValoresOrganizacionais,
      getTimeInfo,
      sendPercepcao,
    } = this.props

    if (id === 0) {
      let sucessoEnvioPesquisas = 0
      if (selectedRows.length > 0 || sendToAll) {
        let colaboradoresToSend = [...selectedRows]
        if (sendToAll) {
          const { listaColaboradores } = this.props
          colaboradoresToSend = [...listaColaboradores]
        }
        colaboradoresToSend.forEach(async (row) => {
          if (modalStates[id].vetorComportamental) {
            const values = {
              pessoas: [{ pes_id: row.id }],
              enviarEmails: modalStates[id].enviarEmails,
            }
            const sendVetor = await sendVetorComportamental(values)
            if (sendVetor) {
              const { response } = sendVetor
              if (response.status === 200) {
                sucessoEnvioPesquisas++
                if (sucessoEnvioPesquisas === colaboradoresToSend.length) {
                  toast.success('Enviado com sucesso!')
                }
              }
            }
          }
          if (modalStates[id].percepcaoAmbiente) {
            const sendPercep = await sendPercepcao(row.id, {
              enviarEmails: modalStates[id].enviarEmails,
            })
            if (sendPercep) {
              const { response } = sendPercep
              if (response.status === 200) {
                sucessoEnvioPesquisas++
                if (sucessoEnvioPesquisas === colaboradoresToSend.length) {
                  toast.success('Enviado com sucesso!')
                }
              }
            }
          }
          if (modalStates[id].valoresOrganizacionais) {
            const sendValores = await sendValoresOrganizacionais(row.id, {
              enviarEmails: modalStates[id].enviarEmails,
            })
            if (sendValores) {
              const { response } = sendValores
              if (response.status === 200) {
                sucessoEnvioPesquisas++
                if (sucessoEnvioPesquisas === colaboradoresToSend.length) {
                  toast.success('Enviado com sucesso!')
                }
              }
            }
          }
        })
        this.closeModal(0)
      } else {
        this.closeModal(0)
      }
    } else if (id === 1) {
      if (modalStates[id].pes_id) {
        const idsIguais = dataSource.filter(
          (d) => d.id === modalStates[id].pes_id
        )
        if (idsIguais.length > 0)
          message.error('O colaborador selecionado já faz parte do time!')
        else {
          postTimePessoa({
            grupo_id: time.id,
            pes_id: modalStates[id].pes_id,
          }).then(({ response }) => {
            if (response.status === 200) {
              message.success('Adicionado com sucesso!')
              getTimePessoas(time.id).then(({ response }) => {
                if (response.status === 200) {
                  this.setState({
                    dataSource: response.data.pessoas.map((pessoa) =>
                      apiToFrontGrupoPessoa({
                        pessoa,
                      })
                    ),
                  })
                }
              })
              getTimeInfo(time.id).then(({ response }) => {
                if (response.status === 200) {
                  this.setState({
                    time: apiToFrontGrupo({
                      grupo: response.data.grupo,
                    }),
                  })
                }
              })
              this.closeModal(1)
            }
          })
        }
      } else message.error('Selecione um colaborador!')
    }
  }

  handleChangeLider(id) {
    const {
      updatePessoa,
      getColaborador,
      getTimePessoas,
      getGrupoLider,
      postGrupoLider,
      deleteGrupoLider,
    } = this.props
    const { dataSource, time } = this.state

    const { lider } = dataSource
      .filter((d) => d.id === id)
      .map((d) => {
        return { lider: d.lider }
      })[0]

    if (!lider) {
      postGrupoLider(time.id, id).then(({ response }) => {
        if (response.status === 200) {
          getTimePessoas(time.id).then(({ response }) => {
            if (response.status === 200) {
              this.setState({
                dataSource: response.data.pessoas.map((pessoa) =>
                  apiToFrontGrupoPessoa({ pessoa })
                ),
              })
            }
          })
        }
      })
    } else {
      deleteGrupoLider(time.id, id).then(({ response }) => {
        if (response.status === 200) {
          getTimePessoas(time.id).then(({ response }) => {
            if (response.status === 200) {
              this.setState({
                dataSource: response.data.pessoas.map((pessoa) =>
                  apiToFrontGrupoPessoa({ pessoa })
                ),
              })
            }
          })
        }
      })
    }

    // getColaborador(id).then(({ response }) => {
    //   if (response.status === 200) {
    //     updatePessoa({ ...response.data.pessoa, ...pessoa }).then(
    //       ({ response }) => {
    //         if (response.status === 200) {
    //           getTimePessoas(time.id).then(({ response }) => {
    //             if (response.status === 200) {
    //               this.setState({
    //                 dataSource: response.data.pessoas.map(pessoa =>
    //                   apiToFrontGrupoPessoa({ pessoa }),
    //                 ),
    //               });
    //             }
    //           });
    //         }
    //       },
    //     );
    //   }
    // });
  }

  onTableDelete = (idPessoa) => {
    const { deleteTimePessoa } = this.props
    const { dataSource, time } = this.state
    const newData = dataSource.filter((data) => data.id !== idPessoa)

    deleteTimePessoa(time.id, idPessoa).then(({ response }) => {
      if (response.status === 200) {
        this.setState({ dataSource: newData })
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder="Buscar"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  handleSaveCulturaDesejadaGrupo = (data) => {
    const { id } = this.props.match.params
    const { updateCulturaDesejadaGrupo } = this.props

    updateCulturaDesejadaGrupo({ grupo_id: id, ...data }).then(
      ({ response }) => {
        if (response.status === 200) {
          toast.success('Cultura desejada do time ajustada com sucesso.')
          this.loadCulturaDesejadaGrupo()
        }
      }
    )
  }

  render() {
    const {
      handleChangeLider,
      onModalSubmit,
      handleChangeCheckbox,
      handleChangeModal,
      openModal,
      closeModal,
      getColumnSearchProps,
      onTableDelete,
      handleSaveCulturaDesejadaGrupo,
    } = this
    const {
      dataSource,
      time,
      showModals,
      modalStates,
      colaboradores,
      culturaDesejadaGrupo,
      culturaDesejadaEmpresa,
      culturaInstaladaEmpresa,
    } = this.state

    const { id } = this.props.match.params

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys,
          selectedRows,
        })
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }

    return (
      <TimesDetalhesView
        dataSource={dataSource}
        onModalSubmit={onModalSubmit}
        handleChangeCheckbox={handleChangeCheckbox}
        handleChangeModal={handleChangeModal}
        showModals={showModals}
        modalStates={modalStates}
        openModal={openModal}
        closeModal={closeModal}
        handleChangeLider={handleChangeLider}
        time={time}
        colaboradores={colaboradores}
        getColumnSearchProps={getColumnSearchProps}
        onTableDelete={onTableDelete}
        id={id}
        handleSaveCulturaDesejadaGrupo={handleSaveCulturaDesejadaGrupo}
        culturaDesejadaGrupo={culturaDesejadaGrupo}
        culturaDesejadaEmpresa={culturaDesejadaEmpresa}
        culturaInstaladaEmpresa={culturaInstaladaEmpresa}
        rowSelection={rowSelection}
        {...this.props}
      />
    )
  }
}

export default TimesDetalhesController
