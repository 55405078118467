import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'

// Components
import PerfilComportamentalView from './PerfilComportamentalView'

// CSS

// Constants

class PerfilComportamentalController extends Component {
  state = {
    currentStep: 0,
    checkboxesSelecionados: { 0: {}, 1: {}, 2: {} },
    showModal: false,
    name: '',
    showHints: [],
    showIntro: true,
    pesquisasPendentes: [],
    conta_url: undefined,
  }

  componentDidMount = () => {
    const {
      getCaracteristicasPerfilComportamental,
      getInfoPerfilComportamental,
      match,
      loadVetor,
      history,
    } = this.props

    loadVetor(match.params.id).then(({ response }) => {
      if (response.status !== 200) {
        history.push('/')
        return
      }

      const { pesquisasPendentes, conta_url } = response.data

      this.setState({
        pesquisasPendentes: pesquisasPendentes || [],
        conta_url,
      })

      getCaracteristicasPerfilComportamental()
      getInfoPerfilComportamental(match.params.id).then(({ response }) => {
        if (response.status === 200) {
          this.setState({ name: response.data.avaliacao.pessoa.pes_nome })
        }
      })
    })
  }

  handleChangeCheckbox = (evt, step, id) => {
    this.setState((prevState) => ({
      checkboxesSelecionados: {
        ...prevState.checkboxesSelecionados,
        [step]: {
          ...prevState.checkboxesSelecionados[step],
          [id]: evt.target.checked,
        },
      },
    }))
  }

  nextStep = () => {
    const { checkboxesSelecionados, currentStep } = this.state

    const checkedCounter = Object.keys(
      checkboxesSelecionados[currentStep]
    ).reduce((total, next) => {
      return checkboxesSelecionados[currentStep][next] ? total + 1 : total
    }, 0)

    if (checkedCounter >= 4) {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep + 1,
      }))
    } else toast.error('Selecione pelo menos 4 comportamentos.')
  }

  prevStep = () =>
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }))

  openModal = () => {
    this.setState({
      showModal: true,
    })
  }

  onSubmit = () => {
    const { checkboxesSelecionados } = this.state

    const steps = [0, 1, 2]
    const stepsValidation = steps.map((step) =>
      Object.keys(checkboxesSelecionados[step]).reduce((total, next) => {
        return checkboxesSelecionados[step][next] ? total + 1 : total
      }, 0)
    )

    if (stepsValidation[0] < 4) {
      toast.error(
        `É necessário selecionar ao menos 4 comportamentos para 'Quem acho que sou'.`
      )
    } else if (stepsValidation[1] < 4) {
      toast.error(
        `É necessário selecionar ao menos 4 comportamentos para 'O que exigem de mim'.`
      )
    } else if (stepsValidation[2] < 4) {
      toast.error(
        `É necessário selecionar ao menos 4 comportamentos para 'O que consigo entregar'.`
      )
    } else {
      const { postRespostaPerfilComportamental, match } = this.props
      const { checkboxesSelecionados } = this.state

      postRespostaPerfilComportamental({
        id: match.params.id,
        checkboxesSelecionados: _.values(checkboxesSelecionados).map((elem) =>
          _.map(elem, (val, key) => val && key).filter((elem) => elem)
        ),
      }).then(({ response }) => {
        if (response.status === 200) {
          this.openModal()
        }
      })
    }
  }

  handleInit = () => {
    this.setState({ showIntro: false })
  }

  render() {
    return (
      <PerfilComportamentalView
        {...this.props}
        {...this.state}
        nextStep={this.nextStep}
        prevStep={this.prevStep}
        onSubmit={this.onSubmit}
        handleChangeCheckbox={this.handleChangeCheckbox}
        handleInit={this.handleInit}
      />
    )
  }
}

export default PerfilComportamentalController
