import React, { Component } from 'react'
import * as cepPromise from 'cep-promise'
import _ from 'lodash'
import { toast } from 'react-toastify'
import xlsxParser from 'xlsx-parse-json'
import { SearchOutlined } from '@ant-design/icons'
import { message, Button, Input } from 'antd'

// Components
import CandidatosView from './CandidatosView'

// CSS

// Constants
import { modals, apiToFront } from './constants/constantsCandidatos'

import { validateForm } from '../../utils/forms'

const formFields = [
  {
    name: 'nome',
    required: true,
  },
  {
    name: 'email',
    required: true,
  },
  {
    name: 'cargosEscolhidos',
    minLength: 1,
  },
  {
    name: 'twitter',
    twitter: true,
  },
  {
    name: 'linkedin',
    linkedin: true,
  },
  {
    name: 'site',
    url: true,
  },
]

class CandidatosController extends Component {
  state = {
    showModals: [false, false, false],
    dataSource: [],
    tableCount: 1,
    modalStates: modals.map((modal) => ({ ...modal.initialState })),
    selectedRowKeys: [],
    selectedRows: [],
    cargos: [],
    error: {},
    novoCandidatoCurrentStep: 0,
    fileList: [],
    fetchingCpf: false,
    loadingCandidatos: false,
    processos: [],
  }

  initLoadingCandidatos = () => this.setState({ loadingCandidatos: true })

  endLoadingCandidatos = () => this.setState({ loadingCandidatos: false })

  componentDidMount = () => {
    const { getCandidatos, getCargos, listarProcessos } = this.props

    this.initLoadingCandidatos()

    getCandidatos().then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          dataSource: response.data.candidatos.map((candidato) =>
            apiToFront({ candidato })
          ),
        })
      }
      this.endLoadingCandidatos()
    })

    getCargos().then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          cargos: response.data.cargos
            .filter((cargo) => cargo.cargo_ativo === '1')
            .map(({ cargo_id, cargo_nome }) => {
              return { cargo_id, cargo_nome }
            }),
        })
      }
    })

    listarProcessos().then(({ response }) => {
      if (response.status === 200) {
        this.setState({ processos: response.data.processos })
      } else toast.error('Erro ao listar Processos Seletivos.')
    })
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  handleChangeModal = (id, { target }) => {
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.value } : elem
      ),
    }))
  }

  handleChangeCheckbox = (id, { target }) => {
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.checked } : elem
      ),
    }))
  }

  openModal = (id) => {
    const { showModals } = this.state
    const newshowModals = showModals

    newshowModals[id] = true

    this.setState({ showModals: newshowModals })
  }

  closeModal = (id, reset = true) => {
    const { showModals, modalStates } = this.state
    const newshowModals = showModals

    newshowModals[id] = false

    const newModalStates = modalStates

    if (reset) {
      newModalStates[id] = modals[id].initialState
    }

    this.setState({
      showModals: newshowModals,
      modalStates: newModalStates,
      error: {},
      fileList: [],
    })

    if (id === 1) {
      this.setState({ novoCandidatoCurrentStep: 0 })
    }

    if (id === 4) {
      this.closeModal(3)
    }
  }

  onModalSubmit = (id, confirm = false) => {
    const { modalStates, selectedRows } = this.state
    const {
      postCandidato,
      getCandidatos,
      sendVetorComportamental,
      efetivaCandidato,
      sendValoresOrganizacionaisLote,
      triarCandidatos,
      // sendPercepcao,
    } = this.props

    if (id === 0) {
      const dataVetorComportamental = { pessoas: [] }
      const dataValoresOrganizacionais = { pessoas: [] }
      // var sucessoEnvioPesquisas = 0;

      if (selectedRows.length === 0) {
        toast.error('Nenhum candidato selecionado!')
        return this.closeModal(0)
      }
      selectedRows.forEach((row) => {
        if (modalStates[id].vetorComportamental)
          dataVetorComportamental.pessoas.push({ pes_id: row.key })
        if (modalStates[id].valoresOrganizacionais)
          dataValoresOrganizacionais.pessoas.push({ pes_id: row.key })
        /*
				if (modalStates[id].percepcaoAmbiente)
					sendPercepcao(row.key).then(({ response }) => {
						if (response.status === 200) {
							sucessoEnvioPesquisas++;
							if (sucessoEnvioPesquisas === selectedRows.length)
								toast.success('Enviado com sucesso!');
						}
					});
				*/
      })
      if (modalStates[id].vetorComportamental) {
        sendVetorComportamental({
          ...dataVetorComportamental,
          enviarEmails: modalStates[id].enviarEmails,
        }).then(({ response }) => {
          if (response.status === 200)
            toast.success(
              'Pesquisas de Vetor Comportamental enviadas com sucesso.'
            )
        })
      }

      if (modalStates[id].valoresOrganizacionais) {
        sendValoresOrganizacionaisLote({
          ...dataValoresOrganizacionais,
          enviarEmails: modalStates[id].enviarEmails,
        }).then(({ response }) => {
          if (response.status === 200)
            toast.success('Pesquisas de Valores enviadas com sucesso.')
        })
      }

      this.closeModal(0)
    }

    if (id === 1) {
      const error = validateForm(formFields, modalStates[id])
      this.setState({ error: error.messages })

      if (error.hasError) return

      postCandidato(modalStates[id]).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Candidato cadastrado com sucesso.')
          this.initLoadingCandidatos()
          getCandidatos().then(({ response }) => {
            if (response.status === 200) {
              this.setState({
                dataSource: response.data.candidatos.map((candidato) =>
                  apiToFront({ candidato })
                ),
              })
            }
            this.endLoadingCandidatos()
          })
          this.closeModal(id)
        }
      })
    }

    if (id === 2) {
      const { cargo, id, cpf } = modalStates[2]
      efetivaCandidato({ cargo, id, cpf }).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Efetivado com sucesso!')
          this.initLoadingCandidatos()
          getCandidatos().then(({ response }) => {
            if (response.status === 200) {
              this.setState({
                dataSource: response.data.candidatos.map((candidato) =>
                  apiToFront({ candidato })
                ),
              })
              this.closeModal(id)
            }
            this.endLoadingCandidatos()
          })
        }
      })
    }

    // triagem
    if (id === 3) {
      const { processoSelected } = modalStates[3]
      if (this.state.selectedRowKeys.length > 0) {
        triarCandidatos({
          processo_id: processoSelected,
          candidatos: this.state.selectedRowKeys,
          confirm,
        }).then(({ response }) => {
          if (response.status === 200) {
            toast.success('Candidatos triados com sucesso.')
            this.closeModal(id)
            this.closeModal(4)
          } else if (response.status === 412) {
            const { message, candidatosToConfirm } = response.data

            this.handleChangeModal(4, {
              target: {
                name: 'message',
                value: message,
              },
            })

            this.handleChangeModal(4, {
              target: {
                name: 'list',
                value: candidatosToConfirm.map((c) => c.pes_nome),
              },
            })

            this.closeModal(id, false)
            this.openModal(4)
          } else toast.error('Erro ao triar candidatos.')
        })
      } else toast.error('Selecione candidatos para triar.')
    }

    // confirm triagem
    if (id === 4) {
      this.onModalSubmit(3, true)
    }
  }

  onTableAdd = (newData) => {
    const { dataSource } = this.state

    dataSource.push(newData)

    this.setState({
      dataSource,
    })
  }

  onTableDelete = (id) => {
    const { deleteCandidato, getCandidatos } = this.props
    deleteCandidato(id).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Excluído com sucesso!')
        this.initLoadingCandidatos()

        getCandidatos().then(({ response }) => {
          if (response.status === 200) {
            this.setState({
              dataSource: response.data.candidatos.map((candidato) =>
                apiToFront({ candidato })
              ),
            })
          }
          this.endLoadingCandidatos()
        })
      }
    })
  }

  handleEfetivar = (id) => {
    const { openModal } = this
    const { efetivaCandidato, getCandidatos } = this.props
    const { dataSource, cargos } = this.state

    const candidato = dataSource
      .filter(({ key }) => key === id)
      .map(({ cargo, cpf, key }) => {
        let newCargos = cargo
        if (cargo.includes(',')) {
          newCargos = cargo.split(',').map((nomeCargo) => {
            return cargos
              .filter(({ cargo_nome }) => cargo_nome === nomeCargo)
              .map(({ cargo_id }) => cargo_id)[0]
          })
        } else {
          newCargos = cargos
            .filter(({ cargo_nome }) => cargo_nome === cargo)
            .map(({ cargo_id }) => cargo_id)[0]
        }
        return {
          cargo: newCargos,
          cpf,
          id: key,
        }
      })[0]

    if (Array.isArray(candidato.cargo)) {
      this.setState(
        (prevState) => ({
          modalStates: prevState.modalStates.map((elem, index) =>
            index === 2
              ? {
                  ...elem,
                  cargosEfetivar: cargos
                    .filter(({ cargo_id }) =>
                      candidato.cargo.includes(cargo_id)
                    )
                    .map(({ cargo_id, cargo_nome }) => {
                      return { cargo_id, cargo_nome }
                    }),
                  cpf: candidato.cpf,
                  id,
                }
              : elem
          ),
        }),
        () => openModal(2)
      )
    } else {
      efetivaCandidato(candidato).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Efetivado com sucesso!')
          this.initLoadingCandidatos()

          getCandidatos().then(({ response }) => {
            if (response.status === 200) {
              this.setState({
                dataSource: response.data.candidatos.map((candidato) =>
                  apiToFront({ candidato })
                ),
              })
            }
            this.endLoadingCandidatos()
          })
        }
      })
    }
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder="Buscar"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedRows,
    })
  }

  handleNextStepAddCandidato = () => {
    const { novoCandidatoCurrentStep } = this.state
    this.setState({ novoCandidatoCurrentStep: novoCandidatoCurrentStep + 1 })
  }

  handlePrevStepAddCandidato = () => {
    const { novoCandidatoCurrentStep } = this.state
    this.setState({ novoCandidatoCurrentStep: novoCandidatoCurrentStep - 1 })
  }

  handleCheckCEP = (value) => {
    this.setState({ fetchingCpf: true })
    cepPromise(value)
      .then((result) => {
        this.setState((prevState) => ({
          modalStates: prevState.modalStates.map((elem, index) =>
            index === 1
              ? {
                  ...elem,
                  logradouro: result.street,
                  ufid: result.state,
                  bairro: result.neighborhood,
                  cidadeid: result.city,
                  cep: value,
                }
              : elem
          ),
          fetchingCpf: false,
        }))
      })
      .catch((err) => {
        toast.error(err.message)
        this.setState((prevState) => ({
          modalStates: prevState.modalStates.map((elem, index) =>
            index === 1
              ? {
                  ...elem,
                  logradouro: undefined,
                  ufid: undefined,
                  bairro: undefined,
                  cidadeid: undefined,
                }
              : elem
          ),
        }))
      })
  }

  handleSelectCurriculo = (info) => {
    let fileList = [...info.fileList]
    fileList = fileList.slice(-1)

    if (fileList[0] && fileList[0].type !== 'application/pdf') {
      toast.error('Arquivo inválido. Por favor, selecione apenas arquivos .pdf')
      this.setState({ fileList: [] })
      return
    }

    this.setState({ fileList })

    const reader = new FileReader()
    let curriculo

    reader.onload = (e) => {
      curriculo = e.target.result
      this.handleChangeModal(1, {
        target: { name: 'curriculo', value: curriculo },
      })
    }

    if (fileList.length > 0) {
      reader.readAsDataURL(fileList[0].originFileObj)
    }
  }

  handleRemoveCurriculo = () => {
    this.setState({ fileList: [] })
    this.handleChangeModal(1, {
      target: { name: 'curriculo', value: undefined },
    })
  }

  handleSelectCargos = (cargos) => {
    if (cargos.length > 3) {
      toast.error('Você deve escolher no máximo 3 cargos')
      return false
    }
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === 1
          ? {
              ...elem,
              cargosEscolhidos: cargos,
            }
          : elem
      ),
    }))
  }

  handleSelectProcessos = (processo_id) => {
    this.setState({ processoSelected: processo_id })
  }

  render() {
    const {
      onTableDelete,
      onModalSubmit,
      openModal,
      closeModal,
      handleChangeModal,
      handleChangeCheckbox,
      onRemoveFile,
      onSelectChange,
      getColumnSearchProps,
      handleEfetivar,
      handleNextStepAddCandidato,
      handlePrevStepAddCandidato,
      handleCheckCEP,
      handleSelectCurriculo,
      handleSelectCargos,
      handleRemoveCurriculo,
    } = this

    const {
      showModals,
      dataSource,
      tableCount,
      modalStates,
      selectedRowKeys,
      cargos,
      error,
      novoCandidatoCurrentStep,
      fileList,
      fetchingCpf,
      loadingCandidatos,
      processos,
    } = this.state

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    }

    return (
      <CandidatosView
        openModal={openModal}
        closeModal={closeModal}
        onModalSubmit={onModalSubmit}
        showModals={showModals}
        modalStates={modalStates}
        handleChangeModal={handleChangeModal}
        onTableDelete={onTableDelete}
        dataSource={dataSource}
        tableCount={tableCount}
        rowSelection={rowSelection}
        onRemoveFile={onRemoveFile}
        getColumnSearchProps={getColumnSearchProps}
        handleEfetivar={handleEfetivar}
        handleChangeCheckbox={handleChangeCheckbox}
        cargos={cargos}
        error={error}
        novoCandidatoCurrentStep={novoCandidatoCurrentStep}
        handleNextStepAddCandidato={handleNextStepAddCandidato}
        handlePrevStepAddCandidato={handlePrevStepAddCandidato}
        handleCheckCEP={handleCheckCEP}
        handleSelectCurriculo={handleSelectCurriculo}
        fileList={fileList}
        handleSelectCargos={handleSelectCargos}
        handleRemoveCurriculo={handleRemoveCurriculo}
        fetchingCpf={fetchingCpf}
        loadingCandidatos={loadingCandidatos}
        processos={processos}
      />
    )
  }
}

export default CandidatosController
