import { Select, Input, Button } from 'antd'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getGruposDisponiveis, insertSubCultura } from '../Api/api'

const { Option } = Select

const App = (props) => {
  const [times, setTimes] = useState('')
  const [isLoading, setLoading] = useState(true)
  const [descricao, setDescricao] = useState('')
  const [grupoId, setGrupoId] = useState('')

  useEffect(() => {
    async function getTimes() {
      const response = await getGruposDisponiveis()
      setTimes(response.grupos)
      setLoading(false)
    }

    getTimes()
  }, [])

  const handleChange = (value) => {
    setGrupoId(value)
  }

  const saveNewSubCulture = async () => {
    const newObj = {
      emp_id: props.cultura.emp_id,
      conta_id: props.cultura.conta_id,
      cultura_id: props.cultura.cultura_id,
      descricao,
    }
    const response = await insertSubCultura(newObj)
    if (response.success) {
      toast.success('Cadastrado com sucesso')
      props.updateSubCultura()
      props.closeModal()
    } else toast.error(response.message)
  }

  return (
    <div style={{ minWidth: 200 }}>
      <p>Descrição da SubCultura</p>
      <Input
        showCount
        rows={4}
        placeholder="Descrição"
        maxLength={255}
        onChange={(res) => setDescricao(res.currentTarget.value)}
        value={descricao}
      />
      <Button
        onClick={saveNewSubCulture}
        type="primary"
        className="btn_purple newCultureBtn"
      >
        Cadastrar SubCultura
      </Button>
    </div>
  )
}

export default App
