import React from 'react'

import { Chart, Axis, Tooltip, Coordinate, Legend, Interval } from 'bizcharts'

import { Empty } from 'antd'

import DataSet from '@antv/data-set'

import { sortChartData, colors } from '../../../../utils/utils'

import { cols } from '../../constants'

export default function PieChart({ data, transformPercent }) {
  const maxDataValue = data
    ? data.reduce((prev, next) => {
        if (parseFloat(next.VALOR) > prev) {
          prev = parseFloat(next.VALOR)
        }
        return prev
      }, 0)
    : 0

  let dv
  data.forEach((item) => {
    item.item = item.TEXTO
    item.VALOR = parseFloat(item.VALOR)
  })
  if (!transformPercent) {
    data = sortChartData(data)
    data.forEach((item) => {
      item.percent = parseFloat(item.VALOR)
    })
    dv = data
  } else {
    const ds = new DataSet()
    dv = ds.createView().source(data).transform({
      type: 'percent',
      field: 'VALOR',
      dimension: 'item',
      as: 'percent',
    })
  }

  return (
    <>
      {data && data.length > 0 && maxDataValue > 0 ? (
        <div style={{ height: 'auto' }}>
          <Chart height={300} data={dv} scale={cols} autoFit>
            <Coordinate type="theta" radius={0.65} />
            <Legend position="bottom" />
            <Tooltip showTitle={false} />
            <Interval
              adjust="stack"
              position="percent"
              color={['item', colors]}
              style={{
                lineWidth: 1,
                stroke: '#fff',
              }}
              label={[
                'percent',
                (percent) => {
                  return parseFloat(percent) > 0 ? percent : null
                },
              ]}
            />
          </Chart>
        </div>
      ) : (
        <Empty />
      )}
    </>
  )
}
