import React from 'react'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import {
  Row,
  Col,
  Card,
  Modal,
  Empty,
  Tabs,
  Tooltip,
  Button as ButtonAntd,
} from 'antd'

import { TeamOutlined, AimOutlined, DashboardOutlined } from '@ant-design/icons'

import {
  Chart,
  Interval,
  Line,
  Point,
  Axis,
  Coordinate,
  Legend,
} from 'bizcharts'
import DataSet from '@antv/data-set'

// Components

// Modals
import CulturaConsensuada from './components/culturaConsensuada'

// CSS
import './ObjetivosEstrategicos.css'

// Constants

// Utils
import { arqRange, colors, forcasRange } from '../../utils/utils'

// Services
import { getContaURL } from '../../services/auth'

import history from '../../utils/history'

const { Meta } = Card

const { TabPane } = Tabs

const cols = {
  percent: {
    min: 0,

    formatter(val) {
      return `${(val * 100).toFixed(2)}%`
    },
  },
}

function ObjetivosEstrategicosView({
  dataForcasEstrategicas,
  dataCulturaDesejada,
  onboarding = false,
  modalStates,
  showModals,
  openModal,
  closeModal,
  handleChangeModal,
  resetModal,
  onModalSubmit,
  error,
  gestao,
  olhares,
  handleDeleteCulturaConsensuada,
}) {
  const ds = new DataSet()
  const dvGestao = ds
    .createView()
    .source(gestao)
    .transform({
      type: 'percent',
      field: 'Valor',
      dimension: 'Tipo',
      groupBy: ['Label'],
      as: 'percent',
    })
  const dvOlhares = ds
    .createView()
    .source(olhares)
    .transform({
      type: 'percent',
      field: 'Valor',
      dimension: 'Tipo',
      groupBy: ['Label'],
      as: 'percent',
    })

  return (
    <>
      <Helmet>
        <title>TeamHub | Cultura Desejada</title>
      </Helmet>
      <div className="objetivos-estrategicos">
        <Tabs
          defaultActiveKey="1"
          style={{
            borderRadius: '2px',
            borderTop: 'none',
          }}
          onTabClick={(tab) => {
            tab === '2' &&
              history.push(`/${getContaURL()}/cultura-desejada/avaliadores`)
            tab === '3' &&
              history.push(
                `/${getContaURL()}/cultura-desejada/objetivos-estrategicos`
              )
          }}
        >
          {!onboarding && (
            <TabPane key="1">
              <Row gutter={40}>
                <Col md={24} xxl={8} xs={24} sm={24}>
                  <Card
                    type="inner"
                    title={
                      <span>
                        <b>Forças estratégicas</b>
                      </span>
                    }
                    className="objetivos-estrategicos__forcas-estrategicas objetivos-estrategicos__card"
                  >
                    <Chart
                      height={400}
                      data={dataForcasEstrategicas.sort(function (a, b) {
                        if (a.Tipo < b.Tipo) {
                          return -1
                        }
                        if (a.Tipo > b.Tipo) {
                          return 1
                        }
                        return 0
                      })}
                      scale={forcasRange}
                      autoFit
                    >
                      <Legend />
                      <Axis name="Tipo" />
                      <Axis name="Valor" />
                      <Tooltip
                        crosshairs={{
                          type: 'y',
                        }}
                      />
                      <Interval
                        adjust={[
                          {
                            type: 'dodge',
                            marginRatio: 1 / 32,
                          },
                        ]}
                        position="Tipo*Valor"
                        color={['Label', colors]}
                      />
                    </Chart>
                  </Card>
                </Col>
                <Col md={24} xxl={8} xs={24} sm={24}>
                  <Card
                    type="inner"
                    title={
                      <span>
                        <b>Gestão</b>
                      </span>
                    }
                    className="objetivos-estrategicos__forcas-estrategicas objetivos-estrategicos__card"
                  >
                    <Row>
                      <Col xs={24} sm={24} md={12}>
                        <div className="objetivos-estrategicos__modelo-gesta__container">
                          <span className="objetivos-estrategicos__modelo-gestao__title">
                            Modelo de Gestão
                          </span>
                          {gestao.length === 0 ? (
                            <Empty description={false} />
                          ) : (
                            <Chart
                              height={364}
                              data={dvGestao.rows.sort(function (a, b) {
                                if (a.Label < b.Label) {
                                  return -1
                                }
                                if (a.Label > b.Label) {
                                  return 1
                                }
                                return 0
                              })}
                              scale={cols}
                              autoFit
                            >
                              <Legend />
                              <Axis name="Label" />
                              <Axis name="percent" />
                              <Tooltip />
                              <Interval
                                adjust="stack"
                                position="Label*percent"
                                color={['Tipo', colors]}
                              />
                            </Chart>
                          )}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <div className="objetivos-estrategicos__modelo-gesta__container">
                          <span className="objetivos-estrategicos__modelo-gestao__title">
                            Olhar para o Ambiente
                          </span>
                          {olhares.length === 0 ? (
                            <Empty description={false} />
                          ) : (
                            <Chart
                              height={364}
                              data={dvOlhares.rows.sort(function (a, b) {
                                if (a.Label < b.Label) {
                                  return -1
                                }
                                if (a.Label > b.Label) {
                                  return 1
                                }
                                return 0
                              })}
                              scale={cols}
                              autoFit
                            >
                              <Legend />
                              <Axis name="Label" />
                              <Axis name="percent" />
                              <Tooltip />
                              <Interval
                                adjust="stack"
                                position="Label*percent"
                                color={['Tipo', colors]}
                              />
                            </Chart>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Modal
                visible={showModals.culturaConsenso}
                title={`AJUSTAR CULTURA - Percentagem atual: ${parseFloat(
                  modalStates.culturaConsenso.currentPercentage
                )}%`}
                destroyOnClose
                centered
                onCancel={() => closeModal('culturaConsenso')}
                onOk={() => onModalSubmit('culturaConsenso')}
                width="94%"
                style={{ maxWidth: '1000px' }}
                footer={[
                  modalStates.culturaConsenso.hasCulturaConsensuada ? (
                    <ButtonAntd
                      type="primary"
                      danger
                      key="delete"
                      onClick={() => handleDeleteCulturaConsensuada()}
                    >
                      EXCLUIR AJUSTE
                    </ButtonAntd>
                  ) : null,
                  <ButtonAntd
                    type="secondary"
                    key="close"
                    onClick={() => closeModal('culturaConsenso')}
                  >
                    CANCELAR
                  </ButtonAntd>,
                  <ButtonAntd
                    type="primary"
                    key="submit"
                    onClick={() => onModalSubmit('culturaConsenso')}
                  >
                    SALVAR
                  </ButtonAntd>,
                ]}
              >
                <CulturaConsensuada
                  id="culturaConsenso"
                  handleChangeModal={(event) =>
                    handleChangeModal('culturaConsenso', event)
                  }
                  resetModal={resetModal}
                  error={error}
                  tooltipVisible={showModals.culturaConsenso}
                  {...modalStates.culturaConsenso}
                />
              </Modal>
            </TabPane>
          )}
        </Tabs>
      </div>
    </>
  )
}

export default injectIntl(ObjetivosEstrategicosView)
