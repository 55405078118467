import styled from 'styled-components'
import { Row, Col } from 'antd'

export const EstatisticasLine = styled(Row)``

export const EstatisticasRow = styled(Row)``

export const EstatisticasCol = styled(Col)`
  display: flex;
  justify-content: center;

  .ant-statistic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-statistic .ant-statistic-content {
    color: var(--orange);
    font-weight: 500;
    font-size: 28px;
  }

  .ant-statistic .ant-statistic-title {
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
  }
`
