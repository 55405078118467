// Redux types
import * as constants from '../constants/constantsObjetivosEstrategicos'

export const getGestores = (data) => {
  return {
    type: constants.GESTORES_GET,
    api: {
      method: 'get',
      url: constants.URL_GESTORES_GET + '?page=1&page_limit=100000',
    },
  }
}

export const postGestor = ({
  nome,
  email,
  sexo,
  cpf,
  peso,
  cargo,
  pes_id,
  filial,
  grupo,
  enviarEmails = false,
}) => {
  return {
    type: constants.GESTOR_POST,
    api: {
      method: 'post',
      url: pes_id
        ? constants.URL_GESTOR_POST(pes_id)
        : constants.URL_GESTOR_POST(),
      ...(pes_id
        ? { data: { enviarEmails, pes_id } }
        : {
            data: {
              pes_nome: nome,
              pes_email: email,
              pes_sexo: sexo,
              pes_cpf: cpf,
              peso,
              cargo: cargo,
              filial,
              grupo,
              cadastrar: true,
              enviarEmails,
            },
          }),
    },
  }
}

export const updateGestor = ({ pes_id, peso, filial_id }) => {
  return {
    type: constants.GESTOR_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_GESTOR_UPDATE(pes_id),
      data: { peso, filial_id },
    },
  }
}

export const deleteGestor = ({ id }) => {
  return {
    type: constants.GESTOR_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_GESTOR_DELETE}/${id}`,
    },
  }
}

export const getPesos = () => {
  return {
    type: constants.PESOS_GET,
    api: {
      method: 'get',
      url: constants.URL_PESOS_GET,
    },
  }
}

export const enviarEmailGestores = ({ id } = {}) => {
  return {
    type: constants.ENVIAR_EMAILS_POST,
    api: {
      method: 'post',
      url: `${constants.URL_ENVIAR_EMAILS_POST}/${id || ''}`,
    },
  }
}

export const getCulturaDesejada = () => {
  return {
    type: constants.CULTURA_DESEJADA_GET,
    api: {
      method: 'get',
      url: constants.URL_CULTURA_DESEJADA_GET,
    },
  }
}

/* export const getCulturas = () => {
	return {
		type: constants.GET_OBJETIVOS_ESTRATEGICOS_CULTURAS,
		api: {
			method: 'get',
			url: constants.URL_OBJETIVOS_ESTRATEGICOS_CULTURAS,
		},
	};
};
*/

export const getObjetivos = () => {
  return {
    type: constants.OBJETIVOS_ESTRATEGICOS_LIST_OBJETIVO,
    api: {
      method: 'get',
      url: constants.URL_OBJETIVOS_ESTRATEGICOS_LIST_OBJETIVO,
    },
  }
}

export const getObjetivosTeamhub = () => {
  return {
    type: constants.OBJETIVOS_ESTRATEGICOS_LIST_OBJETIVOS_TEAMHUB,
    api: {
      method: 'get',
      url: constants.URL_OBJETIVOS_ESTRATEGICOS_LIST_OBJETIVOS_TEAMHUB,
    },
  }
}

export const storeObjetivo = (data) => {
  return {
    type: constants.OBJETIVOS_ESTRATEGICOS_STORE_OBJETIVO,
    api: {
      method: 'post',
      url: constants.URL_OBJETIVOS_ESTRATEGICOS_STORE_OBJETIVO,
      data: { ...data },
    },
  }
}

export const updateObjetivo = (id, data) => {
  return {
    type: constants.OBJETIVOS_ESTRATEGICOS_UPDATE_OBJETIVO,
    api: {
      method: 'put',
      url: constants.URL_OBJETIVOS_ESTRATEGICOS_UPDATE_OBJETIVO(id),
      data: { ...data },
    },
  }
}

export const deleteObjetivo = (id) => {
  return {
    type: constants.OBJETIVOS_ESTRATEGICOS_DELETE_OBJETIVO,
    api: {
      method: 'delete',
      url: constants.URL_OBJETIVOS_ESTRATEGICOS_DELETE_OBJETIVO(id),
    },
  }
}

export const getColaboradores = () => {
  return {
    type: constants.OBJETIVOS_ESTRATEGICOS_GET_COLABORADORES,
    api: {
      method: 'get',
      url: constants.URL_OBJETIVOS_ESTRATEGICOS_GET_COLABORADORES,
    },
  }
}

export const postCulturaConsensuada = ({
  regra,
  complacencia,
  aprendizado,
  cla,
  colaboracao,
  empreendedora,
  inovacao,
  autoridade,
  mercado,
  cliente,
  operacao,
  interno,
  olhar_externo,
  olhar_interno,
  controle,
  flexibilidade,
  regra_limite,
  complacencia_limite,
  aprendizado_limite,
  cla_limite,
  colaboracao_limite,
  empreendedora_limite,
  inovacao_limite,
  autoridade_limite,
}) => {
  return {
    type: constants.OBJETIVOS_ESTRATEGICOS_POST_CULTURA_CONSENSUADA,
    api: {
      method: 'post',
      url: constants.URL_OBJETIVOS_ESTRATEGICOS_POST_CULTURA_CONSENSUADA,
      data: {
        regra,
        complacencia,
        aprendizado,
        cla,
        colaboracao,
        empreendedora,
        inovacao,
        autoridade,
        mercado,
        cliente,
        operacao,
        interno,
        olhar_externo,
        olhar_interno,
        controle,
        flexibilidade,
        regra_limite,
        complacencia_limite,
        aprendizado_limite,
        cla_limite,
        colaboracao_limite,
        empreendedora_limite,
        inovacao_limite,
        autoridade_limite,
      },
    },
  }
}

export const deleteCulturaConsensuada = () => {
  return {
    type: constants.URL_OBJETIVOS_ESTRATEGICOS_DELETE_CULTURA_CONSENSUADA,
    api: {
      method: 'delete',
      url: constants.URL_OBJETIVOS_ESTRATEGICOS_DELETE_CULTURA_CONSENSUADA,
    },
  }
}

// Listar grupos

export const getTimes = () => {
  return {
    type: constants.TIMES_GET,
    api: {
      method: 'get',
      url: constants.URL_TIMES_GET,
    },
  }
}

// Listar filiais

// Filiais
export const getFiliais = () => {
  return {
    type: constants.PS_FILIAL_LIST,
    api: {
      method: 'get',
      url: constants.URL_PS_FILIAL,
    },
  }
}

// Listar times pessoas

export const getTimesPessoas = () => {
  return {
    type: constants.TIMES_PESSOAS_GET,
    api: {
      method: 'get',
      url: constants.URL_TIMES_PESSOAS_GET,
    },
  }
}
