import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import { PlusOutlined } from '@ant-design/icons'
import { Row, Col, Divider, Modal, Checkbox } from 'antd'

// Components
import Input, { Date, MaskedInput } from '../../../../components/input/Input'
import Select, { Option } from '../../../../components/select/Select'

// CSS
import './EditarCandidato.css'

// Constants

function EditarCandidato({
  intl,
  handleChange,
  showModal,
  onSubmit,
  closeModal,
  cpf,
  nome,
  dataNascimento,
  email,
  sexo,
  grupo,
  telefone,
  cargo,
  isOnboarding = false,
  pcdDefault,
  pcd_tipo,
  cid,
}) {
  const [pessoaComDeficiencia, setPessoaComDeficiencia] = useState(
    pcdDefault === 1
  )

  return (
    <Modal
      visible={showModal}
      title="Editar Candidato"
      cancelText="CANCELAR"
      okText="SALVAR"
      onCancel={closeModal}
      onOk={onSubmit}
      destroyOnClose
    >
      <div className="adicionar-colaboradores">
        <Row gutter={20}>
          <Col md={10}>
            <MaskedInput
              label="CPF"
              mask="999.999.999-99"
              name="cpf"
              defaultValue={cpf}
              onChange={handleChange}
            />
          </Col>
          <Col md={14}>
            <Input
              label="Nome"
              type="text"
              placeholder="Digite o nome"
              name="nome"
              defaultValue={nome}
              onChange={handleChange}
            />
          </Col>
          <Col md={10}>
            <Date
              label="Data de Nascimento"
              placeholder="Digite o nascimento"
              name="dataNascimento"
              defaultValue={
                moment(dataNascimento, 'DD/MM/YYYY').isValid() &&
                moment(dataNascimento, 'DD/MM/YYYY')
              }
              onChange={(date) =>
                handleChange({
                  target: { name: 'dataNascimento', value: date },
                })
              }
            />
          </Col>
          <Col md={14}>
            <Input
              label="E-mail"
              type="text"
              placeholder="Digite o e-mail"
              name="email"
              defaultValue={email}
              onChange={handleChange}
            />
          </Col>
          <Col md={10}>
            <Select
              label="Sexo"
              placeholder="Insira o sexo"
              defaultValue={sexo}
              name="sexo"
              onChange={(value) =>
                handleChange({ target: { name: 'sexo', value } })
              }
              style={{ width: '100%' }}
            >
              <Option value="M">Masculino</Option>
              <Option value="F">Feminino</Option>
            </Select>
          </Col>
          {isOnboarding && (
            <Col md={14}>
              <Select
                label="Grupo"
                placeholder="Selecione o grupo"
                name="grupo"
                // onChange={handleChange}
                style={{ width: '100%' }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={{ padding: '8px', cursor: 'pointer' }}>
                      <PlusOutlined /> Novo Grupo
                    </div>
                  </div>
                )}
              >
                <Option value="grupo1">Analista de marketing</Option>
                <Option value="grupo2">Analista de vendas</Option>
                <Option value="grupo3">Analista de logistica</Option>
              </Select>
            </Col>
          )}
          <Col md={isOnboarding ? 10 : 14}>
            <MaskedInput
              label={intl.formatMessage({
                id: 'common.telefone',
              })}
              mask="(99) 99999-9999"
              name="telefone"
              defaultValue={telefone}
              onChange={handleChange}
            />
          </Col>

          <Col md={24}>
            <Checkbox
              name="pcd"
              defaultChecked={pcdDefault === 1}
              onChange={({ target }) => {
                handleChange(
                  {
                    target: {
                      name: 'pcd',
                      value: target.checked ? 1 : -1,
                    },
                  },
                  setPessoaComDeficiencia(target.checked)
                )
              }}
              style={{ marginTop: 5 }}
            >
              É pessoa com deficiência (PCD)?
            </Checkbox>
          </Col>

          {pessoaComDeficiencia && (
            <>
              <Col xs={12} md={12} lg={12}>
                <Select
                  label="Tipo de PCD"
                  // defaultValue={pcd_tipo}
                  placeholder="Selecione o tipo"
                  name="pcd_tipo"
                  onChange={(value) => {
                    handleChange({
                      target: { name: 'pcd_tipo', value },
                    })
                  }}
                  style={{ width: '100%' }}
                  defaultValue="Física"
                >
                  <Option value="Física">Física</Option>
                  <Option value="Auditiva">Auditiva</Option>
                  <Option value="Visual">Visual</Option>
                  <Option value="Mental">Mental</Option>
                  <Option value="Múltipla">Múltipla</Option>
                </Select>
              </Col>
              <Col xs={12} md={12} lg={12}>
                <Input
                  label="CID"
                  type="text"
                  defaultValue={cid}
                  placeholder="Digite o CID"
                  name="cid"
                  onChange={handleChange}
                />
              </Col>
            </>
          )}
          {isOnboarding && (
            <Col md={14}>
              <Select
                label="Cargo"
                placeholder="Selecione o cargo"
                name="cargo"
                // onChange={handleChange}
                style={{ width: '100%' }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={{ padding: '8px', cursor: 'pointer' }}>
                      <PlusOutlined /> Novo Cargo
                    </div>
                  </div>
                )}
              >
                <Option value="cargo1">Analista de vendas</Option>
                <Option value="cargo2">Analista de logistica</Option>
              </Select>
            </Col>
          )}
        </Row>
      </div>
    </Modal>
  )
}

export default injectIntl(EditarCandidato)
