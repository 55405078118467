import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { ExclamationCircleFilled } from '@ant-design/icons'

import { Divider, Popconfirm, Button } from 'antd'

import { sorterString, sorterDate } from '../../../../utils/utils'
import { ButtonsContainer, TableContainer, Table } from './styles'

export default function AcoesTable({
  pdt,
  meta,
  data,
  getColumnSearchProps,
  handleDeleteAcao,
  handleCompleteAcao,
  openModal,
  editModal,
  isPDTCompleted,
}) {
  const { pdt_id } = pdt
  const columns = [
    {
      title: 'Ação',
      dataIndex: 'descricao',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'descricao'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('descricao'),
    },
    {
      title: 'Início',
      dataIndex: 'start',
      editable: true,
      sorter: (a, b) => sorterDate(a, b, 'inicio', 'DD/MM/YYYY (HH:mm)'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => (text ? moment(text).format('DD/MM/YYYY (HH:mm)') : ''),
      ...getColumnSearchProps('start'),
    },
    {
      title: 'Fim',
      dataIndex: 'end',
      editable: true,
      sorter: (a, b) => sorterDate(a, b, 'fim', 'DD/MM/YYYY (HH:mm)'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => (text ? moment(text).format('DD/MM/YYYY (HH:mm)') : ''),
      ...getColumnSearchProps('end'),
    },
    {
      title: 'Sabotadores',
      dataIndex: 'sabotadores',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'sabotadores'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('sabotadores'),
    },
    {
      title: 'Opções',
      dataIndex: 'opcoes',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'opcoes'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('opcoes'),
    },
    !isPDTCompleted
      ? {
          title: 'Ações',
          key: 'acoes',
          className: 'column-acoes',
          width: '25%',
          render: (text, record) => {
            return data.length >= 1 ? (
              <span>
                <a
                  href="javascript:;"
                  onClick={() => editModal(1, pdt, meta, record.acao_id)}
                >
                  Editar
                </a>
                <Divider type="vertical" />
                <Popconfirm
                  title="Tem certeza que deseja excluir essa ação?"
                  onConfirm={() =>
                    handleDeleteAcao(pdt_id, record.meta_id, record.acao_id)
                  }
                  okText="Sim"
                  cancelText="Não"
                  icon={
                    <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                  }
                >
                  <a href="javascript:;">Excluir</a>
                </Popconfirm>
                {!record.completed_at && (
                  <>
                    <Divider type="vertical" />
                    <Popconfirm
                      title="Tem certeza que deseja concluir essa ação?"
                      onConfirm={() =>
                        handleCompleteAcao(
                          pdt_id,
                          record.meta_id,
                          record.acao_id
                        )
                      }
                      okText="Sim"
                      cancelText="Não"
                      icon={
                        <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                      }
                    >
                      <a href="javascript:;">Concluir</a>
                    </Popconfirm>
                  </>
                )}
              </span>
            ) : null
          },
        }
      : {},
  ]
  return (
    <TableContainer>
      <Table
        title={() =>
          !isPDTCompleted && (
            <ButtonsContainer>
              <a />
              <Button
                type="primary"
                onClick={() => openModal(0, { pdt, meta })}
              >
                NOVA AÇÃO
              </Button>
            </ButtonsContainer>
          )
        }
        dataSource={data}
        columns={columns}
        isDeleble
      />
    </TableContainer>
  )
}
