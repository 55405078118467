import styled, { css } from 'styled-components'

import { Card } from 'antd'

import { Row, Col, Space } from 'antd'

export const FlexCard = styled(Card)`
  height: 100%;

  .ant-card-body {
    ${(props) =>
      props.noPadding &&
      css`
        padding: 0;
      `}

    ${(props) =>
      props.center &&
      css`
        justify-content: center;
      `}

  height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .bizcharts {
      height: 100% !important;
    }

    > .ant-statistic {
      .ant-statistic-title {
        min-height: 45px;
      }

      flex: 1;
      padding: 0px 16px;
      width: 50%;
      min-width: 50%;

      @media (min-width: 768px) {
        width: 33%;
        min-width: 33%;
      }

      @media (min-width: 1500px) {
        width: 16.6%;
        min-width: 16.6%;
      }
    }
  }
`

export const EstatisticasLine = styled(Row)``

export const EstatisticasRow = styled(Row)``

export const EstatisticasCol = styled(Col)`
  display: flex;
  justify-content: center;

  .ant-statistic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-statistic .ant-statistic-content {
    color: var(--orange);
    font-weight: 500;
    font-size: 28px;
  }

  .ant-statistic .ant-statistic-title {
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
  }
`

export const RangeContainer = styled(Space)`
  display: flex;
  width: 100%;
  margin-top: 24px;
  justify-content: space-between;
`
