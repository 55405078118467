// URLs
export const URL_CARGOS = `${process.env.REACT_APP_API_URL}/cargo`
export const URL_CARGOS_GET = `${URL_CARGOS}/list`

export const URL_CARGO_PESSOAS = `${process.env.REACT_APP_API_URL}/cargoPessoa`
export const URL_CARGO_CANDIDATOS = `${process.env.REACT_APP_API_URL}/cargoCandidato`

export const URL_CARGO_PESSOAS_GET = `${URL_CARGO_PESSOAS}/list/`
export const URL_CARGO_PESSOA_POST = `${URL_CARGO_PESSOAS}/store/`
export const URL_CARGO_CANDIDATO_POST = `${URL_CARGO_CANDIDATOS}/store/`
export const URL_CARGO_PESSOA_DELETE = `${URL_CARGO_PESSOAS}/delete/`
export const URL_CARGO_CANDIDATO_DELETE = `${URL_CARGO_CANDIDATOS}/delete/`

export const URL_CARGO_CULTURA_DESEJADA_CARGO = (cargo_id) =>
  `${URL_CARGOS}/${cargo_id}/culturaDesejada`
export const URL_CARGO_CULTURA_INSTALADA_CARGO = (cargo_id) =>
  `${URL_CARGOS}/${cargo_id}/culturaInstalada`

// Redux types
export const CARGOS_GET = 'CARGOS_GET'
export const CARGO_PESSOAS_GET = 'CARGO_PESSOA_GET'
export const CARGO_PESSOA_POST = 'CARGO_PESSOA_POST'
export const CARGO_PESSOA_DELETE = 'CARGO_PESSOA_DELETE'
export const CARGO_CANDIDATO_POST = 'CARGO_CANDIDATO_POST'
export const CARGO_CANDIDATO_DELETE = 'CARGO_CANDIDATO_DELETE'
export const CARGO_CULTURA_DESEJADA_CARGO = 'CARGO_CULTURA_DESEJADA_CARGO'
export const CARGO_CULTURA_INSTALADA_CARGO = 'CARGO_CULTURA_INSTALADA_CARGO'

export const dataSource = [
  {
    cpf: '116.116.166-06',
    nome: 'Audrey Malone Alencar Oliveira',
    email: 'audreymoliveira@gmail.com',
    entrada: '13/07/2019',
    saida: '13/07/2019',
    key: 1,
  },
  {
    cpf: '116.116.166-06',
    nome: 'Shamanta Telles de Souza Silva',
    email: 'audreymoliveira@gmail.com',
    entrada: '13/07/2019',
    saida: '13/07/2019',
    key: 2,
  },
  {
    cpf: '116.116.166-06',
    nome: 'Ricardo Alves da Silva',
    email: 'audreymoliveira@gmail.com',
    entrada: '13/07/2019',
    saida: '13/07/2019',
    key: 3,
  },
  {
    cpf: '116.116.166-06',
    nome: 'Adryelle Oliveira Bastos',
    email: 'audreymoliveira@gmail.com',
    entrada: '13/07/2019',
    saida: '13/07/2019',
    key: 4,
  },
]

// Data processing
export const apiToFrontCargo = ({ cargo }) => ({
  nome: cargo.cargo_nome,
  cbo: cargo.cargo_cbo,
  atividades: cargo.cargo_atividades,
  descricao: cargo.cargo_descricao,
  piso: cargo.cargo_piso,
  ativo: cargo.cargo_ativo,
  datacriacao: cargo.cargo_datacriacao,
  dataexclusao: cargo.cargo_dataexclusao,
  id: cargo.cargo_id,
})

export const apiToFrontCargoPessoa = ({ pessoa }) => ({
  ...pessoa,
  nome: pessoa.pes_nome || '',
  email: pessoa.pes_email || '',
  cpf: pessoa.pes_cpf || '',
  id: pessoa.pes_id,
  entrada: pessoa.cargopes_dataentrada || '-',
  saida: pessoa.cargopes_datasaida || '-',
})
