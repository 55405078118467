import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'

import { Typography, Alert, Col, Spin } from 'antd'

import Apresentacao from '../../../FormularioQuiz/components/Apresentacao'

import Questoes from '../../../FormularioQuiz/components/Questoes'

import {
  FormularioQuizHeader,
  CompanyLogo,
  Loading,
  Container,
  Placeholder,
} from '../../../FormularioQuiz/styles'

import { loadCompanyLogo } from '../../../FormularioQuiz/actions/actionsFormularioQuiz'

const { Title } = Typography

function PreviewFinal() {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  const [companyLogo, setCompanyLogo] = useState(null)

  const [informacoesQuiz, setInformacoesQuiz] = useState(null)

  const [questoesQuiz, setQuestoesQuiz] = useState(null)

  const [showApresentacao, setShowApresentacao] = useState(true)

  const [showQuestoes, setShowQuestoes] = useState(false)

  const [showEncerramento, setShowEncerramento] = useState(false)

  const [showTimeout, setShowTimeout] = useState(false)

  const [questaoAtual, setQuestaoAtual] = useState(null)

  const [tempoLimite, setTempoLimite] = useState(null)

  const handleIniciar = () => {
    setShowApresentacao(false)
    setShowQuestoes(true)
  }

  const handleEncerrar = () => {
    setShowQuestoes(false)
    setShowEncerramento(true)
  }

  const handleTimeout = () => {
    setShowQuestoes(false)
    setShowTimeout(true)
  }

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('@TeamHubGB:teste_preview'))

    const {
      instrucoes,
      msg_encerramento,
      nota_min,
      objetivo,
      tempo_limite,
      titulo,
      exibir_resultado,
    } = data

    setInformacoesQuiz({
      instrucoes,
      msg_encerramento,
      nota_min,
      objetivo,
      tempo_limite,
      titulo,
      exibir_resultado,
    })

    const { questoes } = data

    setQuestoesQuiz(questoes)

    dispatch(loadCompanyLogo()).then(({ response }) => {
      if (response.status === 200) {
        const logoParam = response.data.filter((item) => item.param === 'logo')
        setCompanyLogo(logoParam[0].customParam.url)
      }
    })

    setLoading(false)
  }, [dispatch, companyLogo])

  return (
    <>
      {loading ? (
        <Loading>
          <Spin size="large" />
        </Loading>
      ) : (
        <>
          <FormularioQuizHeader justify="space-between" align="middle">
            <CompanyLogo src={companyLogo} small />
            <span>
              Este é um preview do teste. Recarregue para ver novas alterações.
            </span>
            <p>Teste por TeamHub</p>
          </FormularioQuizHeader>

          <Col xs={{ offset: 1, span: 22 }} md={{ offset: 2, span: 20 }}>
            {showApresentacao && (
              <Apresentacao
                preview
                companyLogo={companyLogo}
                informacoesQuiz={informacoesQuiz}
                handleIniciar={handleIniciar}
              />
            )}

            {showQuestoes && (
              <Questoes
                preview
                questoesQuiz={questoesQuiz}
                tempo_limite={informacoesQuiz.tempo_limite}
                handleEncerrar={handleEncerrar}
                handleTimeout={handleTimeout}
              />
            )}

            {showEncerramento && (
              <Container>
                <CompanyLogo src={companyLogo} large />
                <Title level={2}>Fim de Teste</Title>

                {informacoesQuiz.msg_encerramento ? (
                  <p>{informacoesQuiz.msg_encerramento}</p>
                ) : (
                  <Placeholder>
                    A sua mensagem de encerramento irá aparecer aqui.
                  </Placeholder>
                )}

                {informacoesQuiz.exibir_resultado && (
                  <Placeholder>
                    A pontuação do candidato irá aparecer aqui
                  </Placeholder>
                )}

                {informacoesQuiz.exibir_resultado &&
                  informacoesQuiz.nota_min && (
                    <Placeholder>
                      O resultado (aprovado ou reprovado) irá aparecer aqui.
                    </Placeholder>
                  )}
              </Container>
            )}

            {showTimeout && (
              <Container>
                <CompanyLogo src={companyLogo} large />

                <Title level={2}>Teste encerrado</Title>

                <p>
                  O seu limite de tempo para a completude da avaliação chegou ao
                  fim, por isso, o seu teste foi encerrado.
                </p>
                <p>As questões respondidas foram enviadas.</p>
              </Container>
            )}
          </Col>

          <Col xs={{ offset: 1, span: 22 }} md={{ offset: 2, span: 20 }}>
            {showApresentacao && (
              <Alert
                type="info"
                showIcon
                message="Esse é um preview do teste. Navegue por ele interagindo como o candidato e recarregue a página para ver suas alterações."
              />
            )}

            {showQuestoes && !informacoesQuiz.tempo_limite && (
              <Alert
                type="info"
                showIcon
                message="O tempo limite de questão está sendo sugerido pois você não preencheu o campo de Tempo Limite. Preencha o campo de tempo limite e atualize para ver alterações."
              />
            )}

            {showQuestoes && informacoesQuiz.tempo_limite && (
              <Alert
                type="info"
                showIcon
                message="O tempo limite de cada questão corresponde ao tempo total inserido dividido pelo número de questões."
              />
            )}

            {showEncerramento && (
              <Alert
                type="info"
                showIcon
                message="Marcando o tempo Exibir Resultado, o candidato verá sua pontuação ao fim do teste. Caso você insira uma Nota Mínima, o candidato verá também se foi aprovado ou reprovado."
              />
            )}

            {showTimeout && (
              <Alert
                type="info"
                showIcon
                message="Caso o candidato ultrapasse o tempo delimitado para resposta, o teste é encerrado. As questões respondidas serão salvas e as demais não pontuadas."
              />
            )}
          </Col>
        </>
      )}
    </>
  )
}

export default PreviewFinal
