import React, { Component } from 'react'

import { toast } from 'react-toastify'

import _ from 'lodash'

// Components
import { SearchOutlined } from '@ant-design/icons'
import { Input, Button, message } from 'antd'
import ObjetivosEstrategicosView from './ObjetivosEstrategicosView'

// CSS

import { validateForm } from '../../utils/forms'

const formFields = [
  {
    name: 'cpf',
    required: true,
  },
  {
    name: 'nome',
    required: true,
  },
  {
    name: 'sexo',
    required: true,
  },
  {
    name: 'email',
    required: true,
  },
]

const escolherGestorFormFields = [{ name: 'pes_id', required: true }]

const objetivoFormFields = [
  { name: 'obj_id', required: true },
  { name: 'objetivo', required: true },
  { name: 'descricao', required: true },
]

class ObjetivosEstrategicosController extends Component {
  initialState = {
    modalStates: {
      novoGestor: {
        cpf: '',
        nome: '',
        email: '',
        sexo: 'M',
        cargo: '',
        peso: 1,
        time: 1,
        filial: undefined,
      },
      novoObjetivo: {
        obj_id: undefined,
        objetivo: '',
        descricao: '',
      },
      updateObjetivo: {
        obj_id: undefined,
        objetivo: '',
        descricao: '',
        obj_conta_id: null,
      },
      culturaConsenso: {
        cultura: [],
        forcasEstrategicas: [],
        // arquétipos
        regra: 0,
        regra_limite: 'min',
        autoridade: 0,
        autoridade_limite: 'min',
        inovacao: 0,
        inovacao_limite: 'min',
        empreendedora: 0,
        empreendedora_limite: 'min',
        complacencia: 0,
        complacencia_limite: 'min',
        aprendizado: 0,
        aprendizado_limite: 'min',
        cla: 0,
        cla_limite: 'min',
        colaboracao: 0,
        colaboracao_limite: 'min',
        // forcas estrategicas
        mercado: 0,
        cliente: 0,
        operacao: 0,
        interno: 0,
        // olhares
        olhar_externo: 0,
        olhar_interno: 0,
        controle: 0,
        flexibilidade: 0,
        //  controls
        currentPercentage: 0,
        hasCulturaConsensuada: false,
      },
      escolherGestor: {
        pes_id: undefined,
      },
    },
    showModals: {
      novoGestor: false,
      novoObjetivo: false,
      updateObjetivo: false,
      culturaConsenso: false,
      escolherGestor: false,
    },
    tableCount: 1,
    error: {},
  }

  state = this.initialState

  componentDidMount = () => {
    const {
      getGestores,
      getPesos,
      getTimesPessoas,
      getTimes,
      getFiliais,
      /* getCulturas, */
      getCulturaDesejada,
      getObjetivos,
      getObjetivosTeamhub,
      getColaboradores,
    } = this.props

    const promises = [
      getGestores(),
      getPesos(),
      getTimesPessoas(),
      getTimes(),
      getFiliais(),
      /* getCulturas(), */
      getCulturaDesejada(),
      getObjetivos(),
      getObjetivosTeamhub(),
      getColaboradores(),
    ]

    Promise.all(promises)
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      culturaDesejada: culturaDesejadaPrev,
      forcasEstrategicas: forcasEstrategicasPrev,
      limites: limitesPrev,
    } = prevProps

    const { culturaDesejada, forcasEstrategicas, limites } = this.props

    const { culturaConsenso } = this.state.modalStates

    const {
      cultura: culturaModal,
      forcasEstrategicas: forcasEstrategicasModal,
    } = culturaConsenso

    if (
      !_.isEqual(culturaDesejada, culturaDesejadaPrev) ||
      (!_.isEqual(culturaDesejada, culturaModal) && culturaModal.length === 0)
    ) {
      this.setState((prevState) => ({
        modalStates: {
          ...prevState.modalStates,
          culturaConsenso: {
            ...prevState.modalStates.culturaConsenso,
            cultura: culturaDesejada,
          },
        },
      }))
    }

    if (
      !_.isEqual(forcasEstrategicas, forcasEstrategicasPrev) ||
      (!_.isEqual(forcasEstrategicas, forcasEstrategicasModal) &&
        forcasEstrategicasModal.length === 0)
    ) {
      this.setState((prevState) => ({
        modalStates: {
          ...prevState.modalStates,
          culturaConsenso: {
            ...prevState.modalStates.culturaConsenso,
            forcasEstrategicas,
          },
        },
      }))
    }

    if (!_.isEqual(limites, limitesPrev)) {
      this.setState((prevState) => ({
        modalStates: {
          ...prevState.modalStates,
          culturaConsenso: {
            ...prevState.modalStates.culturaConsenso,
            ...limites,
          },
        },
      }))
    }
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  handleChangeModal = (id, { target }) => {
    let callback = null
    switch (id) {
      case 'culturaConsenso':
        if (!target.name.includes('limite')) {
          const validateCulturaNewValues =
            this.validateCulturaConsensuadaValues(target)
          if (!validateCulturaNewValues) return
          callback = () => this.handleCulturaConsensuada()
        } else {
          callback = () => console.log(this.state.modalStates.culturaConsenso)
        }
        break
      default:
        callback = () => true
        break
    }
    this.setState(
      (prevState) => ({
        modalStates: {
          ...prevState.modalStates,
          [id]: { ...prevState.modalStates[id], [target.name]: target.value },
        },
      }),
      callback
    )
  }

  validateCulturaConsensuadaValues = ({ name, value }) => {
    const {
      regra,
      complacencia,
      aprendizado,
      cla,
      colaboracao,
      empreendedora,
      inovacao,
      autoridade,
    } = this.state.modalStates.culturaConsenso

    const arquetipos = {
      regra,
      complacencia,
      aprendizado,
      cla,
      colaboracao,
      empreendedora,
      inovacao,
      autoridade,
    }

    const arquetiposFiltered = Object.keys(arquetipos).filter((a) => a !== name)

    const newValue = arquetiposFiltered.reduce(
      (acc, next) => acc + parseFloat(arquetipos[next]),
      value
    )

    if (newValue > 100) return false
    return true
  }

  handleCulturaConsensuada = () => {
    const {
      regra,
      complacencia,
      aprendizado,
      cla,
      colaboracao,
      empreendedora,
      inovacao,
      autoridade,
      cultura,
      forcasEstrategicas,
    } = this.state.modalStates.culturaConsenso

    const culturaFiltered = cultura.filter((c) => c.Label !== 'Ajustada')

    const newArquetipos = [
      { Tipo: 'Regra', Valor: regra, Label: 'Ajustada' },
      {
        Tipo: 'Complacência',
        Valor: complacencia,
        Label: 'Ajustada',
      },
      {
        Tipo: 'Aprendizado',
        Valor: aprendizado,
        Label: 'Ajustada',
      },
      { Tipo: 'Clã', Valor: cla, Label: 'Ajustada' },
      {
        Tipo: 'Colaboração',
        Valor: colaboracao,
        Label: 'Ajustada',
      },
      {
        Tipo: 'Empreendedora',
        Valor: empreendedora,
        Label: 'Ajustada',
      },
      {
        Tipo: 'Inovação',
        Valor: inovacao,
        Label: 'Ajustada',
      },
      {
        Tipo: 'Autoridade',
        Valor: autoridade,
        Label: 'Ajustada',
      },
    ]

    const currentPercentage = newArquetipos.reduce(
      (acc, next) => acc + parseFloat(next.Valor),
      0
    )

    const forcasFiltered = forcasEstrategicas.filter(
      (f) => f.Label !== 'Ajustada'
    )

    const forcas = {
      mercado: parseFloat(
        ((autoridade + inovacao + empreendedora + colaboracao) / 2).toFixed(1)
      ),
      cliente: parseFloat(
        ((empreendedora + colaboracao + cla + aprendizado) / 2).toFixed(1)
      ),
      interno: parseFloat(
        ((cla + aprendizado + complacencia + regra) / 2).toFixed(1)
      ),
      operacao: parseFloat(
        ((complacencia + regra + autoridade + inovacao) / 2).toFixed(1)
      ),
    }

    const forcasArray = [
      {
        Tipo: 'Mercado',
        Valor: forcas.mercado,
        Label: 'Ajustada',
      },
      {
        Tipo: 'Cliente',
        Valor: forcas.cliente,
        Label: 'Ajustada',
      },
      {
        Tipo: 'Ambiente Interno',
        Valor: forcas.interno,
        Label: 'Ajustada',
      },
      {
        Tipo: 'Operação',
        Valor: forcas.operacao,
        Label: 'Ajustada',
      },
    ]

    const olhares = {
      controle: parseFloat(
        ((forcas.operacao / (forcas.operacao + forcas.cliente)) * 100).toFixed(
          1
        )
      ),
      flexibilidade: parseFloat(
        ((forcas.cliente / (forcas.operacao + forcas.cliente)) * 100).toFixed(1)
      ),
      olhar_externo: parseFloat(
        ((forcas.interno / (forcas.interno + forcas.mercado)) * 100).toFixed(1)
      ),
      olhar_interno: parseFloat(
        ((forcas.mercado / (forcas.interno + forcas.mercado)) * 100).toFixed(1)
      ),
    }

    this.setState((prevState) => ({
      modalStates: {
        ...prevState.modalStates,
        culturaConsenso: {
          ...prevState.modalStates.culturaConsenso,
          cultura: [...culturaFiltered, ...newArquetipos],
          forcasEstrategicas: [...forcasFiltered, ...forcasArray],
          ...forcas,
          ...olhares,
          currentPercentage,
        },
      },
    }))
  }

  handleDeleteCulturaConsensuada = (id = 'culturaConsenso') => {
    const { deleteCulturaConsensuada, getCulturaDesejada } = this.props
    deleteCulturaConsensuada().then(({ response }) => {
      if (response.status === 200) {
        getCulturaDesejada()
        toast.success('Cultura Ajustada apagada com sucesso.')
        this.closeModal(id)
      }
    })
  }

  openModal = (id) => {
    this.setState((prevState) => ({
      showModals: {
        ...prevState.showModals,
        [id]: true,
      },
    }))

    if (id === 'culturaConsenso') {
      const { culturaDesejada } = this.props

      const { culturaConsenso } = this.state.modalStates

      if (culturaConsenso && culturaDesejada) {
        culturaConsenso.regra = culturaDesejada.find(
          (item) => item.Label === 'Ajustada' && item.Tipo === 'Regra'
        )?.Valor

        culturaConsenso.autoridade = culturaDesejada.find(
          (item) => item.Label === 'Ajustada' && item.Tipo === 'Autoridade'
        )?.Valor

        culturaConsenso.inovacao = culturaDesejada.find(
          (item) => item.Label === 'Ajustada' && item.Tipo === 'Inovação'
        )?.Valor

        culturaConsenso.empreendedora = culturaDesejada.find(
          (item) => item.Label === 'Ajustada' && item.Tipo === 'Empreendedora'
        )?.Valor

        culturaConsenso.complacencia = culturaDesejada.find(
          (item) => item.Label === 'Ajustada' && item.Tipo === 'Complacência'
        )?.Valor

        culturaConsenso.aprendizado = culturaDesejada.find(
          (item) => item.Label === 'Ajustada' && item.Tipo === 'Aprendizado'
        )?.Valor

        culturaConsenso.cla = culturaDesejada.find(
          (item) => item.Label === 'Ajustada' && item.Tipo === 'Clã'
        )?.Valor

        culturaConsenso.colaboracao = culturaDesejada.find(
          (item) => item.Label === 'Ajustada' && item.Tipo === 'Colaboração'
        )?.Valor

        culturaConsenso.currentPercentage = parseFloat(
          culturaConsenso.regra +
            culturaConsenso.autoridade +
            culturaConsenso.inovacao +
            culturaConsenso.empreendedora +
            culturaConsenso.complacencia +
            culturaConsenso.aprendizado +
            culturaConsenso.cla +
            culturaConsenso.colaboracao
        )
      }
    }
  }

  closeModal = (id) =>
    this.setState(
      (prevState) => ({
        showModals: {
          ...prevState.showModals,
          [id]: false,
        },
      }),
      () => this.resetModal(id)
    )

  resetModal = (id) =>
    this.setState((prevState) => ({
      modalStates: {
        ...prevState.modalStates,
        [id]: this.initialState.modalStates[id],
      },
      error: {},
    }))

  onModalSubmit = (id, { enviarEmails = false } = {}) => {
    const modalActions = {
      novoGestor: () => {
        const { postGestor, getGestores, getTimesPessoas } = this.props
        const { modalStates } = this.state

        const error = validateForm(formFields, modalStates[id])
        this.setState({ error: error.messages })

        if (error.hasError) return

        postGestor({ ...modalStates.novoGestor, enviarEmails }).then(
          ({ response }) => {
            if (response.status === 200) {
              getGestores()
              getTimesPessoas()
              toast.success('Gestor adicionado com sucesso')

              this.closeModal(id)
            } else toast.error('Ocorreu um erro ao adicionar gestor')
          }
        )
      },
      escolherGestor: () => {
        const { postGestor, getGestores } = this.props
        const { modalStates } = this.state

        const error = validateForm(escolherGestorFormFields, modalStates[id])

        this.setState({ error: error.messages })

        if (error.hasError) return

        postGestor({ ...modalStates.escolherGestor, enviarEmails }).then(
          ({ response }) => {
            if (response.status === 200) {
              getGestores()
              toast.success('Gestor adicionado com sucesso')

              this.closeModal(id)
            } else toast.error('Ocorreu um erro ao adicionar gestor')
          }
        )
      },
      novoObjetivo: () => {
        const { storeObjetivo, getObjetivos } = this.props
        const { modalStates } = this.state

        const error = validateForm(objetivoFormFields, modalStates[id])
        this.setState({ error: error.messages })

        if (error.hasError) return

        storeObjetivo(modalStates.novoObjetivo).then(({ response }) => {
          if (response.status === 200) {
            getObjetivos()
            toast.success('Objetivo criado com sucesso')

            this.closeModal(id)
          } else toast.error('Ocorreu um erro ao criar objetivo')
        })
      },
      updateObjetivo: () => {
        const { updateObjetivo, getObjetivos } = this.props
        const { modalStates } = this.state

        const error = validateForm(objetivoFormFields, modalStates[id])
        this.setState({ error: error.messages })

        if (error.hasError) return

        updateObjetivo(
          modalStates.updateObjetivo.obj_conta_id,
          modalStates.updateObjetivo
        ).then(({ response }) => {
          if (response.status === 200) {
            getObjetivos()
            toast.success('Objetivo atualizado com sucesso')

            this.closeModal(id)
          } else toast.error('Ocorreu um erro ao atualizar objetivo')
        })
      },
      culturaConsenso: () => {
        const { postCulturaConsensuada, getCulturaDesejada } = this.props

        const { modalStates } = this.state
        const { culturaConsenso } = modalStates

        const {
          regra,
          complacencia,
          aprendizado,
          cla,
          colaboracao,
          empreendedora,
          inovacao,
          autoridade,
          mercado,
          cliente,
          operacao,
          interno,
          olhar_externo,
          olhar_interno,
          controle,
          flexibilidade,
          regra_limite,
          complacencia_limite,
          aprendizado_limite,
          cla_limite,
          colaboracao_limite,
          empreendedora_limite,
          inovacao_limite,
          autoridade_limite,
        } = culturaConsenso

        const totalPercentage =
          regra +
          complacencia +
          aprendizado +
          cla +
          colaboracao +
          empreendedora +
          inovacao +
          autoridade

        if (totalPercentage < 99.99) {
          toast.error(
            'É necessário distribuir 100% entre todos os arquétipos da Cultura Ajustada.'
          )
          return
        }
        if (totalPercentage > 100) {
          toast.error(
            'Erro: você distribuiu mais que 100% entre os arquétipos da Cultura Ajustada.'
          )
          return
        }

        postCulturaConsensuada({
          regra,
          complacencia,
          aprendizado,
          cla,
          colaboracao,
          empreendedora,
          inovacao,
          autoridade,
          mercado,
          cliente,
          operacao,
          interno,
          olhar_externo,
          olhar_interno,
          controle,
          flexibilidade,
          regra_limite,
          complacencia_limite,
          aprendizado_limite,
          cla_limite,
          colaboracao_limite,
          empreendedora_limite,
          inovacao_limite,
          autoridade_limite,
        }).then(({ response }) => {
          if (response.status === 200) {
            getCulturaDesejada()
            toast.success('Cultura Ajustada gravada com sucesso.')
            this.closeModal(id)
          } else toast.error('Ocorreu um erro ao gravar Cultura Ajustada.')
        })
      },
    }

    modalActions[id]()
  }

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  render() {
    const {
      onboarding,
      listaGestores,
      forcasEstrategicas,
      culturaDesejada,
      gestao,
      olhares,
    } = this.props
    const {
      handleChangeModal,
      openModal,
      closeModal,
      resetModal,
      onModalSubmit,
      onDeleteGestor,
      handleDeleteCulturaConsensuada,
    } = this

    const { modalStates, showModals, error } = this.state

    return (
      <ObjetivosEstrategicosView
        onDeleteGestor={onDeleteGestor}
        dataForcasEstrategicas={forcasEstrategicas}
        dataCulturaDesejada={culturaDesejada}
        onboarding={onboarding}
        modalStates={modalStates}
        showModals={showModals}
        openModal={openModal}
        closeModal={closeModal}
        resetModal={resetModal}
        onModalSubmit={onModalSubmit}
        handleChangeModal={handleChangeModal}
        error={error}
        gestao={gestao}
        olhares={olhares}
        handleDeleteCulturaConsensuada={handleDeleteCulturaConsensuada}
      />
    )
  }
}

export default ObjetivosEstrategicosController
