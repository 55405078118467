// redux types

export const GET_DASHBOARD_GRUPOS = 'GET_DASHBOARD_GRUPOS'
export const GET_DASHBOARD_GRUPO_MEMBROS = 'GET_DASHBOARD_GRUPO_MEMBROS'
export const GET_DASHBOARD_GRUPO_COLABORADORES =
  'GET_DASHBOARD_GRUPO_COLABORADORES'
export const GET_DASHBOARD_GRUPO_CULTURA = 'GET_DASHBOARD_GRUPO_CULTURA'
export const GET_DASHBOARD_GRUPO_STATISTICS = 'GET_DASHBOARD_GRUPO_STATISTICS'
export const GET_DASHBOARD_CULTURA_ORGANIZACIONAL_GRUPO =
  'GET_DASHBOARD_CULTURA_ORGANIZACIONAL_GRUPO'
export const GET_DASHBOARD_VETOR_GRUPO = 'GET_DASHBOARD_VETOR_GRUPO'
export const GET_DASHBOARD_CULTURA_PESSOA = 'GET_DASHBOARD_CULTURA_PESSOA'
export const GET_DASHBOARD_CANDIDATOS = 'GET_DASHBOARD_CANDIDATOS'
export const GET_DASHBOARD_NPS = 'GET_DASHBOARD_NPS'

// urls
const URL_BASE = process.env.REACT_APP_API_URL
export const URL_DASHBOARD = `${URL_BASE}/dashboard`
export const URL_DASHBOARD_GRUPOS = `${URL_DASHBOARD}/grupos`
export const URL_DASHBOARD_GRUPO = `${URL_DASHBOARD}/grupo`
export const URL_DASHBOARD_VETOR_GRUPO = `${URL_DASHBOARD}/grupo/vetor/`
export const URL_DASHBOARD_CULTURA_ORGANIZACIONAL_GRUPO = `${URL_DASHBOARD}/grupo/culturaOrganizacional/`
export const URL_DASHBOARD_GRUPO_STATISTICS = `${URL_DASHBOARD_GRUPO}/statistics/`
export const URL_DASHBOARD_GRUPO_CULTURA = `${URL_DASHBOARD_GRUPO}/cultura/`
export const URL_DASHBOARD_GRUPO_COLABORADORES = `${URL_DASHBOARD_GRUPO}/colaboradores/`
export const URL_DASHBOARD_GRUPO_MEMBROS = `${URL_DASHBOARD_GRUPO}/membros/`
export const URL_DASHBOARD_CULTURA_PESSOA = `${URL_DASHBOARD}/pessoa/cultura/`
export const URL_DASHBOARD_CANDIDATOS = `${URL_DASHBOARD}/candidatos`
export const URL_DASHBOARD_NPS = (id) => `/relatorios/grupo/${id}/nps`

// defaults
export const culturaOrgDefault = {
  culturaOrg: {
    niveisConfianca: [],
    olharLideranca: {
      perceboLider: [],
      frequenciaFeedback: [],
      feedbackContribui: [],
      reconhecimento: [],
      expectativaEntregas: [],
    },
    olharParaMudancas: {
      pontosFortes: [],
      impedimento: [],
      principalProblema: [],
      engajamento: undefined,
      velocidadeAdocao: undefined,
      proficiencia: undefined,
    },
    olharParaSi: {
      escolhaEmpresa: [],
      prioridades: [],
      expectativas6meses: [],
      fonteEntusiasmo: [],
    },
    valoresEvidencia: [],
    satisfacaoGeral: [],
  },
  disc: {},
}

export const npsDefault = {
  nps: 0,
}

export const estatisticasDefault = {
  colaboradores: 0,
  times: 0,
  pesquisas: 0,
  naorespondidas: 0,
}

export const vetorDefault = {
  intensidade: [
    {
      row: '',
      essencia: '',
      exigido: '',
      entrega: '',
    },
  ],
  rct: [],
  edl: [],
  foc: [],
  ode: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  oct: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  tdd: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  cor: [],
  erc: [],
  edc: [],
  principal: {},
}

// Utils
export const FORCAS = ['Mercado', 'Cliente', 'Operação', 'Ambiente interno']
export const OLHARES = ['Olhar externo', 'Olhar interno']
export const GESTAO = ['Controle', 'Flexibilidade']
export const DISC = ['D', 'I', 'S', 'C']
export const CULTURAS = [
  'Inovação',
  'Empreendedora',
  'Colaboração',
  'Clã',
  'Aprendizado',
  'Complacência',
  'Regra',
  'Autoridade',
]

export const labelsCulturaGrupo = ['Desejado', 'Instalado', 'Líder']
