import { Card, Col, Modal, Skeleton, Button } from 'antd'
import {
  Chart,
  Coordinate,
  Axis,
  Tooltip,
  Line,
  Point,
  Legend,
} from 'bizcharts'
import { useEffect, useState } from 'react'
import { arqRange, colors } from '../../../utils/utils'
import { graficos } from '../Api/api'

import MyModal from './modal'

const App = (props) => {
  const [modalVisible, setModal] = useState(false)
  const [indicatorArray, setArray] = useState([])
  const [culturaAtual, setAtual] = useState([])
  const [culturaDesejada, setDesejada] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [isOkBtnEnabled, setOkBtnEnabled] = useState(false)
  const [novoIndice, setNovoIndice] = useState({})
  const [noCharts, setNoCharts] = useState(false)

  function resetValues() {
    setArray([])
    setAtual([])
    setDesejada([])
    setNovoIndice({})
    setNoCharts(false)
  }

  async function getGraficos() {
    const response = await graficos(props.cultura.cultura_id, props.subcultura)
    if (!response) return
    if (response.desejado) {
      if (
        response.instalado.length === 0 &&
        Object.entries(response.desejado).length === 0
      ) {
        setNoCharts(true)
      }
    } else if (response.instalado.length === 0) {
      setNoCharts(true)
    }
    generateEstructure(response.instalado)
    if (response.desejado) {
      const newArray = Object.entries(response.desejado)
      generateDesejada(newArray)
    }
    generateArchWithName(response.instalado)
  }

  function updateScreen() {
    resetValues()
    setLoading(true)
    setModal(false)
    setTimeout(() => {
      getGraficos()
      if (props.changeScreen)
        props.changeScreen({ title: 'Consensuar', key: '1' })
    }, 2000)
  }

  function changeModalVisibility() {
    setModal(!modalVisible)
  }

  useEffect(() => {
    getGraficos()
  }, [])

  if (isLoading) return <Skeleton active />

  // Função necessária para substituir a estrutura dos arquétipos do tipo Id para tipo nome
  function generateArchWithName(arch) {
    const archWithName = {
      Empreendedora: arch[0]?.valor,
      Inovação: arch[1]?.valor,
      Colaboração: arch[2]?.valor,
      Clã: arch[3]?.valor,
      Aprendizado: arch[4]?.valor,
      Complacência: arch[5]?.valor,
      Autoridade: arch[6]?.valor,
      Regra: arch[7]?.valor,
    }

    setNovoIndice({ ...archWithName })
  }

  function generateDesejada(array) {
    const newArray = []
    array.forEach((arquetipo) => {
      const element = {
        Tipo: arquetipo[0],
        Valor: arquetipo[1],
        Label: 'Desejado',
      }
      newArray.push(element)
    })
    setDesejada(newArray)
  }

  function generateType(id) {
    switch (id) {
      case 1:
        return 'Empreendedora'
      case 2:
        return 'Inovação'
      case 3:
        return 'Colaboração'
      case 4:
        return 'Clã'
      case 5:
        return 'Aprendizado'
      case 6:
        return 'Complacência'
      case 7:
        return 'Autoridade'
      case 8:
        return 'Regra'
      default:
        return null
    }
  }

  const forcas = [
    {
      Tipo: 'Ambiente Interno',
      Valor: 25.9,
      Label: 'Gestores',
    },
    {
      Tipo: 'Ambiente Interno',
      Valor: 22.5,
      Label: 'Prioridades',
    },
    {
      Tipo: 'Ambiente Interno',
      Valor: 23,
      Label: 'Ajustada',
    },
    {
      Tipo: 'Cliente',
      Valor: 25,
      Label: 'Gestores',
    },
    {
      Tipo: 'Cliente',
      Valor: 25.1,
      Label: 'Prioridades',
    },
    {
      Tipo: 'Cliente',
      Valor: 30.4,
      Label: 'Ajustada',
    },
    {
      Tipo: 'Mercado',
      Valor: 24.1,
      Label: 'Gestores',
    },
    {
      Tipo: 'Mercado',
      Valor: 31.5,
      Label: 'Prioridades',
    },
    {
      Tipo: 'Mercado',
      Valor: 27.1,
      Label: 'Ajustada',
    },
    {
      Tipo: 'Operação',
      Valor: 25,
      Label: 'Gestores',
    },
    {
      Tipo: 'Operação',
      Valor: 20.9,
      Label: 'Prioridades',
    },
    {
      Tipo: 'Operação',
      Valor: 19.6,
      Label: 'Ajustada',
    },
  ]

  // Gera o array de valores ajustados
  function generateEstructure(array) {
    if (array === undefined) array = {}
    const localArray = []
    array.forEach((arch) => {
      const name = generateType(arch.arquetipo_id)
      putElement(name, arch.valor)
    })

    function putElement(name, Valor) {
      const myobj = { Tipo: name, Label: 'Ajustada', Valor }
      localArray.push(myobj)
    }
    const cleanArray = []
    localArray.forEach((element, index) => {
      if (index < 8) cleanArray.push(element)
    })
    setAtual(cleanArray)
    generateJuncao()
  }

  function generateJuncao() {
    setLoading(false)
  }
  const juncao = culturaAtual.concat(culturaDesejada)

  // Filtra os arquétipos desejados para a constante
  const ArrayDesejado = juncao.filter((array) => array.Label === 'Desejado')

  if (indicatorArray.length === 0 && juncao.length === 8)
    juncao.forEach((array) => indicatorArray.push(array))

  // Array com os arquétipos apenas de cultura desejada
  ArrayDesejado.forEach((array) => indicatorArray.push(array))

  // Array com os arquétipos de desejada e ajustada juntos.
  const uniqueArray = []
  indicatorArray.forEach((element, index) => {
    if (index < 16) uniqueArray.push(element)
  })

  function handleNextStep() {
    props.changeScreen({ title: 'Etapas', key: '2' })
  }

  function isNotPossibleToSee() {
    return (
      <div>
        <Card title="Não é possível visualizar esta tela na etapa de criação">
          {!props.subcultura ? (
            <Button onClick={handleNextStep} type="primary">
              Avançar Etapa
            </Button>
          ) : (
            <h3>Avance etapa na página anterior</h3>
          )}
        </Card>
      </div>
    )
  }

  if (
    props.cultura?.conta_cultura_statuses &&
    props.cultura?.conta_cultura_statuses[0]?.id === 1
  )
    return isNotPossibleToSee()

  return (
    <div>
      <Col md={24} xxl={8} xs={24} sm={24}>
        <Card
          type="inner"
          title={
            <span>
              {!noCharts ? (
                <b>Cultura desejada</b>
              ) : (
                <b>Nenhum avaliador respondeu a avaliação</b>
              )}
            </span>
          }
          className="objetivos-estrategicos__cultura-desejada objetivos-estrategicos__card"
          extra={
            props.cultura?.conta_cultura_statuses[0]?.id !== 3 && (
              <span
                className="consensuar-cultura__span"
                onClick={changeModalVisibility}
              >
                Ajustar Cultura
              </span>
            )
          }
        >
          {noCharts && (
            <h1>
              O gráfico será exibido quando a cultura for consensuada ou algum
              avaliador responder a avaliação
            </h1>
          )}
          {!noCharts && (
            <Chart height={400} data={uniqueArray} scale={arqRange} autoFit>
              <Legend />
              <Coordinate type="polar" radius={0.8} />
              <Axis
                name="Tipo"
                line={null}
                tickLine={null}
                grid={{
                  lineStyle: {
                    lineDash: null,
                  },
                  hideFirstLine: false,
                }}
              />
              <Tooltip />
              <Axis
                name="Valor"
                line={null}
                tickLine={null}
                grid={{
                  type: 'polygon',
                  lineStyle: {
                    lineDash: null,
                  },
                  alternateColor: 'rgba(0, 0, 0, 0.04)',
                }}
              />
              <Line position="Tipo*Valor" color={['Label', colors]} size={2} />
              <Point
                position="Tipo*Valor"
                color={['Label', colors]}
                shape="circle"
                size={4}
                style={{
                  stroke: '#fff',
                  lineWidth: 1,
                  fillOpacity: 1,
                }}
                state
              />
            </Chart>
          )}
        </Card>
      </Col>
      <Modal
        visible={modalVisible}
        title="AJUSTAR CULTURA"
        centered
        onCancel={() => {
          changeModalVisibility()
          if (props.changeScreen)
            props.changeScreen({ title: 'Dashboard', key: '1' })
        }}
        footer={null}
        width="94%"
        style={{ maxWidth: '1000px' }}
      >
        <MyModal
          okBtn={setOkBtnEnabled}
          cultura={uniqueArray}
          indices={novoIndice}
          cultura_id={props.cultura.cultura_id}
          culturaAtual={culturaAtual}
          subcultura={props.subcultura}
          forcas={forcas}
          close={changeModalVisibility}
          update={updateScreen}
        />
      </Modal>
    </div>
  )
}

export default App
