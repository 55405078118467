import { Modal } from 'antd'
import { useCallback } from 'react'
import { useFetch } from '../../../../../../../../hooks/use-fetch'
import AddPessoa from '../../../../../../components/AddPessoa'
import '../../../../../../Dashboard.css'

const ModalAdicionarColaborador = ({
  handleAddPessoa,
  showModalAddColaborador,
  setShowModalAddColaborador,
  grupoId,
}) => {
  const { isLoading: loadingColaboradores, response: colaboradores } = useFetch(
    grupoId ? '/v2/dashboard/candidatos' : undefined
  )

  const handleSubmitModalAddColaborador = useCallback(
    ({ pes_id }) => {
      if (!loadingColaboradores && colaboradores) {
        const pessoa = colaboradores.find((p) => p.pes_id === pes_id)
        handleAddPessoa(pessoa)
        setShowModalAddColaborador(false)
      }
    },
    [
      loadingColaboradores,
      colaboradores,
      handleAddPessoa,
      setShowModalAddColaborador,
    ]
  )

  return (
    <Modal
      visible={showModalAddColaborador}
      title="Adicionar Colaborador"
      cancelText="CANCELAR"
      okText="SALVAR"
      onCancel={() => setShowModalAddColaborador(false)}
      okButtonProps={{
        form: 'add-pessoa',
        key: 'submit',
        htmlType: 'submit',
      }}
      destroyOnClose
    >
      <AddPessoa
        onFinish={handleSubmitModalAddColaborador}
        listPessoas={colaboradores}
        type="colaborador"
        loading={loadingColaboradores}
      />
    </Modal>
  )
}

export default ModalAdicionarColaborador
