import React from 'react'

import {
  Chart,
  Axis,
  Tooltip,
  Coordinate,
  Label,
  Legend,
  Interval,
} from 'bizcharts'

import { Empty } from 'antd'

import DataSet from '@antv/data-set'

import { parseStackedChartData, colors } from '../../../../utils/utils'

import { cols } from '../../constants'

export default function HorizontalStackedHistogram({ data }) {
  const maxDataValue = data
    ? data.reduce((prev, next) => {
        if (next.VALOR > prev) {
          prev = next.VALOR
        }
        return prev
      }, 0)
    : 0

  data = parseStackedChartData(data)
  const ds = new DataSet()
  const dv = ds.createView().source(data)

  dv.transform({
    type: 'fold',
    fields: [
      'De forma alguma',
      'Não muito',
      'Mais ou menos',
      'Muito',
      'Extremamente',
    ],
    key: 'key',
    value: 'value',
    retains: ['TEXTO'],
  })

  return (
    <>
      {data && data.length > 0 && maxDataValue > 0 ? (
        <Chart height={300} data={dv} scale={cols} autoFit>
          <Legend />
          <Coordinate transpose />
          <Axis
            name="TEXTO"
            label={{
              offset: 12,
            }}
          />
          <Axis name="value" />
          <Tooltip />
          <Interval
            adjust="stack"
            color={['key', colors]}
            position="TEXTO*value"
          />
        </Chart>
      ) : (
        <Empty />
      )}
    </>
  )
}
