import React from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import { Card, Row, Col, Steps, Slider, Modal, Popover } from 'antd'

import { FrownOutlined, SmileOutlined } from '@ant-design/icons'
import { ReactComponent as Svg } from '../../assets/success.svg'

// Components
import Container from '../../components/container/Container'
import Button from '../../components/button/Button'
import LegendCard from '../../components/legendCard/LegendCard'

// CSS
import './EmailGestor.css'
import { styleColors } from '../../utils/utils'

// Constants
import { steps as StepsTitle } from './constants/constantsEmailGestor'

const { pink } = styleColors

const { Step } = Steps

function EmailGestorView({
  intl,
  name,
  objetivosRespostas,
  culturaRespostas,
  changeRateTooltips,
  onSubmit,
  currentStep,
  questoes,
  nextStep,
  prevStep,
  onChangeSliderCultura,
  onChangeSliderObjetivos,
  hasObjetivos,
  showModal,
}) {
  const history = useHistory()

  const nameSplitted = name.split(' ')

  const nameParsed = `${nameSplitted[0]}`

  const QuestaoComponent = ({ questao }) => (
    <Row>
      <Col lg={11} md={24} xs={24} sm={24}>
        <p style={{ fontSize: '16px' }}>{questao.descricao}</p>
      </Col>
      <Col lg={1} md={0} xs={0} sm={0} />
      <Col lg={12} md={24} xs={24} sm={24}>
        <Slider
          className="email-gestor__slider"
          step={1}
          min={1}
          max={5}
          marks={{
            1: 'Muito baixo',
            2: 'Baixo',
            3: 'Médio',
            4: 'Alto',
            5: 'Muito alto',
          }}
          onChange={(evt) =>
            questao.cultform_id
              ? onChangeSliderCultura(evt, questao.cultform_id)
              : onChangeSliderObjetivos(evt, questao.obj_conta_id)
          }
          value={
            questao.cultform_id
              ? (culturaRespostas.length > 0 &&
                  culturaRespostas.findIndex(
                    (o) => o.cultform_id === questao.cultform_id
                  ) >= 0 &&
                  culturaRespostas[
                    _.findIndex(culturaRespostas, [
                      'cultform_id',
                      questao.cultform_id,
                    ])
                  ].valor) ||
                1
              : (objetivosRespostas.length > 0 &&
                  objetivosRespostas.findIndex(
                    (o) => o.obj_conta_id === questao.obj_conta_id
                  ) >= 0 &&
                  objetivosRespostas[
                    _.findIndex(objetivosRespostas, [
                      'obj_conta_id',
                      questao.obj_conta_id,
                    ])
                  ].valor) ||
                1
          }
        />
      </Col>
    </Row>
  )

  return (
    <Container className="email-gestor">
      <Card>
        <span className="email-gestor__title">
          Olá {nameParsed}, vamos definir juntos a cultura desejada da sua área?
        </span>
      </Card>
      <Steps current={currentStep} className="email-gestor__content__steps">
        {questoes.map((step, index) => (
          <Step key={index} />
        ))}
      </Steps>
      <Card
        type="inner"
        title={
          <span style={{ fontSize: '16px' }}>
            <b>{StepsTitle[currentStep]}</b>
          </span>
        }
        className="email-gestor__card"
      >
        {questoes[currentStep].length > 0 &&
          questoes[currentStep].map((questao) =>
            questao.cultform_id ? (
              <div className="email-gestor__questao__container">
                <QuestaoComponent questao={questao} />
              </div>
            ) : (
              <LegendCard
                title={questao.obj_cliente}
                bodyStyle={{ padding: '0 24px 24px' }}
              >
                <QuestaoComponent questao={questao} />
              </LegendCard>
            )
          )}
      </Card>
      <div
        style={{
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Button color="orange" type="link" onClick={() => history.goBack()}>
            CANCELAR
          </Button>
        </div>
        <div className="email-gestor__buttons__container">
          {currentStep > 0 && (
            <Button color="orange" type="link" onClick={prevStep}>
              VOLTAR
            </Button>
          )}
          {currentStep < (hasObjetivos ? 4 : 3) ? (
            <Button color="orange" type="primary" onClick={nextStep}>
              PRÓXIMO PASSO
            </Button>
          ) : (
            <Button color="orange" type="primary" onClick={onSubmit}>
              SALVAR
            </Button>
          )}
        </div>
      </div>
      <Modal
        className="modal_fechar"
        visible={showModal}
        closable={false}
        footer={null}
        width={550}
        centered
      >
        <Svg width="500" className="valores-org__modal__image" />
        <h1> Sua resposta foi recebida com sucesso! </h1>
      </Modal>
    </Container>
  )
}

export default injectIntl(EmailGestorView)
