import styled, { css } from 'styled-components'
import { Col, Divider, Card, List } from 'antd'

export const ChartTitle = styled.span`
  color: #000;
  font-size: 16px;
`

export const Label = styled.span`
  color: #000;
  font-size: 15px;
  margin-bottom: 8px;
  font-weight: 500;
  display: table;
`

export const RateSpan = styled.span`
  font-size: 15px;
`

export const RateContainer = styled.div`
  width: 100%;
`

export const FlexTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ProgressTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  flex: 1;
  text-align: center;
`

export const ProgressValues = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ProgressLabels = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 15% 0px 15%;

  .ant-progress-bg {
    background-color: var(--purple);
  }
  .ant-progress-success-bg {
    background-color: var(--orange);
  }
`

export const FullCol = styled(Col)`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .ant-card:last-of-type {
    display: flex;
    flex-direction: column;
    height: 100%;
    .ant-card-body {
      flex: 1;
    }
  }

  @media screen and (min-width: 1600px) {
    .flex-row-xxl-col {
      flex-direction: row !important;
    }
  }
`

export const ColFill = styled(Col)`
  flex: 1;
`

export const CardFill = styled(Card)`
  .ant-card-body {
    flex: 1;
    ${(props) =>
      props.flexlayout &&
      css`
        display: flex;
        flex-direction: ${props.flexlayout};
        justify-content: space-between;
        ${props.flexlayout === 'column' && `align-items: center`}
      `}
  }
`

export const FlexCard = styled(Card)`
  ${(props) =>
    props.notitlepadding &&
    css`
      .ant-card-head {
        min-height: unset;
        .ant-card-head-wrapper,
        .ant-card-head-title {
          padding: 0px 0px 8px 0px;
        }
      }
    `}

  .ant-card-body {
    padding: 0;
    flex: 1;
    display: flex;
    justify-content: space-between;
    ${(props) =>
      props.flexdirection &&
      (props.flexdirection === 'column' || props.flexdirection === 'row') &&
      css`
        flex-direction: ${props.flexdirection};
      `}
    align-items: baseline;
  }
`

export const Divider16 = styled(Divider)`
  margin: 8px 0px;
`

export const Divider64 = styled(Divider)`
  margin: 32px 0px;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  * + * {
    margin-left: 16px;
  }
  span {
    word-wrap: break-word;
  }
  .span__anchor {
    color: var(--purple);
    font-size: 15px;
    cursor: pointer;
  }
  margin-top: 8px;
`

export const Container = styled(Col)`
  padding: 0px !important;
  margin-top: 8px;

  ${FlexRow} + ${FlexRow} {
    margin-top: 16px;
  }

  @media screen and (max-width: 1599px) and (min-width: 1200px) {
    .colaboradores__row-historico {
      flex-direction: row;
    }
  }

  .textual__col {
    margin-bottom: 16px !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
`

export const Span16 = styled.span`
  font-size: 16px;
`

export const PerfilComposicao = styled.div`
  border: 1px solid var(--purple);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;

  span {
    font-weight: 500;
    font-size: 20px;
    color: var(--purple);
  }
`

export const PerfilDetalhesTag = styled.span`
  background-color: var(--orange);
  color: #fff;
  align-self: flex-end;
  padding: 0px 4px 0px 4px;
  border-radius: 2px;
  margin-bottom: 8px;
`

export const PerfilDetalhes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${Label} {
    margin-bottom: 4px !important;
  }
`

export const HistoricoOverlay = styled.div`
  display: flex;
  justify-content: space-around;
  transition: all 0.75s;
  padding: 16px 16px 16px 16px;
  transition: all 0.75s;
  padding: 16px 16px 16px 16px;
  align-items: center;

  @media screen {
    ${PerfilDetalhes} {
      margin-left: 8px;
    }
  }

  @media screen and (min-width: 1920px) {
    ${PerfilDetalhes} {
      margin: 0;
    }
  }

  @media screen and (min-width: 1600px) {
    width: 100%;
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  ${(props) =>
    props.selected &&
    css`
      background-color: rgba(0, 0, 0, 0.05);
    `}
`

export const HistoricoRow = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`

export const CustomList = styled(List)`
  .ant-list-pagination {
    margin-top: 8px;
    .ant-pagination {
      padding: 0px 8px 8px 0px;
      margin-top: 8px !important;
    }
  }
  @media screen and (max-width: 1599px) {
    .ant-row {
      margin: 0 !important;
      display: flex !important;
      justify-content: flex-start !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      overflow-x: auto;
      flex-wrap: nowrap;

      span {
        white-space: nowrap;
      }

      ${HistoricoRow} {
        border-bottom: none;
      }

      ${HistoricoRow} + ${HistoricoRow} {
        ${HistoricoOverlay} {
          border-left: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
`

export const FlexTablesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .ant-table-wrapper {
    flex: 1;
    .ant-table-body {
      margin: 0px 8px 8px 8px;

      table {
        border-collapse: separate;
        border-spacing: 0 4px;

        tbody {
        }

        td:hover {
          background: transparent !important;
        }
      }
    }
    .table-fitcultural-baixo-row {
      color: red;
      font-weight: 500;
    }
    .table-fitcultural-medio-row {
      color: orange;
      font-weight: 500;
    }
    .table-fitcultural-alto-row {
      color: green;
      font-weight: 500;
    }
  }
`

export const FitCulturalCard = styled(Card)`
  p {
    font-size: 15px;
  }
  ${Label} {
    font-size: 16px;
  }
  ${FullCol} {
    margin-top: 16px;
  }
  p + ${Label} {
    margin-top: 16px;
  }
`

export const FitCulturalContainer = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  span {
    font-weight: 500;
    font-size: 16px;
  }

  .fitcultural__details {
    color: var(--orange);
    cursor: pointer;
    :hover {
    }
  }
`

export const Link = styled.span`
  color: var(--purple);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  font-size: 14px;
`

export const Link16 = styled(Link)`
  font-size: 16px;
`

export const LinkRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media screen and (max-width: 1599px) {
    justify-content: flex-start;
    ${Link} + ${Link} {
      margin-left: 48px;
    }
  }
`
