import { message } from 'antd'
import { Component } from 'react'
// Services
import { login } from '../../services/auth'
// Components
import LoginColaboradorView from './LoginColaboradorView'

// CSS

class LoginColaboradorController extends Component {
  state = {
    login: '',
    senha: '',
    showModal: false,
    contas: [],
    contaSelecionada: 0,
    isLoading: false,
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  handleChangeSelect = (value) => this.setState({ contaSelecionada: value })

  onSubmit = (event) => {
    const { openModal } = this
    const { postLogin } = this.props
    const { ...state } = this.state

    event.preventDefault()

    postLogin(state)
      .then(({ response }) => {
        if (response == undefined) {
          message.error(
            'Estamos temporariamente indisponiveis, volte daqui a alguns minutos'
          )
        } else if (response.status === 200) {
          message.success('Login efetuado com sucesso')
          openModal(response.data.logins)
        } else if (response.data.erros) {
          for (let i = 0; response.data.erros.length > i; i++) {
            message.error(response.data.erros[i].msg)
          }
        } else if (response.status === 500) {
          message.error(
            'Estamos temporariamente indisponiveis, volte daqui a alguns minutos'
          )
        } else {
          message.error(response.data.message)
        }
        this.setState({ isLoading: false })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  onSelectConta = () => {
    const { contas, contaSelecionada } = this.state
    const { history } = this.props

    login(contas[contaSelecionada].auth.token)

    history.push(`/${contas[contaSelecionada].auth.conta_url}/pesquisas/`)
  }

  openModal = (contas) => this.setState({ showModal: true, contas })

  closeModal = () => this.setState({ showModal: false })

  render() {
    const {
      handleChange,
      handleChangeSelect,
      onSubmit,
      onSelectConta,
      closeModal,
      isLoading,
    } = this
    const { history } = this.props
    const { showModal, contas, contaSelecionada } = this.state

    return (
      <LoginColaboradorView
        handleChange={handleChange}
        handleChangeSelect={handleChangeSelect}
        onSubmit={onSubmit}
        history={history}
        showModal={showModal}
        closeModal={closeModal}
        contas={contas}
        contaSelecionada={contaSelecionada}
        onSelectConta={onSelectConta}
        isLoading={isLoading}
      />
    )
  }
}

export default LoginColaboradorController
