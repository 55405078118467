import { Component } from 'react'
import { logout } from '../../services/auth'
// CSS
// Utils
import history from '../../utils/history'
// Components
import DashboardLayoutView from './DashboardLayoutView'

// Constants
// Added line
class DashboardLayoutController extends Component {
  state = { collapsed: false }

  componentDidMount = () => {
    this.props.getUserInfo()
  }

  handleSider = () =>
    this.setState((prevState) => ({ collapsed: !prevState.collapsed }))

  logout = () => {
    logout()
    history.push('/')
  }

  render() {
    const { component, user, ...props } = this.props
    const { collapsed } = this.state
    return (
      <DashboardLayoutView
        component={component}
        collapsed={collapsed}
        handleSider={this.handleSider}
        logout={this.logout}
        user={user}
        {...props}
      />
    )
  }
}

export default DashboardLayoutController
