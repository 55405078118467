import React, { Component } from 'react'
import qs from 'qs'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { Modal } from 'antd'
// Components
import EmailGestorView from './EmailGestorView'

import history from '../../utils/history'

// CSS

class EmailGestorController extends Component {
  state = {
    currentStep: 0,
    objetivosRespostas: [],
    culturaRespostas: [],
    objetivosQuestoes: [],
    culturaQuestoes: [[], [], [], []],
    id: undefined,
    name: '',
    showModal: false,
    showQuestions: false,
  }

  componentDidMount = () => {
    const { empresa, hash, id } = this.props.match.params
    if (hash) {
      this.props
        .getQuestionarioGestor({ contaUrl: empresa, hash })
        .then(({ response }) => {
          if (response.status === 200) {
            this.setState({
              showQuestions:
                response.data?.avaliador.conta_culturas[0]?.cultura_status ===
                2,
            })
            const {
              avaliador: { objetivosRespostas, culturaRespostas },
            } = response.data

            this.setState(
              {
                objetivosRespostas,
                culturaRespostas,
                id: response.data.avaliador.avaliador_id,
                name: response.data.avaliador.nome,
              },
              getQuestoes()
            )
          }
        })
    } else {
      this.props.getQuestionarioGestor({ id }).then(({ response }) => {
        if (response.status === 200) {
          const {
            avaliador: { objetivosRespostas, culturaRespostas, pes_id, nome },
          } = response.data

          this.setState(
            {
              objetivosRespostas,
              culturaRespostas,
              id: pes_id,
              name: nome,
            },
            getQuestoes()
          )
        }
      })
    }

    const getQuestoes = () => {
      this.props.getCulturaQuestoes().then(({ response }) => {
        if (response.status === 200) {
          const { culturaQuestoes } = response.data
          const { culturaRespostas } = this.state

          const newCulturaRespostas = [...culturaRespostas]

          const culturaQuestoesFlat = culturaQuestoes.flat()

          culturaQuestoesFlat.map(
            (cq) =>
              newCulturaRespostas.findIndex(
                (cr) => cr.cultform_id === cq.cultform_id
              ) < 0 &&
              newCulturaRespostas.push({
                cultform_id: cq.cultform_id,
                valor: 1,
              })
          )

          this.setState({
            culturaQuestoes: [...culturaQuestoes],
            culturaRespostas: [...newCulturaRespostas],
          })
        }
      })

      this.props
        .getObjetivosQuestoes(hash ? { contaUrl: empresa, hash } : {})
        .then(({ response }) => {
          if (response.status === 200) {
            console.log(response.data)
            const objetivosEstrategicos = response.data.response
            const { objetivosRespostas } = this.state

            const newObjetivosRespostas = [...objetivosRespostas]

            objetivosEstrategicos.map(
              (o) =>
                newObjetivosRespostas.findIndex(
                  (or) => or.obj_conta_id === o.obj_conta_id
                ) < 0 &&
                newObjetivosRespostas.push({
                  obj_conta_id: o.obj_conta_id,
                  valor: 1,
                })
            )

            this.setState((prevstate) => {
              return {
                objetivosQuestoes: [...objetivosEstrategicos],
                objetivosRespostas: [...newObjetivosRespostas],
              }
            })
          }
        })
    }
  }

  nextStep = () =>
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }))

  prevStep = () =>
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }))

  openModal = () => {
    this.setState({
      showModal: true,
    })
  }

  handleChangeQuestion = ({ tipo, data, obj_conta_id, cultform_id }) => {
    const { objetivosRespostas, culturaRespostas } = this.state

    const newObjetivosRespostas = [...objetivosRespostas]

    const newCulturaRespostas = [...culturaRespostas]

    switch (tipo) {
      case 'obj':
        const obj =
          newObjetivosRespostas[
            _.findIndex(objetivosRespostas, ['obj_conta_id', obj_conta_id])
          ]

        if (obj) {
          newObjetivosRespostas[
            _.findIndex(objetivosRespostas, ['obj_conta_id', obj_conta_id])
          ].valor = data.value
        } else {
          newObjetivosRespostas.push({ obj_conta_id, valor: data.value })
        }

        break
      case 'cultura':
        const cult =
          newCulturaRespostas[
            _.findIndex(culturaRespostas, ['cultform_id', cultform_id])
          ]

        if (cult) {
          newCulturaRespostas[
            _.findIndex(culturaRespostas, ['cultform_id', cultform_id])
          ].valor = data.value
        } else {
          newCulturaRespostas.push({ cultform_id, valor: data.value })
        }
        break
      default:
        break
    }
    this.setState(() => ({
      objetivosRespostas: [...newObjetivosRespostas],
      culturaRespostas: [...newCulturaRespostas],
    }))
  }

  onChangeSliderCultura = (value, cultform_id) => {
    this.handleChangeQuestion({
      data: { value },
      cultform_id,
      tipo: 'cultura',
    })
  }

  onChangeSliderObjetivos = (value, obj_conta_id) => {
    this.handleChangeQuestion({
      data: { value },
      obj_conta_id,
      tipo: 'obj',
    })
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  changeRateTooltips = ({ key, value }) => {
    const newRateValues = this.state.rateValues

    newRateValues[key] = value || null
    this.setState({ rateValues: newRateValues })
  }

  onSubmit = () => {
    const { putQuestionarioGestor } = this.props
    const { objetivosRespostas, culturaRespostas } = this.state
    const { hash, empresa, id } = this.props.match.params

    if (hash) {
      putQuestionarioGestor({
        type: 'salvar',
        contaUrl: empresa,
        hash,
        objetivosRespostas,
        culturaRespostas,
      }).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Resposta salva com sucesso!')
          history.push(`../`)
        }
      })
    } else {
      putQuestionarioGestor({
        type: 'editar',
        objetivosRespostas,
        culturaRespostas,
        id,
      }).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Resposta salva com sucesso!')
          history.push(`/${empresa}/cultura-desejada`)
        }
      })
    }
  }

  render() {
    const {
      onSubmit,
      nextStep,
      prevStep,
      onChangeSliderCultura,
      onChangeSliderObjetivos,
    } = this

    const {
      id,
      name,
      currentStep,
      objetivosRespostas,
      culturaRespostas,
      objetivosQuestoes,
      culturaQuestoes,
      showModal,
    } = this.state

    const hasObjetivos = objetivosQuestoes.length > 0

    if (this.state.showQuestions) {
      return (
        <EmailGestorView
          id={id}
          name={name}
          currentStep={currentStep}
          onSubmit={onSubmit}
          objetivosRespostas={objetivosRespostas}
          culturaRespostas={culturaRespostas}
          questoes={[
            ...culturaQuestoes,
            ...(hasObjetivos ? [[...objetivosQuestoes]] : []),
          ]}
          nextStep={nextStep}
          prevStep={prevStep}
          showModal={showModal}
          onChangeSliderCultura={onChangeSliderCultura}
          onChangeSliderObjetivos={onChangeSliderObjetivos}
          hasObjetivos={hasObjetivos}
        />
      )
    }

    return <h1>Desculpe, você não pode mais responder a este questionário</h1>
  }
}

export default EmailGestorController
