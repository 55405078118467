// URLs
export const URL_BASE = process.env.REACT_APP_API_URL
export const URL_PESQUISAS_DISPONIVEIS_GET = `${URL_BASE}/avaliacoes/check/`

// Redux types
export const PESQUISAS_DISPONIVEIS_GET = 'PESQUISAS_DISPONIVEIS_GET'

// Descriçoes dos passos
export const STEPS = {
  0: '{{NAME}}, quais comportamentos fazem parte da sua essência? Selecione ao menos 4 :)',
  1: '{{NAME}}, quais comportamentos são exigidos de você? Selecione ao menos 4 :)',
  2: '{{NAME}}, quais comportamentos você tem apresentado? Selecione ao menos 4 :)',
}
