import * as constants from '../constants/constants'

export const loadFerramentas = () => {
  return {
    type: constants.FERRAMENTA_LOAD,
  }
}

export const listFerramentas = (id) => {
  return {
    type: constants.FERRAMENTA_LIST,
    api: {
      method: 'get',
      url: constants.URL_FERRAMENTA_LIST(id),
    },
  }
}

export const getFrequencia = () => {
  return {
    type: constants.FREQUENCIA_CARGO,
    api: {
      method: 'get',
      url: constants.URL_FREQUENCIA_CARGO,
    },
  }
}

export const storeFerramentas = ({ id, data, nome, tipo_id }) => {
  return {
    type: constants.FERRAMENTA_POST,
    api: {
      method: 'post',
      url: constants.URL_FERRAMENTA_POST(id),
      data: { nome: data.nome, tipo_id: data.tipo_id },
    },
  }
}

export const updateFerramenta = ({
  id,
  ferramenta_id,
  data,
  nome,
  tipo_id,
}) => {
  return {
    type: constants.FERRAMENTA_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_FERRAMENTA_ID(id, ferramenta_id),
      data: { nome, tipo_id },
    },
  }
}

export const deleteFerramentas = ({ id, ferramenta_id }) => {
  return {
    type: constants.FERRAMENTA_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_FERRAMENTA_ID(id, ferramenta_id),
    },
  }
}
