import React from 'react'

import moment from 'moment'

import 'moment-timezone'

import { Card, Row, Col, Empty, InputNumber, Button } from 'antd'

import { noPadding } from '../../../../utils/inlineStyles'

import { Container, ContainersRow, Footer } from './styles'

function Pesquisas({
  createdBy,
  createdAt,
  updatedBy,
  updatedAt,
  pesoValores,
  pesoArquetipos,
  fitMinimo,
  handleChangePesos,
  handleSubmitPesos,
}) {
  return (
    <Col span={24} style={noPadding}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xxl={12}>
          <Card title="Distribuição de peso entre Arquétipos Culturais e Valores Organizacionais no Fit Cultural">
            <ContainersRow>
              <Container>
                <InputNumber
                  value={pesoArquetipos}
                  onChange={(value) =>
                    handleChangePesos({ name: 'arquetipos', value })
                  }
                  formatter={(value) => `${value} %`}
                />
                <span>Arquétipos Culturais</span>
              </Container>
              <Container>
                <InputNumber
                  value={pesoValores}
                  onChange={(value) =>
                    handleChangePesos({ name: 'valores', value })
                  }
                  formatter={(value) => `${value} %`}
                />
                <span>Valores Organizacionais</span>
              </Container>
              <Container>
                <InputNumber
                  value={fitMinimo}
                  onChange={(value) =>
                    handleChangePesos({ name: 'fitminimo', value })
                  }
                  formatter={(value) => `${value} %`}
                />
                <span>Fit mínimo</span>
              </Container>
            </ContainersRow>
            <Footer>
              <div className="details">
                <span>
                  <strong>Criado por: </strong>
                  {createdBy}
                  {' - '}
                  {moment(createdAt)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('DD/MM/YYYY (HH:mm)')}
                </span>
                <span>
                  <strong>Alterado por: </strong>
                  {updatedBy}
                  {' - '}
                  {moment(updatedAt)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('DD/MM/YYYY (HH:mm)')}
                </span>
              </div>
              <Button type="primary" onClick={() => handleSubmitPesos()}>
                SALVAR
              </Button>
            </Footer>
          </Card>
        </Col>
      </Row>
    </Col>
  )
}

export default Pesquisas
