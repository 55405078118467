import React, { useEffect, useState, useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { toast } from 'react-toastify'

import ReactPlayer from 'react-player'

import {
  Checkbox,
  Button,
  Modal,
  Popconfirm,
  Table,
  Input,
  Switch,
  Tabs,
  Spin,
  Select,
} from 'antd'

import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  EditOutlined,
  SearchOutlined,
  UserAddOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  ShareAltOutlined,
  MailOutlined,
  FilterOutlined,
  YoutubeOutlined,
} from '@ant-design/icons'

import moment from 'moment'

import 'moment-timezone'

import {
  checkProcesso,
  listCandidatos,
  getDetails,
  listEtapas,
  updateEtapaCandidato,
  storeEtapa,
  deleteEtapa,
  rateCandidato,
  deleteCandidato,
  contratarCandidato,
  cancelProcesso,
  listMotivosCancelamento,
  finalizarProcesso,
  clearKanban,
  validateFinalizarProcesso,
  validateContratacao,
  getHunter,
  updateHunter,
  getCandidatosDisponiveis,
  updateEtapa,
  getFitMinimo,
  sendEmailManual,
  getTemplatesManuais,
  getEmailsManuaisParams,
  getCandidatosDisponiveisProcesso,
  getColaboradoresDisponiveisProcesso,
} from './actions/psKanbanActions'

import { triarCandidatos } from '../../../candidatos/actions/actionsCandidatos'

import {
  sendValoresOrganizacionaisLote,
  sendVetorComportamental,
} from '../../../gestaoPesquisasColaboradores/actions/actionsGestaoPesquisas'

import { candidatoUpdateInitialState } from './constants/psKanbanConstants'

import Candidato from './components/CandidatoItem'

import NovaEtapa from './components/NovaEtapa'

import RegistrarObservacao from './components/RegistrarObservacao'

import EnviarEmail from './components/EnviarEmail'

import EnviarPesquisa from '../../../../components/enviarPesquisa/EnviarPesquisa'

import MotivoExclusao from './components/MotivoExclusao'

import ContratarCandidato from './components/ContratarCandidato'

import CancelarProcesso from '../ProcessoSeletivo/components/CancelarProcesso'

import Confirm from '../../../../components/Confirm'

import Link from '../../../../components/privateLink/PrivateLink'

import {
  Container,
  EtapaContainer,
  EtapaWrapper,
  KanbanContainer,
  Header,
  EtapaHeader,
  TableHeader,
  EtapaEditInputIcons,
  FilterIndicator,
} from './styles'

import {
  sorterString,
  sorterNumber,
  getScrollbarWidth,
} from '../../../../utils/utils'

import { getContaURL, getUserPesId } from '../../../../services/auth'

const { TabPane } = Tabs

function PsKanban({ match }, searchInput) {
  const history = useHistory()

  const contaUrl = getContaURL()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFitMinimo())
  }, [dispatch])

  const candidatosRedux = useSelector((state) => state.psKanban.candidatos)

  const fit = useSelector((state) => state.psKanban.fit)

  const [candidatos, setCandidatos] = useState([])

  const [fitMinimo, setFitMinimo] = useState(0)

  const [templatesManuais, setTemplatesManuais] = useState([])

  const [idEmailManualEnviar, setIdEmailManualEnviar] = useState(0)

  // Lista dos templates de e-mails manuais
  const loadTemplatesManuais = useCallback(() => {
    dispatch(getTemplatesManuais()).then(({ response }) => {
      if (response.status === 200) {
        setTemplatesManuais(response.data)
      }
    })
  }, [dispatch])

  /* Parâmetros para emails manuais */
  const [parametros, setParametros] = useState([])

  /* Armazenar os parâmetros para emails manuais */
  const loadParametrosEmailsManuais = useCallback(() => {
    dispatch(getEmailsManuaisParams()).then(({ response }) => {
      if (response.status === 200) {
        setParametros(response.data)
      }
    })
  }, [dispatch])

  useEffect(() => {
    const { fitminimo } = fit
    setFitMinimo(fitminimo)
  }, [fit])

  useEffect(() => {
    setCandidatos(candidatosRedux)
    loadTemplatesManuais()
    loadParametrosEmailsManuais()
  }, [candidatosRedux, loadTemplatesManuais, loadParametrosEmailsManuais])

  // Confirmar triagem
  const [showModalConfirmTriagem, setShowModalConfirmTriagem] = useState(false)
  const [confirmTriagemText, setConfirmTriagemText] = useState()
  const [candidatosToConfirm, setCandidatosToConfirm] = useState([])

  const [searchText, setSearchText] = useState('')

  const [selectedRowKeysCandidatos, setSelectedRowKeysCandidatos] = useState([])
  const [selectedRowKeysColaboradores, setSelectedRowKeysColaboradores] =
    useState([])

  const [selectedRowsCandidatos, setSelectedRowsCandidatos] = useState([])
  const [selectedRowsColaboradores, setSelectedRowsColaboradores] = useState([])

  const processo = useSelector((state) => state.psKanban.processo)

  const [etapas, setEtapas] = useState([])

  const [candidatosDisponiveisProcesso, setCandidatosDisponiveisProcesso] =
    useState([])

  const [
    colaboradoresDisponiveisProcesso,
    setColaboradoresDisponiveisProcesso,
  ] = useState([])

  const motivosCancelamento = useSelector(
    (state) => state.psKanban.motivosCancelamento
  )

  // Valor para filtro de fit minimo
  const [candidatoFit, setCandidatoFit] = useState('all')

  const handleMudarFiltro = (value) => {
    setCandidatoFit(value)
  }

  useEffect(() => {
    switch (candidatoFit) {
      case 'all':
        setCandidatos(candidatosRedux)
        break
      case 'fit':
        setCandidatos(
          candidatosRedux.filter(
            (candidato) =>
              candidato.fitCultural &&
              parseFloat(candidato.fitCultural.geral) >= fitMinimo
          )
        )
        break
      case 'nofit':
        setCandidatos(
          candidatosRedux.filter(
            (candidato) =>
              !candidato.fitCultural ||
              (candidato.fitCultural &&
                parseFloat(candidato.fitCultural.geral) < fitMinimo)
          )
        )
        break
      default:
        setCandidatos(candidatosRedux)
    }
  }, [candidatosRedux, candidatoFit, fitMinimo])

  const [loadingCandidatos, setLoadingCandidatos] = useState(false)

  // Candidatos Disponiveis para triagem - filtrados pelo cargo do processo
  const [candidatosDisponiveisRaw, setCandidatosDisponiveisRaw] = useState([])
  const [candidatosDisponiveis, setCandidatosDisponiveis] = useState([])
  const [loadingCandidatosDisponiveis, setLoadingCandidatosDisponiveis] =
    useState(false)

  const [
    loadingCandidatosDisponiveisProcesso,
    setLoadingCandidatosDisponiveisProcesso,
  ] = useState(false)

  const [
    loadingColaboradoresDisponiveisProcesso,
    setLoadingColaboradoresDisponiveisProcesso,
  ] = useState(false)

  // Colaboradores disponiveis para triagem
  const [colaboradoresDisponiveis, setColaboradoresDisponiveis] = useState([])
  const [loadingColaboradoresDisponiveis, setLoadingColaboradoresDisponiveis] =
    useState(false)

  const [triandoCandidatos, setTriandoCandidatos] = useState(false)
  const [candidatosCargoFilter, setCandidatosCargoFilter] = useState(true)

  const [showModalTriagem, setShowModalCandidatosDisponiveis] = useState(false)

  const [novaEtapa, setNovaEtapa] = useState(null)

  const [showNovaEtapaModal, setShowNovaEtapaModal] = useState(false)

  const [showModalNovaObservacao, setShowModalNovaObservacao] = useState(false)

  const [showModalVideo, setShowModalVideo] = useState(false)

  const [showModalEnviarEmail, setShowModalEnviarEmail] = useState(false)

  const [showModalConfirmarEmailEnviado, setShowModalConfirmarEmailEnviado] =
    useState(false)

  // Enviando e-mail (ativar e desativa botão de envio do modal)
  const [sendingEmail, setSendingEmail] = useState(false)

  const [candidatosChecked, setCandidatosChecked] = useState(
    candidatos.map((c) => ({
      etapa_id: c.etapa,
      pes_id: c.pes_id,
      etapacand_id: c.etapacand_id,
      checked: false,
    }))
  )

  const [candidatosToCheck, setCandidatosToCheck] = useState([])

  const [candidatosCheckedTotal, setCandidatosCheckedTotal] = useState(0)

  const [candidatoSelected, setCandidatoSelected] = useState(null)

  const [kanbanIdSelected, setKanbanIdSelected] = useState(0)

  const [videoSelected, setVideoSelected] = useState(null)
  const [etapaCandidatoId, setEtapaCandidatoId] = useState(0)
  const [etapaIdSelected, setEtapaIdSelected] = useState(0)

  const [candidatoUpdate, setCandidatoUpdate] = useState(
    candidatoUpdateInitialState
  )

  const [allChecked, setAllChecked] = useState([])

  const [motivoExclusao, setMotivoExclusao] = useState(null)

  const [showModalMotivoExclusao, setShowModalMotivoExclusao] = useState(false)

  const [showModalEnvioPesquisas, setShowModalEnvioPesquisas] = useState(false)

  const [vetorComportamental, setVetorComportamental] = useState(false)

  const [valoresOrganizacionais, setValoresOrganizacionais] = useState(false)

  const [enviarEmails, setEnviarEmails] = useState(false)

  const [observacao, setObservacao] = useState(null)

  const [showModalContratarCandidato, setShowModalContratarCandidato] =
    useState(false)

  // A quantidade de candidatos selecionadas é alterada quando for feito o check individual
  useEffect(() => {
    const qtd = candidatosChecked.filter((c) => c.checked === true)
    setCandidatosCheckedTotal(qtd.length)
  }, [candidatosChecked])

  const [showModalCancelarProcesso, setShowModalCancelarProcesso] =
    useState(false)

  const [motivoCancelamentoSelected, setMotivoCancelamentoSelected] =
    useState(null)

  const [confirmFinalizacaoText, setConfirmFinalizacaoText] = useState('')

  const [showModalConfirmFinalizacao, setShowModalConfirmFinalizacao] =
    useState(false)

  const [confirmContratacaoText, setConfirmContratacaoText] = useState('')

  const [showModalConfirmContratacao, setShowModalConfirmContratacao] =
    useState(false)

  const [candidatoContratacaoData, setCandidatoContratacaoData] = useState()

  // assumir processo como hunter
  const [showModalBecomeHunter, setShowModalBecomeHunter] = useState(false)
  const [confirmBecomeHunterText, setConfirmBecomeHunterText] = useState()

  const handleChangeNovaEtapa = (etapa) => {
    setNovaEtapa(etapa)
  }

  const handleCloseNovaEtapaModal = () => {
    setShowNovaEtapaModal(false)
    setNovaEtapa(null)
  }

  const handleSubmitNovaEtapa = () => {
    const { processo_id } = match.params

    dispatch(storeEtapa({ processo_id, etapa: novaEtapa })).then(
      ({ response }) => {
        if (response.status === 200) {
          toast.success('Etapa cadastrada com sucesso.')
          handleCloseNovaEtapaModal()
          loadEtapas(processo_id)
        } else toast.error('Erro ao cadastrar nova etapa.')
      }
    )
  }

  const handleDeleteEtapa = (etapa_id) => {
    const { processo_id } = match.params

    dispatch(deleteEtapa({ processo_id: processo.processo_id, etapa_id })).then(
      ({ response }) => {
        if (response.status === 200) {
          toast.success('Etapa excluída.')
          loadEtapas(processo_id)
          loadCandidatos(processo_id)
        } else toast.error('Erro ao excluir etapa.')
      }
    )
  }

  const handleCheckAll = (etapa_id) => {
    /* const candidatosCheckedTemp = candidatosChecked
     const candidatosCheckedFiltered = candidatosCheckedTemp.filter(
      (c) =>
        c.etapa_id !== etapa_id && c.etapa_id && parseInt(c.contratado) === 0
    )
    const allCheckedFiltered = allChecked.filter(
      (e) => e.etapa_id !== etapa_id && e.etapa_id
    ) */

    const filteredCandidates = candidatosChecked.filter(
      (e) => e.etapa_id === etapa_id
    )

    setCandidatosToCheck(
      filteredCandidates.map((c) => ({ ...c, checked: true }))
    )

    setAllChecked(filteredCandidates.map((c) => ({ ...c, checked: true })))

    setCandidatosCheckedTotal(
      filteredCandidates.map((c) => ({ ...c, checked: true })).length
    )
    setCandidatosChecked(
      filteredCandidates.map((c) => ({ ...c, checked: true }))
    )

    setKanbanIdSelected(etapa_id)
  }

  const handleUncheckAll = (etapa_id) => {
    /* const candidatosCheckedTemp = candidatosChecked
    const candidatosCheckedFiltered = candidatosCheckedTemp.filter(
      (c) => c.etapa_id !== etapa_id && c.etapa_id
    )
    const allCheckedFiltered = allChecked.filter(
      (e) => e.etapa_id !== etapa_id && e.etapa_id
    ) */

    const filteredCandidates = candidatosChecked.filter(
      (e) => e.etapa_id === etapa_id
    )

    setCandidatosToCheck(
      filteredCandidates.map((c) => ({ ...c, checked: false }))
    )

    setAllChecked(filteredCandidates.map((c) => ({ ...c, checked: false })))

    setCandidatosCheckedTotal(0)

    setCandidatosChecked(
      filteredCandidates.map((c) => ({ ...c, checked: false }))
    )
  }

  useEffect(() => {
    const value = candidatosToCheck.filter(
      (c) => c.etapa_id === kanbanIdSelected && c.checked === true
    ).length
    setCandidatosCheckedTotal(value)
  }, [candidatosToCheck, kanbanIdSelected])

  const handleCheckCandidato = (etapacand_id) => {
    const valueFilter =
      candidatosToCheck.length <= 0 ? candidatosChecked : candidatosToCheck
    const candidatosCheckedFiltered = valueFilter.filter(
      (c) => c.etapacand_id !== etapacand_id && c.etapacand_id
    )

    setCandidatosToCheck([
      ...candidatosCheckedFiltered,
      ...valueFilter
        .filter((c) => c.etapacand_id === etapacand_id)
        .map((c) => ({ ...c, checked: !c.checked })),
    ])

    setCandidatosChecked([
      ...candidatosCheckedFiltered,
      ...valueFilter
        .filter((c) => c.etapacand_id === etapacand_id)
        .map((c) => ({ ...c, checked: !c.checked })),
    ])

    const etapa_id = valueFilter.filter((c) => c.etapacand_id === etapacand_id)

    setKanbanIdSelected(etapa_id[0]?.etapa_id ? etapa_id[0].etapa_id : 0)
  }

  const loadCandidatos = useCallback(
    (processo_id) => {
      setLoadingCandidatos(true)
      dispatch(listCandidatos(processo_id)).then(({ response }) => {
        if (response.status === 200) {
          const { candidatos } = response.data
          setCandidatosChecked(
            candidatos.map((c) => ({
              etapa_id: c.etapa,
              pes_id: c.pes_id,
              etapacand_id: c.etapacand_id,
              checked: false,
            }))
          )
        } else toast.error('Erro ao listar candidatos do processo seletivo.')

        setLoadingCandidatos(false)
      })
    },
    [dispatch]
  )

  const loadEtapas = useCallback(
    (processo_id) => {
      dispatch(listEtapas(processo_id)).then(({ response }) => {
        if (response.status === 200) {
          const { etapas } = response.data
          setEtapas(etapas)
          setAllChecked(
            etapas.map((e) => ({ etapa_id: e.etapa_id, checked: false }))
          )
        }
      })
    },
    [dispatch]
  )

  const checkHunter = useCallback(
    (processo_id) => {
      dispatch(getHunter(processo_id)).then(({ response }) => {
        if (response.status === 204) {
          setConfirmBecomeHunterText('Deseja assumir este Processo Seletivo?')
          setShowModalBecomeHunter(true)
        } else {
          setConfirmBecomeHunterText(undefined)
          setShowModalBecomeHunter(false)
        }
      })
    },
    [dispatch]
  )

  const loadCandidatosDisponiveis = useCallback(
    (processo_id) => {
      setLoadingCandidatosDisponiveis(true)
      setLoadingColaboradoresDisponiveis(true)
      dispatch(getCandidatosDisponiveis(processo_id)).then(({ response }) => {
        if (response.status === 200) {
          const { candidatos: candidatosData } = response.data

          setCandidatosDisponiveisRaw(
            candidatosData.filter((c) => parseInt(c.ativorelatorios) === 0)
          )

          setColaboradoresDisponiveis(
            candidatosData.filter((c) => parseInt(c.ativorelatorios) === 1)
          )
        }
        setLoadingCandidatosDisponiveis(false)
        setLoadingColaboradoresDisponiveis(false)
      })
    },
    [dispatch]
  )

  const loadCandidatosDisponiveisProcesso = useCallback(
    (processo_id) => {
      setLoadingCandidatosDisponiveisProcesso(true)
      dispatch(getCandidatosDisponiveisProcesso(processo_id)).then(
        ({ response }) => {
          if (response.status === 200) {
            const { candidatos: candidatosData } = response.data
            setCandidatosDisponiveisProcesso(candidatosData)
          }
        },
        setLoadingCandidatosDisponiveisProcesso(false)
      )
    },
    [dispatch]
  )

  const loadColaboradoresDisponiveisProcesso = useCallback(
    (processo_id) => {
      setLoadingColaboradoresDisponiveisProcesso(true)
      dispatch(getColaboradoresDisponiveisProcesso(processo_id)).then(
        ({ response }) => {
          if (response.status === 200) {
            const { colaboradores: colaboradoresData } = response.data
            setColaboradoresDisponiveisProcesso(colaboradoresData)
          }
        },
        setLoadingColaboradoresDisponiveisProcesso(false)
      )
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(clearKanban())
    const { processo_id } = match.params
    dispatch(checkProcesso(processo_id)).then(({ response }) => {
      if (response.status === 200) {
        dispatch(getDetails(processo_id))
        loadEtapas(processo_id)
        loadCandidatos(processo_id)
        dispatch(listMotivosCancelamento())
        checkHunter(processo_id)
        loadColaboradoresDisponiveisProcesso(processo_id)
        loadCandidatosDisponiveisProcesso(processo_id)
      } else {
        history.goBack()
      }
    })
  }, [
    checkHunter,
    dispatch,
    history,
    loadCandidatos,
    loadColaboradoresDisponiveisProcesso,
    loadCandidatosDisponiveisProcesso,
    loadEtapas,
    match.params,
  ])

  useEffect(() => {
    return () => dispatch(clearKanban())
  }, [dispatch])

  useEffect(() => {
    if (candidatosCargoFilter && processo.cargo) {
      const newCandidatosDisponiveis = [
        ...candidatosDisponiveisRaw.filter(
          (c) =>
            c.cargos &&
            c.cargos.findIndex((cargo) => cargo.cargo_id === processo.cargo) >=
              0
        ),
      ]

      setCandidatosDisponiveis(newCandidatosDisponiveis)
    } else {
      setCandidatosDisponiveis([...candidatosDisponiveisRaw])
    }
  }, [candidatosCargoFilter, candidatosDisponiveisRaw, processo.cargo])

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
  })

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result

    if (!destination) {
      return
    }

    const { droppableId: sourceId } = source
    const { droppableId: destinationId, index: destinationIndex } = destination

    let new_etapa_id

    if (sourceId !== destinationId) {
      new_etapa_id = destinationId
    }

    const { processo_id } = match.params

    dispatch(
      updateEtapaCandidato({
        etapacand_id: draggableId,
        new_etapa_id,
        processo_id: processo.processo_id,
        index: destinationIndex,
      })
    ).then(({ response }) => {
      if (response.status === 200) {
        loadCandidatos(processo_id)
      } else toast.error('Erro ao mover candidato.')
    })
  }

  /* Enviar email manual - apenas um usuário */
  const handleSendEmailManual = useCallback(
    ({ textoemail }) => {
      const { processo_id } = match.params
      if (textoemail.level === undefined) {
        dispatch(
          sendEmailManual({
            processo_id,
            etapacand_id: etapaCandidatoId,
            id_email: idEmailManualEnviar,
            etapa_id: etapaIdSelected,
            textoemail,
          })
        ).then(({ response }) => {
          if (response.status === 200) {
            toast.success('E-mail enviado com sucesso!')
            setShowModalConfirmarEmailEnviado(false)
          }
        })
      } else {
        dispatch(
          sendEmailManual({
            processo_id,
            etapacand_id: etapaCandidatoId,
            id_email: idEmailManualEnviar,
            etapa_id: etapaIdSelected,
            textoemail: textoemail.level.content,
          })
        ).then(({ response }) => {
          if (response.status === 200) {
            toast.success('E-mail enviado com sucesso!')
            setShowModalConfirmarEmailEnviado(false)
          }
        })
      }
    },
    [
      dispatch,
      etapaCandidatoId,
      idEmailManualEnviar,
      etapaIdSelected,
      match.params,
    ]
  )

  /* Enviar email manual - vários usuários */
  const handleSendEmailManualVariosUsuarios = useCallback(
    ({ textoemail }) => {
      const { processo_id } = match.params

      const candidatosToSendEmail = candidatosToCheck.filter(
        (c) => c.checked && c.etapa_id === kanbanIdSelected
      )
      for (let i = 0; i < candidatosToSendEmail.length; i++) {
        if (textoemail.level === undefined) {
          dispatch(
            sendEmailManual({
              processo_id,
              etapacand_id: candidatosToSendEmail[i].etapacand_id,
              id_email: idEmailManualEnviar,
              etapa_id: candidatosToSendEmail[i].etapa_id,
              textoemail,
            })
          ).then(({ response }) => {
            if (response.status === 200) {
              toast.success('E-mail enviado com sucesso!')
              setShowModalConfirmarEmailEnviado(false)
            }
          })
        } else {
          dispatch(
            sendEmailManual({
              processo_id,
              etapacand_id: candidatosChecked[i].etapacand_id,
              id_email: idEmailManualEnviar,
              etapa_id: candidatosChecked[i].etapa_id,
              textoemail: textoemail.level.content,
            })
          ).then(({ response }) => {
            if (response.status === 200) {
              toast.success('E-mail enviado com sucesso!')
              setShowModalConfirmarEmailEnviado(false)
            }
          })
        }
      }
    },
    [
      dispatch,
      candidatosChecked,
      idEmailManualEnviar,
      candidatosToCheck,
      kanbanIdSelected,
      match.params,
    ]
  )

  const handleChangeCandidatoRate = ({ etapacand_id, rate }) => {
    const { processo_id } = match.params

    dispatch(rateCandidato({ etapacand_id, rate, processo_id }))
  }

  const handleChangeObservacao = (value) => setObservacao(value)

  const handleOpenNovaObservacao = (etapacand_id) => {
    setCandidatoSelected(etapacand_id)
    setCandidatoUpdate({})
    setShowModalNovaObservacao(true)
  }

  const handleOpenVideo = (video) => {
    setVideoSelected(video)
    setShowModalVideo(true)
  }

  const handleCloseVideo = () => {
    setShowModalVideo(false)
  }

  const handleOpenEnviarEmail = (etapacand_id, etapa_id) => {
    setShowModalEnviarEmail(true)
    setEtapaCandidatoId(etapacand_id)
    setEtapaIdSelected(etapa_id)
    handleUncheckAll(etapa_id)
  }

  const handleOpenEnviarEmailVariosUsuarios = (etapacand_id) => {
    setShowModalEnviarEmail(true)
    setEtapaCandidatoId(etapacand_id)
  }

  const handleCloseNovaObservacao = () => {
    setShowModalNovaObservacao(false)
    setObservacao(null)
  }

  const handleCloseEnviarEmail = () => {
    setShowModalEnviarEmail(false)
    setObservacao(null)
  }

  const handleSubmitNovaObservacao = () => {
    const { processo_id } = match.params

    if (observacao && candidatoSelected) {
      dispatch(
        updateEtapaCandidato({
          etapacand_id: candidatoSelected,
          processo_id,
          observacao,
        })
      ).then(({ response }) => {
        if (response.status === 200) {
          handleCloseNovaObservacao()
          toast.success('Observação gravada com sucesso.')
        } else toast.error('Erro ao editar observação.')
      })
    }
  }

  const openModalMotivoExclusao = (etapacand_id) => {
    setShowModalMotivoExclusao(true)
    setCandidatoSelected(etapacand_id)
  }

  const closeModalMotivoExclusao = () => {
    setShowModalMotivoExclusao(false)
    setMotivoExclusao(null)
    setCandidatoSelected(null)
  }

  const handleChangeMotivoExclusao = (value) => {
    setMotivoExclusao(value)
  }

  const handleSubmitDeleteCandidato = () => {
    const { processo_id } = match.params

    dispatch(
      deleteCandidato({
        processo_id,
        etapacand_id: candidatoSelected,
        motivoExclusao,
      })
    ).then(({ response }) => {
      if (response.status === 200) {
        loadCandidatos(processo_id)
        loadCandidatosDisponiveis(processo_id)
        toast.success('Candidato removido do Processo Seletivo.')
        closeModalMotivoExclusao()
      } else toast.error('Erro ao remover candidato do Processo Seletivo.')
    })
  }

  const handleChangeCheckboxPesquisas = ({ target }) => {
    const actions = {
      vetorComportamental: () => setVetorComportamental(!vetorComportamental),
      valoresOrganizacionais: () =>
        setValoresOrganizacionais(!valoresOrganizacionais),
      enviarEmails: () => setEnviarEmails(!enviarEmails),
    }

    if (actions[target.name]) {
      actions[target.name]()
    }
  }

  const openModalEnvioPesquisas = () => {
    const candidatos = candidatosChecked.filter(
      (c) => c.etapacand_id && c.checked === true
    )

    if (candidatos.length > 0) {
      setShowModalEnvioPesquisas(true)
    } else
      toast.warn(
        'Selecione os candidatos desejados antes de enviar uma pesquisa.'
      )
  }

  const closeModalEnvioPesquisas = () => {
    setShowModalEnvioPesquisas(false)
    setValoresOrganizacionais(false)
    setVetorComportamental(false)
    setEnviarEmails(false)
  }

  const submitModalEnvioPesquisas = () => {
    const candidatos = candidatosChecked.filter(
      (c) => c.etapacand_id && c.checked === true
    )

    const pessoas = []

    for (const c of candidatos) {
      pessoas.push(c.pes_id)
    }

    if (vetorComportamental) {
      dispatch(sendVetorComportamental({ pessoas }, { enviarEmails })).then(
        ({ response }) => {
          if (response.status === 200) {
            toast.success(
              'Pesquisas de Vetor Comportamental enviadas com sucesso.'
            )
          } else {
            toast.error('Erro ao enviar pesquisas de Vetor Comportamental.')
          }
        }
      )
    }

    if (valoresOrganizacionais) {
      dispatch(sendValoresOrganizacionaisLote({ pessoas, enviarEmails })).then(
        ({ response }) => {
          if (response.status === 200) {
            toast.success(
              'Pesquisas de Valores Organizacionais enviadas com sucesso.'
            )
          } else {
            toast.error('Erro ao enviar pesquisas de Valores Organizacionais.')
          }
        }
      )
    }

    closeModalEnvioPesquisas()
  }

  const openModalContratarCandidato = () => setShowModalContratarCandidato(true)

  const handleContratarCandidato = (etapacand_id) => {
    setCandidatoSelected(etapacand_id)

    openModalContratarCandidato()
  }

  const handleChangeContratacaoData = (changedValues, allValues) => {
    setCandidatoContratacaoData(allValues)
  }

  const handleClearContratacaoCandidato = () => {
    setShowModalContratarCandidato(false)
    setCandidatoContratacaoData(null)
  }

  const handleSubmitContratacaoCandidato = ({ confirm = false }) => {
    const { processo_id } = match.params

    setShowModalContratarCandidato(false)

    dispatch(
      validateContratacao({ etapacand_id: candidatoSelected, processo_id })
    ).then(({ response }) => {
      if (response.status === 204 || confirm === true) {
        dispatch(
          contratarCandidato({
            etapacand_id: candidatoSelected,
            processo_id,
            ...candidatoContratacaoData,
          })
        ).then(({ response }) => {
          if (response.status === 200) {
            toast.success('Candidato contratado.')
            handleClearContratacaoCandidato()
            closeModalConfirmContratacao()
            loadCandidatos(processo_id)
          } else if (response.status === 412) {
            toast.error(response.data.message)
            openModalContratarCandidato()
            setShowModalConfirmContratacao(false)
          } else {
            toast.error('Erro ao contratar candidato.')
            handleClearContratacaoCandidato()
          }
        })
      } else if (response.status === 412) {
        setConfirmContratacaoText(response.data.message)
        setShowModalConfirmContratacao(true)
      }
    })
  }

  const closeModalConfirmContratacao = () => {
    setShowModalConfirmContratacao(false)
    setConfirmContratacaoText('')
    handleClearContratacaoCandidato()
  }

  const closeModalCancelarProcesso = () => {
    setMotivoCancelamentoSelected(null)
    setShowModalCancelarProcesso(false)
  }

  const submitModalCancelarProcesso = () => {
    const { processo_id } = match.params

    if (motivoCancelamentoSelected) {
      dispatch(
        cancelProcesso({ processo_id, cancel_id: motivoCancelamentoSelected })
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Processo Seletivo cancelado.')
          history.push(`/${contaUrl}/recrutamento-selecao`)
        } else {
          toast.error('Erro ao cancelar Processo Seletivo.')
        }
      })
    }
  }

  const handleChangeMotivoCancelamento = (option) => {
    setMotivoCancelamentoSelected(option)
  }

  const handleFinalizarProcessoSeletivo = (confirm = false) => {
    const { processo_id } = match.params

    dispatch(validateFinalizarProcesso(processo_id)).then(({ response }) => {
      if (response.status === 204 || confirm) {
        dispatch(finalizarProcesso(processo_id)).then(({ response }) => {
          if (response.status === 200) {
            toast.success('Processo Seletivo finalizado.')
            history.push(`/${contaUrl}/recrutamento-selecao`)
          } else if (response.status === 500) {
            toast.error('Erro ao finalizar Processo Seletivo.')
          }
        })
      } else if (response.status === 412) {
        setConfirmFinalizacaoText(response.data.message)
        setShowModalConfirmFinalizacao(true)
      }
    })
  }

  const closeModalConfirmFinalizacao = () => {
    setShowModalConfirmFinalizacao(false)
  }

  const handleInitUpdateEtapa = useCallback((etapa_id) => {
    setEtapas((state) =>
      state.map((etapa) =>
        etapa.etapa_id === etapa_id ? { ...etapa, editing: true } : etapa
      )
    )
  }, [])

  const handleUpdateEtapa = useCallback((etapa_id, data) => {
    setEtapas((state) =>
      state.map((etapa) =>
        etapa.etapa_id === etapa_id ? { ...etapa, ...data } : etapa
      )
    )
  }, [])

  const handleConfirmUpdateEtapa = useCallback(
    (etapa_id) => {
      const etapa = etapas.find((e) => e.etapa_id === etapa_id)

      if (etapa) {
        const { processo_id } = match.params
        if (etapa.etapa.length > 20) {
          toast.error('Nome da etapa deve conter no máximo 20 caracteres.')
        } else {
          dispatch(
            updateEtapa({ processo_id, etapa_id, data: { ...etapa } })
          ).then(({ response }) => {
            if (response.status === 200) {
              toast.success('Etapa atualizada com sucesso.')
              setEtapas((state) =>
                state.map((etapa) =>
                  etapa.etapa_id === etapa_id
                    ? { ...etapa, editing: false }
                    : etapa
                )
              )
            }
          })
        }
      }
    },
    [dispatch, etapas, match.params]
  )

  const handleCancelUpdateEtapa = useCallback((etapa_id) => {
    setEtapas((state) =>
      state.map((etapa) =>
        etapa.etapa_id === etapa_id ? { ...etapa, editing: false } : etapa
      )
    )
  }, [])

  const handleSubmitBecomeHunter = useCallback(() => {
    const userPesId = getUserPesId()

    if (userPesId) {
      const { processo_id } = match.params

      dispatch(updateHunter(processo_id, userPesId)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Processo assumido com sucesso.')
        }

        setShowModalBecomeHunter(false)
        setConfirmBecomeHunterText(undefined)
      })
    } else toast.error('Erro ao verificar usuário logado.')
  }, [dispatch, match.params])

  const handleCancelBecomeHunter = useCallback(() => {
    setShowModalBecomeHunter(false)
    setConfirmBecomeHunterText(undefined)
  }, [])

  const handleCloseTriagem = useCallback(() => {
    setShowModalCandidatosDisponiveis(false)
    setSelectedRowKeysCandidatos([])
    setSelectedRowKeysColaboradores([])
    setSelectedRowsCandidatos([])
    setSelectedRowsColaboradores([])
  }, [])

  const handleTriarCandidatos = useCallback(
    (confirm = false) => {
      const { processo_id } = match.params
      setTriandoCandidatos(true)
      dispatch(
        triarCandidatos({
          processo_id,
          candidatos: [
            ...selectedRowKeysCandidatos,
            ...selectedRowKeysColaboradores,
          ],
          confirm,
        })
      ).then(({ response }) => {
        if (response.status === 200) {
          loadColaboradoresDisponiveisProcesso(processo_id)
          loadCandidatosDisponiveisProcesso(processo_id)
          loadCandidatos(processo_id)
          setSelectedRowKeysCandidatos([])
          setSelectedRowKeysColaboradores([])
          setSelectedRowsCandidatos([])
          setSelectedRowsColaboradores([])
          setConfirmTriagemText('')
          setCandidatosToConfirm([])
          setShowModalConfirmTriagem(false)
          toast.success('Candidatos triados com sucesso.')
        } else if (response.status === 412) {
          const { message, candidatosToConfirm: candidatosData } = response.data

          setConfirmTriagemText(message)
          setCandidatosToConfirm(candidatosData.map((c) => c.pes_nome))
          setShowModalConfirmTriagem(true)
        }
        setTriandoCandidatos(false)
      })
    },
    [
      dispatch,
      loadCandidatos,
      loadColaboradoresDisponiveisProcesso,
      loadCandidatosDisponiveisProcesso,
      match.params,
      selectedRowKeysCandidatos,
      selectedRowKeysColaboradores,
    ]
  )

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder="Buscar"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    setSearchText(selectedKeys[0])
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const onSelectChangeCandidatos = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeysCandidatos(newSelectedRowKeys)
    setSelectedRowsCandidatos(newSelectedRows)
  }

  const onSelectChangeColaboradores = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeysColaboradores(newSelectedRowKeys)
    setSelectedRowsColaboradores(newSelectedRows)
  }

  const candidatosDisponiveisColumns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      width: 200,
      align: 'center',
      fixed: 'left',
      sorter: (a, b) => sorterString(a, b, 'nome'),
      ...getColumnSearchProps('nome'),
      render: (text, record) => {
        return (
          <Link to={`/perfilCandidato/${record.pes_id}`} target="_blank">
            <span>{record.nome}</span>
          </Link>
        )
      },
    },
    {
      title: 'Profissão',
      dataIndex: 'profissao',
      width: 150,
      align: 'center',
      ...getColumnSearchProps('profissao'),
    },
    {
      title: 'Idade',
      dataIndex: 'dataNascimento',
      width: 120,
      align: 'center',
      sorter: (a, b) => Number(a.dataNascimento) - Number(b.dataNascimento),
      render: (text, record) => {
        return record.dataNascimento
      },
    },
    {
      title: 'Salário pretendido',
      dataIndex: 'pretensaoSalarial',
      width: 80,
      align: 'center',
      sorter: (a, b) =>
        parseFloat(a.pretensaoSalarial) - parseFloat(b.ppretensaoSalarial),
    },
    {
      title: 'Fit Cultural',
      dataIndex: ['fitCultural', 'geral'],
      width: 100,
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => sorterNumber(a, b, ['fitCultural', 'geral']),
    },
  ]

  const handleSwitchCandidatoFilter = useCallback(() => {
    setCandidatosCargoFilter((state) => !state)
  }, [])

  return (
    <>
      <Container>
        {processo.status !== 'suspenso' &&
          processo.status !== 'finalizado' &&
          processo.status !== 'cancelado' && (
            <Header>
              <div className="select-filter">
                <FilterIndicator>
                  <FilterOutlined />
                </FilterIndicator>
                <Select
                  placeholder="Insira o filtro"
                  value={candidatoFit}
                  withoutMargin
                  onChange={(value) => handleMudarFiltro(value)}
                  options={[
                    { label: 'Todos Canditatos', value: 'all' },
                    { label: 'Somente com Fit', value: 'fit' },
                    { label: 'Somente sem Fit', value: 'nofit' },
                  ]}
                />
              </div>

              <Button
                type="default"
                icon={<ShareAltOutlined />}
                className="ps-kanban__button__enviar-pesquisas"
                onClick={() => openModalEnvioPesquisas()}
              >
                PESQUISAS
              </Button>
            </Header>
          )}

        <KanbanContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            {etapas.map((etapa) => (
              <EtapaWrapper>
                <EtapaHeader>
                  {etapa.editing &&
                  processo.status !== 'suspenso' &&
                  processo.status !== 'finalizado' &&
                  processo.status !== 'cancelado' ? (
                    <Input
                      defaultValue={etapa.etapa}
                      suffix={
                        <EtapaEditInputIcons>
                          <CheckCircleOutlined
                            onClick={() =>
                              handleConfirmUpdateEtapa(etapa.etapa_id)
                            }
                            className="etapa__edit__confirm"
                          />
                          <CloseCircleOutlined
                            onClick={() =>
                              handleCancelUpdateEtapa(etapa.etapa_id)
                            }
                            className="etapa__edit__cancel"
                          />
                        </EtapaEditInputIcons>
                      }
                      onChange={(e) =>
                        handleUpdateEtapa(etapa.etapa_id, {
                          etapa: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <span>{etapa.etapa}</span>
                  )}
                  <div>
                    {!etapa.editing &&
                      processo.status !== 'suspenso' &&
                      processo.status !== 'finalizado' &&
                      processo.status !== 'cancelado' && (
                        <>
                          {kanbanIdSelected === etapa.etapa_id &&
                            candidatosCheckedTotal >= 2 && (
                              <MailOutlined
                                onClick={handleOpenEnviarEmailVariosUsuarios}
                              />
                            )}
                          <EditOutlined
                            onClick={() =>
                              handleInitUpdateEtapa(etapa.etapa_id)
                            }
                          />
                          <Popconfirm
                            title="Tem certeza que deseja excluir essa etapa?"
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={() => handleDeleteEtapa(etapa.etapa_id)}
                            icon={
                              <ExclamationCircleFilled
                                style={{ color: '#ef5472' }}
                              />
                            }
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                          <Checkbox
                            onClick={() =>
                              allChecked.find(
                                (e) => e.etapa_id === etapa.etapa_id
                              ) &&
                              allChecked.find(
                                (e) => e.etapa_id === etapa.etapa_id
                              ).checked === false
                                ? handleCheckAll(etapa.etapa_id)
                                : handleUncheckAll(etapa.etapa_id)
                            }
                            checked={
                              kanbanIdSelected === etapa.etapa_id &&
                              candidatosCheckedTotal ===
                                candidatosToCheck.filter(
                                  (c) => c.etapa_id === etapa.etapa_id
                                ).length
                            }
                          />
                        </>
                      )}
                  </div>
                </EtapaHeader>
                <Droppable
                  key={etapa.etapa_id}
                  droppableId={`${etapa.etapa_id}`}
                >
                  {(provided, snapshot) => (
                    <EtapaContainer
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      loading={loadingCandidatos}
                    >
                      {!loadingCandidatos ? (
                        candidatos
                          .filter((c) => c.etapa === etapa.etapa_id)
                          .map((c, index) =>
                            processo.status !== 'suspenso' &&
                            processo.status !== 'finalizado' &&
                            processo.status !== 'cancelado' ? (
                              <Draggable
                                draggableId={`${c.etapacand_id}`}
                                key={c.etapacand_id}
                                index={parseInt(c.ordem)}
                              >
                                {(provided, snapshot) => (
                                  <Candidato
                                    processo={processo}
                                    handleOpenVideo={handleOpenVideo}
                                    etapaSelectedId={etapa.etapa_id}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    handleOpenEnviarEmail={
                                      handleOpenEnviarEmail
                                    }
                                    handleContratarCandidato={
                                      handleContratarCandidato
                                    }
                                    openModalMotivoExclusao={
                                      openModalMotivoExclusao
                                    }
                                    handleOpenNovaObservacao={
                                      handleOpenNovaObservacao
                                    }
                                    handleCheckCandidato={handleCheckCandidato}
                                    checked={
                                      candidatosToCheck.find(
                                        (cc) =>
                                          cc.etapacand_id === c.etapacand_id
                                      )
                                        ? candidatosToCheck.find(
                                            (cc) =>
                                              cc.etapacand_id === c.etapacand_id
                                          ).checked
                                        : null
                                    }
                                    ref={provided.innerRef}
                                    draggableProps={provided.draggableProps}
                                    dragHandleProps={provided.dragHandleProps}
                                    isDragging={snapshot.isDragging}
                                    scrollWidth={getScrollbarWidth()}
                                    {...c}
                                  />
                                )}
                              </Draggable>
                            ) : (
                              <Draggable
                                draggableId={`${c.etapacand_id}`}
                                key={c.etapacand_id}
                                index={parseInt(c.ordem)}
                                isDragDisabled
                              >
                                {(provided, snapshot) => (
                                  <Candidato
                                    processo={processo}
                                    handleOpenEnviarEmail={
                                      handleOpenEnviarEmail
                                    }
                                    handleContratarCandidato={
                                      handleContratarCandidato
                                    }
                                    openModalMotivoExclusao={
                                      openModalMotivoExclusao
                                    }
                                    handleOpenNovaObservacao={
                                      handleOpenNovaObservacao
                                    }
                                    handleOpenVideo={handleOpenVideo}
                                    etapaSelectedId={etapa.etapa_id}
                                    handleCheckCandidato={handleCheckCandidato}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    checked={
                                      candidatosToCheck.find(
                                        (cc) =>
                                          cc.etapacand_id === c.etapacand_id
                                      )
                                        ? candidatosToCheck.find(
                                            (cc) =>
                                              cc.etapacand_id === c.etapacand_id
                                          ).checked
                                        : null
                                    }
                                    ref={provided.innerRef}
                                    draggableProps={provided.draggableProps}
                                    dragHandleProps={provided.dragHandleProps}
                                    isDragging={snapshot.isDragging}
                                    scrollWidth={getScrollbarWidth()}
                                    handleChangeCandidatoRate={
                                      handleChangeCandidatoRate
                                    }
                                    {...c}
                                  />
                                )}
                              </Draggable>
                            )
                          )
                      ) : (
                        <Spin size="large" />
                      )}
                      {provided.placeholder}
                    </EtapaContainer>
                  )}
                </Droppable>
              </EtapaWrapper>
            ))}
          </DragDropContext>
          {processo.status !== 'suspenso' &&
            processo.status !== 'finalizado' &&
            processo.status !== 'cancelado' && (
              <Button
                icon={<PlusOutlined />}
                onClick={() => setShowNovaEtapaModal(true)}
                type="dashed"
                size="large"
              >
                Adicionar Etapa
              </Button>
            )}
        </KanbanContainer>
      </Container>
      <Modal
        visible={showNovaEtapaModal}
        title="ADICIONAR NOVA ETAPA"
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => handleCloseNovaEtapaModal()}
        destroyOnClose
        key="1"
        okButtonProps={{
          form: 'nova-etapa-submit',
          key: 'submit',
          htmlType: 'submit',
        }}
      >
        <NovaEtapa
          handleChangeNovaEtapa={handleChangeNovaEtapa}
          etapa={novaEtapa}
          onFinish={handleSubmitNovaEtapa}
        />
      </Modal>
      <Modal
        visible={showModalNovaObservacao}
        title="REGISTRAR OBSERVAÇÃO"
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => handleCloseNovaObservacao()}
        destroyOnClose
        key="2"
        okButtonProps={{
          form: 'registrar-observacao-submit',
          key: 'submit',
          htmlType: 'submit',
        }}
      >
        <RegistrarObservacao
          handleChangeObservacao={handleChangeObservacao}
          onFinish={handleSubmitNovaObservacao}
          observacao={observacao}
        />
      </Modal>
      <Modal
        visible={showModalVideo}
        title={
          <>
            <YoutubeOutlined /> VÍDEO DO CANDIDATO
          </>
        }
        onCancel={() => handleCloseVideo()}
        destroyOnClose
        key="14"
        footer={null}
      >
        <ReactPlayer url={videoSelected} muted controls width="100%" />
      </Modal>
      <Modal
        visible={showModalEnviarEmail}
        title="ENVIAR NOVO E-MAIL"
        cancelText="CANCELAR"
        okText="ENVIAR"
        onCancel={() => handleCloseEnviarEmail()}
        destroyOnClose
        key="3"
        width="75%"
        okButtonProps={{
          form: 'enviar-email-manual',
          key: 'submit',
          htmlType: 'submit',
          disabled: sendingEmail,
        }}
        onOk={() => setShowModalConfirmarEmailEnviado(true)}
      >
        <EnviarEmail
          handleChangeObservacao={handleChangeObservacao}
          onFinish={
            candidatosCheckedTotal > 0
              ? handleSendEmailManualVariosUsuarios
              : handleSendEmailManual
          }
          templatesManuais={templatesManuais}
          setIdEmailManualEnviar={setIdEmailManualEnviar}
          parametros={parametros}
        />
      </Modal>
      <Modal
        visible={showModalMotivoExclusao}
        title="MOTIVO EXCLUSÃO"
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => closeModalMotivoExclusao()}
        destroyOnClose
        key="4"
        okButtonProps={{
          form: 'motivo-exclusao-submit',
          key: 'submit',
          htmlType: 'submit',
        }}
      >
        <MotivoExclusao
          handleChangeMotivoExclusao={handleChangeMotivoExclusao}
          motivoExclusao={motivoExclusao}
          onFinish={handleSubmitDeleteCandidato}
        />
      </Modal>
      <Modal
        visible={showModalEnvioPesquisas}
        title="ENVIAR PESQUISAS"
        cancelText="CANCELAR"
        okText="ENVIAR"
        onCancel={() => closeModalEnvioPesquisas()}
        destroyOnClose
        key="5"
        onOk={() => submitModalEnvioPesquisas()}
      >
        <EnviarPesquisa
          isCandidato
          handleChangeCheckbox={handleChangeCheckboxPesquisas}
        />
      </Modal>
      <Modal
        visible={showModalContratarCandidato}
        title="CONTRATAR CANDIDATO"
        cancelText="CANCELAR"
        okText="ENVIAR"
        onCancel={() => handleClearContratacaoCandidato()}
        destroyOnClose
        key="6"
        okButtonProps={{
          form: 'contratar-candidato-submit',
          key: 'submit',
          htmlType: 'submit',
        }}
      >
        <ContratarCandidato
          onValuesChange={handleChangeContratacaoData}
          onFinish={handleSubmitContratacaoCandidato}
          initialValues={
            candidatoContratacaoData || {
              data_contratacao: moment(),
              pes_cpf: candidatos.find(
                (c) => c.etapacand_id === candidatoSelected
              )
                ? candidatos.find((c) => c.etapacand_id === candidatoSelected)
                    .pes_cpf
                : undefined,
            }
          }
        />
      </Modal>
      <Modal
        visible={showModalCancelarProcesso}
        title="CANCELAR PROCESSO SELETIVO"
        cancelText="CANCELAR"
        okText="CONFIRMAR"
        onCancel={() => closeModalCancelarProcesso()}
        destroyOnClose
        key="7"
        okButtonProps={{
          form: 'cancelar-processo-submit',
          key: 'submit',
          htmlType: 'submit',
        }}
      >
        <CancelarProcesso
          motivosCancelamento={motivosCancelamento}
          handleChangeMotivoCancelamento={handleChangeMotivoCancelamento}
          cancel_id={motivoCancelamentoSelected}
          onFinish={submitModalCancelarProcesso}
        />
      </Modal>
      <Modal
        visible={showModalConfirmFinalizacao}
        title="CONFIRMAR FINALIZAÇÃO"
        cancelText="CANCELAR"
        okText="CONFIRMAR"
        onCancel={() => closeModalConfirmFinalizacao()}
        destroyOnClose
        key="8"
        onOk={() => handleFinalizarProcessoSeletivo(true)}
      >
        <Confirm message={confirmFinalizacaoText} />
      </Modal>
      <Modal
        visible={showModalConfirmContratacao}
        title="CONFIRMAR CONTRATAÇÃO"
        cancelText="CANCELAR"
        okText="CONFIRMAR"
        onCancel={() => closeModalConfirmContratacao()}
        destroyOnClose
        key="9"
        onOk={() => handleSubmitContratacaoCandidato({ confirm: true })}
      >
        <Confirm message={confirmContratacaoText} />
      </Modal>
      <Modal
        visible={showModalBecomeHunter}
        title="ASSUMIR PROCESSO SELETIVO"
        cancelText="CANCELAR"
        okText="ASSUMIR"
        onCancel={() => handleCancelBecomeHunter()}
        destroyOnClose
        key="10"
        onOk={() => handleSubmitBecomeHunter()}
      >
        <Confirm message={confirmBecomeHunterText} />
      </Modal>
      <Modal
        visible={showModalTriagem}
        title="TRIAGEM DE CANDIDATOS"
        cancelText="FECHAR"
        okText="TRIAR"
        destroyOnClose
        onCancel={() => handleCloseTriagem()}
        onOk={() => handleTriarCandidatos()}
        width="70%"
        okButtonProps={{
          disabled: triandoCandidatos,
          loading: triandoCandidatos,
        }}
        centered
        key="11"
      >
        <Tabs defaultActiveKey="1">
          <TabPane key="1" tab="Candidatos">
            <Table
              loading={loadingCandidatosDisponiveisProcesso}
              dataSource={candidatosDisponiveisProcesso}
              columns={candidatosDisponiveisColumns}
              rowSelection={{
                selectedRowKeys: selectedRowKeysCandidatos,
                onChange: onSelectChangeCandidatos,
              }}
              rowKey={(record) => record.pes_id}
            />
          </TabPane>
          <TabPane key="2" tab="Colaboradores">
            <Table
              loading={loadingColaboradoresDisponiveisProcesso}
              dataSource={colaboradoresDisponiveisProcesso}
              columns={candidatosDisponiveisColumns}
              rowSelection={{
                selectedRowKeys: selectedRowKeysColaboradores,
                onChange: onSelectChangeColaboradores,
              }}
              rowKey={(record) => record.pes_id}
            />
          </TabPane>
        </Tabs>
      </Modal>
      <Modal
        visible={showModalConfirmTriagem}
        title="CONFIRMAR TRIAGEM"
        cancelText="CANCELAR"
        okText="TRIAR"
        onCancel={() => {
          setShowModalConfirmTriagem(false)
          setCandidatosToConfirm([])
          setConfirmTriagemText('')
        }}
        destroyOnClose
        key="12"
        onOk={() => handleTriarCandidatos(true)}
      >
        <Confirm message={confirmTriagemText} list={candidatosToConfirm} />
      </Modal>

      {/* Modal exibido quando o e-mail para um individuo ou para várias pessoas é enviado */}
      <Modal
        visible={showModalConfirmarEmailEnviado}
        title="AGUARDE, OS E-MAILS ESTÃO SENDO ENVIADOS!"
        destroyOnClose
        key="13"
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Confirm
          message={
            candidatosCheckedTotal === 0
              ? 'Enviando e-mail para o candidato!'
              : 'Enviando e-mail para os candidatos!'
          }
        />
      </Modal>
    </>
  )
}

export default PsKanban
