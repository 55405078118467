import styled from 'styled-components'
import { Col, Row, Card } from 'antd'

export const ChartTitle = styled.span`
  color: #000;
  font-size: 16px;
`

export const ColFill = styled(Col)`
  padding: 0;
  flex: 1;
  .ant-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    .ant-card-body {
      flex: 1;
      .ant-row {
        .ant-col + .ant-col {
          margin-bottom: 8px;
        }
      }
    }
  }
`

export const Tag = styled.span`
  background-color: var(--orange);
  padding: 2px 6px 2px 6px;
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Label = styled.span`
  color: #000;
  font-size: 16px;
  margin-bottom: 24px;
`

export const DestaquesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex: 1;

  ${Tag} {
    align-self: flex-end;
  }

  @media (max-width: 880px) {
    ${Tag} {
      align-self: center;
    }
  }
`

export const DestaquesRow = styled(Row)`
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100%;

  .ant-divider,
  .ant-divider-vertical {
    height: 222px;
    align-self: stretch;
    flex: 1;
    max-width: 1px;
  }

  @media (max-width: 800px) {
    .ant-divider,
    .ant-divider-vertical {
      display: none;
    }
    ${DestaquesContainer} {
      min-width: 100%;
    }
  }
`

export const Box = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  min-width: 33%;

  .ant-statistic .ant-statistic-content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--orange);
    font-weight: 500;
    .ant-statistic-content-value {
      font-size: 32px;
    }
  }

  .ant-statistic .ant-statistic-title {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
  }

  @media (max-width: 768px) {
    min-width: 50%;
  }

  @media (max-width: 620px) {
    min-width: 100%;
  }
`

export const SatisfacaoBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  min-width: 25%;

  .ant-statistic .ant-statistic-content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--purple);
    font-weight: 500;
    .ant-statistic-content-value {
      font-size: 24px;
    }
  }

  .ant-statistic .ant-statistic-title {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
  }

  @media (max-width: 880px) {
    min-width: 50%;
  }

  @media (max-width: 620px) {
    min-width: 100%;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FlexCard = styled(Card)`
  .ant-card-body {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
  }
`

export const ConfiancaLabel = styled.span`
  font-weight: 500;
  font-size: 18px;
  min-width: 120px;
  word-wrap: break-word;
  text-align: start;
`

export const NPSChartPercent = styled(Col)`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const NPSLabel = styled.span`
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  display: block;
  word-wrap: break-word;
  text-align: center;
`

export const NPSLegendaLabel = styled.span`
  width: 100%;
  display: block;
`

export const ConfiancaBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
  min-width: 50%;

  .ant-progress {
    margin-right: 16px;
    .ant-progress-text {
      color: var(--orange);
    }
  }

  @media (min-width: 1600px) and (max-width: 2000px), (max-width: 1270px) {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 8px;
    .ant-progress {
      margin: 0px 0px 8px 0px;
    }
    ${ConfiancaLabel} {
      font-size: 16px;
      text-align: center;
    }
  }

  @media (min-width: 820px) and (max-width: 991px) {
    flex-direction: row-reverse;
    justify-content: center;
    padding: 16px;

    .ant-progress {
      margin-right: 16px;
    }
    ${ConfiancaLabel} {
      font-size: 18px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 8px;
    min-width: 100%;
    .ant-progress {
      margin: 0px 0px 8px 0px;
    }
    ${ConfiancaLabel} {
      font-size: 18px;
      text-align: center;
    }
  }
`
