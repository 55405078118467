// TYPES
export const CONFIG_GET_LIMITES_CONTA = 'CONFIG_GET_LIMITES_CONTA '
export const CONFIG_SOLICITAR_NOVOS_LIMITES = 'CONFIG_SOLICITAR_NOVOS_LIMITES'
export const BLOCK_SOLICITAR_NOVOS_LIMITES = 'BLOCK_SOLICITAR_NOVOS_LIMITES'
export const CONFIG_PESQUISAS_SET_PESOS = 'CONFIG_PESQUISAS_SET_PESOS'
export const CONFIG_PESQUISAS_GET_PESOS = 'CONFIG_PESQUISAS_GET_PESOS'
export const CONFIG_PESQUISAS_SET_FIT_MINIMO = 'CONFIG_PESQUISAS_SET_FIT_MINIMO'
export const CONFIG_PESQUISAS_GET_FIT_MINIMO = 'CONFIG_PESQUISAS_GET_FIT_MINIMO'

// URLS
const URL_CONTA = `${process.env.REACT_APP_API_URL}/conta`
export const URL_GET_LIMITES_CONTA = `${URL_CONTA}/limiteCadastro`
export const URL_SOLICITAR_NOVOS_LIMITES = `${URL_CONTA}/solicitarAumento`
export const URL_CONFIG_PESQUISAS_SET_PESOS = `${process.env.REACT_APP_API_URL}/config/pesquisas/pesos`
export const URL_CONFIG_PESQUISAS_GET_PESOS = `${process.env.REACT_APP_API_URL}/config/pesquisas/pesos`
export const URL_CONFIG_PESQUISAS_SET_FIT_MINIMO = `${process.env.REACT_APP_API_URL}/config/pesquisas/pesos/fitminimo`
export const URL_CONFIG_PESQUISAS_GET_FIT_MINIMO = `${process.env.REACT_APP_API_URL}/config/pesquisas/pesos/fitminimo`
