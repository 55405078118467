import styled from 'styled-components'

export const LimitesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  div + div {
    margin-left: 32px;
  }

  > div {
    > span {
      font-weight: 500;
      font-size: 16px;
    }
    > * + * {
      margin-left: 8px;
    }
    .ant-input-number {
      width: 130px;
    }
  }
`
export const Disclaimer = styled.span`
  font-weight: 500;
  font-size: 16px;
`
