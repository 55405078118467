import styled from 'styled-components'

export const Container = styled.div``

export const ArquetipoBox = styled.div`
  display: flex;
  justify-content: space-between;
  .ant-slider {
    flex: 1;
  }

  .ant-input-number {
    height: auto;
    margin-right: 8px;
  }

  .select {
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    .ant-input-number {
      display: none;
    }
  }
`
