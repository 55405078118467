import { Card, Col, Empty, Row } from 'antd'
import { GaugeChart } from 'bizcharts'
import { noPadding } from '../../utils/inlineStyles'
import { colors, parsePieChartData, styleColors } from '../../utils/utils'
import HorizontalStackedHistogram from './components/HorizontalStackedHistogram'
// import HorizontalBarChart from './components/HorizontalBarChart';
import PieChart from './components/PieChart'
import SlicedPieChart from './components/SlicedPieChart'
import { ChartTitle, Col20 } from './styles'

const { pink, purple } = styleColors

export function OlharParaSi({ data, loading }) {
  const { prioridades, escolhaEmpresa, expectativas6meses, fonteEntusiasmo } =
    data

  return (
    <Row gutter={16}>
      <Col span={24} style={{ padding: '0' }}>
        <Row gutter={16} style={{ margin: '0' }}>
          <Col lg={12} sm={24} xs={24} style={{ marginBottom: '16px' }}>
            <Card
              bordered={false}
              loading={loading}
              title="Tenho como prioridade neste momento:"
            >
              <HorizontalStackedHistogram data={prioridades} />
            </Card>
          </Col>
          <Col lg={12} sm={24} xs={24} style={{ marginBottom: '16px' }}>
            <Card
              bordered={false}
              loading={loading}
              title="Porque escolhi esta empresa para trabalhar?"
            >
              <HorizontalStackedHistogram data={escolhaEmpresa} />
            </Card>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ padding: '0' }}>
        <Row gutter={16} style={{ margin: '0' }}>
          <Col lg={12} xs={24} sm={24} style={{ marginBottom: '16px' }}>
            <Card
              bordered={false}
              loading={loading}
              title="Para os próximos 6 meses, espero:"
            >
              <HorizontalStackedHistogram data={expectativas6meses} />
            </Card>
          </Col>
          <Col lg={12} sm={24} xs={24} style={{ marginBottom: '16px' }}>
            <Card
              bordered={false}
              loading={loading}
              title="Qual tem sido sua maior fonte de Entusiasmo neste momento?"
            >
              <SlicedPieChart data={fonteEntusiasmo} />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export function OlharParaMudancas({ data, loading }) {
  const {
    pontosFortes: pontosFortesData,
    impedimento: impedimentoData,
    principalProblema: principalProblemaData,
    velocidadeAdocao: velocidadeAdocaoData,
    engajamento: engajamentoData,
    proficiencia: proficienciaData,
  } = data

  const pontosFortes = parsePieChartData(pontosFortesData)
  const impedimento = parsePieChartData(impedimentoData)
  const principalProblema = parsePieChartData(principalProblemaData)

  return (
    <Row gutter={16}>
      <Col span={24} style={{ padding: '0' }}>
        <Row gutter={16} style={{ margin: '0' }}>
          <Col xl={12} sm={24} xs={24} style={{ marginBottom: '16px' }}>
            <Card
              bordered={false}
              loading={loading}
              title="Pontos Fortes que auxiliam as Mudanças"
            >
              <Row
                style={{
                  ...(pontosFortes.length === 0
                    ? { justifyContent: 'center' }
                    : {}),
                }}
              >
                {pontosFortes.length > 0 ? (
                  pontosFortes.map((item) => (
                    <Col
                      xxl={12}
                      sm={24}
                      xs={24}
                      style={{
                        marginBottom: '16px',
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'column',
                      }}
                      key={item.TEXTO}
                    >
                      <ChartTitle>{item.TEXTO}</ChartTitle>
                      <PieChart data={item.data} transformPercent />
                    </Col>
                  ))
                ) : (
                  <Empty />
                )}
              </Row>
            </Card>
          </Col>
          <Col xl={12} lg={24} sm={24} xs={24} style={{ marginBottom: '16px' }}>
            <Card
              bordered={false}
              loading={loading}
              title="O que impede as mudanças"
            >
              <Row
                style={{
                  ...(impedimento.length === 0
                    ? { justifyContent: 'center' }
                    : {}),
                }}
              >
                {impedimento.length > 0 ? (
                  impedimento.map((item) => (
                    <Col
                      xxl={12}
                      sm={24}
                      xs={24}
                      style={{
                        marginBottom: '16px',
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'column',
                      }}
                      key={item.TEXTO}
                    >
                      <ChartTitle>{item.TEXTO}</ChartTitle>
                      <PieChart data={item.data} transformPercent />
                    </Col>
                  ))
                ) : (
                  <Empty />
                )}
              </Row>
            </Card>
          </Col>

          <Col sm={24} xs={24} style={{ marginBottom: '16px' }}>
            <Card
              bordered={false}
              loading={loading}
              title="Principal Problema percebido"
            >
              <Row>
                <Col
                  sm={24}
                  xs={24}
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    height: '100%',
                    flex: '1',
                    ...(principalProblema.length === 0
                      ? { justifyContent: 'center' }
                      : {}),
                  }}
                >
                  {principalProblema.length > 0 ? (
                    principalProblema.map((item) => (
                      <Col20 key={item.TEXTO}>
                        <ChartTitle>{item.TEXTO}</ChartTitle>
                        <PieChart data={item.data} transformPercent />
                      </Col20>
                    ))
                  ) : (
                    <Empty />
                  )}
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} sm={24}>
            <Card bordered={false} title="Indicadores de mudança">
              <Row>
                <Col sm={24} xs={24} lg={8} style={{ marginBottom: '8px' }}>
                  {velocidadeAdocaoData ? (
                    <GaugeChart
                      forceFit
                      title={{
                        visible: true,
                        text: 'Velocidade de adoção à mudança',
                        style: {
                          fontFamily: 'inherit',
                        },
                      }}
                      min={0}
                      max={100}
                      value={parseFloat(
                        parseFloat(velocidadeAdocaoData.percent).toFixed(1)
                      )}
                      height={280}
                      range={[0, 25, 50, 75, 100]}
                      color={colors}
                      statistic={{
                        visible: true,
                        text: `${parseFloat(
                          velocidadeAdocaoData.percent
                        ).toFixed(1)}%`,
                        size: 35,
                        position: ['50%', '100%'],
                      }}
                      axis={{
                        offset: -15,
                        tickLine: {
                          visible: true,
                          length: 10,
                        },
                        label: {
                          visible: false,
                        },
                      }}
                    />
                  ) : (
                    <Empty />
                  )}
                </Col>
                <Col sm={24} xs={24} lg={8} style={{ marginBottom: '8px' }}>
                  {engajamentoData ? (
                    <GaugeChart
                      forceFit
                      title={{
                        visible: true,
                        text: 'Engajamento no processo de mudança',
                        style: {
                          fontFamily: 'inherit',
                        },
                      }}
                      min={0}
                      max={100}
                      value={parseFloat(
                        parseFloat(engajamentoData.percent).toFixed(1)
                      )}
                      statistic={{
                        visible: true,
                        text: `${parseFloat(engajamentoData.percent).toFixed(
                          1
                        )}%`,
                        size: 35,
                        position: ['50%', '100%'],
                      }}
                      height={280}
                      range={[0, 25, 50, 75, 100]}
                      color={colors}
                      axis={{
                        offset: -15,
                        tickLine: {
                          visible: true,
                          length: 10,
                        },
                        label: {
                          visible: false,
                        },
                      }}
                    />
                  ) : (
                    <Empty />
                  )}
                </Col>
                <Col sm={24} xs={24} lg={8} style={{ marginBottom: '8px' }}>
                  {proficienciaData ? (
                    <GaugeChart
                      forceFit
                      title={{
                        visible: true,
                        text: 'Proficiência',
                        style: {
                          fontFamily: 'inherit',
                        },
                      }}
                      min={0}
                      max={100}
                      value={parseFloat(
                        parseFloat(proficienciaData.percent).toFixed(1)
                      )}
                      statistic={{
                        visible: true,
                        text: `${parseFloat(proficienciaData.percent).toFixed(
                          1
                        )}%`,
                        size: 35,
                        position: ['50%', '100%'],
                      }}
                      height={280}
                      range={[0, 25, 50, 75, 100]}
                      color={colors}
                      axis={{
                        offset: -15,
                        tickLine: {
                          visible: true,
                          length: 10,
                        },
                        label: {
                          visible: false,
                        },
                      }}
                    />
                  ) : (
                    <Empty />
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export function OlharParaLideranca({ data, loading }) {
  const {
    perceboLider: perceboLiderData,
    frequenciaFeedback,
    feedbackContribui,
    reconhecimento,
    expectativaEntregas,
    perfilLiderDireto,
  } = data

  const perceboLider = parsePieChartData(perceboLiderData)

  return (
    <Row gutter={16} style={{ width: '100%' }}>
      <Col span={24} style={{ padding: '0' }}>
        <Row gutter={16} style={{ margin: '0' }}>
          <Col xl={8} sm={24} xs={24} style={{ marginBottom: '16px' }}>
            <Card
              bordered={false}
              title="Perfil do Líder Direto"
              loading={loading}
            >
              <PieChart data={perfilLiderDireto} transformPercent />
            </Card>
          </Col>
          <Col xl={8} sm={24} xs={24} style={{ marginBottom: '16px' }}>
            <Card
              bordered={false}
              loading={loading}
              title="Sou reconhecido e elogiado"
            >
              <PieChart data={reconhecimento} transformPercent />
            </Card>
          </Col>
          <Col xl={8} sm={24} xs={24} style={{ marginBottom: '16px' }}>
            <Card
              bordered={false}
              loading={loading}
              title="Tenho clareza sobre as minhas entregas"
            >
              <PieChart data={expectativaEntregas} transformPercent />
            </Card>
          </Col>
          <Col sm={24} xs={24} style={{ marginBottom: '16px' }}>
            <Card
              bordered={false}
              loading={loading}
              title="Percebo que meu líder"
            >
              <Col
                sm={24}
                xs={24}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  height: '100%',
                  flex: '1',
                  ...(perceboLider.length === 0
                    ? { justifyContent: 'center' }
                    : {}),
                }}
              >
                {perceboLider.length > 0 ? (
                  perceboLider.map((item) => (
                    <Col20 key={item.TEXTO}>
                      <ChartTitle>{item.TEXTO}</ChartTitle>
                      <PieChart data={item.data} transformPercent />
                    </Col20>
                  ))
                ) : (
                  <Empty />
                )}
              </Col>
            </Card>
          </Col>
          <Col sm={24} xs={24} style={{ ...noPadding }}>
            <Row gutter={16} style={{ margin: '0' }}>
              <Col xl={12} sm={24} xs={24} style={{ marginBottom: '16px' }}>
                <Card
                  bordered={false}
                  loading={loading}
                  title="Frequência do Feedback"
                >
                  <PieChart data={frequenciaFeedback} transformPercent />
                </Card>
              </Col>
              <Col xl={12} sm={24} xs={24} style={{ marginBottom: '16px' }}>
                <Card
                  bordered={false}
                  loading={loading}
                  title="Feedback contribui para"
                >
                  <PieChart data={feedbackContribui} transformPercent />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
