/* eslint-disable react/no-unknown-property */
import { useField } from '@unform/core'
import React, { useEffect, useRef } from 'react'
import { Input, Form, Typography } from 'antd'

const { TextArea } = Input
const { Text } = Typography

const TextAreaField = ({
  name,
  autoSize,
  required,
  label,
  placeholder,
  maxLength,
  width,
  rules,
  ...rest
}) => {
  const inputRef = useRef(null)

  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value
      },

      setValue: (ref, value) => {
        ref.current.value = value
      },

      clearValue: (ref) => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <>
      <Form.Item label={label} name={name} rules={rules}>
        <textarea
          className="ant-input"
          rows={2}
          ref={inputRef}
          autoSize={autoSize}
          defaultValue={defaultValue}
          placeholder={placeholder}
          required={required}
          maxLength={maxLength}
          showCount
          {...rest}
        />
      </Form.Item>

      {error && <Text type="warning">{error}</Text>}
    </>
  )
}

export default TextAreaField
