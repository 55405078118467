import React, { Component } from 'react'
import { message } from 'antd'

// Components
import CadastroView from './CadastroView'

// CSS

class CadastroController extends Component {
  state = {
    nome: '',
    email: '',
    telefone: '',
    senha: '',
    razaoSocial: '',
    urlEmpresa: '',
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  onSubmit = (event) => {
    const { step, ...state } = this.state
    const { postCadastro, history } = this.props

    event.preventDefault()
    postCadastro(state).then(({ response }) => {
      if (response.status === 200) {
        history.push('/')
        message.success(response.data.message)
      }
    })
  }

  render() {
    const { handleChange, onSubmit } = this

    return <CadastroView handleChange={handleChange} onSubmit={onSubmit} />
  }
}

export default CadastroController
