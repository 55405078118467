import React, { useState } from 'react'

import { Form, Input, Select } from 'antd'

import { Option } from '../../../../../../components/select/Select'

export default function ModalCadastro({
  handleChangeRequisito,
  onFinish,
  frequencia,
  nivel_id,
  nome,
}) {
  const [form] = Form.useForm()

  const [valorInvalido, setValorInvalido] = useState(false)

  const validationTecnica = (rule, value, callback) => {
    if (!value.match(/^(?!\s+$).*/)) {
      setValorInvalido(true)
      return callback('Por favor, insira um nome válido!')
    }
    setValorInvalido(false)
    return callback()
  }

  const compararValor = nome ? nome.length : 0

  return (
    <Form
      layout="vertical"
      id="cadastro"
      onFinish={onFinish}
      form={form}
      initialValues={{ nome, nivel_id }}
    >
      <Form.Item
        label="Descrição"
        name="nome"
        style={{
          marginBottom: 3,
        }}
        rules={[
          {
            required: true,
            message: 'Por favor, preencha este campo.',
          },
          {
            validator: validationTecnica,
            message: 'Insira uma descrição válida!',
          },
        ]}
      >
        <Input
          onChange={(e) => handleChangeRequisito(e.target.value)}
          type="text"
          placeholder="Digite o que o cargo faz"
          value={nome}
          maxLength={255}
        />
      </Form.Item>
      <p
        style={{
          color: compararValor >= 240 ? 'red' : '',
          marginTop: valorInvalido ? -9 : -6,
        }}
      >
        {compararValor <= 9
          ? compararValor.toString().padStart(2, '0')
          : compararValor}
        /255
      </p>
      <Form.Item label="Selecione o nível" name="nivel_id">
        <Select
          value={nivel_id}
          placeholder="Escolha o nível"
          style={{ width: '100%' }}
        >
          {frequencia.map(({ nome, id }) => (
            <Option
              value={id}
              key={id}
              onChange={(e) => handleChangeRequisito(e.target.value)}
            >
              {nome}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}
