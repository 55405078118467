import React from 'react'

import moment from 'moment'

import { Form, DatePicker } from 'antd'

import { MaskedInput } from '../../../../../../components/input/Input'

function ContratarCandidato({ onValuesChange, initialValues = {}, onFinish }) {
  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      id="contratar-candidato-submit"
      onValuesChange={onValuesChange}
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        label="Informe o CPF do candidato"
        name="pes_cpf"
        rules={[
          {
            required: true,
            message:
              'Por favor, informe o CPF do candidato para prosseguir com a contratação.',
          },
        ]}
      >
        <MaskedInput mask="999.999.999-99" withoutMargin />
      </Form.Item>
      <Form.Item label="Data de Contratação" name="data_contratacao">
        <DatePicker format="DD/MM/YYYY" />
      </Form.Item>
    </Form>
  )
}

export default ContratarCandidato
