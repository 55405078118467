import React, { Component } from 'react'
import ReactHighchart from 'react-highcharts'
import HighchartMore from 'highcharts/highcharts-more'

HighchartMore(ReactHighchart.Highcharts)

class App extends Component {
  static formatTooltip(tooltip, x = this.x, y = this.y, series = this.series) {
    return `<b>${x}</b><br/>${series.name}: ${y}`
  }

  static getConfig = (navs, series) => {
    return {
      chart: {
        type: 'line',
        polar: true,
      },
      title: {
        text: 'Gráfico',
        x: -80,
      },
      pane: {
        size: '300px',
      },

      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
      },

      xAxis: {
        categories: [
          'REGRA',
          'COMPLACENCIA',
          'APRENDIZADO',
          'CLA',
          'COLABORACAO',
          'EMPREENDEDORA',
          'INOVACAO',
          'AUTORIDADE',
        ],
        tickmarkPlacement: 'on',
        lineWidth: 0,
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0,
        allowDecimals: true,
      },

      tooltip: {
        shared: true,
        pointFormat:
          '<span style="color:{series.color}">{series.name}: <b>{point.y:.2f}%</b><br/>',
      },

      series,
    }
  }

  render() {
    function createInstalado(valor) {
      const valores = []
      valores.push(valor.Regras)
      valores.push(valor.Complacência)
      valores.push(valor.Aprendizado)
      valores.push(valor.Clã)
      valores.push(valor.Colaboração)
      valores.push(valor.Empreendedora)
      valores.push(valor.Inovação)
      valores.push(valor.Autoridade)

      return valores
    }

    function reoderArchs(data) {
      const ArrayOrdered = []
      ArrayOrdered.push(data[7])
      ArrayOrdered.push(data[5])
      ArrayOrdered.push(data[4])
      ArrayOrdered.push(data[3])
      ArrayOrdered.push(data[2])
      ArrayOrdered.push(data[0])
      ArrayOrdered.push(data[1])
      ArrayOrdered.push(data[6])

      return ArrayOrdered
    }

    const desejado = reoderArchs(this.props.data.desejado)

    const acima = []
    const dentro = []
    const abaixo = []
    const instalado = []
    const valores = createInstalado(this.props.data.instalado)
    desejado.forEach((item) => {
      acima.push(item.acima)
      dentro.push(item.dentro)
      abaixo.push(item.abaixo)
      instalado.push(item.valor)
    })

    const myObj = [
      {
        name: 'Desejado',
        data: instalado,
        pointPlacement: 'on',
        color: 'black',
      },
      {
        name: 'Instalado',
        data: valores,
        pointPlacement: 'on',
        color: 'purple',
      },
      {
        pointPlacement: 'on',
        name: 'Acima',
        data: acima,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: ReactHighchart.Highcharts.getOptions().colors[6],
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false,
        },
      },
      {
        pointPlacement: 'on',
        name: 'Abaixo',
        data: abaixo,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: ReactHighchart.Highcharts.getOptions().colors[6],
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false,
        },
      },
      {
        pointPlacement: 'on',
        name: 'Dentro',
        data: dentro,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: ReactHighchart.Highcharts.getOptions().colors[7],
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false,
        },
      },
    ]

    Object.entries(this.props.data).forEach((key) => {
      if (
        key[0] == 'instalado' ||
        key[0] == 'desejado' ||
        key[0] == 'grafico_1' ||
        key[0] == 'grafico_2' ||
        key[0] == 'grafico_3'
      )
        return

      const newElement = {
        name: key[0],
        data: createInstalado(key[1]),
        pointPlacement: 'on',
        color: 'red',
        visible: false,
        marker: {
          enabled: false,
        },
      }
      myObj.unshift(newElement)
    })
    return (
      <div>
        <ReactHighchart config={App.getConfig(this.props.navs, myObj)} />
      </div>
    )
  }
}

export default App
