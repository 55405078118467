export const GET_DASHBOARD_ESTATISTICAS = 'GET_DASHBOARD_ESTATISTICAS'
export const GET_DASHBOARD_CULTURAS = 'GET_DASHBOARD_CULTURAS'
export const GET_DASHBOARD_TOP_ARQ = 'GET_DASHBOARD_TOP_ARQ'
export const GET_DASHBOARD_CULTURA_ORGANIZACIONAL =
  'GET_DASHBOARD_CULTURA_ORGANIZACIONAL'
export const GET_DASHBOARD_VETOR_GERAL = 'GET_DASHBOARD_VETOR_GERAL'
export const GET_DASHBOARD_CANDIDATO_COMPLETO =
  'GET_DASHBOARD_CANDIDATO_COMPLETO'
export const GET_DASHBOARD_CANDIDATOS = 'GET_DASHBOARD_CANDIDATOS'
export const GET_DASHBOARD_NPS = 'GET_DASHBOARD_NPS'

// urls
export const URL_DASHBOARD = `${process.env.REACT_APP_API_URL}/dashboard`
export const URL_DASHBOARD_ESTATISTICAS = `${URL_DASHBOARD}/statistics`
export const URL_DASHBOARD_CULTURAS = `${URL_DASHBOARD}/culturas`
export const URL_DASHBOARD_TOP_ARQ = `${URL_DASHBOARD}/topArquetipos`
export const URL_DASHBOARD_CULTURA_ORGANIZACIONAL = `${URL_DASHBOARD}/culturaOrganizacional`
export const URL_DASHBOARD_VETOR_GERAL = `${URL_DASHBOARD}/vetor`
export const URL_DASHBOARD_CANDIDATO_COMPLETO = `${process.env.REACT_APP_API_URL}/vetor/candidatos`
export const URL_DASHBOARD_CANDIDATOS = `${URL_DASHBOARD}/candidatos`
export const URL_DASHBOARD_NPS = `/relatorios/nps`

// defaults
export const culturaOrgDefault = {
  culturaOrg: {
    niveisConfianca: [],
    olharLideranca: {
      perceboLider: [],
      frequenciaFeedback: [],
      feedbackContribui: [],
      reconhecimento: [],
      expectativaEntregas: [],
    },
    olharParaMudancas: {
      pontosFortes: [],
      impedimento: [],
      principalProblema: [],
      engajamento: undefined,
      velocidadeAdocao: undefined,
      proficiencia: undefined,
    },
    olharParaSi: {
      escolhaEmpresa: [],
      prioridades: [],
      expectativas6meses: [],
      fonteEntusiasmo: [],
    },
    valoresEvidencia: [],
    satisfacaoGeral: [],
  },
  disc: {},
}

export const estatisticasDefault = {
  colaboradores: 0,
  times: 0,
  pesquisas: 0,
  naorespondidas: 0,
}

export const npsDefault = {
  nps: 0,
}

export const vetorDefault = {
  intensidade: [
    {
      row: '',
      essencia: '',
      exigido: '',
      entrega: '',
    },
  ],
  rct: [],
  edl: [],
  foc: [],
  ode: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  oct: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  tdd: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  cor: [],
  erc: [],
  edc: [],
  principal: {},
}
