import * as constants from '../constants/constantsPainelQuiz'

export const getQuiz = (quiz_id) => {
  return {
    type: constants.QUIZ_GET,
    api: {
      method: 'get',
      url: constants.URL_QUIZ_GET(quiz_id),
    },
  }
}

export const updateStatusQuiz = (quiz_id) => {
  return {
    type: constants.UPDATE_STATUS_QUIZ,
    api: {
      method: 'put',
      url: constants.URL_UPDATE_STATUS_QUIZ(quiz_id),
    },
  }
}

export const updateQuiz = (quiz_id, data) => {
  return {
    type: constants.UPDATE_QUIZ,
    api: {
      method: 'put',
      url: constants.URL_UPDATE_QUIZ(quiz_id),
      data: { ...data },
    },
  }
}
