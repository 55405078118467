// URLs
export const URL_BASE = process.env.REACT_APP_API_URL
export const URL_PESQUISAS_DISPONIVEIS_GET = `${URL_BASE}/pesquisa/`
export const URL_AVALIACOES_DISPONIVEIS_GET = `${URL_BASE}/avaliacoes/check/`
export const URL_CARACTERISTICAS_PERFIL_COMPORTAMENTAL_GET = `${URL_BASE}/vetor/caracteristicas/list`
export const URL_PERFIL_COMPORTAMENTAL_INFO_GET = `${URL_BASE}/avaliacoes/load`
export const URL_RESPOSTA_PERFIL_COMPORTAMENTAL_POST = `${URL_BASE}/avaliacoes/respostas/store`
export const URL_QUESTOES_PESQUISA_GET = `${URL_BASE}/pesquisa/questoes`
export const URL_RESPOSTA_PESQUISA_POST = `${URL_BASE}/pesquisa/respostas`

export const URL_PESQUISAS_PERCEPCAO_LOAD = (hash) =>
  `${URL_BASE}/pesquisa/load/${hash}`

export const URL_PESQUISAS_VETOR_LOAD = (hash) =>
  `${URL_BASE}/avaliacoes/load/${hash}`

// Redux types
export const PESQUISAS_DISPONIVEIS_GET = 'PESQUISAS_DISPONIVEIS_GET'
export const AVALIACOES_DISPONIVEIS_GET = 'AVALIACOES_DISPONIVEIS_GET'
export const CARACTERISTICAS_PERFIL_COMPORTAMENTAL_GET =
  'CARACTERISTICAS_PERFIL_COMPORTAMENTAL_GET'
export const PERFIL_COMPORTAMENTAL_INFO_GET = 'PERFIL_COMPORTAMENTAL_INFO_GET'
export const RESPOSTA_PERFIL_COMPORTAMENTAL_POST =
  'RESPOSTA_PERFIL_COMPORTAMENTAL_POST'
export const QUESTOES_PESQUISA_GET = 'QUESTOES_PESQUISA_GET'
export const RESPOSTA_PESQUISA_POST = 'RESPOSTA_PESQUISA_POST'
export const PESQUISAS_PERCEPCAO_LOAD = 'PESQUISAS_PERCEPCAO_LOAD'
export const PESQUISAS_VETOR_LOAD = 'PESQUISAS_VETOR_LOAD'

// Tipos de avaliacoes
export const PERFIL_COMPORTAMENTAL = 'Perfil Comportamental'

// Tipos de pesquisas
export const PESQUISAS = { 2: 'Percepcão do Ambiente' }
