import React from 'react'

import { useState } from 'react'

import { Statistic, Progress, DatePicker, Col, Alert } from 'antd'

// CSS

import { FlexCard, RangeContainer } from './styles'

const { RangePicker } = DatePicker

export default function ItemEstatisticaPesquisas({
  titulo,
  enviadas,
  entregues,
  percentual,
  handleByDate,
}) {
  const [dataInicio, setDataInicio] = useState()
  const [dataFim, setDataFim] = useState()

  return (
    <Col xs={24} sm={24} xl={8} style={{ marginBottom: '16px' }}>
      <div>
        <FlexCard title={`Pesquisas de ${titulo}`}>
          <Statistic title="Enviadas" value={enviadas} />
          <Statistic title="Entregues" value={entregues} />
          <Progress
            type="dashboard"
            percent={parseFloat(percentual)}
            gapDegree={30}
            width={90}
            strokeColor="rgb(239, 84, 114)"
          />
          {percentual === null ? (
            <Alert
              message="Não existem pesquisas registradas para o período selecionado. Selecione outro período."
              type="warning"
              style={{
                marginTop: '24px',
                fontSize: '12px',
                width: '100%',
              }}
            />
          ) : (
            <div />
          )}

          <RangeContainer>
            <span>Filtre as pesquisas por períodos:</span>
            <RangePicker
              format="YYYY-MM-DD"
              onChange={(dates, dateStrings) => {
                handleByDate(dateStrings[0], dateStrings[1])
              }}
            />
          </RangeContainer>
          <span>
            <br />* A quantidade de pesquisas enviadas e entregues é um
            somatório de colaboradores e candidatos.
          </span>
        </FlexCard>
      </div>
    </Col>
  )
}
