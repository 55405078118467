import { toast } from 'react-toastify'

import * as constants from '../constants/constants'

const initialState = { motivos: [], loading: false }

export const psMotivoCancelamento = (state = initialState, action) => {
  switch (action.type) {
    case constants.PS_MOTIVO_CANCELAMENTO_LIST: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204) {
          toast.error(
            'Erro ao listar motivos de cancelamento do processo seletivo.'
          )
        }
        return { ...state, loading: false, motivos: [] }
      }

      const { motivosCancelamento } = action.response.data

      return { ...state, motivos: motivosCancelamento, loading: false }
    }

    case constants.PS_MOTIVO_CANCELAMENTO_LOAD:
      return { ...state, loading: true }

    default:
      return { ...state }
  }
}

export default psMotivoCancelamento
