import moment from 'moment'

// Redux types
import * as constants from '../constants/constantsCadastroColaboradores'

export const getColaboradores = () => {
  return {
    type: constants.COLABORADORES_GET,
    api: {
      method: 'get',
      url: constants.URL_COLABORADORES_GET,
    },
  }
}

export const postColaborador = ({
  cpf,
  nome,
  dataNascimento,
  email,
  sexo,
  grupo = undefined,
  telefone,
  cargo = undefined,
  dataAdmissao,
  vetorComportamental = false,
  percepcaoAmbiente = false,
  valoresOrganizacionais = false,
  enviarEmails = false,
  filial_id,
  pcd = 0,
  pcd_tipo = undefined,
  cid = undefined,
}) => {
  return {
    type: constants.COLABORADOR_POST,
    api: {
      method: 'post',
      url: constants.URL_COLABORADOR_POST,
      data: {
        pes_cpf: cpf,
        pes_nome: nome,
        pes_datanascimento: moment(dataNascimento).format(),
        pes_email: email,
        pes_sexo: sexo,
        grupo,
        pes_cel: telefone,
        cargo,
        dataadmissao: moment(dataAdmissao).format(),
        filial_id,
        pesquisa: percepcaoAmbiente,
        idpesquisa: percepcaoAmbiente ? 2 : undefined,
        vetorComportamental: vetorComportamental,
        valoresOrganizacionais,
        enviarEmails,
        pcd,
        pcd_tipo,
        cid,
      },
    },
  }
}

export const postColaboradores = ({
  listaColaboradores,
  vetorComportamental,
  percepcaoAmbiente,
  enviarEmails,
  valoresOrganizacionais,
}) => {
  return {
    type: constants.COLABORADORES_POST,
    api: {
      method: 'post',
      url: constants.URL_COLABORADORES_POST,
      data: {
        pessoas: listaColaboradores.map(
          ({ NOME, EMAIL, CPF, SEXO, NASCIMENTO, TELEFONE, CARGO, GRUPO }) => ({
            pes_cpf: CPF,
            pes_nome: NOME,
            pes_datanascimento: NASCIMENTO,
            pes_email: EMAIL,
            pes_sexo: SEXO,
            pes_cel: TELEFONE,
            cargo: CARGO,
            grupo: GRUPO,
            pesquisa: percepcaoAmbiente,
            vetorComportamental: vetorComportamental,
            valoresOrganizacionais,
          })
        ),
      },
      enviarEmails: enviarEmails,
    },
  }
}

export const putColaborador = ({
  id,
  cpf,
  nome,
  dataNascimento,
  email,
  sexo,
  telefone,
  filial_id,
  dataAdmissao,
  pcd,
  pcd_tipo,
  cid,
}) => {
  return {
    type: constants.COLABORADOR_PUT,
    api: {
      method: 'put',
      url: `${constants.URL_COLABORADOR_PUT}/${id}`,
      data: {
        filial_id,
        pes_cpf: cpf,
        pes_nome: nome,
        pes_datanascimento: dataNascimento && moment(dataNascimento).format(),
        pes_email: email,
        pes_sexo: sexo,
        pes_cel: telefone,
        dataadmissao: dataAdmissao && moment(dataAdmissao).format(),
        pcd,
        pcd_tipo,
        cid,
      },
    },
  }
}

export const deleteColaborador = ({ id }) => {
  return {
    type: constants.COLABORADOR_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_COLABORADOR_DELETE}/${id}`,
    },
  }
}

export const sendVetorComportamental = (data) => {
  return {
    type: constants.COLABORADORES_SEND_AVALIACOES,
    api: {
      method: 'post',
      url: constants.URL_COLABORADORES_SEND_AVALIACOES,
      data,
    },
  }
}

export const sendPercepcao = (id, { enviarEmails }) => {
  return {
    type: constants.SEND_PERCEPCAO,
    api: {
      method: 'post',
      url: constants.URL_SEND_PERCEPCAO + '/' + id + '/2',
      data: {
        enviarEmails,
      },
    },
  }
}
