import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { Col, Row, Card, Avatar, Button } from 'antd'

// Assets
import logo from '../../assets/logo_sm.png'

// Components
import Container from '../../components/container/Container'
import ModalPesquisaComportamental from './components/modalPesquisaComportamental/ModalPesquisaComportamental'

// CSS
import './Pesquisas.css'

// Constants

const { Meta } = Card

function PesquisasView({
  intl,
  history,
  onStart,
  showModal,
  openModal,
  closeModal,
  username = 'Rodrigo Grossi',
  pesquisasDisponiveis,
}) {
  return (
    <>
      <Helmet>
        <title>TeamHub | Pesquisas</title>
      </Helmet>
      <div className="pesquisas">
        <div className="pesquisas__header">
          <div className="pesquisas__header__title">
            <span>
              <b>Olá{username}, que bom que você voltou! :)</b>
            </span>
          </div>
          <span className="pesquisas__header__subtitle">
            Selecione as pesquisas disponíveis nos cards abaixo.
          </span>
        </div>

        <Row>
          <Col xs={{ offset: 1, span: 22 }} md={{ offset: 2, span: 20 }}>
            <Container className="pesquisas__content">
              {pesquisasDisponiveis.map((item) => (
                <Card
                  actions={[
                    <Button
                      type="link"
                      onClick={() => onStart(item.tipo, item.id)}
                    >
                      Iniciar pesquisa
                      <ModalPesquisaComportamental
                        id={item.id}
                        history={history}
                        showModal={showModal['Perfil Comportamental']}
                        openModal={() => openModal('Perfil Comportamental')}
                        closeModal={() => closeModal('Perfil Comportamental')}
                      />
                    </Button>,
                  ]}
                  className="pesquisas__content__card"
                >
                  <Meta
                    avatar={
                      <Avatar
                        src={logo}
                        size={50}
                        className="pesquisas__content__avatar"
                      />
                    }
                    title={item.tipo}
                    description={`Esta pesquisa é composta de três etapas. Solicitado em: ${item.data}`}
                  />
                </Card>
              ))}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default injectIntl(PesquisasView)
