// Redux types
import * as constants from '../constants/constantsCargosDetalhes'
import { message } from 'antd'

const initialState = {}

export const cargosDetalhes = (state = initialState, action) => {
  switch (action.type) {
    case constants.CARGO_PESSOA_DELETE: {
      const { data, status } = action.response

      if (status !== 200) {
        message.error('Erro ao remover, tente novamente.')
        return { ...state }
      }

      message.success(data.message)

      return {
        ...state,
      }
    }

    default:
      return { ...state }
  }
}
