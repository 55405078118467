// redux types

export const PS_FLUXO_REQUISICOES_LIST_TIMES = 'PS_FLUXO_REQUISICOES_LIST_TIMES'
export const PS_FLUXO_REQUISICOES_LIST_ETAPAS =
  'PS_FLUXO_REQUISICOES_LIST_ETAPAS'
export const PS_FLUXO_REQUISICOES_STORE_ETAPA =
  'PS_FLUXO_REQUISICOES_STORE_ETAPA'
export const PS_FLUXO_REQUISICOES_UPDATE_ETAPA =
  'PS_FLUXO_REQUISICOES_UPDATE_ETAPA'
export const PS_FLUXO_REQUISICOES_DELETE_ETAPA =
  'PS_FLUXO_REQUISICOES_DELETE_ETAPA'

// urls
const URL_BASE = process.env.REACT_APP_API_URL
const URL_ETAPAS = (grupo_id) => `${URL_BASE}/ps/requisicoes/etapas/${grupo_id}`
export const URL_PS_FLUXO_REQUISICOES_LIST_TIMES = `${URL_BASE}/dashboard/grupos`
export const URL_PS_FLUXO_REQUISICOES_LIST_ETAPAS = (grupo_id) =>
  URL_ETAPAS(grupo_id)
export const URL_PS_FLUXO_REQUISICOES_STORE_ETAPA = (grupo_id) =>
  URL_ETAPAS(grupo_id)
export const URL_PS_FLUXO_REQUISICOES_UPDATE_ETAPA = (grupo_id, id) =>
  `${URL_ETAPAS(grupo_id)}/${id}`
export const URL_PS_FLUXO_REQUISICOES_DELETE_ETAPA = (grupo_id, id) =>
  `${URL_ETAPAS(grupo_id)}/${id}`
