import React, { useState, useEffect, useMemo } from 'react'

import moment from 'moment'

import { toast } from 'react-toastify'

import * as cepPromise from 'cep-promise'

import { injectIntl } from 'react-intl'

import {
  UploadOutlined,
  TwitterOutlined,
  LinkedinOutlined,
} from '@ant-design/icons'

import '@ant-design/compatible/assets/index.css'

import {
  Row,
  Col,
  Divider,
  Checkbox,
  InputNumber,
  Upload,
  Button,
  Input as InputAntd,
  Form,
  Steps,
  Modal,
  Input,
  Select,
} from 'antd'

// COMPONENTS
import AtividadeCargo from '../adicionarAtividade'
import FerramentaCargo from '../adicionarFerramenta'
import RequisitoCargo from '../adicionarRequisito'
import TecnicaCargo from '../adicionarTecnica'

const { Option } = Select

const { Step } = Steps

function AdicionarNovoCargo({ form }) {
  const [valorInvalido, setValorInvalido] = useState(false)

  /* Não remover rule */
  const validationNomeCargo = (rule, value, callback) => {
    if (!value.match(/^(?!\s+$).*/)) {
      setValorInvalido(true)
      return callback('Por favor, insira um nome válido!')
    }
    setValorInvalido(false)
    return callback()
  }

  const [maximoValor, setMaximoValor] = useState(0)

  const handleVerificarMaximoValor = (e) => {
    setMaximoValor(e.target.value.length)
  }

  return (
    <Form form={form} id="novo-cargo-submit" layout="vertical" preserve>
      <Row gutter={20}>
        <Col md={14}>
          <Form.Item
            label="Cargo"
            name="nome"
            style={{
              marginBottom: 3,
            }}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Informe o nome do cargo!',
              },
              {
                validator: validationNomeCargo,
                message: 'Insira um nome válido!',
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Digite o nome do cargo"
              onChange={(e) => handleVerificarMaximoValor(e)}
              maxLength={81}
              key="cargo_novo-nome"
            />
          </Form.Item>
          <p
            style={{
              color: maximoValor >= 70 ? 'red' : '',
              marginTop: valorInvalido ? -9 : -6,
            }}
          >
            {maximoValor <= 9
              ? maximoValor.toString().padStart(2, '0')
              : maximoValor}
            /81
          </p>
        </Col>
        <Col md={10}>
          <Form.Item label="CBO" name="cbo">
            <Input
              type="text"
              placeholder="Digite o CBO"
              key="cargo_novo-cbo"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={10}>
          <Form.Item label="Piso salarial" name="piso">
            <Input
              type="number"
              placeholder="Digite o piso salarial"
              prefix="R$"
              key="cargo_novo"
            />
          </Form.Item>
        </Col>
        <Col md={14}>
          <Form.Item label="Lotação" name="lotacao">
            <Input
              type="text"
              placeholder="Digite a lotação"
              key="cargo_novo-lotacao"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <Form.Item label="Propósito do cargo" name="proposito">
            <Input.TextArea
              placeholder="Digite a descrição"
              key="cargo_novo-proposito"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <Form.Item label="Objetivo do cargo" name="descricao">
            <Input.TextArea
              placeholder="Digite a descrição"
              key="cargo_novo-objetivo"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export const steps = 5

function CadastroCargo({
  currentStep,
  intl,
  type = 'new',
  modal = true,
  visible = false,
  closeModal,
  closable = true,
  onSubmit,
  frequencia,
  ferramenta,
  formacao,
}) {
  const [currentStepModal, setCurrentStepModal] = useState(0)

  const [novoCargoForm] = Form.useForm()

  const [atividadeForm] = Form.useForm()

  const [ferramentaForm] = Form.useForm()

  const [requisitoForm] = Form.useForm()

  const [tecnicaForm] = Form.useForm()

  const [fields, setFields] = useState(null)

  const stepsComponents = [
    <AdicionarNovoCargo
      name="form-cargo"
      intl={intl}
      type={type}
      form={novoCargoForm}
      key="cargo_novo"
    />,
    <AtividadeCargo
      name="form-atividade"
      intl={intl}
      type={type}
      form={atividadeForm}
      key="form_atividade"
      frequencia={frequencia}
    />,
    <FerramentaCargo
      name="form-ferramenta"
      intl={intl}
      type={type}
      form={ferramentaForm}
      key="form_ferramenta"
      ferramenta={ferramenta}
    />,
    <RequisitoCargo
      name="form-requisito"
      intl={intl}
      type={type}
      form={requisitoForm}
      key="form_requisito"
      formacao={formacao}
    />,
    <TecnicaCargo
      name="form-tecnica"
      intl={intl}
      type={type}
      form={tecnicaForm}
      key="form_tecnica"
      frequencia={frequencia}
    />,
  ]

  const Forms = (
    <div className="adicionar-candidatos">
      <Steps
        current={modal ? currentStepModal : currentStep}
        style={{ marginBottom: 32 }}
        size="small"
      >
        <Step title="Novo Cargo" />
        <Step title="Atividade" />
        <Step title="Ferramenta" />
        <Step title="Requisito" />
        <Step title="Técnica" />
      </Steps>
      {stepsComponents[modal ? currentStepModal : currentStep]}
    </div>
  )

  const saveFields = (step) => {
    const actions = {
      0: () => setFields({ ...fields, ...novoCargoForm.getFieldsValue() }),
      1: () => setFields({ ...fields, ...atividadeForm.getFieldsValue() }),
      2: () => setFields({ ...fields, ...ferramentaForm.getFieldsValue() }),
      3: () => setFields({ ...fields, ...requisitoForm.getFieldsValue() }),
      4: () => setFields({ ...fields, ...tecnicaForm.getFieldsValue() }),
    }

    if (actions[step]) {
      actions[step]()
    }
  }

  const handleNextStep = () => {
    setCurrentStepModal(currentStepModal + 1)
    saveFields(currentStepModal)
  }

  const handlePrevStep = () => {
    setCurrentStepModal(currentStepModal - 1)
  }

  const handleClose = () => {
    novoCargoForm.resetFields()
    atividadeForm.resetFields()
    ferramentaForm.resetFields()
    requisitoForm.resetFields()
    tecnicaForm.resetFields()
    setCurrentStepModal(0)
    setFields(null)
    closeModal()
  }

  const handleSubmit = async () => {
    const fieldsValues = { ...fields, ...tecnicaForm.getFieldsValue() }
    const fieldsUpdatedValues = Object.keys(fieldsValues).reduce(
      (acc, nextKey) => ({ ...acc, [nextKey]: fieldsValues[nextKey] }),
      {}
    )

    const success = await onSubmit(fieldsUpdatedValues)

    if (success) {
      handleClose()
    }
  }

  return modal ? (
    <Modal
      closable={closable}
      title="NOVO CARGO"
      visible={visible}
      destroyOnClose
      width={650}
      onCancel={handleClose}
      footer={
        <div className="candidatos__addmodal__footer">
          <Button
            type="primary"
            danger
            onClick={handleClose}
            className="candidatos__button__cancel"
          >
            CANCELAR
          </Button>
          {currentStepModal > 0 && (
            <Button onClick={handlePrevStep}>VOLTAR</Button>
          )}
          {currentStepModal + 1 < steps ? (
            <Button type="primary" onClick={handleNextStep}>
              PRÓXIMO
            </Button>
          ) : (
            <Button type="primary" onClick={handleSubmit}>
              SALVAR
            </Button>
          )}
        </div>
      }
    >
      {Forms}
    </Modal>
  ) : (
    Forms
  )
}

export default injectIntl(CadastroCargo)
