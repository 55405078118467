import { Select, Form, Input, Button } from 'antd'
import { toast } from 'react-toastify'
import { putAvaliadores } from '../../Api/api'

const { Option } = Select

const App = (props) => {
  const onFinish = async (values) => {
    let response
    if (props.tipoRequisicao === 'subCultura') {
      response = await putAvaliadores(
        props?.cultura.cultura_id,
        props?.cultura.grupo_id,
        props?.editable.avaliador_id,
        values
      )
    } else {
      response = await putAvaliadores(
        props?.cultura.cultura_id,
        null,
        props?.editable.avaliador_id,
        values
      )
    }
    if (response.success) {
      props.update()
      props.closeModal()
      toast.success('Avaliador atualizado com sucesso')
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
        nome: props?.editable.nome,
        email: props?.editable.email,
        peso: props?.editable.peso,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Nome"
        name="nome"
        rules={[
          {
            required: true,
            message: 'Por favor insira o seu nome',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Por favor insira seu email',
          },
        ]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item name="peso" label="Peso" rules={[{ required: false }]}>
        <Select placeholder="Informe o peso" allowClear>
          <Option value={1}>Baixo</Option>
          <Option value={2}>Médio</Option>
          <Option value={3}>Alto</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn_purple newCultureBtn floatRight"
        >
          Atualizar
        </Button>
      </Form.Item>
    </Form>
  )
}

export default App
