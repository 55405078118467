import React, { useState, useEffect, useMemo } from 'react'

import moment from 'moment'

import { toast } from 'react-toastify'

import * as cepPromise from 'cep-promise'

import { injectIntl } from 'react-intl'

// COMPONENTS
import {
  UploadOutlined,
  TwitterOutlined,
  LinkedinOutlined,
} from '@ant-design/icons'

import '@ant-design/compatible/assets/index.css'

import {
  Row,
  Col,
  Divider,
  Checkbox,
  InputNumber,
  Upload,
  Button,
  Input as InputAntd,
  Form,
  Steps,
  Modal,
} from 'antd'

import Input, { Date, MaskedInput } from '../input/Input'

import Select, { Option } from '../select/Select'

const { Step } = Steps

const DadosPessoaisForm = ({
  intl,
  type,
  initialValues,
  form,
  name,
  pcdChecked,
}) => {
  const [pessoaComDeficiencia, setPessoaComDeficiencia] = useState(
    pcdChecked === 1
  )

  return (
    <Form
      name={name}
      layout="vertical"
      form={form}
      initialValues={{
        pes_nome: undefined,
        pes_cpf: undefined,
        pes_email: undefined,
        pes_datanascimento: undefined,
        sexo: undefined,
        pes_cel: undefined,
        pcd: 0,
        pcd_tipo: undefined,
        cid: undefined,
        ...(!type === 'edit'
          ? {
              valoresOrganizacionais: false,
              vetorComportamental: false,
              enviarEmails: false,
            }
          : {}),
        ...initialValues,
      }}
      preserve
    >
      <Row gutter={20}>
        <Col md={14} xs={14} sm={14}>
          <Form.Item
            label="Nome"
            name="pes_nome"
            rules={[{ required: true, message: 'Digite o nome.' }]}
          >
            <Input type="text" placeholder="Digite o nome" withoutMargin />
          </Form.Item>
        </Col>
        <Col md={10} xs={10} sm={10}>
          <Form.Item label="CPF" name="pes_cpf">
            <MaskedInput mask="999.999.999-99" withoutMargin />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item
            label="E-mail"
            name="pes_email"
            rules={[{ required: true, message: 'Digite o e-email.' }]}
          >
            <Input type="text" placeholder="Digite o e-mail" withoutMargin />
          </Form.Item>
        </Col>
        <Col md={14} xs={14} sm={14}>
          <Form.Item label="Data de Nascimento" name="pes_datanascimento">
            <Date placeholder="Digite o nascimento" withoutMargin />
          </Form.Item>
        </Col>
        <Col md={10} xs={10} sm={10}>
          <Form.Item
            label="Sexo"
            name="sexo"
            rules={[{ required: true, message: 'Informe o sexo.' }]}
          >
            <Select
              placeholder="Insira o sexo"
              style={{ width: '100%' }}
              defaultValue="M"
              withoutMargin
            >
              <Option value="M">Masculino</Option>
              <Option value="F">Feminino</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        {/* <Col md={14}>
        <Form.Item label={'Cargo'}>
          <Select
            placeholder={'Selecione o cargo'}
            name='cargo'
            onChange={(cargo) =>
              handleChange({
                target: { name: 'cargo', value: cargo },
              })
            }
            style={{ width: '100%' }}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <div style={{ padding: '8px', cursor: 'pointer' }}>
                  <PlusOutlined /> Novo Cargo
                </div>
              </div>
            )}
            withoutMargin
          >
            {cargos.map(({ cargo_id, cargo_nome }) => (
              <Option key={cargo_id} value={cargo_id}>
                {cargo_nome}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      */}
        <Col md={10}>
          <Form.Item
            label={intl.formatMessage({
              id: 'common.telefone',
            })}
            name="pes_cel"
          >
            <MaskedInput mask="(99) 99999-9999" withoutMargin />
          </Form.Item>
        </Col>

        <Col md={14} style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Item name="pcd">
            <Checkbox
              defaultChecked={pcdChecked === 1}
              style={{ marginTop: 30 }}
              onChange={({ target }) => {
                if (target.checked === true) {
                  form.setFieldsValue({ pcd: 1 })
                  form.setFieldsValue({ pcd_tipo: 'Física' })
                } else {
                  form.setFieldsValue({ pcd: 0 })
                  form.setFieldsValue({ pcd_tipo: null })
                  form.setFieldsValue({ cid: null })
                }
                setPessoaComDeficiencia(target.checked)
              }}
            >
              É pessoa com deficiência (PCD)?
            </Checkbox>
          </Form.Item>
        </Col>

        {pessoaComDeficiencia && (
          <>
            <Col xs={12} md={12} lg={12}>
              <Form.Item label="Tipo de PCD" name="pcd_tipo">
                <Select
                  placeholder="Selecione o tipo"
                  style={{ width: '100%' }}
                  defaultValue="Física"
                >
                  <Option value="Física">Física</Option>
                  <Option value="Auditiva">Auditiva</Option>
                  <Option value="Visual">Visual</Option>
                  <Option value="Mental">Mental</Option>
                  <Option value="Múltipla">Múltipla</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <Form.Item label="CID" name="cid">
                <Input type="text" placeholder="Digite o CID" />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>

      {!(type === 'edit') && (
        <>
          <Row>
            <Form.Item name="vetorComportamental">
              <Checkbox>Gerar pesquisa de Perfil Comportamental</Checkbox>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item name="valoresOrganizacionais">
              <Checkbox>Gerar pesquisa de Valores Organizacionais</Checkbox>
            </Form.Item>
          </Row>
          <Divider />

          <Row>
            <Form.Item name="enviarEmails">
              <Checkbox>Enviar e-mails</Checkbox>
            </Form.Item>
          </Row>
        </>
      )}
    </Form>
  )
}

const EnderecoForm = ({ initialValues, form, name }) => {
  const [fetchingCpf, setFetchingCpf] = useState(false)

  const handleCheckCEP = (value) => {
    setFetchingCpf(true)
    cepPromise(value)
      .then((result) => {
        setFetchingCpf(false)
        form.setFieldsValue({
          logradouro: result.street,
          ufid: result.state,
          bairro: result.neighborhood,
          cidadeid: result.city,
          cep: value,
        })
      })
      .catch((err) => {
        toast.error(err.message)
        setFetchingCpf(false)
        form.setFieldsValue({
          logradouro: undefined,
          ufid: undefined,
          bairro: undefined,
          cidadeid: undefined,
          cep: undefined,
        })
      })
  }

  return (
    <Form
      name={name}
      layout="vertical"
      id="cadastro-candidato-endereco"
      initialValues={{
        logradouro: undefined,
        ufid: undefined,
        bairro: undefined,
        cidadeid: undefined,
        cep: undefined,
        ...initialValues,
      }}
      form={form}
      preserve
    >
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item label="CEP" name="cep">
            <InputAntd.Search
              enterButton
              placeholder="Informe o CEP"
              onSearch={(value, event) => handleCheckCEP(value)}
              withoutMargin
              loading={fetchingCpf}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={18} xs={18} sm={18}>
          <Form.Item label="Rua / Avenida / Logradouro" name="logradouro">
            <Input type="text" onChange={() => true} withoutMargin />
          </Form.Item>
        </Col>

        <Col md={6} xs={6} sm={6}>
          <Form.Item label="Número" name="nro">
            <InputNumber
              defaultValue={null}
              min={0}
              max={10000}
              withoutMargin
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item label="Complemento" name="complemento">
            <Input type="text" withoutMargin />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={12} xs={12} sm={12}>
          <Form.Item label="Bairro" name="bairro">
            <Input type="text" withoutMargin />
          </Form.Item>
        </Col>
        <Col md={4} xs={4} sm={4}>
          <Form.Item label="UF" name="ufid">
            <Input type="text" withoutMargin />
          </Form.Item>
        </Col>
        <Col md={8} xs={8} sm={8}>
          <Form.Item label="Cidade" name="cidadeid">
            <Input type="text" withoutMargin />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

const VagaForm = ({ cargos, initialValues, form, name }) => {
  const [fileList, setFileList] = useState([])

  const handleSelectCurriculo = (info) => {
    let fileList = [...info.fileList]
    fileList = fileList.slice(-1)

    if (fileList[0] && fileList[0].type !== 'application/pdf') {
      toast.error('Arquivo inválido. Por favor, selecione apenas arquivos .pdf')
      setFileList([])
      form.setFieldsValue({ curriculo: null })
      return
    }

    setFileList(fileList)

    const reader = new FileReader()
    let curriculo

    reader.onload = (e) => {
      curriculo = e.target.result
      form.setFieldsValue({ curriculo })
    }

    if (fileList.length > 0) {
      reader.readAsDataURL(fileList[0].originFileObj)
    }
  }

  const handleRemoveCurriculo = () => {
    setFileList([])
  }

  const handleSelectCargos = (cargos) => {
    if (cargos.length > 3) {
      toast.error('Você deve escolher no máximo 3 cargos')
      return false
    }
  }

  return (
    <Form
      name={name}
      layout="vertical"
      initialValues={{
        profissao: undefined,
        prtsalario: undefined,
        indicacao: undefined,
        linkedin: undefined,
        twitter: undefined,
        site: undefined,
        cargosEscolhidos: [],
        curriculo: undefined,
        ...initialValues,
      }}
      form={form}
      preserve
    >
      <Row gutter={20}>
        <Col md={14} xs={14} sm={14}>
          <Form.Item label="Profissão" name="profissao">
            <Input
              type="text"
              placeholder="Informe sua profissão"
              withoutMargin
            />
          </Form.Item>
        </Col>
        <Col md={10} xs={10} sm={10}>
          <Form.Item label="Pretensão Salarial" name="prtsalario">
            <InputNumber
              defaultValue={0}
              min={0}
              max={10000000}
              decimalSeparator=","
              parser={(value) => value.replace(/\R\$\s?|(,*)/g, '')}
              formatter={(value) => `R$ ${value}`}
              withoutMargin
              style={{ width: '100%' }}
              key="candidato_vaga-form-prtsalario"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={12} xs={12} sm={12}>
          <Form.Item label="Linkedin" name="linkedin">
            <Input
              type="text"
              placeholder="Caso possua, informe o link"
              suffix={<LinkedinOutlined />}
              withoutMargin
              key="candidato_vaga-form-linkedin"
            />
          </Form.Item>
        </Col>
        <Col md={12} xs={12} sm={12}>
          <Form.Item label="Twitter" name="twitter">
            <Input
              type="text"
              suffix={<TwitterOutlined />}
              placeholder="Caso possua, informe o link"
              withoutMargin
              key="candidato_vaga-form-twitter"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item label="Site" name="site">
            <Input
              type="text"
              placeholder="Caso possua um site próprio, blog ou portifólio informe o link"
              withoutMargin
              key="candidato_vaga-form-site"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item label="Indicação" name="indicacao">
            <Input
              type="text"
              placeholder="Caso tenha sido indicado, informe o nome de quem o indicou"
              withoutMargin
              key="candidato_vaga-form-indicacao"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item hidden name="curriculo">
            <Input type="text" key="candidato_vaga-form-curriculo" />
          </Form.Item>
          <Form.Item name="files">
            <Upload
              accept=".pdf,application/pdf"
              multiple={false}
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  onSuccess('ok')
                }, 0)
              }}
              onChange={(info) => handleSelectCurriculo(info)}
              fileList={fileList}
              onRemove={handleRemoveCurriculo}
              key="candidato_vaga-form-files"
            >
              <Button icon={<UploadOutlined />} type="primary">
                Enviar currículo
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24} sm={24}>
          <Form.Item
            label="Cargo pretendido (Selecione até 3 cargos)"
            name="cargosEscolhidos"
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Selecione o cargo"
              name="cargosEscolhidos"
              onChange={(cargos) => handleSelectCargos(cargos)}
              style={{ width: '100%' }}
              dropdownRender={(menu) => <div>{menu}</div>}
              withoutMargin
              key="candidato_vaga-form-cargos"
            >
              {cargos.map(({ cargo_id, cargo_nome }) => (
                <Option key={cargo_id} value={cargo_id}>
                  {cargo_nome}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export const steps = 3

function CadastroCandidato({
  pcdChecked,
  cargos,
  currentStep,
  intl,
  initialValues = {},
  type = 'new',
  modal = true,
  visible = false,
  closeModal,
  closable = true,
  onSubmit,
}) {
  const [currentStepModal, setCurrentStepModal] = useState(0)

  const [dadosPessoaisForm] = Form.useForm()

  const [enderecoForm] = Form.useForm()

  const [vagaForm] = Form.useForm()

  const [fields, setFields] = useState(null)

  const stepsComponents = [
    <DadosPessoaisForm
      pcdChecked={pcdChecked}
      name="candidato-dados-form"
      intl={intl}
      type={type}
      initialValues={initialValues}
      form={dadosPessoaisForm}
      key="candidato_dados-pessoais-form"
    />,
    <EnderecoForm
      name="candidato-endereco-form"
      initialValues={initialValues}
      form={enderecoForm}
      key="candidato_endereco-form"
    />,
    <VagaForm
      name="candidato-vaga-form"
      cargos={cargos}
      initialValues={initialValues}
      form={vagaForm}
      key="candidato_vaga-form"
    />,
  ]

  const Forms = (
    <div className="adicionar-candidatos">
      <Steps
        current={modal ? currentStepModal : currentStep}
        style={{ marginBottom: 32 }}
      >
        <Step title="Dados Pessoais" />
        <Step title="Endereço" />
        <Step title="Vaga" />
      </Steps>
      {stepsComponents[modal ? currentStepModal : currentStep]}
    </div>
  )

  const saveFields = (step) => {
    const actions = {
      0: () => setFields({ ...fields, ...dadosPessoaisForm.getFieldsValue() }),
      1: () => setFields({ ...fields, ...enderecoForm.getFieldsValue() }),
      2: () => setFields({ ...fields, ...vagaForm.getFieldsValue() }),
    }

    if (actions[step]) {
      actions[step]()
    }
  }

  const handleNextStep = () => {
    saveFields(currentStepModal)
    setCurrentStepModal(currentStepModal + 1)
  }

  const handlePrevStep = () => {
    setCurrentStepModal(currentStepModal - 1)
  }

  const handleClose = () => {
    dadosPessoaisForm.resetFields()
    vagaForm.resetFields()
    enderecoForm.resetFields()
    setCurrentStepModal(0)
    setFields(null)
    closeModal()
  }

  const handleSubmit = async () => {
    const fieldsValues = { ...fields, ...vagaForm.getFieldsValue() }
    const fieldsUpdatedValues = Object.keys(fieldsValues)
      .filter((key) => fieldsValues[key] !== initialValues[key])
      .reduce(
        (acc, nextKey) => ({ ...acc, [nextKey]: fieldsValues[nextKey] }),
        {}
      )

    const success = await onSubmit(fieldsUpdatedValues)

    if (success) {
      setCurrentStepModal(0)
      setFields(null)
      closeModal()
    }
  }

  return modal ? (
    <Modal
      closable={closable}
      title={
        type === 'new'
          ? 'CADASTRAR CANDIDATO'
          : type === 'edit'
          ? 'EDITAR CANDIDATO'
          : 'CANDIDATO'
      }
      visible={visible}
      destroyOnClose
      onCancel={handleClose}
      footer={
        <div className="candidatos__addmodal__footer">
          <Button
            type="primary"
            danger
            onClick={handleClose}
            className="candidatos__button__cancel"
          >
            CANCELAR
          </Button>
          {currentStepModal > 0 && (
            <Button onClick={handlePrevStep}>VOLTAR</Button>
          )}
          {currentStepModal + 1 < steps ? (
            <Button type="primary" onClick={handleNextStep}>
              PRÓXIMO
            </Button>
          ) : (
            <Button type="primary" onClick={handleSubmit}>
              SALVAR
            </Button>
          )}
        </div>
      }
    >
      {Forms}
    </Modal>
  ) : (
    Forms
  )
}

export default injectIntl(CadastroCandidato)
