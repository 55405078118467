// Redux types
import * as constants from '../constants/constantsEsqueciMinhaSenha'

export const postResetSenha = ({ email, contaUrl }) => {
  return {
    type: constants.RESET_SENHA_POST,
    api: {
      method: 'post',
      url: `${constants.URL_RESET_SENHA}`,
      data: {
        email,
        conta_url: contaUrl,
      },
    },
  }
}
