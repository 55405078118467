import styled from 'styled-components'

export const Status = styled.span`
  display: block;
  width: 6px;
  margin: 0 auto;
  height: 6px;
  border-radius: 50%;

  background-color: ${(props) => (props.ativo ? '#3dd353' : '#db092f')};
`
