import styled from 'styled-components'

export const Log = styled.div`
  display: flex;
  flex-direction: column;

  .log-username {
    font-weight: 500;
    font-size: 14px;
  }

  span {
    font-size: 14px;
  }

  .log-date {
    font-size: 13px;
  }

  .anticon {
    margin-left: 8px;
  }

  .log-obs {
    width: 100%;
    padding: 5px 10px 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
  }
`

export const LogsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 485px;
  overflow-y: auto;
  padding: 0px 10px 0px 10px;
  margin-bottom: 16px;

  ${Log} + ${Log} {
    margin-top: 16px;
  }
`

export const Container = styled.div`
  > .ant-input {
    margin-bottom: 16px;
  }

  .btn-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    > .ant-btn {
      width: 100%;
    }
  }

  @media screen and (min-width: 575px) {
    .btn-row > .ant-btn {
      width: auto;
    }
  }

  padding: 10px 10px 20px 10px;
`
