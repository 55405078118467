// Redux types
import * as constants from '../constants/constantsGestaoPesquisas'

export const getColaboradores = () => {
  return {
    type: constants.COLABORADORES_GET,
    api: {
      method: 'get',
      url: constants.URL_COLABORADORES_GET,
    },
  }
}
export const getPesquisas = () => {
  return {
    type: constants.PESQUISAS_GET,
    api: {
      method: 'get',
      url: constants.URL_PESQUISAS_GET,
    },
  }
}

export const getKpiComportamental = () => {
  return {
    type: constants.PESQUISA_COMPORTAMENTAL,
    api: {
      method: 'get',
      url: constants.URL_PESQUISA_COMPORTAMENTAL,
    },
  }
}

export const getKpiComportamentalByDate = (inicio, fim) => {
  return {
    type: constants.PESQUISA_COMPORTAMENTAL_KPI,
    api: {
      method: 'get',
      url: `${constants.URL_PESQUISA_COMPORTAMENTAL_KPI}/?from=${inicio}&to=${fim}`,
    },
  }
}

export const getKpiPercepcao = () => {
  return {
    type: constants.PESQUISA_PERCEPCAO_KPI,
    api: {
      method: 'get',
      url: constants.URL_PESQUISA_PERCEPCAO_KPI,
    },
  }
}

export const getKpiPercepcaoByDate = (inicio, fim) => {
  return {
    type: constants.PESQUISA_PERCEPCAO_KPI,
    api: {
      method: 'get',
      url: `${constants.URL_PESQUISA_PERCEPCAO_KPI}/?from=${inicio}&to=${fim}`,
    },
  }
}

export const getKpiValores = () => {
  return {
    type: constants.PESQUISA_VALORES_KPI,
    api: {
      method: 'get',
      url: constants.URL_PESQUISA_VALORES_KPI,
    },
  }
}

export const getKpiValoresByDate = (inicio, fim) => {
  return {
    type: constants.PESQUISA_VALORES_KPI,
    api: {
      method: 'get',
      url: `${constants.URL_PESQUISA_VALORES_KPI}/?from=${inicio}&to=${fim}`,
    },
  }
}

export const sendVetorComportamental = (data, { enviarEmails }) => {
  return {
    type: constants.SEND_AVALIACOES,
    api: {
      method: 'post',
      url: constants.URL_SEND_AVALIACOES,
      data: {
        ...data,
        enviarEmails,
      },
    },
  }
}

export const sendPercepcao = (pes_id, { enviarEmails }) => {
  return {
    type: constants.SEND_PERCEPCAO,
    api: {
      method: 'post',
      url: constants.URL_SEND_PERCEPCAO + '/' + pes_id + '/2',
      data: { enviarEmails },
    },
  }
}

export const sendValoresOrganizacionais = (pes_id, { enviarEmails }) => {
  return {
    type: constants.SEND_PESQ_VALORES_ORGANIZACIONAIS,
    api: {
      method: 'post',
      url: constants.URL_SEND_PESQ_VALORES_ORGANIZACIONAIS(pes_id),
      data: { enviarEmails },
    },
  }
}

export const cancelVetorComportamental = (id, avalia_id) => {
  return {
    type: constants.VETOR_COMPORTAMENTAL_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_VETOR_COMPORTAMENTAL_DELETE}/${id}/${avalia_id}`,
    },
  }
}
export const cancelPercepcao = (id, avalia_id) => {
  return {
    type: constants.PERCEPCAO_AMBIENTE_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_PERCEPCAO_AMBIENTE_DELETE}/${id}/${avalia_id}`,
    },
  }
}
export const cancelValoresOrganizacionais = (id, avalia_id) => {
  return {
    type: constants.DELETE_PESQ_VALORES_ORGANIZACIONAIS,
    api: {
      method: 'delete',
      url: `${constants.URL_DELETE_PESQ_VALORES_ORGANIZACIONAIS(avalia_id)}`,
    },
  }
}

export const newLink = (pes_id, avalia_id) => {
  return {
    type: constants.POST_GESTAO_PESQUISAS_VETOR_SHARE,
    api: {
      method: 'post',
      url: constants.URL_POST_GESTAO_PESQUISAS_VETOR_SHARE(pes_id, avalia_id),
    },
  }
}

export const sendValoresOrganizacionaisLote = ({ pessoas, enviarEmails }) => {
  return {
    type: constants.SENDLOTE_PESQ_VALORES_ORGANIZACIONAIS,
    api: {
      method: 'post',
      url: constants.URL_SENDLOTE_PESQ_VALORES_ORGANIZACIONAIS,
      data: {
        pessoas,
        enviarEmails,
      },
    },
  }
}
