import * as constants from '../constants/constants'

export const loadAtividades = () => {
  return {
    type: constants.ATIVIDADE_LOAD,
  }
}

export const listAtividades = (id) => {
  return {
    type: constants.ATIVIDADE_LIST,
    api: {
      method: 'get',
      url: constants.URL_ATIVIDADE_LIST(id),
    },
  }
}

export const getFrequencia = () => {
  return {
    type: constants.FREQUENCIA_CARGO,
    api: {
      method: 'get',
      url: constants.URL_FREQUENCIA_CARGO,
    },
  }
}

export const storeAtividades = ({
  id,
  data,
  oquefaz,
  paraquefaz,
  comofaz,
  frequencia_id,
}) => {
  return {
    type: constants.ATIVIDADE_POST,
    api: {
      method: 'post',
      url: constants.URL_ATIVIDADE_POST(id),
      data: {
        oquefaz: data.oquefaz,
        paraquefaz: data.paraquefaz,
        comofaz: data.comofaz,
        frequencia_id: data.frequencia_id,
      },
    },
  }
}

export const updateAtividade = ({
  id,
  data,
  atividade_id,
  oquefaz,
  paraquefaz,
  comofaz,
  frequencia_id,
}) => {
  return {
    type: constants.ATIVIDADE_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_ATIVIDADE_ID(id, atividade_id),
      data: { oquefaz, paraquefaz, comofaz, frequencia_id },
    },
  }
}

export const deleteAtividades = ({ id, atividade_id }) => {
  return {
    type: constants.ATIVIDADE_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_ATIVIDADE_ID(id, atividade_id),
    },
  }
}
