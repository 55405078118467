import React from 'react'

import Olhar360GeralView from '../../../relatoriosGeral/olhar360/Olhar360GeralView'
import { CulturaOrganizacional } from '../../@types'

interface Olhar360Props {
  culturaOrganizacional: CulturaOrganizacional
}

export default function Olhar360({
  culturaOrganizacional,
}: Olhar360Props): JSX.Element {
  return <Olhar360GeralView culturaOrganizacional={culturaOrganizacional} />
}
