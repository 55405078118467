import styled from 'styled-components'

export const ButtonUpload = styled.button`
  border: none;
  color: white;
  background-color: #30318b;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    margin-right: 21px;
    margin-bottom: 3px;
  }

  input {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 40px;
    cursor: pointer;
    width: 100%;
  }
`

export const ContainerAnexos = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const AnexoWrapper = styled.div`
  width: auto;
  height: 30px;
  border: 1px solid #afacac;
  display: flex;
  justify-content: space-between;
  padding: 0 6px;
  margin-right: 10px;
  background: red;
  background: white;
  span {
    font-size: 10px;
    color: #afacac;
    line-height: 6px;
  }

  .delete-anexo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 15px;
      height: 15px;
      color: red;
      margin-left: 10px;
      margin-top: 6px;
    }
  }
`

export const EditorLegend = styled.div`
  margin-top: 16px;

  > ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    > li {
      line-height: 2em;
      display: inline-block;
    }
  }

  @media screen and (min-width: 700px) {
    > ul {
      flex-direction: row;
      justify-content: center;
      align-items: center;

      > li {
        margin: 0;

        & + li {
          margin-left: 16px;
        }
      }
    }
  }
`
