import React, { Component } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { message, Input, Button } from 'antd'
import { toast } from 'react-toastify'

// Components
import PerfilViewCandidato from './PerfilViewCandidato'

// CSS

// Constants

// Services
import { getContaURL } from '../../services/auth'

class PerfilCandidatoController extends Component {
  state = {
    showModal: {
      alocarTime: false,
      alocarCargo: false,
      editarCandidato: false,
      escolherCargo: false,
      enviarPesquisa: false,
      contratarCandidato: false,
      triarCandidato: false,
      confirmTriagem: false,
      novoCargo: false,
    },
    isCandidato: true,
    cargoId: '',
    cargoNome: '',
    candidato: {},
    cargos: [],
    pcd: 0,
    vetorComportamental: false,
    processoSelected: null,
    etapacand_id: null,
    observacao: null,
    processoTriagemSelected: null,
    editCandidatoCurrentStep: 0,
    newCargoContratacao: null,
    confirmTriagemMessage: undefined,
    contratacaoCandidatoData: undefined,
  }

  componentDidUpdate = (_, prevState) => {
    if (
      prevState.processoSelected !== this.state.processoSelected &&
      this.state.processoSelected &&
      this.state.etapacand_id
    ) {
      const { listLogs } = this.props
      listLogs({
        etapacand_id: this.state.etapacand_id,
        processo_id: this.state.processoSelected,
      })
    }
  }

  componentWillUnmount = () => {
    const { perfilCandidatoDestroy } = this.props
    perfilCandidatoDestroy()
  }

  componentDidMount = () => {
    const {
      match,
      getInfoPerfil,
      getVetores,
      getVetorComportamental,
      getCargos,
      getCargosDisponiveis,
      getCandidatos,
      listProcessos,
      getCultura,
      getCulturaOrg,
      getFitCultural,
      getCulturaOrgEmpresa,
      getCulturaEmpresa,
      listValoresOrg,
      listProcessosTriagem,
      listCargos,
      history,
    } = this.props

    getInfoPerfil({ id: match.params.id }).then(({ response }) => {
      const { data } = response
      const { pessoa } = data
      if (parseInt(pessoa.ativorelatorios) === 1) {
        history.push(`/${getContaURL()}/perfil/${match.params.id}`)
      }
      getVetores({ id: match.params.id })
      getVetorComportamental({ id: match.params.id })
      getCargos({ id: match.params.id })
      getCargosDisponiveis()
      getCultura(match.params.id)
      getCulturaOrg(match.params.id)
      getFitCultural(match.params.id)
    })

    getCulturaEmpresa()
    getCulturaOrgEmpresa()

    listValoresOrg()

    listProcessosTriagem()

    listCargos()

    getCandidatos().then(({ response }) => {
      if (response.status === 200) {
        const candidato = response.data.candidatos.filter(
          (candidato) => candidato.pes_id === match.params.id
        )
        this.setState({
          cargoId: candidato.length > 0 && candidato[0].cargos_id,
          cargoNome: candidato.length > 0 && candidato[0].cargos,
        })
      }
    })

    listProcessos(match.params.id).then(({ response }) => {
      if (response.status === 200 && response.data.processos.length > 0) {
        const { etapacand_id } =
          response.data.processos[0].etapas[0].etapaCandidato[0]
        const { processo_id } = response.data.processos[0]
        this.setState({
          processoSelected: processo_id,
          etapacand_id,
        })
      }
    })
  }

  handleChange = ({ target }, subState) => {
    if (subState) {
      this.setState((prevState) => ({
        [subState]: {
          ...prevState[subState],
          [target.name]: target.value,
        },
      }))
    } else this.setState({ [target.name]: target.value })
  }

  openModal = (modalId) => {
    this.setState((prevState) => ({
      showModal: { ...prevState.showModal, [modalId]: true },
    }))
  }

  closeModal = (modalId) => {
    this.setState((prevState) => ({
      showModal: { ...prevState.showModal, [modalId]: false },
    }))
  }

  /*
	handleEfetivar = (id) => {
		const { openModal } = this;
		const { efetivaCandidato, info, getInfoPerfil } = this.props;
		const { cargoId, cargoNome } = this.state;

		const candidato = {
			cpf: info['documento'],
			id: info['id'],
			cargo: cargoId.includes(',')
				? cargoId.split(',').map((cargo_id, index) => {
					return {
						cargo_id,
						cargo_nome: cargoNome.split(',')[index],
					};
				})
				: cargoId,
		};

		if (Array.isArray(candidato.cargo)) {
			this.setState({ candidato, cargos: candidato.cargo }, () =>
				openModal('escolherCargo'),
			);
		} else {
			efetivaCandidato(candidato).then(({ response }) => {
				if (response.status === 200) {
					message.success('Efetivado com sucesso!');
					getInfoPerfil({ id: info['id'] });
					this.setState({ isCandidato: false });
				}
			});
		}
	};
	*/

  handleChangeModal = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value })
  }

  handleChangeCheckbox = (evt) => {
    this.setState({ [evt.target.name]: evt.target.checked })
  }

  onModalSubmit = (modalId, confirm = false) => {
    const { postCargoCandidato, getCargos, info } = this.props
    const { alocarCargo } = this.state

    switch (modalId) {
      case 'alocarCargo': {
        return postCargoCandidato(alocarCargo.id, info.id).then(
          ({ response }) => {
            if (response.status === 200) {
              message.success('Cargo adicionado com sucesso!')
              getCargos({ id: info.id })
              this.closeModal('alocarCargo')
            }
          }
        )
      }

      case 'enviarPesquisa':
        {
          const { vetorComportamental } = this.state
          const { match, sendVetorComportamental, getVetorComportamental } =
            this.props

          const promises = []

          if (vetorComportamental)
            promises.push(
              sendVetorComportamental({
                pessoas: [{ pes_id: info.id }],
              })
            )

          Promise.all(promises).then((payload) => {
            if (
              payload.length > 0 &&
              payload.filter(({ response }) => response.status !== 200)
                .length === 0
            ) {
              message.success('Pesquisa enviada com sucesso!')
              getVetorComportamental({ id: match.params.id })
            }

            this.closeModal('enviarPesquisa')
          })
        }
        break

      case 'triarCandidato': {
        const { processoTriagemSelected } = this.state
        const { triarCandidatos, validateTriagem, match } = this.props
        if (processoTriagemSelected) {
          validateTriagem({ pes_id: match.params.id }).then(({ response }) => {
            if (response.status === 204 || confirm) {
              triarCandidatos({
                processo_id: processoTriagemSelected,
                candidatos: [match.params.id],
                confirm: true,
              }).then(({ response }) => {
                if (response.status === 200) {
                  toast.success('Candidato triado com sucesso.')
                  this.closeModal('confirmTriagem')
                  this.closeModal('triarCandidato')
                  this.setState({
                    processoTriagemSelected: null,
                    confirmTriagemMessage: undefined,
                  })
                } else toast.error('Erro ao triar candidato.')
              })
            } else if (response.status === 412) {
              const { message } = response.data
              this.setState({
                confirmTriagemMessage: message,
              })
              etapacand_id
              this.closeModal('triarCandidato')
              this.openModal('confirmTriagem')
            }
          })
        }
        break
      }

      default:
        break
    }
  }

  getColumnSearchProps = (dataIndex, label = '') => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Buscar por ${label || dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  deleteCargoCandidato = (idCargo, idPessoa) => {
    const { match, deleteCargoCandidato, getCargos } = this.props
    deleteCargoCandidato(idCargo, idPessoa).then(({ response }) => {
      if (response.status === 200) {
        getCargos({ id: match.params.id })
      }
    })
  }

  excluirPerfil = () => {
    const { excluirPerfil, info, history } = this.props
    const contaURL = getContaURL()

    excluirPerfil({ id: info.id }).then(({ response }) => {
      if (response.status === 200) {
        history.push(`/${contaURL}/colaboradores`)
        message.success('Colaborador excluido com sucesso')
      }
    })
  }

  handleChangePsSelected = (processo_id) => {
    const { processos } = this.props

    const processo = processos.find((p) => p.processo_id === processo_id)

    if (processo) {
      this.setState({
        processoSelected: processo_id,
        etapacand_id: processo.etapas[0].etapaCandidato[0].etapacand_id,
      })
    }
  }

  handleChangeObservacao = (value) => {
    this.setState({ observacao: value })
  }

  handleCreatePsLog = () => {
    const { storeLog, listLogs } = this.props
    const { observacao, etapacand_id, processoSelected } = this.state
    if (observacao && etapacand_id && processoSelected) {
      storeLog({
        etapacand_id,
        processo_id: processoSelected,
        observacao,
      }).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Observação registrada com sucesso.')
          this.setState({ observacao: null })
          listLogs({ processo_id: processoSelected, etapacand_id })
        } else toast.error('Erro ao registrar observação.')
      })
    }
  }

  handleDeletePsLog = (log_id) => {
    const { deleteLog, listLogs } = this.props
    const { etapacand_id, processoSelected } = this.state

    if (etapacand_id && processoSelected && log_id) {
      deleteLog({ etapacand_id, processo_id: processoSelected, log_id }).then(
        ({ response }) => {
          if (response.status === 200) {
            toast.success('Observação excluída com sucesso.')
            listLogs({ processo_id: processoSelected, etapacand_id })
          } else if (response.status === 400 && response.data.message) {
            toast.error(response.data.message)
          } else toast.error('Erro ao excluir observação.')
        }
      )
    }
  }

  handleChangePsTriagem = ({ target }) => {
    this.setState({ processoTriagemSelected: target.value })
  }

  handleTriar = () => {
    this.openModal('triarCandidato')
  }

  handleNextStepEditCandidato = () => {
    const { editCandidatoCurrentStep } = this.state
    this.setState({ editCandidatoCurrentStep: editCandidatoCurrentStep + 1 })
  }

  handlePrevStepEditCandidato = () => {
    const { editCandidatoCurrentStep } = this.state
    this.setState({ editCandidatoCurrentStep: editCandidatoCurrentStep - 1 })
  }

  handleSubmitEditCandidato = async (newValues) => {
    const { getInfoPerfil, info, putCandidato } = this.props
    return putCandidato({
      pes_id: info.id,
      data: newValues,
    }).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Candidato editado com sucesso!')
        getInfoPerfil({ id: info.id })
        return true
      }

      toast.error('Erro ao editar cadastro do candidato.')
      return false
    })
  }

  handleCancelContratacaoCandidato = () => {
    this.setState({
      newCargoContratacao: null,
    })
    this.closeModal('contratarCandidato')
    this.closeModal('escolherCargo')
  }

  handleOpenContratacao = () => {
    this.closeModal('escolherCargo')
    this.openModal('contratarCandidato')
  }

  handleContratarCandidato = (data) => {
    this.closeModal('contratarCandidato')

    this.closeModal('escolherCargo')

    const { contratarCandidato, info, history } = this.props

    const { newCargoContratacao } = this.state

    const pes_id = info.id

    if (!newCargoContratacao) {
      this.openModal('escolherCargo')
      return
    }

    contratarCandidato({
      pes_id,
      cargo: newCargoContratacao,
      ...data,
    }).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Candidato contratado com sucesso.')
        this.setState({ contratacaoCandidatoData: undefined })
        history.push(`/${getContaURL()}/perfil/${pes_id}`)
      } else if (response.status === 412) {
        const { message } = response.data
        toast.error(message)
        this.openModal('contratarCandidato')
      }
    })
  }

  handleCloseConfirmTriagem = () => {
    this.setState({
      confirmTriagemMessage: undefined,
      confirmTriagemList: [],
      processoTriagemSelected: null,
    })
    this.closeModal('confirmTriagem')
    this.closeModal('triarCandidato')
  }

  handleCreateCargo = (data) => {
    const { postCargo, listCargos } = this.props

    postCargo(data).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Cargo cadastrado com sucesso.')
        listCargos()
        this.closeModal('novoCargo')
      }
    })
  }

  render() {
    const { vetorComportamental, percepcaoAmbiente, ...state } = this.state
    return (
      <PerfilViewCandidato
        {...this.props}
        {...state}
        handleChange={this.handleChange}
        openModal={this.openModal}
        closeModal={this.closeModal}
        onModalSubmit={this.onModalSubmit}
        handleEfetivar={this.handleEfetivar}
        handleChangeModal={this.handleChangeModal}
        handleChangeCheckbox={this.handleChangeCheckbox}
        getColumnSearchProps={this.getColumnSearchProps}
        deleteCargoCandidato={this.deleteCargoCandidato}
        excluirPerfil={this.excluirPerfil}
        handleCreatePsLog={this.handleCreatePsLog}
        handleUpdatePsLog={this.handleUpdatePsLog}
        handleDeletePsLog={this.handleDeletePsLog}
        handleChangePsSelected={this.handleChangePsSelected}
        handleChangeObservacao={this.handleChangeObservacao}
        handleTriar={this.handleTriar}
        handleChangePsTriagem={this.handleChangePsTriagem}
        handleNextStepEditCandidato={this.handleNextStepEditCandidato}
        handlePrevStepEditCandidato={this.handlePrevStepEditCandidato}
        handleSubmitEditCandidato={this.handleSubmitEditCandidato}
        handleChangeNewCpfContratacao={this.handleChangeNewCpfContratacao}
        handleContratarCandidato={this.handleContratarCandidato}
        handleCancelContratacaoCandidato={this.handleCancelContratacaoCandidato}
        handleCloseConfirmTriagem={this.handleCloseConfirmTriagem}
        confirmTriagemMessage={this.state.confirmTriagemMessage}
        handleOpenContratacao={this.handleOpenContratacao}
        handleCreateCargo={this.handleCreateCargo}
      />
    )
  }
}

export default PerfilCandidatoController
