// URLs
export const URL_OBJETIVOS_ESTRATEGICOS = `${process.env.REACT_APP_API_URL}/objetivosEstrategicos`
export const URL_GET_QUESTIONARIO_GESTOR = `${URL_OBJETIVOS_ESTRATEGICOS}/load`
export const URL_PUT_QUESTIONARIO_GESTOR = `${URL_OBJETIVOS_ESTRATEGICOS}/respostas`
export const URL_PUT_QUESTIONARIO_AVALIADOR = `${process.env.REACT_APP_API_URL}/conta/cultura/respostas`
export const URL_GET_QUESTIONARO_GESTOR_CULTURA_QUESTOES = `${URL_OBJETIVOS_ESTRATEGICOS}/culturaQuestoes`
export const URL_GET_QUESTIONARO_GESTOR_OBJETIVOS_QUESTOES = `${URL_OBJETIVOS_ESTRATEGICOS}/objetivosQuestoes`
export const NEW_URL_OBJETIVOS_ESTRATEGICOS = `/conta/cultura/formulario/`

// Redux types
export const GET_QUESTIONARIO_GESTOR = 'GET_QUESTIONARIO_GESTOR'
export const PUT_QUESTIONARIO_GESTOR = 'PUT_QUESTIONARIO_GESTOR'
export const GET_QUESTIONARO_GESTOR_CULTURA_QUESTOES =
  'GET_QUESTIONARO_GESTOR_CULTURA_QUESTOES'

export const GET_QUESTIONARO_GESTOR_OBJETIVOS_QUESTOES =
  'GET_QUESTIONARO_GESTOR_OBJETIVOS_QUESTOES'

//

export const steps = [
  'Eu desejo um sistema: ',
  'Eu valorizo: ',
  'São palavras-chave da cultura que desejo: ',
  'Demarcam a forma de valorização das relações entre membros: ',
  'Se você pudesse priorizar a Cultura Desejada da sua empresa, como seria? ',
]
