import styled from 'styled-components'
import { Col } from 'antd'

export const Col20 = styled(Col)`
  min-height: 100%;
  flex: 1;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 991px) {
    flex-basis: 100%;
    width: 100%;
  }

  @media (min-width: 992px) {
    flex-basis: 50%;
    width: 50%;
  }
  @media (min-width: 1600px) {
    flex-basis: 20%;
    width: 20%;
  }
`

export const ChartTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  min-width: 100%;
`

export const Title = styled.div`
  background-color: transparent;
  font-size: 20px;
  font-weight: 500;
  color: #000;
`
