import styled from 'styled-components'

import { shade } from 'polished'

import { Col } from 'antd'

export const Etapas = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > div {
    border-radius: 10px;
    background-color: var(--pink);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    width: 100%;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    span,
    p {
      color: #f3f3f3;
    }

    > span {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    > p {
      font-size: 16px;
      margin: auto;
      text-align: center;
    }

    & + div {
      margin-top: 48px;
      margin-left: 0px;
    }
  }

  > span {
    min-height: 0;
    height: auto;
    svg {
      vertical-align: middle;
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    > div {
      width: auto;
      max-width: 400px;
      min-height: 0;
      height: 100%;

      & + div {
        margin-left: 48px;
        margin-top: 0px;
      }
    }
  }

  margin: 40px 0px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 0;
  margin: 0;

  overflow-y: auto;

  padding: 20px 40px;

  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  & h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 8px;
    text-align: center;
  }

  > span {
    font-size: 20px;
    font-weight: 500;
  }

  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: center;

    > span {
      font-weight: 500;
      font-size: 22px;
    }

    > div {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > span {
        font-size: 16px;

        & + span {
          margin-top: 16px;
        }
      }
    }
  }

  > button {
    margin-top: 40px;
    background-color: var(--pink);
    border-color: var(--pink);
    width: 100%;

    span {
      font-size: 16px;
      font-weight: 500;
      vertical-align: middle;
    }

    &:hover {
      background-color: ${shade(0.2, '#ef5472')};
      border-color: ${shade(0.2, '#ef5472')};
    }

    &:focus {
      background-color: ${shade(0.1, '#ef5472')} !important;
      border-color: ${shade(0.1, '#ef5472')} !important;
    }
  }
`

export const Wrapper = styled(Col)`
  flex: 1;
  min-height: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 20px 0;
`
