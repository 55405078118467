import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import { Col, Tabs } from 'antd'

import Usuarios from '../Usuarios'

import { noPadding } from '../../utils/inlineStyles'
import { getContaURL } from '../../services/auth'
import history from '../../utils/history'

const { TabPane } = Tabs

function Configuracoes() {
  return (
    <>
      <Helmet>
        <title>TeamHub | Configurações</title>
      </Helmet>
      <Col span={24} style={noPadding}>
        <Tabs
          defaultActiveKey="3"
          tabBarStyle={{
            marginBottom: '8px',
            paddingRight: '8px',
            paddingLeft: '8px',
          }}
          onTabClick={(tab) => {
            tab === '1' && history.push(`/${getContaURL()}/config/planos`)
            tab === '2' && history.push(`/${getContaURL()}/config/pesquisas`)
            tab === '4' &&
              history.push(`/${getContaURL()}/config/processos-seletivos`)
          }}
        >
          <TabPane tab={<span>Limites de Cadastro</span>} key="1" />
          <TabPane tab={<span>Pesquisas</span>} key="2" />
          <TabPane tab={<span>Usuários</span>} key="3">
            <Usuarios />
          </TabPane>
          <TabPane tab={<span>Processo Seletivo</span>} key="4" />
        </Tabs>
      </Col>
    </>
  )
}

export default Configuracoes
