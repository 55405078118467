// URLs
export const URL_VALORES_ORGANIZACIONAIS = `${process.env.REACT_APP_API_URL}/valoresOrganizacionais`

// Redux types
export const VALORES_ORGANIZACIONAIS_PUT = 'VALORES_ORGANIZACIONAIS_PUT'
export const VALORES_ORGANIZACIONAIS_GET = 'VALORES_ORGANIZACIONAIS_GET'

// Cards
export const cards = []

// Data processing
export const apiToFront = (data) =>
  data.valores.map(
    ({
      valor,
      comportamentos,
      ativo,
      valor_id,
      fitcultural,
      descricao,
      usarponderacao,
    }) => ({
      nome: valor,
      comportamentos,
      excluded: !ativo,
      default: false,
      valor_id,
      fitcultural,
      descricao,
      usarponderacao: usarponderacao === 0 ? false : true,
    })
  )

export const frontToApi = (data) =>
  data.map(
    ({
      nome,
      comportamentos,
      excluded,
      valor_id,
      fitcultural,
      descricao,
      usarponderacao,
    }) => ({
      valor: nome,
      comportamentos,
      ativo: !excluded,
      valor_id,
      fitcultural,
      ...(descricao ? { descricao } : {}),
      usarponderacao: usarponderacao ? 1 : 0,
    })
  )
