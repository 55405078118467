import { Select, Input, Button } from 'antd'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { updateSubCultura } from '../Api/api'

const App = (props) => {
  const [descricao, setDescricao] = useState({})
  const [grupoId, setGrupoId] = useState('')

  useEffect(() => {
    setDescricao(props.column.desc)
  }, [props.column.desc])

  const handleChange = (value) => {
    setGrupoId(value)
  }

  const saveNewSubCulture = async () => {
    const newObj = {
      descricao,
    }
    const response = await updateSubCultura(
      props.column.cultura_id,
      props.column.subcultura_id,
      newObj
    )
    if (response.success) {
      toast.success('Cadastrado com sucesso')
      props.update()
      props.closeModal()
    }
  }

  return (
    <div style={{ minWidth: 200 }}>
      <p>Descrição</p>
      <Input
        showCount
        rows={4}
        placeholder="Descrição"
        maxLength={255}
        onChange={(res) => setDescricao(res.currentTarget.value)}
        value={descricao}
      />
      <Button
        onClick={saveNewSubCulture}
        type="primary"
        className="btn_purple newCultureBtn"
      >
        Salvar
      </Button>
    </div>
  )
}

export default App
