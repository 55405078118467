import React from 'react'

import { Container } from './styles'

//  USE WITH MODAL
function Confirm({ message, list = [] }) {
  return (
    <Container>
      <span>{message}</span>

      {list.length > 0 && (
        <ul>
          {list.map((row, index) => (
            <li key={index}>{row}</li>
          ))}
        </ul>
      )}
    </Container>
  )
}

export default Confirm
