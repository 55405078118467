import axios from 'axios'

export async function getCulturaDesejada(idCultura) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  const data = await axios.get(
    `${process.env.REACT_APP_API_URL}/conta/cultura/${idCultura}`,
    config
  )
  if (data.status !== 200)
    throw new Error('Cannot response 200 from getCulturaDesejada')
  return data
}

export async function getCargosFromApi() {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/ps/pendentes`,
    config
  )
  if (response.status !== 200)
    throw new Error('Cannot response 200 from getCargos')
  return response.data
}

export async function updateCulturaDesejada(idCultura, data) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/conta/cultura/${idCultura}`,
    data,
    config
  )
  if (response.status !== 200)
    throw new Error('Cannot response 200 from updateCulturaDesejada')
  return response
}

export async function updateStatusCulturaDesejada(statusCultura, idCultura) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const data = { cultura_status: statusCultura }
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/conta/cultura/${idCultura}`,
    data,
    config
  )
  if (response.status !== 200)
    throw new Error('Cannot response 200 from updateStatusCulturaDesejada')
  return response
}

export async function getTimesList() {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/grupo/list`,
    config
  )
  return response.data
}

export async function insertSubCultura(data) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/cultura/${data.cultura_id}/subculturas`,
      data,
      config
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function triarColaborador(id, data) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/ps/${id}/triar`,
      data,
      config
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getSubCulturas(cultura_id) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/subculturas`,
    config
  )
  return response.data
}

export async function deleteSubCultura(cultura_id, subcultura_id) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/subculturas/${subcultura_id}`,
    config
  )
  return response.data
}

export async function deleteObjetivosEstrategicos(
  cultura_id,
  subcultura_id,
  conta_id
) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  let response
  if (subcultura_id) {
    response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/objetivos-estrategicos/${conta_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        data: { subcultura_id },
      }
    )
  } else {
    response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/objetivos-estrategicos/${conta_id}`,
      config
    )
  }
  return response.data
}

export async function updateSubCultura(cultura_id, subcultura_id, data) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/subculturas/${subcultura_id}`,
    data,
    config
  )
  return response.data
}

export async function getObjetivosEstrategicos() {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/objetivosEstrategicos`,
    config
  )
  return response.data
}

export async function getObjetivos(cultura_id, subcultura_id) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  let response
  try {
    if (subcultura_id)
      response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/objetivos-estrategicos`,
        {
          params: { subcultura_id },
          headers: { Authorization: `Bearer ${token}` },
        }
      )
    else
      response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/objetivos-estrategicos`,
        config
      )
    return response.data
  } catch (error) {
    return error?.response?.data
  }
}

export async function postAvaliadores(cultura_id, subcultura_id, data) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  if (subcultura_id) data.subcultura_id = subcultura_id

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/avaliadores/`,
      data,
      config
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function putAvaliadores(
  cultura_id,
  subcultura_id,
  avaliador,
  data
) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  if (subcultura_id) {
    data.subcultura_id = subcultura_id
  }
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/avaliadores/${avaliador}`,
      data,
      config
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function deleteAvaliadores(
  cultura_id,
  subcultura_id,
  avaliador_id
) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  try {
    let response
    if (subcultura_id) {
      response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/avaliadores/${avaliador_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          data: { subcultura_id },
        }
      )
    } else {
      response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/avaliadores/${avaliador_id}`,
        config
      )
    }
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function postObjetivosEstrategicos(
  cultura_id,
  subcultura_id,
  data
) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  if (subcultura_id) data.grupo_id = subcultura_id

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/objetivos-estrategicos`,
      data,
      config
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getGruposDisponiveis() {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cultura/grupos/`,
      config
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getTimes(culturaID) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cultura/${culturaID}/times/`,
      config
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function vincularTimeSubcultura(data) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/cultura/${data.cultura_id}/times/`,
      data,
      config
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getAvaliadores(cultura_id, subcultura_id) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  let response
  try {
    if (subcultura_id)
      response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/avaliadores`,
        {
          params: { subcultura_id },
          headers: { Authorization: `Bearer ${token}` },
        }
      )
    else
      response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/avaliadores/`,
        config
      )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function deleteTimeSubcultura(
  cultura_id,
  subcultura_id,
  obj_conta_id
) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/times/${obj_conta_id}`,
      config
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function sendEmailForAllAvaliators(cultura_id) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  try {
    const data = {
      sendEmail: true,
      avaliadores: [],
    }
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/pesquisas/send`,
      data,
      config
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function sendEmailForAnAvaliatorAPI(data, cultura_id) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/pesquisas/send`,
      data,
      config
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function graficos(id, subcultura) {
  if (!id) return
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const url = subcultura
    ? `${process.env.REACT_APP_API_URL}/cultura/${id}/subculturas/${subcultura}/consensuar`
    : `${process.env.REACT_APP_API_URL}/cultura/${id}/consensuar`
  try {
    const response = await axios.get(url, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function postConsensuar(data, cultura_id, subcultura_id) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  let url
  if (subcultura_id)
    url = `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/subculturas/${subcultura_id}/consensuar`
  else url = `${process.env.REACT_APP_API_URL}/cultura/${cultura_id}/consensuar`
  try {
    const response = await axios.post(url, data, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getRelatorios(cultura_id) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const URL = `${process.env.REACT_APP_API_URL}/cultura/relatorios/empresa/instalado?cultura_id=${cultura_id}`
  try {
    const response = await axios.get(URL, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getRelatoriosWithGrupo(cultura_id, grupo_id, fit_id) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const URL = `${process.env.REACT_APP_API_URL}/cultura/relatorios/grupos/instalado?cultura_id=${cultura_id}&grupo_id=${grupo_id}&fit_id=${fit_id}`
  try {
    const response = await axios.get(URL, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getProcessosSeletivos() {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const URL = `${process.env.REACT_APP_API_URL}/cultura/relatorios/recrutamento/processos`
  try {
    const response = await axios.get(URL, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getCulturas() {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const URL = `${process.env.REACT_APP_API_URL}/conta/cultura?page=1&page_limit=100000`
  try {
    const response = await axios.get(URL, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getCulturasFromEmpresas() {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const URL = `${process.env.REACT_APP_API_URL}/fit/empresa`
  try {
    const response = await axios.get(URL, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getCulturasFromTimes() {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const URL = `${process.env.REACT_APP_API_URL}/fit/times`
  try {
    const response = await axios.get(URL, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getTimeFromCultura(cultura_id, fit_id) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const URL = `${process.env.REACT_APP_API_URL}/cultura/relatorios/grupos/list?cultura_id=${cultura_id}&fit_id=${fit_id}`
  try {
    const response = await axios.get(URL, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getAllTimes() {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const URL = `${process.env.REACT_APP_API_URL}/grupo/list/`
  try {
    const response = await axios.get(URL, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getFitsByGroup(grupo_id, cultura_id, fit_id) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  let URL = null
  if (grupo_id != null)
    URL = `${process.env.REACT_APP_API_URL}/cultura/relatorios/geral?grupo_id=${grupo_id}&cultura_id=${cultura_id}&fit_id=${fit_id}`
  else
    URL = `${process.env.REACT_APP_API_URL}/cultura/relatorios/geral?cultura_id=${cultura_id}&fit_id=${fit_id}`
  try {
    const response = await axios.get(URL, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getRecrutamentoInstalado(cultura_id, processo_id, page) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  let URL = ''
  if (processo_id !== 0)
    URL = `${process.env.REACT_APP_API_URL}/cultura/relatorios/recrutamento/instalado?cultura_id=${cultura_id}&processo_id=${processo_id}&page=${page}`
  else
    URL = `${process.env.REACT_APP_API_URL}/cultura/relatorios/recrutamento?cultura_id=${cultura_id}&page=${page}`
  try {
    const response = await axios.get(URL, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getFitByPeoples(cultura_id, grupo_id, fit_id) {
  const token = localStorage.getItem('access_token')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  let URL
  if (grupo_id)
    URL = `${process.env.REACT_APP_API_URL}/cultura/relatorios/pessoa/instalado?grupo_id=${grupo_id}&cultura_id=${cultura_id}&fit_id=${fit_id}`
  else
    URL = `${process.env.REACT_APP_API_URL}/cultura/relatorios/pessoa/instalado?cultura_id=${cultura_id}&fit_id=${fit_id}`
  try {
    const response = await axios.get(URL, config)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
