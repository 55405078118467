import React from 'react'

// Components
import EnviarPesquisa from '../../../components/enviarPesquisa/EnviarPesquisa'
import AdicionarNovoMembro from '../components/adicionarNovoMembro/AdicionarNovoMembro'

// URLs
export const URL_TIMES = `${process.env.REACT_APP_API_URL}/grupo`
export const URL_TIMES_GET = `${URL_TIMES}/list`
export const URL_TIME_INFO_GET = `${URL_TIMES}/check`

export const URL_TIMES_SEND_AVALIACOES = `${process.env.REACT_APP_API_URL}/avaliacoes/send/grupo/`
export const URL_TIMES_SEND_PESQUISAS = `${process.env.REACT_APP_API_URL}/pesquisa/grupo/`

export const URL_TIME_PESSOAS = `${process.env.REACT_APP_API_URL}/grupoPessoa`
export const URL_TIME_PESSOAS_GET = `${URL_TIME_PESSOAS}/list/`
export const URL_TIME_PESSOA_POST = `${URL_TIME_PESSOAS}/store/`
export const URL_TIME_PESSOA_DELETE = `${URL_TIME_PESSOAS}/delete/`

export const URL_PESSOAS = `${process.env.REACT_APP_API_URL}/pessoas`
export const URL_PESSOAS_GET = `${URL_PESSOAS}/list/1`

export const URL_GRUPO_LIDER = `${process.env.REACT_APP_API_URL}/grupoLider`
export const URL_GRUPO_LIDER_GET = `${URL_GRUPO_LIDER}/list/`
export const URL_GRUPO_LIDER_POST = `${URL_GRUPO_LIDER}/store/`
export const URL_GRUPO_LIDER_DELETE = `${URL_GRUPO_LIDER}/delete/`

export const URL_TIMES_SEND_VALORES = (id) =>
  `${process.env.REACT_APP_API_URL}/pesqvalores/sendlote/grupo/${id}`

export const URL_GRUPO_CULTURA_DESEJADA_GRUPO = (grupo_id) =>
  `${URL_TIMES}/${grupo_id}/culturaDesejada`
export const URL_GRUPO_CULTURA_INSTALADA_GRUPO = (grupo_id) =>
  `${URL_TIMES}/${grupo_id}/culturaInstalada`

// Redux types
export const TIMES_GET = 'TIMES_GET'
export const TIME_INFO_GET = 'TIME_INFO_GET'
export const PESSOAS_GET = 'PESSOAS_GET'
export const PESSOA_GET = 'PESSOA_GET'
export const PESSOAS_PUT = 'PESSOAS_PUT'
export const TIME_PESSOAS_GET = 'TIME_PESSOA_GET'
export const TIME_PESSOA_POST = 'TIME_PESSOA_POST'
export const TIME_PESSOA_DELETE = 'TIME_PESSOA_DELETE'
export const TIMES_SEND_AVALIACOES = 'TIMES_SEND_AVALIACOES'
export const TIMES_SEND_PESQUISAS = 'TIMES_SEND_PESQUISAS'
export const GRUPO_LIDER_GET = 'GRUPO_LIDER_GET'
export const GRUPO_LIDER_POST = 'GRUPO_LIDER_POST'
export const GRUPO_LIDER_DELETE = 'GRUPO_LIDER_DELETE'
export const GRUPO_CULTURA_DESEJADA_GRUPO = 'GRUPO_CULTURA_DESEJADA_GRUPO'
export const GRUPO_CULTURA_INSTALADA_GRUPO = 'GRUPO_CULTURA_INSTALADA_GRUPO'
export const TIMES_SEND_VALORES = `TIMES_SEND_VALORES`

// Modals
export const modals = [
  {
    id: 0,
    title: 'GERAR PESQUISA PARA OS MEMBROS DESTE TIME',
    cancelText: 'CANCELAR',
    okText: 'ENVIAR',
    children: EnviarPesquisa,
    initialState: {
      vetorComportamental: false,
      percepcaoAmbiente: false,
      enviarEmails: false,
    },
  },
  {
    id: 1,
    title: 'Adicionar Novo Membro Neste Time',
    cancelText: 'CANCELAR',
    okText: 'SALVAR',
    children: AdicionarNovoMembro,
    initialState: {
      pes_id: false,
    },
  },
]

export const dataSource = [
  {
    lider: true,
    nome: 'Audrey Malone Alencar Oliveira',
    email: 'audreymoliveira@gmail.com',
    vetorComportamental: true,
    d: '16.67%',
    i: '16.67%',
    s: '16.67%',
    c: '16.67%',
    key: 1,
  },
  {
    lider: false,
    nome: 'Shamanta Telles de Souza Silva',
    email: 'audreymoliveira@gmail.com',
    vetorComportamental: false,
    d: '16.67%',
    i: '16.67%',
    s: '16.67%',
    c: '16.67%',
    key: 2,
  },
  {
    lider: false,
    nome: 'Ricardo Alves da Silva',
    email: 'audreymoliveira@gmail.com',
    vetorComportamental: false,
    d: '16.67%',
    i: '16.67%',
    s: '16.67%',
    c: '16.67%',
    key: 3,
  },
  {
    lider: false,
    nome: 'Adryelle Oliveira Bastos',
    email: 'audreymoliveira@gmail.com',
    vetorComportamental: false,
    d: '16.67%',
    i: '16.67%',
    s: '16.67%',
    c: '16.67%',
    key: 4,
  },
]

// Data processing
export const apiToFrontGrupo = ({ grupo }) => ({
  nome: grupo.grupo_nome,
  pai: grupo.grupo_pai,
  descricao: grupo.grupo_descricao,
  qtde: grupo.qtde,
  id: grupo.grupo_id,
})

export const apiToFrontGrupoPessoa = ({ pessoa }) => ({
  nome: pessoa.pes_nome || '',
  email: pessoa.pes_email || '',
  log_data: pessoa.log_data || '',
  id: pessoa.pes_id || '',
  lider: pessoa.lider || '',
  d: pessoa.D || '',
  i: pessoa.I || '',
  s: pessoa.S || '',
  c: pessoa.C || '',
  vetorComportamental: pessoa.perfil,
})

export const SET_ACTIVE_TAB_TIMES = 'SET_ACTIVE_TAB_TIMES'
