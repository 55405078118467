import * as constants from '../constants/perfilComportamentalConstants'

import { parseVetor } from '../../../../utils/utils'

const initialState = {
  vetor: {
    intensidade: [
      {
        row: '',
        essencia: '',
        exigido: '',
        entrega: '',
      },
    ],
    rct: [],
    edl: [],
    foc: [],
    ode: {
      maior: {
        nome: '',
        score: '',
      },
      menor: {
        nome: '',
        score: '',
      },
    },
    oct: {
      maior: {
        nome: '',
        score: '',
      },
      menor: {
        nome: '',
        score: '',
      },
    },
    tdd: {
      maior: {
        nome: '',
        score: '',
      },
      menor: {
        nome: '',
        score: '',
      },
    },
    cor: [],
    erc: [],
    edc: [],
    principal: {},
    disc: [],
    perfilComp: [],
  },
  perfis: [],
}

export const perfilComportamentalGeral = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_PERFILCOMPORTAMENTAL_GERAL_VETOR: {
      if (action.response.status !== 200) {
        return state
      }

      const { data } = action.response.data

      const vetor = parseVetor(data)

      return {
        ...state,
        vetor,
      }
    }
    case constants.GET_PERFILCOMPORTAMENTAL_GERAL_PERFIS: {
      if (action.response.status !== 200) {
        return state
      }

      const { data } = action.response

      const { perfis } = data

      return { ...state, perfis }
    }
    default:
      return { ...state }
  }
}
