import React from 'react'

import AnaliseGeralView from '../../../../components/AnaliseGeral'
import { CulturaOrganizacional, CulturaItem, CulturaGeral } from '../../@types'

interface Olhar360Props {
  culturaOrganizacional: CulturaOrganizacional
  cultura: CulturaGeral
  loadingCultura: boolean
  loadingCulturaOrg: boolean
}

export default function AnaliseGeral({
  culturaOrganizacional,
  cultura,
  loadingCultura,
  loadingCulturaOrg,
}: Olhar360Props): JSX.Element {
  return (
    <AnaliseGeralView
      cultura={cultura}
      culturaOrganizacional={culturaOrganizacional}
      loadingCultura={loadingCultura}
      loadingCulturaOrg={loadingCulturaOrg}
    />
  )
}
