import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { message } from 'antd'

import history from '../../utils/history'

// Services
import { getEmpresa } from '../../services/auth'

// Components
import EsqueciMinhaSenhaView from './EsqueciMinhaSenhaView'

// CSS

class EsqueciMinhaSenhaController extends Component {
  state = {
    email: '',
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  onSubmit = (event) => {
    const { postResetSenha } = this.props
    const { email } = this.state

    event.preventDefault()
    if (!email) {
      toast.error('Insira um email para redefinir a senha')
    } else {
      postResetSenha({ email, contaUrl: getEmpresa() || 'apollo' }).then(
        ({ response }) => {
          if (response.status === 200) {
            history.push('/')
            message.success(response.data.message)
          } else if (
            response.status === 404 ||
            response.data.message == 'Usuário não encontrado'
          ) {
            history.push('/')
            message.success(
              'Detalhes de como recuperar sua senha foram enviados para seu e-mail.'
            )
          }
        }
      )
    }
  }

  render() {
    const { handleChange, onSubmit } = this

    return (
      <EsqueciMinhaSenhaView handleChange={handleChange} onSubmit={onSubmit} />
    )
  }
}

export default EsqueciMinhaSenhaController
