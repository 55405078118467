// URLs
export const URL_BASE = process.env.REACT_APP_API_URL
export const URL_PERFIL_INFO_GET = `${URL_BASE}/pessoas/check`
export const URL_VETORES_GET = `${URL_BASE}/vetorCompleto`
export const URL_VETOR_COMPORTAMENTAL_GET = `${URL_BASE}/avaliacoes/list`
export const URL_VETOR_COMPORTAMENTAL_DELETE = `${URL_BASE}/avaliacoes`
export const URL_ATIVAR_PERFIL_DELETE = `${URL_BASE}/pessoas/turnOn`
export const URL_DESATIVAR_PERFIL_DELETE = `${URL_BASE}/pessoas/delete`
export const URL_EXCLUIR_PERFIL_DELETE = `${URL_BASE}/pessoas/permaDelete`
export const URL_PERFIL_CARGOS_GET = `${URL_BASE}/candidato/listCargosCandidato`
export const URL_PERFIL_CANDIDATO_LIST_CARGOS = `${URL_BASE}/cargo/list`

export const URL_PERFIL_CANDIDATO_PROCESSOS_LIST = (candidato_id) =>
  `${URL_BASE}/candidato/${candidato_id}/listProcessos`

const URL_PERFIL_CANDIDATO_PROCESSO_LOG = ({ etapacand_id, processo_id }) =>
  `${URL_BASE}/ps/${processo_id}/etapacand/${etapacand_id}/logs`

export const URL_PERFIL_CANDIDATO_PROCESSO_LOG_LIST = ({
  etapacand_id,
  processo_id,
}) => URL_PERFIL_CANDIDATO_PROCESSO_LOG({ etapacand_id, processo_id })

export const URL_PERFIL_CANDIDATO_PROCESSO_LOG_STORE = ({
  etapacand_id,
  processo_id,
}) => URL_PERFIL_CANDIDATO_PROCESSO_LOG({ etapacand_id, processo_id })

export const URL_PERFIL_CANDIDATO_PROCESSO_LOG_DELETE = ({
  etapacand_id,
  processo_id,
  log_id,
}) =>
  `${URL_PERFIL_CANDIDATO_PROCESSO_LOG({
    etapacand_id,
    processo_id,
  })}/${log_id}`

export const URL_PERFIL_CANDIDATO_CULTURA_GET = (pes_id) =>
  `${URL_BASE}/dashboard/pessoa/cultura/${pes_id}`

export const URL_PERFIL_CANDIDATO_CULTURAORG_GET = (pes_id) =>
  `${URL_BASE}/dashboard/pessoa/culturaOrganizacional/${pes_id}`

export const URL_PERFIL_CANDIDATO_FITCULTURAL_GET = (pes_id) =>
  `${URL_BASE}/fitcultural/empresa/${pes_id}`

export const URL_PERFIL_CANDIDATO_CULTURAORG_EMPRESA_GET = `${URL_BASE}/dashboard/culturaOrganizacional`

export const URL_PERFIL_CANDIDATO_CULTURA_EMPRESA_GET = `${URL_BASE}/dashboard/culturas`

export const URL_PERFIL_CANDIDATO_VALORES_ORG_GET = `${URL_BASE}/valoresOrganizacionais`

export const URL_PERFIL_CANDIDATO_PROCESSOS_TRIAGEM_LIST = `${URL_BASE}/ps/pendentes`

export const URL_PERFIL_CANDIDATO_PUT_CANDIDATO = (pes_id) =>
  `${URL_BASE}/candidato/update/${pes_id}`

export const URL_PERFIL_CANDIDATO_CONTRATAR_CANDIDATO = (pes_id) =>
  `${URL_BASE}/candidato/${pes_id}/contratar`

export const URL_PERFIL_CANDIDATO_VALIDATE_TRIAGEM = (pes_id) =>
  `${URL_BASE}/candidato/${pes_id}/validateTriagem`

// Redux types
export const PERFIL_INFO_GET = 'PERFIL_INFO_GET'
export const VETORES_GET = 'VETORES_GET'
export const VETOR_COMPORTAMENTAL_GET = 'VETOR_COMPORTAMENTAL_GET'
export const VETOR_COMPORTAMENTAL_DELETE = 'VETOR_COMPORTAMENTAL_DELETE'

export const ATIVAR_PERFIL_DELETE = 'ATIVAR_PERFIL_DELETE'
export const DESATIVAR_PERFIL_DELETE = 'DESATIVAR_PERFIL_DELETE'
export const EXCLUIR_PERFIL_DELETE = 'EXCLUIR_PERFIL_DELETE'
export const PERFIL_CARGOS_GET = 'PERFIL_CARGOS_GET'

export const PERFIL_CANDIDATO_PROCESSOS_LIST = 'PERFIL_CANDIDATO_PROCESSOS_LIST'

export const PERFIL_CANDIDATO_PROCESSO_LOG_LIST =
  'PERFIL_CANDIDATO_PROCESSO_LOG_LIST'

export const PERFIL_CANDIDATO_PROCESSO_LOG_DELETE =
  'PERFIL_CANDIDATO_PROCESSO_LOG_DELETE'

export const PERFIL_CANDIDATO_PROCESSO_LOG_STORE =
  'PERFIL_CANDIDATO_PROCESSO_LOG_STORE'

export const PERFIL_CANDIDATO_CULTURA_GET = 'PERFIL_CANDIDATO_CULTURA_GET'

export const PERFIL_CANDIDATO_CULTURAORG_GET = 'PERFIL_CANDIDATO_CULTURAORG_GET'

export const PERFIL_CANDIDATO_FITCULTURAL_GET =
  'PERFIL_CANDIDATO_FITCULTURAL_GET'

export const PERFIL_CANDIDATO_CULTURAORG_EMPRESA_GET =
  'PERFIL_CANDIDATO_CULTURAORG_EMPRESA_GET'

export const PERFIL_CANDIDATO_CULTURA_EMPRESA_GET =
  'PERFIL_CANDIDATO_CULTURA_EMPRESA_GET'

export const PERFIL_CANDIDATO_VALORES_ORG_GET =
  'PERFIL_CANDIDATO_VALORES_ORG_GET'

export const PERFIL_CANDIDATO_PROCESSOS_TRIAGEM_LIST =
  'PERFIL_CANDIDATO_PROCESSOS_TRIAGEM_LIST'

export const PERFIL_CANDIDATO_DESTROY = 'PERFIL_CANDIDATO_DESTROY'

export const PERFIL_CANDIDATO_LIST_CARGOS = 'PERFIL_CANDIDATO_LIST_CARGOS'

export const PERFIL_CANDIDATO_PUT_CANDIDATO = 'PERFIL_CANDIDATO_PUT_CANDIDATO'

export const PERFIL_CANDIDATO_CONTRATAR_CANDIDATO =
  'PERFIL_CANDIDATO_CONTRATAR_CANDIDATO'

export const PERFIL_CANDIDATO_VALIDATE_TRIAGEM =
  'PERFIL_CANDIDATO_VALIDATE_TRIAGEM'
