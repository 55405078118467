// redux types
export const GET_DASHBOARD_VETOR_COMPLETO = 'GET_DASHBOARD_VETOR_COMPLETO'
export const GET_DASHBOARD_VETOR = 'GET_DASHBOARD_VETOR'
export const GET_DASHBOARD_COLABORADORES = 'GET_DASHBOARD_COLABORADORES'
export const GET_DASHBOARD_CULTURA_PESSOA_INDIVIDUAL =
  'GET_DASHBOARD_CULTURA_PESSOA_INDIVIDUAL'
export const GET_DASHBOARD_HISTORICO_VETOR_INDIVIDUAL =
  'GET_DASHBOARD_HISTORICO_VETOR_INDIVIDUAL'
export const GET_DASHBOARD_FIT_CULTURAL_EMPRESA_INDIVIDUAL =
  'GET_DASHBOARD_FIT_CULTURAL_EMPRESA_INDIVIDUAL'
export const POST_DASHBOARD_NEW_LINK = 'POST_DASHBOARD_NEW_LINK'
export const GET_DASHBOARD_CULTURAS = 'GET_DASHBOARD_CULTURAS'

// urls
export const URL_DASHBOARD = `${process.env.REACT_APP_API_URL}/dashboard`
export const URL_DASHBOARD_VETOR_COMPLETO = `${process.env.REACT_APP_API_URL}/vetorCompleto/`
export const URL_DASHBOARD_CULTURA_PESSOA = `${URL_DASHBOARD}/pessoa/cultura/`
export const URL_DASHBOARD_VETOR = `${process.env.REACT_APP_API_URL}/vetor/`
export const URL_COLABORADORES = `${process.env.REACT_APP_API_URL}/pessoas`
export const URL_GET_DASHBOARD_COLABORADORES = `${URL_COLABORADORES}/list/1`
export const URL_DASHBOARD_HISTORICO_VETOR_INDIVIDUAL = (id) =>
  `${process.env.REACT_APP_API_URL}/vetor/historico/${id}`
export const URL_DASHBOARD_FIT_CULTURAL_EMPRESA_INDIVIDUAL = (id) =>
  `${process.env.REACT_APP_API_URL}/fitcultural/empresa/${id}`
export const URL_DASHBOARD_NEW_LINK = (pes_id, avalia_id) =>
  `${process.env.REACT_APP_API_URL}/share/vetor/pessoa/${pes_id}/${avalia_id}`
export const URL_DASHBOARD_CULTURAS = `${URL_DASHBOARD}/culturas`

// defaults

export const vetorDefault = {
  pes_nome: undefined,
  avalia_id: undefined,
  hash: undefined,
  cultura: [],
  perfilComp: [],
  minutosPesquisa: { tempo: undefined },
  intensidade: [
    {
      row: '',
      essencia: '',
      exigido: '',
      entrega: '',
    },
  ],
  rct: [],
  edl: [],
  foc: [],
  ode: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  oct: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  tdd: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  cor: [],
  erc: [],
  edc: [],
  principal: {},
  vetores: [],
  fitCulturalEmpresa: {},
}

// Utils
export const FORCAS = ['Mercado', 'Cliente', 'Operação', 'Ambiente interno']
export const OLHARES = ['Olhar externo', 'Olhar interno']
export const GESTAO = ['Controle', 'Flexibilidade']
export const DISC = ['D', 'I', 'S', 'C']
export const CULTURAS = [
  'Inovação',
  'Empreendedora',
  'Colaboração',
  'Clã',
  'Aprendizado',
  'Complacência',
  'Regra',
  'Autoridade',
]
