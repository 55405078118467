import React from 'react'
import { useFetch } from '../../../../../../../hooks/use-fetch'

type Props = {
  membroId: number
}

interface IPerfilComportamental {
  perfilComportamental: string
}

const PerfilComportamentalMembroTime = ({ membroId }: Props) => {
  const { response, isLoading } = useFetch<IPerfilComportamental>(
    membroId
      ? `/v2/dashboard/membros/${membroId}/perfil-comportamental`
      : undefined
  )

  if (isLoading) return '...'
  return response.perfilComportamental
}

export default PerfilComportamentalMembroTime
