import React from 'react'

import moment from 'moment'

import 'moment-timezone'

import { Form } from 'antd'

import Select, { Option } from '../../../../components/select/Select'

function TriarCandidatos({
  processos,
  handleChangeModal,
  processoSelected,
  onFinish,
  single = false,
}) {
  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      initialValues={{ processoSelected }}
      id="triar-candidatos-submit"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        label={
          !single
            ? 'Selecione o Processo Seletivo que deseja triar os candidatos'
            : 'Selecione o Processo Seletivo que deseja triar o candidato.'
        }
        name="processoSelected"
        rules={[
          {
            required: true,
            message: 'Por favor, selecione o Processo Seletivo desejado.',
          },
        ]}
      >
        <Select
          placeholder="Selecione"
          onChange={(option) =>
            handleChangeModal({
              target: {
                name: 'processoSelected',
                value: option,
              },
            })
          }
          style={{ width: '100%' }}
          value={processoSelected}
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {processos.map(({ cargo, processo_id, inicio, grupo }) => (
            <Option value={processo_id} key={processo_id}>
              {cargo && cargo.cargo_nome} {grupo && `(${grupo.grupo_nome})`} -
              Início em{' '}
              {moment(inicio).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}

export default TriarCandidatos
