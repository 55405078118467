import {
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  StopOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tabs,
  Tag,
  Tooltip,
} from 'antd'
import 'moment-timezone'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as Svg } from '../../../../assets/onu_pcd.svg'
import Confirm from '../../../../components/Confirm'
import { getContaURL } from '../../../../services/auth'
import {
  retomarProcesso,
  suspenderProcesso,
} from '../ProcessoSeletivo/actions/actionsProcessoSeletivo'
import CancelarProcesso from '../ProcessoSeletivo/components/CancelarProcesso'
import {
  cancelProcesso,
  finalizarProcesso,
  validateFinalizarProcesso,
} from '../PsKanban/actions/psKanbanActions'
import PsKanban from '../PsKanban/index'
import {
  checkProcesso,
  clearPainel,
  getAllQuizzesCandidates,
  getDetails,
  getProcessoQuiz,
} from './actions/psPainelActions'
import InformacoesBasicas from './containers/InformacoesBasicas/index'
import Testes from './containers/Testes'
import { Header } from './styles'

const { TabPane } = Tabs

function PsPainel({ match }) {
  const history = useHistory()

  const contaUrl = getContaURL()

  const dispatch = useDispatch()

  const processo = useSelector((state) => state.psPainel.processo)

  const motivosCancelamento = useSelector(
    (state) => state.psPainel.motivosCancelamento
  )

  const [loading, setLoading] = useState(true)

  const [confirmFinalizacaoText, setConfirmFinalizacaoText] = useState('')

  const [showModalConfirmFinalizacao, setShowModalConfirmFinalizacao] =
    useState(false)

  const [showModalCancelarProcesso, setShowModalCancelarProcesso] =
    useState(false)

  const [motivoCancelamentoSelected, setMotivoCancelamentoSelected] =
    useState(null)

  const [objectQuizzes, setObjectQuizzes] = useState([])

  const [loadingQuizzes, setLoadingQuizzes] = useState(true)

  const loadQuizzesCandidates = useCallback(() => {
    const { processo_id } = match.params
    dispatch(getAllQuizzesCandidates(processo_id)).then(({ response }) => {
      if (response.status === 200) {
        setObjectQuizzes(response.data)
        setLoadingQuizzes(false)
      }
    })
  }, [dispatch, match.params])

  const closeModalConfirmFinalizacao = () => {
    setShowModalConfirmFinalizacao(false)
  }

  const openModalCancelarProcesso = () => {
    if (motivosCancelamento.length === 0) {
      toast.error(
        'Você precisa cadastrar ao menos 1 motivo de cancelamento antes de cancelar Processos Seletivos.'
      )
    } else setShowModalCancelarProcesso(true)
  }

  const closeModalCancelarProcesso = () => {
    setMotivoCancelamentoSelected(null)
    setShowModalCancelarProcesso(false)
  }

  const handleChangeMotivoCancelamento = (option) => {
    setMotivoCancelamentoSelected(option)
  }

  useEffect(() => {
    loadQuizzesCandidates()
  }, [loadQuizzesCandidates])

  useEffect(() => {
    dispatch(clearPainel())

    const { processo_id } = match.params

    dispatch(checkProcesso(processo_id)).then(({ response }) => {
      if (response.status === 200) {
        dispatch(getDetails(processo_id)).then(() => setLoading(false))
        dispatch(getProcessoQuiz(processo_id)).then(() => setLoading(false))
      } else {
        history.goBack()
      }
    })
  }, [history, dispatch, match.params])

  const motivoCancelamentoPS = motivosCancelamento.find(
    ({ cancel_id }) => cancel_id === processo.cancel_id
  )

  const reloadProcesso = useCallback(
    (processo_id) => {
      setLoading(true)
      dispatch(getDetails(processo_id)).then(() => setLoading(false))
    },
    [dispatch]
  )

  const handleSuspenderProcesso = useCallback(
    (processo_id) => {
      dispatch(suspenderProcesso(processo_id)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Processo Seletivo suspenso')
          reloadProcesso(processo_id)
        }
      })
    },
    [dispatch, reloadProcesso]
  )

  const handleRetomarProcesso = useCallback(
    (processo_id) => {
      dispatch(retomarProcesso(processo_id)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Processo Seletivo retomado')
          reloadProcesso(processo_id)
        }
      })
    },
    [dispatch, reloadProcesso]
  )

  // const handleFinalizarProcesso = (processo_id) => {
  //   dispatch(
  //     validateFinalizarProcesso(processo_id).then(({ response }) => {
  //       if (response.status === 204) {
  //         dispatch(finalizarProcesso(processo_id)).then(({ response }) => {
  //           if (response.status === 200) {
  //             toast.success("Processo Seletivo finalizado");
  //             history.push(`/${contaUrl}/recrutamento-selecao`);
  //           } else if (response.status === 500) {
  //             toast.error("Erro ao finalizar Processo Seletivo");
  //           }
  //         });
  //       } else if (response.status == 412) {
  //         //
  //       }
  //     })
  //   );
  // };

  const handleFinalizarProcesso = (processo_id) => {
    dispatch(validateFinalizarProcesso(processo_id)).then(({ response }) => {
      if (response.status === 204 || response.status === 200) {
        dispatch(finalizarProcesso(processo_id)).then(({ response }) => {
          if (response.status === 200) {
            toast.success('Processo Seletivo finalizado.')
            history.push(`/${contaUrl}/recrutamento-selecao`)
          } else if (response.status === 500) {
            toast.error('Erro ao finalizar Processo Seletivo.')
          }
        })
      } else if (response.status === 412) {
        setConfirmFinalizacaoText(response.data.message)
        setShowModalConfirmFinalizacao(true)
      } else if (response.status === 400) {
        message.error(response.data.message)
        message.error(
          'Para finalizar um processo seletivo deve se ter um candidato contratado'
        )
      }
    })
  }

  const submitModalCancelarProcesso = () => {
    const { processo_id } = match.params

    if (motivoCancelamentoSelected) {
      dispatch(
        cancelProcesso({ processo_id, cancel_id: motivoCancelamentoSelected })
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Processo Seletivo cancelado.')
          history.push(`/${contaUrl}/recrutamento-selecao`)
        } else {
          toast.error('Erro ao cancelar Processo Seletivo.')
        }
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Processo Seletivo | Painel</title>
      </Helmet>
      <Row gutter={16}>
        <Col span={24}>
          <Header>
            {!loading ? (
              <Space direction="horizontal">
                <h1 style={{ display: 'flex', alignItems: 'center' }}>
                  {processo && processo.cargo ? processo.cargo.cargo_nome : ''}
                  {processo.pcd === 1 && (
                    <Tooltip
                      title={
                        processo.pcd_tipo
                          ? `Tipo de PCD: ${processo.pcd_tipo}`
                          : 'Tipo de PCD: Todos'
                      }
                    >
                      <Tag color="blue" style={{ marginLeft: 8 }}>
                        <Svg
                          height="22"
                          width="22"
                          style={{ display: 'flex', alignItems: 'center' }}
                        />
                      </Tag>
                    </Tooltip>
                  )}
                </h1>
                <h2>{processo.numero && `(${processo.numero})`}</h2>
                {processo.status === 'finalizado' ? (
                  <h2 style={{ color: 'green' }}>(finalizado)</h2>
                ) : processo.status === 'suspenso' ? (
                  <h2 style={{ color: 'orange' }}>(suspenso)</h2>
                ) : processo.status === 'cancelado' ? (
                  <h2 style={{ color: 'red' }}>
                    (cancelado: "
                    {motivoCancelamentoPS && motivoCancelamentoPS.motivo}")
                  </h2>
                ) : null}
              </Space>
            ) : (
              <Spin size="large" />
            )}

            <Space direction="horizontal">
              {processo.status !== 'finalizado' &&
                processo.status !== 'cancelado' &&
                (!processo.suspenso ? (
                  <>
                    <Popconfirm
                      title="Tem certeza que deseja suspender o Processo Seletivo?"
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={() =>
                        handleSuspenderProcesso(processo.processo_id)
                      }
                      icon={
                        <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                      }
                    >
                      <Button type="default" icon={<StopOutlined />} danger>
                        SUSPENDER
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      title="Tem certeza que deseja cancelar este Processo Seletivo?"
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={() => openModalCancelarProcesso()}
                      icon={
                        <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                      }
                    >
                      <Button type="default" icon={<DeleteOutlined />} danger>
                        CANCELAR
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      title="Tem certeza que deseja finalizar este Processo Seletivo?"
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={() =>
                        handleFinalizarProcesso(processo.processo_id)
                      }
                      icon={<CheckOutlined style={{ color: 'green' }} />}
                    >
                      <Button
                        type="default"
                        icon={<CheckOutlined style={{ color: 'green' }} />}
                        className="ps-kanban__button__finalizar"
                      >
                        FINALIZAR
                      </Button>
                    </Popconfirm>
                  </>
                ) : (
                  <Popconfirm
                    title="Tem certeza que deseja retomar o Processo Seletivo?"
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={() =>
                      handleRetomarProcesso(processo.processo_id)
                    }
                    icon={
                      <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                    }
                  >
                    <Button type="default" icon={<StopOutlined />} danger>
                      RETOMAR
                    </Button>
                  </Popconfirm>
                ))}
            </Space>
          </Header>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Kanban" key="1">
              <PsKanban match={match} />
            </TabPane>
            <TabPane tab="Informações Básicas" key="2">
              {processo && processo.cargo ? (
                <InformacoesBasicas
                  cargo={processo?.cargo.cargo_nome}
                  cidade={processo?.cidade}
                  concluirem={processo?.concluirem}
                  contrato={processo?.contrato}
                  contratados={processo?.contratados}
                  criadopor={processo?.creator.pes_nome}
                  grupo={processo?.grupo}
                  homeoffice={processo?.homeoffice}
                  duracao={processo?.duracao}
                  filial={processo?.filial}
                  inicio={processo?.criadoem}
                  inscritos={processo?.inscritos}
                  jornada={processo?.jornada}
                  motivo={processo?.motivo}
                  quantidade={processo?.qtde}
                  prazo={processo?.prazo}
                  prioridade={processo?.prioridade}
                  recrutadopor={processo?.responsible?.pes_nome}
                  salario={processo?.salario}
                  salario_faixa={processo?.salario_faixa}
                  sigilo={processo?.sigiloso}
                  triados={processo?.triados}
                />
              ) : (
                <></>
              )}
            </TabPane>
            {!loadingQuizzes && (
              <TabPane tab="Testes" key="4">
                <Testes objectQuizzes={objectQuizzes} />
              </TabPane>
            )}
            {/* <TabPane tab="Pesquisas" key="5" /> */}
          </Tabs>
        </Col>
        <Modal
          visible={showModalConfirmFinalizacao}
          title="CONFIRMAR FINALIZAÇÃO"
          cancelText="CANCELAR"
          okText="CONFIRMAR"
          onCancel={() => closeModalConfirmFinalizacao()}
          destroyOnClose
          key="8"
          onOk={() => handleFinalizarProcessoSeletivo(true)}
        >
          <Confirm message={confirmFinalizacaoText} />
        </Modal>
        <Modal
          visible={showModalCancelarProcesso}
          title="CANCELAR PROCESSO SELETIVO"
          cancelText="CANCELAR"
          okText="CONFIRMAR"
          onCancel={() => closeModalCancelarProcesso()}
          destroyOnClose
          key="7"
          okButtonProps={{
            form: 'cancelar-processo-submit',
            key: 'submit',
            htmlType: 'submit',
          }}
        >
          <CancelarProcesso
            motivosCancelamento={motivosCancelamento}
            handleChangeMotivoCancelamento={handleChangeMotivoCancelamento}
            cancel_id={motivoCancelamentoSelected}
            onFinish={submitModalCancelarProcesso}
          />
        </Modal>
      </Row>
    </>
  )
}

export default PsPainel
