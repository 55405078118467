import styled from 'styled-components'

import {
  Row as antdRow,
  Steps as antdSteps,
  Button as antdButton,
  Col as antdCol,
} from 'antd'

export const Loading = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FormularioQuizHeader = styled(antdRow)`
  background-color: white;
  padding: 12px 24px;
  border-bottom: #ebedf0 1px solid;

  & p {
    color: #30318b;
    margin: 0;
  }

  & span {
    color: grey;
    font-style: italic;
  }
`

export const ContainerQuestoes = styled.div`
  background-color: white;
  border-radius: 4px;
  border: #ebedf0 1px solid;
  margin: 50px 0;
  padding: 16px 0;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`

export const ContainerIndicadorQuestao = styled.div`
  width: 100%;
  padding-bottom: 12px;
  border-bottom: #ebedf0 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const IndicadorQuestao = styled(antdSteps)`
  margin: auto;

  & .ant-steps-item-icon {
    border-radius: 0;
    border: none;
  }

  & .ant-steps-item-process {
    color: red;
  }

  & .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: rgba(0, 0, 0, 0.85);
  }

  & .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: transparent;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    color: rgba(0, 0, 0, 0.85);
  }
`

export const ContainerIndicadorProgresso = styled.div`
  width: 100%;
  text-align: center;
  margin: 40px 0;

  & .ant-col {
    margin: auto;
  }
`

export const ContainerQuestaoMain = styled.div`
  padding: 0px 24px;
  width: 100%;

  & .ant-typography {
    width: 90%;
    margin: auto;
    margin-bottom: 40px;
    text-align: center;
  }
`

export const ContainerAlternativas = styled(antdCol)`
  margin: auto;

  & button + button {
    margin-top: 24px;
  }

  & .formulario__container__confirmar {
    width: 100%;
    text-align: center;
  }

  & .formulario__textual__confirmar {
    margin: auto;
    margin: 24px 0;
    text-transform: uppercase;
  }
`

export const Alternativa = styled(antdButton)`
  height: 60px;
  width: 100%;
  padding: 0px 18px;
  text-align: left;
  white-space: normal;

  & span {
    font-size: 16px;
  }
`

/* APRESENTACAO QUIZ */
export const Container = styled.div`
  background-color: white;
  border-radius: 4px;
  border: #ebedf0 1px solid;
  margin: 50px 0;
  padding: 50px 25px;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  & h2 {
    text-align: center;
  }

  & p {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }

  & .ant-divider-horizontal {
    min-width: 60%;
    width: 60%;
    margin: 40px 0;
  }

  & .ant-alert-message {
    text-align: center;
  }

  & .ant-alert {
    margin: 6px 0;
  }

  & .ant-btn {
    width: 100%;
    background-color: #ef5472;
    border: 1px solid #ef5472;
    margin: 6px 0;
  }
`

export const Placeholder = styled.span`
  color: #181818;
  font-style: italic;

  font-size: ${(props) => props.large && '24px'};
  font-weight: ${(props) => props.large && 'bold'};
  width: ${(props) => props.large && '100%'};
  display: ${(props) => props.large && 'inline-block'};
  text-align: ${(props) => props.large && 'center'};
  margin: ${(props) => props.large && '24px 0'};
`

export const CompanyLogo = styled.img`
  max-width: ${(props) => (props.large ? '200px' : '100px')};
  max-height: ${(props) => (props.large ? '100px' : '40px')};
  margin: ${(props) => (props.large ? '36px' : '0px')};
`
