import { toast } from 'react-toastify'

import * as constants from '../constants'

const initialState = {
  comportamentos: [],
  pessoa: {},
  avaliacao: {},
}

export const pesqValores = (state = initialState, action) => {
  switch (action.type) {
    case constants.PESQ_VALORES_GET_COMPORTAMENTOS: {
      if (action.response.status !== 200) {
        toast.error('Erro ao carregar comportamentos.')

        return { ...state }
      }

      const { comportamentos } = action.response.data

      return { ...state, comportamentos }
    }

    case constants.PESQ_VALORES_LOAD: {
      if (action.response.status !== 200) {
        return { ...state }
      }

      const { pessoa, avaliacao } = action.response.data

      return { ...state, pessoa, avaliacao }
    }

    default:
      return { ...state }
  }
}

export default pesqValores
