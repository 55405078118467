import React, { useState, useEffect, useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { Col, Row, Card, Modal, Steps, Button, Popconfirm } from 'antd'

import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'

import { toast } from 'react-toastify'

// actions
import {
  listTimes,
  listEtapas,
  storeEtapa,
  updateEtapa,
  deleteEtapa,
} from './actions'

import { listUsuariosVinculados } from '../../../Usuarios/actions'

// components
import NovaEtapa from './components/NovaEtapa'

import { Collapse, StepTitle } from './styles'

const { Panel } = Collapse

const { Step } = Steps

function FluxoRequisicoes() {
  const dispatch = useDispatch([])

  const [loading, setLoading] = useState([])

  const [times, setTimes] = useState([])

  const [timeSelected, setTimeSelected] = useState()

  const [usuarios, setUsuarios] = useState([])

  const [showModalNovaEtapa, setShowModalNovaEtapa] = useState(false)

  const [showModalEditEtapa, setShowModalEditEtapa] = useState(false)

  const [etapaEditData, setEtapaEditData] = useState()

  const loadUsuarios = useCallback(() => {
    dispatch(listUsuariosVinculados()).then(({ response }) => {
      if (response.status === 200) {
        setUsuarios(response.data)
      }
    })
  }, [dispatch])

  const loadTimes = useCallback(() => {
    setLoading(true)
    dispatch(listTimes()).then(({ response }) => {
      if (response.status === 200) {
        const { grupos } = response.data
        setTimes(grupos)
      }
    })
  }, [dispatch])

  const loadEtapas = useCallback(
    (key) => {
      const grupo_id = parseInt(key)

      setTimeSelected(grupo_id || undefined)

      if (grupo_id) {
        setTimes((currentTimes) => {
          const newTimes = [...currentTimes]

          const time = newTimes.find((t) => t.grupo_id === grupo_id)

          time.loadingEtapas = true

          return newTimes
        })

        dispatch(listEtapas(grupo_id)).then(({ response }) => {
          setTimes((currentTimes) => {
            const newTimes = [...currentTimes]

            const time = newTimes.find((t) => t.grupo_id === grupo_id)

            if (response.status === 200) {
              const { etapas } = response.data
              time.etapas = etapas
            }

            time.loadingEtapas = false

            return newTimes
          })
        })
      }
    },
    [dispatch]
  )

  useEffect(() => {
    loadTimes()
    loadUsuarios()
  }, [loadTimes, loadUsuarios])

  const handleSubmitNovaEtapa = useCallback(
    (data) => {
      dispatch(storeEtapa(timeSelected, data)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Etapa cadastrada com sucesso.')
          setShowModalNovaEtapa(false)
          loadEtapas(timeSelected)
        }
      })
    },
    [dispatch, loadEtapas, timeSelected]
  )

  const handleDeleteEtapa = useCallback(
    (id) => {
      dispatch(deleteEtapa(timeSelected, id)).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Etapa removida com sucesso.')
          loadEtapas(timeSelected)
        }
      })
    },
    [dispatch, loadEtapas, timeSelected]
  )

  const handleEditEtapa = useCallback(
    (id) => {
      setEtapaEditData(undefined)

      const time = times.find((t) => t.grupo_id === timeSelected)

      const etapa = time.etapas ? time.etapas.find((e) => e.id === id) : null

      if (etapa) {
        setEtapaEditData(etapa)
        setShowModalEditEtapa(true)
      }
    },
    [timeSelected, times]
  )

  const handleSubmitUpdateEtapa = useCallback(
    (data) => {
      const { id } = etapaEditData

      dispatch(updateEtapa(timeSelected, id, data)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Etapa atualizada com sucesso.')
          setShowModalEditEtapa(false)
          loadEtapas(timeSelected)
        }
      })
    },
    [dispatch, etapaEditData, loadEtapas, timeSelected]
  )

  return (
    <>
      <Col span={24}>
        <Row gutter={20}>
          <Col xs={24} sm={24}>
            <Card title="Fluxos de Requisições do Processo Seletivo">
              <Collapse
                bordered={false}
                onChange={(key) => loadEtapas(key)}
                accordion
                activeKey={timeSelected}
              >
                {times.map((time) => (
                  <Panel
                    key={time.grupo_id}
                    header={<span>{time.grupo_nome}</span>}
                  >
                    <Steps direction="vertical" size="small" current={null}>
                      <Step
                        title="Requisição"
                        status="process"
                        description="Processo seletivo requisitado"
                      />
                      {time.etapas &&
                        time.etapas.map((etapa) => (
                          <Step
                            title={
                              <StepTitle>
                                <span>{etapa.nome}</span>
                                <Popconfirm
                                  title="Tem certeza que deseja excluir etapa?"
                                  onConfirm={() => handleDeleteEtapa(etapa.id)}
                                  okText="Sim"
                                  cancelText="Não"
                                >
                                  <DeleteOutlined />
                                </Popconfirm>

                                <EditOutlined
                                  onClick={() => handleEditEtapa(etapa.id)}
                                />
                              </StepTitle>
                            }
                            key={etapa.id}
                            status="process"
                            description={
                              <>
                                <span>
                                  Responsável:{' '}
                                  {etapa.aprovador.colaborador.pes_nome}
                                </span>
                              </>
                            }
                          />
                        ))}
                    </Steps>
                    <Button
                      icon={<PlusOutlined />}
                      type="dashed"
                      onClick={() => setShowModalNovaEtapa(true)}
                    >
                      Adicionar Etapa
                    </Button>
                  </Panel>
                ))}
              </Collapse>
            </Card>
          </Col>
        </Row>
      </Col>
      <Modal
        visible={showModalNovaEtapa}
        title="ADICIONAR NOVA ETAPA"
        cancelText="CANCELAR"
        onCancel={() => setShowModalNovaEtapa(false)}
        okText="SALVAR"
        okButtonProps={{
          form: 'nova-etapa-requisicao-ps',
          key: 'submit',
          htmlType: 'submit',
        }}
        destroyOnClose
      >
        <NovaEtapa
          usuarios={usuarios
            .filter((l) => l.user_ativo)
            .map((l) => ({
              label: `${l.user_nome} (${l.user_tipos[0].descricao})`,
              value: l.user_id,
            }))}
          onFinish={handleSubmitNovaEtapa}
        />
      </Modal>
      <Modal
        visible={showModalEditEtapa}
        title="EDITAR ETAPA"
        cancelText="CANCELAR"
        onCancel={() => setShowModalEditEtapa(false)}
        okText="SALVAR"
        okButtonProps={{
          form: 'nova-etapa-requisicao-ps',
          key: 'submit',
          htmlType: 'submit',
        }}
        destroyOnClose
      >
        <NovaEtapa
          usuarios={usuarios
            .filter((l) => l.user_ativo)
            .map((l) => ({
              label: `${l.user_nome} (${l.user_tipos[0].descricao})`,
              value: l.user_id,
            }))}
          onFinish={handleSubmitUpdateEtapa}
          initialValues={{
            ...etapaEditData,
            user_aprovador_id: etapaEditData
              ? etapaEditData.user_id
              : undefined,
          }}
        />
      </Modal>
    </>
  )
}

export default FluxoRequisicoes
