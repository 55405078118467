import { Button, Select, Skeleton } from 'antd'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import Charts from '../Components/Charts'
import ChartsTwo from '../Components/ChartsTwo'
import TimesTable from './Tables/TimesTable'
import Pdf from '../Pdf'
import Table from './Tables'
import TableTwo from './Tables/percentageTable'
import NewTable from './Tables/FitsTable'
import MenuBar from '../Components/Menu'
import AutoCompleteRemove from '../Components/AutoCompleteRemove'
import AutoCompleteAdd from '../Components/AutoCompleteAdd'
import ReportLogo from '../../../assets/business-report.png'
import SpinLoading from '../../../utils/SpinLoading'

import {
  getRelatoriosWithGrupo,
  getCulturasFromTimes,
  getTimeFromCultura,
  getAllTimes,
  getFitsByGroup,
} from '../Api/api'

const App = () => {
  const [isDownloading, setDownloading] = useState(false)
  const [typeDownload, setTypeDownload] = useState(null)
  const [advancedFilter, setAdvancedFilter] = useState(false)
  const [isSearched, setSearched] = useState(false)
  const [relatorios, setRelatorios] = useState(null)
  const [culturas, setCulturas] = useState([])
  const [selectedCultura, setSelectedCultura] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [timeFromCultura, setTimeFromCultura] = useState(null)
  const [AllTimes, setAllTimes] = useState(null)
  const [novoGrafico, setNovoGrafico] = useState(null)
  const [graficoOne, setGraficoOne] = useState(null)
  const [instalado, setInstalado] = useState(null)
  const [graficoFour, setGraficoFour] = useState(null)
  const [graficoFive, setGraficoFive] = useState(null)
  const [selectedCulturaData, setSelectedCulturaData] = useState(null)
  const [loading, setLoading] = useState(false)

  const { Option } = Select

  function handleAdvancedFilter() {
    setAdvancedFilter(!advancedFilter)
  }

  function closeDownload() {
    setDownloading(false)
  }

  function handlePdf() {
    setTypeDownload(1)
    setDownloading(true)
  }

  useEffect(() => {
    setTimeFromCultura([])
    setSelectedTime(null)
  }, [selectedCultura])

  function generateGrafico4(arquetipo) {
    const newElement = {
      name: arquetipo.arquetipo,
      'Abaixo Máximo': arquetipo.abaixo[1],
      'Abaixo Mínimo': arquetipo.abaixo[0],
      'Acima Máximo': arquetipo.acima[1],
      'Acima Mínimo': arquetipo.acima[0],
      'Dentro Mínimo': arquetipo.dentro[0],
      'Dentro Máximo': arquetipo.dentro[1],
      Desejado: arquetipo.valor,
      'Muito Abaixo': arquetipo.muito_abaixo,
      'Muito Acima': arquetipo.muito_acima,
    }
    return newElement
  }

  async function handleSearch() {
    setLoading(true)
    if (selectedTime == null) {
      toast.error('Selecione o time')
      setLoading(false)
      return
    }
    getFitsFromAPI()
    const RESPONSE = await getRelatoriosWithGrupo(
      selectedCultura,
      selectedTime,
      selectedCulturaData.fit_id
    )

    if (RESPONSE.success && RESPONSE.success === false) {
      toast.error('Não foi possível gerar relatório para essa cultura')
      setLoading(false)
      return
    }

    toast.success('Fit Carregado com sucesso')

    if (
      !RESPONSE.grafico_2 ||
      Object.entries(RESPONSE.grafico_2).length === 0
    ) {
      toast.error('Não é possível gerar relatório para essa cultura')
    } else {
      const newArray = []
      Object.entries(RESPONSE.grafico_2).forEach((elemento) => {
        elemento[1].name = elemento[0]
        newArray.push(elemento[1])
      })
      newArray.forEach((element) => {
        const desejado = element.Desejado.replace('%', '')
        const maximo = element['Dentro Máximo'].replace('%', '')
        const minimo = element['Dentro Mínimo'].replace('%', '')
        const acimaMax = element['Acima Máximo'].replace('%', '')
        const acimaMin = element['Acima Mínimo'].replace('%', '')
        const abaixoMax = element['Abaixo Máximo'].replace('%', '')
        const abaixoMin = element['Abaixo Mínimo'].replace('%', '')

        element.Acima = Number(acimaMax) + Number(acimaMin)
        element.Abaixo = Number(abaixoMax) + Number(abaixoMin)
        element.Desejado = Number(desejado) + Number(maximo) + Number(minimo)

        element.Acima = `${element.Acima.toFixed(2)}%`
        element.Abaixo = `${element.Abaixo.toFixed(2)}%`
        element.Desejado = `${element.Desejado.toFixed(2)}%`
      })
      setNovoGrafico(newArray)
      const graficoNovo = []
      RESPONSE.grafico_1.desejado.forEach((element) => {
        graficoNovo.push(generateGrafico4(element))
      })
      setGraficoFour(graficoNovo)
      const arrayOne = []

      Object.entries(RESPONSE.grafico_2).forEach((valor) => {
        Object.entries(valor[1]).forEach((categorias) => {
          const newObject = {
            arquetipo: valor[0],
            categoria: categorias[0],
            valor: parseFloat(categorias[1]),
          }
          arrayOne.push(newObject)
        })
      })
      setGraficoOne(arrayOne)
      setInstalado(RESPONSE.grafico_1)
      setRelatorios(RESPONSE.grafico_3)
    }
    setLoading(false)
    setSearched(true)
  }

  async function getCulturasFromApi() {
    const AllTimes = await getAllTimes()
    if (AllTimes.success) {
      setAllTimes(AllTimes.grupos)
    }
    const RESPONSE = await getCulturasFromTimes()
    setCulturas(filterCulturasWithFitId(RESPONSE.culture))
  }

  function removeDecimals(value) {
    value = value.replace('%', '')
    value = Number(value).toFixed(2)
    return `${value}%`
  }

  function fixFitValores(values) {
    if (!values) return
    values.forEach((element) => {
      if (element.fitValores) {
        element.fitValores = removeDecimals(element.fitValores)
      }
    })
    return values
  }

  function fixFitArquetipos(values) {
    if (!values) return
    values.forEach((element) => {
      if (element.fitArquetipos)
        element.fitArquetipos = element.fitArquetipos.geral
    })
    return values
  }

  async function getFitsFromAPI() {
    const response = await getFitsByGroup(
      selectedTime,
      selectedCulturaData.cultura_id,
      selectedCulturaData.fit_id
    )

    let newData = fixFitValores(response.grafico_5)
    newData = fixFitArquetipos(newData)

    if (response) setGraficoFive(newData)
  }

  useEffect(() => {
    getCulturasFromApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getTimeFromCulturaFromAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCultura])

  async function getTimeFromCulturaFromAPI() {
    if (selectedCultura != null) {
      const RESPONSE = await getTimeFromCultura(
        selectedCulturaData.cultura_id,
        selectedCulturaData.fit_id
      )
      if (RESPONSE) {
        const children = []
        const FilteredArray = []
        AllTimes.forEach((time) => {
          if (time.children.length > 0) {
            children.push(...time.children)
            AllTimes.push(...time.children)
          }
        })
        RESPONSE.forEach((item) => {
          AllTimes.forEach((time) => {
            if (item === time.grupo_id) {
              FilteredArray.push(time)
            }
          })
        })
        setTimeFromCultura(FilteredArray)
      }
    }
  }

  function updateCultura(id) {
    culturas.forEach((cultura) => {
      if (cultura.cultura_id == id) {
        setSelectedCulturaData({ ...cultura })
      }
    })
  }

  function filterCulturasWithFitId(array) {
    const newArray = array.filter((cultura) => cultura?.fit_id != undefined)
    return newArray
  }
  return (
    <div>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: 5,
          padding: 20,
          border: '0.5px solid black',
        }}
      >
        <MenuBar selectedKeys={['1']} />

        <div style={{ margin: 25 }}>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                minWidth: 220,
              }}
            >
              <span>Cultura Desejada</span>
              <Select
                style={{ maxWidth: 220 }}
                onChange={(value) => {
                  setTimeFromCultura(null)
                  getCulturasFromApi()
                  setSelectedCultura(value)
                  updateCultura(value)
                }}
              >
                {culturas?.map((cultura) => (
                  <Option key={cultura.cultura_id} value={cultura.cultura_id}>
                    {cultura.conta_culturas[0].descricao}
                  </Option>
                ))}
              </Select>
            </div>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                minWidth: 200,
                marginLeft: 30,
              }}
            >
              <span>Time</span>
              <Select
                onChange={(value) => setSelectedTime(value)}
                style={{ maxWidth: 220 }}
              >
                {timeFromCultura?.map((time) => (
                  <Option key={time.grupo_nome} value={time.grupo_id}>
                    {time.grupo_nome}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <div>
              <Button
                style={{ display: 'none' }}
                onClick={handleAdvancedFilter}
                type="primary"
              >
                Filtros Avançados
              </Button>
              {advancedFilter && (
                <div
                  style={{
                    border: 'gray 1px solid',
                    padding: 20,
                    marginTop: 10,
                    borderRadius: 5,
                    backgroundColor: '#f2f2f2',
                  }}
                >
                  {' '}
                  <div style={{ flex: 'row', width: 250 }}>
                    <span>Remover do Comparativo:</span>
                    <AutoCompleteRemove key="1" />
                  </div>
                  <div style={{ flex: 'row', marginTop: 20, width: 250 }}>
                    <span>Adicionar ao Comparativo:</span>
                    <AutoCompleteAdd key="2" />
                  </div>{' '}
                </div>
              )}
            </div>
            <div>
              <Button style={{ marginRight: 5 }}>Limpar Filtros</Button>
              <Button onClick={handleSearch} type="primary">
                {loading ? <SpinLoading /> : 'Gerar Relátorio'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: 5,
          padding: 20,
          border: '0.5px solid black',
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {isSearched ? (
            <div
              style={{
                borderWidth: 1,
                borderColor: 'black',
                borderStyle: 'solid',
                borderRadius: 5,
                padding: 20,
                backgroundColor: '#F1F1F1',
                flex: 1,
                maxWidth: '20%',
              }}
            >
              <h4>Resultado da Pesquisa</h4>
              <h4>
                Cultura desejada:{' '}
                <span>{selectedCulturaData.conta_culturas[0].descricao}</span>
              </h4>
              <h4>
                Período{' '}
                <span>
                  {moment(
                    selectedCulturaData.conta_culturas[0].data_inicio
                  ).format('DD-MM-YYYY')}{' '}
                  à{' '}
                  {moment(
                    selectedCulturaData.conta_culturas[0].data_final
                  ).format('DD-MM-YYYY')}
                </span>
              </h4>
            </div>
          ) : null}

          {isSearched ? (
            <div>
              <Button onClick={handlePdf}>Exportar documento</Button>
            </div>
          ) : null}
        </div>
        {isDownloading ? (
          <Download
            instalado={instalado}
            grafico_2={novoGrafico}
            grafico_1={graficoOne}
            downloads={typeDownload}
            close={closeDownload}
            grafico_3={relatorios}
            graficoFour={graficoFour}
            graficoFive={graficoFive}
          />
        ) : isSearched ? (
          <Relatorios
            grafico_2={novoGrafico}
            grafico_1={graficoOne}
            instalado={instalado}
            grafico_3={relatorios}
            graficoFour={graficoFour}
            graficoFive={graficoFive}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            {loading || isSearched ? (
              <Skeleton active />
            ) : (
              <>
                <h1>O relatório será exibido aqui quando for gerado.</h1>
                <img style={{ maxWidth: 200 }} src={ReportLogo} />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const Relatorios = (props) => (
  <div>
    <Charts data={props.instalado} />
    <ChartsTwo data={props.grafico_1} />
    <TableTwo dataSource={props.grafico_2} />
    {props.graficoFive ? <NewTable data={props.graficoFive} /> : null}
    {props.grafico_3 ? <TimesTable dataSource={props.grafico_3} /> : null}
    {props.setGraficoFour ? <Table data={props.graficoFour} /> : null}
  </div>
)
const Download = (props) => (
  <Pdf
    downloads={props.downloads}
    instalado={props.instalado}
    grafico_2={props.grafico_2}
    grafico_1={props.grafico_1}
    close={props.close}
    grafico_3={props.grafico_3}
    graficoFour={props.graficoFour}
    graficoFive={props.graficoFive}
  />
)

export default App
