import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Button,
  Popconfirm,
  Modal,
  Divider,
  Card,
  Tooltip,
  Switch,
  Space,
} from 'antd'

import {
  ExclamationCircleFilled,
  DeleteOutlined,
  Loading3QuartersOutlined,
  UserAddOutlined,
  EditOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import Table from '../../../../components/editableTable/EditableTable'

import ModalCadastro from './components/ModalCadastro/index'

import {
  listTecnicas,
  storeTecnicas,
  updateTecnica,
  deleteTecnicas,
  loadTecnicas,
  getFrequencia,
} from './actions/actions'

import { Action, TableHeader } from './styles'

function Tecnicas({ id }) {
  const dispatch = useDispatch()

  const [showModalNewTecnica, setShowModalNewTecnica] = useState(false)

  const [showModalUpdateTecnica, setShowModalUpdateTecnica] = useState(false)

  const [newTecnica, setNewTecnica] = useState(null)

  const [tecnicas, setTecnicas] = useState([])

  const [frequencia_nome, setFrequenciaNome] = useState([])

  const [frequencia, setFrequencias] = useState([])

  const [showInativos, setShowInativos] = useState(false)

  const [loadingTecnicas, setloadingTecnicas] = useState(false)

  const [handleTecnica, sethandleUpdateTecnica] = useState(false)

  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const [tecnicaUpdated, setTecnicaUpdated] = useState({
    nome: undefined,
    descricao: undefined,
  })

  const loadTecnicas = useCallback(() => {
    setloadingTecnicas(true)
    dispatch(listTecnicas(id)).then(({ response }) => {
      setloadingTecnicas(false)
      if (response.status === 200) {
        setTecnicas(response.data)
      }
    })
  }, [dispatch, id])

  const listFrequencias = useCallback(() => {
    dispatch(getFrequencia()).then(({ response }) => {
      if (response.status === 200) {
        const frequencia = response.data
        setFrequencias(frequencia)
        const frequencia_nome = response.data
        setFrequenciaNome(
          frequencia_nome.map((m) => ({ value: m.id, label: m.nome }))
        )
      }
    })
  }, [dispatch])

  useEffect(() => {
    listFrequencias()
    loadTecnicas()
  }, [loadTecnicas, listFrequencias])

  const onFinishNewTecnica = () => {
    dispatch(storeTecnicas()).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Tecnica cadastrado com sucesso.')
        loadTecnicas()
      } else toast.error('Erro ao cadastrar Tecnica.')
    })
  }

  const handleListTecnicas = useCallback(() => {
    dispatch(listTecnicas()).then(({ response }) => {
      if (response.status === 200) {
        const { tecnicas } = response.data
        setTecnicas(
          tecnicas.map((m) => ({ value: m.tecnica_id, label: m.nome }))
        )
      }
    })
  }, [dispatch])

  const handleDeleteTecnica = useCallback(
    (tecnica_id) => {
      dispatch(deleteTecnicas({ tecnica_id, id })).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Tecnica excluída com sucesso.')
          loadTecnicas()
        }
      })
    },
    [dispatch, id, loadTecnicas]
  )

  const handleUpdateTecnica = useCallback(
    (updatedTecnica, data) => {
      dispatch(
        updateTecnica({
          tecnica_id: updatedTecnica.tecnica_id,
          ...updatedTecnica,
        })
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Tecnica atualizada com sucesso.')
          loadTecnicas()
        }
      })
    },
    [dispatch, loadTecnicas]
  )

  const handleChangeNewTecnica = (tecnica) => {
    setNewTecnica(tecnica)
  }

  const handleCancelNewTecnica = useCallback(() => {
    setShowModalNewTecnica(false)
  }, [])

  const handleNewTecnica = useCallback(
    (data) => {
      dispatch(storeTecnicas({ data, id })).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Tecnica criada com sucesso.')
          setShowModalNewTecnica(false)
          loadTecnicas()
        }
      })
    },
    [dispatch, loadTecnicas, id]
  )

  // UPDATE REQUISITO
  const handleUpdateModal = ({ id, nome, descricao }) => {
    setTecnicaUpdated({ id, nome, descricao })
    setShowUpdateModal(true)
  }

  const handleChangeUpdateTecnica = (nome, descricao) => {
    setTecnicaUpdated((prevState) => ({
      ...prevState,
      nome,
      descricao,
    }))
  }

  const handleSubmitUpdateTecnica = (data) => {
    dispatch(
      updateTecnica({
        id,
        tecnica_id: tecnicaUpdated.id,
        ...data,
      })
    ).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Atualizado com sucesso.')
        loadTecnicas()
        handleCloseUpdateTecnica()
      } else toast.error('Erro ao atualizar tecnica.')
    })
  }

  const handleCloseUpdateTecnica = () => {
    setTecnicaUpdated({
      nome: undefined,
      descricao: undefined,
    })
    setShowUpdateModal(false)
  }

  //

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
    },
    {
      title: 'Opções',
      key: 'opcoes',
      editable: true,
      className: 'column-opcoes',
      render: (text, record) => (
        <Space split={<Divider type="vertical" />}>
          <a>
            <Popconfirm
              title="Tem certeza que deseja remover do cargo?"
              onConfirm={() => handleDeleteTecnica(record.id)}
              okText="Sim"
              cancelText="Não"
            >
              <Tooltip title="Excluir" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </a>
          <a
            href="javascript:;"
            onClick={() => handleUpdateModal(record)}
            aria-label="Editar"
          >
            <Tooltip title="Editar" placement="bottom">
              <EditOutlined />
            </Tooltip>
          </a>
        </Space>
      ),
    },
  ]

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Card title="Tecnicas do cargo" type="inner">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '12px',
              }}
            >
              <Button
                type="primary"
                className="btn_purple"
                onClick={() => setShowModalNewTecnica(true)}
                icon={<UserAddOutlined />}
              >
                NOVO
              </Button>
            </div>
            <Table
              bordered
              pagination={false}
              size="small"
              dataSource={tecnicas.map((tecnicas, frequencia_nome) => {
                return {
                  ...tecnicas,
                  ...frequencia_nome,
                  key: tecnicas.pes_id,
                }
              })}
              columns={columns}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="NOVA INFORMAÇÃO"
        visible={showModalNewTecnica}
        cancelText="CANCELAR"
        onCancel={() => handleCancelNewTecnica()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'cadastro',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <ModalCadastro
          handleChangeTecnica={handleChangeNewTecnica}
          onFinish={handleNewTecnica}
          {...handleTecnica}
          frequencia={frequencia}
        />
      </Modal>
      <Modal
        title="EDITAR INFORMAÇÃO"
        visible={showUpdateModal}
        cancelText="CANCELAR"
        onCancel={() => handleCloseUpdateTecnica()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'cadastro',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <ModalCadastro
          handleChangeTecnica={handleChangeUpdateTecnica}
          onFinish={handleSubmitUpdateTecnica}
          {...tecnicaUpdated}
        />
      </Modal>
    </Col>
  )
}
export default Tecnicas
