import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import { PlusOutlined } from '@ant-design/icons'
import { Row, Col, Divider, Modal, Form, Checkbox } from 'antd'

// Components
import Input, { Date, MaskedInput } from '../../../../components/input/Input'
import Select, { Option } from '../../../../components/select/Select'

// CSS
import './AdicionarNovoColaborador.css'

// Constants

function AdicionarNovoColaborador({
  intl,
  handleChange,
  showModal,
  onSubmit,
  closeModal,
  cpf,
  nome,
  dataNascimento,
  email,
  sexo,
  grupo,
  dataAdmissao,
  telefone,
  cargo,
  isOnboarding = false,
  filiais,
  filial_id,
  pcdDefault,
  pcd_tipo,
  cid,
}) {
  const [pessoaComDeficiencia, setPessoaComDeficiencia] = useState(false)

  useEffect(() => {
    setPessoaComDeficiencia(pcdDefault)
  }, [pcdDefault])

  return (
    <Modal
      visible={showModal}
      width="50%"
      title="Editar Colaborador"
      cancelText="CANCELAR"
      okText="SALVAR"
      onCancel={closeModal}
      onOk={onSubmit}
      destroyOnClose
    >
      <div className="adicionar-colaboradores">
        <Row gutter={20}>
          <Col xs={24} md={24} lg={16}>
            <Input
              label="Nome"
              type="text"
              placeholder="Digite o nome"
              name="nome"
              defaultValue={nome}
              onChange={handleChange}
            />
          </Col>
          <Col xs={24} md={24} lg={8}>
            <Select
              label="Sexo"
              placeholder="Insira o sexo"
              defaultValue={sexo}
              name="sexo"
              onChange={(value) =>
                handleChange({ target: { name: 'pes_sexo', value } })
              }
              style={{ width: '100%' }}
            >
              <Option value="M">Masculino</Option>
              <Option value="F">Feminino</Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24} sm={24} lg={12}>
            <MaskedInput
              label="CPF"
              mask="999.999.999-99"
              name="cpf"
              defaultValue={cpf}
              onChange={handleChange}
            />
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Date
              label="Data de Nascimento"
              placeholder="Digite o nascimento"
              name="dataNascimento"
              defaultValue={
                moment(dataNascimento, 'DD/MM/YYYY').isValid() &&
                moment(dataNascimento, 'DD/MM/YYYY')
              }
              onChange={(date) =>
                handleChange({
                  target: { name: 'pes_datanascimento', value: date },
                })
              }
            />
          </Col>
        </Row>

        <Row gutter={20}>
          <Col xs={24} sm={24} lg={16}>
            <Input
              label="E-mail"
              type="text"
              placeholder="Digite o e-mail"
              name="email"
              defaultValue={email}
              onChange={handleChange}
            />
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <MaskedInput
              label={intl.formatMessage({
                id: 'common.telefone',
              })}
              mask="(99) 99999-9999"
              name="telefone"
              defaultValue={telefone}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row gutter={20}>
          <Col md={24} xs={24} sm={24}>
            <Select
              defaultValue={filial_id}
              label="Filial"
              placeholder="Selecione a filial"
              name="filial_id"
              showSearch
              options={filiais
                .filter((filial) => filial.ativo === 1)
                .map((filial) => {
                  return {
                    label: filial.nome,
                    value: filial.filial_id,
                  }
                })}
              onChange={(option) =>
                handleChange({
                  target: { name: 'filial_id', value: option },
                })
              }
              style={{ width: '100%', marginBottom: 20 }}
              withoutMargin
            />
          </Col>

          <Col xs={24} sm={24} lg={8}>
            <Date
              label="Data de Admissão"
              placeholder="Digite a data de admissão"
              name="dataAdmissao"
              defaultValue={
                moment(dataAdmissao, 'DD/MM/YYYY').isValid() &&
                moment(dataAdmissao, 'DD/MM/YYYY')
              }
              onChange={(date) =>
                handleChange({
                  target: { name: 'dataadmissao', value: date },
                })
              }
            />
          </Col>
          <Col md={24}>
            <Form.Item>
              <Checkbox
                name="pcd"
                defaultChecked={pcdDefault === 1}
                onChange={({ target }) => {
                  if (target.checked === true) {
                    handleChange({
                      target: {
                        name: 'pcd',
                        value: 1,
                      },
                    })
                    handleChange({
                      target: {
                        name: 'pcd_tipo',
                        value: 'Física',
                      },
                    })
                  } else {
                    handleChange({
                      target: {
                        name: 'pcd',
                        value: -1,
                      },
                    })
                    handleChange({
                      target: {
                        name: 'pcd_tipo',
                        value: null,
                      },
                    })
                    handleChange({
                      target: {
                        name: 'cid',
                        value: null,
                      },
                    })
                  }
                  setPessoaComDeficiencia(target.checked)
                }}
                style={{ marginTop: 5 }}
              >
                É pessoa com deficiência (PCD)?
              </Checkbox>
            </Form.Item>
          </Col>

          {!!pessoaComDeficiencia && (
            <>
              <Col xs={12} md={12} lg={12}>
                <Form.Item
                  label="Tipo de PCD"
                  rules={{ required: true, message: 'Campo obrigatório.' }}
                >
                  <Select
                    defaultValue={pcd_tipo === null ? 'Física' : pcd_tipo}
                    placeholder="Selecione o tipo"
                    name="pcd_tipo"
                    onChange={(value) => {
                      handleChange({
                        target: { name: 'pcd_tipo', value },
                      })
                    }}
                    style={{ width: '100%' }}
                  >
                    <Option value="Física">Física</Option>
                    <Option value="Auditiva">Auditiva</Option>
                    <Option value="Visual">Visual</Option>
                    <Option value="Mental">Mental</Option>
                    <Option value="Múltipla">Múltipla</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={12} lg={12}>
                <Form.Item label="CID">
                  <Input
                    type="text"
                    defaultValue={cid}
                    placeholder="Digite o CID"
                    name="cid"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </div>
    </Modal>
  )
}

export default injectIntl(AdicionarNovoColaborador)
