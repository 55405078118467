import { LoadingOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Card, Col, Divider, Empty, Row, Spin, Switch } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import queryString from 'query-string'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  PerfilComportamental,
  PerfilComportamentalTextual,
} from '../../../../components/perfilComportamental/newIndex'
import { PerfilComportamental as PFFit } from '../../../../components/perfilComportamental'
import Link from '../../../../components/privateLink/PrivateLink'
import Select, { Option } from '../../../../components/select/Select'
// Components
import { cardHeadBlue, noPadding } from '../../../../utils/inlineStyles'
// utils
import { parseCulturas, parseDISC, parseVetor } from '../../../../utils/utils'
// actions
import {
  getColaboradores,
  getCultura,
  getCulturaEmpresa,
  getHistoricoVetor,
  getVetor,
  getVetorCompleto,
  newLink,
} from './actions'
// constants
import { CULTURAS, DISC, vetorDefault } from './constants'
import {
  Container,
  CustomList,
  FlexRow,
  FullCol,
  HistoricoOverlay,
  HistoricoRow,
  Label,
  Link16,
  LinkRow,
  PerfilComposicao,
  PerfilDetalhes,
  PerfilDetalhesTag,
  Span16,
} from './styles'

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />

function Colaboradores() {
  const dispatch = useDispatch()

  const location = useLocation()
  const [colaborador, setColaborador] = useState()
  const [initialAvaliaId, setInitialAvaliaId] = useState()

  useEffect(() => {
    if (location && location.search) {
      const queries = queryString.parse(location.search)

      if (queries.colaborador) {
        setColaborador(Number(queries.colaborador))
        if (queries.avaliacao) {
          setInitialAvaliaId(Number(queries.avaliacao))
        }
      }
    }
  }, [location])

  const [vetor, setVetor] = useState(vetorDefault)
  const [vetores, setVetores] = useState([])
  const [historicoAvaliacoes, setHistoricoAvaliacoes] = useState([])
  const [cultura, setCultura] = useState([])
  const [culturaEmpresa, setCulturaEmpresa] = useState([])
  const [disc, setDisc] = useState([])
  const [colaboradores, setColaboradores] = useState([])
  const [colaboradoresRaw, setColaboradoresRaw] = useState([])
  const [pessoaEscolhida, setPessoaEscolhida] = useState({
    pes_id: null,
    pes_nome: undefined,
  })

  const [loadingAvaliacao, setLoadingAvaliacao] = useState(false)
  const [showInfografico, setShowInfografico] = useState(true)
  const [showGraficoRadar, setShowGraficoRadar] = useState(true)
  const [showHistoricoDisc, setShowHistoricoDisc] = useState(false)
  const [showColaboradoresDesligados, setShowColaboradoresDesligados] =
    useState(false)
  const [horizontalList, setHorizontalList] = useState(
    window.matchMedia('(max-width: 1599px)').matches
  )

  const loadColaboradores = useCallback(() => {
    dispatch(getColaboradores()).then(({ response }) => {
      if (response.status === 200) {
        const { pessoas: pessoasData } = response.data

        if (pessoasData.length > 0) {
          const pessoas = showColaboradoresDesligados
            ? [...pessoasData]
            : [...pessoasData.filter((p) => parseInt(p.pes_ativo) === 1)]

          setPessoaEscolhida(
            colaborador
              ? pessoas.find((pessoa) => pessoa.pes_id === colaborador)
              : pessoas[0]
          )

          setColaboradores(pessoas)

          setColaboradoresRaw(pessoasData)
        }
      } else {
        setColaboradores([])
        setColaboradoresRaw([])
        setPessoaEscolhida(undefined)
      }
    })
  }, [colaborador, dispatch, showColaboradoresDesligados])

  const loadVetor = useCallback(
    (pes_id, avalia_id = null) => {
      setLoadingAvaliacao(true)
      dispatch(getVetor(pes_id, avalia_id)).then(({ response }) => {
        if (response.status === 200) {
          if (avalia_id) {
            setHistoricoAvaliacoes((hist) =>
              hist.map((h) => {
                if (h.avalia_id === avalia_id) {
                  h.selected = true
                } else h.selected = false
                return h
              })
            )
          }

          const { data } = response.data

          delete data.culturaEmpresa
          delete data.cultura

          const v = parseVetor(data)

          setVetor(v)
        } else {
          setVetor(vetorDefault)
        }
        setLoadingAvaliacao(false)
      })
    },
    [dispatch]
  )

  const loadCultura = useCallback(
    (pes_id) => {
      dispatch(getCultura(pes_id)).then(({ response }) => {
        if (response.status === 200) {
          const { cultura } = response.data

          const culturasParsed = Object.keys(cultura)
            .filter((key) => CULTURAS.includes(key))
            .map((key) => ({
              Tipo: key,
              Valor: Number(cultura[key]),
              Label: pessoaEscolhida.pes_nome,
            }))

          const discParsed = Object.keys(cultura)
            .filter((key) => DISC.includes(key))
            .map((key) => ({
              Tipo: parseDISC(key),
              Valor: Number(cultura[key]),
              Label: pessoaEscolhida.pes_nome,
            }))

          setCultura(culturasParsed)
          setDisc(discParsed)
        } else {
          setCultura([])
          setDisc([])
        }
      })
    },
    [dispatch, pessoaEscolhida.pes_nome]
  )

  const loadVetorCompleto = useCallback(
    (pes_id) => {
      dispatch(getVetorCompleto(pes_id)).then(({ response }) => {
        if (response.status === 200) {
          const { data } = response.data

          const { vetores: vetoresData } = data

          const newVetores = vetoresData
            .map((vetor) => {
              return [
                {
                  log_data: vetor.log_data,
                  value: Math.round(vetor.D),
                  type: 'D',
                },
                {
                  log_data: vetor.log_data,
                  value: Math.round(vetor.I),
                  type: 'I',
                },
                {
                  log_data: vetor.log_data,
                  value: Math.round(vetor.S),
                  type: 'S',
                },
                {
                  log_data: vetor.log_data,
                  value: Math.round(vetor.C),
                  type: 'C',
                },
              ]
            })
            .flat()

          setVetores([...newVetores])
        } else {
          setVetores([])
        }
      })
    },
    [dispatch]
  )

  const loadHistoricoVetores = useCallback(
    (pes_id, avalia_id = null) => {
      dispatch(getHistoricoVetor(pes_id)).then(({ response }) => {
        if (response.status === 200) {
          const { historico } = response.data

          if (historico.length > 0 && !avalia_id) {
            historico[0].selected = true
            loadVetor(pessoaEscolhida.pes_id, historico[0].avalia_id)
          } else if (historico.length > 0 && avalia_id) {
            const avaliacaoIndex = historico.findIndex(
              (av) => av.avalia_id === avalia_id
            )
            if (avaliacaoIndex >= 0) {
              historico[avaliacaoIndex].selected = true
              loadVetor(pessoaEscolhida.pes_id, avalia_id)
            }
          }

          setHistoricoAvaliacoes(historico)
        } else {
          setHistoricoAvaliacoes([])
          setVetor(vetorDefault)
        }
      })
    },
    [dispatch, loadVetor, pessoaEscolhida.pes_id]
  )

  const loadCulturaEmpresa = useCallback(() => {
    dispatch(getCulturaEmpresa()).then(({ response }) => {
      if (response.status === 200) {
        const { culturaDesejada, culturaInstalada } = response.data

        const { cultura } = parseCulturas({
          culturaDesejada,
          culturaInstalada,
        })

        setCulturaEmpresa(cultura)
      } else {
        setCulturaEmpresa([])
      }
    })
  }, [dispatch])

  useEffect(() => {
    loadColaboradores()
    loadCulturaEmpresa()
  }, [loadColaboradores, loadCulturaEmpresa])

  useEffect(() => {
    if (pessoaEscolhida.pes_id) {
      loadCultura(pessoaEscolhida.pes_id)
      loadVetorCompleto(pessoaEscolhida.pes_id)
    }
  }, [loadCultura, loadVetorCompleto, pessoaEscolhida.pes_id])

  useEffect(() => {
    if (pessoaEscolhida.pes_id) {
      if (pessoaEscolhida.pes_id === colaborador && initialAvaliaId) {
        loadHistoricoVetores(pessoaEscolhida.pes_id, initialAvaliaId)
        setInitialAvaliaId(undefined)
      } else if (vetor && vetor.pes_id !== pessoaEscolhida.pes_id) {
        loadHistoricoVetores(pessoaEscolhida.pes_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pessoaEscolhida.pes_id, colaborador, vetor, loadHistoricoVetores])

  useEffect(() => {
    if (showColaboradoresDesligados) {
      setColaboradores(colaboradoresRaw)
    } else {
      setColaboradores(
        colaboradoresRaw.filter((c) => parseInt(c.pes_ativo) === 1)
      )
    }
  }, [colaboradoresRaw, showColaboradoresDesligados])

  const handleMediaListaHorizontal = (e) => setHorizontalList(e.matches)

  useEffect(() => {
    window
      .matchMedia('(max-width: 1599px)')
      .addListener(handleMediaListaHorizontal)
  }, [])

  const handleChangeAvaliacao = useCallback(
    (avalia_id) => {
      const { pes_id } = pessoaEscolhida

      loadVetor(pes_id, avalia_id)
    },
    [loadVetor, pessoaEscolhida]
  )

  const handleChangeInfograficoTextual = useCallback(() => {
    setShowInfografico((state) => !state)
  }, [])

  const handleSwitchHistoricoDisc = useCallback(() => {
    setShowHistoricoDisc((state) => !state)
  }, [])

  const handleSwitchGraficoRadar = useCallback(() => {
    setShowGraficoRadar((state) => !state)
  }, [])

  const handleNewLink = useCallback(
    (pes_id, avalia_id) => {
      dispatch(newLink(pes_id, avalia_id)).then(({ response }) => {
        if (response.status === 200) {
          handleChangeAvaliacao(avalia_id)
        }
      })
    },
    [dispatch, handleChangeAvaliacao]
  )

  const getDateDifference = useCallback((data) => {
    const difference = moment().diff(moment(data, 'DD/MM/YYYY HH:mm'), 'months')

    const years = Math.floor(difference / 12)

    return difference >= 12
      ? `${years} ${years > 1 ? 'anos' : 'ano'}`
      : `${difference} meses`
  }, [])

  const handleChangeColaborador = useCallback(
    (pes_id) => {
      const pessoa = colaboradores.find((c) => c.pes_id === pes_id)
      setPessoaEscolhida(pessoa)
    },
    [colaboradores]
  )

  const handleSwitchColaboradores = useCallback(() => {
    setShowColaboradoresDesligados((state) => !state)
  }, [])

  const { hash, avalia_id, principal } = vetor

  return (
    <Container span={24}>
      <Row gutter={16} type="flex" style={noPadding}>
        <FullCol
          xxl={{ span: 4, order: 1 }}
          xl={{ span: 24, order: 1 }}
          lg={{ span: 24, order: 1 }}
          md={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          xs={{ span: 24, order: 1 }}
        >
          <Row className="colaboradores__row-historico">
            <Col
              xs={24}
              sm={24}
              xl={24}
              xxl={24}
              style={{ marginBottom: '16px', ...noPadding }}
            >
              <Label>Selecione um Colaborador: </Label>
              <FlexRow>
                <Switch
                  onChange={(checked) => handleSwitchColaboradores(checked)}
                  checked={showColaboradoresDesligados}
                />
                {showColaboradoresDesligados ? (
                  <Span16>Exibir desligados</Span16>
                ) : (
                  <Span16>Esconder desligados</Span16>
                )}
              </FlexRow>
              <Select
                placeholder="Selecione"
                onChange={(option) => handleChangeColaborador(option)}
                style={{ width: '100%', marginTop: '8px' }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={pessoaEscolhida ? pessoaEscolhida.pes_id : null}
                data-html2canvas-ignore
              >
                {colaboradores.map(({ pes_nome, pes_id }) => (
                  <Option value={pes_id} key={pes_id}>
                    {pes_nome}
                  </Option>
                ))}
              </Select>

              <Row>
                <Link
                  key={pessoaEscolhida.pes_id}
                  to={`/perfil/${pessoaEscolhida.pes_id}`}
                >
                  <Button type="link" icon={<UserOutlined />}>
                    Ver perfil deste colaborador
                  </Button>
                </Link>
              </Row>

              <LinkRow>
                <Link16 onClick={() => handleChangeInfograficoTextual()}>
                  Veja o {showInfografico ? 'textual' : 'infográfico'}
                </Link16>
                <Divider type="vertical" />
                {hash ? (
                  <div>
                    <span>Copiar Link</span>
                    <br />
                    <CopyToClipboard
                      text={`${window.location.host}/share/vetor/pessoa/relatorio/${hash}`}
                      onCopy={() =>
                        toast.success(
                          'Link do relatório copiado para a área de transferência.'
                        )
                      }
                    >
                      <Link16>Completo</Link16>
                    </CopyToClipboard>
                    <br />

                    <CopyToClipboard
                      text={`${window.location.host}/share/vetor/pessoa/${hash}`}
                      onCopy={() =>
                        toast.success(
                          'Link do relatório copiado para a área de transferência.'
                        )
                      }
                    >
                      <Link16>Perf. Comportamental</Link16>
                    </CopyToClipboard>
                    <br />
                  </div>
                ) : (
                  <Link16
                    onClick={() =>
                      handleNewLink(pessoaEscolhida.pes_id, avalia_id)
                    }
                  >
                    Gerar Link
                  </Link16>
                )}
              </LinkRow>
            </Col>

            <Col xs={24} sm={24} xl={24} xxl={24} style={{ ...noPadding }}>
              <Card
                title="Histórico"
                bodyStyle={noPadding}
                headStyle={cardHeadBlue}
              >
                <CustomList
                  grid={horizontalList ? { gutter: 8 } : false}
                  pagination={
                    horizontalList
                      ? false
                      : {
                          pageSize: 7,
                          position: 'bottom',
                        }
                  }
                  dataSource={historicoAvaliacoes}
                  renderItem={(item) => {
                    return (
                      <HistoricoRow
                        onClick={() => handleChangeAvaliacao(item.avalia_id)}
                      >
                        <HistoricoOverlay selected={item.selected}>
                          <PerfilComposicao>
                            <span>{`${item.primario}${item.secundario}`}</span>
                          </PerfilComposicao>
                          <PerfilDetalhes>
                            <PerfilDetalhesTag>
                              {getDateDifference(item.log_data)}
                            </PerfilDetalhesTag>
                            <Label>Respondido em:</Label>
                            <span>{item.log_data}</span>
                          </PerfilDetalhes>
                        </HistoricoOverlay>
                      </HistoricoRow>
                    )
                  }}
                />
              </Card>
            </Col>
          </Row>
        </FullCol>
        {loadingAvaliacao ? (
          <FullCol
            xxl={{ span: 20, order: 2 }}
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
          >
            <Spin
              indicator={antIcon}
              style={{
                flex: '1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          </FullCol>
        ) : showInfografico ? (
          <Col
            xxl={{ span: 20, order: 2 }}
            xl={{ span: 24, order: 2 }}
            lg={{ span: 24, order: 2 }}
            md={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            xs={{ span: 24, order: 2 }}
            style={{ ...noPadding }}
          >
            <Row gutter={16} type="flex" style={noPadding}>
              <PerfilComportamental
                type="colaborador"
                showHistoricoDisc={showHistoricoDisc}
                handleSwitchGraficoRadar={handleSwitchGraficoRadar}
                handleSwitchHistoricoDisc={handleSwitchHistoricoDisc}
                showGraficoRadar={showGraficoRadar}
                vetores={vetores}
                cultura={cultura}
                culturaEmpresa={culturaEmpresa}
                disc={disc}
                {...vetor}
              />
            </Row>
            <Row>
              <PerfilComportamentalTextual
                type="colaborador"
                cultura={cultura}
                culturaEmpresa={culturaEmpresa}
                {...vetor}
              />
            </Row>
          </Col>
        ) : principal ? (
          <Col
            xxl={{ span: 20, order: 2 }}
            sm={{ span: 24, order: 2 }}
            xs={{ span: 24, order: 2 }}
          >
            <Row>
              <PerfilComportamentalTextual
                type="colaborador"
                cultura={cultura}
                culturaEmpresa={culturaEmpresa}
                {...vetor}
              />
            </Row>
          </Col>
        ) : (
          <Empty />
        )}
      </Row>
    </Container>
  )
}

export default Colaboradores
