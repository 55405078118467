import React, { useState } from 'react'

import { Form, Input, Select } from 'antd'

import { Option } from '../../../../../../components/select/Select'

export default function ModalCadastro({
  handleChangeTecnica,
  onFinish,
  nome,
  descricao,
}) {
  const [form] = Form.useForm()

  const [valorInvalido, setValorInvalido] = useState(false)

  const validationTecnica = (rule, value, callback) => {
    if (!value.match(/^(?!\s+$).*/)) {
      setValorInvalido(true)
      return callback('Por favor, insira um nome válido!')
    }
    setValorInvalido(false)
    return callback()
  }

  const compararValor = nome ? nome.length : 0

  return (
    <Form
      layout="vertical"
      id="cadastro"
      onFinish={onFinish}
      form={form}
      initialValues={{ nome, descricao }}
    >
      <Form.Item
        label="Nome"
        name="nome"
        style={{
          marginBottom: 3,
        }}
        rules={[
          {
            required: true,
            message: 'Por favor, preencha este campo.',
          },
          {
            validator: validationTecnica,
            message: 'Insira uma descrição válida!',
          },
        ]}
      >
        <Input
          onChange={(e) => handleChangeTecnica(e.target.value)}
          type="text"
          placeholder="Digite o nome"
          value={nome}
          maxLength={255}
        />
      </Form.Item>
      <p
        style={{
          color: compararValor >= 140 ? 'red' : '',
          marginTop: valorInvalido ? -9 : -6,
        }}
      >
        {compararValor <= 9
          ? compararValor.toString().padStart(2, '0')
          : compararValor}
        /150
      </p>
      <Form.Item
        label="Descrição"
        name="descricao"
        rules={[
          {
            required: true,
            message: 'Por favor, preencha este campo.',
          },
          {
            validator: validationTecnica,
            message: 'Insira uma descrição válida!',
          },
        ]}
      >
        <Input
          onChange={(e) => handleChangeTecnica(e.target.value)}
          type="text"
          placeholder="Digite a descrição"
          value={descricao}
        />
      </Form.Item>
    </Form>
  )
}
