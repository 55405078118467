import { toast } from 'react-toastify'

import * as constants from '../constants/psKanbanConstants'

const initialState = {
  candidatos: [],
  etapas: [],
  processo: {
    cargo: undefined,
  },
  motivosCancelamento: [],
  fit: {
    fitminimo: 0,
  },
}

export const psKanban = (state = initialState, action) => {
  switch (action.type) {
    case constants.PS_KANBAN_CANDIDATOS_LIST: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error('Erro ao listar candidatos do Processo Seletivo.')
        return { ...state }
      }

      const { candidatos } = action.response.data

      return { ...state, candidatos }
    }
    // case constants.PS_KANBAN_PROCESSO_ETAPAS_LIST: {
    // 	if (action.response.status !== 200) {
    // 		if (action.response.status !== 204)
    // 			toast.error('Erro ao listar etapas do Processo Seletivo.');
    // 		return { ...state };
    // 	}

    // 	const { etapas } = action.response.data;

    // 	return { ...state, etapas };
    // }
    case constants.PS_KANBAN_PROCESSO_DETAILS_GET: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error('Erro ao obter detalhes do Processo Seletivo.')
        return { ...state }
      }
      const { details } = action.response.data
      return { ...state, processo: details }
    }

    case constants.PS_KANBAN_PROCESSO_LIST_MOTIVOSCANCELAMENTO: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204)
          toast.error(
            'Erro ao listar motivos de cancelamento do Processo Seletivo.'
          )
        return { ...state }
      }

      const { motivosCancelamento } = action.response.data

      return { ...state, motivosCancelamento }
    }
    case constants.PS_KANBAN_CLEAR: {
      return { ...initialState }
    }

    case constants.CONFIG_PESQUISAS_GET_FIT_MINIMO: {
      if (action.response.status !== 200) {
        if (action.response.status !== 204) {
          const { message } = action.response.data
          toast.error(message)
        }
        return { ...state }
      }

      const { fit } = action.response.data

      return {
        ...state,
        fit: {
          fitminimo: parseFloat(fit[0].fitminimo),
        },
      }
    }

    default:
      return { ...state }
  }
}

export default psKanban
