import React, { useState } from 'react'

import { Input, Button, Empty, Select, Form, Divider } from 'antd'

import { CheckOutlined } from '@ant-design/icons'

// Components

import { Container, ButtonContainer } from './styles'

// Types
import { IPdi, IUpdatePDI } from '../../@types'
import { IFeedbackOption } from '../../../feedback/@types'

const { TextArea } = Input

interface IProps {
  data?: IPdi
  isFinished?: boolean
  saving: boolean
  feedbacksOptions: IFeedbackOption[]
  handleUpdatePDI(data: Omit<IUpdatePDI, 'pdi_id'>): Promise<void>
  initialValues?: IPdi | undefined
  children?: React.ReactNode
}

const PdiItem: React.FC<IProps> = ({
  data: pdi,
  isFinished = false,
  saving = false,
  feedbacksOptions,
  children,
  handleUpdatePDI,
  initialValues = {},
}) => {
  const [form] = Form.useForm()

  if (!pdi) {
    return <Empty />
  }

  return (
    <>
      <Container>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpdatePDI}
          initialValues={initialValues}
        >
          <Form.Item label="Feedback" name="feedback_id">
            <Select
              placeholder="PDI não vinculado á nenhum Feedback."
              options={feedbacksOptions}
              disabled={isFinished}
            />
          </Form.Item>

          <Form.Item label="Objetivo" name="objetivo">
            <TextArea
              maxLength={1000}
              placeholder="Qual o objetivo deste PDI? O que deve ser alcançado?"
              readOnly={isFinished}
            />
          </Form.Item>
          {!isFinished && (
            <ButtonContainer>
              <Button
                type="primary"
                htmlType="submit"
                loading={saving}
                disabled={saving}
                icon={<CheckOutlined />}
              >
                Salvar PDI
              </Button>
            </ButtonContainer>
          )}
          <Divider />
        </Form>
        {children}
      </Container>
    </>
  )
}

export default PdiItem
