// types
export const PS_FILIAL_LIST = 'PS_FILIAL_LIST'
export const PS_FILIAL_STORE = 'PS_FILIAL_STORE'
export const PS_FILIAL_UPDATE = 'PS_FILIAL_UPDATE'
export const PS_FILIAL_DELETE = 'PS_FILIAL_DELETE'
export const PS_FILIAL_LOAD = 'PS_FILIAL_LOAD'

// urls
export const URL_PS_FILIAL = `${process.env.REACT_APP_API_URL}/filiais`
export const URL_PS_FILIAL_ID = (id) =>
  `${process.env.REACT_APP_API_URL}/filiais/${id}`
