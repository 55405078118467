import styled from 'styled-components'

import { shade } from 'polished'

import { Col } from 'antd'

export const Wrapper = styled(Col)`
  flex: 1;
  min-height: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 20px 0;

  @media screen and (min-width: 768px) {
    padding: 90px 40px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 5px;
  border: 1px solid #ebedf0;

  > p {
    font-size: 16px;
  }

  > span:first-of-type {
    margin-top: 1em;
    font-weight: 500;
    font-size: 18px;
  }

  > ul {
    padding: 0;
    margin-top: 1em;
    list-style: none;

    li {
      line-height: 2em;
      font-size: 16px;
    }
  }

  > button {
    margin-top: 1em;
    width: 100%;
    background-color: #ef5472;
    border-color: #ef5472;

    &:hover {
      background-color: ${shade(0.1, '#ef5472')};
      border-color: ${shade(0.1, '#ef5472')};
    }

    &:focus {
      background-color: ${shade(0.1, '#ef5472')} !important;
      border-color: ${shade(0.1, '#ef5472')} !important;
    }
  }
`
