import { Button, Table } from 'antd'
import { CSVLink } from 'react-csv'

type Props = {
  dataSource: any[]
  type: string
  isPrintable?: boolean
}

function getBackgroundColor(record: string, valorReal: string) {
  if (record === 'Desejado Time' || record === 'Desejado Empresa')
    return 'purple'
  if (Number(valorReal[1]) > 7) return '#d9534f'
  if (Number(valorReal[1]) > 3) return '#ffcc5c'
  if (Number(valorReal[1]) != 1) return '#88d8b0'
}

function counterFit(myElement: any) {
  let sum = 0
  Object.keys(myElement).forEach((key, index) => {
    if (index > 1) {
      if (myElement[key][1] == '2' || myElement[key][1] == '3') sum += 1
    }
  })
  return sum
}

// Sort object descending
function sortObject(obj: any) {
  obj.sort(function (a: any, b: any) {
    return counterFit(b) - counterFit(a)
  })
}

// Get element with sum is equal to 1
function getFirstElement(myElement: any) {
  let position = 0
  myElement.forEach((element: any, index: number) => {
    if (element.Aprendizado[1] == '1') position = index
  })
  return position
}

// Delete element at position index
function deleteElement(obj: any) {
  const index = getFirstElement(obj)
  addElement(obj, index)
}

// Receive index and add element at first position
function addElement(obj: any, index: number) {
  obj.unshift(obj[index])
  obj.splice(index + 1, 1)
}

const App = (props: Props) => {
  sortObject(props.dataSource)
  deleteElement(props.dataSource)
  if (props.type === '1') {
    props.dataSource[0].name = 'Desejado Empresa'
  }

  /*
  const columnsFit = [
    {
      title: 'Pessoa',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Fit Geral',
      dataIndex: 'Fit Geral',
      key: 'Fit Geral',
      render: (text: string, record: any) => {
        return {
          children: <div>50.1%</div>,
        }
      },
    },
    {
      title: 'Fit Arquétipos',
      dataIndex: 'Fit Arquétipos',
      key: 'Fit Arquétipos',
      render: (text: string, record: any) => {
        return {
          children: <div>50.1%</div>,
        }
      },
    },
    {
      title: 'Fit Valores',
      dataIndex: 'Fit Valores',
      key: 'Fit Valores',
      render: (text: string, record: any) => {
        return {
          children: <div>50.1%</div>,
        }
      },
    },
  ] */
  const columns = [
    {
      title: '#',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Aprend.',
      dataIndex: 'Aprendizado',
      key: 'Aprendizado',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Autor.',
      dataIndex: 'Autoridade',
      key: 'Autoridade',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Clã',
      dataIndex: 'Clã',
      key: 'Clã',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Colab.',
      dataIndex: 'Colaboração',
      key: 'Colaboração',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Compla.',
      dataIndex: 'Complacência',
      key: 'Colaboração',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Empre.',
      dataIndex: 'Empreendedora',
      key: 'Empreendedora',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Inov.',
      dataIndex: 'Inovação',
      key: 'Inovação',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
    {
      title: 'Reg.',
      dataIndex: 'Regras',
      key: 'Regras',
      render(text: string, record: any) {
        return {
          props: {
            style: {
              background: getBackgroundColor(record.name, text),
              color: 'white',
            },
          },
          children: <div>{text[0]}</div>,
        }
      },
    },
  ]

  if (!props.dataSource) return <div />

  return (
    <div style={{ marginTop: 50 }}>
      {!props.isPrintable && (
        <Button type="primary" style={{ marginBottom: 20 }}>
          <CSVLink
            filename="Reports.csv"
            data={props.dataSource}
            className="btn btn-primary"
            onClick={() => {
              console.log('The file is downloading')
            }}
          >
            Exportar para CSV
          </CSVLink>
        </Button>
      )}

      {props.isPrintable ? (
        <Table
          dataSource={props.dataSource}
          columns={columns}
          pagination={false}
        />
      ) : (
        <Table
          dataSource={props.dataSource}
          columns={columns}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
          }}
        />
      )}
    </div>
  )
}

export default App
