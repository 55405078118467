import React from 'react'

import { Col, Row } from 'antd'

import {
  PerfilComportamental,
  PerfilComportamentalTextual,
} from '../../components/perfilComportamental/newIndex'

import { noPadding } from '../../utils/inlineStyles'

import { Container } from './styles'

function VetorIndividualShareView(props) {
  const {
    cor,
    disc,
    edc,
    edl,
    erc,
    foc,
    oct,
    ode,
    rct,
    tdd,
    intensidade,
    perfilComp,
    pes_nome,
    principal,
    fitCulturalEmpresa,
    showGraficoRadar,
    handleSwitchGraficoRadar,
    cultura,
    culturaEmpresa,
  } = props
  return (
    <>
      <Col span={24} style={noPadding}>
        <Row type="flex" style={noPadding}>
          <Col sm={0} xs={0} md={0} xxl={1} style={noPadding} />
          <Col sm={24} xs={24} md={24} xxl={22} style={noPadding}>
            <Row gutter={16} type="flex" style={noPadding}>
              <PerfilComportamental
                {...{
                  cor,
                  disc,
                  edc,
                  edl,
                  erc,
                  foc,
                  oct,
                  ode,
                  rct,
                  tdd,
                  intensidade,
                  pes_nome,
                  principal,
                  fitCulturalEmpresa,
                  type: 'colaborador',
                  handleSwitchGraficoRadar,
                  showGraficoRadar,
                  perfilComp,
                }}
                cultura={cultura}
                culturaEmpresa={culturaEmpresa}
              />
            </Row>
          </Col>
          <Col sm={0} xs={0} md={0} xxl={1} style={noPadding} />
        </Row>
      </Col>
      <Container span={24}>
        <Row gutter={16} type="flex" style={noPadding}>
          <Col sm={0} xs={0} md={0} xxl={1} style={noPadding} />
          <Col sm={24} xs={24} md={24} xxl={22}>
            <Row>
              <PerfilComportamentalTextual
                type="candidato"
                principal={principal}
                fitCulturalEmpresa={fitCulturalEmpresa}
                cultura={cultura}
                culturaEmpresa={culturaEmpresa}
              />
            </Row>
          </Col>
          <Col sm={0} xs={0} md={0} xxl={1} style={noPadding} />
        </Row>
      </Container>
    </>
  )
}

export default VetorIndividualShareView
