import { connect } from 'react-redux'

// Components
import PerfilComportamentalController from './PerfilComportamentalController'

// Actions
import {
  getCaracteristicasPerfilComportamental,
  postRespostaPerfilComportamental,
  getInfoPerfilComportamental,
  loadVetor,
} from '../../actions/actionsPesquisas'

const mapStateToProps = ({ pesquisas }) => {
  return {
    ...pesquisas.perfilComportamental,
  }
}

export default connect(mapStateToProps, {
  getCaracteristicasPerfilComportamental,
  postRespostaPerfilComportamental,
  getInfoPerfilComportamental,
  loadVetor,
})(PerfilComportamentalController)
