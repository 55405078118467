import React from 'react'
import { Link } from 'react-router-dom'

// Services
import { getContaURL } from '../../services/auth'

const PrivateLink = ({ children, to, ...rest }) => {
  const contaURL = getContaURL()
  return (
    <Link {...rest} to={`/${contaURL}${to}`}>
      {children}
    </Link>
  )
}

export default PrivateLink
