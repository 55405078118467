import React from 'react'

import { Helmet } from 'react-helmet'

import { Col, Row } from 'antd'

import { PerfilComportamental } from '../../../components/perfilComportamental/antigo'

import { noPadding } from '../../../utils/inlineStyles'

const PerfilComportamentalView = ({
  cor,
  disc,
  edc,
  edl,
  erc,
  foc,
  intensidade,
  oct,
  ode,
  perfilComp,
  principal,
  rct,
  tdd,
  perfis,
  showGraficoRadar,
  handleSwitchGraficoRadar,
  discInstalado,
}) => {
  return (
    <>
      <Helmet>
        <title>TeamHub | Perfil Comportamental</title>
      </Helmet>
      <Col span={24} style={noPadding}>
        <Row gutter={16} type="flex" style={noPadding}>
          <PerfilComportamental
            disc={disc}
            discInstalado={discInstalado}
            intensidade={intensidade}
            rct={rct}
            edl={edl}
            foc={foc}
            ode={ode}
            oct={oct}
            tdd={tdd}
            cor={cor}
            erc={erc}
            edc={edc}
            principal={principal}
            perfilComp={perfilComp}
            type="geral"
            showGraficoRadar={showGraficoRadar}
            handleSwitchGraficoRadar={handleSwitchGraficoRadar}
            perfisQtde={perfis}
          />
        </Row>
      </Col>
    </>
  )
}

export default PerfilComportamentalView
