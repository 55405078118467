import React from 'react'

import { Form, Input } from 'antd'

function MotivoExclusao({
  handleChangeMotivoExclusao,
  motivoExclusao,
  onFinish,
}) {
  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      initialValues={{ motivoExclusao }}
      id="motivo-exclusao-submit"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        name="motivoExclusao"
        rules={[
          {
            required: true,
            message: 'Por favor, preencha o motivo da exclusão.',
          },
        ]}
      >
        <Input
          onChange={(e) => handleChangeMotivoExclusao(e.target.value)}
          placeholder="Motivo da exclusão do candidato do Processo Seletivo."
          size="middle"
          value={motivoExclusao}
        />
      </Form.Item>
    </Form>
  )
}

export default MotivoExclusao
