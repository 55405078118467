import React, { useEffect, useState, useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { Select, Row, Col, Card, Tooltip, Modal } from 'antd'
import { Helmet } from 'react-helmet'
import Link from '../../components/privateLink/PrivateLink'
import err404 from '../../assets/error-404.png'

// import { Container } from './styles';

import AnaliseGeralView from '../../components/AnaliseGeral'

function AnaliseGeral() {
  return (
    <>
      <Helmet>
        <title>TeamHub | Erro 404 Página não encontrada </title>
      </Helmet>
      <Row>
        <Modal
          visible
          centered
          mask={false}
          footer={null}
          closable={false}
          wrapClassName="login"
          width="70vw"
          style={{
            textAlign: 'center',
            fontSize: '32px',
          }}
        >
          <div>
            <img
              src={err404}
              alt="págin não encontrada"
              style={{ width: '60vw', marginTop: '-25px' }}
            />
          </div>
          <Link
            to="/dashboard/visao-geral"
            style={{
              textAlign: 'center',
              fontSize: '32px',
              marginTop: '-25px',
            }}
          >
            Voltar para a tela inicial
          </Link>
        </Modal>
      </Row>
    </>
  )
}

export default AnaliseGeral
