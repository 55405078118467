import { ConfigProvider } from 'antd'
// CSS
import 'antd/dist/antd.less'
import pt_BR from 'antd/es/locale-provider/pt_BR'
import moment from 'moment'
import 'moment/locale/pt-br'
import ReactDOM from 'react-dom'
import { addLocaleData, IntlProvider } from 'react-intl'
import en from 'react-intl/locale-data/en'
import pt from 'react-intl/locale-data/pt'
import { Provider } from 'react-redux'
// React Toastify
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { SWRConfig } from 'swr'
// Routes
import Routes from './routes'
import { swrConfiguration } from './services/api'
import { store } from './services/rootReducer'
import './style.css'
import i18n from './utils/i18n'
import { flattenMessages } from './utils/utils'

// I18N
addLocaleData([...en, ...pt])
const params = new URLSearchParams(document.location.search.substring(1))
const locale = params.get('lang') || 'pt-BR'
moment.locale('pt-br')

// BizCharts

ReactDOM.render(
  <ConfigProvider locale={pt_BR}>
    <Provider store={store}>
      <IntlProvider locale={locale} messages={flattenMessages(i18n[locale])}>
        <SWRConfig value={swrConfiguration}>
          <Routes />
        </SWRConfig>
      </IntlProvider>
      <ToastContainer autoClose={3000} />
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
)
