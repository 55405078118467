import React, { useEffect, useState, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import {
  Modal,
  Row,
  Col,
  Card,
  Alert,
  Popconfirm,
  Divider,
  Tabs,
  Tooltip,
} from 'antd'

import { DeleteOutlined, PoweroffOutlined } from '@ant-design/icons'

import Link from '../../components/privateLink/PrivateLink'
import { logout, isLider, getContaURL } from '../../services/auth'

import DesligamentoModal from '../perfil/components/DesligamentoModal'
import history from '../../utils/history'

// Components
import Button from '../../components/button/Button'
import Table from '../../components/editableTable/EditableTable'

// CSS
import './ColaboradoresInativos.css'

// Utils
import { sorterString, sorterDate } from '../../utils/utils'

const { TabPane } = Tabs

function ColaboradoresInativosView({
  intl,
  showModals,
  handleChangeModal,
  onTableSave,
  onTableDelete,
  listaColaboradoresInativos,
  tableCount,
  rowSelection,
  openModal,
  closeModal,
  closeModalDesligamento,
  submitModalDesligamento,
  onModalSubmit,
  exportarExcel,
  onFileUpload,
  onRemoveFile,
  modalStates,
  okButtonProps,
  handleChangeCheckbox,
  getColumnSearchProps,
  times,
  pcd_tipo,
  cargos,
  excluirPerfil,
  error,
  setCurrentPessoa,
  onboarding = false,
  filiais,
}) {
  const lider = isLider()
  // Table columns
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'nome'),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) =>
        onboarding ? text : <Link to={`/perfil/${record.key}`}>{text}</Link>,
      ...getColumnSearchProps('nome'),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      editable: true,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'cargo'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('cargo'),
    },
    {
      title: 'Times',
      dataIndex: 'times',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'times'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('times'),
    },
    {
      title: 'Filial',
      dataIndex: 'nome_filial',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'nome_filial'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('nome_filial'),
    },
    {
      title: 'Último Feedback',
      dataIndex: 'ultimofeedback',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'ultimofeedback'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('ultimofeedback'),
    },
    {
      title: '% PDI',
      dataIndex: 'pct_metas',
      editable: true,
      sorter: (a, b) => sorterString(a, b, 'pct_metas'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('pct_metas'),
    },
    {
      title: 'Data de Nascimento',
      dataIndex: 'pes_datanascimento',
      editable: true,
      sorter: (a, b) => sorterDate(a, b, 'pes_datanascimento'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('pes_datanascimento'),
    },
    {
      title: 'Data de Admissão',
      dataIndex: 'dataadmissao',
      editable: true,
      sorter: (a, b) => sorterDate(a, b, 'dataadmissao'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dataadmissao'),
    },
  ]

  return (
    <>
      <Helmet>
        <title>TeamHub | Colaboradores</title>
      </Helmet>
      <div className="colaboradores-inativos">
        <Row>
          <Col md={24}>
            <Tabs
              defaultActiveKey="2"
              onTabClick={(tab) => {
                tab === '1' && history.push(`/${getContaURL()}/colaboradores`)
              }}
            >
              <TabPane tab="Ativos" key="1" />
              <TabPane tab="Inativos" key="2">
                <Card
                  type="inner"
                  title={
                    <span>
                      <b>Visualize seus colaboradores inativos.</b>
                    </span>
                  }
                >
                  <div className="colaboradores-inativos__table">
                    <Table
                      dataSource={listaColaboradoresInativos}
                      count={tableCount}
                      columns={columns}
                      onDelete={onTableDelete}
                      rowSelection={!lider ? rowSelection : false}
                      isDeleble={!lider}
                      deleteNode={(handleDelete, key) =>
                        !lider && (
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Divider type="vertical" />
                            <Popconfirm
                              title="Tem certeza que deseja excluir?"
                              onConfirm={() => excluirPerfil(key)}
                              okText="Sim"
                              cancelText="Não"
                            >
                              <a>
                                <Tooltip title="Excluir" placement="bottom">
                                  <DeleteOutlined />
                                </Tooltip>
                              </a>
                            </Popconfirm>
                          </span>
                        )
                      }
                    />
                  </div>
                </Card>
              </TabPane>
            </Tabs>
          </Col>
        </Row>

        {/* NEW DESLIGAMENTO MODAL */}
        <Modal
          id={6}
          title="Desligar Colaborador?"
          visible={showModals[6]}
          cancelText="CANCELAR"
          okText="SALVAR"
          okButtonProps={{ htmlType: 'submit', form: 'desligamento-modal' }}
          onCancel={() => closeModalDesligamento()}
          destroyOnClose
          okText="Sim"
          cancelText="Não"
          centered
        >
          <DesligamentoModal onFinish={submitModalDesligamento} />
        </Modal>
      </div>
    </>
  )
}

export default injectIntl(ColaboradoresInativosView)
