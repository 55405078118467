import * as constants from '../constants/constants'

export const loadRequisitos = () => {
  return {
    type: constants.REQUISITO_LOAD,
  }
}

export const listRequisitos = (id) => {
  return {
    type: constants.REQUISITO_LIST,
    api: {
      method: 'get',
      url: constants.URL_REQUISITO_LIST(id),
    },
  }
}

export const getFrequencia = () => {
  return {
    type: constants.FREQUENCIA_CARGO,
    api: {
      method: 'get',
      url: constants.URL_FREQUENCIA_CARGO,
    },
  }
}

export const storeRequisitos = ({ id, data, nome, nivel_id }) => {
  return {
    type: constants.REQUISITO_POST,
    api: {
      method: 'post',
      url: constants.URL_REQUISITO_POST(id),
      data: { nome: data.nome, nivel_id: data.nivel_id },
    },
  }
}

export const updateRequisito = ({ id, formacoes_id, nome, nivel_id }) => {
  return {
    type: constants.REQUISITO_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_REQUISITO_ID(id, formacoes_id),
      data: { nome, nivel_id },
    },
  }
}

export const deleteRequisitos = ({ id, formacoes_id }) => {
  return {
    type: constants.REQUISITO_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_REQUISITO_ID(id, formacoes_id),
    },
  }
}
