import styled from 'styled-components'

import { Modal as ModalAntd } from 'antd'

export const ValoresOrg = styled.div`
  .valores-org__header {
    background: #fff;
    padding: 15px 30px;
    border-bottom: 1px solid #ebedf0;
  }

  .valores-org__header__title {
    color: black;
    font-size: 24px;
  }

  .valores-org__footer {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
      justify-content: center;
      width: 100%;
      .ant-btn {
        width: 100%;
      }
    }
  }
`

export const Container = styled.div`
  background: #fff;
  border-radius: 4px;
  border: #ebedf0 1px solid;
  margin: 30px 0;
  padding: 20px 25px;

  @media (max-width: 768px) {
    .valores-org__content__grade + .valores-org__content__grade {
      margin-top: 24px;
    }
  }

  .valores-org__content__grade {
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      .valores-org__content__grade__slider {
        min-width: auto;
        padding: 0px;
      }
    }
  }

  .valores-org__content__grade__slider {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    .ant-slider {
      flex: 1;
      margin: 32px;
    }

    padding: 0px 30px;
  }

  .valores-org__content__grade__slider .anticon {
    width: 16px;
    height: 16px;
    line-height: 1;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.75);
  }

  .valores-org__content__grade__slider .anticon:first-child {
    left: 0;
  }

  .valores-org__content__grade__slider .ant-slider-rail {
    background: #00000040;
  }
  .valores-org__content__grade__slider .ant-slider-dot {
    background: #656565;
  }
  .valores-org__content__grade__slider .ant-slider-dot-active {
    background: #3839a9;
  }
  .valores-org__content__grade__slider .ant-slider-track {
    background: #292be6c2;
  }

  .valores-org__content__grade__slider .anticon:last-child {
    right: 0;
  }
`

export const Modal = styled(ModalAntd)`
  text-align: center;

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .valores-org__modal__image {
    margin: 30px 0;
    width: 100%;
  }

  .valores-org__modal h1 {
    color: #585858;
  }

  .valores-org__modal button {
    margin: 30px 0;
  }
`

export const TutorialContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  border: #ebedf0 1px solid;
  margin: 30px 0;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 18px;
    margin: 0;
    text-align: center;
  }

  .valores-org__tutorial__hint__label {
    font-weight: 500;
    font-size: 24px;
    margin: 24px 0px;
  }

  .valores-org__tutorial__hints {
    display: flex;
    flex-direction: column;
    span {
      font-size: 16px;
    }
    span + span {
      margin-top: 8px;
    }
  }

  & .ant-btn {
    margin-top: 16px;
  }
`
