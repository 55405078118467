import React, { useEffect, useState, useCallback, useMemo } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Button,
  Popconfirm,
  Modal,
  Divider,
  Card,
  Switch,
  Tooltip,
} from 'antd'

import {
  ExclamationCircleFilled,
  Loading3QuartersOutlined,
  UserAddOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import Table from '../../../../components/editableTable/EditableTable'

import PsContrato from './components/novoTipoContrato'

import {
  listTipoContrato,
  storeTipoContrato,
  updateTipoContrato,
  deleteTipoContrato,
  load,
} from './actions/actions'

import { Action, TableHeader } from './styles'

function TipoContrato() {
  const dispatch = useDispatch()

  const [showModalNewTipoContrato, setShowModalNewTipoContrato] =
    useState(false)

  const [TipoContratos, setTipoContrato] = useState([])

  const [showInativos, setShowInativos] = useState(false)

  const [loadingTipoContratos, setloadingTipoContratos] = useState(false)

  const [handleTipoContrato, sethandleUpdateTipoContrato] = useState(false)

  const loadTipoContratos = useCallback(() => {
    setloadingTipoContratos(true)
    dispatch(listTipoContrato()).then(({ response }) => {
      setloadingTipoContratos(false)
      if (response.status === 200) {
        setTipoContrato(response.data)
      }
    })
  }, [dispatch])

  useEffect(() => {
    loadTipoContratos()
  }, [loadTipoContratos])

  const onFinishNewTipoContrato = () => {
    dispatch(TipoContrato(contrato)).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Contrato cadastrado com sucesso.')
        loadTipoContratos()
      } else toast.error('Erro ao cadastrar Tipo de Contrato.')
    })
  }
  const handleDeleteTipoContrato = useCallback(
    (contrato_id) => {
      dispatch(deleteTipoContrato({ contrato_id })).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Tipo de Contrato excluída com sucesso.')
          loadTipoContratos()
        }
      })
    },
    [dispatch, loadTipoContratos]
  )

  const handleUpdateTipoContrato = useCallback(
    (updatedTipoContrato, data) => {
      dispatch(
        updateTipoContrato({
          contrato_id: updatedTipoContrato.contrato_id,
          ...updatedTipoContrato,
        })
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Tipo de Contrato atualizada com sucesso.')
          loadTipoContratos()
        }
      })
    },
    [dispatch, loadTipoContratos]
  )

  const columns = useMemo(
    () => [
      {
        title: 'Nome',
        dataIndex: 'nome',
        width: '25%',
        sorter: (a, b) => sorterString(a, b, 'nome'),
        editable: true,
      },
      {
        title: 'Descricao',
        dataIndex: 'descricao',
        width: '25%',
        sorter: (a, b) => sorterString(a, b, 'descricao'),
        editable: true,
      },
      {
        title: 'Criado Por',
        dataIndex: ['creator', 'colaborador', 'pes_nome'],
        width: '15%',
        sorter: (a, b) =>
          sorterString(a, b, ['creator', 'colaborador', 'pes_nome']),
      },
      {
        title: 'Atualizado Por',
        dataIndex: ['updater', 'colaborador', 'pes_nome'],
        width: '15%',
        sorter: (a, b) =>
          sorterString(a, b, ['updater', 'colaborador', 'pes_nome']),
      },
      {
        title: 'Data de Atualização',
        dataIndex: 'updated_at',
        width: '10.5%',
        sorter: (a, b) => sorterDate(a, b, 'updated_at'),
        render: (text) =>
          text
            ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
            : '',
      },
      {
        title: 'Data de Criação',
        dataIndex: 'created_at',
        width: '15%',
        sorter: (a, b) => sorterDate(a, b, 'created_at'),
        render: (text) =>
          text
            ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
            : '',
      },
      {
        title: 'Ações',
        key: 'extra_actions',
        width: '20%',
        render: (text, record) => {
          return (
            <>
              <Divider type="vertical" />
              <Tooltip title="Excluir">
                <Popconfirm
                  title="Tem certeza que deseja excluir esse tipo de contrato?"
                  onConfirm={() => handleDeleteTipoContrato(record.contrato_id)}
                  okText="Excluir"
                  cancelText="Cancelar"
                  icon={
                    <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                  }
                >
                  <Action>
                    <DeleteOutlined />
                  </Action>
                </Popconfirm>
              </Tooltip>
            </>
          )
        },
      },
    ],
    [handleDeleteTipoContrato]
  )
  const handleCancelNewTipoContrato = useCallback(() => {
    setShowModalNewTipoContrato(false)
  }, [])

  const handleNewTipoContrato = useCallback(
    (data) => {
      dispatch(storeTipoContrato(data)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Tipo de contrato criada com sucesso.')
          setShowModalNewTipoContrato(false)
          loadTipoContratos()
        }
      })
    },
    [dispatch, loadTipoContratos]
  )

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Card title="Cadastre e gerencie tipos de contratos">
            <Table
              dataSource={TipoContratos.filter((contrato) => {
                if (showInativos) {
                  return contrato
                }
                return parseInt(contrato.ativo) === 1
              }).map((contrato) => ({
                ...contrato,
                key: contrato.contrato_id,
              }))}
              isEditable
              key={(record) => record.contrato_id}
              loading={loadingTipoContratos}
              columns={columns}
              onSave={handleUpdateTipoContrato}
              header={() => (
                <TableHeader>
                  <div>
                    <Switch
                      checked={showInativos}
                      onChange={() => setShowInativos(!showInativos)}
                    />
                    <span>Exibir Tipos de Contratos inativos</span>
                  </div>
                  <Button
                    type="primary"
                    onClick={() => setShowModalNewTipoContrato(true)}
                  >
                    <UserAddOutlined /> NOVO TIPO DE CONTRATO
                  </Button>
                </TableHeader>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="NOVO TIPO DE CONTRATO"
        visible={showModalNewTipoContrato}
        cancelText="CANCELAR"
        onCancel={() => handleCancelNewTipoContrato()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'novo-tipo-contrato',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <PsContrato
          handleChangeTipoContrato={handleUpdateTipoContrato}
          onFinish={handleNewTipoContrato}
          {...handleTipoContrato}
        />
      </Modal>
    </Col>
  )
}
export default TipoContrato
