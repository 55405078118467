// Redux types
import * as constants from '../constants/constantsGestaoPesquisas'

export const getCandidatos = () => {
  return {
    type: constants.GESTAOPESQUISAS_CANDIDATOS_GET,
    api: {
      method: 'get',
      url: constants.URL_GESTAOPESQUISAS_CANDIDATOS_GET,
    },
  }
}
export const getPesquisas = () => {
  return {
    type: constants.PESQUISAS_GET,
    api: {
      method: 'get',
      url: constants.URL_PESQUISAS_GET,
    },
  }
}

export const sendVetorComportamental = (data, { enviarEmails }) => {
  return {
    type: constants.SEND_AVALIACOES,
    api: {
      method: 'post',
      url: constants.URL_SEND_AVALIACOES,
      data: {
        ...data,
        enviarEmails,
      },
    },
  }
}

export const sendValoresOrganizacionais = (pes_id, { enviarEmails }) => {
  return {
    type: constants.SEND_PESQ_VALORES_ORGANIZACIONAIS,
    api: {
      method: 'post',
      url: constants.URL_SEND_PESQ_VALORES_ORGANIZACIONAIS(pes_id),
      data: { enviarEmails },
    },
  }
}

export const cancelVetorComportamental = (id, avalia_id) => {
  return {
    type: constants.VETOR_COMPORTAMENTAL_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_VETOR_COMPORTAMENTAL_DELETE}/${id}/${avalia_id}`,
    },
  }
}
export const cancelPercepcao = (id, avalia_id) => {
  return {
    type: constants.PERCEPCAO_AMBIENTE_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_PERCEPCAO_AMBIENTE_DELETE}/${id}/${avalia_id}`,
    },
  }
}
export const cancelValoresOrganizacionais = (id, avalia_id) => {
  return {
    type: constants.DELETE_PESQ_VALORES_ORGANIZACIONAIS,
    api: {
      method: 'delete',
      url: `${constants.URL_DELETE_PESQ_VALORES_ORGANIZACIONAIS(avalia_id)}`,
    },
  }
}

export const newLink = (pes_id, avalia_id) => {
  return {
    type: constants.POST_GESTAO_PESQUISAS_VETOR_SHARE,
    api: {
      method: 'post',
      url: constants.URL_POST_GESTAO_PESQUISAS_VETOR_SHARE(pes_id, avalia_id),
    },
  }
}
