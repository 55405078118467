import React from 'react'
import {
  ExclamationCircleFilled,
  LinkOutlined,
  RetweetOutlined,
  PoweroffOutlined,
  DashboardOutlined,
} from '@ant-design/icons'
import { Table, Badge, message, Divider, Popconfirm, Tooltip } from 'antd'
import {
  Chart,
  Interval,
  Line,
  Point,
  Axis,
  Coordinate,
  Legend,
} from 'bizcharts'
import moment from 'moment'

// Utils
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { sorterDate, colors, discRange } from '../../../utils/utils'
import Link from '../../../components/privateLink/PrivateLink'
import { getContaURL, isLider } from '../../../services/auth'

function TabHome({
  chartVetores,
  vetorComportamental,
  getColumnSearchProps,
  sendVetorComportamental,
  sendPercepcao,
  getVetorComportamental,
  cancelPercepcao,
  cancelVetorComportamental,
}) {
  const lider = isLider()

  const columnsHome = [
    {
      title: 'Status',
      dataIndex: 'respondido',
      align: 'center',
      key: 'respondido',
      sorter: (a, b) => a.respondido - b.respondido,
      sortDirections: ['descend', 'ascend'],
      render: (text) => {
        return <Badge status={text === 1 ? 'success' : 'error'} />
      },
    },
    {
      title: 'Enviado Em',
      key: 'data_solicitacao',

      dataIndex: 'data_solicitacao',
      align: 'center',
      sorter: (a, b) => sorterDate(a, b, 'enviadoEm', 'DD/MM/YYYY (HH:mm)'),
      sortDirections: ['descend', 'ascend'],
      render: (text) =>
        text
          ? moment(text, 'DD/MM/YYYY (HH:mm)').format('DD/MM/YYYY (HH:mm)')
          : '',
      ...getColumnSearchProps('enviadoEm', 'data envio'),
    },
    {
      title: 'Respondido Em',
      dataIndex: 'data_entrega',
      key: 'data_entrega',

      align: 'center',
      sorter: (a, b) => sorterDate(a, b, 'respondidoEm', 'DD/MM/YYYY (HH:mm)'),
      sortDirections: ['descend', 'ascend'],
      render: (text) =>
        text
          ? moment(text, 'DD/MM/YYYY (HH:mm)').format('DD/MM/YYYY (HH:mm)')
          : '',
      ...getColumnSearchProps('respondidoEm', 'data resposta'),
    },
    {
      title: 'Ações',
      key: 'acoes',
      align: 'center',
      render: (text, record) => {
        return record.respondido === 1 ? (
          <Link
            key={record.pes_id}
            to={`/dashboard/colaboradores?colaborador=${record.pes_id}&avaliacao=${record.avalia_id}`}
          >
            <Tooltip placement="bottom" title="Ver na Dashboard">
              <DashboardOutlined />
            </Tooltip>
          </Link>
        ) : !lider ? (
          <span key={record.pes_id}>
            <CopyToClipboard
              text={`${window.location.host}/${getContaURL()}/pesquisas/${
                record.tipo === 'Perfil Comportamental'
                  ? 'perfil-comportamental'
                  : 'percepcao-ambiente'
              }/${record.hashacesso}`}
              onCopy={() =>
                message.success(
                  'Link da pesquisa copiado para a área de transferência.'
                )
              }
            >
              <a href="javascript:;" aria-label="Link">
                <Tooltip placement="bottom" title="Link da Pesquisa">
                  <LinkOutlined />
                </Tooltip>
              </a>
            </CopyToClipboard>
            <Divider type="vertical" />
            <Popconfirm
              title="Reenviar pesquisa?"
              onConfirm={() => {
                sendVetorComportamental({
                  pessoas: [{ pes_id: record.pes_id }],
                  enviarEmails: true,
                }).then(({ response }) => {
                  if (response.status === 200) {
                    message.success('Enviado com sucesso!')
                  }
                })
              }}
              okText="Sim"
              cancelText="Não"
              icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
            >
              <a href="javascript:;" aria-label="reenviar">
                <Tooltip placement="bottom" title="Reenviar">
                  <RetweetOutlined />
                </Tooltip>
              </a>
            </Popconfirm>
            <Divider type="vertical" />
            <Popconfirm
              title="Cancelar pesquisa?"
              onConfirm={() => {
                const canceledId = record.pes_id
                cancelVetorComportamental(record.pes_id, record.avalia_id).then(
                  ({ response }) => {
                    if (response.status === 200) {
                      message.success('Cancelado com sucesso!')
                    }
                    getVetorComportamental({ id: canceledId })
                  }
                )
              }}
              okText="Sim"
              cancelText="Não"
              icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
            >
              <a href="javascript:;" aria-label="cancelar">
                <Tooltip placement="bottom" title="Cancelar">
                  <PoweroffOutlined />
                </Tooltip>
              </a>
            </Popconfirm>
          </span>
        ) : null
      },
    },
  ]

  return (
    <div className="perfil__tabs__home">
      <div className="perfil__tabs__home__chart">
        <Chart
          data={chartVetores}
          height={250}
          scale={
            chartVetores.length / 4 > 1
              ? { value: { ticks: [0, 20, 40, 60, 80, 100] } }
              : discRange
          }
          autoFit
        >
          {chartVetores.length / 4 > 1 ? (
            <>
              <Axis name="log_data" />
              <Axis name="value" />
              <Tooltip
                crosshairs={{
                  type: 'y',
                }}
              />
              <Line position="log_data*value" color="type" />
            </>
          ) : (
            <>
              <Coordinate type="polar" radius={0.8} />
              <Axis
                name="type"
                line={null}
                tickLine={null}
                grid={{
                  lineStyle: {
                    lineDash: null,
                  },
                  hideFirstLine: false,
                }}
              />
              <Axis
                name="value"
                line={null}
                tickLine={null}
                grid={{
                  type: 'polygon',
                  lineStyle: {
                    lineDash: null,
                  },
                  alternateColor: 'rgba(0, 0, 0, 0.04)',
                }}
              />
              <Tooltip />
              <Legend name="colors" marker="circle" offset={30} />
              <Line
                position="type*value"
                color={['log_data', colors]}
                size={2}
              />
              <Point
                position="type*value"
                color={['log_data', colors]}
                shape="circle"
                size={4}
                style={{
                  stroke: '#fff',
                  lineWidth: 1,
                  fillOpacity: 1,
                }}
              />
            </>
          )}
        </Chart>
      </div>

      <div className="perfil__tabs__home__table">
        <Divider />
        <Table
          columns={columnsHome}
          dataSource={vetorComportamental}
          title={() => {
            return (
              <span style={{ fontSize: 16, fontWeight: 500 }}>
                Vetores Comportamentais
              </span>
            )
          }}
        />
      </div>
    </div>
  )
}

export default TabHome
