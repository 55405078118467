import React, { useEffect, useState, useCallback, useMemo } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Button,
  Popconfirm,
  Modal,
  Divider,
  Card,
  Switch,
} from 'antd'

import {
  ExclamationCircleFilled,
  Loading3QuartersOutlined,
  UserAddOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import Table from '../../../../components/editableTable/EditableTable'

import NovoMotivoSuspensao from './components/NovoMotivoSuspensao/index'

import {
  listMotivoSuspensao,
  storeMotivoSuspensao,
  updateMotivoSuspensao,
  deleteMotivoSuspensao,
  loadMotivoSuspensao,
} from './actions/actions'

import { Action, TableHeader } from './styles'
import MotivoCancelamento from '../MotivoCancelamento'

function PsMotivoSuspensao() {
  const dispatch = useDispatch()

  const [showModalNewMotivoSuspensao, setShowModalNewMotivoSuspensao] =
    useState(false)

  const [motivosSuspensao, setMotivosSuspensao] = useState([])

  const [showInativos, setShowInativos] = useState(false)

  const [loadingMotivoSuspensao, setloadingMotivoSuspensao] = useState(false)

  const [handleMotivoSuspensao, sethandleUpdateMotivoSuspensao] =
    useState(false)

  const loadMotivoSupensao = useCallback(() => {
    setloadingMotivoSuspensao(true)
    dispatch(listMotivoSuspensao()).then(({ response }) => {
      setloadingMotivoSuspensao(false)
      if (response.status === 200) {
        setMotivosSuspensao(response.data)
      }
    })
  }, [dispatch])

  useEffect(() => {
    loadMotivoSupensao()
  }, [loadMotivoSupensao])

  const handleDeleteMotivoSuspensao = useCallback(
    (suspensao_id) => {
      dispatch(deleteMotivoSuspensao({ suspensao_id })).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Motivo de suspensão excluído com sucesso.')
          loadMotivoSupensao()
        }
      })
    },
    [dispatch, loadMotivoSupensao]
  )

  const handleUpdateMotivoSuspensao = useCallback(
    (updatedMotivoSuspensao, data) => {
      dispatch(
        updateMotivoSuspensao({
          suspensao_id: updatedMotivoSuspensao.suspensao_id,
          ...updatedMotivoSuspensao,
        })
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Motivo de Suspensão atualizado com sucesso.')
          loadMotivoSupensao()
        }
      })
    },
    [dispatch, loadMotivoSupensao]
  )

  const columns = useMemo(() => {
    return [
      {
        title: 'Nome',
        dataIndex: 'nome',
        width: '20%',
        sorter: (a, b) => sorterString(a, b, 'nome'),
        editable: true,
      },
      {
        title: 'Descricao',
        dataIndex: 'descricao',
        width: '25%',
        sorter: (a, b) => sorterString(a, b, 'descricao'),
        editable: true,
      },
      {
        title: 'Data de Criação',
        dataIndex: 'created_at',
        width: '15%',
        sorter: (a, b) => sorterDate(a, b, 'created_at'),
        render: (text) =>
          text
            ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
            : '',
      },
      {
        title: 'Criado Por',
        dataIndex: ['creator', 'colaborador', 'pes_nome'],
        width: '15%',
        sorter: (a, b) =>
          sorterString(a, b, ['creator', 'colaborador', 'pes_nome']),
      },
      {
        title: 'Data de Atualização',
        dataIndex: 'updated_at',
        width: '10.5%',
        sorter: (a, b) => sorterDate(a, b, 'updated_at'),
        render: (text) =>
          text
            ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
            : '',
      },
      {
        title: 'Atualizado Por',
        dataIndex: ['updater', 'colaborador', 'pes_nome'],
        width: '15%',
        sorter: (a, b) =>
          sorterString(a, b, ['updater', 'colaborador', 'pes_nome']),
      },
      {
        title: 'Ações',
        key: 'extra_actions',
        width: '20%',
        render: (text, record) => {
          return (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title="Tem certeza que deseja excluir esse motivo de suspensão?"
                onConfirm={() =>
                  handleDeleteMotivoSuspensao(record.suspensao_id)
                }
                okText="Excluir"
                cancelText="Cancelar"
                icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
              >
                <Action>
                  <DeleteOutlined />
                </Action>
              </Popconfirm>
            </>
          )
        },
      },
    ]
  }, [handleDeleteMotivoSuspensao])

  const handleCancelNewMotivoSuspensao = useCallback(() => {
    setShowModalNewMotivoSuspensao(false)
  }, [])

  const handleNewMotivoSuspensao = useCallback(
    (data) => {
      dispatch(storeMotivoSuspensao(data)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Motivo de suspensão criado com sucesso.')
          setShowModalNewMotivoSuspensao(false)
          loadMotivoSupensao()
        }
      })
    },
    [dispatch, loadMotivoSupensao]
  )

  const dataSource = useMemo(() => {
    let motivosParsed = motivosSuspensao.map((motivo) => ({
      ...motivo,
      key: motivo.suspensao_id,
    }))
    if (!showInativos) {
      motivosParsed = motivosParsed.filter((motivo) => motivo.ativo === 1)
    }
    return motivosParsed
  }, [motivosSuspensao, showInativos])
  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Card title="Cadastre e gerencie motivos de suspensão">
            <Table
              isEditable
              dataSource={dataSource}
              key={(record) => record.suspensao_id}
              loading={loadingMotivoSuspensao}
              columns={columns}
              onSave={handleUpdateMotivoSuspensao}
              header={() => (
                <TableHeader>
                  <div>
                    <Switch
                      checked={showInativos}
                      onChange={() => setShowInativos(!showInativos)}
                    />
                    <span>Exibir usuários inativos</span>
                  </div>
                  <div />
                  <Button
                    type="primary"
                    onClick={() => setShowModalNewMotivoSuspensao(true)}
                  >
                    <UserAddOutlined /> NOVO MOTIVO DE SUSPENSAO
                  </Button>
                </TableHeader>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="NOVO MOTIVO DE SUSPENSAO"
        visible={showModalNewMotivoSuspensao}
        cancelText="CANCELAR"
        onCancel={() => handleCancelNewMotivoSuspensao()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'nova-motivo-suspensao',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <NovoMotivoSuspensao onFinish={handleNewMotivoSuspensao} />
      </Modal>
    </Col>
  )
}
export default PsMotivoSuspensao
