import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import {
  Modal,
  Row,
  Col,
  Card,
  Divider,
  Table as AntdTable,
  Popconfirm,
  Tooltip,
} from 'antd'
import {
  UsergroupAddOutlined,
  DashboardOutlined,
  ZoomInOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { sorterString } from '../../utils/utils'
import Link from '../../components/privateLink/PrivateLink'

// Layouts
import ContentLayout from '../../layouts/contentLayout/ContentLayout'

// Components
import Button from '../../components/button/Button'
import Table from '../../components/editableTable/EditableTable'

// CSS
import './Times.css'

// Constants
import { modals } from './constants/constantsTimes'

// Services
import { isLider } from '../../services/auth'

// Utils

function TimesView({
  intl,
  openModal,
  rowSelection,
  showModals,
  closeModal,
  onModalSubmit,
  handleChangeModal,
  modalStates,
  handleChangeCheckbox,
  dataSource = [],
  dataFilial = [],
  getColumnSearchProps,
  onTableDelete,
  error,
  handleSaveTime,
}) {
  const navigate = useHistory()
  const lider = isLider()

  function setIDtoLocalStorage(id, nome) {
    localStorage.setItem('id', id)
    localStorage.setItem('nome', nome)
  }

  function navigateToDashboard() {
    navigate.push('./dashboard/visao-times')
  }

  function handleNavigateToDashboard(record) {
    setIDtoLocalStorage(record.grupo_id, record.grupo_nome)
    navigateToDashboard()
  }

  const columns = [
    {
      title: 'Times',
      dataIndex: 'grupo_nome',
      editable: true,
      className: 'column-times',
      width: '30%',
      sorter: (a, b) => sorterString(a, b, 'grupo_nome'),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <Link to={`/times/${record.grupo_id}`}>{text}</Link>
      ),
      ...getColumnSearchProps('grupo_nome', 'nome'),
    },
    {
      title: 'Quantidade de membros',
      dataIndex: 'qtde',
      editable: false,
      className: 'column-membros',
      width: '15%',
      sorter: (a, b) => a.qtde - b.qtde,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('qtde', 'quantidade de membros'),
    },
    {
      title: 'Grupo Pai',
      dataIndex: 'grupo_pai',
      className: 'column-grupo-pai',
      editable: true,
      inputType: 'select',
      width: '20%',
      sorter: (a, b) => sorterString(a, b, 'grupo_pai'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('grupo_pai', 'grupo pai'),
      options: [
        { value: 0, label: 'Nenhum' },
        ...dataSource.map((grupo) => ({
          value: grupo.grupo_id,
          label: grupo.grupo_nome,
        })),
      ],
      render: (text, record) => {
        const findGrupoPai = dataSource.find(
          (grupo) => grupo.grupo_id === parseInt(record.grupo_pai)
        )
        return <span>{findGrupoPai && findGrupoPai.grupo_nome}</span>
      },
    },
    {
      title: 'Filial',
      dataIndex: 'filial_id',
      className: 'column-filial',
      editable: true,
      inputType: 'select',
      width: '15%',
      sorter: (a, b) => sorterString(a, b, 'filial_nome'),
      sortDirections: ['descend', 'ascend'],
      options: [
        { value: 0, label: 'Nenhum' },
        ...dataFilial.map((filial) => ({
          value: filial.filial_id,
          label: filial.nome,
        })),
      ],
      render: (text, record) => {
        const findFilial = dataFilial.find(
          (filial) => filial.filial_id === parseInt(record.filial_id)
        )
        return <span>{findFilial && findFilial.nome}</span>
      },
    },
    {
      title: 'Opções',
      key: 'extra_actions',
      editable: false,
      className: 'column-opcoes',
      width: '20%',
      align: 'center',
      render: (text, record) => (
        <Col>
          <Divider type="vertical" />
          <Link to={`/times/${record.grupo_id}`}>
            <Tooltip title="Detalhes">
              <ZoomInOutlined />
            </Tooltip>
          </Link>
          {!lider && (
            <>
              <Divider type="vertical" />
              <Tooltip title="Mostrar na Dashboard">
                <a href="javascript:;" aria-label="dashboard">
                  <DashboardOutlined
                    onClick={() => handleNavigateToDashboard(record)}
                  />
                </a>
              </Tooltip>
            </>
          )}
          {!lider && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title="Tem certeza que deseja excluir esse time?"
                onConfirm={() => onTableDelete(record.grupo_id)}
                okText="Sim"
                cancelText="Não"
              >
                <a>
                  <Tooltip title="Excluir" placement="bottom">
                    <DeleteOutlined />
                  </Tooltip>
                </a>
              </Popconfirm>
            </>
          )}
        </Col>
      ),
    },
  ]

  return (
    <>
      <Helmet>
        <title>TeamHub | Times</title>
      </Helmet>
      <ContentLayout
        className="times"
        header={{
          title: 'Times',
          subtitle: 'Gerencie aqui todos os times da sua empresa :)',
          breadcrumb: [{ name: 'Home', path: '/' }, { name: 'Times' }],
        }}
      >
        <div className="times__body">
          <Row>
            <Col md={24}>
              <Card type="inner" className="times__card">
                <div className="times__table">
                  <Table
                    header={() =>
                      !lider && (
                        <div className="times__table__buttons">
                          <Button
                            className="times__table__button btn_purple"
                            onClick={() => openModal(0)}
                          >
                            GERAR PESQUISA
                          </Button>
                          <Button
                            type="primary"
                            className="times__table__button btn_purple"
                            onClick={() => openModal(1)}
                            icon={<UsergroupAddOutlined />}
                          >
                            NOVO
                          </Button>
                        </div>
                      )
                    }
                    dataSource={dataSource.map((grupo) => ({
                      ...grupo,
                      key: grupo.grupo_id,
                    }))}
                    columns={columns}
                    rowSelection={rowSelection}
                    isEditable
                    onSave={handleSaveTime}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        {modals.map(
          ({ title, cancelText, okText, id, width, children: Children }) => (
            <Modal
              visible={showModals[id]}
              title={title}
              cancelText={cancelText}
              okText={okText}
              onCancel={() => closeModal(id)}
              onOk={() => onModalSubmit(id)}
              width={width}
              destroyOnClose
              key={id}
            >
              <Children
                handleChangeModal={(event) => handleChangeModal(id, event)}
                handleChangeCheckbox={(event) =>
                  handleChangeCheckbox(id, event)
                }
                showEnviarFIlhos
                dataSource={dataSource}
                dataFilial={dataFilial}
                error={error}
                {...modalStates[id]}
              />
            </Modal>
          )
        )}
      </ContentLayout>
    </>
  )
}

export default injectIntl(TimesView)
