import React, { createRef, useEffect, useState } from 'react'
import { Button } from 'antd'
import html2canvas from 'html2canvas'
import { LoadingOutlined } from '@ant-design/icons'
import Base64Downloader from 'common-base64-downloader-react'
import Charts from '../Components/Charts'
import ChartsTwo from '../Components/ChartsTwo'
import TimesTable from '../Relatorios/Tables/TimesTableWithouPaginate'
import TableTwo from '../Relatorios/Tables/percentageTable'

const App = (props) => {
  const ref = createRef(null)
  const [base64, setBase] = useState(null)

  function print() {
    setTimeout(() => {
      function generatePDF() {
        html2canvas(ref.current).then(function (canvas) {
          const croppedCanvas = document.createElement('canvas')
          const croppedCanvasContext = croppedCanvas.getContext('2d') // init data

          const cropPositionTop = 0
          const cropPositionLeft = 0
          const cropWidth = canvas.width
          const cropHeight = canvas.height
          croppedCanvas.width = cropWidth
          croppedCanvas.height = cropHeight
          croppedCanvasContext.drawImage(
            canvas,
            cropPositionLeft,
            cropPositionTop
          )
          const base64Image = croppedCanvas.toDataURL('application/pdf', 1.0)
          setBase(base64Image)
        })
      }
      generatePDF()
    }, 1000)
  }

  useEffect(() => {
    print()
  }, [])

  return (
    <div style={{ marginTop: 35 }}>
      <Base64Downloader base64={base64} downloadName="pdfDownload">
        {base64 ? (
          <Button onClick={() => props.close()} type="primary">
            Baixar como PNG
          </Button>
        ) : (
          <LoadingOutlined />
        )}
      </Base64Downloader>
      <div style={{ width: 800 }} ref={ref}>
        <Charts data={props.instalado} />
        <ChartsTwo data={props.grafico_1} />
        <TableTwo dataSource={props.grafico_2} isPrintable />
        <TimesTable dataSource={props.grafico_3} type={props.type} />
      </div>
    </div>
  )
}

export default App
