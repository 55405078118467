import React from 'react'

import moment from 'moment'

import 'moment-timezone'

import { Timeline } from 'antd'

import { Container } from './styles'

function RequisicaoHistory({ history }) {
  return (
    <Timeline mode="left">
      {history.map((h) => {
        return (
          <Timeline.Item
            key={h.id}
            label={moment(h.createdAt)
              .utc()
              .tz(moment.tz.guess())
              .format('DD/MM/YYYY (HH:mm)')}
            color={
              h.status === 0
                ? 'red'
                : h.status === 1
                ? 'var(--purple)'
                : h.status === 2
                ? 'green'
                : 'red'
            }
          >
            <Container>
              <span>{h.title}</span>
              <span>{h.subtitle}</span>
              {h.description && <p>Obs:{h.description}</p>}
            </Container>
          </Timeline.Item>
        )
      })}
    </Timeline>
  )
}

export default RequisicaoHistory
