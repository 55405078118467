import * as constants from '../constants/analiseCulturaGeralConstants'

export const getCulturaOrganizacional = () => {
  return {
    type: constants.GET_ANALISECULTURA_GERAL_CULTURA_ORGANIZACIONAL,
    api: {
      method: 'get',
      url: constants.URL_ANALISECULTURA_GERAL_CULTURA_ORGANIZACIONAL,
    },
  }
}

export const getCulturas = () => {
  return {
    type: constants.GET_ANALISECULTURA_GERAL_CULTURAS,
    api: {
      method: 'get',
      url: constants.URL_ANALISECULTURA_GERAL_CULTURAS,
    },
  }
}

export const getTopArq = () => {
  return {
    type: constants.GET_ANALISECULTURA_GERAL_TOP_ARQ,
    api: {
      method: 'get',
      url: constants.URL_ANALISECULTURA_GERAL_TOP_ARQ,
    },
  }
}

export const getNps = () => {
  return {
    type: constants.GET_DASHBOARD_NPS,
    api: {
      method: 'get',
      url: constants.URL_DASHBOARD_NPS,
    },
  }
}
