import React from 'react'

import {
  Chart,
  Tooltip,
  Coordinate,
  Interval,
  Legend,
  registerShape,
} from 'bizcharts'

import { Empty } from 'antd'

import { sortChartData, colors } from '../../../../utils/utils'

import { cols } from '../../constants'

export default function SlicedPieChart({ data }) {
  const maxDataValue = data
    ? data.reduce((prev, next) => {
        if (next.VALOR > prev) {
          prev = next.VALOR
        }
        return prev
      }, 0)
    : 0

  data = data && data.length > 0 ? sortChartData(data, true) : []

  data.forEach((item) => {
    item.type = item.TEXTO
    item.VALOR = parseFloat(item.VALOR)
  })

  const sliceNumber = 0.01

  registerShape('interval', 'sliceShape', {
    draw(cfg, container) {
      const { points } = cfg
      let path = []
      path.push(['M', points[0].x, points[0].y])
      path.push(['L', points[1].x, points[1].y - sliceNumber])
      path.push(['L', points[2].x, points[2].y - sliceNumber])
      path.push(['L', points[3].x, points[3].y])
      path.push('Z')
      path = this.parsePath(path)
      return container.addShape('path', {
        attrs: {
          fill: cfg.color,
          path,
        },
      })
    },
  })

  return (
    <>
      {data && data.length > 0 && maxDataValue > 0 ? (
        <div style={{ height: 'auto' }}>
          <Chart
            data={data}
            scale={cols}
            height={300}
            padding={[20, 0, 60, 0]}
            renderer="canvas"
            autoFit
          >
            <Coordinate type="theta" innerRadius={0.75} />
            <Tooltip showTitle={false} />
            <Legend position="bottom" />
            <Interval
              adjust="stack"
              position="VALOR"
              color={['type', colors]}
              shape="sliceShape"
              label={[
                'VALOR',
                (valor) => {
                  return `${valor}%`
                },
              ]}
            />
          </Chart>
        </div>
      ) : (
        <Empty />
      )}
    </>
  )
}
