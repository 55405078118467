/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState, useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { Helmet } from 'react-helmet'

import { toast } from 'react-toastify'

import moment from 'moment'

import 'moment-timezone'

import {
  Row,
  Col,
  Typography,
  Card,
  Button,
  Divider,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Modal,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Alert,
  Spin,
} from 'antd'

import {
  EditOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'

import {
  Header,
  AcoesContainer,
  InfoContainer,
  Statistic,
  QuestoesContainer,
  Questao,
  Alternativa,
} from './styles'

import {
  getQuiz,
  updateQuiz,
  updateStatusQuiz,
} from './actions/actionsPainelQuiz'
import { getUsuarios } from '../../ProcessoSeletivo/actions/actionsProcessoSeletivo'

const { Title } = Typography

const { TabPane } = Tabs

const { TextArea } = Input

function PainelQuiz({ match }) {
  const history = useHistory()

  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const [formSuspender] = Form.useForm()

  const [loading, setLoading] = useState(true)

  const [criador, setCriador] = useState(null)
  const [atualizador, setAtualizador] = useState(null)

  const [showModalEditar, setShowModalEditar] = useState(false)

  const [showModalSuspender, setShowModalSuspender] = useState(false)

  const quiz = useSelector((state) => state.quizPainel)

  const loadQuiz = useCallback(
    (quiz_id) => {
      setLoading(true)
      dispatch(getQuiz(quiz_id)).then(() => setLoading(false))
    },
    [dispatch]
  )

  const loadUsuarios = useCallback(() => {
    dispatch(getUsuarios()).then(({ response }) => {
      if (response.status === 200) {
        const usuariosAtivos = response.data.filter(
          (usuario) => usuario.user_ativo === 1 && usuario.pes_id
        )

        const criador = usuariosAtivos.find(
          (usuario) => usuario.user_id === quiz.criado_por
        )

        if (criador) setCriador(criador.colaborador.pes_nome)

        const atualizador = usuariosAtivos.find(
          (usuario) => usuario.user_id === quiz.atualizado_por
        )

        if (atualizador) setAtualizador(atualizador.colaborador.pes_nome)
      }
    })
  }, [quiz.atualizado_por, quiz.criado_por, dispatch])

  useEffect(() => {
    const { quiz_id } = match.params

    loadQuiz(quiz_id)
    loadUsuarios()
  }, [loadQuiz, loadUsuarios, match.params])

  const handleSuspenderQuiz = (quiz_id, values) => {
    const toUpdate = { ...quiz, values }

    dispatch(updateStatusQuiz(quiz_id)).then(({ response }) => {
      if (response.status === 200) {
        dispatch(updateQuiz(quiz_id, values)).then(({ response }) => {
          if (response.status === 200) {
            toast.success('Quiz suspenso com sucesso.')
            loadQuiz(quiz_id)

            setShowModalSuspender(false)
          }
        })
      }
    })
  }

  const handleAtivarQuiz = (quiz_id) => {
    dispatch(updateStatusQuiz(quiz_id)).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Quiz ativado com sucesso.')
        loadQuiz(quiz_id)
      }
    })
  }

  const handleEditarQuiz = (quiz_id, values) => {
    dispatch(updateQuiz(quiz_id, values)).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Quiz atualizado com sucesso.')
        loadQuiz(quiz_id)
        setShowModalEditar(false)
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Teste | Painel</title>
      </Helmet>
      <Row gutter={16}>
        <Col span={24}>
          <Header>
            {loading ? (
              <Spin />
            ) : (
              <>
                <Title level={3}>{quiz.titulo}</Title>
                {quiz.status === 1 ? (
                  <Tag color="green">Ativo</Tag>
                ) : (
                  <Tag color="red">Suspenso</Tag>
                )}
              </>
            )}
          </Header>
        </Col>
        <Col sm={24} md={24} span={24}>
          {loading ? (
            <Card>
              <Spin />
            </Card>
          ) : (
            <Row gutter={16}>
              <Col lg={8}>
                <Card>
                  <AcoesContainer direction="vertical">
                    <Alert
                      closable
                      description="Esse teste ainda não foi utilizado em nenhum processo
                        seletivo, portanto, ainda é possível editar suas informações."
                    />
                    <Button
                      type="secondary"
                      icon={<EditOutlined />}
                      onClick={() => setShowModalEditar(true)}
                    >
                      EDITAR
                    </Button>
                    {quiz.status === 1 ? (
                      <Button
                        type="danger"
                        icon={<PauseCircleOutlined />}
                        onClick={() => setShowModalSuspender(true)}
                      >
                        SUSPENDER
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        icon={<PlayCircleOutlined />}
                        onClick={() => handleAtivarQuiz(quiz.quiz_id)}
                      >
                        ATIVAR
                      </Button>
                    )}
                    <Tooltip
                      placement="bottom"
                      title="Ao suspender um teste esse não aparecerá mais como opção no cadastro de novos Processos Seletivos. Todas as informações desse teste e os resultados dos candidatos para esse teste permanecerão salvos e acessíveis."
                    >
                      <InfoContainer>
                        <QuestionCircleOutlined />
                        <span>O que significa suspender um teste?</span>
                      </InfoContainer>
                    </Tooltip>
                  </AcoesContainer>

                  <Divider />

                  <Space direction="vertical" size="large">
                    <Row>
                      <Space size="large">
                        <Statistic
                          title="Criado em"
                          value={moment(quiz.criado_em)
                            .utc()
                            .tz(moment.tz.guess())
                            .format('DD/MM/YYYY (H:mm)')}
                        />
                        <Statistic title="Criado por" value={criador} />
                      </Space>
                    </Row>
                    <Row>
                      <Space size="large">
                        {quiz.atualizado_em ? (
                          <>
                            <Statistic
                              title="Atualizado em"
                              value={moment(quiz.atualizado_em)
                                .utc()
                                .tz(moment.tz.guess())
                                .format('DD/MM/YYYY (H:mm)')}
                            />

                            <Statistic
                              title="Atualizado por"
                              value={atualizador}
                            />
                          </>
                        ) : (
                          <Statistic
                            title="Atualizado em"
                            value="Esse teste nunca foi atualizado"
                          />
                        )}
                      </Space>
                    </Row>
                  </Space>

                  <Divider />

                  <Space direction="horizontal" size="large">
                    {/* TODO: Inserir quantidade de processos seletivos em que o teste já foi utilizado */}
                    <Statistic
                      title="Questões"
                      value={quiz.questoes.length}
                      suffix="questões"
                    />
                    {quiz.tempo_limite ? (
                      <Tooltip title="O tempo abaixo é divido igualmente entre cada pergunta do teste.">
                        <Statistic
                          title="Duração total"
                          value={quiz.tempo_limite}
                          suffix="minutos"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Esse teste não apresenta tempo limitado de execução.">
                        <Statistic
                          title="Duração total"
                          value="Indeterminada"
                        />
                      </Tooltip>
                    )}
                    {quiz.exibir_resultado === 1 ? (
                      <Tooltip title="Os candidatos irão ver sua pontuação e situação no processo ao fim do teste.">
                        <Statistic title="Exibir resultado?" value="Sim" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Os candidatos não irão ver sua pontuação e situação no processo ao fim do teste.">
                        <Statistic title="Exibir resultado?" value="Não" />
                      </Tooltip>
                    )}
                  </Space>
                </Card>

                {/* TODO: Listar todos os processos seletivos que se relacionam com esse teste */}
              </Col>

              <Col sm={24} md={24} lg={16}>
                <Card>
                  <Tabs>
                    <TabPane tab="Informações Básicas" key="1">
                      <Col>
                        <Title level={5}>Objetivo do Teste</Title>
                        <span>{quiz.objetivo}</span>

                        <Divider />

                        {quiz.status === 0 && (
                          <>
                            <Title level={5}>Motivo de Suspensão</Title>
                            <span>{quiz.motivo_suspensao}</span>
                            <Divider />
                          </>
                        )}

                        <Title level={5}>Instruções</Title>
                        <span>{quiz.instrucoes}</span>

                        <Divider />

                        <Title level={5}>Mensagem de encerramento</Title>
                        <span>{quiz.msg_encerramento}</span>
                      </Col>
                    </TabPane>
                    <TabPane tab="Questões" key="2">
                      <QuestoesContainer>
                        {quiz.questoes.map((questao, index) => (
                          <Questao key={index}>
                            <p level={5}>Questão {index + 1} </p>
                            <Title level={5}>{questao.questao}</Title>
                            {questao.tipo === 'T' && <Tag>Textual</Tag>}
                            {questao.quizQuestaoAlternativa.map(
                              (alternativa, index) =>
                                alternativa.resposta_certa === 1 ? (
                                  <Alternativa key={index}>
                                    {alternativa.alternativa}
                                    <Tag color="green">Resposta Certa</Tag>
                                  </Alternativa>
                                ) : (
                                  <Alternativa key={index}>
                                    {alternativa.alternativa}
                                  </Alternativa>
                                )
                            )}
                          </Questao>
                        ))}
                      </QuestoesContainer>
                    </TabPane>
                  </Tabs>
                </Card>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      {/* MODAL EDITAR QUIZ */}
      <Modal
        title="EDITAR TESTE"
        visible={showModalEditar}
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => setShowModalEditar(false)}
        key="2"
        okButtonProps={{ form: 'req-teste', key: 'submit', htmlType: 'submit' }}
        onOk={null}
        footer={
          <Space direction="horizontal" size="small">
            <Button onClick={() => setShowModalEditar(false)}>CANCELAR</Button>
            <Button type="primary" onClick={form.submit} form="req-teste">
              SALVAR
            </Button>
          </Space>
        }
        centered
        width="60%"
      >
        <Form
          layout="vertical"
          initialValues={{
            titulo: quiz.titulo,
            instrucoes: quiz.instrucoes,
            objetivo: quiz.objetivo,
            msg_encerramento: quiz.msg_encerramento,
            tempo_limite: quiz.tempo_limite,
            nota_min: quiz.nota_min,
          }}
          id="req-teste"
          onFinish={(values) => handleEditarQuiz(quiz.quiz_id, values)}
          form={form}
        >
          <Col span={24}>
            <Form.Item
              label="Título do teste"
              name="titulo"
              required
              requiredMark
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Objetivo do teste"
              name="objetivo"
              required
              requiredMark
            >
              <TextArea />
            </Form.Item>
            <Form.Item label="Instruções do teste" name="instrucoes">
              <TextArea />
            </Form.Item>
            <Form.Item label="Mensagem de encerramento" name="msg_encerramento">
              <TextArea />
            </Form.Item>
            <Row gutter={20}>
              <Col>
                <Form.Item
                  label="Tempo limite (em minutos)"
                  name="tempo_limite"
                  help="Tempo máximo: 300 minutos"
                  required
                  requiredMark
                >
                  <InputNumber min={1} max={300} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Nota mínima (em porcentagem)" name="nota_min">
                  <InputNumber min={1} max={100} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Form>
      </Modal>

      {/* MODAL SUSPENDER QUIZ */}
      <Modal
        title="SUSPENDER TESTE"
        visible={showModalSuspender}
        cancelText="CANCELAR"
        okText="CONFIRMAR SUSPENSÃO"
        onCancel={() => setShowModalSuspender(false)}
        okButtonProps={{
          form: 'suspender-teste',
          key: 'submit',
          htmlType: 'submit',
        }}
        onOk={null}
        footer={
          <Space direction="horizontal" size="small">
            <Button onClick={() => setShowModalSuspender(false)}>
              CANCELAR
            </Button>
            <Button
              type="primary"
              onClick={formSuspender.submit}
              form="suspender-teste"
            >
              SUSPENDER
            </Button>
          </Space>
        }
        width="60%"
      >
        <Form
          layout="vertical"
          initialValues={{
            titulo: quiz.titulo,
            instrucoes: quiz.instrucoes,
            objetivo: quiz.objetivo,
            msg_encerramento: quiz.msg_encerramento,
            tempo_limite: quiz.tempo_limite,
            nota_min: quiz.nota_min,
            ativo: 0,
            motivo_suspensao: null,
          }}
          id="suspender-teste"
          onFinish={(values) =>
            handleSuspenderQuiz(quiz.quiz_id, {
              titulo: quiz.titulo,
              instrucoes: quiz.instrucoes,
              objetivo: quiz.objetivo,
              msg_encerramento: quiz.msg_encerramento,
              tempo_limite: quiz.tempo_limite,
              nota_min: quiz.nota_min,
              motivo_suspensao: values.motivo_suspensao,
            })
          }
          form={formSuspender}
        >
          <Col span={24}>
            <Form.Item
              label="Motivo de suspensão"
              name="motivo_suspensao"
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira o motivo para a suspensão.',
                },
              ]}
            >
              <TextArea
                placeholder="Insira o motivo para a suspensão desse teste"
                maxLength={250}
                showCount
              />
            </Form.Item>
          </Col>
        </Form>
      </Modal>
    </>
  )
}

export default PainelQuiz
