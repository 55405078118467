import React from 'react'

import { Editor } from '@tinymce/tinymce-react'

import { tinyApiKey, tinyDefaultConfig } from '../../config/tinyMCE'

function TinyEditor({
  value,
  init = {},
  onEditorChange,
  outputFormat = 'html',
  disabled = false,
  initialValue = '',
  ...rest
}) {
  return (
    <Editor
      // {...(value ? { value } : {})}
      initialValue={`<p>${
        initialValue == undefined || initialValue == null ? '' : initialValue
      }</p>`}
      init={tinyDefaultConfig}
      onEditorChange={onEditorChange}
      apiKey={tinyApiKey}
      outputFormat={outputFormat}
      disabled={disabled}
      {...rest}
    />
  )
}

export default TinyEditor
