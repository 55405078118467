import React, { Component } from 'react'
import qs from 'qs'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

// Components
import PerfilComportamentalDetalhesView from './PerfilComportamentalDetalhesView'

class PerfilComportamentalDetalhesController extends Component {
  state = {
    loadingPDF: false,
    nome: '',
    donutText: { item: 'Monocrônico', value: 41 },
    essencia: {},
    exigido: {},
    entrega: {},
    principal: 'entrega',
    dataPComp: [],
    intensidade: [
      {
        row: '',
        essencia: '',
        exigido: '',
        entrega: '',
        key: '1',
      },
    ],
    dataDISC: [],
    rct: [],
    defaultRct: {
      nome: '',
      score: '',
    },
    edl: [],
    defaultEdl: {
      nome: '',
      score: '',
    },
    foc: [],
    defaultFoc: {
      nome: '',
      score: '',
    },
    ode: {
      maior: {
        nome: '',
        score: '',
      },
      menor: {
        nome: '',
        score: '',
      },
    },
    oct: {
      maior: {
        nome: '',
        score: '',
      },
      menor: {
        nome: '',
        score: '',
      },
    },
    tdd: {
      maior: {
        nome: '',
        score: '',
      },
      menor: {
        nome: '',
        score: '',
      },
    },
    cor: [],
    erc: [],
    edc: [],
  }

  componentDidMount() {
    const { getPessoa, getDadosVetor } = this.props
    const { id } = this.props.match.params
    const { avaliaId } = this.props.match.params
    getPessoa(id).then(({ response }) => {
      if (response.status === 200) {
        const name = response.data.pessoa.pes_nome
          ? response.data.pessoa.pes_nome
          : ''
        this.setState({ nome: name })
      }
    })
    getDadosVetor(id, avaliaId || '').then(({ response }) => {
      if (response.status === 200) {
        const { entrega, essencia, exigido, principal } = response.data.data
        this.setState({
          entrega,
          essencia,
          exigido,
          principal,
          dataPComp: [
            {
              item: 'Conformidade',
              score: Number(response.data.data[principal].CPOS),
              type: 'Positivo',
            },
            {
              item: 'Conformidade',
              score: Number(response.data.data[principal].CNEG),
              type: 'Negativo',
            },
            {
              item: 'Estabilidade',
              score: Number(response.data.data[principal].SPOS),
              type: 'Positivo',
            },
            {
              item: 'Estabilidade',
              score: Number(response.data.data[principal].SNEG),
              type: 'Negativo',
            },
            {
              item: 'Influência',
              score: Number(response.data.data[principal].IPOS),
              type: 'Positivo',
            },
            {
              item: 'Influência',
              score: Number(response.data.data[principal].INEG),
              type: 'Negativo',
            },
            {
              item: 'Dominância',
              score: Number(response.data.data[principal].DPOS),
              type: 'Positivo',
            },
            {
              item: 'Dominância',
              score: Number(response.data.data[principal].DNEG),
              type: 'Negativo',
            },
          ],
          intensidade: [
            {
              row: 'D',
              key: 'D',
              essencia: Number(essencia.intensidade.D),
              exigido: Number(exigido.intensidade.D),
              entrega: Number(entrega.intensidade.D),
            },
            {
              row: 'I',
              key: 'I',
              essencia: Number(essencia.intensidade.I),
              exigido: Number(exigido.intensidade.I),
              entrega: Number(entrega.intensidade.I),
            },
            {
              row: 'S',
              key: 'S',
              essencia: Number(essencia.intensidade.S),
              exigido: Number(exigido.intensidade.S),
              entrega: Number(entrega.intensidade.S),
            },
            {
              row: 'C',
              key: 'C',
              essencia: Number(essencia.intensidade.C),
              exigido: Number(exigido.intensidade.C),
              entrega: Number(entrega.intensidade.C),
            },
          ],
          dataDISC: [
            {
              item: 'Dominância',
              score: Number(essencia.intensidade.D),
              type: 'Essência',
            },
            {
              item: 'Dominância',
              score: Number(exigido.intensidade.D),
              type: 'Exigido',
            },
            {
              item: 'Dominância',
              score: Number(entrega.intensidade.D),
              type: 'Entrega',
            },
            {
              item: 'Influência',
              score: Number(essencia.intensidade.I),
              type: 'Essência',
            },
            {
              item: 'Influência',
              score: Number(exigido.intensidade.I),
              type: 'Exigido',
            },
            {
              item: 'Influência',
              score: Number(entrega.intensidade.I),
              type: 'Entrega',
            },
            {
              item: 'Estabilidade',
              score: Number(essencia.intensidade.S),
              type: 'Essência',
            },
            {
              item: 'Estabilidade',
              score: Number(exigido.intensidade.S),
              type: 'Exigido',
            },
            {
              item: 'Estabilidade',
              score: Number(entrega.intensidade.S),
              type: 'Entrega',
            },
            {
              item: 'Conformidade',
              score: Number(essencia.intensidade.C),
              type: 'Essência',
            },
            {
              item: 'Conformidade',
              score: Number(exigido.intensidade.C),
              type: 'Exigido',
            },
            {
              item: 'Conformidade',
              score: Number(entrega.intensidade.C),
              type: 'Entrega',
            },
          ],
          rct: [
            {
              nome: 'Monocrônico',
              score: Number(response.data.data[principal].rct.rctMonocronico),
            },
            {
              nome: 'Policrônico',
              score: Number(response.data.data[principal].rct.rctPolicronico),
            },
          ],
          defaultRct: {
            nome: response.data.data[principal].rct.nome,
            score: Number(
              response.data.data[principal].rct.nome === 'Policrônico'
                ? response.data.data[principal].rct.rctPolicronico
                : response.data.data[principal].rct.rctMonocronico
            ),
          },
          edl: [
            {
              nome: 'Autocrático',
              score: Number(response.data.data[principal].edl.edlAutocratico),
            },
            {
              nome: 'Democrático',
              score: Number(response.data.data[principal].edl.edlDemocratico),
            },
          ],
          defaultEdl: {
            nome: response.data.data[principal].edl.nome,
            score: Number(
              response.data.data[principal].edl.nome === 'Autocrático'
                ? response.data.data[principal].edl.edlAutocratico
                : response.data.data[principal].edl.edlDemocratico
            ),
          },
          foc: [
            {
              nome: 'Processos',
              score: Number(response.data.data[principal].foc.focProcessos),
            },
            {
              nome: 'Resultados',
              score: Number(response.data.data[principal].foc.focResultados),
            },
          ],
          defaultFoc: {
            nome: response.data.data[principal].foc.nome,
            score: Number(
              response.data.data[principal].foc.nome === 'Processos'
                ? response.data.data[principal].foc.focProcessos
                : response.data.data[principal].foc.focResultados
            ),
          },
          ode:
            response.data.data[principal].ode.nome === 'Extroversão'
              ? {
                  maior: {
                    nome: 'Extroversão',
                    score: Number(
                      response.data.data[principal].ode.odeExtroversao
                    ),
                  },
                  menor: {
                    nome: 'Introversão',
                    score: Number(
                      response.data.data[principal].ode.odeIntroversao
                    ),
                  },
                }
              : {
                  maior: {
                    nome: 'Introversão',
                    score: Number(
                      response.data.data[principal].ode.odeIntroversao
                    ),
                  },
                  menor: {
                    nome: 'Extroversão',
                    score: Number(
                      response.data.data[principal].ode.odeExtroversao
                    ),
                  },
                },
          oct:
            response.data.data[principal].oct.nome === 'Tarefas'
              ? {
                  maior: {
                    nome: 'Tarefas',
                    score: Number(response.data.data[principal].oct.octTarefas),
                  },
                  menor: {
                    nome: 'Relacionamentos',
                    score: Number(
                      response.data.data[principal].oct.octRelacionamentos
                    ),
                  },
                }
              : {
                  maior: {
                    nome: 'Relacionamentos',
                    score: Number(
                      response.data.data[principal].oct.octRelacionamentos
                    ),
                  },
                  menor: {
                    nome: 'Tarefas',
                    score: Number(response.data.data[principal].oct.octTarefas),
                  },
                },
          tdd:
            response.data.data[principal].tdd.nome === 'Racional'
              ? {
                  maior: {
                    nome: 'Racional',
                    score: Number(
                      response.data.data[principal].tdd.tddRaciocinio
                    ),
                  },
                  menor: {
                    nome: 'Emocional',
                    score: Number(
                      response.data.data[principal].tdd.tddSentimentos
                    ),
                  },
                }
              : {
                  maior: {
                    nome: 'Emocional',
                    score: Number(
                      response.data.data[principal].tdd.tddSentimentos
                    ),
                  },
                  menor: {
                    nome: 'Racional',
                    score: Number(
                      response.data.data[principal].tdd.tddRaciocinio
                    ),
                  },
                },
          cor: [
            {
              nome: 'Cliente',
              selected: response.data.data[principal].cor.nome === 'Cliente',
            },
            {
              nome: 'Ação',
              selected: response.data.data[principal].cor.nome === 'Ação',
            },
            {
              nome: 'Pessoa',
              selected: response.data.data[principal].cor.nome === 'Pessoa',
            },
            {
              nome: 'Qualidade',
              selected: response.data.data[principal].cor.nome === 'Qualidade',
            },
          ],
          erc: [
            {
              nome: 'Cede',
              selected: response.data.data[principal].erc.nome === 'Cede',
            },
            {
              nome: 'Negocia',
              selected: response.data.data[principal].erc.nome === 'Negocia',
            },
            {
              nome: 'Impõe',
              selected: response.data.data[principal].erc.nome === 'Impõe',
            },
            {
              nome: 'Evita',
              selected: response.data.data[principal].erc.nome === 'Evita',
            },
          ],
          edc: [
            {
              nome: 'Emocional',
              selected: response.data.data[principal].edc.nome === 'Emocional',
            },
            {
              nome: 'Racional',
              selected: response.data.data[principal].edc.nome === 'Racional',
            },
            {
              nome: 'Objetiva',
              selected: response.data.data[principal].edc.nome === 'Objetiva',
            },
            {
              nome: 'Instrutiva',
              selected: response.data.data[principal].edc.nome === 'Instrutiva',
            },
          ],
        })
      }
    })
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  printDocument = () => {
    const page1 = document.getElementById('divToPrint1')
    const pdf = new jsPDF()

    this.setState({ loadingPDF: true })
    html2canvas(page1, {
      windowWidth: 1240,
      windowHeight: 1754,
      width: 1240,
      height: 1754,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297, null, 'FAST')

      const page2 = document.getElementById('divToPrint2')
      html2canvas(page2, {
        windowWidth: 1240,
        windowHeight: 1754,
        width: 1240,
        height: 1754,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        pdf.addPage()
        pdf.fromHTML()
        pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297, null, 'FAST')
        // pdf.output('dataurlnewwindow');
        pdf.save('perfil-comportamental.pdf')
        this.setState({ loadingPDF: false })
      })
    })
  }

  render() {
    return (
      <PerfilComportamentalDetalhesView
        handleChange={this.handleChange}
        printDocument={this.printDocument}
        {...this.state}
      />
    )
  }
}

export default PerfilComportamentalDetalhesController
