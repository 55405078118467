import * as constants from '../constants/constants'

export const loadFilial = () => {
  return {
    type: constants.PS_FILIAL_LOAD,
  }
}

export const listFiliais = () => {
  return {
    type: constants.PS_FILIAL_LIST,
    api: {
      method: 'get',
      url: constants.URL_PS_FILIAL,
    },
  }
}

export const storeFilial = ({ nome, descricao }) => {
  return {
    type: constants.PS_FILIAL_STORE,
    api: {
      method: 'post',
      url: constants.URL_PS_FILIAL,
      data: { nome, descricao },
    },
  }
}

export const updateFilial = ({ filial_id, nome, descricao }) => {
  return {
    type: constants.PS_FILIAL_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_PS_FILIAL_ID(filial_id),
      data: { nome, descricao },
    },
  }
}

export const deleteFilial = ({ filial_id }) => {
  return {
    type: constants.PS_FILIAL_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_PS_FILIAL_ID(filial_id),
    },
  }
}
