import React from 'react'

// Components
import EnviarPesquisa from '../../../components/enviarPesquisa/EnviarPesquisa'
import AdicionarNovoCargo from '../components/adicionarNovoCargo/AdicionarNovoCargo'

// URLs
export const URL_CARGOS = `${process.env.REACT_APP_API_URL}/cargo`
export const URL_CARGOS_GET = `${URL_CARGOS}/list`
export const URL_CARGO_POST = `${URL_CARGOS}/store`
export const URL_CARGOS_PUT = `${URL_CARGOS}/update/`
export const URL_CARGO_DELETE = `${URL_CARGOS}/delete/`
export const URL_CARGOS_DELETE = `${URL_CARGOS}/delCargos`
export const URL_CARGOS_SEND_AVALIACOES = `${process.env.REACT_APP_API_URL}/avaliacoes/send/cargo/`
export const URL_SEND_PERCEPCAO = `${process.env.REACT_APP_API_URL}/pesquisa/cargo`
export const URL_CARGOS_SEND_VALORES = (id) =>
  `${process.env.REACT_APP_API_URL}/pesqvalores/sendlote/cargo/${id}`

export const URL_ATIVIDADE_POST = (id) => `${URL_CARGOS}/${id}/atividades`

export const URL_FERRAMENTA_POST = (id) => `${URL_CARGOS}/${id}/ferramentas`

export const URL_REQUISITO_POST = (id) => `${URL_CARGOS}/${id}/formacoes`

export const URL_TECNICA_POST = (id) => `${URL_CARGOS}/${id}/tecnicas`

export const URL_FREQUENCIA_CARGO = `/cargo/atividades/frequencias`

export const URL_FERRAMENTA_CARGO = `/cargo/ferramentas/tipos`

export const URL_NIVEL_FORMACAO_CARGO = `/cargo/formacoes/niveis`

// Redux types
export const CARGOS_GET = 'CARGOS_GET'
export const CARGO_POST = 'CARGO_POST'
export const CARGOS_PUT = 'CARGOS_PUT'
export const CARGO_DELETE = 'CARGO_DELETE'
export const CARGOS_DELETE = 'CARGOS_DELETE'
export const CARGOS_SEND_AVALIACOES = 'CARGOS_SEND_AVALIACOES'
export const SEND_PERCEPCAO = 'SEND_PERCEPCAO'
export const CARGOS_SEND_VALORES = 'CARGOS_SEND_VALORES'
export const FREQUENCIA_CARGO = 'FREQUENCIA_CARGO'
export const FERRAMENTA_CARGO = 'FERRAMENTA_CARGO'
export const ATIVIDADE_POST = 'ATIVIDADE_POST'
export const FERRAMENTA_POST = 'FERRAMENTA_POST'
export const REQUISITO_POST = 'REQUISITO_POST'
export const TECNICA_POST = 'TECNICA_POST'
export const NIVEL_FORMACAO = 'NIVEL_FORMACAO'

// Modals
export const modals = [
  {
    id: 0,
    title: 'GERAR PESQUISA PARA OS TIMES SELECIONADOS',
    cancelText: 'CANCELAR',
    okText: 'ENVIAR',
    children: EnviarPesquisa,
    initialState: {
      vetorComportamental: false,
      percepcaoAmbiente: false,
      enviarEmails: false,
      valoresOrganizacionais: false,
    },
  },
]

// Data processing
export const apiToFront = ({ cargo }) => ({
  nome: cargo.cargo_nome || '',
  cbo: cargo.cargo_cbo,
  descricao: cargo.cargo_descricao,
  piso: cargo.cargo_piso,
  ativo: cargo.cargo_ativo,
  datacriacao: cargo.cargo_datacriacao,
  dataexclusao: cargo.cargo_dataexclusao,
  id: cargo.cargo_id,
  lotacao: cargo.lotacao,
  proposito: cargo.proposito,
})
