import React from 'react'
import { Table, Divider, Button, Badge, Popconfirm } from 'antd'
import moment from 'moment'

// Utils
import { sorterString, sorterDate } from '../../../utils/utils'

function TabTimesCargos({
  handleChangeLider,
  id,
  openModal,
  times,
  cargos,
  getColumnSearchProps,
  deleteCargoCandidato,
  deleteTimePessoa,
  isCandidato,
}) {
  const columnsCargos = [
    // {
    // 	title: 'Status',
    // 	dataIndex: 'status',
    // 	align: 'center',
    // 	sorter: (a, b) => a.status - b.status,
    // 	sortDirections: ['descend', 'ascend'],
    // 	render: text => <Badge status={text === '0' ? 'error' : 'success'} />,
    // },
    {
      title: 'Cargo',
      dataIndex: 'cargo_nome',
      sorter: (a, b) => sorterString(a, b, 'cargo_nome'),
      sortDirections: ['descend', 'ascend'],
      align: 'center',
      ...getColumnSearchProps('cargo_nome', 'cargo'),
    },
    {
      title: 'Data de candidatura',
      dataIndex: 'cargopes_dataentrada',
      align: 'center',
      sorter: (a, b) => sorterDate(a, b, 'cargopes_dataentrada', 'DD/MM/YYYY'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => text || '',
      ...getColumnSearchProps('cargopes_dataentrada', 'data entrada'),
    },
    // {
    // 	title: 'Data de Saida',
    // 	dataIndex: 'cargopes_datasaida',
    // 	align: 'center',
    // 	sorter: (a, b) => sorterDate(a, b, 'cargopes_datasaida', 'DD/MM/YYYY'),
    // 	sortDirections: ['descend', 'ascend'],
    // 	render: text => text || '',
    // 	...getColumnSearchProps('cargopes_datasaida', 'data saída'),
    // },
    {
      title: 'Ações',
      key: 'acoes',
      align: 'center',
      render: (text, record) => (
        <span>
          {/* <Popconfirm
						title='Tem certeza que deseja remover do cargo?'
						onConfirm={() => deleteCargoCandidato(record.cargo_id, id)}
						okText='Sim'
						cancelText='Não'
					>
						<a href='javascript:;'>Excluir</a>
					</Popconfirm>
					<Divider type='vertical' />
					<a href='javascript:;'>Desativar</a> */}
          {parseInt(record.status) !== 0 ? (
            <Popconfirm
              title="Tem certeza que deseja remover do cargo?"
              onConfirm={() => deleteCargoCandidato(record.cargo_id, id)}
              okText="Sim"
              cancelText="Não"
            >
              <a href="javascript:;">Remover</a>
            </Popconfirm>
          ) : (
            <></>
          )}
        </span>
      ),
    },
  ]
  return (
    <div className="perfil__tabs__times-cargos">
      <div className="perfil__tabs__times-cargos__table__title">
        <h3>Cargo</h3>

        <Button type="primary" onClick={() => openModal('alocarCargo')}>
          Vincular a um Cargo
        </Button>
      </div>
      <Table bordered columns={columnsCargos} dataSource={cargos} />
    </div>
  )
}

export default TabTimesCargos
