import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'

// Components
import PercepcaoAmbienteView from './PercepcaoAmbienteView'

// CSS

// Constants

class PercepcaoAmbienteController extends Component {
  state = {
    currentStep: 0,
    listaRespostas: {
      questoes: [],
    },
    showModal: false,
    pesquisasPendentes: [],
    conta_url: undefined,
    showIntro: true,
    countChecked: 1,
  }

  componentDidMount = () => {
    const { getQuestoesPesquisas, loadPercepcao, match, history } = this.props

    loadPercepcao(match.params.id).then(({ response }) => {
      if (response.status !== 200) {
        history.push('/')
        return
      }
      const { pesquisasPendentes, conta_url } = response.data
      this.setState({ pesquisasPendentes: pesquisasPendentes || [], conta_url })
    })

    getQuestoesPesquisas(2).then(({ response }) => {
      this.setState({
        listaRespostas: {
          questoes: [
            ...response.data.questoes,
            ...response.data.questoesGrade,
          ].map((questao) =>
            questao.tipo === 'escala'
              ? {
                  id: questao.idquestao,
                  alternativas: questao.alternativas.map((alt) => ({
                    key: `${alt.idalternativa}`,
                    title: alt.texto,
                  })),
                }
              : questao.tipo === 'caixa_selecao'
              ? { id: questao.idquestao, alternativas: {} }
              : { id: questao.idquestao, valor: 1 }
          ),
        },
      })
    })
  }

  nextStep = () =>
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }))

  prevStep = () =>
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }))

  handleChangeQuestion = ({ tipo, data, idQuestao }) => {
    const { listaRespostas } = this.state
    switch (tipo) {
      case 'escala': {
        listaRespostas.questoes[
          _.findIndex(listaRespostas.questoes, ['id', idQuestao])
        ].alternativas = data
        break
      }
      case 'caixa_selecao': {
        listaRespostas.questoes[
          _.findIndex(listaRespostas.questoes, ['id', idQuestao])
        ].alternativas = {
          ...listaRespostas.questoes[
            _.findIndex(listaRespostas.questoes, ['id', idQuestao])
          ].alternativas,
          [data.idAlternativa]: data.checked,
        }

        break
      }
      case 'multipla_escolha':
      case 'grade': {
        listaRespostas.questoes[
          _.findIndex(listaRespostas.questoes, ['id', idQuestao])
        ].valor = data.value

        break
      }
      default:
        break
    }

    this.setState((prevState) => ({
      listaRespostas: {
        ...prevState.listaRespostas,
        questoes: listaRespostas.questoes,
      },
    }))
  }

  reorder = (list, questionIndex, startIndex, endIndex) => {
    list.questoes.forEach((questao) => {
      if (questao.id === questionIndex) {
        const result = Array.from(questao.alternativas)

        const [removed] = result.splice(startIndex, 1)

        result.splice(endIndex, 0, removed)

        questao.alternativas = result
      }
    })

    return list
  }

  onDragEnd = (result, index) => {
    const { listaRespostas } = this.state

    if (!result.destination) {
      return
    }

    const listaReordenada = this.reorder(
      listaRespostas,
      index,
      result.source.index,
      result.destination.index
    )

    this.setState((prevState) => ({
      listaRespostas: {
        ...prevState.listaRespostas,
        questoes: listaReordenada.questoes,
      },
    }))
  }

  onChangeTreeOrder = (info, data, idQuestao) => {
    const dragKey = info.dragNode.props.eventKey
    const dropKey = info.node.props.eventKey
    const dropPos = info.node.props.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          return callback(item, index, arr)
        }
      })
    }

    // Find dragObject
    let dragObj

    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    let ar
    let i

    loop(data, dropKey, (item, index, arr) => {
      ar = arr
      i = index
    })

    if (dropPosition === -1) {
      ar.splice(i, 0, dragObj)
    } else {
      ar.splice(i + 1, 0, dragObj)
    }

    this.handleChangeQuestion({ tipo: 'escala', data, idQuestao })
  }

  onChangeCheckbox = ({ target: { checked } }, idQuestao, idAlternativa) => {
    const { countChecked } = this.state
    if (checked === false) {
      this.setState({
        countChecked: countChecked - 1,
      })
      this.handleChangeQuestion({
        tipo: 'caixa_selecao',
        data: { idAlternativa, checked },
        idQuestao,
      })
    } else if (countChecked <= 2) {
      this.setState({
        countChecked: countChecked + 1,
      })

      this.handleChangeQuestion({
        tipo: 'caixa_selecao',
        data: { idAlternativa, checked },
        idQuestao,
      })
    }
  }

  onChangeRadio = ({ target: { value } }, idQuestao) => {
    this.handleChangeQuestion({
      tipo: 'multipla_escolha',
      data: { value },
      idQuestao,
    })
  }

  onChangeSlider = (value, idQuestao) => {
    this.handleChangeQuestion({
      tipo: 'grade',
      data: { value },
      idQuestao,
    })
  }

  openModal = () => {
    this.setState({
      showModal: true,
    })
  }

  onSubmit = () => {
    const { postRespostaPesquisas, match } = this.props
    const { listaRespostas } = this.state

    postRespostaPesquisas({
      id: match.params.id,
      tipoPesquisa: 2,
      listaRespostas,
    }).then((payload) => {
      if (payload.response && payload.response.status === 200) {
        this.openModal()
      }
    })
  }

  handleInit = () => {
    this.setState({ showIntro: false })
  }

  render() {
    return (
      <PercepcaoAmbienteView
        {...this.props}
        {...this.state}
        nextStep={this.nextStep}
        prevStep={this.prevStep}
        onDragEnd={this.onDragEnd}
        onChangeTreeOrder={this.onChangeTreeOrder}
        onChangeCheckbox={this.onChangeCheckbox}
        onChangeRadio={this.onChangeRadio}
        onChangeSlider={this.onChangeSlider}
        onSubmit={this.onSubmit}
        handleInit={this.handleInit}
      />
    )
  }
}

export default PercepcaoAmbienteController
