import { Modal } from 'antd'
import { useCallback } from 'react'
import { useFetch } from '../../../../../../../../hooks/use-fetch'
import AddPessoa from '../../../../../../components/AddPessoa'
import '../../../../../../Dashboard.css'

const ModalAdicionarCandidato = ({
  showModalAddCandidato,
  setShowModalAddCandidato,
  handleAddPessoa,
}) => {
  const { isLoading: loadingCandidatos, response: candidatos } = useFetch(
    '/v2/dashboard/candidatos'
  )

  const handleSubmitModalAddCandidato = useCallback(
    ({ pes_id }) => {
      if (!loadingCandidatos && candidatos) {
        const pessoa = candidatos.find((p) => p.pes_id === pes_id)
        handleAddPessoa(pessoa)
        setShowModalAddCandidato(false)
      }
    },
    [loadingCandidatos, candidatos, handleAddPessoa, setShowModalAddCandidato]
  )

  return (
    <Modal
      visible={showModalAddCandidato}
      title="Adicionar Candidato"
      cancelText="CANCELAR"
      okText="SALVAR"
      onCancel={() => setShowModalAddCandidato(false)}
      okButtonProps={{
        form: 'add-pessoa',
        key: 'submit',
        htmlType: 'submit',
      }}
      destroyOnClose
    >
      <AddPessoa
        onFinish={handleSubmitModalAddCandidato}
        listPessoas={candidatos}
        type="candidato"
        loading={loadingCandidatos}
      />
    </Modal>
  )
}

export default ModalAdicionarCandidato
