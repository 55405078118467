import { Layout, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Avaliadores from './subCulturaAvaliadores'
import Objetivos from './objetivosEstrategicos'
import Resumo from './subCulturaResumo'
import Dashboard from '../Dashboard/index'

const App = () => {
  const params = useLocation()
  const cultura = params.state
  const [screen, setScreen] = useState(localStorage.getItem('screen') || '1')

  const HandleGetScreen = () => {
    localStorage.setItem('screen', screen)
    if (screen === '0') return <Resumo cultura={cultura} />
    if (screen === '1')
      return <Dashboard cultura={cultura} subcultura={cultura.subcultura_id} />

    if (screen === '2') return <Objetivos cultura={cultura} />

    if (screen === '3') return <Avaliadores cultura={cultura} />
  }

  return (
    <div>
      <Layout>
        <Menu mode="horizontal" selectedKeys={screen}>
          <Menu.Item onClick={() => setScreen('1')} key="1">
            Consensuar
          </Menu.Item>
          <Menu.Item onClick={() => setScreen('2')} key="2">
            Objetivos Estratégicos
          </Menu.Item>
          <Menu.Item onClick={() => setScreen('3')} key="3">
            Avaliadores
          </Menu.Item>
        </Menu>
        <HandleGetScreen />
      </Layout>
    </div>
  )
}

export default App
