import { connect } from 'react-redux'

// Components
import OnboardingController from './OnboardingController'

// Actions
import * as actionsOnboarding from './actions/actionsOnboarding'

const mapStateToProps = ({ login }) => {
  return { onboarding: login.onboarding }
}

export default connect(mapStateToProps, { ...actionsOnboarding })(
  OnboardingController
)
