import {
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  FormOutlined,
  InfoCircleOutlined,
  PlusSquareOutlined,
  SearchOutlined,
  SendOutlined,
} from '@ant-design/icons'
// Utils
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import moment from 'moment'
import 'moment-timezone'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import Confirm from '../../../../components/Confirm'
import {
  getContaURL,
  getUserId,
  getUserPesId,
  isAdmin,
} from '../../../../services/auth'
import history from '../../../../utils/history'
// actions
import { sorterDate, sorterString } from '../../../../utils/utils'
import { listFiliais } from '../../../ConfigFiliais/actions/actions'
// components
import ProcessoSeletivoForm from '../../../recrutamentoSelecao/components/ProcessoSeletivoForm'
import { listTipoContrato } from '../../../recrutamentoSelecao/containers/PsContrato/actions/actions'
import { listMotivos } from '../../../recrutamentoSelecao/containers/PsMotivo/actions/actions'
import { listSlas } from '../../../recrutamentoSelecao/containers/PsSla/actions/actions'
import { parseSalarioFaixa } from '../../../recrutamentoSelecao/utils'
import {
  getCargos,
  getCidades,
  getUFs,
  getUsuarios,
} from '../../../recrutamentoSelecaoPsAtivos/containers/ProcessoSeletivo/actions/actionsProcessoSeletivo'
import {
  aprovarEtapaRequisicao,
  cancelRequisicao,
  checkRequisitor,
  getEtapas,
  getTimes,
  listRequisicoes,
  reenviarRequisicao,
  reprovarEtapaRequisicao,
  storeRequisicao,
  updateRequisicao,
} from './actions'
import Observacao from './components/Observacao'
import RequisicaoHistory from './components/RequisicaoHistory'
import RequisicaoProcessoForm from './components/RequisicaoProcessoForm'
import {
  AcaoContainer,
  AprovacaoRow,
  Description,
  LinkSpan,
  Steps,
  SubTitle,
  Tabs,
  Title,
  VagaContainer,
} from './styles'

const { Step } = Steps

const { TabPane } = Tabs

function Requisicoes(_, searchInput) {
  const userId = parseInt(getUserId())

  const userPesId = parseInt(getUserPesId())

  const dispatch = useDispatch()

  const location = useLocation()

  const gestor = isAdmin()

  /* Requisições aprovadas */
  const [requisicoesAprovadas, setRequisicoesAprovadas] = useState([])

  /* Requisições em andamento */
  const [requisicoesAndamento, setRequisicoesAndamento] = useState([])

  /* Requisições canceladas */
  const [requisicoesCanceladas, setRequisicoesCanceladas] = useState([])

  const [loadingRequisicoes, setLoadingRequisicoes] = useState(false)

  const [searchText, setSearchText] = useState('')

  // Create e Update Requisicao
  const [confirmNewReqText, setConfirmNewReqText] = useState()

  const [usuarios, setUsuarios] = useState([])

  const [loadingTimes, setLoadingTimes] = useState(false)
  const [times, setTimes] = useState([])

  const [loadingCargos, setloadingCargos] = useState(false)
  const [cargos, setCargos] = useState([])

  const [loadingUfs, setloadingUfs] = useState(false)
  const [ufs, setUfs] = useState([])

  const [loadingCidades, setloadingCidades] = useState(false)
  const [cidades, setCidades] = useState([])

  const [currentProcessoId, setCurrentProcessoId] = useState()
  const [currentEtapaId, setCurrentEtapaId] = useState()

  const [requisicaoCreateData, setRequisicaoCreateData] = useState()
  const [requisicaoUpdateData, setRequisicaoUpdateData] = useState()

  // Aprovacao e Reprovacao
  const [observacao, setObservacao] = useState()

  // modals
  const [showModalNewReq, setShowModalNewReq] = useState(false)

  const [showModalDetalhesReq, setShowModalDetalhesReq] = useState(false)

  const [showModalUpdateReq, setShowModalUpdateReq] = useState(false)

  const [showModalAprovarReq, setShowModalAprovarReq] = useState(false)

  const [showModalReprovarReq, setShowModalReprovarReq] = useState(false)

  const [showModalConfirmNewReq, setShowModalConfirmNewReq] = useState(false)

  const [showModalReenvioReq, setShowModalReenvioReq] = useState(false)

  const [motivos, setMotivos] = useState([])
  const [contratos, setContratos] = useState([])
  const [filiais, setFiliais] = useState([])
  const [slas, setSlas] = useState([])

  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  useEffect(() => {
    if (location && location.search) {
      const queries = queryString.parse(location.search)

      if (queries.row) {
        setExpandedRowKeys([Number(queries.row)])
      }
    }
  }, [location])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [expandedRowKeys])

  const loadRequisicoes = useCallback(() => {
    setLoadingRequisicoes(true)
    dispatch(listRequisicoes()).then(({ response }) => {
      if (response.status === 200) {
        const { requisicoes: requisicoesData } = response.data
        setRequisicoesAprovadas(
          requisicoesData.filter(
            (requisicao) =>
              requisicao.finalizadoem === null &&
              requisicao.cancelado === 0 &&
              requisicao.requisicao === '1' &&
              requisicao.aprovado === 1
          )
        )
        setRequisicoesAndamento(
          requisicoesData.filter(
            (requisicao) =>
              requisicao.finalizadoem === null &&
              requisicao.cancelado === 0 &&
              requisicao.requisicao === '1' &&
              requisicao.aprovado === 0
          )
        )
        setRequisicoesCanceladas(
          requisicoesData.filter(
            (requisicao) =>
              requisicao.finalizadoem !== null &&
              requisicao.cancelado === 1 &&
              requisicao.requisicao === '1'
          )
        )
      }
      setLoadingRequisicoes(false)
    })
  }, [dispatch])

  const loadContratos = useCallback(() => {
    dispatch(listTipoContrato()).then(({ response }) => {
      if (response.status === 200) {
        setContratos(response.data.filter((contrato) => contrato.ativo === 1))
      }
    })
  }, [dispatch])

  const loadFiliais = useCallback(() => {
    dispatch(listFiliais()).then(({ response }) => {
      if (response.status === 200) {
        setFiliais(response.data.filter((filial) => filial.ativo === 1))
      }
    })
  }, [dispatch])

  const loadUsuarios = useCallback(() => {
    dispatch(getUsuarios()).then(({ response }) => {
      if (response.status === 200) {
        setUsuarios(
          response.data.filter(
            (usuario) => usuario.user_ativo === 1 && usuario.pes_id
          )
        )
      }
    })
  }, [dispatch])

  const loadMotivos = useCallback(() => {
    dispatch(listMotivos()).then(({ response }) => {
      if (response.status === 200) {
        setMotivos(response.data.filter((motivo) => motivo.ativo === 1))
      }
    })
  }, [dispatch])

  const handleAprovarEtapa = useCallback(() => {
    dispatch(
      aprovarEtapaRequisicao({
        processo_id: currentProcessoId,
        etapa_id: currentEtapaId,
        observacao,
      })
    ).then(({ response }) => {
      if (response.status === 204) {
        loadRequisicoes()
        setObservacao(undefined)
        setCurrentEtapaId(undefined)
        setCurrentProcessoId(undefined)
        setShowModalAprovarReq(false)
        toast.success('Requisição aprovada.')
      }
    })
  }, [currentEtapaId, currentProcessoId, dispatch, loadRequisicoes, observacao])

  const handleReprovarEtapa = useCallback(() => {
    dispatch(
      reprovarEtapaRequisicao({
        processo_id: currentProcessoId,
        etapa_id: currentEtapaId,
        observacao,
      })
    ).then(({ response }) => {
      if (response.status === 204) {
        loadRequisicoes()
        setObservacao(undefined)
        setCurrentEtapaId(undefined)
        setCurrentProcessoId(undefined)
        setShowModalReprovarReq(false)
        toast.success('Requisição reprovada.')
      }
    })
  }, [currentEtapaId, currentProcessoId, dispatch, loadRequisicoes, observacao])

  const loadUfs = useCallback(() => {
    setloadingUfs(true)
    dispatch(getUFs()).then(({ response }) => {
      if (response.status === 200) {
        const { ufs: ufsData } = response.data
        setUfs(ufsData)
      }
      setloadingUfs(false)
    })
  }, [dispatch])

  const loadCidades = useCallback(
    (UFID) => {
      setloadingCidades(true)
      dispatch(getCidades(UFID)).then(({ response }) => {
        if (response.status === 200) {
          const { cidades: cidadesData } = response.data
          setCidades(cidadesData)
        } else {
          setCidades([])
        }
        setloadingCidades(false)
      })
    },
    [dispatch]
  )

  const loadTimes = useCallback(() => {
    setLoadingTimes(true)
    dispatch(getTimes()).then(({ response }) => {
      if (response.status === 200) {
        const { grupos: timesData } = response.data
        setTimes(timesData)
      }
      setLoadingTimes(false)
    })
  }, [dispatch])

  const loadCargos = useCallback(() => {
    setloadingCargos(true)
    dispatch(getCargos()).then(({ response }) => {
      if (response.status === 200) {
        const { cargos: cargosData } = response.data
        setCargos(cargosData)
      }
      setloadingCargos(false)
    })
  }, [dispatch])

  const loadSlas = useCallback(() => {
    dispatch(listSlas()).then(({ response }) => {
      if (response.status === 200) {
        setSlas(response.data.filter((sla) => sla.ativo === 1))
      }
    })
  }, [dispatch])

  useEffect(() => {
    loadFiliais()
    loadRequisicoes()
    loadUfs()
    loadCargos()
    loadUsuarios()
    loadTimes()
    loadContratos()
    loadMotivos()
    loadSlas()
  }, [
    loadCargos,
    loadContratos,
    loadRequisicoes,
    loadTimes,
    loadUsuarios,
    loadFiliais,
    loadUfs,
    loadMotivos,
    loadSlas,
  ])

  useEffect(() => {
    if (requisicaoUpdateData && requisicaoUpdateData.ufid) {
      loadCidades(requisicaoUpdateData.ufid)
    }
  }, [loadCidades, requisicaoUpdateData])

  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    setSearchText(selectedKeys[0])
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder="Buscar"
          hunter
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  const columns = [
    {
      title: 'Número',
      dataIndex: 'numero',
      sorter: (a, b) => sorterString(a, b, 'numero'),
      render: (text, record) => {
        if (text && text !== null) {
          return <span>{text.padStart(5, '0')}</span>
        }
      },
    },
    /* {
      title: "Status",
      dataIndex: ["cargo", "cargo_nome"],
      center: true,
      align: "center",
      render: (text, record) => (
        <Badge
          status={
            record.reprovado || record.cancelado
              ? "error"
              : record.aprovado
              ? "success"
              : record.prazo
              ? record.prazo.toString().toUpperCase().includes("ATRASADO")
                ? "warning"
                : "processing"
              : ""
          }
        />
      ),
    }, */
    {
      title: 'Vaga',
      dataIndex: ['cargo', 'cargo_nome'],
      center: true,
      width: 300,
      render: (text, record) => (
        <VagaContainer>
          <div>
            <p>{text}</p>
            <span>
              {record.homeoffice
                ? '(HomeOffice)'
                : record.cidade && record.cidade.CIDADE
                ? record.cidade.uf && record.cidade.uf.UF
                  ? `${record.cidade.CIDADE.trim()} / ${record.cidade.uf.UF.trim()}`
                  : record.cidade.CIDADE.trim()
                : ''}
            </span>
          </div>
        </VagaContainer>
      ),
      sorter: (a, b) => sorterString(a, b, ['cargo', 'cargo_nome']),
      ...getColumnSearchProps('cidade'),
      onFilter: (value, record) => {
        return (
          (record.cargo &&
            record.cargo_nome
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())) ||
          (record.cidade &&
            record.cidade.CIDADE.toString()
              .toLowerCase()
              .includes(value.toLowerCase()))
        )
      },
    },
    {
      title: 'Quantidade',
      dataIndex: 'qtde',
      width: '5%',
      center: true,
      align: 'center',
      sorter: (a, b) => a.qtde - b.qtde,
    },
    {
      title: 'Solicitado por',
      dataIndex: ['creator', 'pes_nome'],
      width: '20%',
      center: true,
      sorter: (a, b) => sorterString(a, b, ['creator', 'pes_nome']),
      render: (text, record) => (
        <>
          <span>{text}</span>
          {record.grupo && (
            <span style={{ display: 'block' }}>
              ({record.grupo.grupo_nome})
            </span>
          )}
        </>
      ),
    },
    {
      title: 'Responsável',
      dataIndex: ['responsible', 'pes_nome'],
      width: '20%',
      center: true,
      sorter: (a, b) => sorterString(a, b, ['responsible', 'pes_nome']),
    },
    {
      title: 'Início',
      dataIndex: 'criadoem',
      width: '10%',
      center: true,
      render: (text, record) =>
        text
          ? moment(text)
              .utc()
              .tz(moment.tz.guess())
              .format('DD/MM/YYYY (HH:mm)')
          : '',
      sorter: (a, b) => sorterDate(a, b, 'criadoem', 'DD/MM/YYYY'),
    },
    {
      title: 'Duração',
      dataIndex: 'duracao',
      width: '10%',
      center: true,
      render: (text, record) => `${text} dias`,
      sorter: (a, b) => a.duracao - b.duracao,
    },
    {
      title: 'Prazo',
      dataIndex: 'prazo',
      width: '10%',
      center: true,
      render: (text, record) => {
        return record.aprovado
          ? `(APROVADO) ${record.prazo ? record.prazo : ''}`
          : record.cancelado
          ? `CANCELADO`
          : record.prazo
      },
    },
    {
      title: 'Ações',
      key: 'acoes',
      width: '25%',
      align: 'center',
      render: (text, record) => {
        return (
          <AcaoContainer>
            <Tooltip title="Detalhes">
              <LinkSpan
                onClick={() => {
                  setCurrentProcessoId(parseInt(record.processo_id))
                  setRequisicaoUpdateData({
                    ...record,
                    concluirem: moment(record.concluirem),
                    time: record.grupo_id,
                  })
                  setShowModalDetalhesReq(true)
                }}
              >
                <PlusSquareOutlined />
              </LinkSpan>
            </Tooltip>
            {!record.cancelado && !record.aprovado && (
              <>
                {parseInt(record.criadopor) === userPesId &&
                  !record.cancelado && (
                    <>
                      <Divider type="vertical" />
                      <Tooltip title="Editar">
                        <LinkSpan
                          onClick={() => {
                            setCurrentProcessoId(parseInt(record.processo_id))
                            setRequisicaoUpdateData({
                              ...record,
                              concluirem: moment(record.concluirem),
                              time: record.grupo_id,
                            })
                            setShowModalUpdateReq(true)
                          }}
                        >
                          <FormOutlined />
                        </LinkSpan>
                      </Tooltip>
                    </>
                  )}
                {Boolean(record.reprovado) &&
                  parseInt(record.criadopor) === userPesId && (
                    <>
                      <Divider type="vertical" />
                      <Popconfirm
                        title="Tem certeza que deseja reenviar a requisição para aprovação?"
                        onConfirm={() => {
                          setCurrentProcessoId(record.processo_id)
                          setShowModalReenvioReq(true)
                        }}
                        okText="Sim"
                        cancelText="Não"
                        icon={<InfoCircleOutlined />}
                      >
                        <Tooltip title="Reenviar">
                          <LinkSpan>
                            <SendOutlined />
                          </LinkSpan>
                        </Tooltip>
                      </Popconfirm>
                    </>
                  )}
                {parseInt(record.criadopor) === userPesId &&
                  !record.cancelado && (
                    <>
                      <Divider type="vertical" />

                      <Popconfirm
                        title="Tem certeza que deseja cancelar a requisição?"
                        onConfirm={() => {
                          handleCancelReq(record.processo_id)
                        }}
                        okText="Sim"
                        cancelText="Não"
                        icon={<CloseOutlined style={{ color: 'red' }} />}
                      >
                        {' '}
                        <Tooltip title="Cancelar">
                          <LinkSpan>
                            {' '}
                            <CloseSquareOutlined />
                          </LinkSpan>
                        </Tooltip>
                      </Popconfirm>
                    </>
                  )}
              </>
            )}
          </AcaoContainer>
        )
      },
    },
  ]

  const getEtapaDescription = useCallback(
    ({ etapa, requisicao }) => {
      return etapa.status &&
        (etapa.status.aprovado || parseInt(etapa.status.aprovado) >= 0) ? (
        parseInt(etapa.status.aprovado) === 1 ? (
          <Description>
            <span>
              Aprovado por:
              {etapa.aprovador.colaborador.pes_nome}
            </span>
            {etapa.status.observacao && <p>Obs:{etapa.status.observacao}</p>}
          </Description>
        ) : (
          <Description>
            <span>
              Reprovado por:
              {etapa.aprovador.colaborador.pes_nome}
            </span>
            {etapa.status.observacao && <p>Obs:{etapa.status.observacao}</p>}
          </Description>
        )
      ) : etapa.current && etapa.aprovador.user_id === userId ? (
        <AprovacaoRow>
          <span>A requisição necessita de sua aprovação.</span>
          <Popconfirm
            title="Tem certeza que deseja aprovar a requisição?"
            onConfirm={() => {
              setCurrentProcessoId(parseInt(requisicao.processo_id))
              setCurrentEtapaId(parseInt(etapa.id))
              setShowModalAprovarReq(true)
            }}
            okText="Sim"
            cancelText="Não"
            icon={<CheckOutlined style={{ color: 'green' }} />}
          >
            <Button size="small" icon={<CheckOutlined />} type="primary">
              Aprovar
            </Button>
          </Popconfirm>

          <Popconfirm
            title="Tem certeza que deseja reprovar a requisição?"
            onConfirm={() => {
              setCurrentProcessoId(parseInt(requisicao.processo_id))
              setCurrentEtapaId(parseInt(etapa.id))
              setShowModalReprovarReq(true)
            }}
            okText="Sim"
            cancelText="Não"
            icon={<CloseOutlined style={{ color: 'red' }} />}
          >
            <Button size="small" type="primary" icon={<CloseOutlined />} danger>
              Reprovar
            </Button>
          </Popconfirm>
        </AprovacaoRow>
      ) : (
        'Aprovação pendente'
      )
    },
    [userId]
  )

  const mountWorkflow = useCallback(
    (requisicao) => {
      return (
        <Steps direction="vertical" size="small">
          {requisicao.etapas.map((etapa) => (
            <Step
              status={
                etapa.status
                  ? etapa.current
                    ? parseInt(etapa.status.aprovado) === 1
                      ? 'finish'
                      : 'error'
                    : requisicao.current &&
                      parseInt(requisicao.current) > etapa.id
                    ? 'finish'
                    : 'wait'
                  : 'wait'
              }
              key={etapa.id}
              title={
                <Title>
                  <span>{etapa.nome}</span>
                  {etapa.current && !requisicao.reprovado ? (
                    <Tag closable={false} color="var(--purple)">
                      Etapa Atual
                    </Tag>
                  ) : (
                    ''
                  )}
                </Title>
              }
              subTitle={
                <SubTitle>
                  Responsável: {etapa.aprovador.colaborador.pes_nome}
                </SubTitle>
              }
              description={getEtapaDescription({ etapa, requisicao })}
              {...(!etapa.status
                ? { icon: <ClockCircleOutlined /> }
                : !etapa.status.aprovado
                ? { icon: <CloseCircleOutlined /> }
                : { icon: <CheckCircleOutlined /> })}
            />
          ))}
        </Steps>
      )
    },
    [getEtapaDescription]
  )

  const handleOpenModalNewReq = useCallback(() => {
    dispatch(checkRequisitor()).then(({ response }) => {
      if (response.status === 204) {
        setShowModalNewReq(true)
      }
    })
  }, [dispatch])

  const handleChangeNewReq = useCallback((_, allValues) => {
    setRequisicaoCreateData(allValues)
  }, [])

  const handleSubmitNewReq = useCallback(async () => {
    const { salario_faixa_min, salario_faixa_max } = requisicaoCreateData

    const salario_faixa = await parseSalarioFaixa(
      salario_faixa_min,
      salario_faixa_max
    )

    let descricao

    if (requisicaoCreateData.descricao) {
      descricao =
        typeof requisicaoCreateData.descricao === 'string'
          ? requisicaoCreateData.descricao
          : requisicaoCreateData.descricao.target.getContent()
    }

    dispatch(
      storeRequisicao({ ...requisicaoCreateData, salario_faixa, descricao })
    ).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Requisição criada com sucesso.')
        loadRequisicoes()
        setRequisicaoCreateData(undefined)
        setShowModalConfirmNewReq(false)
        setShowModalNewReq(false)
        setConfirmNewReqText(undefined)
      }
    })
  }, [dispatch, loadRequisicoes, requisicaoCreateData])

  const handleCancelNewReq = useCallback(() => {
    setShowModalConfirmNewReq(false)
    setShowModalNewReq(false)
    setConfirmNewReqText(undefined)
    setRequisicaoCreateData(undefined)
  }, [])

  const handleFinishNewReq = useCallback(() => {
    const { time } = requisicaoCreateData
    dispatch(getEtapas(time)).then(({ response }) => {
      if (response.status === 200) {
        const { etapas } = response.data

        if (etapas.length > 0) {
          const [etapa] = etapas
          setShowModalNewReq(false)
          setShowModalConfirmNewReq(true)
          setConfirmNewReqText(`Esta requisição será enviada para ${etapa.nome} que tem como responsável ${etapa.aprovador.colaborador.pes_nome}.
          Deseja realmente enviar esta requisição?`)
        } else {
          handleSubmitNewReq()
        }
      } else {
        handleSubmitNewReq()
      }
    })
  }, [dispatch, handleSubmitNewReq, requisicaoCreateData])

  const handleSubmitUpdateReq = useCallback(
    async (data) => {
      const { salario_faixa_min, salario_faixa_max } = data

      const salario_faixa = await parseSalarioFaixa(
        salario_faixa_min,
        salario_faixa_max
      )

      let descricao

      if (data.descricao) {
        descricao =
          typeof data.descricao === 'string'
            ? data.descricao
            : data.descricao.target.getContent()
      }

      dispatch(
        updateRequisicao({
          processo_id: currentProcessoId,
          ...data,
          salario_faixa,
          descricao,
        })
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Requisição atualizada com sucesso.')
          setShowModalUpdateReq(false)
          setRequisicaoUpdateData(null)
          setCurrentProcessoId(undefined)
          loadRequisicoes()
        }
      })
    },
    [currentProcessoId, dispatch, loadRequisicoes]
  )

  const handleCancelReq = useCallback(
    (processo_id) => {
      dispatch(cancelRequisicao(processo_id)).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Requisição cancelada com sucesso.')
          loadRequisicoes()
        }
      })
    },
    [dispatch, loadRequisicoes]
  )

  const handleChangeObservacao = (changedValues, allValues) => {
    const { observacao: observacaoData } = allValues
    setObservacao(observacaoData)
  }

  const handleSubmitReenvioReq = useCallback(
    (data) => {
      dispatch(
        reenviarRequisicao({ processo_id: currentProcessoId, ...data })
      ).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Requisição reenviada para aprovação.')
          loadRequisicoes()
          setCurrentProcessoId(undefined)
          setShowModalReenvioReq(false)
        }
      })
    },
    [currentProcessoId, dispatch, loadRequisicoes]
  )

  const huntersOptions = useMemo(() => {
    return usuarios
      .filter((usuario) => usuario.usertipo_id === 1 && usuario.pes_id)
      .map((usuario) => ({
        label: usuario.user_nome,
        value: usuario.pes_id,
      }))
  }, [usuarios])

  const filialOptions = useMemo(() => {
    return filiais.map((filial) => {
      return {
        label: filial.nome,
        value: filial.filial_id,
      }
    })
  }, [filiais])

  const handleCancelReenvioReq = useCallback(() => {
    setCurrentProcessoId(undefined)
    setShowModalReenvioReq(false)
  }, [])

  const motivoOptions = useMemo(() => {
    return motivos.map((motivo) => {
      return {
        label: motivo.nome,
        value: motivo.motivo_id,
      }
    })
  }, [motivos])

  const contratoOptions = useMemo(() => {
    return contratos.map((contrato) => {
      return {
        label: contrato.nome,
        value: contrato.contrato_id,
      }
    })
  }, [contratos])

  const slasOptions = useMemo(() => {
    return slas.map((sla) => {
      return {
        label: sla.nome,
        value: sla.sla_id,
      }
    })
  }, [slas])

  return (
    <Col span={24}>
      <Row gutter={20}>
        <Col xs={24} sm={24}>
          <Card
            title={
              !isAdmin()
                ? 'Cadastre requisições de Processos Seletivos'
                : 'Gerencie as requisições de Processos Seletivos'
            }
          >
            {!gestor ? (
              <Space
                xs={24}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: '12px',
                }}
              >
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => handleOpenModalNewReq()}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  NOVA REQUISIÇÃO
                </Button>
              </Space>
            ) : (
              ''
            )}
            <Tabs
              defaultActiveKey="2"
              onTabClick={(key) => {
                key === '1' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/requisicoes/aprovadas`
                  )
                key === '2' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/requisicoes/em-andamento`
                  )
                key === '3' &&
                  history.push(
                    `/${getContaURL()}/recrutamento-selecao/requisicoes/canceladas`
                  )
              }}
            >
              <TabPane tab={<span>Aprovadas</span>} key="1" />
              <TabPane tab={<span>Em andamento</span>} key="2">
                <Table
                  dataSource={requisicoesAndamento.map((requisicao) => ({
                    ...requisicao,
                    key: requisicao.processo_id,
                    workflow: mountWorkflow(requisicao),
                  }))}
                  key={(record) => record.key}
                  columns={columns}
                  expandable={{
                    rowExpandable: (record) => !record.cancelado,
                    expandedRowRender: (record) => record.workflow,
                    expandedRowKeys,
                    onExpandedRowsChange: (rows) => setExpandedRowKeys(rows),
                  }}
                  loading={loadingRequisicoes}
                />
              </TabPane>
              <TabPane tab={<span>Canceladas</span>} key="3" />
            </Tabs>
          </Card>
        </Col>
      </Row>

      {/* MODAL DE REQUISIÇÃO DE PROCESSO SELETIVO */}
      <Modal
        title="NOVA REQUISIÇÃO DE PROCESSO SELETIVO"
        visible={showModalNewReq}
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => handleCancelNewReq()}
        destroyOnClose
        width="60%"
        okButtonProps={{
          form: 'req-processo-seletivo',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <RequisicaoProcessoForm
          onFinish={() => {
            handleFinishNewReq()
          }}
          times={times}
          loadingTimes={loadingTimes}
          cargos={cargos}
          loadingCargos={loadingCargos}
          ufs={ufs}
          loadingUfs={loadingUfs}
          cidades={cidades}
          loadingCidades={loadingCidades}
          motivos={motivoOptions}
          contratos={contratoOptions}
          filiais={filialOptions}
          loadCidades={loadCidades}
          onValuesChange={handleChangeNewReq}
          huntersOptions={huntersOptions}
          slas={slasOptions}
          requisicao
        />
      </Modal>
      <Modal
        title="EDITAR REQUISIÇÃO"
        visible={showModalUpdateReq}
        okText="SALVAR"
        okButtonProps={{
          form: 'req-processo-seletivo',
          key: 'submit',
          htmlType: 'submit',
        }}
        cancelText="CANCELAR"
        onCancel={() => {
          setRequisicaoUpdateData(null)
          setCurrentProcessoId(null)
          setShowModalUpdateReq(false)
        }}
        destroyOnClose
        centered
        width="60%"
      >
        <RequisicaoProcessoForm
          initialValues={requisicaoUpdateData}
          onFinish={handleSubmitUpdateReq}
          times={times}
          loadingTimes={loadingTimes}
          cargos={cargos}
          loadingCargos={loadingCargos}
          ufs={ufs}
          loadingUfs={loadingUfs}
          cidades={cidades}
          filiais={filialOptions}
          loadingCidades={loadingCidades}
          motivos={motivoOptions}
          loadCidades={loadCidades}
          contratos={contratoOptions}
          huntersOptions={huntersOptions}
          slas={slasOptions}
          requisicao
        />
      </Modal>
      <Modal
        title="DETALHES DA REQUISIÇÃO"
        visible={showModalDetalhesReq}
        cancelText="FECHAR"
        onCancel={() => {
          setRequisicaoUpdateData(null)
          setCurrentProcessoId(null)
          setShowModalDetalhesReq(false)
        }}
        okButtonProps={{ style: { display: 'none' } }}
        destroyOnClose
        centered
        bodyStyle={{ paddingTop: 0 }}
        width="60%"
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<span>Requisição</span>} key="1">
            <ProcessoSeletivoForm
              initialValues={requisicaoUpdateData}
              readOnly
              times={times}
              loadingTimes={loadingTimes}
              cargos={cargos}
              loadingCargos={loadingCargos}
              ufs={ufs}
              loadingUfs={loadingUfs}
              cidades={cidades}
              loadingCidades={loadingCidades}
              motivos={motivoOptions}
              contratos={contratoOptions}
              filiais={filialOptions}
              loadCidades={loadCidades}
              huntersOptions={huntersOptions}
              slas={slasOptions}
            />
          </TabPane>
          {requisicaoUpdateData && requisicaoUpdateData.history && (
            <TabPane tab={<span>Histórico</span>} key="2">
              <RequisicaoHistory history={requisicaoUpdateData.history} />
            </TabPane>
          )}
        </Tabs>
      </Modal>
      <Modal
        title="APROVAR REQUISIÇÃO"
        visible={showModalAprovarReq}
        okText="APROVAR"
        onOk={() => handleAprovarEtapa()}
        destroyOnClose
        cancelText="CANCELAR"
        onCancel={() => {
          setObservacao(undefined)
          setCurrentEtapaId(undefined)
          setCurrentProcessoId(undefined)
          setShowModalAprovarReq(false)
        }}
      >
        <Observacao onValuesChange={handleChangeObservacao} />
      </Modal>
      <Modal
        title="REPROVAR REQUISIÇÃO"
        visible={showModalReprovarReq}
        okText="REPROVAR"
        onOk={() => handleReprovarEtapa()}
        destroyOnClose
        cancelText="CANCELAR"
        onCancel={() => {
          setObservacao(undefined)
          setCurrentEtapaId(undefined)
          setCurrentProcessoId(undefined)
          setShowModalReprovarReq(false)
        }}
      >
        <Observacao onValuesChange={handleChangeObservacao} />
      </Modal>
      <Modal
        title="CONFIRMAR NOVA REQUISIÇÃO"
        visible={showModalConfirmNewReq}
        okText="SALVAR"
        cancelText="CANCELAR"
        destroyOnClose
        onCancel={() => {
          handleCancelNewReq()
        }}
        onOk={() => {
          handleSubmitNewReq()
        }}
      >
        <Confirm message={confirmNewReqText} />
      </Modal>
      <Modal
        title="REENVIAR REQUISIÇÃO"
        visible={showModalReenvioReq}
        okText="REENVIAR"
        destroyOnClose
        cancelText="CANCELAR"
        onCancel={() => {
          handleCancelReenvioReq()
        }}
        okButtonProps={{
          form: 'req-observacao',
          key: 'submit',
          htmlType: 'submit',
        }}
      >
        <Observacao onFinish={handleSubmitReenvioReq} />
      </Modal>
    </Col>
  )
}

export default Requisicoes
