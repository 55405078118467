import React from 'react'
import moment from 'moment'

// Components
import EnviarPesquisa from '../../../components/enviarPesquisa/EnviarPesquisa'
import AdicionarColaboradores from '../components/adicionarColaboradores/AdicionarColaboradores'
import AdicionarNovoColaborador from '../components/adicionarNovoColaborador/AdicionarNovoColaborador'
import AdicionarNovoTime from '../../times/components/adicionarNovoTime/AdicionarNovoTime'
import AdicionarNovoCargo from '../../cargos/components/adicionarNovoCargo/AdicionarNovoCargo'
import Confirm from '../../../components/Confirm'

// URLs
export const URL_COLABORADORES = `${process.env.REACT_APP_API_URL}/pessoas`
export const URL_COLABORADORES_GET = `${URL_COLABORADORES}/list?inativos=true`
export const URL_COLABORADOR_POST = `${URL_COLABORADORES}/store`
export const URL_COLABORADORES_POST = `${URL_COLABORADORES}/storePessoas`
export const URL_COLABORADOR_PUT = `${URL_COLABORADORES}/update`
export const URL_COLABORADOR_DELETE = `${URL_COLABORADORES}/delete`
export const URL_COLABORADORES_SEND_AVALIACOES = `${process.env.REACT_APP_API_URL}/avaliacoes/send`
export const URL_SEND_PERCEPCAO = `${process.env.REACT_APP_API_URL}/pesquisa`

// Redux types
export const COLABORADORES_GET = 'COLABORADORES_GET'
export const COLABORADOR_POST = 'COLABORADOR_POST'
export const COLABORADORES_POST = 'COLABORADORES_POST'
export const COLABORADOR_DELETE = 'COLABORADOR_DELETE'
export const COLABORADOR_PUT = 'COLABORADOR_PUT'
export const COLABORADORES_SEND_AVALIACOES = 'COLABORADORES_SEND_AVALIACOES'
export const SEND_PERCEPCAO = 'SEND_PERCEPCAO'

// Erros da planilha
export const SHEET_NAME_UNDEFINED = 'Nome'
export const SHEET_EMAIL_UNDEFINED = 'Email'
export const SHEET_NASCIMENTO_UNDEFINED = 'Data de Nascimento'
export const SHEET_CPF_UNDEFINED = 'CPF'
export const SHEET_SEXO_UNDEFINED = 'Sexo'
export const SHEET_NASCIMENTO_INVALID_FORMAT =
  'Você utilizou formatos de data inválidos. Utilize um dos seguintes formatos: DD-MM-YYYY ou YYYY-MM-DD.'

// Modals
export const modals = [
  {
    id: 0,
    title: 'GERAR PESQUISA PARA OS COLABORADORES',
    cancelText: 'CANCELAR',
    okText: 'ENVIAR',
    children: EnviarPesquisa,
    initialState: {
      vetorComportamental: false,
      percepcaoAmbiente: false,
      enviarEmails: false,
      valoresOrganizacionais: false,
    },
  },
  {
    id: 1,
    title: 'ADICIONAR MULTIPLOS COLABORADORES',
    cancelText: 'CANCELAR',
    okText: 'SALVAR',
    width: 750,
    children: AdicionarColaboradores,
    initialState: {
      listaColaboradores: [],
      fileList: [],
      vetorComportamental: false,
      percepcaoAmbiente: false,
      enviarEmails: false,
      valoresOrganizacionais: false,
    },
  },
  {
    id: 2,
    title: 'ADICIONAR NOVO COLABORADOR',
    cancelText: 'CANCELAR',
    okText: 'SALVAR',
    width: '50%',
    children: AdicionarNovoColaborador,
    initialState: {
      cpf: undefined,
      filial_id: undefined,
      nome: undefined,
      dataNascimento: moment(),
      email: undefined,
      sexo: 'M',
      grupo: undefined,
      telefone: undefined,
      cargo: undefined,
      dataAdmissao: moment(),
      vetorComportamental: false,
      percepcaoAmbiente: false,
      valoresOrganizacionais: false,
      enviarEmails: false,
      pcd: 0,
      pcd_tipo: undefined,
      cid: undefined,
    },
  },
  {
    id: 3,
    title: 'ADICIONAR NOVO TIME',
    cancelText: 'CANCELAR',
    okText: 'SALVAR',
    children: (props) => <AdicionarNovoTime {...props} showTimePai={false} />,
    initialState: {
      nome: '',
      pai: '',
      descricao: '',
      filial: '',
    },
  },
  {
    id: 4,
    title: 'ADICIONAR NOVO CARGO',
    cancelText: 'CANCELAR',
    okText: 'SALVAR',
    children: (props) => <AdicionarNovoCargo {...props} />,
    initialState: {
      nome: '',
      cbo: '',
      atividades: '',
      descricao: '',
      piso: '',
    },
  },
  {
    // CONFIRMAR ENVIO PARA TODOS OS CANDIDATOS
    id: 5,
    title: 'CONFIRMAR PESQUISAS',
    cancelText: 'CANCELAR',
    okText: 'ENVIAR',
    children: (props) => (
      <Confirm
        message={`Você não selecionou nenhum colaborador para enviar pesquisas.
			Deseja enviar para todos os colaboradores cadastrados?`}
      />
    ),
    initialState: {},
  },
]

// Data processing
export const apiToFront = ({ pessoa }) => ({
  cpf: pessoa.pes_cpf || '',
  nome: pessoa.pes_nome || '',
  email: pessoa.pes_email || '',
  cargo: pessoa.cargos || '',
  key: pessoa.pes_id,
  times: pessoa.times || '',
  nome_filial: pessoa.nome_filial || '',
  ultimofeedback: pessoa.ultimofeedback
    ? moment(pessoa.ultimofeedback).format('DD/MM/YYYY')
    : '',
  pes_datanascimento: pessoa.pes_datanascimento
    ? moment(pessoa.pes_datanascimento).format('DD/MM/YYYY')
    : '',
  dataadmissao: pessoa.dataadmissao
    ? moment(pessoa.dataadmissao).format('DD/MM/YYYY')
    : '',
  datademissao: pessoa.datademissao
    ? moment(pessoa.datademissao).format('DD/MM/YYYY (HH:mm)')
    : '',
  pct_metas: pessoa.pct_metas ? `${pessoa.pct_metas}%` : '',
})
