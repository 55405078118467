import history from '../utils/history'
import { logout } from './auth'
import api from './api'

// Logger middleware
export const logger = (store) => (next) => (action) => {
  let result = next(action)

  return result
}

// Authenticated requests middleware
export const authenticatedRequests = (store) => (next) => async (action) => {
  let result

  if (action.api) {
    await api({
      ...action.api,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(({ status, statusText, data }) => {
        result = next({
          ...action,
          response: {
            status,
            statusText,
            data,
          },
        })
      })
      .catch((payload) => {
        if (payload.response) {
          const { status, statusText, data } = payload.response

          if (status === 401) {
            logout()
            history.push('/')
          }

          result = next({
            ...action,
            response: {
              status,
              statusText,
              data,
            },
          })
        }
      })
  } else {
    result = next(action)
  }

  return result
}
