import styled from 'styled-components'

import { Tabs as AntdTabs, Steps as AntdSteps, Badge } from 'antd'

export const LinkSpan = styled.span`
  color: var(--purple);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const VagaContainer = styled.span`
  display: flex;
  justify-content: flex-start;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: rgba(0, 0, 0, 0.85);
    }

    p {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
`

export const AprovacaoRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  align-items: center;

  > span {
    margin-right: 8px;
  }

  button {
    & + button {
      margin-left: 8px;
    }
  }
`
export const Description = styled.div`
  > span {
    font-size: 14px;
    font-weight: 500;
  }

  span + p {
    margin-top: 4px;
  }
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > span:not(.ant-tag) {
    font-size: 16px;
    font-weight: 500;
  }

  .ant-tag {
    margin-left: 16px;
  }
`

export const SubTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
`

export const Tabs = styled(AntdTabs)`
  .ant-tabs-nav-list {
    padding: 0;
  }

  .ant-tabs-content-holder {
    padding: 0px 8px;
  }
`

export const Steps = styled(AntdSteps)`
  .ant-steps-item-title {
    display: flex;
    flex-direction: column;

    .ant-steps-item-subtitle {
      margin-top: 4px;
    }
  }

  .ant-steps-item-description {
    margin-top: 8px;
  }
`

export const AcaoContainer = styled.div`
  white-space: nowrap;
`
