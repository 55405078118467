// types
export const RELATORIOS_LIST_COLABORADORES_FITCULTURAL =
  'RELATORIOS_LIST_COLABORADORES_FITCULTURAL'

export const RELATORIOS_LIST_CANDIDATOS_FITCULTURAL =
  'RELATORIOS_LIST_CANDIDATOS_FITCULTURAL'

// urls
export const URL_RELATORIOS_LIST_COLABORADORES_FITCULTURAL = `${process.env.REACT_APP_API_URL}/pessoas/listWithFitCultural`

//
export const URL_RELATORIOS_LIST_CANDIDATOS_FITCULTURAL = `${process.env.REACT_APP_API_URL}/candidato/listWithFitCultural`
