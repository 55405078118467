import * as constants from '../constants/constantsQuiz'

export const getQuizes = () => {
  return {
    type: constants.QUIZ_LIST,
    api: {
      method: 'get',
      url: constants.URL_QUIZ_LIST,
    },
  }
}

export const postQuiz = (data) => {
  return {
    type: constants.QUIZ_POST,
    api: {
      method: 'post',
      url: constants.URL_QUIZ_POST,
      data,
    },
  }
}
