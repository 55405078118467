// types
export const QUIZ_GET = 'QUIZ_LIST'
export const UPDATE_STATUS_QUIZ = 'UPDATE_STATUS_QUIZ'
export const UPDATE_QUIZ = 'UPDATE_QUIZ'

// urls
export const URL_QUIZ_GET = (quiz_id) =>
  `${process.env.REACT_APP_API_URL}/listaquiz/${quiz_id}`
export const URL_UPDATE_STATUS_QUIZ = (quiz_id) =>
  `${process.env.REACT_APP_API_URL}/quizativo/${quiz_id}`
export const URL_UPDATE_QUIZ = (quiz_id) =>
  `${process.env.REACT_APP_API_URL}/quiz/${quiz_id}`
