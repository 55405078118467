import React, { useEffect, useState } from 'react'
import { Layout, Table, Modal, Button, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import {
  EditOutlined,
  DeleteOutlined,
  LinkOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { toast } from 'react-toastify'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FormAvaliador from '../Forms/SubCultura/AddAvaliador'
import EditAvaliador from '../Forms/SubCultura/EditAvaliador'
import {
  deleteAvaliadores,
  sendEmailForAnAvaliatorAPI,
  getAvaliadores,
} from '../Api/api'
import { getContaURL } from '../../../services/auth'

const { Column } = Table

const App = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalEditVisible, setModalEditVisible] = useState(false)
  const [tableData, setData] = useState([])
  const [avaliadorData, setAvaliador] = useState({})

  const updateAvaliadores = () => {
    getAvaliadoresfromApi(
      props?.cultura.cultura_id,
      props?.cultura.subcultura_id
    )
  }

  const sendEmailForAnAvaliator = async (
    nome,
    email,
    avaliador_id,
    hashacesso,
    cultura_id
  ) => {
    const data = {
      sendEmail: true,
      avaliadores: [
        {
          avaliador_id,
          nome,
          email,
          hashacesso,
        },
      ],
    }
    const response = await sendEmailForAnAvaliatorAPI(data, cultura_id)
    if (response.success) toast.success('Email enviado com sucesso')
  }

  const deleteAvaliador = async (cultura_id, subcultura_id, avaliador_id) => {
    // eslint-disable-next-line no-restricted-globals
    const answer = confirm('Tem Certeza que deseja apagar o avaliador?')
    if (answer) {
      let response
      if (props?.cultura.cultura_tipo === 2) {
        response = await deleteAvaliadores(
          cultura_id,
          subcultura_id,
          avaliador_id
        )
      } else {
        response = await deleteAvaliadores(cultura_id, null, avaliador_id)
      }

      if (response.success) {
        toast.success('Excluido com sucesso')
        filterAndGetAvaliadores()
      }
    }
  }

  useEffect(() => {
    filterAndGetAvaliadores()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function filterAndGetAvaliadores() {
    if (props?.cultura.cultura_tipo === 2) {
      getAvaliadoresfromApi(
        props?.cultura.cultura_id,
        props?.cultura.subcultura_id
      )
    } else {
      getAvaliadoresFromApi(props?.cultura.cultura_id)
    }
  }

  const getAvaliadoresfromApi = async (cultura_id, subcultura_id) => {
    const response = await getAvaliadores(cultura_id, subcultura_id)
    const avaliadores = response.conta_cultura_avaliador
    avaliadores.forEach((avaliador) => {
      if (avaliador.peso === 1) avaliador.peso = 'Baixo'
      else if (avaliador.peso === 2) avaliador.peso = 'Médio'
      else if (avaliador.peso === 3) avaliador.peso = 'Alto'
      avaliador.acoes = ['Editar', 'Excluir', 'Copiar', 'Enviar']
      avaliador.key = avaliador.hashacesso
    })
    setData(avaliadores)
  }

  const getAvaliadoresFromApi = async (cultura_id) => {
    const response = await getAvaliadores(cultura_id)
    response.conta_cultura_avaliador.forEach((avaliador) => {
      if (avaliador.peso === 1) avaliador.peso = 'Baixo'
      else if (avaliador.peso === 2) avaliador.peso = 'Médio'
      else if (avaliador.peso === 3) avaliador.peso = 'Alto'
      avaliador.acoes = ['Editar', 'Excluir', 'Copiar', 'Enviar']
      avaliador.key = avaliador.hash
    })
    setData(response.conta_cultura_avaliador)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }
  const showEditModal = () => {
    setModalEditVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setModalEditVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setModalEditVisible(false)
  }

  return (
    <div>
      <Layout style={{ padding: 20 }}>
        <h1 className="subTitle">
          Adicione pessoas para responderem a pesquisa de Cultura Desejada
        </h1>
        {props?.cultura?.conta_cultura_statuses && (
          <div>
            {props?.cultura?.conta_cultura_statuses[0]?.id == 1 && (
              <Button
                type="primary"
                className="btn_purple newCultureBtn floatRight"
                onClick={showModal}
              >
                Novo Avaliador
              </Button>
            )}
          </div>
        )}

        <Table dataSource={tableData}>
          <Column title="Nome" key="nome" dataIndex="nome" />
          <Column title="Email" key="email" dataIndex="email" />
          <Column title="Peso" key="peso" dataIndex="peso" />
          <Column
            title="Ação"
            key="acoes"
            dataIndex="acoes"
            render={(tags, record) => {
              return (
                <>
                  {tags.map((tag, index) => {
                    return tag === 'Editar' &&
                      props?.cultura?.conta_cultura_statuses[0]?.id == 1 ? (
                      <Tooltip title="Editar" placement="bottom">
                        <EditOutlined
                          style={{ margin: 5, color: '#30318b' }}
                          key={index}
                          onClick={() => {
                            showEditModal()
                            setAvaliador(record)
                          }}
                        />
                      </Tooltip>
                    ) : tag === 'Excluir' &&
                      props?.cultura?.conta_cultura_statuses[0]?.id == 1 ? (
                      <Tooltip title="Remover" placement="bottom">
                        <DeleteOutlined
                          style={{ margin: 5, color: '#30318b' }}
                          key={index}
                          onClick={() => {
                            deleteAvaliador(
                              record.cultura_id,
                              record.subcultura_id,
                              record.avaliador_id
                            )
                          }}
                        />
                      </Tooltip>
                    ) : tag === 'Copiar' &&
                      props?.cultura?.conta_cultura_statuses[0]?.id === 2 ? (
                      <CopyToClipboard
                        text={`${`${
                          window.location.host
                        }/${getContaURL()}`}/avaliador/${record.hashacesso}`}
                        onCopy={() =>
                          toast.success(
                            'Link do relatório copiado para a área de transferência.'
                          )
                        }
                      >
                        <Tooltip title="Copiar o link" placement="bottom">
                          <LinkOutlined
                            style={{ margin: 5, color: '#30318b' }}
                          />
                        </Tooltip>
                      </CopyToClipboard>
                    ) : tag === 'Enviar' &&
                      props?.cultura?.conta_cultura_statuses[0]?.id === 2 ? (
                      <Tooltip title="Reenviar e-mail" placement="bottom">
                        <MailOutlined
                          onClick={() =>
                            sendEmailForAnAvaliator(
                              record.nome,
                              record.email,
                              record.avaliador_id,
                              record.hashacesso,
                              record.cultura_id
                            )
                          }
                          style={{
                            margin: 5,
                            color: '#30318b',
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                    ) : null
                  })}
                </>
              )
            }}
          />
        </Table>
      </Layout>
      <Modal
        title="Adicionar Avaliador"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <FormAvaliador
          update={filterAndGetAvaliadores}
          closeModal={handleCancel}
          cultura={props.cultura}
          tipoRequisicao={props.tipoRequisicao}
        />
      </Modal>
      <Modal
        title="Editar Avaliador"
        visible={isModalEditVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <EditAvaliador
          update={filterAndGetAvaliadores}
          closeModal={handleCancel}
          cultura={props.cultura}
          editable={avaliadorData}
          tipoRequisicao={props.tipoRequisicao}
        />
      </Modal>
    </div>
  )
}

export default App
