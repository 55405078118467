// Redux types
import * as constants from '../constants/constantsPerfil'

export const getInfoPerfil = ({ id }) => {
  return {
    type: constants.PERFIL_INFO_GET,
    api: {
      method: 'get',
      url: `${constants.URL_PERFIL_INFO_GET}/${id}`,
      data: {},
    },
  }
}

export const getVetores = ({ id }) => {
  return {
    type: constants.VETORES_GET,
    api: {
      method: 'get',
      url: `${constants.URL_VETORES_GET}/${id}`,
      data: {},
    },
  }
}

export const getVetorComportamental = ({ id }) => {
  return {
    type: constants.VETOR_COMPORTAMENTAL_GET,
    api: {
      method: 'get',
      url: `${constants.URL_VETOR_COMPORTAMENTAL_GET}/${id}`,
      data: {},
    },
  }
}

export const getPercepcaoAmbiente = ({ id }) => {
  return {
    type: constants.PERCEPCAO_AMBIENTE_GET,
    api: {
      method: 'get',
      url: `${constants.URL_PERCEPCAO_AMBIENTE_GET}/${id}`,
      data: {},
    },
  }
}

export const ativarPerfil = ({ id }) => {
  return {
    type: constants.ATIVAR_PERFIL_DELETE,
    api: {
      method: 'post',
      url: `${constants.URL_ATIVAR_PERFIL_DELETE}/${id}`,
      data: {},
    },
  }
}

export const desativarPerfil = ({ id, datademissao }) => {
  return {
    type: constants.DESATIVAR_PERFIL_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_DESATIVAR_PERFIL_DELETE}/${id}`,
      data: { datademissao },
    },
  }
}

export const excluirPerfil = ({ id }) => {
  return {
    type: constants.EXCLUIR_PERFIL_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_EXCLUIR_PERFIL_DELETE}/${id}`,
      data: {},
    },
  }
}

export const getTimes = ({ id }) => {
  return {
    type: constants.PERFIL_TIMES_GET,
    api: {
      method: 'get',
      url: `${constants.URL_PERFIL_TIMES_GET}/${id}`,
      data: {},
    },
  }
}

export const getCargos = ({ id }) => {
  return {
    type: constants.PERFIL_CARGOS_GET,
    api: {
      method: 'get',
      url: `${constants.URL_PERFIL_CARGOS_GET}/${id}`,
      data: {},
    },
  }
}

export const cancelVetorComportamental = (id, avalia_id) => {
  return {
    type: constants.VETOR_COMPORTAMENTAL_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_VETOR_COMPORTAMENTAL_DELETE}/${id}/${avalia_id}`,
    },
  }
}
export const cancelPercepcao = (id, avalia_id) => {
  return {
    type: constants.PERCEPCAO_AMBIENTE_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_PERCEPCAO_AMBIENTE_DELETE}/${id}/${avalia_id}`,
    },
  }
}
export const cancelValoresOrganizacionais = (id, avalia_id) => {
  return {
    type: constants.PERFIL_DELETE_PESQ_VALORES_ORGANIZACIONAIS,
    api: {
      method: 'delete',
      url: `${constants.URL_DELETE_PERFIL_PESQ_VALORES_ORGANIZACIONAIS(
        avalia_id
      )}`,
    },
  }
}

export const setActiveTab = (id) => {
  return {
    type: constants.SET_ACTIVE_TAB,
    value: id,
  }
}

export const getValoresOrganizacionais = ({ id }) => {
  return {
    type: constants.PERFIL_GET_PESQ_VALORES_ORGANIZACIONAIS,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_PESQ_VALORES_ORGANIZACIONAIS(id),
    },
  }
}

//

export const getCultura = (pes_id) => {
  return {
    type: constants.PERFIL_COLABORADOR_CULTURA_GET,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_COLABORADOR_CULTURA_GET(pes_id),
    },
  }
}

export const getCulturaOrg = (pes_id) => {
  return {
    type: constants.PERFIL_COLABORADOR_CULTURAORG_GET,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_COLABORADOR_CULTURAORG_GET(pes_id),
    },
  }
}

export const getFitCultural = (pes_id) => {
  return {
    type: constants.PERFIL_COLABORADOR_FITCULTURAL_GET,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_COLABORADOR_FITCULTURAL_GET(pes_id),
    },
  }
}

export const getCulturaEmpresa = () => {
  return {
    type: constants.PERFIL_COLABORADOR_CULTURA_EMPRESA_GET,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_COLABORADOR_CULTURA_EMPRESA_GET,
    },
  }
}

export const getCulturaOrgEmpresa = () => {
  return {
    type: constants.PERFIL_COLABORADOR_CULTURAORG_EMPRESA_GET,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_COLABORADOR_CULTURAORG_EMPRESA_GET,
    },
  }
}

export const listValoresOrg = () => {
  return {
    type: constants.PERFIL_COLABORADOR_VALORES_ORG_GET,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_COLABORADOR_VALORES_ORG_GET,
    },
  }
}

export const updateCargoPessoa = (cargopes_id, data) => {
  return {
    type: constants.PERFIL_COLABORADOR_UPDATE_CARGO,
    api: {
      method: 'put',
      url: constants.URL_PERFIL_COLABORADOR_UPDATE_CARGO(cargopes_id),
      data,
    },
  }
}

export const updateGrupoPessoa = (
  idPessoa,
  { data_entrada, data_saida, grupo_id, grupopessoa_id, status }
) => {
  return {
    type: constants.GRUPO_PESSOA_PUT,
    api: {
      method: 'put',
      url: `${constants.URL_GRUPO_PESSOA_PUT}/${idPessoa}`,
      data: {
        data_entrada,
        data_saida,
        grupo_id,
        grupopessoa_id,
        status,
      },
    },
  }
}

export const listProcessos = (pes_id) => {
  return {
    type: constants.PERFIL_COLABORADOR_PROCESSOS_LIST,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_COLABORADOR_PROCESSOS_LIST(pes_id),
    },
  }
}

export const listLogs = ({ etapacand_id, processo_id }) => {
  return {
    type: constants.PERFIL_COLABORADOR_PROCESSO_LOG_LIST,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_COLABORADOR_PROCESSO_LOG_LIST({
        etapacand_id,
        processo_id,
      }),
    },
  }
}

export const storeLog = ({ etapacand_id, processo_id, observacao }) => {
  return {
    type: constants.PERFIL_COLABORADOR_PROCESSO_LOG_STORE,
    api: {
      method: 'post',
      url: constants.URL_PERFIL_COLABORADOR_PROCESSO_LOG_STORE({
        etapacand_id,
        processo_id,
      }),
      data: {
        observacao,
      },
    },
  }
}

export const deleteLog = ({ etapacand_id, processo_id, log_id }) => {
  return {
    type: constants.PERFIL_COLABORADOR_PROCESSO_LOG_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_PERFIL_COLABORADOR_PROCESSO_LOG_DELETE({
        etapacand_id,
        processo_id,
        log_id,
      }),
    },
  }
}

export const listProcessosTriagem = () => {
  return {
    type: constants.PERFIL_COLABORADOR_PROCESSOS_TRIAGEM_LIST,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_COLABORADOR_PROCESSOS_TRIAGEM_LIST,
    },
  }
}
// old
export const processosSeletivos = () => {
  return {
    type: constants.PERFIL_PROCESSO_SELETIVO,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_PROCESSO_SELETIVO,
    },
  }
}
//
