import React, { useCallback, useEffect, useState } from 'react'

import api from '../../services/api'

import AnaliseCultura from './components/AnaliseCultura'
import Olhar360 from './components/Olhar360'
import PerfilComportamental from './components/PerfilComportamental'

import { CulturaItem, CulturaOrganizacional, Destaques, Vetor } from './@types'
import { parseCulturas, parseVetor } from '../../utils/utils'
import AnaliseGeral from './components/AnaliseGeral'

interface RelatoriosLideresProps {
  type: string
}

const culturaOrganizacionalInitialState: CulturaOrganizacional = {
  loading: false,
  niveisConfianca: [],
  olharLideranca: {
    perceboLider: [],
    frequenciaFeedback: [],
    feedbackContribui: [],
    reconhecimento: [],
    expectativaEntregas: [],
    perfilLiderDireto: [],
  },
  olharParaMudancas: {
    pontosFortes: [],
    impedimento: [],
    principalProblema: [],
    velocidadeAdocao: [],
    engajamento: [],
    proficiencia: [],
  },
  olharParaSi: {
    escolhaEmpresa: [],
    prioridades: [],
    expectativas6meses: [],
    fonteEntusiasmo: [],
  },
  valoresEvidencia: [],
  satisfacaoGeral: [],
}

const vetorInitialState = {
  intensidade: [],
  rct: [],
  edl: [],
  foc: [],
  ode: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  oct: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  tdd: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  cor: [],
  erc: [],
  edc: [],
  principal: {},
  disc: [],
  perfilComp: [],
}

export default function RelatoriosLideres({ type }: RelatoriosLideresProps) {
  const [loadingCultura, setLoadingCultura] = useState(false)
  const [loadingCulturaOrg, setLoadingCulturaOrg] = useState(false)

  const [cultura, setCultura] = useState<CulturaItem[]>([])
  const [forcas, setForcas] = useState<CulturaItem[]>([])
  const [disc, setDisc] = useState<CulturaItem[]>([])
  const [olhares, setOlhares] = useState<CulturaItem[]>([])
  const [gestao, setGestao] = useState<CulturaItem[]>([])
  const [geracoes, setGeracoes] = useState<CulturaItem[]>([])
  const [generos, setGeneros] = useState<CulturaItem[]>([])
  const [culturaOrganizacional, setCulturaOrganizacional] =
    useState<CulturaOrganizacional>(culturaOrganizacionalInitialState)
  const [vetor, setVetor] = useState<Vetor>(vetorInitialState)
  const [perfis, setPerfis] = useState([])

  const [showGraficoRadar, setShowGraficoRadar] = useState(true)

  const handleSwitchGraficoRadar = async () =>
    setShowGraficoRadar(!showGraficoRadar)

  const loadRelatorioLideres = useCallback(async () => {
    setLoadingCultura(true)
    setLoadingCulturaOrg(true)
    try {
      const response = await api.get('/relatorios/lideres')

      if (response.status === 200) {
        const {
          culturaDesejada,
          culturaInstalada,
          culturaOrganizacional,
          generos,
          geracoes,
          perfis,
          vetor: vetorData,
        } = response.data

        const { cultura, olhares, gestao, forcas, disc } = parseCulturas({
          culturaInstalada,
          culturaDesejada,
          culturaConsensuada: undefined,
          culturaLider: undefined,
          custom: undefined,
          culturaDesejadaGrupo: undefined,
          labelCulturaDesejadaEmpresa: 'Desejado',
          labelCulturaDesejadaGrupo: 'Desejado (Time)',
        })

        const vetor = parseVetor(vetorData)

        setVetor(vetor)
        setPerfis(perfis)
        setGeracoes(geracoes)
        setGeneros(generos)
        setDisc(disc)
        setCulturaOrganizacional(culturaOrganizacional)
        setCultura(cultura)
        setOlhares(olhares)
        setGestao(gestao)
        setForcas(forcas)
      }
    } finally {
      setLoadingCultura(false)
      setLoadingCulturaOrg(false)
    }
  }, [])

  useEffect(() => {
    loadRelatorioLideres()
  }, [loadRelatorioLideres])

  return (
    <>
      {type === 'analiseCultura' && (
        <AnaliseCultura
          culturaOrganizacional={culturaOrganizacional}
          cultura={cultura}
          forcas={forcas}
          disc={disc}
          olhares={olhares}
          gestao={gestao}
          geracoes={geracoes}
          generos={generos}
        />
      )}

      {type === 'olhar360' && (
        <Olhar360 culturaOrganizacional={culturaOrganizacional} />
      )}

      {type === 'perfilComportamental' && (
        <PerfilComportamental
          vetor={vetor}
          handleSwitchGraficoRadar={handleSwitchGraficoRadar}
          perfis={perfis}
          showGraficoRadar={showGraficoRadar}
        />
      )}

      {type === 'analiseGeral' && (
        <AnaliseGeral
          culturaOrganizacional={culturaOrganizacional}
          cultura={{
            cultura,
            forcas,
            disc,
            generos,
            geracoes,
            olhares,
            gestao,
          }}
          loadingCultura={loadingCultura}
          loadingCulturaOrg={loadingCulturaOrg}
        />
      )}
    </>
  )
}
