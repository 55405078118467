import React from 'react'

import { Input, Form } from 'antd'

function Observacao({ onFinish, onValuesChange }) {
  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      id="req-observacao"
      onFinish={onFinish}
      form={form}
      onValuesChange={onValuesChange}
    >
      <Form.Item label="Observação" name="observacao">
        <Input.TextArea
          style={{ minHeight: 150 }}
          placeholder="Insira aqui uma observação a respeito da requisição"
        />
      </Form.Item>
    </Form>
  )
}

export default Observacao
