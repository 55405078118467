import { Button, Select, Skeleton } from 'antd'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import Charts from '../Components/Charts'
import ChartsTwo from '../Components/ChartsTwo'
import Pdf from '../Pdf'
import Table from './Tables'
import TableTwo from './Tables/percentageTable'
import MenuBar from '../Components/Menu'
import ReportLogo from '../../../assets/business-report.png'
import { getCulturasFromEmpresas, getRelatorios } from '../Api/api'
import SpinLoading from '../../../utils/SpinLoading'

const App = () => {
  const [isDownloading, setDownloading] = useState(false)
  const [typeDownload, setTypeDownload] = useState(null)
  const [isSearched, setSearched] = useState(false)
  const [culturas, setCulturas] = useState([])
  const [selectedCultura, setSelectedCultura] = useState(null)
  const [selectedCulturaData, setSelectedCulturaData] = useState(null)
  const [novoGrafico, setNovoGrafico] = useState(null)
  const [graficoFour, setGraficoFour] = useState(null)
  const [graficoOne, setGraficoOne] = useState(null)
  const [instalado, setInstalado] = useState(null)
  const [loading, setLoading] = useState(false)

  const { Option } = Select

  function closeDownload() {
    setDownloading(false)
  }

  function handlePdf() {
    setTypeDownload(1)
    setDownloading(true)
  }

  function cleanFilters() {
    setSearched(false)
  }

  function generateGrafico4(arquetipo) {
    const newElement = {
      name: arquetipo.arquetipo,
      'Abaixo Máximo': arquetipo.abaixo[1],
      'Abaixo Mínimo': arquetipo.abaixo[0],
      'Acima Máximo': arquetipo.acima[1],
      'Acima Mínimo': arquetipo.acima[0],
      'Dentro Mínimo': arquetipo.dentro[0],
      'Dentro Máximo': arquetipo.dentro[1],
      Desejado: arquetipo.valor,
      'Muito Abaixo': arquetipo.muito_abaixo,
      'Muito Acima': arquetipo.muito_acima,
    }
    return newElement
  }

  // Nessa funcao e feita a chamada e a desestruturacao dos arquivos.
  async function handleSearch() {
    setLoading(true)
    const RESPONSE = await getRelatorios(selectedCultura)
    if (
      !RESPONSE.grafico_2 ||
      Object.entries(RESPONSE.grafico_2).length === 0
    ) {
      toast.error('Não é possível gerar relatório para essa cultura')
    } else {
      const newArray = []
      Object.entries(RESPONSE.grafico_2).forEach((elemento) => {
        elemento[1].name = elemento[0]
        newArray.push(elemento[1])
      })
      newArray.forEach((element) => {
        const desejado = element.Desejado.replace('%', '')
        const maximo = element['Dentro Máximo'].replace('%', '')
        const minimo = element['Dentro Mínimo'].replace('%', '')
        const acimaMax = element['Acima Máximo'].replace('%', '')
        const acimaMin = element['Acima Mínimo'].replace('%', '')
        const abaixoMax = element['Abaixo Máximo'].replace('%', '')
        const abaixoMin = element['Abaixo Mínimo'].replace('%', '')

        element.Acima = Number(acimaMax) + Number(acimaMin)
        element.Abaixo = Number(abaixoMax) + Number(abaixoMin)
        element.Desejado = Number(desejado) + Number(maximo) + Number(minimo)

        element.Acima = `${element.Acima.toFixed(2)}%`
        element.Abaixo = `${element.Abaixo.toFixed(2)}%`
        element.Desejado = `${element.Desejado.toFixed(2)}%`
      })
      setNovoGrafico(newArray)

      const graficoNovo = []
      RESPONSE.grafico_1.desejado.forEach((element) => {
        graficoNovo.push(generateGrafico4(element))
      })
      setGraficoFour(graficoNovo)
      const arrayOne = []

      Object.entries(RESPONSE.grafico_2).forEach((valor) => {
        Object.entries(valor[1]).forEach((categorias) => {
          const newObject = {
            arquetipo: valor[0],
            categoria: categorias[0],
            valor: parseFloat(categorias[1]),
          }
          arrayOne.push(newObject)
        })
      })

      setGraficoOne(arrayOne)
      setInstalado(RESPONSE.grafico_1)
      setTimeout(() => {
        setSearched(true)
      }, 1000)
    }
    setLoading(false)
  }

  async function getCulturasFromApi() {
    const RESPONSE = await getCulturasFromEmpresas()
    setCulturas(filterCulturasWithFitId(RESPONSE.culture))
  }

  useEffect(() => {
    getCulturasFromApi()
  }, [])

  function updateCultura(id) {
    culturas.forEach((cultura) => {
      if (cultura.cultura_id == id) {
        setSelectedCulturaData({ ...cultura })
      }
    })
  }

  function filterCulturasWithFitId(array) {
    const newArray = array.filter((cultura) => cultura?.fit_id != undefined)
    return newArray
  }
  return (
    <div>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: 5,
          padding: 20,
          border: '0.5px solid black',
        }}
      >
        <MenuBar selectedKeys={['0']} />

        <div style={{ margin: 25 }}>
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            <span>Cultura Desejada</span>
            <Select
              onChange={(value) => {
                setSelectedCultura(value)
                updateCultura(value)
              }}
              style={{ maxWidth: 220 }}
            >
              {culturas?.map((cultura) => (
                <Option key={cultura.cultura_id} value={cultura.cultura_id}>
                  {cultura.conta_culturas[0].descricao}
                </Option>
              ))}
            </Select>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <div />
            <div>
              <Button onClick={cleanFilters} style={{ marginRight: 5 }}>
                Limpar Filtros
              </Button>
              <Button onClick={handleSearch} type="primary">
                {loading ? <SpinLoading /> : 'Gerar Relatório'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: 5,
          padding: 20,
          border: '0.5px solid black',
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {isSearched ? (
            <div
              style={{
                borderWidth: 1,
                borderColor: 'black',
                borderStyle: 'solid',
                borderRadius: 5,
                padding: 20,
                backgroundColor: '#F1F1F1',
                maxWidth: '20%',
              }}
            >
              <h4>Resultado da Pesquisa</h4>
              <h4>
                Cultura desejada:{' '}
                <span>{selectedCulturaData.conta_culturas[0].descricao}</span>
              </h4>
              <h4>
                Período{' '}
                <span>
                  {moment(
                    selectedCulturaData.conta_culturas[0].data_inicio
                  ).format('DD-MM-YYYY')}{' '}
                  à{' '}
                  {moment(
                    selectedCulturaData.conta_culturas[0].data_final
                  ).format('DD-MM-YYYY')}
                </span>
              </h4>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <h1>O relatório será exibido aqui quando for gerado.</h1>
              <img style={{ maxWidth: 200 }} src={ReportLogo} />
            </div>
          )}

          {isSearched ? (
            <div>
              <Button onClick={handlePdf}>Exportar documento</Button>
            </div>
          ) : null}
        </div>
        {!isDownloading ? (
          isSearched ? (
            <Relatorios
              grafico_2={novoGrafico}
              grafico_1={graficoOne}
              instalado={instalado}
              graficoFour={graficoFour}
            />
          ) : null
        ) : (
          <Download
            instalado={instalado}
            downloads={typeDownload}
            close={closeDownload}
            grafico_2={novoGrafico}
            grafico_1={graficoOne}
            graficoFour={graficoFour}
          />
        )}
      </div>
    </div>
  )
}

const Relatorios = (props) => (
  <div>
    <Charts data={props.instalado} />
    <ChartsTwo data={props.grafico_1} />
    <TableTwo dataSource={props.grafico_2} />
    <Table data={props.graficoFour} />
  </div>
)
const Download = (props) => (
  <Pdf
    instalado={props.instalado}
    grafico_2={props.grafico_2}
    grafico_1={props.grafico_1}
    downloads={props.downloads}
    close={props.close}
    graficoFour={props.graficoFour}
  />
)

export default App
