import styled from 'styled-components'

import { Form as FormAntd } from 'antd'

export const Form = styled(FormAntd)`
  .select {
    margin-bottom: 0px;
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
`
