export const parseSalarioFaixa = async (
  min: number | undefined,
  max: number
): Promise<string | undefined> => {
  let salario_faixa

  if (min && max) {
    salario_faixa = `R$${min} - R$${max}`
  } else if (min) {
    salario_faixa = `R$${min} - ?`
  } else if (max) {
    salario_faixa = `? - R$${max}`
  }

  return salario_faixa
}
