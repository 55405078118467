// TYPES
export const SET_RELATORIOSTIMES_TIME_SELECIONADO =
  'SET_RELATORIOSTIMES_TIME_SELECIONADO'

export const GET_RELATORIOSTIMES_CULTURA = 'GET_RELATORIOSTIMES_CULTURA'

export const GET_RELATORIOSTIMES_CULTURA_ORGANIZACIONAL =
  'GET_RELATORIOSTIMES_CULTURA_ORGANIZACIONAL'

export const GET_RELATORIOSTIMES_TIMES = 'GET_RELATORIOSTIMES_TIMES'

export const GET_RELATORIOSTIMES_VETOR = 'GET_RELATORIOSTIMES_VETOR'

export const GET_RELATORIOSTIMES_PERFIS = 'GET_RELATORIOSTIMES_PERFIS'

export const GET_RELATORIOSTIMES_TOP_ARQ = 'GET_RELATORIOSTIMES_TOP_ARQ'

// URLS
export const URL_DASHBOARD = `${process.env.REACT_APP_API_URL}/dashboard`

export const URL_RELATORIOSTIMES_CULTURA_ORGANIZACIONAL = (id) =>
  `${URL_DASHBOARD}/grupo/culturaOrganizacional/${id}`

export const URL_RELATORIOSTIMES_CULTURA = (id) =>
  `${URL_DASHBOARD}/grupo/cultura/${id}`

export const URL_RELATORIOSTIMES_TIMES = `${URL_DASHBOARD}/grupos`

export const URL_RELATORIOSTIMES_VETOR = (id) =>
  `${URL_DASHBOARD}/grupo/vetor/${id}`

export const URL_RELATORIOSTIMES_PERFIS = (id) =>
  `${URL_DASHBOARD}/grupo/perfis/${id}`

export const URL_RELATORIOSTIMES_TOP_ARQ = `${URL_DASHBOARD}/topArquetipos`
