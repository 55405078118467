// types
export const PS_SLA_LIST = 'PS_SLA_LIST'
export const PS_SLA_STORE = 'PS_SLA_STORE'
export const PS_SLA_UPDATE = 'PS_SLA_UPDATE'
export const PS_SLA_DELETE = 'PS_SLA_DELETE'
export const PS_SLA_LOAD = 'PS_SLA_LOAD'

// urls
export const URL_PS_SLA = `${process.env.REACT_APP_API_URL}/psSla`
export const URL_PS_SLA_ID = (id) =>
  `${process.env.REACT_APP_API_URL}/psSla/${id}`
