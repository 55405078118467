import React, { useEffect, useState, useCallback, useMemo } from 'react'

import moment from 'moment'
import 'moment-timezone'

import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tooltip,
} from 'antd'

import {
  CheckOutlined,
  DeleteOutlined,
  MailOutlined,
  PlusOutlined,
  ProfileOutlined,
  SearchOutlined,
} from '@ant-design/icons'

import { ColumnsType } from 'antd/lib/table'

import { toast } from 'react-toastify'

// import { Container } from './styles';

import api from '../../services/api'

import {
  IFinishAcaoFn,
  ICreateAcao,
  ICreatePDIFn,
  IDeleteAcaoFn,
  IPdi,
  IUpdateAcaoFn,
  IUpdatePDI,
  IGetColumnSearchProps,
} from './@types'

import { IFeedback, IFeedbackOption } from '../feedback/@types'

// Components
import CreatePdi from './components/CreatePdi'
import AdicionarNovaAcao from './components/AdicionarNovaAcao'
import AcoesTable from './components/AcoesTable'

// Styles
import { PdiButton, Header, Label } from './styles'
import PdiItem from './components/PdiItem'

interface PdiProps {
  pes_id: number
}

const Pdi: React.FC<PdiProps> = ({ pes_id }, searchInput) => {
  const [pdis, setPdis] = useState<IPdi[]>([])

  const [loadingPdis, setLoadingPdis] = useState(false)

  const [loadingFeedbacks, setLoadingFeedbacks] = useState(false)

  const [feedbacksOptions, setFeedbacksOptions] = useState<IFeedbackOption[]>(
    []
  )

  const [currentPdiId, setCurrentPdiId] = useState<number>()

  const [creatingPdi, setCreatingPdi] = useState(false)

  const [savingPdi, setSavingPdi] = useState<boolean>(false)

  const [creatingAcao, setCreatingAcao] = useState(false)

  const [showModalPdiDetails, setShowModalPdiDetails] = useState(false)

  const [showModalNewPdi, setShowModalNewPdi] = useState(false)

  const [showModalNewAcao, setShowModalNewAcao] = useState(false)

  const [searchText, setSearchText] = useState<string>()

  const loadPdis = useCallback(async () => {
    setLoadingPdis(true)

    try {
      const response = await api.get(`/pessoas/${pes_id}/pdis`)

      if (response.status === 200) {
        setPdis(response.data)
      }
    } finally {
      setLoadingPdis(false)
    }
  }, [pes_id])

  const loadFeedbacks = useCallback(async () => {
    setLoadingFeedbacks(true)
    try {
      const response = await api.get<IFeedback[]>(
        `/pessoas/${pes_id}/feedbacks`
      )

      if (response.status === 200) {
        setFeedbacksOptions([
          ...response.data.map((feedback) => ({
            label: moment(feedback.created_at)
              .utc()
              .tz(moment.tz.guess())
              .format('DD/MM/YYYY (HH:mm)'),
            value: feedback.feedback_id,
          })),
        ])
      }
    } finally {
      setLoadingFeedbacks(false)
    }
  }, [pes_id])

  useEffect(() => {
    if (pes_id) {
      loadPdis()
      loadFeedbacks()
    }
  }, [loadFeedbacks, loadPdis, pes_id])

  const toggleModalNewPdi = useCallback(() => {
    setShowModalNewPdi((state) => !state)
  }, [])

  const handleCreatePDI = useCallback(
    async ({ feedback_id, objetivo }: ICreatePDIFn) => {
      if (pes_id) {
        setCreatingPdi(true)

        try {
          const response = await api.post(`/pdis`, {
            feedback_id,
            objetivo,
            pes_id,
          })

          if (response.status === 200) {
            loadPdis()
            toast.success('PDI criado com sucesso.')
            toggleModalNewPdi()
          }
        } finally {
          setCreatingPdi(false)
        }
      }
    },
    [loadPdis, pes_id, toggleModalNewPdi]
  )

  const handleUpdatePDI = useCallback(
    async (data: Omit<IUpdatePDI, 'pdi_id'>) => {
      if (currentPdiId) {
        setSavingPdi(true)

        try {
          await api.put(`/pdis/${currentPdiId}`, data)

          loadPdis()
          toast.success('PDI atualizado com sucesso.')
        } finally {
          setSavingPdi(false)
        }
      }
    },
    [currentPdiId, loadPdis]
  )

  const handleDeletePDI = useCallback(
    async (pdi_id: number) => {
      const response = await api.delete(`/pdis/${pdi_id}`)

      if (response.status === 200) {
        loadPdis()
        toast.success('PDI excluído com sucesso.')
      }
    },
    [loadPdis]
  )

  const handleFinishPDI = useCallback(
    async (pdi_id: number) => {
      const response = await api.post(`/pdis/${pdi_id}/finish`)

      if (response.status === 200) {
        loadPdis()
        toast.success('PDI concluído com sucesso.')
      }
    },
    [loadPdis]
  )

  const handleSendEmailPdi = useCallback(async (pdi_id: number) => {
    const response = await api.post(`/pdis/${pdi_id}/sendEmail`)

    if (response.status === 204) {
      toast.success('E-mail com os detalhes do PDI enviado com sucesso.')
    }
  }, [])

  const toggleModalPdiDetails = useCallback(
    async (pdi_id?: number) => {
      if (showModalPdiDetails) {
        setCurrentPdiId(undefined)
        setShowModalPdiDetails(false)
      } else {
        setCurrentPdiId(pdi_id)
        setShowModalPdiDetails(true)
      }
    },
    [showModalPdiDetails]
  )

  const toggleModalNewAcao = useCallback(() => {
    if (showModalNewAcao) {
      setShowModalNewAcao(false)
    } else {
      setShowModalNewAcao(true)
    }
  }, [showModalNewAcao])

  const handleCreateAcao = useCallback(
    async ({ descricao, sabotadores, opcoes, startAndEnd }: ICreateAcao) => {
      if (currentPdiId) {
        try {
          setCreatingAcao(true)

          const response = await api.post(`/pdis/${currentPdiId}/acoes`, {
            descricao,
            sabotadores,
            opcoes,
            start: startAndEnd[0],
            end: startAndEnd[1],
          })

          if (response.status === 200) {
            loadPdis()
            toast.success('Ação criada com sucesso.')
            toggleModalNewAcao()
          }
        } finally {
          setCreatingAcao(false)
        }
      }
    },
    [currentPdiId, loadPdis, toggleModalNewAcao]
  )

  const handleUpdateAcao = useCallback(
    async ({
      pdi_id,
      acao_id,
      descricao,
      end,
      opcoes,
      sabotadores,
      start,
    }: IUpdateAcaoFn) => {
      const response = await api.put(`/pdis/${pdi_id}/acoes/${acao_id}`, {
        descricao,
        end,
        opcoes,
        sabotadores,
        start,
      })

      if (response.status === 200) {
        loadPdis()
        toast.success('Ação atualizada com sucesso.')
      }
    },
    [loadPdis]
  )

  const handleDeleteAcao = useCallback(
    async ({ pdi_id, acao_id }: IDeleteAcaoFn) => {
      const response = await api.delete(`/pdis/${pdi_id}/acoes/${acao_id}`)

      if (response.status === 204) {
        loadPdis()
        toast.success('Ação excluída com sucesso.')
      }
    },
    [loadPdis]
  )

  const handleFinishAcao = useCallback(
    async ({ pdi_id, acao_id }: IFinishAcaoFn) => {
      const response = await api.post(`/pdis/${pdi_id}/acoes/${acao_id}/finish`)

      if (response.status === 200) {
        loadPdis()
        toast.success('Ação concluída com sucesso.')
      }
    },
    [loadPdis]
  )

  const columns: ColumnsType<IPdi> = useMemo(() => {
    return [
      {
        title: 'Objetivo',
        dataIndex: 'objetivo',
        align: 'center',
        width: '30%',
      },
      {
        title: 'Feedback',
        width: '15%',
        dataIndex: ['feedback', 'created_at'],
        align: 'center',

        render: (text: string, record: IPdi) => {
          return text
            ? moment(text)
                .utc()
                .tz(moment.tz.guess())
                .format('DD/MM/YYYY (HH:mm)')
            : ''
        },
      },
      {
        title: 'Criado por',
        width: '20%',
        dataIndex: ['creator', 'colaborador', 'pes_nome'],
        align: 'center',
        render: (text: string, record: IPdi) => {
          return (
            <>
              {record.creator && (
                <>
                  <span>{record.creator.colaborador.pes_nome}</span>
                </>
              )}
              {record.creator && record.created_at && <br />}
              {record.created_at && (
                <span>
                  {moment(record.created_at)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('(DD/MM/YYYY [às] HH:mm)')}
                </span>
              )}
            </>
          )
        },
      },
      {
        title: 'Atualizado por',
        width: '20%',
        dataIndex: ['updater', 'colaborador', 'pes_nome'],
        align: 'center',
        render: (text: string, record: IPdi) => {
          return (
            <>
              {record.updater && (
                <>
                  <span>{record.updater.colaborador.pes_nome}</span>
                </>
              )}
              {record.updater && record.updated_at && <br />}
              {record.updated_at && (
                <span>
                  {moment(record.updated_at)
                    .utc()
                    .tz(moment.tz.guess())
                    .format('(DD/MM/YYYY [às] HH:mm)')}
                </span>
              )}
            </>
          )
        },
      },
      {
        title: 'Ações',
        align: 'center',
        width: '15%',
        render: (_, record) => (
          <>
            <Tooltip title="Ver detalhes">
              <a>
                <ProfileOutlined
                  onClick={() => toggleModalPdiDetails(record.pdi_id)}
                />
              </a>
            </Tooltip>
            {!record.isFinished && (
              <>
                <Divider type="vertical" />
                <Popconfirm
                  title="Deseja finalizar este PDI ?"
                  onConfirm={() => handleFinishPDI(record.pdi_id)}
                >
                  <Tooltip title="Finalizar">
                    <a>
                      <CheckOutlined />
                    </a>
                  </Tooltip>
                </Popconfirm>
              </>
            )}

            <Divider type="vertical" />
            <Popconfirm
              title="Deseja excluir este PDI ?"
              onConfirm={() => handleDeletePDI(record.pdi_id)}
            >
              <Tooltip title="Excluir">
                <a>
                  <DeleteOutlined />
                </a>
              </Tooltip>
            </Popconfirm>

            <Divider type="vertical" />

            <Popconfirm
              title="Enviar e-email do PDI?"
              onConfirm={() => {
                handleSendEmailPdi(record.pdi_id)
              }}
              okText="Sim"
              cancelText="Não"
            >
              <Tooltip title="Enviar e-mail" placement="top">
                <a>
                  <MailOutlined />
                </a>
              </Tooltip>
            </Popconfirm>
          </>
        ),
      },
    ]
  }, [
    handleDeletePDI,
    handleFinishPDI,
    handleSendEmailPdi,
    toggleModalPdiDetails,
  ])

  const handleSearch = (
    selectedKeys: number[] | string[],
    confirm: Function
  ) => {
    confirm()
    setSearchText(selectedKeys[0].toString())
  }

  const handleReset = (clearFilters: Function) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: IGetColumnSearchProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder="Buscar"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value: string, record: Record<string, any>) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  const currentPdi = useMemo(() => {
    if (!currentPdiId) return undefined

    return pdis.find((pdi) => pdi.pdi_id === currentPdiId)
  }, [currentPdiId, pdis])

  return (
    <Col span={24}>
      <Row gutter={20}>
        <Col xs={24} sm={24}>
          <Table
            loading={loadingPdis}
            dataSource={pdis}
            columns={columns}
            title={() => (
              <Header>
                <Label>Planos de Desenvolvimento Individual</Label>
                <PdiButton
                  type="primary"
                  onClick={() => toggleModalNewPdi()}
                  icon={<PlusOutlined />}
                >
                  NOVO PDI
                </PdiButton>
              </Header>
            )}
          />
        </Col>
      </Row>
      <Modal
        title="Criar novo PDI"
        destroyOnClose
        visible={showModalNewPdi}
        onCancel={() => toggleModalNewPdi()}
        okText="Salvar"
        cancelText="Cancelar"
        okButtonProps={{
          form: 'new-pdi',
          htmlType: 'submit',
          loading: creatingPdi,
        }}
        closable={!creatingPdi}
      >
        <CreatePdi
          onFinish={handleCreatePDI}
          feedbacksOptions={feedbacksOptions}
        />
      </Modal>
      <Modal
        title="Plano de Desenvolvimento Individual"
        destroyOnClose
        visible={showModalPdiDetails}
        onCancel={() => toggleModalPdiDetails()}
        cancelText="Fechar"
        okButtonProps={{
          hidden: true,
        }}
        closable={!creatingPdi}
        width="80%"
        centered
      >
        <PdiItem
          data={currentPdi}
          handleUpdatePDI={handleUpdatePDI}
          saving={savingPdi}
          isFinished={currentPdi ? currentPdi.isFinished : false}
          feedbacksOptions={feedbacksOptions}
          initialValues={currentPdi}
        >
          <AcoesTable
            data={currentPdi && currentPdi.acoes ? currentPdi.acoes : []}
            handleDeleteAcao={handleDeleteAcao}
            handleFinishAcao={handleFinishAcao}
            pdi={currentPdi}
            handleUpdateAcao={handleUpdateAcao}
            toggleModalNewAcao={toggleModalNewAcao}
            isPdiFinished={currentPdi ? currentPdi.isFinished : false}
            getColumnSearchProps={getColumnSearchProps}
            loading={loadingPdis}
          />
        </PdiItem>
      </Modal>
      <Modal
        title="Criar nova ação"
        destroyOnClose
        visible={showModalNewAcao}
        onCancel={() => toggleModalNewAcao()}
        okText="Salvar"
        cancelText="Cancelar"
        okButtonProps={{
          form: 'pdi-new-acao',
          htmlType: 'submit',
          loading: creatingAcao,
        }}
        closable={!creatingAcao}
      >
        <AdicionarNovaAcao onFinish={handleCreateAcao} />
      </Modal>
    </Col>
  )
}

export default Pdi
