import { toast } from 'react-toastify'

import * as constants from '../constants/vetorIndividualShareConstants'

import { parseVetor, parseCulturas } from '../../../utils/utils'

const initialState = {
  disc: [],
  cultura: [],
  culturaEmpresa: [],
  perfilComp: [],
  intensidade: [
    {
      row: '',
      essencia: '',
      exigido: '',
      entrega: '',
    },
  ],
  rct: [],
  edl: [],
  foc: [],
  ode: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  oct: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  tdd: {
    maior: {
      nome: '',
      score: '',
    },
    menor: {
      nome: '',
      score: '',
    },
  },
  cor: [],
  erc: [],
  edc: [],
  principal: {},
  vetores: [],
  fitCulturalEmpresa: {},
  pes_nome: undefined,
  pes_id: undefined,
  hash: undefined,
  avalia_id: undefined,
}

export const vetorIndividualShare = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_VETOR_INDIVIDUAL_SHARE: {
      if (action.response.status !== 200) {
        return state
      }

      const { data } = action.response.data

      const { cultura, culturaEmpresa, pes_nome } = data

      const newCulturas = Object.keys(cultura)
        .filter((key) => constants.CULTURAS.includes(key))
        .map((key) => ({
          Tipo: key,
          Valor: Number(cultura[key]),
          Label: pes_nome,
        }))

      const { cultura: culturaEmpresaParsed } = parseCulturas({
        culturaDesejada: culturaEmpresa,
      })

      const vetor = parseVetor(data)

      return {
        ...state,
        ...vetor,
        cultura: newCulturas,
        culturaEmpresa: culturaEmpresaParsed,
      }
    }
    default:
      return state
  }
}
