import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { load } from './actions/vetorIndividualShareActions'

import VetorComportamentalShareView from './VetorIndividualShareFitView'

function VetorComportamentalShare(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    const { hash } = props.match.params
    dispatch(load(hash))
  }, [dispatch, props.match.params])

  const cor = useSelector((state) => state.vetorIndividualShare.cor)
  const edc = useSelector((state) => state.vetorIndividualShare.edc)
  const edl = useSelector((state) => state.vetorIndividualShare.edl)
  const erc = useSelector((state) => state.vetorIndividualShare.erc)
  const foc = useSelector((state) => state.vetorIndividualShare.foc)
  const oct = useSelector((state) => state.vetorIndividualShare.oct)
  const ode = useSelector((state) => state.vetorIndividualShare.ode)
  const rct = useSelector((state) => state.vetorIndividualShare.rct)
  const tdd = useSelector((state) => state.vetorIndividualShare.tdd)

  const disc = useSelector((state) => state.vetorIndividualShare.disc)
  const intensidade = useSelector(
    (state) => state.vetorIndividualShare.intensidade
  )
  const perfilComp = useSelector(
    (state) => state.vetorIndividualShare.perfilComp
  )
  const pes_nome = useSelector((state) => state.vetorIndividualShare.pes_nome)
  const principal = useSelector((state) => state.vetorIndividualShare.principal)
  const fitCulturalEmpresa = useSelector(
    (state) => state.vetorIndividualShare.fitCulturalEmpresa
  )
  const cultura = useSelector((state) => state.vetorIndividualShare.cultura)
  const culturaEmpresa = useSelector(
    (state) => state.vetorIndividualShare.culturaEmpresa
  )
  const [showGraficoRadar, setShowGraficoRadar] = useState(true)

  const handleSwitchGraficoRadar = () => setShowGraficoRadar(!showGraficoRadar)

  return (
    <VetorComportamentalShareView
      {...{
        cor,
        disc,
        edc,
        edl,
        erc,
        foc,
        oct,
        ode,
        rct,
        tdd,
        intensidade,
        perfilComp,
        pes_nome,
        principal,
        fitCulturalEmpresa,
        showGraficoRadar,
        handleSwitchGraficoRadar,
        cultura,
        culturaEmpresa,
      }}
    />
  )
}

export default VetorComportamentalShare
