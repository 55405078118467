export const cols = {
  VALOR: {
    min: 0,
    formatter(val) {
      return val + '%'
    },
  },
  percent: {
    formatter: (val) => (val = `${(val * 100).toFixed(1)}%`),
  },
  value: {
    formatter: (val) => (val = `${(val * 100).toFixed(1)}%`),
  },
}

export const pontosFortesFields = [
  'Forte',
  'Fraco',
  'Muito Fraco',
  'Muito forte',
]

export const impedimentoFields = [
  'Às vezes',
  'Frequentemente',
  'Nunca',
  'Sempre',
]

export const principalProblemaFields = [
  'Às vezes',
  'Frequentemente',
  'Nunca',
  'Sempre',
]
