// Redux types
import * as constants from '../constants/constantsLogin'
import { message } from 'antd'

const initialState = {
  user: {
    name: '',
    email: '',
  },
}

export const login = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN_POST:
      if (action.response.status !== 200) message.error('Erro ao fazer login.')

      return state

    case constants.SALVAR_ONBOARDING:
      const { onboarding } = action

      return { ...state, onboarding }

    case constants.INFO_GET:
      const { response } = action

      if (response.status === 200) {
        return {
          ...state,
          user: {
            name: response.data.user.user_nome || response.data.user.pes_nome,
            email:
              response.data.user.user_email || response.data.user.pes_email,
          },
        }
      }

      return { ...state }

    default:
      return state
  }
}
