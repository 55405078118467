import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { Form, Row, Col, Input, Steps } from 'antd'

// Constants

const { Step } = Steps

function AdicionarNovoCargo({ onFinish, initialValues, showModalNovoCargo }) {
  const [form] = Form.useForm()

  const [valorInvalido, setValorInvalido] = useState(false)

  /* Não remover rule */
  const validationNomeCargo = (rule, value, callback) => {
    if (!value.match(/^(?!\s+$).*/)) {
      setValorInvalido(true)
      return callback('Por favor, insira um nome válido!')
    }
    setValorInvalido(false)
    return callback()
  }

  const [maximoValor, setMaximoValor] = useState(0)

  const handleVerificarMaximoValor = (e) => {
    setMaximoValor(e.target.value.length)
  }

  return (
    <div className="adicionar-colaboradores">
      <Form
        form={form}
        id="novo-cargo-submit"
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValues}
      >
        <Row gutter={20}>
          {/* {showModalNovoCargo && (
            <Col md={24} style={{ marginBottom: "32px" }}>
              <Steps size="small" current={0}>
                <Step title="Novo Cargo" />
                <Step title="Atividade" />
                <Step title="Ferramenta" />
                <Step title="Requisito" />
                <Step title="Tecnica" />
              </Steps>
            </Col>
          )} */}
          <Col md={14}>
            <Form.Item
              label="Cargo"
              name="nome"
              style={{
                marginBottom: 3,
              }}
              rules={[
                {
                  required: true,
                  message: 'Informe o nome do cargo!',
                },
                {
                  validator: validationNomeCargo,
                  message: 'Insira um nome válido!',
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Digite o cargo"
                onChange={(e) => handleVerificarMaximoValor(e)}
                maxLength={81}
              />
            </Form.Item>
            <p
              style={{
                color: maximoValor >= 70 ? 'red' : '',
                marginTop: valorInvalido ? -9 : -6,
              }}
            >
              {maximoValor <= 9
                ? maximoValor.toString().padStart(2, '0')
                : maximoValor}
              /81
            </p>
          </Col>
          <Col md={10}>
            <Form.Item label="CBO" name="cbo">
              <Input type="text" placeholder="Digite o CBO" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col md={10}>
            <Form.Item label="Piso salarial" name="piso">
              <Input
                type="number"
                placeholder="Digite o piso salarial"
                prefix="R$"
                withoutMargin
              />
            </Form.Item>
          </Col>
          <Col md={14}>
            <Form.Item label="Lotação" name="lotacao">
              <Input type="text" placeholder="Digite a lotação" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Form.Item label="Propósito do cargo" name="proposito">
              <Input.TextArea placeholder="Digite a descrição" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Form.Item label="Objetivo do cargo" name="descricao">
              <Input.TextArea placeholder="Digite a descrição" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default injectIntl(AdicionarNovoCargo)
