import React, { useState, useEffect, useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { toast } from 'react-toastify'

import { Row, Typography, Divider, Alert, Button, Col, Spin, Tag } from 'antd'

import { PlayCircleFilled } from '@ant-design/icons'

// Services
import { getContaURL } from '../../../../../services/auth'

import { FormularioQuizHeader, Container, CompanyLogo, Loading } from './styles'

import {
  getAvaliacaoByHash,
  loadQuiz,
  loadCompanyLogo,
  postResposta,
  loadNota,
} from './actions/actionsFormularioQuiz'

import Questao from './components/Questao'
import Apresentacao from './components/Apresentacao'
import Questoes from './components/Questoes'

const { Title } = Typography

function FormularioQuiz({ match, history }) {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  const [showNotFound, setShowNotFound] = useState(false)

  const [avaliaId, setAvaliaId] = useState(null)

  const [companyLogo, setCompanyLogo] = useState(null)

  const [informacoesQuiz, setInformacoesQuiz] = useState(null)

  const [questoesQuiz, setQuestoesQuiz] = useState(null)

  const [resultado, setResultado] = useState(null)

  const [showApresentacao, setShowApresentacao] = useState(true)

  const [showQuestoes, setShowQuestoes] = useState(false)

  const [showEncerramento, setShowEncerramento] = useState(false)

  const [showTimeout, setShowTimeout] = useState(false)

  const resposta = useSelector((state) => state.formularioQuiz.resposta)

  const [roar, setRoar] = useState(0)

  const contaURL = window.location.href.split('/').slice(-3, -1)[0]

  const initResposta = useCallback(
    (quizQuestao) => {
      let arrayRespostas = []

      quizQuestao.forEach((questao) => {
        arrayRespostas = [...arrayRespostas, { questao_id: questao.questao_id }]
      })

      dispatch({ type: 'INIT_RESPOSTA', data: arrayRespostas })
    },
    [dispatch]
  )

  useEffect(() => {
    const { hash } = match.params

    setLoading(true)

    dispatch(loadCompanyLogo(contaURL)).then(({ response }) => {
      if (response.status === 200) {
        const logoParam = response.data.filter((item) => item.param === 'logo')
        setCompanyLogo(logoParam[0].customParam.url)
      }
    })

    dispatch(getAvaliacaoByHash(hash)).then(({ response }) => {
      if (response.status !== 200) {
        setShowNotFound(true)
        history.push('/')
      } else {
        const { quiz_id } = response.data.quizzavaliacao

        const { avalia_id } = response.data.quizzavaliacao

        setAvaliaId(avalia_id)

        dispatch(loadQuiz(quiz_id)).then(({ response }) => {
          const {
            ativo,
            instrucoes,
            msg_encerramento,
            nota_min,
            objetivo,
            tempo_limite,
            titulo,
            exibir_resultado,
            quizQuestao,
          } = response.data.quizz[0]

          setQuestoesQuiz(quizQuestao)

          setInformacoesQuiz({
            ativo,
            instrucoes,
            msg_encerramento,
            nota_min,
            objetivo,
            tempo_limite,
            titulo,
            exibir_resultado,
          })

          initResposta(quizQuestao)

          setLoading(false)
        })
      }
    })
  }, [dispatch, history, match, companyLogo, initResposta, contaURL])

  const handlePostResposta = useCallback(
    (avalia_id, resposta) => {
      dispatch(postResposta(avalia_id, { respostas: resposta })).then(
        ({ response }) => {
          if (response.status !== 201) {
            toast.error('Houve um problema ao gravar a sua resposta.')
          } else {
            dispatch(loadNota(avalia_id)).then(({ response }) => {
              setResultado(response.data)
              setShowEncerramento(true)
            })
          }
        }
      )
    },
    [dispatch, setResultado]
  )

  const handleIniciar = () => {
    setShowApresentacao(false)
    setShowQuestoes(true)
  }

  const handleEncerrar = () => {
    setShowQuestoes(false)
    handlePostResposta(avaliaId, resposta)
    setShowEncerramento(true)
  }

  const handleTimeout = () => {
    setShowQuestoes(false)
    handlePostResposta(avaliaId, resposta)
    setShowTimeout(true)
  }

  return (
    <>
      {loading ? (
        <Loading>
          <Spin size="large" />
        </Loading>
      ) : (
        <>
          <FormularioQuizHeader justify="space-between" align="middle">
            <CompanyLogo src={companyLogo} small />
            <span>Teste por TeamHub</span>
          </FormularioQuizHeader>

          <Col xs={{ offset: 1, span: 22 }} md={{ offset: 2, span: 20 }}>
            {showNotFound && (
              <Container>
                <Title>Avaliação não encontrada.</Title>
                <p>Por favor, verifique o seu link para a avaliação.</p>
              </Container>
            )}

            {showApresentacao && informacoesQuiz.ativo === 1 && (
              <Apresentacao
                companyLogo={companyLogo}
                informacoesQuiz={informacoesQuiz}
                handleIniciar={handleIniciar}
              />
            )}

            {showApresentacao && informacoesQuiz.ativo === 0 && (
              <Container>
                <Title>Teste inativo</Title>
                <p>
                  Esse teste não pode mais receber respostas por já ter sido
                  respondido ou porque o processo seletivo dessa vaga já foi
                  finalizado.
                </p>
              </Container>
            )}

            {showQuestoes && (
              <Questoes
                preview={false}
                questoesQuiz={questoesQuiz}
                tempo_limite={informacoesQuiz.tempo_limite}
                handleEncerrar={handleEncerrar}
                handleTimeout={handleTimeout}
              />
            )}

            {showEncerramento && (
              <Container>
                <CompanyLogo src={companyLogo} large />
                <Title level={2}>Fim de Teste</Title>

                <p>{informacoesQuiz.msg_encerramento}</p>

                {informacoesQuiz.exibir_resultado === 1 && resultado && (
                  <>
                    <p>Sua pontuação</p>
                    <Title>
                      {resultado.nota} / {resultado.questao_quantidade}
                    </Title>
                    {resultado.statusResposta === 0 && (
                      <Tag color="red">Reprovado</Tag>
                    )}
                    {resultado.statusResposta === 1 && (
                      <Tag color="green">Aprovado</Tag>
                    )}
                  </>
                )}
              </Container>
            )}

            {showTimeout && (
              <Container>
                <CompanyLogo src={companyLogo} large />

                <Title level={2}>Teste encerrado</Title>

                <p>
                  O seu limite de tempo para a completude da avaliação chegou ao
                  fim, por isso, o seu teste foi encerrado.
                </p>
                <p>As questões respondidas foram enviadas.</p>
              </Container>
            )}
          </Col>
        </>
      )}
    </>
  )
}

export default FormularioQuiz
