/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-no-comment-textnodes */
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { Form, Input, Row } from 'antd'
import React, { useEffect } from 'react'
import TinyEditor from '../../../../components/TinyEditor'
import {
  AnexoWrapper,
  ButtonUpload,
  ContainerAnexos,
  EditorLegend,
} from './styles'

export default function EditarTemplateEmailAutomatico({
  onFinish,
  initialValues,
  updateFile,
  files,
  type = 'update',
  handleRemoveFile,
  currentId,
  parametros,
}) {
  const [form] = Form.useForm()

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        grupos: initialValues.grupos
          ? initialValues.grupos.map((grupo) => grupo.grupo_id)
          : [],
      })
    }
  }, [form, initialValues])

  function renderFileNames() {
    const render = []
    const filesFilter = files.filter((file) => file.idemail === currentId)
    if (type === 'update' && files) {
      return (
        <ContainerAnexos>
          {filesFilter.map((file) =>
            render.push(
              <Form.Item key={file.idanexo}>
                <AnexoWrapper>
                  <a target="_blank" href={file.url}>
                    <span>
                      {file.titulo.length > 12
                        ? `${file.titulo.substring(0, 30)}...pdf`
                        : `${file.titulo}.pdf`}
                    </span>
                  </a>
                  <a>
                    <DeleteOutlined
                      className="delete-anexo"
                      onClick={() => handleRemoveFile(file.idanexo)}
                    />
                  </a>
                </AnexoWrapper>
              </Form.Item>
            )
          )}
        </ContainerAnexos>
      )
    }
    return render
  }

  function renderParams() {
    const render = []
    parametros &&
      parametros.length > 0 &&
      render.push(
        <EditorLegend>
          <ul>
            {parametros.map((param) => (
              <li key={param.id}>
                <strong>{`{${param.param}}`}</strong> - {param.legend}
              </li>
            ))}
          </ul>
        </EditorLegend>
      )

    return render
  }

  return (
    <Form
      layout="vertical"
      id="editar-email-automatico"
      onFinish={onFinish}
      initialValues={
        initialValues
          ? {
              ...initialValues,
              grupos: initialValues.grupos
                ? initialValues.grupos.map((grupo) => grupo.grupo_id)
                : [],
            }
          : undefined
      }
      form={form}
    >
      <Form.Item label="Descrição do template" name="descricao">
        <Input type="text" style={{ width: '100%' }} disabled />
      </Form.Item>
      <Form.Item
        label="Assunto do e-mail"
        name="assunto"
        rules={[
          {
            required: true,
            message: 'Por favor, preencha o assunto do e-mail.',
          },
        ]}
      >
        <Input type="text" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item label="CCO" name="cco">
        <Input
          type="text"
          style={{ width: '100%' }}
          placeholder="Para mais de um email, separe com ponto e vírgula. Ex: joao@email.com; maria@email.com"
        />
      </Form.Item>

      <Form.Item
        style={{
          width: 240,
        }}
      >
        <ButtonUpload type="submit">
          <input
            type="file"
            multiple
            accept="application/pdf"
            onChange={updateFile}
          />
          <UploadOutlined /> ADICIONAR ARQUIVOS
        </ButtonUpload>
      </Form.Item>

      <Form.Item>
        <Row>{renderFileNames()}</Row>
      </Form.Item>

      <Form.Item
        label="TEMPLATE"
        name="textoemail"
        rules={[
          {
            required: true,
            message: 'Por favor, insira o template de e-mail.',
          },
        ]}
      >
        <TinyEditor disabled={false} />
      </Form.Item>

      <Form.Item>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {renderParams()}
        </Row>
      </Form.Item>
    </Form>
  )
}
