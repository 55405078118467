import React from 'react'

import { Form, Input } from 'antd'

function NovaEtapa({ handleChangeNovaEtapa, etapa, onFinish }) {
  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      initialValues={{ etapa }}
      id="nova-etapa-submit"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        name="etapa"
        rules={[
          {
            required: true,
            message: 'Por favor, preencha o nome da etapa.',
          },
          {
            whitespace: true,
            max: 20,
            message: 'Nome da etapa deve conter no máximo 20 caracteres.',
          },
        ]}
      >
        <Input
          onChange={(e) => handleChangeNovaEtapa(e.target.value)}
          placeholder="Nome da nova etapa"
          size="middle"
          value={etapa}
        />
      </Form.Item>
    </Form>
  )
}

export default NovaEtapa
