import React from 'react'
import { CSVLink } from 'react-csv'
import { toast } from 'react-toastify'
import { injectIntl } from 'react-intl'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Divider, Table, Popconfirm, message, Tooltip } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import moment from 'moment'
import {
  DashboardOutlined,
  LinkOutlined,
  MailOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { sorterString, sorterDate } from '../../../../utils/utils'
import Link from '../../../../components/privateLink/PrivateLink'

// Components
import Button from '../../../../components/button/Button'

import { Range } from '../../../../components/input/Input'

// CSS

// Constants
import { modals } from '../../constants/constantsGestaoPesquisas'

// Utils
import { getContaURL } from '../../../../services/auth'

function TableGestaoPesquisas({
  openModal,
  modalId,
  dataSource,
  rowSelection,
  getColumnSearchProps,
  exportarExcel,
  sendVetorComportamental,
  sendPercepcao,
  cancelVetorComportamental,
  cancelPercepcao,
  getPesquisas,
  type = 'colaborador',
  handleNewLink,
  cancelValoresOrganizacionais,
  sendValoresOrganizacionais,
}) {
  const contaUrl = getContaURL()

  // Table columns
  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      width: '5%',
      filters: [
        {
          text: 'Respondida',
          value: 1,
        },
        {
          text: 'Não respondida',
          value: 0,
        },
      ],
      onFilter: (value, record) => {
        return record.status === value
      },
      render: (text, record) =>
        text === 0 ? (
          <span className="gestao-pesquisas__status gestao-pesquisas__status--nao-respondido" />
        ) : (
          <span className="gestao-pesquisas__status gestao-pesquisas__status--respondido" />
        ),
    },
    {
      title: 'Pesquisa',
      dataIndex: 'tipo',
      filters: [
        {
          text: 'Perfil Comportamental',
          value: 'Perfil Comportamental',
        },
        {
          text: 'Pesquisa de Percepção',
          value: 'Pesquisa de Percepção',
        },
        {
          text: 'Valores Organizacionais',
          value: 'Valores Organizacionais',
        },
      ],
      onFilter: (value, record) => {
        return record.tipo && record.tipo.indexOf(value) === 0
      },
      width: '10%',
    },
    {
      title: 'Nome',
      dataIndex: 'pes_nome',
      width: '10%',
      sorter: (a, b) => sorterString(a, b, 'pes_nome'),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <Link
          to={
            type === 'colaborador'
              ? `/perfil/${record.pes_id}`
              : `/perfilCandidato/${record.pes_id}`
          }
        >
          {text}
        </Link>
      ),
      ...getColumnSearchProps('pes_nome'),
    },
    {
      title: 'Login',
      dataIndex: 'pes_login',
      width: '15%',
      sorter: (a, b) => sorterString(a, b, 'pes_login'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('pes_login'),
    },
    {
      title: 'Time',
      dataIndex: 'grupos',
      width: '5%',
      sorter: (a, b) => sorterString(a, b, 'grupos'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('grupos'),
    },
    {
      title: 'Data de envio',
      dataIndex: 'data_solicitacao',
      width: '10%',
      sorter: (a, b) => sorterDate(a, b, 'data_solicitacao'),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>{moment(text).format('DD/MM/YYYY')}</span>
      ),
      ...getColumnSearchProps('data_solicitacao'),
    },
    {
      title: 'Criado Por',
      dataIndex: ['creator', 'colaborador', 'pes_nome'],
      width: '10%',
      sorter: (a, b) =>
        sorterString(a, b, ['creator', 'colaborador', 'pes_nome']),
    },
    {
      title: 'Data de Atualização',
      dataIndex: 'updated_at',
      width: '10%',
      sorter: (a, b) => sorterDate(a, b, 'updated_at'),
      render: (text) =>
        text
          ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
          : '',
    },
    {
      title: 'Atualizado Por',
      dataIndex: ['updater', 'colaborador', 'pes_nome'],
      width: '10%',
      sorter: (a, b) =>
        sorterString(a, b, ['updater', 'colaborador', 'pes_nome']),
    },
    {
      title: 'Data de resposta',
      dataIndex: 'data_entrega',
      width: '10%',
      sorter: (a, b) => sorterDate(a, b, 'data_entrega'),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>{text ? moment(text).format('DD/MM/YYYY') : ''}</span>
      ),
      ...getColumnSearchProps('data_entrega'),
    },
    {
      title: 'Senha',
      dataIndex: 'pes_senha',
      width: '5%',
      ...getColumnSearchProps('pes_senha'),
    },
    {
      title: 'E-mail',
      dataIndex: 'enviada',
      width: '10%',
      render: (text, record) =>
        text === 0 ? (
          <span>Não enviado</span>
        ) : text === 1 ? (
          <span>Enviado</span>
        ) : (
          <span>Reenviado</span>
        ),
    },
    {
      title: 'Ações',
      key: 'acoes',
      width: '15%',
      render: (text, record) => {
        return dataSource.length >= 1 ? (
          record.status === 1 ? (
            record.tipo === 'Perfil Comportamental' ? (
              <span
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Link
                  to={
                    type === 'colaborador'
                      ? `/dashboard/colaboradores?colaborador=${record.pes_id}&avaliacao=${record.avalia_id}`
                      : `/dashboard/colaboradores?candidato=${record.pes_id}`
                  }
                >
                  <Tooltip title="Ver na Dashboard">
                    <DashboardOutlined />
                  </Tooltip>
                </Link>
                <Divider type="vertical" />
                {record.hash_share ? (
                  <CopyToClipboard
                    text={`${window.location.host}/share/vetor/pessoa/${record.hash_share}`}
                    onCopy={() =>
                      toast.success(
                        'Link da relatório copiado para a área de transferência.'
                      )
                    }
                  >
                    <a href="javascript:;" aria-label="Link">
                      <Tooltip title="Link do Relatório">
                        <LinkOutlined />
                      </Tooltip>
                    </a>
                  </CopyToClipboard>
                ) : (
                  <a
                    href="javascript:;"
                    onClick={() =>
                      handleNewLink(record.pes_id, record.avalia_id)
                    }
                    aria-label="Gerar Link"
                  >
                    <Tooltip title="Gerar Link">
                      <LinkOutlined />
                    </Tooltip>
                  </a>
                )}
              </span>
            ) : (
              ''
            )
          ) : (
            <span
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CopyToClipboard
                text={`${window.location.host}/${contaUrl}/pesquisas/${
                  record.tipo === 'Perfil Comportamental'
                    ? 'perfil-comportamental'
                    : record.tipo === 'Valores Organizacionais'
                    ? 'valores-organizacionais'
                    : 'percepcao-ambiente'
                }/${record.hashacesso}`}
                onCopy={() => {
                  toast.success(
                    'Link da pesquisa copiado para a área de transferência.'
                  )
                }}
              >
                <a href="javascript:;" aria-label="Copiar">
                  <Tooltip title="Copiar Link" placement="bottom">
                    <LinkOutlined />
                  </Tooltip>
                </a>
              </CopyToClipboard>
              <Divider type="vertical" />
              <Popconfirm
                title="Reenviar pesquisa?"
                onConfirm={() => {
                  if (record.tipo === 'Perfil Comportamental')
                    sendVetorComportamental(
                      {
                        pessoas: [{ pes_id: record.pes_id }],
                      },
                      { enviarEmails: true }
                    ).then(({ response }) => {
                      if (response.status === 200) {
                        toast.success('Enviado com sucesso!')
                        getPesquisas()
                      }
                    })
                  else if (record.tipo === 'Pesquisa de Percepção')
                    sendPercepcao(record.pes_id, { enviarEmails: true }).then(
                      ({ response }) => {
                        if (response.status === 200) {
                          toast.success('Enviado com sucesso!')
                          getPesquisas()
                        }
                      }
                    )
                  else if (record.tipo === 'Valores Organizacionais')
                    sendValoresOrganizacionais(record.pes_id, {
                      enviarEmails: true,
                    }).then(({ response }) => {
                      if (response.status === 200) {
                        toast.success('Enviado com sucesso.')
                        getPesquisas()
                      }
                    })
                }}
                okText="Sim"
                cancelText="Não"
                icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
              >
                <a href="javascript:;">
                  {record.enviada ? (
                    <Tooltip title="Reenviar e-mail" placement="bottom">
                      <MailOutlined />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Enviar e-mail" placement="bottom">
                      <MailOutlined />
                    </Tooltip>
                  )}
                </a>
              </Popconfirm>
              <Divider type="vertical" />
              <Popconfirm
                title="Cancelar pesquisa?"
                onConfirm={() => {
                  const canceledId = record.pes_id
                  if (record.tipo === 'Perfil Comportamental') {
                    cancelVetorComportamental(
                      record.pes_id,
                      record.avalia_id
                    ).then(({ response }) => {
                      if (response.status === 200) {
                        toast.success('Cancelado com sucesso!')
                        getPesquisas()
                      }
                    })
                  } else if (record.tipo === 'Pesquisa de Percepção') {
                    cancelPercepcao(record.pes_id, record.avalia_id).then(
                      ({ response }) => {
                        if (response.status === 200) {
                          toast.success('Cancelado com sucesso!')
                          getPesquisas()
                        }
                      }
                    )
                  } else if (record.tipo === 'Valores Organizacionais') {
                    cancelValoresOrganizacionais(
                      record.pes_id,
                      record.avalia_id
                    ).then(({ response }) => {
                      if (response.status === 200) {
                        toast.success('Cancelado com sucesso!')
                        getPesquisas()
                      }
                    })
                  }
                }}
                okText="Sim"
                cancelText="Não"
                icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
              >
                <a href="javascript:;" aria-label="cancelar">
                  <Tooltip title="Cancelar" placement="bottom">
                    <StopOutlined />
                  </Tooltip>
                </a>
              </Popconfirm>
            </span>
          )
        ) : null
      },
    },
  ]
  let toExport = dataSource
  return (
    <div className="gestao-pesquisas__table">
      <Table
        title={() => (
          <div className="gestao-pesquisas__table__buttons">
            <div>
              <Button
                className="gestao-pesquisas__table__button btn_purple"
                onClick={() => exportarExcel(toExport)}
              >
                EXPORTAR PARA EXCEL
              </Button>
              {/* <CSVLink
								data={dataSource}
								filename={'pesquisas.csv'}
								headers={[
									{ label: 'Status', key: 'status' },
									{ label: 'Pesquisa', key: 'tipo' },
									{ label: 'Nome', key: 'pes_nome' },
									{ label: 'Login', key: 'pes_login' },
									{ label: 'Grupo', key: 'grupos' },
									{
										label: 'Data de envio',
										key: 'data_solicitacao',
									},
									{
										label: 'Data de resposta',
										key: 'data_entrega',
									},
									{
										label: 'Senha',
										key: 'pes_senha',
									},
								]}
							>
								<Button className='gestao-pesquisas__table__button btn_purple'>
									EXPORTAR CSV
								</Button>
							</CSVLink> */}
            </div>
            <Button
              type="primary"
              className="gestao-pesquisas__table__button btn_purple"
              onClick={() => openModal(modalId)}
            >
              NOVA PESQUISA
            </Button>
          </div>
        )}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} registros`,
        }}
        dataSource={dataSource}
        columns={columns}
        bordered
        locale={{ emptyText: 'Nenhum registro' }}
        onChange={(pagination, filters, sorter, extra) => {
          // TODO jogar na prop  e pegar para excel
          toExport = extra.currentDataSource
        }}
      />
    </div>
  )
}

export default injectIntl(TableGestaoPesquisas)
