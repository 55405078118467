import { URL_PS_KANBAN_PROCESSO_CANCEL_PROCESSO } from '../../PsKanban/constants/psKanbanConstants'

// types
export const PS_PAINEL_CLEAR = 'PS_PAINEL_CLEAR'

export const PS_PAINEL_PROCESSO_DETAILS_GET = 'PS_PAINEL_PROCESSO_DETAILS_GET'

export const PS_PAINEL_PROCESSO_QUIZ_GET = 'PS_PAINEL_PROCESSO_QUIZ_GET'

export const PS_KANBAN_PROCESSO_LIST_MOTIVOSCANCELAMENTO =
  'PS_KANBAN_PROCESSO_LIST_MOTIVOSCANCELAMENTO'

export const PS_PAINEL_CHECK = 'PS_PAINEL_CHECK'

export const PS_PAINEL_SUSPENSAO = 'PS_PAINEL_SUSPENSAO'

export const PS_LIST_ALL_QUIZZES_CANDIDATES = 'PS_LIST_ALL_QUIZZES_CANDIDATES'

// URLs

const URL_BASE = `${process.env.REACT_APP_API_URL}`

const URL_PS_PAINEL_PROCESSO = (processo_id) =>
  `${process.env.REACT_APP_API_URL}/ps/${processo_id}`

export const URL_PS_PAINEL_CHECK = (processo_id) =>
  URL_PS_KANBAN_PROCESSO_CANCEL_PROCESSO(processo_id)

export const URL_PS_PAINEL_PROCESSO_DETAILS_GET = (processo_id) =>
  `${URL_PS_PAINEL_PROCESSO(processo_id)}/details`

export const URL_PS_PAINEL_PROCESSO_QUIZ_GET = (processo_id) =>
  `${process.env.REACT_APP_API_URL}/ps_quizes/${processo_id}`

export const URL_PROCESSO_SELETIVO_SUSPENSAO = (processo_id) =>
  `${URL_PS_PAINEL_PROCESSO(processo_id)}/suspensao`

export const URL_PS_KANBAN_PROCESSO_LIST_MOTIVOSCANCELAMENTO = `${process.env.REACT_APP_API_URL}/ps/cancel`

export const URL_PS_LIST_ALL_QUIZZES_CANDIDATES = (processo_id) =>
  `${process.env.REACT_APP_API_URL}/quiz_psquiz_nota/${processo_id}`
