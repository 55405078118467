import { Col, Modal, Tooltip } from 'antd'
import { Axis, Chart, Coordinate, Legend, Line, Point } from 'bizcharts'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { arqRange, colors } from '../../../../../../../../utils/utils'
import '../../../../../../Dashboard.css'

const ModalGraficoArquetiposCulturais = ({
  isModalVisible,
  handleOk,
  handleCancel,
  cultura,
}) => {
  return (
    <Modal
      title="VISUALIZAR GRÁFICO DE ARQUÉTIPOS CULTURAIS"
      centered
      width={900}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      render="svg"
    >
      <TransformWrapper>
        <TransformComponent>
          <Col
            style={{
              flexGrow: '1 1 auto',
              display: 'flex',
            }}
            justify="center"
            render="svg"
          >
            <Chart
              height={500}
              width={844}
              renderer="svg"
              data={cultura}
              scale={arqRange}
              autoFit
            >
              <Legend />
              <Coordinate type="polar" radius={0.8} />
              <Axis
                name="Tipo"
                line={null}
                tickLine={null}
                grid={{
                  lineStyle: {
                    lineDash: null,
                  },
                  hideFirstLine: false,
                }}
              />
              <Tooltip />
              <Axis
                name="Valor"
                line={null}
                tickLine={null}
                grid={{
                  type: 'polygon',
                  lineStyle: {
                    lineDash: null,
                  },
                  alternateColor: 'rgba(0, 0, 0, 0.04)',
                }}
              />
              <Line position="Tipo*Valor" color={['Label', colors]} size={2} />
              <Point
                position="Tipo*Valor"
                color={['Label', colors]}
                shape="circle"
                size={4}
                style={{
                  stroke: '#fff',
                  lineWidth: 1,
                  fillOpacity: 1,
                }}
              />
            </Chart>
          </Col>
        </TransformComponent>
      </TransformWrapper>
    </Modal>
  )
}

export default ModalGraficoArquetiposCulturais
