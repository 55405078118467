import { connect } from 'react-redux'
import * as actions from './actions/actionsValoresOrganizacionais'

// Components
import ValoresOrganizacionaisController from './ValoresOrganizacionaisController'

// Actions

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, { ...actions })(
  ValoresOrganizacionaisController
)
