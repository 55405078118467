import React, { useEffect, useState } from 'react'
import { Layout, Table, Modal, Button, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import {
  EditOutlined,
  DeleteOutlined,
  LinkOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { getTimes, deleteTimeSubcultura } from '../Api/api'
import AddNewCulture from '../Forms/AddNewCulture'
import EditCulture from '../Forms/EditCulture'

const { Column } = Table

const App = (props) => {
  const [dataSource, setData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEditModalVisible, setEditModal] = useState(false)
  const [actualColumn, setColumn] = useState({})
  const newObj = []
  async function getTime() {
    const response = await getTimes(props?.cultura.cultura_id)
    const data = response.conta_cultura_grupo
    data.forEach((time) => {
      time.grupos.cultura_id = time.cultura_id
      time.grupos.key = time.grupo_id
      time.grupos.subcultura_id = time.subcultura_id
      time.grupos.emp_id = time.grupos[0].emp_id
      time.grupos.grupo_nome = time.grupos[0].grupo_nome
      time.grupos.grupo_pai = time.grupos[0].grupo_pai
      time.grupos.grupo_id = time.grupos[0].grupo_id
      time.grupos.subcultura = time.conta_cultura_subculturas[0].descricao
      newObj.push(time.grupos)
    })
    setData(newObj)
  }
  useEffect(() => {
    getTime()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setEditModal(false)
  }

  const deleteTime = async (time) => {
    // eslint-disable-next-line no-restricted-globals
    const answer = confirm('Tem Certeza que deseja apagar o grupo?')
    if (answer) {
      const response = await deleteTimeSubcultura(
        time.cultura_id,
        time.subcultura_id,
        time.grupo_id
      )
      if (response?.success) getTime()
    }
  }

  return (
    <>
      {props.cultura.cultura_status === 1 && (
        <div style={{ float: 'right' }}>
          <Button
            type="primary"
            onClick={showModal}
            className="btn_purple newCultureBtn"
          >
            Nova cultura desejada
          </Button>
        </div>
      )}
      <Table dataSource={dataSource}>
        <Column title="Time" key="grupo_nome" dataIndex="grupo_nome" />
        <Column title="Time Pai" key="grupo_pai" dataIndex="grupo_pai" />
        <Column title="Sub Cultura" key="subcultura" dataIndex="subcultura" />
        <Column
          title="Ação"
          key="ações"
          dataIndex="ações"
          render={(tags, row) => (
            <Tooltip title="Excluir" placement="bottom">
              <DeleteOutlined
                onClick={() => deleteTime(row)}
                style={{ margin: 5, color: '#30318b', cursor: 'pointer' }}
              />
            </Tooltip>
          )}
        />
      </Table>
      <Modal
        title="Adicionar nova cultura"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <AddNewCulture
          cultura={props.cultura}
          updateSubCultura={getTime}
          closeModal={handleCancel}
        />
      </Modal>
      <Modal
        title="Editar cultura"
        visible={isEditModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <EditCulture
          closeModal={handleCancel}
          update={getTime}
          column={actualColumn}
        />
      </Modal>
    </>
  )
}

export default App
