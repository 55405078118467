import React, { useState, useEffect, useCallback } from 'react'

import AnaliseCulturaGeralView from '../../../relatoriosGeral/analiseCulturaLider/AnaliseCulturaGeralView'
import { CulturaOrganizacional, Destaques } from '../../@types'

interface AnaliseCulturaProps {
  culturaOrganizacional: CulturaOrganizacional
  cultura: any[]
  forcas: any[]
  disc: any[]
  olhares: any[]
  gestao: any[]
  geracoes: any[]
  generos: any[]
}

export default function AnaliseCultura({
  culturaOrganizacional,
  cultura,
  forcas,
  disc,
  olhares,
  gestao,
  geracoes,
  generos,
}: AnaliseCulturaProps): JSX.Element {
  return (
    <AnaliseCulturaGeralView
      culturaOrganizacional={culturaOrganizacional}
      cultura={cultura}
      forcas={forcas}
      disc={disc}
      olhares={olhares}
      gestao={gestao}
      geracoes={geracoes}
      generos={generos}
    />
  )
}
