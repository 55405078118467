import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Button,
  Popconfirm,
  Modal,
  Divider,
  Card,
  Tooltip,
  Switch,
  Space,
} from 'antd'

import {
  ExclamationCircleFilled,
  DeleteOutlined,
  Loading3QuartersOutlined,
  UserAddOutlined,
  EditOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import Table from '../../../../components/editableTable/EditableTable'

import ModalCadastro from './components/ModalCadastro/index'

import {
  listFerramentas,
  storeFerramentas,
  updateFerramenta,
  deleteFerramentas,
  loadFerramentas,
  getFrequencia,
} from './actions/actions'

import { Action, TableHeader } from './styles'

function Ferramentas({ id }) {
  const dispatch = useDispatch()

  const [showModalNewFerramenta, setShowModalNewFerramenta] = useState(false)

  const [showModalUpdateFerramenta, setShowModalUpdateFerramenta] =
    useState(false)

  const [newFerramenta, setNewFerramenta] = useState(null)

  const [ferramentas, setFerramentas] = useState([])

  const [frequencia_nome, setFrequenciaNome] = useState([])

  const [frequencia, setFrequencias] = useState([])

  const [showInativos, setShowInativos] = useState(false)

  const [loadingFerramentas, setloadingFerramentas] = useState(false)

  const [handleFerramenta, sethandleUpdateFerramenta] = useState(false)

  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const [ferramentaUpdated, setFerramentaUpdated] = useState({
    nome: undefined,
    tipo_id: undefined,
  })

  const loadFerramentas = useCallback(() => {
    setloadingFerramentas(true)
    dispatch(listFerramentas(id)).then(({ response }) => {
      setloadingFerramentas(false)
      if (response.status === 200) {
        setFerramentas(response.data)
      }
    })
  }, [dispatch, id])

  const listFrequencias = useCallback(() => {
    dispatch(getFrequencia()).then(({ response }) => {
      if (response.status === 200) {
        const frequencia = response.data
        setFrequencias(frequencia)
        const frequencia_nome = response.data
        setFrequenciaNome(
          frequencia_nome.map((m) => ({ value: m.id, label: m.nome }))
        )
      }
    })
  }, [dispatch])

  useEffect(() => {
    listFrequencias()
    loadFerramentas()
  }, [loadFerramentas, listFrequencias])

  const onFinishNewFerramenta = () => {
    dispatch(storeFerramentas()).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Ferramenta cadastrado com sucesso.')
        loadFerramentas()
      } else toast.error('Erro ao cadastrar Ferramenta.')
    })
  }

  const handleListFerramentas = useCallback(() => {
    dispatch(listFerramentas()).then(({ response }) => {
      if (response.status === 200) {
        const { ferramentas } = response.data
        setFerramentas(
          ferramentas.map((m) => ({ value: m.ferramenta_id, label: m.nome }))
        )
      }
    })
  }, [dispatch])

  const handleDeleteFerramenta = useCallback(
    (ferramenta_id) => {
      dispatch(deleteFerramentas({ ferramenta_id, id })).then(
        ({ response }) => {
          if (response.status === 204) {
            toast.success('Ferramenta excluída com sucesso.')
            loadFerramentas()
          }
        }
      )
    },
    [dispatch, id, loadFerramentas]
  )

  const handleUpdateFerramenta = useCallback(
    (updatedFerramenta, data) => {
      dispatch(
        updateFerramenta({
          ferramenta_id: updatedFerramenta.ferramenta_id,
          ...updatedFerramenta,
        })
      ).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Ferramenta atualizada com sucesso.')
          loadFerramentas()
        }
      })
    },
    [dispatch, loadFerramentas]
  )

  const handleChangeNewFerramenta = (ferramenta) => {
    setNewFerramenta(ferramenta)
  }

  const handleCancelNewFerramenta = useCallback(() => {
    setShowModalNewFerramenta(false)
  }, [])

  const handleNewFerramenta = useCallback(
    (data) => {
      dispatch(storeFerramentas({ data, id })).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Ferramenta criada com sucesso.')
          setShowModalNewFerramenta(false)
          loadFerramentas()
        }
      })
    },
    [dispatch, loadFerramentas, id]
  )

  // UPDATE REQUISITO
  const handleUpdateModal = ({ id, nome, tipo_id }) => {
    setFerramentaUpdated({ id, nome, tipo_id })
    setShowUpdateModal(true)
  }

  const handleChangeUpdateFerramenta = (nome, tipo_id) => {
    setFerramentaUpdated((prevState) => ({
      ...prevState,
      nome,
      tipo_id,
    }))
  }

  const handleSubmitUpdateFerramenta = (data) => {
    dispatch(
      updateFerramenta({
        id,
        ferramenta_id: ferramentaUpdated.id,
        ...data,
      })
    ).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Atualizado com sucesso.')
        loadFerramentas()
        handleCloseUpdateFerramenta()
      } else toast.error('Erro ao atualizar ferramenta.')
    })
  }

  const handleCloseUpdateFerramenta = () => {
    setFerramentaUpdated({
      nome: undefined,
      tipo_id: undefined,
    })
    setShowUpdateModal(false)
  }

  //

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo_id',
      render: (text, record) => {
        const frequencia_nome = frequencia.filter(
          (frequencia) => frequencia.id === text
        )
        return (
          <span>
            {frequencia_nome[0] !== undefined ? frequencia_nome[0].nome : ''}
          </span>
        )
      },
    },
    {
      title: 'Opções',
      key: 'opcoes',
      editable: true,
      className: 'column-opcoes',
      render: (text, record) => (
        <Space split={<Divider type="vertical" />}>
          <a>
            <Popconfirm
              title="Tem certeza que deseja remover do cargo?"
              onConfirm={() => handleDeleteFerramenta(record.id)}
              okText="Sim"
              cancelText="Não"
            >
              <Tooltip title="Excluir" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </a>
          <a
            href="javascript:;"
            onClick={() => handleUpdateModal(record)}
            aria-label="Editar"
          >
            <Tooltip title="Editar" placement="bottom">
              <EditOutlined />
            </Tooltip>
          </a>
        </Space>
      ),
    },
  ]

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Card title="Ferramentas do cargo" type="inner">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '12px',
              }}
            >
              <Button
                type="primary"
                className="btn_purple"
                onClick={() => setShowModalNewFerramenta(true)}
                icon={<UserAddOutlined />}
              >
                NOVO
              </Button>
            </div>
            <Table
              bordered
              pagination={false}
              size="small"
              dataSource={ferramentas.map((ferramentas, frequencia_nome) => {
                return {
                  ...ferramentas,
                  ...frequencia_nome,
                  key: ferramentas.pes_id,
                }
              })}
              columns={columns}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="NOVA INFORMAÇÃO"
        visible={showModalNewFerramenta}
        cancelText="CANCELAR"
        onCancel={() => handleCancelNewFerramenta()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'cadastro',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <ModalCadastro
          handleChangeFerramenta={handleChangeNewFerramenta}
          onFinish={handleNewFerramenta}
          {...handleFerramenta}
          frequencia={frequencia}
        />
      </Modal>
      <Modal
        title="EDITAR INFORMAÇÃO"
        visible={showUpdateModal}
        cancelText="CANCELAR"
        onCancel={() => handleCloseUpdateFerramenta()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'cadastro',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <ModalCadastro
          handleChangeFerramenta={handleChangeUpdateFerramenta}
          onFinish={handleSubmitUpdateFerramenta}
          {...ferramentaUpdated}
          frequencia={frequencia}
        />
      </Modal>
    </Col>
  )
}
export default Ferramentas
