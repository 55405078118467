import React from 'react'
import { injectIntl } from 'react-intl'
import { Table, Popconfirm } from 'antd'
import moment from 'moment'

// CSS

// Utils
import { sorterString } from '../../../../utils/utils'
import Link from '../../../../components/privateLink/PrivateLink'

function TableCargosDetalhes({
  dataSource,
  getColumnSearchProps,
  onTableDelete,
}) {
  // Table columns
  const columns = [
    {
      title: 'CPF',
      dataIndex: 'cpf',
      className: 'data-center',
      ...getColumnSearchProps('cpf'),
      width: '16%',
    },
    {
      title: 'Colaborador',
      dataIndex: 'nome',
      width: '30%',
      sorter: (a, b) => sorterString(a, b, 'nome'),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('nome'),
      render: (text, record) => <Link to={`/perfil/${record.id}`}>{text}</Link>,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      width: '30%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Entrada',
      dataIndex: 'entrada',
      width: '12%',
      className: 'data-center',
      sorter: (a, b) => moment(a.entrada).unix() - moment(b.entrada).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text) =>
        text !== '-' ? moment(text).format('DD/MM/YYYY') : text,
      ...getColumnSearchProps('entrada'),
    },
    {
      title: 'Saída',
      dataIndex: 'saida',
      width: '12%',
      className: 'data-center',
      sorter: (a, b) => moment(a.saida).unix() - moment(b.saida).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (text) =>
        text !== '-' ? moment(text).format('DD/MM/YYYY') : text,
      ...getColumnSearchProps('saida'),
    },
    {
      title: 'Opções',
      key: 'opcoes',
      editable: true,
      className: 'column-opcoes',
      width: '40%',
      render: (text, record) => (
        <span>
          <Popconfirm
            title="Tem certeza que deseja remover do cargo?"
            onConfirm={() => onTableDelete(record.id)}
            okText="Sim"
            cancelText="Não"
          >
            <a>Remover</a>
          </Popconfirm>
        </span>
      ),
    },
  ]

  return (
    <div className="cargos-detalhes__table">
      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        bordered
        locale={{ emptyText: 'Nenhum registro' }}
      />
    </div>
  )
}

export default injectIntl(TableCargosDetalhes)
