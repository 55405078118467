import React from 'react'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import { CaretRightOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Row, Col } from 'antd'

// Components
import Input, { Date, TextArea } from '../../../../components/input/Input'
import Select, { Option } from '../../../../components/select/Select'

// CSS
import './AdicionarNovoTime.css'

// Constants

const childrenRender = (times, level = 0) => {
  return times.map((time) => [
    <Option value={time.grupo_id}>
      {_.times(level, () => (
        <>&emsp;</>
      ))}
      <CaretRightOutlined style={{ opacity: '0.8', fontSize: '12px' }} />{' '}
      {time.grupo_nome}
    </Option>,
    childrenRender(time.children, level + 1),
  ])
}

function AdicionarNovoTime({
  intl,
  handleChangeModal,
  dataSource,
  dataFilial,
  showTimePai = true,
  error,
}) {
  return (
    <div className="adicionar-time">
      <Form layout="vertical">
        <Row>
          <Col md={24}>
            <Form.Item
              label="Nome do time"
              validateStatus={error.nome ? 'error' : ''}
              help={error.nome || ''}
            >
              <Input
                type="text"
                placeholder="Digite o nome do time"
                name="nome"
                onChange={handleChangeModal}
                required
                withoutMargin
              />
            </Form.Item>
          </Col>
        </Row>
        {showTimePai && (
          <Row>
            <Col md={24}>
              <Form.Item label="Time pai">
                <Select
                  placeholder="Selecione o time pai"
                  name="pai"
                  onChange={(evt) =>
                    handleChangeModal({ target: { name: 'pai', value: evt } })
                  }
                  withoutMargin
                >
                  {childrenRender(dataSource)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={24}>
            <Form.Item label="Filial">
              <Select
                placeholder="Selecione a filial"
                name="filial"
                onChange={(filial_id) =>
                  handleChangeModal({
                    target: { name: 'filial', value: filial_id },
                  })
                }
                withoutMargin
              >
                {dataFilial.map(({ nome, filial_id }) => (
                  <Option value={filial_id} key={filial_id}>
                    {nome}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Form.Item label="Descrição">
              <TextArea
                placeholder="Digite a descrição"
                name="descricao"
                rows={4}
                onChange={handleChangeModal}
                withoutMargin
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default injectIntl(AdicionarNovoTime)
