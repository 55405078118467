import React, { useEffect, useState, useCallback, useMemo } from 'react'

import {
  Col,
  Row,
  Card,
  Button,
  Switch,
  Divider,
  Popconfirm,
  Modal,
  Table,
  Tooltip,
  Badge,
} from 'antd'

import {
  UserAddOutlined,
  ExclamationCircleFilled,
  PoweroffOutlined,
  EditOutlined,
  DeleteOutlined,
  RedoOutlined,
} from '@ant-design/icons'

import { useDispatch } from 'react-redux'

import { toast } from 'react-toastify'

import moment from 'moment'

import 'moment-timezone'

// actions
import { sorterString, sorterDate } from '../../utils/utils'
import {
  listUsers,
  storeUser,
  updateUser,
  deleteUser,
  disableUser,
  enableUser,
  listTipos,
  listColaboradores,
  resetPassword,
  listTimes,
} from './actions'

// components
import NovoUsuario from './components/NovoUsuario'

import { listFiliais } from '../ConfigFiliais/actions/actions'

import { TableHeader } from './styles'

function Usuarios() {
  const dispatch = useDispatch()

  const [users, setUsers] = useState([])

  const [currentColaboradorId, setCurrentColaboradorId] = useState()

  // user update
  const [currentUserId, setCurrentUserId] = useState()
  const [showModalUpdateUser, setShowModalUpdateUser] = useState(false)

  const [userTypes, setUserTypes] = useState([])

  const [colaboradores, setColaboradores] = useState([])

  const [showInativos, setShowInativos] = useState(false)

  const [loadingUsuarios, setLoadingUsuarios] = useState(false)

  const [showModalNewUser, setShowModalNewUser] = useState(false)

  const [filiais, setFiliais] = useState([])

  const [usuarios, setUsuarios] = useState([])

  const [loadingTimes, setLoadingTimes] = useState(false)

  const [times, setTimes] = useState([])

  const loadUsers = useCallback(() => {
    setLoadingUsuarios(true)
    dispatch(listUsers()).then(({ response }) => {
      setLoadingUsuarios(false)
      if (response.status === 200) {
        setUsers(response.data)
      }
    })
  }, [dispatch])

  const loadFiliais = useCallback(() => {
    dispatch(listFiliais()).then(({ response }) => {
      if (response.status === 200) {
        setFiliais(response.data.filter((filial) => filial.ativo === 1))
      }
    })
  }, [dispatch])

  const loadTimes = useCallback(() => {
    setLoadingTimes(true)
    dispatch(listTimes()).then(({ response }) => {
      if (response.status === 200) {
        setTimes(
          response.data.grupos.map((grupo) => ({
            label: grupo.grupo_nome,
            value: grupo.grupo_id,
          }))
        )
      }
      setLoadingTimes(false)
    })
  }, [dispatch])

  const loadUserTypes = useCallback(() => {
    dispatch(listTipos()).then(({ response }) => {
      if (response.status === 200) {
        setUserTypes(response.data)
      }
    })
  }, [dispatch])

  const loadColaboradores = useCallback(() => {
    dispatch(listColaboradores()).then(({ response }) => {
      if (response.status === 200) {
        const { pessoas } = response.data
        setColaboradores(pessoas)
      }
    })
  }, [dispatch])

  useEffect(() => {
    loadUsers()
    loadUserTypes()
    loadColaboradores()
    loadFiliais()
    loadTimes()
  }, [loadColaboradores, loadFiliais, loadTimes, loadUserTypes, loadUsers])

  const handleDisableUser = useCallback(
    (user_id) => {
      dispatch(disableUser(user_id)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Usuário desativado com sucesso.')
          loadUsers()
        }
      })
    },
    [dispatch, loadUsers]
  )

  const handleEnableUser = useCallback(
    (user_id) => {
      dispatch(enableUser(user_id)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Usuário ativado com sucesso.')
          loadUsers()
        }
      })
    },
    [dispatch, loadUsers]
  )

  const handleDeleteUser = useCallback(
    (user_id) => {
      dispatch(deleteUser(user_id)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Usuário excluído com sucesso.')
          loadUsers()
        }
      })
    },
    [dispatch, loadUsers]
  )

  const handleInitUpdateUser = useCallback((user_id) => {
    setCurrentUserId(user_id)
    setShowModalUpdateUser(true)
  }, [])

  const handleCloseUpdateUser = useCallback(() => {
    setCurrentUserId(undefined)
    setShowModalUpdateUser(false)
  }, [])

  const handleUpdateUser = useCallback(
    (data) => {
      dispatch(updateUser({ user_id: currentUserId, ...data })).then(
        ({ response }) => {
          if (response.status === 200) {
            toast.success('Usuário atualizado com sucesso.')
            handleCloseUpdateUser()
            loadUsers()
          }
        }
      )
    },
    [currentUserId, dispatch, handleCloseUpdateUser, loadUsers]
  )

  const handleResetPassword = useCallback(
    (user_email) => {
      dispatch(resetPassword(user_email)).then(({ response }) => {
        if (response.status === 200) {
          toast.success(
            'Um e-mail contendo detalhes sobre como recuperar sua senha foi enviado ao usuário.'
          )
        }
      })
    },
    [dispatch]
  )

  const filiaisOptions = useMemo(() => {
    return filiais.map((filial) => {
      return {
        label: filial.nome,
        value: filial.filial_id,
      }
    })
  }, [filiais])

  const filiaisFilters = useMemo(() => {
    return filiais.map((filial) => {
      return {
        text: filial.nome,
        value: filial.filial_id,
      }
    })
  }, [filiais])

  const usuariosOptions = useMemo(() => {
    return users.map((user) => {
      return {
        label: user.user_nome,
        value: user.user_id,
      }
    })
  }, [users])

  const userTypesOptions = useMemo(() => {
    return userTypes.map((type) => {
      return {
        label: type.descricao,
        value: type.id,
      }
    })
  }, [userTypes])

  const userTypesFilters = useMemo(() => {
    return userTypes.map((type) => {
      return {
        text: type.descricao,
        value: type.id,
      }
    })
  }, [userTypes])

  const columns = useMemo(
    () => [
      {
        title: 'Status',
        key: 'status',
        align: 'center',
        render: (text, record) => {
          return (
            <>
              {record.user_ativo ? (
                <Tooltip title="Ativo">
                  <Badge status="success" />
                </Tooltip>
              ) : (
                <Tooltip title="Inativo">
                  <Badge status="default" />
                </Tooltip>
              )}
            </>
          )
        },
      },
      {
        title: 'Nome',
        dataIndex: ['colaborador', 'pes_nome'],
        width: '25%',
        sorter: (a, b) => sorterString(a, b, 'user_nome'),
      },
      {
        title: 'E-mail',
        dataIndex: 'user_email',
        width: '20%',
        sorter: (a, b) => sorterString(a, b, 'user_email'),
        editable: true,
        align: 'center',
      },
      {
        title: 'Tipo',
        dataIndex: 'usertipo_id',
        width: '10%',
        sorter: (a, b) => sorterString(a, b, 'usertipo_id'),
        editable: true,
        inputType: 'select',
        options: userTypesOptions,
        filters: userTypesFilters,
        onFilter: (value, record) => {
          return record.usertipo_id && record.usertipo_id === value
        },
        render: (text, record) => {
          const findType = userTypes.find((type) => type.id === parseInt(text))
          return <span>{findType && findType.descricao}</span>
        },
        align: 'center',
      },
      {
        title: 'Filial',
        dataIndex: ['colaborador', 'filial', 'nome'],
        width: '10%',
        editable: true,
        inputType: 'select',
        options: filiaisOptions,
        filters: filiaisFilters,
        onFilter: (value, record) => {
          if (record.colaborador !== null) {
            return (
              record.colaborador.filial_id &&
              record.colaborador.filial_id === value
            )
          }
        },
        sorter: (a, b) => {
          if (a.colaborador && b.colaborador) {
            if (a.colaborador.filial && b.colaborador.filial) {
              return sorterString(a, b, ['colaborador', 'filial', 'nome'])
            }
            if (a.colaborador.filial) {
              return 1
            }
            if (b.colaborador.filial) {
              return -1
            }
            return 0
          }
          if (a.colaborador) return 1
          if (b.colaborador) return -1
          return 0
        },
        render: (text, record) => {
          const filial = filiais.find((filial) => filial.nome === text)
          return <span>{filial && filial.nome}</span>
        },
        align: 'center',
      },
      {
        title: 'Último Login',

        width: '15%',
        sorter: (a, b) => sorterDate(a, b, 'last_login'),
        render: (text, record) => {
          if (!record.logsAcesso || record.logsAcesso.length === 0) return ''

          return moment(record.logsAcesso[0].log_dataacesso)
            .utc()
            .tz(moment.tz.guess())
            .format('DD/MM/YYYY (HH:mm)')
        },
        align: 'center',
      },
      {
        title: 'Criado por',
        dataIndex: ['created_by'],
        width: '10%',
        sorter: (a, b) => sorterString(a, b, 'created_by'),
        render: (text, record) => {
          if (!record.created_by) return ''
          const usuario = usuariosOptions.find(
            (usuario) => usuario.value === parseInt(text)
          )
          return <span>{usuario && usuario.label}</span>
        },
        align: 'center',
      },
      {
        title: 'Criado em',

        width: '15%',
        sorter: (a, b) => sorterDate(a, b, 'created_at'),
        render: (text, record) => {
          return moment(record.created_at)
            .utc()
            .tz(moment.tz.guess())
            .format('DD/MM/YYYY (HH:mm)')
        },
        align: 'center',
      },
      {
        title: 'Alterado por',
        dataIndex: ['updated_by'],
        width: '10%',
        options: usuariosOptions,
        sorter: (a, b) => sorterString(a, b, 'updated_by'),
        render: (text, record) => {
          if (!record.updated_by) return ''
          const usuario = usuariosOptions.find(
            (usuario) => usuario.value === parseInt(text)
          )
          return <span>{usuario && usuario.label}</span>
        },
        align: 'center',
      },
      {
        title: 'Alterado em',
        width: '15%',
        sorter: (a, b) => sorterDate(a, b, 'updated_at'),
        render: (text, record) => {
          if (record.updated_at === null) return ''
          return moment(record.updated_at)
            .utc()
            .tz(moment.tz.guess())
            .format('DD/MM/YYYY (HH:mm)')
        },
        align: 'center',
      },
      {
        title: 'Ações',
        key: 'actions',
        render: (text, record) => {
          return (
            <>
              {parseInt(record.user_ativo) === 1 ? (
                <>
                  <Tooltip title="Editar">
                    <a>
                      <EditOutlined
                        onClick={() => handleInitUpdateUser(record.user_id)}
                      />
                    </a>
                  </Tooltip>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="Tem certeza que deseja desativar o usuário?"
                    onConfirm={() => handleDisableUser(record.user_id)}
                    okText="Desativar"
                    cancelText="Cancelar"
                  >
                    <Tooltip title="Desativar usuário">
                      <a>
                        <PoweroffOutlined />
                      </a>
                    </Tooltip>
                  </Popconfirm>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="Tem certeza que deseja resetar a senha do usuário?"
                    onConfirm={() => handleResetPassword(record.user_email)}
                    okText="Resetar"
                    cancelText="Cancelar"
                  >
                    <Tooltip title="Resetar senha">
                      <a>
                        <RedoOutlined />
                      </a>
                    </Tooltip>
                  </Popconfirm>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="Tem certeza que deseja excluir o usuário?"
                    onConfirm={() => handleDeleteUser(record.user_id)}
                    okText="Excluir"
                    cancelText="Cancelar"
                    icon={
                      <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                    }
                  >
                    <Tooltip title="Excluir">
                      <a>
                        <DeleteOutlined />
                      </a>
                    </Tooltip>
                  </Popconfirm>
                </>
              ) : (
                <Popconfirm
                  title="Tem certeza que deseja ativar o usuário?"
                  onConfirm={() => handleEnableUser(record.user_id)}
                  okText="Ativar"
                  cancelText="Cancelar"
                >
                  <Tooltip title="Ativar usuário">
                    <a>
                      <PoweroffOutlined />
                    </a>
                  </Tooltip>
                </Popconfirm>
              )}
            </>
          )
        },
      },
    ],
    [
      filiaisFilters,
      filiaisOptions,
      handleDeleteUser,
      handleDisableUser,
      handleEnableUser,
      handleInitUpdateUser,
      handleResetPassword,
      userTypes,
      userTypesFilters,
      userTypesOptions,
      usuariosOptions,
      filiais,
    ]
  )

  const handleCloseNewUser = useCallback(() => {
    setShowModalNewUser(false)
    setCurrentColaboradorId(undefined)
  }, [])

  const handleNewUser = useCallback(
    (data) => {
      dispatch(storeUser(data)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Usuário criado com sucesso.')
          handleCloseNewUser()
          loadUsers()
        }
      })
    },
    [dispatch, handleCloseNewUser, loadUsers]
  )

  const colaboradoresOptions = useMemo(
    () => colaboradores.map((p) => ({ value: p.pes_id, label: p.pes_nome })),
    [colaboradores]
  )

  const handleChangePessoa = useCallback((pes_id) => {
    setCurrentColaboradorId(pes_id)
  }, [])

  const currentUser = useMemo(() => {
    return users.find((user) => user.user_id === currentUserId)
  }, [currentUserId, users])

  const currentColaborador = useMemo(() => {
    return colaboradores.find(
      (colaborador) => colaborador.pes_id === currentColaboradorId
    )
  }, [colaboradores, currentColaboradorId])

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Card title="Cadastre e gerencie usuários do sistema">
            <Table
              dataSource={users
                .filter((user) => {
                  if (showInativos) {
                    return user
                  }
                  return parseInt(user.user_ativo) === 1
                })
                .map((user) => ({ ...user, key: user.user_id }))}
              key={(record) => record.user_id}
              loading={loadingUsuarios}
              columns={columns}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total} registros`,
              }}
              title={() => (
                <TableHeader>
                  <div>
                    <Switch
                      checked={showInativos}
                      onChange={() => setShowInativos(!showInativos)}
                    />
                    <span>Exibir usuários inativos</span>
                  </div>
                  <Button
                    type="primary"
                    onClick={() => setShowModalNewUser(true)}
                  >
                    <UserAddOutlined /> NOVO USUÁRIO
                  </Button>
                </TableHeader>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="NOVO USUÁRIO"
        visible={showModalNewUser}
        cancelText="CANCELAR"
        onCancel={() => handleCloseNewUser()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'novo-usuario',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <NovoUsuario
          userTypes={userTypes.map((type) => ({
            value: type.id,
            label: type.descricao,
          }))}
          colaboradores={colaboradoresOptions}
          handleChangePessoa={handleChangePessoa}
          filiais={filiaisOptions}
          initialValues={{
            user_email: currentColaborador ? currentColaborador.pes_email : '',
            filial_id: currentColaborador
              ? currentColaborador.filial_id
              : undefined,
            ...(currentColaborador && !currentColaborador.filial_id
              ? { ps_filial_filtro: false }
              : {}),
          }}
          onFinish={handleNewUser}
          timesOptions={times}
          loadingTimes={loadingTimes}
        />
      </Modal>
      <Modal
        title="EDITAR USUÁRIO"
        visible={showModalUpdateUser}
        cancelText="CANCELAR"
        onCancel={() => handleCloseUpdateUser()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'novo-usuario',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <NovoUsuario
          type="update"
          userTypes={userTypes.map((type) => ({
            value: type.id,
            label: type.descricao,
          }))}
          colaboradores={colaboradoresOptions}
          handleChangePessoa={handleChangePessoa}
          filiais={filiaisOptions}
          initialValues={{
            ...currentUser,
            user_email: currentColaborador
              ? currentColaborador.pes_email
              : currentUser
              ? currentUser.user_email
              : '',
            filial_id: currentColaborador
              ? currentColaborador.filial_id
              : currentUser && currentUser.colaborador
              ? currentUser.colaborador.filial_id
              : undefined,
            ps_filial_filtro:
              currentColaborador && !currentColaborador.filial_id
                ? false
                : currentUser
                ? currentUser.ps_filial_filtro
                : false,
            pes_id:
              currentColaboradorId ||
              (currentUser ? currentUser.pes_id : undefined),
          }}
          onFinish={handleUpdateUser}
          timesOptions={times}
          loadingTimes={loadingTimes}
        />
      </Modal>
    </Col>
  )
}

export default Usuarios
