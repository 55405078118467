import * as constants from '../constants/constants'

export const load = () => {
  return {
    type: constants.PS_MOTIVO_CANCELAMENTO_LOAD,
  }
}

export const listMotivos = () => {
  return {
    type: constants.PS_MOTIVO_CANCELAMENTO_LIST,
    api: {
      method: 'get',
      url: constants.URL_PS_MOTIVO_CANCELAMENTO,
    },
  }
}

export const storeMotivo = ({ motivo }) => {
  return {
    type: constants.PS_MOTIVO_CANCELAMENTO_STORE,
    api: {
      method: 'post',
      url: constants.URL_PS_MOTIVO_CANCELAMENTO,
      data: { motivo },
    },
  }
}

export const updateMotivo = ({ cancel_id, motivo }) => {
  return {
    type: constants.PS_MOTIVO_CANCELAMENTO_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_PS_MOTIVO_CANCELAMENTO_ID(cancel_id),
      data: { motivo },
    },
  }
}

export const deleteMotivo = ({ cancel_id }) => {
  return {
    type: constants.PS_MOTIVO_CANCELAMENTO_UPDATE,
    api: {
      method: 'delete',
      url: constants.URL_PS_MOTIVO_CANCELAMENTO_ID(cancel_id),
    },
  }
}
