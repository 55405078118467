// types
export const GET_PERFILCOMPORTAMENTAL_GERAL_VETOR =
  'GET_PERFILCOMPORTAMENTAL_GERAL_VETOR'

export const GET_PERFILCOMPORTAMENTAL_GERAL_PERFIS =
  'GET_PERFILCOMPORTAMENTAL_GERAL_PERFIS'

// URLS
export const URL_DASHBOARD = `${process.env.REACT_APP_API_URL}/dashboard`
export const URL_PERFILCOMPORTAMENTAL_GERAL_VETOR = `${URL_DASHBOARD}/vetor`
export const URL_PERFILCOMPORTAMENTAL_GERAL_PERFIS = `${URL_DASHBOARD}/perfis`

export const culturaOrgDefault = {
  culturaOrg: {
    niveisConfianca: [],
    olharLideranca: {
      perceboLider: [],
      frequenciaFeedback: [],
      feedbackContribui: [],
      reconhecimento: [],
      expectativaEntregas: [],
    },
    olharParaMudancas: {
      pontosFortes: [],
      impedimento: [],
      principalProblema: [],
      engajamento: undefined,
      velocidadeAdocao: undefined,
      proficiencia: undefined,
    },
    olharParaSi: {
      escolhaEmpresa: [],
      prioridades: [],
      expectativas6meses: [],
      fonteEntusiasmo: [],
    },
    valoresEvidencia: [],
    satisfacaoGeral: [],
  },
  disc: {},
}
