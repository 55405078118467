import React from 'react'

import { Chart, Tooltip, Interval } from 'bizcharts'

import { Statistic } from '../styles'

function BarChart({ extraTitle, extraValue, data, position, color, label }) {
  return (
    <>
      {extraTitle ? (
        <Statistic
          title={extraTitle}
          value={extraValue}
          style={{ margin: '8px' }}
        />
      ) : (
        <Statistic
          title="default"
          value="default"
          style={{ visibility: 'hidden' }}
        />
      )}

      <Chart
        height={350}
        autoFit
        data={data}
        interactions={['active-region']}
        appendPadding={[20, 0, 0, 0]}
      >
        <Interval position={position} color={color} label={label}>
          <Tooltip shared />
        </Interval>
      </Chart>
    </>
  )
}

export default BarChart
