import React from 'react'

import { Button } from 'antd'

import { Container, Wrapper } from './styles'

function Intro({ handleInit }) {
  return (
    <Wrapper xs={22} md={20}>
      <Container>
        <p>
          A pesquisa a seguir tem como objetivo extrair sua percepção sobre a
          empresa, si mesmo, a liderança e outros aspectos importantes.
        </p>
        <p>
          Fique tranquilo, sua resposta é confidencial. As análises serão feitas
          apenas por time.
        </p>
        <span>4 dicas para responder</span>
        <ul>
          <li>
            1) Não existem respostas certas ou erradas, apenas o que tem mais ou
            menos a ver com você
          </li>
          <li>2) Separe um tempo exclusivo para respondê-la</li>
          <li>
            3) Responda à pesquisa em um ambiente tranquilo e sem interferências
          </li>
          <li>4) Seja sincero!</li>
        </ul>
        <Button type="primary" onClick={handleInit}>
          CLIQUE AQUI PARA COMEÇAR
        </Button>
      </Container>
    </Wrapper>
  )
}

export default Intro
