import React from 'react'
import {
  StarFilled,
  DeleteOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import { Divider, Button, Badge, Popconfirm, Tooltip } from 'antd'
import moment from 'moment'

import Table from '../../../components/editableTable/EditableTable'

// Utils
import { sorterString, sorterDate } from '../../../utils/utils'
import { isLider } from '../../../services/auth'

function TabTimesCargos({
  handleChangeLider,
  id,
  openModal,
  times,
  cargos,
  getColumnSearchProps,
  deleteCargoPessoa,
  deleteTimePessoa,
  isCandidato,
  handleSaveCargo,
  handleSaveTime,
}) {
  const lider = isLider()
  const columnsCargos = [
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      render: (text) => <Badge status={text === '0' ? 'error' : 'success'} />,
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo_nome',
      sorter: (a, b) => sorterString(a, b, 'cargo_nome'),
      sortDirections: ['descend', 'ascend'],
      align: 'center',
      ...getColumnSearchProps('cargo_nome', 'cargo'),
    },
    {
      title: 'Data de Entrada',
      dataIndex: 'cargopes_dataentrada',
      align: 'center',
      editable: true,
      inputType: 'date',
      sorter: (a, b) => sorterDate(a, b, 'cargopes_dataentrada', 'DD/MM/YYYY'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => text || '',
      ...getColumnSearchProps('cargopes_dataentrada', 'data entrada'),
    },
    {
      title: 'Data de Saida',
      dataIndex: 'cargopes_datasaida',
      align: 'center',
      editable: true,
      inputType: 'date',
      editableConditionKey: 'status',
      editableConditionValue: '0',
      sorter: (a, b) => sorterDate(a, b, 'cargopes_datasaida', 'DD/MM/YYYY'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => text || '',
      ...getColumnSearchProps('cargopes_datasaida', 'data saída'),
    },
    {
      title: 'Ações',
      key: 'extra_actions',
      align: 'center',
      render: (text, record) => (
        <span>
          {/* <Popconfirm
						title='Tem certeza que deseja remover do cargo?'
						onConfirm={() => deleteCargoPessoa(record.cargo_id, id)}
						okText='Sim'
						cancelText='Não'
					>
						<a href='javascript:;'>Excluir</a>
					</Popconfirm>
					<Divider type='vertical' />
					<a href='javascript:;'>Desativar</a> */}
          {parseInt(record.status) !== 0 && !lider ? (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title="Tem certeza que deseja remover do cargo?"
                onConfirm={() => deleteCargoPessoa(record.cargo_id, id)}
                okText="Sim"
                cancelText="Não"
              >
                <a href="javascript:;" aria-label="remover">
                  <Tooltip title="Remover" placement="bottom">
                    <DeleteOutlined />
                  </Tooltip>
                </a>
              </Popconfirm>
            </>
          ) : (
            <></>
          )}
        </span>
      ),
    },
  ]

  const columnsTimes = [
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <Tooltip title={record.data_saida === null ? 'Ativo' : 'Inativo'}>
          <Badge status={record.data_saida === null ? 'success' : 'error'} />
        </Tooltip>
      ),
    },
    {
      title: 'Líder',
      dataIndex: 'lider',
      align: 'center',
      sorter: (a, b) => Boolean(a.lider) - Boolean(b.lider),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <StarFilled style={{ color: text ? '#ef5472' : 'rgba(0,0,0,.18)' }} />
      ),
    },
    {
      title: 'Time',
      dataIndex: 'grupo_nome',
      sorter: (a, b) => sorterString(a, b, 'grupo_nome'),
      sortDirections: ['descend', 'ascend'],
      align: 'center',
      ...getColumnSearchProps('grupo_nome', 'time'),
    },
    {
      title: 'Data de Entrada',
      dataIndex: 'data_entrada',
      align: 'center',
      editable: true,
      inputType: 'date',
      sorter: (a, b) => sorterDate(a, b, 'data_entrada', 'DD/MM/YYYY'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => text || '',
      ...getColumnSearchProps('data_entrada', 'data entrada'),
    },
    {
      title: 'Data de Saida',
      dataIndex: 'data_saida',
      editable: true,
      inputType: 'date',
      editableConditionKey: 'status',
      editableConditionValue: 0,
      align: 'center',
      sorter: (a, b) => sorterDate(a, b, 'data_saida', 'DD/MM/YYYY'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => text || '',
      ...getColumnSearchProps('data_saida', 'data saída'),
    },
    {
      title: 'Ações',
      key: 'extra_actions',
      align: 'center',
      render: (text, record) => (
        <span key={record.grupo_id}>
          {/* <Popconfirm
						title='Tem certeza que deseja remover do time?'
						onConfirm={() => deleteTimePessoa(record.grupo_id, id)}
						okText='Sim'
						cancelText='Não'
					>
						<a href='javascript:;'>Excluir</a>
					</Popconfirm>
					<Divider type='vertical' />
					<a href='javascript:;'>Desativar</a> */}
          {parseInt(record.status) !== 0 && !lider ? (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title="Tem certeza que deseja remover do time?"
                onConfirm={() => deleteTimePessoa(record.grupo_id, id)}
                okText="Sim"
                cancelText="Não"
              >
                <a href="javascript:;" aria-label="remover">
                  <Tooltip title="Remover" placement="bottom">
                    <DeleteOutlined />
                  </Tooltip>
                </a>
              </Popconfirm>
            </>
          ) : (
            <></>
          )}
        </span>
      ),
    },
  ]

  return (
    <div className="perfil__tabs__times-cargos">
      <div className="perfil__tabs__times-cargos__table__title">
        <h3>Cargo</h3>

        {!lider && (
          <Button
            icon={<UserAddOutlined />}
            type="primary"
            onClick={() => openModal('alocarCargo')}
          >
            VINCULAR A UM CARGO
          </Button>
        )}
      </div>
      <Table
        bordered
        columns={columnsCargos}
        dataSource={cargos.map((cargo) => ({
          ...cargo,
          key: cargo.cargopes_id,
        }))}
        isEditable
        key={(record) => record.key}
        onSave={handleSaveCargo}
      />

      {!isCandidato && (
        <>
          <Divider />

          <div className="perfil__tabs__times-cargos__table__title">
            <h3>Time</h3>

            {!lider && (
              <Button
                icon={<UsergroupAddOutlined />}
                type="primary"
                onClick={() => openModal('alocarTime')}
              >
                VINCULAR A UM TIME
              </Button>
            )}
          </div>
          <Table
            bordered
            columns={columnsTimes}
            dataSource={times.map((time, index) => ({
              ...time,
              key: time.grupopessoa_id,
            }))}
            isEditable
            key={(record) => record.key}
            onSave={handleSaveTime}
          />
        </>
      )}
    </div>
  )
}

export default TabTimesCargos
