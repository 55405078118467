import React from 'react'

import { Button, Col } from 'antd'

import { ArrowRightOutlined } from '@ant-design/icons'

import { Container, Wrapper, Etapas } from './styles'

function Introducao({ handleNext }) {
  const customDot = (dot, { status, index }) => <>{dot}</>

  return (
    <Wrapper xs={22} md={20}>
      <Container>
        <h3>
          Em apenas 3 passos, você responderá a pesquisa de Perfil
          Comportamental
        </h3>
        <Etapas>
          <div>
            <span>Essência</span>
            <p>Como você se vê?</p>
          </div>
          <div>
            <span>Exigido</span>
            <p>O que as pessoas do seu ambiente de trabalho exigem de você?</p>
          </div>
          <div>
            <span>Entrega</span>
            <p>
              O que você está conseguindo entregar, no dia-a-dia, apesar das
              exigências que fazem de você?
            </p>
          </div>
        </Etapas>
        <div>
          <span>5 dicas para responder</span>

          <div>
            <span>
              1) Não existem respostas certas ou erradas, apenas o que tem mais
              ou menos a ver com você;
            </span>
            <span>2) Separe um tempo exclusivo para respondê-la;</span>
            <span>
              3) Responda à pesquisa em um ambiente tranquilo e sem
              interferências;
            </span>
            <span>
              4) Em cada um dos passos marque quantas características você
              considerar necessárias;
            </span>
            <span>5) Seja sincero!</span>
          </div>
        </div>
        <Button type="primary" size="large" onClick={handleNext}>
          CLIQUE AQUI PARA COMEÇAR
        </Button>
      </Container>
    </Wrapper>
  )
}

export default Introducao
