// Redux types
import * as constants from '../constants/constantsEmailGestor'

export const getQuestionarioGestor = ({
  id = null,
  contaUrl = null,
  hash = null,
}) => {
  return {
    type: constants.GET_QUESTIONARIO_GESTOR,
    api: {
      method: 'get',
      url: !id
        ? `${constants.URL_GET_QUESTIONARIO_GESTOR}/${contaUrl}/${hash}`
        : `${constants.URL_GET_QUESTIONARIO_GESTOR}/${id}`,
    },
  }
}

export const putQuestionarioGestor = ({
  type,
  contaUrl,
  id,
  hash,
  objetivosRespostas,
  culturaRespostas,
}) => {
  return {
    type: constants.PUT_QUESTIONARIO_GESTOR,
    api: {
      method: 'put',
      url: !(type === 'editar')
        ? `${constants.URL_PUT_QUESTIONARIO_GESTOR}/${contaUrl}/${hash}`
        : `${constants.URL_PUT_QUESTIONARIO_GESTOR}/${id}`,
      data: {
        objetivosRespostas,
        culturaRespostas,
      },
    },
  }
}

export const getCulturaQuestoes = () => {
  return {
    type: constants.GET_QUESTIONARO_GESTOR_CULTURA_QUESTOES,
    api: {
      method: 'get',
      url: constants.URL_GET_QUESTIONARO_GESTOR_CULTURA_QUESTOES,
    },
  }
}

export const getObjetivosQuestoes = ({ contaUrl = null, hash = null }) => {
  return {
    type: constants.GET_QUESTIONARO_GESTOR_OBJETIVOS_QUESTOES,
    api: {
      method: 'get',
      url: hash
        ? `${constants.URL_GET_QUESTIONARO_GESTOR_OBJETIVOS_QUESTOES}/${contaUrl}/${hash}`
        : constants.URL_GET_QUESTIONARO_GESTOR_OBJETIVOS_QUESTOES,
    },
  }
}
