// Redux types
import * as constants from '../constants/constantsNovaSenha'

export const postNovaSenha = ({
  user_senha,
  user_senha_old,
  authorization,
}) => {
  return {
    type: constants.NOVA_SENHA_POST,
    api: {
      method: 'put',
      url: `${constants.URL_NOVA_SENHA}`,
      defaults: { headers: { authorization } },
      data: {
        user_senha,
        user_senha_old,
      },
    },
  }
}
