import { toast } from 'react-toastify'

import * as constants from '../constants/constantsFormularioQuiz'

const initialState = {
  resposta: [],
}

export const formularioQuiz = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_RESPOSTA': {
      const { data } = action

      return { resposta: data }
    }
    case 'POST_RESPOSTA_QUESTAO': {
      const { data } = action
      const { resposta } = state

      let index = resposta.findIndex((questao) => {
        return questao.questao_id == data.questao_id
      })

      resposta[index] = data

      return { ...state, resposta }
    }

    default:
      return { ...state }
  }
}

export default formularioQuiz
