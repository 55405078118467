// types
export const TECNICA_LIST = 'TECNICA_LIST'
export const TECNICA_POST = 'TECNICA_POST'
export const TECNICA_UPDATE = 'TECNICA_UPDATE'
export const TECNICA_DELETE = 'TECNICA_DELETE'
export const TECNICA_LOAD = 'TECNICA_LOAD'

export const FREQUENCIA_CARGO = 'FREQUENCIA_CARGO'

// urls
export const URL_TECNICA = `${process.env.REACT_APP_API_URL}/cargo`
export const URL_TECNICA_ID = (id, tecnica_id) =>
  `${URL_TECNICA}/${id}/tecnicas/${tecnica_id}`
export const URL_TECNICA_POST = (id) => `${URL_TECNICA}/${id}/tecnicas`
export const URL_TECNICA_LIST = (id) => `${URL_TECNICA}/${id}/tecnicas`

export const URL_FREQUENCIA_CARGO = `/cargo/tecnicas/frequencias`
