// Redux types
import * as constants from '../constants/constantsValoresOrganizacionais'

export const getValoresOrganizacionais = () => {
  return {
    type: constants.VALORES_ORGANIZACIONAIS_GET,
    api: {
      method: 'get',
      url: constants.URL_VALORES_ORGANIZACIONAIS,
      data: {},
    },
  }
}

export const putValoresOrganizacionais = (valoresOrganizacionais) => {
  return {
    type: constants.VALORES_ORGANIZACIONAIS_PUT,
    api: {
      method: 'put',
      url: constants.URL_VALORES_ORGANIZACIONAIS,
      data: { valores: [...valoresOrganizacionais] },
    },
  }
}
