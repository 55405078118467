import { connect } from 'react-redux'
import * as actionsPerfil from './actions/actionsPerfilCandidato'
import {
  postCargoCandidato,
  deleteCargoCandidato,
} from '../cargosDetalhes/actions/actionsCargosDetalhes'
import {
  getCargos as getCargosDisponiveis,
  postCargo,
} from '../cargos/actions/actionsCargos'
import {
  getCandidatos,
  efetivaCandidato,
  triarCandidatos,
} from '../candidatos/actions/actionsCandidatos'
import {
  sendVetorComportamental,
  sendPercepcao,
} from '../gestaoPesquisasColaboradores/actions/actionsGestaoPesquisas'

// Components
import PerfilCandidatoController from './PerfilCandidatoController'

// Actions

const mapStateToProps = ({ perfilCandidato, times, cargos }) => {
  return {
    ...perfilCandidato,
    timesDisponiveis: times.list,
    cargosDisponiveis: cargos.list,
  }
}

export default connect(mapStateToProps, {
  ...actionsPerfil,
  getCargosDisponiveis,
  postCargoCandidato,
  getCandidatos,
  efetivaCandidato,
  sendVetorComportamental,
  sendPercepcao,
  deleteCargoCandidato,
  triarCandidatos,
  postCargo,
})(PerfilCandidatoController)
