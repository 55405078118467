import * as constants from '../constants'

export const listTimes = () => {
  return {
    type: constants.PS_FLUXO_REQUISICOES_LIST_TIMES,
    api: {
      method: 'get',
      url: constants.URL_PS_FLUXO_REQUISICOES_LIST_TIMES,
    },
  }
}

export const listEtapas = (grupo_id) => {
  return {
    type: constants.PS_FLUXO_REQUISICOES_LIST_ETAPAS,
    api: {
      method: 'get',
      url: constants.URL_PS_FLUXO_REQUISICOES_LIST_ETAPAS(grupo_id),
    },
  }
}

export const storeEtapa = (grupo_id, data) => {
  return {
    type: constants.PS_FLUXO_REQUISICOES_STORE_ETAPA,
    api: {
      method: 'post',
      url: constants.URL_PS_FLUXO_REQUISICOES_STORE_ETAPA(grupo_id),
      data,
    },
  }
}

export const updateEtapa = (grupo_id, id, data) => {
  return {
    type: constants.PS_FLUXO_REQUISICOES_UPDATE_ETAPA,
    api: {
      method: 'put',
      url: constants.URL_PS_FLUXO_REQUISICOES_UPDATE_ETAPA(grupo_id, id),
      data,
    },
  }
}

export const deleteEtapa = (grupo_id, id) => {
  return {
    type: constants.PS_FLUXO_REQUISICOES_DELETE_ETAPA,
    api: {
      method: 'delete',
      url: constants.URL_PS_FLUXO_REQUISICOES_DELETE_ETAPA(grupo_id, id),
    },
  }
}
