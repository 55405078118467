import React, { Component, useCallback, useState } from 'react'

import { toast } from 'react-toastify'

// Components
import { SearchOutlined } from '@ant-design/icons'
import { Input, Button, message } from 'antd'
import TimesView from './TimesView'
import { sorterString } from '../../utils/utils'
// Constants
import { modals } from './constants/constantsTimes'

import { validateForm } from '../../utils/forms'

const formFields = [
  {
    name: 'nome',
    required: true,
  },
]

class TimesController extends Component {
  state = {
    showModals: [false, false],
    dateSource: [],
    modalStates: modals.map((modal) => ({ ...modal.initialState })),
    selectedRows: [],
    selectedRowKeys: [],
    error: {},
  }

  componentDidMount() {
    this.getTimes()
    this.getFiliais()
  }

  getTimes = () => {
    const { getTimes } = this.props
    getTimes().then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          dataSource: response.data.grupos.map((grupo) => ({
            ...grupo,
            key: grupo.grupo_id,
            children: grupo.children.map((child) => {
              return { ...child, key: child.grupo_id }
            }),
          })),
        })
      }
    })
  }

  getFiliais = () => {
    const { getFiliais } = this.props
    getFiliais().then(({ response }) => {
      if (response.status === 200) {
        /* Ordenando as filiais por nome */
        const responseSort = response.data.sort((a, b) =>
          sorterString(a, b, 'nome')
        )
        const filiaisAtivias = responseSort.filter(
          (filial) => filial.ativo === 1
        )
        this.setState({
          dataFilial: filiaisAtivias.map((filial) => ({
            filial_id: filial.filial_id,
            nome: filial.nome,
          })),
        })
      }
    })
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  handleChangeModal = (id, { target }) =>
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.value } : elem
      ),
    }))

  handleChangeCheckbox = (id, { target }) =>
    this.setState((prevState) => ({
      modalStates: prevState.modalStates.map((elem, index) =>
        index === id ? { ...elem, [target.name]: target.checked } : elem
      ),
    }))

  openModal = (id) => {
    const { showModals } = this.state
    const newshowModals = showModals

    newshowModals[id] = true

    this.setState({ showModals: newshowModals })
  }

  closeModal = (id) => {
    const { showModals, modalStates } = this.state
    const newshowModals = showModals

    newshowModals[id] = false

    const newModalStates = modalStates

    newModalStates[id] = modals[id].initialState

    this.setState({
      showModals: newshowModals,
      modalStates: newModalStates,
      error: {},
    })
  }

  onModalSubmit = (id) => {
    const { modalStates, selectedRows } = this.state
    const {
      postTime,
      getTimes,
      getFiliais,
      sendVetorComportamental,
      sendValoresOrganizacionais,
      sendPercepcao,
    } = this.props

    if (id === 0) {
      let sucessoEnvioPesquisas = 0
      selectedRows.forEach((row) => {
        if (modalStates[id].vetorComportamental)
          sendVetorComportamental(row.key, {
            enviarEmails: modalStates[id].enviarEmails,
          }).then(({ response }) => {
            if (response.status === 200) {
              sucessoEnvioPesquisas++
              if (sucessoEnvioPesquisas === selectedRows.length)
                message.success('Enviado com sucesso!')
            }
          })
        if (modalStates[id].percepcaoAmbiente)
          sendPercepcao(row.key, {
            enviarGruposFilho: modalStates[id].enviarGruposFilho,
            enviarEmails: modalStates[id].enviarEmails,
          }).then(({ response }) => {
            if (response.status === 200) {
              sucessoEnvioPesquisas++
              if (sucessoEnvioPesquisas === selectedRows.length)
                message.success('Enviado com sucesso!')
            }
          })

        if (modalStates[id].valoresOrganizacionais)
          sendValoresOrganizacionais(row.key, {
            enviarGruposFilho: modalStates[id].enviarGruposFilho,
            enviarEmails: modalStates[id].enviarEmails,
          }).then(({ response }) => {
            if (response.status === 200) {
              sucessoEnvioPesquisas++
              if (sucessoEnvioPesquisas === selectedRows.length)
                message.success('Enviado com sucesso!')
            }
          })
      })
      this.closeModal(0)
    } else if (id === 1) {
      const error = validateForm(formFields, modalStates[id])
      this.setState({ error: error.messages })

      if (error.hasError) return

      postTime(modalStates[id]).then(({ response }) => {
        console.log(response)
        if (
          response.status === 200 &&
          response.data.message != 'Grupo informado já existe'
        ) {
          message.success('Adicionado com sucesso!')
          this.getTimes()
          this.closeModal(1)
        } else {
          message.error(response.data.message)
        }
      })
    }
  }

  onTableAdd = (newData) => {
    const { dataSource } = this.state

    dataSource.push(newData)

    this.setState({
      dataSource,
    })
  }

  onTableDelete = (id) => {
    const { deleteTime } = this.props

    deleteTime(id).then(({ response }) => {
      if (response.status === 200) {
        message.success('Excluído com sucesso!')
        this.getTimes()
      } else {
        message.error('Erro ao excluir, tente novamente.')
      }
    })
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedRows,
    })
  }

  getColumnSearchProps = (dataIndex, label = '') => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Buscar por ${label || dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  handleSaveTime = (timeUpdated, data) => {
    const { grupo_id } = timeUpdated
    const { updateTime } = this.props

    updateTime(grupo_id, timeUpdated).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Grupo vinculado com sucesso.')
        this.getTimes()
      }
    })
  }

  render() {
    const {
      onModalSubmit,
      openModal,
      closeModal,
      handleChangeModal,
      handleChangeCheckbox,
      onSelectChange,
      getColumnSearchProps,
      onTableDelete,
      handleSaveTime,
    } = this
    const {
      showModals,
      selectedRowKeys,
      dataSource,
      dataFilial,
      modalStates,
      colaboradores,
      error,
    } = this.state

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    }

    return (
      <TimesView
        openModal={openModal}
        closeModal={closeModal}
        onModalSubmit={onModalSubmit}
        showModals={showModals}
        modalStates={modalStates}
        handleChangeModal={handleChangeModal}
        handleChangeCheckbox={handleChangeCheckbox}
        dataSource={dataSource}
        dataFilial={dataFilial}
        rowSelection={rowSelection}
        colaboradores={colaboradores}
        getColumnSearchProps={getColumnSearchProps}
        onTableDelete={onTableDelete}
        error={error}
        handleSaveTime={handleSaveTime}
      />
    )
  }
}

export default TimesController
