// Redux types
import * as constants from '../constants/constantsPerfilCandidato'

export const getInfoPerfil = ({ id }) => {
  return {
    type: constants.PERFIL_INFO_GET,
    api: {
      method: 'get',
      url: `${constants.URL_PERFIL_INFO_GET}/${id}`,
      data: {},
    },
  }
}

export const getVetores = ({ id }) => {
  return {
    type: constants.VETORES_GET,
    api: {
      method: 'get',
      url: `${constants.URL_VETORES_GET}/${id}`,
      data: {},
    },
  }
}

export const getVetorComportamental = ({ id }) => {
  return {
    type: constants.VETOR_COMPORTAMENTAL_GET,
    api: {
      method: 'get',
      url: `${constants.URL_VETOR_COMPORTAMENTAL_GET}/${id}`,
      data: {},
    },
  }
}

export const ativarPerfil = ({ id }) => {
  return {
    type: constants.ATIVAR_PERFIL_DELETE,
    api: {
      method: 'post',
      url: `${constants.URL_ATIVAR_PERFIL_DELETE}/${id}`,
      data: {},
    },
  }
}

export const desativarPerfil = ({ id }) => {
  return {
    type: constants.DESATIVAR_PERFIL_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_DESATIVAR_PERFIL_DELETE}/${id}`,
      data: {},
    },
  }
}

export const excluirPerfil = ({ id }) => {
  return {
    type: constants.EXCLUIR_PERFIL_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_EXCLUIR_PERFIL_DELETE}/${id}`,
      data: {},
    },
  }
}

export const getCargos = ({ id }) => {
  return {
    type: constants.PERFIL_CARGOS_GET,
    api: {
      method: 'get',
      url: `${constants.URL_PERFIL_CARGOS_GET}/${id}`,
      data: {},
    },
  }
}

export const cancelVetorComportamental = (id, avalia_id) => {
  return {
    type: constants.VETOR_COMPORTAMENTAL_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_VETOR_COMPORTAMENTAL_DELETE}/${id}/${avalia_id}`,
    },
  }
}

export const listProcessos = (candidato_id) => {
  return {
    type: constants.PERFIL_CANDIDATO_PROCESSOS_LIST,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_CANDIDATO_PROCESSOS_LIST(candidato_id),
    },
  }
}

export const listLogs = ({ etapacand_id, processo_id }) => {
  return {
    type: constants.PERFIL_CANDIDATO_PROCESSO_LOG_LIST,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_CANDIDATO_PROCESSO_LOG_LIST({
        etapacand_id,
        processo_id,
      }),
    },
  }
}

export const storeLog = ({ etapacand_id, processo_id, observacao }) => {
  return {
    type: constants.PERFIL_CANDIDATO_PROCESSO_LOG_STORE,
    api: {
      method: 'post',
      url: constants.URL_PERFIL_CANDIDATO_PROCESSO_LOG_STORE({
        etapacand_id,
        processo_id,
      }),
      data: {
        observacao,
      },
    },
  }
}

export const deleteLog = ({ etapacand_id, processo_id, log_id }) => {
  return {
    type: constants.PERFIL_CANDIDATO_PROCESSO_LOG_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_PERFIL_CANDIDATO_PROCESSO_LOG_DELETE({
        etapacand_id,
        processo_id,
        log_id,
      }),
    },
  }
}

export const getCultura = (pes_id) => {
  return {
    type: constants.PERFIL_CANDIDATO_CULTURA_GET,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_CANDIDATO_CULTURA_GET(pes_id),
    },
  }
}

export const getCulturaOrg = (pes_id) => {
  return {
    type: constants.PERFIL_CANDIDATO_CULTURAORG_GET,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_CANDIDATO_CULTURAORG_GET(pes_id),
    },
  }
}

export const getFitCultural = (pes_id) => {
  return {
    type: constants.PERFIL_CANDIDATO_FITCULTURAL_GET,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_CANDIDATO_FITCULTURAL_GET(pes_id),
    },
  }
}

export const getCulturaEmpresa = () => {
  return {
    type: constants.PERFIL_CANDIDATO_CULTURA_EMPRESA_GET,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_CANDIDATO_CULTURA_EMPRESA_GET,
    },
  }
}

export const getCulturaOrgEmpresa = () => {
  return {
    type: constants.PERFIL_CANDIDATO_CULTURAORG_EMPRESA_GET,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_CANDIDATO_CULTURAORG_EMPRESA_GET,
    },
  }
}

export const listValoresOrg = () => {
  return {
    type: constants.PERFIL_CANDIDATO_VALORES_ORG_GET,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_CANDIDATO_VALORES_ORG_GET,
    },
  }
}

export const listProcessosTriagem = () => {
  return {
    type: constants.PERFIL_CANDIDATO_PROCESSOS_TRIAGEM_LIST,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_CANDIDATO_PROCESSOS_TRIAGEM_LIST,
    },
  }
}

export const perfilCandidatoDestroy = () => {
  return {
    type: constants.PERFIL_CANDIDATO_DESTROY,
  }
}

export const listCargos = () => {
  return {
    type: constants.PERFIL_CANDIDATO_LIST_CARGOS,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_CANDIDATO_LIST_CARGOS,
    },
  }
}

export const putCandidato = ({ pes_id, data }) => {
  return {
    type: constants.PERFIL_CANDIDATO_PUT_CANDIDATO,
    api: {
      method: 'put',
      url: constants.URL_PERFIL_CANDIDATO_PUT_CANDIDATO(pes_id),
      data: {
        ...data,
      },
    },
  }
}

export const contratarCandidato = ({
  pes_id,
  pes_cpf,
  cargo,
  data_contratacao,
}) => {
  return {
    type: constants.PERFIL_CANDIDATO_CONTRATAR_CANDIDATO,
    api: {
      method: 'post',
      url: constants.URL_PERFIL_CANDIDATO_CONTRATAR_CANDIDATO(pes_id),
      data: {
        pes_cpf,
        cargo,
        data_contratacao,
      },
    },
  }
}

export const validateTriagem = ({ pes_id }) => {
  return {
    type: constants.PERFIL_CANDIDATO_VALIDATE_TRIAGEM,
    api: {
      method: 'get',
      url: constants.URL_PERFIL_CANDIDATO_VALIDATE_TRIAGEM(pes_id),
    },
  }
}
