import { connect } from 'react-redux'
import * as actions from './actions/actionsEsqueciMinhaSenha'

// Components
import EsqueciMinhaSenhaController from './EsqueciMinhaSenhaController'

// Actions

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, { ...actions })(
  EsqueciMinhaSenhaController
)
