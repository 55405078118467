import React, { useState, useCallback, useEffect, useMemo } from 'react'

import { useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Card,
  Button,
  Tabs,
  Modal,
  Popconfirm,
  Tooltip,
  Badge,
  Divider,
  Table,
} from 'antd'

import {
  MailFilled,
  DeleteOutlined,
  ExclamationCircleFilled,
  EditOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import moment from 'moment'

import { sorterString, sorterDate } from '../../utils/utils'
import { getContaURL } from '../../services/auth'
import history from '../../utils/history'

import {
  getAnexosTemplatesAutomaticos,
  getTemplatesManuais,
  storeNewManualEmailTemplate,
  updateManualTemplate,
  deleteManualEmailTemplate,
  getAnexosTemplatesManuais,
  storeAnexoManualEmailTemplate,
  deleteAnexoManualEmailTemplate,
  getEmailsManuaisParams,
} from './actions'

import { listColaboradores } from '../Usuarios/actions'

// components
import NovoEmailManualTemplate from './components/NovoEmailManualTemplate'

const { TabPane } = Tabs

function ConfigProcessoSeletivo() {
  const dispatch = useDispatch()

  const [colaboradores, setColaboradores] = useState([])

  const [templatesManuais, setTemplatesManuais] = useState([])
  const [showModalNewManualTemplate, setShowModalNewManualTemplate] =
    useState(false)

  /* Anexos temporários */
  const [anexosTemporarios, setAnexosTemporarios] = useState([])

  /* Anexos manuais */
  const [anexosTemplatesManuais, setAnexosTemplatesManuais] = useState([])

  /* Udpate Email Manual */
  const [currentEmailManualId, setCurrentEmailManualId] = useState()
  const [showModalUpdateEmailManual, setShowModalUpdateEmailManual] =
    useState(false)

  /* Parâmetros para emails manuais */
  const [parametros, setParametros] = useState([])

  const loadColaboradores = useCallback(() => {
    dispatch(listColaboradores()).then(({ response }) => {
      if (response.status === 200) {
        const { pessoas } = response.data
        setColaboradores(pessoas)
      }
    })
  }, [dispatch])

  /* Armazenar templates de e-mails manuais */
  const loadTemplatesManuais = useCallback(() => {
    dispatch(getTemplatesManuais()).then(({ response }) => {
      if (response.status === 200) {
        setTemplatesManuais(response.data)
      }
    })
  }, [dispatch])

  /* Armazenar anexos dos templates de e-mails manuais */
  const loadAnexosTemplatesManuais = useCallback(() => {
    dispatch(getAnexosTemplatesManuais()).then(({ response }) => {
      if (response.status === 200) {
        setAnexosTemplatesManuais(response.data)
      }
    })
  }, [dispatch])

  /* Armazenar os parâmetros para emails manuais */
  const loadParametrosEmailsManuais = useCallback(() => {
    dispatch(getEmailsManuaisParams()).then(({ response }) => {
      if (response.status === 200) {
        setParametros(response.data)
      }
    })
  }, [dispatch])

  useEffect(() => {
    loadColaboradores()
    loadTemplatesManuais()
    loadAnexosTemplatesManuais()
    loadParametrosEmailsManuais()
  }, [
    loadColaboradores,
    loadTemplatesManuais,
    loadAnexosTemplatesManuais,
    loadParametrosEmailsManuais,
  ])

  const handleCloseNewManualTemplate = useCallback(() => {
    setShowModalNewManualTemplate(false)
    setAnexosTemporarios([])
  }, [])

  const handleCloseUpdateEmailManualTemplate = useCallback(() => {
    setShowModalUpdateEmailManual(false)
    setAnexosTemporarios([])
  }, [])

  const usuariosOptions = useMemo(() => {
    return colaboradores.map((colaborador) => {
      return {
        label: colaborador.pes_nome,
        value: colaborador.pes_id,
      }
    })
  }, [colaboradores])

  const handleNewManualTemplate = useCallback(
    (data) => {
      const anexo = new FormData()

      for (const files of anexosTemporarios) {
        for (const file of files) {
          if (file.type !== 'application/pdf') {
            toast.error(
              'Arquivo inválido. Por favor, selecione apenas arquivos .pdf'
            )
          } else {
            anexo.append('anexo', file)
          }
        }
      }

      dispatch(
        storeNewManualEmailTemplate(
          data.descricao,
          data.assunto,
          data.cco,
          data.textoemail.level.content
        )
      ).then(({ response }) => {
        if (response.status === 200) {
          const { email_id } = response.data
          dispatch(storeAnexoManualEmailTemplate(anexo, email_id)).then(
            ({ response }) => {
              if (response.status === 200) {
                toast.success('Template criado com sucesso.')
                handleCloseNewManualTemplate()
                loadTemplatesManuais()
                loadAnexosTemplatesManuais()
                setAnexosTemporarios([])
              }
            }
          )
        }
      })
    },
    [
      dispatch,
      handleCloseNewManualTemplate,
      loadTemplatesManuais,
      loadAnexosTemplatesManuais,
      anexosTemporarios,
    ]
  )

  const handleAddAnexoTeste = useCallback(
    (e) => {
      setAnexosTemporarios([...anexosTemporarios, Array.from(e.target.files)])
    },
    [anexosTemporarios]
  )

  const handleDeleteAnexoListaTemporaria = (
    originalindex,
    originalsubindex
  ) => {
    const newTeste = []
    anexosTemporarios.map((value, index) =>
      value.map((c, subindex) =>
        !(index === originalindex && subindex === originalsubindex)
          ? newTeste.push(c)
          : null
      )
    )
    setAnexosTemporarios([newTeste])
  }

  /* Para o modal de edição de e-mails manuais - o arquivo já é salvo diretamente */
  const handleUploadAnexos = useCallback(
    (e) => {
      const newFiles = e.target.files
      const anexo = new FormData()

      for (const file of newFiles) {
        if (file.type !== 'application/pdf') {
          toast.error(
            'Arquivo inválido. Por favor, selecione apenas arquivos .pdf'
          )
        } else {
          anexo.append('anexo', file)
        }
      }

      dispatch(storeAnexoManualEmailTemplate(anexo, currentEmailManualId)).then(
        ({ response }) => {
          if (response.status === 200) {
            toast.success('Anexo adicionado com sucesso.')
            loadAnexosTemplatesManuais()
          }
        }
      )
    },
    [currentEmailManualId, dispatch, loadAnexosTemplatesManuais]
  )

  /* Emails manuais */
  /* const handleChangeContentStateManualEmail = useCallback(
    ({ id, content }) => {
      const newTemplates = [...templatesManuais];

      const newTemplate = newTemplates.find((t) => t.id === id);

      newTemplate.textoemail = content;

      newTemplate.updated = true;

      setTemplatesManuais([...newTemplates]);
    },
    [templatesManuais]
  ); */

  /* Emails manuais */
  /* const handleChangeTemplateSubjectManualEmail = useCallback(
    ({ id, subject }) => {
      const newTemplates = [...templatesManuais];

      const newTemplate = newTemplates.find((t) => t.id === id);

      newTemplate.assunto = subject;

      newTemplate.updated = true;

      setTemplatesManuais([...newTemplates]);
    },
    [templatesManuais]
  ); */

  /* Emails manuais */
  /* const handleChangeTemplateCcoManualEmail = useCallback(
    ({ id, cco }) => {
      const newTemplates = [...templatesManuais];
      const newTemplate = newTemplates.find((t) => t.id === id);
      newTemplate.cco = cco;
      newTemplate.updated = true;
      setTemplatesManuais([...newTemplates]);
    },
    [templatesManuais]
  ); */

  /* Emails manuais */

  const handleDeleteManualEmailTemplate = useCallback(
    (id) => {
      dispatch(deleteManualEmailTemplate(id)).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Template excluído com sucesso.')
          loadTemplatesManuais()
        }
      })
    },
    [dispatch, loadTemplatesManuais]
  )

  /* Remover anexo */
  const handleDeleteAnexoManualEmailTemplate = useCallback(
    (id) => {
      dispatch(deleteAnexoManualEmailTemplate(id)).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Anexo excluído com sucesso.')
          loadAnexosTemplatesManuais()
        }
      })
    },
    [dispatch, loadAnexosTemplatesManuais]
  )

  /* Editar Email Manual */
  const handleInitUpdateEmailManualTemplate = useCallback((id) => {
    setCurrentEmailManualId(id)
    setShowModalUpdateEmailManual(true)
  }, [])

  /* Obtendo informações de um Template de Email Manual Específico */
  const currentEmailManualTemplate = useMemo(() => {
    return templatesManuais.find((email) => email.id === currentEmailManualId)
  }, [currentEmailManualId, templatesManuais])

  /* Salvando edição do template manual */
  const handleUpdateEmailTemplateManual = useCallback(
    (data) => {
      if (data.textoemail.level === undefined) {
        dispatch(
          updateManualTemplate(currentEmailManualId, {
            assunto: data.assunto,
            descricao: data.descricao,
            textoemail: data.textoemail,
            cco: data.cco,
          })
        ).then(({ response }) => {
          if (response.status === 200) {
            toast.success('Template atualizado com sucesso!')
            handleCloseUpdateEmailManualTemplate()
            loadTemplatesManuais()
          }
        })
      } else {
        dispatch(
          updateManualTemplate(currentEmailManualId, {
            assunto: data.assunto,
            descricao: data.descricao,
            textoemail: data.textoemail.level.content,
            cco: data.cco,
          })
        ).then(({ response }) => {
          if (response.status === 200) {
            toast.success('Template atualizado com sucesso!')
            handleCloseUpdateEmailManualTemplate()
            loadTemplatesManuais()
          }
        })
      }
    },
    [
      currentEmailManualId,
      dispatch,
      handleCloseUpdateEmailManualTemplate,
      loadTemplatesManuais,
    ]
  )

  /* Salvar alterações nos templates manuais */

  /* const handleSaveManualTemplates = useCallback(() => {
    const updatedManualTemplates = [...templatesManuais].filter(
      (template) => template.updated
    );

    const updatePromises = [];

    updatedManualTemplates.forEach((template) => {
      updatePromises.push(
        dispatch(
          updateManualTemplate(template.id, {
            tipo: template.tipo,
            textoemail: template.textoemail,
            ativo: template.ativo,
            assunto: template.assunto,
            cco: template.cco,
          })
        )
      );
    });

    Promise.all(updatePromises).then((payload) => {
      if (
        payload.length > 0 &&
        payload.filter(({ response }) => response.status !== 200).length === 0
      ) {
        toast.success("Templates de e-mail atualizados com sucesso.");
        loadTemplatesManuais();
      } else {
        toast.error("Ocorreu um erro ao salvar os templates de e-mail.");
      }
    });
  }, [dispatch, loadTemplatesManuais, templatesManuais]); */

  // Colunas para apresentação dos e-mails manuais
  const columnsTemplatesManuais = useMemo(
    () => [
      {
        title: 'Status',
        key: 'status',
        align: 'center',
        render: (text, record) => {
          return (
            <>
              {record.ativo ? (
                <Tooltip title="Ativo">
                  <Badge status="success" />
                </Tooltip>
              ) : (
                <Tooltip title="Inativo">
                  <Badge status="default" />
                </Tooltip>
              )}
            </>
          )
        },
      },
      {
        title: 'Assunto',
        dataIndex: 'assunto',
        width: '10%',
        sorter: (a, b) => sorterString(a, b, 'assunto'),
      },
      {
        title: 'Descrição',
        dataIndex: 'descricao',
        width: '15%',
        sorter: (a, b) => sorterString(a, b, 'descricao'),
        editable: true,
        align: 'center',
      },
      {
        title: 'Quantidade anexos',
        dataIndex: 'id',
        width: '10%',
        sorter: (a, b) => sorterString(a, b, 'id'),
        render: (text, record) => {
          const anexos = anexosTemplatesManuais.filter(
            (item) => item.idemail === parseInt(text)
          )
          return <span>{anexos && anexos.length}</span>
        },
        editable: true,
        align: 'center',
      },
      {
        title: 'Criado em',

        width: '20%',
        sorter: (a, b) => sorterDate(a, b, 'createdAt'),
        render: (text, record) => {
          return moment(record.createdAt)
            .utc()
            .tz(moment.tz.guess())
            .format('DD/MM/YYYY (HH:mm)')
        },
        align: 'center',
      },
      {
        title: 'Alterado por',
        dataIndex: 'updatedBy',
        width: '15%',
        sorter: (a, b) => sorterString(a, b, 'updatedBy'),
        render: (text, record) => {
          const usuario = usuariosOptions.find(
            (usuario) => usuario.value === parseInt(text)
          )
          return <span>{usuario && usuario.label}</span>
        },
        align: 'center',
      },
      {
        title: 'Alterado em',
        dataIndex: 'updatedAt',
        width: '20%',
        sorter: (a, b) => sorterDate(a, b, 'updatedAt'),
        render: (text, record) => {
          return moment(record.updatedAt)
            .utc()
            .tz(moment.tz.guess())
            .format('DD/MM/YYYY (HH:mm)')
        },
        align: 'center',
      },
      {
        title: 'Ações',
        key: 'actions',
        render: (text, record) => {
          return (
            <>
              {parseInt(record.ativo) === 1 ? (
                <>
                  <Tooltip title="Editar">
                    <a>
                      <EditOutlined
                        onClick={() =>
                          handleInitUpdateEmailManualTemplate(record.id)
                        }
                      />
                    </a>
                  </Tooltip>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="Tem certeza que deseja excluir o template?"
                    onConfirm={() => handleDeleteManualEmailTemplate(record.id)}
                    okText="Excluir"
                    cancelText="Cancelar"
                    icon={
                      <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                    }
                  >
                    <Tooltip title="Excluir">
                      <a>
                        <DeleteOutlined />
                      </a>
                    </Tooltip>
                  </Popconfirm>
                </>
              ) : null}
            </>
          )
        },
      },
    ],
    [
      usuariosOptions,
      anexosTemplatesManuais,
      handleDeleteManualEmailTemplate,
      handleInitUpdateEmailManualTemplate,
    ]
  )

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Col span={24}>
            <Tabs
              defaultActiveKey="4"
              tabBarStyle={{
                marginBottom: '8px',
                paddingRight: '8px',
                paddingLeft: '8px',
              }}
              onTabClick={(tab) => {
                tab === '1' && history.push(`/${getContaURL()}/config/planos`)
                tab === '2' &&
                  history.push(`/${getContaURL()}/config/pesquisas`)
                tab === '3' && history.push(`/${getContaURL()}/config/usuarios`)
              }}
            >
              <TabPane tab={<span>Limites de Cadastro</span>} key="1" />
              <TabPane tab={<span>Pesquisas</span>} key="2" />
              <TabPane tab={<span>Usuários</span>} key="3" />
              <TabPane tab={<span>Processo Seletivo</span>} key="4">
                <Card ex>
                  <Col span={24}>
                    <Tabs
                      defaultActiveKey="2"
                      tabBarStyle={{
                        marginBottom: '8px',
                        paddingRight: '8px',
                        paddingLeft: '8px',
                      }}
                      onTabClick={(tab) => {
                        tab === '1' &&
                          history.push(
                            `/${getContaURL()}/config/processos-seletivos/email-automatico`
                          )
                      }}
                    >
                      <TabPane
                        tab={<span>Envio de e-mails automático</span>}
                        key="1"
                      />

                      <TabPane
                        tab={<span>Envio de e-mail manual</span>}
                        key="2"
                      >
                        <Row style={{ marginBottom: 10 }}>
                          <Button
                            type="primary"
                            style={{ marginLeft: 'auto' }}
                            onClick={() => setShowModalNewManualTemplate(true)}
                          >
                            <MailFilled /> NOVO
                          </Button>
                        </Row>
                        <Row>
                          {/* {templatesManuais.map((template) => (
                          <EditorCol key={template.id} xs={24} sm={24} xxl={12}>
                            <span style={{ fontWeight: "bold", marginBottom: 10 }}>
                              {template.descricao}
                            </span>
                            <Row gutter={32}>
                              <ButtonUploadWrapper
                                style={{
                                  marginBottom: loadingUploadAnexos ? 0 : 30,
                                }}
                              >
                                <ButtonUpload type="submit">
                                  <input
                                    type="file"
                                    multiple
                                    accept="application/pdf"
                                    onChange={(e) => handleAddAnexoTeste(e)}
                                  />
                                  <UploadOutlined /> ADICIONAR ARQUIVOS
                                </ButtonUpload>
                              </ButtonUploadWrapper>
                              <button
                                type="button"
                                onClick={handleStoreAnexoEmailManual}
                              >
                                upload
                              </button>
                            </Row>
                            <>
                              {loadingUploadAnexos && (
                                <div style={{ marginBottom: 24 }}>
                                  <LoadingOutlined />
                                  <span
                                    style={{
                                      marginLeft: 10,
                                      fontSize: 12,
                                    }}
                                  >
                                    Fazendo upload dos arquivos...
                                  </span>
                                </div>
                              )}
                              <ContainerAnexos>
                                {anexosTemplatesManuais
                                  .filter((item) => item.idemail === template.id)
                                  .map((opt) => (
                                    <AnexoWrapper key={opt.idanexo}>
                                      <a target="_blank" href={opt.url}>
                                        <span>
                                          {opt.titulo.length > 12
                                            ? `${opt.titulo.substring(0, 6)}...pdf`
                                            : `${opt.titulo}.pdf`}
                                        </span>
                                      </a>
                                      <a>
                                        <DeleteOutlined
                                          className="delete-anexo"
                                          onClick={() =>
                                            handleDeleteAnexoManualEmailTemplate(
                                              opt.idanexo
                                            )
                                          }
                                        />
                                      </a>
                                    </AnexoWrapper>
                                  ))}
                                {anexosTemporarios.map((item) => (
                                  <span>{item.name}</span>
                                ))}
                              </ContainerAnexos>
                            </>
                            <div className="editor-delete">
                              <Popconfirm
                                title="Tem certeza que deseja excluir o template?"
                                onConfirm={() =>
                                  handleDeleteManualEmailTemplate(template.id)
                                }
                                okText="Excluir"
                                cancelText="Cancelar"
                                icon={
                                  <ExclamationCircleFilled
                                    style={{ color: "#ef5472" }}
                                  />
                                }
                              >
                                <a>
                                  <DeleteOutlined size={45} />
                                </a>
                              </Popconfirm>
                            </div>
                            <div className="editor-subject">
                              <span>Assunto do e-mail</span>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  handleChangeTemplateSubjectManualEmail({
                                    id: template.id,
                                    subject: e.target.value,
                                  })
                                }
                                value={template.assunto}
                                disabled={!parseInt(template.ativo)}
                              />
                            </div>
                            <div className="editor-subject">
                              <span>CCO</span>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  handleChangeTemplateCcoManualEmail({
                                    id: template.id,
                                    cco: e.target.value,
                                  })
                                }
                                value={template.cco}
                                placeholder="Para mais de um email, separe com ponto e vírgula. Ex: joao@email.com; maria@email.com"
                                disabled={!parseInt(template.ativo)}
                              />
                            </div>
                            <TinyEditor
                              value={template.textoemail}
                              onEditorChange={(content) =>
                                handleChangeContentStateManualEmail({
                                  id: template.id,
                                  content,
                                })
                              }
                              disabled={!parseInt(template.ativo)}
                            />
                            {template.params && template.params.length > 0 && (
                              <div className="editor-legend">
                                <ul>
                                  {template.params.map((param) => (
                                    <li key={param.id}>
                                      <strong>{`{${param.param}}`}</strong> -{" "}
                                      {param.legend}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </EditorCol>
                        ))} */}
                          <Table
                            style={{
                              width: '100%',
                            }}
                            dataSource={templatesManuais
                              .filter((anexo) => {
                                /* if (showInativos) {
                                return user;
                              } */
                                return anexo
                              })
                              .map((anexo) => ({ ...anexo, key: anexo.id }))}
                            key={(record) => record.id}
                            columns={columnsTemplatesManuais}
                          />
                        </Row>
                        {/* {templatesManuais.length > 0 ? (
                        <Row style={{ marginTop: 16 }}>
                          <Button
                            type="primary"
                            style={{ marginLeft: "auto" }}
                            onClick={() => handleSaveManualTemplates()}
                          >
                            Salvar Templates
                          </Button>
                        </Row>
                      ) : null} */}
                      </TabPane>
                    </Tabs>
                  </Col>
                </Card>
              </TabPane>
            </Tabs>
          </Col>
        </Col>
      </Row>

      <Modal
        title="NOVO E-MAIL MANUAL"
        visible={showModalNewManualTemplate}
        cancelText="CANCELAR"
        onCancel={() => handleCloseNewManualTemplate()}
        okText="SALVAR"
        destroyOnClose
        width="75%"
        okButtonProps={{
          form: 'novo-email-manual',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <NovoEmailManualTemplate
          type="create"
          onFinish={handleNewManualTemplate}
          updateFile={handleAddAnexoTeste}
          handleRemoveFileFromList={handleDeleteAnexoListaTemporaria}
          files={anexosTemporarios}
          parametros={parametros}
        />
      </Modal>

      <Modal
        title="EDITAR E-MAIL MANUAL"
        visible={showModalUpdateEmailManual}
        cancelText="CANCELAR"
        onCancel={() => handleCloseUpdateEmailManualTemplate()}
        okText="SALVAR"
        destroyOnClose
        width="75%"
        okButtonProps={{
          form: 'novo-email-manual',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <NovoEmailManualTemplate
          type="update"
          onFinish={handleUpdateEmailTemplateManual}
          files={anexosTemplatesManuais}
          initialValues={{
            ...currentEmailManualTemplate,
          }}
          handleRemoveFile={handleDeleteAnexoManualEmailTemplate}
          updateFile={handleUploadAnexos}
          currentId={currentEmailManualId}
          parametros={parametros}
        />
      </Modal>
    </Col>
  )
}

export default ConfigProcessoSeletivo
