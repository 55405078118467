import styled from 'styled-components'

import { darken } from 'polished'

export const Container = styled.div``
export const FlexTablesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .ant-table-wrapper {
    flex: 1;
    .ant-table-body {
      margin: 0px 8px 8px 8px;

      table {
        border-collapse: separate;
        border-spacing: 0 4px;

        tbody {
        }

        td:hover {
          background: transparent !important;
        }
      }
    }
  }
`

export const Title = styled.span`
  font-weight: 500;
  font-size: 16px;
  margin-left: 16px;
`

export const TitleRow = styled.div`
  span {
    margin: 0;
  }
  display: flex;
  justify-content: center;
`

export const ProgressWrapper = styled.div`
  padding: 0px 10px 0px 10px;
  span {
    font-weight: 500;
  }
`

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${ProgressWrapper} {
    width: 49%;
  }
  margin-top: 24px;

  max-height: 300px;
  overflow-y: auto;
`

export const FitCulturalRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  > * > * {
    margin-top: 16px;
  }

  @media screen and (min-width: 768px) {
    justify-content: space-around;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    > * > * {
      margin: 0;
    }
  }

  ${FlexTablesContainer} {
    flex: 3;
  }

  .ant-progress {
    flex: 1;
  }
`

export const FitsContainer = styled.div`
  ${TitleRow} {
    margin-bottom: 24px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      & + div {
        margin-top: 36px;
      }

      > span {
        font-weight: 500;
        font-size: 18px;

        & + .ant-progress {
          margin-top: 20px;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    > div {
      flex-direction: row;
      > div {
        & + div {
          margin-top: 0;
        }
        flex: 1;
      }
    }
  }
`
