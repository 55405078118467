import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Row, Col, DatePicker } from 'antd'
import moment from 'moment'
import 'moment-timezone'

// Components
import Input, { TextArea, Range } from '../../../../components/input/Input'

// Constants

function AdicionarNovaAcao({
  intl,
  handleChangeModal,
  descricao,
  sabotadores,
  opcoes,
  start,
  end,
  error,
}) {
  return (
    <div>
      <Form>
        <Row gutter={20}>
          <Col md={24}>
            <Form.Item
              label="Descricao"
              validateStatus={error.descricao ? 'error' : ''}
              help={error.descricao || ''}
            >
              <Input
                type="text"
                placeholder="Descreva a ação"
                name="descricao"
                value={descricao || ''}
                onChange={handleChangeModal}
                withoutMargin
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col md={24}>
            <Form.Item
              label="Sabotadores"
              validateStatus={error.sabotadores ? 'error' : ''}
              help={error.sabotadores || ''}
            >
              <TextArea
                type="text"
                placeholder="Descreva os sabotadores"
                name="sabotadores"
                value={sabotadores || ''}
                onChange={handleChangeModal}
                withoutMargin
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col md={24}>
            <Form.Item
              label="Opcoes"
              validateStatus={error.opcoes ? 'error' : ''}
              help={error.opcoes || ''}
            >
              <TextArea
                type="text"
                placeholder="Informe as opções"
                name="opcoes"
                value={opcoes || ''}
                onChange={handleChangeModal}
                withoutMargin
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col md={24}>
            <Form.Item
              label="Prazo"
              validateStatus={error.startAndEnd ? 'error' : ''}
              help={error.startAndEnd || ''}
            >
              <Range
                format="DD/MM/YYYY"
                value={[
                  start ? moment(start).utc().tz(moment.tz.guess()) : null,
                  end ? moment(end).utc().tz(moment.tz.guess()) : null,
                ]}
                name="startAndEnd"
                onChange={(dates) =>
                  handleChangeModal({
                    target: {
                      name: 'startAndEnd',
                      value: [dates[0].toISOString(), dates[1].toISOString()],
                    },
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default injectIntl(AdicionarNovaAcao)
