import { Column } from '@ant-design/plots'

const App = (props) => {
  const data = []

  props.data.forEach((dados) => {
    if (dados.categoria === 'Muito Acima') data.push(dados)
  })
  props.data.forEach((dados) => {
    if (dados.categoria === 'Acima') data.push(dados)
  })
  props.data.forEach((dados) => {
    if (dados.categoria === 'Desejado') data.push(dados)
  })
  props.data.forEach((dados) => {
    if (dados.categoria === 'Abaixo') data.push(dados)
  })
  props.data.forEach((dados) => {
    if (dados.categoria === 'Muito Abaixo') data.push(dados)
  })

  const config = {
    data,
    xField: 'arquetipo',
    yField: 'valor',
    seriesField: 'categoria',
    isPercent: false,
    isStack: true,
  }
  return (
    <Column
      color={['#d9534f', '#ffcc5c', '#88d8b0', '#ffcc5c', '#d9534f']}
      {...config}
    />
  )
}

export default App
