import React from 'react'

import { Row, Col, Card, Table } from 'antd'
import {
  Chart,
  Interval,
  Line,
  Point,
  Axis,
  Tooltip,
  Coordinate,
  Legend,
} from 'bizcharts'

// Components
import Donut from './components/Donut'
import CardStatistic from './components/CardStatistic'
import PageHeader from '../../../../components/pageHeader/PageHeader'

// CSS
import './Infografico.css'

function Infografico({
  nome,
  donutText,
  handleChange,
  dataPComp,
  intensidade,
  dataDISC,
  rct,
  defaultRct,
  edl,
  defaultEdl,
  foc,
  defaultFoc,
  ode,
  oct,
  tdd,
  cor,
  erc,
  edc,
  ...props
}) {
  const colsDISC = {
    score: {
      min: 0,
      max: 100,
    },
  }
  const colsPComp = {
    score: {
      min: 0,
      max: 100,
    },
  }
  return (
    <div style={{ width: '1240px', height: '1754px', padding: '10px' }}>
      <Row>
        <PageHeader
          title={`Perfil Comportamental - ${nome}`}
          subtitle="Apollo III - 21/01/2019"
        />
      </Row>
      <Row gutter={24} type="flex" justify="space-between">
        <Col xs={12}>
          <Card
            className="infografico--pdf__card"
            title="Perfil comportamental"
          >
            <Chart height={400} data={dataPComp} scale={colsPComp} autoFit>
              <Legend />
              <Coordinate transpose />
              <Axis
                name="item"
                label={{
                  offset: 12,
                }}
              />
              <Axis name="score" />
              <Tooltip />
              <Interval
                position="item*score"
                color={['type', ['#30318b', '#e0dddc']]}
                adjust={[
                  {
                    type: 'dodge',
                    marginRatio: 1 / 32,
                  },
                ]}
              />
            </Chart>
          </Card>
        </Col>
        <Col xs={12}>
          <Card className="infografico--pdf__card" title="Gráfico D.I.S.C">
            <Chart height={400} data={dataDISC} scale={colsDISC} autoFit>
              <Coordinate type="polar" radius={0.8} />
              <Axis
                name="item"
                line={null}
                tickLine={null}
                grid={{
                  lineStyle: {
                    lineDash: null,
                  },
                  hideFirstLine: false,
                }}
              />
              <Tooltip />
              <Axis
                name="score"
                line={null}
                tickLine={null}
                grid={{
                  type: 'polygon',
                  lineStyle: {
                    lineDash: null,
                  },
                }}
              />
              <Legend name="type" marker="circle" offset={30} />
              <Line
                position="item*score"
                color={['type', ['#1890ff', '#db092f', '#2fc25b']]}
                size={2}
              />
              <Point
                position="item*score"
                color={['type', ['#1890ff', '#db092f', '#2fc25b']]}
                shape="circle"
                size={4}
                style={{
                  stroke: '#fff',
                  lineWidth: 1,
                  fillOpacity: 1,
                }}
              />
            </Chart>
          </Card>
        </Col>
      </Row>
      <Row gutter={24} type="flex" justify="space-between">
        <Col xs={12}>
          <Card
            className="infografico--pdf__card"
            title="Características Pessoais"
          >
            <Row>
              <h1 className="infografico--pdf__card__title-caracteristicas">
                Competências Organizacionais
              </h1>
              <Row gutter={24}>
                {cor.map(({ nome, selected }) => (
                  <Col xs={6} key={nome}>
                    <Card
                      className={`infografico--pdf__card infografico--pdf__card--caracteristicas ${
                        selected && 'infografico--pdf__card--selected'
                      }`}
                    >
                      {nome}
                    </Card>
                  </Col>
                ))}
              </Row>
            </Row>

            <Row>
              <h1 className="infografico--pdf__card__title-caracteristicas">
                Resolução de Conflitos
              </h1>
              <Row gutter={24}>
                {erc.map(({ nome, selected }) => (
                  <Col xs={6} key={nome}>
                    <Card
                      className={`infografico--pdf__card infografico--pdf__card--caracteristicas ${
                        selected && 'infografico--pdf__card--selected'
                      }`}
                    >
                      {nome}
                    </Card>
                  </Col>
                ))}
              </Row>
            </Row>

            <Row>
              <h1 className="infografico--pdf__card__title-caracteristicas">
                Estilos de Comunicação
              </h1>
              <Row gutter={24}>
                {edc.map(({ nome, selected }) => (
                  <Col xs={6} key={nome}>
                    <Card
                      className={`infografico--pdf__card infografico--pdf__card--caracteristicas ${
                        selected && 'infografico--pdf__card--selected'
                      }`}
                    >
                      {nome}
                    </Card>
                  </Col>
                ))}
              </Row>
            </Row>
          </Card>
        </Col>
        <Col xs={12}>
          <Card className="infografico__card" title="D.I.S.C">
            <Table
              className="infografico__card__table"
              columns={[
                {
                  title: 'Característica',
                  dataIndex: 'row',
                },
                {
                  title: 'Essência',
                  dataIndex: 'essencia',
                },
                {
                  title: 'Exigido',
                  dataIndex: 'exigido',
                },
                {
                  title: 'Entrega',
                  dataIndex: 'entrega',
                },
              ]}
              dataSource={intensidade}
              bordered
              pagination={false}
            />
          </Card>
        </Col>
        <Col xs={24}>
          <Card
            className="infografico--pdf__card"
            title="Outras Características"
          >
            <Row gutter={24} type="flex" justify="space-between">
              <Col xs={8}>
                <h1 className="infografico--pdf__donut-title">
                  Relação com o tempo
                </h1>
                <Donut
                  data={rct}
                  scale={{}}
                  handleChange={handleChange}
                  nameProp="defaultRct"
                  nomeCenter={defaultRct.nome}
                  scoreCenter={defaultRct.score}
                  nome="nome"
                  score="score"
                  color={['nome', ['#1890ff', '#f96c6c']]}
                />
              </Col>
              <Col xs={8}>
                <h1 className="infografico--pdf__donut-title">Liderança</h1>
                <Donut
                  data={edl}
                  scale={{}}
                  handleChange={handleChange}
                  nameProp="defaultEdl"
                  nomeCenter={defaultEdl.nome}
                  scoreCenter={defaultEdl.score}
                  nome="nome"
                  score="score"
                  color={['nome', ['#1890ff', '#f96c6c']]}
                />
              </Col>
              <Col xs={8}>
                <h1 className="infografico--pdf__donut-title">Foco</h1>
                <Donut
                  data={foc}
                  scale={{}}
                  handleChange={handleChange}
                  nameProp="defaultFoc"
                  nomeCenter={defaultFoc.nome}
                  scoreCenter={defaultFoc.score}
                  nome="nome"
                  score="score"
                  color={['nome', ['#1890ff', '#f96c6c']]}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24}>
          <Row gutter={24} type="flex" justify="space-between">
            <Col xs={8}>
              <CardStatistic
                nome="Obtenção de energia"
                maiorScore={ode.maior.score}
                menorScore={ode.menor.score}
                maiorNome={ode.maior.nome}
                menorNome={ode.menor.nome}
              />
            </Col>
            <Col xs={8}>
              <CardStatistic
                nome="Orientação"
                maiorScore={oct.maior.score}
                menorScore={oct.menor.score}
                maiorNome={oct.maior.nome}
                menorNome={oct.menor.nome}
              />
            </Col>
            <Col xs={8}>
              <CardStatistic
                nome="Tomada de decisão"
                maiorScore={tdd.maior.score}
                menorScore={tdd.menor.score}
                maiorNome={tdd.maior.nome}
                menorNome={tdd.menor.nome}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Infografico
