import styled from 'styled-components'

export const DragItem = styled.div`
  display: flex;

  span,
  p {
    margin: 0;
  }

  span {
    margin-right: 6px;
    color: #777;
  }

  &:before {
    content: '::';
    font-weight: bold;
    color: #babac6;
    background-size: 100% 100%;
    background-position: center;
    margin-right: 6px;
  }
`
