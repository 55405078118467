import React from 'react'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Table, Divider, Button, Badge, Popconfirm, message } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'

// Utils
import { sorterDate } from '../../../utils/utils'
import { getContaURL } from '../../../services/auth'
import Link from '../../../components/privateLink/PrivateLink'

function TabPesquisas({
  openModal,
  percepcaoAmbiente,
  vetorComportamental,
  getColumnSearchProps,
  getVetorComportamental,
  getPercepcaoAmbiente,
  sendVetorComportamental,
  sendPercepcao,
  cancelPercepcao,
  cancelVetorComportamental,
  id,
}) {
  const columnsPesquisas = [
    {
      title: 'Status',
      dataIndex: 'respondido',
      align: 'center',
      sorter: (a, b) => a.respondido - b.respondido,
      sortDirections: ['descend', 'ascend'],
      render: (text) => {
        return <Badge status={text === 1 ? 'success' : 'error'} />
      },
    },
    {
      title: 'Enviado Em',
      dataIndex: 'data_solicitacao',
      align: 'center',
      sorter: (a, b) =>
        sorterDate(a, b, 'data_solicitacao', 'DD/MM/YYYY (HH:mm)'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => text || '',
      ...getColumnSearchProps('data_solicitacao', 'data envio'),
    },
    {
      title: 'Respondido Em',
      dataIndex: 'data_entrega',
      align: 'center',
      sorter: (a, b) => sorterDate(a, b, 'data_entrega', 'DD/MM/YYYY (HH:mm)'),
      sortDirections: ['descend', 'ascend'],
      render: (text) => text || '',
      ...getColumnSearchProps('data_entrega', 'data resposta'),
    },
    {
      title: 'Ações',
      key: 'acoes',
      align: 'center',
      render: (text, record) => {
        return record.respondido === 1 ? (
          <Link to={`/dashboard?candidato=${record.pes_id}`}>
            Ver Resultado
          </Link>
        ) : (
          <span>
            <CopyToClipboard
              text={`${window.location.host}/${getContaURL()}/pesquisas/${
                record.tipo === 'Perfil Comportamental'
                  ? 'perfil-comportamental'
                  : 'percepcao-ambiente'
              }/${record.hashacesso}`}
              onCopy={() =>
                message.success(
                  'Link da pesquisa copiado para a área de transferência.'
                )
              }
            >
              <a href="javascript:;">Link da Pesquisa</a>
            </CopyToClipboard>
            <Divider type="vertical" />
            <Popconfirm
              title="Reenviar pesquisa?"
              onConfirm={() => {
                if (record.tipo === 'Perfil Comportamental')
                  sendVetorComportamental({
                    pessoas: [{ pes_id: id }],
                    enviarEmails: true,
                  }).then(({ response }) => {
                    if (response.status === 200) {
                      message.success('Enviado com sucesso!')
                    }
                  })
                else
                  sendPercepcao(id, { enviarEmails: true }).then(
                    ({ response }) => {
                      if (response.status === 200) {
                        message.success('Enviado com sucesso!')
                      }
                    }
                  )
              }}
              okText="Sim"
              cancelText="Não"
              icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
            >
              <a href="javascript:;">Reenviar</a>
            </Popconfirm>
            <Divider type="vertical" />
            <Popconfirm
              title="Cancelar pesquisa?"
              onConfirm={() => {
                const canceledId = record.pes_id || id

                if (record.tipo === 'Perfil Comportamental')
                  cancelVetorComportamental(canceledId, record.avalia_id).then(
                    ({ response }) => {
                      if (response.status === 200) {
                        message.success('Cancelado com sucesso!')
                      }
                      getVetorComportamental({ id: canceledId })
                    }
                  )
                else
                  cancelPercepcao(canceledId, record.avalia_id).then(
                    ({ response }) => {
                      if (response.status === 200) {
                        message.success('Cancelado com sucesso!')
                      }
                      getPercepcaoAmbiente({ id: canceledId })
                    }
                  )
              }}
              okText="Sim"
              cancelText="Não"
              icon={<ExclamationCircleFilled style={{ color: '#ef5472' }} />}
            >
              <a href="javascript:;">Cancelar</a>
            </Popconfirm>
          </span>
        )
      },
    },
  ]

  return (
    <div className="perfil__tabs__pesquisas">
      <div className="perfil__tabs__pesquisas__table">
        <div className="perfil__tabs__pesquisas__table__title">
          <h3>Percepção do ambiente</h3>

          <Button type="primary" onClick={() => openModal('enviarPesquisa')}>
            GERAR PESQUISA
          </Button>
        </div>
        <Table
          bordered
          columns={columnsPesquisas}
          dataSource={percepcaoAmbiente}
        />

        <Divider />

        <div className="perfil__tabs__pesquisas__table__title">
          <h3>Perfil Comportamental</h3>
        </div>
        <Table
          bordered
          columns={columnsPesquisas}
          dataSource={vetorComportamental}
        />
      </div>
    </div>
  )
}

export default TabPesquisas
