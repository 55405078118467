import { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import XLSX from 'xlsx'

import { Button, Card, Col, Input, Pagination, Row } from 'antd'

import { SearchOutlined } from '@ant-design/icons'

import { useFetch } from '../../../hooks/use-fetch'
import Fit from './fit'
import { Table } from './styles'

const PAGINATE = 10

function ListTimesFitCultural(_, searchInput) {
  const [pageIndex, setPageIndex] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const { response, isLoading } = useFetch(
    `/v2/grupo/paginate?paginate=${PAGINATE}&page=${pageIndex}`
  )

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder="Buscar"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  const columns = [
    {
      title: 'Time',
      dataIndex: 'nome',
      align: 'center',
      width: '25%',
    },
    {
      title: 'Fit Cultural',
      dataIndex: ['fit', 'geral'],
      align: 'center',
      render: (fit) => {
        return <Fit fit={fit} />
      },
      width: '10%',
    },
    {
      title: 'Fit (Arquétipos)',
      dataIndex: ['fit', 'arquetipos'],
      align: 'center',
      render: (fit) => {
        return <Fit fit={fit} />
      },
      width: '10%',
    },
    {
      title: 'Fit (Valores)',
      dataIndex: ['fit', 'valores'],
      align: 'center',
      render: (fit) => {
        return <Fit fit={fit} />
      },
      width: '10%',
    },
  ]

  useEffect(() => {
    if (!isLoading && response) {
      setTotalPages(response.total)
    }
  }, [isLoading, response])

  const exportarExcel = ({ times, title = 'times_fit' }) => {
    const aoa = [
      ['TIME', 'FIT CULTURAL', 'FIT (ARQUÉTIPOS)', 'FIT (VALORES)'],
      ...times.map((time) => [
        time.grupo_nome,
        time.fitCulturalEmpresa
          ? `${parseFloat(time.fitCulturalEmpresa.geral).toFixed(1)}%`
          : '',
        time.fitCulturalEmpresa && time.fitCulturalEmpresa.fitArquetipos
          ? `${parseFloat(time.fitCulturalEmpresa.fitArquetipos.geral).toFixed(
              1
            )}%`
          : '',
        time.fitCulturalEmpresa && time.fitCulturalEmpresa.fitValores
          ? `${parseFloat(time.fitCulturalEmpresa.fitValores.geral).toFixed(
              1
            )}%`
          : '',
      ]),
    ]

    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(aoa)

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Times - Fit Cultural')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `${title}.xlsx`)
  }

  function itemRender(current, type) {
    if (type === 'prev') {
      return (
        <Button
          p="3"
          disabled={!response || !response.hasPrev}
          onClick={() => setPageIndex(pageIndex - 1)}
        >
          <span role="img" aria-label="left" className="anticon anticon-left">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="left"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z" />
            </svg>
          </span>
        </Button>
      )
    }

    if (type === 'next') {
      return (
        <Button
          p="3"
          disabled={!response || !response.hasNext}
          onClick={() => setPageIndex(pageIndex + 1)}
        >
          <span role="img" aria-label="left" className="anticon anticon-left">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="left"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z" />
            </svg>
          </span>
        </Button>
      )
    }

    return <a onClick={() => setPageIndex(current)}>{current}</a>
  }

  return (
    <>
      <Helmet>
        <title>TeamHub | Times - Fit Cultural</title>
      </Helmet>
      <Col span={24}>
        <Row>
          <Col xs={24} sm={24}>
            <Card>
              <Table
                dataSource={response && response.docs}
                columns={columns}
                loading={isLoading}
                pagination={false}
              />
              <Pagination
                itemRender={itemRender}
                current={pageIndex}
                total={totalPages}
              />
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default ListTimesFitCultural
