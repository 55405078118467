import styled from 'styled-components'

import { Table as TableAntd, Col as ColAntd } from 'antd'

export const Container = styled(ColAntd)`
  .ant-row {
    display: flex;
  }
  > .ant-row {
    align-items: stretch;
    > .ant-col {
      display: flex;
      flex-direction: column;
      .ant-card {
        flex: 1;
        display: flex;
        flex-direction: column;
        .ant-card-body {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 10px;
        }
      }
    }
  }
`

export const Table = styled(TableAntd)`
  .ant-table-title {
    font-size: 13px;
    background-color: var(--purple);
    color: #fff;
  }

  table {
    min-height: 100%;
    td {
      font-size: 12px;
    }
    th {
      font-size: 12px;
    }
  }
`

export const Col = styled(ColAntd)``
