import * as constants from '../constants/constants'

export const load = () => {
  return {
    type: constants.PS_TIPOCONTRATO_LOAD,
  }
}

export const listTipoContrato = () => {
  return {
    type: constants.PS_TIPOCONTRATO_LIST,
    api: {
      method: 'get',
      url: constants.URL_PS_TIPOCONTRATO,
    },
  }
}

export const storeTipoContrato = (contrato) => {
  return {
    type: constants.PS_TIPOCONTRATO_STORE,
    api: {
      method: 'post',
      url: constants.URL_PS_TIPOCONTRATO,
      data: { ...contrato },
    },
  }
}

export const updateTipoContrato = ({ contrato_id, ...contrato }) => {
  return {
    type: constants.PS_TIPOCONTRATO_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_PS_TIPOCONTRATO_ID(contrato_id),
      data: { ...contrato },
    },
  }
}

export const deleteTipoContrato = ({ contrato_id }) => {
  return {
    type: constants.PS_TIPOCONTRATO_UPDATE,
    api: {
      method: 'delete',
      url: constants.URL_PS_TIPOCONTRATO_ID(contrato_id),
    },
  }
}
