// Redux types
import * as constants from '../constants/constantsObjetivosEstrategicos'
import { message } from 'antd'
import _ from 'lodash'
import { objetivosEstrategicosRate } from '../../../constants/global'
import { sorterString } from '../../../utils/utils'
import { parseCulturas } from '../../../utils/utils'

const initialState = {
  listaGestores: [],
  listaPesos: [
    {
      descricao: 'Alto',
      valor: 3,
    },
    {
      descricao: 'Médio',
      valor: 2,
    },
    {
      descricao: 'Baixo',
      valor: 1,
    },
  ],
  listaTimes: [
    {
      filial_id: 1,
      filial_nome: 'Filial',
      grupo_descricao: 'Grupo',
      grupo_id: 1,
      grupo_nome: 'Grupo',
      grupo_pai: 1,
      key: 23,
      qtde: 12,
    },
  ],
  listaFiliais: [
    {
      filial_id: 1,
      nome: 'Filial',
    },
  ],
  listaTimesPessoas: [
    {
      pes_id: 1,
      times: 'Times',
    },
  ],
  culturaDesejada: [],
  forcasEstrategicas: [],
  olhares: [],
  gestao: [],
  objetivosEstrategicos: [],
  listaObjetivosTeamhub: [],
  culturaConsensuada: [],
  listColaboradores: [],
  limites: null,
}

export const objetivosEstrategicos = (state = initialState, action) => {
  switch (action.type) {
    case constants.GESTORES_GET: {
      const { data, status } = action.response

      if (status !== 200) return { ...state }

      return {
        ...state,
        listaGestores: data.avaliadores,
      }
    }

    case constants.PESOS_GET: {
      const { data, status } = action.response

      if (status !== 200) return { ...state }

      return {
        ...state,
        listaPesos: data.pesos,
      }
    }

    case constants.TIMES_GET: {
      const { data, status } = action.response

      if (status !== 200) return { ...state }

      return {
        ...state,
        listaTimes: data.grupos,
      }
    }

    case constants.PS_FILIAL_LIST: {
      const { data, status } = action.response

      if (status !== 200) return { ...state }

      const responseSort = data.sort((a, b) => sorterString(a, b, 'nome'))
      const filiaisAtivias = responseSort.filter((filial) => filial.ativo === 1)

      return {
        ...state,
        listaFiliais: filiaisAtivias,
      }
    }

    case constants.TIMES_PESSOAS_GET: {
      const { data, status } = action.response

      if (status !== 200) return { ...state }

      return {
        ...state,
        listaTimesPessoas: data.listaTimes,
      }
    }

    case constants.ENVIAR_EMAILS_POST: {
      const { data, status } = action.response

      if (status !== 200) return { ...state }

      message.success(data.message)

      return {
        ...state,
      }
    }
    case constants.GET_OBJETIVOS_ESTRATEGICOS_CULTURAS:
    case constants.CULTURA_DESEJADA_GET: {
      const { data, status } = action.response
      if (status !== 200) return { ...state }

      const {
        culturaDesejada: culturaConsensuada,
        culturaDesejadaOriginal: culturaDesejada,
      } = data

      const { cultura, forcas, olhares, gestao } = parseCulturas({
        culturaDesejada: culturaDesejada ? culturaDesejada : culturaConsensuada,
        culturaConsensuada: culturaDesejada ? culturaConsensuada : null,
        custom: 'objetivos_estrategicos',
      })

      return {
        ...state,
        olhares,
        gestao,
        forcasEstrategicas: forcas,
        culturaDesejada: cultura,
        culturaConsensuada: cultura.filter((c) => c.Label === 'Ajustada'),
        ...(culturaConsensuada ? { limites: culturaConsensuada.limites } : {}),
      }
    }

    case constants.OBJETIVOS_ESTRATEGICOS_LIST_OBJETIVO: {
      const { data, status } = action.response
      if (status !== 200) return { ...state }

      const { objetivosEstrategicos } = data

      return { ...state, objetivosEstrategicos }
    }

    case constants.OBJETIVOS_ESTRATEGICOS_LIST_OBJETIVOS_TEAMHUB: {
      const { data, status } = action.response
      if (status !== 200) return { ...state }

      const { objetivosTeamhub } = data
      return { ...state, listaObjetivosTeamhub: objetivosTeamhub }
    }
    case constants.OBJETIVOS_ESTRATEGICOS_GET_COLABORADORES: {
      const { data, status } = action.response
      if (status !== 200) return { ...state }

      const { pessoas } = data

      const colaboradores = pessoas
        .filter(({ pes_ativo }) => pes_ativo === 1)
        .map(({ pes_id, pes_nome }) => ({
          nome: pes_nome,
          id: pes_id,
        }))

      return { ...state, listColaboradores: [...colaboradores] }
    }

    default:
      return { ...state }
  }
}
