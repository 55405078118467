import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { message } from 'antd'

// Components
import ValoresOrganizacionaisView from './ValoresOrganizacionaisView'

// CSS

// Constants
import {
  apiToFront,
  frontToApi,
} from './constants/constantsValoresOrganizacionais'

import { cards } from './constants/constantsValoresOrganizacionais'

import { validateForm } from '../../utils/forms'

const formFields = [
  {
    name: 'nomeValorOrganizacional',
    required: true,
  },
  { name: 'descricao', required: false },
  {
    name: 'comportamentos',
    required: true,
  },
  { name: 'usarponderacao', required: false },
]

class ValoresOrganizacionaisController extends Component {
  state = {
    valoresOrganizacionais: [...cards],
    nomeValorOrganizacional: '',
    fitcultural: undefined,
    descricao: undefined,
    modalCardIndex: null,
    showModal: false,
    comportamentos: [],
    error: {},
    novoComportamento: null,
    usarponderacao: false,
    isEdit: false,
  }

  componentDidMount = () => {
    const { getValoresOrganizacionais } = this.props

    getValoresOrganizacionais().then(({ response }) => {
      if (response.status === 200) {
        this.setState({
          valoresOrganizacionais: apiToFront(response.data),
        })
      }
    })
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  resetModal = () => {
    this.setState({
      modalCardIndex: null,
      nomeValorOrganizacional: '',
      descricao: undefined,
      fitcultural: undefined,
      comportamentos: [],
      error: {},
      novoComportamento: '',
      usarponderacao: false,
    })
  }

  openModal = (evt) => {
    if (evt && evt.card) {
      const { card, index } = evt

      this.setState({
        modalCardIndex: index,
        nomeValorOrganizacional: card.nome,
        descricao: card.descricao,
        fitcultural: card.fitcultural,
        comportamentos: card.comportamentos,
        usarponderacao: card.usarponderacao,
      })
    }

    this.setState({ showModal: true })
  }

  closeModal = () => this.setState({ showModal: false }, this.resetModal)

  onModalSubmit = () => {
    const {
      valoresOrganizacionais,
      modalCardIndex,
      nomeValorOrganizacional,
      comportamentos,
      fitcultural,
      descricao,
      usarponderacao,
    } = this.state
    const { putValoresOrganizacionais, getValoresOrganizacionais } = this.props

    const error = validateForm(formFields, this.state)
    this.setState({ error: error.messages })

    if (error.hasError) return

    const newValoresOrganizacionais = valoresOrganizacionais

    const newCard = {
      nome: nomeValorOrganizacional,
      comportamentos,
      fitcultural,
      descricao,
      usarponderacao,
    }

    if (modalCardIndex !== null) {
      newValoresOrganizacionais[modalCardIndex] = {
        ...newValoresOrganizacionais[modalCardIndex],
        ...newCard,
      }
    } else {
      newValoresOrganizacionais.push({
        ...newCard,
        disabled: false,
        default: false,
        excluded: false,
      })
    }

    putValoresOrganizacionais(frontToApi(newValoresOrganizacionais)).then(
      ({ response }) => {
        if (response.status === 200) {
          getValoresOrganizacionais().then(({ response }) => {
            if (response.status === 200) {
              this.setState({
                valoresOrganizacionais: apiToFront(response.data),
              })
            }
          })
          this.closeModal()
        } else {
          if (response.data.message) {
            message.error(response.data.message)
          }
          if (response.data.erros) {
            response.data.erros.map((item) => {
              return message.error(item.msg)
            })
          }
          newValoresOrganizacionais.pop()
        }
      }
    )
  }

  handleDisable = (index) => {
    const { valoresOrganizacionais } = this.state
    const newValoresOrganizacionais = valoresOrganizacionais

    newValoresOrganizacionais[index].disabled =
      !valoresOrganizacionais[index].disabled

    this.setState({
      valoresOrganizacionais: newValoresOrganizacionais,
    })
  }

  handleDelete = (index) => {
    const { valoresOrganizacionais } = this.state
    const { putValoresOrganizacionais, getValoresOrganizacionais } = this.props

    const newValoresOrganizacionais = valoresOrganizacionais

    newValoresOrganizacionais[index].excluded =
      !valoresOrganizacionais[index].excluded

    putValoresOrganizacionais(frontToApi(newValoresOrganizacionais)).then(
      ({ response }) => {
        if (response.status === 200) {
          getValoresOrganizacionais().then(({ response }) => {
            if (response.status === 200) {
              this.setState({
                valoresOrganizacionais: apiToFront(response.data),
              })
            }
          })
          this.closeModal()
        }
      }
    )
  }

  handleChangeNovoComportamento = (comportamento) => {
    this.setState({ novoComportamento: comportamento })
  }

  handleDeleteNovoComportamento = (comportamento) => {
    const { comportamentos } = this.state
    this.setState({
      comportamentos: comportamentos.filter(
        (c) => c.comportamento !== comportamento
      ),
    })
  }

  handleAddNovoComportamento = () => {
    const { novoComportamento: novoComportamentoRaw, comportamentos } =
      this.state

    const novoComportamento = novoComportamentoRaw.trim()

    if (
      novoComportamento &&
      novoComportamento !== '' &&
      comportamentos.findIndex((c) => c.comportamento === novoComportamento) < 0
    ) {
      this.setState({
        comportamentos: [
          ...comportamentos,
          { comportamento: novoComportamento, ponderacao: 0 },
        ],
        novoComportamento: null,
      })
    }
  }

  onSaveComportamento = (comportamento, comportamentosUpdated) => {
    this.setState({ comportamentos: [...comportamentosUpdated] })
  }

  render() {
    const {
      handleChange,
      handleDisable,
      handleDelete,
      closeModal,
      openModal,
      onModalSubmit,
      handleChangeNovoComportamento,
      handleAddNovoComportamento,
      handleDeleteNovoComportamento,
      onSaveComportamento,
    } = this

    const {
      valoresOrganizacionais,
      showModal,
      nomeValorOrganizacional,
      comportamentos,
      descricao,
      fitcultural,
      error,
      novoComportamento,
      usarponderacao,
    } = this.state

    return (
      <ValoresOrganizacionaisView
        handleChange={handleChange}
        handleDisable={handleDisable}
        handleDelete={handleDelete}
        closeModal={closeModal}
        openModal={openModal}
        onModalSubmit={onModalSubmit}
        cards={valoresOrganizacionais}
        showModal={showModal}
        nomeValorOrganizacional={nomeValorOrganizacional}
        comportamentos={comportamentos}
        descricao={descricao}
        fitcultural={fitcultural}
        error={error}
        handleChangeNovoComportamento={handleChangeNovoComportamento}
        handleAddNovoComportamento={handleAddNovoComportamento}
        novoComportamento={novoComportamento}
        handleDeleteNovoComportamento={handleDeleteNovoComportamento}
        onSaveComportamento={onSaveComportamento}
        usarponderacao={usarponderacao}
      />
    )
  }
}

export default ValoresOrganizacionaisController
