import React from 'react'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import moment from 'moment'

import {
  CaretRightOutlined,
  DotChartOutlined,
  ExclamationCircleFilled,
  GiftOutlined,
  HomeOutlined,
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  StarFilled,
  TeamOutlined,
  UserOutlined,
  WomanOutlined,
  ManOutlined,
  CalendarOutlined,
} from '@ant-design/icons'

import { Icon as LegacyIcon } from '@ant-design/compatible'

import {
  Row,
  Col,
  Avatar,
  Divider,
  Tag,
  Tabs,
  Timeline,
  Statistic,
  Button,
  Popconfirm,
  Modal,
  Select as AntdSelect,
  Tooltip,
} from 'antd'

// Components
import Select, { Option } from '../../components/select/Select'
import FitCulturalPerfil from '../../components/FitCulturalPerfil'
import TabPesquisas from './components/tabPesquisas'
import TabTimesCargos from './components/tabTimesCargos'
import EditarCandidato from './components/editarCandidato/EditarCandidato'
import EnviarPesquisa from '../../components/enviarPesquisa/EnviarPesquisa'
import TabProcessoSeletivo from './components/TabProcessoSeletivo'
import TabDetalhes from './components/tabDetalhes'
import TriarCandidato from '../candidatos/components/TriarCandidatos/index'
import CadastroCandidato from '../../components/CadastroCandidato'
import ContratarCandidato from '../recrutamentoSelecao/containers/PsKanban/components/ContratarCandidato'
import Confirm from '../../components/Confirm'
import AdicionarNovoCargo from '../cargos/components/adicionarNovoCargo/AdicionarNovoCargo'
import { ReactComponent as Svg } from '../../assets/onu_pcd.svg'

// CSS
import './PerfilCandidato.css'

// Constants
import { styleColors } from '../../utils/utils'

const { pink } = styleColors

const { TabPane } = Tabs

const childrenRender = (times, level = 0) => {
  return times.map((time) => [
    <Option value={time.grupo_id}>
      {_.times(level, () => (
        <>&emsp;</>
      ))}
      <CaretRightOutlined style={{ opacity: '0.8', fontSize: '12px' }} />{' '}
      {time.grupo_nome}
    </Option>,
    childrenRender(time.children, level + 1),
  ])
}

function PerfilViewCandidato({
  intl,
  handleChange,
  info,
  showModal,
  openModal,
  closeModal,
  onModalSubmit,
  timesDisponiveis,
  cargosDisponiveis,
  ativarPerfil,
  desativarPerfil,
  excluirPerfil,
  vetores,
  chartVetores,
  percepcaoAmbiente,
  vetorComportamental,
  isCandidato,
  efetivaCandidato,
  candidato,
  handleEfetivar,
  handleChangeModal,
  handleChangeCheckbox,
  handleChangeLider,
  cargos,
  sendVetorComportamental,
  cancelVetorComportamental,
  sendPercepcao,
  cancelPercepcao,
  getColumnSearchProps,
  deleteCargoCandidato,
  deleteTimePessoa,
  getVetorComportamental,
  getPercepcaoAmbiente,
  processos,
  processoSelected,
  handleCreatePsLog,
  handleDeletePsLog,
  handleChangePsSelected,
  logs,
  observacao,
  handleChangeObservacao,
  cultura,
  forcas,
  olhares,
  gestao,
  disc,
  culturaEmpresa,
  forcasEmpresa,
  olharesEmpresa,
  gestaoEmpresa,
  discEmpresa,
  fitCultural,
  culturaOrg,
  culturaOrgEmpresa,
  valoresOrgEmpresa,
  handleTriar,
  handleChangePsTriagem,
  processoTriagemSelected,
  processosTriagem,
  editCandidatoCurrentStep,
  handlePrevStepEditCandidato,
  handleNextStepEditCandidato,
  cargosList,
  handleSubmitEditCandidato,
  newCpfContratacao,
  handleChangeNewCpfContratacao,
  handleContratarCandidato,
  handleCancelContratacaoCandidato,
  handleCloseConfirmTriagem,
  confirmTriagemMessage,
  contratacaoCandidatoData,
  handleChangeContratacaoData,
  handleOpenContratacao,
  handleCreateCargo,
}) {
  return (
    <div className="perfil">
      <Row gutter={20}>
        <Col md={{ span: 6 }}>
          <div className="perfil__info">
            <div className="perfil__info__user">
              <Avatar
                size={150}
                icon={<UserOutlined />}
                className={`perfil__info__user__avatar${
                  info.ativo === 0 ? ' desativado' : ''
                }`}
              />
              <h2
                className={`perfil__info__user__nome${
                  info.ativo === 0 ? ' desativado' : ''
                }`}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {info.nome}
                {info.pcd === 1 && (
                  <Tooltip
                    title={info.pcd_tipo && `Tipo de PCD: ${info.pcd_tipo}`}
                  >
                    <Tag color="blue" style={{ marginLeft: 8 }}>
                      <Svg
                        height="22"
                        width="22"
                        style={{ display: 'flex', alignItems: 'center' }}
                      />
                    </Tag>
                  </Tooltip>
                )}
              </h2>
              <h3
                className={`perfil__info__user__cargo${
                  info.ativo === 0 ? ' desativado' : ''
                }`}
              >
                {info.ativo === 0 ? (
                  <span className="desativado">Desligado - </span>
                ) : (
                  ''
                )}
                {info.cargosEscolhidos.map(
                  (cargo) =>
                    cargo.status === '1' && (
                      <>
                        <span>{cargo.cargo_nome}</span>
                        <br />
                      </>
                    )
                )}
              </h3>

              <div className="perfil__info__user__dados">
                <span>
                  <MailOutlined />
                  <p>{info.email}</p>
                </span>
                <span>
                  <PhoneOutlined />
                  <p>{info.telefone}</p>
                </span>
                <span>
                  <IdcardOutlined />
                  <p>{info.documento}</p>
                </span>
                <span>
                  <GiftOutlined />
                  <p>
                    {info.nascimento} - {info.idadetotal}{' '}
                    {info.idade ? '(anos)' : ''}
                  </p>
                </span>
                <span>
                  <CalendarOutlined />
                  <p>
                    {info.admissao} - {info.tempoempresa}{' '}
                    {info.companyTime || info.companyTime === 0 ? '(anos)' : ''}
                  </p>
                </span>
                <span>
                  <span>
                    {info.sexo === 'Masculino' ? (
                      <ManOutlined />
                    ) : (
                      <WomanOutlined />
                    )}
                    <p>{info.sexo}</p>
                  </span>

                  <a onClick={() => openModal('editarCandidato')}>EDITAR</a>
                </span>
              </div>
            </div>
            {!isCandidato && (
              <>
                <Divider dashed />
                <div className="perfil__info__tags">
                  <h3>Times</h3>

                  {info.times
                    .filter((time) => time.data_saida === null)
                    .map((tag) => (
                      <Tag
                        key={tag.grupo_id}
                        closable={false}
                        className="perfil__info__tag"
                      >
                        {tag.lider === 1 && (
                          <StarFilled className="perfil__info__tag__lider" />
                        )}

                        {tag.grupo_nome}
                      </Tag>
                    ))}
                  <Tag
                    onClick={() => openModal('alocarTime')}
                    className="perfil__info__tag--adicionar"
                    style={{ cursor: 'pointer' }}
                  >
                    <PlusOutlined />
                  </Tag>
                </div>
              </>
            )}
            <Divider dashed />
            {/* <div className="perfil__info__acessos">
							<h3>Útimos Acessos</h3>

							<Timeline>
								<Timeline.Item>Acessado em 08/08/2017 às 18:54</Timeline.Item>
								<Timeline.Item>Acessado em 08/08/2017 às 18:54</Timeline.Item>
								<Timeline.Item>Acessado em 08/08/2017 às 18:54</Timeline.Item>
								<Timeline.Item>Acessado em 08/08/2017 às 18:54</Timeline.Item>
							</Timeline>
						</div>

						<Divider dashed /> */}

            <Row gutter={20}>
              <Col xxl={8}>
                <Popconfirm
                  title="Contratar Candidato"
                  onConfirm={() => handleContratarCandidato()}
                  okText="Sim"
                  cancelText="Não"
                  icon={
                    <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                  }
                >
                  <Button
                    className="perfil__info__login__btn"
                    type="primary"
                    icon={<LegacyIcon type="user-add" />}
                    style={{ marginTop: '10px' }}
                    block
                  >
                    CONTRATAR
                  </Button>
                </Popconfirm>
              </Col>
              <Col xxl={8}>
                <Popconfirm
                  title="Triar Candidato"
                  onConfirm={() => handleTriar()}
                  okText="Sim"
                  cancelText="Não"
                  icon={
                    <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                  }
                >
                  <Button
                    className={`perfil__info__login__btn${
                      info.ativo === 1 && !isCandidato ? '--desativar' : ''
                    }`}
                    type="primary"
                    icon={<LegacyIcon type="form" />}
                    style={{ marginTop: '10px' }}
                    block
                  >
                    TRIAR
                  </Button>
                </Popconfirm>
              </Col>
              <Col xxl={8}>
                <Popconfirm
                  title="Excluir Colaborador?"
                  onConfirm={excluirPerfil}
                  okText="Sim"
                  cancelText="Não"
                  icon={
                    <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                  }
                >
                  <Button style={{ marginTop: '10px' }} block>
                    EXCLUIR
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={{ span: 18 }}>
          <Row gutter={20}>
            <Col span={24}>
              <div className="perfil__data">
                <div className="perfil__data__item">
                  <Statistic
                    title="Vetor"
                    value={`${
                      (vetores.length > 0 &&
                        vetores[vetores.length - 1].primario) ||
                      ' '
                    }${
                      (vetores.length > 0 &&
                        vetores[vetores.length - 1].secundario) ||
                      ' '
                    }`}
                  />
                </div>
                <Divider
                  type="vertical"
                  className="perfil__data__item__divider"
                />
                <div className="perfil__data__item">
                  <Statistic
                    title="Dominância"
                    value={
                      (vetores.length > 0 && vetores[vetores.length - 1].D) || 0
                    }
                    suffix="%"
                    suffix="%"
                  />
                </div>
                <Divider
                  type="vertical"
                  className="perfil__data__item__divider"
                />
                <div className="perfil__data__item">
                  <Statistic
                    title="Influência"
                    value={
                      (vetores.length > 0 && vetores[vetores.length - 1].I) || 0
                    }
                    suffix="%"
                  />
                </div>
                <Divider
                  type="vertical"
                  className="perfil__data__item__divider"
                />
                <div className="perfil__data__item">
                  <Statistic
                    title="Estabilidade"
                    value={
                      (vetores.length > 0 && vetores[vetores.length - 1].S) || 0
                    }
                    suffix="%"
                    suffix="%"
                  />
                </div>
                <Divider
                  type="vertical"
                  className="perfil__data__item__divider"
                />
                <div className="perfil__data__item">
                  <Statistic
                    title="Conformidade"
                    value={
                      (vetores.length > 0 && vetores[vetores.length - 1].C) || 0
                    }
                    suffix="%"
                  />
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="perfil__tabs">
                <Tabs defaultActiveKey="1">
                  <TabPane tab={<span>Detalhes do Candidato</span>} key="2">
                    <TabDetalhes info={info} />
                  </TabPane>
                  {!isCandidato && (
                    <TabPane
                      tab={
                        <span>
                          <DotChartOutlined />
                          Pesquisas
                        </span>
                      }
                      key="3"
                    >
                      <TabPesquisas
                        openModal={openModal}
                        percepcaoAmbiente={percepcaoAmbiente}
                        vetorComportamental={vetorComportamental}
                        getColumnSearchProps={getColumnSearchProps}
                        sendVetorComportamental={sendVetorComportamental}
                        sendPercepcao={sendPercepcao}
                        cancelVetorComportamental={cancelVetorComportamental}
                        cancelPercepcao={cancelPercepcao}
                        id={info.id}
                        getVetorComportamental={getVetorComportamental}
                        getPercepcaoAmbiente={getPercepcaoAmbiente}
                      />
                    </TabPane>
                  )}
                  {!isCandidato && (
                    <TabPane
                      tab={
                        <span>
                          <TeamOutlined />
                          Times & Cargos
                        </span>
                      }
                      key="4"
                    >
                      <TabTimesCargos
                        openModal={openModal}
                        times={info.times}
                        cargos={info.cargos}
                        isCandidato={isCandidato}
                        getColumnSearchProps={getColumnSearchProps}
                        handleChangeLider={handleChangeLider}
                        deleteCargoCandidato={deleteCargoCandidato}
                        deleteTimePessoa={deleteTimePessoa}
                        id={info.id}
                      />
                    </TabPane>
                  )}

                  <TabPane tab={<span>Processos Seletivos</span>} key="5">
                    <TabProcessoSeletivo
                      processos={processos}
                      processoSelected={processoSelected}
                      handleCreatePsLog={handleCreatePsLog}
                      handleDeletePsLog={handleDeletePsLog}
                      handleChangePsSelected={handleChangePsSelected}
                      logs={logs}
                      observacao={observacao}
                      handleChangeObservacao={handleChangeObservacao}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        visible={showModal.alocarTime}
        title="Adicionar novo time a este colaborador"
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => closeModal('alocarTime')}
        onOk={() => onModalSubmit('alocarTime')}
        destroyOnClose
      >
        <div className="alocar-time">
          <Row>
            <Col md={24}>
              <Select
                label="Selecione o novo time a ser adicionado:"
                placeholder="Selecione o novo time"
                showSearch
                onChange={(evt) =>
                  handleChange(
                    {
                      target: {
                        name: 'id',
                        value: evt,
                      },
                    },
                    'alocarTime'
                  )
                }
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {childrenRender(timesDisponiveis)}
              </Select>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        visible={showModal.alocarCargo}
        title="Adicionar novo cargo a este colaborador"
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => closeModal('alocarCargo')}
        onOk={() => onModalSubmit('alocarCargo')}
        destroyOnClose
      >
        <div className="alocar-cargo">
          <Row>
            <Col md={24}>
              <Select
                label="Selecione o novo cargo a ser adicionado:"
                placeholder="Selecione o novo cargo"
                showSearch
                onChange={(evt) =>
                  handleChange(
                    {
                      target: {
                        name: 'id',
                        value: evt,
                      },
                    },
                    'alocarCargo'
                  )
                }
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cargosDisponiveis.map((cargo) => (
                  <Option value={cargo.cargo_id}>{cargo.cargo_nome}</Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        visible={showModal.escolherCargo}
        title="ESCOLHA O CARGO PARA CONTRATAR O CANDIDATO"
        cancelText="CANCELAR"
        okText="SALVAR"
        onCancel={() => handleCancelContratacaoCandidato()}
        onOk={() => handleOpenContratacao()}
      >
        <div className="adicionar-candidatos">
          <Row>
            <Col md={24}>
              <AntdSelect
                label="Cargo"
                placeholder="Selecione o cargo"
                name="cargo"
                onChange={(cargo) =>
                  handleChangeModal({
                    target: {
                      name: 'newCargoContratacao',
                      value: cargo,
                    },
                  })
                }
                style={{ width: '100%' }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                      }}
                    >
                      <Button
                        style={{
                          padding: '8px',
                          cursor: 'pointer',
                        }}
                        icon={<PlusOutlined />}
                        style={{ width: '80%', maxWidth: '300px' }}
                        type="dashed"
                        onClick={() => openModal('novoCargo')}
                      >
                        Novo Cargo
                      </Button>
                    </div>
                  </div>
                )}
                options={cargosList.map((cargo) => ({
                  label: cargo.cargo_nome,
                  value: cargo.cargo_id,
                }))}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        visible={showModal.enviarPesquisa}
        title="GERAR PESQUISA"
        cancelText="CANCELAR"
        okText="ENVIAR"
        onCancel={() => closeModal('enviarPesquisa')}
        onOk={() => onModalSubmit('enviarPesquisa')}
        destroyOnClose
      >
        <EnviarPesquisa handleChangeCheckbox={handleChangeCheckbox} />
      </Modal>
      <Modal
        visible={showModal.triarCandidato}
        title="TRIAR CANDIDATO"
        cancelText="CANCELAR"
        okText="TRIAR"
        onCancel={() => closeModal('triarCandidato')}
        destroyOnClose
        okButtonProps={{
          form: 'triar-candidatos-submit',
          key: 'submit',
          htmlType: 'submit',
        }}
      >
        <TriarCandidato
          processos={processosTriagem}
          handleChangeModal={handleChangePsTriagem}
          processoSelected={processoTriagemSelected}
          onFinish={() => onModalSubmit('triarCandidato')}
          single
        />
      </Modal>
      <Modal
        visible={showModal.contratarCandidato}
        title="CONTRATAR CANDIDATO"
        cancelText="CANCELAR"
        okText="ENVIAR"
        onCancel={() => handleCancelContratacaoCandidato()}
        destroyOnClose
        okButtonProps={{
          form: 'contratar-candidato-submit',
          key: 'submit',
          htmlType: 'submit',
        }}
      >
        <ContratarCandidato
          onFinish={handleContratarCandidato}
          initialValues={
            contratacaoCandidatoData || {
              data_contratacao: moment(),
              pes_cpf: info.document,
            }
          }
        />
      </Modal>
      {/*
			<EditarCandidato
				handleChange={(evt) => handleChange(evt, 'editarCandidato')}
				showModal={showModal['editarCandidato']}
				onSubmit={() => onModalSubmit('editarCandidato')}
				closeModal={() => closeModal('editarCandidato')}
				cpf={info['documento']}
				nome={info['nome']}
				dataNascimento={info['nascimento']}
				email={info['email']}
				sexo={info['sexo']}
				// grupo={}
				telefone={info['telefone']}
			// cargo={}
			/>
			*/}
      <CadastroCandidato
        intl={intl}
        cargos={cargosList}
        initialValues={{
          ...info,
          pes_nome: info.nome,
          pes_cpf: info.documento,
          pes_datanascimento: moment(info.nascimento, 'DD/MM/YYYY'),
          pes_email: info.email,
          pes_sexo: info.sexo,
          pes_cel: info.telefone,
          pcd: info.pcd,
          pcd_tipo: info.pcd_tipo,
          cid: info.cid,
        }}
        pcdChecked={info.pcd}
        type="edit"
        visible={showModal.editarCandidato}
        closeModal={() => closeModal('editarCandidato')}
        onSubmit={handleSubmitEditCandidato}
      />
      <Modal
        visible={showModal.confirmTriagem}
        title="CONFIRMAR TRIAGEM CANDIDATO"
        cancelText="CANCELAR"
        okText="TRIAR"
        onCancel={() => handleCloseConfirmTriagem()}
        onOk={() => onModalSubmit('triarCandidato', true)}
        destroyOnClose
      >
        <Confirm message={confirmTriagemMessage} />
      </Modal>
      <Modal
        visible={showModal.novoCargo}
        title="NOVO CARGO"
        cancelText="CANCELAR"
        onCancel={() => closeModal('novoCargo')}
        okText="SALVAR"
        okButtonProps={{
          form: 'novo-cargo-submit',
          key: 'submit',
          htmlType: 'submit',
        }}
      >
        <AdicionarNovoCargo onFinish={handleCreateCargo} />
      </Modal>
    </div>
  )
}

export default injectIntl(PerfilViewCandidato)
