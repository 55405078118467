import AdicionarNovaAcao from '../components/AdicionarNovaAcao'
import AdicionarPDT from '../components/AdicionarPDT'
import AdicionarMeta from '../components/AdicionarMeta'

// PDT ACTIONS TYPE
export const CHECK_GRUPO_PDT = 'CHECK_GRUPO_PDT'
export const LIST_PDTS_GRUPO = 'LIST_PDTS_GRUPO'
export const GET_PDT = 'GET_PDT'
export const STORE_PDT = 'STORE_PDT'
export const COMPLETE_PDT = 'COMPLETE_PDT'
export const UPDATE_PDT = 'UPDATE_PDT'
export const DELETE_PDT = 'DELETE_PDT'

// PDT URLS
export const URL_CHECK_GRUPO_PDT = (grupo_id) =>
  `${process.env.REACT_APP_API_URL}/pessoas/check/${grupo_id}`

export const URL_PDT_BASE = `${process.env.REACT_APP_API_URL}/pdt`

export const URL_PDT = (pdi_td) => `${URL_PDT_BASE}/${pdi_td}`

export const URL_LIST_PDTS_GRUPO = (grupo_id) => `${URL_PDT_BASE}/${grupo_id}`

export const URL_GET_PDT = (pdt_id) => `${URL_PDT_BASE}/${pdt_id}/details`

export const URL_STORE_PDT = (grupo_id) => `${URL_PDT_BASE}/${grupo_id}`

export const URL_COMPLETE_PDT = (pdt_id) => `${URL_PDT_BASE}/${pdt_id}/complete`

export const URL_UPDATE_PDT = (pdt_id) => `${URL_PDT_BASE}/${pdt_id}`

export const URL_DELETE_PDT = (pdt_id) => `${URL_PDT_BASE}/${pdt_id}`

// META_PDT ACTIONS TYPE
export const GET_META_PDT = 'GET_META_PDT'
export const STORE_META_PDT = 'STORE_META_PDT'
export const UPDATE_META_PDT = 'UPDATE_META_PDT'
export const DELETE_META_PDT = 'DELETE_META_PDT'

// META_PDT URLS
export const URL_META_PDT = (pdt_id) => `${URL_PDT(pdt_id)}/meta`

export const URL_GET_META_PDT = (pdt_id) => `${URL_META_PDT(pdt_id)}`

export const URL_STORE_META_PDT = (pdt_id) => `${URL_META_PDT(pdt_id)}`

export const URL_UPDATE_META_PDT = (pdt_id, meta_id) =>
  `${URL_META_PDT(pdt_id)}/${meta_id}`

export const URL_DELETE_META_PDT = (pdt_id, meta_id) =>
  `${URL_META_PDT(pdt_id)}/${meta_id}`

// ACAO_PDT ACTIONS TYPE
export const GET_ACAO_PDT = 'GET_ACAO_PDT'
export const STORE_ACAO_PDT = 'STORE_ACAO_PDT'
export const COMPLETE_ACAO_PDT = 'COMPLETE_ACAO_PDT'
export const UPDATE_ACAO_PDT = 'UPDATE_ACAO_PDT'
export const DELETE_ACAO_PDT = 'DELETE_ACAO_PDT'

// ACAO_PDT URLS
export const URL_ACAO_PDT = (pdt_id, meta_id) =>
  `${URL_META_PDT(pdt_id)}/${meta_id}/acao`

export const URL_GET_ACAO_PDT = (pdt_id, meta_id) =>
  `${URL_ACAO_PDT(pdt_id, meta_id)}`

export const URL_STORE_ACAO_PDT = (pdt_id, meta_id) =>
  `${URL_ACAO_PDT(pdt_id, meta_id)}`

export const URL_COMPLETE_ACAO_PDT = (pdt_id, meta_id, acao_id) =>
  `${URL_ACAO_PDT(pdt_id, meta_id)}/${acao_id}/complete`

export const URL_UPDATE_ACAO_PDT = (pdt_id, meta_id, acao_id) =>
  `${URL_ACAO_PDT(pdt_id, meta_id)}/${acao_id}`

export const URL_DELETE_ACAO_PDT = (pdt_id, meta_id, acao_id) =>
  `${URL_ACAO_PDT(pdt_id, meta_id)}/${acao_id}`

// CHANGE PDT DATA
export const CHANGE_PDTS_DATA = 'CHANGE_PDTS_DATA'

// MODALS

export const acaoModals = [
  {
    id: 0,
    title: 'Adicionar Nova Ação',
    cancelText: 'CANCELAR',
    okText: 'ADICIONAR',
    children: AdicionarNovaAcao,
    initialState: {
      pdt: undefined,
      meta: undefined,
      descricao: undefined,
      sabotadores: undefined,
      opcoes: undefined,
      start: undefined,
      end: undefined,
      startAndEnd: undefined,
    },
  },
  {
    id: 1,
    title: 'Editar Ação',
    cancelText: 'CANCELAR',
    okText: 'SALVAR',
    children: AdicionarNovaAcao,
    initialState: {
      pdt: undefined,
      meta: undefined,
      descricao: undefined,
      sabotadores: undefined,
      opcoes: undefined,
      start: undefined,
      end: undefined,
      startAndEnd: undefined,
    },
  },
]

export const pdtModals = [
  {
    id: 2,
    title: 'Novo PDT',
    cancelText: 'CANCELAR',
    okText: 'SALVAR',
    children: AdicionarPDT,
    initialState: {
      situacao: undefined,
      comportamento: undefined,
      impacto: undefined,
      sugestao: undefined,
    },
  },
]

export const metaModals = [
  {
    id: 3,
    title: 'Nova Meta',
    cancelText: 'CANCELAR',
    okText: 'SALVAR',
    children: AdicionarMeta,
    initialState: {
      descricao: undefined,
      pdt: undefined,
    },
  },
]
