import * as constants from '../constants/constantsProcessoSeletivo'

export const getProcessos = () => {
  return {
    type: constants.PROCESSO_SELETIVO_LIST,
    api: {
      method: 'get',
      url: constants.URL_PROCESSO_SELETIVO_LIST,
    },
  }
}

export const storeProcesso = ({ ufid, cidadeid, homeoffice, ...data }) => {
  return {
    type: constants.PROCESSO_SELETIVO_STORE,
    api: {
      method: 'post',
      url: constants.URL_PROCESSO_SELETIVO_STORE,
      data: {
        ...data,
        parametros: [
          {
            param_id: 1,
            tipo: 'endereco',
            coluna: 'check_endereco_completo',
            obrigatorio: data.check_endereco_completo ? 1 : 0,
          },
          {
            param_id: 2,
            tipo: 'endereco',
            coluna: 'check_cep',
            obrigatorio: data.check_cep ? 1 : 0,
          },
          {
            param_id: 3,
            tipo: 'endereco',
            coluna: 'check_logradouro',
            obrigatorio: data.check_logradouro ? 1 : 0,
          },
          {
            param_id: 4,
            tipo: 'endereco',
            coluna: 'check_bairro',
            obrigatorio: data.check_bairro ? 1 : 0,
          },
          {
            param_id: 5,
            tipo: 'endereco',
            coluna: 'check_numero',
            obrigatorio: data.check_numero ? 1 : 0,
          },
          {
            param_id: 6,
            tipo: 'endereco',
            coluna: 'check_complemento',
            obrigatorio: data.check_complemento ? 1 : 0,
          },
          {
            param_id: 7,
            tipo: 'endereco',
            coluna: 'check_estado',
            obrigatorio: data.check_estado ? 1 : 0,
          },
          {
            param_id: 8,
            tipo: 'endereco',
            coluna: 'check_cidade',
            obrigatorio: data.check_cidade ? 1 : 0,
          },
          {
            param_id: 9,
            tipo: 'pessoa',
            coluna: 'check_nome_completo',
            obrigatorio: data.check_nome_completo ? 1 : 0,
          },
          {
            param_id: 10,
            tipo: 'pessoa',
            coluna: 'check_email',
            obrigatorio: data.check_email ? 1 : 0,
          },
          {
            param_id: 11,
            tipo: 'pessoa',
            coluna: 'check_cpf',
            obrigatorio: data.check_cpf ? 1 : 0,
          },
          {
            param_id: 12,
            tipo: 'pessoa',
            coluna: 'check_genero',
            obrigatorio: data.check_genero ? 1 : 0,
          },
          {
            param_id: 13,
            tipo: 'pessoa',
            coluna: 'check_data_nascimento',
            obrigatorio: data.check_data_nascimento ? 1 : 0,
          },
          {
            param_id: 14,
            tipo: 'pessoa',
            coluna: 'check_celular',
            obrigatorio: data.check_celular ? 1 : 0,
          },
          {
            param_id: 15,
            tipo: 'profissional',
            coluna: 'check_profissao',
            obrigatorio: data.check_profissao ? 1 : 0,
          },
          {
            param_id: 16,
            tipo: 'profissional',
            coluna: 'check_pretensao_salarial',
            obrigatorio: data.check_pretensao_salarial ? 1 : 0,
          },
          {
            param_id: 17,
            tipo: 'profissional',
            coluna: 'check_linkedin',
            obrigatorio: data.check_linkedin ? 1 : 0,
          },
          {
            param_id: 18,
            tipo: 'profissional',
            coluna: 'check_twitter',
            obrigatorio: data.check_twitter ? 1 : 0,
          },
          {
            param_id: 19,
            tipo: 'profissional',
            coluna: 'check_site_portfolio',
            obrigatorio: data.check_site_portfolio ? 1 : 0,
          },
          {
            param_id: 20,
            tipo: 'profissional',
            coluna: 'check_indicacao',
            obrigatorio: data.check_indicacao ? 1 : 0,
          },
          {
            param_id: 21,
            tipo: 'profissional',
            coluna: 'check_video_apresentacao',
            obrigatorio: data.check_video_apresentacao ? 1 : 0,
          },
          {
            param_id: 22,
            tipo: 'profissional',
            coluna: 'check_curriculo',
            obrigatorio: data.check_curriculo ? 1 : 0,
          },
          {
            param_id: 23,
            tipo: 'teste_plataforma',
            coluna: 'check_teste_perfil_comportamental',
            obrigatorio: data.check_teste_perfil_comportamental ? 1 : 0,
          },
          {
            param_id: 24,
            tipo: 'teste_plataforma',
            coluna: 'check_teste_percepcao_ambiente',
            obrigatorio: data.check_teste_percepcao_ambiente ? 1 : 0,
          },
          {
            param_id: 25,
            tipo: 'teste_plataforma',
            coluna: 'check_teste_valores_organizacionais',
            obrigatorio: data.check_teste_valores_organizacionais ? 1 : 0,
          },
        ],
        ...(homeoffice ? { homeoffice } : { cidadeid, ufid }),
      },
    },
  }
}

export const updateProcesso = ({
  processo_id,
  ufid,
  cidadeid,
  homeoffice,
  ...data
}) => {
  return {
    type: constants.PROCESSO_SELETIVO_UPDATE,
    api: {
      method: 'put',
      url: constants.URL_PROCESSO_SELETIVO_UPDATE(processo_id),
      data: {
        ...data,
        parametros: [
          {
            param_id: 1,
            tipo: 'endereco',
            coluna: 'check_endereco_completo',
            obrigatorio: data.check_endereco_completo ? 1 : 0,
          },
          {
            param_id: 2,
            tipo: 'endereco',
            coluna: 'check_cep',
            obrigatorio: data.check_cep ? 1 : 0,
          },
          {
            param_id: 3,
            tipo: 'endereco',
            coluna: 'check_logradouro',
            obrigatorio: data.check_logradouro ? 1 : 0,
          },
          {
            param_id: 4,
            tipo: 'endereco',
            coluna: 'check_bairro',
            obrigatorio: data.check_bairro ? 1 : 0,
          },
          {
            param_id: 5,
            tipo: 'endereco',
            coluna: 'check_numero',
            obrigatorio: data.check_numero ? 1 : 0,
          },
          {
            param_id: 6,
            tipo: 'endereco',
            coluna: 'check_complemento',
            obrigatorio: data.check_complemento ? 1 : 0,
          },
          {
            param_id: 7,
            tipo: 'endereco',
            coluna: 'check_estado',
            obrigatorio: data.check_estado ? 1 : 0,
          },
          {
            param_id: 8,
            tipo: 'endereco',
            coluna: 'check_cidade',
            obrigatorio: data.check_cidade ? 1 : 0,
          },
          {
            param_id: 9,
            tipo: 'pessoa',
            coluna: 'check_nome_completo',
            obrigatorio: data.check_nome_completo ? 1 : 0,
          },
          {
            param_id: 10,
            tipo: 'pessoa',
            coluna: 'check_email',
            obrigatorio: data.check_email ? 1 : 0,
          },
          {
            param_id: 11,
            tipo: 'pessoa',
            coluna: 'check_cpf',
            obrigatorio: data.check_cpf ? 1 : 0,
          },
          {
            param_id: 12,
            tipo: 'pessoa',
            coluna: 'check_genero',
            obrigatorio: data.check_genero ? 1 : 0,
          },
          {
            param_id: 13,
            tipo: 'pessoa',
            coluna: 'check_data_nascimento',
            obrigatorio: data.check_data_nascimento ? 1 : 0,
          },
          {
            param_id: 14,
            tipo: 'pessoa',
            coluna: 'check_celular',
            obrigatorio: data.check_celular ? 1 : 0,
          },
          {
            param_id: 15,
            tipo: 'profissional',
            coluna: 'check_profissao',
            obrigatorio: data.check_profissao ? 1 : 0,
          },
          {
            param_id: 16,
            tipo: 'profissional',
            coluna: 'check_pretensao_salarial',
            obrigatorio: data.check_pretensao_salarial ? 1 : 0,
          },
          {
            param_id: 17,
            tipo: 'profissional',
            coluna: 'check_linkedin',
            obrigatorio: data.check_linkedin ? 1 : 0,
          },
          {
            param_id: 18,
            tipo: 'profissional',
            coluna: 'check_twitter',
            obrigatorio: data.check_twitter ? 1 : 0,
          },
          {
            param_id: 19,
            tipo: 'profissional',
            coluna: 'check_site_portfolio',
            obrigatorio: data.check_site_portfolio ? 1 : 0,
          },
          {
            param_id: 20,
            tipo: 'profissional',
            coluna: 'check_indicacao',
            obrigatorio: data.check_indicacao ? 1 : 0,
          },
          {
            param_id: 21,
            tipo: 'profissional',
            coluna: 'check_video_apresentacao',
            obrigatorio: data.check_video_apresentacao ? 1 : 0,
          },
          {
            param_id: 22,
            tipo: 'profissional',
            coluna: 'check_curriculo',
            obrigatorio: data.check_curriculo ? 1 : 0,
          },
          {
            param_id: 23,
            tipo: 'teste_plataforma',
            coluna: 'check_teste_perfil_comportamental',
            obrigatorio: data.check_teste_perfil_comportamental ? 1 : 0,
          },
          {
            param_id: 24,
            tipo: 'teste_plataforma',
            coluna: 'check_teste_percepcao_ambiente',
            obrigatorio: data.check_teste_percepcao_ambiente ? 1 : 0,
          },
          {
            param_id: 25,
            tipo: 'teste_plataforma',
            coluna: 'check_teste_valores_organizacionais',
            obrigatorio: data.check_teste_valores_organizacionais ? 1 : 0,
          },
        ],
        descricao: data.descricao,
        ...(homeoffice
          ? { homeoffice }
          : { cidadeid, ufid, homeoffice: false }),
      },
    },
  }
}

export const deleteProcesso = (processo_id) => {
  return {
    type: constants.PROCESSO_SELETIVO_DELETE,
    api: {
      method: 'delete',
      url: constants.URL_PROCESSO_SELETIVO_DELETE(processo_id),
    },
  }
}

export const getCargos = () => {
  return {
    type: constants.PROCESSO_SELETIVO_CARGOS_GET,
    api: {
      method: 'get',
      url: constants.URL_PROCESSO_SELETIVO_CARGOS_GET,
    },
  }
}

export const getTimes = () => {
  return {
    type: constants.PROCESSO_SELETIVO_TIMES_GET,
    api: {
      method: 'get',
      url: constants.URL_PROCESSO_SELETIVO_TIMES_GET,
    },
  }
}

export const getUFs = () => {
  return {
    type: constants.PROCESSO_SELETIVO_UFS_GET,
    api: {
      method: 'get',
      url: constants.URL_PROCESSO_SELETIVO_UFS_GET,
    },
  }
}

export const getCidades = (UFID) => {
  return {
    type: constants.PROCESSO_SELETIVO_CIDADES_GET,
    api: {
      method: 'get',
      url: constants.URL_PROCESSO_SELETIVO_CIDADES_GET(UFID),
    },
  }
}

export const suspenderProcesso = (processo_id) => {
  return {
    type: constants.PROCESSO_SELETIVO_SUSPENSAO,
    api: {
      method: 'post',
      url: constants.URL_PROCESSO_SELETIVO_SUSPENSAO(processo_id),
    },
  }
}

export const getCargoAtividadesList = () => {
  return {
    type: constants.CARGO_ATIVIDADE_LIST,
    api: {
      method: 'get',
      url: constants.URL_CARGO_ATIVIDADE_LIST,
    },
  }
}

export const retomarProcesso = (processo_id) => {
  return {
    type: constants.PROCESSO_SELETIVO_SUSPENSAO,
    api: {
      method: 'delete',
      url: constants.URL_PROCESSO_SELETIVO_SUSPENSAO(processo_id),
    },
  }
}

export const getUsuarios = () => {
  return {
    type: constants.PROCESSO_SELETIVO_LIST_USUARIOS,
    api: {
      method: 'get',
      url: constants.URL_PROCESSO_SELETIVO_LIST_USUARIOS,
    },
  }
}

export const getQuizes = () => {
  return {
    type: constants.PROCESSO_SELETIVO_LIST_QUIZES,
    api: {
      method: 'get',
      url: constants.URL_PROCESSO_SELETIVO_LIST_QUIZES,
    },
  }
}
