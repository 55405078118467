import React, { useEffect, useMemo, useState } from 'react'

import moment from 'moment'

import { Row, Tabs, Tag, Button, Alert, Card, Space, Select, Empty } from 'antd'

import { DateObject } from 'react-multi-date-picker'
import { Link } from 'react-router-dom'
import EmptyFit from '../../../../components/empty/fit'

import { IRelatorioParsed } from '../../@types'

import { Table, Container, Col } from './styles'
import { getCulturasFromEmpresas } from '../../../../components/FitCultural/Api/api'

const { TabPane } = Tabs
interface RelatorioProps {
  selectedDates: DateObject[]
  loading: boolean
  setLoading: any
  dataSource: IRelatorioParsed
  selectedGroup?: any
  selectedGroupId?: string
  updateGroup?: any
}

export const Relatorio: React.FC<RelatorioProps> = ({
  selectedDates,
  loading,
  dataSource,
  selectedGroup,
  setLoading,
  selectedGroupId,
  updateGroup,
}) => {
  const columns = [
    {
      title: 'Arquétipo',
      dataIndex: 'Tipo',
    },
    {
      title: 'Instalado',
      dataIndex: 'Instalado',
    },
  ]
  const arquetiposColumns = useMemo(() => {
    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }
    return columns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates, selectedGroup])

  const valoresColumns = useMemo(() => {
    const columns = [
      {
        title: 'Valor',
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }

    return columns
  }, [selectedDates, selectedGroup])

  const forcasColumns = useMemo(() => {
    const columns = [
      {
        title: 'Força',
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]

    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  const gestaoColumns = useMemo(() => {
    const columns = [
      {
        title: 'Gestão',
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]

    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  const satisfacaoColumns = useMemo(() => {
    const columns = [
      {
        title: 'Satisfação',
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]

    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  const olharesColumns = useMemo(() => {
    const columns = [
      {
        title: 'Olhares',
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]

    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  const niveisConfiancaColumns = useMemo(() => {
    const columns = [
      {
        title: 'Confiança',
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]

    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  const generosColumns = useMemo(() => {
    const columns = [
      {
        title: 'Gênero',
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]
    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  const geracoesColumns = useMemo(() => {
    const columns = [
      {
        title: 'Geraçao',
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]

    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  const perfilLiderColumns = useMemo(() => {
    const columns = [
      {
        title: 'Perfil',
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]

    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  const expectativasEntregasColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]

    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  const perceboLiderColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Sempre',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Sempre',
          },
        ],
      },
      {
        title: 'Frequentemente',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Frequentemente',
          },
        ],
      },
      {
        title: 'Raramente',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Raramente',
          },
        ],
      },
      {
        title: 'Às vezes',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Às vezes',
          },
        ],
      },
      {
        title: 'Nunca',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Nunca',
          },
        ],
      },
    ]

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns[1].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Sempre`,
      })

      columns[2].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Frequentemente`,
      })

      columns[3].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Raramente`,
      })

      columns[4].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Às vezes`,
      })

      columns[5].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Nunca`,
      })
    })

    return columns
  }, [selectedDates])

  const reconhecimentoColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]

    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }
    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  const feedbackContribuiColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]
    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  const frequenciaFeedbackColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]
    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  const prioridadesColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Extremamente',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Extremamente',
          },
        ],
      },
      {
        title: 'Muito',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Muito',
          },
        ],
      },
      {
        title: 'Mais ou menos',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Mais ou menos',
          },
        ],
      },
      {
        title: 'Não muito',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Não muito',
          },
        ],
      },
      {
        title: 'De forma alguma',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'De forma alguma',
          },
        ],
      },
    ]

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns[1].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Extremamente`,
      })

      columns[2].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Muito`,
      })

      columns[3].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Mais ou menos`,
      })

      columns[4].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Não muito`,
      })

      columns[5].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-De forma alguma`,
      })
    })

    return columns
  }, [selectedDates])

  const escolhaEmpresaColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Extremamente',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Extremamente',
          },
        ],
      },
      {
        title: 'Muito',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Muito',
          },
        ],
      },
      {
        title: 'Mais ou menos',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Mais ou menos',
          },
        ],
      },
      {
        title: 'Não muito',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Não muito',
          },
        ],
      },
      {
        title: 'De forma alguma',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'De forma alguma',
          },
        ],
      },
    ]

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns[1].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Extremamente`,
      })

      columns[2].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Muito`,
      })

      columns[3].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Mais ou menos`,
      })

      columns[4].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Não muito`,
      })

      columns[5].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-De forma alguma`,
      })
    })

    return columns
  }, [selectedDates])

  const expectativa6MesesColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Extremamente',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Extremamente',
          },
        ],
      },
      {
        title: 'Muito',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Muito',
          },
        ],
      },
      {
        title: 'Mais ou menos',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Mais ou menos',
          },
        ],
      },
      {
        title: 'Não muito',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Não muito',
          },
        ],
      },
      {
        title: 'De forma alguma',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'De forma alguma',
          },
        ],
      },
    ]

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns[1].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Extremamente`,
      })

      columns[2].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Muito`,
      })

      columns[3].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Mais ou menos`,
      })

      columns[4].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Não muito`,
      })

      columns[5].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-De forma alguma`,
      })
    })

    return columns
  }, [selectedDates])

  const fonteEntusiasmoColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]

    if (selectedGroup) {
      columns.push({
        title: `${selectedGroup} - Instalado`,
        dataIndex: 'Grupo',
      })
    }
    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates, selectedGroup])

  // Olhar para as mudanças

  const impedimentoColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Sempre',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Sempre',
          },
        ],
      },
      {
        title: 'Frequentemente',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Frequentemente',
          },
        ],
      },
      {
        title: 'Às vezes',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Às vezes',
          },
        ],
      },
      {
        title: 'Nunca',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Nunca',
          },
        ],
      },
    ]

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns[1].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Sempre`,
      })

      columns[2].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Frequentemente`,
      })

      columns[3].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Às vezes`,
      })

      columns[4].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Nunca`,
      })
    })

    return columns
  }, [selectedDates])

  const pontosFortesColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Muito forte',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Muito forte',
          },
        ],
      },
      {
        title: 'Forte',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Forte',
          },
        ],
      },
      {
        title: 'Fraco',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Fraco',
          },
        ],
      },
      {
        title: 'Muito fraco',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Muito fraco',
          },
        ],
      },
    ]

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns[1].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Muito forte`,
      })

      columns[2].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Forte`,
      })

      columns[3].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Fraco`,
      })

      columns[4].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Muito fraco`,
      })
    })

    return columns
  }, [selectedDates])

  const principalProblemaColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Sempre',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Sempre',
          },
        ],
      },
      {
        title: 'Frequentemente',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Frequentemente',
          },
        ],
      },
      {
        title: 'Raramente',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Raramente',
          },
        ],
      },
      {
        title: 'Às vezes',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Às vezes',
          },
        ],
      },
      {
        title: 'Nunca',
        children: [
          {
            title: 'Instalado',
            dataIndex: 'Nunca',
          },
        ],
      },
    ]

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns[1].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Sempre`,
      })

      columns[2].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Frequentemente`,
      })

      columns[3].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Raramente`,
      })

      columns[4].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Às vezes`,
      })

      columns[5].children?.push({
        title: formattedDate,
        dataIndex: `${formattedDate}-Nunca`,
      })
    })

    return columns
  }, [selectedDates])

  const [showFit, setShow] = useState(false)

  useEffect(() => {
    culturasFromEmpresas()
  }, [])

  async function culturasFromEmpresas() {
    const culturas = await getCulturasFromEmpresas()
    if (culturas.count == 0) setShow(true)
  }

  const indicadoresMudancaColumns = useMemo(() => {
    const columns = [
      {
        dataIndex: 'Tipo',
      },
      {
        title: 'Instalado',
        dataIndex: 'Instalado',
      },
    ]

    selectedDates.forEach((date) => {
      const formattedDate = moment(date.toDate()).format('DD/MM/YYYY')
      columns.push({
        title: formattedDate,
        dataIndex: formattedDate,
      })
    })

    return columns
  }, [selectedDates])

  return (
    <>
      <Tabs defaultActiveKey="1" onChange={updateGroup}>
        <TabPane tab={<span>Informações Gerais</span>} key="1">
          <Container>
            <Card>
              <Row gutter={16}>
                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  {showFit ? (
                    <Table
                      loading={loading}
                      title={() => 'Arquétipos Culturais'}
                      bordered
                      pagination={false}
                      size="small"
                      dataSource={dataSource.culturaInstalada.cultura}
                      columns={arquetiposColumns}
                    />
                  ) : (
                    <EmptyFit
                      description={
                        <Link to="../../cultura-desejada/relatorios/empresas">
                          Verifique o fit na aba de fit cultural
                        </Link>
                      }
                    />
                  )}
                </Col>

                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Valores em Evidência'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={valoresColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.valoresEvidencia
                    }
                  />
                </Col>

                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Forças Estratégicas'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={forcasColumns}
                    dataSource={dataSource.culturaInstalada.forcas}
                  />
                </Col>
                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Gestão'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={gestaoColumns}
                    dataSource={dataSource.culturaInstalada.gestao}
                  />
                </Col>
                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Satisfação Geral'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={satisfacaoColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.satisfacaoGeral
                    }
                  />
                </Col>
                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Olhares para o Ambiente'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={olharesColumns}
                    dataSource={dataSource.culturaInstalada.olhares}
                  />
                </Col>
                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Níveis de Confiança'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={niveisConfiancaColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.niveisConfianca
                    }
                  />
                </Col>
                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Gêneros'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={generosColumns}
                    dataSource={dataSource.generos}
                  />
                </Col>
                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Gerações'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={geracoesColumns}
                    dataSource={dataSource.geracoes}
                  />
                </Col>
              </Row>
            </Card>
          </Container>
        </TabPane>
        <TabPane tab={<span>Olhar para Liderança</span>} key="2">
          <Container>
            <Card>
              <Row gutter={16}>
                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Perfil do Líder direto'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={perfilLiderColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharLideranca
                        .perfilLiderDireto
                    }
                  />
                </Col>
                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Tenho clareza sobre as minhas entregas'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={expectativasEntregasColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharLideranca
                        .expectativaEntregas
                    }
                  />
                </Col>

                <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Percebo que meu Líder'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={perceboLiderColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharLideranca
                        .perceboLider
                    }
                  />
                </Col>
                <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Sou reconhecido e elogiado'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={reconhecimentoColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharLideranca
                        .reconhecimento
                    }
                  />
                </Col>
                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Feedback contribui para'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={feedbackContribuiColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharLideranca
                        .feedbackContribui
                    }
                  />
                </Col>
                <Col xs={24} sm={24} lg={12} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Frequência do Feedback'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={frequenciaFeedbackColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharLideranca
                        .frequenciaFeedback
                    }
                  />
                </Col>
              </Row>
            </Card>
          </Container>
        </TabPane>
        <TabPane tab={<span>Olhar para Si</span>} key="3">
          <Container>
            <Card>
              <Row gutter={16}>
                <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Tenho como prioridade neste momento'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={prioridadesColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharParaSi.prioridades
                    }
                  />
                </Col>
                <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Porque escolhi esta empresa para trabalhar?'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={escolhaEmpresaColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharParaSi
                        .escolhaEmpresa
                    }
                  />
                </Col>
                <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Para os próximos 6 meses, espero'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={expectativa6MesesColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharParaSi
                        .expectativas6meses
                    }
                  />
                </Col>
                <Col xs={24} sm={24} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() =>
                      'Qual tem sido sua maior fonte de entusiasmo neste momento?'
                    }
                    bordered
                    pagination={false}
                    size="small"
                    columns={fonteEntusiasmoColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharParaSi
                        .fonteEntusiasmo
                    }
                  />
                </Col>
              </Row>
            </Card>
          </Container>
        </TabPane>
        <TabPane tab={<span>Olhar para Mudança</span>} key="4">
          <Container>
            <Card>
              <Row gutter={16}>
                <Col xs={24} sm={24} lg={24} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Principal problema percebido'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={principalProblemaColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharParaMudancas
                        .principalProblema
                    }
                  />
                </Col>
                <Col xs={24} sm={24} lg={24} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'O que impede as mudanças'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={impedimentoColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharParaMudancas
                        .impedimento
                    }
                  />
                </Col>
                <Col xs={24} sm={24} lg={24} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Pontos fortes que auxiliam as mudanças'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={pontosFortesColumns}
                    dataSource={
                      dataSource.culturaOrganizacional.olharParaMudancas
                        .pontosFortes
                    }
                  />
                </Col>
                <Col xs={24} sm={24} lg={24} style={{ marginBottom: '16px' }}>
                  <Table
                    loading={loading}
                    title={() => 'Indicadores de mudança'}
                    bordered
                    pagination={false}
                    size="small"
                    columns={indicadoresMudancaColumns}
                    dataSource={[
                      dataSource.culturaOrganizacional.olharParaMudancas
                        .velocidadeAdocao,
                      dataSource.culturaOrganizacional.olharParaMudancas
                        .engajamento,
                      dataSource.culturaOrganizacional.olharParaMudancas
                        .proficiencia,
                    ]}
                  />
                </Col>
              </Row>
            </Card>
          </Container>
        </TabPane>
      </Tabs>
    </>
  )
}
