import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import { useDispatch } from 'react-redux'

import XLSX from 'xlsx'

import { toast } from 'react-toastify'

import { Col, Row, Card, Input, Button, Tabs } from 'antd'

import { SearchOutlined } from '@ant-design/icons'

import { sorterString } from '../../../utils/utils'
import Table from '../../../components/editableTable/EditableTable'

import { getColaboradores, getCandidatos } from './actions'

const { TabPane } = Tabs

function ListagemFitCultural({}, searchInput) {
  const dispatch = useDispatch()

  const [colaboradores, setColaboradores] = useState([])

  const [candidatos, setCandidatos] = useState([])

  useEffect(() => {
    dispatch(getColaboradores()).then(({ response }) => {
      if (response.status === 200) {
        setColaboradores(response.data.colaboradores)
      } else toast.error('Erro ao listar colaboradores.')
    })

    dispatch(getCandidatos()).then(({ response }) => {
      if (response.status === 200) {
        setCandidatos(response.data.candidatos)
      } else toast.error('Erro ao listar candidatos.')
    })
  }, [dispatch])

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder="Buscar"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, display: 'block', marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#30318b' : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      )
    },
  })

  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  const columnsColaboradores = [
    {
      title: 'Nome',
      dataIndex: 'pes_nome',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => sorterString(a, b, 'pes_nome'),
      ...getColumnSearchProps('pes_nome'),
      align: 'center',
      width: '25%',
    },
    {
      title: 'Fit Cultural',
      dataIndex: ['fitCulturalEmpresa', 'geral'],
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        if (a.fitCulturalEmpresa && b.fitCulturalEmpresa) {
          return (
            parseFloat(a.fitCulturalEmpresa.geral) -
            parseFloat(b.fitCulturalEmpresa.geral)
          )
        }
        if (a.fitCulturalEmpresa) {
          return 1
        }
        if (b.fitCulturalEmpresa) {
          return -1
        }

        return 0
      },
      align: 'center',
      render: (text, record) => (text ? `${parseFloat(text).toFixed(1)}%` : ''),
      width: '10%',
    },
    {
      title: 'Fit (Arquétipos)',
      dataIndex: ['fitCulturalEmpresa', 'fitArquetipos', 'geral'],
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        if (
          a.fitCulturalEmpresa &&
          a.fitCulturalEmpresa.fitArquetipos &&
          b.fitCulturalEmpresa &&
          b.fitCulturalEmpresa.fitArquetipos
        ) {
          return (
            parseFloat(a.fitCulturalEmpresa.fitArquetipos.geral) -
            parseFloat(b.fitCulturalEmpresa.fitArquetipos.geral)
          )
        }
        if (a.fitCulturalEmpresa && a.fitCulturalEmpresa.fitArquetipos) {
          return 1
        }
        if (b.fitCulturalEmpresa && b.fitCulturalEmpresa.fitArquetipos) {
          return -1
        }

        return 0
      },
      align: 'center',
      render: (text, record) => (text ? `${parseFloat(text).toFixed(1)}%` : ''),
      width: '10%',
    },
    {
      title: 'Fit (Valores)',
      dataIndex: ['fitCulturalEmpresa', 'fitValores', 'geral'],
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        if (
          a.fitCulturalEmpresa &&
          a.fitCulturalEmpresa.fitValores &&
          b.fitCulturalEmpresa &&
          b.fitCulturalEmpresa.fitValores
        ) {
          return (
            parseFloat(a.fitCulturalEmpresa.fitValores.geral) -
            parseFloat(b.fitCulturalEmpresa.fitValores.geral)
          )
        }
        if (a.fitCulturalEmpresa && a.fitCulturalEmpresa.fitValores) {
          return 1
        }
        if (b.fitCulturalEmpresa && b.fitCulturalEmpresa.fitValores) {
          return -1
        }

        return 0
      },
      align: 'center',
      render: (text, record) => (text ? `${parseFloat(text).toFixed(1)}%` : ''),
      width: '10%',
    },
    {
      title: 'Times',
      dataIndex: 'times',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => sorterString(a, b, 'times'),
      ...getColumnSearchProps('times'),
      align: 'center',
      width: '20%',
    },
    {
      title: 'Cargos',
      dataIndex: 'cargos',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => sorterString(a, b, 'cargos'),
      ...getColumnSearchProps('cargos'),
      align: 'center',
      width: '15%',
    },
    {
      title: 'Perfil Comportamental',
      dataIndex: 'perfil',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => sorterString(a, b, 'perfil'),
      ...getColumnSearchProps('perfil'),
      render: (text, record) => {
        return record.perfil
          ? `${record.perfil.primario}${record.perfil.secundario}`
          : ''
      },
      align: 'center',
      width: '10%',
    },
  ]

  const columnsCandidatos = [
    {
      title: 'Nome',
      dataIndex: 'pes_nome',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => sorterString(a, b, 'pes_nome'),
      ...getColumnSearchProps('pes_nome'),
      align: 'center',
      width: '30%',
    },
    {
      title: 'Fit Cultural',
      dataIndex: ['fitCulturalEmpresa', 'geral'],
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        if (a.fitCulturalEmpresa && b.fitCulturalEmpresa) {
          return (
            parseFloat(a.fitCulturalEmpresa.geral) -
            parseFloat(b.fitCulturalEmpresa.geral)
          )
        }
        if (a.fitCulturalEmpresa) {
          return 1
        }
        if (b.fitCulturalEmpresa) {
          return -1
        }

        return 0
      },
      align: 'center',
      render: (text, record) => (text ? `${parseFloat(text).toFixed(1)}%` : ''),
      width: '15%',
    },
    {
      title: 'Fit (Arquétipos)',
      dataIndex: ['fitCulturalEmpresa', 'fitArquetipos', 'geral'],
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        if (
          a.fitCulturalEmpresa &&
          a.fitCulturalEmpresa.fitArquetipos &&
          b.fitCulturalEmpresa &&
          b.fitCulturalEmpresa.fitArquetipos
        ) {
          return (
            parseFloat(a.fitCulturalEmpresa.fitArquetipos.geral) -
            parseFloat(b.fitCulturalEmpresa.fitArquetipos.geral)
          )
        }
        if (a.fitCulturalEmpresa && a.fitCulturalEmpresa.fitArquetipos) {
          return 1
        }
        if (b.fitCulturalEmpresa && b.fitCulturalEmpresa.fitArquetipos) {
          return -1
        }

        return 0
      },
      align: 'center',
      render: (text, record) => (text ? `${parseFloat(text).toFixed(1)}%` : ''),
      width: '15%',
    },
    {
      title: 'Fit (Valores)',
      dataIndex: ['fitCulturalEmpresa', 'fitValores', 'geral'],
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        if (
          a.fitCulturalEmpresa &&
          a.fitCulturalEmpresa.fitValores &&
          b.fitCulturalEmpresa &&
          b.fitCulturalEmpresa.fitValores
        ) {
          return (
            parseFloat(a.fitCulturalEmpresa.fitValores.geral) -
            parseFloat(b.fitCulturalEmpresa.fitValores.geral)
          )
        }
        if (a.fitCulturalEmpresa && a.fitCulturalEmpresa.fitValores) {
          return 1
        }
        if (b.fitCulturalEmpresa && b.fitCulturalEmpresa.fitValores) {
          return -1
        }

        return 0
      },
      align: 'center',
      render: (text, record) => (text ? `${parseFloat(text).toFixed(1)}%` : ''),
      width: '15%',
    },
    {
      title: 'Perfil Comportamental',
      dataIndex: 'perfil',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => sorterString(a, b, 'perfil'),
      ...getColumnSearchProps('perfil'),
      render: (text, record) => {
        return record.perfil
          ? `${record.perfil.primario}${record.perfil.secundario}`
          : ''
      },
      align: 'center',
      width: '25%',
    },
  ]

  const exportarExcel = ({
    pessoas,
    title = 'pessoas_fit',
    type = 'colaborador',
  }) => {
    const aoa = [
      [
        'NOME',
        'FIT CULTURAL',
        'FIT (ARQUÉTIPOS)',
        'FIT (VALORES)',
        ...(type === 'colaborador' ? ['TIMES'] : []),
        ...(type === 'colaborador' ? ['CARGOS'] : []),
        'PERFIL COMPORTAMENTAL',
      ],
      ...pessoas.map((pessoa) => [
        pessoa.pes_nome,
        pessoa.fitCulturalEmpresa
          ? `${parseFloat(pessoa.fitCulturalEmpresa.geral).toFixed(1)}%`
          : '',
        pessoa.fitCulturalEmpresa && pessoa.fitCulturalEmpresa.fitArquetipos
          ? `${parseFloat(
              pessoa.fitCulturalEmpresa.fitArquetipos.geral
            ).toFixed(1)}%`
          : '',
        pessoa.fitCulturalEmpresa && pessoa.fitCulturalEmpresa.fitValores
          ? `${parseFloat(pessoa.fitCulturalEmpresa.fitValores.geral).toFixed(
              1
            )}%`
          : '',
        ...(type === 'colaborador' ? [pessoa.times] : []),
        ...(type === 'colaborador' ? [pessoa.cargos] : []),
        pessoa.perfil
          ? `${pessoa.perfil.primario}${pessoa.perfil.secundario}`
          : '',
      ]),
    ]

    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(aoa)

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Colaboradores Fit')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `${title}.xlsx`)
  }

  return (
    <>
      <Helmet>
        <title>TeamHub | Pessoas - Fit Cultural</title>
      </Helmet>
      <Col span={24}>
        <Row>
          <Col xs={24} sm={24}>
            <Tabs>
              <TabPane key="1" tab={<span>Colaboradores</span>}>
                <Card>
                  <Table
                    header={() => (
                      <Button
                        type="primary"
                        style={{ marginBottom: '16px' }}
                        onClick={() =>
                          exportarExcel({
                            pessoas: colaboradores,
                            title: 'colaboradores_fit',
                          })
                        }
                        disabled={colaboradores.length === 0}
                      >
                        Exportar Excel
                      </Button>
                    )}
                    dataSource={colaboradores}
                    columns={columnsColaboradores}
                    isDeleble={false}
                  />
                </Card>
              </TabPane>
              <TabPane key="2" tab={<span>Candidatos</span>}>
                <Card>
                  <Table
                    header={() => (
                      <Button
                        type="primary"
                        style={{ marginBottom: '16px' }}
                        onClick={() =>
                          exportarExcel({
                            pessoas: candidatos,
                            title: 'candidatos_fit',
                            type: 'candidato',
                          })
                        }
                        disabled={candidatos.length === 0}
                      >
                        Exportar Excel
                      </Button>
                    )}
                    dataSource={candidatos}
                    columns={columnsCandidatos}
                    isDeleble={false}
                  />
                </Card>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default ListagemFitCultural
