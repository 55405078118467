import React from 'react'

import { Form, Input } from 'antd'

function RegistrarObservacao({ handleChangeObservacao, observacao, onFinish }) {
  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      initialValues={{ observacao }}
      id="registrar-observacao-submit"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        name="observacao"
        rules={[
          {
            required: true,
            message: 'Por favor, preencha a observação.',
          },
        ]}
      >
        <Input.TextArea
          onChange={(e) => handleChangeObservacao(e.target.value)}
          placeholder="Informe sua observação"
          value={observacao}
          style={{ height: '100px' }}
        />
      </Form.Item>
    </Form>
  )
}

export default RegistrarObservacao
