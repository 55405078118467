import React, { Component } from 'react'

// Components
import PesquisasView from './PesquisasView'

// Services
import { getContaURL } from '../../services/auth'

// CSS

// Constants

class PesquisasController extends Component {
  state = {
    showModal: {
      'Perfil Comportamental': false,
    },
  }

  componentDidMount = () => {
    const { getPesquisasDisponiveis, getAvaliacoesDisponiveis } = this.props

    getPesquisasDisponiveis()
    getAvaliacoesDisponiveis()
  }

  onStart = (modalId, pesquisaId) => {
    const { history } = this.props
    const contaURL = getContaURL()

    if (modalId === 'Perfil Comportamental') this.openModal(modalId)
    else if (modalId === 'Percepcão do Ambiente')
      history.push(`/${contaURL}/pesquisas/percepcao-ambiente/${pesquisaId}`)
  }

  openModal = (modalId) => {
    this.setState((prevState) => ({
      showModal: { ...prevState.showModal, [modalId]: true },
    }))
  }

  closeModal = (modalId) => {
    this.setState((prevState) => ({
      showModal: { ...prevState.showModal, [modalId]: false },
    }))
  }

  render() {
    return (
      <PesquisasView
        {...this.props}
        {...this.state}
        openModal={this.openModal}
        closeModal={this.closeModal}
        onStart={this.onStart}
      />
    )
  }
}

export default PesquisasController
