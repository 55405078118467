import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import {
  Col,
  Row,
  Button,
  Popconfirm,
  Modal,
  Divider,
  Card,
  Tooltip,
  Switch,
} from 'antd'

import {
  ExclamationCircleFilled,
  DeleteOutlined,
  Loading3QuartersOutlined,
  UserAddOutlined,
} from '@ant-design/icons'

import { toast } from 'react-toastify'

import Table from '../../../../components/editableTable/EditableTable'

import PsSla from './components/NovaSla/index'

import {
  listSlas,
  storeSlas,
  updateSlas,
  deleteSlas,
  loadSlas,
} from './actions/actions'

import { Action, TableHeader } from './styles'

function Sla() {
  const dispatch = useDispatch()

  const [showModalNewSla, setShowModalNewSla] = useState(false)

  const [Slas, setSlas] = useState([])

  const [showInativos, setShowInativos] = useState(false)

  const [loadingSlas, setloadingSlas] = useState(false)

  const [handleSla, sethandleUpdateSla] = useState(false)

  const loadSlas = useCallback(() => {
    setloadingSlas(true)
    dispatch(listSlas()).then(({ response }) => {
      setloadingSlas(false)
      if (response.status === 200) {
        setSlas(response.data)
      }
    })
  }, [dispatch])

  useEffect(() => {
    loadSlas()
  }, [loadSlas])

  const onFinishNewSla = () => {
    dispatch(storeSlas(Sla)).then(({ response }) => {
      if (response.status === 200) {
        toast.success('Sla cadastrado com sucesso.')
        loadSlas()
      } else toast.error('Erro ao cadastrar Sla.')
    })
  }

  const handleListSlas = useCallback(() => {
    dispatch(listSlas()).then(({ response }) => {
      if (response.status === 200) {
        const { slas } = response.data
        setSlas(slas.map((m) => ({ value: m.sla_id, label: m.nome })))
      }
    })
  }, [dispatch])

  const handleDeleteSla = useCallback(
    (sla_id) => {
      dispatch(deleteSlas({ sla_id })).then(({ response }) => {
        if (response.status === 204) {
          toast.success('Sla excluída com sucesso.')
          loadSlas()
        }
      })
    },
    [dispatch, loadSlas]
  )

  const handleUpdateSla = useCallback(
    (updatedSla, data) => {
      dispatch(updateSlas({ sla_id: updatedSla.sla_id, ...updatedSla })).then(
        ({ response }) => {
          if (response.status === 200) {
            toast.success('Sla atualizada com sucesso.')
            loadSlas()
          }
        }
      )
    },
    [dispatch, loadSlas]
  )

  const getColumns = useCallback(() => {
    return [
      {
        title: 'Nome',
        dataIndex: 'nome',
        width: '20%',
        sorter: (a, b) => sorterString(a, b, 'nome'),
        editable: true,
      },
      {
        title: 'Descricao',
        dataIndex: 'descricao',
        width: '25%',
        sorter: (a, b) => sorterString(a, b, 'descricao'),
        editable: true,
      },
      {
        title: 'Dias',
        dataIndex: 'dias',
        width: '25%',
        sorter: (a, b) => sorterString(a, b, 'dias'),
        editable: true,
      },
      {
        title: 'Criado Por',
        dataIndex: ['creator', 'colaborador', 'pes_nome'],
        width: '15%',
        sorter: (a, b) =>
          sorterString(a, b, ['creator', 'colaborador', 'pes_nome']),
      },
      {
        title: 'Atualizado Por',
        dataIndex: ['updater', 'colaborador', 'pes_nome'],
        width: '15%',
        sorter: (a, b) =>
          sorterString(a, b, ['updater', 'colaborador', 'pes_nome']),
      },
      {
        title: 'Data de Atualização',
        dataIndex: 'updated_at',
        width: '10.5%',
        sorter: (a, b) => sorterDate(a, b, 'updated_at'),
        render: (text) =>
          text
            ? moment(text).utc().tz(moment.tz.guess()).format('DD/MM/YYYY')
            : '',
      },
      {
        title: 'Ações',
        key: 'extra_actions',
        width: '10%',
        render: (text, record) => {
          return (
            <>
              <Divider type="vertical" />
              <Tooltip title="Excluir">
                {' '}
                <Popconfirm
                  title="Tem certeza que deseja excluir esse sla?"
                  onConfirm={() => handleDeleteSla(record.sla_id)}
                  okText="Excluir"
                  cancelText="Cancelar"
                  icon={
                    <ExclamationCircleFilled style={{ color: '#ef5472' }} />
                  }
                >
                  <Action>
                    <DeleteOutlined />
                  </Action>
                </Popconfirm>
              </Tooltip>
            </>
          )
        },
      },
    ]
  }, [handleDeleteSla])

  const handleCancelNewSla = useCallback(() => {
    setShowModalNewSla(false)
  }, [])

  const handleNewSla = useCallback(
    (data) => {
      dispatch(storeSlas(data)).then(({ response }) => {
        if (response.status === 200) {
          toast.success('Sla criada com sucesso.')
          setShowModalNewSla(false)
          loadSlas()
        }
      })
    },
    [dispatch, loadSlas]
  )

  return (
    <Col span={24}>
      <Row>
        <Col xs={24} sm={24}>
          <Card title="Cadastre e gerencie seus SLAs">
            <Table
              dataSource={Slas.filter((sla) => {
                if (showInativos) {
                  return sla
                }
                return parseInt(sla.ativo) === 1
              }).map((sla) => ({ ...sla, key: sla.sla_id }))}
              isEditable
              key={(record) => record.sla_id}
              loading={loadingSlas}
              columns={getColumns()}
              onSave={handleUpdateSla}
              header={() => (
                <TableHeader>
                  <div>
                    <Switch
                      checked={showInativos}
                      onChange={() => setShowInativos(!showInativos)}
                    />
                    <span>Exibir SLAs inativos</span>
                  </div>
                  <Button
                    type="primary"
                    onClick={() => setShowModalNewSla(true)}
                  >
                    <UserAddOutlined /> NOVO SLA
                  </Button>
                </TableHeader>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="NOVO SLA"
        visible={showModalNewSla}
        cancelText="CANCELAR"
        onCancel={() => handleCancelNewSla()}
        okText="SALVAR"
        destroyOnClose
        okButtonProps={{
          form: 'nova-sla',
          key: 'submit',
          htmlType: 'submit',
        }}
        centered
      >
        <PsSla
          handleChangeSla={handleUpdateSla}
          onFinish={handleNewSla}
          {...handleSla}
        />
      </Modal>
    </Col>
  )
}
export default Sla
