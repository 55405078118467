import moment from 'moment'

// Redux types
import * as constants from '../constants/constantsCandidatos'

export const getCandidatos = () => {
  return {
    type: constants.CANDIDATOS_GET,
    api: {
      method: 'get',
      url: constants.URL_CANDIDATOS_GET,
    },
  }
}

export const postCandidato = ({
  cpf,
  nome,
  dataNascimento,
  email,
  sexo,
  telefone,
  cargo,
  vetorComportamental,
  enviarEmails,
  valoresOrganizacionais,
  profissao,
  prtsalario,
  indicacao,
  linkedin,
  twitter,
  site,
  cep,
  logradouro,
  nro,
  complemento,
  bairro,
  ufid,
  cidadeid,
  cargosEscolhidos,
  curriculo,
  pcd,
  pcd_tipo,
  cid,
}) => {
  return {
    type: constants.CANDIDATO_POST,
    api: {
      method: 'post',
      url: constants.URL_CANDIDATO_POST,
      data: {
        pes_cpf: cpf,
        pes_nome: nome,
        pes_datanascimento: moment(dataNascimento).format(),
        pes_email: email,
        pes_sexo: sexo,
        pes_cel: telefone,
        cargo,
        vetorComportamental,
        enviarEmails,
        valoresOrganizacionais,
        profissao,
        prtsalario,
        indicacao,
        linkedin,
        twitter,
        site,
        cep,
        logradouro,
        nro,
        complemento,
        bairro,
        ufid,
        cidadeid,
        cargosEscolhidos,
        curriculo,
        pcd,
        pcd_tipo,
        cid,
      },
    },
  }
}

export const efetivaCandidato = ({ cpf, cargo, id }) => {
  return {
    type: constants.CANDIDATO_EFETIVA,
    api: {
      method: 'post',
      url: constants.URL_CANDIDATO_EFETIVA + '/' + id,
      data: {
        cargo,
        cpf,
      },
    },
  }
}

export const postCandidatos = (listaCandidatos) => {
  return {
    type: constants.CANDIDATOS_POST,
    api: {
      method: 'post',
      url: constants.URL_CANDIDATOS_POST,
      data: {
        pessoas: listaCandidatos.map(
          ({ NOME, EMAIL, CPF, SEXO, NASCIMENTO, TELEFONE, CARGO, GRUPO }) => ({
            pes_cpf: CPF,
            pes_nome: NOME,
            pes_datanascimento: moment(NASCIMENTO, 'DD/MM/YYYY').format(),
            pes_email: EMAIL,
            pes_sexo: SEXO,
            pes_cel: TELEFONE,
            cargo: CARGO,
            grupo: GRUPO,
          })
        ),
      },
    },
  }
}

export const putCandidato = ({
  id,
  cpf,
  nome,
  dataNascimento,
  email,
  sexo,
  telefone,
}) => {
  return {
    type: constants.CANDIDATO_PUT,
    api: {
      method: 'put',
      url: `${constants.URL_CANDIDATO_PUT}/${id}`,
      data: {
        pes_cpf: cpf,
        pes_nome: nome,
        pes_datanascimento: dataNascimento && moment(dataNascimento).format(),
        pes_email: email,
        pes_sexo: sexo,
        pes_cel: telefone,
      },
    },
  }
}

export const deleteCandidato = (id) => {
  return {
    type: constants.CANDIDATO_DELETE,
    api: {
      method: 'delete',
      url: `${constants.URL_CANDIDATO_DELETE}/${id}`,
    },
  }
}

export const sendVetorComportamental = (data) => {
  return {
    type: constants.CANDIDATOS_SEND_AVALIACOES,
    api: {
      method: 'post',
      url: constants.URL_CANDIDATOS_SEND_AVALIACOES,
      data,
    },
  }
}

export const sendValoresOrganizacionaisLote = (data) => {
  return {
    type: constants.CANDIDATOS_SEND_VALORES,
    api: {
      method: 'post',
      url: constants.URL_CANDIDATOS_SEND_VALORES,
      data,
    },
  }
}

/*
export const sendPercepcao = id => {
  return {
    type: constants.SEND_PERCEPCAO,
    api: {
      method: 'post',
      url: constants.URL_SEND_PERCEPCAO + '/' + id + '/2',
    },
  };
};
*/

export const getCargos = () => {
  return {
    type: constants.CARGOS_GET,
    api: {
      method: 'get',
      url: constants.URL_CARGOS_GET,
    },
  }
}

export const triarCandidatos = ({
  processo_id,
  candidatos,
  confirm = false,
}) => {
  return {
    type: constants.CANDIDATOS_TRIAR,
    api: {
      method: 'post',
      url: constants.URL_CANDIDATOS_TRIAR(processo_id),
      data: {
        candidatos,
        confirm,
      },
    },
  }
}

export const listarProcessos = () => {
  return {
    type: constants.CANDIDATOS_LIST_PROCESSOS,
    api: {
      method: 'get',
      url: constants.URL_CANDIDATOS_LIST_PROCESSOS,
    },
  }
}
